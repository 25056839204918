// VideoDetails.js

import React from 'react';
import InstallationVideoBitmovin from './InstallationVideoBitmovin'; // Import the Bitmovin player component
import { Container, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

const VideoDetail = () => {
    const location = useLocation();
    const video = location.state.video;
    return (
        <div>

        {video && (
            <>
              <Container maxWidth="lg" >
        <div className='container'>
          <Grid container>
          <Grid item xs={12} md={10} sm={12} lg={1.5} >
            </Grid>
            <Grid item xs={12} md={10} sm={12} lg={9} >
              <div className='widget-wrap'>
              <InstallationVideoBitmovin videoUrl={video?.url} poster={video?.poster} />
              </div>
              <div className='elementor-wrap-div '>
                <div className='elementor-col  elementor-m-col '>
                  <h3 className='heading-title'> {video?.maintitle} </h3>
                </div>
                <div className='divider' style={{margin: "1% 0 0 0"}}></div>
                <div className='paragraph'>
                  <p>{video?.title}</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={10} sm={12} lg={1.5} >
            </Grid>
          
          </Grid>
        </div>
      </Container>
            </>
        )}
    </div>
    );
};

export default VideoDetail;
