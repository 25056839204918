import React from 'react'
import SliderGallery from '../components/SliderGallery'



const CoolstepCopperGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Copper-1.jpg"
    },
   
   
  ]

  return (
    <div>
      <SliderGallery
        title="Coolstep Copper"
        para1="Designed to look beautiful while keeping cooler than it’s darker Dec-Tec counterparts. The copper flecks add an aesthetic detail from the original CoolStep and allow it to compliment warm color palettes."
        para2="*We currently only have one gallery picture of CoolStep Copper. If you have any pictures of this membrane, please send them in to us!*"
        galleryImages={galleryImages}
        previouslink="/coolstep-gallery/"
        nextlink="/coolstep-sandalwood-gallery/"
      />
    </div>
  )
}

export default CoolstepCopperGallery
