import React from 'react'
import '../css/technicalCard.css';
import { NavLink} from 'react-router-dom'



const TechnicalCard = (props) => {
  return (

    <div className='card1'>
      <div className='card__body1'>
        <NavLink to={props.imglink}>
          <img className='card__img1' srcSet={props.img} src={props.img} alt="dec-tec" />
        </NavLink>
        <h3 className='card__title1'>
          <NavLink to={props.headerlink}>
            <b>{props.title}</b>
          </NavLink>
        </h3>
        <div className='contentTitle'></div>
        <p className='card__description1'>{props.description}</p>
      </div>
    </div>

  )
}

export default TechnicalCard
