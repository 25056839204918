import React, { useState } from 'react'
import { SaveTechnicalDocuments } from '../api/UserAPI';
import TitleCard from '../components/TitleCard';
import { Container } from '@mui/system'
import { Button, Grid, InputLabel, TextareaAutosize, TextField } from '@mui/material'
import { NavLink} from 'react-router-dom'

const TechnicalRequest = () => {

    const [technicalRequestDetails, setTechnicalRequestDetails] = useState({
        fullName: '',
        title: '',
        email: '',
        document: "",
      })

      function handleChange(e) {
        setTechnicalRequestDetails({
          ...technicalRequestDetails,
          [e.target.name]: e.target.value
        });
      }
    
      async function handleSend() {
        const obj = {
          UserId: 1,
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [{
            Id: 0,
            Name: technicalRequestDetails.fullName,
            Title: technicalRequestDetails.title,
            Email: technicalRequestDetails.email,
            DocumentsNeeded : technicalRequestDetails.document,
            ModifiedBy: 1
          }]
        }
        try {
          const res = await SaveTechnicalDocuments(obj);
          if (res[0].message === "OK") {
            alert("Document List Sent Successfully...")
            setTechnicalRequestDetails({
              fullName: '',
              title: '',
              email: '',
              document: "",
            })
    
          }
        }
        catch (err) {
          console.log(err);
        }
      }


    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/jeff-sheldon-3220-unsplash.jpg",
            firstText: 'TECHNICAL',
            boldText: ' REQUESTS ',
            description: '',
            isImageOnlyFlag: true,
            textShow: true,
            textDirect: true,
            textAlign: true,
            cartHeight: '100vh',
            bgAttachment: 'initial',
            bgPosition: 'center center',
        },
    ];



    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>


            <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                <Grid item xs={12} sx={{ padding: "0% 5%" }}>
                    <div className='product-div-wrap' >
                    <div className='elementor'>
                        <h3 className='heading-title  '> HAVE A <span className='title-bold'>QUESTION?</span></h3>
                        <div className='divider classic-section-third-divider' >
                        </div>
                        </div>
                        <div className='paragraph' style={{ textAlign:"center" }}>
                            <p>Dec-Tec prides itself to be able to answer any question our contractors and customers may have as well as support our clients from beginning to end. If you have a technical request or question for us, please do not hesitate to reach out to us via the email link below or through our customer service line.</p>
                        </div>

                        <div className='paragraph' style={{ fontSize: '1.8em' }}>
                        <NavLink to="mailto:technical@dec-tec.com">
                            <p style={{color:"#00779f"}}><b>technical@dec-tec.com</b></p>
                            </NavLink>
                        </div>
                    </div>
                </Grid>
            </Container>

            
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
                <Grid className='formgridLeft' item lg={7} md={7} sm={12} xs={12}>
                    <div className="request_form padding-lg">
                        <div className='title-container'>
                            <h1 align="center" style={{ color: "#ffffff" }} className='contentTitle'> <span className='thin-content'>LOOKING FOR <br /></span>TECHNICAL DOCUMENTS? </h1>
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item lg={6} sm={12} xs={12} md={6}>
                                <InputLabel shrink htmlFor="bootstrap-input"> NAME</InputLabel>
                                <TextField
                                    placeholder="Name"
                                    fullWidth
                                    id="outlined-start-adornment"
                                    name="fullName"
                                    value={technicalRequestDetails.fullName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item lg={6} sm={12} xs={12} md={6}>
                                <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> TITLE </InputLabel>
                                <TextField
                                    placeholder="Title"
                                    fullWidth
                                    id="outlined-start-adornment"
                                    name="title"
                                    value={technicalRequestDetails.title}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12} md={12}>
                                <InputLabel shrink htmlFor="bootstrap-input"> EMAIL </InputLabel>
                                <TextField
                                    placeholder="Email"
                                    fullWidth
                                    id="outlined-start-adornment"
                                    name="email"
                                    value={technicalRequestDetails.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item lg={12} sm={12} xs={12} md={12}>
                                <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> DOCUMENTS NEEDED </InputLabel>
                                <TextareaAutosize
                                    className=''
                                    placeholder="Describe what you need in as much detail as possible."
                                    fullWidth
                                    name="document"
                                    value={technicalRequestDetails.document}
                                    onChange={handleChange}
                                    style={{ width: "99%", height: 200, backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid #ffffff", color: "#ffffff" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Button style={{ marginTop: "10px", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid #ffffff" }} fullWidth variant="contained" onClick={handleSend}>Send</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid className='formgridRight' item lg={5} md={5} sm={12} xs={12} style={{ display:"flex"}}>
                    <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/photo-1508282353155-05eb4eac138d-min-822x1024.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/photo-1508282353155-05eb4eac138d-min-822x1024.jpg" className='first-image' alt="dec-tec" />
                </Grid>
            </Grid>
        </div>
    )
}

export default TechnicalRequest
