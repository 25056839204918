import { Container, Grid } from '@mui/material';
import React from 'react'
import TitleCard from '../components/TitleCard';
import { NavLink } from 'react-router-dom'

const Education = () => {


    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/andrew-neel-308138-unsplash-min.jpg",
            firstText: 'CONTINUING',
            boldText: 'EDUCATION ',
            description: 'Dec-Tec is continually expanding its reach to the architectural and design communities. Our CE courses, approved by professional associations such as AIA, allow us to provide the most current professional development while assisting members to maintain their mandatory continuing education requirements.',
            isImageOnlyFlag: true,
            textShow: false,
            textDirect: false,
            textAlign: true,
            cartHeight: '100vh',
            bgAttachment: 'initial',
            bgPosition: 'center center',
        },
    ];

    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "10% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center'}}>
                        <div >
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/daniel-mccullough-348488-unsplash-min-1-o33ibzblp9c336k9uszn3pjuqk3bk0k27gaa7vqv0o.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/daniel-mccullough-348488-unsplash-min-1-o33ibzblp9c336k9uszn3pjuqk3bk0k27gaa7vqv0o.jpg" alt='secThird' width={"100%"} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        {/* <div className='product-div-wrap' style={{ alignItems: 'flex-start', marginLeft: "10%", marginRight: "10%" }} > */}
                        <div className='product-div-wrap content-padding-left' >
                            <h3 className='heading-title  '> PROFESSIONAL<br /><span className='title-bold'>DEVELOPMENT</span></h3>
                            <div className='divider classic-section-third-divider' >
                            </div>
                            <div className='paragraph leftTextAlign'>
                                <p>
                                    Our course is available online through our provider partner, Ron Blank & Associates, Inc., or if you prefer to see us live and in person, “Lunch and Learn” presentations may be available.
                                </p>
                                <p>
                                    Please inquire through your Distributor Representative, our Customer Contact Centre at 1-866-461-3914, or via our <NavLink to="/contact-us" > Contact Us form.</NavLink>
                                </p>
                                <p>
                                    AIA-member architects earn one (1) Health Safety Welfare (HSW) LU for successfully completing this program. Non-members can also take the online course for CEU credit.
                                </p>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                                <NavLink to="https://www.ronblank.com/Course_Sponsors/dectec">
                                    <button className='btnLearnMore '>Learn More</button>
                                </NavLink>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>

        </div>
    )
}

export default Education
