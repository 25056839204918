import React, { useState } from 'react';
import 'react-html5video/dist/styles.css';
import { DefaultPlayer as Video } from 'react-html5video/dist';
import { Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { Helmet } from "react-helmet";

const VideoPage = () => {
  const { url, maintitle, title, poster } = useParams();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div>
      <Helmet>
        <title>Dec-Tec | {title}</title>
      </Helmet>
      <Container maxWidth="lg">
        <section style={{ textAlign: 'center', padding: '50px 0px' }}>
          <div className="Wrapper">
            <Grid container>
              <Grid item md={1} lg={1} sm={0} xs={0}></Grid>
              <Grid item md={10} lg={10} sm={12} xs={12}>
                <div className="imagwrapvideo">
                  <div className="poster-container">
                    {isVideoLoaded ? (
                      <Video
                        loop
                        autoPlay
                        poster={decodeURIComponent(poster)}
                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                        onCanPlay={handleVideoLoaded}
                      >
                        <source srcSet={decodeURIComponent(url)} src={decodeURIComponent(url)} type="video/webm" />
                      </Video>
                    ) : (
                      <div className="poster">
                        <img srcSet={decodeURIComponent(poster)} src={decodeURIComponent(poster)} alt="Video Poster" />
                        <button className="play-button" onClick={handleVideoLoaded}>
                          <FontAwesomeIcon icon={faCirclePlay} size="4x" style={{ color: "#ffffff", }} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="title-container">
                  <h3 style={{ fontWeight: 'lighter' }} align="center" className="contentTitle">
                    {decodeURIComponent(maintitle)}
                  </h3>
                </div>
                <p>{decodeURIComponent(title)}</p>
              </Grid>
              <Grid item md={1} lg={1} sm={0} xs={0}></Grid>
            </Grid>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default VideoPage;
