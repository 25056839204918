import { Route, Routes, useLocation } from 'react-router-dom';
import "./App.css"
import About from './pages/About';
import DecTecProfessional from './pages/DecTecProfessional';
import Footer from './components/Footer';
import Faqs from './pages/Faqs';
import ProductDocument from './pages/ProductDocument';
import Home from './pages/Home';
import Products from './pages/Products';
import Membranes from './pages/Membranes';
import ClassicLine from './pages/ClassicLine';
import CoolStepLine from './pages/CoolStepLine';
import Accesories from './pages/Accesories';
import InstallationProducts from './pages/InstallationProducts';
import Technical from './pages/Technical';
import TechnicalLibrary from './pages/TechnicalLibrary';
import TechnicalRequest from './pages/TechnicalRequest';
import Education from './pages/Education';
import Specification from './pages/Specification';
import Reports from './pages/Reports';
import DetailDrawings from './pages/DetailDrawings';
import TechnicalGuides from './pages/TechnicalGuides';
import Checklist from './pages/Checklist';
import TechnicalBulletins from './pages/TechnicalBulletins';
import SafetyDataSheets from './pages/SafetyDataSheets';
import OurWarranty from './pages/OurWarranty';
import Contact from './pages/Contact';
import Menubar from './components/NewMenubar';
import HarlandGallery from './pages/HarlandGallery';
import RowanGallery from './pages/RowanGallery';
import GraftonGallery from './pages/GraftonGallery';
import AshdownGallery from './pages/AshdownGallery';
import BronzeGallery from './pages/BronzeGallery';
import BrickGallery from './pages/BrickGallery';
import GraniteGallery from './pages/GraniteGallery';
import CoolstepGallery from './pages/CoolstepGallery';
import CoolstepCopperGallery from './pages/CoolstepCopperGallery';
import CoolstepSandalwoodGallery from './pages/CoolstepSandalwoodGallery';
import CoolstepSlateGallery from './pages/CoolstepSlateGallery';
import ApplyForTraining from './pages/ApplyForTraining';
import TestingVideos from './pages/TestingVideos';
import TechnicalSupport from './pages/TechnicalSupport';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
// import InstallationVideos from './pages/InstallationVideos';
import FileNotFound from './pages/FileNotFound'
import VideoPage from './components/VideoPage';
import { Helmet } from "react-helmet";
import VideoDetail from './pages/VideoDetail';
import InstallationVideoNew from './pages/InstallationVideoNew';

function App() {
  const location = useLocation();
  const pageTitle = location.pathname.split("/").slice(-1).toString().split("-").join(" ");
  const arr = location.pathname.split("/")

  function capitalizeEachWord(str) {
    if (str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      })
    } else if (arr.length > 2) {
      return arr[arr.length - 2].split("-").join(" ").replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      })
    }
    else {
      return "Home"
    }
  }

  return (
    <div className="App">
      <Helmet>
        <title>
          Dec-Tec | {capitalizeEachWord(pageTitle)}
        </title>
      </Helmet>

      <Menubar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/about/product-document" element={<ProductDocument />}></Route>
        <Route path="/about/faqs" element={<Faqs />}></Route>
        <Route path="/our-warranty" element={<OurWarranty />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/products/membranes" element={<Membranes />}></Route>
        <Route path="/dec-tec-professional" element={<DecTecProfessional />}></Route>
        <Route path="/products/membranes/classic-line" element={<ClassicLine />}></Route>
        <Route path="/products/membranes/coolstep-line" element={<CoolStepLine />}></Route>
        <Route path="/products/accesories" element={<Accesories />}></Route>
        <Route path="/products/adhesives" element={<InstallationProducts />}></Route>
        <Route path="/harland-gallery/" element={<HarlandGallery />}></Route>
        <Route path="/rowan-gallery/" element={<RowanGallery />}></Route>
        <Route path="/grafton-gallery-2/" element={<GraftonGallery />}></Route>
        <Route path="/ashdown-gallery/" element={<AshdownGallery />}></Route>
        <Route path="/bronze-gallery/" element={<BronzeGallery />}></Route>
        <Route path="/brick-gallery/" element={<BrickGallery />}></Route>
        <Route path="/granite-gallery/" element={<GraniteGallery />}></Route>
        <Route path="/coolstep-gallery/" element={<CoolstepGallery />}></Route>
        <Route path="/coolstep-copper-gallery/" element={<CoolstepCopperGallery />}></Route>
        <Route path="/coolstep-sandalwood-gallery/" element={<CoolstepSandalwoodGallery />}></Route>
        <Route path="/coolstep-slate-gallery/" element={<CoolstepSlateGallery />}></Route>
        <Route path="/technical" element={<Technical />}></Route>
        <Route path="/apply-for-training/" element={<ApplyForTraining />}></Route>
        <Route path="/technical/dec-tec-professional" element={<DecTecProfessional />}></Route>
        <Route path="/technical/technical-library" element={<TechnicalLibrary />}></Route>
        <Route path="/technical/technical-library/specifications" element={<Specification />}></Route>
        <Route path="/technical/technical-library/reports" element={<Reports />}></Route>
        <Route path="/technical/technical-library/detailed-drawings" element={<DetailDrawings />}></Route>
        <Route path="/technical/technical-library/technical-guides" element={<TechnicalGuides />}></Route>
        {/* <Route path="/technical/technical-library/installation-videos" element={<InstallationVideos />}></Route> */}
        <Route path="/technical/technical-library/installation-videos" element={<InstallationVideoNew />}></Route>
        <Route path="/video-detail/:id" element={<VideoDetail />}></Route>
        <Route path="/video/:url/:maintitle/:title/:poster" element={<VideoPage />}></Route>
        <Route path="/technical/technical-library/checklists" element={<Checklist />}></Route>
        <Route path="/technical/technical-library/bulletins" element={<TechnicalBulletins />}></Route>
        <Route path="/technical/technical-library/safety-data-sheets" element={<SafetyDataSheets />}></Route>
        <Route path="/technical/technical-requests" element={<TechnicalRequest />}></Route>
        <Route path="/technical/education" element={<Education />}></Route>
        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/technical-support/" element={<TechnicalSupport />}></Route>
        <Route path="/videos-testing-pro/" element={<TestingVideos />}></Route>
        <Route path="/privacy-policy/" element={<PrivacyPolicy />}></Route>
        <Route path="/terms-of-service/" element={<TermsOfService />}></Route>
        <Route path="*" element={<FileNotFound />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
