import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { fetchState, SaveProTrainings } from '../api/UserAPI';
import { NavLink} from 'react-router-dom'

const ApplyForTraining = () => {

  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [stateList, setStateList] = useState([]);
  const applyForTrainingValues = {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    stateId: '',
    zipCode: '',
    email: '',
    phoneNo: '',
    cellNo: '',
    companyName: '',
    willingToTravel: '',
  }
  const [applyForTraining, setApplyForTraining] = useState(applyForTrainingValues);
 


  function handleChange(e) {
    const { name, value } = e.target;
    setApplyForTraining({ ...applyForTraining, [name]: value });
  }

     async function submitHandleSend() {
    console.log("CALLED")
    setFormErrors(validate(applyForTraining));
    setIsSubmit(true);
}

  async function fetchStateList() {
    try{
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: 1,
      IncludeRecordNr: true,
      SearchList: [{}],
      SortList: [
        {
          FieldName: "StateName",
          Direction: "ASC",
        },
      ],
    }
    const stateRes = await fetchState(obj);
    setStateList(stateRes);
  }
  catch (err) {
    console.log(err);
  }
}

  const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.firstName) {
            errors.firstName = "FirstName is required!";
        }
        if (!values.lastName) {
            errors.lastName = "LastName is required!";
        }
        if (!values.address) {
            errors.address = "Address is required!";
        }
        if (!values.city) {
            errors.city = "City is required!";
        }
        if (!values.stateId) {
            errors.stateId = "State is required!";
        }
        if (!values.zipCode) {
            errors.zipCode = "ZipCode is required!";
        }
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!values.phoneNo) {
            errors.phoneNo = "Phone Number is required!";
        }
        if (!values.willingToTravel) {
          errors.willingToTravel = "This field is required!";
      }
       
        return errors;
    };

async function getFinalApiCall() {

        console.log("CALLED ----- - - - - ");
        alert("Registered Successfully...")
        if (applyForTraining.firstName === '' && applyForTraining.lastName === '' && applyForTraining.address === '' && applyForTraining.city === '' &&
        applyForTraining.stateId === '' && applyForTraining.zipCode === '' && applyForTraining.email === '' && applyForTraining.phoneNo === ''  && applyForTraining.willingToTravel === '') {
            return;
        }
        try {
            const usersProfileObject = {
              UserId: 1,
              ReturnRecordId: true,
              ReturnRecordError: true,
              SaveList: [{
                Id: 0,
                FirstName: applyForTraining.firstName,
                LastName: applyForTraining.lastName,
                Address: applyForTraining.address,
                City: applyForTraining.city,
                StateId: applyForTraining.stateId,
                PostalCode: applyForTraining.zipCode,
                Email: applyForTraining.email,
                PhoneNumber: applyForTraining.phoneNo,
                CellNumber: applyForTraining.cellNo,
                CompanyName: applyForTraining.companyName,
                WillingToTravel: applyForTraining.willingToTravel,
                ModifiedBy: 1
              }]
            }

            const usersProfileResponces = await SaveProTrainings(usersProfileObject);
            console.log("usersProfileResponces===>", usersProfileResponces)
            console.log("usersProfileResponces-Id", usersProfileResponces[0].objData.id);

            setApplyForTraining(applyForTrainingValues)
        }
        catch (err) {
            console.log("submitHandleSend --> ", err);
        }
    
    }
        
    useEffect(() => {
      fetchStateList();

  }, []);

  useEffect(() => {
      console.log("___:", formErrors);
      if (Object.keys(formErrors).length === 0 && isSubmit) {
          getFinalApiCall();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);





  return (
    <div>
        <div className="warranty-form padding-lg">
          <div className='elementor-wrap-div'>
            <h3 className='heading-title' style={{ textAlign: "center" }}> <span className='title-bold'>REGISTER FOR<br /></span> DEC-TEC PRO TRAINING?</h3>
            <div className='divider'>
            </div>
            <div className='paragraph'>
              <p>Fill out your information below to register for our Dec-Tec Pro training program.</p>
            </div>
          </div>


          <Container maxWidth="lg" sx={{ padding: "5% 5% 0% 5%" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>


              <Grid item lg={6} sm={12} xs={12} md={6}>
                <InputLabel shrink htmlFor="bootstrap-input"> First Name <span className="mandatoryField">*</span></InputLabel>
                <TextField
                  placeholder="John"
                  type='text'
                  required
                  inputProps={{ style: { color: "black" } }}
                  name="firstName"
                  value={applyForTraining.firstName}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />

                 <p style={{ color: 'red' }}>{formErrors.firstName}</p>
              </Grid>

              <Grid item lg={6} sm={12} xs={12} md={6}>
                <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> Last Name <span className="mandatoryField">*</span></InputLabel>
                <TextField
                  placeholder="Doe"
                  type="text"
                  inputProps={{ style: { color: "black" } }}
                  name="lastName"
                  required
                  value={applyForTraining.lastName}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />
                <p style={{ color: 'red' }}>{formErrors.lastName}</p>
              </Grid>

              <Grid item lg={12} sm={12} xs={12} md={12}>
                <InputLabel shrink htmlFor="bootstrap-input"> ADDRESS <span className="mandatoryField">*</span></InputLabel>
                <TextField
                  placeholder="123 Main Street"
                  type="text"
                  inputProps={{ style: { color: "black" } }}
                  name="address"
                  value={applyForTraining.address}
                  required
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />
                 <p style={{ color: 'red' }}>{formErrors.address}</p>
              </Grid>



              <Grid item lg={4} sm={12} xs={12} md={4}>
                <InputLabel shrink htmlFor="bootstrap-input"> CITY <span className="mandatoryField">*</span></InputLabel>
                <TextField
                  placeholder="New York City"
                  type='text'
                  inputProps={{ style: { color: "black" } }}
                  name="city"
                  value={applyForTraining.city}
                  required
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />
                 <p style={{ color: 'red' }}>{formErrors.city}</p>
              </Grid>



              <Grid item lg={4} sm={12} xs={12} md={4}>
                <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> STATE/PROVINCE <span className="mandatoryField">*</span></InputLabel>
                <FormControl
                  style={{ width: '100%' }}>
                  <Select
                    // disableUnderline
                    displayEmpty
                    name="stateId"
                    defaultValue=""
                    // value={contactUsDetails.stateName}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {
                      stateList.map(({ id, stateName }) => {
                        return <MenuItem key={id} value={id}>{stateName}
                        </MenuItem>
                      })
                    }
                  </Select>
                  <p style={{ color: 'red' }}>{formErrors.stateId}</p>
                </FormControl>
              </Grid>



              <Grid item lg={4} sm={12} xs={12} md={4}>
                <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> ZIP/POSTAL CODE <span className="mandatoryField">*</span></InputLabel>
                <TextField

                  placeholder="85001 or A1B 2C3"
                  inputProps={{ style: { color: "black" } }}
                  name="zipCode"
                  type='text'
                  size="small"
                  value={applyForTraining.zipCode}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />
                 <p style={{ color: 'red' }}>{formErrors.zipCode}</p>
              </Grid>

              <Grid item lg={4} sm={12} xs={12} md={4}>
                <InputLabel shrink htmlFor="bootstrap-input"> EMAIL <span className="mandatoryField">*</span></InputLabel>
                <TextField
                  placeholder="johndoe@email.com"
                  type='email'
                  size="small"
                  inputProps={{ style: { color: "black" } }}
                  name="email"
                  value={applyForTraining.email}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />
                <p style={{ color: 'red' }}>{formErrors.email}</p>
              </Grid>

              <Grid item lg={4} sm={12} xs={12} md={4}>
                <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> PHONE NUMBER <span className="mandatoryField">*</span></InputLabel>
                <TextField
                  placeholder="1-555-123-4567"
                  type="number"
                  size="small"
                  inputProps={{ style: { color: "black" } }}
                  name="phoneNo"
                  value={applyForTraining.phoneNo}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />
                <p style={{ color: 'red' }}>{formErrors.phoneNo}</p>
              </Grid>

              <Grid item lg={4} sm={12} xs={12} md={4}>
                <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> CELL NUMBER </InputLabel>
                <TextField
                  placeholder="1-555-123-4567"
                  type="number"
                  size="small"
                  inputProps={{ style: { color: "black"} }}
                  name="cellNo"
                  value={applyForTraining.cellNo}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />
              </Grid>

              <Grid item lg={6} sm={12} xs={12} md={6}>
                <InputLabel shrink htmlFor="bootstrap-input"> COMPANY NAME (IF APPLICABLE) </InputLabel>
                <TextField
                  placeholder="Joe's Contractors Inc."
                  inputProps={{ style: { color: "black" } }}
                  name="companyName"
                  value={applyForTraining.companyName}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                  id="outlined-start-adornment"
                />
              </Grid>

              <Grid item lg={6} sm={12} xs={12} md={6}>
                <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> WILLING TO TRAVEL? <span className="mandatoryField">*</span></InputLabel>
                <FormControl
                  style={{ width: '100%' }}>
                  <Select

                    // disableUnderline
                    placeholder="John"
                    displayEmpty
                    name="willingToTravel"
                    defaultValue="Select"
                    value={applyForTraining.willingToTravel}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                     <MenuItem value={""}>Select Option</MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={2}>No</MenuItem>
                  </Select>
                  <p style={{ color: 'red' }}>{formErrors.willingToTravel}</p>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

              <Grid item lg={12} md={12} xs={12} sm={12}>
                <Button style={{ marginTop: "10px", width: '100%', backgroundColor: "#00779f" }} onClick={(e) => submitHandleSend(e)} variant="contained">SUBMIT</Button>
              </Grid>
            </Grid>
          </Container>


        </div>
  
      <Container maxWidth="lg" style={{ padding: "2%" }}>
        <Grid container rowSpacing={1} style={{ borderTop: "1px solid #dddddd" }}>
          <Grid item xs={6} md={6} sm={6} lg={6} sx={{ paddingLeft: 0 }}>
          <NavLink to="/technical-support" rel="prev"><span class="ast-left-arrow">←</span> Previous Page</NavLink>
          </Grid>
          <Grid item xs={6} md={6} sm={6} lg={6} style={{ textAlign: "right" }}>
          <NavLink to="/videos-testing-pro/#" rel="next">Next Page <span class="ast-right-arrow">→</span></NavLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ApplyForTraining