import React from "react";
import "../css/FileNotFound.css";

const FileNotFound = () => {
  return (
    <div className="file-not-found-section">
    <section class="file-not-found-container">
  <div class="container-inside">
    <h1>404</h1>
    <h4>Page not found!!</h4>
    <a href='/'>back to home</a>
  </div>
</section>
    </div>
  );
};

export default FileNotFound;
