import React from 'react'
import { Container, Grid } from '@mui/material'
import TitleCard from '../components/TitleCard';

const TechnicalSupport = () => {

  const allCardDetails = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/trent-erwin-338084-unsplash.jpg",
      firstText: 'TECHNICAL',
      boldText: 'SUPPORT',
      description: 'Dec-Tec is committed to industry-leading support. Any question large or small, months after installation or months before the first deck is laid, we want to provide you with help every step of the process.',
      isImageOnlyFlag: true,
      textShow: true,
      textDirect: true,
      textAlign: true,
      cartHeight: '85vh',
      bgAttachment: 'initial',
      bgPosition: 'center center',
    },
  ];
  return (
    <div>
      <Grid item xs={12}>
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>


      <Container maxWidth="lg" sx={{ padding: "10% 0%" }}>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
          <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'end', display: "flex" }}>
            <div style={{}}>
              <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/jeff-sheldon-3220-unsplash-1024x768.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/jeff-sheldon-3220-unsplash-1024x768.jpg" alt='sectionThird' width={"100%"} />
            </div>
          </Grid>
          <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
            <div className='product-div-wrap content-padding-left'>
              <h3 className='heading-title'  style={{textAlign:"center" }}>SERVICES & <span className='title-bold'>SUPPORT </span></h3>
              <div className='divider accessories-card-margin'></div>
              <div className='paragraph' style={{ textAlign: 'left' }}>
                <p >
                  We strive to serve our customers in the best way possible. A new premium initiative by the Technical Team – Dec-Tec Professional Services – offers support in a variety of ways. From consultations to in-person installation assistance. Contact our Technical Support Builder to learn more or obtain a quote.
                </p>

              </div>
            </div>

          </Grid>
        </Grid>

      </Container>

      <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
        <Grid item xs={12} sx={{ padding: "0% 9%" }}>
          <div className='product-div-wrap' >
            <div className='elementor'>
              <h3 className='heading-title  '> HAVE A <span className='title-bold'>QUESTION?</span></h3>
              <div className='divider classic-section-third-divider'>
              </div>
            </div>
            <div className='paragraph' style={{ fontSize: '0.8em', textAlign:"center" }}>
              <p>Dec-Tec prides itself on being available and supporting our customers from the beginning to the end of their project. If you have a technical request or question for us, please do not hesitate to reach out via the many contact methods list below and we will get back to you as soon as possible.</p>
            </div>
            <div className='paragraph' style={{ fontSize: '2.1em' }}>
              <p><a href='technical@dec-tec.com'><b>1-866-461-3914</b></a></p>
            </div>

            <div className='paragraph'><b>JEFF HODGES</b></div>
            <h6 style={{ fontSize:"14px", marginTop:"10px" }}><em>Technical Competency Builder</em></h6>
            <h6 style={{ fontSize:"14px", marginTop:"10px" }}>608-843-6908</h6>

            <div style={{ marginTop: "45px" }}>
              <a href="mailto:jhodges@dec-tec.com" alt="mailto">
              <button className='btnLearnMore '>Send Mail</button>
              </a>
						
            </div>
          </div>
        </Grid>
      </Container>

    </div>
  )
}

export default TechnicalSupport
