import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const MediaCard=(props) =>{
  return (
    <Card sx={{ maxWidth: props.itemWidth , padding:0}}>
      <CardMedia
        sx={{ height: props.itemHeight }}
        image={props.img}
      />
      <CardContent>
        <Typography gutterBottom variant="h7" component="div" textAlign={'center'}>
         {props.title}
        </Typography>
      </CardContent>
    </Card>
  );
}
export default MediaCard