import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react'
import TitleCard from '../components/TitleCard';
import { NavLink} from 'react-router-dom'



const Reports = () => {
    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/andrew-neel-308138-unsplash-min.jpg",
            firstText: 'REPORTS',
            boldText: '',
            description: 'Compliance reports demonstrate how Dec-Tec meets relevant codes and standards proving it to be an approved product choice.',
            isImageOnlyFlag: true,
            textShow: true,
            textDirect: true,
            textAlign: true,
            cartHeight: '100vh',
            bgAttachment: 'initial',
            bgPosition: 'center center',
        },
    ];
    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                    <Grid item xs={12} md={5} sm={12} lg={5} sx={{ alignItems: 'center'}}>
                        <div >
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/3651_Intertek-ETL-CC-C-US_Listed_crop.png" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/3651_Intertek-ETL-CC-C-US_Listed_crop.png" alt='sectionThird' width={"100%"} />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={7} sm={12} lg={7} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap content-padding-left'>
                            <h3 className='heading-title leftTextAlign' style={{ fontSize: '2.1em' }}><span className='title-bold'>Third Party Testing Agencies</span><br />& Product <span className='text-with-border'>Compliancy</span></h3>
                          
                            <div className='paragraph leftTextAlign' style={{paddingTop:"40px"}}>
                                <p>
                                    Dec-Tec Membranes and Accessories are made to perform, and that performance is qualified and quantified through accredited third-party test agencies. Dec-Tec enlisted, partnered, and invested in Intertek to satisfy third party compliancy.
                                </p>
                                <p><NavLink to='https://www.intertek.com/?gclid=EAIaIQobChMI5sqgnc-29AIVjhmtBh1SMwW-EAAYAiAAEgJ3qvD_BwE' target={'_blank'} rel="noreferrer" >Intertek </NavLink> is a leading Total Quality Assurance provider to industries worldwide</p>
                                <p>
                                    Their network of more than 1,000 laboratories and offices and over 43,800 people in more than 100 countries, delivers innovative and bespoke Assurance, Testing, Inspection and Certification solutions for their customers’ operations and supply chains.
                                </p>
                               
                            </div>
                           
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Grid item xs={12}>
                <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                        <Grid item xs={12} md={7} sm={12} lg={7} sx={{ alignItems: 'center', display: "flex" }}>
                            <div className='product-div-wrap content-padding-right'>
                                <h3 className='heading-title rightTextAlign ' style={{ fontSize: '2.1em' }}><span className='title-bold'>Code Compliance Research Report</span> <span className='text-with-border'>(CCRR)</span></h3>
                           
                                <div className='paragraph rightTextAlign ' style={{paddingTop:"40px"}}>
                                    <p>
                                        Dec-Tec enlisted, partnered, and invested in Intertek to satisfy third party compliancy.
                                    </p>
                                    <p>
                                        The <b>CCRR-0405</b> Report below, provided by Intertek, is a technical report that verifies specific code requirements are met by Dec-Tec and that Dec-Tec has achieved compliancy.
                                    </p>

                                    <p>
                                        Refer to Dec-Tec’s  <a href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB034 - CODE COMPLIANCE RESEARCH REPORT v.03.pdf' target={'_blank'} rel="noreferrer" >CCRR Technical Bulletin</a> to learn more.You can also find Dec-Tec’s <b>CCRR-0405 Report,</b> as well as more information, on<NavLink to="https://bpdirectory.intertek.com/pages/DLP_SearchDetail.aspx?SpecID=61317"  target={'_blank'}> Intertek’s website.</NavLink>
                                    </p>


                                </div>
                                <div style={{ marginRight: "15px" }}>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec CCRR0405 to 022825.pdf" target={'_blank'} rel="noreferrer" >
                                    <button className='btnLearnMore '>Download</button>
                                    </a>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5} sm={12} lg={5} sx={{ alignItems: 'center', display: "flex" }}>
                            <div >
                                <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/rawpixel-1137314-unsplash-min-1024x683.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/rawpixel-1137314-unsplash-min-1024x683.jpg"  alt='sectionThird' width={'100%'} />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="lg" sx={{ padding: "10% 15px" }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
                        <Grid item md={12} lg={12} xs={12} sm={12}>
                            <div className='product-div-wrap' >
                            <div className='elementor'>
                                <h3 className='heading-title'> BUILDING CODE<span className='title-bold'><span className='text-with-border'> REGULATORY ORGANIZATIONS</span> </span></h3>
                              
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <div className='paragraph leftTextAlign' style={{paddingTop:"40px"}}>
                                <p><b>Florida Building Code </b>(FBC)</p>
                                <p>The Florida Building Commission (FBC) is overseen by the Florida Department of Business and Professional Regulation and administers the Florida Product Approval system. The state of Florida has a unified code composed of two sections with product approval systems for each section.</p>
                                <p>Check out Dec-Tec’s approved  <b>FBC Code Report.</b></p>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Florida-Building-Code-Product-Approval-Dec-Tec-Summary.pdf" target={'_blank'} rel="noreferrer" >
                                <button className='btnLearnMore ' style={{ margin: '4%', marginLeft: '2%' }}>DOWNLOAD SUMMARY</button>
                                </a>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Florida-Building-Code-Product-Approval-Dec-Tec-Full-Report.pdf" target={'_blank'} rel="noreferrer" >
                                <button className='btnLearnMore ' style={{ margin: '4%', marginLeft: '6%' }}>DOWNLOAD FULL REPORT</button>
                                </a>
                                <p>You can also view this report from <NavLink to="https://floridabuilding.org/pr/pr_app_srch.aspx" target={'_blank'} rel="noreferrer" >FBC’s website.</NavLink> You only have to fill out the following fields to generate the report:</p>         
                                    <li><b>Code Version :</b> 2023</li>
                                    <li><b>FL # :</b> 38019-R3</li>    
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <div className='paragraph leftTextAlign' style={{paddingTop:"40px"}}>
                                <p><b>Texas Department Of Insurance </b>(TDI)</p>
                                <p>The Texas Department of Insurance Requirements (TDI) is administered by the state Engineering Services Program and is an evaluation service for assessing compliance to building code product and assembly requirements, particularly related to high velocity winds.</p>
                                <p>Check out the <b>Product Evaluation Report</b> TDI has respectfully provided.</p>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec-TDI-Evaluation-ID-RC-676.pdf" target={'_blank'} rel="noreferrer" >
                                <button className='btnLearnMore' style={{ margin: '4%', marginLeft: '2%' }}>DOWNLOAD</button>
                                </a> 
                                <p>You can also find Dec-Tec’s product evaluation on <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/rc676.pdf" target={'_blank'} rel="noreferrer" >TDI’s website.</a></p>
                                        
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </div>
    )
}

export default Reports;
