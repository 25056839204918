import React from 'react'
import SliderGallery from '../components/SliderGallery'



const RowanGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Dec-7-ROWAN-Image-2-v1.2-HighRes.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Dec-7-ROWAN-Balcony-Image-v1.2-HighRes.jpg"
    },
 
 
  ]

  return (
    <div>
      <SliderGallery
        title="Rowan"
        para1="Compliment your tan, brown, or beige tones on your house with Rowan. A flattering slatted wood design integrated with proven features and benefits will upgrade your outdoor living space and have your friends dropping ‘wow’ bombs."
        galleryImages={galleryImages}
        previouslink="/harland-gallery/"
        nextlink="/grafton-gallery-2/"
      />
    </div>
  )
}

export default RowanGallery
