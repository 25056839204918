import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Grid } from '@mui/material'
import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import '../css/SliderGallery.css'

const SliderGallery = (props) => {

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(props.galleryImages.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image  
    const nextSlide = () => {
        slideNumber + 1 === props.galleryImages.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    return (
        <div>
            <Container maxWidth="lg" sx={{ padding: "10% 10% 3% 10%" }}>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                    <div className='elementor-wrap-div'>
                        <h3 className='heading-title'>{props.title}</h3>
                        <div className='divider'>
                        </div>
                        <div className='paragraph galleryparagraph'>
                            <p>A staple in our Classic Line, Brick is a popular, neutral design that we can’t keep on the shelves. It is a tough, clean, and timeless waterproof decking product.</p>

                            <p className style={{ backgroundColor: "transparent", backgroundImage: "linear-gradient(180deg, #00779f 0%, #F5F5F5 100%)", padding: "20px" }}> <em><strong>Note: </strong>Images shown below showcase the Dec-Tec membrane in various applications and settings. We recognize resolution quality will vary from image to image.</em></p>
                        </div>
                    </div>
                </Grid>
            </Container>


            <Container maxWidth="lg" >
            {openModal &&
                <div className='sliderWrap'>
                    <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal} />
                    <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
                    <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
                    <div className='fullScreenImage'>
                        <img srcSet={props.galleryImages[slideNumber].img} src={props.galleryImages[slideNumber].img} alt='' />
                    </div>
                </div>
            }

          
                <div className='galleryWrap'>
                    {
                        props.galleryImages && props.galleryImages.map((slide, index) => {
                            return (
                                <div
                                    className='single'
                                    key={index}
                                    onClick={() => handleOpenModal(index)}
                                >
                                    <img srcSet={slide.img} src={slide.img} alt='' />
                                </div>
                            )
                        })
                    }
                </div>
                </Container>

             
          

            <Container maxWidth="lg" style={{ padding: "2%" }}>
                <Grid container rowSpacing={1} style={{ borderTop: "1px solid #dddddd" }}>
                    <Grid item xs={6} md={6} sm={6} lg={6} sx={{ paddingLeft: 0 }}>
                        <a href={props.previouslink} rel="prev"><span class="ast-left-arrow">←</span> Previous Page</a>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ textAlign: "right" }}>
                        <a href={props.nextlink} rel="next">Next Page <span class="ast-right-arrow">→</span></a>
                    </Grid>
                </Grid>
            </Container>

        </div>
    )
}

export default SliderGallery