import React from 'react'
import "./../css/homePage.css"
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TitleCard from '../components/TitleCard';
import { NavLink } from 'react-router-dom'

const DecTecProfessional = () => {
    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/daniel-mccullough-348488-unsplash-min-1.jpg",
            firstText: 'DEC-TEC',
            boldText: 'PROFESSIONALS',
            description: '',
            isImageOnlyFlag: true,
            textShow: false,
            textDirect: false,
            textAlign: true,
            cartHeight: '100vh',
            bgAttachment: 'initial',
            bgPosition: 'center top',
        },
    ];
    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "10% 0% 0% 0%" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className='product-div-wrap' >
                        <h3 className='heading-title' style={{ textAlign: "center" }}> WHO ARE DEC-TEC <span className='title-bold text-with-border'>PROFESSIONALS?</span></h3>

                        <div className='paragraph' style={{ padding: "40px 0", textAlign: "center", }}>
                            <p>To match the professional quality of Dec-Tec’s products, we seek to have professional quality installations completed so that the product performs as intended and delivers an exceptional experience for the end user.</p>
                            <p>Dec-Tec proudly delivers a training program, that when completed, creates Dec-Tec Professional competency for participants who will be installing our products.</p>
                            <p>Dec-Tec Professionals undergo a (1) hour Theoretical in-class session immediately followed by a (4) hour Practical hands on session working with the Dec-Tec products directly on training decks.</p>
                            <p>The program is led by a Dec-Tec Professional expert trainer from start to finish.</p>
                        </div>
                    </div>
                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ padding: "10% 0% 10% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className='product-div-wrap' >
                            <h3 className='heading-title' style={{ textAlign: "center" }}> WHAT ARE THE <span className='title-bold text-with-border'>BENEFITS?</span></h3>

                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='paragraph leftTextAlign' style={{ paddingLeft: '3%', paddingRight: '5%', paddingTop: "40px" }}>
                            <p><b>The benefits of becoming a Dec-Tec Professional are as follow:</b></p>
                            <ul style={{ margin: 0, textAlign: "left" }}>
                                <li>Get recommended by our Territory Builders to the Specifier, Builder, Owner</li>

                                <li>Promotion of your company and services</li>

                                <li>Core competency where it counts on the Dec-Tec product offerings</li>

                                <li>Ongoing education, support, and communication from the Dec-Tec Technical Team </li>

                                <li>Referrals, meaning more business </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <div className='paragraph leftTextAlign' style={{ paddingRight: '5%', paddingLeft: '3%', paddingTop: "40px" }}>
                            <p><b>The benefits of using a Dec-Tec Professional for your project are as follow:</b></p>
                            <ul style={{ margin: 0, textAlign: "left" }}>
                                <li>Peace of mind knowing that the installer has been thoroughly trained by Dec-Tec</li>

                                <li>Confidence in knowing that the installer will recommend the correct products</li>

                                <li>The installer will do a proper job, the first time</li>

                                <li>The installed product will perform as intended balancing watertightness with aesthetics </li>

                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        


            <Grid item xs={12}>
                <section className='professionalProgram'>
                    <Container maxWidth="lg" sx={{ padding: "10% 0%" }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <div className='product-div-wrap' >
                                    <h3 className='heading-title' style={{ textAlign: "center" }}> OUR DEC-TEC <span className='title-bold text-with-border'>PROFESSIONAL</span> TRAINING PROGRAM</h3>

                                    <div className='paragraph' style={{ padding: "40px 0 0 0", textAlign: "center",color:"black" }}>
                                        <p> Dec-Tec’s Technical Team are focused on supporting the installing contractor.  We achieve this through many initiatives ranging from one-on-one consultations virtually or in person to estimation support, but our most valuable support initiative is our Dec-Tec Professional training program.</p>

                                           
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5} sm={12} lg={5} >
                                <div style={{marginTop:"20px",paddingLeft:0,paddingRight:0}}>
                                    <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/fancycrave-219666-unsplash-min-1024x683.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/fancycrave-219666-unsplash-min-1024x683.jpg" alt='sectionThird' width={"100%"} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={7} sm={12} lg={7} sx={{ alignItems: 'center', display: "flex" }}>
                                <div className='leftTextAlign' style={{padding:"0 10px"}} >
                                 
                                    <div className='paragraph leftTextAlign' style={{ color: 'black' }}>
                                       
                                           <b> Dec-Tec Professionals are created one of two ways: </b>

                                        <p> 1.Virtually :  We offer a weekly scheduled online virtual training.<br />
                                            <span style={{paddingLeft:"20px"}}>a.All participants will be trained on a virtual segment.  No hands-on training.<br /></span>
                                            <span style={{paddingLeft:"20px"}}>b.All participants from this training receive a Dec-Tec Professional – virtual status designation.<br /></span>

                                        2.In Person :  Scheduled and delivered in person.<br />
                                        <span style={{paddingLeft:"20px"}}>a.All participants receive the theoretical and hands-on experience.</span><br />
                                        <span style={{paddingLeft:"20px"}}> b.All participants from this training receive an outright Dec-Tec Professional designation.</span></p>


                                        <p> This program is specifically targeted towards installers and provides an overview on who Dec-Tec are, Dec-Tec product knowledge, best known installation methods, tips, tricks, and a strong competency foundation.<br />



                                            Questions about the Dec-Tec Professional Training Program?  please email : <a href="mailto:technical@dec-tec.com"><b> technical@dec-tec.com </b></a><br />

                                            Looking to sign up today for the next available date or schedule one? click the button below:</p>
                                    </div>
                                    <div style={{ marginLeft: "15px", marginTop: '3%' }}>
                                        <NavLink to="/apply-for-training/" alt="form">
                                            <button className='btnLearnMore '>SIGN UP FOR TRAINING</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </section>
            </Grid>
        </div>
    )
}

export default DecTecProfessional