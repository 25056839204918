import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import ClassicCard from '../components/ClassicCard'
import MultiImageSliders from '../components/MultiImageSliders'
import "../css/cardClassic.css"
import "../css/classicLine.css"
import TitleCard from '../components/TitleCard'
import { NavLink } from 'react-router-dom'



const CoolStepLine = () => {


  function toggleRoute(type) {
    if (type === "") {
      window.open("/coolstep-gallery/")
    }
    else if (type === "copper") {
      window.open("/coolstep-copper-gallery/")
    }

    else if (type === "sandalwood") {
      window.open("/coolstep-sandalwood-gallery/")
    }

    else if (type === "slate") {
      window.open("/coolstep-slate-gallery/")
    }

  }

  const allCardDetails = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-Sandalwood-2.jpg",
      firstText: 'COOLSTEP',
      boldText: 'LINE',
      description: '',
      isImageOnlyFlag: false,
      textShow: true,
      textDirect: true,
      textAlign: false,
      cartHeight: "100vh",
      bgAttachment: 'initial',
      bgPosition: 'bottom center',
    },
  ]
  const classicCardDetails = [
    {
      id: 11,
      position: "one",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/g_shutterstock_279325493-Coolstep-1.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 1,
      position: "two",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-coolstep-01.jpg",
      isImageOnlyFlag: false,
      title: '',
      textShow: 'left',
      textbtn2: 'Gallery',
      link2: "/products/membranes/coolstep-line/#coolstepmembraneinfo",
      description: 'A light, neutral pattern, high reflectance, and high traction equals a product perfect to waterproof a rooftop or patio deck located in a warmer climate. Our original CoolStep can bring a lighter, airier feel to your living space.',
      coolstep: true,
    },
    {
      id: 2,
      position: "three",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-coolstep-copper-01.jpg",
      isImageOnlyFlag: false,
      title: 'copper',
      textShow: 'right',
      textbtn2: 'Gallery',
      link2: "/products/membranes/coolstep-line/#coolstepmembraneinfo",
      description: 'Designed to look beautiful while keeping cooler than it’s darker Dec-Tec counterparts. The copper flecks add an aesthetic detail from the original CoolStep and allow it to compliment warm color palettes.',
      coolstep: true,
    },
    {
      id: 22,
      position: "four",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-Copper-1.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 33,
      position: "five",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/COOLSTEP-SANDALWOOD-1-edt.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 3,
      position: "six",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/1.jpg",
      isImageOnlyFlag: false,
      title: 'sandalwood',
      textShow: 'left',
      textbtn2: 'Gallery',
      link2: "/products/membranes/coolstep-line/#coolstepmembraneinfo",
      description: 'As one of the newest members of our CoolStep Line, this membrane combines all the features of our 80 mil CoolStep with a high definition wood-grain pattern. Without a doubt, CoolStep Sandalwood is the coolest solution for locations that get a lot of sun!',
      coolstep: true,
    },
    {
      id: 4,
      position: "seven",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/2.jpg",
      isImageOnlyFlag: false,
      title: 'slate',
      textShow: 'right',
      textbtn2: 'Gallery',
      link2: "/products/membranes/coolstep-line/#coolstepmembraneinfo",
      description: 'CoolStep Slate gives you the best of both worlds – combining a high solar reflective index with high definition tile prints – something the industry has never seen before! The large designer tiles of CoolStep Slate make it an excellent choice for warm sunny climates. Enjoy the outdoors all year long! ',
      coolstep: true,
    },
    {
      id: 44,
      position: "eight",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/AdobeStock_69012624-Slate.jpg",
      isImageOnlyFlag: true,
      title: '',
      description: '',
      textShow: '',
    }
  ]

  const multiImages = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-coolstep-01.jpg",
      titleSlider: 'coolstep',
      link2:`/products/membranes/coolstep-line`
    },
    {
      id: 2,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-coolstep-copper-01.jpg",
      titleSlider: 'coolstep copper',
      link2:`/products/membranes/coolstep-line`
    },
    {
      id: 3,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/1.jpg",
      titleSlider: 'coolstep sandalwood',
      link2:`/products/membranes/coolstep-line`
    },
    {
      id: 4,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/2.jpg",
      titleSlider: 'coolstep slate',
      link2:`/products/membranes/coolstep-line`
    },
  ]

  return (
    <div>
      <Grid xs={12} >
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>
      <Container maxWidth="lg" sx={{ padding: "100px 0px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={6} lg={6} xs={12} sm={12} >
            <div className='product-div-wrap '>
              <div className='elementor'>
                <h3 className='heading-title'> BUILT TO LOOK <span className='title-bold'>COOL </span> </h3>
                <div className='divider'></div>
              </div>
              <div className='paragraph' style={{ textAlign: "center", width: '82%', fontSize: '0.8em' }}>
                <p >
                  With our CoolStep line, Dec-Tec offers all the same waterproofing capabilities in the form of white, light pattern membranes
                  with increased solar reflectance making it the perfect option for warmer, sunnier climates.
                </p>
                <p >
                  CoolStep is only available as an 80 mil product, automatically providing you with our 20-year limited membrane warranty
                  and comes in two neutral toned patterns – a great option for both cool and warm color palettes
                </p>
              </div>
            </div>
          </Grid>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            <div>
              <MultiImageSliders array={multiImages} noOfImages={3} />
            </div>
          </Grid>
        </Grid>
      </Container>

      <section className='coolstep-section-third parent'>
        <div className='child-overlay'></div>
        <Container maxWidth="lg" sx={{ paddingTop: "80px" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
            <Grid item md={6} lg={6} xs={12} sm={12} >
              <div className='classline-img' style={{ textAlign: "center" }}>
                <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/products/iStock-175185702_coolstep-edt3-o33ihq40f697xki4y5kk49xdlo5ehta2tdbuh63504.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/products/iStock-175185702_coolstep-edt3-o33ihq40f697xki4y5kk49xdlo5ehta2tdbuh63504.jpg" alt='sectionThird' width={"75%"} />
              </div>
            </Grid>
            <Grid item md={6} lg={6} xs={12} sm={12} sx={{ alignItems: 'center', display: "flex" }}>
              <div className='product-div-wrap classline-items'>
                <h3 className='heading-title textWhite '> RELAX, WE'VE <br /> GOT YOU <span className='title-bold text-with-border'>COVERED</span> </h3>

                <div className='paragraph leftTextAlign'>
                  <p className='textWhite' style={{ fontSize: '0.9em', paddingTop: "30px" }}>
                    Our warranties are close to our hearts because we know that a product’s warranty is a nod to its integrity. We designed our warranties to keep our customer’s mind at ease, but also to show that we stand by our product.
                  </p>
                </div>
                <div style={{ marginLeft: "15px" }}>
                  <NavLink to="/our-warranty">
                    <button className='btnLearnMore textWhite'>Learn More</button>
                  </NavLink>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="elementor-shape elementor-shape-bottom" data-shape="triangle" data-negative="true">
          <svg viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path className="elementor-shape-fill" fill="#ffffff" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
          </svg>
        </div>
      </section>
      <section className='classic-section-fourth'>
        <div className='elementor' style={{ padding: "50px 0px" }}>
          <h3 className='heading-title'> <span className='title-bold'>COOLSTEP </span> LINE</h3>
          <div className='divider' style={{ width: '5%' }}></div>
        </div>
      </section>
      <section className='classic-section-fifth' id="coolstepline">
        <ClassicCard toggleRoute={toggleRoute} cardData={{ classicCardDetails }} />
      </section>
      <section className='classic-section-sixth'>
      </section>
      <section id="coolstepmembraneinfo">
        <Container maxWidth="lg" sx={{ padding: "50px 0px" }} >
          <div className='elementor' style={{ padding: "" }}>
            <h3 className='heading-title'>MEMBRANE <span className='title-bold'>INFO </span></h3>
            <div className='divider' style={{ width: '5%' }}></div>
          </div>


          <Grid container spacing={2} sx={{ paddingBottom: "25px" }}>
            <Grid item md={3} lg={3} xs={12} sm={12} className="membrane-info-title">
              <span className='classic-section-sixth-text'>
                <span className='title-bold'>FEATURES </span>
              </span>
            </Grid>
            <Grid itemmd={4} lg={4} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>High Solar Reflective Index</li>
                </ul>
                <ul>
                  <li>Spread coat construction eliminates delamination</li>
                </ul>
                <ul>
                  <li>Non-directional Fiberglass Core</li>
                </ul>
                <ul>
                  <li>High Traction – Slip Resistance</li>
                </ul>
              </p>
            </Grid>
            <Grid item md={5} lg={5} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>Excellent vinyl to vinyl weldability</li>
                </ul>
                <ul>
                  <li>Widest membrane on the market at 76”</li>
                </ul>
                <ul>
                  <li>Complemented by a full line of color coordinated system accessories</li>
                </ul>
                <ul>
                  <li>Low maintenance</li>
                </ul>
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ paddingBottom: "25px" }}>
            <Grid item md={3} lg={3} xs={12} sm={12} className="membrane-info-title">
              <span className='classic-section-sixth-text'>
                <span className='title-bold'>THICKNESS </span>
              </span>
            </Grid>
            <Grid itemmd={4} lg={4} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>80 mil thickness</li>
                </ul>
              </p>
            </Grid>
            <Grid item md={5} lg={5} xs={12} sm={12}></Grid>
          </Grid>

          <Grid container spacing={2} sx={{ paddingBottom: "25px" }}>
            <Grid item md={3} lg={3} xs={12} sm={12} className="membrane-info-title">
              <span className='classic-section-sixth-text'>
                <span className='title-bold'>PACKAGING </span>
              </span>
            </Grid>
            <Grid itemmd={4} lg={4} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>Cut to Size</li>
                </ul>
                <ul>
                  <li>Master Rolls at 76″ x 600′</li>
                </ul>

              </p>
            </Grid>
            <Grid item md={5} lg={5} xs={12} sm={12}></Grid>
          </Grid>

          <Grid container spacing={2} sx={{ paddingBottom: "25px" }}>
            <Grid item md={3} lg={3} xs={12} sm={12} className="membrane-info-title">
              <span className='classic-section-sixth-text'>
                <span className='title-bold'>WARRANTY </span>
              </span>
            </Grid>
            <Grid itemmd={4} lg={4} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>20 year limited warranty on 80 mil membrane</li>
                </ul>
                <ul>
                  <span className='cardLink'> <NavLink to='/our-warranty' className='cardLink-anchor' ><b>(more info)</b> </NavLink></span>
                </ul>
              </p>
            </Grid>
            <Grid item md={5} lg={5} xs={12} sm={12}></Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item md={4} lg={4} xs={12} sm={4} sx={{ textAlign: "center", paddingBottom: "10px" }}>
              <a href="https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Coolstep_Product_Data_Sheet.pdf" rel="noreferrer" target={'_blank'}> <button className='btnLearnMore'>PRODUCT DATA SHEET</button></a>
            </Grid>
            <Grid item md={4} lg={4} xs={12} sm={4} sx={{ textAlign: "center", paddingBottom: "10px" }}>
              <a href="https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Tec-Membrane-SDS.pdf" rel="noreferrer" target={'_blank'}><button className='btnLearnMore'>SAFETY DATA SHEET</button></a>
            </Grid>
            <Grid item md={4} lg={4} xs={12} sm={4} sx={{ textAlign: "center" }}>
              <a href="https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/AM001-Dec-Tec-Care-Maintenance-Guide-v.02.pdf" rel="noreferrer" target={'_blank'}><button className='btnLearnMore'>MEMBRANE MAINTENANCE GUIDE</button></a>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div >
  )
}

export default CoolStepLine

