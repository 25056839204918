import React from 'react'
import { Container, Grid } from '@mui/material'
import DetailDrawingCard from '../components/DetailDrawingCard';
import TitleCard from '../components/TitleCard';



const DetailDrawings = () => {
    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/daniel-mccullough-348488-unsplash-min-1.jpg",
            firstText: 'DETAIL',
            boldText: 'DRAWINGS',
            description: 'Our quality details can help broaden the foundation from which you assemble your design drawings and are available in a multitude of formats to ensure you have all our drawings at your disposal.',
            isImageOnlyFlag: true,
            textShow: false,
            textDirect: false,
            textAlign: true,
            cartHeight: '100vh',
            bgAttachment: 'initial',
            bgPosition: 'center top',
        },
    ];
    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ padding: "4% 4%" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap'>
                            <div className='elementor'>
                                <h3 className='heading-title  ' style={{ fontSize: '2em' }}><span className='title-bold'>DETAIL DRAWING BOOKLET</span></h3>
                                <div className='divider' style={{ width: '25%' }} >
                                </div>
                            </div>
                            <div className='paragraph' style={{ textAlign: "center" }}>
                                <p>
                                    Download our detail drawing booklet that includes all 25 detailed drawings, or select an individual detailed drawing below.
                                </p>
                            </div>
                            <div>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings Booklet.pdf" target={'_blank'} rel="noreferrer">
                                    <button className='btnLearnMore ' style={{ marginBottom: "50px" }} >DOWNLOAD</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'end', display: "flex" }}>
                        <div>
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/fancycrave-219666-unsplash-min-1024x683.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/fancycrave-219666-unsplash-min-1024x683.jpg" alt='sectionThird' width={"100%"} />
                        </div>
                    </Grid>
                </Grid>

            </Container>

            <Container maxWidth="lg" sx={{ padding: "4% 4%" }}>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                    <div className='product-div-wrap' >
                        <div className='divider' style={{ width: '40%' }} ></div>
                        <div className='paragraph' style={{ textAlign: "center" }}>
                            <p style={{ marginBottom: '0%' }}>
                                Let us know if you would like to view any of these detail drawings in an alternate format by contacting us at
                            </p>
                            <a href="mailto:technical@dec-tec.com"><b> technical@dec-tec.com </b></a>
                            <p>
                                We are able to provide you with the following formats:<br />
                                DWF, DWG, DXF, GIF, and/or Word
                            </p>
                        </div>
                        <div className='divider' style={{ width: '40%' }} ></div>
                    </div>
                </Grid>
            </Container>


            <Grid item xs={12} sm={12} md={12} lg={12}>
                <section className='triangle'>
                    <h3 className='membranes-second-heading-title2'>Individual <span className='title-bold'>Detail Drawings </span></h3>
                    <div className='membranes-section-second-divider'>
                    </div>
                </section>
            </Grid>
            <Container maxWidth="lg" >
                <section className='wrapper'>
                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD001.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-001.gif"
                        title="DECK DETAIL: Typical"
                        description="TDD001"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD002.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-002.gif"
                        title="DECK DETAIL: Vinyl Siding"
                        description="TDD002"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD003.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-003.gif"
                        title="DECK DETAIL: Stucco Siding"
                        description="TDD003"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD004.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-004.gif"
                        title="DECK DETAIL: Brick Siding, Sawcut Reglet & Counter Flashing"
                        description="TDD004"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD005.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-005.gif"
                        title="DECK DETAIL: Brick Siding, Dec-Clad Kickout"
                        description="TDD005"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD006.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-006.gif"
                        title="DECK DETAIL: Concrete Wall Siding, Dec-Clad Kickout"
                        description="TDD006"
                    />


                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD007.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-003.gif"
                        title="DECK DETAIL: Fiber Cement Siding"
                        description="TDD007"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD008.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-008.gif"
                        title="DEC-CLAD DRIP EDGE DETAIL: Typical"
                        description="TDD008"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD009.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-009.gif"
                        title="DEC-CORNER DETAIL: Perpendicular"
                        description="TDD009"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD010.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-010.gif"
                        title="DEC-CORNER DETAIL: Parallel"
                        description="TDD010"
                    />


                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD011.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-011.gif"
                        title="DOOR SILL DETAIL"
                        description="TDD011"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD012.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-012.gif"
                        title="NDR 4-1 DEC-DRAIN DETAIL"
                        description="TDD012"
                    />


                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD013.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-013.gif"
                        title="DEC-SCUPPER DETAIL"
                        description="TDD013"
                    />


                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD014.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-014.gif"
                        title="SCUPPER BOX DETAIL"
                        description="TDD014"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD015.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-015.gif"
                        title="POST/COLUMN WRAP DETAIL"
                        description="TDD015"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD016.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-016.gif"
                        title="CURB DETAIL"
                        description="TDD016"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD017.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-017.gif"
                        title="DEC-TEC TIE-IN TO SHINGLE ROOF SYSTEM"
                        description="TDD017"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD018.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-018.gif"
                        title="PARAPET DETAIL"
                        description="TDD018"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD019.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-019.gif"
                        title="GUTTER DETAIL"
                        description="TDD019"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD020.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-020.gif"
                        title="NON-REMOVAL DETAIL: Door, Sliding Door & Window"
                        description="TDD020"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD021.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-Page-021.gif"
                        title="PIPE PENETRATION WRAP DETAIL"
                        description="TDD021"
                    />

                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD022.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-TDD022.png"
                        title="RAIL POST DETAIL: Surface Mount"
                        description="TDD022"
                    />
                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD023.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-TDD023.png"
                        title="DECK DETAIL : Membrane Strip In"
                        description="TDD023"
                    />
                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD024.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-TDD024.png"
                        title="DECK DETAIL : Membrane Strip In Overlap To Field Membrane"
                        description="TDD024"
                    />
                    <DetailDrawingCard
                        link="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec Technical Detail Drawings-TDD025.pdf"
                        img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Dec-Tec-Technical-Detail-Drawings-TDD025.png"
                        title="DECK DETAIL : Membrane Strip In Overlap To Field Membrane Strip In"
                        description="TDD025"
                    />
                </section>
            </Container>
        </div>
    )
}

export default DetailDrawings
