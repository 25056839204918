export const canadaStatesData = {
    type: "FeatureCollection",
    
    features: [
        {
            type: "Feature",
            id: "19",
            properties: { cityName: "British Columbia", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-9941 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-128.0536, 50.8768],
                        [-128.0543, 50.8702],
                        [-128.0454, 50.8655],
                        [-128.0336, 50.8643],
                        [-128.0173, 50.8661],
                        [-128.009, 50.8589],
                        [-127.9839, 50.8604],
                        [-127.9605, 50.872],
                        [-127.9316, 50.8752],
                        [-127.9207, 50.8716],
                        [-127.9061, 50.8748],
                        [-127.873, 50.8652],
                        [-127.8685, 50.8578],
                        [-127.8691, 50.8487],
                        [-127.8592, 50.8529],
                        [-127.8125, 50.8467],
                        [-127.8046, 50.8423],
                        [-127.7878, 50.8424],
                        [-127.775, 50.8385],
                        [-127.758, 50.8366],
                        [-127.7469, 50.832],
                        [-127.7292, 50.8298],
                        [-127.7126, 50.8255],
                        [-127.7003, 50.8199],
                        [-127.669, 50.815],
                        [-127.6512, 50.8082],
                        [-127.61, 50.796],
                        [-127.6023, 50.7953],
                        [-127.5945, 50.7902],
                        [-127.5799, 50.7896],
                        [-127.5761, 50.784],
                        [-127.567, 50.7853],
                        [-127.5385, 50.7795],
                        [-127.5106, 50.7715],
                        [-127.488, 50.767],
                        [-127.494, 50.7561],
                        [-127.4965, 50.7331],
                        [-127.4877, 50.7202],
                        [-127.49, 50.7107],
                        [-127.4779, 50.6981],
                        [-127.472, 50.7008],
                        [-127.4727, 50.7077],
                        [-127.4829, 50.7159],
                        [-127.4686, 50.7225],
                        [-127.4575, 50.7211],
                        [-127.4587, 50.7303],
                        [-127.4477, 50.7327],
                        [-127.4411, 50.7469],
                        [-127.4096, 50.7475],
                        [-127.4068, 50.7433],
                        [-127.4129, 50.7257],
                        [-127.4253, 50.7176],
                        [-127.4254, 50.7047],
                        [-127.4171, 50.696],
                        [-127.4104, 50.6951],
                        [-127.3841, 50.6999],
                        [-127.3759, 50.6894],
                        [-127.3437, 50.6764],
                        [-127.337, 50.6713],
                        [-127.3139, 50.6646],
                        [-127.3029, 50.6595],
                        [-127.2816, 50.6583],
                        [-127.2766, 50.6511],
                        [-127.2582, 50.6465],
                        [-127.246, 50.6414],
                        [-127.2415, 50.6368],
                        [-127.2324, 50.6353],
                        [-127.207, 50.6224],
                        [-127.2017, 50.6142],
                        [-127.193, 50.61],
                        [-127.1807, 50.6126],
                        [-127.1593, 50.6128],
                        [-127.1462, 50.6079],
                        [-127.1298, 50.6128],
                        [-127.1095, 50.6112],
                        [-127.0842, 50.6072],
                        [-127.0789, 50.6041],
                        [-127.113, 50.5996],
                        [-127.1332, 50.6017],
                        [-127.1372, 50.5937],
                        [-127.1236, 50.5894],
                        [-127.1145, 50.5903],
                        [-127.0912, 50.5889],
                        [-127.0858, 50.5916],
                        [-127.0688, 50.5866],
                        [-127.0549, 50.5889],
                        [-127.0409, 50.5875],
                        [-127.0237, 50.583],
                        [-127.0006, 50.5861],
                        [-126.98, 50.5805],
                        [-126.9749, 50.5711],
                        [-126.9788, 50.5684],
                        [-126.9681, 50.5601],
                        [-126.9219, 50.5601],
                        [-126.9074, 50.5611],
                        [-126.872, 50.556],
                        [-126.8545, 50.5508],
                        [-126.8608, 50.5454],
                        [-126.8784, 50.5431],
                        [-126.8706, 50.5379],
                        [-126.8702, 50.5294],
                        [-126.8584, 50.5353],
                        [-126.8482, 50.5365],
                        [-126.8441, 50.5453],
                        [-126.8313, 50.55],
                        [-126.8155, 50.5482],
                        [-126.8199, 50.5421],
                        [-126.8027, 50.5436],
                        [-126.7855, 50.5421],
                        [-126.7844, 50.5394],
                        [-126.7533, 50.534],
                        [-126.7146, 50.5239],
                        [-126.7007, 50.5182],
                        [-126.6882, 50.5169],
                        [-126.6615, 50.5099],
                        [-126.654, 50.5043],
                        [-126.6406, 50.4985],
                        [-126.6168, 50.4938],
                        [-126.6074, 50.4847],
                        [-126.5964, 50.4848],
                        [-126.5882, 50.4807],
                        [-126.5725, 50.4837],
                        [-126.5669, 50.4889],
                        [-126.5457, 50.4867],
                        [-126.5393, 50.4887],
                        [-126.5235, 50.4874],
                        [-126.5172, 50.4846],
                        [-126.505, 50.4855],
                        [-126.4907, 50.4822],
                        [-126.4694, 50.48],
                        [-126.4661, 50.4824],
                        [-126.4345, 50.4771],
                        [-126.4157, 50.4783],
                        [-126.3983, 50.4835],
                        [-126.3726, 50.4821],
                        [-126.3597, 50.4834],
                        [-126.3414, 50.4797],
                        [-126.3159, 50.4801],
                        [-126.3018, 50.4763],
                        [-126.2891, 50.4685],
                        [-126.277, 50.4656],
                        [-126.2715, 50.4691],
                        [-126.253, 50.4732],
                        [-126.2217, 50.4691],
                        [-126.1979, 50.4611],
                        [-126.1927, 50.4637],
                        [-126.1706, 50.4609],
                        [-126.1723, 50.457],
                        [-126.1542, 50.4559],
                        [-126.1395, 50.4592],
                        [-126.1237, 50.4573],
                        [-126.0847, 50.4488],
                        [-126.0772, 50.4447],
                        [-126.0542, 50.4389],
                        [-126.0398, 50.4311],
                        [-126.0221, 50.4288],
                        [-126.0176, 50.4253],
                        [-125.9948, 50.4168],
                        [-125.9755, 50.4056],
                        [-125.9623, 50.4],
                        [-125.959, 50.3951],
                        [-125.9581, 50.3833],
                        [-125.9417, 50.3768],
                        [-125.9452, 50.3907],
                        [-125.9187, 50.3882],
                        [-125.9069, 50.3887],
                        [-125.8858, 50.384],
                        [-125.8585, 50.38],
                        [-125.8425, 50.3837],
                        [-125.8269, 50.3845],
                        [-125.8128, 50.3789],
                        [-125.8015, 50.3767],
                        [-125.7902, 50.3683],
                        [-125.7729, 50.3626],
                        [-125.7571, 50.3634],
                        [-125.7519, 50.361],
                        [-125.7337, 50.3605],
                        [-125.7223, 50.3565],
                        [-125.6923, 50.3598],
                        [-125.6862, 50.3645],
                        [-125.6724, 50.3656],
                        [-125.6614, 50.3624],
                        [-125.6483, 50.3613],
                        [-125.6051, 50.367],
                        [-125.5797, 50.3682],
                        [-125.568, 50.3635],
                        [-125.5598, 50.3564],
                        [-125.5451, 50.3535],
                        [-125.5281, 50.3449],
                        [-125.516, 50.3409],
                        [-125.5005, 50.3409],
                        [-125.4865, 50.3359],
                        [-125.4871, 50.3328],
                        [-125.4737, 50.3272],
                        [-125.4586, 50.3295],
                        [-125.4465, 50.334],
                        [-125.4387, 50.3306],
                        [-125.454, 50.3249],
                        [-125.4444, 50.3217],
                        [-125.4353, 50.3048],
                        [-125.4416, 50.3009],
                        [-125.4334, 50.2882],
                        [-125.4411, 50.2806],
                        [-125.4369, 50.2767],
                        [-125.4114, 50.27],
                        [-125.405, 50.2586],
                        [-125.3983, 50.2518],
                        [-125.3976, 50.2447],
                        [-125.403, 50.2408],
                        [-125.394, 50.2272],
                        [-125.3963, 50.2219],
                        [-125.3841, 50.2082],
                        [-125.3852, 50.199],
                        [-125.3806, 50.1775],
                        [-125.3706, 50.1712],
                        [-125.3683, 50.1586],
                        [-125.3588, 50.1304],
                        [-125.3658, 50.1308],
                        [-125.3871, 50.1385],
                        [-125.3934, 50.1354],
                        [-125.3859, 50.1226],
                        [-125.3734, 50.1135],
                        [-125.3503, 50.116],
                        [-125.3389, 50.1128],
                        [-125.3246, 50.1132],
                        [-125.3246, 50.1022],
                        [-125.3174, 50.0922],
                        [-125.3082, 50.0902],
                        [-125.3031, 50.0788],
                        [-125.2935, 50.0714],
                        [-125.2822, 50.0761],
                        [-125.2714, 50.0693],
                        [-125.2642, 50.0569],
                        [-125.2615, 50.047],
                        [-125.2542, 50.0485],
                        [-125.2464, 50.0369],
                        [-125.2329, 50.0126],
                        [-125.2331, 50.0068],
                        [-125.2235, 49.9815],
                        [-125.2175, 49.9722],
                        [-125.2079, 49.9667],
                        [-125.2089, 49.9621],
                        [-125.2032, 49.9535],
                        [-125.1848, 49.9416],
                        [-125.1871, 49.9366],
                        [-125.1823, 49.9285],
                        [-125.1839, 49.9156],
                        [-125.1737, 49.9068],
                        [-125.1591, 49.898],
                        [-125.1332, 49.8931],
                        [-125.1219, 49.8881],
                        [-125.1145, 49.8802],
                        [-125.1162, 49.8705],
                        [-125.1143, 49.8625],
                        [-125.0934, 49.8498],
                        [-125.0739, 49.8452],
                        [-125.0604, 49.8371],
                        [-125.0544, 49.829],
                        [-125.0432, 49.8225],
                        [-125.0338, 49.8201],
                        [-125.0155, 49.8034],
                        [-125.0082, 49.7945],
                        [-124.9988, 49.7913],
                        [-124.9848, 49.7808],
                        [-124.9735, 49.7745],
                        [-124.9713, 49.7679],
                        [-124.9635, 49.7615],
                        [-124.9517, 49.7599],
                        [-124.9405, 49.7491],
                        [-124.9285, 49.7403],
                        [-124.913, 49.7397],
                        [-124.9016, 49.7313],
                        [-124.8929, 49.7282],
                        [-124.879, 49.7098],
                        [-124.8727, 49.7065],
                        [-124.8624, 49.7076],
                        [-124.8598, 49.7011],
                        [-124.8652, 49.6937],
                        [-124.8751, 49.6886],
                        [-124.8864, 49.6757],
                        [-124.8982, 49.6686],
                        [-124.9233, 49.6696],
                        [-124.9326, 49.6727],
                        [-124.9457, 49.6722],
                        [-124.9579, 49.678],
                        [-124.9739, 49.6812],
                        [-124.9793, 49.6735],
                        [-124.9699, 49.6626],
                        [-124.9552, 49.6531],
                        [-124.9371, 49.6447],
                        [-124.9255, 49.6454],
                        [-124.9225, 49.6346],
                        [-124.9092, 49.6257],
                        [-124.9057, 49.6166],
                        [-124.8916, 49.6007],
                        [-124.8865, 49.5862],
                        [-124.873, 49.562],
                        [-124.8724, 49.5559],
                        [-124.8576, 49.5345],
                        [-124.8441, 49.5221],
                        [-124.8335, 49.5231],
                        [-124.8288, 49.5165],
                        [-124.8336, 49.5116],
                        [-124.8209, 49.5042],
                        [-124.8151, 49.4959],
                        [-124.808, 49.4989],
                        [-124.8065, 49.5056],
                        [-124.7926, 49.4979],
                        [-124.7973, 49.4946],
                        [-124.796, 49.4853],
                        [-124.7998, 49.4801],
                        [-124.7929, 49.4759],
                        [-124.7937, 49.4645],
                        [-124.789, 49.4601],
                        [-124.7769, 49.4687],
                        [-124.7617, 49.4646],
                        [-124.7588, 49.457],
                        [-124.7512, 49.455],
                        [-124.7373, 49.4558],
                        [-124.7257, 49.465],
                        [-124.7202, 49.4596],
                        [-124.7091, 49.4567],
                        [-124.6964, 49.4505],
                        [-124.6853, 49.4486],
                        [-124.6724, 49.4376],
                        [-124.6652, 49.4352],
                        [-124.6544, 49.4257],
                        [-124.6413, 49.4221],
                        [-124.6343, 49.4079],
                        [-124.6299, 49.4045],
                        [-124.612, 49.4016],
                        [-124.6026, 49.3934],
                        [-124.5799, 49.3825],
                        [-124.57, 49.3797],
                        [-124.5498, 49.3783],
                        [-124.528, 49.374],
                        [-124.5186, 49.3692],
                        [-124.4995, 49.3655],
                        [-124.4865, 49.3689],
                        [-124.4607, 49.3547],
                        [-124.4443, 49.3554],
                        [-124.417, 49.3603],
                        [-124.4024, 49.3587],
                        [-124.3782, 49.3537],
                        [-124.3646, 49.3537],
                        [-124.3564, 49.3452],
                        [-124.3416, 49.3411],
                        [-124.3295, 49.3341],
                        [-124.3222, 49.3247],
                        [-124.3172, 49.3229],
                        [-124.3001, 49.3296],
                        [-124.2909, 49.3256],
                        [-124.2861, 49.3319],
                        [-124.2641, 49.3252],
                        [-124.263, 49.3143],
                        [-124.2532, 49.3057],
                        [-124.2404, 49.3055],
                        [-124.2377, 49.3109],
                        [-124.2262, 49.3039],
                        [-124.213, 49.3015],
                        [-124.2081, 49.2942],
                        [-124.1989, 49.2951],
                        [-124.1989, 49.3019],
                        [-124.2118, 49.3108],
                        [-124.2101, 49.3146],
                        [-124.1937, 49.304],
                        [-124.1797, 49.306],
                        [-124.1688, 49.3005],
                        [-124.1668, 49.295],
                        [-124.1571, 49.2905],
                        [-124.1455, 49.2931],
                        [-124.1372, 49.29],
                        [-124.1251, 49.2797],
                        [-124.1173, 49.2762],
                        [-124.1241, 49.2655],
                        [-124.1459, 49.2655],
                        [-124.1656, 49.2702],
                        [-124.1918, 49.2689],
                        [-124.1888, 49.2616],
                        [-124.1692, 49.2533],
                        [-124.1474, 49.2533],
                        [-124.1351, 49.2518],
                        [-124.1102, 49.256],
                        [-124.0914, 49.2541],
                        [-124.0836, 49.2572],
                        [-124.0756, 49.2529],
                        [-124.0631, 49.2518],
                        [-124.0527, 49.248],
                        [-124.034, 49.2489],
                        [-124.0264, 49.2442],
                        [-124.0091, 49.2391],
                        [-123.9807, 49.2389],
                        [-123.9699, 49.2348],
                        [-123.9573, 49.2267],
                        [-123.948, 49.2234],
                        [-123.9412, 49.2118],
                        [-123.9572, 49.2108],
                        [-123.9693, 49.2064],
                        [-123.9683, 49.1995],
                        [-123.9486, 49.187],
                        [-123.9409, 49.1736],
                        [-123.9213, 49.1582],
                        [-123.9283, 49.1545],
                        [-123.9265, 49.1439],
                        [-123.9194, 49.1359],
                        [-123.911, 49.1337],
                        [-123.8883, 49.1394],
                        [-123.8867, 49.1525],
                        [-123.8771, 49.1483],
                        [-123.8719, 49.1424],
                        [-123.8635, 49.1395],
                        [-123.8423, 49.1381],
                        [-123.8318, 49.1352],
                        [-123.8197, 49.1361],
                        [-123.8072, 49.1252],
                        [-123.8059, 49.1138],
                        [-123.8005, 49.0993],
                        [-123.805, 49.0952],
                        [-123.7964, 49.0912],
                        [-123.7841, 49.0779],
                        [-123.7699, 49.0595],
                        [-123.7607, 49.0544],
                        [-123.7604, 49.0494],
                        [-123.7477, 49.0419],
                        [-123.7626, 49.0287],
                        [-123.7822, 49.0297],
                        [-123.7906, 49.0279],
                        [-123.7866, 49.0186],
                        [-123.7757, 49.0172],
                        [-123.764, 49.0092],
                        [-123.7588, 48.9961],
                        [-123.7617, 48.9879],
                        [-123.7694, 48.986],
                        [-123.7864, 48.9919],
                        [-123.7919, 48.9975],
                        [-123.8013, 48.9997],
                        [-123.8184, 49.0118],
                        [-123.8214, 49.0111],
                        [-123.8528, 49.0274],
                        [-123.8603, 49.0223],
                        [-123.8482, 49.0182],
                        [-123.8269, 49.0046],
                        [-123.8182, 49.0005],
                        [-123.804, 48.9813],
                        [-123.794, 48.9729],
                        [-123.7559, 48.9582],
                        [-123.7495, 48.9537],
                        [-123.7373, 48.9372],
                        [-123.7166, 48.9286],
                        [-123.7103, 48.9183],
                        [-123.7024, 48.9195],
                        [-123.6916, 48.9052],
                        [-123.6842, 48.9048],
                        [-123.6717, 48.8981],
                        [-123.6735, 48.8917],
                        [-123.666, 48.8894],
                        [-123.662, 48.8803],
                        [-123.6459, 48.8825],
                        [-123.6392, 48.8644],
                        [-123.6198, 48.8611],
                        [-123.614, 48.8538],
                        [-123.5924, 48.8482],
                        [-123.5873, 48.8396],
                        [-123.5906, 48.8368],
                        [-123.5852, 48.8284],
                        [-123.5857, 48.8222],
                        [-123.6011, 48.8217],
                        [-123.6097, 48.8133],
                        [-123.6022, 48.8014],
                        [-123.5941, 48.8054],
                        [-123.5818, 48.8047],
                        [-123.5638, 48.7992],
                        [-123.5645, 48.7809],
                        [-123.5806, 48.7682],
                        [-123.5778, 48.7593],
                        [-123.5695, 48.7494],
                        [-123.5772, 48.7462],
                        [-123.5921, 48.7615],
                        [-123.6005, 48.7613],
                        [-123.6053, 48.7556],
                        [-123.6237, 48.7657],
                        [-123.6373, 48.7601],
                        [-123.6424, 48.7462],
                        [-123.6234, 48.741],
                        [-123.6112, 48.7403],
                        [-123.5904, 48.7354],
                        [-123.5687, 48.7246],
                        [-123.555, 48.7156],
                        [-123.5547, 48.7044],
                        [-123.5349, 48.6939],
                        [-123.532, 48.6822],
                        [-123.5315, 48.6697],
                        [-123.5341, 48.6597],
                        [-123.5401, 48.6543],
                        [-123.5542, 48.6593],
                        [-123.5526, 48.6488],
                        [-123.5459, 48.6447],
                        [-123.5301, 48.6307],
                        [-123.5185, 48.6149],
                        [-123.5185, 48.609],
                        [-123.5233, 48.5988],
                        [-123.5217, 48.5827],
                        [-123.5148, 48.572],
                        [-123.5211, 48.5608],
                        [-123.5312, 48.5615],
                        [-123.5496, 48.5572],
                        [-123.5578, 48.5497],
                        [-123.5551, 48.5354],
                        [-123.5464, 48.5275],
                        [-123.5425, 48.5189],
                        [-123.5494, 48.5129],
                        [-123.554, 48.5],
                        [-123.5473, 48.4961],
                        [-123.5355, 48.5148],
                        [-123.5348, 48.527],
                        [-123.5381, 48.5333],
                        [-123.537, 48.547],
                        [-123.5079, 48.5524],
                        [-123.5057, 48.5574],
                        [-123.4971, 48.5628],
                        [-123.4872, 48.5771],
                        [-123.4764, 48.5749],
                        [-123.4739, 48.5681],
                        [-123.4625, 48.573],
                        [-123.4694, 48.5792],
                        [-123.4648, 48.5903],
                        [-123.4808, 48.6032],
                        [-123.4799, 48.6106],
                        [-123.4721, 48.6177],
                        [-123.4645, 48.6291],
                        [-123.4799, 48.628],
                        [-123.4739, 48.641],
                        [-123.4607, 48.6484],
                        [-123.4459, 48.6535],
                        [-123.4513, 48.6629],
                        [-123.4566, 48.6659],
                        [-123.4691, 48.6651],
                        [-123.4823, 48.6716],
                        [-123.4865, 48.6766],
                        [-123.474, 48.6814],
                        [-123.4762, 48.6954],
                        [-123.4392, 48.699],
                        [-123.4078, 48.687],
                        [-123.3962, 48.6759],
                        [-123.4099, 48.6741],
                        [-123.414, 48.6818],
                        [-123.4232, 48.6774],
                        [-123.4209, 48.6723],
                        [-123.4038, 48.6633],
                        [-123.3925, 48.6614],
                        [-123.3971, 48.6529],
                        [-123.3936, 48.6462],
                        [-123.4084, 48.6324],
                        [-123.4103, 48.6283],
                        [-123.3976, 48.6107],
                        [-123.3921, 48.5944],
                        [-123.3834, 48.5915],
                        [-123.3744, 48.592],
                        [-123.3672, 48.5763],
                        [-123.3672, 48.56],
                        [-123.362, 48.5575],
                        [-123.3656, 48.5489],
                        [-123.3625, 48.5403],
                        [-123.3665, 48.5321],
                        [-123.3647, 48.5147],
                        [-123.334, 48.4973],
                        [-123.323, 48.494],
                        [-123.305, 48.4948],
                        [-123.3028, 48.4767],
                        [-123.2877, 48.4707],
                        [-123.2757, 48.4639],
                        [-123.2687, 48.4558],
                        [-123.2721, 48.4513],
                        [-123.2805, 48.4502],
                        [-123.2907, 48.4594],
                        [-123.2975, 48.4569],
                        [-123.2909, 48.4447],
                        [-123.2932, 48.438],
                        [-123.3034, 48.4338],
                        [-123.307, 48.4251],
                        [-123.2962, 48.4175],
                        [-123.2984, 48.4121],
                        [-123.3164, 48.4083],
                        [-123.3296, 48.4114],
                        [-123.3453, 48.4085],
                        [-123.3509, 48.4052],
                        [-123.3765, 48.4093],
                        [-123.3891, 48.4202],
                        [-123.3773, 48.4244],
                        [-123.3705, 48.421],
                        [-123.3718, 48.4341],
                        [-123.3774, 48.4342],
                        [-123.3804, 48.4412],
                        [-123.3885, 48.4408],
                        [-123.3956, 48.4457],
                        [-123.4233, 48.4543],
                        [-123.4113, 48.4599],
                        [-123.425, 48.4635],
                        [-123.4302, 48.4542],
                        [-123.3988, 48.4456],
                        [-123.3869, 48.438],
                        [-123.3799, 48.4363],
                        [-123.3727, 48.428],
                        [-123.3759, 48.4256],
                        [-123.3938, 48.4288],
                        [-123.3971, 48.4193],
                        [-123.4119, 48.421],
                        [-123.4308, 48.4278],
                        [-123.4203, 48.4338],
                        [-123.4313, 48.4372],
                        [-123.431, 48.4444],
                        [-123.4365, 48.4491],
                        [-123.4476, 48.4526],
                        [-123.4506, 48.4405],
                        [-123.449, 48.4312],
                        [-123.4587, 48.4326],
                        [-123.4729, 48.4297],
                        [-123.47, 48.419],
                        [-123.4833, 48.4038],
                        [-123.4889, 48.3934],
                        [-123.4819, 48.3845],
                        [-123.5043, 48.3876],
                        [-123.5143, 48.3854],
                        [-123.5251, 48.3728],
                        [-123.5296, 48.3704],
                        [-123.5365, 48.3593],
                        [-123.5441, 48.3553],
                        [-123.54, 48.3406],
                        [-123.555, 48.3411],
                        [-123.5544, 48.3351],
                        [-123.5401, 48.3252],
                        [-123.5607, 48.3089],
                        [-123.5642, 48.3135],
                        [-123.5731, 48.3139],
                        [-123.5847, 48.3101],
                        [-123.5908, 48.3157],
                        [-123.6017, 48.3202],
                        [-123.592, 48.3301],
                        [-123.5918, 48.3399],
                        [-123.6078, 48.334],
                        [-123.6143, 48.3367],
                        [-123.6313, 48.3349],
                        [-123.6384, 48.3165],
                        [-123.6499, 48.3137],
                        [-123.6677, 48.3203],
                        [-123.6714, 48.3265],
                        [-123.6897, 48.3357],
                        [-123.7052, 48.3397],
                        [-123.7164, 48.3476],
                        [-123.7155, 48.3523],
                        [-123.7032, 48.3592],
                        [-123.715, 48.3642],
                        [-123.7015, 48.3747],
                        [-123.6818, 48.374],
                        [-123.6816, 48.369],
                        [-123.6623, 48.3635],
                        [-123.634, 48.3663],
                        [-123.637, 48.374],
                        [-123.6337, 48.3782],
                        [-123.6342, 48.388],
                        [-123.6479, 48.3859],
                        [-123.6587, 48.3902],
                        [-123.683, 48.3878],
                        [-123.6878, 48.381],
                        [-123.6983, 48.3844],
                        [-123.7064, 48.3826],
                        [-123.7262, 48.3714],
                        [-123.732, 48.3643],
                        [-123.7263, 48.3568],
                        [-123.7351, 48.3538],
                        [-123.7536, 48.3592],
                        [-123.7624, 48.3715],
                        [-123.7786, 48.369],
                        [-123.7863, 48.3644],
                        [-123.7981, 48.364],
                        [-123.821, 48.3571],
                        [-123.8375, 48.371],
                        [-123.8483, 48.3729],
                        [-123.8602, 48.3784],
                        [-123.8919, 48.3824],
                        [-123.9228, 48.3772],
                        [-123.9409, 48.3852],
                        [-123.9488, 48.3955],
                        [-123.957, 48.3963],
                        [-123.9798, 48.3935],
                        [-123.9932, 48.4068],
                        [-124.0112, 48.4108],
                        [-124.0193, 48.4154],
                        [-124.0408, 48.4213],
                        [-124.069, 48.4227],
                        [-124.0888, 48.4305],
                        [-124.0962, 48.4354],
                        [-124.1056, 48.4325],
                        [-124.1161, 48.4394],
                        [-124.1413, 48.4436],
                        [-124.1679, 48.4515],
                        [-124.2069, 48.4574],
                        [-124.2178, 48.462],
                        [-124.2485, 48.4701],
                        [-124.2796, 48.4822],
                        [-124.2921, 48.4847],
                        [-124.2916, 48.4912],
                        [-124.3042, 48.5024],
                        [-124.3145, 48.5055],
                        [-124.365, 48.5087],
                        [-124.418, 48.5227],
                        [-124.4399, 48.5247],
                        [-124.4587, 48.5322],
                        [-124.456, 48.5371],
                        [-124.4413, 48.5468],
                        [-124.4269, 48.5531],
                        [-124.4075, 48.5563],
                        [-124.4018, 48.5606],
                        [-124.4016, 48.5663],
                        [-124.4112, 48.5756],
                        [-124.4366, 48.5696],
                        [-124.4443, 48.5652],
                        [-124.4726, 48.5584],
                        [-124.4759, 48.5538],
                        [-124.4981, 48.5427],
                        [-124.5461, 48.5545],
                        [-124.5825, 48.5612],
                        [-124.6038, 48.5641],
                        [-124.629, 48.571],
                        [-124.6499, 48.5752],
                        [-124.6563, 48.5796],
                        [-124.6749, 48.5839],
                        [-124.686, 48.589],
                        [-124.6985, 48.5899],
                        [-124.7162, 48.5941],
                        [-124.7253, 48.5986],
                        [-124.735, 48.6108],
                        [-124.7454, 48.6134],
                        [-124.7512, 48.6109],
                        [-124.7616, 48.6171],
                        [-124.7693, 48.629],
                        [-124.7826, 48.6379],
                        [-124.7943, 48.6398],
                        [-124.815, 48.6615],
                        [-124.8319, 48.66],
                        [-124.851, 48.6711],
                        [-124.8603, 48.6702],
                        [-124.8709, 48.6763],
                        [-124.8922, 48.6773],
                        [-124.9098, 48.6821],
                        [-124.9286, 48.6914],
                        [-124.9364, 48.692],
                        [-124.9531, 48.7002],
                        [-124.9735, 48.701],
                        [-125.0079, 48.7094],
                        [-125.0261, 48.7153],
                        [-125.0354, 48.7161],
                        [-125.0619, 48.7221],
                        [-125.0795, 48.7237],
                        [-125.0889, 48.7198],
                        [-125.0991, 48.7215],
                        [-125.1171, 48.7318],
                        [-125.1183, 48.7393],
                        [-125.129, 48.7474],
                        [-125.1302, 48.7531],
                        [-125.1259, 48.7599],
                        [-125.1334, 48.7725],
                        [-125.1147, 48.7913],
                        [-125.1271, 48.7989],
                        [-125.1427, 48.7873],
                        [-125.1416, 48.7823],
                        [-125.1533, 48.7764],
                        [-125.1654, 48.7772],
                        [-125.1645, 48.7827],
                        [-125.1734, 48.7872],
                        [-125.181, 48.7875],
                        [-125.1977, 48.782],
                        [-125.2126, 48.7847],
                        [-125.2076, 48.7975],
                        [-125.1915, 48.7969],
                        [-125.1818, 48.8017],
                        [-125.1835, 48.8095],
                        [-125.1554, 48.8251],
                        [-125.1517, 48.8299],
                        [-125.1393, 48.8377],
                        [-125.14, 48.8277],
                        [-125.1561, 48.8151],
                        [-125.1465, 48.8155],
                        [-125.1351, 48.8226],
                        [-125.1369, 48.8346],
                        [-125.129, 48.8475],
                        [-125.1111, 48.8519],
                        [-125.1036, 48.8591],
                        [-125.1065, 48.8641],
                        [-125.0802, 48.8801],
                        [-125.0656, 48.8849],
                        [-125.0587, 48.8829],
                        [-125.0448, 48.8844],
                        [-125.0327, 48.8835],
                        [-125.0289, 48.8892],
                        [-125.0212, 48.8925],
                        [-125.0092, 48.9023],
                        [-125.0109, 48.9072],
                        [-125.0037, 48.9126],
                        [-125.0062, 48.9198],
                        [-125.0225, 48.9249],
                        [-125.0318, 48.9324],
                        [-125.0184, 48.9439],
                        [-125.0068, 48.9469],
                        [-124.9985, 48.9397],
                        [-124.9862, 48.9418],
                        [-124.9875, 48.9561],
                        [-124.9827, 48.9632],
                        [-124.9636, 48.9733],
                        [-124.9467, 48.9771],
                        [-124.9064, 48.9732],
                        [-124.8934, 48.9814],
                        [-124.8917, 48.9887],
                        [-124.859, 49.0041],
                        [-124.8574, 49.0163],
                        [-124.8532, 49.0247],
                        [-124.847, 49.0288],
                        [-124.8427, 49.0415],
                        [-124.8387, 49.0431],
                        [-124.8388, 49.0572],
                        [-124.8354, 49.0707],
                        [-124.8252, 49.0801],
                        [-124.8141, 49.0962],
                        [-124.8198, 49.1001],
                        [-124.8111, 49.1081],
                        [-124.8149, 49.1125],
                        [-124.805, 49.1211],
                        [-124.7969, 49.1319],
                        [-124.7921, 49.1428],
                        [-124.7916, 49.1506],
                        [-124.7972, 49.1535],
                        [-124.7924, 49.1596],
                        [-124.8076, 49.169],
                        [-124.8165, 49.1841],
                        [-124.8117, 49.1915],
                        [-124.8106, 49.2063],
                        [-124.8168, 49.2162],
                        [-124.8124, 49.222],
                        [-124.8155, 49.2395],
                        [-124.8124, 49.2442],
                        [-124.8309, 49.2475],
                        [-124.8386, 49.2419],
                        [-124.8382, 49.2288],
                        [-124.8282, 49.2156],
                        [-124.8272, 49.202],
                        [-124.8233, 49.1896],
                        [-124.824, 49.184],
                        [-124.8316, 49.1811],
                        [-124.8283, 49.1678],
                        [-124.8144, 49.161],
                        [-124.8109, 49.1517],
                        [-124.8165, 49.1208],
                        [-124.8236, 49.1168],
                        [-124.8271, 49.105],
                        [-124.8375, 49.1011],
                        [-124.8312, 49.0882],
                        [-124.8371, 49.0838],
                        [-124.8552, 49.0761],
                        [-124.8605, 49.0689],
                        [-124.8846, 49.0613],
                        [-124.8633, 49.0556],
                        [-124.8631, 49.0383],
                        [-124.8658, 49.0272],
                        [-124.8741, 49.0109],
                        [-124.8931, 49.0028],
                        [-124.8978, 48.9931],
                        [-124.9115, 48.9849],
                        [-124.9361, 48.9826],
                        [-124.9471, 48.9865],
                        [-124.9605, 48.9845],
                        [-124.9864, 48.9841],
                        [-124.9794, 48.9906],
                        [-125.0185, 49.0125],
                        [-125.0332, 49.016],
                        [-125.032, 49.0252],
                        [-125.0505, 49.0161],
                        [-125.0403, 49.0085],
                        [-125.0132, 48.9999],
                        [-125.0031, 48.9905],
                        [-124.9985, 48.9801],
                        [-124.9935, 48.9774],
                        [-125.0031, 48.9656],
                        [-125.0209, 48.9622],
                        [-125.0258, 48.9668],
                        [-125.0192, 48.9718],
                        [-125.0122, 48.9842],
                        [-125.0253, 48.9848],
                        [-125.0501, 48.9982],
                        [-125.0609, 48.991],
                        [-125.068, 48.9894],
                        [-125.0844, 48.9766],
                        [-125.0953, 48.9729],
                        [-125.11, 48.9733],
                        [-125.1205, 48.9804],
                        [-125.1254, 48.9887],
                        [-125.1327, 48.9917],
                        [-125.1316, 48.9967],
                        [-125.1395, 49.0005],
                        [-125.1525, 49.0004],
                        [-125.1413, 49.0085],
                        [-125.15, 49.0105],
                        [-125.1604, 49.0031],
                        [-125.1587, 48.9966],
                        [-125.1667, 48.989],
                        [-125.1858, 48.9907],
                        [-125.1844, 48.996],
                        [-125.1649, 49.0116],
                        [-125.1475, 49.0227],
                        [-125.1513, 49.0267],
                        [-125.1391, 49.0399],
                        [-125.1376, 49.0467],
                        [-125.1466, 49.0596],
                        [-125.1529, 49.0748],
                        [-125.1627, 49.0778],
                        [-125.17, 49.0855],
                        [-125.1987, 49.0973],
                        [-125.1957, 49.0896],
                        [-125.1809, 49.0862],
                        [-125.1737, 49.0822],
                        [-125.1685, 49.0739],
                        [-125.1539, 49.0651],
                        [-125.1537, 49.0551],
                        [-125.1669, 49.049],
                        [-125.1574, 49.0383],
                        [-125.1571, 49.0271],
                        [-125.1686, 49.0147],
                        [-125.1801, 49.0176],
                        [-125.1945, 49.0049],
                        [-125.1959, 48.9921],
                        [-125.1926, 48.9765],
                        [-125.1978, 48.9675],
                        [-125.2074, 48.9597],
                        [-125.2267, 48.9492],
                        [-125.2431, 48.9444],
                        [-125.2514, 48.9543],
                        [-125.2753, 48.963],
                        [-125.2784, 48.9659],
                        [-125.3019, 48.9682],
                        [-125.3093, 48.9659],
                        [-125.3188, 48.9719],
                        [-125.2929, 48.9754],
                        [-125.296, 48.9995],
                        [-125.3133, 49.0012],
                        [-125.3202, 49.0048],
                        [-125.3229, 49.0132],
                        [-125.2966, 49.0164],
                        [-125.2899, 49.02],
                        [-125.2553, 49.0254],
                        [-125.2455, 49.0284],
                        [-125.2358, 49.0282],
                        [-125.2197, 49.033],
                        [-125.2249, 49.0376],
                        [-125.2418, 49.032],
                        [-125.2804, 49.0242],
                        [-125.2907, 49.0237],
                        [-125.3107, 49.0286],
                        [-125.3162, 49.0342],
                        [-125.332, 49.0313],
                        [-125.3358, 49.0366],
                        [-125.3485, 49.0408],
                        [-125.3638, 49.0282],
                        [-125.3579, 49.0193],
                        [-125.3634, 49.0095],
                        [-125.3713, 49.0036],
                        [-125.3689, 48.9981],
                        [-125.3815, 48.9929],
                        [-125.3939, 48.9847],
                        [-125.3912, 48.9808],
                        [-125.4182, 48.9638],
                        [-125.4252, 48.9637],
                        [-125.4351, 48.9549],
                        [-125.446, 48.9497],
                        [-125.4468, 48.9429],
                        [-125.4585, 48.9425],
                        [-125.4636, 48.9342],
                        [-125.4758, 48.9264],
                        [-125.4792, 48.9218],
                        [-125.5096, 48.9218],
                        [-125.5191, 48.9344],
                        [-125.5284, 48.9375],
                        [-125.5428, 48.9474],
                        [-125.5472, 48.9536],
                        [-125.5577, 48.958],
                        [-125.5684, 48.9712],
                        [-125.5781, 48.9788],
                        [-125.5862, 48.976],
                        [-125.5852, 48.9628],
                        [-125.57, 48.9578],
                        [-125.556, 48.9459],
                        [-125.5492, 48.9467],
                        [-125.529, 48.9326],
                        [-125.5433, 48.9273],
                        [-125.5525, 48.9371],
                        [-125.573, 48.9403],
                        [-125.5736, 48.9434],
                        [-125.5868, 48.9492],
                        [-125.589, 48.9566],
                        [-125.5997, 48.9575],
                        [-125.6072, 48.9704],
                        [-125.6168, 48.9735],
                        [-125.6104, 48.9819],
                        [-125.6129, 48.9865],
                        [-125.6333, 48.9997],
                        [-125.6425, 49.0039],
                        [-125.6539, 49.0034],
                        [-125.6593, 48.9976],
                        [-125.6689, 48.9954],
                        [-125.6746, 49.0028],
                        [-125.6759, 49.0208],
                        [-125.6942, 49.0369],
                        [-125.712, 49.0457],
                        [-125.7254, 49.0561],
                        [-125.7495, 49.0685],
                        [-125.7696, 49.0727],
                        [-125.7815, 49.0711],
                        [-125.785, 49.0654],
                        [-125.7961, 49.066],
                        [-125.8038, 49.0694],
                        [-125.8151, 49.0687],
                        [-125.8163, 49.0638],
                        [-125.8274, 49.0661],
                        [-125.8281, 49.0701],
                        [-125.8443, 49.0764],
                        [-125.8582, 49.0776],
                        [-125.8767, 49.0895],
                        [-125.8739, 49.1066],
                        [-125.8912, 49.1127],
                        [-125.9013, 49.1296],
                        [-125.9098, 49.1378],
                        [-125.9151, 49.1482],
                        [-125.907, 49.1546],
                        [-125.8916, 49.1501],
                        [-125.8836, 49.1253],
                        [-125.8748, 49.1203],
                        [-125.8644, 49.1248],
                        [-125.8539, 49.1236],
                        [-125.8522, 49.1197],
                        [-125.8576, 49.113],
                        [-125.843, 49.1132],
                        [-125.8287, 49.0998],
                        [-125.8194, 49.1054],
                        [-125.8371, 49.1181],
                        [-125.8273, 49.1203],
                        [-125.8076, 49.12],
                        [-125.7935, 49.1121],
                        [-125.785, 49.1097],
                        [-125.7808, 49.1007],
                        [-125.7687, 49.1013],
                        [-125.7582, 49.0943],
                        [-125.7468, 49.0909],
                        [-125.7379, 49.0851],
                        [-125.727, 49.0892],
                        [-125.7283, 49.097],
                        [-125.7519, 49.1045],
                        [-125.7421, 49.1095],
                        [-125.7186, 49.1144],
                        [-125.7238, 49.1219],
                        [-125.6849, 49.1408],
                        [-125.6677, 49.1365],
                        [-125.6638, 49.1438],
                        [-125.6751, 49.1479],
                        [-125.6694, 49.1516],
                        [-125.6525, 49.1536],
                        [-125.6402, 49.1641],
                        [-125.6369, 49.1811],
                        [-125.6276, 49.1883],
                        [-125.6406, 49.1901],
                        [-125.6339, 49.1986],
                        [-125.6018, 49.2036],
                        [-125.6026, 49.211],
                        [-125.5932, 49.2309],
                        [-125.6028, 49.2306],
                        [-125.6014, 49.2251],
                        [-125.609, 49.2192],
                        [-125.6173, 49.2172],
                        [-125.625, 49.2116],
                        [-125.6421, 49.2076],
                        [-125.6567, 49.2006],
                        [-125.6544, 49.1907],
                        [-125.6568, 49.1806],
                        [-125.6546, 49.1759],
                        [-125.6662, 49.1687],
                        [-125.6716, 49.1832],
                        [-125.6681, 49.188],
                        [-125.6685, 49.2015],
                        [-125.665, 49.21],
                        [-125.6744, 49.2091],
                        [-125.6786, 49.1992],
                        [-125.6886, 49.1911],
                        [-125.688, 49.1808],
                        [-125.6951, 49.1805],
                        [-125.7023, 49.1742],
                        [-125.7055, 49.1659],
                        [-125.7132, 49.1661],
                        [-125.719, 49.149],
                        [-125.7265, 49.1433],
                        [-125.7353, 49.1457],
                        [-125.7309, 49.1554],
                        [-125.7395, 49.1603],
                        [-125.743, 49.1497],
                        [-125.7513, 49.1448],
                        [-125.7632, 49.1492],
                        [-125.7701, 49.1451],
                        [-125.7611, 49.1357],
                        [-125.7664, 49.1293],
                        [-125.7788, 49.1329],
                        [-125.7827, 49.1382],
                        [-125.7825, 49.1519],
                        [-125.7727, 49.1555],
                        [-125.7629, 49.169],
                        [-125.7578, 49.1713],
                        [-125.7453, 49.185],
                        [-125.7533, 49.1977],
                        [-125.7542, 49.2204],
                        [-125.7494, 49.2338],
                        [-125.7396, 49.241],
                        [-125.7369, 49.249],
                        [-125.7264, 49.2593],
                        [-125.7337, 49.2626],
                        [-125.7528, 49.2564],
                        [-125.7583, 49.2485],
                        [-125.7676, 49.2466],
                        [-125.7856, 49.2395],
                        [-125.7939, 49.2392],
                        [-125.8088, 49.243],
                        [-125.8135, 49.2542],
                        [-125.8083, 49.2645],
                        [-125.8068, 49.281],
                        [-125.8004, 49.2996],
                        [-125.7919, 49.3099],
                        [-125.7928, 49.3284],
                        [-125.8019, 49.3326],
                        [-125.7977, 49.3395],
                        [-125.7878, 49.3442],
                        [-125.7864, 49.35],
                        [-125.7725, 49.3557],
                        [-125.7772, 49.3626],
                        [-125.7912, 49.3568],
                        [-125.7981, 49.3455],
                        [-125.8036, 49.3447],
                        [-125.8089, 49.3328],
                        [-125.8163, 49.3229],
                        [-125.8146, 49.3142],
                        [-125.8208, 49.3039],
                        [-125.829, 49.299],
                        [-125.8324, 49.29],
                        [-125.839, 49.2854],
                        [-125.8351, 49.2763],
                        [-125.8344, 49.2614],
                        [-125.8435, 49.2613],
                        [-125.8578, 49.2548],
                        [-125.8625, 49.2618],
                        [-125.8621, 49.2725],
                        [-125.8732, 49.2748],
                        [-125.8829, 49.2832],
                        [-125.9199, 49.2772],
                        [-125.9139, 49.2671],
                        [-125.9231, 49.264],
                        [-125.9277, 49.2573],
                        [-125.936, 49.2585],
                        [-125.9446, 49.2505],
                        [-125.9362, 49.2456],
                        [-125.9525, 49.2414],
                        [-125.9672, 49.2285],
                        [-125.9748, 49.2291],
                        [-126.0011, 49.2423],
                        [-126.0078, 49.2412],
                        [-126.0131, 49.2552],
                        [-126.0241, 49.2622],
                        [-126.0259, 49.2754],
                        [-126.0324, 49.2846],
                        [-126.0212, 49.2862],
                        [-126.0095, 49.2806],
                        [-126.0039, 49.2923],
                        [-126.0132, 49.2964],
                        [-126.0169, 49.3044],
                        [-126.0024, 49.3051],
                        [-125.981, 49.2943],
                        [-125.9711, 49.2942],
                        [-125.9713, 49.3009],
                        [-125.9553, 49.3023],
                        [-125.9569, 49.3141],
                        [-125.9471, 49.3207],
                        [-125.953, 49.334],
                        [-125.9447, 49.338],
                        [-125.9314, 49.3636],
                        [-125.934, 49.3732],
                        [-125.9298, 49.3775],
                        [-125.9352, 49.3829],
                        [-125.9317, 49.3913],
                        [-125.9158, 49.3926],
                        [-125.904, 49.3984],
                        [-125.9028, 49.4045],
                        [-125.896, 49.4106],
                        [-125.902, 49.4206],
                        [-125.9107, 49.42],
                        [-125.9166, 49.415],
                        [-125.9138, 49.4087],
                        [-125.9217, 49.4017],
                        [-125.9328, 49.4028],
                        [-125.9451, 49.3985],
                        [-125.9478, 49.3876],
                        [-125.9533, 49.386],
                        [-125.9596, 49.3716],
                        [-125.9554, 49.3661],
                        [-125.9725, 49.3473],
                        [-125.9828, 49.344],
                        [-125.9894, 49.3386],
                        [-125.9901, 49.3295],
                        [-126.0019, 49.3202],
                        [-126.0137, 49.3193],
                        [-126.0356, 49.3253],
                        [-126.0383, 49.3308],
                        [-126.0591, 49.3411],
                        [-126.062, 49.3503],
                        [-126.0595, 49.3595],
                        [-126.062, 49.3721],
                        [-126.068, 49.386],
                        [-126.0755, 49.3952],
                        [-126.0587, 49.4072],
                        [-126.0612, 49.4219],
                        [-126.0509, 49.422],
                        [-126.0398, 49.4258],
                        [-126.0346, 49.4349],
                        [-126.0416, 49.4394],
                        [-126.0407, 49.4494],
                        [-126.0522, 49.4429],
                        [-126.0528, 49.4343],
                        [-126.0678, 49.4302],
                        [-126.0768, 49.438],
                        [-126.089, 49.4356],
                        [-126.0922, 49.4313],
                        [-126.1233, 49.4296],
                        [-126.1228, 49.4234],
                        [-126.1417, 49.4151],
                        [-126.1444, 49.4105],
                        [-126.1635, 49.4098],
                        [-126.173, 49.4033],
                        [-126.1725, 49.398],
                        [-126.1866, 49.4],
                        [-126.192, 49.398],
                        [-126.2068, 49.4029],
                        [-126.2258, 49.4042],
                        [-126.2304, 49.4128],
                        [-126.2379, 49.4189],
                        [-126.2311, 49.4277],
                        [-126.2355, 49.44],
                        [-126.2316, 49.452],
                        [-126.2342, 49.4622],
                        [-126.2473, 49.4699],
                        [-126.2465, 49.4602],
                        [-126.2413, 49.4538],
                        [-126.2507, 49.437],
                        [-126.2585, 49.4413],
                        [-126.2682, 49.4631],
                        [-126.2713, 49.4763],
                        [-126.2789, 49.4835],
                        [-126.2829, 49.4958],
                        [-126.2914, 49.5108],
                        [-126.2972, 49.5032],
                        [-126.2928, 49.4916],
                        [-126.2875, 49.4866],
                        [-126.2818, 49.4751],
                        [-126.281, 49.4648],
                        [-126.2773, 49.4544],
                        [-126.2923, 49.4481],
                        [-126.302, 49.4379],
                        [-126.3032, 49.4219],
                        [-126.2907, 49.4312],
                        [-126.2919, 49.4356],
                        [-126.2825, 49.4416],
                        [-126.2684, 49.4451],
                        [-126.2524, 49.4271],
                        [-126.251, 49.422],
                        [-126.2548, 49.4051],
                        [-126.2603, 49.4044],
                        [-126.2629, 49.3875],
                        [-126.2564, 49.3567],
                        [-126.2717, 49.3578],
                        [-126.2806, 49.3523],
                        [-126.2885, 49.3598],
                        [-126.3015, 49.363],
                        [-126.3117, 49.3718],
                        [-126.323, 49.3745],
                        [-126.3348, 49.3829],
                        [-126.3378, 49.3898],
                        [-126.3507, 49.4016],
                        [-126.3741, 49.4076],
                        [-126.3806, 49.4065],
                        [-126.3944, 49.4114],
                        [-126.4018, 49.4109],
                        [-126.4037, 49.4281],
                        [-126.4154, 49.4348],
                        [-126.4184, 49.4421],
                        [-126.4323, 49.4466],
                        [-126.4277, 49.4536],
                        [-126.4183, 49.4554],
                        [-126.4133, 49.4602],
                        [-126.4116, 49.4718],
                        [-126.4238, 49.4767],
                        [-126.4356, 49.4752],
                        [-126.4484, 49.4692],
                        [-126.4558, 49.4596],
                        [-126.4543, 49.4563],
                        [-126.4689, 49.4438],
                        [-126.4763, 49.4439],
                        [-126.4886, 49.4311],
                        [-126.4901, 49.4229],
                        [-126.4834, 49.4169],
                        [-126.4711, 49.4165],
                        [-126.4713, 49.4108],
                        [-126.4622, 49.4066],
                        [-126.4687, 49.3973],
                        [-126.4645, 49.392],
                        [-126.4756, 49.3781],
                        [-126.4956, 49.3817],
                        [-126.516, 49.3784],
                        [-126.5326, 49.3777],
                        [-126.5395, 49.3799],
                        [-126.5414, 49.3902],
                        [-126.553, 49.4],
                        [-126.5649, 49.4045],
                        [-126.5719, 49.414],
                        [-126.5742, 49.4215],
                        [-126.5638, 49.4256],
                        [-126.5611, 49.4353],
                        [-126.5555, 49.4444],
                        [-126.5479, 49.4489],
                        [-126.5666, 49.4564],
                        [-126.5741, 49.4644],
                        [-126.5672, 49.467],
                        [-126.5712, 49.475],
                        [-126.5611, 49.4835],
                        [-126.5582, 49.494],
                        [-126.558, 49.5093],
                        [-126.5501, 49.5153],
                        [-126.5573, 49.5255],
                        [-126.5688, 49.5317],
                        [-126.5593, 49.5393],
                        [-126.5653, 49.555],
                        [-126.5608, 49.5655],
                        [-126.5659, 49.5794],
                        [-126.5561, 49.5866],
                        [-126.5479, 49.5894],
                        [-126.5263, 49.5906],
                        [-126.5233, 49.5946],
                        [-126.5104, 49.5992],
                        [-126.5007, 49.6051],
                        [-126.494, 49.6128],
                        [-126.4912, 49.6211],
                        [-126.4841, 49.6233],
                        [-126.4794, 49.6298],
                        [-126.4812, 49.6384],
                        [-126.478, 49.6437],
                        [-126.4629, 49.6445],
                        [-126.4576, 49.632],
                        [-126.4446, 49.6309],
                        [-126.4413, 49.6371],
                        [-126.4022, 49.6392],
                        [-126.3881, 49.6358],
                        [-126.3747, 49.6291],
                        [-126.3661, 49.6321],
                        [-126.3505, 49.6311],
                        [-126.3479, 49.6365],
                        [-126.3395, 49.6375],
                        [-126.2814, 49.6384],
                        [-126.2755, 49.6428],
                        [-126.2576, 49.6419],
                        [-126.2275, 49.6463],
                        [-126.2229, 49.652],
                        [-126.2064, 49.6535],
                        [-126.1899, 49.657],
                        [-126.1775, 49.656],
                        [-126.1683, 49.6499],
                        [-126.1607, 49.6502],
                        [-126.1413, 49.6584],
                        [-126.135, 49.6565],
                        [-126.1223, 49.659],
                        [-126.1048, 49.6584],
                        [-126.0967, 49.653],
                        [-126.0985, 49.6472],
                        [-126.0906, 49.6355],
                        [-126.07, 49.6281],
                        [-126.0543, 49.6118],
                        [-126.0458, 49.6127],
                        [-126.0486, 49.6214],
                        [-126.0576, 49.63],
                        [-126.0752, 49.6347],
                        [-126.0847, 49.6422],
                        [-126.0875, 49.6545],
                        [-126.0847, 49.6577],
                        [-126.094, 49.6636],
                        [-126.1043, 49.6779],
                        [-126.1287, 49.6795],
                        [-126.1326, 49.6761],
                        [-126.1483, 49.6777],
                        [-126.1627, 49.6771],
                        [-126.1835, 49.6707],
                        [-126.1934, 49.672],
                        [-126.2236, 49.6667],
                        [-126.2368, 49.6588],
                        [-126.2681, 49.6507],
                        [-126.2875, 49.6492],
                        [-126.3389, 49.6476],
                        [-126.3483, 49.6446],
                        [-126.3606, 49.651],
                        [-126.3724, 49.6521],
                        [-126.3795, 49.6583],
                        [-126.3738, 49.6651],
                        [-126.3929, 49.6617],
                        [-126.4097, 49.6632],
                        [-126.4283, 49.66],
                        [-126.4413, 49.6529],
                        [-126.4522, 49.6615],
                        [-126.4632, 49.6649],
                        [-126.4696, 49.6703],
                        [-126.4692, 49.6767],
                        [-126.4825, 49.6823],
                        [-126.4993, 49.6864],
                        [-126.4912, 49.6914],
                        [-126.4899, 49.7025],
                        [-126.4833, 49.7097],
                        [-126.4682, 49.7138],
                        [-126.4633, 49.7214],
                        [-126.4574, 49.7219],
                        [-126.4526, 49.7318],
                        [-126.4554, 49.7366],
                        [-126.4445, 49.7408],
                        [-126.4431, 49.7477],
                        [-126.4358, 49.7559],
                        [-126.4217, 49.7549],
                        [-126.4137, 49.7608],
                        [-126.4056, 49.7565],
                        [-126.3915, 49.7553],
                        [-126.4008, 49.764],
                        [-126.4038, 49.772],
                        [-126.4105, 49.772],
                        [-126.4246, 49.7667],
                        [-126.4415, 49.7652],
                        [-126.4533, 49.7619],
                        [-126.4638, 49.7682],
                        [-126.4546, 49.7801],
                        [-126.4431, 49.7864],
                        [-126.4394, 49.792],
                        [-126.465, 49.7884],
                        [-126.4769, 49.793],
                        [-126.4905, 49.8015],
                        [-126.4946, 49.7977],
                        [-126.4864, 49.7851],
                        [-126.477, 49.7745],
                        [-126.48, 49.7708],
                        [-126.473, 49.7616],
                        [-126.4611, 49.7562],
                        [-126.4563, 49.7494],
                        [-126.4681, 49.7363],
                        [-126.4688, 49.7307],
                        [-126.4755, 49.7276],
                        [-126.4882, 49.7363],
                        [-126.4944, 49.7326],
                        [-126.5095, 49.7387],
                        [-126.5154, 49.7447],
                        [-126.5217, 49.7399],
                        [-126.4947, 49.7211],
                        [-126.4958, 49.7128],
                        [-126.5086, 49.7102],
                        [-126.5126, 49.7063],
                        [-126.5314, 49.7075],
                        [-126.5343, 49.7019],
                        [-126.555, 49.6937],
                        [-126.5918, 49.697],
                        [-126.5952, 49.703],
                        [-126.6131, 49.7207],
                        [-126.6265, 49.7484],
                        [-126.6272, 49.761],
                        [-126.633, 49.7725],
                        [-126.6422, 49.7773],
                        [-126.6414, 49.7845],
                        [-126.6442, 49.8001],
                        [-126.6506, 49.8087],
                        [-126.6529, 49.8174],
                        [-126.6514, 49.827],
                        [-126.6544, 49.8549],
                        [-126.651, 49.8573],
                        [-126.6536, 49.8709],
                        [-126.6495, 49.883],
                        [-126.6488, 49.8967],
                        [-126.6434, 49.9119],
                        [-126.6526, 49.9143],
                        [-126.6519, 49.9214],
                        [-126.6631, 49.9198],
                        [-126.6632, 49.9053],
                        [-126.6692, 49.898],
                        [-126.6672, 49.8866],
                        [-126.6787, 49.8648],
                        [-126.6865, 49.8625],
                        [-126.708, 49.8647],
                        [-126.7116, 49.873],
                        [-126.7229, 49.8724],
                        [-126.733, 49.8682],
                        [-126.7498, 49.8729],
                        [-126.7807, 49.8994],
                        [-126.784, 49.9141],
                        [-126.788, 49.9178],
                        [-126.7924, 49.931],
                        [-126.7998, 49.9383],
                        [-126.802, 49.949],
                        [-126.8074, 49.9535],
                        [-126.819, 49.9541],
                        [-126.8459, 49.9594],
                        [-126.8432, 49.9795],
                        [-126.8599, 49.9826],
                        [-126.8569, 49.9701],
                        [-126.8611, 49.9567],
                        [-126.8522, 49.9512],
                        [-126.8202, 49.9453],
                        [-126.8158, 49.9397],
                        [-126.813, 49.9272],
                        [-126.8031, 49.9145],
                        [-126.8039, 49.9022],
                        [-126.819, 49.8923],
                        [-126.8157, 49.8878],
                        [-126.8262, 49.8807],
                        [-126.8542, 49.8754],
                        [-126.8582, 49.8719],
                        [-126.8955, 49.8702],
                        [-126.9087, 49.8686],
                        [-126.9154, 49.8743],
                        [-126.9153, 49.891],
                        [-126.9233, 49.9032],
                        [-126.9178, 49.9144],
                        [-126.9179, 49.9248],
                        [-126.9056, 49.9297],
                        [-126.9077, 49.9408],
                        [-126.9053, 49.9486],
                        [-126.9069, 49.9587],
                        [-126.8876, 49.9564],
                        [-126.8722, 49.9613],
                        [-126.9015, 49.9621],
                        [-126.9132, 49.9582],
                        [-126.9143, 49.9498],
                        [-126.9112, 49.932],
                        [-126.9182, 49.9335],
                        [-126.9251, 49.944],
                        [-126.931, 49.966],
                        [-126.9282, 49.9771],
                        [-126.9314, 49.9842],
                        [-126.9433, 49.9889],
                        [-126.9465, 49.9848],
                        [-126.9436, 49.9736],
                        [-126.9458, 49.9693],
                        [-126.9423, 49.9541],
                        [-126.9386, 49.9468],
                        [-126.9359, 49.9335],
                        [-126.9425, 49.9226],
                        [-126.9368, 49.8978],
                        [-126.9368, 49.8848],
                        [-126.9397, 49.8741],
                        [-126.9325, 49.8733],
                        [-126.9412, 49.8613],
                        [-126.9567, 49.856],
                        [-126.979, 49.8614],
                        [-126.9734, 49.8708],
                        [-126.9873, 49.88],
                        [-126.9903, 49.8742],
                        [-127.0056, 49.8813],
                        [-127.0138, 49.8891],
                        [-127.0183, 49.9066],
                        [-127.0256, 49.9205],
                        [-127.0354, 49.9275],
                        [-127.0437, 49.9423],
                        [-127.0515, 49.943],
                        [-127.0479, 49.9322],
                        [-127.0248, 49.8932],
                        [-127.0201, 49.8809],
                        [-127.0081, 49.8702],
                        [-127.0012, 49.8702],
                        [-126.9915, 49.8638],
                        [-126.9956, 49.8556],
                        [-127.0143, 49.8498],
                        [-127.024, 49.8547],
                        [-127.031, 49.8523],
                        [-127.0659, 49.8581],
                        [-127.0771, 49.8632],
                        [-127.0853, 49.8602],
                        [-127.0945, 49.8663],
                        [-127.1143, 49.8635],
                        [-127.1215, 49.8579],
                        [-127.1279, 49.8613],
                        [-127.1408, 49.8575],
                        [-127.1534, 49.8603],
                        [-127.1582, 49.8663],
                        [-127.1684, 49.8708],
                        [-127.182, 49.8988],
                        [-127.194, 49.9034],
                        [-127.1932, 49.909],
                        [-127.2015, 49.9138],
                        [-127.199, 49.92],
                        [-127.2048, 49.9252],
                        [-127.2166, 49.9255],
                        [-127.2168, 49.9313],
                        [-127.2233, 49.9361],
                        [-127.2256, 49.9457],
                        [-127.2209, 49.9483],
                        [-127.2323, 49.9598],
                        [-127.2405, 49.9637],
                        [-127.2523, 49.9628],
                        [-127.2315, 49.9778],
                        [-127.2235, 49.9783],
                        [-127.2107, 49.9906],
                        [-127.1871, 50.0009],
                        [-127.1734, 50.0026],
                        [-127.1642, 49.9989],
                        [-127.1257, 49.9958],
                        [-127.1284, 49.9993],
                        [-127.1513, 49.9996],
                        [-127.1586, 50.0167],
                        [-127.1459, 50.0236],
                        [-127.1121, 50.0178],
                        [-127.096, 50.0248],
                        [-127.0803, 50.0229],
                        [-127.0864, 50.0322],
                        [-127.1023, 50.0279],
                        [-127.1263, 50.026],
                        [-127.1516, 50.0273],
                        [-127.1748, 50.0206],
                        [-127.195, 50.0128],
                        [-127.2058, 50.0058],
                        [-127.2133, 50.0119],
                        [-127.2335, 50.0106],
                        [-127.2529, 50.0009],
                        [-127.2644, 49.9826],
                        [-127.2812, 49.9727],
                        [-127.2877, 49.9776],
                        [-127.2857, 49.9846],
                        [-127.2907, 49.9917],
                        [-127.3021, 49.9956],
                        [-127.3036, 49.9899],
                        [-127.3224, 50.005],
                        [-127.3129, 50.0151],
                        [-127.3144, 50.0274],
                        [-127.291, 50.0396],
                        [-127.2653, 50.0383],
                        [-127.2412, 50.0406],
                        [-127.2351, 50.0501],
                        [-127.2227, 50.0548],
                        [-127.2124, 50.0542],
                        [-127.2022, 50.0613],
                        [-127.1942, 50.0588],
                        [-127.1903, 50.051],
                        [-127.1984, 50.0509],
                        [-127.1921, 50.0416],
                        [-127.2006, 50.0408],
                        [-127.2149, 50.0331],
                        [-127.1922, 50.025],
                        [-127.1821, 50.034],
                        [-127.1718, 50.0389],
                        [-127.1711, 50.0456],
                        [-127.178, 50.0606],
                        [-127.185, 50.0649],
                        [-127.1743, 50.0735],
                        [-127.1656, 50.0758],
                        [-127.1548, 50.0725],
                        [-127.1491, 50.0632],
                        [-127.1296, 50.0635],
                        [-127.118, 50.0602],
                        [-127.113, 50.0619],
                        [-127.0985, 50.059],
                        [-127.0981, 50.0634],
                        [-127.1347, 50.0694],
                        [-127.1443, 50.0687],
                        [-127.1545, 50.0792],
                        [-127.1334, 50.0893],
                        [-127.1233, 50.0926],
                        [-127.1218, 50.0977],
                        [-127.103, 50.103],
                        [-127.0917, 50.1031],
                        [-127.0852, 50.1133],
                        [-127.0917, 50.1192],
                        [-127.086, 50.1225],
                        [-127.0931, 50.1463],
                        [-127.1024, 50.1448],
                        [-127.1053, 50.1383],
                        [-127.099, 50.1275],
                        [-127.1145, 50.1136],
                        [-127.1321, 50.1052],
                        [-127.1515, 50.1064],
                        [-127.1628, 50.103],
                        [-127.1691, 50.0964],
                        [-127.1868, 50.0955],
                        [-127.1998, 50.0919],
                        [-127.2171, 50.0942],
                        [-127.2292, 50.101],
                        [-127.2271, 50.1115],
                        [-127.2303, 50.1186],
                        [-127.25, 50.1254],
                        [-127.2507, 50.1315],
                        [-127.265, 50.1372],
                        [-127.2635, 50.142],
                        [-127.2812, 50.1456],
                        [-127.3071, 50.1604],
                        [-127.3, 50.1687],
                        [-127.3037, 50.1763],
                        [-127.2995, 50.1865],
                        [-127.3101, 50.1882],
                        [-127.3123, 50.1735],
                        [-127.3087, 50.17],
                        [-127.3108, 50.161],
                        [-127.3065, 50.1472],
                        [-127.2982, 50.1441],
                        [-127.3092, 50.1389],
                        [-127.3064, 50.1269],
                        [-127.2895, 50.1185],
                        [-127.2996, 50.1361],
                        [-127.291, 50.1354],
                        [-127.2905, 50.1289],
                        [-127.2804, 50.1285],
                        [-127.2651, 50.1184],
                        [-127.2698, 50.1094],
                        [-127.2841, 50.0967],
                        [-127.2795, 50.0892],
                        [-127.2705, 50.0857],
                        [-127.2743, 50.0781],
                        [-127.2825, 50.0759],
                        [-127.2875, 50.0691],
                        [-127.2747, 50.0642],
                        [-127.2672, 50.0578],
                        [-127.2709, 50.0544],
                        [-127.2871, 50.0538],
                        [-127.3006, 50.0445],
                        [-127.3263, 50.0367],
                        [-127.3296, 50.0298],
                        [-127.3437, 50.0261],
                        [-127.351, 50.0211],
                        [-127.3594, 50.0271],
                        [-127.3481, 50.0408],
                        [-127.3578, 50.0438],
                        [-127.3614, 50.0314],
                        [-127.3874, 50.0299],
                        [-127.3986, 50.0318],
                        [-127.4097, 50.041],
                        [-127.4282, 50.0403],
                        [-127.4361, 50.044],
                        [-127.4425, 50.0641],
                        [-127.4716, 50.0822],
                        [-127.4765, 50.0901],
                        [-127.4848, 50.0928],
                        [-127.484, 50.1005],
                        [-127.4776, 50.1149],
                        [-127.4585, 50.1239],
                        [-127.4346, 50.1245],
                        [-127.4193, 50.1328],
                        [-127.4228, 50.1385],
                        [-127.4363, 50.1334],
                        [-127.458, 50.1328],
                        [-127.4684, 50.1311],
                        [-127.4861, 50.1203],
                        [-127.4986, 50.1147],
                        [-127.5151, 50.1232],
                        [-127.5083, 50.127],
                        [-127.5106, 50.1351],
                        [-127.5049, 50.1408],
                        [-127.4893, 50.1443],
                        [-127.4861, 50.1506],
                        [-127.4706, 50.1555],
                        [-127.4575, 50.1648],
                        [-127.4532, 50.1712],
                        [-127.4527, 50.1792],
                        [-127.4356, 50.1825],
                        [-127.4335, 50.189],
                        [-127.4562, 50.1854],
                        [-127.4602, 50.1792],
                        [-127.4797, 50.1797],
                        [-127.4798, 50.1752],
                        [-127.4946, 50.1627],
                        [-127.5038, 50.1617],
                        [-127.5106, 50.1533],
                        [-127.5097, 50.1472],
                        [-127.5227, 50.1407],
                        [-127.5303, 50.1396],
                        [-127.5302, 50.1327],
                        [-127.539, 50.1263],
                        [-127.5442, 50.1279],
                        [-127.5558, 50.1236],
                        [-127.575, 50.1283],
                        [-127.5934, 50.1208],
                        [-127.5998, 50.1089],
                        [-127.6067, 50.1148],
                        [-127.6201, 50.1177],
                        [-127.6303, 50.1267],
                        [-127.6311, 50.1322],
                        [-127.6386, 50.1374],
                        [-127.6388, 50.1442],
                        [-127.6312, 50.1542],
                        [-127.6323, 50.161],
                        [-127.6214, 50.1676],
                        [-127.6112, 50.1925],
                        [-127.6222, 50.1879],
                        [-127.6274, 50.1753],
                        [-127.6353, 50.1687],
                        [-127.643, 50.1682],
                        [-127.6518, 50.172],
                        [-127.6604, 50.1859],
                        [-127.6671, 50.1824],
                        [-127.6508, 50.1707],
                        [-127.659, 50.1503],
                        [-127.6648, 50.1461],
                        [-127.6733, 50.1505],
                        [-127.6964, 50.1313],
                        [-127.7116, 50.1315],
                        [-127.7129, 50.1276],
                        [-127.7335, 50.1228],
                        [-127.7461, 50.1114],
                        [-127.7568, 50.1045],
                        [-127.7643, 50.104],
                        [-127.7876, 50.0844],
                        [-127.7961, 50.0804],
                        [-127.8085, 50.0797],
                        [-127.8336, 50.0885],
                        [-127.8431, 50.089],
                        [-127.8576, 50.0944],
                        [-127.8631, 50.0922],
                        [-127.8811, 50.0977],
                        [-127.8851, 50.1041],
                        [-127.9058, 50.112],
                        [-127.915, 50.1129],
                        [-127.9191, 50.126],
                        [-127.918, 50.1306],
                        [-127.907, 50.1323],
                        [-127.9033, 50.1388],
                        [-127.8844, 50.1485],
                        [-127.8727, 50.1509],
                        [-127.8639, 50.1577],
                        [-127.8569, 50.1584],
                        [-127.8463, 50.169],
                        [-127.838, 50.1692],
                        [-127.8113, 50.2003],
                        [-127.8001, 50.1952],
                        [-127.7931, 50.2088],
                        [-127.8013, 50.2153],
                        [-127.7894, 50.2228],
                        [-127.7782, 50.2213],
                        [-127.7643, 50.2282],
                        [-127.7558, 50.2383],
                        [-127.7281, 50.2411],
                        [-127.7348, 50.2474],
                        [-127.7473, 50.2529],
                        [-127.7743, 50.2451],
                        [-127.7953, 50.2478],
                        [-127.7994, 50.2467],
                        [-127.8098, 50.255],
                        [-127.8195, 50.2574],
                        [-127.842, 50.2713],
                        [-127.8544, 50.2812],
                        [-127.8583, 50.2919],
                        [-127.8478, 50.2888],
                        [-127.8282, 50.2962],
                        [-127.8193, 50.2944],
                        [-127.8049, 50.3035],
                        [-127.8034, 50.3173],
                        [-127.7745, 50.3126],
                        [-127.7539, 50.3024],
                        [-127.7421, 50.3005],
                        [-127.732, 50.3018],
                        [-127.75, 50.3107],
                        [-127.7627, 50.3142],
                        [-127.7659, 50.3177],
                        [-127.7865, 50.3245],
                        [-127.8073, 50.3238],
                        [-127.8168, 50.3155],
                        [-127.8388, 50.3073],
                        [-127.8432, 50.3114],
                        [-127.8578, 50.3129],
                        [-127.8639, 50.3189],
                        [-127.863, 50.3245],
                        [-127.8745, 50.3442],
                        [-127.9013, 50.3397],
                        [-127.9133, 50.3334],
                        [-127.9131, 50.3297],
                        [-127.932, 50.3244],
                        [-127.9421, 50.3253],
                        [-127.9501, 50.3192],
                        [-127.9751, 50.3289],
                        [-127.9755, 50.335],
                        [-127.9897, 50.3449],
                        [-127.989, 50.3525],
                        [-127.9766, 50.3506],
                        [-127.9691, 50.3535],
                        [-127.9706, 50.3591],
                        [-127.9613, 50.3664],
                        [-127.9634, 50.3733],
                        [-127.9598, 50.3813],
                        [-127.9571, 50.3984],
                        [-127.947, 50.4013],
                        [-127.9514, 50.4088],
                        [-127.9439, 50.4156],
                        [-127.9328, 50.418],
                        [-127.9276, 50.4298],
                        [-127.9399, 50.4371],
                        [-127.9363, 50.4647],
                        [-127.9142, 50.4656],
                        [-127.9073, 50.4602],
                        [-127.8964, 50.4581],
                        [-127.8909, 50.454],
                        [-127.873, 50.459],
                        [-127.8615, 50.4589],
                        [-127.8529, 50.4689],
                        [-127.8425, 50.4704],
                        [-127.8241, 50.462],
                        [-127.8098, 50.4571],
                        [-127.8013, 50.4588],
                        [-127.79, 50.4712],
                        [-127.7708, 50.4828],
                        [-127.7543, 50.4762],
                        [-127.7432, 50.4824],
                        [-127.732, 50.4785],
                        [-127.7124, 50.4859],
                        [-127.7051, 50.4853],
                        [-127.6855, 50.4906],
                        [-127.6698, 50.488],
                        [-127.6621, 50.4838],
                        [-127.6538, 50.4871],
                        [-127.6241, 50.4809],
                        [-127.6184, 50.4906],
                        [-127.6062, 50.4861],
                        [-127.5835, 50.49],
                        [-127.5746, 50.4846],
                        [-127.5505, 50.4614],
                        [-127.5496, 50.4576],
                        [-127.5341, 50.446],
                        [-127.5302, 50.4362],
                        [-127.5158, 50.4226],
                        [-127.4992, 50.403],
                        [-127.4974, 50.3947],
                        [-127.4833, 50.384],
                        [-127.4726, 50.3859],
                        [-127.465, 50.3807],
                        [-127.467, 50.3762],
                        [-127.4521, 50.3725],
                        [-127.4498, 50.3686],
                        [-127.4483, 50.3498],
                        [-127.4399, 50.3425],
                        [-127.4334, 50.3435],
                        [-127.4415, 50.3587],
                        [-127.4373, 50.3693],
                        [-127.443, 50.3802],
                        [-127.4526, 50.3824],
                        [-127.4571, 50.3874],
                        [-127.4785, 50.4008],
                        [-127.486, 50.4115],
                        [-127.4818, 50.4187],
                        [-127.4904, 50.4274],
                        [-127.4909, 50.4316],
                        [-127.5018, 50.4457],
                        [-127.5163, 50.456],
                        [-127.5188, 50.4605],
                        [-127.5305, 50.4677],
                        [-127.5384, 50.4785],
                        [-127.5521, 50.4829],
                        [-127.5692, 50.502],
                        [-127.5814, 50.5043],
                        [-127.5987, 50.5138],
                        [-127.6074, 50.521],
                        [-127.5996, 50.5266],
                        [-127.6085, 50.5351],
                        [-127.6131, 50.5334],
                        [-127.628, 50.5362],
                        [-127.6451, 50.5369],
                        [-127.6537, 50.5329],
                        [-127.663, 50.5331],
                        [-127.6734, 50.5275],
                        [-127.7075, 50.5165],
                        [-127.7288, 50.5119],
                        [-127.7371, 50.5126],
                        [-127.7564, 50.5043],
                        [-127.799, 50.4934],
                        [-127.7944, 50.4907],
                        [-127.8016, 50.4843],
                        [-127.8187, 50.4843],
                        [-127.8389, 50.4876],
                        [-127.8427, 50.4959],
                        [-127.8336, 50.4998],
                        [-127.8375, 50.5081],
                        [-127.859, 50.5075],
                        [-127.8617, 50.503],
                        [-127.8778, 50.5018],
                        [-127.8741, 50.4892],
                        [-127.8844, 50.4826],
                        [-127.8968, 50.4787],
                        [-127.9175, 50.4842],
                        [-127.9264, 50.4837],
                        [-127.9372, 50.4785],
                        [-127.9431, 50.4806],
                        [-127.9559, 50.4796],
                        [-127.9627, 50.4747],
                        [-127.9768, 50.4698],
                        [-127.9858, 50.4719],
                        [-127.9998, 50.4688],
                        [-128.0097, 50.4698],
                        [-128.0132, 50.4832],
                        [-128.0318, 50.5003],
                        [-128.0095, 50.5169],
                        [-128.0106, 50.5261],
                        [-127.9933, 50.5296],
                        [-127.9721, 50.5418],
                        [-127.9787, 50.5445],
                        [-127.9871, 50.541],
                        [-128.0133, 50.5379],
                        [-128.0139, 50.5328],
                        [-128.0304, 50.5183],
                        [-128.0291, 50.5108],
                        [-128.0411, 50.5042],
                        [-128.0432, 50.4936],
                        [-128.0547, 50.489],
                        [-128.0666, 50.4932],
                        [-128.0808, 50.5036],
                        [-128.0908, 50.5087],
                        [-128.0933, 50.4925],
                        [-128.0857, 50.4915],
                        [-128.0832, 50.5033],
                        [-128.048, 50.4781],
                        [-128.0452, 50.4718],
                        [-128.0295, 50.4624],
                        [-128.0374, 50.4531],
                        [-128.0382, 50.4473],
                        [-128.0587, 50.4521],
                        [-128.0638, 50.4646],
                        [-128.0766, 50.4676],
                        [-128.0816, 50.4792],
                        [-128.0998, 50.4795],
                        [-128.1013, 50.4705],
                        [-128.109, 50.472],
                        [-128.1399, 50.5045],
                        [-128.1618, 50.5084],
                        [-128.1637, 50.5126],
                        [-128.1734, 50.5158],
                        [-128.176, 50.5215],
                        [-128.207, 50.5292],
                        [-128.2179, 50.5422],
                        [-128.2113, 50.5427],
                        [-128.2184, 50.5547],
                        [-128.2337, 50.5623],
                        [-128.2443, 50.5712],
                        [-128.2388, 50.5731],
                        [-128.2355, 50.5808],
                        [-128.2259, 50.5856],
                        [-128.2396, 50.5909],
                        [-128.2529, 50.5932],
                        [-128.2632, 50.5973],
                        [-128.2726, 50.6057],
                        [-128.28, 50.6044],
                        [-128.3035, 50.6087],
                        [-128.3147, 50.6209],
                        [-128.3251, 50.6286],
                        [-128.3276, 50.6369],
                        [-128.3205, 50.6392],
                        [-128.3157, 50.6476],
                        [-128.2898, 50.6499],
                        [-128.2824, 50.658],
                        [-128.2868, 50.6612],
                        [-128.2755, 50.6677],
                        [-128.2733, 50.6739],
                        [-128.2924, 50.678],
                        [-128.3094, 50.6653],
                        [-128.3212, 50.6656],
                        [-128.3287, 50.6704],
                        [-128.3436, 50.6728],
                        [-128.3446, 50.688],
                        [-128.3544, 50.6895],
                        [-128.3555, 50.6778],
                        [-128.3604, 50.6714],
                        [-128.3726, 50.6749],
                        [-128.3754, 50.6801],
                        [-128.3726, 50.6919],
                        [-128.3651, 50.6952],
                        [-128.3633, 50.7017],
                        [-128.3735, 50.717],
                        [-128.3884, 50.7273],
                        [-128.3843, 50.7306],
                        [-128.3716, 50.7327],
                        [-128.3654, 50.7478],
                        [-128.3562, 50.7584],
                        [-128.3493, 50.7597],
                        [-128.3494, 50.77],
                        [-128.3548, 50.7718],
                        [-128.3694, 50.7531],
                        [-128.3748, 50.7401],
                        [-128.3843, 50.7355],
                        [-128.3993, 50.7407],
                        [-128.4091, 50.7419],
                        [-128.3981, 50.7519],
                        [-128.4047, 50.7576],
                        [-128.4029, 50.7721],
                        [-128.413, 50.7723],
                        [-128.4148, 50.7673],
                        [-128.4295, 50.772],
                        [-128.4317, 50.7843],
                        [-128.4203, 50.7829],
                        [-128.4142, 50.7757],
                        [-128.4028, 50.7768],
                        [-128.3896, 50.781],
                        [-128.3847, 50.7853],
                        [-128.3712, 50.7853],
                        [-128.3668, 50.7822],
                        [-128.3551, 50.7876],
                        [-128.3495, 50.8012],
                        [-128.3282, 50.8058],
                        [-128.3169, 50.8001],
                        [-128.3152, 50.7951],
                        [-128.3006, 50.7954],
                        [-128.291, 50.803],
                        [-128.2737, 50.8067],
                        [-128.2661, 50.8012],
                        [-128.2571, 50.8051],
                        [-128.249, 50.8034],
                        [-128.227, 50.8156],
                        [-128.2117, 50.8291],
                        [-128.2115, 50.834],
                        [-128.1853, 50.8342],
                        [-128.1771, 50.8433],
                        [-128.1584, 50.8423],
                        [-128.1329, 50.8379],
                        [-128.1237, 50.8412],
                        [-128.1181, 50.8475],
                        [-128.1197, 50.853],
                        [-128.1131, 50.8593],
                        [-128.1026, 50.8631],
                        [-128.097, 50.8683],
                        [-128.066, 50.8701],
                        [-128.0616, 50.8753],
                        [-128.0536, 50.8768]
                    ],
                ],
            },
        },
        {
            type: "Feature",
            id: "20",
            properties: { cityName: "British Columbia", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-9941 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-139.0522, 60],
                        [-138.75, 60],
                        [-138.5, 60],
                        [-138.25, 60],
                        [-138, 60],
                        [-137.75, 60],
                        [-137.5, 60],
                        [-137.25, 60],
                        [-137, 60],
                        [-136.75, 60],
                        [-136.5, 60],
                        [-136.25, 60],
                        [-136, 60],
                        [-135.75, 60],
                        [-135.5, 60],
                        [-135.25, 60],
                        [-135, 60],
                        [-134.75, 60],
                        [-134.5, 60],
                        [-134.25, 60],
                        [-134, 60],
                        [-133.75, 60],
                        [-133.5, 60],
                        [-133.25, 60],
                        [-133, 60],
                        [-132.75, 60],
                        [-132.5, 60],
                        [-132.25, 60],
                        [-132, 60],
                        [-131.75, 60],
                        [-131.5, 60],
                        [-131.25, 60],
                        [-131, 60],
                        [-130.75, 60],
                        [-130.5, 60],
                        [-130.25, 60],
                        [-130, 60],
                        [-129.75, 60],
                        [-129.5, 60],
                        [-129.25, 60],
                        [-129, 60],
                        [-128.75, 60],
                        [-128.5, 60],
                        [-128.25, 60],
                        [-128, 60],
                        [-127.75, 60],
                        [-127.5, 60],
                        [-127.25, 60],
                        [-127, 60],
                        [-126.75, 60],
                        [-126.5, 60],
                        [-126.25, 60],
                        [-126, 60],
                        [-125.75, 60],
                        [-125.5, 60],
                        [-125.25, 60],
                        [-125, 60],
                        [-124.75, 60],
                        [-124.5, 60],
                        [-124.25, 60],
                        [-124, 60],
                        [-123.7893, 60],
                        [-123.5, 60],
                        [-123.25, 60],
                        [-123, 60],
                        [-122.75, 60],
                        [-122.5, 60],
                        [-122.25, 60],
                        [-122, 60],
                        [-121.75, 60],
                        [-121.5, 60],
                        [-121.25, 60],
                        [-121, 60],
                        [-120.75, 60],
                        [-120.5, 60],
                        [-120.25, 60],
                        [-120, 60],
                        [-120, 59.75],
                        [-120, 59.5843],
                        [-120, 59.5],
                        [-120, 59.3326],
                        [-120, 59.25],
                        [-120, 59.0893],
                        [-120, 58.9997],
                        [-120, 58.75],
                        [-120, 58.6342],
                        [-120, 58.5],
                        [-120, 58.3795],
                        [-120, 58.25],
                        [-120, 58.0795],
                        [-120, 57.9111],
                        [-120, 57.7317],
                        [-119.9999, 57.514],
                        [-120, 57.3806],
                        [-120, 57.2084],
                        [-120, 57],
                        [-120, 56.8041],
                        [-120.0001, 56.656],
                        [-120, 56.5502],
                        [-120, 56.4262],
                        [-120, 56.2612],
                        [-120, 56.1407],
                        [-119.9999, 55.9654],
                        [-120, 55.8429],
                        [-120, 55.6802],
                        [-120, 55.5792],
                        [-120, 55.4383],
                        [-120, 55.3323],
                        [-120, 55.1966],
                        [-119.9998, 55.1148],
                        [-120, 54.9977],
                        [-120, 54.9162],
                        [-120.0003, 54.7977],
                        [-120, 54.7367],
                        [-120, 54.5874],
                        [-119.9999, 54.4909],
                        [-120, 54.2689],
                        [-120, 53.9374],
                        [-120, 53.8062],
                        [-119.9774, 53.8054],
                        [-119.9713, 53.8016],
                        [-119.983, 53.7972],
                        [-119.9592, 53.7811],
                        [-119.9423, 53.7759],
                        [-119.8882, 53.7795],
                        [-119.8858, 53.7685],
                        [-119.8926, 53.7575],
                        [-119.8874, 53.75],
                        [-119.9021, 53.7384],
                        [-119.8975, 53.7307],
                        [-119.9008, 53.723],
                        [-119.9157, 53.7173],
                        [-119.908, 53.7106],
                        [-119.8895, 53.7105],
                        [-119.8704, 53.7148],
                        [-119.8443, 53.7147],
                        [-119.842, 53.708],
                        [-119.8451, 53.7025],
                        [-119.8396, 53.6974],
                        [-119.8221, 53.7005],
                        [-119.8137, 53.7046],
                        [-119.7974, 53.7078],
                        [-119.7867, 53.7013],
                        [-119.7838, 53.6953],
                        [-119.7732, 53.6856],
                        [-119.7684, 53.675],
                        [-119.7556, 53.6649],
                        [-119.7345, 53.6632],
                        [-119.7372, 53.6368],
                        [-119.7264, 53.6283],
                        [-119.7134, 53.6146],
                        [-119.7148, 53.6117],
                        [-119.7392, 53.6022],
                        [-119.7487, 53.5934],
                        [-119.7592, 53.591],
                        [-119.7762, 53.5912],
                        [-119.784, 53.594],
                        [-119.7931, 53.6024],
                        [-119.8167, 53.6041],
                        [-119.8294, 53.6091],
                        [-119.8541, 53.6077],
                        [-119.8794, 53.6168],
                        [-119.9027, 53.6202],
                        [-119.9259, 53.6145],
                        [-119.9304, 53.6104],
                        [-119.9232, 53.6053],
                        [-119.9107, 53.6013],
                        [-119.9029, 53.59],
                        [-119.8915, 53.5777],
                        [-119.8731, 53.5714],
                        [-119.8633, 53.5616],
                        [-119.8623, 53.5485],
                        [-119.9003, 53.533],
                        [-119.8995, 53.5191],
                        [-119.8899, 53.5125],
                        [-119.8754, 53.5063],
                        [-119.8702, 53.5],
                        [-119.8539, 53.4999],
                        [-119.8465, 53.5117],
                        [-119.8329, 53.5193],
                        [-119.8302, 53.5147],
                        [-119.8125, 53.4999],
                        [-119.8001, 53.5],
                        [-119.7804, 53.4918],
                        [-119.7821, 53.4853],
                        [-119.7906, 53.4793],
                        [-119.7837, 53.4704],
                        [-119.7715, 53.4458],
                        [-119.7585, 53.4402],
                        [-119.7584, 53.4264],
                        [-119.7393, 53.4118],
                        [-119.7311, 53.4022],
                        [-119.7254, 53.3888],
                        [-119.7088, 53.3884],
                        [-119.6954, 53.3906],
                        [-119.6828, 53.3843],
                        [-119.6764, 53.3712],
                        [-119.6689, 53.3678],
                        [-119.6375, 53.3691],
                        [-119.6253, 53.3635],
                        [-119.5983, 53.3657],
                        [-119.6045, 53.3834],
                        [-119.5903, 53.3811],
                        [-119.5785, 53.3753],
                        [-119.5485, 53.3675],
                        [-119.5335, 53.3659],
                        [-119.5175, 53.3701],
                        [-119.4592, 53.3572],
                        [-119.4367, 53.3576],
                        [-119.4061, 53.3681],
                        [-119.3907, 53.3608],
                        [-119.3559, 53.3401],
                        [-119.3469, 53.3263],
                        [-119.356, 53.3123],
                        [-119.3297, 53.2966],
                        [-119.3405, 53.2871],
                        [-119.3253, 53.2696],
                        [-119.3005, 53.2562],
                        [-119.3015, 53.2498],
                        [-119.2914, 53.25],
                        [-119.2881, 53.2401],
                        [-119.2625, 53.2125],
                        [-119.2515, 53.199],
                        [-119.2532, 53.1848],
                        [-119.2571, 53.1766],
                        [-119.2327, 53.1818],
                        [-119.2332, 53.1935],
                        [-119.2191, 53.194],
                        [-119.1961, 53.1855],
                        [-119.1756, 53.1846],
                        [-119.146, 53.1912],
                        [-119.1415, 53.1817],
                        [-119.1195, 53.1618],
                        [-119.0885, 53.1655],
                        [-119.0686, 53.1575],
                        [-119.0469, 53.1449],
                        [-119.048, 53.1268],
                        [-119.0246, 53.1267],
                        [-119.0251, 53.1346],
                        [-118.9999, 53.1486],
                        [-119.0288, 53.1642],
                        [-119.0199, 53.1702],
                        [-119.0086, 53.1861],
                        [-118.9994, 53.1928],
                        [-118.9994, 53.2127],
                        [-119.0183, 53.2194],
                        [-119.0262, 53.2264],
                        [-119.0239, 53.2319],
                        [-118.9841, 53.239],
                        [-118.9752, 53.2417],
                        [-118.9653, 53.2368],
                        [-118.9475, 53.2389],
                        [-118.9234, 53.2252],
                        [-118.916, 53.2118],
                        [-118.9094, 53.2097],
                        [-118.8796, 53.2059],
                        [-118.8621, 53.1909],
                        [-118.8478, 53.1859],
                        [-118.8315, 53.183],
                        [-118.8234, 53.1729],
                        [-118.8058, 53.1652],
                        [-118.7865, 53.1592],
                        [-118.7793, 53.1509],
                        [-118.784, 53.1449],
                        [-118.7725, 53.133],
                        [-118.7324, 53.1192],
                        [-118.7295, 53.1155],
                        [-118.7368, 53.1065],
                        [-118.7488, 53.0972],
                        [-118.7487, 53.0883],
                        [-118.7544, 53.0828],
                        [-118.7698, 53.0745],
                        [-118.7591, 53.0658],
                        [-118.7762, 53.0453],
                        [-118.7589, 53.038],
                        [-118.7564, 53.0438],
                        [-118.7461, 53.0523],
                        [-118.7264, 53.0602],
                        [-118.7103, 53.0522],
                        [-118.7006, 53.0444],
                        [-118.6959, 53.0328],
                        [-118.6857, 53.035],
                        [-118.6797, 53.0413],
                        [-118.6688, 53.0425],
                        [-118.6554, 53.0348],
                        [-118.6498, 53.0251],
                        [-118.6526, 53.0184],
                        [-118.6408, 52.9993],
                        [-118.649, 53.0003],
                        [-118.6493, 52.9915],
                        [-118.6698, 52.9834],
                        [-118.6712, 52.9783],
                        [-118.6611, 52.9726],
                        [-118.6602, 52.9639],
                        [-118.6513, 52.9555],
                        [-118.6385, 52.9519],
                        [-118.6134, 52.936],
                        [-118.621, 52.9163],
                        [-118.6067, 52.904],
                        [-118.6143, 52.9001],
                        [-118.6138, 52.8837],
                        [-118.6081, 52.8796],
                        [-118.5872, 52.8822],
                        [-118.5695, 52.8889],
                        [-118.5619, 52.9012],
                        [-118.5437, 52.9084],
                        [-118.5222, 52.8981],
                        [-118.5109, 52.9051],
                        [-118.5001, 52.9063],
                        [-118.4768, 52.9008],
                        [-118.4693, 52.8956],
                        [-118.4483, 52.8853],
                        [-118.4486, 52.8803],
                        [-118.4612, 52.8693],
                        [-118.4584, 52.8596],
                        [-118.4445, 52.8522],
                        [-118.4032, 52.8508],
                        [-118.3889, 52.8489],
                        [-118.4011, 52.8405],
                        [-118.3996, 52.8245],
                        [-118.413, 52.806],
                        [-118.4224, 52.7758],
                        [-118.3911, 52.7604],
                        [-118.3428, 52.7386],
                        [-118.3433, 52.7089],
                        [-118.2901, 52.6777],
                        [-118.301, 52.6541],
                        [-118.3545, 52.6337],
                        [-118.3525, 52.6106],
                        [-118.3336, 52.6062],
                        [-118.3326, 52.5802],
                        [-118.2728, 52.566],
                        [-118.2891, 52.5385],
                        [-118.2743, 52.524],
                        [-118.2355, 52.4902],
                        [-118.1934, 52.4778],
                        [-118.2553, 52.4495],
                        [-118.2513, 52.4398],
                        [-118.2398, 52.427],
                        [-118.2399, 52.4176],
                        [-118.2444, 52.408],
                        [-118.2209, 52.3976],
                        [-118.2203, 52.3851],
                        [-118.226, 52.3803],
                        [-118.2128, 52.3703],
                        [-118.2007, 52.3727],
                        [-118.1801, 52.3726],
                        [-118.1821, 52.3828],
                        [-118.164, 52.3937],
                        [-118.142, 52.4024],
                        [-118.138, 52.4075],
                        [-118.0442, 52.3985],
                        [-118.0407, 52.4185],
                        [-118.0304, 52.4381],
                        [-118.0529, 52.4496],
                        [-118.0507, 52.4571],
                        [-118.0421, 52.459],
                        [-118.0211, 52.4555],
                        [-118.0185, 52.4593],
                        [-118.0218, 52.4723],
                        [-118.0133, 52.4839],
                        [-118.0035, 52.4926],
                        [-117.988, 52.5002],
                        [-117.9668, 52.4697],
                        [-117.8869, 52.4261],
                        [-117.8397, 52.4211],
                        [-117.7665, 52.4177],
                        [-117.7298, 52.3823],
                        [-117.7058, 52.3653],
                        [-117.7048, 52.3575],
                        [-117.723, 52.3556],
                        [-117.7529, 52.3164],
                        [-117.778, 52.3189],
                        [-117.7961, 52.2925],
                        [-117.8396, 52.2741],
                        [-117.8222, 52.2464],
                        [-117.8183, 52.2264],
                        [-117.7413, 52.203],
                        [-117.7432, 52.194],
                        [-117.6635, 52.1979],
                        [-117.6293, 52.1748],
                        [-117.6113, 52.1443],
                        [-117.5231, 52.1582],
                        [-117.5099, 52.1578],
                        [-117.5002, 52.1443],
                        [-117.3813, 52.1377],
                        [-117.334, 52.1486],
                        [-117.3272, 52.1899],
                        [-117.3173, 52.194],
                        [-117.307, 52.141],
                        [-117.2986, 52.0943],
                        [-117.292, 52.0854],
                        [-117.3051, 52.0743],
                        [-117.2945, 52.0651],
                        [-117.2684, 52.0558],
                        [-117.2706, 52.0489],
                        [-117.2521, 52.0386],
                        [-117.2482, 52.0348],
                        [-117.232, 52.0411],
                        [-117.2147, 52.0285],
                        [-117.2227, 52.0174],
                        [-117.232, 52.0165],
                        [-117.2164, 51.9973],
                        [-117.2017, 51.9929],
                        [-117.197, 51.9877],
                        [-117.1972, 51.9801],
                        [-117.1773, 51.9744],
                        [-117.1542, 51.9719],
                        [-117.1485, 51.9734],
                        [-117.1333, 51.9683],
                        [-117.1202, 51.9557],
                        [-117.1032, 51.9565],
                        [-117.0977, 51.9397],
                        [-117.0862, 51.9369],
                        [-117.0797, 51.9308],
                        [-117.0467, 51.9102],
                        [-117.0195, 51.8914],
                        [-117.0108, 51.8897],
                        [-117.0233, 51.88],
                        [-117.0343, 51.8598],
                        [-117.0321, 51.8544],
                        [-117.0194, 51.8429],
                        [-117.0057, 51.8361],
                        [-116.9845, 51.836],
                        [-116.9789, 51.8336],
                        [-116.9882, 51.8229],
                        [-116.9842, 51.8203],
                        [-116.989, 51.8111],
                        [-116.964, 51.795],
                        [-116.9661, 51.7877],
                        [-116.9632, 51.7743],
                        [-116.9737, 51.7695],
                        [-116.9764, 51.7602],
                        [-116.9606, 51.752],
                        [-116.9543, 51.7432],
                        [-116.9445, 51.7417],
                        [-116.9436, 51.7339],
                        [-116.9265, 51.7291],
                        [-116.926, 51.7175],
                        [-116.9206, 51.7098],
                        [-116.9026, 51.7038],
                        [-116.8873, 51.703],
                        [-116.8652, 51.7052],
                        [-116.8536, 51.7083],
                        [-116.8366, 51.7033],
                        [-116.8097, 51.7053],
                        [-116.807, 51.7131],
                        [-116.8001, 51.7169],
                        [-116.798, 51.7236],
                        [-116.8049, 51.7259],
                        [-116.8186, 51.7356],
                        [-116.8112, 51.7406],
                        [-116.8065, 51.7505],
                        [-116.7915, 51.764],
                        [-116.7729, 51.7748],
                        [-116.7653, 51.7846],
                        [-116.7596, 51.7875],
                        [-116.7545, 51.7999],
                        [-116.7442, 51.8066],
                        [-116.7251, 51.8048],
                        [-116.7155, 51.7987],
                        [-116.7068, 51.8024],
                        [-116.6945, 51.8036],
                        [-116.6821, 51.8121],
                        [-116.6543, 51.8013],
                        [-116.656, 51.7969],
                        [-116.6431, 51.7867],
                        [-116.651, 51.7668],
                        [-116.6503, 51.7537],
                        [-116.6403, 51.7512],
                        [-116.6281, 51.7449],
                        [-116.6312, 51.7314],
                        [-116.6242, 51.7273],
                        [-116.6105, 51.724],
                        [-116.5985, 51.7239],
                        [-116.5876, 51.7093],
                        [-116.5812, 51.6973],
                        [-116.5814, 51.6875],
                        [-116.5914, 51.6777],
                        [-116.5875, 51.673],
                        [-116.595, 51.6618],
                        [-116.5804, 51.6531],
                        [-116.5706, 51.6529],
                        [-116.5701, 51.6471],
                        [-116.5459, 51.6382],
                        [-116.5365, 51.6387],
                        [-116.5299, 51.6317],
                        [-116.5135, 51.6276],
                        [-116.4999, 51.6277],
                        [-116.4996, 51.6234],
                        [-116.489, 51.6167],
                        [-116.491, 51.6123],
                        [-116.4714, 51.6047],
                        [-116.466, 51.5941],
                        [-116.4733, 51.5827],
                        [-116.4648, 51.5658],
                        [-116.4529, 51.5606],
                        [-116.4154, 51.5533],
                        [-116.4014, 51.5465],
                        [-116.3909, 51.5456],
                        [-116.3829, 51.5326],
                        [-116.3954, 51.5255],
                        [-116.3935, 51.518],
                        [-116.3963, 51.5126],
                        [-116.3939, 51.5057],
                        [-116.3798, 51.4883],
                        [-116.369, 51.483],
                        [-116.3606, 51.4709],
                        [-116.3466, 51.4695],
                        [-116.338, 51.4643],
                        [-116.3223, 51.4625],
                        [-116.2925, 51.4618],
                        [-116.2841, 51.4565],
                        [-116.2881, 51.4485],
                        [-116.2855, 51.4353],
                        [-116.2943, 51.4299],
                        [-116.2825, 51.4068],
                        [-116.2925, 51.4036],
                        [-116.2919, 51.3972],
                        [-116.3115, 51.385],
                        [-116.3027, 51.3713],
                        [-116.2934, 51.3648],
                        [-116.2777, 51.3617],
                        [-116.2773, 51.3547],
                        [-116.29, 51.3431],
                        [-116.2867, 51.3345],
                        [-116.2748, 51.3282],
                        [-116.2746, 51.3194],
                        [-116.268, 51.3119],
                        [-116.26, 51.3098],
                        [-116.2434, 51.3014],
                        [-116.2271, 51.3015],
                        [-116.2191, 51.2945],
                        [-116.2068, 51.2962],
                        [-116.1885, 51.3015],
                        [-116.1794, 51.2954],
                        [-116.1641, 51.2978],
                        [-116.1516, 51.2871],
                        [-116.1591, 51.2758],
                        [-116.1532, 51.2634],
                        [-116.1441, 51.2647],
                        [-116.1251, 51.2593],
                        [-116.1117, 51.2533],
                        [-116.0918, 51.2508],
                        [-116.0813, 51.2518],
                        [-116.0652, 51.2479],
                        [-116.0492, 51.2274],
                        [-116.0267, 51.2198],
                        [-116.0165, 51.2236],
                        [-116.0076, 51.222],
                        [-116.002, 51.2154],
                        [-116.0065, 51.2107],
                        [-116, 51.1939],
                        [-116.0061, 51.1902],
                        [-116.0213, 51.187],
                        [-116.0239, 51.1762],
                        [-116.0368, 51.17],
                        [-116.0295, 51.1627],
                        [-116.0175, 51.1561],
                        [-116.0094, 51.1551],
                        [-116.0099, 51.1437],
                        [-116.0156, 51.1381],
                        [-116.0113, 51.1277],
                        [-116.0044, 51.1247],
                        [-115.9848, 51.126],
                        [-115.9683, 51.1221],
                        [-115.9676, 51.116],
                        [-115.9572, 51.1152],
                        [-115.9504, 51.1069],
                        [-115.9485, 51.0992],
                        [-115.9325, 51.0967],
                        [-115.9236, 51.0831],
                        [-115.8947, 51.0861],
                        [-115.883, 51.086],
                        [-115.8679, 51.0893],
                        [-115.8577, 51.0791],
                        [-115.8476, 51.0818],
                        [-115.8334, 51.0759],
                        [-115.8255, 51.0827],
                        [-115.8166, 51.0847],
                        [-115.808, 51.0806],
                        [-115.8028, 51.0696],
                        [-115.7892, 51.0724],
                        [-115.7724, 51.067],
                        [-115.762, 51.0719],
                        [-115.7613, 51.0608],
                        [-115.7736, 51.0577],
                        [-115.767, 51.0394],
                        [-115.7515, 51.0315],
                        [-115.7431, 51.0339],
                        [-115.7353, 51.0296],
                        [-115.74, 51.0249],
                        [-115.7268, 51.0187],
                        [-115.7171, 51.0174],
                        [-115.7038, 51.0213],
                        [-115.6923, 51.0286],
                        [-115.6922, 51.0198],
                        [-115.6886, 51.0136],
                        [-115.6778, 51.0072],
                        [-115.6694, 50.9993],
                        [-115.6574, 51.0013],
                        [-115.6488, 50.9975],
                        [-115.6362, 50.982],
                        [-115.6281, 50.9777],
                        [-115.622, 50.9655],
                        [-115.6117, 50.9589],
                        [-115.6146, 50.9512],
                        [-115.6108, 50.9468],
                        [-115.597, 50.9399],
                        [-115.6055, 50.9334],
                        [-115.6098, 50.9263],
                        [-115.5999, 50.9179],
                        [-115.5842, 50.9158],
                        [-115.5686, 50.9021],
                        [-115.5621, 50.8943],
                        [-115.5779, 50.8883],
                        [-115.5926, 50.8926],
                        [-115.6127, 50.8853],
                        [-115.6169, 50.8794],
                        [-115.629, 50.8798],
                        [-115.6489, 50.8741],
                        [-115.651, 50.8659],
                        [-115.6439, 50.8573],
                        [-115.643, 50.8422],
                        [-115.6394, 50.838],
                        [-115.627, 50.8334],
                        [-115.6181, 50.8357],
                        [-115.6074, 50.844],
                        [-115.5906, 50.8431],
                        [-115.5782, 50.8405],
                        [-115.5649, 50.8322],
                        [-115.5608, 50.8265],
                        [-115.5665, 50.8147],
                        [-115.5605, 50.8047],
                        [-115.5529, 50.8025],
                        [-115.5509, 50.7971],
                        [-115.5386, 50.7966],
                        [-115.5325, 50.7882],
                        [-115.517, 50.7802],
                        [-115.5122, 50.7864],
                        [-115.4976, 50.7823],
                        [-115.4819, 50.7686],
                        [-115.4848, 50.7607],
                        [-115.4812, 50.7552],
                        [-115.4703, 50.752],
                        [-115.4605, 50.7569],
                        [-115.4471, 50.7532],
                        [-115.4363, 50.7557],
                        [-115.4196, 50.7366],
                        [-115.4124, 50.7336],
                        [-115.4114, 50.7232],
                        [-115.4169, 50.7199],
                        [-115.4138, 50.7113],
                        [-115.4037, 50.7123],
                        [-115.3912, 50.7065],
                        [-115.3789, 50.7053],
                        [-115.3776, 50.7109],
                        [-115.3546, 50.7227],
                        [-115.3499, 50.7213],
                        [-115.333, 50.7241],
                        [-115.3276, 50.7219],
                        [-115.3151, 50.7258],
                        [-115.3107, 50.7216],
                        [-115.3144, 50.7154],
                        [-115.3016, 50.7006],
                        [-115.2936, 50.6984],
                        [-115.3008, 50.6907],
                        [-115.2984, 50.6833],
                        [-115.2823, 50.6632],
                        [-115.2813, 50.6538],
                        [-115.2855, 50.6388],
                        [-115.3119, 50.6382],
                        [-115.3101, 50.6273],
                        [-115.298, 50.6248],
                        [-115.2936, 50.6111],
                        [-115.2804, 50.6055],
                        [-115.2719, 50.5979],
                        [-115.2601, 50.5963],
                        [-115.2473, 50.5881],
                        [-115.2397, 50.5892],
                        [-115.2302, 50.5775],
                        [-115.2419, 50.5712],
                        [-115.2349, 50.5676],
                        [-115.2298, 50.5592],
                        [-115.231, 50.5449],
                        [-115.2169, 50.5365],
                        [-115.2081, 50.5278],
                        [-115.2022, 50.533],
                        [-115.1909, 50.5351],
                        [-115.1837, 50.544],
                        [-115.1909, 50.5541],
                        [-115.1777, 50.5582],
                        [-115.1736, 50.5676],
                        [-115.1546, 50.5733],
                        [-115.1485, 50.5708],
                        [-115.1361, 50.5744],
                        [-115.1175, 50.5698],
                        [-115.1009, 50.5779],
                        [-115.0853, 50.5897],
                        [-115.0737, 50.5884],
                        [-115.0495, 50.5752],
                        [-115.0336, 50.5793],
                        [-115.0205, 50.5847],
                        [-115.0151, 50.5824],
                        [-115.0137, 50.5709],
                        [-114.9914, 50.5444],
                        [-114.9783, 50.535],
                        [-114.9775, 50.5296],
                        [-114.959, 50.5044],
                        [-114.9458, 50.4896],
                        [-114.9372, 50.4863],
                        [-114.9317, 50.4785],
                        [-114.9323, 50.4735],
                        [-114.9174, 50.4615],
                        [-114.9122, 50.4514],
                        [-114.8878, 50.4356],
                        [-114.8747, 50.4396],
                        [-114.873, 50.4302],
                        [-114.8596, 50.421],
                        [-114.8636, 50.4173],
                        [-114.8654, 50.3989],
                        [-114.8569, 50.3925],
                        [-114.8383, 50.3936],
                        [-114.8312, 50.3903],
                        [-114.8248, 50.3933],
                        [-114.8203, 50.3845],
                        [-114.8139, 50.3819],
                        [-114.8192, 50.3691],
                        [-114.8088, 50.3707],
                        [-114.8039, 50.3649],
                        [-114.7891, 50.3581],
                        [-114.7845, 50.3628],
                        [-114.775, 50.3625],
                        [-114.7686, 50.3588],
                        [-114.7662, 50.3507],
                        [-114.7739, 50.3446],
                        [-114.7922, 50.3358],
                        [-114.7976, 50.3261],
                        [-114.7804, 50.3153],
                        [-114.7816, 50.3016],
                        [-114.7694, 50.3026],
                        [-114.7551, 50.2992],
                        [-114.7517, 50.2753],
                        [-114.7641, 50.2652],
                        [-114.7665, 50.254],
                        [-114.7702, 50.25],
                        [-114.768, 50.2431],
                        [-114.762, 50.2409],
                        [-114.7629, 50.2347],
                        [-114.7558, 50.2318],
                        [-114.7453, 50.2223],
                        [-114.749, 50.2149],
                        [-114.7396, 50.2117],
                        [-114.7389, 50.2027],
                        [-114.7247, 50.1902],
                        [-114.7239, 50.1839],
                        [-114.7309, 50.176],
                        [-114.7326, 50.1693],
                        [-114.7253, 50.1614],
                        [-114.7273, 50.1484],
                        [-114.7248, 50.1433],
                        [-114.7333, 50.1186],
                        [-114.7204, 50.1103],
                        [-114.7194, 50.1057],
                        [-114.7108, 50.1022],
                        [-114.6974, 50.0869],
                        [-114.6913, 50.0713],
                        [-114.6771, 50.0635],
                        [-114.6652, 50.0637],
                        [-114.6664, 50.0509],
                        [-114.6761, 50.0481],
                        [-114.6629, 50.0396],
                        [-114.6664, 50.0354],
                        [-114.6577, 50.0297],
                        [-114.6577, 50.0191],
                        [-114.6666, 50.0033],
                        [-114.6548, 49.9969],
                        [-114.6515, 49.9755],
                        [-114.6574, 49.9684],
                        [-114.6833, 49.9671],
                        [-114.6853, 49.9552],
                        [-114.6937, 49.9426],
                        [-114.6902, 49.9216],
                        [-114.6873, 49.9171],
                        [-114.6918, 49.8963],
                        [-114.6849, 49.8878],
                        [-114.6727, 49.8783],
                        [-114.6714, 49.8645],
                        [-114.6614, 49.8623],
                        [-114.6627, 49.8548],
                        [-114.6548, 49.8467],
                        [-114.6506, 49.8384],
                        [-114.6399, 49.8277],
                        [-114.6474, 49.8193],
                        [-114.6419, 49.808],
                        [-114.6422, 49.7958],
                        [-114.6361, 49.7848],
                        [-114.6444, 49.773],
                        [-114.6591, 49.7651],
                        [-114.6514, 49.7587],
                        [-114.6422, 49.7463],
                        [-114.6429, 49.7391],
                        [-114.6331, 49.7341],
                        [-114.6389, 49.7178],
                        [-114.661, 49.7062],
                        [-114.6683, 49.7038],
                        [-114.6766, 49.6961],
                        [-114.669, 49.691],
                        [-114.6698, 49.6729],
                        [-114.657, 49.6609],
                        [-114.6621, 49.6434],
                        [-114.6677, 49.6377],
                        [-114.6746, 49.6435],
                        [-114.6871, 49.6379],
                        [-114.691, 49.6327],
                        [-114.7002, 49.6368],
                        [-114.7083, 49.6359],
                        [-114.7234, 49.6239],
                        [-114.7473, 49.6194],
                        [-114.7471, 49.6024],
                        [-114.7372, 49.5959],
                        [-114.7326, 49.5765],
                        [-114.7103, 49.5629],
                        [-114.7103, 49.5585],
                        [-114.6958, 49.5466],
                        [-114.6905, 49.5537],
                        [-114.6833, 49.5565],
                        [-114.6763, 49.5533],
                        [-114.6626, 49.552],
                        [-114.6546, 49.5452],
                        [-114.6379, 49.5436],
                        [-114.6304, 49.5491],
                        [-114.6196, 49.5464],
                        [-114.6089, 49.5549],
                        [-114.6073, 49.5593],
                        [-114.5934, 49.5611],
                        [-114.5882, 49.5654],
                        [-114.5789, 49.5581],
                        [-114.573, 49.557],
                        [-114.5742, 49.5464],
                        [-114.5698, 49.537],
                        [-114.5727, 49.5247],
                        [-114.5832, 49.5202],
                        [-114.585, 49.5125],
                        [-114.5946, 49.5027],
                        [-114.5929, 49.4932],
                        [-114.5939, 49.4694],
                        [-114.5994, 49.4666],
                        [-114.5983, 49.4454],
                        [-114.6051, 49.4389],
                        [-114.5986, 49.4339],
                        [-114.6008, 49.4247],
                        [-114.5972, 49.4215],
                        [-114.5984, 49.4126],
                        [-114.592, 49.4049],
                        [-114.5906, 49.3961],
                        [-114.5761, 49.3867],
                        [-114.5669, 49.3769],
                        [-114.5442, 49.3776],
                        [-114.5366, 49.3796],
                        [-114.5263, 49.3751],
                        [-114.5316, 49.3705],
                        [-114.5241, 49.3621],
                        [-114.523, 49.3563],
                        [-114.5069, 49.3566],
                        [-114.4876, 49.3473],
                        [-114.4789, 49.3268],
                        [-114.4892, 49.3239],
                        [-114.4921, 49.3138],
                        [-114.485, 49.3107],
                        [-114.4776, 49.3124],
                        [-114.4668, 49.3044],
                        [-114.4612, 49.3041],
                        [-114.4468, 49.2884],
                        [-114.4512, 49.2804],
                        [-114.4479, 49.2644],
                        [-114.4352, 49.2622],
                        [-114.4145, 49.2622],
                        [-114.3935, 49.2572],
                        [-114.3836, 49.2462],
                        [-114.4027, 49.2417],
                        [-114.4011, 49.2278],
                        [-114.405, 49.2243],
                        [-114.3939, 49.2194],
                        [-114.4021, 49.2133],
                        [-114.3868, 49.205],
                        [-114.3773, 49.2029],
                        [-114.3662, 49.2037],
                        [-114.3589, 49.1984],
                        [-114.3456, 49.1936],
                        [-114.3369, 49.1994],
                        [-114.3184, 49.2],
                        [-114.3105, 49.192],
                        [-114.301, 49.1908],
                        [-114.2901, 49.186],
                        [-114.2873, 49.178],
                        [-114.2694, 49.181],
                        [-114.2571, 49.177],
                        [-114.2467, 49.1873],
                        [-114.2347, 49.1854],
                        [-114.2305, 49.1893],
                        [-114.2215, 49.1891],
                        [-114.2127, 49.1734],
                        [-114.1749, 49.1636],
                        [-114.1619, 49.1517],
                        [-114.1491, 49.1437],
                        [-114.1655, 49.1371],
                        [-114.1579, 49.1237],
                        [-114.1498, 49.1181],
                        [-114.1491, 49.1074],
                        [-114.1533, 49.0996],
                        [-114.1298, 49.0782],
                        [-114.118, 49.0741],
                        [-114.1072, 49.0651],
                        [-114.0896, 49.0585],
                        [-114.0813, 49.0598],
                        [-114.0766, 49.0537],
                        [-114.0639, 49.0455],
                        [-114.0623, 49.0375],
                        [-114.0542, 49.0267],
                        [-114.0591, 49.0184],
                        [-114.0718, 49.006],
                        [-114.0693, 49.0001],
                        [-114.3098, 49.0001],
                        [-114.5008, 49],
                        [-114.7635, 49],
                        [-114.9766, 49],
                        [-115.2403, 49],
                        [-115.4844, 49],
                        [-115.5139, 49.0007],
                        [-115.8181, 49.0012],
                        [-116.027, 49.0009],
                        [-116.0271, 49],
                        [-116.2757, 49],
                        [-116.5, 49],
                        [-116.7553, 49],
                        [-117.0152, 49],
                        [-117.2684, 49],
                        [-117.4633, 49.0006],
                        [-117.6363, 49.0008],
                        [-117.7585, 49.0006],
                        [-118.0019, 49.0004],
                        [-118.1544, 49.0002],
                        [-118.27, 49.0002],
                        [-118.5, 49],
                        [-118.6061, 49.0001],
                        [-118.7997, 49.0002],
                        [-119.0085, 49],
                        [-119.25, 49],
                        [-119.536, 49],
                        [-119.7027, 49],
                        [-119.9181, 49.0001],
                        [-120.0698, 49.0003],
                        [-120.37, 49.0001],
                        [-120.4847, 49],
                        [-120.7486, 49],
                        [-121, 49],
                        [-121.25, 49],
                        [-121.5, 49],
                        [-121.75, 49],
                        [-121.9959, 49],
                        [-122.1168, 49.0022],
                        [-122.2345, 49.0022],
                        [-122.3201, 49.0025],
                        [-122.4592, 49.0023],
                        [-122.6031, 49.0022],
                        [-122.7579, 49.0022],
                        [-122.771, 49.0103],
                        [-122.816, 49.0238],
                        [-122.8261, 49.0253],
                        [-122.8667, 49.0244],
                        [-122.8741, 49.0284],
                        [-122.8846, 49.045],
                        [-122.8879, 49.0568],
                        [-122.8755, 49.0562],
                        [-122.8605, 49.0634],
                        [-122.8587, 49.0742],
                        [-122.8525, 49.0829],
                        [-122.8761, 49.0907],
                        [-122.8907, 49.0915],
                        [-122.9086, 49.0846],
                        [-122.946, 49.0736],
                        [-122.9757, 49.068],
                        [-123.0081, 49.0634],
                        [-123.0175, 49.0606],
                        [-123.0397, 49.0489],
                        [-123.046, 49.047],
                        [-123.0565, 49.0275],
                        [-123.0397, 49.0176],
                        [-123.0352, 49.0021],
                        [-123.0908, 49.0019],
                        [-123.0955, 49.0161],
                        [-123.1023, 49.0265],
                        [-123.0992, 49.0348],
                        [-123.1071, 49.0383],
                        [-123.1125, 49.0477],
                        [-123.1231, 49.0496],
                        [-123.1502, 49.0303],
                        [-123.1566, 49.0237],
                        [-123.1523, 49.0197],
                        [-123.1575, 49.0142],
                        [-123.1719, 49.0187],
                        [-123.1668, 49.0247],
                        [-123.1587, 49.0244],
                        [-123.1527, 49.0305],
                        [-123.1238, 49.0508],
                        [-123.1493, 49.0621],
                        [-123.1578, 49.0645],
                        [-123.1556, 49.0709],
                        [-123.1787, 49.0836],
                        [-123.1811, 49.0932],
                        [-123.1884, 49.1013],
                        [-123.1871, 49.1095],
                        [-123.1914, 49.1246],
                        [-123.1955, 49.1266],
                        [-123.1943, 49.1443],
                        [-123.1974, 49.1681],
                        [-123.2004, 49.1736],
                        [-123.1936, 49.1784],
                        [-123.2119, 49.1836],
                        [-123.2086, 49.2003],
                        [-123.2034, 49.2066],
                        [-123.2015, 49.2159],
                        [-123.2118, 49.2168],
                        [-123.2157, 49.2208],
                        [-123.2366, 49.2306],
                        [-123.2454, 49.2372],
                        [-123.2482, 49.2489],
                        [-123.2648, 49.2665],
                        [-123.2624, 49.2708],
                        [-123.2502, 49.2784],
                        [-123.2436, 49.2798],
                        [-123.222, 49.2782],
                        [-123.2111, 49.2753],
                        [-123.2021, 49.2765],
                        [-123.171, 49.2717],
                        [-123.1553, 49.2738],
                        [-123.1444, 49.2789],
                        [-123.144, 49.2845],
                        [-123.1438, 49.2885],
                        [-123.1545, 49.2977],
                        [-123.1559, 49.3087],
                        [-123.1414, 49.3141],
                        [-123.1252, 49.2977],
                        [-123.1252, 49.2905],
                        [-123.1139, 49.2896],
                        [-123.1022, 49.2853],
                        [-123.0991, 49.2903],
                        [-123.0853, 49.2862],
                        [-123.0641, 49.2879],
                        [-123.0502, 49.2939],
                        [-123.0317, 49.2904],
                        [-123.0201, 49.2937],
                        [-123.0002, 49.2908],
                        [-122.9894, 49.295],
                        [-122.9663, 49.2881],
                        [-122.9292, 49.2925],
                        [-122.9181, 49.2891],
                        [-122.8994, 49.2887],
                        [-122.8945, 49.2917],
                        [-122.8735, 49.2922],
                        [-122.8584, 49.2812],
                        [-122.8455, 49.2799],
                        [-122.8385, 49.2825],
                        [-122.8435, 49.2885],
                        [-122.8614, 49.2951],
                        [-122.8711, 49.3016],
                        [-122.884, 49.3011],
                        [-122.8949, 49.2965],
                        [-122.9185, 49.2932],
                        [-122.9292, 49.3045],
                        [-122.9273, 49.3215],
                        [-122.918, 49.3278],
                        [-122.9092, 49.3207],
                        [-122.8871, 49.3363],
                        [-122.8913, 49.339],
                        [-122.889, 49.3501],
                        [-122.8752, 49.3646],
                        [-122.8735, 49.3718],
                        [-122.8626, 49.3836],
                        [-122.8705, 49.3899],
                        [-122.8694, 49.4003],
                        [-122.86, 49.4062],
                        [-122.8575, 49.4236],
                        [-122.8539, 49.4258],
                        [-122.854, 49.4365],
                        [-122.8626, 49.45],
                        [-122.8624, 49.4539],
                        [-122.8717, 49.4581],
                        [-122.8868, 49.4704],
                        [-122.8868, 49.4581],
                        [-122.8766, 49.4471],
                        [-122.8795, 49.4432],
                        [-122.8732, 49.4319],
                        [-122.8753, 49.4217],
                        [-122.8848, 49.4099],
                        [-122.8834, 49.4036],
                        [-122.8869, 49.3971],
                        [-122.8796, 49.3807],
                        [-122.8915, 49.3754],
                        [-122.8899, 49.3645],
                        [-122.8935, 49.3595],
                        [-122.9065, 49.3565],
                        [-122.9097, 49.349],
                        [-122.9268, 49.3393],
                        [-122.9351, 49.3329],
                        [-122.9452, 49.3314],
                        [-122.9484, 49.3264],
                        [-122.9368, 49.3216],
                        [-122.9486, 49.3144],
                        [-122.9486, 49.3048],
                        [-122.9564, 49.3006],
                        [-122.9698, 49.3031],
                        [-122.9856, 49.3084],
                        [-122.9997, 49.3079],
                        [-123.0005, 49.3044],
                        [-123.0131, 49.2979],
                        [-123.0213, 49.2967],
                        [-123.0394, 49.2983],
                        [-123.0561, 49.3047],
                        [-123.0696, 49.3049],
                        [-123.0917, 49.3133],
                        [-123.1069, 49.314],
                        [-123.115, 49.3085],
                        [-123.1482, 49.3216],
                        [-123.1536, 49.3267],
                        [-123.1708, 49.3279],
                        [-123.1772, 49.332],
                        [-123.1943, 49.3352],
                        [-123.2056, 49.3402],
                        [-123.2192, 49.3382],
                        [-123.2279, 49.3412],
                        [-123.2376, 49.3383],
                        [-123.245, 49.3405],
                        [-123.2648, 49.3298],
                        [-123.2698, 49.3402],
                        [-123.2673, 49.3445],
                        [-123.2693, 49.3541],
                        [-123.2818, 49.3579],
                        [-123.2871, 49.3662],
                        [-123.2882, 49.3752],
                        [-123.2812, 49.379],
                        [-123.2674, 49.3811],
                        [-123.2455, 49.4025],
                        [-123.2435, 49.4092],
                        [-123.2343, 49.4183],
                        [-123.233, 49.425],
                        [-123.2392, 49.439],
                        [-123.2406, 49.4695],
                        [-123.2499, 49.4889],
                        [-123.2471, 49.4944],
                        [-123.2495, 49.5016],
                        [-123.2613, 49.5083],
                        [-123.2628, 49.5195],
                        [-123.2525, 49.5383],
                        [-123.2373, 49.5569],
                        [-123.2277, 49.5768],
                        [-123.2278, 49.5819],
                        [-123.2218, 49.5891],
                        [-123.2251, 49.5937],
                        [-123.2168, 49.6113],
                        [-123.2061, 49.6201],
                        [-123.2078, 49.629],
                        [-123.2249, 49.6471],
                        [-123.2197, 49.6547],
                        [-123.2117, 49.6575],
                        [-123.1899, 49.6569],
                        [-123.178, 49.6607],
                        [-123.1692, 49.6664],
                        [-123.1677, 49.6717],
                        [-123.157, 49.6795],
                        [-123.1565, 49.6861],
                        [-123.171, 49.6835],
                        [-123.1833, 49.6852],
                        [-123.1872, 49.6823],
                        [-123.2, 49.6823],
                        [-123.2186, 49.6772],
                        [-123.2399, 49.6689],
                        [-123.2497, 49.6675],
                        [-123.2593, 49.6602],
                        [-123.2575, 49.656],
                        [-123.261, 49.6468],
                        [-123.2593, 49.6374],
                        [-123.2526, 49.6301],
                        [-123.2492, 49.6115],
                        [-123.2555, 49.6098],
                        [-123.2654, 49.5893],
                        [-123.2786, 49.5822],
                        [-123.2977, 49.5821],
                        [-123.3087, 49.5837],
                        [-123.3243, 49.5799],
                        [-123.3327, 49.5715],
                        [-123.3456, 49.5686],
                        [-123.3507, 49.5639],
                        [-123.3508, 49.5559],
                        [-123.3578, 49.5541],
                        [-123.3761, 49.5586],
                        [-123.3815, 49.5624],
                        [-123.3922, 49.5607],
                        [-123.4016, 49.5529],
                        [-123.4137, 49.5504],
                        [-123.4289, 49.5426],
                        [-123.4459, 49.54],
                        [-123.4637, 49.5325],
                        [-123.4779, 49.5284],
                        [-123.4822, 49.5214],
                        [-123.4923, 49.5188],
                        [-123.4936, 49.5042],
                        [-123.4962, 49.5013],
                        [-123.4849, 49.4933],
                        [-123.4711, 49.4868],
                        [-123.4832, 49.4814],
                        [-123.4869, 49.4742],
                        [-123.481, 49.4645],
                        [-123.4678, 49.4494],
                        [-123.4668, 49.4431],
                        [-123.4721, 49.4396],
                        [-123.4794, 49.4277],
                        [-123.4824, 49.4165],
                        [-123.4954, 49.4122],
                        [-123.5041, 49.402],
                        [-123.5003, 49.3936],
                        [-123.5113, 49.3914],
                        [-123.5141, 49.3877],
                        [-123.538, 49.3838],
                        [-123.5578, 49.391],
                        [-123.5605, 49.3968],
                        [-123.5691, 49.4019],
                        [-123.5853, 49.4028],
                        [-123.6019, 49.4098],
                        [-123.6246, 49.4153],
                        [-123.6354, 49.4193],
                        [-123.6448, 49.4186],
                        [-123.6655, 49.4293],
                        [-123.6728, 49.4297],
                        [-123.6841, 49.4361],
                        [-123.7132, 49.4363],
                        [-123.7232, 49.4384],
                        [-123.7431, 49.4642],
                        [-123.743, 49.4694],
                        [-123.7549, 49.4706],
                        [-123.773, 49.4699],
                        [-123.7886, 49.4657],
                        [-123.8001, 49.4674],
                        [-123.817, 49.4673],
                        [-123.8221, 49.4703],
                        [-123.8412, 49.4721],
                        [-123.8555, 49.4778],
                        [-123.8626, 49.4748],
                        [-123.8556, 49.469],
                        [-123.8709, 49.4679],
                        [-123.9015, 49.4801],
                        [-123.9122, 49.4985],
                        [-123.9076, 49.5075],
                        [-123.9146, 49.513],
                        [-123.925, 49.5132],
                        [-123.9508, 49.5037],
                        [-123.9658, 49.5126],
                        [-123.96, 49.5227],
                        [-123.9631, 49.5273],
                        [-123.9578, 49.5322],
                        [-123.9667, 49.5379],
                        [-123.978, 49.5372],
                        [-123.9929, 49.5507],
                        [-124.0017, 49.5553],
                        [-124.0017, 49.566],
                        [-124.0158, 49.5865],
                        [-124.0158, 49.5947],
                        [-124.0207, 49.5961],
                        [-124.0245, 49.6061],
                        [-124.0353, 49.6131],
                        [-124.0227, 49.625],
                        [-124.039, 49.6332],
                        [-124.0574, 49.6314],
                        [-124.0622, 49.6384],
                        [-124.0748, 49.642],
                        [-124.0704, 49.6503],
                        [-124.0739, 49.6616],
                        [-124.066, 49.6688],
                        [-124.068, 49.6746],
                        [-124.0642, 49.6888],
                        [-124.0667, 49.6943],
                        [-124.0468, 49.7106],
                        [-124.0445, 49.7184],
                        [-124.0355, 49.7234],
                        [-124.0368, 49.7369],
                        [-124.0331, 49.7421],
                        [-124.0055, 49.7532],
                        [-123.9983, 49.7509],
                        [-123.9893, 49.7538],
                        [-123.9898, 49.7584],
                        [-123.9721, 49.7669],
                        [-123.949, 49.762],
                        [-123.9464, 49.7589],
                        [-123.9296, 49.7542],
                        [-123.9302, 49.75],
                        [-123.9159, 49.7463],
                        [-123.8936, 49.7344],
                        [-123.8869, 49.7263],
                        [-123.8903, 49.7195],
                        [-123.8903, 49.6997],
                        [-123.8641, 49.6757],
                        [-123.862, 49.6585],
                        [-123.8566, 49.6468],
                        [-123.8481, 49.6395],
                        [-123.8458, 49.6272],
                        [-123.8416, 49.62],
                        [-123.8313, 49.6109],
                        [-123.8276, 49.6033],
                        [-123.8149, 49.5936],
                        [-123.818, 49.5879],
                        [-123.8095, 49.5752],
                        [-123.8086, 49.5647],
                        [-123.7998, 49.5515],
                        [-123.7938, 49.5473],
                        [-123.7999, 49.5412],
                        [-123.7934, 49.5343],
                        [-123.7994, 49.5194],
                        [-123.7944, 49.5157],
                        [-123.7971, 49.5102],
                        [-123.7837, 49.5073],
                        [-123.7647, 49.4928],
                        [-123.7672, 49.4855],
                        [-123.7557, 49.4806],
                        [-123.748, 49.4949],
                        [-123.7469, 49.503],
                        [-123.7566, 49.5068],
                        [-123.7773, 49.5198],
                        [-123.7637, 49.5348],
                        [-123.7635, 49.5505],
                        [-123.7681, 49.5567],
                        [-123.784, 49.5689],
                        [-123.7807, 49.5722],
                        [-123.7858, 49.5839],
                        [-123.7831, 49.5895],
                        [-123.7878, 49.6026],
                        [-123.7725, 49.6072],
                        [-123.7596, 49.6181],
                        [-123.7357, 49.6232],
                        [-123.7237, 49.6289],
                        [-123.7185, 49.6368],
                        [-123.7078, 49.6371],
                        [-123.6876, 49.6413],
                        [-123.6808, 49.6376],
                        [-123.6678, 49.6368],
                        [-123.6458, 49.643],
                        [-123.6312, 49.6535],
                        [-123.6181, 49.6536],
                        [-123.5908, 49.6615],
                        [-123.5869, 49.667],
                        [-123.5609, 49.6725],
                        [-123.5539, 49.6789],
                        [-123.5445, 49.6817],
                        [-123.5434, 49.6883],
                        [-123.5283, 49.6992],
                        [-123.5329, 49.7042],
                        [-123.5481, 49.6964],
                        [-123.5593, 49.6831],
                        [-123.5702, 49.6833],
                        [-123.5746, 49.6783],
                        [-123.5911, 49.6769],
                        [-123.6125, 49.6703],
                        [-123.63, 49.6624],
                        [-123.6508, 49.6636],
                        [-123.6572, 49.6604],
                        [-123.6644, 49.6493],
                        [-123.6874, 49.6535],
                        [-123.7166, 49.6482],
                        [-123.7287, 49.6492],
                        [-123.7515, 49.6412],
                        [-123.7714, 49.6407],
                        [-123.8061, 49.6295],
                        [-123.825, 49.6347],
                        [-123.8351, 49.6495],
                        [-123.8409, 49.6534],
                        [-123.8403, 49.6594],
                        [-123.8299, 49.6637],
                        [-123.8228, 49.6761],
                        [-123.8006, 49.6823],
                        [-123.7929, 49.6871],
                        [-123.7737, 49.7125],
                        [-123.7614, 49.7131],
                        [-123.742, 49.7211],
                        [-123.7285, 49.7352],
                        [-123.7262, 49.7446],
                        [-123.7172, 49.7616],
                        [-123.7161, 49.7712],
                        [-123.7196, 49.7825],
                        [-123.7283, 49.7722],
                        [-123.7278, 49.7606],
                        [-123.7371, 49.7466],
                        [-123.7371, 49.739],
                        [-123.751, 49.7243],
                        [-123.7734, 49.7159],
                        [-123.7756, 49.7126],
                        [-123.7895, 49.7099],
                        [-123.8036, 49.6945],
                        [-123.8412, 49.6864],
                        [-123.8592, 49.709],
                        [-123.8637, 49.7106],
                        [-123.8682, 49.7263],
                        [-123.8804, 49.7342],
                        [-123.8882, 49.735],
                        [-123.8937, 49.7433],
                        [-123.8925, 49.7493],
                        [-123.917, 49.7565],
                        [-123.9255, 49.7623],
                        [-123.9363, 49.7657],
                        [-123.9374, 49.7705],
                        [-123.9469, 49.7764],
                        [-123.9425, 49.7798],
                        [-123.9204, 49.7846],
                        [-123.9231, 49.7986],
                        [-123.9135, 49.8009],
                        [-123.9085, 49.8065],
                        [-123.8956, 49.8132],
                        [-123.8889, 49.8275],
                        [-123.8731, 49.8463],
                        [-123.8785, 49.8577],
                        [-123.8695, 49.872],
                        [-123.8682, 49.8796],
                        [-123.8745, 49.8905],
                        [-123.8812, 49.8958],
                        [-123.8846, 49.9085],
                        [-123.8669, 49.916],
                        [-123.8749, 49.9246],
                        [-123.8863, 49.9215],
                        [-123.8933, 49.9165],
                        [-123.9025, 49.9152],
                        [-123.9246, 49.9171],
                        [-123.9367, 49.9208],
                        [-123.9492, 49.9286],
                        [-123.9614, 49.9386],
                        [-123.9765, 49.9613],
                        [-123.9743, 49.9808],
                        [-123.9686, 49.9885],
                        [-123.9564, 49.9977],
                        [-123.9265, 50.0021],
                        [-123.9199, 50.0011],
                        [-123.9071, 50.004],
                        [-123.8985, 50.0135],
                        [-123.8655, 50.0286],
                        [-123.8421, 50.0345],
                        [-123.8236, 50.0342],
                        [-123.8027, 50.0405],
                        [-123.7897, 50.0463],
                        [-123.7838, 50.0534],
                        [-123.793, 50.0588],
                        [-123.787, 50.0685],
                        [-123.7681, 50.0761],
                        [-123.755, 50.0849],
                        [-123.7429, 50.087],
                        [-123.7473, 50.0968],
                        [-123.7629, 50.1006],
                        [-123.7645, 50.1061],
                        [-123.7859, 50.1125],
                        [-123.7936, 50.1171],
                        [-123.7998, 50.1312],
                        [-123.8127, 50.1424],
                        [-123.8275, 50.1481],
                        [-123.8352, 50.1542],
                        [-123.8477, 50.1596],
                        [-123.8508, 50.1635],
                        [-123.8283, 50.1745],
                        [-123.8197, 50.1742],
                        [-123.7889, 50.1823],
                        [-123.7696, 50.1906],
                        [-123.7651, 50.2009],
                        [-123.7702, 50.206],
                        [-123.7849, 50.1939],
                        [-123.8003, 50.187],
                        [-123.8075, 50.1901],
                        [-123.8212, 50.1846],
                        [-123.8249, 50.1786],
                        [-123.837, 50.1788],
                        [-123.8565, 50.1621],
                        [-123.8725, 50.1693],
                        [-123.9029, 50.1779],
                        [-123.9414, 50.191],
                        [-123.9564, 50.1979],
                        [-123.9795, 50.2137],
                        [-123.9878, 50.2069],
                        [-123.9781, 50.1982],
                        [-123.9795, 50.192],
                        [-123.9675, 50.1828],
                        [-123.9525, 50.1783],
                        [-123.948, 50.1727],
                        [-123.9204, 50.1584],
                        [-123.906, 50.1551],
                        [-123.8885, 50.1481],
                        [-123.8796, 50.1468],
                        [-123.852, 50.1342],
                        [-123.8532, 50.1295],
                        [-123.8432, 50.12],
                        [-123.8443, 50.1164],
                        [-123.8324, 50.1042],
                        [-123.8183, 50.1023],
                        [-123.8084, 50.0981],
                        [-123.8028, 50.0922],
                        [-123.8217, 50.0803],
                        [-123.838, 50.0651],
                        [-123.8421, 50.0578],
                        [-123.8475, 50.0567],
                        [-123.875, 50.044],
                        [-123.8933, 50.0378],
                        [-123.9053, 50.0317],
                        [-123.9233, 50.0274],
                        [-123.9374, 50.0193],
                        [-123.9612, 50.0214],
                        [-123.975, 50.0167],
                        [-123.9898, 50.0018],
                        [-124.0085, 49.9981],
                        [-124.0109, 49.9932],
                        [-124.0084, 49.98],
                        [-124.0097, 49.9685],
                        [-124.0041, 49.9601],
                        [-123.9962, 49.9541],
                        [-123.9869, 49.9424],
                        [-123.9799, 49.93],
                        [-123.9788, 49.9209],
                        [-123.9652, 49.9109],
                        [-123.9612, 49.9052],
                        [-123.9401, 49.8988],
                        [-123.911, 49.8871],
                        [-123.9037, 49.8789],
                        [-123.9051, 49.872],
                        [-123.9116, 49.8677],
                        [-123.9155, 49.8538],
                        [-123.9204, 49.8488],
                        [-123.9215, 49.8277],
                        [-123.9309, 49.8377],
                        [-123.9455, 49.8384],
                        [-123.9595, 49.8269],
                        [-123.9539, 49.8236],
                        [-123.9657, 49.8154],
                        [-123.9594, 49.8075],
                        [-123.9692, 49.8047],
                        [-123.9997, 49.8116],
                        [-124.005, 49.8166],
                        [-124.0038, 49.8328],
                        [-123.994, 49.8376],
                        [-123.9958, 49.8424],
                        [-124.016, 49.8696],
                        [-124.0258, 49.8746],
                        [-124.0333, 49.8748],
                        [-124.0314, 49.8934],
                        [-124.0189, 49.9073],
                        [-124.0202, 49.9174],
                        [-124.033, 49.9169],
                        [-124.0321, 49.9308],
                        [-124.0391, 49.9318],
                        [-124.0401, 49.9205],
                        [-124.05, 49.9086],
                        [-124.0512, 49.8999],
                        [-124.057, 49.8952],
                        [-124.0651, 49.8834],
                        [-124.0666, 49.8754],
                        [-124.0766, 49.8716],
                        [-124.0774, 49.8664],
                        [-124.0637, 49.8603],
                        [-124.0538, 49.8593],
                        [-124.0566, 49.8531],
                        [-124.0484, 49.8415],
                        [-124.0388, 49.8354],
                        [-124.0615, 49.836],
                        [-124.0663, 49.834],
                        [-124.0845, 49.8429],
                        [-124.0915, 49.8376],
                        [-124.0931, 49.8223],
                        [-124.1004, 49.8184],
                        [-124.0957, 49.8115],
                        [-124.0811, 49.8024],
                        [-124.0921, 49.7975],
                        [-124.1018, 49.7981],
                        [-124.1126, 49.7916],
                        [-124.1211, 49.792],
                        [-124.1228, 49.7858],
                        [-124.1303, 49.7818],
                        [-124.1423, 49.78],
                        [-124.1696, 49.7794],
                        [-124.1802, 49.7813],
                        [-124.2056, 49.7762],
                        [-124.2164, 49.782],
                        [-124.2804, 49.773],
                        [-124.2728, 49.7678],
                        [-124.272, 49.7617],
                        [-124.264, 49.7578],
                        [-124.2598, 49.7495],
                        [-124.2687, 49.7424],
                        [-124.2761, 49.7432],
                        [-124.2874, 49.7568],
                        [-124.2965, 49.7626],
                        [-124.311, 49.7573],
                        [-124.3108, 49.7665],
                        [-124.3306, 49.7719],
                        [-124.3459, 49.7717],
                        [-124.3523, 49.7767],
                        [-124.3641, 49.7738],
                        [-124.383, 49.773],
                        [-124.3967, 49.7664],
                        [-124.4197, 49.7695],
                        [-124.4498, 49.7776],
                        [-124.4539, 49.7826],
                        [-124.4722, 49.7948],
                        [-124.4979, 49.7956],
                        [-124.5099, 49.8011],
                        [-124.5265, 49.8044],
                        [-124.5254, 49.8215],
                        [-124.5303, 49.846],
                        [-124.5491, 49.8608],
                        [-124.5563, 49.8719],
                        [-124.5777, 49.8787],
                        [-124.5921, 49.889],
                        [-124.6077, 49.896],
                        [-124.6194, 49.8955],
                        [-124.6333, 49.9103],
                        [-124.6415, 49.9155],
                        [-124.6498, 49.9167],
                        [-124.6685, 49.9262],
                        [-124.6912, 49.9361],
                        [-124.6997, 49.9367],
                        [-124.723, 49.95],
                        [-124.73, 49.9573],
                        [-124.7426, 49.9625],
                        [-124.7518, 49.9634],
                        [-124.7596, 49.9701],
                        [-124.7631, 49.9829],
                        [-124.7737, 49.9849],
                        [-124.7909, 50.0034],
                        [-124.803, 50.008],
                        [-124.8129, 50.02],
                        [-124.812, 50.0248],
                        [-124.8215, 50.0285],
                        [-124.8176, 50.0362],
                        [-124.8287, 50.0426],
                        [-124.8376, 50.0515],
                        [-124.8373, 50.0589],
                        [-124.8298, 50.0648],
                        [-124.823, 50.0628],
                        [-124.8089, 50.064],
                        [-124.8021, 50.0572],
                        [-124.7857, 50.0488],
                        [-124.7769, 50.0394],
                        [-124.7707, 50.0402],
                        [-124.7545, 50.0283],
                        [-124.7411, 50.0244],
                        [-124.7375, 50.0267],
                        [-124.7243, 50.0157],
                        [-124.7233, 50.002],
                        [-124.7194, 49.9952],
                        [-124.7022, 49.9856],
                        [-124.6846, 49.967],
                        [-124.6787, 49.974],
                        [-124.687, 49.9818],
                        [-124.6889, 49.9877],
                        [-124.7064, 49.999],
                        [-124.7049, 50.0074],
                        [-124.7194, 50.0313],
                        [-124.7174, 50.0383],
                        [-124.708, 50.044],
                        [-124.7098, 50.0542],
                        [-124.7008, 50.0591],
                        [-124.7069, 50.0664],
                        [-124.6919, 50.0647],
                        [-124.6765, 50.0687],
                        [-124.6535, 50.0797],
                        [-124.6637, 50.0827],
                        [-124.6787, 50.0734],
                        [-124.6848, 50.074],
                        [-124.6946, 50.0666],
                        [-124.7043, 50.0732],
                        [-124.7114, 50.0831],
                        [-124.724, 50.0823],
                        [-124.7177, 50.0733],
                        [-124.7222, 50.0686],
                        [-124.725, 50.0583],
                        [-124.7254, 50.043],
                        [-124.736, 50.0429],
                        [-124.7441, 50.0372],
                        [-124.7557, 50.0488],
                        [-124.7626, 50.0423],
                        [-124.7745, 50.0508],
                        [-124.7764, 50.058],
                        [-124.7916, 50.0615],
                        [-124.8058, 50.0734],
                        [-124.8005, 50.0767],
                        [-124.7827, 50.0683],
                        [-124.7744, 50.0753],
                        [-124.7683, 50.0764],
                        [-124.745, 50.0857],
                        [-124.7253, 50.0884],
                        [-124.7211, 50.0928],
                        [-124.7107, 50.0934],
                        [-124.701, 50.0987],
                        [-124.7079, 50.1043],
                        [-124.696, 50.1215],
                        [-124.6968, 50.1265],
                        [-124.7066, 50.1275],
                        [-124.7123, 50.1234],
                        [-124.7105, 50.1169],
                        [-124.721, 50.1161],
                        [-124.7295, 50.12],
                        [-124.7245, 50.1276],
                        [-124.7068, 50.1339],
                        [-124.7012, 50.1379],
                        [-124.6791, 50.1411],
                        [-124.6667, 50.146],
                        [-124.6589, 50.1546],
                        [-124.6441, 50.1554],
                        [-124.6315, 50.161],
                        [-124.6252, 50.1745],
                        [-124.6202, 50.1778],
                        [-124.6084, 50.193],
                        [-124.6059, 50.2018],
                        [-124.6161, 50.2035],
                        [-124.6162, 50.2187],
                        [-124.6141, 50.2347],
                        [-124.5964, 50.2348],
                        [-124.5905, 50.2474],
                        [-124.6156, 50.2581],
                        [-124.6242, 50.267],
                        [-124.6215, 50.2759],
                        [-124.6329, 50.2849],
                        [-124.6329, 50.293],
                        [-124.6393, 50.2975],
                        [-124.6551, 50.3016],
                        [-124.6646, 50.3072],
                        [-124.6608, 50.3126],
                        [-124.6657, 50.3173],
                        [-124.6846, 50.3126],
                        [-124.7088, 50.3213],
                        [-124.7191, 50.3205],
                        [-124.7342, 50.3226],
                        [-124.736, 50.3254],
                        [-124.7214, 50.3302],
                        [-124.697, 50.3409],
                        [-124.6965, 50.3454],
                        [-124.6863, 50.3554],
                        [-124.6762, 50.3691],
                        [-124.6667, 50.3896],
                        [-124.6564, 50.3974],
                        [-124.6436, 50.4035],
                        [-124.636, 50.4003],
                        [-124.6087, 50.3985],
                        [-124.5857, 50.3983],
                        [-124.5606, 50.4001],
                        [-124.5464, 50.3957],
                        [-124.5218, 50.3982],
                        [-124.5152, 50.4078],
                        [-124.5052, 50.4122],
                        [-124.5079, 50.4173],
                        [-124.4986, 50.4225],
                        [-124.4838, 50.4253],
                        [-124.4777, 50.4233],
                        [-124.4661, 50.4271],
                        [-124.4456, 50.4311],
                        [-124.4389, 50.439],
                        [-124.4293, 50.4409],
                        [-124.4177, 50.4382],
                        [-124.4091, 50.4409],
                        [-124.4028, 50.448],
                        [-124.3804, 50.4589],
                        [-124.3815, 50.4705],
                        [-124.3726, 50.4823],
                        [-124.3635, 50.4852],
                        [-124.3728, 50.494],
                        [-124.3852, 50.4945],
                        [-124.3925, 50.4898],
                        [-124.4117, 50.4671],
                        [-124.4297, 50.4601],
                        [-124.4629, 50.4539],
                        [-124.4757, 50.4483],
                        [-124.4942, 50.4426],
                        [-124.5289, 50.4362],
                        [-124.5418, 50.4219],
                        [-124.5558, 50.4195],
                        [-124.5893, 50.4206],
                        [-124.6125, 50.4193],
                        [-124.6455, 50.4261],
                        [-124.6616, 50.437],
                        [-124.6736, 50.4336],
                        [-124.6719, 50.4261],
                        [-124.6785, 50.4169],
                        [-124.6929, 50.4015],
                        [-124.7038, 50.3967],
                        [-124.6999, 50.3903],
                        [-124.708, 50.382],
                        [-124.7121, 50.3734],
                        [-124.7205, 50.3692],
                        [-124.7314, 50.3583],
                        [-124.7397, 50.3581],
                        [-124.752, 50.3448],
                        [-124.7601, 50.3457],
                        [-124.7704, 50.3419],
                        [-124.7853, 50.3314],
                        [-124.7849, 50.3277],
                        [-124.8014, 50.3219],
                        [-124.8077, 50.3158],
                        [-124.8204, 50.3127],
                        [-124.8297, 50.3068],
                        [-124.8365, 50.3101],
                        [-124.8851, 50.3194],
                        [-124.8934, 50.3247],
                        [-124.9157, 50.3266],
                        [-124.9379, 50.326],
                        [-124.948, 50.3292],
                        [-124.9618, 50.3375],
                        [-124.9653, 50.3454],
                        [-124.959, 50.3552],
                        [-124.9504, 50.358],
                        [-124.9404, 50.3768],
                        [-124.9419, 50.3861],
                        [-124.9524, 50.3902],
                        [-124.9505, 50.3946],
                        [-124.9559, 50.4038],
                        [-124.9632, 50.4295],
                        [-124.9704, 50.4318],
                        [-124.9797, 50.4395],
                        [-124.9998, 50.446],
                        [-125.0083, 50.4464],
                        [-125.0131, 50.4403],
                        [-124.9968, 50.4289],
                        [-124.9876, 50.4205],
                        [-124.9802, 50.4052],
                        [-124.9814, 50.3997],
                        [-124.9746, 50.3848],
                        [-124.9688, 50.3783],
                        [-124.9752, 50.3685],
                        [-124.9944, 50.3473],
                        [-125.0033, 50.3427],
                        [-125.018, 50.3429],
                        [-125.0257, 50.3472],
                        [-125.0421, 50.3512],
                        [-125.0425, 50.3463],
                        [-125.0318, 50.3328],
                        [-125.0416, 50.3206],
                        [-125.0548, 50.3154],
                        [-125.0692, 50.3188],
                        [-125.0797, 50.3255],
                        [-125.0763, 50.3328],
                        [-125.0802, 50.3398],
                        [-125.0913, 50.3452],
                        [-125.0885, 50.3557],
                        [-125.0809, 50.3588],
                        [-125.0692, 50.3593],
                        [-125.0541, 50.3737],
                        [-125.0587, 50.3787],
                        [-125.0517, 50.3869],
                        [-125.0571, 50.3991],
                        [-125.0656, 50.4096],
                        [-125.057, 50.4191],
                        [-125.0547, 50.4365],
                        [-125.0569, 50.4443],
                        [-125.0431, 50.4519],
                        [-125.0507, 50.4635],
                        [-125.0486, 50.4699],
                        [-125.0502, 50.4809],
                        [-125.0388, 50.4839],
                        [-125.0241, 50.4844],
                        [-125.0078, 50.4939],
                        [-124.9927, 50.4983],
                        [-124.9827, 50.4979],
                        [-124.9697, 50.5005],
                        [-124.9654, 50.5072],
                        [-124.9503, 50.5167],
                        [-124.9354, 50.5344],
                        [-124.9441, 50.545],
                        [-124.9438, 50.5535],
                        [-124.9308, 50.5539],
                        [-124.9221, 50.5507],
                        [-124.9077, 50.5562],
                        [-124.8861, 50.5609],
                        [-124.8764, 50.5675],
                        [-124.874, 50.5818],
                        [-124.8678, 50.5908],
                        [-124.8589, 50.5917],
                        [-124.8596, 50.5986],
                        [-124.8753, 50.5999],
                        [-124.8679, 50.606],
                        [-124.867, 50.6174],
                        [-124.8749, 50.6249],
                        [-124.8739, 50.6288],
                        [-124.8606, 50.6419],
                        [-124.8608, 50.649],
                        [-124.8654, 50.6576],
                        [-124.8525, 50.6656],
                        [-124.8598, 50.6776],
                        [-124.8637, 50.689],
                        [-124.8479, 50.6971],
                        [-124.8406, 50.7079],
                        [-124.833, 50.7128],
                        [-124.8333, 50.735],
                        [-124.8476, 50.7468],
                        [-124.8607, 50.7519],
                        [-124.8648, 50.7642],
                        [-124.8857, 50.7696],
                        [-124.8954, 50.7696],
                        [-124.9025, 50.7748],
                        [-124.9123, 50.7862],
                        [-124.9115, 50.7942],
                        [-124.917, 50.8007],
                        [-124.9126, 50.8039],
                        [-124.8832, 50.8143],
                        [-124.8641, 50.8246],
                        [-124.852, 50.8401],
                        [-124.844, 50.8455],
                        [-124.8419, 50.8606],
                        [-124.8438, 50.8644],
                        [-124.8373, 50.8727],
                        [-124.8284, 50.8728],
                        [-124.8187, 50.8802],
                        [-124.8029, 50.8881],
                        [-124.7914, 50.8856],
                        [-124.7905, 50.8991],
                        [-124.7968, 50.9061],
                        [-124.7997, 50.9148],
                        [-124.806, 50.9174],
                        [-124.8248, 50.9314],
                        [-124.8543, 50.9303],
                        [-124.8565, 50.9271],
                        [-124.8515, 50.9128],
                        [-124.8541, 50.9013],
                        [-124.8652, 50.8949],
                        [-124.8713, 50.8884],
                        [-124.8819, 50.8859],
                        [-124.8947, 50.8718],
                        [-124.9001, 50.8601],
                        [-124.8964, 50.8559],
                        [-124.9002, 50.8497],
                        [-124.9095, 50.843],
                        [-124.9121, 50.8366],
                        [-124.9312, 50.8339],
                        [-124.9413, 50.8346],
                        [-124.9552, 50.8321],
                        [-124.957, 50.828],
                        [-124.971, 50.8222],
                        [-124.9825, 50.8095],
                        [-124.9818, 50.7973],
                        [-124.9635, 50.7872],
                        [-124.9444, 50.7837],
                        [-124.9511, 50.7757],
                        [-124.9514, 50.7613],
                        [-124.9481, 50.7553],
                        [-124.9155, 50.7464],
                        [-124.9031, 50.7366],
                        [-124.8896, 50.7318],
                        [-124.8876, 50.7256],
                        [-124.8976, 50.7221],
                        [-124.9157, 50.7035],
                        [-124.9184, 50.6954],
                        [-124.9131, 50.6918],
                        [-124.9121, 50.684],
                        [-124.9292, 50.6715],
                        [-124.9229, 50.6658],
                        [-124.9163, 50.6461],
                        [-124.8989, 50.6401],
                        [-124.8982, 50.6357],
                        [-124.9096, 50.6215],
                        [-124.9062, 50.6143],
                        [-124.9119, 50.6119],
                        [-124.9181, 50.6018],
                        [-124.9323, 50.5885],
                        [-124.9546, 50.588],
                        [-124.9625, 50.5826],
                        [-124.989, 50.5686],
                        [-124.996, 50.559],
                        [-124.9916, 50.5378],
                        [-125.0223, 50.5235],
                        [-125.0446, 50.516],
                        [-125.0576, 50.5142],
                        [-125.0659, 50.5104],
                        [-125.1079, 50.5004],
                        [-125.1062, 50.4894],
                        [-125.1128, 50.4824],
                        [-125.1059, 50.4714],
                        [-125.1106, 50.4606],
                        [-125.1033, 50.4549],
                        [-125.0976, 50.4455],
                        [-125.1138, 50.4305],
                        [-125.1445, 50.4199],
                        [-125.1598, 50.4203],
                        [-125.1684, 50.4091],
                        [-125.1829, 50.4077],
                        [-125.1944, 50.4135],
                        [-125.1972, 50.4188],
                        [-125.2077, 50.4223],
                        [-125.2243, 50.423],
                        [-125.2245, 50.441],
                        [-125.2349, 50.4484],
                        [-125.2508, 50.4532],
                        [-125.2578, 50.4579],
                        [-125.2696, 50.4597],
                        [-125.2562, 50.4697],
                        [-125.2509, 50.4845],
                        [-125.2451, 50.4883],
                        [-125.2472, 50.5014],
                        [-125.2566, 50.5058],
                        [-125.2632, 50.5002],
                        [-125.2639, 50.4927],
                        [-125.2729, 50.4907],
                        [-125.2859, 50.48],
                        [-125.2989, 50.4649],
                        [-125.2973, 50.4571],
                        [-125.3107, 50.4556],
                        [-125.3146, 50.4606],
                        [-125.3376, 50.4708],
                        [-125.3508, 50.482],
                        [-125.3531, 50.4915],
                        [-125.3579, 50.497],
                        [-125.3511, 50.5075],
                        [-125.3572, 50.5146],
                        [-125.3572, 50.535],
                        [-125.352, 50.537],
                        [-125.3594, 50.5461],
                        [-125.3636, 50.5573],
                        [-125.3747, 50.5503],
                        [-125.3714, 50.5447],
                        [-125.3745, 50.5326],
                        [-125.3858, 50.5267],
                        [-125.3872, 50.5194],
                        [-125.3735, 50.5054],
                        [-125.3802, 50.4868],
                        [-125.395, 50.474],
                        [-125.3968, 50.4683],
                        [-125.4045, 50.466],
                        [-125.4176, 50.458],
                        [-125.4252, 50.4576],
                        [-125.4361, 50.4491],
                        [-125.481, 50.4435],
                        [-125.4946, 50.4444],
                        [-125.5004, 50.4483],
                        [-125.5164, 50.4534],
                        [-125.5252, 50.4533],
                        [-125.5395, 50.4485],
                        [-125.5515, 50.4502],
                        [-125.5785, 50.4479],
                        [-125.5931, 50.4492],
                        [-125.59, 50.4602],
                        [-125.5781, 50.4681],
                        [-125.5766, 50.4795],
                        [-125.5688, 50.4848],
                        [-125.5662, 50.4917],
                        [-125.5516, 50.4989],
                        [-125.5504, 50.5078],
                        [-125.5444, 50.5129],
                        [-125.5474, 50.5206],
                        [-125.5289, 50.5346],
                        [-125.53, 50.5408],
                        [-125.5224, 50.5518],
                        [-125.5262, 50.5579],
                        [-125.5363, 50.5598],
                        [-125.5397, 50.5672],
                        [-125.5372, 50.5832],
                        [-125.5266, 50.5892],
                        [-125.5342, 50.6107],
                        [-125.5285, 50.6342],
                        [-125.5177, 50.6485],
                        [-125.5199, 50.6507],
                        [-125.5103, 50.6596],
                        [-125.5135, 50.6667],
                        [-125.507, 50.6689],
                        [-125.4852, 50.6669],
                        [-125.4769, 50.6703],
                        [-125.4585, 50.6738],
                        [-125.4538, 50.6777],
                        [-125.4499, 50.694],
                        [-125.4408, 50.7035],
                        [-125.4282, 50.7105],
                        [-125.4218, 50.7201],
                        [-125.4388, 50.7181],
                        [-125.4565, 50.7137],
                        [-125.4637, 50.7213],
                        [-125.4756, 50.7227],
                        [-125.4737, 50.7112],
                        [-125.4635, 50.6998],
                        [-125.4667, 50.6895],
                        [-125.4862, 50.6811],
                        [-125.5026, 50.6776],
                        [-125.5123, 50.6789],
                        [-125.5279, 50.6739],
                        [-125.5338, 50.6646],
                        [-125.5347, 50.655],
                        [-125.5401, 50.6484],
                        [-125.5548, 50.6373],
                        [-125.5587, 50.6282],
                        [-125.5574, 50.6078],
                        [-125.5528, 50.5954],
                        [-125.5679, 50.5818],
                        [-125.561, 50.5782],
                        [-125.5601, 50.5682],
                        [-125.5504, 50.5496],
                        [-125.5642, 50.5434],
                        [-125.5746, 50.529],
                        [-125.5918, 50.5225],
                        [-125.6066, 50.5142],
                        [-125.5899, 50.5119],
                        [-125.6105, 50.505],
                        [-125.6179, 50.5056],
                        [-125.6293, 50.4993],
                        [-125.6289, 50.4955],
                        [-125.6033, 50.5049],
                        [-125.5919, 50.5067],
                        [-125.5824, 50.5054],
                        [-125.6053, 50.4666],
                        [-125.6126, 50.4609],
                        [-125.6173, 50.4504],
                        [-125.6346, 50.4379],
                        [-125.6501, 50.4346],
                        [-125.657, 50.4287],
                        [-125.6668, 50.4284],
                        [-125.7036, 50.4221],
                        [-125.7104, 50.4228],
                        [-125.7195, 50.4319],
                        [-125.7366, 50.4409],
                        [-125.7503, 50.4514],
                        [-125.7545, 50.4576],
                        [-125.7623, 50.4615],
                        [-125.769, 50.4688],
                        [-125.7639, 50.4735],
                        [-125.7423, 50.4801],
                        [-125.7116, 50.4821],
                        [-125.7015, 50.4854],
                        [-125.6981, 50.4902],
                        [-125.7189, 50.4902],
                        [-125.7556, 50.4835],
                        [-125.7555, 50.4786],
                        [-125.7698, 50.4734],
                        [-125.7773, 50.4759],
                        [-125.7784, 50.4818],
                        [-125.7634, 50.4814],
                        [-125.7686, 50.4881],
                        [-125.7766, 50.4906],
                        [-125.7943, 50.4915],
                        [-125.8093, 50.4874],
                        [-125.8173, 50.49],
                        [-125.8162, 50.5004],
                        [-125.8076, 50.5051],
                        [-125.7971, 50.5063],
                        [-125.7698, 50.516],
                        [-125.7604, 50.5148],
                        [-125.7288, 50.5197],
                        [-125.7299, 50.526],
                        [-125.7539, 50.5263],
                        [-125.7685, 50.5321],
                        [-125.7783, 50.534],
                        [-125.7847, 50.5287],
                        [-125.7995, 50.5227],
                        [-125.8143, 50.5235],
                        [-125.8264, 50.521],
                        [-125.8293, 50.5142],
                        [-125.8395, 50.5086],
                        [-125.8426, 50.4998],
                        [-125.8488, 50.4955],
                        [-125.8617, 50.496],
                        [-125.886, 50.4909],
                        [-125.9044, 50.4795],
                        [-125.9321, 50.4712],
                        [-125.9471, 50.4698],
                        [-125.9552, 50.4722],
                        [-125.9643, 50.4691],
                        [-125.9766, 50.4702],
                        [-125.9934, 50.4755],
                        [-125.991, 50.4848],
                        [-125.9804, 50.4896],
                        [-125.9846, 50.494],
                        [-126.0056, 50.4931],
                        [-126.0154, 50.4847],
                        [-126.0287, 50.4814],
                        [-126.0361, 50.4742],
                        [-126.0496, 50.4748],
                        [-126.084, 50.485],
                        [-126.087, 50.4937],
                        [-126.0774, 50.5059],
                        [-126.0597, 50.5034],
                        [-126.05, 50.5092],
                        [-126.0395, 50.5103],
                        [-126.0365, 50.5151],
                        [-126.0213, 50.5202],
                        [-125.9946, 50.5188],
                        [-125.9804, 50.5206],
                        [-125.9889, 50.5258],
                        [-125.9855, 50.5322],
                        [-125.9768, 50.5331],
                        [-125.9671, 50.5408],
                        [-125.9487, 50.5438],
                        [-125.9393, 50.5429],
                        [-125.9373, 50.5477],
                        [-125.9449, 50.5532],
                        [-125.9434, 50.5583],
                        [-125.9713, 50.5474],
                        [-125.9771, 50.5399],
                        [-125.9916, 50.5384],
                        [-125.9973, 50.5328],
                        [-126.0106, 50.5338],
                        [-126.0214, 50.5323],
                        [-126.0368, 50.5242],
                        [-126.0685, 50.5197],
                        [-126.0741, 50.5143],
                        [-126.0888, 50.5059],
                        [-126.0952, 50.4932],
                        [-126.1316, 50.4833],
                        [-126.1398, 50.4856],
                        [-126.1555, 50.4856],
                        [-126.1686, 50.4922],
                        [-126.1945, 50.4966],
                        [-126.2033, 50.5007],
                        [-126.2147, 50.4986],
                        [-126.2288, 50.4999],
                        [-126.2396, 50.5032],
                        [-126.2418, 50.5082],
                        [-126.2548, 50.5069],
                        [-126.2709, 50.5077],
                        [-126.2818, 50.5149],
                        [-126.2872, 50.5224],
                        [-126.2844, 50.5273],
                        [-126.2564, 50.5263],
                        [-126.2353, 50.5326],
                        [-126.225, 50.5323],
                        [-126.2108, 50.5379],
                        [-126.195, 50.5344],
                        [-126.1817, 50.5205],
                        [-126.1727, 50.5231],
                        [-126.1787, 50.5365],
                        [-126.184, 50.537],
                        [-126.1899, 50.5505],
                        [-126.1864, 50.5618],
                        [-126.1904, 50.5691],
                        [-126.1722, 50.5757],
                        [-126.1504, 50.5795],
                        [-126.1429, 50.5873],
                        [-126.1261, 50.5931],
                        [-126.1108, 50.5906],
                        [-126.1028, 50.5981],
                        [-126.0706, 50.6004],
                        [-126.0597, 50.6039],
                        [-126.0528, 50.6102],
                        [-126.0369, 50.6091],
                        [-126.0315, 50.6124],
                        [-126.0047, 50.6111],
                        [-125.9997, 50.6192],
                        [-125.9589, 50.6225],
                        [-125.9476, 50.6277],
                        [-125.9383, 50.637],
                        [-125.9505, 50.6399],
                        [-125.9574, 50.6387],
                        [-125.9745, 50.6424],
                        [-125.9859, 50.6366],
                        [-126.0029, 50.6327],
                        [-126.0104, 50.6271],
                        [-126.0274, 50.6213],
                        [-126.0418, 50.619],
                        [-126.0467, 50.6207],
                        [-126.0774, 50.6173],
                        [-126.0962, 50.6113],
                        [-126.1045, 50.6064],
                        [-126.13, 50.598],
                        [-126.1477, 50.599],
                        [-126.1667, 50.589],
                        [-126.1828, 50.5847],
                        [-126.1895, 50.5802],
                        [-126.2026, 50.5813],
                        [-126.2059, 50.586],
                        [-126.2227, 50.5846],
                        [-126.2331, 50.5886],
                        [-126.24, 50.5837],
                        [-126.2623, 50.5923],
                        [-126.2704, 50.6023],
                        [-126.2691, 50.6082],
                        [-126.2732, 50.6148],
                        [-126.2635, 50.6162],
                        [-126.2407, 50.6233],
                        [-126.2466, 50.6269],
                        [-126.2701, 50.6196],
                        [-126.2848, 50.6212],
                        [-126.2973, 50.6256],
                        [-126.2941, 50.632],
                        [-126.2751, 50.6303],
                        [-126.2631, 50.6327],
                        [-126.2314, 50.6361],
                        [-126.213, 50.6404],
                        [-126.2095, 50.6374],
                        [-126.1927, 50.6437],
                        [-126.1751, 50.6476],
                        [-126.1643, 50.6436],
                        [-126.1632, 50.6398],
                        [-126.1056, 50.6446],
                        [-126.1014, 50.6479],
                        [-126.0696, 50.6579],
                        [-126.0422, 50.6613],
                        [-126.0294, 50.6599],
                        [-126.0032, 50.6678],
                        [-125.9832, 50.6644],
                        [-125.9581, 50.6665],
                        [-125.9286, 50.6656],
                        [-125.9071, 50.6673],
                        [-125.887, 50.6643],
                        [-125.8654, 50.6682],
                        [-125.8579, 50.6712],
                        [-125.8507, 50.6785],
                        [-125.833, 50.6816],
                        [-125.8145, 50.6807],
                        [-125.7947, 50.6824],
                        [-125.7889, 50.678],
                        [-125.767, 50.6748],
                        [-125.7572, 50.6805],
                        [-125.7605, 50.6873],
                        [-125.739, 50.6882],
                        [-125.737, 50.6805],
                        [-125.7376, 50.6639],
                        [-125.7279, 50.6725],
                        [-125.7244, 50.6828],
                        [-125.7168, 50.6856],
                        [-125.7017, 50.6873],
                        [-125.6927, 50.6957],
                        [-125.6941, 50.7006],
                        [-125.6824, 50.71],
                        [-125.6783, 50.7269],
                        [-125.6843, 50.7323],
                        [-125.6827, 50.7399],
                        [-125.6745, 50.7377],
                        [-125.6657, 50.7505],
                        [-125.6559, 50.7527],
                        [-125.6388, 50.751],
                        [-125.6297, 50.7573],
                        [-125.6159, 50.7589],
                        [-125.613, 50.7632],
                        [-125.6126, 50.776],
                        [-125.6008, 50.7839],
                        [-125.5911, 50.7955],
                        [-125.5997, 50.8025],
                        [-125.6098, 50.8149],
                        [-125.634, 50.8247],
                        [-125.6452, 50.8396],
                        [-125.6433, 50.8463],
                        [-125.647, 50.852],
                        [-125.6422, 50.8589],
                        [-125.6321, 50.8642],
                        [-125.6225, 50.8645],
                        [-125.6199, 50.8734],
                        [-125.6142, 50.8747],
                        [-125.601, 50.8695],
                        [-125.5809, 50.8695],
                        [-125.5634, 50.8649],
                        [-125.5526, 50.8677],
                        [-125.5375, 50.878],
                        [-125.532, 50.8902],
                        [-125.5358, 50.895],
                        [-125.5309, 50.9016],
                        [-125.53, 50.9092],
                        [-125.5158, 50.9262],
                        [-125.5043, 50.9319],
                        [-125.5012, 50.9427],
                        [-125.5051, 50.9552],
                        [-125.5204, 50.9691],
                        [-125.5361, 50.9736],
                        [-125.5267, 50.9846],
                        [-125.5314, 50.9961],
                        [-125.5413, 51.0019],
                        [-125.5441, 51.0129],
                        [-125.5444, 51.0267],
                        [-125.5384, 51.0436],
                        [-125.543, 51.0567],
                        [-125.5521, 51.0649],
                        [-125.5647, 51.0689],
                        [-125.5751, 51.0773],
                        [-125.5892, 51.082],
                        [-125.5869, 51.0945],
                        [-125.6121, 51.0938],
                        [-125.6401, 51.0901],
                        [-125.6344, 51.0665],
                        [-125.6143, 51.0539],
                        [-125.6078, 51.0518],
                        [-125.5908, 51.0363],
                        [-125.5893, 51.0291],
                        [-125.5931, 51.0252],
                        [-125.6027, 51.0258],
                        [-125.6089, 51.0209],
                        [-125.5921, 51.0156],
                        [-125.5932, 51.0053],
                        [-125.5784, 50.9864],
                        [-125.59, 50.9729],
                        [-125.5915, 50.9657],
                        [-125.5802, 50.957],
                        [-125.5686, 50.9521],
                        [-125.5575, 50.9532],
                        [-125.5499, 50.9497],
                        [-125.5448, 50.9373],
                        [-125.5532, 50.9233],
                        [-125.5681, 50.9162],
                        [-125.5651, 50.9058],
                        [-125.571, 50.9024],
                        [-125.5934, 50.9022],
                        [-125.604, 50.9082],
                        [-125.6396, 50.9001],
                        [-125.6367, 50.8864],
                        [-125.6544, 50.8834],
                        [-125.6633, 50.8799],
                        [-125.6849, 50.875],
                        [-125.6788, 50.8673],
                        [-125.685, 50.8596],
                        [-125.688, 50.851],
                        [-125.6824, 50.8367],
                        [-125.6815, 50.8178],
                        [-125.6662, 50.8118],
                        [-125.6535, 50.8097],
                        [-125.6444, 50.7942],
                        [-125.6434, 50.7876],
                        [-125.65, 50.7815],
                        [-125.6519, 50.7737],
                        [-125.6662, 50.7766],
                        [-125.6741, 50.7759],
                        [-125.6832, 50.7804],
                        [-125.6915, 50.7764],
                        [-125.6944, 50.7689],
                        [-125.7051, 50.7608],
                        [-125.7032, 50.7533],
                        [-125.7223, 50.7454],
                        [-125.7138, 50.7344],
                        [-125.7106, 50.7175],
                        [-125.7186, 50.7156],
                        [-125.7314, 50.7074],
                        [-125.7564, 50.7096],
                        [-125.777, 50.7056],
                        [-125.8042, 50.7023],
                        [-125.8349, 50.7017],
                        [-125.8514, 50.7024],
                        [-125.8661, 50.6982],
                        [-125.8806, 50.6963],
                        [-125.8911, 50.6928],
                        [-125.9175, 50.6889],
                        [-125.958, 50.6852],
                        [-125.9782, 50.6897],
                        [-125.9776, 50.6934],
                        [-125.9583, 50.6996],
                        [-125.9551, 50.7051],
                        [-125.9702, 50.7038],
                        [-125.9867, 50.6979],
                        [-126.0013, 50.6965],
                        [-126.0139, 50.7031],
                        [-126.022, 50.6961],
                        [-126.0412, 50.6891],
                        [-126.056, 50.688],
                        [-126.0675, 50.6811],
                        [-126.0799, 50.6801],
                        [-126.0934, 50.6754],
                        [-126.1138, 50.6737],
                        [-126.1236, 50.6708],
                        [-126.1416, 50.6704],
                        [-126.1511, 50.6725],
                        [-126.1569, 50.6693],
                        [-126.1778, 50.6694],
                        [-126.1976, 50.689],
                        [-126.1975, 50.6971],
                        [-126.1931, 50.7131],
                        [-126.1885, 50.7169],
                        [-126.1679, 50.7214],
                        [-126.1572, 50.7288],
                        [-126.1429, 50.7286],
                        [-126.1286, 50.7342],
                        [-126.1243, 50.7431],
                        [-126.114, 50.7471],
                        [-126.1108, 50.755],
                        [-126.0952, 50.7591],
                        [-126.0815, 50.7653],
                        [-126.0702, 50.7733],
                        [-126.0494, 50.776],
                        [-126.0383, 50.7798],
                        [-126.0229, 50.7818],
                        [-126.0087, 50.7932],
                        [-126.0168, 50.8025],
                        [-126.0242, 50.8067],
                        [-126.036, 50.7931],
                        [-126.0583, 50.7907],
                        [-126.0882, 50.781],
                        [-126.1124, 50.7784],
                        [-126.1192, 50.7691],
                        [-126.1272, 50.7711],
                        [-126.1389, 50.7695],
                        [-126.1522, 50.7756],
                        [-126.1874, 50.782],
                        [-126.2039, 50.8105],
                        [-126.1898, 50.8245],
                        [-126.1764, 50.8318],
                        [-126.1677, 50.8408],
                        [-126.1663, 50.849],
                        [-126.1698, 50.8559],
                        [-126.164, 50.8664],
                        [-126.1846, 50.876],
                        [-126.1903, 50.8622],
                        [-126.1946, 50.8577],
                        [-126.1903, 50.8478],
                        [-126.1951, 50.842],
                        [-126.2127, 50.8339],
                        [-126.2332, 50.8342],
                        [-126.2512, 50.833],
                        [-126.261, 50.8443],
                        [-126.2547, 50.8487],
                        [-126.2501, 50.8577],
                        [-126.2352, 50.8662],
                        [-126.2364, 50.8698],
                        [-126.2526, 50.8676],
                        [-126.2525, 50.8628],
                        [-126.2641, 50.861],
                        [-126.2797, 50.8462],
                        [-126.3066, 50.8436],
                        [-126.3143, 50.8479],
                        [-126.3277, 50.8507],
                        [-126.3497, 50.8418],
                        [-126.3719, 50.8376],
                        [-126.3744, 50.8326],
                        [-126.3872, 50.8293],
                        [-126.4043, 50.8282],
                        [-126.431, 50.8231],
                        [-126.4442, 50.8176],
                        [-126.4514, 50.819],
                        [-126.4755, 50.8138],
                        [-126.5058, 50.8174],
                        [-126.5, 50.8267],
                        [-126.4942, 50.8258],
                        [-126.4853, 50.8329],
                        [-126.4865, 50.836],
                        [-126.4668, 50.848],
                        [-126.4712, 50.8526],
                        [-126.4833, 50.8524],
                        [-126.5009, 50.8616],
                        [-126.5123, 50.8645],
                        [-126.5242, 50.8619],
                        [-126.5255, 50.8554],
                        [-126.532, 50.8526],
                        [-126.5508, 50.8556],
                        [-126.5507, 50.8504],
                        [-126.5424, 50.8476],
                        [-126.5098, 50.8517],
                        [-126.4872, 50.8458],
                        [-126.4934, 50.8359],
                        [-126.5029, 50.8361],
                        [-126.509, 50.8294],
                        [-126.5551, 50.8308],
                        [-126.5665, 50.8336],
                        [-126.5732, 50.8423],
                        [-126.5812, 50.8452],
                        [-126.5747, 50.8511],
                        [-126.5627, 50.8436],
                        [-126.5615, 50.8508],
                        [-126.5548, 50.8591],
                        [-126.562, 50.8623],
                        [-126.5554, 50.8683],
                        [-126.5391, 50.8719],
                        [-126.5436, 50.8761],
                        [-126.539, 50.8818],
                        [-126.5504, 50.8846],
                        [-126.5523, 50.8934],
                        [-126.5485, 50.8977],
                        [-126.5298, 50.9055],
                        [-126.517, 50.9043],
                        [-126.5086, 50.9075],
                        [-126.4986, 50.9235],
                        [-126.4869, 50.926],
                        [-126.4636, 50.9235],
                        [-126.4447, 50.9147],
                        [-126.4399, 50.9094],
                        [-126.4264, 50.9076],
                        [-126.4152, 50.8963],
                        [-126.4279, 50.8879],
                        [-126.4337, 50.8875],
                        [-126.4504, 50.8802],
                        [-126.4771, 50.8769],
                        [-126.4703, 50.8733],
                        [-126.4565, 50.8742],
                        [-126.4451, 50.8774],
                        [-126.4364, 50.8764],
                        [-126.412, 50.8846],
                        [-126.4032, 50.8903],
                        [-126.3998, 50.9005],
                        [-126.4032, 50.9041],
                        [-126.4144, 50.902],
                        [-126.417, 50.9105],
                        [-126.4045, 50.9161],
                        [-126.3896, 50.9183],
                        [-126.3756, 50.9164],
                        [-126.3624, 50.917],
                        [-126.3519, 50.9119],
                        [-126.325, 50.9133],
                        [-126.3113, 50.9171],
                        [-126.2856, 50.9136],
                        [-126.2718, 50.9084],
                        [-126.2613, 50.9087],
                        [-126.2547, 50.9057],
                        [-126.2434, 50.9075],
                        [-126.2158, 50.9044],
                        [-126.2121, 50.9011],
                        [-126.1975, 50.9052],
                        [-126.1952, 50.9163],
                        [-126.1866, 50.9267],
                        [-126.1992, 50.9357],
                        [-126.2114, 50.9331],
                        [-126.2258, 50.9249],
                        [-126.2754, 50.9265],
                        [-126.284, 50.9308],
                        [-126.3093, 50.9339],
                        [-126.3338, 50.9284],
                        [-126.3505, 50.9299],
                        [-126.3577, 50.9359],
                        [-126.367, 50.9358],
                        [-126.3784, 50.9321],
                        [-126.3906, 50.9317],
                        [-126.4036, 50.9371],
                        [-126.4144, 50.9385],
                        [-126.4321, 50.9446],
                        [-126.4448, 50.9464],
                        [-126.4611, 50.944],
                        [-126.4715, 50.9507],
                        [-126.4735, 50.959],
                        [-126.4831, 50.9616],
                        [-126.4924, 50.9764],
                        [-126.4883, 50.9835],
                        [-126.4926, 50.9896],
                        [-126.503, 50.9945],
                        [-126.5087, 51.007],
                        [-126.5044, 51.0179],
                        [-126.5157, 51.0379],
                        [-126.5333, 51.0372],
                        [-126.5479, 51.0118],
                        [-126.5499, 51.0031],
                        [-126.5429, 50.9989],
                        [-126.5378, 50.9817],
                        [-126.5334, 50.979],
                        [-126.5133, 50.9564],
                        [-126.5116, 50.9414],
                        [-126.5336, 50.9355],
                        [-126.5435, 50.9283],
                        [-126.5577, 50.9213],
                        [-126.5602, 50.9107],
                        [-126.5752, 50.9054],
                        [-126.5845, 50.9046],
                        [-126.6072, 50.8936],
                        [-126.6194, 50.891],
                        [-126.6255, 50.886],
                        [-126.6554, 50.8833],
                        [-126.6568, 50.8734],
                        [-126.6661, 50.8665],
                        [-126.686, 50.8726],
                        [-126.7061, 50.8744],
                        [-126.7162, 50.8726],
                        [-126.7346, 50.8808],
                        [-126.7429, 50.8891],
                        [-126.7541, 50.8935],
                        [-126.758, 50.8992],
                        [-126.7768, 50.9017],
                        [-126.7813, 50.9058],
                        [-126.7975, 50.9044],
                        [-126.8036, 50.9076],
                        [-126.8137, 50.9048],
                        [-126.8127, 50.9003],
                        [-126.8355, 50.908],
                        [-126.8395, 50.9141],
                        [-126.8169, 50.9171],
                        [-126.7993, 50.9213],
                        [-126.7842, 50.9129],
                        [-126.7823, 50.9238],
                        [-126.7695, 50.927],
                        [-126.7579, 50.9272],
                        [-126.7374, 50.9302],
                        [-126.7283, 50.929],
                        [-126.6882, 50.9288],
                        [-126.6835, 50.9333],
                        [-126.6866, 50.9382],
                        [-126.7023, 50.9461],
                        [-126.7102, 50.952],
                        [-126.7165, 50.9481],
                        [-126.6982, 50.9408],
                        [-126.7067, 50.9333],
                        [-126.7145, 50.9314],
                        [-126.7248, 50.9396],
                        [-126.7413, 50.9416],
                        [-126.7609, 50.9353],
                        [-126.7809, 50.9353],
                        [-126.7976, 50.9468],
                        [-126.808, 50.9485],
                        [-126.8127, 50.9563],
                        [-126.8288, 50.9586],
                        [-126.8329, 50.9644],
                        [-126.8401, 50.9644],
                        [-126.8419, 50.9563],
                        [-126.8295, 50.9546],
                        [-126.8362, 50.9483],
                        [-126.8525, 50.947],
                        [-126.8552, 50.9554],
                        [-126.8645, 50.9581],
                        [-126.8672, 50.9441],
                        [-126.8728, 50.9296],
                        [-126.8885, 50.9303],
                        [-126.8916, 50.934],
                        [-126.904, 50.9342],
                        [-126.8852, 50.9235],
                        [-126.901, 50.9124],
                        [-126.9069, 50.9134],
                        [-126.9128, 50.9057],
                        [-126.896, 50.9082],
                        [-126.8887, 50.9047],
                        [-126.8895, 50.8991],
                        [-126.9088, 50.8948],
                        [-126.93, 50.904],
                        [-126.9405, 50.898],
                        [-126.9874, 50.8979],
                        [-126.9995, 50.9054],
                        [-127.0193, 50.9036],
                        [-127.022, 50.9106],
                        [-127.0292, 50.9168],
                        [-127.0361, 50.9093],
                        [-127.0518, 50.9068],
                        [-127.0721, 50.9171],
                        [-127.0844, 50.9218],
                        [-127.0957, 50.9162],
                        [-127.1096, 50.917],
                        [-127.117, 50.9231],
                        [-127.1364, 50.9312],
                        [-127.1407, 50.9361],
                        [-127.17, 50.9323],
                        [-127.1727, 50.9282],
                        [-127.1892, 50.9301],
                        [-127.1904, 50.9214],
                        [-127.1662, 50.9236],
                        [-127.1508, 50.9208],
                        [-127.1451, 50.9108],
                        [-127.1369, 50.9111],
                        [-127.1194, 50.9059],
                        [-127.1083, 50.9053],
                        [-127.0845, 50.9003],
                        [-127.0673, 50.894],
                        [-127.0462, 50.8886],
                        [-127.0281, 50.8868],
                        [-127.0079, 50.891],
                        [-127.0016, 50.8869],
                        [-126.9913, 50.891],
                        [-126.9825, 50.8909],
                        [-126.9801, 50.8829],
                        [-126.9688, 50.8872],
                        [-126.9639, 50.8934],
                        [-126.9547, 50.8956],
                        [-126.94, 50.8917],
                        [-126.9342, 50.8954],
                        [-126.911, 50.8932],
                        [-126.896, 50.8845],
                        [-126.9075, 50.8788],
                        [-126.9072, 50.8738],
                        [-126.9443, 50.8504],
                        [-126.9507, 50.8504],
                        [-126.9578, 50.845],
                        [-126.9722, 50.8441],
                        [-126.9832, 50.8382],
                        [-127.0096, 50.8339],
                        [-127.0055, 50.8276],
                        [-127.0198, 50.8175],
                        [-127.0356, 50.8189],
                        [-127.0378, 50.8223],
                        [-127.0537, 50.8201],
                        [-127.0633, 50.8216],
                        [-127.0651, 50.83],
                        [-127.0847, 50.8404],
                        [-127.0884, 50.8449],
                        [-127.0999, 50.8496],
                        [-127.1082, 50.8502],
                        [-127.123, 50.8553],
                        [-127.1394, 50.8584],
                        [-127.1598, 50.873],
                        [-127.1805, 50.8724],
                        [-127.1861, 50.8758],
                        [-127.1973, 50.876],
                        [-127.2049, 50.8831],
                        [-127.2107, 50.8823],
                        [-127.2273, 50.8909],
                        [-127.2651, 50.9087],
                        [-127.274, 50.9158],
                        [-127.2822, 50.9109],
                        [-127.2963, 50.9104],
                        [-127.2967, 50.9019],
                        [-127.307, 50.9031],
                        [-127.3139, 50.9072],
                        [-127.3263, 50.9031],
                        [-127.3365, 50.9055],
                        [-127.3449, 50.922],
                        [-127.3617, 50.9264],
                        [-127.3744, 50.9216],
                        [-127.408, 50.927],
                        [-127.4029, 50.9322],
                        [-127.4262, 50.9391],
                        [-127.4326, 50.9467],
                        [-127.4444, 50.9502],
                        [-127.4518, 50.9632],
                        [-127.4356, 50.9609],
                        [-127.4221, 50.9616],
                        [-127.4212, 50.9694],
                        [-127.4339, 50.9702],
                        [-127.4538, 50.9751],
                        [-127.4682, 50.9754],
                        [-127.5034, 50.9872],
                        [-127.512, 50.9928],
                        [-127.5249, 51.0138],
                        [-127.5189, 51.0341],
                        [-127.5234, 51.0441],
                        [-127.5169, 51.0596],
                        [-127.5201, 51.0629],
                        [-127.5156, 51.0784],
                        [-127.5063, 51.0852],
                        [-127.4975, 51.0838],
                        [-127.4946, 51.0747],
                        [-127.4882, 51.0756],
                        [-127.497, 51.0846],
                        [-127.5092, 51.0876],
                        [-127.4986, 51.0985],
                        [-127.4882, 51.0949],
                        [-127.4572, 51.0787],
                        [-127.4431, 51.0732],
                        [-127.4407, 51.0698],
                        [-127.4255, 51.0666],
                        [-127.4125, 51.0618],
                        [-127.4068, 51.052],
                        [-127.4002, 51.0463],
                        [-127.3823, 51.0423],
                        [-127.3632, 51.042],
                        [-127.3544, 51.0379],
                        [-127.3327, 51.0326],
                        [-127.3182, 51.0318],
                        [-127.2962, 51.0167],
                        [-127.2892, 51.0049],
                        [-127.2585, 51.0019],
                        [-127.2725, 50.9955],
                        [-127.2442, 50.9933],
                        [-127.2176, 51.0011],
                        [-127.1929, 51.0046],
                        [-127.1853, 51.0097],
                        [-127.1942, 51.0208],
                        [-127.2013, 51.0191],
                        [-127.2005, 51.0103],
                        [-127.2148, 51.0031],
                        [-127.2308, 51.002],
                        [-127.2355, 50.9994],
                        [-127.2575, 51.0024],
                        [-127.2664, 51.0079],
                        [-127.2887, 51.0145],
                        [-127.2932, 51.0203],
                        [-127.3056, 51.0265],
                        [-127.3035, 51.0336],
                        [-127.3071, 51.0377],
                        [-127.3035, 51.0437],
                        [-127.2631, 51.041],
                        [-127.2478, 51.0411],
                        [-127.2466, 51.0362],
                        [-127.2343, 51.0325],
                        [-127.2259, 51.0329],
                        [-127.2195, 51.0402],
                        [-127.2135, 51.039],
                        [-127.1826, 51.0407],
                        [-127.169, 51.0374],
                        [-127.1517, 51.0393],
                        [-127.1424, 51.038],
                        [-127.1354, 51.0437],
                        [-127.1144, 51.0451],
                        [-127.1065, 51.0477],
                        [-127.0873, 51.0468],
                        [-127.0686, 51.0475],
                        [-127.0598, 51.0527],
                        [-127.0455, 51.0534],
                        [-127.0386, 51.0576],
                        [-127.017, 51.0599],
                        [-127.0044, 51.0578],
                        [-126.9688, 51.0559],
                        [-126.9487, 51.0593],
                        [-126.9289, 51.0585],
                        [-126.91, 51.0612],
                        [-126.9024, 51.064],
                        [-126.8907, 51.0586],
                        [-126.883, 51.0611],
                        [-126.8564, 51.0646],
                        [-126.8421, 51.0615],
                        [-126.8342, 51.0629],
                        [-126.7952, 51.0654],
                        [-126.7752, 51.0677],
                        [-126.7743, 51.0762],
                        [-126.7693, 51.0818],
                        [-126.7507, 51.0896],
                        [-126.7478, 51.093],
                        [-126.731, 51.099],
                        [-126.7176, 51.1107],
                        [-126.7157, 51.1202],
                        [-126.7004, 51.1214],
                        [-126.6792, 51.1334],
                        [-126.6704, 51.1337],
                        [-126.6587, 51.142],
                        [-126.6583, 51.1477],
                        [-126.6515, 51.155],
                        [-126.6511, 51.1672],
                        [-126.6457, 51.175],
                        [-126.6566, 51.1966],
                        [-126.6673, 51.1959],
                        [-126.6743, 51.1918],
                        [-126.6686, 51.1859],
                        [-126.6698, 51.1673],
                        [-126.6748, 51.1543],
                        [-126.6839, 51.1516],
                        [-126.6913, 51.1453],
                        [-126.7035, 51.1392],
                        [-126.711, 51.1294],
                        [-126.7207, 51.1342],
                        [-126.7316, 51.1262],
                        [-126.7331, 51.1134],
                        [-126.7541, 51.1157],
                        [-126.76, 51.1083],
                        [-126.7667, 51.1063],
                        [-126.7748, 51.0967],
                        [-126.7925, 51.0818],
                        [-126.8281, 51.0758],
                        [-126.8512, 51.0803],
                        [-126.8534, 51.0827],
                        [-126.874, 51.0851],
                        [-126.9084, 51.0816],
                        [-126.9221, 51.0831],
                        [-126.947, 51.0755],
                        [-126.9709, 51.0783],
                        [-126.9866, 51.0818],
                        [-126.999, 51.0806],
                        [-127.015, 51.0839],
                        [-127.0489, 51.0719],
                        [-127.0638, 51.0714],
                        [-127.0731, 51.0667],
                        [-127.084, 51.0668],
                        [-127.1011, 51.0609],
                        [-127.1226, 51.0635],
                        [-127.1422, 51.0597],
                        [-127.1558, 51.0532],
                        [-127.1813, 51.0565],
                        [-127.239, 51.0536],
                        [-127.282, 51.0557],
                        [-127.3106, 51.0589],
                        [-127.3194, 51.0534],
                        [-127.3336, 51.0542],
                        [-127.3406, 51.0498],
                        [-127.332, 51.0441],
                        [-127.339, 51.0381],
                        [-127.3631, 51.05],
                        [-127.3773, 51.0547],
                        [-127.3713, 51.0616],
                        [-127.4042, 51.066],
                        [-127.4172, 51.0693],
                        [-127.4565, 51.0832],
                        [-127.4613, 51.091],
                        [-127.4318, 51.0874],
                        [-127.428, 51.0889],
                        [-127.4059, 51.0835],
                        [-127.3875, 51.0833],
                        [-127.3851, 51.0864],
                        [-127.3621, 51.0871],
                        [-127.3362, 51.0832],
                        [-127.3262, 51.0893],
                        [-127.3069, 51.0855],
                        [-127.289, 51.0865],
                        [-127.261, 51.0821],
                        [-127.2565, 51.085],
                        [-127.2372, 51.0853],
                        [-127.2208, 51.083],
                        [-127.2105, 51.0848],
                        [-127.2, 51.0833],
                        [-127.194, 51.093],
                        [-127.2066, 51.0907],
                        [-127.2193, 51.0926],
                        [-127.2414, 51.0886],
                        [-127.2625, 51.0916],
                        [-127.2879, 51.0911],
                        [-127.2962, 51.0937],
                        [-127.319, 51.0904],
                        [-127.3296, 51.0927],
                        [-127.3545, 51.0941],
                        [-127.3673, 51.0896],
                        [-127.386, 51.0938],
                        [-127.3971, 51.0902],
                        [-127.4018, 51.0929],
                        [-127.4267, 51.0935],
                        [-127.454, 51.093],
                        [-127.4719, 51.0976],
                        [-127.5044, 51.1078],
                        [-127.5202, 51.0936],
                        [-127.5334, 51.0972],
                        [-127.5412, 51.103],
                        [-127.5567, 51.0946],
                        [-127.5731, 51.0913],
                        [-127.5765, 51.0954],
                        [-127.5893, 51.0912],
                        [-127.6033, 51.0898],
                        [-127.6135, 51.0916],
                        [-127.6351, 51.0878],
                        [-127.6489, 51.0896],
                        [-127.6572, 51.087],
                        [-127.6699, 51.092],
                        [-127.6642, 51.0961],
                        [-127.6703, 51.1042],
                        [-127.6693, 51.1122],
                        [-127.6736, 51.122],
                        [-127.6831, 51.1333],
                        [-127.6935, 51.1362],
                        [-127.6982, 51.1415],
                        [-127.7248, 51.137],
                        [-127.7296, 51.1449],
                        [-127.7438, 51.1449],
                        [-127.7426, 51.1504],
                        [-127.7537, 51.1619],
                        [-127.7713, 51.1586],
                        [-127.7874, 51.164],
                        [-127.7866, 51.1714],
                        [-127.774, 51.1765],
                        [-127.772, 51.1845],
                        [-127.7815, 51.1901],
                        [-127.7881, 51.1981],
                        [-127.781, 51.2047],
                        [-127.7842, 51.2085],
                        [-127.7721, 51.2138],
                        [-127.7728, 51.2199],
                        [-127.7845, 51.2277],
                        [-127.7808, 51.2361],
                        [-127.765, 51.2493],
                        [-127.7485, 51.2523],
                        [-127.722, 51.2601],
                        [-127.7116, 51.2556],
                        [-127.6945, 51.2653],
                        [-127.6839, 51.2662],
                        [-127.677, 51.2724],
                        [-127.6626, 51.274],
                        [-127.6538, 51.2772],
                        [-127.6349, 51.2795],
                        [-127.6154, 51.2801],
                        [-127.6082, 51.2756],
                        [-127.602, 51.2838],
                        [-127.5767, 51.2821],
                        [-127.583, 51.2739],
                        [-127.5935, 51.2709],
                        [-127.5808, 51.262],
                        [-127.5584, 51.2649],
                        [-127.5193, 51.2652],
                        [-127.4949, 51.2672],
                        [-127.4691, 51.2649],
                        [-127.4634, 51.2695],
                        [-127.4426, 51.2718],
                        [-127.4316, 51.269],
                        [-127.4235, 51.2751],
                        [-127.4361, 51.2769],
                        [-127.4631, 51.2767],
                        [-127.4544, 51.2829],
                        [-127.4363, 51.2853],
                        [-127.4082, 51.2847],
                        [-127.3921, 51.2874],
                        [-127.3789, 51.2858],
                        [-127.3604, 51.2905],
                        [-127.3251, 51.2904],
                        [-127.2853, 51.2948],
                        [-127.2662, 51.2995],
                        [-127.246, 51.3017],
                        [-127.2352, 51.3055],
                        [-127.2051, 51.3082],
                        [-127.1945, 51.3148],
                        [-127.179, 51.3183],
                        [-127.1543, 51.3211],
                        [-127.1391, 51.3291],
                        [-127.1276, 51.3327],
                        [-127.1286, 51.3409],
                        [-127.1438, 51.3322],
                        [-127.1551, 51.3291],
                        [-127.1676, 51.3307],
                        [-127.1483, 51.3408],
                        [-127.1466, 51.3554],
                        [-127.1357, 51.3605],
                        [-127.1237, 51.3607],
                        [-127.1184, 51.3534],
                        [-127.1063, 51.3537],
                        [-127.0777, 51.3485],
                        [-127.0736, 51.3536],
                        [-127.1123, 51.3592],
                        [-127.1073, 51.3833],
                        [-127.1246, 51.3915],
                        [-127.1222, 51.3784],
                        [-127.1247, 51.3732],
                        [-127.1338, 51.3682],
                        [-127.1568, 51.3619],
                        [-127.1575, 51.3502],
                        [-127.1647, 51.343],
                        [-127.1855, 51.3389],
                        [-127.1873, 51.3307],
                        [-127.1926, 51.3248],
                        [-127.2158, 51.3175],
                        [-127.2404, 51.3143],
                        [-127.257, 51.3104],
                        [-127.2743, 51.3096],
                        [-127.2917, 51.3047],
                        [-127.3113, 51.3036],
                        [-127.3257, 51.3002],
                        [-127.3425, 51.3047],
                        [-127.3602, 51.3016],
                        [-127.3683, 51.3073],
                        [-127.3809, 51.3079],
                        [-127.3967, 51.3129],
                        [-127.4273, 51.3128],
                        [-127.4399, 51.3167],
                        [-127.4738, 51.3134],
                        [-127.4943, 51.3154],
                        [-127.5059, 51.3206],
                        [-127.5252, 51.3211],
                        [-127.5309, 51.3234],
                        [-127.514, 51.3348],
                        [-127.5245, 51.3424],
                        [-127.4674, 51.3535],
                        [-127.4525, 51.3605],
                        [-127.4318, 51.3627],
                        [-127.4301, 51.3704],
                        [-127.4175, 51.3744],
                        [-127.4173, 51.3796],
                        [-127.3994, 51.3816],
                        [-127.3783, 51.3881],
                        [-127.3699, 51.3939],
                        [-127.3552, 51.4004],
                        [-127.362, 51.4065],
                        [-127.3683, 51.4007],
                        [-127.3855, 51.3946],
                        [-127.3936, 51.3873],
                        [-127.4135, 51.3845],
                        [-127.434, 51.3788],
                        [-127.4285, 51.3753],
                        [-127.4402, 51.367],
                        [-127.4617, 51.3674],
                        [-127.474, 51.3705],
                        [-127.4958, 51.3614],
                        [-127.5043, 51.3595],
                        [-127.5142, 51.3528],
                        [-127.5305, 51.348],
                        [-127.5632, 51.3416],
                        [-127.5709, 51.3384],
                        [-127.578, 51.3424],
                        [-127.5911, 51.3389],
                        [-127.6126, 51.3378],
                        [-127.6181, 51.3397],
                        [-127.6334, 51.3341],
                        [-127.6533, 51.3331],
                        [-127.6619, 51.3405],
                        [-127.6787, 51.3406],
                        [-127.6888, 51.3373],
                        [-127.6991, 51.3262],
                        [-127.7074, 51.3266],
                        [-127.7244, 51.3221],
                        [-127.748, 51.3189],
                        [-127.7551, 51.3201],
                        [-127.7703, 51.327],
                        [-127.7828, 51.339],
                        [-127.7923, 51.3568],
                        [-127.7814, 51.3678],
                        [-127.7678, 51.3661],
                        [-127.7547, 51.3696],
                        [-127.737, 51.3858],
                        [-127.7376, 51.3932],
                        [-127.7202, 51.4012],
                        [-127.7025, 51.3972],
                        [-127.6939, 51.4033],
                        [-127.6887, 51.4002],
                        [-127.6682, 51.3977],
                        [-127.6644, 51.39],
                        [-127.6552, 51.3948],
                        [-127.6447, 51.4067],
                        [-127.6452, 51.4096],
                        [-127.6342, 51.4227],
                        [-127.6339, 51.4304],
                        [-127.6224, 51.4379],
                        [-127.598, 51.4491],
                        [-127.5963, 51.4562],
                        [-127.5843, 51.467],
                        [-127.5657, 51.4729],
                        [-127.5528, 51.4714],
                        [-127.5418, 51.4591],
                        [-127.5465, 51.455],
                        [-127.5299, 51.4489],
                        [-127.5156, 51.4477],
                        [-127.4911, 51.4431],
                        [-127.4667, 51.4369],
                        [-127.448, 51.4364],
                        [-127.4387, 51.4378],
                        [-127.414, 51.4354],
                        [-127.4044, 51.4376],
                        [-127.3789, 51.4352],
                        [-127.3667, 51.4323],
                        [-127.3318, 51.4308],
                        [-127.3152, 51.425],
                        [-127.3145, 51.4063],
                        [-127.3011, 51.4005],
                        [-127.2935, 51.406],
                        [-127.3071, 51.4078],
                        [-127.301, 51.4143],
                        [-127.3044, 51.4298],
                        [-127.3182, 51.4418],
                        [-127.3233, 51.4399],
                        [-127.3444, 51.4415],
                        [-127.3582, 51.4399],
                        [-127.3806, 51.4401],
                        [-127.3953, 51.4449],
                        [-127.4239, 51.4424],
                        [-127.4344, 51.4466],
                        [-127.4601, 51.4465],
                        [-127.4817, 51.4451],
                        [-127.5001, 51.4499],
                        [-127.5171, 51.4518],
                        [-127.5357, 51.4589],
                        [-127.5359, 51.4753],
                        [-127.5443, 51.4791],
                        [-127.5546, 51.4732],
                        [-127.5634, 51.4776],
                        [-127.5632, 51.4998],
                        [-127.553, 51.5058],
                        [-127.5366, 51.5071],
                        [-127.5319, 51.5013],
                        [-127.5211, 51.5135],
                        [-127.5147, 51.5401],
                        [-127.5154, 51.5492],
                        [-127.5125, 51.5555],
                        [-127.5205, 51.5682],
                        [-127.5213, 51.5788],
                        [-127.5108, 51.5942],
                        [-127.5133, 51.6045],
                        [-127.5048, 51.6232],
                        [-127.4923, 51.6273],
                        [-127.4743, 51.6243],
                        [-127.4413, 51.6398],
                        [-127.4156, 51.6353],
                        [-127.392, 51.6441],
                        [-127.3838, 51.6563],
                        [-127.3658, 51.6568],
                        [-127.3498, 51.6516],
                        [-127.342, 51.6583],
                        [-127.3255, 51.6655],
                        [-127.3061, 51.6673],
                        [-127.2657, 51.6667],
                        [-127.2558, 51.677],
                        [-127.2622, 51.685],
                        [-127.28, 51.6852],
                        [-127.2918, 51.6878],
                        [-127.3024, 51.6866],
                        [-127.3354, 51.6927],
                        [-127.3408, 51.7014],
                        [-127.336, 51.7091],
                        [-127.3467, 51.7191],
                        [-127.3529, 51.7175],
                        [-127.355, 51.7082],
                        [-127.3605, 51.6998],
                        [-127.357, 51.6925],
                        [-127.3626, 51.6832],
                        [-127.3753, 51.6794],
                        [-127.3833, 51.6829],
                        [-127.394, 51.6824],
                        [-127.4056, 51.6762],
                        [-127.4231, 51.6713],
                        [-127.4271, 51.6663],
                        [-127.4246, 51.6596],
                        [-127.4349, 51.6574],
                        [-127.4521, 51.6705],
                        [-127.4354, 51.6972],
                        [-127.4354, 51.7103],
                        [-127.4449, 51.725],
                        [-127.449, 51.7529],
                        [-127.4453, 51.7602],
                        [-127.4375, 51.7663],
                        [-127.4177, 51.7624],
                        [-127.4062, 51.7628],
                        [-127.3937, 51.7667],
                        [-127.3805, 51.7779],
                        [-127.3789, 51.7918],
                        [-127.3869, 51.7958],
                        [-127.3843, 51.8003],
                        [-127.3705, 51.8106],
                        [-127.364, 51.8215],
                        [-127.3575, 51.8275],
                        [-127.353, 51.8365],
                        [-127.3556, 51.8473],
                        [-127.3526, 51.8542],
                        [-127.3518, 51.867],
                        [-127.3634, 51.867],
                        [-127.3653, 51.8503],
                        [-127.3718, 51.8349],
                        [-127.3803, 51.8247],
                        [-127.3862, 51.8105],
                        [-127.3912, 51.8096],
                        [-127.4007, 51.7937],
                        [-127.4004, 51.7823],
                        [-127.4133, 51.7709],
                        [-127.4213, 51.7733],
                        [-127.4291, 51.769],
                        [-127.4466, 51.7836],
                        [-127.4516, 51.7802],
                        [-127.4513, 51.7701],
                        [-127.4572, 51.7646],
                        [-127.4631, 51.7487],
                        [-127.4626, 51.7322],
                        [-127.4552, 51.7153],
                        [-127.454, 51.7022],
                        [-127.4579, 51.6951],
                        [-127.4583, 51.6856],
                        [-127.4692, 51.6666],
                        [-127.4676, 51.6604],
                        [-127.4848, 51.6463],
                        [-127.507, 51.6462],
                        [-127.5173, 51.643],
                        [-127.5277, 51.6447],
                        [-127.5341, 51.6407],
                        [-127.548, 51.6563],
                        [-127.5481, 51.6381],
                        [-127.5557, 51.6282],
                        [-127.5595, 51.6082],
                        [-127.573, 51.5951],
                        [-127.5773, 51.5853],
                        [-127.5852, 51.5763],
                        [-127.5913, 51.5767],
                        [-127.5954, 51.5662],
                        [-127.6076, 51.5607],
                        [-127.6151, 51.5537],
                        [-127.6244, 51.5497],
                        [-127.6388, 51.5285],
                        [-127.662, 51.5168],
                        [-127.6727, 51.5179],
                        [-127.6718, 51.5265],
                        [-127.6812, 51.5268],
                        [-127.683, 51.5357],
                        [-127.6961, 51.5368],
                        [-127.7154, 51.5303],
                        [-127.73, 51.5218],
                        [-127.7438, 51.5266],
                        [-127.756, 51.5404],
                        [-127.7631, 51.5434],
                        [-127.7709, 51.5378],
                        [-127.7702, 51.5226],
                        [-127.7801, 51.5195],
                        [-127.7823, 51.5314],
                        [-127.7978, 51.5376],
                        [-127.796, 51.5453],
                        [-127.7872, 51.556],
                        [-127.795, 51.5614],
                        [-127.7951, 51.571],
                        [-127.7996, 51.5807],
                        [-127.7876, 51.588],
                        [-127.7958, 51.5957],
                        [-127.7837, 51.6053],
                        [-127.7646, 51.6004],
                        [-127.7561, 51.5916],
                        [-127.7461, 51.6057],
                        [-127.7282, 51.6049],
                        [-127.7019, 51.6064],
                        [-127.6777, 51.6036],
                        [-127.688, 51.6128],
                        [-127.6922, 51.6213],
                        [-127.6994, 51.6216],
                        [-127.7138, 51.6164],
                        [-127.7226, 51.6193],
                        [-127.7172, 51.6267],
                        [-127.7157, 51.6513],
                        [-127.7221, 51.6499],
                        [-127.7267, 51.6285],
                        [-127.7334, 51.6374],
                        [-127.7439, 51.6288],
                        [-127.7505, 51.6296],
                        [-127.7514, 51.638],
                        [-127.7609, 51.6457],
                        [-127.7757, 51.6316],
                        [-127.792, 51.6415],
                        [-127.7921, 51.6231],
                        [-127.8069, 51.6227],
                        [-127.8184, 51.63],
                        [-127.825, 51.6437],
                        [-127.8209, 51.6584],
                        [-127.8275, 51.6587],
                        [-127.8342, 51.6524],
                        [-127.8374, 51.6438],
                        [-127.8502, 51.6462],
                        [-127.8771, 51.6699],
                        [-127.8775, 51.6799],
                        [-127.8837, 51.6936],
                        [-127.89, 51.6951],
                        [-127.8858, 51.704],
                        [-127.8915, 51.7152],
                        [-127.8841, 51.7235],
                        [-127.8887, 51.7304],
                        [-127.8824, 51.7425],
                        [-127.8768, 51.7475],
                        [-127.8735, 51.7626],
                        [-127.8812, 51.7687],
                        [-127.8817, 51.7823],
                        [-127.8895, 51.7899],
                        [-127.8926, 51.7972],
                        [-127.8934, 51.8111],
                        [-127.8973, 51.8159],
                        [-127.892, 51.8271],
                        [-127.8801, 51.8335],
                        [-127.8785, 51.8387],
                        [-127.8681, 51.8446],
                        [-127.8813, 51.8528],
                        [-127.8731, 51.8564],
                        [-127.8627, 51.8661],
                        [-127.8722, 51.8691],
                        [-127.8682, 51.8791],
                        [-127.8862, 51.8836],
                        [-127.8824, 51.8971],
                        [-127.8774, 51.9019],
                        [-127.861, 51.9064],
                        [-127.8551, 51.9031],
                        [-127.8382, 51.9],
                        [-127.8426, 51.9111],
                        [-127.8402, 51.9155],
                        [-127.8233, 51.9273],
                        [-127.802, 51.9366],
                        [-127.7905, 51.9387],
                        [-127.7793, 51.9356],
                        [-127.7675, 51.9425],
                        [-127.757, 51.9443],
                        [-127.7474, 51.951],
                        [-127.7294, 51.9503],
                        [-127.7141, 51.9468],
                        [-127.7041, 51.9411],
                        [-127.69, 51.9506],
                        [-127.6823, 51.9534],
                        [-127.6769, 51.9482],
                        [-127.6679, 51.9512],
                        [-127.6669, 51.9596],
                        [-127.672, 51.9681],
                        [-127.6622, 51.9739],
                        [-127.6525, 51.9886],
                        [-127.6512, 52.0057],
                        [-127.6354, 52.0127],
                        [-127.6314, 52.0261],
                        [-127.6416, 52.0274],
                        [-127.6508, 52.0249],
                        [-127.6553, 52.0295],
                        [-127.6489, 52.039],
                        [-127.6478, 52.048],
                        [-127.6416, 52.0612],
                        [-127.6325, 52.0664],
                        [-127.6275, 52.0736],
                        [-127.6247, 52.0863],
                        [-127.613, 52.0964],
                        [-127.613, 52.1098],
                        [-127.6071, 52.1217],
                        [-127.5881, 52.1249],
                        [-127.5704, 52.1215],
                        [-127.555, 52.1245],
                        [-127.5442, 52.1306],
                        [-127.5291, 52.1468],
                        [-127.5272, 52.1582],
                        [-127.5128, 52.1576],
                        [-127.5102, 52.154],
                        [-127.4911, 52.1573],
                        [-127.4844, 52.1499],
                        [-127.475, 52.1472],
                        [-127.4652, 52.1338],
                        [-127.4633, 52.1269],
                        [-127.4691, 52.1218],
                        [-127.4638, 52.116],
                        [-127.4655, 52.1086],
                        [-127.4743, 52.0998],
                        [-127.4967, 52.1002],
                        [-127.5002, 52.0971],
                        [-127.5234, 52.1026],
                        [-127.5323, 52.1013],
                        [-127.5546, 52.1053],
                        [-127.5642, 52.1017],
                        [-127.5749, 52.1024],
                        [-127.5804, 52.0992],
                        [-127.5861, 52.0879],
                        [-127.5949, 52.0781],
                        [-127.604, 52.0634],
                        [-127.6008, 52.0599],
                        [-127.6028, 52.0495],
                        [-127.6164, 52.0368],
                        [-127.5852, 52.0459],
                        [-127.5812, 52.0609],
                        [-127.568, 52.0721],
                        [-127.5641, 52.0811],
                        [-127.5542, 52.0898],
                        [-127.5445, 52.0906],
                        [-127.5265, 52.0864],
                        [-127.5127, 52.0771],
                        [-127.5099, 52.0821],
                        [-127.4838, 52.0838],
                        [-127.4672, 52.0769],
                        [-127.4567, 52.0798],
                        [-127.4348, 52.0957],
                        [-127.4324, 52.1074],
                        [-127.4145, 52.1052],
                        [-127.4015, 52.0996],
                        [-127.3839, 52.1037],
                        [-127.3853, 52.111],
                        [-127.395, 52.1125],
                        [-127.4064, 52.1093],
                        [-127.4226, 52.1166],
                        [-127.4324, 52.1164],
                        [-127.4321, 52.1266],
                        [-127.4361, 52.1374],
                        [-127.4457, 52.1462],
                        [-127.4542, 52.1596],
                        [-127.4535, 52.1689],
                        [-127.4575, 52.1769],
                        [-127.466, 52.1784],
                        [-127.4729, 52.1867],
                        [-127.4616, 52.1911],
                        [-127.4417, 52.1873],
                        [-127.4262, 52.1885],
                        [-127.412, 52.196],
                        [-127.3993, 52.2053],
                        [-127.3994, 52.2174],
                        [-127.3708, 52.2258],
                        [-127.3612, 52.2246],
                        [-127.3504, 52.2266],
                        [-127.3392, 52.2333],
                        [-127.3268, 52.2297],
                        [-127.3163, 52.234],
                        [-127.2973, 52.2324],
                        [-127.285, 52.242],
                        [-127.2628, 52.2534],
                        [-127.2579, 52.261],
                        [-127.2412, 52.2617],
                        [-127.2334, 52.2643],
                        [-127.2257, 52.2717],
                        [-127.2128, 52.2775],
                        [-127.2048, 52.2933],
                        [-127.1935, 52.2918],
                        [-127.1815, 52.2932],
                        [-127.1817, 52.3029],
                        [-127.1768, 52.3087],
                        [-127.1646, 52.3088],
                        [-127.1529, 52.317],
                        [-127.1295, 52.3101],
                        [-127.1173, 52.3117],
                        [-127.0823, 52.2983],
                        [-127.0626, 52.3052],
                        [-127.0325, 52.3106],
                        [-127.0204, 52.3024],
                        [-127.0062, 52.3013],
                        [-126.9906, 52.2902],
                        [-126.969, 52.2691],
                        [-126.9576, 52.2627],
                        [-126.9536, 52.2536],
                        [-126.9436, 52.24],
                        [-126.9367, 52.226],
                        [-126.9425, 52.2137],
                        [-126.9271, 52.1886],
                        [-126.9229, 52.1879],
                        [-126.8913, 52.1698],
                        [-126.8742, 52.1654],
                        [-126.8519, 52.1518],
                        [-126.8382, 52.149],
                        [-126.8308, 52.1409],
                        [-126.8123, 52.1301],
                        [-126.8034, 52.1212],
                        [-126.7966, 52.1108],
                        [-126.785, 52.1046],
                        [-126.7592, 52.0852],
                        [-126.7558, 52.0762],
                        [-126.7491, 52.0731],
                        [-126.7342, 52.0563],
                        [-126.7167, 52.0432],
                        [-126.6967, 52.0263],
                        [-126.6922, 52.0185],
                        [-126.6934, 52.0114],
                        [-126.7024, 51.9993],
                        [-126.6893, 51.9958],
                        [-126.6945, 51.9846],
                        [-126.6857, 51.9798],
                        [-126.682, 51.9637],
                        [-126.6689, 51.963],
                        [-126.6696, 51.9816],
                        [-126.6806, 51.9958],
                        [-126.6716, 52.0048],
                        [-126.682, 52.0094],
                        [-126.6867, 52.019],
                        [-126.6829, 52.0267],
                        [-126.6739, 52.0308],
                        [-126.6786, 52.0385],
                        [-126.6728, 52.0426],
                        [-126.6756, 52.0481],
                        [-126.6833, 52.0497],
                        [-126.6936, 52.0603],
                        [-126.707, 52.0659],
                        [-126.7198, 52.0754],
                        [-126.7258, 52.0867],
                        [-126.7347, 52.0942],
                        [-126.7401, 52.1034],
                        [-126.7505, 52.1141],
                        [-126.7665, 52.1235],
                        [-126.7831, 52.1312],
                        [-126.7978, 52.1504],
                        [-126.8154, 52.1611],
                        [-126.8451, 52.1675],
                        [-126.8452, 52.1745],
                        [-126.8602, 52.182],
                        [-126.8641, 52.178],
                        [-126.8753, 52.1862],
                        [-126.9006, 52.1968],
                        [-126.9035, 52.2039],
                        [-126.9131, 52.2126],
                        [-126.9138, 52.2224],
                        [-126.9204, 52.2411],
                        [-126.9154, 52.2465],
                        [-126.9162, 52.2525],
                        [-126.9384, 52.2753],
                        [-126.9468, 52.2868],
                        [-126.9498, 52.2969],
                        [-126.9608, 52.3037],
                        [-126.9654, 52.3121],
                        [-126.9471, 52.317],
                        [-126.9355, 52.3287],
                        [-126.9346, 52.3417],
                        [-126.9279, 52.3494],
                        [-126.9165, 52.3432],
                        [-126.8917, 52.3473],
                        [-126.8865, 52.3517],
                        [-126.8821, 52.3618],
                        [-126.8687, 52.3653],
                        [-126.8531, 52.3659],
                        [-126.8413, 52.37],
                        [-126.8093, 52.3719],
                        [-126.802, 52.3764],
                        [-126.786, 52.3725],
                        [-126.774, 52.3928],
                        [-126.7836, 52.3973],
                        [-126.7968, 52.4],
                        [-126.8183, 52.3951],
                        [-126.8345, 52.389],
                        [-126.8531, 52.3872],
                        [-126.8724, 52.3804],
                        [-126.88, 52.3809],
                        [-126.8942, 52.3737],
                        [-126.9075, 52.3715],
                        [-126.9202, 52.3646],
                        [-126.9284, 52.3689],
                        [-126.9424, 52.366],
                        [-126.952, 52.3671],
                        [-126.9623, 52.3636],
                        [-126.9684, 52.3563],
                        [-126.9714, 52.3456],
                        [-126.9816, 52.3415],
                        [-126.9809, 52.3355],
                        [-126.9947, 52.3312],
                        [-127.011, 52.3334],
                        [-127.0153, 52.3363],
                        [-127.0405, 52.3385],
                        [-127.0531, 52.3338],
                        [-127.0674, 52.3359],
                        [-127.0782, 52.333],
                        [-127.1158, 52.3368],
                        [-127.1299, 52.3448],
                        [-127.1335, 52.3403],
                        [-127.1638, 52.3433],
                        [-127.1676, 52.356],
                        [-127.1749, 52.3619],
                        [-127.1822, 52.374],
                        [-127.1921, 52.3821],
                        [-127.2163, 52.3849],
                        [-127.2108, 52.3942],
                        [-127.1911, 52.4055],
                        [-127.1881, 52.4183],
                        [-127.1923, 52.4295],
                        [-127.201, 52.4383],
                        [-127.2183, 52.445],
                        [-127.2318, 52.4457],
                        [-127.2426, 52.4531],
                        [-127.2338, 52.4557],
                        [-127.2235, 52.4658],
                        [-127.2183, 52.4756],
                        [-127.2202, 52.4871],
                        [-127.2286, 52.4947],
                        [-127.2378, 52.514],
                        [-127.2354, 52.5259],
                        [-127.2266, 52.537],
                        [-127.2173, 52.5354],
                        [-127.2037, 52.5367],
                        [-127.1917, 52.5421],
                        [-127.189, 52.5551],
                        [-127.1826, 52.5595],
                        [-127.1727, 52.5609],
                        [-127.1565, 52.5715],
                        [-127.1315, 52.5768],
                        [-127.1173, 52.578],
                        [-127.1055, 52.5857],
                        [-127.1046, 52.6002],
                        [-127.0998, 52.6057],
                        [-127.0743, 52.6173],
                        [-127.0379, 52.6284],
                        [-127.0312, 52.6262],
                        [-127.0024, 52.6255],
                        [-126.9873, 52.6292],
                        [-126.9829, 52.6385],
                        [-126.9765, 52.6432],
                        [-126.978, 52.6558],
                        [-126.9743, 52.6593],
                        [-126.9756, 52.6778],
                        [-126.9596, 52.6862],
                        [-126.9449, 52.6988],
                        [-126.9306, 52.7013],
                        [-126.9194, 52.711],
                        [-126.9158, 52.7222],
                        [-126.9225, 52.7281],
                        [-126.9415, 52.7365],
                        [-126.9504, 52.7549],
                        [-126.9577, 52.7622],
                        [-126.9583, 52.7674],
                        [-126.9515, 52.7722],
                        [-126.956, 52.7809],
                        [-126.9484, 52.7896],
                        [-126.954, 52.8039],
                        [-126.974, 52.8057],
                        [-126.9825, 52.815],
                        [-126.9646, 52.8289],
                        [-126.9665, 52.8394],
                        [-126.9788, 52.842],
                        [-127.0092, 52.8384],
                        [-127.0153, 52.841],
                        [-127.023, 52.8504],
                        [-127.038, 52.8635],
                        [-127.0504, 52.8673],
                        [-127.0736, 52.8832],
                        [-127.0825, 52.8827],
                        [-127.0864, 52.8768],
                        [-127.0751, 52.8648],
                        [-127.0552, 52.8542],
                        [-127.0438, 52.8447],
                        [-127.0291, 52.8374],
                        [-127.0178, 52.8278],
                        [-126.9968, 52.8139],
                        [-126.9967, 52.8059],
                        [-126.9916, 52.7908],
                        [-126.9946, 52.7766],
                        [-127, 52.7737],
                        [-127.0023, 52.7637],
                        [-126.987, 52.7447],
                        [-126.9772, 52.738],
                        [-126.9733, 52.724],
                        [-126.9683, 52.7169],
                        [-126.9773, 52.7123],
                        [-126.9844, 52.7049],
                        [-126.9864, 52.6977],
                        [-126.9992, 52.6883],
                        [-127.0088, 52.6843],
                        [-127.0097, 52.6681],
                        [-127.0188, 52.67],
                        [-127.0322, 52.6646],
                        [-127.0329, 52.6529],
                        [-127.0379, 52.6426],
                        [-127.0789, 52.6351],
                        [-127.0863, 52.6306],
                        [-127.1025, 52.6266],
                        [-127.1171, 52.6169],
                        [-127.1252, 52.6017],
                        [-127.1342, 52.5938],
                        [-127.1473, 52.5949],
                        [-127.17, 52.6054],
                        [-127.172, 52.599],
                        [-127.1628, 52.5887],
                        [-127.1832, 52.5772],
                        [-127.2023, 52.5733],
                        [-127.2154, 52.5677],
                        [-127.2248, 52.5552],
                        [-127.2372, 52.5522],
                        [-127.2556, 52.5428],
                        [-127.2579, 52.5342],
                        [-127.2637, 52.5304],
                        [-127.2625, 52.5236],
                        [-127.2675, 52.5168],
                        [-127.2668, 52.4983],
                        [-127.2723, 52.4908],
                        [-127.2644, 52.4879],
                        [-127.262, 52.4735],
                        [-127.2722, 52.4627],
                        [-127.2839, 52.46],
                        [-127.2956, 52.4534],
                        [-127.3068, 52.4535],
                        [-127.3171, 52.4429],
                        [-127.3226, 52.4318],
                        [-127.3457, 52.4175],
                        [-127.3593, 52.4189],
                        [-127.3745, 52.4235],
                        [-127.4087, 52.4177],
                        [-127.4193, 52.4243],
                        [-127.4283, 52.4268],
                        [-127.4398, 52.4404],
                        [-127.4578, 52.4547],
                        [-127.4714, 52.4674],
                        [-127.4899, 52.4753],
                        [-127.4983, 52.489],
                        [-127.5226, 52.5103],
                        [-127.528, 52.5182],
                        [-127.5364, 52.5209],
                        [-127.5431, 52.5313],
                        [-127.5677, 52.5447],
                        [-127.5795, 52.56],
                        [-127.6016, 52.5771],
                        [-127.6049, 52.5892],
                        [-127.6211, 52.6094],
                        [-127.6328, 52.607],
                        [-127.6245, 52.5923],
                        [-127.6177, 52.5878],
                        [-127.6169, 52.5761],
                        [-127.6019, 52.5592],
                        [-127.5968, 52.5576],
                        [-127.5876, 52.5476],
                        [-127.5754, 52.5396],
                        [-127.5451, 52.512],
                        [-127.5369, 52.503],
                        [-127.5272, 52.4978],
                        [-127.5114, 52.4842],
                        [-127.5046, 52.472],
                        [-127.4859, 52.4513],
                        [-127.4785, 52.449],
                        [-127.4651, 52.4381],
                        [-127.4603, 52.4307],
                        [-127.4514, 52.4238],
                        [-127.4335, 52.4185],
                        [-127.4183, 52.4097],
                        [-127.4176, 52.4049],
                        [-127.4253, 52.4012],
                        [-127.4342, 52.4133],
                        [-127.4543, 52.407],
                        [-127.4612, 52.393],
                        [-127.4712, 52.378],
                        [-127.4822, 52.3756],
                        [-127.4919, 52.3843],
                        [-127.5222, 52.3944],
                        [-127.532, 52.3911],
                        [-127.5121, 52.3844],
                        [-127.4978, 52.3816],
                        [-127.4813, 52.3644],
                        [-127.4762, 52.3534],
                        [-127.4886, 52.3382],
                        [-127.5047, 52.334],
                        [-127.524, 52.334],
                        [-127.5382, 52.3314],
                        [-127.5402, 52.3267],
                        [-127.5541, 52.3215],
                        [-127.5605, 52.3223],
                        [-127.5718, 52.3162],
                        [-127.5817, 52.3202],
                        [-127.6004, 52.3197],
                        [-127.6063, 52.3152],
                        [-127.6035, 52.3023],
                        [-127.6085, 52.2911],
                        [-127.6305, 52.2835],
                        [-127.6363, 52.2836],
                        [-127.6466, 52.2908],
                        [-127.6712, 52.2941],
                        [-127.6839, 52.2899],
                        [-127.6924, 52.2834],
                        [-127.7169, 52.272],
                        [-127.7504, 52.2695],
                        [-127.7619, 52.2749],
                        [-127.7579, 52.2853],
                        [-127.7501, 52.29],
                        [-127.7477, 52.2984],
                        [-127.7519, 52.313],
                        [-127.732, 52.3292],
                        [-127.7337, 52.3395],
                        [-127.7164, 52.3502],
                        [-127.7095, 52.347],
                        [-127.6953, 52.3459],
                        [-127.6915, 52.3518],
                        [-127.7103, 52.3554],
                        [-127.7214, 52.3597],
                        [-127.7371, 52.3491],
                        [-127.7502, 52.332],
                        [-127.7499, 52.3273],
                        [-127.7623, 52.318],
                        [-127.7611, 52.3026],
                        [-127.7788, 52.2808],
                        [-127.7862, 52.2738],
                        [-127.785, 52.2678],
                        [-127.8034, 52.2593],
                        [-127.8092, 52.2523],
                        [-127.8189, 52.2504],
                        [-127.8209, 52.242],
                        [-127.8334, 52.2349],
                        [-127.8408, 52.222],
                        [-127.8482, 52.218],
                        [-127.854, 52.2074],
                        [-127.8606, 52.2027],
                        [-127.8676, 52.2044],
                        [-127.8761, 52.1983],
                        [-127.8876, 52.2042],
                        [-127.8883, 52.211],
                        [-127.8985, 52.2248],
                        [-127.895, 52.2315],
                        [-127.8965, 52.2417],
                        [-127.9015, 52.2484],
                        [-127.9008, 52.2588],
                        [-127.9084, 52.27],
                        [-127.9254, 52.2804],
                        [-127.9309, 52.2947],
                        [-127.9279, 52.3087],
                        [-127.9324, 52.3174],
                        [-127.9233, 52.3376],
                        [-127.9249, 52.35],
                        [-127.9061, 52.3558],
                        [-127.8877, 52.356],
                        [-127.8653, 52.3626],
                        [-127.8548, 52.354],
                        [-127.8482, 52.3517],
                        [-127.8557, 52.3736],
                        [-127.8512, 52.3753],
                        [-127.8577, 52.3889],
                        [-127.8689, 52.3902],
                        [-127.876, 52.3969],
                        [-127.8819, 52.4088],
                        [-127.8816, 52.4207],
                        [-127.8915, 52.4192],
                        [-127.8945, 52.4134],
                        [-127.921, 52.4267],
                        [-127.9257, 52.4308],
                        [-127.9277, 52.4437],
                        [-127.9026, 52.4527],
                        [-127.8869, 52.4642],
                        [-127.8895, 52.4832],
                        [-127.8736, 52.4909],
                        [-127.8461, 52.4914],
                        [-127.8467, 52.4853],
                        [-127.8356, 52.4759],
                        [-127.8234, 52.4718],
                        [-127.8108, 52.4721],
                        [-127.8116, 52.4573],
                        [-127.807, 52.453],
                        [-127.7838, 52.4489],
                        [-127.7611, 52.4594],
                        [-127.7435, 52.4634],
                        [-127.7294, 52.4518],
                        [-127.7133, 52.4498],
                        [-127.7226, 52.4603],
                        [-127.7387, 52.4698],
                        [-127.7475, 52.4718],
                        [-127.7688, 52.4645],
                        [-127.7812, 52.4557],
                        [-127.7917, 52.4555],
                        [-127.8025, 52.4662],
                        [-127.8005, 52.4734],
                        [-127.8065, 52.4832],
                        [-127.8221, 52.4849],
                        [-127.8294, 52.4881],
                        [-127.8377, 52.4985],
                        [-127.8303, 52.5116],
                        [-127.8384, 52.5131],
                        [-127.847, 52.5047],
                        [-127.8588, 52.5002],
                        [-127.8756, 52.5002],
                        [-127.8794, 52.5035],
                        [-127.893, 52.5007],
                        [-127.9051, 52.4857],
                        [-127.9054, 52.4679],
                        [-127.9257, 52.4507],
                        [-127.9319, 52.4581],
                        [-127.9394, 52.4612],
                        [-127.9457, 52.4449],
                        [-127.9345, 52.4233],
                        [-127.9233, 52.4118],
                        [-127.9121, 52.4044],
                        [-127.9065, 52.3971],
                        [-127.8993, 52.3947],
                        [-127.8907, 52.3857],
                        [-127.883, 52.3724],
                        [-127.905, 52.367],
                        [-127.913, 52.3673],
                        [-127.9245, 52.3609],
                        [-127.9448, 52.3559],
                        [-127.9411, 52.3402],
                        [-127.9418, 52.3303],
                        [-127.9575, 52.3182],
                        [-127.9807, 52.3152],
                        [-127.9815, 52.321],
                        [-127.9909, 52.323],
                        [-128.0079, 52.3181],
                        [-128.0073, 52.3387],
                        [-127.9983, 52.3549],
                        [-128.0032, 52.3642],
                        [-128.0124, 52.3692],
                        [-128.0084, 52.3746],
                        [-127.9916, 52.3811],
                        [-127.9859, 52.394],
                        [-127.9834, 52.4245],
                        [-127.9871, 52.434],
                        [-127.9839, 52.4436],
                        [-127.9763, 52.4501],
                        [-127.9748, 52.4617],
                        [-127.9547, 52.4758],
                        [-127.9387, 52.4732],
                        [-127.9404, 52.4803],
                        [-127.9617, 52.4794],
                        [-127.9717, 52.4706],
                        [-127.9872, 52.4666],
                        [-127.992, 52.4548],
                        [-128.0018, 52.4465],
                        [-128.0008, 52.4367],
                        [-127.9949, 52.427],
                        [-127.9993, 52.4195],
                        [-127.9943, 52.4008],
                        [-128.0107, 52.3901],
                        [-128.0038, 52.3851],
                        [-128.0149, 52.3789],
                        [-128.0199, 52.3713],
                        [-128.0096, 52.3575],
                        [-128.016, 52.3489],
                        [-128.0161, 52.3311],
                        [-128.0185, 52.32],
                        [-128.0349, 52.3292],
                        [-128.0382, 52.339],
                        [-128.0448, 52.3454],
                        [-128.0548, 52.3657],
                        [-128.0592, 52.3827],
                        [-128.0705, 52.3981],
                        [-128.0689, 52.4037],
                        [-128.0785, 52.419],
                        [-128.0807, 52.4496],
                        [-128.0747, 52.4594],
                        [-128.0572, 52.4608],
                        [-128.0396, 52.4661],
                        [-128.0355, 52.4765],
                        [-128.0437, 52.4834],
                        [-128.0307, 52.5006],
                        [-128.0212, 52.4993],
                        [-128.0055, 52.5122],
                        [-127.9788, 52.5177],
                        [-127.9912, 52.5234],
                        [-128.0042, 52.5175],
                        [-128.0142, 52.5181],
                        [-128.014, 52.5291],
                        [-128.0293, 52.5346],
                        [-128.0375, 52.5312],
                        [-128.0332, 52.5185],
                        [-128.0499, 52.5187],
                        [-128.0544, 52.5129],
                        [-128.0666, 52.5111],
                        [-128.085, 52.5292],
                        [-128.0984, 52.5503],
                        [-128.097, 52.5687],
                        [-128.0975, 52.5857],
                        [-128.091, 52.5932],
                        [-128.0718, 52.5997],
                        [-128.0637, 52.6057],
                        [-128.0478, 52.6076],
                        [-128.0393, 52.6133],
                        [-128.0255, 52.6302],
                        [-128.0312, 52.6342],
                        [-128.0441, 52.6346],
                        [-128.0427, 52.6468],
                        [-128.0525, 52.6491],
                        [-128.0475, 52.6279],
                        [-128.0695, 52.6147],
                        [-128.087, 52.5986],
                        [-128.1081, 52.6008],
                        [-128.1128, 52.5962],
                        [-128.112, 52.5657],
                        [-128.1088, 52.5587],
                        [-128.1123, 52.5457],
                        [-128.0986, 52.536],
                        [-128.0914, 52.5208],
                        [-128.1061, 52.5114],
                        [-128.1053, 52.507],
                        [-128.0959, 52.4969],
                        [-128.1008, 52.4881],
                        [-128.1081, 52.488],
                        [-128.1036, 52.4749],
                        [-128.1136, 52.4639],
                        [-128.1244, 52.4633],
                        [-128.1248, 52.4563],
                        [-128.1303, 52.4529],
                        [-128.1403, 52.4407],
                        [-128.1497, 52.4445],
                        [-128.1548, 52.4525],
                        [-128.1593, 52.4702],
                        [-128.1636, 52.4683],
                        [-128.1587, 52.4601],
                        [-128.1578, 52.4468],
                        [-128.1906, 52.4583],
                        [-128.1964, 52.4553],
                        [-128.1685, 52.4424],
                        [-128.15, 52.431],
                        [-128.1476, 52.4233],
                        [-128.1517, 52.4093],
                        [-128.1626, 52.3993],
                        [-128.1702, 52.3976],
                        [-128.1775, 52.3825],
                        [-128.1894, 52.3667],
                        [-128.2042, 52.3648],
                        [-128.2064, 52.3709],
                        [-128.2163, 52.3761],
                        [-128.2233, 52.3863],
                        [-128.2293, 52.3801],
                        [-128.2271, 52.371],
                        [-128.2207, 52.3612],
                        [-128.2079, 52.367],
                        [-128.2027, 52.3591],
                        [-128.2052, 52.3515],
                        [-128.2119, 52.3503],
                        [-128.2129, 52.3432],
                        [-128.2239, 52.3354],
                        [-128.2361, 52.3183],
                        [-128.2343, 52.3137],
                        [-128.2548, 52.2942],
                        [-128.2599, 52.2932],
                        [-128.2674, 52.2815],
                        [-128.275, 52.2748],
                        [-128.2791, 52.2666],
                        [-128.2899, 52.2581],
                        [-128.3082, 52.2583],
                        [-128.3266, 52.2687],
                        [-128.3434, 52.2691],
                        [-128.3583, 52.2662],
                        [-128.3593, 52.2863],
                        [-128.3703, 52.2933],
                        [-128.3539, 52.3164],
                        [-128.3247, 52.3367],
                        [-128.3396, 52.3356],
                        [-128.3336, 52.3445],
                        [-128.3377, 52.3566],
                        [-128.3318, 52.365],
                        [-128.3395, 52.3718],
                        [-128.319, 52.3865],
                        [-128.3115, 52.3991],
                        [-128.2982, 52.4005],
                        [-128.2762, 52.4056],
                        [-128.2626, 52.3919],
                        [-128.2639, 52.4054],
                        [-128.2528, 52.4082],
                        [-128.2447, 52.4268],
                        [-128.236, 52.4258],
                        [-128.2358, 52.4377],
                        [-128.2277, 52.4488],
                        [-128.2281, 52.4617],
                        [-128.2221, 52.4713],
                        [-128.2247, 52.4744],
                        [-128.203, 52.4868],
                        [-128.223, 52.4844],
                        [-128.2298, 52.4893],
                        [-128.2386, 52.5014],
                        [-128.2481, 52.5358],
                        [-128.244, 52.5365],
                        [-128.2473, 52.5543],
                        [-128.2466, 52.5665],
                        [-128.2433, 52.5715],
                        [-128.2308, 52.5702],
                        [-128.2303, 52.5777],
                        [-128.2204, 52.5855],
                        [-128.2096, 52.6046],
                        [-128.1948, 52.623],
                        [-128.1843, 52.6263],
                        [-128.178, 52.6463],
                        [-128.1787, 52.6528],
                        [-128.1721, 52.661],
                        [-128.171, 52.6763],
                        [-128.1629, 52.6879],
                        [-128.1511, 52.6939],
                        [-128.1435, 52.7061],
                        [-128.1358, 52.7122],
                        [-128.136, 52.7263],
                        [-128.1272, 52.7413],
                        [-128.113, 52.7597],
                        [-128.0908, 52.7645],
                        [-128.0711, 52.7611],
                        [-128.0485, 52.7531],
                        [-127.9877, 52.7403],
                        [-127.9749, 52.7398],
                        [-127.9663, 52.7428],
                        [-127.9589, 52.7311],
                        [-127.9434, 52.7286],
                        [-127.9302, 52.7337],
                        [-127.9038, 52.7359],
                        [-127.8832, 52.7512],
                        [-127.8764, 52.7477],
                        [-127.8748, 52.7416],
                        [-127.8556, 52.7386],
                        [-127.8538, 52.7351],
                        [-127.8382, 52.7305],
                        [-127.8291, 52.7314],
                        [-127.8271, 52.7365],
                        [-127.8489, 52.7397],
                        [-127.8583, 52.7465],
                        [-127.8648, 52.7469],
                        [-127.8742, 52.7541],
                        [-127.8862, 52.7586],
                        [-127.9039, 52.7473],
                        [-127.9206, 52.7427],
                        [-127.9278, 52.7444],
                        [-127.9403, 52.7385],
                        [-127.948, 52.7396],
                        [-127.9504, 52.7471],
                        [-127.9597, 52.7523],
                        [-127.9779, 52.7517],
                        [-127.9881, 52.7658],
                        [-127.9882, 52.7781],
                        [-127.9912, 52.7868],
                        [-128.0012, 52.7859],
                        [-128.0048, 52.773],
                        [-128.0003, 52.759],
                        [-128.01, 52.7574],
                        [-128.0218, 52.7601],
                        [-128.0339, 52.76],
                        [-128.0604, 52.7679],
                        [-128.0684, 52.7719],
                        [-128.0893, 52.7762],
                        [-128.1006, 52.7755],
                        [-128.1301, 52.7703],
                        [-128.1305, 52.7902],
                        [-128.1264, 52.8085],
                        [-128.1279, 52.8167],
                        [-128.1264, 52.8296],
                        [-128.132, 52.8399],
                        [-128.1407, 52.8399],
                        [-128.1432, 52.8478],
                        [-128.1376, 52.854],
                        [-128.1352, 52.8629],
                        [-128.1364, 52.8769],
                        [-128.1155, 52.8841],
                        [-128.1215, 52.8931],
                        [-128.1102, 52.8982],
                        [-128.0996, 52.895],
                        [-128.0769, 52.8985],
                        [-128.0703, 52.9032],
                        [-128.0535, 52.9079],
                        [-128.0302, 52.9059],
                        [-128.0446, 52.9181],
                        [-128.0606, 52.9162],
                        [-128.0823, 52.9096],
                        [-128.091, 52.9109],
                        [-128.1163, 52.9097],
                        [-128.1269, 52.9162],
                        [-128.1369, 52.9182],
                        [-128.1373, 52.9065],
                        [-128.1532, 52.8887],
                        [-128.1608, 52.8694],
                        [-128.1599, 52.8602],
                        [-128.1802, 52.8478],
                        [-128.1976, 52.8413],
                        [-128.2232, 52.8378],
                        [-128.2189, 52.8342],
                        [-128.2046, 52.8313],
                        [-128.2098, 52.8225],
                        [-128.2224, 52.8148],
                        [-128.2254, 52.8065],
                        [-128.233, 52.8056],
                        [-128.2408, 52.7996],
                        [-128.2729, 52.7969],
                        [-128.3005, 52.7986],
                        [-128.3243, 52.7972],
                        [-128.3407, 52.8009],
                        [-128.3479, 52.806],
                        [-128.3683, 52.8093],
                        [-128.3837, 52.8142],
                        [-128.3918, 52.8189],
                        [-128.3896, 52.8309],
                        [-128.3965, 52.8327],
                        [-128.4013, 52.8252],
                        [-128.4199, 52.8216],
                        [-128.4322, 52.8145],
                        [-128.4431, 52.822],
                        [-128.4536, 52.8448],
                        [-128.469, 52.8605],
                        [-128.4797, 52.8635],
                        [-128.4873, 52.8736],
                        [-128.4984, 52.8838],
                        [-128.5012, 52.8925],
                        [-128.4984, 52.9094],
                        [-128.4988, 52.919],
                        [-128.484, 52.9146],
                        [-128.4752, 52.9142],
                        [-128.4551, 52.9203],
                        [-128.4372, 52.921],
                        [-128.4221, 52.9315],
                        [-128.4104, 52.9365],
                        [-128.4115, 52.9429],
                        [-128.4281, 52.9397],
                        [-128.4435, 52.9293],
                        [-128.455, 52.9244],
                        [-128.4664, 52.9242],
                        [-128.4728, 52.9186],
                        [-128.491, 52.924],
                        [-128.5034, 52.9326],
                        [-128.5018, 52.9388],
                        [-128.5083, 52.9598],
                        [-128.5071, 52.9673],
                        [-128.5134, 52.9956],
                        [-128.5123, 53.0071],
                        [-128.5043, 53.0139],
                        [-128.5126, 53.0161],
                        [-128.5199, 53.013],
                        [-128.5244, 53.0253],
                        [-128.5238, 53.032],
                        [-128.5414, 53.0543],
                        [-128.5504, 53.0685],
                        [-128.538, 53.0787],
                        [-128.5356, 53.085],
                        [-128.5239, 53.0883],
                        [-128.5093, 53.0881],
                        [-128.496, 53.0912],
                        [-128.4774, 53.0921],
                        [-128.4726, 53.095],
                        [-128.4597, 53.0936],
                        [-128.4485, 53.0961],
                        [-128.4378, 53.0855],
                        [-128.425, 53.0833],
                        [-128.4218, 53.0873],
                        [-128.4348, 53.1026],
                        [-128.4659, 53.1068],
                        [-128.4899, 53.0964],
                        [-128.5063, 53.0947],
                        [-128.5269, 53.0968],
                        [-128.5422, 53.0945],
                        [-128.5577, 53.0885],
                        [-128.571, 53.1118],
                        [-128.5698, 53.118],
                        [-128.5611, 53.1255],
                        [-128.5498, 53.1283],
                        [-128.5316, 53.1235],
                        [-128.5113, 53.1238],
                        [-128.5032, 53.1288],
                        [-128.4772, 53.1373],
                        [-128.4796, 53.1472],
                        [-128.4888, 53.146],
                        [-128.5016, 53.1388],
                        [-128.521, 53.1316],
                        [-128.5301, 53.1304],
                        [-128.5472, 53.1351],
                        [-128.5586, 53.1357],
                        [-128.5792, 53.1283],
                        [-128.5922, 53.1448],
                        [-128.5922, 53.1493],
                        [-128.611, 53.1642],
                        [-128.624, 53.167],
                        [-128.6443, 53.1849],
                        [-128.6535, 53.2039],
                        [-128.6628, 53.218],
                        [-128.6724, 53.2262],
                        [-128.6761, 53.2376],
                        [-128.6877, 53.2417],
                        [-128.6825, 53.2287],
                        [-128.6762, 53.2211],
                        [-128.6737, 53.2074],
                        [-128.6668, 53.2029],
                        [-128.6563, 53.1808],
                        [-128.6962, 53.1897],
                        [-128.7077, 53.1953],
                        [-128.7283, 53.2021],
                        [-128.7425, 53.2105],
                        [-128.7549, 53.215],
                        [-128.7656, 53.2238],
                        [-128.7946, 53.2384],
                        [-128.8268, 53.2575],
                        [-128.8286, 53.2606],
                        [-128.8471, 53.2677],
                        [-128.8576, 53.2762],
                        [-128.8597, 53.284],
                        [-128.8749, 53.2948],
                        [-128.8864, 53.3072],
                        [-128.8869, 53.3226],
                        [-128.8896, 53.3292],
                        [-128.8861, 53.3368],
                        [-128.8872, 53.3475],
                        [-128.8633, 53.3587],
                        [-128.865, 53.3649],
                        [-128.8794, 53.359],
                        [-128.8905, 53.3612],
                        [-128.8914, 53.3696],
                        [-128.8826, 53.3797],
                        [-128.8876, 53.3934],
                        [-128.8816, 53.4018],
                        [-128.8854, 53.4111],
                        [-128.9023, 53.4248],
                        [-128.9047, 53.4298],
                        [-128.8856, 53.4425],
                        [-128.8737, 53.4535],
                        [-128.8695, 53.4659],
                        [-128.8527, 53.4684],
                        [-128.8393, 53.4659],
                        [-128.8352, 53.4699],
                        [-128.8408, 53.4758],
                        [-128.8683, 53.4815],
                        [-128.8963, 53.4713],
                        [-128.9082, 53.4531],
                        [-128.9151, 53.4455],
                        [-128.9236, 53.448],
                        [-128.9351, 53.4567],
                        [-128.9526, 53.4659],
                        [-128.958, 53.4744],
                        [-128.9617, 53.488],
                        [-128.9729, 53.4963],
                        [-128.9665, 53.5105],
                        [-128.9701, 53.5252],
                        [-128.9649, 53.5323],
                        [-128.9876, 53.5382],
                        [-128.9812, 53.5466],
                        [-128.9669, 53.5502],
                        [-128.9346, 53.5407],
                        [-128.9267, 53.5335],
                        [-128.9171, 53.5341],
                        [-128.9082, 53.5306],
                        [-128.8678, 53.53],
                        [-128.8569, 53.5393],
                        [-128.8488, 53.542],
                        [-128.8407, 53.5546],
                        [-128.8142, 53.5683],
                        [-128.8079, 53.5644],
                        [-128.784, 53.5284],
                        [-128.7791, 53.526],
                        [-128.7679, 53.5036],
                        [-128.7699, 53.4942],
                        [-128.7569, 53.4845],
                        [-128.7311, 53.4895],
                        [-128.7097, 53.4756],
                        [-128.699, 53.4639],
                        [-128.6881, 53.4482],
                        [-128.6813, 53.4502],
                        [-128.6804, 53.4588],
                        [-128.691, 53.4686],
                        [-128.6979, 53.4709],
                        [-128.7017, 53.4799],
                        [-128.7126, 53.484],
                        [-128.7116, 53.4918],
                        [-128.6954, 53.4846],
                        [-128.677, 53.482],
                        [-128.6628, 53.4744],
                        [-128.6572, 53.4752],
                        [-128.6416, 53.4586],
                        [-128.6343, 53.4533],
                        [-128.5991, 53.4378],
                        [-128.5851, 53.4306],
                        [-128.5681, 53.4158],
                        [-128.5393, 53.4071],
                        [-128.5188, 53.3941],
                        [-128.5083, 53.396],
                        [-128.4906, 53.3925],
                        [-128.4939, 53.3812],
                        [-128.5003, 53.3718],
                        [-128.5029, 53.3505],
                        [-128.4954, 53.3346],
                        [-128.4805, 53.3249],
                        [-128.4815, 53.3368],
                        [-128.49, 53.3443],
                        [-128.4931, 53.3543],
                        [-128.4913, 53.3652],
                        [-128.4935, 53.3721],
                        [-128.4867, 53.3806],
                        [-128.484, 53.3945],
                        [-128.4928, 53.397],
                        [-128.5194, 53.3996],
                        [-128.5353, 53.428],
                        [-128.5447, 53.4297],
                        [-128.557, 53.4423],
                        [-128.5532, 53.4497],
                        [-128.572, 53.4494],
                        [-128.5929, 53.456],
                        [-128.6018, 53.4655],
                        [-128.5868, 53.4704],
                        [-128.5868, 53.4773],
                        [-128.6011, 53.488],
                        [-128.6344, 53.4952],
                        [-128.6441, 53.5022],
                        [-128.6553, 53.5035],
                        [-128.6682, 53.4999],
                        [-128.6868, 53.502],
                        [-128.696, 53.5071],
                        [-128.7047, 53.5175],
                        [-128.7116, 53.5363],
                        [-128.7278, 53.5447],
                        [-128.7304, 53.5511],
                        [-128.7402, 53.5557],
                        [-128.7377, 53.5426],
                        [-128.7495, 53.5401],
                        [-128.763, 53.5468],
                        [-128.774, 53.5599],
                        [-128.7646, 53.5596],
                        [-128.7689, 53.5688],
                        [-128.7993, 53.5906],
                        [-128.7974, 53.5961],
                        [-128.8134, 53.6055],
                        [-128.824, 53.6197],
                        [-128.8267, 53.6291],
                        [-128.8345, 53.6412],
                        [-128.8354, 53.6495],
                        [-128.8216, 53.6589],
                        [-128.8167, 53.6683],
                        [-128.8063, 53.6697],
                        [-128.8009, 53.6739],
                        [-128.8014, 53.6805],
                        [-128.7936, 53.692],
                        [-128.7851, 53.7093],
                        [-128.7831, 53.7207],
                        [-128.7887, 53.7239],
                        [-128.8051, 53.7434],
                        [-128.8026, 53.7514],
                        [-128.8112, 53.7608],
                        [-128.8082, 53.7699],
                        [-128.7991, 53.7755],
                        [-128.785, 53.79],
                        [-128.7697, 53.7964],
                        [-128.7529, 53.7997],
                        [-128.747, 53.8046],
                        [-128.7326, 53.8095],
                        [-128.7055, 53.8226],
                        [-128.707, 53.8276],
                        [-128.6969, 53.8389],
                        [-128.6899, 53.8417],
                        [-128.6782, 53.8528],
                        [-128.6559, 53.8541],
                        [-128.6203, 53.8388],
                        [-128.6096, 53.8328],
                        [-128.593, 53.8316],
                        [-128.5608, 53.8316],
                        [-128.5501, 53.8358],
                        [-128.5434, 53.8327],
                        [-128.5316, 53.8328],
                        [-128.5195, 53.8261],
                        [-128.5053, 53.8254],
                        [-128.4998, 53.8202],
                        [-128.487, 53.8266],
                        [-128.4934, 53.8362],
                        [-128.4958, 53.8469],
                        [-128.5106, 53.8464],
                        [-128.5251, 53.8513],
                        [-128.5346, 53.8495],
                        [-128.546, 53.851],
                        [-128.5649, 53.8475],
                        [-128.5711, 53.843],
                        [-128.5812, 53.8457],
                        [-128.5886, 53.8436],
                        [-128.6034, 53.8448],
                        [-128.6273, 53.8512],
                        [-128.6325, 53.8601],
                        [-128.6449, 53.8672],
                        [-128.6646, 53.8651],
                        [-128.6729, 53.8691],
                        [-128.6861, 53.8679],
                        [-128.6962, 53.8701],
                        [-128.7076, 53.8767],
                        [-128.715, 53.9018],
                        [-128.7011, 53.9105],
                        [-128.6845, 53.8979],
                        [-128.6808, 53.9042],
                        [-128.6913, 53.9135],
                        [-128.6797, 53.9276],
                        [-128.6612, 53.9438],
                        [-128.649, 53.9699],
                        [-128.6521, 53.9734],
                        [-128.6499, 53.9825],
                        [-128.6543, 53.9869],
                        [-128.6493, 54.0045],
                        [-128.6377, 54.0084],
                        [-128.6094, 54.0253],
                        [-128.608, 54.0296],
                        [-128.6208, 54.0339],
                        [-128.6445, 54.016],
                        [-128.6589, 54.0109],
                        [-128.6777, 54.009],
                        [-128.6932, 54.0019],
                        [-128.6975, 53.9958],
                        [-128.6954, 53.9883],
                        [-128.6998, 53.9806],
                        [-128.6985, 53.9747],
                        [-128.703, 53.9629],
                        [-128.7071, 53.9615],
                        [-128.7127, 53.9434],
                        [-128.7221, 53.9291],
                        [-128.7306, 53.9225],
                        [-128.7388, 53.9213],
                        [-128.7569, 53.9231],
                        [-128.7628, 53.9139],
                        [-128.7601, 53.9062],
                        [-128.7726, 53.9023],
                        [-128.7894, 53.9015],
                        [-128.7874, 53.8943],
                        [-128.7894, 53.8809],
                        [-128.7949, 53.8739],
                        [-128.8127, 53.8648],
                        [-128.8285, 53.8631],
                        [-128.8472, 53.8586],
                        [-128.8503, 53.8477],
                        [-128.857, 53.8397],
                        [-128.8646, 53.8376],
                        [-128.8652, 53.8262],
                        [-128.8734, 53.8144],
                        [-128.9027, 53.8164],
                        [-128.8968, 53.8067],
                        [-128.9276, 53.7868],
                        [-128.9306, 53.7814],
                        [-128.9434, 53.7728],
                        [-128.9519, 53.7733],
                        [-128.9413, 53.785],
                        [-128.9601, 53.7985],
                        [-128.9602, 53.8149],
                        [-128.9662, 53.8202],
                        [-128.966, 53.8278],
                        [-128.958, 53.8453],
                        [-128.9674, 53.8498],
                        [-128.9627, 53.8671],
                        [-128.9657, 53.8738],
                        [-128.992, 53.8934],
                        [-129.0149, 53.8945],
                        [-129.0343, 53.8982],
                        [-129.0338, 53.8929],
                        [-129.0259, 53.8862],
                        [-129.0152, 53.8849],
                        [-128.9982, 53.8878],
                        [-128.9963, 53.8826],
                        [-128.9814, 53.8709],
                        [-128.9828, 53.8645],
                        [-128.9748, 53.8541],
                        [-128.9784, 53.8433],
                        [-128.9805, 53.8241],
                        [-128.9733, 53.807],
                        [-128.9637, 53.7923],
                        [-128.9627, 53.7836],
                        [-128.9752, 53.7686],
                        [-129.0015, 53.7629],
                        [-129.0099, 53.7587],
                        [-129.0263, 53.769],
                        [-129.0311, 53.7534],
                        [-129.0446, 53.7443],
                        [-129.0664, 53.735],
                        [-129.0867, 53.7287],
                        [-129.0939, 53.7291],
                        [-129.1035, 53.7228],
                        [-129.1173, 53.7179],
                        [-129.1275, 53.7086],
                        [-129.128, 53.7035],
                        [-129.1363, 53.6965],
                        [-129.1373, 53.6878],
                        [-129.1538, 53.6736],
                        [-129.1682, 53.6662],
                        [-129.1918, 53.6602],
                        [-129.2238, 53.6409],
                        [-129.2315, 53.6309],
                        [-129.2574, 53.6365],
                        [-129.2666, 53.6399],
                        [-129.2747, 53.6384],
                        [-129.2946, 53.6491],
                        [-129.303, 53.6366],
                        [-129.2978, 53.6299],
                        [-129.2637, 53.6221],
                        [-129.2402, 53.6135],
                        [-129.2498, 53.6037],
                        [-129.2517, 53.5783],
                        [-129.2387, 53.5642],
                        [-129.2406, 53.5537],
                        [-129.2368, 53.545],
                        [-129.2401, 53.5379],
                        [-129.2321, 53.5218],
                        [-129.234, 53.5186],
                        [-129.2647, 53.5176],
                        [-129.2811, 53.5191],
                        [-129.2987, 53.5275],
                        [-129.3008, 53.5369],
                        [-129.3149, 53.5513],
                        [-129.3252, 53.5475],
                        [-129.31, 53.5363],
                        [-129.3094, 53.5301],
                        [-129.2987, 53.5201],
                        [-129.291, 53.5199],
                        [-129.2775, 53.5129],
                        [-129.2677, 53.5113],
                        [-129.2402, 53.5128],
                        [-129.2354, 53.5098],
                        [-129.2411, 53.5016],
                        [-129.2392, 53.4812],
                        [-129.2425, 53.4767],
                        [-129.2344, 53.4603],
                        [-129.2343, 53.4495],
                        [-129.24, 53.4388],
                        [-129.2376, 53.4244],
                        [-129.25, 53.4255],
                        [-129.2601, 53.4053],
                        [-129.2852, 53.401],
                        [-129.291, 53.3965],
                        [-129.2878, 53.3891],
                        [-129.2791, 53.3806],
                        [-129.2789, 53.3735],
                        [-129.2699, 53.3647],
                        [-129.2809, 53.3626],
                        [-129.2995, 53.3699],
                        [-129.3102, 53.3717],
                        [-129.339, 53.3825],
                        [-129.363, 53.398],
                        [-129.384, 53.4082],
                        [-129.3968, 53.4193],
                        [-129.4044, 53.422],
                        [-129.4139, 53.4329],
                        [-129.4233, 53.4357],
                        [-129.457, 53.4613],
                        [-129.4739, 53.4698],
                        [-129.4785, 53.4754],
                        [-129.4875, 53.4737],
                        [-129.498, 53.4792],
                        [-129.5107, 53.4894],
                        [-129.5606, 53.5167],
                        [-129.5642, 53.5205],
                        [-129.583, 53.5305],
                        [-129.5956, 53.539],
                        [-129.5923, 53.5477],
                        [-129.5774, 53.5524],
                        [-129.576, 53.5571],
                        [-129.5645, 53.5559],
                        [-129.5686, 53.5633],
                        [-129.5806, 53.5622],
                        [-129.5877, 53.555],
                        [-129.5986, 53.5519],
                        [-129.5994, 53.5441],
                        [-129.6066, 53.5444],
                        [-129.6464, 53.5664],
                        [-129.6879, 53.5976],
                        [-129.7008, 53.6114],
                        [-129.7214, 53.6282],
                        [-129.7363, 53.6448],
                        [-129.7575, 53.6647],
                        [-129.7593, 53.6788],
                        [-129.7537, 53.6845],
                        [-129.7451, 53.6807],
                        [-129.7404, 53.6724],
                        [-129.7139, 53.6674],
                        [-129.7217, 53.6751],
                        [-129.7294, 53.6914],
                        [-129.7386, 53.6987],
                        [-129.7515, 53.6941],
                        [-129.7593, 53.6955],
                        [-129.7857, 53.7059],
                        [-129.7902, 53.7126],
                        [-129.814, 53.7242],
                        [-129.8282, 53.7394],
                        [-129.8339, 53.7363],
                        [-129.8452, 53.7402],
                        [-129.873, 53.7558],
                        [-129.8827, 53.764],
                        [-129.8966, 53.7681],
                        [-129.9078, 53.7773],
                        [-129.9214, 53.7847],
                        [-129.9373, 53.7996],
                        [-129.9539, 53.8096],
                        [-129.9488, 53.8165],
                        [-129.9207, 53.8186],
                        [-129.9164, 53.8136],
                        [-129.8906, 53.8105],
                        [-129.8852, 53.8145],
                        [-129.8697, 53.8149],
                        [-129.8519, 53.806],
                        [-129.8499, 53.8095],
                        [-129.8585, 53.8163],
                        [-129.8726, 53.8202],
                        [-129.8966, 53.8166],
                        [-129.9064, 53.8204],
                        [-129.9351, 53.8235],
                        [-129.9469, 53.8212],
                        [-129.9545, 53.8133],
                        [-129.9714, 53.8233],
                        [-129.9811, 53.8239],
                        [-129.9897, 53.8292],
                        [-130.0075, 53.8445],
                        [-130.0072, 53.8507],
                        [-129.9874, 53.8562],
                        [-129.9761, 53.869],
                        [-129.9847, 53.8708],
                        [-129.9865, 53.8796],
                        [-129.9766, 53.8811],
                        [-129.9746, 53.8856],
                        [-129.9954, 53.8907],
                        [-130.0056, 53.8901],
                        [-129.9889, 53.8786],
                        [-129.9891, 53.8647],
                        [-129.9985, 53.8624],
                        [-130.0146, 53.8548],
                        [-130.0238, 53.8617],
                        [-130.037, 53.8623],
                        [-130.0504, 53.8721],
                        [-130.0529, 53.8779],
                        [-130.0724, 53.8917],
                        [-130.0857, 53.9177],
                        [-130.0849, 53.9291],
                        [-130.0943, 53.931],
                        [-130.1028, 53.9441],
                        [-130.1091, 53.9498],
                        [-130.1062, 53.9572],
                        [-130.091, 53.9692],
                        [-130.0992, 53.9704],
                        [-130.1049, 53.9789],
                        [-130.0967, 53.9929],
                        [-130.0931, 54.017],
                        [-130.0853, 54.0237],
                        [-130.0919, 54.0348],
                        [-130.0906, 54.0437],
                        [-130.0933, 54.052],
                        [-130.1022, 54.0636],
                        [-130.0905, 54.0778],
                        [-130.088, 54.1],
                        [-130.0826, 54.1145],
                        [-130.1247, 54.1495],
                        [-130.1296, 54.1484],
                        [-130.1575, 54.1677],
                        [-130.1779, 54.1786],
                        [-130.2021, 54.1877],
                        [-130.2301, 54.196],
                        [-130.2401, 54.1952],
                        [-130.2627, 54.1987],
                        [-130.2784, 54.2041],
                        [-130.2879, 54.2118],
                        [-130.2937, 54.222],
                        [-130.3007, 54.244],
                        [-130.2727, 54.2422],
                        [-130.2591, 54.2534],
                        [-130.2452, 54.255],
                        [-130.2359, 54.2591],
                        [-130.221, 54.2576],
                        [-130.2097, 54.2611],
                        [-130.1977, 54.2468],
                        [-130.1859, 54.2691],
                        [-130.2041, 54.265],
                        [-130.2171, 54.2682],
                        [-130.2316, 54.2679],
                        [-130.2387, 54.2837],
                        [-130.2438, 54.289],
                        [-130.2427, 54.3019],
                        [-130.2538, 54.3199],
                        [-130.261, 54.3194],
                        [-130.2724, 54.3292],
                        [-130.275, 54.34],
                        [-130.2602, 54.3581],
                        [-130.2526, 54.3784],
                        [-130.2442, 54.3814],
                        [-130.2491, 54.3943],
                        [-130.2646, 54.4062],
                        [-130.2757, 54.4091],
                        [-130.2877, 54.4159],
                        [-130.3103, 54.4331],
                        [-130.314, 54.4299],
                        [-130.3054, 54.4199],
                        [-130.2791, 54.403],
                        [-130.2619, 54.3992],
                        [-130.2609, 54.3899],
                        [-130.2713, 54.3808],
                        [-130.2743, 54.3687],
                        [-130.2861, 54.35],
                        [-130.3071, 54.3374],
                        [-130.3198, 54.3391],
                        [-130.3298, 54.3307],
                        [-130.3457, 54.3314],
                        [-130.3559, 54.3229],
                        [-130.3633, 54.3274],
                        [-130.3725, 54.3194],
                        [-130.3831, 54.326],
                        [-130.3855, 54.3312],
                        [-130.4169, 54.3279],
                        [-130.419, 54.3428],
                        [-130.4335, 54.3408],
                        [-130.4445, 54.3365],
                        [-130.4635, 54.3371],
                        [-130.4592, 54.3476],
                        [-130.4617, 54.355],
                        [-130.482, 54.3686],
                        [-130.476, 54.3754],
                        [-130.4788, 54.383],
                        [-130.4743, 54.3901],
                        [-130.4866, 54.4017],
                        [-130.4873, 54.4065],
                        [-130.4776, 54.4154],
                        [-130.4893, 54.4292],
                        [-130.4838, 54.4388],
                        [-130.4649, 54.4408],
                        [-130.4655, 54.4473],
                        [-130.4479, 54.4571],
                        [-130.4251, 54.449],
                        [-130.3886, 54.4547],
                        [-130.4101, 54.4696],
                        [-130.4145, 54.4755],
                        [-130.4254, 54.4739],
                        [-130.4444, 54.4901],
                        [-130.4467, 54.5006],
                        [-130.4407, 54.5046],
                        [-130.447, 54.5102],
                        [-130.4508, 54.5286],
                        [-130.4364, 54.5569],
                        [-130.4271, 54.5621],
                        [-130.4173, 54.5636],
                        [-130.4005, 54.559],
                        [-130.3842, 54.5614],
                        [-130.416, 54.588],
                        [-130.4278, 54.5956],
                        [-130.4358, 54.5966],
                        [-130.4389, 54.6087],
                        [-130.4562, 54.6129],
                        [-130.4571, 54.6202],
                        [-130.4506, 54.6272],
                        [-130.4313, 54.6373],
                        [-130.4017, 54.6208],
                        [-130.3946, 54.6209],
                        [-130.3732, 54.6037],
                        [-130.3669, 54.602],
                        [-130.3491, 54.5863],
                        [-130.3409, 54.5819],
                        [-130.3487, 54.5768],
                        [-130.359, 54.5848],
                        [-130.3634, 54.5786],
                        [-130.3179, 54.5522],
                        [-130.3055, 54.5409],
                        [-130.3058, 54.5376],
                        [-130.2692, 54.5017],
                        [-130.2668, 54.4964],
                        [-130.2429, 54.4841],
                        [-130.237, 54.4758],
                        [-130.207, 54.4567],
                        [-130.1896, 54.449],
                        [-130.1732, 54.437],
                        [-130.1652, 54.4245],
                        [-130.1535, 54.4183],
                        [-130.1402, 54.4067],
                        [-130.131, 54.4038],
                        [-130.1141, 54.3939],
                        [-130.1079, 54.3867],
                        [-130.0827, 54.366],
                        [-130.075, 54.3555],
                        [-130.0758, 54.3481],
                        [-130.0631, 54.3396],
                        [-130.0557, 54.3322],
                        [-130.0413, 54.323],
                        [-129.9985, 54.3044],
                        [-129.9954, 54.3007],
                        [-129.9833, 54.3024],
                        [-129.984, 54.3122],
                        [-130.009, 54.3166],
                        [-130.0434, 54.3351],
                        [-130.0519, 54.3449],
                        [-130.0556, 54.3598],
                        [-130.0729, 54.3725],
                        [-130.083, 54.3848],
                        [-130.0955, 54.3946],
                        [-130.1106, 54.4173],
                        [-130.1197, 54.4202],
                        [-130.1413, 54.4341],
                        [-130.1505, 54.4446],
                        [-130.192, 54.4702],
                        [-130.1959, 54.4801],
                        [-130.2151, 54.4848],
                        [-130.227, 54.4932],
                        [-130.2451, 54.5115],
                        [-130.2396, 54.5187],
                        [-130.2554, 54.5205],
                        [-130.2621, 54.5273],
                        [-130.2591, 54.5343],
                        [-130.2701, 54.5378],
                        [-130.2828, 54.5452],
                        [-130.2946, 54.5583],
                        [-130.3042, 54.566],
                        [-130.2937, 54.568],
                        [-130.2916, 54.5734],
                        [-130.3048, 54.5818],
                        [-130.3257, 54.5921],
                        [-130.3559, 54.6094],
                        [-130.3574, 54.6137],
                        [-130.3715, 54.6179],
                        [-130.3789, 54.6243],
                        [-130.3925, 54.6314],
                        [-130.3852, 54.6472],
                        [-130.3771, 54.6574],
                        [-130.3762, 54.6658],
                        [-130.3693, 54.6741],
                        [-130.3694, 54.6807],
                        [-130.3496, 54.6947],
                        [-130.3195, 54.6945],
                        [-130.3065, 54.6994],
                        [-130.2908, 54.7012],
                        [-130.2681, 54.7065],
                        [-130.2404, 54.7095],
                        [-130.2373, 54.7146],
                        [-130.2283, 54.7164],
                        [-130.2208, 54.7097],
                        [-130.1966, 54.7101],
                        [-130.1912, 54.714],
                        [-130.1691, 54.7075],
                        [-130.1595, 54.7091],
                        [-130.1462, 54.7034],
                        [-130.1398, 54.6954],
                        [-130.1332, 54.6936],
                        [-130.1284, 54.6843],
                        [-130.113, 54.6766],
                        [-130.1077, 54.6674],
                        [-130.0949, 54.6643],
                        [-130.1038, 54.6763],
                        [-130.0992, 54.6842],
                        [-130.0935, 54.6852],
                        [-130.0895, 54.6753],
                        [-130.0772, 54.6602],
                        [-130.0721, 54.6489],
                        [-130.0648, 54.6397],
                        [-130.0481, 54.6324],
                        [-130.0437, 54.6267],
                        [-130.0244, 54.6203],
                        [-130.0178, 54.6225],
                        [-130.0073, 54.6174],
                        [-129.9964, 54.6083],
                        [-129.9812, 54.603],
                        [-129.9696, 54.6021],
                        [-129.9458, 54.5958],
                        [-129.9348, 54.6025],
                        [-129.9211, 54.6042],
                        [-129.9013, 54.6137],
                        [-129.8956, 54.6215],
                        [-129.9066, 54.6229],
                        [-129.9173, 54.6135],
                        [-129.9378, 54.6102],
                        [-129.9489, 54.6026],
                        [-129.9606, 54.6081],
                        [-129.9739, 54.6098],
                        [-129.9921, 54.6169],
                        [-129.9942, 54.622],
                        [-130.0141, 54.6323],
                        [-130.0187, 54.637],
                        [-130.0402, 54.6431],
                        [-130.0437, 54.6477],
                        [-130.0572, 54.6536],
                        [-130.0632, 54.6632],
                        [-130.0766, 54.6785],
                        [-130.0829, 54.69],
                        [-130.0922, 54.6928],
                        [-130.109, 54.6916],
                        [-130.1192, 54.697],
                        [-130.1234, 54.7037],
                        [-130.1434, 54.7152],
                        [-130.1755, 54.7184],
                        [-130.1949, 54.7226],
                        [-130.2077, 54.7204],
                        [-130.2174, 54.7264],
                        [-130.2084, 54.7316],
                        [-130.1956, 54.7508],
                        [-130.1947, 54.7682],
                        [-130.182, 54.7817],
                        [-130.1652, 54.7741],
                        [-130.1563, 54.7783],
                        [-130.1719, 54.7862],
                        [-130.1761, 54.7934],
                        [-130.1707, 54.8026],
                        [-130.1694, 54.8152],
                        [-130.1599, 54.8206],
                        [-130.1506, 54.8297],
                        [-130.1375, 54.829],
                        [-130.1224, 54.8349],
                        [-130.1075, 54.8385],
                        [-130.1014, 54.8484],
                        [-130.0839, 54.8573],
                        [-130.0744, 54.8672],
                        [-130.0735, 54.8742],
                        [-130.0659, 54.8783],
                        [-130.0561, 54.8922],
                        [-130.0659, 54.8975],
                        [-130.0925, 54.886],
                        [-130.0988, 54.8787],
                        [-130.1, 54.8707],
                        [-130.1215, 54.8543],
                        [-130.1366, 54.8469],
                        [-130.1582, 54.8452],
                        [-130.1736, 54.8417],
                        [-130.1766, 54.8475],
                        [-130.1611, 54.8665],
                        [-130.1317, 54.8896],
                        [-130.1142, 54.9],
                        [-130.0585, 54.9529],
                        [-130.0472, 54.9613],
                        [-130.0381, 54.9644],
                        [-130.0217, 54.9737],
                        [-130.0086, 54.9765],
                        [-130.004, 54.9921],
                        [-129.9963, 55.0045],
                        [-130.0041, 55.0127],
                        [-129.9956, 55.0271],
                        [-129.9947, 55.0328],
                        [-129.9857, 55.0447],
                        [-129.9775, 55.0491],
                        [-129.9876, 55.059],
                        [-129.9763, 55.0751],
                        [-129.9594, 55.0879],
                        [-129.9527, 55.1011],
                        [-129.9307, 55.1191],
                        [-129.9069, 55.1325],
                        [-129.8888, 55.1454],
                        [-129.8786, 55.1579],
                        [-129.8677, 55.1659],
                        [-129.86, 55.1772],
                        [-129.8608, 55.1892],
                        [-129.8545, 55.2049],
                        [-129.8576, 55.2095],
                        [-129.8373, 55.23],
                        [-129.829, 55.2348],
                        [-129.8196, 55.2452],
                        [-129.818, 55.2574],
                        [-129.8067, 55.2687],
                        [-129.7839, 55.2704],
                        [-129.7685, 55.2778],
                        [-129.7548, 55.2811],
                        [-129.7532, 55.2898],
                        [-129.7429, 55.2922],
                        [-129.744, 55.301],
                        [-129.7369, 55.3229],
                        [-129.7294, 55.3321],
                        [-129.7225, 55.3463],
                        [-129.7171, 55.3517],
                        [-129.6927, 55.3661],
                        [-129.6914, 55.3713],
                        [-129.6771, 55.3777],
                        [-129.6807, 55.3824],
                        [-129.6737, 55.3936],
                        [-129.6776, 55.4018],
                        [-129.6673, 55.4109],
                        [-129.6707, 55.415],
                        [-129.6652, 55.4205],
                        [-129.668, 55.426],
                        [-129.6644, 55.432],
                        [-129.6477, 55.436],
                        [-129.6285, 55.4454],
                        [-129.6053, 55.4458],
                        [-129.5826, 55.4427],
                        [-129.5594, 55.4377],
                        [-129.5256, 55.4378],
                        [-129.4943, 55.4457],
                        [-129.4827, 55.459],
                        [-129.4571, 55.4697],
                        [-129.4627, 55.4784],
                        [-129.4828, 55.4797],
                        [-129.4944, 55.4741],
                        [-129.5033, 55.4614],
                        [-129.503, 55.456],
                        [-129.5169, 55.4512],
                        [-129.5311, 55.4508],
                        [-129.5366, 55.4539],
                        [-129.552, 55.4539],
                        [-129.5634, 55.4564],
                        [-129.5891, 55.4584],
                        [-129.6213, 55.4588],
                        [-129.6356, 55.4577],
                        [-129.6527, 55.4527],
                        [-129.6632, 55.4448],
                        [-129.6762, 55.4399],
                        [-129.6814, 55.4227],
                        [-129.6934, 55.4169],
                        [-129.6959, 55.4083],
                        [-129.7107, 55.4136],
                        [-129.7148, 55.4309],
                        [-129.7197, 55.4335],
                        [-129.7238, 55.4522],
                        [-129.7216, 55.4611],
                        [-129.7285, 55.4731],
                        [-129.745, 55.4803],
                        [-129.7518, 55.4973],
                        [-129.7572, 55.5015],
                        [-129.7595, 55.5121],
                        [-129.768, 55.5294],
                        [-129.7737, 55.5345],
                        [-129.7674, 55.5433],
                        [-129.7673, 55.5495],
                        [-129.7811, 55.5592],
                        [-129.7853, 55.5671],
                        [-129.7861, 55.5772],
                        [-129.7772, 55.5949],
                        [-129.7869, 55.6059],
                        [-129.7962, 55.6304],
                        [-129.8135, 55.63],
                        [-129.8124, 55.6147],
                        [-129.8005, 55.5941],
                        [-129.8014, 55.5873],
                        [-129.8109, 55.5749],
                        [-129.8095, 55.5646],
                        [-129.7986, 55.5541],
                        [-129.8015, 55.5436],
                        [-129.7984, 55.5305],
                        [-129.7923, 55.5228],
                        [-129.7892, 55.512],
                        [-129.777, 55.4979],
                        [-129.7743, 55.48],
                        [-129.7673, 55.4731],
                        [-129.7728, 55.4691],
                        [-129.7722, 55.4626],
                        [-129.7783, 55.4555],
                        [-129.7766, 55.4464],
                        [-129.7862, 55.4256],
                        [-129.7974, 55.4261],
                        [-129.811, 55.4199],
                        [-129.8314, 55.4072],
                        [-129.8395, 55.3943],
                        [-129.8325, 55.3875],
                        [-129.8387, 55.385],
                        [-129.8398, 55.374],
                        [-129.8332, 55.3705],
                        [-129.8352, 55.3575],
                        [-129.8144, 55.3797],
                        [-129.8205, 55.3886],
                        [-129.8178, 55.3991],
                        [-129.8036, 55.4116],
                        [-129.7962, 55.4153],
                        [-129.7898, 55.409],
                        [-129.7867, 55.4],
                        [-129.7944, 55.3888],
                        [-129.8088, 55.3812],
                        [-129.8141, 55.3686],
                        [-129.8129, 55.3588],
                        [-129.8013, 55.3632],
                        [-129.7915, 55.377],
                        [-129.7754, 55.3677],
                        [-129.7765, 55.3628],
                        [-129.7911, 55.3648],
                        [-129.7964, 55.3509],
                        [-129.8286, 55.3402],
                        [-129.8255, 55.3337],
                        [-129.8319, 55.3273],
                        [-129.8329, 55.3138],
                        [-129.8157, 55.2898],
                        [-129.8216, 55.2832],
                        [-129.8389, 55.2728],
                        [-129.8506, 55.2702],
                        [-129.8459, 55.2627],
                        [-129.8513, 55.2521],
                        [-129.8651, 55.2395],
                        [-129.8766, 55.2234],
                        [-129.8878, 55.2134],
                        [-129.8916, 55.2022],
                        [-129.8908, 55.1903],
                        [-129.901, 55.1665],
                        [-129.9465, 55.1324],
                        [-129.9572, 55.1262],
                        [-129.9766, 55.1064],
                        [-129.9824, 55.1059],
                        [-129.9868, 55.0974],
                        [-130.0078, 55.0828],
                        [-130.0219, 55.0613],
                        [-130.0258, 55.0593],
                        [-130.0341, 55.03],
                        [-130.0492, 55.0141],
                        [-130.0721, 54.9968],
                        [-130.0937, 54.9849],
                        [-130.1043, 54.9832],
                        [-130.1141, 54.9906],
                        [-130.1241, 55.0054],
                        [-130.1344, 55.0267],
                        [-130.1366, 55.0369],
                        [-130.1328, 55.0429],
                        [-130.1376, 55.0522],
                        [-130.1493, 55.0565],
                        [-130.162, 55.0695],
                        [-130.1659, 55.0797],
                        [-130.1605, 55.0939],
                        [-130.1439, 55.1087],
                        [-130.1359, 55.12],
                        [-130.1295, 55.1479],
                        [-130.1146, 55.158],
                        [-130.1046, 55.1697],
                        [-130.0996, 55.1796],
                        [-130.0755, 55.2015],
                        [-130.0545, 55.2129],
                        [-130.0464, 55.22],
                        [-130.0328, 55.2272],
                        [-130.0087, 55.2497],
                        [-129.9834, 55.2632],
                        [-129.9643, 55.277],
                        [-129.9437, 55.2879],
                        [-129.9455, 55.2943],
                        [-129.9588, 55.2975],
                        [-129.9672, 55.3118],
                        [-129.9679, 55.3171],
                        [-129.9774, 55.3236],
                        [-129.9874, 55.3264],
                        [-130.003, 55.3361],
                        [-130.0047, 55.3458],
                        [-130.0023, 55.354],
                        [-130.0046, 55.3639],
                        [-130.0093, 55.3676],
                        [-130.016, 55.3812],
                        [-130.0137, 55.3987],
                        [-130.018, 55.4085],
                        [-130.0163, 55.4165],
                        [-130.0098, 55.4207],
                        [-130.016, 55.426],
                        [-130.023, 55.449],
                        [-130.0241, 55.4611],
                        [-130.034, 55.4656],
                        [-130.0548, 55.4819],
                        [-130.0576, 55.4874],
                        [-130.0692, 55.4957],
                        [-130.075, 55.5047],
                        [-130.0772, 55.5238],
                        [-130.0878, 55.5302],
                        [-130.0976, 55.5506],
                        [-130.0968, 55.5554],
                        [-130.1062, 55.5601],
                        [-130.1104, 55.5663],
                        [-130.1078, 55.5728],
                        [-130.1104, 55.5809],
                        [-130.1048, 55.5918],
                        [-130.1082, 55.5994],
                        [-130.1054, 55.6056],
                        [-130.1051, 55.6188],
                        [-130.0916, 55.6293],
                        [-130.0941, 55.6376],
                        [-130.0876, 55.6464],
                        [-130.0923, 55.6639],
                        [-130.0907, 55.6778],
                        [-130.0863, 55.687],
                        [-130.0995, 55.6905],
                        [-130.1035, 55.6974],
                        [-130.118, 55.7008],
                        [-130.1205, 55.7075],
                        [-130.1326, 55.7175],
                        [-130.133, 55.7398],
                        [-130.1379, 55.7513],
                        [-130.1335, 55.756],
                        [-130.1334, 55.7655],
                        [-130.1197, 55.7773],
                        [-130.1092, 55.79],
                        [-130.1088, 55.7976],
                        [-130.1, 55.799],
                        [-130.0816, 55.8123],
                        [-130.0683, 55.8182],
                        [-130.0666, 55.826],
                        [-130.0598, 55.8294],
                        [-130.0555, 55.8386],
                        [-130.0386, 55.8528],
                        [-130.0297, 55.8653],
                        [-130.0151, 55.875],
                        [-130.0159, 55.8822],
                        [-130.0045, 55.883],
                        [-130.0049, 55.8882],
                        [-129.9957, 55.8991],
                        [-129.9871, 55.9175],
                        [-129.9942, 55.9225],
                        [-129.9907, 55.9319],
                        [-130.0025, 55.9309],
                        [-130.0082, 55.9249],
                        [-130.0106, 55.9125],
                        [-130.0204, 55.9101],
                        [-130.0021, 56.0066],
                        [-130.0596, 56.0715],
                        [-130.1037, 56.122],
                        [-130.2412, 56.0958],
                        [-130.3151, 56.1161],
                        [-130.3315, 56.1226],
                        [-130.3924, 56.1345],
                        [-130.4197, 56.1389],
                        [-130.4651, 56.2415],
                        [-130.4981, 56.2425],
                        [-130.5341, 56.2467],
                        [-130.5855, 56.2554],
                        [-130.6335, 56.2716],
                        [-130.6562, 56.2834],
                        [-130.6755, 56.298],
                        [-130.7266, 56.3322],
                        [-130.7569, 56.3491],
                        [-130.7814, 56.3681],
                        [-131.0872, 56.4064],
                        [-131.1642, 56.4473],
                        [-131.2021, 56.4583],
                        [-131.2503, 56.4764],
                        [-131.3315, 56.5058],
                        [-131.4192, 56.5345],
                        [-131.4674, 56.5511],
                        [-131.5427, 56.5896],
                        [-131.5818, 56.6124],
                        [-131.6837, 56.6073],
                        [-131.8336, 56.5988],
                        [-131.8575, 56.7018],
                        [-131.9018, 56.7544],
                        [-131.8708, 56.8062],
                        [-132.1211, 56.8669],
                        [-132.0692, 56.9899],
                        [-132.0459, 57.044],
                        [-132.3693, 57.0914],
                        [-132.2446, 57.212],
                        [-132.3307, 57.3063],
                        [-132.3696, 57.3514],
                        [-132.4243, 57.3922],
                        [-132.4783, 57.4379],
                        [-132.5144, 57.4645],
                        [-132.5636, 57.5068],
                        [-132.576, 57.5257],
                        [-132.625, 57.5749],
                        [-132.6867, 57.643],
                        [-132.7195, 57.6691],
                        [-132.7594, 57.7075],
                        [-132.8025, 57.7583],
                        [-132.8229, 57.7876],
                        [-132.8681, 57.8446],
                        [-132.918, 57.8795],
                        [-132.9618, 57.9207],
                        [-132.989, 57.943],
                        [-133.0695, 58.0001],
                        [-133.1724, 58.1516],
                        [-133.3541, 58.2834],
                        [-133.4613, 58.3899],
                        [-133.3818, 58.4326],
                        [-133.5042, 58.4964],
                        [-133.6995, 58.6091],
                        [-133.8413, 58.7301],
                        [-134.0026, 58.7741],
                        [-134.2564, 58.8591],
                        [-134.3374, 58.9203],
                        [-134.3123, 58.9614],
                        [-134.4076, 58.9784],
                        [-134.3854, 59.0414],
                        [-134.4449, 59.0865],
                        [-134.4848, 59.1334],
                        [-134.5698, 59.1335],
                        [-134.6779, 59.1928],
                        [-134.6993, 59.2484],
                        [-134.9589, 59.2811],
                        [-135.0302, 59.3484],
                        [-134.9892, 59.3879],
                        [-135.0975, 59.427],
                        [-135.0683, 59.455],
                        [-135.028, 59.4763],
                        [-135.0277, 59.5639],
                        [-135.1175, 59.6234],
                        [-135.1545, 59.6265],
                        [-135.2218, 59.6641],
                        [-135.2347, 59.6959],
                        [-135.377, 59.7429],
                        [-135.479, 59.7986],
                        [-135.5461, 59.7799],
                        [-135.7448, 59.7223],
                        [-135.9457, 59.6636],
                        [-136.1953, 59.6388],
                        [-136.3557, 59.6007],
                        [-136.2414, 59.5591],
                        [-136.2363, 59.5267],
                        [-136.3014, 59.4658],
                        [-136.3315, 59.456],
                        [-136.3605, 59.4509],
                        [-136.3977, 59.4472],
                        [-136.4762, 59.4642],
                        [-136.4723, 59.388],
                        [-136.4675, 59.2846],
                        [-136.4951, 59.2751],
                        [-136.4888, 59.258],
                        [-136.582, 59.1655],
                        [-136.8247, 59.1598],
                        [-136.867, 59.1427],
                        [-136.9996, 59.0914],
                        [-137.0977, 59.0565],
                        [-137.2366, 59.0114],
                        [-137.2884, 58.9995],
                        [-137.4466, 58.9087],
                        [-137.5267, 58.9066],
                        [-137.4993, 58.9831],
                        [-137.5575, 59.1295],
                        [-137.6021, 59.2407],
                        [-137.878, 59.3814],
                        [-138.0197, 59.4563],
                        [-138.2367, 59.5701],
                        [-138.4192, 59.6641],
                        [-138.6054, 59.7577],
                        [-138.6263, 59.7705],
                        [-138.6563, 59.7992],
                        [-138.7058, 59.9062],
                        [-138.7908, 59.923],
                        [-139.0421, 59.9916],
                        [-139.0522, 60]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "24",
            properties: { cityName: "British Columbia", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-9941 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-131.8079, 53.2382],
                        [-131.8028, 53.2306],
                        [-131.7885, 53.2245],
                        [-131.7866, 53.2076],
                        [-131.7825, 53.2013],
                        [-131.773, 53.1969],
                        [-131.778, 53.1872],
                        [-131.7766, 53.1767],
                        [-131.7792, 53.1724],
                        [-131.7914, 53.1698],
                        [-131.7866, 53.1631],
                        [-131.7773, 53.1585],
                        [-131.7624, 53.1591],
                        [-131.7582, 53.1509],
                        [-131.7628, 53.1428],
                        [-131.7541, 53.1427],
                        [-131.7473, 53.1533],
                        [-131.7381, 53.1531],
                        [-131.7083, 53.1484],
                        [-131.7108, 53.141],
                        [-131.7021, 53.1335],
                        [-131.7064, 53.1263],
                        [-131.7012, 53.1187],
                        [-131.6886, 53.1101],
                        [-131.6734, 53.1082],
                        [-131.6607, 53.1124],
                        [-131.6452, 53.1089],
                        [-131.6386, 53.0969],
                        [-131.6438, 53.0824],
                        [-131.6355, 53.0714],
                        [-131.6398, 53.0666],
                        [-131.6283, 53.0619],
                        [-131.6305, 53.0444],
                        [-131.621, 53.042],
                        [-131.6115, 53.0436],
                        [-131.6061, 53.0381],
                        [-131.6114, 53.0302],
                        [-131.633, 53.0292],
                        [-131.6558, 53.0336],
                        [-131.6535, 53.0417],
                        [-131.6793, 53.0428],
                        [-131.6971, 53.0417],
                        [-131.726, 53.0458],
                        [-131.7342, 53.0496],
                        [-131.7514, 53.0526],
                        [-131.7594, 53.0497],
                        [-131.8056, 53.068],
                        [-131.8211, 53.0656],
                        [-131.8361, 53.0684],
                        [-131.8481, 53.0592],
                        [-131.854, 53.062],
                        [-131.8711, 53.0643],
                        [-131.8877, 53.0642],
                        [-131.8972, 53.0549],
                        [-131.9152, 53.0537],
                        [-131.9172, 53.0485],
                        [-131.9449, 53.0481],
                        [-131.9605, 53.0496],
                        [-131.9758, 53.0539],
                        [-131.9826, 53.0523],
                        [-131.991, 53.0571],
                        [-132.0126, 53.058],
                        [-132.023, 53.0544],
                        [-132.0243, 53.0459],
                        [-132.0016, 53.0424],
                        [-131.9947, 53.0434],
                        [-131.9822, 53.0392],
                        [-131.9654, 53.0412],
                        [-131.9552, 53.0372],
                        [-131.9565, 53.0285],
                        [-131.9631, 53.0207],
                        [-131.9622, 53.014],
                        [-131.9499, 53.0083],
                        [-131.9392, 52.9887],
                        [-131.9266, 52.9841],
                        [-131.9181, 52.9749],
                        [-131.9017, 52.9527],
                        [-131.9036, 52.94],
                        [-131.9134, 52.9337],
                        [-131.9278, 52.9308],
                        [-131.9497, 52.937],
                        [-131.9676, 52.9382],
                        [-131.9673, 52.9337],
                        [-131.9487, 52.9342],
                        [-131.9503, 52.9281],
                        [-131.9409, 52.9237],
                        [-131.9247, 52.9223],
                        [-131.9123, 52.9236],
                        [-131.8997, 52.9209],
                        [-131.8936, 52.9121],
                        [-131.8978, 52.9074],
                        [-131.9101, 52.9095],
                        [-131.929, 52.9035],
                        [-131.9359, 52.8949],
                        [-131.9472, 52.8919],
                        [-131.9793, 52.8866],
                        [-131.9811, 52.8831],
                        [-132.0004, 52.8816],
                        [-131.9997, 52.8769],
                        [-131.9839, 52.876],
                        [-131.9732, 52.8792],
                        [-131.9579, 52.8771],
                        [-131.943, 52.8795],
                        [-131.9258, 52.8905],
                        [-131.9135, 52.895],
                        [-131.8999, 52.895],
                        [-131.8891, 52.892],
                        [-131.875, 52.8789],
                        [-131.8521, 52.8704],
                        [-131.8472, 52.8631],
                        [-131.8587, 52.8575],
                        [-131.8733, 52.8585],
                        [-131.8639, 52.8509],
                        [-131.8726, 52.8416],
                        [-131.8881, 52.8368],
                        [-131.881, 52.8308],
                        [-131.868, 52.8339],
                        [-131.8512, 52.8333],
                        [-131.8355, 52.8191],
                        [-131.8386, 52.8094],
                        [-131.8193, 52.801],
                        [-131.8136, 52.7965],
                        [-131.7734, 52.8017],
                        [-131.7562, 52.7972],
                        [-131.7298, 52.7941],
                        [-131.7196, 52.7974],
                        [-131.6956, 52.7983],
                        [-131.6752, 52.804],
                        [-131.6649, 52.8101],
                        [-131.6536, 52.8047],
                        [-131.6577, 52.7974],
                        [-131.6833, 52.7925],
                        [-131.7001, 52.7849],
                        [-131.7113, 52.7847],
                        [-131.7334, 52.7886],
                        [-131.7586, 52.7879],
                        [-131.7721, 52.7817],
                        [-131.776, 52.777],
                        [-131.7913, 52.7733],
                        [-131.7961, 52.7572],
                        [-131.8078, 52.7563],
                        [-131.8167, 52.766],
                        [-131.8307, 52.7698],
                        [-131.8464, 52.7704],
                        [-131.8674, 52.7601],
                        [-131.8806, 52.7612],
                        [-131.8892, 52.7557],
                        [-131.8846, 52.7514],
                        [-131.8639, 52.7599],
                        [-131.8507, 52.7623],
                        [-131.8413, 52.7601],
                        [-131.8259, 52.76],
                        [-131.8127, 52.7484],
                        [-131.8035, 52.743],
                        [-131.8236, 52.7232],
                        [-131.8322, 52.7193],
                        [-131.8273, 52.7148],
                        [-131.8167, 52.7133],
                        [-131.8151, 52.7051],
                        [-131.8067, 52.7035],
                        [-131.8055, 52.7116],
                        [-131.7936, 52.7151],
                        [-131.7694, 52.7071],
                        [-131.7636, 52.6992],
                        [-131.7515, 52.6935],
                        [-131.744, 52.6939],
                        [-131.7326, 52.6843],
                        [-131.7297, 52.6716],
                        [-131.7236, 52.6617],
                        [-131.7151, 52.6577],
                        [-131.7131, 52.6491],
                        [-131.6969, 52.6349],
                        [-131.6898, 52.6239],
                        [-131.6943, 52.6191],
                        [-131.7148, 52.625],
                        [-131.7207, 52.6249],
                        [-131.7413, 52.6326],
                        [-131.7584, 52.6374],
                        [-131.7636, 52.6339],
                        [-131.7352, 52.6222],
                        [-131.7102, 52.616],
                        [-131.6866, 52.6044],
                        [-131.6771, 52.595],
                        [-131.6837, 52.5884],
                        [-131.6749, 52.5808],
                        [-131.6585, 52.5794],
                        [-131.6509, 52.5725],
                        [-131.6379, 52.5771],
                        [-131.6276, 52.5743],
                        [-131.6184, 52.5671],
                        [-131.6192, 52.5622],
                        [-131.6337, 52.5584],
                        [-131.6409, 52.5473],
                        [-131.6489, 52.5494],
                        [-131.6582, 52.5417],
                        [-131.6767, 52.5481],
                        [-131.6767, 52.5431],
                        [-131.658, 52.535],
                        [-131.6524, 52.5378],
                        [-131.6284, 52.53],
                        [-131.6142, 52.5298],
                        [-131.6146, 52.5249],
                        [-131.6019, 52.5206],
                        [-131.5827, 52.5246],
                        [-131.5764, 52.5361],
                        [-131.5395, 52.5281],
                        [-131.5388, 52.524],
                        [-131.5501, 52.5197],
                        [-131.5441, 52.5115],
                        [-131.5342, 52.5144],
                        [-131.5039, 52.5171],
                        [-131.5042, 52.5112],
                        [-131.4786, 52.5062],
                        [-131.4698, 52.4976],
                        [-131.4796, 52.4956],
                        [-131.4735, 52.4773],
                        [-131.5023, 52.4713],
                        [-131.4936, 52.468],
                        [-131.4849, 52.4712],
                        [-131.471, 52.4725],
                        [-131.4689, 52.4667],
                        [-131.4809, 52.4486],
                        [-131.469, 52.4511],
                        [-131.4674, 52.4632],
                        [-131.4551, 52.47],
                        [-131.4509, 52.462],
                        [-131.4267, 52.4674],
                        [-131.4165, 52.4544],
                        [-131.4183, 52.4395],
                        [-131.4287, 52.4262],
                        [-131.4299, 52.4153],
                        [-131.4269, 52.4078],
                        [-131.4363, 52.405],
                        [-131.4499, 52.3901],
                        [-131.4418, 52.3808],
                        [-131.4269, 52.3964],
                        [-131.4153, 52.3993],
                        [-131.4101, 52.4057],
                        [-131.3931, 52.3998],
                        [-131.3921, 52.3882],
                        [-131.3839, 52.3726],
                        [-131.4056, 52.3649],
                        [-131.4136, 52.3546],
                        [-131.4067, 52.3508],
                        [-131.3917, 52.3577],
                        [-131.3852, 52.3566],
                        [-131.3628, 52.3757],
                        [-131.3533, 52.3641],
                        [-131.3525, 52.3492],
                        [-131.3694, 52.3486],
                        [-131.3661, 52.3439],
                        [-131.3554, 52.3474],
                        [-131.3444, 52.3385],
                        [-131.3455, 52.329],
                        [-131.3515, 52.3236],
                        [-131.3401, 52.3205],
                        [-131.3394, 52.312],
                        [-131.3452, 52.3085],
                        [-131.3331, 52.3033],
                        [-131.3317, 52.296],
                        [-131.3254, 52.2935],
                        [-131.3179, 52.2997],
                        [-131.3058, 52.3007],
                        [-131.2992, 52.2858],
                        [-131.2934, 52.2851],
                        [-131.2802, 52.2775],
                        [-131.2545, 52.2583],
                        [-131.2465, 52.245],
                        [-131.2314, 52.2534],
                        [-131.2305, 52.2593],
                        [-131.2196, 52.2616],
                        [-131.2444, 52.2718],
                        [-131.265, 52.2851],
                        [-131.2447, 52.3041],
                        [-131.2348, 52.3033],
                        [-131.2261, 52.2941],
                        [-131.2181, 52.2929],
                        [-131.2183, 52.3017],
                        [-131.2232, 52.306],
                        [-131.2146, 52.3132],
                        [-131.1745, 52.3221],
                        [-131.1622, 52.3187],
                        [-131.1372, 52.3151],
                        [-131.145, 52.3076],
                        [-131.1376, 52.3046],
                        [-131.1347, 52.2961],
                        [-131.139, 52.2901],
                        [-131.1284, 52.2802],
                        [-131.1115, 52.2869],
                        [-131.0954, 52.2896],
                        [-131.0846, 52.2733],
                        [-131.0839, 52.2657],
                        [-131.0946, 52.2591],
                        [-131.0895, 52.256],
                        [-131.086, 52.2467],
                        [-131.1149, 52.2448],
                        [-131.1206, 52.2461],
                        [-131.1463, 52.242],
                        [-131.158, 52.2415],
                        [-131.1906, 52.2254],
                        [-131.1772, 52.2239],
                        [-131.1619, 52.2267],
                        [-131.1407, 52.2329],
                        [-131.1298, 52.2302],
                        [-131.1239, 52.2255],
                        [-131.1239, 52.2153],
                        [-131.1071, 52.2185],
                        [-131.1004, 52.2238],
                        [-131.0895, 52.2238],
                        [-131.0819, 52.215],
                        [-131.0584, 52.2226],
                        [-131.0453, 52.2232],
                        [-131.0326, 52.232],
                        [-131.0205, 52.2292],
                        [-131.0071, 52.2148],
                        [-131.013, 52.2084],
                        [-131.0105, 52.1951],
                        [-131.0149, 52.1815],
                        [-131.0269, 52.1738],
                        [-131.0407, 52.1692],
                        [-131.0451, 52.1633],
                        [-131.0595, 52.1665],
                        [-131.0668, 52.1638],
                        [-131.0742, 52.1672],
                        [-131.09, 52.1664],
                        [-131.0953, 52.1685],
                        [-131.1216, 52.1659],
                        [-131.1424, 52.1615],
                        [-131.1396, 52.1508],
                        [-131.1424, 52.1387],
                        [-131.1543, 52.1276],
                        [-131.1728, 52.1241],
                        [-131.1787, 52.118],
                        [-131.1936, 52.1214],
                        [-131.195, 52.127],
                        [-131.2093, 52.1445],
                        [-131.2083, 52.1498],
                        [-131.2186, 52.158],
                        [-131.2112, 52.1665],
                        [-131.2206, 52.1835],
                        [-131.2382, 52.1976],
                        [-131.2468, 52.2093],
                        [-131.2632, 52.2163],
                        [-131.2821, 52.2288],
                        [-131.2818, 52.2198],
                        [-131.2729, 52.2121],
                        [-131.2705, 52.2047],
                        [-131.2543, 52.1918],
                        [-131.2465, 52.181],
                        [-131.2451, 52.1741],
                        [-131.2324, 52.1579],
                        [-131.2426, 52.1516],
                        [-131.2446, 52.1441],
                        [-131.2394, 52.1339],
                        [-131.2335, 52.1303],
                        [-131.247, 52.1238],
                        [-131.2552, 52.127],
                        [-131.2628, 52.1201],
                        [-131.2694, 52.1257],
                        [-131.2715, 52.1348],
                        [-131.2832, 52.1463],
                        [-131.3019, 52.1466],
                        [-131.3119, 52.1505],
                        [-131.2968, 52.1552],
                        [-131.3047, 52.1643],
                        [-131.3043, 52.1706],
                        [-131.3218, 52.1804],
                        [-131.3292, 52.193],
                        [-131.3416, 52.1974],
                        [-131.3502, 52.2084],
                        [-131.3417, 52.2173],
                        [-131.3586, 52.2298],
                        [-131.3601, 52.2372],
                        [-131.3517, 52.2401],
                        [-131.3622, 52.2604],
                        [-131.3754, 52.2738],
                        [-131.3774, 52.2664],
                        [-131.3733, 52.2446],
                        [-131.3647, 52.2324],
                        [-131.3604, 52.2209],
                        [-131.3649, 52.2172],
                        [-131.3599, 52.2036],
                        [-131.3604, 52.1865],
                        [-131.3709, 52.1918],
                        [-131.374, 52.1968],
                        [-131.3906, 52.1952],
                        [-131.4102, 52.2134],
                        [-131.421, 52.2155],
                        [-131.4298, 52.2218],
                        [-131.4278, 52.2316],
                        [-131.4348, 52.2351],
                        [-131.4443, 52.2487],
                        [-131.4463, 52.259],
                        [-131.4684, 52.2757],
                        [-131.4832, 52.2783],
                        [-131.4921, 52.2884],
                        [-131.5026, 52.2889],
                        [-131.5151, 52.3014],
                        [-131.5069, 52.3127],
                        [-131.5109, 52.3208],
                        [-131.5174, 52.3225],
                        [-131.5317, 52.3169],
                        [-131.5365, 52.3202],
                        [-131.5543, 52.3247],
                        [-131.5583, 52.3294],
                        [-131.5463, 52.3341],
                        [-131.5307, 52.3476],
                        [-131.56, 52.3501],
                        [-131.5752, 52.3438],
                        [-131.5829, 52.3591],
                        [-131.578, 52.3651],
                        [-131.5911, 52.3723],
                        [-131.5997, 52.3824],
                        [-131.592, 52.3915],
                        [-131.5905, 52.3999],
                        [-131.5834, 52.4044],
                        [-131.5708, 52.4063],
                        [-131.5664, 52.4097],
                        [-131.5547, 52.4048],
                        [-131.5378, 52.4077],
                        [-131.5342, 52.4042],
                        [-131.5084, 52.3931],
                        [-131.5049, 52.4063],
                        [-131.5174, 52.4158],
                        [-131.5357, 52.4157],
                        [-131.5537, 52.4233],
                        [-131.5472, 52.4381],
                        [-131.5579, 52.4334],
                        [-131.5713, 52.4342],
                        [-131.5751, 52.4257],
                        [-131.5868, 52.4214],
                        [-131.5984, 52.4212],
                        [-131.6131, 52.4178],
                        [-131.6136, 52.4234],
                        [-131.6274, 52.4305],
                        [-131.632, 52.4431],
                        [-131.6232, 52.4456],
                        [-131.6196, 52.4526],
                        [-131.6265, 52.4561],
                        [-131.6451, 52.4488],
                        [-131.6468, 52.4549],
                        [-131.6543, 52.4561],
                        [-131.6489, 52.464],
                        [-131.6551, 52.4734],
                        [-131.6706, 52.4784],
                        [-131.6937, 52.4818],
                        [-131.7041, 52.4881],
                        [-131.7152, 52.4889],
                        [-131.7294, 52.4986],
                        [-131.7432, 52.4954],
                        [-131.7477, 52.5],
                        [-131.7723, 52.5096],
                        [-131.7826, 52.5115],
                        [-131.7875, 52.5206],
                        [-131.7847, 52.5285],
                        [-131.7521, 52.5307],
                        [-131.7596, 52.5346],
                        [-131.7701, 52.5326],
                        [-131.7871, 52.5342],
                        [-131.7954, 52.5297],
                        [-131.8081, 52.5302],
                        [-131.8195, 52.5478],
                        [-131.8344, 52.5476],
                        [-131.8343, 52.5604],
                        [-131.8391, 52.5641],
                        [-131.8254, 52.5684],
                        [-131.8122, 52.5682],
                        [-131.7996, 52.5741],
                        [-131.7868, 52.5775],
                        [-131.7902, 52.5829],
                        [-131.8122, 52.5742],
                        [-131.8399, 52.5707],
                        [-131.8528, 52.5619],
                        [-131.8639, 52.5682],
                        [-131.8626, 52.5761],
                        [-131.8709, 52.5778],
                        [-131.8816, 52.5756],
                        [-131.8953, 52.582],
                        [-131.8911, 52.5887],
                        [-131.9064, 52.5877],
                        [-131.9124, 52.5948],
                        [-131.908, 52.6059],
                        [-131.9024, 52.6071],
                        [-131.8856, 52.6045],
                        [-131.8706, 52.6057],
                        [-131.8901, 52.6145],
                        [-131.9076, 52.6107],
                        [-131.9183, 52.618],
                        [-131.9326, 52.6172],
                        [-131.9368, 52.6229],
                        [-131.9335, 52.6422],
                        [-131.9144, 52.6503],
                        [-131.9046, 52.6481],
                        [-131.8964, 52.6517],
                        [-131.8865, 52.6468],
                        [-131.8793, 52.6517],
                        [-131.8945, 52.6564],
                        [-131.9139, 52.6573],
                        [-131.9355, 52.6505],
                        [-131.9456, 52.6522],
                        [-131.9441, 52.662],
                        [-131.9557, 52.6601],
                        [-131.9536, 52.6503],
                        [-131.9726, 52.6469],
                        [-131.9817, 52.6547],
                        [-131.9954, 52.6605],
                        [-132.0008, 52.6671],
                        [-132.0022, 52.6759],
                        [-132.013, 52.6697],
                        [-132.0303, 52.6779],
                        [-132.0249, 52.684],
                        [-132.0446, 52.684],
                        [-132.0622, 52.6985],
                        [-132.0693, 52.6997],
                        [-132.0746, 52.7106],
                        [-132.101, 52.7254],
                        [-132.1083, 52.7348],
                        [-132.0971, 52.748],
                        [-132.0885, 52.7466],
                        [-132.0785, 52.7491],
                        [-132.07, 52.7567],
                        [-132.0564, 52.7631],
                        [-132.0358, 52.7674],
                        [-132.0246, 52.7519],
                        [-132.0113, 52.7507],
                        [-132.0034, 52.7383],
                        [-131.9964, 52.7378],
                        [-131.9886, 52.7226],
                        [-131.982, 52.7196],
                        [-131.9824, 52.7347],
                        [-131.9873, 52.7415],
                        [-132.0049, 52.755],
                        [-132.0171, 52.7613],
                        [-132.0246, 52.7711],
                        [-132.0143, 52.7834],
                        [-132.0063, 52.7794],
                        [-131.9906, 52.7647],
                        [-131.9865, 52.7644],
                        [-131.966, 52.748],
                        [-131.9574, 52.739],
                        [-131.9485, 52.7375],
                        [-131.9209, 52.7137],
                        [-131.9193, 52.7206],
                        [-131.933, 52.7297],
                        [-131.9454, 52.7425],
                        [-131.9537, 52.7457],
                        [-131.9574, 52.7516],
                        [-131.9416, 52.754],
                        [-131.9502, 52.773],
                        [-131.9631, 52.7844],
                        [-131.9705, 52.7948],
                        [-131.9845, 52.7945],
                        [-132.0015, 52.8009],
                        [-132.0139, 52.7968],
                        [-132.0265, 52.7986],
                        [-132.04, 52.8054],
                        [-132.0371, 52.8099],
                        [-132.0491, 52.8182],
                        [-132.049, 52.8249],
                        [-132.0633, 52.8375],
                        [-132.0726, 52.8519],
                        [-132.0873, 52.8499],
                        [-132.0918, 52.8453],
                        [-132.0853, 52.8369],
                        [-132.063, 52.8131],
                        [-132.0609, 52.8034],
                        [-132.0677, 52.8],
                        [-132.0616, 52.7909],
                        [-132.0689, 52.7865],
                        [-132.076, 52.789],
                        [-132.0979, 52.7866],
                        [-132.1055, 52.7708],
                        [-132.101, 52.766],
                        [-132.1024, 52.757],
                        [-132.1165, 52.7455],
                        [-132.1267, 52.7468],
                        [-132.1342, 52.744],
                        [-132.149, 52.7524],
                        [-132.1552, 52.7639],
                        [-132.1626, 52.7678],
                        [-132.1752, 52.7849],
                        [-132.1915, 52.7843],
                        [-132.1833, 52.7907],
                        [-132.2058, 52.7945],
                        [-132.2171, 52.7989],
                        [-132.2357, 52.8009],
                        [-132.2454, 52.8148],
                        [-132.245, 52.8195],
                        [-132.2566, 52.8278],
                        [-132.264, 52.8403],
                        [-132.2569, 52.8508],
                        [-132.2352, 52.8614],
                        [-132.2259, 52.8574],
                        [-132.2095, 52.858],
                        [-132.2137, 52.849],
                        [-132.2018, 52.8473],
                        [-132.1981, 52.8436],
                        [-132.1781, 52.847],
                        [-132.1812, 52.8512],
                        [-132.1988, 52.8493],
                        [-132.2048, 52.8587],
                        [-132.2239, 52.8613],
                        [-132.2343, 52.865],
                        [-132.2533, 52.8668],
                        [-132.2614, 52.8652],
                        [-132.271, 52.8724],
                        [-132.2785, 52.8712],
                        [-132.3113, 52.8869],
                        [-132.3141, 52.8911],
                        [-132.3254, 52.8935],
                        [-132.325, 52.8996],
                        [-132.3094, 52.9013],
                        [-132.3021, 52.9063],
                        [-132.2894, 52.9051],
                        [-132.2734, 52.907],
                        [-132.2636, 52.9132],
                        [-132.2938, 52.9091],
                        [-132.3025, 52.9117],
                        [-132.3125, 52.9036],
                        [-132.3242, 52.9071],
                        [-132.3437, 52.9211],
                        [-132.3656, 52.9291],
                        [-132.3553, 52.9387],
                        [-132.3571, 52.9473],
                        [-132.3482, 52.9476],
                        [-132.3375, 52.9523],
                        [-132.316, 52.9552],
                        [-132.3005, 52.9526],
                        [-132.2881, 52.9538],
                        [-132.2852, 52.9593],
                        [-132.2696, 52.9569],
                        [-132.258, 52.959],
                        [-132.2209, 52.9459],
                        [-132.2125, 52.9461],
                        [-132.2091, 52.9388],
                        [-132.1993, 52.9292],
                        [-132.193, 52.9302],
                        [-132.1747, 52.9272],
                        [-132.1744, 52.933],
                        [-132.1898, 52.9347],
                        [-132.2007, 52.9438],
                        [-132.2299, 52.9605],
                        [-132.2251, 52.9657],
                        [-132.211, 52.9693],
                        [-132.1994, 52.9615],
                        [-132.1785, 52.9559],
                        [-132.1693, 52.945],
                        [-132.1485, 52.9461],
                        [-132.1692, 52.9593],
                        [-132.1906, 52.967],
                        [-132.1788, 52.9697],
                        [-132.1666, 52.9766],
                        [-132.1577, 52.9729],
                        [-132.1458, 52.9613],
                        [-132.1366, 52.9556],
                        [-132.1261, 52.9546],
                        [-132.1428, 52.9703],
                        [-132.1569, 52.9782],
                        [-132.1492, 52.9899],
                        [-132.1338, 52.9934],
                        [-132.1237, 52.9878],
                        [-132.1067, 52.9815],
                        [-132.1019, 52.9742],
                        [-132.0881, 52.9667],
                        [-132.0861, 52.9725],
                        [-132.1025, 52.9795],
                        [-132.1004, 52.9821],
                        [-132.1194, 52.9944],
                        [-132.1438, 52.9983],
                        [-132.151, 52.9956],
                        [-132.1749, 52.993],
                        [-132.2094, 52.9966],
                        [-132.2358, 53.0071],
                        [-132.2515, 53.0098],
                        [-132.2472, 53.0144],
                        [-132.2563, 53.0182],
                        [-132.2724, 53.0207],
                        [-132.2857, 53.0186],
                        [-132.308, 53.0188],
                        [-132.3216, 53.0268],
                        [-132.3272, 53.0241],
                        [-132.3526, 53.0188],
                        [-132.3605, 53.0263],
                        [-132.352, 53.0301],
                        [-132.3338, 53.0327],
                        [-132.3268, 53.0388],
                        [-132.3051, 53.0424],
                        [-132.291, 53.0481],
                        [-132.2839, 53.0538],
                        [-132.2675, 53.0536],
                        [-132.2654, 53.0594],
                        [-132.2852, 53.0623],
                        [-132.2953, 53.0509],
                        [-132.3079, 53.0459],
                        [-132.323, 53.0447],
                        [-132.3347, 53.0375],
                        [-132.3375, 53.0468],
                        [-132.3571, 53.0345],
                        [-132.3864, 53.0273],
                        [-132.4069, 53.0253],
                        [-132.4174, 53.028],
                        [-132.426, 53.0347],
                        [-132.4261, 53.0403],
                        [-132.4379, 53.0427],
                        [-132.4549, 53.0334],
                        [-132.4682, 53.0356],
                        [-132.4862, 53.028],
                        [-132.4976, 53.0273],
                        [-132.505, 53.0339],
                        [-132.5143, 53.0347],
                        [-132.521, 53.0405],
                        [-132.5331, 53.0457],
                        [-132.5258, 53.0494],
                        [-132.5254, 53.0669],
                        [-132.5442, 53.0675],
                        [-132.5584, 53.0726],
                        [-132.5764, 53.088],
                        [-132.5713, 53.0973],
                        [-132.5585, 53.0968],
                        [-132.541, 53.1002],
                        [-132.5182, 53.0965],
                        [-132.4801, 53.0995],
                        [-132.4566, 53.0958],
                        [-132.4308, 53.099],
                        [-132.4162, 53.0992],
                        [-132.3931, 53.1039],
                        [-132.3899, 53.1078],
                        [-132.3892, 53.1192],
                        [-132.3921, 53.1276],
                        [-132.3519, 53.1348],
                        [-132.3533, 53.1466],
                        [-132.3331, 53.1464],
                        [-132.3017, 53.1532],
                        [-132.287, 53.1523],
                        [-132.2718, 53.1471],
                        [-132.2492, 53.1456],
                        [-132.2315, 53.1393],
                        [-132.2153, 53.1417],
                        [-132.2004, 53.1472],
                        [-132.1823, 53.1476],
                        [-132.161, 53.1567],
                        [-132.1442, 53.1598],
                        [-132.126, 53.1585],
                        [-132.123, 53.1485],
                        [-132.0945, 53.1518],
                        [-132.084, 53.148],
                        [-132.0725, 53.1581],
                        [-132.0441, 53.1692],
                        [-132.0301, 53.1839],
                        [-132.0139, 53.1984],
                        [-131.982, 53.2011],
                        [-131.9837, 53.2139],
                        [-131.9754, 53.2151],
                        [-131.9592, 53.2133],
                        [-131.9445, 53.2241],
                        [-131.9353, 53.227],
                        [-131.918, 53.239],
                        [-131.8829, 53.2356],
                        [-131.8754, 53.2391],
                        [-131.8538, 53.2364],
                        [-131.8366, 53.2395],
                        [-131.8265, 53.2447],
                        [-131.8196, 53.2603],
                        [-131.8027, 53.2537],
                        [-131.8079, 53.2382]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "25",
            properties: { cityName: "British Columbia", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-9941 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-129.029, 53.3065],
                        [-129.0195, 53.306],
                        [-129.0027, 53.3011],
                        [-128.9941, 53.2952],
                        [-128.9683, 53.2846],
                        [-128.9407, 53.2903],
                        [-128.9222, 53.2889],
                        [-128.9208, 53.2954],
                        [-128.9071, 53.2984],
                        [-128.898, 53.2913],
                        [-128.8879, 53.2766],
                        [-128.8869, 53.2719],
                        [-128.8703, 53.259],
                        [-128.8443, 53.2487],
                        [-128.8148, 53.2283],
                        [-128.7478, 53.1948],
                        [-128.7455, 53.1919],
                        [-128.7142, 53.1765],
                        [-128.6981, 53.1655],
                        [-128.6945, 53.1591],
                        [-128.6857, 53.163],
                        [-128.6684, 53.1613],
                        [-128.66, 53.1585],
                        [-128.6377, 53.1569],
                        [-128.6271, 53.1463],
                        [-128.6178, 53.1406],
                        [-128.6052, 53.1287],
                        [-128.6008, 53.1173],
                        [-128.5959, 53.1118],
                        [-128.5866, 53.108],
                        [-128.5757, 53.0938],
                        [-128.5762, 53.0881],
                        [-128.5694, 53.0794],
                        [-128.5629, 53.0569],
                        [-128.5542, 53.0476],
                        [-128.5393, 53.0276],
                        [-128.5323, 53.007],
                        [-128.5324, 52.9854],
                        [-128.5236, 52.965],
                        [-128.5247, 52.949],
                        [-128.5221, 52.9433],
                        [-128.526, 52.9128],
                        [-128.5224, 52.9058],
                        [-128.5263, 52.8911],
                        [-128.5337, 52.8815],
                        [-128.5422, 52.8775],
                        [-128.5431, 52.8598],
                        [-128.5406, 52.8412],
                        [-128.546, 52.8263],
                        [-128.5451, 52.8051],
                        [-128.55, 52.7899],
                        [-128.5555, 52.7827],
                        [-128.5546, 52.773],
                        [-128.5608, 52.7634],
                        [-128.5629, 52.7429],
                        [-128.5655, 52.7343],
                        [-128.5788, 52.7319],
                        [-128.5824, 52.7385],
                        [-128.5787, 52.7433],
                        [-128.5916, 52.7449],
                        [-128.5879, 52.7304],
                        [-128.5839, 52.7276],
                        [-128.5786, 52.715],
                        [-128.5764, 52.6992],
                        [-128.5699, 52.6865],
                        [-128.5844, 52.6745],
                        [-128.5911, 52.6712],
                        [-128.5992, 52.6743],
                        [-128.6302, 52.6559],
                        [-128.6297, 52.6438],
                        [-128.6334, 52.6391],
                        [-128.6489, 52.633],
                        [-128.6647, 52.6391],
                        [-128.6716, 52.6368],
                        [-128.6537, 52.6272],
                        [-128.64, 52.6294],
                        [-128.6293, 52.6364],
                        [-128.6284, 52.642],
                        [-128.62, 52.6454],
                        [-128.6249, 52.6516],
                        [-128.6101, 52.658],
                        [-128.5985, 52.6688],
                        [-128.5897, 52.6669],
                        [-128.5766, 52.6691],
                        [-128.574, 52.665],
                        [-128.5789, 52.649],
                        [-128.589, 52.6438],
                        [-128.5855, 52.6354],
                        [-128.5873, 52.6287],
                        [-128.5941, 52.627],
                        [-128.5931, 52.6085],
                        [-128.6062, 52.6054],
                        [-128.6247, 52.6078],
                        [-128.6327, 52.6125],
                        [-128.6611, 52.6117],
                        [-128.6778, 52.6091],
                        [-128.6834, 52.6105],
                        [-128.7014, 52.6066],
                        [-128.7155, 52.6094],
                        [-128.7321, 52.6031],
                        [-128.7551, 52.6003],
                        [-128.7599, 52.6065],
                        [-128.7566, 52.6298],
                        [-128.7507, 52.643],
                        [-128.7542, 52.654],
                        [-128.7392, 52.6551],
                        [-128.7454, 52.6631],
                        [-128.7383, 52.673],
                        [-128.7456, 52.6916],
                        [-128.7425, 52.7026],
                        [-128.7574, 52.7263],
                        [-128.7599, 52.7501],
                        [-128.7559, 52.7656],
                        [-128.7441, 52.7723],
                        [-128.7475, 52.7814],
                        [-128.7392, 52.7807],
                        [-128.7319, 52.7928],
                        [-128.7277, 52.8108],
                        [-128.7317, 52.8209],
                        [-128.7069, 52.8626],
                        [-128.6966, 52.869],
                        [-128.6827, 52.8572],
                        [-128.6793, 52.8617],
                        [-128.6886, 52.8678],
                        [-128.686, 52.8779],
                        [-128.6736, 52.8873],
                        [-128.664, 52.8885],
                        [-128.6558, 52.9005],
                        [-128.6562, 52.9204],
                        [-128.6511, 52.9292],
                        [-128.6542, 52.9586],
                        [-128.6611, 52.9705],
                        [-128.6822, 52.9688],
                        [-128.6875, 52.9612],
                        [-128.6829, 52.9555],
                        [-128.6832, 52.9399],
                        [-128.6743, 52.925],
                        [-128.6779, 52.9081],
                        [-128.6982, 52.8934],
                        [-128.7186, 52.8946],
                        [-128.7187, 52.902],
                        [-128.729, 52.8992],
                        [-128.7209, 52.8828],
                        [-128.7265, 52.8654],
                        [-128.73, 52.8633],
                        [-128.7349, 52.85],
                        [-128.7495, 52.8366],
                        [-128.7759, 52.8451],
                        [-128.7765, 52.8424],
                        [-128.7637, 52.8358],
                        [-128.7534, 52.828],
                        [-128.755, 52.8169],
                        [-128.751, 52.8058],
                        [-128.7572, 52.803],
                        [-128.7649, 52.8097],
                        [-128.7734, 52.8016],
                        [-128.7761, 52.7735],
                        [-128.7806, 52.7696],
                        [-128.7844, 52.7558],
                        [-128.7905, 52.7519],
                        [-128.7864, 52.7464],
                        [-128.7913, 52.7337],
                        [-128.7823, 52.7275],
                        [-128.7784, 52.7186],
                        [-128.7651, 52.7042],
                        [-128.7617, 52.671],
                        [-128.7688, 52.6665],
                        [-128.7758, 52.6678],
                        [-128.7908, 52.6643],
                        [-128.7822, 52.6754],
                        [-128.7887, 52.6819],
                        [-128.7969, 52.6842],
                        [-128.7994, 52.6953],
                        [-128.8106, 52.6893],
                        [-128.8158, 52.6727],
                        [-128.8259, 52.68],
                        [-128.8507, 52.6726],
                        [-128.8498, 52.6654],
                        [-128.842, 52.6648],
                        [-128.8472, 52.6563],
                        [-128.8602, 52.6556],
                        [-128.8707, 52.6628],
                        [-128.8863, 52.6569],
                        [-128.8803, 52.6525],
                        [-128.8927, 52.6489],
                        [-128.8944, 52.6551],
                        [-128.9155, 52.659],
                        [-128.9289, 52.6643],
                        [-128.9273, 52.6698],
                        [-128.9459, 52.6801],
                        [-128.9617, 52.6863],
                        [-128.9742, 52.6881],
                        [-129.0066, 52.7023],
                        [-129.0028, 52.7122],
                        [-128.991, 52.7209],
                        [-128.981, 52.7191],
                        [-128.9757, 52.736],
                        [-128.9847, 52.7402],
                        [-128.9875, 52.7299],
                        [-128.9855, 52.7224],
                        [-128.9962, 52.7244],
                        [-129.0083, 52.7113],
                        [-129.0175, 52.7099],
                        [-129.0251, 52.7163],
                        [-129.0424, 52.7238],
                        [-129.0626, 52.7385],
                        [-129.0673, 52.751],
                        [-129.0668, 52.7573],
                        [-129.0527, 52.7602],
                        [-129.0355, 52.7597],
                        [-129.0246, 52.7663],
                        [-129.0107, 52.7689],
                        [-128.9976, 52.7745],
                        [-129.0085, 52.7782],
                        [-129.0122, 52.7711],
                        [-129.0292, 52.7652],
                        [-129.0455, 52.7624],
                        [-129.0646, 52.766],
                        [-129.0787, 52.7652],
                        [-129.1028, 52.7859],
                        [-129.0998, 52.7913],
                        [-129.0752, 52.8009],
                        [-129.0811, 52.8035],
                        [-129.0945, 52.795],
                        [-129.1048, 52.7914],
                        [-129.1134, 52.7985],
                        [-129.1236, 52.8236],
                        [-129.137, 52.8481],
                        [-129.1283, 52.8517],
                        [-129.1291, 52.864],
                        [-129.1355, 52.8713],
                        [-129.1263, 52.8733],
                        [-129.1251, 52.8822],
                        [-129.1153, 52.8821],
                        [-129.0995, 52.8764],
                        [-129.0862, 52.8747],
                        [-129.0824, 52.8709],
                        [-129.0709, 52.8691],
                        [-129.0672, 52.8627],
                        [-129.0521, 52.8615],
                        [-129.04, 52.8569],
                        [-129.0218, 52.8414],
                        [-129.0149, 52.8437],
                        [-129.0315, 52.8531],
                        [-129.0369, 52.8668],
                        [-129.0497, 52.8708],
                        [-129.0552, 52.8686],
                        [-129.0698, 52.8779],
                        [-129.091, 52.8835],
                        [-129.0946, 52.8888],
                        [-129.0475, 52.9055],
                        [-129.0359, 52.9066],
                        [-129.0257, 52.9128],
                        [-129.0102, 52.9258],
                        [-128.9813, 52.9316],
                        [-128.9771, 52.9401],
                        [-128.9833, 52.9471],
                        [-128.971, 52.9649],
                        [-128.9741, 52.9808],
                        [-128.9672, 52.9882],
                        [-128.9452, 53.0025],
                        [-128.9313, 53.0139],
                        [-128.9281, 53.0209],
                        [-128.9158, 53.024],
                        [-128.9097, 53.0222],
                        [-128.8986, 53.0267],
                        [-128.9006, 53.0306],
                        [-128.9206, 53.0327],
                        [-128.9369, 53.0249],
                        [-128.9442, 53.0119],
                        [-128.9539, 53.012],
                        [-128.9553, 53.0055],
                        [-128.969, 52.9957],
                        [-128.9746, 52.9946],
                        [-128.9872, 52.9725],
                        [-128.9839, 52.9684],
                        [-128.9868, 52.9589],
                        [-129.004, 52.9436],
                        [-129.0273, 52.9287],
                        [-129.0307, 52.9245],
                        [-129.05, 52.9148],
                        [-129.0747, 52.906],
                        [-129.0868, 52.9047],
                        [-129.0924, 52.9013],
                        [-129.107, 52.8984],
                        [-129.1262, 52.8995],
                        [-129.1316, 52.9158],
                        [-129.1279, 52.9221],
                        [-129.1361, 52.9414],
                        [-129.1378, 52.9571],
                        [-129.1311, 52.9797],
                        [-129.1364, 52.9802],
                        [-129.1397, 52.9637],
                        [-129.1459, 52.9591],
                        [-129.1441, 52.9522],
                        [-129.1479, 52.9442],
                        [-129.1387, 52.9358],
                        [-129.1374, 52.926],
                        [-129.1489, 52.9235],
                        [-129.1615, 52.9238],
                        [-129.1669, 52.9193],
                        [-129.1747, 52.9303],
                        [-129.1863, 52.9394],
                        [-129.1894, 52.9483],
                        [-129.1966, 52.9499],
                        [-129.2001, 52.9637],
                        [-129.1963, 52.9717],
                        [-129.2009, 52.9807],
                        [-129.1977, 52.986],
                        [-129.2009, 52.9982],
                        [-129.1971, 53.0024],
                        [-129.1982, 53.0107],
                        [-129.1863, 53.0333],
                        [-129.1752, 53.0411],
                        [-129.172, 53.0517],
                        [-129.1681, 53.053],
                        [-129.1831, 53.0674],
                        [-129.1693, 53.0717],
                        [-129.1502, 53.0726],
                        [-129.1246, 53.0781],
                        [-129.1185, 53.0692],
                        [-129.1002, 53.0661],
                        [-129.0973, 53.0821],
                        [-129.0937, 53.0889],
                        [-129.0822, 53.097],
                        [-129.0878, 53.103],
                        [-129.0843, 53.1074],
                        [-129.0893, 53.1147],
                        [-129.0833, 53.1325],
                        [-129.0865, 53.1575],
                        [-129.0859, 53.166],
                        [-129.0909, 53.1709],
                        [-129.0887, 53.1785],
                        [-129.0959, 53.1839],
                        [-129.0925, 53.1907],
                        [-129.0668, 53.1982],
                        [-129.0579, 53.2066],
                        [-129.0485, 53.2088],
                        [-129.0376, 53.199],
                        [-129.0193, 53.1955],
                        [-129.0022, 53.1834],
                        [-128.9901, 53.1793],
                        [-128.9952, 53.1753],
                        [-128.9859, 53.1538],
                        [-128.9878, 53.1457],
                        [-128.98, 53.1382],
                        [-128.9736, 53.1419],
                        [-128.9862, 53.1716],
                        [-128.978, 53.1735],
                        [-128.9648, 53.17],
                        [-128.9457, 53.1583],
                        [-128.9183, 53.1661],
                        [-128.8991, 53.1605],
                        [-128.8903, 53.1636],
                        [-128.9148, 53.1698],
                        [-128.9387, 53.1646],
                        [-128.9485, 53.1646],
                        [-128.9507, 53.1695],
                        [-128.9659, 53.1742],
                        [-128.9823, 53.1839],
                        [-128.9901, 53.1847],
                        [-128.9988, 53.1956],
                        [-129.0144, 53.1986],
                        [-129.0411, 53.206],
                        [-129.0518, 53.2127],
                        [-129.0631, 53.2259],
                        [-129.0682, 53.2434],
                        [-129.0743, 53.2455],
                        [-129.085, 53.2592],
                        [-129.0897, 53.2693],
                        [-129.0766, 53.2694],
                        [-129.0742, 53.2731],
                        [-129.0822, 53.2787],
                        [-129.0918, 53.2811],
                        [-129.0958, 53.2876],
                        [-129.0848, 53.2935],
                        [-129.0709, 53.2953],
                        [-129.061, 53.3008],
                        [-129.0363, 53.308],
                        [-129.029, 53.3065]
                    ]
                ],
            },
        },  
        {
            type: "Feature",
            id: "27",
            properties: { cityName: "British Columbia", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-9941 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-131.6763, 54.0721],
                        [-131.6793, 54.0583],
                        [-131.678, 54.053],
                        [-131.689, 54.0278],
                        [-131.6963, 54.0037],
                        [-131.708, 53.9773],
                        [-131.7272, 53.9494],
                        [-131.7594, 53.9121],
                        [-131.7731, 53.8981],
                        [-131.806, 53.8681],
                        [-131.8147, 53.8591],
                        [-131.8288, 53.8394],
                        [-131.8473, 53.8235],
                        [-131.8626, 53.8069],
                        [-131.8707, 53.7953],
                        [-131.8847, 53.7702],
                        [-131.8842, 53.7612],
                        [-131.8771, 53.7438],
                        [-131.878, 53.738],
                        [-131.8743, 53.72],
                        [-131.8744, 53.7088],
                        [-131.8796, 53.6985],
                        [-131.8989, 53.6746],
                        [-131.9057, 53.668],
                        [-131.9178, 53.6502],
                        [-131.9186, 53.6431],
                        [-131.9334, 53.6195],
                        [-131.931, 53.616],
                        [-131.9331, 53.6043],
                        [-131.9306, 53.5813],
                        [-131.9253, 53.576],
                        [-131.9249, 53.5629],
                        [-131.9293, 53.5564],
                        [-131.9486, 53.5387],
                        [-131.9559, 53.5254],
                        [-131.9559, 53.5127],
                        [-131.9456, 53.5014],
                        [-131.9451, 53.4932],
                        [-131.9362, 53.4888],
                        [-131.9377, 53.4798],
                        [-131.9322, 53.4767],
                        [-131.9298, 53.4637],
                        [-131.9208, 53.4505],
                        [-131.9121, 53.432],
                        [-131.9184, 53.406],
                        [-131.925, 53.4017],
                        [-131.9314, 53.3901],
                        [-131.9339, 53.3799],
                        [-131.9258, 53.3686],
                        [-131.9261, 53.3583],
                        [-131.9384, 53.3505],
                        [-131.9455, 53.3319],
                        [-131.9509, 53.3301],
                        [-131.9542, 53.3189],
                        [-131.9591, 53.3157],
                        [-131.9695, 53.2904],
                        [-131.9686, 53.284],
                        [-131.9731, 53.2758],
                        [-131.9884, 53.2674],
                        [-131.9917, 53.2529],
                        [-132.0022, 53.2447],
                        [-132.009, 53.2468],
                        [-132.0202, 53.2438],
                        [-132.0288, 53.2498],
                        [-132.0657, 53.2539],
                        [-132.0903, 53.2548],
                        [-132.1084, 53.2528],
                        [-132.1187, 53.2495],
                        [-132.1251, 53.252],
                        [-132.1484, 53.2394],
                        [-132.1585, 53.2418],
                        [-132.1835, 53.2391],
                        [-132.2074, 53.2319],
                        [-132.2183, 53.2311],
                        [-132.2461, 53.2179],
                        [-132.2448, 53.2081],
                        [-132.2556, 53.1994],
                        [-132.2679, 53.2038],
                        [-132.2751, 53.209],
                        [-132.2863, 53.2223],
                        [-132.3017, 53.231],
                        [-132.3165, 53.2353],
                        [-132.3252, 53.2281],
                        [-132.3102, 53.2238],
                        [-132.293, 53.2143],
                        [-132.284, 53.2073],
                        [-132.2879, 53.2027],
                        [-132.2843, 53.1934],
                        [-132.2728, 53.1886],
                        [-132.2535, 53.1843],
                        [-132.2261, 53.1914],
                        [-132.2188, 53.1976],
                        [-132.2211, 53.2058],
                        [-132.207, 53.2039],
                        [-132.1935, 53.197],
                        [-132.1741, 53.1995],
                        [-132.1521, 53.1983],
                        [-132.1369, 53.1952],
                        [-132.1309, 53.19],
                        [-132.1337, 53.1775],
                        [-132.1483, 53.1739],
                        [-132.1549, 53.1686],
                        [-132.1682, 53.1679],
                        [-132.1761, 53.1623],
                        [-132.1992, 53.1579],
                        [-132.2154, 53.1476],
                        [-132.2333, 53.1454],
                        [-132.2611, 53.15],
                        [-132.2995, 53.1549],
                        [-132.3081, 53.1583],
                        [-132.3124, 53.1647],
                        [-132.3116, 53.1727],
                        [-132.3153, 53.1837],
                        [-132.3366, 53.1896],
                        [-132.3215, 53.1726],
                        [-132.3205, 53.1633],
                        [-132.3287, 53.1527],
                        [-132.3428, 53.1503],
                        [-132.3606, 53.1533],
                        [-132.3708, 53.1439],
                        [-132.3974, 53.1378],
                        [-132.4203, 53.1398],
                        [-132.4588, 53.1353],
                        [-132.4722, 53.1353],
                        [-132.4751, 53.1394],
                        [-132.4981, 53.1467],
                        [-132.4849, 53.1527],
                        [-132.4847, 53.1595],
                        [-132.4578, 53.161],
                        [-132.4512, 53.1641],
                        [-132.4305, 53.168],
                        [-132.4339, 53.1725],
                        [-132.4563, 53.1668],
                        [-132.4724, 53.1658],
                        [-132.4842, 53.1679],
                        [-132.4808, 53.1746],
                        [-132.4891, 53.1756],
                        [-132.478, 53.1884],
                        [-132.4788, 53.2035],
                        [-132.4756, 53.2092],
                        [-132.4786, 53.2154],
                        [-132.475, 53.2222],
                        [-132.4829, 53.2233],
                        [-132.489, 53.2129],
                        [-132.4902, 53.203],
                        [-132.4868, 53.1921],
                        [-132.4926, 53.1782],
                        [-132.507, 53.1553],
                        [-132.5264, 53.1583],
                        [-132.5327, 53.1645],
                        [-132.5514, 53.168],
                        [-132.5636, 53.1787],
                        [-132.5536, 53.1846],
                        [-132.5384, 53.1889],
                        [-132.5426, 53.1941],
                        [-132.5584, 53.1925],
                        [-132.5683, 53.1945],
                        [-132.5852, 53.1911],
                        [-132.5946, 53.2051],
                        [-132.5852, 53.2072],
                        [-132.5751, 53.2174],
                        [-132.567, 53.2192],
                        [-132.5633, 53.2317],
                        [-132.5755, 53.229],
                        [-132.595, 53.2296],
                        [-132.6008, 53.237],
                        [-132.6134, 53.2414],
                        [-132.617, 53.2499],
                        [-132.608, 53.2531],
                        [-132.5795, 53.258],
                        [-132.5633, 53.2655],
                        [-132.5519, 53.2666],
                        [-132.528, 53.2727],
                        [-132.507, 53.2796],
                        [-132.5039, 53.2853],
                        [-132.5237, 53.2814],
                        [-132.536, 53.2767],
                        [-132.5747, 53.2676],
                        [-132.5914, 53.2597],
                        [-132.623, 53.2567],
                        [-132.6332, 53.2531],
                        [-132.6452, 53.2525],
                        [-132.6571, 53.2575],
                        [-132.6902, 53.2611],
                        [-132.7007, 53.2594],
                        [-132.7087, 53.2542],
                        [-132.7158, 53.254],
                        [-132.716, 53.2634],
                        [-132.706, 53.2737],
                        [-132.7119, 53.2785],
                        [-132.7047, 53.2843],
                        [-132.6776, 53.2849],
                        [-132.6703, 53.2879],
                        [-132.6511, 53.2872],
                        [-132.6283, 53.2912],
                        [-132.6406, 53.3046],
                        [-132.6374, 53.3153],
                        [-132.6106, 53.3171],
                        [-132.5956, 53.3211],
                        [-132.5782, 53.3225],
                        [-132.5653, 53.3168],
                        [-132.5563, 53.3175],
                        [-132.5506, 53.3267],
                        [-132.5545, 53.3327],
                        [-132.5745, 53.3348],
                        [-132.587, 53.3317],
                        [-132.6004, 53.3315],
                        [-132.605, 53.328],
                        [-132.6329, 53.3251],
                        [-132.6784, 53.3244],
                        [-132.686, 53.3273],
                        [-132.7253, 53.3254],
                        [-132.7493, 53.3164],
                        [-132.7666, 53.3173],
                        [-132.7622, 53.3297],
                        [-132.7459, 53.3336],
                        [-132.7396, 53.3375],
                        [-132.7261, 53.356],
                        [-132.71, 53.3607],
                        [-132.7298, 53.3657],
                        [-132.7208, 53.3769],
                        [-132.6995, 53.3741],
                        [-132.6896, 53.3647],
                        [-132.6703, 53.3701],
                        [-132.6529, 53.3653],
                        [-132.6325, 53.3665],
                        [-132.6242, 53.3688],
                        [-132.6071, 53.3647],
                        [-132.58, 53.3628],
                        [-132.5676, 53.3654],
                        [-132.5409, 53.3657],
                        [-132.5133, 53.3577],
                        [-132.51, 53.3534],
                        [-132.5188, 53.3431],
                        [-132.5067, 53.3402],
                        [-132.4898, 53.3323],
                        [-132.4833, 53.3223],
                        [-132.4604, 53.3206],
                        [-132.4418, 53.3219],
                        [-132.4382, 53.3073],
                        [-132.4276, 53.291],
                        [-132.4221, 53.2964],
                        [-132.4312, 53.2991],
                        [-132.4242, 53.3091],
                        [-132.4168, 53.3092],
                        [-132.4212, 53.3182],
                        [-132.412, 53.3208],
                        [-132.4205, 53.3404],
                        [-132.4325, 53.3478],
                        [-132.4788, 53.362],
                        [-132.4861, 53.3684],
                        [-132.5135, 53.3775],
                        [-132.5173, 53.3873],
                        [-132.529, 53.3877],
                        [-132.5264, 53.3968],
                        [-132.5204, 53.397],
                        [-132.5226, 53.4066],
                        [-132.53, 53.4089],
                        [-132.5324, 53.4151],
                        [-132.5411, 53.4158],
                        [-132.5647, 53.4134],
                        [-132.5704, 53.4182],
                        [-132.5863, 53.4246],
                        [-132.5935, 53.4223],
                        [-132.6081, 53.423],
                        [-132.6281, 53.4282],
                        [-132.6431, 53.4251],
                        [-132.6539, 53.4268],
                        [-132.6657, 53.4337],
                        [-132.6719, 53.4408],
                        [-132.6614, 53.4454],
                        [-132.661, 53.4545],
                        [-132.6547, 53.4751],
                        [-132.6761, 53.4812],
                        [-132.674, 53.4686],
                        [-132.676, 53.462],
                        [-132.689, 53.449],
                        [-132.7049, 53.4435],
                        [-132.7169, 53.4485],
                        [-132.732, 53.4504],
                        [-132.745, 53.4548],
                        [-132.7592, 53.4559],
                        [-132.7761, 53.4642],
                        [-132.7661, 53.4686],
                        [-132.7501, 53.4722],
                        [-132.7384, 53.4791],
                        [-132.7297, 53.4909],
                        [-132.7335, 53.4983],
                        [-132.7449, 53.4984],
                        [-132.7403, 53.5053],
                        [-132.7528, 53.5159],
                        [-132.7445, 53.5205],
                        [-132.7548, 53.5248],
                        [-132.7616, 53.5168],
                        [-132.7593, 53.5094],
                        [-132.7633, 53.4996],
                        [-132.7552, 53.4932],
                        [-132.7674, 53.4852],
                        [-132.7874, 53.485],
                        [-132.8136, 53.4817],
                        [-132.8278, 53.4724],
                        [-132.8418, 53.4702],
                        [-132.8899, 53.467],
                        [-132.8964, 53.4763],
                        [-132.8855, 53.4861],
                        [-132.8894, 53.4987],
                        [-132.8645, 53.4973],
                        [-132.8479, 53.501],
                        [-132.8415, 53.5071],
                        [-132.8451, 53.5121],
                        [-132.8375, 53.5158],
                        [-132.8507, 53.5219],
                        [-132.8673, 53.5195],
                        [-132.8897, 53.5201],
                        [-132.911, 53.5176],
                        [-132.9373, 53.5165],
                        [-132.9451, 53.523],
                        [-132.9386, 53.5335],
                        [-132.9243, 53.5402],
                        [-132.9225, 53.5447],
                        [-132.9323, 53.5491],
                        [-132.934, 53.5545],
                        [-132.9213, 53.5562],
                        [-132.9026, 53.5641],
                        [-132.9302, 53.5636],
                        [-132.9449, 53.5602],
                        [-132.9515, 53.5548],
                        [-132.9754, 53.5571],
                        [-132.9841, 53.5622],
                        [-132.9691, 53.5659],
                        [-132.9717, 53.5726],
                        [-133.0048, 53.5913],
                        [-132.9945, 53.5963],
                        [-132.9799, 53.5945],
                        [-132.972, 53.6038],
                        [-132.9634, 53.6061],
                        [-132.9229, 53.5891],
                        [-132.9117, 53.5901],
                        [-132.9043, 53.5848],
                        [-132.892, 53.5938],
                        [-132.8808, 53.5935],
                        [-132.8649, 53.5877],
                        [-132.8445, 53.5866],
                        [-132.8318, 53.5905],
                        [-132.841, 53.5979],
                        [-132.8632, 53.5977],
                        [-132.8764, 53.6018],
                        [-132.9001, 53.6019],
                        [-132.9092, 53.6035],
                        [-132.9289, 53.6166],
                        [-132.9337, 53.6241],
                        [-132.9351, 53.641],
                        [-132.9562, 53.6418],
                        [-132.9682, 53.6393],
                        [-132.9709, 53.6452],
                        [-132.9575, 53.6505],
                        [-132.9493, 53.6481],
                        [-132.9487, 53.6574],
                        [-132.9523, 53.6627],
                        [-132.9616, 53.6655],
                        [-132.9799, 53.6646],
                        [-133.0028, 53.6666],
                        [-133.0111, 53.6711],
                        [-133.0184, 53.6906],
                        [-133.0004, 53.6845],
                        [-132.9764, 53.684],
                        [-132.9791, 53.6945],
                        [-132.9677, 53.6994],
                        [-132.9575, 53.692],
                        [-132.9611, 53.6858],
                        [-132.948, 53.685],
                        [-132.9475, 53.693],
                        [-132.939, 53.6944],
                        [-132.9295, 53.7001],
                        [-132.9306, 53.7047],
                        [-132.9632, 53.7109],
                        [-132.9731, 53.7085],
                        [-132.9776, 53.7123],
                        [-132.9943, 53.714],
                        [-132.9958, 53.7292],
                        [-132.9922, 53.7416],
                        [-132.994, 53.7469],
                        [-133.0071, 53.7514],
                        [-133.0016, 53.7606],
                        [-132.9898, 53.7676],
                        [-132.9951, 53.7723],
                        [-133.0207, 53.7667],
                        [-133.0298, 53.7586],
                        [-133.0375, 53.7478],
                        [-133.0524, 53.756],
                        [-133.0647, 53.7708],
                        [-133.0892, 53.7719],
                        [-133.1127, 53.7774],
                        [-133.1283, 53.7979],
                        [-133.1193, 53.8042],
                        [-133.121, 53.8076],
                        [-133.1027, 53.8137],
                        [-133.0961, 53.8187],
                        [-133.0817, 53.8225],
                        [-133.1007, 53.8347],
                        [-133.1096, 53.8359],
                        [-133.1166, 53.8461],
                        [-133.1144, 53.8534],
                        [-133.1266, 53.8657],
                        [-133.1362, 53.8632],
                        [-133.1441, 53.8849],
                        [-133.1503, 53.8873],
                        [-133.1514, 53.895],
                        [-133.147, 53.9006],
                        [-133.1543, 53.9137],
                        [-133.1495, 53.9188],
                        [-133.1309, 53.9242],
                        [-133.1239, 53.9298],
                        [-133.1263, 53.9346],
                        [-133.1361, 53.9365],
                        [-133.1379, 53.9437],
                        [-133.117, 53.9441],
                        [-133.118, 53.9561],
                        [-133.1135, 53.9616],
                        [-133.117, 53.9786],
                        [-133.1235, 53.9809],
                        [-133.1188, 54.0005],
                        [-133.1046, 54.0067],
                        [-133.0933, 54.0028],
                        [-133.0788, 54.0085],
                        [-133.0857, 54.0196],
                        [-133.0794, 54.023],
                        [-133.0677, 54.023],
                        [-133.057, 54.03],
                        [-133.0595, 54.0389],
                        [-133.0503, 54.0454],
                        [-133.057, 54.0532],
                        [-133.0695, 54.0559],
                        [-133.0704, 54.0731],
                        [-133.0753, 54.0782],
                        [-133.0707, 54.0941],
                        [-133.0648, 54.0993],
                        [-133.0721, 54.1069],
                        [-133.0962, 54.1038],
                        [-133.102, 54.1117],
                        [-133.1001, 54.1228],
                        [-133.0829, 54.1294],
                        [-133.0679, 54.141],
                        [-133.065, 54.1484],
                        [-133.0515, 54.1606],
                        [-133.0382, 54.162],
                        [-133.0307, 54.1727],
                        [-133.043, 54.1762],
                        [-133.0572, 54.1731],
                        [-133.0721, 54.175],
                        [-133.0853, 54.1705],
                        [-133.0881, 54.1767],
                        [-133.0835, 54.1834],
                        [-133.0612, 54.1801],
                        [-133.0396, 54.1848],
                        [-133.0259, 54.1842],
                        [-133.0162, 54.1756],
                        [-132.9899, 54.1712],
                        [-132.9793, 54.1656],
                        [-132.9673, 54.1689],
                        [-132.9561, 54.1652],
                        [-132.943, 54.1702],
                        [-132.9368, 54.1662],
                        [-132.9317, 54.157],
                        [-132.9175, 54.1482],
                        [-132.8922, 54.1485],
                        [-132.8745, 54.142],
                        [-132.8659, 54.1364],
                        [-132.8485, 54.137],
                        [-132.8203, 54.1274],
                        [-132.8053, 54.1284],
                        [-132.7932, 54.1323],
                        [-132.7636, 54.1326],
                        [-132.7589, 54.1385],
                        [-132.7437, 54.1366],
                        [-132.7362, 54.14],
                        [-132.7183, 54.1415],
                        [-132.7088, 54.1473],
                        [-132.6866, 54.147],
                        [-132.6689, 54.1537],
                        [-132.6567, 54.1503],
                        [-132.6583, 54.1455],
                        [-132.6371, 54.1394],
                        [-132.6325, 54.1344],
                        [-132.5947, 54.1224],
                        [-132.5824, 54.1198],
                        [-132.5902, 54.1051],
                        [-132.572, 54.0934],
                        [-132.5711, 54.0861],
                        [-132.5815, 54.0779],
                        [-132.5842, 54.0686],
                        [-132.5738, 54.0573],
                        [-132.5665, 54.0527],
                        [-132.568, 54.048],
                        [-132.5774, 54.0461],
                        [-132.591, 54.0246],
                        [-132.623, 54.013],
                        [-132.6303, 54.0152],
                        [-132.6418, 54.0058],
                        [-132.6519, 54.0024],
                        [-132.6624, 53.9897],
                        [-132.6831, 53.9824],
                        [-132.679, 53.9765],
                        [-132.6653, 53.9727],
                        [-132.6647, 53.963],
                        [-132.6796, 53.9589],
                        [-132.6643, 53.9466],
                        [-132.6426, 53.9581],
                        [-132.6443, 53.9613],
                        [-132.6283, 53.9663],
                        [-132.6203, 53.9719],
                        [-132.6035, 53.9776],
                        [-132.5908, 53.9779],
                        [-132.5831, 53.9885],
                        [-132.5619, 53.9985],
                        [-132.5559, 54.0035],
                        [-132.5715, 54.0102],
                        [-132.5641, 54.024],
                        [-132.5664, 54.028],
                        [-132.5609, 54.0424],
                        [-132.5447, 54.0504],
                        [-132.5263, 54.054],
                        [-132.5228, 54.0615],
                        [-132.4843, 54.0657],
                        [-132.4718, 54.0695],
                        [-132.466, 54.075],
                        [-132.4475, 54.0829],
                        [-132.4334, 54.1003],
                        [-132.4163, 54.1017],
                        [-132.4035, 54.1001],
                        [-132.3792, 54.1024],
                        [-132.3611, 54.1092],
                        [-132.3441, 54.1069],
                        [-132.314, 54.1136],
                        [-132.3067, 54.109],
                        [-132.2907, 54.1064],
                        [-132.2836, 54.1001],
                        [-132.2693, 54.098],
                        [-132.2648, 54.0893],
                        [-132.2626, 54.0774],
                        [-132.2534, 54.0796],
                        [-132.2383, 54.0695],
                        [-132.2375, 54.0591],
                        [-132.2427, 54.0556],
                        [-132.2435, 54.0468],
                        [-132.2505, 54.0396],
                        [-132.2479, 54.0347],
                        [-132.2353, 54.0274],
                        [-132.2041, 54.0208],
                        [-132.188, 54.0198],
                        [-132.1782, 54.0166],
                        [-132.1753, 54.004],
                        [-132.1612, 53.9902],
                        [-132.1532, 53.9861],
                        [-132.1528, 53.98],
                        [-132.1463, 53.9725],
                        [-132.1462, 53.9515],
                        [-132.1547, 53.9378],
                        [-132.1528, 53.9307],
                        [-132.1399, 53.919],
                        [-132.1355, 53.9182],
                        [-132.1203, 53.905],
                        [-132.1069, 53.8883],
                        [-132.1056, 53.8811],
                        [-132.1111, 53.8689],
                        [-132.1255, 53.8498],
                        [-132.1279, 53.8437],
                        [-132.1447, 53.8386],
                        [-132.1527, 53.8319],
                        [-132.1851, 53.82],
                        [-132.2035, 53.8152],
                        [-132.2062, 53.8075],
                        [-132.2164, 53.7976],
                        [-132.2245, 53.7834],
                        [-132.2499, 53.7763],
                        [-132.2596, 53.7647],
                        [-132.2779, 53.7643],
                        [-132.2823, 53.761],
                        [-132.3005, 53.7631],
                        [-132.3168, 53.7569],
                        [-132.3392, 53.7536],
                        [-132.3506, 53.7537],
                        [-132.3586, 53.7586],
                        [-132.3747, 53.7544],
                        [-132.3882, 53.7532],
                        [-132.3937, 53.7492],
                        [-132.4089, 53.7456],
                        [-132.4149, 53.7364],
                        [-132.4238, 53.7316],
                        [-132.4318, 53.7222],
                        [-132.4388, 53.7189],
                        [-132.4533, 53.7173],
                        [-132.4759, 53.705],
                        [-132.4829, 53.7026],
                        [-132.4939, 53.7049],
                        [-132.5061, 53.7005],
                        [-132.5269, 53.697],
                        [-132.5373, 53.6975],
                        [-132.5604, 53.6891],
                        [-132.5743, 53.6889],
                        [-132.587, 53.6939],
                        [-132.5971, 53.6952],
                        [-132.6239, 53.6951],
                        [-132.6603, 53.6897],
                        [-132.6715, 53.6805],
                        [-132.6725, 53.671],
                        [-132.6803, 53.6649],
                        [-132.6747, 53.66],
                        [-132.6626, 53.6732],
                        [-132.6631, 53.6774],
                        [-132.6408, 53.6861],
                        [-132.6214, 53.6882],
                        [-132.6048, 53.6853],
                        [-132.5928, 53.6796],
                        [-132.5852, 53.6699],
                        [-132.5985, 53.6555],
                        [-132.5995, 53.6481],
                        [-132.6059, 53.6378],
                        [-132.5977, 53.6356],
                        [-132.5872, 53.6507],
                        [-132.5741, 53.6647],
                        [-132.565, 53.6565],
                        [-132.5513, 53.6595],
                        [-132.551, 53.6718],
                        [-132.5348, 53.6745],
                        [-132.5452, 53.6611],
                        [-132.5386, 53.6508],
                        [-132.5294, 53.6507],
                        [-132.5115, 53.6557],
                        [-132.5074, 53.6484],
                        [-132.5087, 53.6412],
                        [-132.5161, 53.6352],
                        [-132.51, 53.6312],
                        [-132.4895, 53.6487],
                        [-132.4783, 53.6552],
                        [-132.4494, 53.6634],
                        [-132.4436, 53.6739],
                        [-132.4322, 53.667],
                        [-132.4198, 53.6723],
                        [-132.4124, 53.6684],
                        [-132.39, 53.6705],
                        [-132.3741, 53.6742],
                        [-132.3537, 53.6751],
                        [-132.3612, 53.6634],
                        [-132.3853, 53.6573],
                        [-132.4109, 53.6432],
                        [-132.4085, 53.6412],
                        [-132.459, 53.6183],
                        [-132.4613, 53.6121],
                        [-132.4696, 53.6063],
                        [-132.4744, 53.5929],
                        [-132.4744, 53.584],
                        [-132.4837, 53.5764],
                        [-132.4857, 53.5668],
                        [-132.4795, 53.564],
                        [-132.4743, 53.573],
                        [-132.4601, 53.5797],
                        [-132.4609, 53.5838],
                        [-132.4512, 53.5945],
                        [-132.4477, 53.6029],
                        [-132.44, 53.6118],
                        [-132.4294, 53.6141],
                        [-132.4206, 53.609],
                        [-132.4132, 53.6117],
                        [-132.4023, 53.6094],
                        [-132.3916, 53.6234],
                        [-132.3866, 53.6142],
                        [-132.3731, 53.6199],
                        [-132.354, 53.6245],
                        [-132.3499, 53.621],
                        [-132.3363, 53.6223],
                        [-132.3342, 53.6171],
                        [-132.3139, 53.6145],
                        [-132.3081, 53.6289],
                        [-132.2825, 53.6289],
                        [-132.2806, 53.6323],
                        [-132.3048, 53.6396],
                        [-132.3157, 53.6371],
                        [-132.3294, 53.6406],
                        [-132.3443, 53.6529],
                        [-132.342, 53.6581],
                        [-132.3462, 53.6643],
                        [-132.3425, 53.6712],
                        [-132.3325, 53.6703],
                        [-132.3184, 53.6763],
                        [-132.3017, 53.6753],
                        [-132.2992, 53.6721],
                        [-132.2594, 53.6698],
                        [-132.254, 53.667],
                        [-132.2246, 53.6681],
                        [-132.218, 53.6565],
                        [-132.2088, 53.6545],
                        [-132.2, 53.6589],
                        [-132.2014, 53.6668],
                        [-132.1931, 53.6741],
                        [-132.1861, 53.6889],
                        [-132.1748, 53.6916],
                        [-132.1718, 53.701],
                        [-132.16, 53.7077],
                        [-132.1591, 53.7247],
                        [-132.1609, 53.7306],
                        [-132.1573, 53.7402],
                        [-132.1417, 53.7479],
                        [-132.1396, 53.7518],
                        [-132.1433, 53.7568],
                        [-132.1355, 53.7697],
                        [-132.1358, 53.788],
                        [-132.1322, 53.8032],
                        [-132.1285, 53.8085],
                        [-132.1341, 53.824],
                        [-132.1242, 53.836],
                        [-132.1084, 53.8456],
                        [-132.1058, 53.8547],
                        [-132.0908, 53.8817],
                        [-132.0903, 53.9029],
                        [-132.1105, 53.9217],
                        [-132.1238, 53.9283],
                        [-132.1274, 53.9347],
                        [-132.1254, 53.9423],
                        [-132.1285, 53.9554],
                        [-132.1232, 53.9685],
                        [-132.1271, 53.9766],
                        [-132.1349, 53.9839],
                        [-132.1367, 53.9944],
                        [-132.1452, 54.008],
                        [-132.1723, 54.0316],
                        [-132.1879, 54.0375],
                        [-132.1967, 54.043],
                        [-132.1944, 54.05],
                        [-132.1854, 54.0495],
                        [-132.1663, 54.0405],
                        [-132.1476, 54.036],
                        [-132.1152, 54.0313],
                        [-132.0564, 54.0323],
                        [-132.0491, 54.0301],
                        [-131.9948, 54.0285],
                        [-131.9661, 54.0319],
                        [-131.939, 54.0365],
                        [-131.8976, 54.0471],
                        [-131.8933, 54.0469],
                        [-131.8644, 54.0574],
                        [-131.837, 54.0689],
                        [-131.8222, 54.069],
                        [-131.7931, 54.077],
                        [-131.7852, 54.0763],
                        [-131.7529, 54.0897],
                        [-131.7271, 54.1036],
                        [-131.7118, 54.1145],
                        [-131.6768, 54.1475],
                        [-131.6658, 54.1595],
                        [-131.6581, 54.1536],
                        [-131.6763, 54.0721]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "28",
            properties: { cityName: "British Columbia", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-99412 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-130.2064, 53.9113],
                        [-130.188, 53.905],
                        [-130.1683, 53.8946],
                        [-130.1527, 53.89],
                        [-130.1275, 53.8894],
                        [-130.1114, 53.8825],
                        [-130.0951, 53.8614],
                        [-130.0816, 53.8542],
                        [-130.067, 53.8532],
                        [-130.062, 53.8455],
                        [-130.0432, 53.8437],
                        [-130.0343, 53.8395],
                        [-130.0266, 53.8255],
                        [-129.9928, 53.8063],
                        [-129.9654, 53.7948],
                        [-129.9486, 53.7832],
                        [-129.9404, 53.7724],
                        [-129.9277, 53.7615],
                        [-129.8965, 53.7505],
                        [-129.875, 53.7393],
                        [-129.8533, 53.7254],
                        [-129.8449, 53.723],
                        [-129.832, 53.7122],
                        [-129.8248, 53.7098],
                        [-129.8106, 53.6972],
                        [-129.79, 53.6824],
                        [-129.7621, 53.6575],
                        [-129.7399, 53.639],
                        [-129.7168, 53.6141],
                        [-129.7062, 53.5997],
                        [-129.682, 53.5858],
                        [-129.6429, 53.5581],
                        [-129.5663, 53.5127],
                        [-129.5512, 53.4971],
                        [-129.5386, 53.4929],
                        [-129.4964, 53.4653],
                        [-129.4876, 53.457],
                        [-129.4649, 53.4473],
                        [-129.4515, 53.4394],
                        [-129.4365, 53.4246],
                        [-129.4189, 53.4137],
                        [-129.4297, 53.4129],
                        [-129.4406, 53.4157],
                        [-129.4355, 53.4019],
                        [-129.4484, 53.3865],
                        [-129.4649, 53.3812],
                        [-129.4591, 53.378],
                        [-129.4655, 53.3633],
                        [-129.4731, 53.3629],
                        [-129.4728, 53.35],
                        [-129.4798, 53.3493],
                        [-129.4811, 53.3386],
                        [-129.4736, 53.3205],
                        [-129.4645, 53.3128],
                        [-129.4692, 53.2938],
                        [-129.4781, 53.2856],
                        [-129.4906, 53.2825],
                        [-129.4986, 53.2869],
                        [-129.5093, 53.2875],
                        [-129.5087, 53.2925],
                        [-129.5172, 53.3024],
                        [-129.5161, 53.3087],
                        [-129.5261, 53.3119],
                        [-129.517, 53.3168],
                        [-129.5321, 53.3238],
                        [-129.5419, 53.3217],
                        [-129.5281, 53.3108],
                        [-129.5292, 53.3046],
                        [-129.5227, 53.2948],
                        [-129.5396, 53.2912],
                        [-129.5177, 53.2859],
                        [-129.5041, 53.2728],
                        [-129.5029, 53.2647],
                        [-129.5123, 53.2583],
                        [-129.5085, 53.2507],
                        [-129.4878, 53.2295],
                        [-129.4906, 53.2225],
                        [-129.4994, 53.2167],
                        [-129.5029, 53.2081],
                        [-129.5253, 53.2206],
                        [-129.5546, 53.2125],
                        [-129.5645, 53.2183],
                        [-129.5858, 53.2116],
                        [-129.6029, 53.2207],
                        [-129.6016, 53.2255],
                        [-129.6092, 53.239],
                        [-129.6241, 53.2476],
                        [-129.6343, 53.2488],
                        [-129.633, 53.2587],
                        [-129.6418, 53.2592],
                        [-129.6456, 53.2646],
                        [-129.6549, 53.2685],
                        [-129.6474, 53.2776],
                        [-129.6654, 53.2769],
                        [-129.6779, 53.2965],
                        [-129.6783, 53.3019],
                        [-129.6697, 53.3068],
                        [-129.6326, 53.3064],
                        [-129.6254, 53.3098],
                        [-129.6011, 53.3114],
                        [-129.5942, 53.3171],
                        [-129.611, 53.3225],
                        [-129.6116, 53.3163],
                        [-129.6222, 53.3123],
                        [-129.6382, 53.3127],
                        [-129.6669, 53.3183],
                        [-129.6722, 53.3111],
                        [-129.6995, 53.3206],
                        [-129.6902, 53.3281],
                        [-129.6777, 53.3341],
                        [-129.653, 53.3315],
                        [-129.6367, 53.3359],
                        [-129.646, 53.3404],
                        [-129.6578, 53.3336],
                        [-129.6628, 53.3394],
                        [-129.6752, 53.3382],
                        [-129.6805, 53.3417],
                        [-129.7051, 53.3319],
                        [-129.7131, 53.3231],
                        [-129.7235, 53.33],
                        [-129.7322, 53.3304],
                        [-129.7631, 53.3501],
                        [-129.7607, 53.3535],
                        [-129.7746, 53.3611],
                        [-129.7796, 53.3711],
                        [-129.7888, 53.3696],
                        [-129.7986, 53.3759],
                        [-129.8327, 53.3875],
                        [-129.8401, 53.3998],
                        [-129.8498, 53.4082],
                        [-129.8413, 53.4129],
                        [-129.85, 53.4179],
                        [-129.8439, 53.4255],
                        [-129.8473, 53.4295],
                        [-129.8223, 53.4435],
                        [-129.7961, 53.4491],
                        [-129.8118, 53.451],
                        [-129.8222, 53.4476],
                        [-129.827, 53.4422],
                        [-129.8367, 53.4382],
                        [-129.8457, 53.4398],
                        [-129.8338, 53.447],
                        [-129.8234, 53.4489],
                        [-129.8244, 53.4579],
                        [-129.8485, 53.454],
                        [-129.8529, 53.4486],
                        [-129.8626, 53.4505],
                        [-129.8695, 53.4604],
                        [-129.8824, 53.4573],
                        [-129.8962, 53.4605],
                        [-129.9055, 53.4598],
                        [-129.9055, 53.4668],
                        [-129.8929, 53.4668],
                        [-129.8832, 53.4715],
                        [-129.9085, 53.4714],
                        [-129.9099, 53.4831],
                        [-129.8999, 53.4892],
                        [-129.8921, 53.501],
                        [-129.8952, 53.5062],
                        [-129.8904, 53.5139],
                        [-129.8788, 53.5138],
                        [-129.8686, 53.5167],
                        [-129.8361, 53.5135],
                        [-129.8296, 53.509],
                        [-129.8139, 53.5142],
                        [-129.8166, 53.5203],
                        [-129.8376, 53.5179],
                        [-129.8495, 53.5225],
                        [-129.8684, 53.5177],
                        [-129.8737, 53.5232],
                        [-129.8841, 53.5271],
                        [-129.8801, 53.5354],
                        [-129.879, 53.5459],
                        [-129.8854, 53.5491],
                        [-129.8946, 53.5453],
                        [-129.9112, 53.5487],
                        [-129.9181, 53.5522],
                        [-129.9279, 53.5498],
                        [-129.9498, 53.5507],
                        [-129.9463, 53.5551],
                        [-129.9296, 53.557],
                        [-129.9222, 53.5559],
                        [-129.915, 53.5694],
                        [-129.9004, 53.5699],
                        [-129.8951, 53.5631],
                        [-129.8814, 53.567],
                        [-129.8928, 53.5837],
                        [-129.9207, 53.5788],
                        [-129.9288, 53.581],
                        [-129.9341, 53.5755],
                        [-129.9441, 53.5727],
                        [-129.9487, 53.5769],
                        [-129.944, 53.5837],
                        [-129.9489, 53.5864],
                        [-129.9377, 53.5928],
                        [-129.9327, 53.6019],
                        [-129.9065, 53.6061],
                        [-129.9087, 53.6096],
                        [-129.9342, 53.6064],
                        [-129.942, 53.5971],
                        [-129.9522, 53.6018],
                        [-129.9622, 53.587],
                        [-129.9529, 53.5867],
                        [-129.9562, 53.5785],
                        [-129.9674, 53.5742],
                        [-129.9794, 53.5759],
                        [-129.984, 53.571],
                        [-130.0088, 53.5648],
                        [-130.0173, 53.5682],
                        [-130.0312, 53.5804],
                        [-130.0451, 53.5847],
                        [-130.0512, 53.5918],
                        [-130.0619, 53.5981],
                        [-130.0605, 53.6155],
                        [-130.0632, 53.6245],
                        [-130.0423, 53.6281],
                        [-129.9933, 53.6346],
                        [-129.9824, 53.6396],
                        [-129.9498, 53.6428],
                        [-129.9211, 53.6475],
                        [-129.9175, 53.6412],
                        [-129.8929, 53.6326],
                        [-129.8918, 53.6352],
                        [-129.9099, 53.6396],
                        [-129.9269, 53.6537],
                        [-129.9392, 53.6566],
                        [-129.951, 53.6482],
                        [-129.9579, 53.6495],
                        [-129.9672, 53.6448],
                        [-129.9751, 53.6473],
                        [-129.9853, 53.6441],
                        [-129.9967, 53.6454],
                        [-130.0026, 53.6419],
                        [-130.0478, 53.6314],
                        [-130.0728, 53.6307],
                        [-130.0871, 53.6424],
                        [-130.089, 53.6556],
                        [-130.099, 53.6683],
                        [-130.0889, 53.6729],
                        [-130.0979, 53.6882],
                        [-130.0991, 53.7018],
                        [-130.0918, 53.7043],
                        [-130.0898, 53.7102],
                        [-130.0786, 53.7122],
                        [-130.0756, 53.717],
                        [-130.084, 53.7247],
                        [-130.0942, 53.7144],
                        [-130.104, 53.6993],
                        [-130.1319, 53.7049],
                        [-130.1358, 53.7089],
                        [-130.1509, 53.7144],
                        [-130.1743, 53.7194],
                        [-130.1824, 53.7185],
                        [-130.2059, 53.7214],
                        [-130.213, 53.7294],
                        [-130.2124, 53.7434],
                        [-130.2038, 53.7475],
                        [-130.2089, 53.7716],
                        [-130.2137, 53.7749],
                        [-130.2104, 53.7845],
                        [-130.2223, 53.7988],
                        [-130.2155, 53.8099],
                        [-130.1847, 53.8083],
                        [-130.1826, 53.8151],
                        [-130.2218, 53.8132],
                        [-130.244, 53.815],
                        [-130.2523, 53.8186],
                        [-130.2729, 53.8194],
                        [-130.2883, 53.8275],
                        [-130.2906, 53.8405],
                        [-130.2952, 53.8454],
                        [-130.2944, 53.8523],
                        [-130.2829, 53.8625],
                        [-130.287, 53.8696],
                        [-130.278, 53.8734],
                        [-130.2579, 53.8895],
                        [-130.2499, 53.8879],
                        [-130.2427, 53.8923],
                        [-130.2326, 53.8911],
                        [-130.2268, 53.8961],
                        [-130.2238, 53.9067],
                        [-130.2064, 53.9113]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "29",
            properties: { cityName: "Alberta", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-9941 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-110, 60],
                        [-110, 59.8726],
                        [-110, 59.7652],
                        [-110, 59.5995],
                        [-110, 59.4747],
                        [-110, 59.391],
                        [-110, 59.25],
                        [-110, 59],
                        [-110, 58.8615],
                        [-110, 58.75],
                        [-110, 58.6048],
                        [-110, 58.5073],
                        [-110, 58.3615],
                        [-110, 58.2059],
                        [-110, 58.0405],
                        [-110, 57.9423],
                        [-110, 57.75],
                        [-110, 57.5575],
                        [-110, 57.4398],
                        [-110, 57.25],
                        [-110, 57.1111],
                        [-110, 56.9291],
                        [-110, 56.8331],
                        [-110, 56.6822],
                        [-110, 56.5],
                        [-110, 56.3613],
                        [-110, 56.232],
                        [-110, 56.0867],
                        [-110, 56],
                        [-109.9999, 55.8728],
                        [-109.9999, 55.75],
                        [-110, 55.6177],
                        [-110.0001, 55.4332],
                        [-110.0001, 55.3312],
                        [-110.0015, 55.1805],
                        [-110.0026, 55.0728],
                        [-110.0038, 54.9492],
                        [-110.0057, 54.7477],
                        [-110.0057, 54.6243],
                        [-110.0057, 54.5],
                        [-110.0057, 54.3259],
                        [-110.0044, 54.2775],
                        [-110.0057, 54.237],
                        [-110.0056, 54.1513],
                        [-110.0057, 54],
                        [-110.0058, 53.8894],
                        [-110.0057, 53.7169],
                        [-110.0067, 53.6245],
                        [-110.0057, 53.6094],
                        [-110.006, 53.5402],
                        [-110.0054, 53.4786],
                        [-110.0059, 53.3035],
                        [-110.0057, 53.2138],
                        [-110.0061, 53.0617],
                        [-110.0066, 53.0295],
                        [-110.0046, 52.9872],
                        [-110.0056, 52.8852],
                        [-110.0048, 52.8681],
                        [-110.0052, 52.8067],
                        [-110.0048, 52.7725],
                        [-110.0054, 52.6671],
                        [-110.0054, 52.6036],
                        [-110.0046, 52.5214],
                        [-110.0042, 52.4335],
                        [-110.0039, 52.3324],
                        [-110.003, 52.2737],
                        [-110.0036, 52.2013],
                        [-110.0051, 52.0556],
                        [-110.004, 51.8816],
                        [-110.0042, 51.8218],
                        [-110.0052, 51.7944],
                        [-110.0051, 51.7071],
                        [-110.0039, 51.6414],
                        [-110.0043, 51.62],
                        [-110.0043, 51.5314],
                        [-110.003, 51.4614],
                        [-110.0034, 51.4445],
                        [-110.0036, 51.3434],
                        [-110.0051, 51.2699],
                        [-110.0048, 51.0958],
                        [-110.0046, 50.9572],
                        [-110.005, 50.9209],
                        [-110.003, 50.8338],
                        [-110.0032, 50.779],
                        [-110.0042, 50.7174],
                        [-110.0035, 50.557],
                        [-110.0035, 50.4544],
                        [-110.0057, 50.426],
                        [-110.006, 50.3507],
                        [-110.0078, 50.2798],
                        [-110.0066, 50.2506],
                        [-110.0072, 50.2219],
                        [-110.0051, 50.0476],
                        [-110.0041, 49.9603],
                        [-110.0041, 49.8714],
                        [-110.0043, 49.6949],
                        [-110.0044, 49.5901],
                        [-110.0046, 49.4037],
                        [-110.0048, 49.2573],
                        [-110.0049, 49.1144],
                        [-110.005, 49.0001],
                        [-110.2714, 49],
                        [-110.4696, 49],
                        [-110.75, 49],
                        [-110.9935, 49],
                        [-111.25, 49],
                        [-111.5077, 49],
                        [-111.7655, 49],
                        [-111.9993, 49],
                        [-112.2754, 49],
                        [-112.5, 49],
                        [-112.7482, 49],
                        [-113.0105, 49],
                        [-113.25, 49],
                        [-113.5017, 49],
                        [-113.7163, 49],
                        [-113.9952, 49],
                        [-114.0693, 49.0001],
                        [-114.0718, 49.006],
                        [-114.0591, 49.0184],
                        [-114.0542, 49.0267],
                        [-114.0623, 49.0375],
                        [-114.0639, 49.0455],
                        [-114.0766, 49.0537],
                        [-114.0813, 49.0598],
                        [-114.0896, 49.0585],
                        [-114.1072, 49.0651],
                        [-114.118, 49.0741],
                        [-114.1298, 49.0782],
                        [-114.1533, 49.0996],
                        [-114.1491, 49.1074],
                        [-114.1498, 49.1181],
                        [-114.1579, 49.1237],
                        [-114.1655, 49.1371],
                        [-114.1491, 49.1437],
                        [-114.1619, 49.1517],
                        [-114.1749, 49.1636],
                        [-114.2127, 49.1734],
                        [-114.2215, 49.1891],
                        [-114.2305, 49.1893],
                        [-114.2347, 49.1854],
                        [-114.2467, 49.1873],
                        [-114.2571, 49.177],
                        [-114.2694, 49.181],
                        [-114.2873, 49.178],
                        [-114.2901, 49.186],
                        [-114.301, 49.1908],
                        [-114.3105, 49.192],
                        [-114.3184, 49.2],
                        [-114.3369, 49.1994],
                        [-114.3456, 49.1936],
                        [-114.3589, 49.1984],
                        [-114.3662, 49.2037],
                        [-114.3773, 49.2029],
                        [-114.3868, 49.205],
                        [-114.4021, 49.2133],
                        [-114.3939, 49.2194],
                        [-114.405, 49.2243],
                        [-114.4011, 49.2278],
                        [-114.4027, 49.2417],
                        [-114.3836, 49.2462],
                        [-114.3935, 49.2572],
                        [-114.4145, 49.2622],
                        [-114.4352, 49.2622],
                        [-114.4479, 49.2644],
                        [-114.4512, 49.2804],
                        [-114.4468, 49.2884],
                        [-114.4612, 49.3041],
                        [-114.4668, 49.3044],
                        [-114.4776, 49.3124],
                        [-114.485, 49.3107],
                        [-114.4921, 49.3138],
                        [-114.4892, 49.3239],
                        [-114.4789, 49.3268],
                        [-114.4876, 49.3473],
                        [-114.5069, 49.3566],
                        [-114.523, 49.3563],
                        [-114.5241, 49.3621],
                        [-114.5316, 49.3705],
                        [-114.5263, 49.3751],
                        [-114.5366, 49.3796],
                        [-114.5442, 49.3776],
                        [-114.5669, 49.3769],
                        [-114.5761, 49.3867],
                        [-114.5906, 49.3961],
                        [-114.592, 49.4049],
                        [-114.5984, 49.4126],
                        [-114.5972, 49.4215],
                        [-114.6008, 49.4247],
                        [-114.5986, 49.4339],
                        [-114.6051, 49.4389],
                        [-114.5983, 49.4454],
                        [-114.5994, 49.4666],
                        [-114.5939, 49.4694],
                        [-114.5929, 49.4932],
                        [-114.5946, 49.5027],
                        [-114.585, 49.5125],
                        [-114.5832, 49.5202],
                        [-114.5727, 49.5247],
                        [-114.5698, 49.537],
                        [-114.5742, 49.5464],
                        [-114.573, 49.557],
                        [-114.5789, 49.5581],
                        [-114.5882, 49.5654],
                        [-114.5934, 49.5611],
                        [-114.6073, 49.5593],
                        [-114.6089, 49.5549],
                        [-114.6196, 49.5464],
                        [-114.6304, 49.5491],
                        [-114.6379, 49.5436],
                        [-114.6546, 49.5452],
                        [-114.6626, 49.552],
                        [-114.6763, 49.5533],
                        [-114.6833, 49.5565],
                        [-114.6905, 49.5537],
                        [-114.6958, 49.5466],
                        [-114.7103, 49.5585],
                        [-114.7103, 49.5629],
                        [-114.7326, 49.5765],
                        [-114.7372, 49.5959],
                        [-114.7471, 49.6024],
                        [-114.7473, 49.6194],
                        [-114.7234, 49.6239],
                        [-114.7083, 49.6359],
                        [-114.7002, 49.6368],
                        [-114.691, 49.6327],
                        [-114.6871, 49.6379],
                        [-114.6746, 49.6435],
                        [-114.6677, 49.6377],
                        [-114.6621, 49.6434],
                        [-114.657, 49.6609],
                        [-114.6698, 49.6729],
                        [-114.669, 49.691],
                        [-114.6766, 49.6961],
                        [-114.6683, 49.7038],
                        [-114.661, 49.7062],
                        [-114.6389, 49.7178],
                        [-114.6331, 49.7341],
                        [-114.6429, 49.7391],
                        [-114.6422, 49.7463],
                        [-114.6514, 49.7587],
                        [-114.6591, 49.7651],
                        [-114.6444, 49.773],
                        [-114.6361, 49.7848],
                        [-114.6422, 49.7958],
                        [-114.6419, 49.808],
                        [-114.6474, 49.8193],
                        [-114.6399, 49.8277],
                        [-114.6506, 49.8384],
                        [-114.6548, 49.8467],
                        [-114.6627, 49.8548],
                        [-114.6614, 49.8623],
                        [-114.6714, 49.8645],
                        [-114.6727, 49.8783],
                        [-114.6849, 49.8878],
                        [-114.6918, 49.8963],
                        [-114.6873, 49.9171],
                        [-114.6902, 49.9216],
                        [-114.6937, 49.9426],
                        [-114.6853, 49.9552],
                        [-114.6833, 49.9671],
                        [-114.6574, 49.9684],
                        [-114.6515, 49.9755],
                        [-114.6548, 49.9969],
                        [-114.6666, 50.0033],
                        [-114.6577, 50.0191],
                        [-114.6577, 50.0297],
                        [-114.6664, 50.0354],
                        [-114.6629, 50.0396],
                        [-114.6761, 50.0481],
                        [-114.6664, 50.0509],
                        [-114.6652, 50.0637],
                        [-114.6771, 50.0635],
                        [-114.6913, 50.0713],
                        [-114.6974, 50.0869],
                        [-114.7108, 50.1022],
                        [-114.7194, 50.1057],
                        [-114.7204, 50.1103],
                        [-114.7333, 50.1186],
                        [-114.7248, 50.1433],
                        [-114.7273, 50.1484],
                        [-114.7253, 50.1614],
                        [-114.7326, 50.1693],
                        [-114.7309, 50.176],
                        [-114.7239, 50.1839],
                        [-114.7247, 50.1902],
                        [-114.7389, 50.2027],
                        [-114.7396, 50.2117],
                        [-114.749, 50.2149],
                        [-114.7453, 50.2223],
                        [-114.7558, 50.2318],
                        [-114.7629, 50.2347],
                        [-114.762, 50.2409],
                        [-114.768, 50.2431],
                        [-114.7702, 50.25],
                        [-114.7665, 50.254],
                        [-114.7641, 50.2652],
                        [-114.7517, 50.2753],
                        [-114.7551, 50.2992],
                        [-114.7694, 50.3026],
                        [-114.7816, 50.3016],
                        [-114.7804, 50.3153],
                        [-114.7976, 50.3261],
                        [-114.7922, 50.3358],
                        [-114.7739, 50.3446],
                        [-114.7662, 50.3507],
                        [-114.7686, 50.3588],
                        [-114.775, 50.3625],
                        [-114.7845, 50.3628],
                        [-114.7891, 50.3581],
                        [-114.8039, 50.3649],
                        [-114.8088, 50.3707],
                        [-114.8192, 50.3691],
                        [-114.8139, 50.3819],
                        [-114.8203, 50.3845],
                        [-114.8248, 50.3933],
                        [-114.8312, 50.3903],
                        [-114.8383, 50.3936],
                        [-114.8569, 50.3925],
                        [-114.8654, 50.3989],
                        [-114.8636, 50.4173],
                        [-114.8596, 50.421],
                        [-114.873, 50.4302],
                        [-114.8747, 50.4396],
                        [-114.8878, 50.4356],
                        [-114.9122, 50.4514],
                        [-114.9174, 50.4615],
                        [-114.9323, 50.4735],
                        [-114.9317, 50.4785],
                        [-114.9372, 50.4863],
                        [-114.9458, 50.4896],
                        [-114.959, 50.5044],
                        [-114.9775, 50.5296],
                        [-114.9783, 50.535],
                        [-114.9914, 50.5444],
                        [-115.0137, 50.5709],
                        [-115.0151, 50.5824],
                        [-115.0205, 50.5847],
                        [-115.0336, 50.5793],
                        [-115.0495, 50.5752],
                        [-115.0737, 50.5884],
                        [-115.0853, 50.5897],
                        [-115.1009, 50.5779],
                        [-115.1175, 50.5698],
                        [-115.1361, 50.5744],
                        [-115.1485, 50.5708],
                        [-115.1546, 50.5733],
                        [-115.1736, 50.5676],
                        [-115.1777, 50.5582],
                        [-115.1909, 50.5541],
                        [-115.1837, 50.544],
                        [-115.1909, 50.5351],
                        [-115.2022, 50.533],
                        [-115.2081, 50.5278],
                        [-115.2169, 50.5365],
                        [-115.231, 50.5449],
                        [-115.2298, 50.5592],
                        [-115.2349, 50.5676],
                        [-115.2419, 50.5712],
                        [-115.2302, 50.5775],
                        [-115.2397, 50.5892],
                        [-115.2473, 50.5881],
                        [-115.2601, 50.5963],
                        [-115.2719, 50.5979],
                        [-115.2804, 50.6055],
                        [-115.2936, 50.6111],
                        [-115.298, 50.6248],
                        [-115.3101, 50.6273],
                        [-115.3119, 50.6382],
                        [-115.2855, 50.6388],
                        [-115.2813, 50.6538],
                        [-115.2823, 50.6632],
                        [-115.2984, 50.6833],
                        [-115.3008, 50.6907],
                        [-115.2936, 50.6984],
                        [-115.3016, 50.7006],
                        [-115.3144, 50.7154],
                        [-115.3107, 50.7216],
                        [-115.3151, 50.7258],
                        [-115.3276, 50.7219],
                        [-115.333, 50.7241],
                        [-115.3499, 50.7213],
                        [-115.3546, 50.7227],
                        [-115.3776, 50.7109],
                        [-115.3789, 50.7053],
                        [-115.3912, 50.7065],
                        [-115.4037, 50.7123],
                        [-115.4138, 50.7113],
                        [-115.4169, 50.7199],
                        [-115.4114, 50.7232],
                        [-115.4124, 50.7336],
                        [-115.4196, 50.7366],
                        [-115.4363, 50.7557],
                        [-115.4471, 50.7532],
                        [-115.4605, 50.7569],
                        [-115.4703, 50.752],
                        [-115.4812, 50.7552],
                        [-115.4848, 50.7607],
                        [-115.4819, 50.7686],
                        [-115.4976, 50.7823],
                        [-115.5122, 50.7864],
                        [-115.517, 50.7802],
                        [-115.5325, 50.7882],
                        [-115.5386, 50.7966],
                        [-115.5509, 50.7971],
                        [-115.5529, 50.8025],
                        [-115.5605, 50.8047],
                        [-115.5665, 50.8147],
                        [-115.5608, 50.8265],
                        [-115.5649, 50.8322],
                        [-115.5782, 50.8405],
                        [-115.5906, 50.8431],
                        [-115.6074, 50.844],
                        [-115.6181, 50.8357],
                        [-115.627, 50.8334],
                        [-115.6394, 50.838],
                        [-115.643, 50.8422],
                        [-115.6439, 50.8573],
                        [-115.651, 50.8659],
                        [-115.6489, 50.8741],
                        [-115.629, 50.8798],
                        [-115.6169, 50.8794],
                        [-115.6127, 50.8853],
                        [-115.5926, 50.8926],
                        [-115.5779, 50.8883],
                        [-115.5621, 50.8943],
                        [-115.5686, 50.9021],
                        [-115.5842, 50.9158],
                        [-115.5999, 50.9179],
                        [-115.6098, 50.9263],
                        [-115.6055, 50.9334],
                        [-115.597, 50.9399],
                        [-115.6108, 50.9468],
                        [-115.6146, 50.9512],
                        [-115.6117, 50.9589],
                        [-115.622, 50.9655],
                        [-115.6281, 50.9777],
                        [-115.6362, 50.982],
                        [-115.6488, 50.9975],
                        [-115.6574, 51.0013],
                        [-115.6694, 50.9993],
                        [-115.6778, 51.0072],
                        [-115.6886, 51.0136],
                        [-115.6922, 51.0198],
                        [-115.6923, 51.0286],
                        [-115.7038, 51.0213],
                        [-115.7171, 51.0174],
                        [-115.7268, 51.0187],
                        [-115.74, 51.0249],
                        [-115.7353, 51.0296],
                        [-115.7431, 51.0339],
                        [-115.7515, 51.0315],
                        [-115.767, 51.0394],
                        [-115.7736, 51.0577],
                        [-115.7613, 51.0608],
                        [-115.762, 51.0719],
                        [-115.7724, 51.067],
                        [-115.7892, 51.0724],
                        [-115.8028, 51.0696],
                        [-115.808, 51.0806],
                        [-115.8166, 51.0847],
                        [-115.8255, 51.0827],
                        [-115.8334, 51.0759],
                        [-115.8476, 51.0818],
                        [-115.8577, 51.0791],
                        [-115.8679, 51.0893],
                        [-115.883, 51.086],
                        [-115.8947, 51.0861],
                        [-115.9236, 51.0831],
                        [-115.9325, 51.0967],
                        [-115.9485, 51.0992],
                        [-115.9504, 51.1069],
                        [-115.9572, 51.1152],
                        [-115.9676, 51.116],
                        [-115.9683, 51.1221],
                        [-115.9848, 51.126],
                        [-116.0044, 51.1247],
                        [-116.0113, 51.1277],
                        [-116.0156, 51.1381],
                        [-116.0099, 51.1437],
                        [-116.0094, 51.1551],
                        [-116.0175, 51.1561],
                        [-116.0295, 51.1627],
                        [-116.0368, 51.17],
                        [-116.0239, 51.1762],
                        [-116.0213, 51.187],
                        [-116.0061, 51.1902],
                        [-116, 51.1939],
                        [-116.0065, 51.2107],
                        [-116.002, 51.2154],
                        [-116.0076, 51.222],
                        [-116.0165, 51.2236],
                        [-116.0267, 51.2198],
                        [-116.0492, 51.2274],
                        [-116.0652, 51.2479],
                        [-116.0813, 51.2518],
                        [-116.0918, 51.2508],
                        [-116.1117, 51.2533],
                        [-116.1251, 51.2593],
                        [-116.1441, 51.2647],
                        [-116.1532, 51.2634],
                        [-116.1591, 51.2758],
                        [-116.1516, 51.2871],
                        [-116.1641, 51.2978],
                        [-116.1794, 51.2954],
                        [-116.1885, 51.3015],
                        [-116.2068, 51.2962],
                        [-116.2191, 51.2945],
                        [-116.2271, 51.3015],
                        [-116.2434, 51.3014],
                        [-116.26, 51.3098],
                        [-116.268, 51.3119],
                        [-116.2746, 51.3194],
                        [-116.2748, 51.3282],
                        [-116.2867, 51.3345],
                        [-116.29, 51.3431],
                        [-116.2773, 51.3547],
                        [-116.2777, 51.3617],
                        [-116.2934, 51.3648],
                        [-116.3027, 51.3713],
                        [-116.3115, 51.385],
                        [-116.2919, 51.3972],
                        [-116.2925, 51.4036],
                        [-116.2825, 51.4068],
                        [-116.2943, 51.4299],
                        [-116.2855, 51.4353],
                        [-116.2881, 51.4485],
                        [-116.2841, 51.4565],
                        [-116.2925, 51.4618],
                        [-116.3223, 51.4625],
                        [-116.338, 51.4643],
                        [-116.3466, 51.4695],
                        [-116.3606, 51.4709],
                        [-116.369, 51.483],
                        [-116.3798, 51.4883],
                        [-116.3939, 51.5057],
                        [-116.3963, 51.5126],
                        [-116.3935, 51.518],
                        [-116.3954, 51.5255],
                        [-116.3829, 51.5326],
                        [-116.3909, 51.5456],
                        [-116.4014, 51.5465],
                        [-116.4154, 51.5533],
                        [-116.4529, 51.5606],
                        [-116.4648, 51.5658],
                        [-116.4733, 51.5827],
                        [-116.466, 51.5941],
                        [-116.4714, 51.6047],
                        [-116.491, 51.6123],
                        [-116.489, 51.6167],
                        [-116.4996, 51.6234],
                        [-116.4999, 51.6277],
                        [-116.5135, 51.6276],
                        [-116.5299, 51.6317],
                        [-116.5365, 51.6387],
                        [-116.5459, 51.6382],
                        [-116.5701, 51.6471],
                        [-116.5706, 51.6529],
                        [-116.5804, 51.6531],
                        [-116.595, 51.6618],
                        [-116.5875, 51.673],
                        [-116.5914, 51.6777],
                        [-116.5814, 51.6875],
                        [-116.5812, 51.6973],
                        [-116.5876, 51.7093],
                        [-116.5985, 51.7239],
                        [-116.6105, 51.724],
                        [-116.6242, 51.7273],
                        [-116.6312, 51.7314],
                        [-116.6281, 51.7449],
                        [-116.6403, 51.7512],
                        [-116.6503, 51.7537],
                        [-116.651, 51.7668],
                        [-116.6431, 51.7867],
                        [-116.656, 51.7969],
                        [-116.6543, 51.8013],
                        [-116.6821, 51.8121],
                        [-116.6945, 51.8036],
                        [-116.7068, 51.8024],
                        [-116.7155, 51.7987],
                        [-116.7251, 51.8048],
                        [-116.7442, 51.8066],
                        [-116.7545, 51.7999],
                        [-116.7596, 51.7875],
                        [-116.7653, 51.7846],
                        [-116.7729, 51.7748],
                        [-116.7915, 51.764],
                        [-116.8065, 51.7505],
                        [-116.8112, 51.7406],
                        [-116.8186, 51.7356],
                        [-116.8049, 51.7259],
                        [-116.798, 51.7236],
                        [-116.8001, 51.7169],
                        [-116.807, 51.7131],
                        [-116.8097, 51.7053],
                        [-116.8366, 51.7033],
                        [-116.8536, 51.7083],
                        [-116.8652, 51.7052],
                        [-116.8873, 51.703],
                        [-116.9026, 51.7038],
                        [-116.9206, 51.7098],
                        [-116.926, 51.7175],
                        [-116.9265, 51.7291],
                        [-116.9436, 51.7339],
                        [-116.9445, 51.7417],
                        [-116.9543, 51.7432],
                        [-116.9606, 51.752],
                        [-116.9764, 51.7602],
                        [-116.9737, 51.7695],
                        [-116.9632, 51.7743],
                        [-116.9661, 51.7877],
                        [-116.964, 51.795],
                        [-116.989, 51.8111],
                        [-116.9842, 51.8203],
                        [-116.9882, 51.8229],
                        [-116.9789, 51.8336],
                        [-116.9845, 51.836],
                        [-117.0057, 51.8361],
                        [-117.0194, 51.8429],
                        [-117.0321, 51.8544],
                        [-117.0343, 51.8598],
                        [-117.0233, 51.88],
                        [-117.0108, 51.8897],
                        [-117.0195, 51.8914],
                        [-117.0467, 51.9102],
                        [-117.0797, 51.9308],
                        [-117.0862, 51.9369],
                        [-117.0977, 51.9397],
                        [-117.1032, 51.9565],
                        [-117.1202, 51.9557],
                        [-117.1333, 51.9683],
                        [-117.1485, 51.9734],
                        [-117.1542, 51.9719],
                        [-117.1773, 51.9744],
                        [-117.1972, 51.9801],
                        [-117.197, 51.9877],
                        [-117.2017, 51.9929],
                        [-117.2164, 51.9973],
                        [-117.232, 52.0165],
                        [-117.2227, 52.0174],
                        [-117.2147, 52.0285],
                        [-117.232, 52.0411],
                        [-117.2482, 52.0348],
                        [-117.2521, 52.0386],
                        [-117.2706, 52.0489],
                        [-117.2684, 52.0558],
                        [-117.2945, 52.0651],
                        [-117.3051, 52.0743],
                        [-117.292, 52.0854],
                        [-117.2986, 52.0943],
                        [-117.307, 52.141],
                        [-117.3173, 52.194],
                        [-117.3272, 52.1899],
                        [-117.334, 52.1486],
                        [-117.3813, 52.1377],
                        [-117.5002, 52.1443],
                        [-117.5099, 52.1578],
                        [-117.5231, 52.1582],
                        [-117.6113, 52.1443],
                        [-117.6293, 52.1748],
                        [-117.6635, 52.1979],
                        [-117.7432, 52.194],
                        [-117.7413, 52.203],
                        [-117.8183, 52.2264],
                        [-117.8222, 52.2464],
                        [-117.8396, 52.2741],
                        [-117.7961, 52.2925],
                        [-117.778, 52.3189],
                        [-117.7529, 52.3164],
                        [-117.723, 52.3556],
                        [-117.7048, 52.3575],
                        [-117.7058, 52.3653],
                        [-117.7298, 52.3823],
                        [-117.7665, 52.4177],
                        [-117.8397, 52.4211],
                        [-117.8869, 52.4261],
                        [-117.9668, 52.4697],
                        [-117.988, 52.5002],
                        [-118.0035, 52.4926],
                        [-118.0133, 52.4839],
                        [-118.0218, 52.4723],
                        [-118.0185, 52.4593],
                        [-118.0211, 52.4555],
                        [-118.0421, 52.459],
                        [-118.0507, 52.4571],
                        [-118.0529, 52.4496],
                        [-118.0304, 52.4381],
                        [-118.0407, 52.4185],
                        [-118.0442, 52.3985],
                        [-118.138, 52.4075],
                        [-118.142, 52.4024],
                        [-118.164, 52.3937],
                        [-118.1821, 52.3828],
                        [-118.1801, 52.3726],
                        [-118.2007, 52.3727],
                        [-118.2128, 52.3703],
                        [-118.226, 52.3803],
                        [-118.2203, 52.3851],
                        [-118.2209, 52.3976],
                        [-118.2444, 52.408],
                        [-118.2399, 52.4176],
                        [-118.2398, 52.427],
                        [-118.2513, 52.4398],
                        [-118.2553, 52.4495],
                        [-118.1934, 52.4778],
                        [-118.2355, 52.4902],
                        [-118.2743, 52.524],
                        [-118.2891, 52.5385],
                        [-118.2728, 52.566],
                        [-118.3326, 52.5802],
                        [-118.3336, 52.6062],
                        [-118.3525, 52.6106],
                        [-118.3545, 52.6337],
                        [-118.301, 52.6541],
                        [-118.2901, 52.6777],
                        [-118.3433, 52.7089],
                        [-118.3428, 52.7386],
                        [-118.3911, 52.7604],
                        [-118.4224, 52.7758],
                        [-118.413, 52.806],
                        [-118.3996, 52.8245],
                        [-118.4011, 52.8405],
                        [-118.3889, 52.8489],
                        [-118.4032, 52.8508],
                        [-118.4445, 52.8522],
                        [-118.4584, 52.8596],
                        [-118.4612, 52.8693],
                        [-118.4486, 52.8803],
                        [-118.4483, 52.8853],
                        [-118.4693, 52.8956],
                        [-118.4768, 52.9008],
                        [-118.5001, 52.9063],
                        [-118.5109, 52.9051],
                        [-118.5222, 52.8981],
                        [-118.5437, 52.9084],
                        [-118.5619, 52.9012],
                        [-118.5695, 52.8889],
                        [-118.5872, 52.8822],
                        [-118.6081, 52.8796],
                        [-118.6138, 52.8837],
                        [-118.6143, 52.9001],
                        [-118.6067, 52.904],
                        [-118.621, 52.9163],
                        [-118.6134, 52.936],
                        [-118.6385, 52.9519],
                        [-118.6513, 52.9555],
                        [-118.6602, 52.9639],
                        [-118.6611, 52.9726],
                        [-118.6712, 52.9783],
                        [-118.6698, 52.9834],
                        [-118.6493, 52.9915],
                        [-118.649, 53.0003],
                        [-118.6408, 52.9993],
                        [-118.6526, 53.0184],
                        [-118.6498, 53.0251],
                        [-118.6554, 53.0348],
                        [-118.6688, 53.0425],
                        [-118.6797, 53.0413],
                        [-118.6857, 53.035],
                        [-118.6959, 53.0328],
                        [-118.7006, 53.0444],
                        [-118.7103, 53.0522],
                        [-118.7264, 53.0602],
                        [-118.7461, 53.0523],
                        [-118.7564, 53.0438],
                        [-118.7589, 53.038],
                        [-118.7762, 53.0453],
                        [-118.7591, 53.0658],
                        [-118.7698, 53.0745],
                        [-118.7544, 53.0828],
                        [-118.7487, 53.0883],
                        [-118.7488, 53.0972],
                        [-118.7368, 53.1065],
                        [-118.7295, 53.1155],
                        [-118.7324, 53.1192],
                        [-118.7725, 53.133],
                        [-118.784, 53.1449],
                        [-118.7793, 53.1509],
                        [-118.7865, 53.1592],
                        [-118.8058, 53.1652],
                        [-118.8234, 53.1729],
                        [-118.8315, 53.183],
                        [-118.8478, 53.1859],
                        [-118.8621, 53.1909],
                        [-118.8796, 53.2059],
                        [-118.9094, 53.2097],
                        [-118.916, 53.2118],
                        [-118.9234, 53.2252],
                        [-118.9475, 53.2389],
                        [-118.9653, 53.2368],
                        [-118.9752, 53.2417],
                        [-118.9841, 53.239],
                        [-119.0239, 53.2319],
                        [-119.0262, 53.2264],
                        [-119.0183, 53.2194],
                        [-118.9994, 53.2127],
                        [-118.9994, 53.1928],
                        [-119.0086, 53.1861],
                        [-119.0199, 53.1702],
                        [-119.0288, 53.1642],
                        [-118.9999, 53.1486],
                        [-119.0251, 53.1346],
                        [-119.0246, 53.1267],
                        [-119.048, 53.1268],
                        [-119.0469, 53.1449],
                        [-119.0686, 53.1575],
                        [-119.0885, 53.1655],
                        [-119.1195, 53.1618],
                        [-119.1415, 53.1817],
                        [-119.146, 53.1912],
                        [-119.1756, 53.1846],
                        [-119.1961, 53.1855],
                        [-119.2191, 53.194],
                        [-119.2332, 53.1935],
                        [-119.2327, 53.1818],
                        [-119.2571, 53.1766],
                        [-119.2532, 53.1848],
                        [-119.2515, 53.199],
                        [-119.2625, 53.2125],
                        [-119.2881, 53.2401],
                        [-119.2914, 53.25],
                        [-119.3015, 53.2498],
                        [-119.3005, 53.2562],
                        [-119.3253, 53.2696],
                        [-119.3405, 53.2871],
                        [-119.3297, 53.2966],
                        [-119.356, 53.3123],
                        [-119.3469, 53.3263],
                        [-119.3559, 53.3401],
                        [-119.3907, 53.3608],
                        [-119.4061, 53.3681],
                        [-119.4367, 53.3576],
                        [-119.4592, 53.3572],
                        [-119.5175, 53.3701],
                        [-119.5335, 53.3659],
                        [-119.5485, 53.3675],
                        [-119.5785, 53.3753],
                        [-119.5903, 53.3811],
                        [-119.6045, 53.3834],
                        [-119.5983, 53.3657],
                        [-119.6253, 53.3635],
                        [-119.6375, 53.3691],
                        [-119.6689, 53.3678],
                        [-119.6764, 53.3712],
                        [-119.6828, 53.3843],
                        [-119.6954, 53.3906],
                        [-119.7088, 53.3884],
                        [-119.7254, 53.3888],
                        [-119.7311, 53.4022],
                        [-119.7393, 53.4118],
                        [-119.7584, 53.4264],
                        [-119.7585, 53.4402],
                        [-119.7715, 53.4458],
                        [-119.7837, 53.4704],
                        [-119.7906, 53.4793],
                        [-119.7821, 53.4853],
                        [-119.7804, 53.4918],
                        [-119.8001, 53.5],
                        [-119.8125, 53.4999],
                        [-119.8302, 53.5147],
                        [-119.8329, 53.5193],
                        [-119.8465, 53.5117],
                        [-119.8539, 53.4999],
                        [-119.8702, 53.5],
                        [-119.8754, 53.5063],
                        [-119.8899, 53.5125],
                        [-119.8995, 53.5191],
                        [-119.9003, 53.533],
                        [-119.8623, 53.5485],
                        [-119.8633, 53.5616],
                        [-119.8731, 53.5714],
                        [-119.8915, 53.5777],
                        [-119.9029, 53.59],
                        [-119.9107, 53.6013],
                        [-119.9232, 53.6053],
                        [-119.9304, 53.6104],
                        [-119.9259, 53.6145],
                        [-119.9027, 53.6202],
                        [-119.8794, 53.6168],
                        [-119.8541, 53.6077],
                        [-119.8294, 53.6091],
                        [-119.8167, 53.6041],
                        [-119.7931, 53.6024],
                        [-119.784, 53.594],
                        [-119.7762, 53.5912],
                        [-119.7592, 53.591],
                        [-119.7487, 53.5934],
                        [-119.7392, 53.6022],
                        [-119.7148, 53.6117],
                        [-119.7134, 53.6146],
                        [-119.7264, 53.6283],
                        [-119.7372, 53.6368],
                        [-119.7345, 53.6632],
                        [-119.7556, 53.6649],
                        [-119.7684, 53.675],
                        [-119.7732, 53.6856],
                        [-119.7838, 53.6953],
                        [-119.7867, 53.7013],
                        [-119.7974, 53.7078],
                        [-119.8137, 53.7046],
                        [-119.8221, 53.7005],
                        [-119.8396, 53.6974],
                        [-119.8451, 53.7025],
                        [-119.842, 53.708],
                        [-119.8443, 53.7147],
                        [-119.8704, 53.7148],
                        [-119.8895, 53.7105],
                        [-119.908, 53.7106],
                        [-119.9157, 53.7173],
                        [-119.9008, 53.723],
                        [-119.8975, 53.7307],
                        [-119.9021, 53.7384],
                        [-119.8874, 53.75],
                        [-119.8926, 53.7575],
                        [-119.8858, 53.7685],
                        [-119.8882, 53.7795],
                        [-119.9423, 53.7759],
                        [-119.9592, 53.7811],
                        [-119.983, 53.7972],
                        [-119.9713, 53.8016],
                        [-119.9774, 53.8054],
                        [-120, 53.8062],
                        [-120, 53.9374],
                        [-120, 54.2689],
                        [-119.9999, 54.4909],
                        [-120, 54.5874],
                        [-120, 54.7367],
                        [-120.0003, 54.7977],
                        [-120, 54.9162],
                        [-120, 54.9977],
                        [-119.9998, 55.1148],
                        [-120, 55.1966],
                        [-120, 55.3323],
                        [-120, 55.4383],
                        [-120, 55.5792],
                        [-120, 55.6802],
                        [-120, 55.8429],
                        [-119.9999, 55.9654],
                        [-120, 56.1407],
                        [-120, 56.2612],
                        [-120, 56.4262],
                        [-120, 56.5502],
                        [-120.0001, 56.656],
                        [-120, 56.8041],
                        [-120, 57],
                        [-120, 57.2084],
                        [-120, 57.3806],
                        [-119.9999, 57.514],
                        [-120, 57.7317],
                        [-120, 57.9111],
                        [-120, 58.0795],
                        [-120, 58.25],
                        [-120, 58.3795],
                        [-120, 58.5],
                        [-120, 58.6342],
                        [-120, 58.75],
                        [-120, 58.9997],
                        [-120, 59.0893],
                        [-120, 59.25],
                        [-120, 59.3326],
                        [-120, 59.5],
                        [-120, 59.5843],
                        [-120, 59.75],
                        [-120, 60],
                        [-119.75, 60],
                        [-119.5, 60],
                        [-119.25, 60],
                        [-119, 60],
                        [-118.75, 60],
                        [-118.5, 60],
                        [-118.25, 60],
                        [-118, 60],
                        [-117.75, 60],
                        [-117.5, 60],
                        [-117.25, 60],
                        [-117, 60],
                        [-116.75, 60],
                        [-116.5, 60],
                        [-116.25, 60],
                        [-116, 60],
                        [-115.75, 60],
                        [-115.5, 60],
                        [-115.25, 60],
                        [-115, 60],
                        [-114.75, 60],
                        [-114.5, 60],
                        [-114.25, 60],
                        [-114, 60],
                        [-113.75, 60],
                        [-113.5, 60],
                        [-113.25, 60],
                        [-113, 60],
                        [-112.75, 60],
                        [-112.5, 60],
                        [-112.25, 60],
                        [-112, 60],
                        [-111.75, 60],
                        [-111.5, 60],
                        [-111.25, 60],
                        [-111, 60],
                        [-110.75, 60],
                        [-110.5, 60],
                        [-110.25, 60],
                        [-110, 60]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "30",
            properties: { cityName: "Saskatchewan", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-9941 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-102, 60],
                        [-102, 59.7682],
                        [-102, 59.5669],
                        [-102, 59.4629],
                        [-102, 59.3073],
                        [-102, 59.1469],
                        [-102, 58.9885],
                        [-102, 58.8563],
                        [-102, 58.75],
                        [-102, 58.6142],
                        [-102, 58.4799],
                        [-102, 58.3618],
                        [-102, 58.25],
                        [-102, 58.1298],
                        [-102, 57.9575],
                        [-102, 57.8656],
                        [-102, 57.6674],
                        [-102, 57.5309],
                        [-102, 57.3283],
                        [-102, 57.1782],
                        [-102, 56.9797],
                        [-102, 56.877],
                        [-102, 56.7407],
                        [-102, 56.5773],
                        [-102, 56.4518],
                        [-102, 56.3276],
                        [-102, 56.2281],
                        [-102, 56.0972],
                        [-102, 55.9443],
                        [-102, 55.8128],
                        [-101.9509, 55.814],
                        [-101.9547, 55.6241],
                        [-101.9554, 55.4633],
                        [-101.9145, 55.4619],
                        [-101.9155, 55.3138],
                        [-101.9173, 55.2763],
                        [-101.9202, 55.1117],
                        [-101.9196, 55.1098],
                        [-101.8798, 55.1091],
                        [-101.8817, 54.9358],
                        [-101.8827, 54.7987],
                        [-101.8836, 54.762],
                        [-101.8467, 54.7633],
                        [-101.8436, 54.7431],
                        [-101.8449, 54.6521],
                        [-101.8489, 54.4773],
                        [-101.8503, 54.415],
                        [-101.8112, 54.4141],
                        [-101.8111, 54.3143],
                        [-101.8111, 54.2047],
                        [-101.8112, 54.0642],
                        [-101.7682, 54.0644],
                        [-101.7707, 53.9775],
                        [-101.7706, 53.8206],
                        [-101.7703, 53.7102],
                        [-101.7409, 53.7103],
                        [-101.7381, 53.6017],
                        [-101.7358, 53.5],
                        [-101.7367, 53.3646],
                        [-101.7022, 53.3643],
                        [-101.7017, 53.25],
                        [-101.7013, 53.1535],
                        [-101.7007, 53.0115],
                        [-101.672, 53.0114],
                        [-101.6697, 52.8576],
                        [-101.6711, 52.8263],
                        [-101.6705, 52.7108],
                        [-101.6707, 52.667],
                        [-101.6368, 52.6672],
                        [-101.6368, 52.5622],
                        [-101.6375, 52.5018],
                        [-101.6373, 52.3341],
                        [-101.6378, 52.3183],
                        [-101.608, 52.3183],
                        [-101.608, 52.2599],
                        [-101.6074, 52.2205],
                        [-101.6092, 52.2035],
                        [-101.6087, 52.1878],
                        [-101.6097, 52.0992],
                        [-101.6084, 51.9691],
                        [-101.5776, 51.9685],
                        [-101.5781, 51.9253],
                        [-101.5769, 51.9044],
                        [-101.5775, 51.8228],
                        [-101.5775, 51.7062],
                        [-101.5778, 51.6551],
                        [-101.5705, 51.6551],
                        [-101.5705, 51.5668],
                        [-101.5698, 51.4746],
                        [-101.5706, 51.4054],
                        [-101.5701, 51.3461],
                        [-101.5705, 51.3019],
                        [-101.5392, 51.3017],
                        [-101.5405, 51.223],
                        [-101.5386, 51.1969],
                        [-101.5396, 51.1012],
                        [-101.5394, 51.0044],
                        [-101.538, 50.9921],
                        [-101.5381, 50.9481],
                        [-101.5085, 50.9482],
                        [-101.508, 50.8597],
                        [-101.5085, 50.7863],
                        [-101.5084, 50.7272],
                        [-101.5093, 50.5933],
                        [-101.4753, 50.5942],
                        [-101.4751, 50.5214],
                        [-101.4762, 50.458],
                        [-101.4757, 50.2998],
                        [-101.4774, 50.2402],
                        [-101.446, 50.2403],
                        [-101.4455, 50.1812],
                        [-101.4455, 50.0786],
                        [-101.4452, 50.0048],
                        [-101.448, 49.9908],
                        [-101.4492, 49.9318],
                        [-101.4481, 49.8872],
                        [-101.4193, 49.8871],
                        [-101.4189, 49.8426],
                        [-101.4193, 49.7981],
                        [-101.4186, 49.7095],
                        [-101.4184, 49.5778],
                        [-101.4186, 49.5327],
                        [-101.3928, 49.5325],
                        [-101.393, 49.4588],
                        [-101.3937, 49.3852],
                        [-101.3929, 49.355],
                        [-101.3928, 49.1784],
                        [-101.3626, 49.1783],
                        [-101.3629, 49.1404],
                        [-101.3623, 49.0293],
                        [-101.3625, 49.0001],
                        [-101.5552, 49.0001],
                        [-101.7098, 49.0002],
                        [-102.0066, 49],
                        [-102.2242, 49],
                        [-102.5, 49],
                        [-102.7504, 49],
                        [-103.0044, 49],
                        [-103.2084, 49],
                        [-103.4736, 49],
                        [-103.7413, 49],
                        [-104, 49],
                        [-104.2943, 49],
                        [-104.5, 49],
                        [-104.6549, 49.0002],
                        [-104.8111, 49.0001],
                        [-105.0636, 49.0002],
                        [-105.2115, 49],
                        [-105.4801, 49],
                        [-105.7249, 49],
                        [-106, 49],
                        [-106.2683, 49],
                        [-106.5412, 49],
                        [-106.7789, 49],
                        [-107.0586, 49.0001],
                        [-107.2565, 49],
                        [-107.5028, 49],
                        [-107.7412, 49],
                        [-108.0025, 49],
                        [-108.2549, 49],
                        [-108.4394, 49.0001],
                        [-108.5658, 49.0002],
                        [-108.7961, 49],
                        [-109, 49],
                        [-109.2501, 49],
                        [-109.4299, 49.0001],
                        [-109.6138, 49.0003],
                        [-109.765, 49],
                        [-110.005, 49.0001],
                        [-110.0049, 49.1144],
                        [-110.0048, 49.2573],
                        [-110.0046, 49.4037],
                        [-110.0044, 49.5901],
                        [-110.0043, 49.6949],
                        [-110.0041, 49.8714],
                        [-110.0041, 49.9603],
                        [-110.0051, 50.0476],
                        [-110.0072, 50.2219],
                        [-110.0066, 50.2506],
                        [-110.0078, 50.2798],
                        [-110.006, 50.3507],
                        [-110.0057, 50.426],
                        [-110.0035, 50.4544],
                        [-110.0035, 50.557],
                        [-110.0042, 50.7174],
                        [-110.0032, 50.779],
                        [-110.003, 50.8338],
                        [-110.005, 50.9209],
                        [-110.0046, 50.9572],
                        [-110.0048, 51.0958],
                        [-110.0051, 51.2699],
                        [-110.0036, 51.3434],
                        [-110.0034, 51.4445],
                        [-110.003, 51.4614],
                        [-110.0043, 51.5314],
                        [-110.0043, 51.62],
                        [-110.0039, 51.6414],
                        [-110.0051, 51.7071],
                        [-110.0052, 51.7944],
                        [-110.0042, 51.8218],
                        [-110.004, 51.8816],
                        [-110.0051, 52.0556],
                        [-110.0036, 52.2013],
                        [-110.003, 52.2737],
                        [-110.0039, 52.3324],
                        [-110.0042, 52.4335],
                        [-110.0046, 52.5214],
                        [-110.0054, 52.6036],
                        [-110.0054, 52.6671],
                        [-110.0048, 52.7725],
                        [-110.0052, 52.8067],
                        [-110.0048, 52.8681],
                        [-110.0056, 52.8852],
                        [-110.0046, 52.9872],
                        [-110.0066, 53.0295],
                        [-110.0061, 53.0617],
                        [-110.0057, 53.2138],
                        [-110.0059, 53.3035],
                        [-110.0054, 53.4786],
                        [-110.006, 53.5402],
                        [-110.0057, 53.6094],
                        [-110.0067, 53.6245],
                        [-110.0057, 53.7169],
                        [-110.0058, 53.8894],
                        [-110.0057, 54],
                        [-110.0056, 54.1513],
                        [-110.0057, 54.237],
                        [-110.0044, 54.2775],
                        [-110.0057, 54.3259],
                        [-110.0057, 54.5],
                        [-110.0057, 54.6243],
                        [-110.0057, 54.7477],
                        [-110.0038, 54.9492],
                        [-110.0026, 55.0728],
                        [-110.0015, 55.1805],
                        [-110.0001, 55.3312],
                        [-110.0001, 55.4332],
                        [-110, 55.6177],
                        [-109.9999, 55.75],
                        [-109.9999, 55.8728],
                        [-110, 56],
                        [-110, 56.0867],
                        [-110, 56.232],
                        [-110, 56.3613],
                        [-110, 56.5],
                        [-110, 56.6822],
                        [-110, 56.8331],
                        [-110, 56.9291],
                        [-110, 57.1111],
                        [-110, 57.25],
                        [-110, 57.4398],
                        [-110, 57.5575],
                        [-110, 57.75],
                        [-110, 57.9423],
                        [-110, 58.0405],
                        [-110, 58.2059],
                        [-110, 58.3615],
                        [-110, 58.5073],
                        [-110, 58.6048],
                        [-110, 58.75],
                        [-110, 58.8615],
                        [-110, 59],
                        [-110, 59.25],
                        [-110, 59.391],
                        [-110, 59.4747],
                        [-110, 59.5995],
                        [-110, 59.7652],
                        [-110, 59.8726],
                        [-110, 60],
                        [-109.75, 60],
                        [-109.5, 60],
                        [-109.25, 60],
                        [-109, 60],
                        [-108.75, 60],
                        [-108.5, 60],
                        [-108.25, 60],
                        [-108, 60],
                        [-107.75, 60],
                        [-107.5, 60],
                        [-107.25, 60],
                        [-107, 60],
                        [-106.75, 60],
                        [-106.5, 60],
                        [-106.25, 60],
                        [-106, 60],
                        [-105.75, 60],
                        [-105.5, 60],
                        [-105.25, 60],
                        [-105, 60],
                        [-104.75, 60],
                        [-104.5, 60],
                        [-104.25, 60],
                        [-104, 60],
                        [-103.75, 60],
                        [-103.5, 60],
                        [-103.25, 60],
                        [-103, 60],
                        [-102.75, 60],
                        [-102.5, 60],
                        [-102.25, 60],
                        [-102, 60]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "31",
            properties: { cityName: "Manitoba", builderName: "Matthew Lefebvre ", phoneNo: "1-403-560-9941 ", email: "mlefebvre@dec-tec.com", builderType: "Territory Builder - Western Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-94.8229, 60],
                        [-94.8214, 59.998],
                        [-94.8331, 59.9844],
                        [-94.8304, 59.9784],
                        [-94.8496, 59.9711],
                        [-94.8513, 59.963],
                        [-94.8373, 59.955],
                        [-94.835, 59.9432],
                        [-94.8383, 59.9349],
                        [-94.8349, 59.9277],
                        [-94.8224, 59.9226],
                        [-94.8271, 59.9165],
                        [-94.8198, 59.9078],
                        [-94.8279, 59.9066],
                        [-94.8346, 59.8984],
                        [-94.8199, 59.8966],
                        [-94.8113, 59.8889],
                        [-94.8279, 59.8867],
                        [-94.8333, 59.8825],
                        [-94.8111, 59.8726],
                        [-94.8125, 59.8644],
                        [-94.7998, 59.8552],
                        [-94.8013, 59.8473],
                        [-94.7974, 59.8393],
                        [-94.8094, 59.819],
                        [-94.7893, 59.8057],
                        [-94.7981, 59.8025],
                        [-94.7947, 59.7951],
                        [-94.7784, 59.7933],
                        [-94.773, 59.7876],
                        [-94.7815, 59.774],
                        [-94.7929, 59.768],
                        [-94.7937, 59.7613],
                        [-94.8043, 59.7532],
                        [-94.8221, 59.749],
                        [-94.8271, 59.7384],
                        [-94.8223, 59.7371],
                        [-94.8097, 59.7207],
                        [-94.8206, 59.7173],
                        [-94.832, 59.7035],
                        [-94.8098, 59.7005],
                        [-94.8119, 59.6941],
                        [-94.8273, 59.6849],
                        [-94.8201, 59.6799],
                        [-94.8252, 59.6736],
                        [-94.8111, 59.6675],
                        [-94.8269, 59.6596],
                        [-94.8368, 59.636],
                        [-94.8299, 59.6184],
                        [-94.833, 59.6007],
                        [-94.8223, 59.5935],
                        [-94.8123, 59.5905],
                        [-94.7981, 59.5698],
                        [-94.8106, 59.5602],
                        [-94.8017, 59.5492],
                        [-94.8111, 59.5431],
                        [-94.7983, 59.531],
                        [-94.7989, 59.5152],
                        [-94.791, 59.5062],
                        [-94.7735, 59.5011],
                        [-94.762, 59.4833],
                        [-94.7624, 59.4747],
                        [-94.7509, 59.4677],
                        [-94.7409, 59.4537],
                        [-94.7384, 59.4381],
                        [-94.7488, 59.4269],
                        [-94.7443, 59.4196],
                        [-94.7327, 59.4118],
                        [-94.748, 59.4047],
                        [-94.7425, 59.397],
                        [-94.7238, 59.394],
                        [-94.7248, 59.3905],
                        [-94.7142, 59.3799],
                        [-94.7236, 59.3704],
                        [-94.7306, 59.3702],
                        [-94.7394, 59.3636],
                        [-94.7331, 59.3532],
                        [-94.7455, 59.3499],
                        [-94.7545, 59.3436],
                        [-94.7456, 59.3415],
                        [-94.733, 59.3338],
                        [-94.7147, 59.335],
                        [-94.711, 59.3259],
                        [-94.73, 59.3243],
                        [-94.7335, 59.3099],
                        [-94.772, 59.3069],
                        [-94.7801, 59.2928],
                        [-94.7694, 59.2849],
                        [-94.7715, 59.2804],
                        [-94.7909, 59.2733],
                        [-94.8023, 59.2663],
                        [-94.798, 59.2609],
                        [-94.7817, 59.2556],
                        [-94.7884, 59.2424],
                        [-94.8026, 59.2412],
                        [-94.8045, 59.2352],
                        [-94.7912, 59.2277],
                        [-94.7887, 59.215],
                        [-94.781, 59.204],
                        [-94.7969, 59.199],
                        [-94.8044, 59.1881],
                        [-94.792, 59.1895],
                        [-94.7901, 59.1764],
                        [-94.7794, 59.1531],
                        [-94.7937, 59.1485],
                        [-94.79, 59.1417],
                        [-94.7991, 59.1377],
                        [-94.7935, 59.1264],
                        [-94.8088, 59.1194],
                        [-94.8116, 59.1124],
                        [-94.8044, 59.1016],
                        [-94.8044, 59.0883],
                        [-94.7879, 59.0666],
                        [-94.7923, 59.049],
                        [-94.7889, 59.0406],
                        [-94.7859, 59.0211],
                        [-94.798, 59.009],
                        [-94.7794, 59.0134],
                        [-94.7592, 59.0157],
                        [-94.7502, 59.0129],
                        [-94.7428, 59.0044],
                        [-94.7167, 58.9959],
                        [-94.7056, 58.9906],
                        [-94.6944, 58.9894],
                        [-94.6918, 58.974],
                        [-94.6841, 58.964],
                        [-94.6775, 58.9604],
                        [-94.6892, 58.9535],
                        [-94.6745, 58.9498],
                        [-94.6662, 58.9534],
                        [-94.6446, 58.9518],
                        [-94.5951, 58.9407],
                        [-94.6017, 58.9272],
                        [-94.6044, 58.9106],
                        [-94.5979, 58.9021],
                        [-94.589, 58.9007],
                        [-94.5887, 58.8917],
                        [-94.6033, 58.8869],
                        [-94.6054, 58.8805],
                        [-94.595, 58.8729],
                        [-94.5969, 58.8663],
                        [-94.579, 58.8473],
                        [-94.5598, 58.8376],
                        [-94.5492, 58.8363],
                        [-94.5358, 58.8278],
                        [-94.5254, 58.8274],
                        [-94.5134, 58.8202],
                        [-94.5099, 58.8088],
                        [-94.4972, 58.8019],
                        [-94.4808, 58.7969],
                        [-94.4873, 58.7934],
                        [-94.4752, 58.7836],
                        [-94.4768, 58.7759],
                        [-94.4697, 58.7547],
                        [-94.4733, 58.7503],
                        [-94.4629, 58.7345],
                        [-94.4486, 58.7236],
                        [-94.4347, 58.7207],
                        [-94.4288, 58.7127],
                        [-94.4308, 58.7075],
                        [-94.4208, 58.7039],
                        [-94.3666, 58.7054],
                        [-94.3359, 58.7144],
                        [-94.3224, 58.7211],
                        [-94.3156, 58.7331],
                        [-94.3074, 58.7372],
                        [-94.302, 58.7481],
                        [-94.2964, 58.7505],
                        [-94.2871, 58.7626],
                        [-94.2889, 58.7725],
                        [-94.2803, 58.7792],
                        [-94.2694, 58.7803],
                        [-94.2651, 58.7903],
                        [-94.2492, 58.7894],
                        [-94.2126, 58.7961],
                        [-94.2022, 58.7894],
                        [-94.1854, 58.7833],
                        [-94.1715, 58.7746],
                        [-94.1388, 58.7697],
                        [-94.1093, 58.7695],
                        [-94.075, 58.7669],
                        [-94.0446, 58.7666],
                        [-94.0216, 58.7645],
                        [-93.9935, 58.7666],
                        [-93.9758, 58.7698],
                        [-93.9381, 58.7671],
                        [-93.909, 58.7677],
                        [-93.8846, 58.7554],
                        [-93.8734, 58.7566],
                        [-93.8683, 58.7684],
                        [-93.8564, 58.7721],
                        [-93.8418, 58.7732],
                        [-93.8156, 58.7783],
                        [-93.7981, 58.7721],
                        [-93.7773, 58.7745],
                        [-93.7614, 58.7707],
                        [-93.7424, 58.7711],
                        [-93.7399, 58.7791],
                        [-93.7503, 58.7867],
                        [-93.7251, 58.7914],
                        [-93.705, 58.7859],
                        [-93.6797, 58.7878],
                        [-93.6916, 58.7788],
                        [-93.6747, 58.7706],
                        [-93.6574, 58.7659],
                        [-93.6371, 58.7675],
                        [-93.6371, 58.7755],
                        [-93.6234, 58.7726],
                        [-93.6223, 58.7666],
                        [-93.6115, 58.7634],
                        [-93.5868, 58.7608],
                        [-93.5694, 58.7618],
                        [-93.5598, 58.7662],
                        [-93.5485, 58.7636],
                        [-93.5326, 58.7638],
                        [-93.5232, 58.7681],
                        [-93.5028, 58.7644],
                        [-93.4862, 58.7543],
                        [-93.4818, 58.745],
                        [-93.4855, 58.7403],
                        [-93.4823, 58.7304],
                        [-93.4961, 58.726],
                        [-93.4905, 58.7224],
                        [-93.4754, 58.7224],
                        [-93.4662, 58.713],
                        [-93.4388, 58.7065],
                        [-93.4397, 58.7008],
                        [-93.4214, 58.6985],
                        [-93.406, 58.6989],
                        [-93.4073, 58.7075],
                        [-93.3932, 58.7225],
                        [-93.3655, 58.7398],
                        [-93.3442, 58.7484],
                        [-93.3322, 58.7578],
                        [-93.3091, 58.7523],
                        [-93.2864, 58.754],
                        [-93.2678, 58.7654],
                        [-93.2607, 58.7534],
                        [-93.2309, 58.7563],
                        [-93.2316, 58.7616],
                        [-93.2401, 58.7675],
                        [-93.2201, 58.7673],
                        [-93.2084, 58.7653],
                        [-93.1889, 58.7515],
                        [-93.1749, 58.7332],
                        [-93.1632, 58.7232],
                        [-93.1742, 58.7138],
                        [-93.1658, 58.7063],
                        [-93.1596, 58.6934],
                        [-93.1485, 58.6869],
                        [-93.141, 58.6759],
                        [-93.1437, 58.6666],
                        [-93.1531, 58.6541],
                        [-93.1585, 58.6537],
                        [-93.1608, 58.6366],
                        [-93.1542, 58.6244],
                        [-93.1444, 58.6176],
                        [-93.1325, 58.6048],
                        [-93.1296, 58.5898],
                        [-93.1373, 58.5721],
                        [-93.1259, 58.5618],
                        [-93.1321, 58.5588],
                        [-93.1371, 58.5474],
                        [-93.1333, 58.53],
                        [-93.1118, 58.5063],
                        [-93.1072, 58.492],
                        [-93.1091, 58.4764],
                        [-93.0987, 58.4652],
                        [-93.0944, 58.4568],
                        [-93.0792, 58.4433],
                        [-93.0687, 58.4266],
                        [-93.0526, 58.414],
                        [-93.0564, 58.411],
                        [-93.05, 58.3823],
                        [-93.0378, 58.3696],
                        [-93.0375, 58.3606],
                        [-93.0305, 58.3486],
                        [-93.0133, 58.337],
                        [-93.0074, 58.3285],
                        [-92.9913, 58.3212],
                        [-92.9765, 58.317],
                        [-92.9769, 58.3107],
                        [-92.9718, 58.2919],
                        [-92.9745, 58.2824],
                        [-92.9647, 58.2657],
                        [-92.9512, 58.2559],
                        [-92.9361, 58.2355],
                        [-92.9246, 58.2237],
                        [-92.9258, 58.219],
                        [-92.9185, 58.2005],
                        [-92.9031, 58.1896],
                        [-92.8918, 58.1785],
                        [-92.8664, 58.1737],
                        [-92.8659, 58.1664],
                        [-92.881, 58.1588],
                        [-92.868, 58.1463],
                        [-92.8483, 58.1354],
                        [-92.8494, 58.1147],
                        [-92.847, 58.1053],
                        [-92.8217, 58.0676],
                        [-92.7945, 58.0423],
                        [-92.8103, 58.034],
                        [-92.8121, 58.0204],
                        [-92.8042, 58.0015],
                        [-92.8005, 57.9755],
                        [-92.7899, 57.951],
                        [-92.7802, 57.9375],
                        [-92.7836, 57.9329],
                        [-92.7673, 57.8881],
                        [-92.7596, 57.88],
                        [-92.7623, 57.8763],
                        [-92.7572, 57.8651],
                        [-92.7423, 57.8519],
                        [-92.7476, 57.8448],
                        [-92.7416, 57.8351],
                        [-92.7327, 57.8328],
                        [-92.7313, 57.8117],
                        [-92.7284, 57.7997],
                        [-92.713, 57.7827],
                        [-92.7129, 57.7767],
                        [-92.7008, 57.7591],
                        [-92.6679, 57.7144],
                        [-92.657, 57.7043],
                        [-92.6398, 57.6801],
                        [-92.6324, 57.6735],
                        [-92.626, 57.6595],
                        [-92.6042, 57.6428],
                        [-92.5881, 57.6111],
                        [-92.5749, 57.6029],
                        [-92.564, 57.5924],
                        [-92.5526, 57.5687],
                        [-92.5373, 57.5405],
                        [-92.5234, 57.5382],
                        [-92.5073, 57.5305],
                        [-92.5025, 57.5252],
                        [-92.4943, 57.5049],
                        [-92.4866, 57.4931],
                        [-92.4686, 57.4727],
                        [-92.4528, 57.4437],
                        [-92.439, 57.3898],
                        [-92.4261, 57.3637],
                        [-92.4239, 57.3521],
                        [-92.4242, 57.3293],
                        [-92.433, 57.309],
                        [-92.4367, 57.2861],
                        [-92.4365, 57.2722],
                        [-92.4397, 57.2568],
                        [-92.45, 57.2349],
                        [-92.4588, 57.2224],
                        [-92.4806, 57.1965],
                        [-92.4998, 57.1714],
                        [-92.5308, 57.1244],
                        [-92.5367, 57.1119],
                        [-92.5454, 57.0996],
                        [-92.5697, 57.0741],
                        [-92.5887, 57.0591],
                        [-92.5959, 57.0502],
                        [-92.6125, 57.0434],
                        [-92.6363, 57.023],
                        [-92.6641, 57.0034],
                        [-92.671, 57],
                        [-92.6829, 57],
                        [-92.7165, 56.9578],
                        [-92.7261, 56.9518],
                        [-92.7448, 56.9484],
                        [-92.7586, 56.9439],
                        [-92.7354, 56.9252],
                        [-92.7095, 56.9295],
                        [-92.676, 56.9404],
                        [-92.6458, 56.9563],
                        [-92.6302, 56.9748],
                        [-92.6114, 56.9883],
                        [-92.5826, 56.9951],
                        [-92.573, 57],
                        [-92.562, 57],
                        [-92.5549, 57.0048],
                        [-92.5143, 57.0221],
                        [-92.4785, 57.0363],
                        [-92.4432, 57.0473],
                        [-92.3877, 57.0568],
                        [-92.3681, 57.0595],
                        [-92.3087, 57.0621],
                        [-92.2703, 57.066],
                        [-92.2303, 57.0718],
                        [-92.2171, 57.0727],
                        [-92.2004, 57.0702],
                        [-92.1946, 57.0645],
                        [-92.1231, 57.0495],
                        [-92.1078, 57.0498],
                        [-92.1, 57.0474],
                        [-92.07, 57.047],
                        [-92.0457, 57.0486],
                        [-92.0051, 57.0532],
                        [-91.9693, 57.055],
                        [-91.9569, 57.0634],
                        [-91.9249, 57.0658],
                        [-91.9034, 57.0717],
                        [-91.8787, 57.0757],
                        [-91.8391, 57.0875],
                        [-91.7877, 57.0996],
                        [-91.7268, 57.1196],
                        [-91.6943, 57.1252],
                        [-91.674, 57.1276],
                        [-91.6491, 57.1325],
                        [-91.608, 57.1434],
                        [-91.6021, 57.1408],
                        [-91.5907, 57.148],
                        [-91.5761, 57.1525],
                        [-91.555, 57.155],
                        [-91.5285, 57.1621],
                        [-91.5065, 57.1663],
                        [-91.466, 57.1779],
                        [-91.4555, 57.1794],
                        [-91.4152, 57.1903],
                        [-91.4019, 57.1948],
                        [-91.3855, 57.1973],
                        [-91.3568, 57.2056],
                        [-91.2958, 57.2178],
                        [-91.2764, 57.221],
                        [-91.2543, 57.2279],
                        [-91.2133, 57.2371],
                        [-91.1886, 57.2405],
                        [-91.1633, 57.2475],
                        [-91.1219, 57.2515],
                        [-91.1113, 57.2549],
                        [-91.097, 57.2544],
                        [-91.0875, 57.259],
                        [-91.0309, 57.261],
                        [-91.0113, 57.2683],
                        [-90.9964, 57.2674],
                        [-90.9869, 57.2637],
                        [-90.9665, 57.26],
                        [-90.9398, 57.2627],
                        [-90.8955, 57.2603],
                        [-90.8885, 57.2663],
                        [-90.8725, 57.2622],
                        [-90.8612, 57.2627],
                        [-90.8388, 57.2573],
                        [-90.8296, 57.2579],
                        [-90.8119, 57.2523],
                        [-90.7875, 57.2478],
                        [-90.7734, 57.2512],
                        [-90.7606, 57.2508],
                        [-90.75, 57.2444],
                        [-90.7451, 57.238],
                        [-90.7056, 57.2287],
                        [-90.6829, 57.2384],
                        [-90.6691, 57.2416],
                        [-90.636, 57.239],
                        [-90.6132, 57.2303],
                        [-90.6032, 57.2297],
                        [-90.5982, 57.2251],
                        [-90.5671, 57.2172],
                        [-90.5585, 57.2123],
                        [-90.5438, 57.2164],
                        [-90.5343, 57.2158],
                        [-90.5225, 57.2014],
                        [-90.5079, 57.197],
                        [-90.489, 57.1999],
                        [-90.4776, 57.1998],
                        [-90.4349, 57.193],
                        [-90.4176, 57.1847],
                        [-90.3925, 57.1754],
                        [-90.3851, 57.1689],
                        [-90.39, 57.1599],
                        [-90.3423, 57.1472],
                        [-90.3211, 57.1428],
                        [-90.3055, 57.1346],
                        [-90.2483, 57.115],
                        [-90.2282, 57.1061],
                        [-90.1888, 57.0917],
                        [-90.1818, 57.0843],
                        [-90.1639, 57.073],
                        [-90.1555, 57.0736],
                        [-90.1436, 57.0698],
                        [-90.0607, 57.0491],
                        [-89.9861, 57.0228],
                        [-89.9633, 57.0077],
                        [-89.9435, 57.0061],
                        [-89.9285, 57.0017],
                        [-89.9067, 57.0006],
                        [-89.8901, 56.9973],
                        [-89.8553, 56.9872],
                        [-89.8195, 56.9791],
                        [-89.7692, 56.972],
                        [-89.7517, 56.9681],
                        [-89.7078, 56.9627],
                        [-89.6895, 56.9585],
                        [-89.6405, 56.9527],
                        [-89.561, 56.9402],
                        [-89.5447, 56.9392],
                        [-89.5001, 56.9301],
                        [-89.47, 56.9298],
                        [-89.4505, 56.9313],
                        [-89.4348, 56.9289],
                        [-89.4061, 56.9308],
                        [-89.3855, 56.9285],
                        [-89.3371, 56.9169],
                        [-89.2748, 56.8963],
                        [-89.2365, 56.8904],
                        [-89.2073, 56.878],
                        [-89.1933, 56.8741],
                        [-89.1721, 56.8756],
                        [-89.0517, 56.8567],
                        [-89.0009, 56.8502],
                        [-88.9865, 56.847],
                        [-89.149, 56.7501],
                        [-89.3059, 56.6557],
                        [-89.458, 56.5636],
                        [-89.6442, 56.4498],
                        [-89.8094, 56.348],
                        [-89.9672, 56.25],
                        [-90.1529, 56.1338],
                        [-90.3112, 56.0342],
                        [-90.5532, 55.8804],
                        [-90.704, 55.7838],
                        [-90.8023, 55.7204],
                        [-91, 55.5912],
                        [-91.1633, 55.4835],
                        [-91.3461, 55.362],
                        [-91.44, 55.2992],
                        [-91.6478, 55.1585],
                        [-91.7476, 55.0851],
                        [-91.8701, 54.9998],
                        [-92, 54.906],
                        [-92.1888, 54.7749],
                        [-92.3841, 54.6423],
                        [-92.5, 54.5629],
                        [-92.6586, 54.4534],
                        [-92.7417, 54.395],
                        [-92.9276, 54.2637],
                        [-93.0917, 54.146],
                        [-93.17, 54.0895],
                        [-93.3292, 53.9697],
                        [-93.5205, 53.8315],
                        [-93.6299, 53.7501],
                        [-93.7028, 53.706],
                        [-93.8233, 53.6345],
                        [-93.9549, 53.5559],
                        [-94.0538, 53.5],
                        [-94.2627, 53.3814],
                        [-94.4261, 53.2838],
                        [-94.642, 53.1535],
                        [-94.7372, 53.0959],
                        [-94.8984, 52.9976],
                        [-95.0036, 52.933],
                        [-95.1532, 52.8406],
                        [-95.1531, 52.7254],
                        [-95.1531, 52.6303],
                        [-95.1531, 52.5157],
                        [-95.1531, 52.3813],
                        [-95.1531, 52.2557],
                        [-95.1531, 52.0961],
                        [-95.1531, 51.9915],
                        [-95.1531, 51.8704],
                        [-95.153, 51.7068],
                        [-95.153, 51.5548],
                        [-95.153, 51.4257],
                        [-95.153, 51.2501],
                        [-95.153, 51.1198],
                        [-95.153, 50.9144],
                        [-95.153, 50.7501],
                        [-95.1529, 50.6371],
                        [-95.1529, 50.5094],
                        [-95.1529, 50.3695],
                        [-95.1529, 50.2606],
                        [-95.1529, 50.1198],
                        [-95.1529, 50.0038],
                        [-95.1529, 49.8866],
                        [-95.1529, 49.7954],
                        [-95.1534, 49.6453],
                        [-95.1698, 49.644],
                        [-95.1861, 49.6271],
                        [-95.1967, 49.6206],
                        [-95.2078, 49.6207],
                        [-95.2116, 49.6036],
                        [-95.2002, 49.608],
                        [-95.1859, 49.6052],
                        [-95.1609, 49.6117],
                        [-95.1532, 49.6163],
                        [-95.1529, 49.6028],
                        [-95.1912, 49.6015],
                        [-95.2036, 49.596],
                        [-95.1999, 49.5912],
                        [-95.1875, 49.5869],
                        [-95.1539, 49.5885],
                        [-95.1526, 49.5766],
                        [-95.1538, 49.5599],
                        [-95.1525, 49.5322],
                        [-95.1524, 49.47],
                        [-95.1629, 49.4653],
                        [-95.1604, 49.4585],
                        [-95.1527, 49.4513],
                        [-95.1535, 49.3987],
                        [-95.1574, 49.3909],
                        [-95.1534, 49.3826],
                        [-95.1537, 49.3531],
                        [-95.1531, 49.1747],
                        [-95.1609, 49.1742],
                        [-95.1747, 49.1778],
                        [-95.1918, 49.1776],
                        [-95.2133, 49.174],
                        [-95.2348, 49.1656],
                        [-95.2485, 49.162],
                        [-95.2766, 49.1401],
                        [-95.2828, 49.1299],
                        [-95.2917, 49.1044],
                        [-95.2902, 49.0932],
                        [-95.2856, 49.0801],
                        [-95.2772, 49.0684],
                        [-95.2763, 49.057],
                        [-95.2609, 49.0414],
                        [-95.2333, 49.0349],
                        [-95.2195, 49.036],
                        [-95.2172, 49.0276],
                        [-95.224, 49.0086],
                        [-95.2317, 49],
                        [-95.5, 49],
                        [-95.75, 49],
                        [-95.9781, 49],
                        [-96.2477, 49],
                        [-96.5006, 49],
                        [-96.7846, 49],
                        [-97, 49],
                        [-97.25, 49],
                        [-97.4811, 49],
                        [-97.6386, 49.0006],
                        [-97.8532, 49.0005],
                        [-98.0331, 49.0004],
                        [-98.1997, 49.0003],
                        [-98.3891, 49.0001],
                        [-98.5275, 49],
                        [-98.7572, 49],
                        [-99.0069, 49],
                        [-99.2431, 49],
                        [-99.5, 49],
                        [-99.7261, 49],
                        [-99.9425, 49.0001],
                        [-100.25, 49],
                        [-100.4376, 49.0001],
                        [-100.5534, 49.0001],
                        [-100.7973, 49],
                        [-100.9625, 49],
                        [-101.2551, 49],
                        [-101.3625, 49.0001],
                        [-101.3623, 49.0293],
                        [-101.3629, 49.1404],
                        [-101.3626, 49.1783],
                        [-101.3928, 49.1784],
                        [-101.3929, 49.355],
                        [-101.3937, 49.3852],
                        [-101.393, 49.4588],
                        [-101.3928, 49.5325],
                        [-101.4186, 49.5327],
                        [-101.4184, 49.5778],
                        [-101.4186, 49.7095],
                        [-101.4193, 49.7981],
                        [-101.4189, 49.8426],
                        [-101.4193, 49.8871],
                        [-101.4481, 49.8872],
                        [-101.4492, 49.9318],
                        [-101.448, 49.9908],
                        [-101.4452, 50.0048],
                        [-101.4455, 50.0786],
                        [-101.4455, 50.1812],
                        [-101.446, 50.2403],
                        [-101.4774, 50.2402],
                        [-101.4757, 50.2998],
                        [-101.4762, 50.458],
                        [-101.4751, 50.5214],
                        [-101.4753, 50.5942],
                        [-101.5093, 50.5933],
                        [-101.5084, 50.7272],
                        [-101.5085, 50.7863],
                        [-101.508, 50.8597],
                        [-101.5085, 50.9482],
                        [-101.5381, 50.9481],
                        [-101.538, 50.9921],
                        [-101.5394, 51.0044],
                        [-101.5396, 51.1012],
                        [-101.5386, 51.1969],
                        [-101.5405, 51.223],
                        [-101.5392, 51.3017],
                        [-101.5705, 51.3019],
                        [-101.5701, 51.3461],
                        [-101.5706, 51.4054],
                        [-101.5698, 51.4746],
                        [-101.5705, 51.5668],
                        [-101.5705, 51.6551],
                        [-101.5778, 51.6551],
                        [-101.5775, 51.7062],
                        [-101.5775, 51.8228],
                        [-101.5769, 51.9044],
                        [-101.5781, 51.9253],
                        [-101.5776, 51.9685],
                        [-101.6084, 51.9691],
                        [-101.6097, 52.0992],
                        [-101.6087, 52.1878],
                        [-101.6092, 52.2035],
                        [-101.6074, 52.2205],
                        [-101.608, 52.2599],
                        [-101.608, 52.3183],
                        [-101.6378, 52.3183],
                        [-101.6373, 52.3341],
                        [-101.6375, 52.5018],
                        [-101.6368, 52.5622],
                        [-101.6368, 52.6672],
                        [-101.6707, 52.667],
                        [-101.6705, 52.7108],
                        [-101.6711, 52.8263],
                        [-101.6697, 52.8576],
                        [-101.672, 53.0114],
                        [-101.7007, 53.0115],
                        [-101.7013, 53.1535],
                        [-101.7017, 53.25],
                        [-101.7022, 53.3643],
                        [-101.7367, 53.3646],
                        [-101.7358, 53.5],
                        [-101.7381, 53.6017],
                        [-101.7409, 53.7103],
                        [-101.7703, 53.7102],
                        [-101.7706, 53.8206],
                        [-101.7707, 53.9775],
                        [-101.7682, 54.0644],
                        [-101.8112, 54.0642],
                        [-101.8111, 54.2047],
                        [-101.8111, 54.3143],
                        [-101.8112, 54.4141],
                        [-101.8503, 54.415],
                        [-101.8489, 54.4773],
                        [-101.8449, 54.6521],
                        [-101.8436, 54.7431],
                        [-101.8467, 54.7633],
                        [-101.8836, 54.762],
                        [-101.8827, 54.7987],
                        [-101.8817, 54.9358],
                        [-101.8798, 55.1091],
                        [-101.9196, 55.1098],
                        [-101.9202, 55.1117],
                        [-101.9173, 55.2763],
                        [-101.9155, 55.3138],
                        [-101.9145, 55.4619],
                        [-101.9554, 55.4633],
                        [-101.9547, 55.6241],
                        [-101.9509, 55.814],
                        [-102, 55.8128],
                        [-102, 55.9443],
                        [-102, 56.0972],
                        [-102, 56.2281],
                        [-102, 56.3276],
                        [-102, 56.4518],
                        [-102, 56.5773],
                        [-102, 56.7407],
                        [-102, 56.877],
                        [-102, 56.9797],
                        [-102, 57.1782],
                        [-102, 57.3283],
                        [-102, 57.5309],
                        [-102, 57.6674],
                        [-102, 57.8656],
                        [-102, 57.9575],
                        [-102, 58.1298],
                        [-102, 58.25],
                        [-102, 58.3618],
                        [-102, 58.4799],
                        [-102, 58.6142],
                        [-102, 58.75],
                        [-102, 58.8563],
                        [-102, 58.9885],
                        [-102, 59.1469],
                        [-102, 59.3073],
                        [-102, 59.4629],
                        [-102, 59.5669],
                        [-102, 59.7682],
                        [-102, 60],
                        [-101.75, 60],
                        [-101.5, 60],
                        [-101.25, 60],
                        [-101, 60],
                        [-100.75, 60],
                        [-100.5, 60],
                        [-100.25, 60],
                        [-100, 60],
                        [-99.75, 60],
                        [-99.5, 60],
                        [-99.25, 60],
                        [-99, 60],
                        [-98.75, 60],
                        [-98.5, 60],
                        [-98.25, 60],
                        [-98, 60],
                        [-97.75, 60],
                        [-97.5, 60],
                        [-97.25, 60],
                        [-97, 60],
                        [-96.75, 60],
                        [-96.5, 60],
                        [-96.25, 60],
                        [-96, 60],
                        [-95.75, 60],
                        [-95.4875, 60],
                        [-95.175, 60],
                        [-94.9375, 60],
                        [-94.8229, 60]
                    ]
                ]
            },
        },
        {
            type: "Feature",
            id: "32",
            properties: { cityName: "Ontario", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-79.5156, 51.4608],
                        [-79.5175, 51.2712],
                        [-79.519, 51.0623],
                        [-79.5179, 51.0096],
                        [-79.5179, 50.886],
                        [-79.5179, 50.762],
                        [-79.5179, 50.6317],
                        [-79.5179, 50.5],
                        [-79.5179, 50.3828],
                        [-79.518, 50.1947],
                        [-79.5175, 50.1654],
                        [-79.5175, 50],
                        [-79.5176, 49.8225],
                        [-79.5177, 49.677],
                        [-79.5178, 49.5257],
                        [-79.5178, 49.4001],
                        [-79.5179, 49.3131],
                        [-79.5179, 49.1906],
                        [-79.518, 49.0089],
                        [-79.5173, 48.9581],
                        [-79.5174, 48.7599],
                        [-79.5177, 48.6691],
                        [-79.5175, 48.5626],
                        [-79.5174, 48.4167],
                        [-79.5174, 48.2878],
                        [-79.5176, 48.1897],
                        [-79.5171, 48.1733],
                        [-79.5177, 48.1026],
                        [-79.5174, 47.9964],
                        [-79.5178, 47.892],
                        [-79.5177, 47.802],
                        [-79.518, 47.7413],
                        [-79.5175, 47.6908],
                        [-79.5174, 47.5572],
                        [-79.5105, 47.5436],
                        [-79.5171, 47.5332],
                        [-79.5474, 47.5178],
                        [-79.5562, 47.504],
                        [-79.5563, 47.4911],
                        [-79.5607, 47.4777],
                        [-79.5788, 47.4583],
                        [-79.5865, 47.4456],
                        [-79.5869, 47.4331],
                        [-79.581, 47.4151],
                        [-79.5668, 47.3989],
                        [-79.5401, 47.3662],
                        [-79.5145, 47.3433],
                        [-79.5017, 47.328],
                        [-79.488, 47.3172],
                        [-79.483, 47.3111],
                        [-79.4778, 47.2985],
                        [-79.4662, 47.2885],
                        [-79.4501, 47.2772],
                        [-79.4268, 47.2501],
                        [-79.4266, 47.2368],
                        [-79.4375, 47.2193],
                        [-79.4375, 47.2124],
                        [-79.4321, 47.1913],
                        [-79.432, 47.18],
                        [-79.441, 47.1501],
                        [-79.4422, 47.1385],
                        [-79.443, 47.101],
                        [-79.435, 47.0842],
                        [-79.4209, 47.0697],
                        [-79.3767, 47.0289],
                        [-79.3538, 47.0144],
                        [-79.3468, 47.0079],
                        [-79.338, 46.9844],
                        [-79.3375, 46.9709],
                        [-79.3297, 46.9572],
                        [-79.3056, 46.9278],
                        [-79.2768, 46.8953],
                        [-79.2574, 46.8808],
                        [-79.2388, 46.8626],
                        [-79.224, 46.8433],
                        [-79.2138, 46.8333],
                        [-79.1708, 46.8252],
                        [-79.1548, 46.8048],
                        [-79.1501, 46.7946],
                        [-79.1431, 46.786],
                        [-79.1416, 46.7772],
                        [-79.1232, 46.7442],
                        [-79.1211, 46.7344],
                        [-79.1028, 46.7109],
                        [-79.0971, 46.6991],
                        [-79.0943, 46.6883],
                        [-79.0719, 46.6642],
                        [-79.0531, 46.6499],
                        [-79.0274, 46.6382],
                        [-79.0153, 46.6269],
                        [-79.0092, 46.6044],
                        [-78.9957, 46.5909],
                        [-78.9972, 46.5659],
                        [-78.9899, 46.5489],
                        [-78.9749, 46.5359],
                        [-78.9693, 46.5288],
                        [-78.9555, 46.5167],
                        [-78.9465, 46.5133],
                        [-78.9325, 46.4969],
                        [-78.9171, 46.4822],
                        [-78.8996, 46.4699],
                        [-78.8927, 46.4619],
                        [-78.8793, 46.4557],
                        [-78.863, 46.4456],
                        [-78.8374, 46.4369],
                        [-78.8113, 46.4266],
                        [-78.7864, 46.4136],
                        [-78.7582, 46.3934],
                        [-78.7395, 46.3892],
                        [-78.7271, 46.3829],
                        [-78.7225, 46.3708],
                        [-78.7242, 46.3604],
                        [-78.7294, 46.3483],
                        [-78.7282, 46.3423],
                        [-78.7188, 46.3315],
                        [-78.7115, 46.326],
                        [-78.6973, 46.3197],
                        [-78.6758, 46.3182],
                        [-78.6484, 46.3208],
                        [-78.631, 46.3212],
                        [-78.5971, 46.3189],
                        [-78.5649, 46.3114],
                        [-78.5438, 46.308],
                        [-78.5242, 46.2999],
                        [-78.5027, 46.2946],
                        [-78.4815, 46.2948],
                        [-78.4701, 46.2935],
                        [-78.4579, 46.2947],
                        [-78.4264, 46.2952],
                        [-78.3884, 46.2917],
                        [-78.3659, 46.2801],
                        [-78.3528, 46.2715],
                        [-78.3334, 46.2615],
                        [-78.3217, 46.2582],
                        [-78.3124, 46.2524],
                        [-78.2881, 46.2586],
                        [-78.2811, 46.2642],
                        [-78.2605, 46.2739],
                        [-78.2397, 46.275],
                        [-78.2164, 46.2692],
                        [-78.1942, 46.274],
                        [-78.1702, 46.2763],
                        [-78.1299, 46.2745],
                        [-78.1166, 46.2648],
                        [-78.1028, 46.2595],
                        [-78.089, 46.2565],
                        [-78.0736, 46.2496],
                        [-78.0559, 46.2439],
                        [-78.0427, 46.2425],
                        [-78.0281, 46.2461],
                        [-77.9999, 46.249],
                        [-77.9766, 46.243],
                        [-77.9467, 46.231],
                        [-77.9268, 46.2241],
                        [-77.9061, 46.2218],
                        [-77.8809, 46.2164],
                        [-77.8633, 46.2094],
                        [-77.8451, 46.2083],
                        [-77.8161, 46.2035],
                        [-77.7956, 46.1976],
                        [-77.7839, 46.1959],
                        [-77.7295, 46.1931],
                        [-77.7107, 46.1886],
                        [-77.6978, 46.1829],
                        [-77.6905, 46.184],
                        [-77.6804, 46.1983],
                        [-77.6691, 46.1965],
                        [-77.6567, 46.1903],
                        [-77.6184, 46.174],
                        [-77.609, 46.1732],
                        [-77.5661, 46.1592],
                        [-77.4894, 46.1175],
                        [-77.4419, 46.0933],
                        [-77.4188, 46.083],
                        [-77.3896, 46.0683],
                        [-77.3624, 46.0595],
                        [-77.3324, 46.0419],
                        [-77.3147, 46.0269],
                        [-77.2831, 46.0177],
                        [-77.2763, 46.0085],
                        [-77.2835, 45.9969],
                        [-77.2867, 45.9865],
                        [-77.2857, 45.9762],
                        [-77.2784, 45.9489],
                        [-77.2729, 45.9411],
                        [-77.25, 45.9257],
                        [-77.2387, 45.9229],
                        [-77.2363, 45.9129],
                        [-77.2304, 45.9055],
                        [-77.2071, 45.8818],
                        [-77.1985, 45.8704],
                        [-77.1903, 45.8639],
                        [-77.1543, 45.8547],
                        [-77.1315, 45.844],
                        [-77.1108, 45.8398],
                        [-77.098, 45.8392],
                        [-77.0816, 45.8356],
                        [-77.0668, 45.8279],
                        [-77.0537, 45.8106],
                        [-77.0424, 45.8073],
                        [-77.0248, 45.8086],
                        [-77.0112, 45.8042],
                        [-76.9943, 45.7903],
                        [-76.985, 45.7861],
                        [-76.9479, 45.7877],
                        [-76.9353, 45.7891],
                        [-76.9184, 45.7984],
                        [-76.912, 45.8097],
                        [-76.9277, 45.845],
                        [-76.9256, 45.8489],
                        [-76.9104, 45.8473],
                        [-76.9019, 45.8501],
                        [-76.9092, 45.8614],
                        [-76.9228, 45.8701],
                        [-76.9235, 45.8764],
                        [-76.9299, 45.8878],
                        [-76.9276, 45.8923],
                        [-76.8948, 45.8981],
                        [-76.8754, 45.8926],
                        [-76.8416, 45.8904],
                        [-76.8332, 45.8881],
                        [-76.8074, 45.8773],
                        [-76.7871, 45.8744],
                        [-76.7787, 45.8714],
                        [-76.7734, 45.8595],
                        [-76.7647, 45.851],
                        [-76.77, 45.8297],
                        [-76.7658, 45.811],
                        [-76.7694, 45.8022],
                        [-76.7814, 45.7853],
                        [-76.7752, 45.7745],
                        [-76.7707, 45.7582],
                        [-76.7713, 45.7504],
                        [-76.7658, 45.7468],
                        [-76.7678, 45.7335],
                        [-76.7555, 45.7265],
                        [-76.7446, 45.7229],
                        [-76.7204, 45.7191],
                        [-76.7109, 45.7211],
                        [-76.7036, 45.7174],
                        [-76.6924, 45.7071],
                        [-76.6961, 45.701],
                        [-76.687, 45.6873],
                        [-76.6908, 45.6794],
                        [-76.7032, 45.676],
                        [-76.7119, 45.6655],
                        [-76.7089, 45.6595],
                        [-76.699, 45.6534],
                        [-76.6815, 45.632],
                        [-76.6726, 45.6267],
                        [-76.6698, 45.6213],
                        [-76.6727, 45.6074],
                        [-76.6738, 45.5815],
                        [-76.66, 45.5606],
                        [-76.6294, 45.5431],
                        [-76.608, 45.5318],
                        [-76.5726, 45.5276],
                        [-76.5179, 45.516],
                        [-76.5011, 45.519],
                        [-76.4815, 45.5048],
                        [-76.4536, 45.5004],
                        [-76.4335, 45.4921],
                        [-76.4092, 45.4771],
                        [-76.3862, 45.4647],
                        [-76.3617, 45.4571],
                        [-76.317, 45.457],
                        [-76.2913, 45.4659],
                        [-76.2451, 45.4686],
                        [-76.2385, 45.4753],
                        [-76.2335, 45.5078],
                        [-76.2278, 45.5118],
                        [-76.201, 45.5189],
                        [-76.1662, 45.5173],
                        [-76.1404, 45.5182],
                        [-76.1056, 45.5162],
                        [-76.0899, 45.5139],
                        [-76.0482, 45.5001],
                        [-76.0232, 45.4891],
                        [-75.9872, 45.4796],
                        [-75.969, 45.4699],
                        [-75.9547, 45.4603],
                        [-75.9287, 45.4288],
                        [-75.916, 45.4174],
                        [-75.8952, 45.4016],
                        [-75.884, 45.395],
                        [-75.8426, 45.376],
                        [-75.8298, 45.3732],
                        [-75.8178, 45.3727],
                        [-75.797, 45.3783],
                        [-75.7789, 45.3903],
                        [-75.7564, 45.4124],
                        [-75.7296, 45.417],
                        [-75.722, 45.4207],
                        [-75.7083, 45.4233],
                        [-75.7037, 45.4266],
                        [-75.7026, 45.4376],
                        [-75.6961, 45.4495],
                        [-75.6889, 45.4558],
                        [-75.6776, 45.4608],
                        [-75.6649, 45.4634],
                        [-75.6394, 45.4659],
                        [-75.6182, 45.4717],
                        [-75.5913, 45.4728],
                        [-75.5756, 45.4748],
                        [-75.5562, 45.4845],
                        [-75.5204, 45.4988],
                        [-75.4779, 45.5135],
                        [-75.4569, 45.5194],
                        [-75.4454, 45.5211],
                        [-75.4236, 45.5199],
                        [-75.4153, 45.5231],
                        [-75.391, 45.5242],
                        [-75.3834, 45.5272],
                        [-75.3425, 45.537],
                        [-75.317, 45.5462],
                        [-75.3046, 45.553],
                        [-75.3003, 45.5583],
                        [-75.2426, 45.5855],
                        [-75.2223, 45.5875],
                        [-75.1983, 45.5854],
                        [-75.1735, 45.577],
                        [-75.1555, 45.5785],
                        [-75.1326, 45.5766],
                        [-75.1135, 45.5786],
                        [-75.0875, 45.588],
                        [-75.0686, 45.5909],
                        [-75.0527, 45.5913],
                        [-75.0355, 45.5938],
                        [-75.0162, 45.5989],
                        [-74.989, 45.6108],
                        [-74.9672, 45.6246],
                        [-74.9552, 45.6356],
                        [-74.943, 45.6434],
                        [-74.9283, 45.6457],
                        [-74.8784, 45.6419],
                        [-74.8481, 45.6368],
                        [-74.8352, 45.6358],
                        [-74.8013, 45.6392],
                        [-74.7864, 45.6388],
                        [-74.7528, 45.6336],
                        [-74.7293, 45.6283],
                        [-74.7168, 45.6285],
                        [-74.6783, 45.6325],
                        [-74.6381, 45.6388],
                        [-74.6255, 45.6346],
                        [-74.6099, 45.6227],
                        [-74.5985, 45.6181],
                        [-74.5628, 45.6066],
                        [-74.5351, 45.5948],
                        [-74.5261, 45.5926],
                        [-74.51, 45.5922],
                        [-74.4895, 45.5953],
                        [-74.4724, 45.5937],
                        [-74.4608, 45.5887],
                        [-74.4433, 45.5763],
                        [-74.4323, 45.5723],
                        [-74.4177, 45.5708],
                        [-74.3962, 45.5705],
                        [-74.3813, 45.5661],
                        [-74.3943, 45.5212],
                        [-74.4147, 45.4592],
                        [-74.4339, 45.4055],
                        [-74.4722, 45.3027],
                        [-74.4325, 45.2732],
                        [-74.344, 45.2052],
                        [-74.3603, 45.1942],
                        [-74.3752, 45.1824],
                        [-74.3881, 45.1812],
                        [-74.392, 45.1773],
                        [-74.3862, 45.1717],
                        [-74.3904, 45.168],
                        [-74.4151, 45.1679],
                        [-74.4298, 45.1613],
                        [-74.4295, 45.1576],
                        [-74.4453, 45.1585],
                        [-74.455, 45.1553],
                        [-74.4724, 45.144],
                        [-74.4743, 45.1379],
                        [-74.4878, 45.1275],
                        [-74.5032, 45.1207],
                        [-74.5079, 45.1133],
                        [-74.5114, 45.0985],
                        [-74.5296, 45.0822],
                        [-74.5296, 45.0777],
                        [-74.5436, 45.0679],
                        [-74.548, 45.062],
                        [-74.5649, 45.0569],
                        [-74.5746, 45.0517],
                        [-74.5995, 45.046],
                        [-74.6126, 45.0406],
                        [-74.6157, 45.0359],
                        [-74.6292, 45.0335],
                        [-74.6478, 45.0354],
                        [-74.6586, 45.03],
                        [-74.6652, 45.0323],
                        [-74.6943, 45.0201],
                        [-74.6996, 45.0162],
                        [-74.7141, 45.0136],
                        [-74.7294, 45.0132],
                        [-74.7346, 45.0107],
                        [-74.7789, 45.0102],
                        [-74.7917, 45.0139],
                        [-74.7895, 45.0243],
                        [-74.7928, 45.0273],
                        [-74.8053, 45.0245],
                        [-74.8141, 45.0248],
                        [-74.8313, 45.0324],
                        [-74.8484, 45.0292],
                        [-74.8602, 45.0299],
                        [-74.8654, 45.0265],
                        [-74.8741, 45.0285],
                        [-74.8894, 45.0273],
                        [-74.8986, 45.024],
                        [-74.9171, 45.0206],
                        [-74.9291, 45.0103],
                        [-74.9365, 45.0138],
                        [-74.936, 45.0238],
                        [-74.9466, 45.023],
                        [-74.9437, 45.0057],
                        [-74.9567, 45.0039],
                        [-74.9639, 44.9968],
                        [-74.977, 44.995],
                        [-74.9931, 44.9905],
                        [-74.9986, 44.9908],
                        [-75.0079, 44.9846],
                        [-75.0075, 44.9772],
                        [-75.0147, 44.969],
                        [-75.0334, 44.9561],
                        [-75.0442, 44.9466],
                        [-75.0644, 44.9463],
                        [-75.0743, 44.9382],
                        [-75.0963, 44.9346],
                        [-75.1136, 44.9332],
                        [-75.1358, 44.924],
                        [-75.169, 44.8984],
                        [-75.1939, 44.8889],
                        [-75.2013, 44.8826],
                        [-75.2164, 44.8822],
                        [-75.2288, 44.8746],
                        [-75.2463, 44.8696],
                        [-75.2588, 44.8614],
                        [-75.2798, 44.8543],
                        [-75.2903, 44.8535],
                        [-75.3077, 44.8437],
                        [-75.3139, 44.8292],
                        [-75.3213, 44.8346],
                        [-75.3264, 44.8286],
                        [-75.3278, 44.8198],
                        [-75.3441, 44.8148],
                        [-75.3638, 44.799],
                        [-75.3725, 44.7937],
                        [-75.3726, 44.787],
                        [-75.3922, 44.7836],
                        [-75.3962, 44.7789],
                        [-75.4075, 44.7764],
                        [-75.4196, 44.779],
                        [-75.4266, 44.7765],
                        [-75.432, 44.7629],
                        [-75.4483, 44.7552],
                        [-75.4492, 44.7506],
                        [-75.4617, 44.7472],
                        [-75.4738, 44.7369],
                        [-75.4725, 44.7335],
                        [-75.4829, 44.7225],
                        [-75.4917, 44.7209],
                        [-75.5206, 44.7035],
                        [-75.5256, 44.7037],
                        [-75.5371, 44.6981],
                        [-75.5514, 44.6868],
                        [-75.5558, 44.6789],
                        [-75.5614, 44.6787],
                        [-75.5766, 44.6598],
                        [-75.5915, 44.6505],
                        [-75.6299, 44.6224],
                        [-75.6392, 44.617],
                        [-75.6619, 44.6009],
                        [-75.6666, 44.5996],
                        [-75.6983, 44.5771],
                        [-75.7122, 44.5699],
                        [-75.7152, 44.5622],
                        [-75.7356, 44.5487],
                        [-75.7442, 44.5473],
                        [-75.7469, 44.5413],
                        [-75.76, 44.5356],
                        [-75.7703, 44.5254],
                        [-75.7814, 44.52],
                        [-75.7841, 44.5149],
                        [-75.8002, 44.5043],
                        [-75.8135, 44.4885],
                        [-75.8298, 44.4844],
                        [-75.8355, 44.4734],
                        [-75.8731, 44.4415],
                        [-75.877, 44.4337],
                        [-75.8891, 44.4264],
                        [-75.8945, 44.4177],
                        [-75.9016, 44.4113],
                        [-75.91, 44.3985],
                        [-75.9242, 44.3911],
                        [-75.9294, 44.3823],
                        [-75.9369, 44.3758],
                        [-75.9488, 44.375],
                        [-75.9576, 44.3697],
                        [-75.9829, 44.3641],
                        [-75.9959, 44.3652],
                        [-76.0127, 44.3643],
                        [-76.0294, 44.3529],
                        [-76.0394, 44.3525],
                        [-76.0436, 44.3492],
                        [-76.0535, 44.3486],
                        [-76.0612, 44.3437],
                        [-76.0683, 44.3461],
                        [-76.0652, 44.3512],
                        [-76.0779, 44.353],
                        [-76.0935, 44.3485],
                        [-76.1026, 44.3392],
                        [-76.1211, 44.335],
                        [-76.1476, 44.3309],
                        [-76.1628, 44.3227],
                        [-76.1718, 44.3225],
                        [-76.184, 44.3171],
                        [-76.1922, 44.3084],
                        [-76.207, 44.3118],
                        [-76.2141, 44.3193],
                        [-76.2213, 44.3193],
                        [-76.2292, 44.3129],
                        [-76.2506, 44.3077],
                        [-76.2711, 44.3056],
                        [-76.2774, 44.3021],
                        [-76.299, 44.2957],
                        [-76.3114, 44.2956],
                        [-76.32, 44.2838],
                        [-76.3339, 44.2736],
                        [-76.3567, 44.2693],
                        [-76.3631, 44.2648],
                        [-76.3806, 44.2626],
                        [-76.3828, 44.2556],
                        [-76.393, 44.2496],
                        [-76.3988, 44.2503],
                        [-76.4106, 44.2435],
                        [-76.4279, 44.2362],
                        [-76.4583, 44.2292],
                        [-76.4646, 44.2347],
                        [-76.4767, 44.2345],
                        [-76.4825, 44.224],
                        [-76.4893, 44.2218],
                        [-76.5046, 44.2212],
                        [-76.5229, 44.2146],
                        [-76.5288, 44.214],
                        [-76.5358, 44.2192],
                        [-76.5473, 44.2191],
                        [-76.5507, 44.2123],
                        [-76.5647, 44.2085],
                        [-76.5688, 44.2131],
                        [-76.5812, 44.2157],
                        [-76.5857, 44.2189],
                        [-76.6081, 44.2132],
                        [-76.6183, 44.2199],
                        [-76.6311, 44.222],
                        [-76.663, 44.2146],
                        [-76.6812, 44.2068],
                        [-76.6899, 44.2083],
                        [-76.688, 44.2165],
                        [-76.7113, 44.2048],
                        [-76.7255, 44.2034],
                        [-76.7297, 44.198],
                        [-76.7582, 44.1824],
                        [-76.7673, 44.1802],
                        [-76.7737, 44.1832],
                        [-76.7864, 44.1755],
                        [-76.7931, 44.1676],
                        [-76.8177, 44.1579],
                        [-76.8455, 44.144],
                        [-76.8671, 44.1311],
                        [-76.8827, 44.1253],
                        [-76.8964, 44.1163],
                        [-76.9125, 44.1119],
                        [-76.9216, 44.1048],
                        [-76.9275, 44.1051],
                        [-76.938, 44.0969],
                        [-76.9421, 44.0891],
                        [-76.958, 44.0777],
                        [-76.9553, 44.0735],
                        [-76.9685, 44.0642],
                        [-76.9861, 44.0619],
                        [-76.9923, 44.0636],
                        [-77.0141, 44.0588],
                        [-77.0206, 44.0508],
                        [-77.0293, 44.0546],
                        [-77.0505, 44.048],
                        [-77.0544, 44.0556],
                        [-77.0423, 44.0577],
                        [-77.0269, 44.0665],
                        [-77.0255, 44.0771],
                        [-77.0388, 44.0694],
                        [-77.0631, 44.0778],
                        [-77.0672, 44.0753],
                        [-77.0795, 44.0774],
                        [-77.0491, 44.0959],
                        [-77.0419, 44.0934],
                        [-77.0152, 44.1048],
                        [-77.0138, 44.1127],
                        [-77.0069, 44.1241],
                        [-77.0111, 44.1269],
                        [-77.0064, 44.1337],
                        [-76.9853, 44.1428],
                        [-76.9774, 44.1409],
                        [-76.9646, 44.1478],
                        [-76.9493, 44.1506],
                        [-76.9401, 44.1493],
                        [-76.9179, 44.1575],
                        [-76.9162, 44.1525],
                        [-76.9064, 44.1511],
                        [-76.898, 44.1553],
                        [-76.8852, 44.155],
                        [-76.8846, 44.1622],
                        [-76.9098, 44.1738],
                        [-76.9155, 44.1697],
                        [-76.9287, 44.1737],
                        [-76.9303, 44.1789],
                        [-76.9619, 44.17],
                        [-76.9575, 44.1555],
                        [-76.97, 44.156],
                        [-76.9763, 44.1499],
                        [-77.0029, 44.1414],
                        [-77.0145, 44.1345],
                        [-77.0218, 44.1267],
                        [-77.0259, 44.1151],
                        [-77.0545, 44.1048],
                        [-77.0678, 44.1052],
                        [-77.0641, 44.1192],
                        [-77.0651, 44.1252],
                        [-77.0593, 44.1395],
                        [-77.055, 44.1421],
                        [-77.0445, 44.1678],
                        [-77.0385, 44.1767],
                        [-77.0185, 44.1857],
                        [-77.0263, 44.197],
                        [-77.0351, 44.2001],
                        [-77.0481, 44.1924],
                        [-77.0536, 44.1923],
                        [-77.0704, 44.1826],
                        [-77.0887, 44.1758],
                        [-77.0971, 44.1762],
                        [-77.1119, 44.1702],
                        [-77.1296, 44.171],
                        [-77.1369, 44.1647],
                        [-77.1473, 44.1619],
                        [-77.1581, 44.1627],
                        [-77.1672, 44.1668],
                        [-77.1808, 44.1638],
                        [-77.1906, 44.1573],
                        [-77.1963, 44.1599],
                        [-77.2102, 44.1569],
                        [-77.2144, 44.1582],
                        [-77.2084, 44.1677],
                        [-77.2178, 44.1742],
                        [-77.2303, 44.1796],
                        [-77.2446, 44.1763],
                        [-77.2523, 44.1788],
                        [-77.2708, 44.1703],
                        [-77.275, 44.1637],
                        [-77.2821, 44.1601],
                        [-77.3042, 44.1549],
                        [-77.3137, 44.1545],
                        [-77.3176, 44.166],
                        [-77.3247, 44.1646],
                        [-77.3356, 44.168],
                        [-77.3438, 44.1624],
                        [-77.348, 44.1652],
                        [-77.3627, 44.1614],
                        [-77.3771, 44.1543],
                        [-77.3825, 44.1571],
                        [-77.4124, 44.141],
                        [-77.4464, 44.1318],
                        [-77.4725, 44.121],
                        [-77.4899, 44.1185],
                        [-77.5036, 44.1185],
                        [-77.5192, 44.1081],
                        [-77.535, 44.1048],
                        [-77.5568, 44.1025],
                        [-77.5597, 44.0986],
                        [-77.572, 44.1025],
                        [-77.575, 44.0895],
                        [-77.5831, 44.0811],
                        [-77.5952, 44.0725],
                        [-77.5985, 44.066],
                        [-77.5877, 44.0594],
                        [-77.6739, 44.0331],
                        [-77.6882, 44.0321],
                        [-77.6978, 44.029],
                        [-77.7043, 44.0308],
                        [-77.7199, 44.019],
                        [-77.7241, 44.0222],
                        [-77.7362, 44.0146],
                        [-77.7307, 44.0095],
                        [-77.7149, 44.0046],
                        [-77.7167, 43.9961],
                        [-77.6966, 44.004],
                        [-77.6867, 44.004],
                        [-77.6754, 43.9978],
                        [-77.691, 43.9939],
                        [-77.704, 43.9888],
                        [-77.7312, 43.9906],
                        [-77.7339, 43.9989],
                        [-77.7449, 44.0088],
                        [-77.7505, 44.0108],
                        [-77.7631, 44.0082],
                        [-77.7831, 44.0084],
                        [-77.797, 44.0029],
                        [-77.8203, 44.0034],
                        [-77.8386, 43.9982],
                        [-77.8428, 43.9943],
                        [-77.8566, 43.9924],
                        [-77.8647, 43.989],
                        [-77.867, 43.9841],
                        [-77.878, 43.9766],
                        [-77.9002, 43.9808],
                        [-77.9353, 43.9702],
                        [-77.9704, 43.9776],
                        [-77.98, 43.9762],
                        [-77.9974, 43.9687],
                        [-78.0066, 43.9632],
                        [-78.0339, 43.969],
                        [-78.0654, 43.9684],
                        [-78.0713, 43.965],
                        [-78.0894, 43.9678],
                        [-78.0993, 43.9658],
                        [-78.1159, 43.9571],
                        [-78.131, 43.9584],
                        [-78.1519, 43.9554],
                        [-78.1646, 43.9575],
                        [-78.1703, 43.9542],
                        [-78.2017, 43.9552],
                        [-78.2149, 43.9508],
                        [-78.2547, 43.9534],
                        [-78.2718, 43.9505],
                        [-78.2877, 43.9462],
                        [-78.2914, 43.9411],
                        [-78.3322, 43.9379],
                        [-78.3437, 43.9319],
                        [-78.3791, 43.9287],
                        [-78.3916, 43.9215],
                        [-78.4143, 43.9135],
                        [-78.4309, 43.9131],
                        [-78.4454, 43.9099],
                        [-78.4701, 43.8993],
                        [-78.4867, 43.8969],
                        [-78.5022, 43.9006],
                        [-78.5297, 43.8982],
                        [-78.5417, 43.8959],
                        [-78.5641, 43.8953],
                        [-78.5711, 43.8972],
                        [-78.5853, 43.8952],
                        [-78.5992, 43.896],
                        [-78.6079, 43.8985],
                        [-78.6198, 43.8981],
                        [-78.6362, 43.8929],
                        [-78.6529, 43.8922],
                        [-78.6711, 43.8872],
                        [-78.6843, 43.8786],
                        [-78.6972, 43.8751],
                        [-78.7118, 43.8676],
                        [-78.7255, 43.8669],
                        [-78.7378, 43.87],
                        [-78.7539, 43.868],
                        [-78.7851, 43.8697],
                        [-78.797, 43.867],
                        [-78.8072, 43.8697],
                        [-78.8186, 43.8685],
                        [-78.8309, 43.8585],
                        [-78.8416, 43.8577],
                        [-78.8701, 43.8502],
                        [-78.8865, 43.8533],
                        [-78.8981, 43.8499],
                        [-78.9021, 43.8455],
                        [-78.9291, 43.8515],
                        [-78.9352, 43.8512],
                        [-78.9438, 43.8459],
                        [-78.9511, 43.8459],
                        [-78.9611, 43.8421],
                        [-78.9728, 43.8317],
                        [-78.9832, 43.8272],
                        [-78.9941, 43.8274],
                        [-79.0115, 43.8195],
                        [-79.0249, 43.8158],
                        [-79.0442, 43.8156],
                        [-79.06, 43.8078],
                        [-79.0798, 43.8123],
                        [-79.0908, 43.8124],
                        [-79.1023, 43.8058],
                        [-79.1084, 43.7989],
                        [-79.1199, 43.7915],
                        [-79.1282, 43.7809],
                        [-79.1533, 43.7591],
                        [-79.1671, 43.7569],
                        [-79.1808, 43.7514],
                        [-79.2085, 43.7332],
                        [-79.2183, 43.7222],
                        [-79.2325, 43.71],
                        [-79.2339, 43.7055],
                        [-79.2553, 43.6954],
                        [-79.2796, 43.6712],
                        [-79.3057, 43.664],
                        [-79.3172, 43.6566],
                        [-79.3214, 43.6458],
                        [-79.3284, 43.6477],
                        [-79.3473, 43.6332],
                        [-79.3602, 43.6453],
                        [-79.3796, 43.6381],
                        [-79.397, 43.6365],
                        [-79.4138, 43.63],
                        [-79.4334, 43.6307],
                        [-79.4475, 43.6365],
                        [-79.4554, 43.6375],
                        [-79.4681, 43.6339],
                        [-79.4753, 43.6283],
                        [-79.4723, 43.6226],
                        [-79.4775, 43.6151],
                        [-79.4851, 43.6173],
                        [-79.4871, 43.6085],
                        [-79.4964, 43.6019],
                        [-79.5005, 43.5953],
                        [-79.5108, 43.5912],
                        [-79.5368, 43.5882],
                        [-79.5421, 43.5839],
                        [-79.5427, 43.575],
                        [-79.5624, 43.562],
                        [-79.584, 43.5499],
                        [-79.5818, 43.5469],
                        [-79.5901, 43.5396],
                        [-79.5987, 43.5358],
                        [-79.6023, 43.5237],
                        [-79.6012, 43.5097],
                        [-79.6065, 43.4966],
                        [-79.6145, 43.4923],
                        [-79.6155, 43.4879],
                        [-79.63, 43.4756],
                        [-79.6418, 43.4685],
                        [-79.6486, 43.4596],
                        [-79.6674, 43.4398],
                        [-79.6842, 43.4242],
                        [-79.6863, 43.4164],
                        [-79.6909, 43.413],
                        [-79.6918, 43.4039],
                        [-79.7023, 43.3992],
                        [-79.7095, 43.3927],
                        [-79.7178, 43.3755],
                        [-79.7251, 43.3714],
                        [-79.7542, 43.3515],
                        [-79.7713, 43.3356],
                        [-79.7958, 43.3237],
                        [-79.8007, 43.3181],
                        [-79.7983, 43.3055],
                        [-79.7878, 43.2876],
                        [-79.7707, 43.2649],
                        [-79.7503, 43.2478],
                        [-79.7437, 43.2454],
                        [-79.7314, 43.2454],
                        [-79.707, 43.2357],
                        [-79.6923, 43.2356],
                        [-79.6791, 43.2329],
                        [-79.6654, 43.2258],
                        [-79.6502, 43.2232],
                        [-79.6279, 43.2285],
                        [-79.6188, 43.2244],
                        [-79.604, 43.2146],
                        [-79.5813, 43.2049],
                        [-79.5654, 43.2069],
                        [-79.557, 43.2062],
                        [-79.5362, 43.1967],
                        [-79.5294, 43.1953],
                        [-79.5005, 43.1966],
                        [-79.4882, 43.1943],
                        [-79.478, 43.1963],
                        [-79.4655, 43.1951],
                        [-79.4438, 43.1976],
                        [-79.4088, 43.198],
                        [-79.3898, 43.1925],
                        [-79.3836, 43.1889],
                        [-79.365, 43.1829],
                        [-79.3435, 43.1806],
                        [-79.3216, 43.1825],
                        [-79.3076, 43.1872],
                        [-79.2997, 43.1921],
                        [-79.2619, 43.2064],
                        [-79.2446, 43.2116],
                        [-79.215, 43.2289],
                        [-79.2088, 43.226],
                        [-79.1932, 43.2288],
                        [-79.1775, 43.2369],
                        [-79.1518, 43.2482],
                        [-79.1386, 43.2567],
                        [-79.1267, 43.2602],
                        [-79.1229, 43.2584],
                        [-79.1044, 43.2594],
                        [-79.0901, 43.2629],
                        [-79.0768, 43.263],
                        [-79.0601, 43.2563],
                        [-79.057, 43.252],
                        [-79.06, 43.2368],
                        [-79.057, 43.2246],
                        [-79.0603, 43.2107],
                        [-79.0522, 43.2],
                        [-79.0576, 43.1855],
                        [-79.057, 43.1719],
                        [-79.0476, 43.1621],
                        [-79.0433, 43.1476],
                        [-79.0443, 43.1393],
                        [-79.056, 43.1282],
                        [-79.0683, 43.1219],
                        [-79.0587, 43.1088],
                        [-79.0676, 43.0916],
                        [-79.0758, 43.0847],
                        [-79.0788, 43.078],
                        [-79.0715, 43.0699],
                        [-79.0595, 43.0696],
                        [-79.047, 43.0625],
                        [-79.0286, 43.0586],
                        [-79.0198, 43.0488],
                        [-79.0178, 43.0356],
                        [-79.0303, 43.0206],
                        [-79.0311, 43.0148],
                        [-79.0273, 42.9952],
                        [-79.028, 42.9902],
                        [-79.0236, 42.9814],
                        [-79.0082, 42.9729],
                        [-78.9895, 42.9697],
                        [-78.9783, 42.9647],
                        [-78.9774, 42.958],
                        [-78.9687, 42.9508],
                        [-78.9513, 42.9482],
                        [-78.9347, 42.9482],
                        [-78.9164, 42.9353],
                        [-78.9082, 42.9132],
                        [-78.9101, 42.9058],
                        [-78.9158, 42.9036],
                        [-78.9229, 42.8909],
                        [-78.936, 42.8835],
                        [-78.953, 42.8804],
                        [-78.9618, 42.8843],
                        [-78.9713, 42.8792],
                        [-78.9909, 42.8792],
                        [-78.9985, 42.8766],
                        [-79.0058, 42.8683],
                        [-79.0159, 42.8695],
                        [-79.0242, 42.8734],
                        [-79.0357, 42.8741],
                        [-79.0578, 42.8592],
                        [-79.0668, 42.8628],
                        [-79.0861, 42.8607],
                        [-79.0937, 42.8561],
                        [-79.0938, 42.8481],
                        [-79.0878, 42.8424],
                        [-79.098, 42.8372],
                        [-79.1001, 42.8451],
                        [-79.1166, 42.86],
                        [-79.126, 42.8639],
                        [-79.139, 42.8661],
                        [-79.1522, 42.8736],
                        [-79.1671, 42.876],
                        [-79.1803, 42.8734],
                        [-79.1841, 42.8695],
                        [-79.1965, 42.8682],
                        [-79.2045, 42.8718],
                        [-79.2155, 42.8712],
                        [-79.22, 42.868],
                        [-79.2352, 42.8757],
                        [-79.2497, 42.8763],
                        [-79.2602, 42.8789],
                        [-79.2697, 42.878],
                        [-79.275, 42.87],
                        [-79.2979, 42.8779],
                        [-79.3084, 42.875],
                        [-79.3302, 42.874],
                        [-79.3352, 42.8715],
                        [-79.3396, 42.8629],
                        [-79.35, 42.8598],
                        [-79.3642, 42.8663],
                        [-79.3726, 42.8667],
                        [-79.3795, 42.8611],
                        [-79.3962, 42.8698],
                        [-79.4089, 42.8733],
                        [-79.4235, 42.8707],
                        [-79.4387, 42.8761],
                        [-79.4479, 42.876],
                        [-79.4733, 42.8574],
                        [-79.4742, 42.8514],
                        [-79.4833, 42.8409],
                        [-79.5051, 42.8465],
                        [-79.5249, 42.8541],
                        [-79.5332, 42.854],
                        [-79.5403, 42.8472],
                        [-79.5502, 42.8413],
                        [-79.5607, 42.8498],
                        [-79.5751, 42.856],
                        [-79.5854, 42.8576],
                        [-79.6071, 42.8443],
                        [-79.6256, 42.8405],
                        [-79.6389, 42.8356],
                        [-79.6513, 42.8437],
                        [-79.6612, 42.844],
                        [-79.6716, 42.8522],
                        [-79.6786, 42.8514],
                        [-79.6985, 42.8605],
                        [-79.7065, 42.8606],
                        [-79.7192, 42.853],
                        [-79.7341, 42.8551],
                        [-79.7446, 42.8486],
                        [-79.7521, 42.841],
                        [-79.7733, 42.8445],
                        [-79.7879, 42.8436],
                        [-79.8033, 42.834],
                        [-79.8113, 42.8328],
                        [-79.8195, 42.8355],
                        [-79.8369, 42.8293],
                        [-79.8433, 42.823],
                        [-79.8582, 42.8254],
                        [-79.8726, 42.825],
                        [-79.8854, 42.8211],
                        [-79.8906, 42.8154],
                        [-79.8997, 42.8186],
                        [-79.9203, 42.8194],
                        [-79.9277, 42.8148],
                        [-79.9358, 42.8139],
                        [-79.9402, 42.8086],
                        [-79.9508, 42.8075],
                        [-79.9608, 42.8089],
                        [-79.9739, 42.8055],
                        [-79.9796, 42.8011],
                        [-79.981, 42.7923],
                        [-80.0018, 42.8012],
                        [-80.0106, 42.8018],
                        [-80.0267, 42.8079],
                        [-80.036, 42.8067],
                        [-80.0381, 42.7995],
                        [-80.0538, 42.795],
                        [-80.0604, 42.7982],
                        [-80.0699, 42.797],
                        [-80.0732, 42.7915],
                        [-80.0883, 42.7894],
                        [-80.1035, 42.7907],
                        [-80.1076, 42.7887],
                        [-80.1223, 42.7899],
                        [-80.1275, 42.7866],
                        [-80.1577, 42.7858],
                        [-80.1747, 42.7878],
                        [-80.1941, 42.7842],
                        [-80.1971, 42.7816],
                        [-80.2121, 42.7838],
                        [-80.2299, 42.7782],
                        [-80.2371, 42.7715],
                        [-80.2404, 42.7626],
                        [-80.2611, 42.7522],
                        [-80.2675, 42.7466],
                        [-80.2711, 42.7387],
                        [-80.2835, 42.7295],
                        [-80.2923, 42.7198],
                        [-80.3118, 42.7081],
                        [-80.3269, 42.6923],
                        [-80.3311, 42.6716],
                        [-80.3366, 42.6629],
                        [-80.3321, 42.6572],
                        [-80.3371, 42.652],
                        [-80.3453, 42.6521],
                        [-80.3565, 42.6576],
                        [-80.3505, 42.6605],
                        [-80.3448, 42.6686],
                        [-80.3543, 42.681],
                        [-80.3618, 42.6791],
                        [-80.3658, 42.6719],
                        [-80.3722, 42.6709],
                        [-80.375, 42.6611],
                        [-80.3863, 42.6601],
                        [-80.3947, 42.6623],
                        [-80.4251, 42.6462],
                        [-80.4356, 42.6339],
                        [-80.4479, 42.6242],
                        [-80.4546, 42.6142],
                        [-80.4496, 42.603],
                        [-80.4503, 42.5973],
                        [-80.4449, 42.5894],
                        [-80.435, 42.5824],
                        [-80.4231, 42.5869],
                        [-80.4103, 42.5847],
                        [-80.3987, 42.5926],
                        [-80.3953, 42.5836],
                        [-80.3855, 42.5814],
                        [-80.3791, 42.585],
                        [-80.3706, 42.5838],
                        [-80.3736, 42.5783],
                        [-80.3662, 42.5754],
                        [-80.3596, 42.58],
                        [-80.3411, 42.5775],
                        [-80.3416, 42.5739],
                        [-80.3225, 42.5756],
                        [-80.3144, 42.5737],
                        [-80.302, 42.5641],
                        [-80.2827, 42.5624],
                        [-80.2528, 42.563],
                        [-80.236, 42.5648],
                        [-80.2307, 42.5539],
                        [-80.1906, 42.5613],
                        [-80.1758, 42.5555],
                        [-80.1635, 42.5567],
                        [-80.1535, 42.5549],
                        [-80.1364, 42.5573],
                        [-80.1329, 42.553],
                        [-80.1138, 42.5546],
                        [-80.1041, 42.5498],
                        [-80.0877, 42.5517],
                        [-80.0809, 42.5484],
                        [-80.06, 42.5498],
                        [-80.0499, 42.547],
                        [-80.1194, 42.5393],
                        [-80.1409, 42.5384],
                        [-80.1641, 42.5399],
                        [-80.2295, 42.5522],
                        [-80.269, 42.5574],
                        [-80.3046, 42.5629],
                        [-80.3263, 42.5692],
                        [-80.3625, 42.5739],
                        [-80.3839, 42.578],
                        [-80.4211, 42.5789],
                        [-80.4715, 42.5749],
                        [-80.528, 42.5734],
                        [-80.6125, 42.5796],
                        [-80.6259, 42.5821],
                        [-80.6391, 42.5865],
                        [-80.6811, 42.603],
                        [-80.7375, 42.6267],
                        [-80.7636, 42.6357],
                        [-80.7937, 42.6436],
                        [-80.8075, 42.6427],
                        [-80.8089, 42.6393],
                        [-80.8383, 42.6516],
                        [-80.9083, 42.66],
                        [-80.9447, 42.6615],
                        [-80.9666, 42.6614],
                        [-80.9937, 42.6595],
                        [-81.0081, 42.6551],
                        [-81.0245, 42.6574],
                        [-81.0627, 42.6603],
                        [-81.0959, 42.6613],
                        [-81.1215, 42.6633],
                        [-81.1664, 42.6624],
                        [-81.1975, 42.6655],
                        [-81.209, 42.6639],
                        [-81.2161, 42.6587],
                        [-81.2471, 42.664],
                        [-81.2625, 42.662],
                        [-81.2767, 42.6577],
                        [-81.2889, 42.6571],
                        [-81.318, 42.652],
                        [-81.3437, 42.6442],
                        [-81.3521, 42.6405],
                        [-81.363, 42.6323],
                        [-81.3874, 42.6176],
                        [-81.3954, 42.6081],
                        [-81.4041, 42.6053],
                        [-81.4212, 42.606],
                        [-81.4344, 42.605],
                        [-81.4489, 42.6009],
                        [-81.4633, 42.5916],
                        [-81.4663, 42.5836],
                        [-81.5045, 42.5766],
                        [-81.522, 42.5694],
                        [-81.54, 42.5603],
                        [-81.5467, 42.5546],
                        [-81.5723, 42.5398],
                        [-81.5941, 42.5215],
                        [-81.6043, 42.5146],
                        [-81.6124, 42.5056],
                        [-81.6234, 42.4984],
                        [-81.6783, 42.4668],
                        [-81.7107, 42.4439],
                        [-81.7293, 42.4331],
                        [-81.7476, 42.4254],
                        [-81.7811, 42.4073],
                        [-81.8037, 42.3925],
                        [-81.8121, 42.3858],
                        [-81.8257, 42.3691],
                        [-81.834, 42.3533],
                        [-81.8408, 42.3269],
                        [-81.8419, 42.3113],
                        [-81.8408, 42.2848],
                        [-81.8425, 42.2681],
                        [-81.847, 42.2588],
                        [-81.8635, 42.2537],
                        [-81.8825, 42.2566],
                        [-81.8788, 42.2676],
                        [-81.8811, 42.2736],
                        [-81.878, 42.2831],
                        [-81.8635, 42.294],
                        [-81.8576, 42.3086],
                        [-81.8525, 42.3135],
                        [-81.8453, 42.33],
                        [-81.8559, 42.3334],
                        [-81.8802, 42.323],
                        [-81.8887, 42.3152],
                        [-81.8942, 42.3151],
                        [-81.9082, 42.3034],
                        [-81.9148, 42.3022],
                        [-81.9205, 42.2942],
                        [-81.9288, 42.2925],
                        [-81.9369, 42.2761],
                        [-81.9382, 42.2659],
                        [-81.9344, 42.2624],
                        [-81.9085, 42.2614],
                        [-81.9088, 42.2558],
                        [-81.9226, 42.2597],
                        [-81.9404, 42.2623],
                        [-81.9652, 42.2641],
                        [-82.004, 42.2649],
                        [-82.0323, 42.2623],
                        [-82.0616, 42.2567],
                        [-82.094, 42.2473],
                        [-82.1434, 42.2268],
                        [-82.1729, 42.2128],
                        [-82.1797, 42.2074],
                        [-82.2001, 42.1965],
                        [-82.256, 42.1738],
                        [-82.3098, 42.1555],
                        [-82.3517, 42.1384],
                        [-82.3685, 42.1328],
                        [-82.3829, 42.1259],
                        [-82.4086, 42.1117],
                        [-82.432, 42.0946],
                        [-82.4436, 42.0837],
                        [-82.4481, 42.077],
                        [-82.4589, 42.0687],
                        [-82.4722, 42.0505],
                        [-82.4817, 42.041],
                        [-82.4925, 42.0119],
                        [-82.5, 41.9863],
                        [-82.5014, 41.9858],
                        [-82.505, 41.939],
                        [-82.5084, 41.9103],
                        [-82.5215, 41.9443],
                        [-82.5352, 41.9686],
                        [-82.5483, 41.9854],
                        [-82.571, 42.0083],
                        [-82.5942, 42.0259],
                        [-82.6115, 42.0319],
                        [-82.6232, 42.0305],
                        [-82.6413, 42.0356],
                        [-82.6516, 42.0344],
                        [-82.6693, 42.0367],
                        [-82.6799, 42.0363],
                        [-82.69, 42.0329],
                        [-82.7178, 42.0322],
                        [-82.7503, 42.0234],
                        [-82.7948, 42.005],
                        [-82.8158, 41.9982],
                        [-82.8475, 41.9953],
                        [-82.8827, 41.991],
                        [-82.9087, 41.9828],
                        [-82.924, 41.9808],
                        [-82.9375, 41.9841],
                        [-82.9486, 41.9892],
                        [-82.9804, 42.0085],
                        [-83.0142, 42.0233],
                        [-83.0408, 42.0306],
                        [-83.0778, 42.0383],
                        [-83.1074, 42.0468],
                        [-83.1162, 42.0551],
                        [-83.1163, 42.0796],
                        [-83.1113, 42.0879],
                        [-83.1147, 42.1053],
                        [-83.1116, 42.1167],
                        [-83.1151, 42.1261],
                        [-83.1163, 42.1384],
                        [-83.1132, 42.1509],
                        [-83.1072, 42.1563],
                        [-83.109, 42.1637],
                        [-83.1163, 42.1654],
                        [-83.1116, 42.177],
                        [-83.1022, 42.1862],
                        [-83.101, 42.1974],
                        [-83.105, 42.2057],
                        [-83.1039, 42.2176],
                        [-83.1074, 42.2569],
                        [-83.1014, 42.2712],
                        [-83.0897, 42.2931],
                        [-83.0768, 42.3065],
                        [-83.0586, 42.3159],
                        [-83.0336, 42.3222],
                        [-83.0074, 42.3275],
                        [-82.9845, 42.3278],
                        [-82.9702, 42.3326],
                        [-82.9573, 42.333],
                        [-82.9486, 42.3415],
                        [-82.9356, 42.3424],
                        [-82.9304, 42.339],
                        [-82.9224, 42.34],
                        [-82.9131, 42.337],
                        [-82.8788, 42.3313],
                        [-82.8577, 42.3238],
                        [-82.8298, 42.3171],
                        [-82.7903, 42.3058],
                        [-82.774, 42.3026],
                        [-82.7384, 42.2976],
                        [-82.7252, 42.2966],
                        [-82.64, 42.2997],
                        [-82.6071, 42.3045],
                        [-82.5822, 42.312],
                        [-82.5671, 42.3151],
                        [-82.5553, 42.3155],
                        [-82.5074, 42.3066],
                        [-82.4799, 42.3074],
                        [-82.4724, 42.309],
                        [-82.4623, 42.3173],
                        [-82.4502, 42.3218],
                        [-82.4297, 42.3424],
                        [-82.4179, 42.3612],
                        [-82.4181, 42.3766],
                        [-82.4112, 42.3911],
                        [-82.41, 42.3993],
                        [-82.4143, 42.4056],
                        [-82.412, 42.4138],
                        [-82.4164, 42.4305],
                        [-82.412, 42.4387],
                        [-82.4169, 42.4451],
                        [-82.4154, 42.4564],
                        [-82.4112, 42.4572],
                        [-82.4116, 42.471],
                        [-82.4146, 42.4825],
                        [-82.4198, 42.4879],
                        [-82.4199, 42.4971],
                        [-82.4338, 42.4886],
                        [-82.4514, 42.4841],
                        [-82.4514, 42.4924],
                        [-82.4628, 42.4904],
                        [-82.4721, 42.4968],
                        [-82.4914, 42.4973],
                        [-82.5085, 42.494],
                        [-82.5195, 42.4845],
                        [-82.5239, 42.493],
                        [-82.5403, 42.5037],
                        [-82.5511, 42.5063],
                        [-82.5726, 42.5039],
                        [-82.5816, 42.5044],
                        [-82.5881, 42.4991],
                        [-82.5972, 42.4966],
                        [-82.5944, 42.5151],
                        [-82.5923, 42.5199],
                        [-82.6012, 42.5216],
                        [-82.6139, 42.527],
                        [-82.6252, 42.5363],
                        [-82.5983, 42.5455],
                        [-82.6136, 42.5453],
                        [-82.627, 42.5413],
                        [-82.6683, 42.5258],
                        [-82.6677, 42.5336],
                        [-82.6624, 42.5412],
                        [-82.6412, 42.5548],
                        [-82.624, 42.5569],
                        [-82.6055, 42.5485],
                        [-82.5911, 42.5499],
                        [-82.584, 42.5539],
                        [-82.5803, 42.5641],
                        [-82.5535, 42.585],
                        [-82.5492, 42.5911],
                        [-82.5234, 42.6074],
                        [-82.5186, 42.6141],
                        [-82.5097, 42.638],
                        [-82.5105, 42.6648],
                        [-82.4946, 42.7003],
                        [-82.4837, 42.7184],
                        [-82.4839, 42.7335],
                        [-82.4674, 42.7625],
                        [-82.4719, 42.7861],
                        [-82.4806, 42.802],
                        [-82.482, 42.8082],
                        [-82.4788, 42.8255],
                        [-82.4727, 42.8367],
                        [-82.4682, 42.8554],
                        [-82.4703, 42.8875],
                        [-82.4621, 42.9061],
                        [-82.4553, 42.9267],
                        [-82.4478, 42.9376],
                        [-82.4286, 42.9522],
                        [-82.4167, 42.9669],
                        [-82.413, 42.9774],
                        [-82.4213, 42.9855],
                        [-82.4245, 42.9922],
                        [-82.4207, 43.0023],
                        [-82.3973, 43.0078],
                        [-82.3848, 43.0142],
                        [-82.3163, 43.0252],
                        [-82.2646, 43.0317],
                        [-82.2408, 43.0358],
                        [-82.2179, 43.0413],
                        [-82.1917, 43.0498],
                        [-82.168, 43.0606],
                        [-82.1555, 43.0691],
                        [-82.1492, 43.0763],
                        [-82.1502, 43.0919],
                        [-82.1216, 43.0994],
                        [-82.099, 43.1099],
                        [-82.0668, 43.1344],
                        [-82.0494, 43.1526],
                        [-82.0214, 43.1719],
                        [-82.0163, 43.1889],
                        [-82.0223, 43.2012],
                        [-82.023, 43.2122],
                        [-82.0143, 43.2133],
                        [-82.0056, 43.2097],
                        [-81.9946, 43.2084],
                        [-81.9825, 43.2097],
                        [-81.9645, 43.214],
                        [-81.921, 43.2286],
                        [-81.8754, 43.2474],
                        [-81.8354, 43.2676],
                        [-81.8031, 43.2866],
                        [-81.784, 43.2995],
                        [-81.7687, 43.3112],
                        [-81.7408, 43.337],
                        [-81.7237, 43.361],
                        [-81.7124, 43.3866],
                        [-81.7098, 43.3981],
                        [-81.7175, 43.4247],
                        [-81.7177, 43.4539],
                        [-81.7163, 43.4749],
                        [-81.7124, 43.485],
                        [-81.7115, 43.5124],
                        [-81.7078, 43.5194],
                        [-81.7061, 43.531],
                        [-81.7064, 43.568],
                        [-81.7094, 43.5696],
                        [-81.7083, 43.5816],
                        [-81.7113, 43.5992],
                        [-81.7125, 43.6241],
                        [-81.7143, 43.6342],
                        [-81.7188, 43.6432],
                        [-81.7211, 43.6628],
                        [-81.7233, 43.6679],
                        [-81.7232, 43.6823],
                        [-81.729, 43.6979],
                        [-81.722, 43.7091],
                        [-81.7245, 43.7183],
                        [-81.7248, 43.7344],
                        [-81.7275, 43.7392],
                        [-81.7227, 43.7569],
                        [-81.7196, 43.7861],
                        [-81.7287, 43.8048],
                        [-81.7211, 43.8178],
                        [-81.7209, 43.8349],
                        [-81.7256, 43.8495],
                        [-81.7241, 43.863],
                        [-81.7279, 43.868],
                        [-81.725, 43.873],
                        [-81.7207, 43.9022],
                        [-81.7227, 43.9167],
                        [-81.7214, 43.9301],
                        [-81.7231, 43.9406],
                        [-81.7319, 43.9687],
                        [-81.7341, 43.9824],
                        [-81.7327, 44.0123],
                        [-81.7348, 44.0291],
                        [-81.7466, 44.0557],
                        [-81.7536, 44.0633],
                        [-81.7573, 44.0726],
                        [-81.7423, 44.0915],
                        [-81.7327, 44.1001],
                        [-81.7092, 44.1144],
                        [-81.6808, 44.1396],
                        [-81.6629, 44.1528],
                        [-81.6569, 44.1609],
                        [-81.6441, 44.1716],
                        [-81.641, 44.1765],
                        [-81.6367, 44.1917],
                        [-81.6296, 44.1976],
                        [-81.6141, 44.2186],
                        [-81.614, 44.2249],
                        [-81.6074, 44.2481],
                        [-81.6021, 44.2578],
                        [-81.6024, 44.2627],
                        [-81.6091, 44.2672],
                        [-81.5915, 44.2881],
                        [-81.5958, 44.2965],
                        [-81.6034, 44.2991],
                        [-81.5963, 44.3057],
                        [-81.6058, 44.3088],
                        [-81.6073, 44.3188],
                        [-81.6005, 44.3285],
                        [-81.5932, 44.3337],
                        [-81.5831, 44.3343],
                        [-81.5828, 44.342],
                        [-81.5706, 44.3422],
                        [-81.5621, 44.332],
                        [-81.5556, 44.3328],
                        [-81.5503, 44.3418],
                        [-81.5542, 44.3519],
                        [-81.5492, 44.361],
                        [-81.5339, 44.3661],
                        [-81.5252, 44.3762],
                        [-81.5209, 44.3872],
                        [-81.5089, 44.3963],
                        [-81.4987, 44.4085],
                        [-81.4808, 44.4181],
                        [-81.456, 44.4195],
                        [-81.4281, 44.4241],
                        [-81.4101, 44.4327],
                        [-81.4033, 44.4398],
                        [-81.4076, 44.4493],
                        [-81.4005, 44.4641],
                        [-81.3895, 44.4679],
                        [-81.3899, 44.4762],
                        [-81.3855, 44.4814],
                        [-81.3875, 44.4898],
                        [-81.3792, 44.4945],
                        [-81.3759, 44.4998],
                        [-81.3594, 44.5161],
                        [-81.348, 44.5353],
                        [-81.3321, 44.5485],
                        [-81.3234, 44.5504],
                        [-81.3192, 44.5579],
                        [-81.3109, 44.5644],
                        [-81.3018, 44.5764],
                        [-81.2934, 44.5844],
                        [-81.2939, 44.5916],
                        [-81.2812, 44.5989],
                        [-81.2737, 44.6139],
                        [-81.2709, 44.6298],
                        [-81.2715, 44.649],
                        [-81.2744, 44.656],
                        [-81.2879, 44.6696],
                        [-81.3033, 44.6802],
                        [-81.3067, 44.6941],
                        [-81.297, 44.697],
                        [-81.2881, 44.6913],
                        [-81.2772, 44.6988],
                        [-81.2773, 44.7115],
                        [-81.2824, 44.719],
                        [-81.2771, 44.725],
                        [-81.2798, 44.7354],
                        [-81.2849, 44.741],
                        [-81.2771, 44.7526],
                        [-81.2783, 44.7605],
                        [-81.2852, 44.7638],
                        [-81.2837, 44.7764],
                        [-81.2951, 44.7825],
                        [-81.2976, 44.7957],
                        [-81.288, 44.7986],
                        [-81.2854, 44.8063],
                        [-81.2979, 44.8121],
                        [-81.294, 44.8159],
                        [-81.2985, 44.8236],
                        [-81.3074, 44.8233],
                        [-81.3165, 44.8275],
                        [-81.3285, 44.8418],
                        [-81.3316, 44.8549],
                        [-81.3271, 44.8666],
                        [-81.3193, 44.8733],
                        [-81.3294, 44.8768],
                        [-81.3353, 44.8656],
                        [-81.3435, 44.8656],
                        [-81.3449, 44.8744],
                        [-81.3548, 44.8782],
                        [-81.3533, 44.8843],
                        [-81.3637, 44.8927],
                        [-81.3623, 44.9024],
                        [-81.373, 44.9062],
                        [-81.3736, 44.9247],
                        [-81.369, 44.9329],
                        [-81.3632, 44.9495],
                        [-81.3577, 44.9554],
                        [-81.3539, 44.9663],
                        [-81.3622, 44.9678],
                        [-81.3763, 44.9669],
                        [-81.371, 44.9799],
                        [-81.3645, 44.9867],
                        [-81.3701, 44.9896],
                        [-81.3703, 44.9979],
                        [-81.3809, 44.9976],
                        [-81.3956, 44.9867],
                        [-81.3947, 44.9827],
                        [-81.4055, 44.9799],
                        [-81.4053, 44.9987],
                        [-81.4155, 44.9949],
                        [-81.4182, 44.9824],
                        [-81.4248, 44.9746],
                        [-81.4325, 44.9712],
                        [-81.437, 44.9751],
                        [-81.4298, 44.9901],
                        [-81.4411, 44.9915],
                        [-81.4406, 44.9963],
                        [-81.4541, 45.0041],
                        [-81.4503, 45.0125],
                        [-81.4571, 45.0177],
                        [-81.4558, 45.0264],
                        [-81.4681, 45.036],
                        [-81.4648, 45.0424],
                        [-81.4673, 45.048],
                        [-81.4814, 45.0448],
                        [-81.4892, 45.0585],
                        [-81.4987, 45.0617],
                        [-81.4989, 45.0728],
                        [-81.5059, 45.0768],
                        [-81.5169, 45.0785],
                        [-81.5134, 45.084],
                        [-81.5143, 45.0933],
                        [-81.5199, 45.1017],
                        [-81.5277, 45.108],
                        [-81.534, 45.1178],
                        [-81.5401, 45.1129],
                        [-81.5519, 45.1188],
                        [-81.5525, 45.1257],
                        [-81.5663, 45.1274],
                        [-81.5675, 45.1373],
                        [-81.573, 45.1432],
                        [-81.571, 45.1528],
                        [-81.5792, 45.1531],
                        [-81.5898, 45.1494],
                        [-81.5929, 45.1596],
                        [-81.5765, 45.1927],
                        [-81.5859, 45.1926],
                        [-81.6091, 45.1798],
                        [-81.6143, 45.182],
                        [-81.6145, 45.1896],
                        [-81.6207, 45.1931],
                        [-81.6273, 45.1901],
                        [-81.6553, 45.1876],
                        [-81.6561, 45.1989],
                        [-81.6619, 45.2014],
                        [-81.6738, 45.2013],
                        [-81.6834, 45.1976],
                        [-81.7006, 45.1979],
                        [-81.7001, 45.207],
                        [-81.7039, 45.2107],
                        [-81.7178, 45.2128],
                        [-81.7295, 45.2213],
                        [-81.7209, 45.2281],
                        [-81.709, 45.2328],
                        [-81.7035, 45.2323],
                        [-81.688, 45.2383],
                        [-81.6948, 45.2457],
                        [-81.6874, 45.257],
                        [-81.6732, 45.2586],
                        [-81.6572, 45.2661],
                        [-81.6375, 45.2648],
                        [-81.6411, 45.2498],
                        [-81.6155, 45.2547],
                        [-81.6068, 45.2489],
                        [-81.5892, 45.2523],
                        [-81.5782, 45.247],
                        [-81.5813, 45.2404],
                        [-81.5707, 45.2401],
                        [-81.5646, 45.2449],
                        [-81.5517, 45.2465],
                        [-81.5458, 45.2447],
                        [-81.5347, 45.247],
                        [-81.5293, 45.2434],
                        [-81.5108, 45.24],
                        [-81.505, 45.2408],
                        [-81.4635, 45.2301],
                        [-81.442, 45.2324],
                        [-81.4221, 45.2323],
                        [-81.4076, 45.2346],
                        [-81.3913, 45.2413],
                        [-81.3754, 45.243],
                        [-81.3515, 45.2402],
                        [-81.32, 45.2453],
                        [-81.3106, 45.2482],
                        [-81.3015, 45.2479],
                        [-81.2981, 45.2413],
                        [-81.2902, 45.2456],
                        [-81.2841, 45.2338],
                        [-81.284, 45.2268],
                        [-81.2956, 45.2117],
                        [-81.3084, 45.2039],
                        [-81.3284, 45.1889],
                        [-81.3349, 45.1764],
                        [-81.3371, 45.1658],
                        [-81.3343, 45.1503],
                        [-81.3348, 45.1399],
                        [-81.3224, 45.1275],
                        [-81.3094, 45.1211],
                        [-81.2934, 45.1177],
                        [-81.2828, 45.1186],
                        [-81.2827, 45.1101],
                        [-81.2742, 45.0948],
                        [-81.2683, 45.0886],
                        [-81.2628, 45.0759],
                        [-81.2703, 45.0494],
                        [-81.2663, 45.0315],
                        [-81.2773, 45.023],
                        [-81.2677, 45.0054],
                        [-81.253, 44.9956],
                        [-81.2485, 44.9874],
                        [-81.2376, 44.9959],
                        [-81.2275, 45.0089],
                        [-81.2175, 45.0106],
                        [-81.214, 45.0075],
                        [-81.1962, 45.0018],
                        [-81.1948, 44.9972],
                        [-81.2029, 44.9881],
                        [-81.2199, 44.9729],
                        [-81.2143, 44.9608],
                        [-81.1954, 44.9581],
                        [-81.1744, 44.9598],
                        [-81.1547, 44.959],
                        [-81.1224, 44.9613],
                        [-81.1144, 44.9489],
                        [-81.1202, 44.9418],
                        [-81.1287, 44.9391],
                        [-81.1591, 44.9092],
                        [-81.1534, 44.9019],
                        [-81.1415, 44.9031],
                        [-81.137, 44.908],
                        [-81.1218, 44.9183],
                        [-81.1109, 44.9222],
                        [-81.1008, 44.9225],
                        [-81.0909, 44.919],
                        [-81.0875, 44.906],
                        [-81.0957, 44.9031],
                        [-81.1014, 44.8973],
                        [-81.0817, 44.8917],
                        [-81.0629, 44.8969],
                        [-81.0595, 44.9011],
                        [-81.0631, 44.9094],
                        [-81.0611, 44.9147],
                        [-81.05, 44.9181],
                        [-81.0313, 44.918],
                        [-81.0243, 44.9257],
                        [-81.0322, 44.9309],
                        [-81.0366, 44.9267],
                        [-81.0511, 44.9331],
                        [-81.0565, 44.9452],
                        [-81.0503, 44.9487],
                        [-81.0509, 44.9545],
                        [-81.0356, 44.9666],
                        [-81.0198, 44.9698],
                        [-81.0133, 44.9669],
                        [-81.01, 44.9586],
                        [-81.004, 44.9559],
                        [-80.9944, 44.9631],
                        [-80.992, 44.9711],
                        [-80.9837, 44.9663],
                        [-80.9627, 44.9588],
                        [-80.9596, 44.9531],
                        [-80.9647, 44.9457],
                        [-80.9809, 44.9399],
                        [-80.9998, 44.9307],
                        [-81.0209, 44.9141],
                        [-81.0221, 44.906],
                        [-81.0307, 44.8951],
                        [-81.0264, 44.8887],
                        [-81.0165, 44.8904],
                        [-81.0075, 44.8851],
                        [-81.0052, 44.8772],
                        [-81.0072, 44.8719],
                        [-81.0164, 44.8635],
                        [-81.021, 44.8504],
                        [-81.0343, 44.8362],
                        [-81.0417, 44.8335],
                        [-81.0559, 44.8244],
                        [-81.076, 44.8086],
                        [-81.1259, 44.7796],
                        [-81.1318, 44.7749],
                        [-81.1306, 44.767],
                        [-81.1399, 44.7472],
                        [-81.1276, 44.7424],
                        [-81.1118, 44.7542],
                        [-81.0957, 44.7685],
                        [-81.0886, 44.7722],
                        [-81.0794, 44.7809],
                        [-81.0641, 44.7855],
                        [-81.0376, 44.7964],
                        [-81.0086, 44.8059],
                        [-80.9908, 44.8099],
                        [-80.977, 44.8078],
                        [-80.9553, 44.796],
                        [-80.9442, 44.7937],
                        [-80.9295, 44.7961],
                        [-80.9232, 44.7992],
                        [-80.9068, 44.7957],
                        [-80.8973, 44.7859],
                        [-80.8888, 44.7706],
                        [-80.8881, 44.7372],
                        [-80.902, 44.7135],
                        [-80.9105, 44.7066],
                        [-80.9094, 44.6952],
                        [-80.9146, 44.689],
                        [-80.9184, 44.6777],
                        [-80.9181, 44.6687],
                        [-80.9257, 44.6587],
                        [-80.9393, 44.6207],
                        [-80.9466, 44.6132],
                        [-80.9435, 44.5913],
                        [-80.9459, 44.5803],
                        [-80.936, 44.5798],
                        [-80.9336, 44.5853],
                        [-80.9247, 44.5932],
                        [-80.9203, 44.6022],
                        [-80.9124, 44.6102],
                        [-80.9102, 44.6184],
                        [-80.8748, 44.6271],
                        [-80.8584, 44.6517],
                        [-80.8321, 44.6813],
                        [-80.8227, 44.6895],
                        [-80.8119, 44.692],
                        [-80.7988, 44.6981],
                        [-80.7956, 44.7043],
                        [-80.7801, 44.7084],
                        [-80.7752, 44.713],
                        [-80.7606, 44.7134],
                        [-80.7508, 44.7183],
                        [-80.7497, 44.7247],
                        [-80.7329, 44.72],
                        [-80.7126, 44.7201],
                        [-80.6971, 44.7227],
                        [-80.6559, 44.7227],
                        [-80.6398, 44.7204],
                        [-80.6384, 44.7019],
                        [-80.6261, 44.6861],
                        [-80.6264, 44.6761],
                        [-80.6231, 44.668],
                        [-80.6275, 44.6602],
                        [-80.6258, 44.6418],
                        [-80.6098, 44.6227],
                        [-80.5942, 44.6123],
                        [-80.571, 44.603],
                        [-80.5599, 44.602],
                        [-80.554, 44.5948],
                        [-80.5388, 44.5923],
                        [-80.511, 44.5957],
                        [-80.4937, 44.589],
                        [-80.4871, 44.5802],
                        [-80.4626, 44.573],
                        [-80.4268, 44.551],
                        [-80.4051, 44.541],
                        [-80.3921, 44.5377],
                        [-80.3806, 44.5411],
                        [-80.3675, 44.5389],
                        [-80.3501, 44.5386],
                        [-80.3385, 44.5345],
                        [-80.3211, 44.526],
                        [-80.3102, 44.5235],
                        [-80.3042, 44.525],
                        [-80.2945, 44.5323],
                        [-80.2831, 44.5265],
                        [-80.2719, 44.5253],
                        [-80.2634, 44.5214],
                        [-80.2498, 44.5214],
                        [-80.2459, 44.515],
                        [-80.2349, 44.5114],
                        [-80.2255, 44.5053],
                        [-80.2005, 44.5079],
                        [-80.1551, 44.4849],
                        [-80.1478, 44.4836],
                        [-80.134, 44.4755],
                        [-80.1197, 44.4704],
                        [-80.1007, 44.4706],
                        [-80.0838, 44.4767],
                        [-80.0572, 44.4922],
                        [-80.0176, 44.5282],
                        [-80.0053, 44.5419],
                        [-79.9947, 44.5613],
                        [-79.9964, 44.568],
                        [-79.9918, 44.5829],
                        [-79.9942, 44.5882],
                        [-80.0025, 44.5913],
                        [-80.0101, 44.599],
                        [-79.9938, 44.623],
                        [-79.9864, 44.6361],
                        [-79.9795, 44.658],
                        [-79.9792, 44.6677],
                        [-79.9844, 44.6743],
                        [-79.9944, 44.6784],
                        [-79.9981, 44.6928],
                        [-80.015, 44.7088],
                        [-80.0203, 44.708],
                        [-80.033, 44.7231],
                        [-80.0466, 44.7242],
                        [-80.0638, 44.7307],
                        [-80.0705, 44.7281],
                        [-80.0896, 44.7418],
                        [-80.1073, 44.744],
                        [-80.127, 44.75],
                        [-80.1327, 44.7604],
                        [-80.1309, 44.78],
                        [-80.1249, 44.7948],
                        [-80.1268, 44.8022],
                        [-80.1245, 44.8098],
                        [-80.1197, 44.8121],
                        [-80.0997, 44.8142],
                        [-80.0795, 44.8242],
                        [-80.0737, 44.8186],
                        [-80.0742, 44.8023],
                        [-80.0625, 44.7997],
                        [-80.0501, 44.8029],
                        [-80.0461, 44.8083],
                        [-80.0526, 44.8183],
                        [-80.0448, 44.824],
                        [-80.0386, 44.8379],
                        [-80.0289, 44.8441],
                        [-80.0328, 44.852],
                        [-80.0207, 44.8495],
                        [-80.0035, 44.8589],
                        [-79.9989, 44.8559],
                        [-79.983, 44.856],
                        [-79.9709, 44.8597],
                        [-79.9593, 44.8658],
                        [-79.951, 44.8669],
                        [-79.9443, 44.8596],
                        [-79.9231, 44.8612],
                        [-79.9129, 44.8553],
                        [-79.9043, 44.8399],
                        [-79.908, 44.8315],
                        [-79.9142, 44.8297],
                        [-79.929, 44.815],
                        [-79.9379, 44.8153],
                        [-79.9398, 44.8084],
                        [-79.9481, 44.8084],
                        [-79.9421, 44.7881],
                        [-79.9461, 44.782],
                        [-79.945, 44.7758],
                        [-79.9535, 44.7731],
                        [-79.9535, 44.7676],
                        [-79.9451, 44.766],
                        [-79.939, 44.7707],
                        [-79.9338, 44.7848],
                        [-79.9323, 44.7958],
                        [-79.9345, 44.8049],
                        [-79.9199, 44.8073],
                        [-79.9097, 44.8067],
                        [-79.8922, 44.8125],
                        [-79.8815, 44.8113],
                        [-79.8734, 44.8072],
                        [-79.8687, 44.7993],
                        [-79.8656, 44.7853],
                        [-79.8676, 44.7803],
                        [-79.8792, 44.7729],
                        [-79.8936, 44.7703],
                        [-79.8933, 44.7624],
                        [-79.8976, 44.7603],
                        [-79.8882, 44.7523],
                        [-79.8819, 44.759],
                        [-79.8727, 44.7588],
                        [-79.8561, 44.7458],
                        [-79.8483, 44.7484],
                        [-79.8491, 44.7558],
                        [-79.8425, 44.7623],
                        [-79.8344, 44.7623],
                        [-79.8077, 44.7705],
                        [-79.8098, 44.764],
                        [-79.8066, 44.756],
                        [-79.795, 44.7497],
                        [-79.8029, 44.7413],
                        [-79.7997, 44.7327],
                        [-79.7903, 44.7427],
                        [-79.7814, 44.7449],
                        [-79.7787, 44.7549],
                        [-79.764, 44.7692],
                        [-79.7545, 44.7635],
                        [-79.7585, 44.7573],
                        [-79.7537, 44.7463],
                        [-79.7454, 44.7343],
                        [-79.7331, 44.7366],
                        [-79.72, 44.7555],
                        [-79.6988, 44.7605],
                        [-79.7131, 44.7665],
                        [-79.7112, 44.7823],
                        [-79.7226, 44.7937],
                        [-79.72, 44.7994],
                        [-79.7378, 44.8035],
                        [-79.7364, 44.7947],
                        [-79.744, 44.7905],
                        [-79.7625, 44.8031],
                        [-79.772, 44.8065],
                        [-79.7732, 44.8113],
                        [-79.7965, 44.8134],
                        [-79.794, 44.8168],
                        [-79.7824, 44.8199],
                        [-79.782, 44.8295],
                        [-79.7757, 44.8345],
                        [-79.7848, 44.8422],
                        [-79.8116, 44.8442],
                        [-79.8076, 44.8506],
                        [-79.8199, 44.8576],
                        [-79.8201, 44.867],
                        [-79.8233, 44.8733],
                        [-79.7982, 44.873],
                        [-79.793, 44.867],
                        [-79.7857, 44.8682],
                        [-79.7777, 44.8754],
                        [-79.7843, 44.8799],
                        [-79.7956, 44.8734],
                        [-79.8067, 44.8763],
                        [-79.8064, 44.8806],
                        [-79.7944, 44.8884],
                        [-79.8004, 44.8915],
                        [-79.8169, 44.8881],
                        [-79.8247, 44.8974],
                        [-79.8379, 44.8954],
                        [-79.8457, 44.9079],
                        [-79.8298, 44.9103],
                        [-79.8288, 44.9186],
                        [-79.8329, 44.9232],
                        [-79.8221, 44.9319],
                        [-79.8315, 44.9344],
                        [-79.8315, 44.9422],
                        [-79.8463, 44.9413],
                        [-79.8479, 44.9473],
                        [-79.8562, 44.9504],
                        [-79.8605, 44.9561],
                        [-79.8698, 44.959],
                        [-79.8832, 44.9585],
                        [-79.8866, 44.9453],
                        [-79.9218, 44.9318],
                        [-79.9214, 44.9406],
                        [-79.932, 44.947],
                        [-79.9296, 44.9635],
                        [-79.9329, 44.9726],
                        [-79.9309, 44.9795],
                        [-79.9513, 44.9786],
                        [-79.9416, 44.9875],
                        [-79.931, 44.9892],
                        [-79.9302, 44.9967],
                        [-79.9255, 45.0023],
                        [-79.9359, 45.005],
                        [-79.9471, 45.0158],
                        [-79.9568, 45.0075],
                        [-79.9659, 45.0125],
                        [-79.972, 45.0123],
                        [-79.9918, 45.0182],
                        [-79.9941, 45.0239],
                        [-80.0106, 45.0282],
                        [-80.0098, 45.0337],
                        [-79.9997, 45.039],
                        [-79.9865, 45.0298],
                        [-79.9824, 45.0353],
                        [-79.984, 45.0444],
                        [-79.9741, 45.0438],
                        [-79.971, 45.0524],
                        [-79.96, 45.0534],
                        [-79.9651, 45.0611],
                        [-79.9799, 45.0609],
                        [-80, 45.0581],
                        [-80.0133, 45.0586],
                        [-80.0136, 45.0628],
                        [-80.0281, 45.0617],
                        [-80.0316, 45.059],
                        [-80.0469, 45.0624],
                        [-80.0512, 45.0676],
                        [-80.0605, 45.0658],
                        [-80.0692, 45.0676],
                        [-80.0665, 45.075],
                        [-80.0744, 45.082],
                        [-80.095, 45.0828],
                        [-80.0834, 45.0898],
                        [-80.0541, 45.0914],
                        [-80.0549, 45.0955],
                        [-80.0684, 45.0957],
                        [-80.0677, 45.1014],
                        [-80.0757, 45.1077],
                        [-80.0863, 45.1031],
                        [-80.0984, 45.1104],
                        [-80.1116, 45.1136],
                        [-80.1098, 45.1189],
                        [-80.0913, 45.1221],
                        [-80.0891, 45.1155],
                        [-80.0755, 45.1098],
                        [-80.0634, 45.1115],
                        [-80.055, 45.1087],
                        [-80.0359, 45.1114],
                        [-80.0284, 45.1167],
                        [-80.0184, 45.1166],
                        [-80.0115, 45.1273],
                        [-79.9847, 45.1304],
                        [-79.9813, 45.1375],
                        [-79.9952, 45.1483],
                        [-79.9817, 45.1518],
                        [-79.971, 45.1624],
                        [-79.9851, 45.1661],
                        [-79.995, 45.1714],
                        [-79.9889, 45.1561],
                        [-79.9941, 45.1511],
                        [-80.0204, 45.1502],
                        [-80.0163, 45.1577],
                        [-80.0194, 45.1691],
                        [-80.0279, 45.1674],
                        [-80.0265, 45.1773],
                        [-80.0204, 45.1851],
                        [-80.0259, 45.1893],
                        [-80.0375, 45.1872],
                        [-80.0492, 45.1732],
                        [-80.0576, 45.1813],
                        [-80.0532, 45.1852],
                        [-80.0618, 45.1938],
                        [-80.0618, 45.199],
                        [-80.0727, 45.1988],
                        [-80.0685, 45.2171],
                        [-80.077, 45.217],
                        [-80.0749, 45.2071],
                        [-80.083, 45.2008],
                        [-80.0975, 45.2044],
                        [-80.0975, 45.1976],
                        [-80.0921, 45.1889],
                        [-80.0981, 45.1864],
                        [-80.0973, 45.1805],
                        [-80.1209, 45.1861],
                        [-80.1176, 45.1905],
                        [-80.1272, 45.1935],
                        [-80.1342, 45.2001],
                        [-80.1309, 45.2077],
                        [-80.1237, 45.212],
                        [-80.1164, 45.2112],
                        [-80.1121, 45.2159],
                        [-80.1041, 45.2129],
                        [-80.0945, 45.2141],
                        [-80.0851, 45.2191],
                        [-80.0886, 45.2267],
                        [-80.0969, 45.2272],
                        [-80.1288, 45.2179],
                        [-80.129, 45.2233],
                        [-80.1477, 45.2417],
                        [-80.1472, 45.2461],
                        [-80.1362, 45.2533],
                        [-80.1284, 45.2519],
                        [-80.1153, 45.2543],
                        [-80.111, 45.261],
                        [-80.086, 45.27],
                        [-80.0773, 45.2772],
                        [-80.0656, 45.2825],
                        [-80.0571, 45.2833],
                        [-80.0446, 45.2932],
                        [-80.0454, 45.3045],
                        [-80.0399, 45.3105],
                        [-80.0437, 45.315],
                        [-80.0573, 45.3131],
                        [-80.0664, 45.3058],
                        [-80.0663, 45.3026],
                        [-80.0809, 45.2963],
                        [-80.0703, 45.2896],
                        [-80.084, 45.2878],
                        [-80.0893, 45.2813],
                        [-80.1036, 45.2856],
                        [-80.1078, 45.2788],
                        [-80.1301, 45.2801],
                        [-80.1351, 45.2781],
                        [-80.1469, 45.2806],
                        [-80.1447, 45.2727],
                        [-80.1469, 45.2675],
                        [-80.1639, 45.2684],
                        [-80.1653, 45.2638],
                        [-80.1532, 45.2595],
                        [-80.153, 45.2551],
                        [-80.1629, 45.2464],
                        [-80.1655, 45.2336],
                        [-80.1775, 45.2275],
                        [-80.1749, 45.2385],
                        [-80.1774, 45.2427],
                        [-80.1978, 45.2433],
                        [-80.1995, 45.258],
                        [-80.2096, 45.2541],
                        [-80.2094, 45.2631],
                        [-80.2032, 45.2673],
                        [-80.222, 45.2782],
                        [-80.2162, 45.2878],
                        [-80.225, 45.2967],
                        [-80.2182, 45.3034],
                        [-80.2162, 45.3126],
                        [-80.2013, 45.3212],
                        [-80.1859, 45.3214],
                        [-80.174, 45.3263],
                        [-80.1692, 45.3368],
                        [-80.178, 45.3384],
                        [-80.192, 45.3467],
                        [-80.1961, 45.3461],
                        [-80.2087, 45.353],
                        [-80.2177, 45.3504],
                        [-80.2228, 45.3427],
                        [-80.2386, 45.3445],
                        [-80.2335, 45.3495],
                        [-80.2424, 45.357],
                        [-80.2546, 45.3527],
                        [-80.2707, 45.3608],
                        [-80.2836, 45.3559],
                        [-80.2982, 45.3552],
                        [-80.306, 45.3688],
                        [-80.3035, 45.3726],
                        [-80.3154, 45.3843],
                        [-80.3089, 45.3918],
                        [-80.3124, 45.3995],
                        [-80.3099, 45.4089],
                        [-80.3223, 45.424],
                        [-80.3355, 45.4285],
                        [-80.3409, 45.4392],
                        [-80.3532, 45.4393],
                        [-80.348, 45.4295],
                        [-80.3532, 45.4272],
                        [-80.3691, 45.4363],
                        [-80.3786, 45.4339],
                        [-80.3961, 45.4603],
                        [-80.3915, 45.4667],
                        [-80.3961, 45.4865],
                        [-80.3874, 45.4937],
                        [-80.3901, 45.5014],
                        [-80.3895, 45.5135],
                        [-80.3928, 45.5219],
                        [-80.3828, 45.5219],
                        [-80.3874, 45.5463],
                        [-80.3864, 45.5665],
                        [-80.3942, 45.571],
                        [-80.4002, 45.5705],
                        [-80.4175, 45.5787],
                        [-80.4113, 45.5842],
                        [-80.402, 45.5823],
                        [-80.3939, 45.5934],
                        [-80.4008, 45.6017],
                        [-80.4014, 45.6077],
                        [-80.4258, 45.6152],
                        [-80.4272, 45.6196],
                        [-80.4492, 45.6149],
                        [-80.4366, 45.6078],
                        [-80.4129, 45.6024],
                        [-80.4101, 45.5961],
                        [-80.4113, 45.5861],
                        [-80.4257, 45.5775],
                        [-80.4511, 45.5746],
                        [-80.4659, 45.569],
                        [-80.4799, 45.5666],
                        [-80.4889, 45.56],
                        [-80.5018, 45.5579],
                        [-80.515, 45.5705],
                        [-80.5096, 45.5752],
                        [-80.4974, 45.5805],
                        [-80.5066, 45.5846],
                        [-80.4926, 45.5898],
                        [-80.504, 45.5946],
                        [-80.5068, 45.5877],
                        [-80.5214, 45.5884],
                        [-80.5286, 45.6041],
                        [-80.52, 45.6054],
                        [-80.5152, 45.6138],
                        [-80.5066, 45.6176],
                        [-80.5176, 45.6252],
                        [-80.5284, 45.6253],
                        [-80.531, 45.6297],
                        [-80.5409, 45.6294],
                        [-80.5533, 45.6338],
                        [-80.5622, 45.6326],
                        [-80.5734, 45.6346],
                        [-80.57, 45.6421],
                        [-80.5835, 45.6419],
                        [-80.5841, 45.6473],
                        [-80.5784, 45.6509],
                        [-80.5902, 45.6569],
                        [-80.5854, 45.6615],
                        [-80.5768, 45.6585],
                        [-80.5763, 45.6657],
                        [-80.5847, 45.6662],
                        [-80.593, 45.663],
                        [-80.5999, 45.669],
                        [-80.6055, 45.6824],
                        [-80.5886, 45.6863],
                        [-80.5876, 45.6913],
                        [-80.6016, 45.6909],
                        [-80.5944, 45.6988],
                        [-80.6122, 45.7024],
                        [-80.6159, 45.7082],
                        [-80.6229, 45.7083],
                        [-80.6372, 45.7187],
                        [-80.6347, 45.7229],
                        [-80.6493, 45.7233],
                        [-80.6566, 45.7316],
                        [-80.6328, 45.7448],
                        [-80.6361, 45.7494],
                        [-80.6458, 45.7504],
                        [-80.6376, 45.7581],
                        [-80.6171, 45.7676],
                        [-80.6346, 45.7692],
                        [-80.645, 45.7797],
                        [-80.6551, 45.7796],
                        [-80.6557, 45.7943],
                        [-80.6661, 45.7949],
                        [-80.6677, 45.7993],
                        [-80.6796, 45.7981],
                        [-80.6793, 45.8046],
                        [-80.6713, 45.8079],
                        [-80.6774, 45.813],
                        [-80.686, 45.8112],
                        [-80.6951, 45.8192],
                        [-80.7138, 45.8161],
                        [-80.7179, 45.8269],
                        [-80.7289, 45.8377],
                        [-80.7275, 45.8455],
                        [-80.733, 45.855],
                        [-80.7323, 45.8645],
                        [-80.7361, 45.8714],
                        [-80.7373, 45.8845],
                        [-80.7325, 45.8897],
                        [-80.7424, 45.8926],
                        [-80.7348, 45.912],
                        [-80.7519, 45.9239],
                        [-80.746, 45.9288],
                        [-80.7499, 45.9332],
                        [-80.7597, 45.9242],
                        [-80.7654, 45.9268],
                        [-80.7765, 45.9265],
                        [-80.7832, 45.9174],
                        [-80.7861, 45.9071],
                        [-80.7906, 45.9011],
                        [-80.8062, 45.9024],
                        [-80.8039, 45.9146],
                        [-80.7968, 45.9249],
                        [-80.7949, 45.9323],
                        [-80.8019, 45.9402],
                        [-80.8013, 45.9501],
                        [-80.8061, 45.9545],
                        [-80.8112, 45.943],
                        [-80.8195, 45.9318],
                        [-80.8303, 45.9311],
                        [-80.8284, 45.9249],
                        [-80.84, 45.9173],
                        [-80.8574, 45.9233],
                        [-80.8658, 45.9235],
                        [-80.8665, 45.9291],
                        [-80.8781, 45.9321],
                        [-80.8778, 45.9393],
                        [-80.892, 45.9415],
                        [-80.9033, 45.9407],
                        [-80.9117, 45.9294],
                        [-80.9315, 45.9253],
                        [-80.9511, 45.9309],
                        [-80.962, 45.9327],
                        [-80.9729, 45.94],
                        [-80.9838, 45.9397],
                        [-80.9976, 45.9446],
                        [-81.0114, 45.9462],
                        [-81.0281, 45.9507],
                        [-81.0537, 45.9517],
                        [-81.0751, 45.9398],
                        [-81.0895, 45.937],
                        [-81.0992, 45.9416],
                        [-81.1073, 45.9365],
                        [-81.1243, 45.9443],
                        [-81.1353, 45.9406],
                        [-81.138, 45.9327],
                        [-81.1449, 45.9287],
                        [-81.1415, 45.9192],
                        [-81.1734, 45.9306],
                        [-81.1852, 45.9296],
                        [-81.1904, 45.9338],
                        [-81.1726, 45.9386],
                        [-81.1815, 45.9476],
                        [-81.1759, 45.9516],
                        [-81.175, 45.9595],
                        [-81.1555, 45.9692],
                        [-81.1558, 45.9719],
                        [-81.144, 45.9842],
                        [-81.1516, 45.9895],
                        [-81.1485, 45.995],
                        [-81.1541, 46.0014],
                        [-81.1778, 46.002],
                        [-81.1864, 46.0003],
                        [-81.2065, 46],
                        [-81.2203, 46.0073],
                        [-81.2277, 45.9959],
                        [-81.2335, 45.9917],
                        [-81.2481, 45.9961],
                        [-81.2618, 45.9938],
                        [-81.278, 45.9938],
                        [-81.2905, 45.9961],
                        [-81.3124, 45.9935],
                        [-81.333, 45.994],
                        [-81.3378, 45.9926],
                        [-81.3549, 45.9937],
                        [-81.3611, 45.9967],
                        [-81.387, 45.9908],
                        [-81.3964, 45.9909],
                        [-81.4126, 45.9881],
                        [-81.4162, 45.9923],
                        [-81.4356, 45.9903],
                        [-81.4495, 45.9855],
                        [-81.4547, 45.9871],
                        [-81.4612, 45.9772],
                        [-81.4732, 45.9748],
                        [-81.4873, 45.9665],
                        [-81.5202, 45.9724],
                        [-81.5146, 45.9784],
                        [-81.5187, 45.9826],
                        [-81.5118, 45.988],
                        [-81.4924, 45.9932],
                        [-81.5051, 45.9985],
                        [-81.5043, 46.0035],
                        [-81.4942, 46.009],
                        [-81.5138, 46.0103],
                        [-81.5311, 46.0046],
                        [-81.5481, 45.9864],
                        [-81.5686, 45.985],
                        [-81.6004, 45.9648],
                        [-81.6179, 45.9569],
                        [-81.6313, 45.957],
                        [-81.6385, 45.9532],
                        [-81.6581, 45.9467],
                        [-81.6625, 45.9418],
                        [-81.6761, 45.9381],
                        [-81.6901, 45.9399],
                        [-81.6895, 45.9477],
                        [-81.6589, 45.9541],
                        [-81.6534, 45.9606],
                        [-81.6461, 45.9595],
                        [-81.6359, 45.9686],
                        [-81.6324, 45.9666],
                        [-81.6047, 45.9796],
                        [-81.6053, 45.9822],
                        [-81.5845, 45.9893],
                        [-81.5769, 45.9876],
                        [-81.5654, 45.9968],
                        [-81.5693, 46.0035],
                        [-81.5827, 46.0056],
                        [-81.6132, 46.0021],
                        [-81.6446, 46.0034],
                        [-81.6604, 46.003],
                        [-81.6714, 46.0078],
                        [-81.6615, 46.011],
                        [-81.6413, 46.0094],
                        [-81.6199, 46.0195],
                        [-81.6026, 46.0199],
                        [-81.5904, 46.0231],
                        [-81.5807, 46.0211],
                        [-81.5504, 46.0215],
                        [-81.5341, 46.0265],
                        [-81.5274, 46.0265],
                        [-81.5061, 46.0315],
                        [-81.5045, 46.0355],
                        [-81.5117, 46.0421],
                        [-81.5264, 46.0375],
                        [-81.5521, 46.0347],
                        [-81.5798, 46.0328],
                        [-81.609, 46.0247],
                        [-81.6408, 46.0205],
                        [-81.657, 46.0135],
                        [-81.6666, 46.0138],
                        [-81.6781, 46.0088],
                        [-81.7147, 46.0077],
                        [-81.7175, 46.0119],
                        [-81.7037, 46.0158],
                        [-81.7002, 46.02],
                        [-81.6871, 46.021],
                        [-81.6806, 46.0269],
                        [-81.6698, 46.0267],
                        [-81.6636, 46.0305],
                        [-81.6331, 46.0263],
                        [-81.6112, 46.0337],
                        [-81.5938, 46.0367],
                        [-81.5689, 46.0446],
                        [-81.5537, 46.0483],
                        [-81.5535, 46.0598],
                        [-81.5666, 46.0601],
                        [-81.5666, 46.0663],
                        [-81.5714, 46.0712],
                        [-81.5939, 46.0688],
                        [-81.6093, 46.0714],
                        [-81.6152, 46.0813],
                        [-81.6273, 46.0852],
                        [-81.6272, 46.0933],
                        [-81.6157, 46.0927],
                        [-81.6028, 46.0994],
                        [-81.5912, 46.0901],
                        [-81.572, 46.0867],
                        [-81.5518, 46.0863],
                        [-81.5542, 46.0929],
                        [-81.5684, 46.0886],
                        [-81.5703, 46.0915],
                        [-81.587, 46.094],
                        [-81.5875, 46.0979],
                        [-81.6152, 46.1014],
                        [-81.6102, 46.106],
                        [-81.6148, 46.1144],
                        [-81.6266, 46.1114],
                        [-81.6611, 46.1149],
                        [-81.6628, 46.1059],
                        [-81.6553, 46.1026],
                        [-81.6566, 46.0977],
                        [-81.6672, 46.0967],
                        [-81.6635, 46.0899],
                        [-81.6556, 46.0896],
                        [-81.667, 46.0737],
                        [-81.6454, 46.0715],
                        [-81.6413, 46.0669],
                        [-81.6481, 46.0634],
                        [-81.6563, 46.0661],
                        [-81.6776, 46.0662],
                        [-81.6831, 46.0635],
                        [-81.7071, 46.0629],
                        [-81.7202, 46.0638],
                        [-81.7315, 46.0614],
                        [-81.7396, 46.065],
                        [-81.7539, 46.0598],
                        [-81.7342, 46.0527],
                        [-81.73, 46.0493],
                        [-81.7362, 46.0352],
                        [-81.7495, 46.0299],
                        [-81.7428, 46.0219],
                        [-81.7535, 46.0091],
                        [-81.7623, 46.0028],
                        [-81.7731, 46.0024],
                        [-81.7791, 46.0082],
                        [-81.7705, 46.0275],
                        [-81.7777, 46.0379],
                        [-81.7785, 46.0448],
                        [-81.7928, 46.0616],
                        [-81.7809, 46.0672],
                        [-81.7778, 46.0725],
                        [-81.7666, 46.0755],
                        [-81.7579, 46.0832],
                        [-81.7394, 46.0829],
                        [-81.7302, 46.0916],
                        [-81.7366, 46.0972],
                        [-81.7197, 46.0969],
                        [-81.7165, 46.1024],
                        [-81.7258, 46.1062],
                        [-81.7376, 46.1076],
                        [-81.752, 46.105],
                        [-81.7683, 46.1077],
                        [-81.7794, 46.101],
                        [-81.8021, 46.099],
                        [-81.8154, 46.1012],
                        [-81.8261, 46.0979],
                        [-81.8333, 46.1032],
                        [-81.8723, 46.101],
                        [-81.8801, 46.0987],
                        [-81.9141, 46.0946],
                        [-81.9246, 46.0953],
                        [-81.9522, 46.0942],
                        [-81.9714, 46.0911],
                        [-82.01, 46.0953],
                        [-82.0353, 46.1033],
                        [-82.0458, 46.0999],
                        [-82.062, 46.1032],
                        [-82.0675, 46.1105],
                        [-82.083, 46.1106],
                        [-82.1016, 46.1074],
                        [-82.1326, 46.1145],
                        [-82.1448, 46.1127],
                        [-82.1546, 46.1173],
                        [-82.1612, 46.1114],
                        [-82.1707, 46.1157],
                        [-82.1843, 46.1169],
                        [-82.2011, 46.1293],
                        [-82.2191, 46.1364],
                        [-82.2237, 46.1426],
                        [-82.245, 46.1407],
                        [-82.2578, 46.1428],
                        [-82.265, 46.1492],
                        [-82.2877, 46.1431],
                        [-82.2861, 46.1371],
                        [-82.2987, 46.137],
                        [-82.3129, 46.1396],
                        [-82.3204, 46.1449],
                        [-82.3287, 46.1463],
                        [-82.3391, 46.1526],
                        [-82.342, 46.158],
                        [-82.3514, 46.1557],
                        [-82.3525, 46.1669],
                        [-82.3637, 46.1652],
                        [-82.3634, 46.1575],
                        [-82.3694, 46.1533],
                        [-82.3751, 46.1659],
                        [-82.3541, 46.1707],
                        [-82.3359, 46.1666],
                        [-82.3161, 46.1712],
                        [-82.3245, 46.1751],
                        [-82.3259, 46.1803],
                        [-82.319, 46.1871],
                        [-82.3364, 46.1873],
                        [-82.3458, 46.1848],
                        [-82.3565, 46.1851],
                        [-82.3829, 46.1818],
                        [-82.3885, 46.1881],
                        [-82.3998, 46.1842],
                        [-82.4112, 46.1897],
                        [-82.4242, 46.1889],
                        [-82.4405, 46.1844],
                        [-82.4531, 46.1727],
                        [-82.4585, 46.1845],
                        [-82.4472, 46.1973],
                        [-82.4514, 46.2009],
                        [-82.4685, 46.1998],
                        [-82.4708, 46.1962],
                        [-82.5102, 46.1777],
                        [-82.5239, 46.174],
                        [-82.5271, 46.1689],
                        [-82.5353, 46.1715],
                        [-82.558, 46.1653],
                        [-82.5702, 46.1686],
                        [-82.5841, 46.1692],
                        [-82.6029, 46.1645],
                        [-82.6144, 46.1692],
                        [-82.6227, 46.1633],
                        [-82.6275, 46.1655],
                        [-82.6418, 46.1574],
                        [-82.6716, 46.1587],
                        [-82.6782, 46.1574],
                        [-82.6934, 46.1612],
                        [-82.6781, 46.1718],
                        [-82.6825, 46.1858],
                        [-82.673, 46.1931],
                        [-82.6554, 46.1981],
                        [-82.6476, 46.197],
                        [-82.6423, 46.2021],
                        [-82.6336, 46.1993],
                        [-82.6197, 46.2029],
                        [-82.6275, 46.2093],
                        [-82.6759, 46.2005],
                        [-82.6962, 46.199],
                        [-82.7262, 46.192],
                        [-82.7324, 46.193],
                        [-82.7573, 46.1896],
                        [-82.7832, 46.1895],
                        [-82.7985, 46.1906],
                        [-82.8045, 46.1823],
                        [-82.8154, 46.1834],
                        [-82.8208, 46.1792],
                        [-82.8371, 46.1765],
                        [-82.8431, 46.1809],
                        [-82.8593, 46.1833],
                        [-82.8749, 46.1802],
                        [-82.8848, 46.1754],
                        [-82.902, 46.177],
                        [-82.9149, 46.1837],
                        [-82.9458, 46.1803],
                        [-82.9546, 46.1837],
                        [-82.9662, 46.1796],
                        [-82.9682, 46.1721],
                        [-82.9896, 46.1707],
                        [-83.0028, 46.173],
                        [-83.0102, 46.1673],
                        [-83.0192, 46.1718],
                        [-83.0297, 46.1726],
                        [-83.0366, 46.1693],
                        [-83.0505, 46.1701],
                        [-83.0496, 46.1764],
                        [-83.0596, 46.1747],
                        [-83.0662, 46.1846],
                        [-83.082, 46.1871],
                        [-83.0958, 46.1961],
                        [-83.1077, 46.1964],
                        [-83.1191, 46.1899],
                        [-83.1324, 46.1859],
                        [-83.1547, 46.1834],
                        [-83.1556, 46.1947],
                        [-83.1882, 46.2021],
                        [-83.2035, 46.2011],
                        [-83.2157, 46.2037],
                        [-83.2281, 46.2024],
                        [-83.2464, 46.2054],
                        [-83.2542, 46.212],
                        [-83.2767, 46.2138],
                        [-83.285, 46.2165],
                        [-83.2971, 46.2161],
                        [-83.3081, 46.2188],
                        [-83.3145, 46.2164],
                        [-83.3233, 46.2196],
                        [-83.3367, 46.2192],
                        [-83.3436, 46.2248],
                        [-83.3517, 46.2234],
                        [-83.3653, 46.2268],
                        [-83.3779, 46.2332],
                        [-83.3991, 46.2375],
                        [-83.4331, 46.2406],
                        [-83.4475, 46.2429],
                        [-83.455, 46.2404],
                        [-83.4703, 46.2468],
                        [-83.4993, 46.2465],
                        [-83.5301, 46.2525],
                        [-83.5354, 46.256],
                        [-83.5492, 46.2572],
                        [-83.5556, 46.2517],
                        [-83.5647, 46.2501],
                        [-83.5666, 46.2603],
                        [-83.5741, 46.2599],
                        [-83.5757, 46.2672],
                        [-83.5901, 46.2761],
                        [-83.6022, 46.277],
                        [-83.6123, 46.2858],
                        [-83.6265, 46.2747],
                        [-83.6353, 46.2723],
                        [-83.6563, 46.2719],
                        [-83.6744, 46.2741],
                        [-83.6821, 46.2703],
                        [-83.6983, 46.2715],
                        [-83.7004, 46.2766],
                        [-83.719, 46.2787],
                        [-83.7174, 46.2828],
                        [-83.7265, 46.2887],
                        [-83.7487, 46.2834],
                        [-83.7491, 46.2963],
                        [-83.7673, 46.2926],
                        [-83.7868, 46.2995],
                        [-83.7956, 46.2932],
                        [-83.8154, 46.2901],
                        [-83.8263, 46.2974],
                        [-83.8421, 46.297],
                        [-83.8453, 46.2933],
                        [-83.8634, 46.2966],
                        [-83.875, 46.3026],
                        [-83.8797, 46.3151],
                        [-83.8823, 46.3313],
                        [-83.8911, 46.3304],
                        [-83.9009, 46.334],
                        [-83.9142, 46.329],
                        [-83.9191, 46.3343],
                        [-83.9344, 46.3334],
                        [-83.9388, 46.3277],
                        [-83.9622, 46.3276],
                        [-83.9646, 46.3224],
                        [-83.986, 46.3219],
                        [-83.9962, 46.3231],
                        [-84.007, 46.3214],
                        [-84.0166, 46.3162],
                        [-84.0242, 46.3196],
                        [-84.0337, 46.32],
                        [-84.0475, 46.3152],
                        [-84.0598, 46.3202],
                        [-84.0616, 46.3287],
                        [-84.0666, 46.3332],
                        [-84.0789, 46.3377],
                        [-84.0885, 46.3471],
                        [-84.0896, 46.3319],
                        [-84.0955, 46.3175],
                        [-84.0812, 46.3134],
                        [-84.0828, 46.3052],
                        [-84.0745, 46.2969],
                        [-84.0637, 46.2916],
                        [-84.0501, 46.2884],
                        [-84.0374, 46.2937],
                        [-84.0324, 46.2924],
                        [-84.0174, 46.2996],
                        [-84.0023, 46.302],
                        [-83.9928, 46.3075],
                        [-83.9919, 46.3118],
                        [-83.9737, 46.3099],
                        [-83.9495, 46.2914],
                        [-83.94, 46.2889],
                        [-83.9291, 46.2972],
                        [-83.9219, 46.2924],
                        [-83.9116, 46.2808],
                        [-83.9055, 46.2768],
                        [-83.8933, 46.262],
                        [-83.8809, 46.2548],
                        [-83.8656, 46.2561],
                        [-83.8644, 46.26],
                        [-83.8496, 46.2644],
                        [-83.8355, 46.2613],
                        [-83.8312, 46.2507],
                        [-83.8417, 46.2507],
                        [-83.8406, 46.238],
                        [-83.8352, 46.2352],
                        [-83.8221, 46.2345],
                        [-83.8168, 46.2299],
                        [-83.8121, 46.2194],
                        [-83.8027, 46.2199],
                        [-83.7964, 46.2261],
                        [-83.7888, 46.2287],
                        [-83.7807, 46.2273],
                        [-83.773, 46.2135],
                        [-83.7823, 46.2101],
                        [-83.7989, 46.2009],
                        [-83.7922, 46.1872],
                        [-83.7871, 46.1839],
                        [-83.7968, 46.1747],
                        [-83.7974, 46.1691],
                        [-83.8075, 46.165],
                        [-83.8159, 46.1549],
                        [-83.8095, 46.1434],
                        [-83.819, 46.1414],
                        [-83.8333, 46.1354],
                        [-83.8428, 46.1386],
                        [-83.8551, 46.1465],
                        [-83.8582, 46.1542],
                        [-83.8725, 46.1592],
                        [-83.8785, 46.1579],
                        [-83.8928, 46.1491],
                        [-83.8853, 46.1385],
                        [-83.878, 46.1321],
                        [-83.8856, 46.1268],
                        [-83.8925, 46.1153],
                        [-83.892, 46.1087],
                        [-83.8962, 46.1039],
                        [-83.8924, 46.0993],
                        [-83.8819, 46.1026],
                        [-83.8769, 46.0988],
                        [-83.8783, 46.093],
                        [-83.889, 46.0934],
                        [-83.8915, 46.0834],
                        [-83.9058, 46.0805],
                        [-83.9134, 46.077],
                        [-83.9154, 46.0703],
                        [-83.9259, 46.0712],
                        [-83.9428, 46.0651],
                        [-83.9393, 46.0583],
                        [-83.9559, 46.0573],
                        [-83.9766, 46.1023],
                        [-84.007, 46.119],
                        [-84.0074, 46.1304],
                        [-83.9983, 46.1284],
                        [-83.9839, 46.1317],
                        [-83.9841, 46.1379],
                        [-83.9952, 46.1458],
                        [-83.9947, 46.1515],
                        [-84.0016, 46.1581],
                        [-84.0087, 46.1598],
                        [-84.0319, 46.1718],
                        [-84.0563, 46.1797],
                        [-84.0726, 46.1925],
                        [-84.0787, 46.2055],
                        [-84.0775, 46.2162],
                        [-84.0842, 46.2274],
                        [-84.0935, 46.2281],
                        [-84.1006, 46.2368],
                        [-84.1083, 46.2412],
                        [-84.0984, 46.2569],
                        [-84.1154, 46.2681],
                        [-84.1194, 46.3149],
                        [-84.1055, 46.3222],
                        [-84.1197, 46.3384],
                        [-84.1386, 46.3714],
                        [-84.1467, 46.4184],
                        [-84.1364, 46.4425],
                        [-84.1114, 46.5041],
                        [-84.1178, 46.5173],
                        [-84.1241, 46.5215],
                        [-84.1296, 46.5305],
                        [-84.1368, 46.5329],
                        [-84.1528, 46.5281],
                        [-84.1681, 46.5263],
                        [-84.1795, 46.5275],
                        [-84.1924, 46.5401],
                        [-84.2264, 46.5343],
                        [-84.2548, 46.5009],
                        [-84.2643, 46.4945],
                        [-84.2761, 46.4927],
                        [-84.2938, 46.4927],
                        [-84.3258, 46.4999],
                        [-84.3424, 46.5075],
                        [-84.3746, 46.509],
                        [-84.3958, 46.4986],
                        [-84.4228, 46.5008],
                        [-84.4456, 46.4881],
                        [-84.4764, 46.4532],
                        [-84.5566, 46.4609],
                        [-84.5874, 46.4801],
                        [-84.6122, 46.5076],
                        [-84.6377, 46.5291],
                        [-84.598, 46.5696],
                        [-84.5929, 46.5783],
                        [-84.5738, 46.5903],
                        [-84.5585, 46.6025],
                        [-84.546, 46.6076],
                        [-84.5357, 46.6149],
                        [-84.5309, 46.6233],
                        [-84.5192, 46.6336],
                        [-84.4977, 46.6436],
                        [-84.4944, 46.6475],
                        [-84.4912, 46.6612],
                        [-84.471, 46.665],
                        [-84.4612, 46.6702],
                        [-84.4539, 46.6698],
                        [-84.4383, 46.6754],
                        [-84.4351, 46.6803],
                        [-84.4253, 46.6826],
                        [-84.4204, 46.6909],
                        [-84.4294, 46.6975],
                        [-84.4399, 46.7125],
                        [-84.4331, 46.7208],
                        [-84.4314, 46.7346],
                        [-84.4425, 46.7493],
                        [-84.4602, 46.758],
                        [-84.4832, 46.7614],
                        [-84.4944, 46.7563],
                        [-84.5072, 46.7464],
                        [-84.5132, 46.7269],
                        [-84.5233, 46.7078],
                        [-84.52, 46.6984],
                        [-84.538, 46.6931],
                        [-84.5559, 46.6836],
                        [-84.5659, 46.6884],
                        [-84.5731, 46.6962],
                        [-84.5733, 46.7107],
                        [-84.5764, 46.714],
                        [-84.5777, 46.7361],
                        [-84.573, 46.7418],
                        [-84.5636, 46.7389],
                        [-84.5498, 46.7389],
                        [-84.5451, 46.7428],
                        [-84.5497, 46.7527],
                        [-84.5616, 46.7619],
                        [-84.553, 46.7702],
                        [-84.5516, 46.7765],
                        [-84.543, 46.7856],
                        [-84.54, 46.8011],
                        [-84.5443, 46.8109],
                        [-84.5555, 46.8128],
                        [-84.5612, 46.8173],
                        [-84.5545, 46.8275],
                        [-84.5365, 46.8316],
                        [-84.5113, 46.8229],
                        [-84.5017, 46.8228],
                        [-84.4868, 46.844],
                        [-84.4708, 46.8472],
                        [-84.4661, 46.8438],
                        [-84.4517, 46.8458],
                        [-84.4438, 46.8447],
                        [-84.4376, 46.8322],
                        [-84.4224, 46.8275],
                        [-84.4239, 46.8194],
                        [-84.4142, 46.8154],
                        [-84.3981, 46.8189],
                        [-84.3983, 46.8264],
                        [-84.3729, 46.8453],
                        [-84.3755, 46.8588],
                        [-84.3551, 46.8692],
                        [-84.3552, 46.8754],
                        [-84.3611, 46.8789],
                        [-84.3623, 46.8906],
                        [-84.3725, 46.8909],
                        [-84.386, 46.8984],
                        [-84.3895, 46.9067],
                        [-84.4023, 46.9142],
                        [-84.4146, 46.9177],
                        [-84.4353, 46.9178],
                        [-84.441, 46.928],
                        [-84.4464, 46.9321],
                        [-84.4562, 46.933],
                        [-84.479, 46.9393],
                        [-84.5024, 46.9331],
                        [-84.5165, 46.92],
                        [-84.5276, 46.9313],
                        [-84.5481, 46.9432],
                        [-84.5637, 46.9457],
                        [-84.5769, 46.946],
                        [-84.5915, 46.9321],
                        [-84.5992, 46.9317],
                        [-84.607, 46.9211],
                        [-84.6024, 46.9097],
                        [-84.5961, 46.9046],
                        [-84.6053, 46.9],
                        [-84.6152, 46.8889],
                        [-84.6528, 46.9173],
                        [-84.6577, 46.955],
                        [-84.6651, 46.9611],
                        [-84.6852, 46.9686],
                        [-84.6978, 46.9691],
                        [-84.7094, 46.9635],
                        [-84.7096, 46.9585],
                        [-84.717, 46.9527],
                        [-84.7244, 46.9521],
                        [-84.742, 46.9575],
                        [-84.7473, 46.9649],
                        [-84.7613, 46.9722],
                        [-84.7734, 46.9757],
                        [-84.7793, 46.9745],
                        [-84.7879, 46.9855],
                        [-84.7868, 46.9893],
                        [-84.7921, 47.0016],
                        [-84.7841, 47.0068],
                        [-84.785, 47.0136],
                        [-84.771, 47.0165],
                        [-84.7839, 47.0351],
                        [-84.7777, 47.0472],
                        [-84.7664, 47.0553],
                        [-84.7597, 47.0629],
                        [-84.7426, 47.0694],
                        [-84.7381, 47.0813],
                        [-84.7222, 47.0909],
                        [-84.7119, 47.1019],
                        [-84.7115, 47.1111],
                        [-84.7241, 47.113],
                        [-84.7291, 47.1212],
                        [-84.7294, 47.1358],
                        [-84.7353, 47.1358],
                        [-84.7406, 47.1423],
                        [-84.7253, 47.1454],
                        [-84.7131, 47.1516],
                        [-84.6981, 47.1616],
                        [-84.6933, 47.1687],
                        [-84.6962, 47.1766],
                        [-84.7083, 47.1764],
                        [-84.7131, 47.1855],
                        [-84.7017, 47.1882],
                        [-84.6958, 47.1946],
                        [-84.6886, 47.1947],
                        [-84.6801, 47.2004],
                        [-84.6762, 47.2091],
                        [-84.6671, 47.2126],
                        [-84.6655, 47.2187],
                        [-84.6564, 47.2231],
                        [-84.647, 47.2424],
                        [-84.6349, 47.246],
                        [-84.6293, 47.2562],
                        [-84.6183, 47.2598],
                        [-84.6159, 47.2649],
                        [-84.5895, 47.2836],
                        [-84.5933, 47.2899],
                        [-84.5859, 47.2992],
                        [-84.5899, 47.3047],
                        [-84.6034, 47.3051],
                        [-84.6162, 47.3317],
                        [-84.624, 47.3424],
                        [-84.6523, 47.3626],
                        [-84.6595, 47.3629],
                        [-84.6693, 47.3578],
                        [-84.6786, 47.3576],
                        [-84.6893, 47.3617],
                        [-84.7068, 47.3739],
                        [-84.702, 47.3822],
                        [-84.7069, 47.391],
                        [-84.7023, 47.3932],
                        [-84.703, 47.401],
                        [-84.7127, 47.4102],
                        [-84.7197, 47.4211],
                        [-84.7472, 47.4392],
                        [-84.7514, 47.4454],
                        [-84.7669, 47.4486],
                        [-84.7766, 47.4581],
                        [-84.7848, 47.4622],
                        [-84.793, 47.4737],
                        [-84.8009, 47.4762],
                        [-84.8232, 47.4764],
                        [-84.8366, 47.4855],
                        [-84.8656, 47.494],
                        [-84.8741, 47.5001],
                        [-84.8854, 47.4993],
                        [-84.8881, 47.5052],
                        [-84.909, 47.5143],
                        [-84.918, 47.5123],
                        [-84.932, 47.5199],
                        [-84.9383, 47.5284],
                        [-84.946, 47.5278],
                        [-84.9607, 47.5378],
                        [-84.9596, 47.5457],
                        [-84.9536, 47.5494],
                        [-84.9494, 47.557],
                        [-84.9538, 47.5621],
                        [-84.9769, 47.5708],
                        [-84.9816, 47.5756],
                        [-84.9906, 47.5774],
                        [-85.0066, 47.5895],
                        [-85.015, 47.5879],
                        [-85.0161, 47.5826],
                        [-85.0298, 47.591],
                        [-85.0346, 47.5963],
                        [-85.0392, 47.6071],
                        [-85.0314, 47.6116],
                        [-85.0216, 47.6115],
                        [-85.0124, 47.6166],
                        [-85.0223, 47.635],
                        [-85.0168, 47.6439],
                        [-85.0063, 47.6528],
                        [-84.9832, 47.6648],
                        [-84.9793, 47.6749],
                        [-84.9804, 47.682],
                        [-84.9892, 47.6951],
                        [-85.008, 47.7026],
                        [-85.0116, 47.7073],
                        [-84.9908, 47.7208],
                        [-84.9774, 47.7326],
                        [-84.9607, 47.7303],
                        [-84.9487, 47.7348],
                        [-84.9455, 47.7433],
                        [-84.926, 47.7628],
                        [-84.9211, 47.7733],
                        [-84.911, 47.7794],
                        [-84.8971, 47.7847],
                        [-84.8984, 47.7912],
                        [-84.9053, 47.7942],
                        [-84.9223, 47.79],
                        [-84.9183, 47.7981],
                        [-84.9303, 47.7994],
                        [-84.9349, 47.8069],
                        [-84.9276, 47.8151],
                        [-84.9383, 47.8158],
                        [-84.9505, 47.8208],
                        [-84.9421, 47.8278],
                        [-84.9391, 47.8448],
                        [-84.9324, 47.8488],
                        [-84.9116, 47.8514],
                        [-84.8986, 47.8686],
                        [-84.8916, 47.8732],
                        [-84.8795, 47.8902],
                        [-84.8657, 47.8977],
                        [-84.8637, 47.9028],
                        [-84.8518, 47.9113],
                        [-84.8389, 47.9146],
                        [-84.8384, 47.9208],
                        [-84.8499, 47.933],
                        [-84.8451, 47.9409],
                        [-84.8457, 47.9463],
                        [-84.859, 47.9577],
                        [-84.8825, 47.9568],
                        [-84.9013, 47.9613],
                        [-84.9117, 47.957],
                        [-84.906, 47.9435],
                        [-84.9178, 47.9474],
                        [-84.9329, 47.96],
                        [-84.9511, 47.963],
                        [-84.9659, 47.9616],
                        [-84.9716, 47.9561],
                        [-84.9877, 47.9634],
                        [-85.0016, 47.9604],
                        [-85.0037, 47.9567],
                        [-85.0231, 47.9617],
                        [-85.0307, 47.9585],
                        [-85.048, 47.9615],
                        [-85.0523, 47.9595],
                        [-85.0751, 47.9571],
                        [-85.0989, 47.9597],
                        [-85.1135, 47.9573],
                        [-85.1314, 47.9592],
                        [-85.1373, 47.9565],
                        [-85.1544, 47.9593],
                        [-85.1729, 47.9582],
                        [-85.1961, 47.9601],
                        [-85.2161, 47.9539],
                        [-85.2226, 47.9546],
                        [-85.2465, 47.9515],
                        [-85.2581, 47.9537],
                        [-85.2678, 47.952],
                        [-85.2736, 47.9561],
                        [-85.2853, 47.957],
                        [-85.2974, 47.9515],
                        [-85.3173, 47.9548],
                        [-85.3322, 47.9473],
                        [-85.3642, 47.9366],
                        [-85.3748, 47.9313],
                        [-85.3832, 47.9235],
                        [-85.3928, 47.9227],
                        [-85.4157, 47.9263],
                        [-85.4194, 47.9233],
                        [-85.4324, 47.9232],
                        [-85.4364, 47.9256],
                        [-85.4638, 47.9182],
                        [-85.4717, 47.9199],
                        [-85.4928, 47.9187],
                        [-85.508, 47.92],
                        [-85.5317, 47.9178],
                        [-85.5425, 47.9212],
                        [-85.5474, 47.9171],
                        [-85.5668, 47.9179],
                        [-85.5915, 47.9216],
                        [-85.5954, 47.92],
                        [-85.633, 47.9236],
                        [-85.6382, 47.9202],
                        [-85.6482, 47.9243],
                        [-85.6666, 47.9231],
                        [-85.6727, 47.9275],
                        [-85.6829, 47.9236],
                        [-85.6985, 47.9311],
                        [-85.7141, 47.9306],
                        [-85.7501, 47.9389],
                        [-85.7591, 47.9431],
                        [-85.7722, 47.9416],
                        [-85.7983, 47.9512],
                        [-85.8048, 47.9507],
                        [-85.8142, 47.9583],
                        [-85.8252, 47.9609],
                        [-85.8456, 47.9702],
                        [-85.8489, 47.9745],
                        [-85.8752, 47.9828],
                        [-85.8823, 47.9872],
                        [-85.8845, 47.9932],
                        [-85.8925, 47.9938],
                        [-85.8931, 48.0018],
                        [-85.8984, 48.009],
                        [-85.9063, 48.0085],
                        [-85.9057, 48.0195],
                        [-85.9168, 48.0273],
                        [-85.9277, 48.0242],
                        [-85.9288, 48.0206],
                        [-85.9421, 48.0213],
                        [-85.9384, 48.0293],
                        [-85.9442, 48.0391],
                        [-85.9397, 48.0417],
                        [-85.9613, 48.0508],
                        [-85.9792, 48.0657],
                        [-85.9919, 48.0703],
                        [-86, 48.0702],
                        [-86.0216, 48.077],
                        [-86.0238, 48.0831],
                        [-86.0322, 48.0894],
                        [-86.0193, 48.0901],
                        [-86, 48.0882],
                        [-86.0183, 48.0948],
                        [-86.0388, 48.1111],
                        [-86.0456, 48.13],
                        [-86.0605, 48.1354],
                        [-86.0591, 48.1384],
                        [-86.0681, 48.145],
                        [-86.07, 48.1508],
                        [-86.0847, 48.1593],
                        [-86.0856, 48.1704],
                        [-86.0963, 48.1827],
                        [-86.1036, 48.184],
                        [-86.1029, 48.1921],
                        [-86.1127, 48.1985],
                        [-86.1034, 48.2017],
                        [-86.1136, 48.2162],
                        [-86.1209, 48.2197],
                        [-86.1329, 48.2316],
                        [-86.1256, 48.2335],
                        [-86.1447, 48.2429],
                        [-86.1406, 48.2546],
                        [-86.1411, 48.2609],
                        [-86.1351, 48.2649],
                        [-86.1385, 48.2695],
                        [-86.1502, 48.2723],
                        [-86.1541, 48.2856],
                        [-86.1522, 48.2925],
                        [-86.1617, 48.3061],
                        [-86.1715, 48.3108],
                        [-86.1786, 48.3312],
                        [-86.1843, 48.3333],
                        [-86.1882, 48.3411],
                        [-86.1902, 48.3631],
                        [-86.1985, 48.3757],
                        [-86.2035, 48.3791],
                        [-86.1894, 48.3861],
                        [-86.1827, 48.3932],
                        [-86.1888, 48.3979],
                        [-86.2135, 48.4047],
                        [-86.2286, 48.4109],
                        [-86.2293, 48.4167],
                        [-86.2365, 48.4268],
                        [-86.2212, 48.4276],
                        [-86.2289, 48.434],
                        [-86.2265, 48.4394],
                        [-86.2339, 48.4425],
                        [-86.2337, 48.4523],
                        [-86.2302, 48.4558],
                        [-86.2373, 48.4642],
                        [-86.2434, 48.4781],
                        [-86.2545, 48.4951],
                        [-86.2477, 48.5029],
                        [-86.2499, 48.5104],
                        [-86.2573, 48.5147],
                        [-86.2615, 48.5229],
                        [-86.2609, 48.5344],
                        [-86.2671, 48.5392],
                        [-86.2671, 48.5456],
                        [-86.2729, 48.5502],
                        [-86.2749, 48.558],
                        [-86.2686, 48.5602],
                        [-86.2728, 48.571],
                        [-86.2805, 48.5756],
                        [-86.2867, 48.5842],
                        [-86.3005, 48.5909],
                        [-86.3031, 48.6006],
                        [-86.3194, 48.6087],
                        [-86.3334, 48.6099],
                        [-86.3308, 48.6232],
                        [-86.3254, 48.6281],
                        [-86.3288, 48.6363],
                        [-86.3218, 48.6406],
                        [-86.308, 48.6416],
                        [-86.3101, 48.6476],
                        [-86.3185, 48.6513],
                        [-86.3243, 48.6482],
                        [-86.3466, 48.6445],
                        [-86.3527, 48.6549],
                        [-86.3635, 48.6673],
                        [-86.3602, 48.6708],
                        [-86.3681, 48.6798],
                        [-86.3773, 48.6858],
                        [-86.3815, 48.6921],
                        [-86.3803, 48.6995],
                        [-86.3955, 48.712],
                        [-86.4097, 48.714],
                        [-86.4075, 48.7212],
                        [-86.4005, 48.7228],
                        [-86.3925, 48.7191],
                        [-86.3852, 48.7247],
                        [-86.3872, 48.735],
                        [-86.4027, 48.7505],
                        [-86.4132, 48.7517],
                        [-86.4229, 48.7453],
                        [-86.4226, 48.7405],
                        [-86.4333, 48.7351],
                        [-86.4425, 48.7348],
                        [-86.4478, 48.7384],
                        [-86.4267, 48.7475],
                        [-86.4293, 48.754],
                        [-86.4264, 48.7588],
                        [-86.4286, 48.7739],
                        [-86.437, 48.773],
                        [-86.4588, 48.7632],
                        [-86.4667, 48.766],
                        [-86.4746, 48.7586],
                        [-86.4833, 48.7592],
                        [-86.4918, 48.7671],
                        [-86.5214, 48.7479],
                        [-86.5343, 48.7509],
                        [-86.5426, 48.7416],
                        [-86.5376, 48.737],
                        [-86.5383, 48.7278],
                        [-86.5506, 48.7303],
                        [-86.567, 48.7275],
                        [-86.5853, 48.7278],
                        [-86.5898, 48.7325],
                        [-86.6073, 48.7328],
                        [-86.6369, 48.7298],
                        [-86.6402, 48.7361],
                        [-86.6296, 48.7529],
                        [-86.6186, 48.7535],
                        [-86.616, 48.7573],
                        [-86.6199, 48.765],
                        [-86.6156, 48.7795],
                        [-86.6364, 48.791],
                        [-86.648, 48.7866],
                        [-86.6697, 48.7914],
                        [-86.6772, 48.7897],
                        [-86.6888, 48.7911],
                        [-86.6958, 48.7982],
                        [-86.6982, 48.8082],
                        [-86.708, 48.8028],
                        [-86.7264, 48.801],
                        [-86.7301, 48.7962],
                        [-86.7272, 48.7909],
                        [-86.7588, 48.7804],
                        [-86.7726, 48.778],
                        [-86.7813, 48.783],
                        [-86.794, 48.7802],
                        [-86.8042, 48.7674],
                        [-86.8144, 48.7644],
                        [-86.8268, 48.7544],
                        [-86.8508, 48.7525],
                        [-86.8648, 48.746],
                        [-86.8753, 48.7485],
                        [-86.8939, 48.7581],
                        [-86.8905, 48.7661],
                        [-86.8958, 48.7727],
                        [-86.9232, 48.7744],
                        [-86.9384, 48.7726],
                        [-86.9444, 48.7774],
                        [-86.9521, 48.7774],
                        [-86.96, 48.7866],
                        [-86.968, 48.7902],
                        [-86.9749, 48.7982],
                        [-86.9709, 48.8037],
                        [-86.9636, 48.8047],
                        [-86.9565, 48.8123],
                        [-86.9604, 48.8166],
                        [-86.9789, 48.8058],
                        [-86.9906, 48.8057],
                        [-87.0034, 48.8134],
                        [-87.0042, 48.795],
                        [-86.9974, 48.7837],
                        [-86.9997, 48.7752],
                        [-87.0072, 48.7723],
                        [-87.02, 48.7764],
                        [-87.022, 48.781],
                        [-87.0165, 48.7869],
                        [-87.0258, 48.7888],
                        [-87.0289, 48.7939],
                        [-87.0488, 48.7842],
                        [-87.0599, 48.7823],
                        [-87.071, 48.7728],
                        [-87.0837, 48.7763],
                        [-87.1037, 48.7729],
                        [-87.1113, 48.7694],
                        [-87.1258, 48.7682],
                        [-87.1353, 48.7708],
                        [-87.1469, 48.7692],
                        [-87.1579, 48.7785],
                        [-87.1604, 48.787],
                        [-87.1806, 48.7839],
                        [-87.1761, 48.7765],
                        [-87.1838, 48.7634],
                        [-87.1988, 48.7608],
                        [-87.2035, 48.7565],
                        [-87.2171, 48.7582],
                        [-87.2296, 48.7556],
                        [-87.259, 48.7683],
                        [-87.2629, 48.7606],
                        [-87.2709, 48.7592],
                        [-87.2982, 48.766],
                        [-87.304, 48.7732],
                        [-87.2895, 48.7928],
                        [-87.3024, 48.7963],
                        [-87.3264, 48.7997],
                        [-87.33, 48.7957],
                        [-87.3438, 48.7961],
                        [-87.3523, 48.7994],
                        [-87.3719, 48.8019],
                        [-87.3906, 48.8063],
                        [-87.3967, 48.8112],
                        [-87.3965, 48.8182],
                        [-87.4083, 48.8334],
                        [-87.4178, 48.8361],
                        [-87.4376, 48.8386],
                        [-87.4469, 48.8371],
                        [-87.457, 48.8395],
                        [-87.4761, 48.8401],
                        [-87.5013, 48.8359],
                        [-87.5213, 48.8337],
                        [-87.5235, 48.8282],
                        [-87.5363, 48.8253],
                        [-87.5491, 48.8252],
                        [-87.5542, 48.8363],
                        [-87.5491, 48.8465],
                        [-87.5448, 48.8613],
                        [-87.5414, 48.8662],
                        [-87.5421, 48.8747],
                        [-87.548, 48.8784],
                        [-87.5757, 48.8779],
                        [-87.5903, 48.8733],
                        [-87.5943, 48.8634],
                        [-87.6333, 48.8556],
                        [-87.6475, 48.8563],
                        [-87.6545, 48.8648],
                        [-87.6604, 48.8869],
                        [-87.6727, 48.8971],
                        [-87.7019, 48.9006],
                        [-87.7195, 48.9065],
                        [-87.7345, 48.9081],
                        [-87.7427, 48.9059],
                        [-87.7667, 48.8929],
                        [-87.7791, 48.8971],
                        [-87.7797, 48.9018],
                        [-87.7675, 48.9133],
                        [-87.7855, 48.9262],
                        [-87.7967, 48.9263],
                        [-87.8144, 48.9201],
                        [-87.8171, 48.916],
                        [-87.8357, 48.9085],
                        [-87.8419, 48.9216],
                        [-87.8613, 48.9276],
                        [-87.8681, 48.9266],
                        [-87.878, 48.9365],
                        [-87.8889, 48.9405],
                        [-87.9035, 48.9372],
                        [-87.916, 48.9309],
                        [-87.9292, 48.9288],
                        [-87.9395, 48.9359],
                        [-87.9712, 48.9495],
                        [-87.9721, 48.9534],
                        [-87.982, 48.958],
                        [-87.9907, 48.9664],
                        [-88.0063, 48.9646],
                        [-88.0134, 48.9721],
                        [-88.0132, 48.9791],
                        [-88.0246, 48.9852],
                        [-88.0238, 49.0069],
                        [-88.0323, 49.0161],
                        [-88.0426, 49.0172],
                        [-88.0564, 49.0108],
                        [-88.0618, 49.0034],
                        [-88.0647, 48.9931],
                        [-88.0718, 48.9835],
                        [-88.0817, 48.9915],
                        [-88.0984, 48.9966],
                        [-88.1047, 49.0007],
                        [-88.1127, 48.999],
                        [-88.1326, 48.9893],
                        [-88.1333, 48.9832],
                        [-88.1425, 48.9821],
                        [-88.153, 48.976],
                        [-88.1656, 48.9627],
                        [-88.1621, 48.9571],
                        [-88.166, 48.9532],
                        [-88.1773, 48.958],
                        [-88.1903, 48.9665],
                        [-88.2059, 48.9658],
                        [-88.2202, 48.9589],
                        [-88.2354, 48.9591],
                        [-88.2485, 48.9401],
                        [-88.2405, 48.9346],
                        [-88.2371, 48.9283],
                        [-88.2262, 48.9255],
                        [-88.2283, 48.9177],
                        [-88.2265, 48.907],
                        [-88.2403, 48.8908],
                        [-88.2446, 48.874],
                        [-88.2407, 48.8712],
                        [-88.2377, 48.8545],
                        [-88.2266, 48.8392],
                        [-88.213, 48.8332],
                        [-88.1917, 48.8295],
                        [-88.1823, 48.8251],
                        [-88.1754, 48.826],
                        [-88.1692, 48.821],
                        [-88.1598, 48.8201],
                        [-88.1583, 48.8155],
                        [-88.1466, 48.8106],
                        [-88.1293, 48.7981],
                        [-88.1055, 48.7975],
                        [-88.0942, 48.7861],
                        [-88.0905, 48.7751],
                        [-88.0956, 48.7707],
                        [-88.1009, 48.7585],
                        [-88.1015, 48.7495],
                        [-88.1055, 48.7456],
                        [-88.1048, 48.7338],
                        [-88.094, 48.7116],
                        [-88.1005, 48.7077],
                        [-88.0992, 48.7012],
                        [-88.1055, 48.6899],
                        [-88.1138, 48.689],
                        [-88.1146, 48.6822],
                        [-88.1382, 48.66],
                        [-88.1486, 48.6485],
                        [-88.1604, 48.6412],
                        [-88.1615, 48.6325],
                        [-88.1813, 48.6246],
                        [-88.19, 48.6196],
                        [-88.1906, 48.6137],
                        [-88.1977, 48.6096],
                        [-88.196, 48.5962],
                        [-88.2098, 48.5992],
                        [-88.2355, 48.5783],
                        [-88.2473, 48.5819],
                        [-88.2543, 48.5771],
                        [-88.2592, 48.5822],
                        [-88.2479, 48.5908],
                        [-88.2481, 48.6074],
                        [-88.237, 48.6197],
                        [-88.2472, 48.6183],
                        [-88.275, 48.5944],
                        [-88.284, 48.5887],
                        [-88.2936, 48.5951],
                        [-88.2971, 48.6033],
                        [-88.2946, 48.6091],
                        [-88.2962, 48.6188],
                        [-88.3048, 48.6237],
                        [-88.3145, 48.6356],
                        [-88.3221, 48.6401],
                        [-88.3225, 48.6466],
                        [-88.3336, 48.6472],
                        [-88.3411, 48.6444],
                        [-88.3359, 48.6215],
                        [-88.336, 48.6145],
                        [-88.3302, 48.6042],
                        [-88.3313, 48.5884],
                        [-88.3176, 48.5848],
                        [-88.3141, 48.5882],
                        [-88.2989, 48.5815],
                        [-88.2973, 48.5776],
                        [-88.3022, 48.5667],
                        [-88.3115, 48.5644],
                        [-88.3187, 48.5533],
                        [-88.3275, 48.5559],
                        [-88.3291, 48.5697],
                        [-88.341, 48.5707],
                        [-88.3537, 48.56],
                        [-88.362, 48.5565],
                        [-88.3661, 48.5471],
                        [-88.3757, 48.5396],
                        [-88.3906, 48.538],
                        [-88.3936, 48.529],
                        [-88.4013, 48.5297],
                        [-88.3978, 48.5383],
                        [-88.4025, 48.5431],
                        [-88.4124, 48.5365],
                        [-88.4166, 48.5451],
                        [-88.4221, 48.5451],
                        [-88.4169, 48.5567],
                        [-88.4163, 48.5675],
                        [-88.4257, 48.5683],
                        [-88.4351, 48.5607],
                        [-88.4441, 48.5458],
                        [-88.4544, 48.5331],
                        [-88.4522, 48.5302],
                        [-88.4652, 48.5128],
                        [-88.4747, 48.5086],
                        [-88.4767, 48.5202],
                        [-88.4823, 48.5209],
                        [-88.4922, 48.5109],
                        [-88.4984, 48.4985],
                        [-88.4966, 48.4814],
                        [-88.4867, 48.4796],
                        [-88.4869, 48.4706],
                        [-88.4829, 48.4636],
                        [-88.492, 48.4625],
                        [-88.4999, 48.4672],
                        [-88.5118, 48.4671],
                        [-88.5162, 48.4635],
                        [-88.524, 48.45],
                        [-88.52, 48.4477],
                        [-88.5301, 48.438],
                        [-88.5208, 48.4318],
                        [-88.5277, 48.4242],
                        [-88.5395, 48.4243],
                        [-88.545, 48.4163],
                        [-88.5607, 48.4103],
                        [-88.5596, 48.4188],
                        [-88.5516, 48.4283],
                        [-88.5653, 48.4366],
                        [-88.568, 48.4563],
                        [-88.5732, 48.4565],
                        [-88.567, 48.4727],
                        [-88.5666, 48.4858],
                        [-88.5796, 48.4792],
                        [-88.5939, 48.4824],
                        [-88.5835, 48.4914],
                        [-88.5717, 48.4972],
                        [-88.5622, 48.5066],
                        [-88.5669, 48.5126],
                        [-88.5581, 48.5228],
                        [-88.5592, 48.5259],
                        [-88.5523, 48.5378],
                        [-88.5403, 48.535],
                        [-88.5268, 48.5443],
                        [-88.4911, 48.574],
                        [-88.4803, 48.5773],
                        [-88.4766, 48.5835],
                        [-88.4647, 48.5899],
                        [-88.4614, 48.5947],
                        [-88.4409, 48.6132],
                        [-88.4108, 48.6349],
                        [-88.3921, 48.6452],
                        [-88.3801, 48.6499],
                        [-88.372, 48.6585],
                        [-88.3531, 48.6653],
                        [-88.3387, 48.6735],
                        [-88.3304, 48.6956],
                        [-88.3292, 48.7027],
                        [-88.3215, 48.7104],
                        [-88.3114, 48.7311],
                        [-88.3114, 48.7347],
                        [-88.3011, 48.7562],
                        [-88.3061, 48.7645],
                        [-88.318, 48.7736],
                        [-88.3358, 48.7814],
                        [-88.336, 48.7848],
                        [-88.3274, 48.7938],
                        [-88.3265, 48.8049],
                        [-88.3424, 48.82],
                        [-88.358, 48.826],
                        [-88.3778, 48.8311],
                        [-88.3978, 48.8329],
                        [-88.4095, 48.8358],
                        [-88.4171, 48.8415],
                        [-88.4373, 48.8472],
                        [-88.4541, 48.8484],
                        [-88.4747, 48.8467],
                        [-88.491, 48.8396],
                        [-88.4992, 48.8336],
                        [-88.4941, 48.8286],
                        [-88.4864, 48.8302],
                        [-88.4866, 48.8156],
                        [-88.526, 48.7936],
                        [-88.5279, 48.7848],
                        [-88.542, 48.7741],
                        [-88.5474, 48.7566],
                        [-88.5438, 48.7524],
                        [-88.5514, 48.7407],
                        [-88.5425, 48.7324],
                        [-88.5394, 48.7218],
                        [-88.5277, 48.7195],
                        [-88.5168, 48.7128],
                        [-88.5259, 48.6998],
                        [-88.5433, 48.6945],
                        [-88.5507, 48.6886],
                        [-88.5561, 48.6775],
                        [-88.5422, 48.6678],
                        [-88.5518, 48.6628],
                        [-88.5516, 48.6535],
                        [-88.5407, 48.6461],
                        [-88.5466, 48.6315],
                        [-88.5627, 48.6318],
                        [-88.5653, 48.6262],
                        [-88.5753, 48.6263],
                        [-88.5871, 48.6184],
                        [-88.5924, 48.6219],
                        [-88.6157, 48.6216],
                        [-88.6305, 48.6034],
                        [-88.6289, 48.5929],
                        [-88.6221, 48.5846],
                        [-88.6259, 48.5767],
                        [-88.634, 48.5721],
                        [-88.64, 48.5441],
                        [-88.6336, 48.5412],
                        [-88.6354, 48.5351],
                        [-88.6498, 48.5204],
                        [-88.6366, 48.518],
                        [-88.6466, 48.5042],
                        [-88.6588, 48.4953],
                        [-88.6619, 48.488],
                        [-88.6732, 48.4868],
                        [-88.6726, 48.4936],
                        [-88.6823, 48.497],
                        [-88.6899, 48.4953],
                        [-88.6996, 48.487],
                        [-88.7021, 48.4795],
                        [-88.6984, 48.4744],
                        [-88.6829, 48.4657],
                        [-88.6915, 48.4567],
                        [-88.6967, 48.4466],
                        [-88.6902, 48.4422],
                        [-88.6987, 48.4336],
                        [-88.6969, 48.4292],
                        [-88.7181, 48.4027],
                        [-88.7155, 48.3978],
                        [-88.7232, 48.3861],
                        [-88.7185, 48.3801],
                        [-88.7277, 48.3776],
                        [-88.7397, 48.3697],
                        [-88.7495, 48.3576],
                        [-88.7471, 48.3498],
                        [-88.7556, 48.3452],
                        [-88.7693, 48.3482],
                        [-88.7701, 48.3401],
                        [-88.7895, 48.3327],
                        [-88.8048, 48.3366],
                        [-88.8194, 48.3299],
                        [-88.8329, 48.335],
                        [-88.8524, 48.3231],
                        [-88.8634, 48.3248],
                        [-88.8818, 48.3209],
                        [-88.8854, 48.3162],
                        [-88.8965, 48.3177],
                        [-88.905, 48.3234],
                        [-88.9101, 48.3223],
                        [-88.9311, 48.304],
                        [-88.9361, 48.3085],
                        [-88.9231, 48.3254],
                        [-88.9218, 48.3334],
                        [-88.9015, 48.3624],
                        [-88.8876, 48.3675],
                        [-88.8717, 48.3596],
                        [-88.8639, 48.3666],
                        [-88.873, 48.3754],
                        [-88.8494, 48.3996],
                        [-88.8618, 48.4053],
                        [-88.8561, 48.4185],
                        [-88.8288, 48.4515],
                        [-88.8373, 48.4566],
                        [-88.8378, 48.4616],
                        [-88.8281, 48.4743],
                        [-88.7954, 48.5036],
                        [-88.7811, 48.5179],
                        [-88.7681, 48.535],
                        [-88.7626, 48.547],
                        [-88.7527, 48.5588],
                        [-88.7586, 48.5673],
                        [-88.7717, 48.5748],
                        [-88.7935, 48.5716],
                        [-88.819, 48.5646],
                        [-88.8274, 48.5585],
                        [-88.8441, 48.5604],
                        [-88.8533, 48.5563],
                        [-88.8716, 48.5448],
                        [-88.892, 48.5423],
                        [-88.9053, 48.5432],
                        [-88.9227, 48.5352],
                        [-88.9268, 48.5281],
                        [-88.9216, 48.5237],
                        [-88.9288, 48.5192],
                        [-88.9328, 48.5258],
                        [-88.9426, 48.5262],
                        [-88.9587, 48.52],
                        [-88.9545, 48.5145],
                        [-88.9709, 48.5095],
                        [-88.9824, 48.51],
                        [-88.9923, 48.513],
                        [-89.0298, 48.5007],
                        [-89.045, 48.4986],
                        [-89.0643, 48.4912],
                        [-89.0815, 48.4894],
                        [-89.0996, 48.4848],
                        [-89.1024, 48.4877],
                        [-89.1183, 48.4872],
                        [-89.139, 48.482],
                        [-89.1503, 48.4773],
                        [-89.1667, 48.4667],
                        [-89.1694, 48.4544],
                        [-89.1776, 48.4544],
                        [-89.182, 48.4478],
                        [-89.1957, 48.4455],
                        [-89.2034, 48.4393],
                        [-89.2137, 48.4374],
                        [-89.224, 48.4249],
                        [-89.2175, 48.4215],
                        [-89.2197, 48.4119],
                        [-89.2165, 48.4095],
                        [-89.2171, 48.3933],
                        [-89.2092, 48.3789],
                        [-89.2143, 48.3714],
                        [-89.2167, 48.3577],
                        [-89.2065, 48.3354],
                        [-89.2019, 48.3314],
                        [-89.2088, 48.3195],
                        [-89.198, 48.3127],
                        [-89.1996, 48.3077],
                        [-89.2112, 48.3],
                        [-89.2209, 48.3042],
                        [-89.2356, 48.3066],
                        [-89.2389, 48.2998],
                        [-89.2361, 48.287],
                        [-89.2462, 48.2718],
                        [-89.2584, 48.2661],
                        [-89.2586, 48.2559],
                        [-89.2641, 48.246],
                        [-89.2727, 48.2375],
                        [-89.2671, 48.2307],
                        [-89.2742, 48.2051],
                        [-89.2672, 48.195],
                        [-89.2889, 48.1858],
                        [-89.3045, 48.1864],
                        [-89.2923, 48.1974],
                        [-89.2927, 48.2033],
                        [-89.3007, 48.2042],
                        [-89.3083, 48.2008],
                        [-89.3126, 48.1855],
                        [-89.3003, 48.1824],
                        [-89.2845, 48.1754],
                        [-89.297, 48.1662],
                        [-89.3113, 48.163],
                        [-89.3125, 48.1546],
                        [-89.3202, 48.1516],
                        [-89.3193, 48.146],
                        [-89.31, 48.1398],
                        [-89.3144, 48.128],
                        [-89.3253, 48.1288],
                        [-89.3423, 48.1262],
                        [-89.349, 48.1163],
                        [-89.3482, 48.1083],
                        [-89.3389, 48.1104],
                        [-89.3357, 48.106],
                        [-89.3467, 48.0944],
                        [-89.3573, 48.1006],
                        [-89.3662, 48.0985],
                        [-89.3756, 48.0909],
                        [-89.3948, 48.0871],
                        [-89.418, 48.0856],
                        [-89.4221, 48.0894],
                        [-89.432, 48.0889],
                        [-89.4431, 48.0833],
                        [-89.4409, 48.0779],
                        [-89.4617, 48.0628],
                        [-89.4387, 48.0665],
                        [-89.4236, 48.0728],
                        [-89.4048, 48.0691],
                        [-89.4536, 48.0608],
                        [-89.4634, 48.0569],
                        [-89.4808, 48.0542],
                        [-89.4785, 48.05],
                        [-89.4652, 48.0447],
                        [-89.4543, 48.0435],
                        [-89.4552, 48.0382],
                        [-89.4651, 48.0395],
                        [-89.4896, 48.0331],
                        [-89.4879, 48.039],
                        [-89.4982, 48.0439],
                        [-89.5093, 48.0419],
                        [-89.5152, 48.0308],
                        [-89.5091, 48.025],
                        [-89.5263, 48.0218],
                        [-89.5452, 48.0206],
                        [-89.5672, 48.0117],
                        [-89.5798, 48.0085],
                        [-89.5705, 48.0033],
                        [-89.5703, 47.9989],
                        [-89.5796, 47.9964],
                        [-89.5858, 48.0017],
                        [-89.6069, 48.0063],
                        [-89.6114, 48.0178],
                        [-89.618, 48.0112],
                        [-89.6375, 48.0092],
                        [-89.6496, 48.0037],
                        [-89.6577, 48.0102],
                        [-89.6662, 48.0076],
                        [-89.6738, 48.0117],
                        [-89.692, 48.01],
                        [-89.7013, 48.0065],
                        [-89.7092, 48.0104],
                        [-89.7166, 48.0101],
                        [-89.7247, 48.0189],
                        [-89.7418, 48.0199],
                        [-89.7492, 48.0234],
                        [-89.7667, 48.0228],
                        [-89.7828, 48.0178],
                        [-89.7915, 48.0184],
                        [-89.8064, 48.0143],
                        [-89.8207, 48.0152],
                        [-89.8314, 47.9998],
                        [-89.8425, 48.001],
                        [-89.8468, 47.9931],
                        [-89.8691, 47.9897],
                        [-89.8712, 47.9861],
                        [-89.8985, 47.988],
                        [-89.9281, 48.0024],
                        [-89.935, 48.0157],
                        [-89.9535, 48.0109],
                        [-89.9683, 48.0145],
                        [-89.9756, 48.0231],
                        [-89.994, 48.0282],
                        [-89.9973, 48.0352],
                        [-89.9938, 48.0492],
                        [-90, 48.0613],
                        [-90.0071, 48.0684],
                        [-90.0145, 48.0672],
                        [-90.0233, 48.0846],
                        [-90.0576, 48.0965],
                        [-90.0944, 48.1047],
                        [-90.1185, 48.1045],
                        [-90.1304, 48.1112],
                        [-90.1437, 48.113],
                        [-90.1663, 48.1101],
                        [-90.1765, 48.1123],
                        [-90.1888, 48.1087],
                        [-90.2098, 48.1066],
                        [-90.2264, 48.1087],
                        [-90.2542, 48.1025],
                        [-90.2738, 48.1033],
                        [-90.2916, 48.099],
                        [-90.3051, 48.1056],
                        [-90.3146, 48.1058],
                        [-90.3357, 48.101],
                        [-90.3449, 48.0945],
                        [-90.3673, 48.0949],
                        [-90.3744, 48.092],
                        [-90.3855, 48.0988],
                        [-90.4043, 48.1055],
                        [-90.4367, 48.0994],
                        [-90.4474, 48.1043],
                        [-90.4664, 48.1095],
                        [-90.488, 48.0995],
                        [-90.5102, 48.1002],
                        [-90.5527, 48.0965],
                        [-90.5597, 48.0971],
                        [-90.5685, 48.1027],
                        [-90.5695, 48.1074],
                        [-90.5556, 48.1173],
                        [-90.5598, 48.1221],
                        [-90.575, 48.1215],
                        [-90.582, 48.1241],
                        [-90.5923, 48.118],
                        [-90.6063, 48.1163],
                        [-90.6161, 48.1125],
                        [-90.6259, 48.1119],
                        [-90.6416, 48.1035],
                        [-90.6909, 48.0998],
                        [-90.7053, 48.0959],
                        [-90.7394, 48.0947],
                        [-90.7506, 48.0916],
                        [-90.7616, 48.0992],
                        [-90.7678, 48.1168],
                        [-90.7749, 48.1203],
                        [-90.7891, 48.132],
                        [-90.7896, 48.1443],
                        [-90.7779, 48.1484],
                        [-90.7759, 48.1609],
                        [-90.7814, 48.1644],
                        [-90.7895, 48.1608],
                        [-90.8005, 48.1633],
                        [-90.8035, 48.1765],
                        [-90.8168, 48.18],
                        [-90.8312, 48.1742],
                        [-90.8359, 48.1772],
                        [-90.8338, 48.1878],
                        [-90.8364, 48.2231],
                        [-90.8385, 48.2385],
                        [-90.8439, 48.2444],
                        [-90.8509, 48.2452],
                        [-90.8657, 48.2391],
                        [-90.8774, 48.2387],
                        [-90.8861, 48.2466],
                        [-90.9046, 48.2388],
                        [-90.9174, 48.2317],
                        [-90.9773, 48.2198],
                        [-91.024, 48.1911],
                        [-91.047, 48.1897],
                        [-91.0632, 48.1863],
                        [-91.0659, 48.1818],
                        [-91.0896, 48.1775],
                        [-91.0926, 48.1736],
                        [-91.1162, 48.1667],
                        [-91.12, 48.1615],
                        [-91.1427, 48.1553],
                        [-91.1375, 48.1498],
                        [-91.1561, 48.1412],
                        [-91.1787, 48.125],
                        [-91.1901, 48.1259],
                        [-91.2156, 48.1034],
                        [-91.2345, 48.097],
                        [-91.2512, 48.0836],
                        [-91.2682, 48.0793],
                        [-91.2755, 48.0796],
                        [-91.2915, 48.0744],
                        [-91.3096, 48.0731],
                        [-91.3228, 48.0739],
                        [-91.3365, 48.0698],
                        [-91.3383, 48.0742],
                        [-91.3492, 48.0729],
                        [-91.3652, 48.068],
                        [-91.3714, 48.0704],
                        [-91.3902, 48.0579],
                        [-91.4155, 48.054],
                        [-91.4323, 48.0495],
                        [-91.4382, 48.0531],
                        [-91.4505, 48.0697],
                        [-91.4673, 48.0672],
                        [-91.4891, 48.0691],
                        [-91.5447, 48.053],
                        [-91.5679, 48.044],
                        [-91.5755, 48.0502],
                        [-91.5728, 48.0581],
                        [-91.5755, 48.0682],
                        [-91.57, 48.0942],
                        [-91.5527, 48.104],
                        [-91.5598, 48.1094],
                        [-91.5759, 48.1074],
                        [-91.5896, 48.1031],
                        [-91.6159, 48.1025],
                        [-91.6408, 48.0975],
                        [-91.6569, 48.1139],
                        [-91.665, 48.1087],
                        [-91.678, 48.1077],
                        [-91.6876, 48.1129],
                        [-91.6916, 48.1182],
                        [-91.7036, 48.1144],
                        [-91.7117, 48.1185],
                        [-91.6993, 48.1342],
                        [-91.6981, 48.1447],
                        [-91.7039, 48.1464],
                        [-91.7079, 48.1535],
                        [-91.7049, 48.1586],
                        [-91.7054, 48.1706],
                        [-91.7094, 48.1732],
                        [-91.725, 48.1713],
                        [-91.7225, 48.1789],
                        [-91.7121, 48.1896],
                        [-91.7149, 48.1998],
                        [-91.7424, 48.2047],
                        [-91.7425, 48.1997],
                        [-91.7807, 48.2011],
                        [-91.79, 48.1967],
                        [-91.7923, 48.2034],
                        [-91.8153, 48.2085],
                        [-91.8158, 48.2125],
                        [-91.8337, 48.2098],
                        [-91.8645, 48.2076],
                        [-91.8678, 48.2195],
                        [-91.8848, 48.228],
                        [-91.8937, 48.2388],
                        [-91.9209, 48.2373],
                        [-91.9364, 48.2353],
                        [-91.942, 48.2312],
                        [-91.9595, 48.2345],
                        [-91.956, 48.2528],
                        [-91.9702, 48.2535],
                        [-91.976, 48.245],
                        [-91.9823, 48.2493],
                        [-91.9886, 48.2592],
                        [-91.9977, 48.2644],
                        [-92.0058, 48.2646],
                        [-92.0073, 48.28],
                        [-92.0123, 48.287],
                        [-92.0125, 48.2976],
                        [-92.0005, 48.3212],
                        [-92.0308, 48.3258],
                        [-92.0392, 48.333],
                        [-92.047, 48.3346],
                        [-92.0468, 48.3478],
                        [-92.0552, 48.3591],
                        [-92.0682, 48.3591],
                        [-92.083, 48.3536],
                        [-92.1432, 48.3556],
                        [-92.1451, 48.3655],
                        [-92.162, 48.3632],
                        [-92.1781, 48.3559],
                        [-92.1792, 48.3515],
                        [-92.1899, 48.3492],
                        [-92.2044, 48.3519],
                        [-92.2097, 48.3458],
                        [-92.2217, 48.3488],
                        [-92.2622, 48.3546],
                        [-92.2884, 48.3424],
                        [-92.2958, 48.3234],
                        [-92.3041, 48.3226],
                        [-92.3058, 48.3164],
                        [-92.2948, 48.3062],
                        [-92.3006, 48.2902],
                        [-92.2933, 48.2761],
                        [-92.2909, 48.2662],
                        [-92.2725, 48.255],
                        [-92.2695, 48.2476],
                        [-92.2795, 48.2449],
                        [-92.3134, 48.2406],
                        [-92.3544, 48.2296],
                        [-92.3699, 48.2213],
                        [-92.3773, 48.2285],
                        [-92.3881, 48.2469],
                        [-92.3865, 48.2506],
                        [-92.3939, 48.2579],
                        [-92.4162, 48.2965],
                        [-92.4258, 48.3045],
                        [-92.4374, 48.309],
                        [-92.4457, 48.3208],
                        [-92.4556, 48.3302],
                        [-92.47, 48.3529],
                        [-92.4767, 48.3704],
                        [-92.4566, 48.4003],
                        [-92.4564, 48.414],
                        [-92.4742, 48.4182],
                        [-92.483, 48.4282],
                        [-92.4887, 48.4299],
                        [-92.5076, 48.448],
                        [-92.5354, 48.4477],
                        [-92.5806, 48.44],
                        [-92.6551, 48.4361],
                        [-92.6597, 48.4382],
                        [-92.6901, 48.4452],
                        [-92.7126, 48.4628],
                        [-92.7092, 48.4737],
                        [-92.7013, 48.4853],
                        [-92.6988, 48.4948],
                        [-92.6854, 48.497],
                        [-92.6627, 48.4965],
                        [-92.6549, 48.5004],
                        [-92.6306, 48.5007],
                        [-92.6317, 48.5069],
                        [-92.6265, 48.5127],
                        [-92.6354, 48.5426],
                        [-92.6585, 48.5459],
                        [-92.7298, 48.5391],
                        [-92.7744, 48.5544],
                        [-92.8869, 48.5922],
                        [-92.9083, 48.5956],
                        [-92.9313, 48.6073],
                        [-92.9504, 48.608],
                        [-92.9499, 48.6299],
                        [-92.9555, 48.6312],
                        [-92.9831, 48.6239],
                        [-93.0922, 48.6278],
                        [-93.1786, 48.6232],
                        [-93.2058, 48.6424],
                        [-93.2536, 48.6427],
                        [-93.3473, 48.6265],
                        [-93.3534, 48.6127],
                        [-93.3621, 48.6132],
                        [-93.3671, 48.6069],
                        [-93.3966, 48.6034],
                        [-93.4058, 48.6104],
                        [-93.4257, 48.6015],
                        [-93.4392, 48.5938],
                        [-93.465, 48.5921],
                        [-93.4663, 48.5854],
                        [-93.4628, 48.5728],
                        [-93.458, 48.5661],
                        [-93.4586, 48.557],
                        [-93.4666, 48.5466],
                        [-93.4914, 48.5432],
                        [-93.5041, 48.54],
                        [-93.5156, 48.5347],
                        [-93.5315, 48.5333],
                        [-93.542, 48.5296],
                        [-93.5782, 48.527],
                        [-93.5941, 48.5299],
                        [-93.6069, 48.5228],
                        [-93.6168, 48.5234],
                        [-93.6287, 48.5314],
                        [-93.6368, 48.5271],
                        [-93.6411, 48.5201],
                        [-93.6472, 48.5174],
                        [-93.6908, 48.5151],
                        [-93.7098, 48.5179],
                        [-93.738, 48.5182],
                        [-93.7515, 48.5156],
                        [-93.764, 48.5166],
                        [-93.7942, 48.5162],
                        [-93.8116, 48.5246],
                        [-93.8188, 48.5333],
                        [-93.8115, 48.5393],
                        [-93.8116, 48.5503],
                        [-93.806, 48.5607],
                        [-93.8053, 48.5762],
                        [-93.8189, 48.6],
                        [-93.8228, 48.6112],
                        [-93.8304, 48.6181],
                        [-93.8343, 48.6258],
                        [-93.8428, 48.6293],
                        [-93.857, 48.6317],
                        [-93.8969, 48.6333],
                        [-93.9055, 48.635],
                        [-93.9186, 48.6334],
                        [-93.9487, 48.6344],
                        [-93.9592, 48.6373],
                        [-93.9907, 48.6403],
                        [-94.0054, 48.6435],
                        [-94.0299, 48.6415],
                        [-94.0428, 48.6437],
                        [-94.061, 48.6429],
                        [-94.0688, 48.6459],
                        [-94.0888, 48.6436],
                        [-94.0988, 48.6458],
                        [-94.1171, 48.644],
                        [-94.1509, 48.6449],
                        [-94.1814, 48.6495],
                        [-94.2031, 48.65],
                        [-94.215, 48.649],
                        [-94.244, 48.6524],
                        [-94.2538, 48.6586],
                        [-94.2514, 48.6818],
                        [-94.263, 48.6961],
                        [-94.274, 48.6991],
                        [-94.2857, 48.7055],
                        [-94.308, 48.7093],
                        [-94.3292, 48.7041],
                        [-94.3497, 48.7028],
                        [-94.365, 48.7049],
                        [-94.3816, 48.7101],
                        [-94.4156, 48.7101],
                        [-94.421, 48.7082],
                        [-94.4411, 48.6942],
                        [-94.4534, 48.6923],
                        [-94.4649, 48.6954],
                        [-94.4907, 48.6981],
                        [-94.5023, 48.7005],
                        [-94.536, 48.7045],
                        [-94.5455, 48.7147],
                        [-94.5533, 48.7172],
                        [-94.5802, 48.7177],
                        [-94.5874, 48.7196],
                        [-94.594, 48.727],
                        [-94.6074, 48.7322],
                        [-94.6148, 48.7376],
                        [-94.6405, 48.7434],
                        [-94.6498, 48.7577],
                        [-94.6748, 48.773],
                        [-94.6871, 48.7787],
                        [-94.6901, 48.79],
                        [-94.6902, 48.8067],
                        [-94.6988, 48.8262],
                        [-94.6864, 48.8321],
                        [-94.6764, 48.844],
                        [-94.6747, 48.8579],
                        [-94.6543, 48.864],
                        [-94.6362, 48.8808],
                        [-94.6376, 48.8879],
                        [-94.6277, 48.8974],
                        [-94.6295, 48.9054],
                        [-94.6131, 48.916],
                        [-94.6082, 48.9274],
                        [-94.5995, 48.9353],
                        [-94.595, 48.9433],
                        [-94.5886, 48.9456],
                        [-94.5798, 48.9541],
                        [-94.5655, 48.9503],
                        [-94.5567, 48.9547],
                        [-94.5491, 48.9524],
                        [-94.5323, 48.9583],
                        [-94.511, 48.9539],
                        [-94.5038, 48.954],
                        [-94.4936, 48.9616],
                        [-94.47, 48.9669],
                        [-94.4613, 48.9751],
                        [-94.4642, 48.9838],
                        [-94.4623, 48.9945],
                        [-94.4561, 49],
                        [-94.4453, 48.992],
                        [-94.4324, 48.9929],
                        [-94.4184, 48.9993],
                        [-94.4136, 49.0049],
                        [-94.4151, 49.0168],
                        [-94.404, 49.0185],
                        [-94.3976, 49.0242],
                        [-94.3993, 49.0298],
                        [-94.3893, 49.0357],
                        [-94.3851, 49.0339],
                        [-94.3639, 49.0384],
                        [-94.3608, 49.0414],
                        [-94.3606, 49.0553],
                        [-94.3506, 49.0591],
                        [-94.3411, 49.0751],
                        [-94.3112, 49.0707],
                        [-94.3281, 49.0822],
                        [-94.3175, 49.0866],
                        [-94.3195, 49.0945],
                        [-94.3292, 49.0928],
                        [-94.33, 49.0831],
                        [-94.3391, 49.089],
                        [-94.3353, 49.0956],
                        [-94.3402, 49.1044],
                        [-94.3354, 49.1124],
                        [-94.324, 49.1185],
                        [-94.3137, 49.1173],
                        [-94.3166, 49.1251],
                        [-94.3073, 49.1373],
                        [-94.2991, 49.1315],
                        [-94.2897, 49.1352],
                        [-94.2744, 49.1315],
                        [-94.2624, 49.125],
                        [-94.2511, 49.1254],
                        [-94.2475, 49.1377],
                        [-94.2396, 49.1243],
                        [-94.2318, 49.1209],
                        [-94.2321, 49.1136],
                        [-94.2271, 49.1106],
                        [-94.2343, 49.0997],
                        [-94.2227, 49.0965],
                        [-94.2148, 49.1024],
                        [-94.2081, 49.1017],
                        [-94.1906, 49.0954],
                        [-94.1942, 49.088],
                        [-94.1794, 49.0894],
                        [-94.1608, 49.0983],
                        [-94.1504, 49.0989],
                        [-94.136, 49.104],
                        [-94.1212, 49.1178],
                        [-94.1083, 49.1145],
                        [-94.1051, 49.11],
                        [-94.0911, 49.1072],
                        [-94.0812, 49.1007],
                        [-94.0685, 49.1044],
                        [-94.064, 49.0982],
                        [-94.0512, 49.0915],
                        [-94.0396, 49.0964],
                        [-94.0294, 49.096],
                        [-94.015, 49.089],
                        [-94.0127, 49.0846],
                        [-93.9986, 49.0811],
                        [-94.0025, 49.0693],
                        [-93.997, 49.0644],
                        [-94.0058, 49.0554],
                        [-93.9987, 49.0542],
                        [-93.9864, 49.0686],
                        [-93.9868, 49.0708],
                        [-93.9686, 49.0836],
                        [-93.9493, 49.0932],
                        [-93.9427, 49.1057],
                        [-93.9266, 49.121],
                        [-93.931, 49.1296],
                        [-93.9403, 49.1209],
                        [-93.9414, 49.1329],
                        [-93.9469, 49.1367],
                        [-93.9371, 49.1479],
                        [-93.9307, 49.1486],
                        [-93.9272, 49.1571],
                        [-93.9324, 49.1663],
                        [-93.9466, 49.1564],
                        [-93.9537, 49.1423],
                        [-93.9506, 49.1378],
                        [-93.9522, 49.1159],
                        [-93.9631, 49.1068],
                        [-93.9588, 49.0999],
                        [-93.9778, 49.1059],
                        [-93.9941, 49.103],
                        [-94.0081, 49.1047],
                        [-94.0187, 49.1167],
                        [-94.0376, 49.1135],
                        [-94.0174, 49.1069],
                        [-94.0351, 49.0992],
                        [-94.0541, 49.0979],
                        [-94.0659, 49.1037],
                        [-94.0561, 49.1124],
                        [-94.0798, 49.1149],
                        [-94.0895, 49.118],
                        [-94.1042, 49.1327],
                        [-94.0897, 49.1415],
                        [-94.0781, 49.1399],
                        [-94.0747, 49.1497],
                        [-94.069, 49.15],
                        [-94.0585, 49.1415],
                        [-94.0431, 49.1435],
                        [-94.0394, 49.1462],
                        [-94.0517, 49.1529],
                        [-94.0354, 49.1532],
                        [-94.0013, 49.1463],
                        [-93.9864, 49.145],
                        [-93.9829, 49.1421],
                        [-93.9618, 49.1598],
                        [-93.971, 49.1682],
                        [-93.9698, 49.1753],
                        [-93.9827, 49.1704],
                        [-93.9768, 49.16],
                        [-93.997, 49.1588],
                        [-94.004, 49.1559],
                        [-94.0148, 49.1596],
                        [-94.0326, 49.1626],
                        [-94.0494, 49.1614],
                        [-94.0717, 49.1716],
                        [-94.0632, 49.1758],
                        [-94.0432, 49.1789],
                        [-94.0219, 49.172],
                        [-94.0072, 49.1647],
                        [-94.0016, 49.1684],
                        [-94.0087, 49.1728],
                        [-93.9977, 49.1879],
                        [-93.9797, 49.1911],
                        [-93.9812, 49.183],
                        [-93.9735, 49.1799],
                        [-93.964, 49.1881],
                        [-93.9737, 49.1929],
                        [-93.989, 49.194],
                        [-94.0108, 49.1895],
                        [-94.0252, 49.1907],
                        [-94.0507, 49.1859],
                        [-94.0615, 49.186],
                        [-94.07, 49.183],
                        [-94.1241, 49.1804],
                        [-94.1378, 49.1819],
                        [-94.1561, 49.1817],
                        [-94.1755, 49.1788],
                        [-94.1881, 49.1789],
                        [-94.211, 49.1754],
                        [-94.2028, 49.1875],
                        [-94.2052, 49.1947],
                        [-94.1985, 49.2019],
                        [-94.2106, 49.2072],
                        [-94.2249, 49.1933],
                        [-94.232, 49.1915],
                        [-94.2504, 49.1995],
                        [-94.2605, 49.1993],
                        [-94.2696, 49.2086],
                        [-94.2627, 49.2278],
                        [-94.2713, 49.2328],
                        [-94.2633, 49.2419],
                        [-94.2793, 49.2444],
                        [-94.2846, 49.2318],
                        [-94.291, 49.2286],
                        [-94.307, 49.2328],
                        [-94.3057, 49.2365],
                        [-94.2917, 49.2394],
                        [-94.289, 49.255],
                        [-94.2915, 49.2606],
                        [-94.2997, 49.2631],
                        [-94.3141, 49.2628],
                        [-94.3197, 49.2681],
                        [-94.3157, 49.276],
                        [-94.3277, 49.2879],
                        [-94.3445, 49.2856],
                        [-94.3492, 49.2777],
                        [-94.3622, 49.2833],
                        [-94.3696, 49.2761],
                        [-94.3793, 49.2723],
                        [-94.3854, 49.261],
                        [-94.3966, 49.2687],
                        [-94.4172, 49.2621],
                        [-94.4135, 49.256],
                        [-94.4266, 49.2527],
                        [-94.4243, 49.2432],
                        [-94.435, 49.2374],
                        [-94.4423, 49.2461],
                        [-94.4452, 49.2344],
                        [-94.4608, 49.2297],
                        [-94.4739, 49.234],
                        [-94.4851, 49.2404],
                        [-94.4941, 49.2379],
                        [-94.5245, 49.2431],
                        [-94.5326, 49.249],
                        [-94.5322, 49.2535],
                        [-94.517, 49.2561],
                        [-94.5077, 49.2601],
                        [-94.5368, 49.2728],
                        [-94.5417, 49.2783],
                        [-94.5565, 49.2789],
                        [-94.5529, 49.2881],
                        [-94.5604, 49.2962],
                        [-94.5672, 49.2978],
                        [-94.5774, 49.2938],
                        [-94.5891, 49.2957],
                        [-94.5964, 49.3016],
                        [-94.5869, 49.3094],
                        [-94.6034, 49.3131],
                        [-94.6188, 49.3065],
                        [-94.6318, 49.3063],
                        [-94.6454, 49.3123],
                        [-94.6482, 49.3177],
                        [-94.6403, 49.3204],
                        [-94.6383, 49.3332],
                        [-94.6268, 49.337],
                        [-94.618, 49.336],
                        [-94.5999, 49.3401],
                        [-94.5987, 49.3357],
                        [-94.5661, 49.3334],
                        [-94.5715, 49.3418],
                        [-94.5858, 49.3422],
                        [-94.5807, 49.3503],
                        [-94.5701, 49.3516],
                        [-94.5842, 49.3631],
                        [-94.6028, 49.3654],
                        [-94.5991, 49.36],
                        [-94.6084, 49.3495],
                        [-94.6319, 49.3541],
                        [-94.6431, 49.3607],
                        [-94.6457, 49.3679],
                        [-94.665, 49.3653],
                        [-94.676, 49.3663],
                        [-94.6726, 49.3723],
                        [-94.6769, 49.3806],
                        [-94.6723, 49.3861],
                        [-94.6771, 49.3922],
                        [-94.6835, 49.3641],
                        [-94.6875, 49.3588],
                        [-94.6972, 49.3569],
                        [-94.7009, 49.365],
                        [-94.6974, 49.374],
                        [-94.6875, 49.3807],
                        [-94.6869, 49.3921],
                        [-94.6788, 49.3985],
                        [-94.6884, 49.4018],
                        [-94.6947, 49.4114],
                        [-94.7025, 49.4168],
                        [-94.6951, 49.4235],
                        [-94.6826, 49.4299],
                        [-94.6901, 49.4359],
                        [-94.7077, 49.4444],
                        [-94.7235, 49.4435],
                        [-94.7292, 49.4389],
                        [-94.7453, 49.4488],
                        [-94.7258, 49.4548],
                        [-94.7205, 49.4595],
                        [-94.7243, 49.4691],
                        [-94.717, 49.4757],
                        [-94.7008, 49.4787],
                        [-94.7157, 49.4839],
                        [-94.7145, 49.4895],
                        [-94.6982, 49.4967],
                        [-94.6632, 49.4968],
                        [-94.6646, 49.4911],
                        [-94.6821, 49.4871],
                        [-94.6947, 49.4792],
                        [-94.6754, 49.4772],
                        [-94.665, 49.4812],
                        [-94.6533, 49.4746],
                        [-94.655, 49.4627],
                        [-94.6443, 49.4678],
                        [-94.6347, 49.4674],
                        [-94.609, 49.454],
                        [-94.5919, 49.4635],
                        [-94.5782, 49.4616],
                        [-94.5684, 49.464],
                        [-94.5633, 49.4602],
                        [-94.5518, 49.467],
                        [-94.539, 49.4662],
                        [-94.5061, 49.4723],
                        [-94.4872, 49.4716],
                        [-94.441, 49.477],
                        [-94.4245, 49.4823],
                        [-94.4056, 49.4804],
                        [-94.4001, 49.4833],
                        [-94.3824, 49.48],
                        [-94.3641, 49.4801],
                        [-94.3506, 49.4858],
                        [-94.3355, 49.4861],
                        [-94.3313, 49.4818],
                        [-94.3169, 49.4924],
                        [-94.3108, 49.491],
                        [-94.3112, 49.4791],
                        [-94.3232, 49.4718],
                        [-94.322, 49.462],
                        [-94.3099, 49.4602],
                        [-94.2906, 49.46],
                        [-94.2698, 49.4495],
                        [-94.2815, 49.4357],
                        [-94.2785, 49.4291],
                        [-94.2925, 49.4276],
                        [-94.29, 49.4184],
                        [-94.284, 49.4154],
                        [-94.2735, 49.4183],
                        [-94.2694, 49.4135],
                        [-94.2782, 49.4081],
                        [-94.2967, 49.4028],
                        [-94.2984, 49.3953],
                        [-94.314, 49.3911],
                        [-94.3201, 49.3921],
                        [-94.3381, 49.3863],
                        [-94.3317, 49.3809],
                        [-94.3209, 49.3821],
                        [-94.3075, 49.3721],
                        [-94.3054, 49.3829],
                        [-94.2892, 49.3816],
                        [-94.2871, 49.3919],
                        [-94.277, 49.3947],
                        [-94.2833, 49.4011],
                        [-94.2563, 49.4001],
                        [-94.2485, 49.4027],
                        [-94.2387, 49.4158],
                        [-94.2312, 49.4203],
                        [-94.2163, 49.4222],
                        [-94.219, 49.4166],
                        [-94.2051, 49.406],
                        [-94.2024, 49.4],
                        [-94.2052, 49.3832],
                        [-94.2228, 49.3847],
                        [-94.2353, 49.3921],
                        [-94.2445, 49.3932],
                        [-94.2476, 49.3889],
                        [-94.2245, 49.3839],
                        [-94.2111, 49.3827],
                        [-94.205, 49.3784],
                        [-94.2043, 49.3725],
                        [-94.2236, 49.3686],
                        [-94.225, 49.3642],
                        [-94.2484, 49.3647],
                        [-94.2424, 49.3603],
                        [-94.2243, 49.3628],
                        [-94.2158, 49.357],
                        [-94.2168, 49.3491],
                        [-94.2228, 49.3473],
                        [-94.2224, 49.3389],
                        [-94.2301, 49.3314],
                        [-94.2141, 49.3237],
                        [-94.2118, 49.3176],
                        [-94.2012, 49.3098],
                        [-94.1908, 49.3166],
                        [-94.1672, 49.3158],
                        [-94.1638, 49.3089],
                        [-94.154, 49.3057],
                        [-94.1419, 49.2971],
                        [-94.1176, 49.2955],
                        [-94.1125, 49.2922],
                        [-94.1089, 49.2818],
                        [-94.101, 49.2888],
                        [-94.0943, 49.2876],
                        [-94.0824, 49.2792],
                        [-94.0745, 49.2802],
                        [-94.062, 49.2622],
                        [-94.0668, 49.2554],
                        [-94.0764, 49.2482],
                        [-94.0662, 49.2472],
                        [-94.069, 49.2393],
                        [-94.0924, 49.2509],
                        [-94.0958, 49.2472],
                        [-94.0763, 49.2411],
                        [-94.0743, 49.2349],
                        [-94.0931, 49.2347],
                        [-94.0935, 49.2315],
                        [-94.1081, 49.2276],
                        [-94.1206, 49.2283],
                        [-94.1215, 49.2237],
                        [-94.1148, 49.2173],
                        [-94.1394, 49.2119],
                        [-94.1381, 49.2045],
                        [-94.1255, 49.1975],
                        [-94.116, 49.1983],
                        [-94.1023, 49.205],
                        [-94.099, 49.211],
                        [-94.0894, 49.2158],
                        [-94.0949, 49.2222],
                        [-94.0669, 49.2314],
                        [-94.0671, 49.2339],
                        [-94.0421, 49.2433],
                        [-94.05, 49.2496],
                        [-94.0379, 49.2532],
                        [-94.0441, 49.2598],
                        [-94.0371, 49.2656],
                        [-94.0338, 49.2754],
                        [-94.0358, 49.2951],
                        [-94.0386, 49.3009],
                        [-94.0346, 49.3098],
                        [-94.0379, 49.3307],
                        [-94.0545, 49.3364],
                        [-94.0597, 49.3433],
                        [-94.0542, 49.355],
                        [-94.062, 49.3577],
                        [-94.0576, 49.3702],
                        [-94.0463, 49.3679],
                        [-94.0373, 49.356],
                        [-94.034, 49.3453],
                        [-94.0291, 49.3394],
                        [-94.0196, 49.3413],
                        [-94.0112, 49.3387],
                        [-94.0076, 49.3459],
                        [-93.9981, 49.3508],
                        [-93.9946, 49.3432],
                        [-94, 49.3339],
                        [-94.0112, 49.3296],
                        [-94.018, 49.3373],
                        [-94.0265, 49.3342],
                        [-94.0216, 49.3289],
                        [-94.0061, 49.3257],
                        [-93.9974, 49.3293],
                        [-93.9821, 49.3395],
                        [-93.9819, 49.3448],
                        [-93.9968, 49.3512],
                        [-93.9995, 49.3614],
                        [-94.0104, 49.3686],
                        [-94.0137, 49.3772],
                        [-94.0277, 49.3741],
                        [-94.0399, 49.3835],
                        [-94.0664, 49.3901],
                        [-94.0728, 49.3765],
                        [-94.0787, 49.3749],
                        [-94.091, 49.3814],
                        [-94.1065, 49.3931],
                        [-94.1184, 49.3946],
                        [-94.1184, 49.4013],
                        [-94.1071, 49.4046],
                        [-94.1053, 49.4115],
                        [-94.1093, 49.4177],
                        [-94.1188, 49.4187],
                        [-94.1401, 49.4149],
                        [-94.1496, 49.4181],
                        [-94.1621, 49.4258],
                        [-94.1575, 49.4316],
                        [-94.1559, 49.4406],
                        [-94.1728, 49.4369],
                        [-94.1797, 49.4372],
                        [-94.1904, 49.4442],
                        [-94.1879, 49.4479],
                        [-94.1973, 49.453],
                        [-94.1967, 49.4604],
                        [-94.1895, 49.4679],
                        [-94.1617, 49.4634],
                        [-94.1482, 49.4578],
                        [-94.1242, 49.4561],
                        [-94.1138, 49.4508],
                        [-94.091, 49.4453],
                        [-94.0764, 49.4444],
                        [-94.0723, 49.4352],
                        [-94.0599, 49.4303],
                        [-94.0397, 49.4321],
                        [-94.0316, 49.4308],
                        [-94.0299, 49.4379],
                        [-94.0063, 49.4388],
                        [-94.0214, 49.4483],
                        [-94.0351, 49.454],
                        [-94.042, 49.4522],
                        [-94.048, 49.4567],
                        [-94.0752, 49.4563],
                        [-94.0977, 49.4596],
                        [-94.1069, 49.464],
                        [-94.1176, 49.4659],
                        [-94.1198, 49.4567],
                        [-94.1478, 49.4627],
                        [-94.147, 49.466],
                        [-94.177, 49.4701],
                        [-94.1933, 49.4796],
                        [-94.2048, 49.4824],
                        [-94.2084, 49.4937],
                        [-94.2269, 49.481],
                        [-94.2617, 49.4866],
                        [-94.2547, 49.4931],
                        [-94.2459, 49.4904],
                        [-94.2424, 49.4975],
                        [-94.2258, 49.4992],
                        [-94.2305, 49.5079],
                        [-94.2424, 49.5059],
                        [-94.2458, 49.5119],
                        [-94.2188, 49.5185],
                        [-94.2236, 49.5219],
                        [-94.2496, 49.5151],
                        [-94.2609, 49.5145],
                        [-94.268, 49.5111],
                        [-94.2848, 49.508],
                        [-94.2987, 49.5089],
                        [-94.3048, 49.5151],
                        [-94.3129, 49.5127],
                        [-94.3225, 49.5151],
                        [-94.3341, 49.5143],
                        [-94.3299, 49.5313],
                        [-94.3179, 49.5361],
                        [-94.3287, 49.5428],
                        [-94.3211, 49.5515],
                        [-94.3239, 49.5551],
                        [-94.3361, 49.553],
                        [-94.3493, 49.5539],
                        [-94.3611, 49.5595],
                        [-94.3653, 49.5656],
                        [-94.381, 49.5669],
                        [-94.3875, 49.5729],
                        [-94.3873, 49.5804],
                        [-94.3947, 49.585],
                        [-94.4142, 49.587],
                        [-94.4313, 49.5959],
                        [-94.4228, 49.5969],
                        [-94.423, 49.6037],
                        [-94.416, 49.6076],
                        [-94.4079, 49.6049],
                        [-94.3939, 49.6053],
                        [-94.3825, 49.5978],
                        [-94.3867, 49.5881],
                        [-94.3814, 49.5776],
                        [-94.3736, 49.5834],
                        [-94.3786, 49.5894],
                        [-94.3787, 49.5957],
                        [-94.3723, 49.5973],
                        [-94.3636, 49.586],
                        [-94.3518, 49.5949],
                        [-94.3392, 49.5869],
                        [-94.3332, 49.5907],
                        [-94.3246, 49.5846],
                        [-94.3082, 49.5897],
                        [-94.2968, 49.5869],
                        [-94.2809, 49.5882],
                        [-94.2672, 49.5863],
                        [-94.2431, 49.5893],
                        [-94.264, 49.5968],
                        [-94.2813, 49.5932],
                        [-94.3024, 49.5946],
                        [-94.3039, 49.6002],
                        [-94.2861, 49.6059],
                        [-94.3106, 49.6091],
                        [-94.3178, 49.614],
                        [-94.3378, 49.6125],
                        [-94.3472, 49.6136],
                        [-94.3377, 49.6219],
                        [-94.354, 49.6254],
                        [-94.3683, 49.6148],
                        [-94.3936, 49.6201],
                        [-94.3943, 49.6299],
                        [-94.3761, 49.6281],
                        [-94.3608, 49.6339],
                        [-94.3505, 49.628],
                        [-94.3441, 49.6311],
                        [-94.3328, 49.6267],
                        [-94.3039, 49.6204],
                        [-94.3101, 49.6266],
                        [-94.309, 49.6318],
                        [-94.3137, 49.6479],
                        [-94.3076, 49.6599],
                        [-94.2979, 49.6672],
                        [-94.3011, 49.676],
                        [-94.3119, 49.6736],
                        [-94.3083, 49.6682],
                        [-94.3194, 49.6662],
                        [-94.3179, 49.6738],
                        [-94.3088, 49.6788],
                        [-94.3167, 49.6834],
                        [-94.3286, 49.6798],
                        [-94.3332, 49.6864],
                        [-94.3441, 49.6839],
                        [-94.356, 49.6909],
                        [-94.3611, 49.691],
                        [-94.3618, 49.7015],
                        [-94.3746, 49.7021],
                        [-94.3722, 49.7098],
                        [-94.358, 49.7245],
                        [-94.36, 49.7284],
                        [-94.3785, 49.7248],
                        [-94.389, 49.7244],
                        [-94.3979, 49.7186],
                        [-94.4103, 49.7246],
                        [-94.4184, 49.7196],
                        [-94.4289, 49.7179],
                        [-94.4449, 49.7029],
                        [-94.4471, 49.7137],
                        [-94.4395, 49.7177],
                        [-94.4469, 49.7234],
                        [-94.4525, 49.7323],
                        [-94.4721, 49.724],
                        [-94.4813, 49.7309],
                        [-94.5081, 49.723],
                        [-94.5176, 49.719],
                        [-94.5224, 49.7217],
                        [-94.5167, 49.7294],
                        [-94.4885, 49.7441],
                        [-94.4913, 49.7569],
                        [-94.4884, 49.7628],
                        [-94.4979, 49.7662],
                        [-94.5082, 49.7643],
                        [-94.51, 49.7697],
                        [-94.5237, 49.7729],
                        [-94.5249, 49.7769],
                        [-94.4983, 49.7757],
                        [-94.499, 49.7825],
                        [-94.5044, 49.788],
                        [-94.5233, 49.7965],
                        [-94.5125, 49.8037],
                        [-94.5176, 49.8139],
                        [-94.5232, 49.8191],
                        [-94.5346, 49.8224],
                        [-94.5445, 49.8178],
                        [-94.552, 49.8257],
                        [-94.5481, 49.8304],
                        [-94.5516, 49.8387],
                        [-94.5495, 49.848],
                        [-94.5603, 49.8484],
                        [-94.5675, 49.8454],
                        [-94.5752, 49.8499],
                        [-94.5774, 49.8642],
                        [-94.5608, 49.8688],
                        [-94.5527, 49.8665],
                        [-94.5425, 49.8742],
                        [-94.5491, 49.9009],
                        [-94.5404, 49.9104],
                        [-94.5463, 49.9147],
                        [-94.5366, 49.927],
                        [-94.5439, 49.9393],
                        [-94.5521, 49.94],
                        [-94.5645, 49.9519],
                        [-94.5571, 49.9564],
                        [-94.5583, 49.9656],
                        [-94.5453, 49.9766],
                        [-94.5179, 49.9775],
                        [-94.5091, 49.9828],
                        [-94.5014, 49.9812],
                        [-94.4923, 49.9842],
                        [-94.4891, 49.9931],
                        [-94.5239, 49.9872],
                        [-94.5361, 49.9798],
                        [-94.5586, 49.9763],
                        [-94.5589, 49.9657],
                        [-94.5674, 49.9556],
                        [-94.565, 49.945],
                        [-94.5578, 49.9392],
                        [-94.5585, 49.9334],
                        [-94.5667, 49.9325],
                        [-94.5887, 49.9453],
                        [-94.5849, 49.9537],
                        [-94.5857, 49.9713],
                        [-94.5946, 49.9697],
                        [-94.6087, 49.9735],
                        [-94.6062, 49.9851],
                        [-94.6143, 49.9866],
                        [-94.6135, 49.9784],
                        [-94.6256, 49.9757],
                        [-94.6293, 49.9799],
                        [-94.6396, 49.9805],
                        [-94.6409, 49.9854],
                        [-94.6516, 49.99],
                        [-94.6685, 49.9855],
                        [-94.6663, 49.9757],
                        [-94.6731, 49.9737],
                        [-94.6706, 49.9646],
                        [-94.6837, 49.9592],
                        [-94.6831, 49.9521],
                        [-94.6876, 49.9446],
                        [-94.6799, 49.9391],
                        [-94.6902, 49.9355],
                        [-94.7046, 49.9412],
                        [-94.6976, 49.9301],
                        [-94.7026, 49.9245],
                        [-94.6934, 49.9227],
                        [-94.6886, 49.9179],
                        [-94.6899, 49.9115],
                        [-94.6753, 49.9132],
                        [-94.666, 49.9086],
                        [-94.6612, 49.914],
                        [-94.6478, 49.9148],
                        [-94.6361, 49.9069],
                        [-94.6311, 49.8907],
                        [-94.6345, 49.868],
                        [-94.6244, 49.8672],
                        [-94.6171, 49.8779],
                        [-94.6074, 49.8804],
                        [-94.5895, 49.8745],
                        [-94.5868, 49.8678],
                        [-94.591, 49.8558],
                        [-94.5964, 49.8485],
                        [-94.6469, 49.8327],
                        [-94.6499, 49.8266],
                        [-94.6581, 49.8226],
                        [-94.67, 49.8208],
                        [-94.6732, 49.8156],
                        [-94.6836, 49.8195],
                        [-94.6911, 49.8048],
                        [-94.6777, 49.8023],
                        [-94.6674, 49.8045],
                        [-94.6708, 49.8116],
                        [-94.6631, 49.8179],
                        [-94.6378, 49.8316],
                        [-94.6294, 49.8298],
                        [-94.6226, 49.834],
                        [-94.5968, 49.8393],
                        [-94.5839, 49.8346],
                        [-94.5825, 49.8287],
                        [-94.5707, 49.8263],
                        [-94.5757, 49.8153],
                        [-94.5754, 49.8053],
                        [-94.5709, 49.7914],
                        [-94.5567, 49.7818],
                        [-94.5486, 49.7804],
                        [-94.5412, 49.7862],
                        [-94.5219, 49.7849],
                        [-94.5255, 49.7772],
                        [-94.5869, 49.7648],
                        [-94.5885, 49.7609],
                        [-94.6008, 49.7604],
                        [-94.6053, 49.7676],
                        [-94.5997, 49.7777],
                        [-94.5947, 49.7809],
                        [-94.6052, 49.7825],
                        [-94.5969, 49.7894],
                        [-94.5987, 49.8012],
                        [-94.6074, 49.7941],
                        [-94.617, 49.7962],
                        [-94.6307, 49.7886],
                        [-94.6368, 49.7924],
                        [-94.6391, 49.7779],
                        [-94.6456, 49.7736],
                        [-94.6669, 49.767],
                        [-94.6635, 49.7632],
                        [-94.6385, 49.7752],
                        [-94.629, 49.7881],
                        [-94.6174, 49.7955],
                        [-94.6105, 49.7947],
                        [-94.6061, 49.7855],
                        [-94.6117, 49.7801],
                        [-94.6041, 49.7815],
                        [-94.6004, 49.7777],
                        [-94.6169, 49.7721],
                        [-94.6304, 49.7701],
                        [-94.6074, 49.7645],
                        [-94.5989, 49.7568],
                        [-94.5902, 49.7551],
                        [-94.5837, 49.7612],
                        [-94.5716, 49.7625],
                        [-94.542, 49.7684],
                        [-94.5188, 49.771],
                        [-94.5103, 49.7685],
                        [-94.5236, 49.7614],
                        [-94.5557, 49.7559],
                        [-94.5697, 49.7513],
                        [-94.5724, 49.7397],
                        [-94.579, 49.7344],
                        [-94.5679, 49.7322],
                        [-94.5674, 49.7241],
                        [-94.5611, 49.7227],
                        [-94.5562, 49.715],
                        [-94.5331, 49.7098],
                        [-94.5152, 49.701],
                        [-94.5276, 49.6965],
                        [-94.543, 49.696],
                        [-94.5476, 49.7017],
                        [-94.5554, 49.6961],
                        [-94.5619, 49.6843],
                        [-94.5511, 49.6794],
                        [-94.5401, 49.6835],
                        [-94.5236, 49.6929],
                        [-94.52, 49.6819],
                        [-94.5297, 49.6748],
                        [-94.539, 49.6789],
                        [-94.5597, 49.6667],
                        [-94.5742, 49.6664],
                        [-94.5904, 49.6555],
                        [-94.6035, 49.6543],
                        [-94.6196, 49.6553],
                        [-94.6529, 49.6539],
                        [-94.6657, 49.6557],
                        [-94.6561, 49.6677],
                        [-94.658, 49.6718],
                        [-94.6376, 49.6876],
                        [-94.6471, 49.6906],
                        [-94.6628, 49.6886],
                        [-94.6683, 49.6959],
                        [-94.663, 49.7009],
                        [-94.6472, 49.6956],
                        [-94.6373, 49.6953],
                        [-94.6323, 49.7012],
                        [-94.6232, 49.7012],
                        [-94.6235, 49.6953],
                        [-94.6162, 49.6935],
                        [-94.6074, 49.6975],
                        [-94.5879, 49.6947],
                        [-94.5768, 49.711],
                        [-94.583, 49.7133],
                        [-94.5938, 49.712],
                        [-94.6052, 49.7049],
                        [-94.6119, 49.7035],
                        [-94.6278, 49.7055],
                        [-94.6496, 49.7121],
                        [-94.6558, 49.7187],
                        [-94.6453, 49.7202],
                        [-94.6519, 49.726],
                        [-94.6611, 49.722],
                        [-94.6566, 49.7161],
                        [-94.6683, 49.7157],
                        [-94.6766, 49.7117],
                        [-94.6963, 49.7145],
                        [-94.74, 49.7098],
                        [-94.7439, 49.714],
                        [-94.7897, 49.699],
                        [-94.7855, 49.7063],
                        [-94.7868, 49.7123],
                        [-94.8097, 49.7097],
                        [-94.8263, 49.7],
                        [-94.8269, 49.6944],
                        [-94.8459, 49.6928],
                        [-94.8463, 49.6896],
                        [-94.8681, 49.6914],
                        [-94.8692, 49.6868],
                        [-94.8501, 49.6853],
                        [-94.8483, 49.677],
                        [-94.8565, 49.6685],
                        [-94.8771, 49.6647],
                        [-94.8943, 49.6708],
                        [-94.9025, 49.667],
                        [-94.9249, 49.6617],
                        [-94.9171, 49.6576],
                        [-94.8972, 49.6616],
                        [-94.877, 49.6608],
                        [-94.8694, 49.6635],
                        [-94.8574, 49.6636],
                        [-94.8728, 49.6536],
                        [-94.8923, 49.6543],
                        [-94.9126, 49.6528],
                        [-94.9222, 49.6496],
                        [-94.9314, 49.6423],
                        [-94.9166, 49.633],
                        [-94.9087, 49.6332],
                        [-94.9045, 49.6384],
                        [-94.8921, 49.6454],
                        [-94.8639, 49.6461],
                        [-94.8542, 49.6496],
                        [-94.8497, 49.6586],
                        [-94.8402, 49.6596],
                        [-94.8357, 49.6538],
                        [-94.8421, 49.6466],
                        [-94.8281, 49.6466],
                        [-94.8167, 49.6496],
                        [-94.8079, 49.6485],
                        [-94.8136, 49.6366],
                        [-94.8055, 49.6358],
                        [-94.7943, 49.6385],
                        [-94.7837, 49.6361],
                        [-94.7829, 49.6235],
                        [-94.7761, 49.6213],
                        [-94.7881, 49.6146],
                        [-94.8081, 49.6136],
                        [-94.8163, 49.616],
                        [-94.8218, 49.6125],
                        [-94.8357, 49.6094],
                        [-94.8435, 49.6033],
                        [-94.8448, 49.5982],
                        [-94.8528, 49.5963],
                        [-94.8511, 49.5858],
                        [-94.8641, 49.5759],
                        [-94.8771, 49.5686],
                        [-94.8925, 49.5706],
                        [-94.9041, 49.5654],
                        [-94.91, 49.5677],
                        [-94.9172, 49.5622],
                        [-94.9271, 49.5595],
                        [-94.9335, 49.5623],
                        [-94.9402, 49.5573],
                        [-94.9449, 49.5684],
                        [-94.959, 49.5642],
                        [-94.9703, 49.5631],
                        [-94.9773, 49.571],
                        [-94.9947, 49.5731],
                        [-94.9945, 49.5786],
                        [-95.0011, 49.586],
                        [-94.983, 49.6107],
                        [-94.9712, 49.6107],
                        [-94.9784, 49.5972],
                        [-94.9744, 49.5856],
                        [-94.9642, 49.5936],
                        [-94.9473, 49.599],
                        [-94.9619, 49.602],
                        [-94.9638, 49.6079],
                        [-94.9557, 49.6239],
                        [-94.9599, 49.6269],
                        [-94.975, 49.6235],
                        [-94.9852, 49.6293],
                        [-94.9944, 49.6273],
                        [-94.9971, 49.6353],
                        [-95.0135, 49.6343],
                        [-95.0162, 49.6402],
                        [-95.0347, 49.6349],
                        [-95.0458, 49.6364],
                        [-95.0693, 49.6241],
                        [-95.0711, 49.6173],
                        [-95.0911, 49.6191],
                        [-95.0999, 49.6183],
                        [-95.0986, 49.6123],
                        [-95.1112, 49.6145],
                        [-95.1182, 49.6184],
                        [-95.0959, 49.6275],
                        [-95.1057, 49.6303],
                        [-95.1075, 49.6387],
                        [-95.1044, 49.6492],
                        [-95.1147, 49.6521],
                        [-95.1344, 49.6527],
                        [-95.1421, 49.6453],
                        [-95.1534, 49.6453],
                        [-95.1529, 49.7954],
                        [-95.1529, 49.8866],
                        [-95.1529, 50.0038],
                        [-95.1529, 50.1198],
                        [-95.1529, 50.2606],
                        [-95.1529, 50.3695],
                        [-95.1529, 50.5094],
                        [-95.1529, 50.6371],
                        [-95.153, 50.7501],
                        [-95.153, 50.9144],
                        [-95.153, 51.1198],
                        [-95.153, 51.2501],
                        [-95.153, 51.4257],
                        [-95.153, 51.5548],
                        [-95.153, 51.7068],
                        [-95.1531, 51.8704],
                        [-95.1531, 51.9915],
                        [-95.1531, 52.0961],
                        [-95.1531, 52.2557],
                        [-95.1531, 52.3813],
                        [-95.1531, 52.5157],
                        [-95.1531, 52.6303],
                        [-95.1531, 52.7254],
                        [-95.1532, 52.8406],
                        [-95.0036, 52.933],
                        [-94.8984, 52.9976],
                        [-94.7372, 53.0959],
                        [-94.642, 53.1535],
                        [-94.4261, 53.2838],
                        [-94.2627, 53.3814],
                        [-94.0538, 53.5],
                        [-93.9549, 53.5559],
                        [-93.8233, 53.6345],
                        [-93.7028, 53.706],
                        [-93.6299, 53.7501],
                        [-93.5205, 53.8315],
                        [-93.3292, 53.9697],
                        [-93.17, 54.0895],
                        [-93.0917, 54.146],
                        [-92.9276, 54.2637],
                        [-92.7417, 54.395],
                        [-92.6586, 54.4534],
                        [-92.5, 54.5629],
                        [-92.3841, 54.6423],
                        [-92.1888, 54.7749],
                        [-92, 54.906],
                        [-91.8701, 54.9998],
                        [-91.7476, 55.0851],
                        [-91.6478, 55.1585],
                        [-91.44, 55.2992],
                        [-91.3461, 55.362],
                        [-91.1633, 55.4835],
                        [-91, 55.5912],
                        [-90.8023, 55.7204],
                        [-90.704, 55.7838],
                        [-90.5532, 55.8804],
                        [-90.3112, 56.0342],
                        [-90.1529, 56.1338],
                        [-89.9672, 56.25],
                        [-89.8094, 56.348],
                        [-89.6442, 56.4498],
                        [-89.458, 56.5636],
                        [-89.3059, 56.6557],
                        [-89.149, 56.7501],
                        [-88.9865, 56.847],
                        [-88.9451, 56.8436],
                        [-88.9182, 56.8463],
                        [-88.8983, 56.8571],
                        [-88.8905, 56.859],
                        [-88.8754, 56.8574],
                        [-88.8661, 56.8478],
                        [-88.8684, 56.835],
                        [-88.8582, 56.8315],
                        [-88.8518, 56.8375],
                        [-88.8546, 56.8425],
                        [-88.8362, 56.8416],
                        [-88.8192, 56.8359],
                        [-88.8147, 56.8275],
                        [-88.8169, 56.8223],
                        [-88.8354, 56.8172],
                        [-88.8383, 56.8098],
                        [-88.8307, 56.7988],
                        [-88.8127, 56.7881],
                        [-88.7503, 56.763],
                        [-88.6963, 56.737],
                        [-88.6876, 56.729],
                        [-88.6832, 56.7142],
                        [-88.6732, 56.7056],
                        [-88.6604, 56.6987],
                        [-88.6068, 56.6782],
                        [-88.5546, 56.6611],
                        [-88.5163, 56.6575],
                        [-88.4877, 56.6467],
                        [-88.4753, 56.6381],
                        [-88.4542, 56.6172],
                        [-88.4393, 56.6078],
                        [-88.3656, 56.573],
                        [-88.2625, 56.5367],
                        [-88.2488, 56.5337],
                        [-88.2199, 56.5219],
                        [-88.1481, 56.5116],
                        [-88.0816, 56.4981],
                        [-88.0467, 56.4879],
                        [-88.0312, 56.4802],
                        [-88, 56.4711],
                        [-87.987, 56.4693],
                        [-87.9658, 56.457],
                        [-87.957, 56.4454],
                        [-87.9522, 56.4346],
                        [-87.9522, 56.4211],
                        [-87.9483, 56.4129],
                        [-87.9252, 56.3896],
                        [-87.8806, 56.3657],
                        [-87.8735, 56.3606],
                        [-87.8643, 56.3472],
                        [-87.853, 56.3382],
                        [-87.8383, 56.3219],
                        [-87.8201, 56.3148],
                        [-87.8089, 56.3017],
                        [-87.8026, 56.2981],
                        [-87.7889, 56.2809],
                        [-87.7811, 56.2768],
                        [-87.7781, 56.2685],
                        [-87.7635, 56.2595],
                        [-87.7574, 56.2522],
                        [-87.7299, 56.2359],
                        [-87.7209, 56.226],
                        [-87.7238, 56.2186],
                        [-87.7141, 56.2091],
                        [-87.7107, 56.1979],
                        [-87.6942, 56.1733],
                        [-87.6807, 56.1585],
                        [-87.6688, 56.1479],
                        [-87.6664, 56.1428],
                        [-87.6399, 56.1188],
                        [-87.6337, 56.1087],
                        [-87.6352, 56.1026],
                        [-87.6263, 56.0939],
                        [-87.5906, 56.0812],
                        [-87.5687, 56.0677],
                        [-87.5391, 56.0588],
                        [-87.5322, 56.0598],
                        [-87.5091, 56.0548],
                        [-87.4997, 56.0495],
                        [-87.4781, 56.0411],
                        [-87.4661, 56.0297],
                        [-87.4784, 56.023],
                        [-87.4779, 56.0164],
                        [-87.4496, 56.0054],
                        [-87.4448, 56.0055],
                        [-87.4277, 55.9979],
                        [-87.3897, 55.9909],
                        [-87.3587, 55.9741],
                        [-87.3646, 55.9602],
                        [-87.3454, 55.9632],
                        [-87.3361, 55.9667],
                        [-87.316, 55.9562],
                        [-87.2545, 55.9424],
                        [-87.2386, 55.9398],
                        [-87.2158, 55.9389],
                        [-87.2056, 55.9346],
                        [-87.1647, 55.9328],
                        [-87.1179, 55.9264],
                        [-87.0861, 55.9268],
                        [-87.0662, 55.9248],
                        [-87.0339, 55.9239],
                        [-87.0186, 55.9191],
                        [-87.0011, 55.9202],
                        [-86.9795, 55.9193],
                        [-86.9663, 55.9149],
                        [-86.9382, 55.9165],
                        [-86.9019, 55.9143],
                        [-86.8848, 55.9055],
                        [-86.8639, 55.898],
                        [-86.8439, 55.896],
                        [-86.8183, 55.8855],
                        [-86.7919, 55.879],
                        [-86.7848, 55.8754],
                        [-86.7627, 55.8722],
                        [-86.7217, 55.8536],
                        [-86.6908, 55.8445],
                        [-86.6862, 55.8418],
                        [-86.6434, 55.8344],
                        [-86.606, 55.8298],
                        [-86.582, 55.8228],
                        [-86.556, 55.8113],
                        [-86.5276, 55.804],
                        [-86.5114, 55.8019],
                        [-86.4704, 55.7926],
                        [-86.4606, 55.7886],
                        [-86.372, 55.7647],
                        [-86.3387, 55.7466],
                        [-86.3333, 55.7404],
                        [-86.2925, 55.719],
                        [-86.26, 55.7072],
                        [-86.2363, 55.7034],
                        [-86.1794, 55.6959],
                        [-86.1693, 55.6927],
                        [-86.13, 55.6896],
                        [-86.0992, 55.6801],
                        [-86.066, 55.6753],
                        [-86.0519, 55.6686],
                        [-86.0351, 55.6724],
                        [-86.0092, 55.6726],
                        [-85.9892, 55.6672],
                        [-85.9634, 55.6664],
                        [-85.9362, 55.6618],
                        [-85.9198, 55.6573],
                        [-85.9028, 55.6554],
                        [-85.8845, 55.6431],
                        [-85.8639, 55.6338],
                        [-85.86, 55.6265],
                        [-85.8334, 55.6247],
                        [-85.8089, 55.6141],
                        [-85.804, 55.6097],
                        [-85.7729, 55.5964],
                        [-85.7659, 55.5979],
                        [-85.7284, 55.5967],
                        [-85.7181, 55.5903],
                        [-85.695, 55.5847],
                        [-85.681, 55.5749],
                        [-85.6525, 55.5703],
                        [-85.6396, 55.5631],
                        [-85.6383, 55.5535],
                        [-85.6305, 55.5452],
                        [-85.6171, 55.5359],
                        [-85.6082, 55.5335],
                        [-85.5902, 55.5217],
                        [-85.5785, 55.5092],
                        [-85.56, 55.5049],
                        [-85.5449, 55.497],
                        [-85.535, 55.4859],
                        [-85.5033, 55.4686],
                        [-85.5016, 55.4622],
                        [-85.4822, 55.4498],
                        [-85.4656, 55.4443],
                        [-85.4453, 55.4318],
                        [-85.424, 55.423],
                        [-85.4094, 55.4143],
                        [-85.4023, 55.407],
                        [-85.3667, 55.396],
                        [-85.3573, 55.3909],
                        [-85.3321, 55.3825],
                        [-85.3, 55.3819],
                        [-85.2768, 55.3742],
                        [-85.2577, 55.3727],
                        [-85.234, 55.3758],
                        [-85.2246, 55.3728],
                        [-85.2201, 55.3604],
                        [-85.213, 55.3498],
                        [-85.2019, 55.3451],
                        [-85.1743, 55.3483],
                        [-85.1552, 55.344],
                        [-85.1476, 55.3359],
                        [-85.1261, 55.3336],
                        [-85.1229, 55.3305],
                        [-85.1233, 55.3173],
                        [-85.1109, 55.3106],
                        [-85.1072, 55.292],
                        [-85.1293, 55.2744],
                        [-85.1091, 55.2604],
                        [-85.0993, 55.2605],
                        [-85.0622, 55.2683],
                        [-85.0548, 55.2723],
                        [-85.0364, 55.2742],
                        [-85.0149, 55.2696],
                        [-85.0021, 55.27],
                        [-84.9978, 55.2735],
                        [-84.9808, 55.2792],
                        [-84.9612, 55.276],
                        [-84.954, 55.2706],
                        [-84.927, 55.2651],
                        [-84.9044, 55.2685],
                        [-84.896, 55.2611],
                        [-84.8848, 55.2602],
                        [-84.8393, 55.2526],
                        [-84.8136, 55.2522],
                        [-84.803, 55.247],
                        [-84.7973, 55.2394],
                        [-84.7876, 55.2364],
                        [-84.7482, 55.2381],
                        [-84.7377, 55.2357],
                        [-84.7267, 55.2382],
                        [-84.7132, 55.2343],
                        [-84.6782, 55.2363],
                        [-84.6694, 55.2354],
                        [-84.6321, 55.2391],
                        [-84.6158, 55.2392],
                        [-84.586, 55.2425],
                        [-84.5638, 55.2414],
                        [-84.551, 55.2367],
                        [-84.5335, 55.2398],
                        [-84.5095, 55.2382],
                        [-84.4889, 55.2407],
                        [-84.4702, 55.2478],
                        [-84.4597, 55.2486],
                        [-84.4437, 55.2579],
                        [-84.4116, 55.2642],
                        [-84.3891, 55.2662],
                        [-84.3461, 55.2667],
                        [-84.3226, 55.2701],
                        [-84.3067, 55.278],
                        [-84.2853, 55.2722],
                        [-84.2646, 55.2687],
                        [-84.2307, 55.2684],
                        [-84.218, 55.2713],
                        [-84.1977, 55.2814],
                        [-84.19, 55.2754],
                        [-84.1713, 55.2784],
                        [-84.1638, 55.2732],
                        [-84.1252, 55.2611],
                        [-84.1224, 55.2537],
                        [-84.1038, 55.2548],
                        [-84.0856, 55.2512],
                        [-84.0735, 55.2528],
                        [-84.0555, 55.2614],
                        [-84.026, 55.2675],
                        [-84.0123, 55.2723],
                        [-83.9987, 55.2741],
                        [-83.9795, 55.2841],
                        [-83.9587, 55.2908],
                        [-83.9375, 55.2943],
                        [-83.9143, 55.2935],
                        [-83.8949, 55.2954],
                        [-83.8833, 55.2945],
                        [-83.8712, 55.2866],
                        [-83.8368, 55.2777],
                        [-83.816, 55.2767],
                        [-83.8069, 55.2708],
                        [-83.784, 55.2632],
                        [-83.7755, 55.2574],
                        [-83.749, 55.2527],
                        [-83.738, 55.2438],
                        [-83.7241, 55.2379],
                        [-83.6943, 55.2223],
                        [-83.6814, 55.2274],
                        [-83.6734, 55.2276],
                        [-83.6577, 55.2231],
                        [-83.6509, 55.2186],
                        [-83.6349, 55.2131],
                        [-83.6351, 55.2192],
                        [-83.6507, 55.2238],
                        [-83.6669, 55.2329],
                        [-83.6889, 55.2534],
                        [-83.6965, 55.2568],
                        [-83.6928, 55.2679],
                        [-83.67, 55.2685],
                        [-83.6513, 55.2648],
                        [-83.6373, 55.2543],
                        [-83.611, 55.248],
                        [-83.5494, 55.2423],
                        [-83.5234, 55.2346],
                        [-83.4909, 55.2206],
                        [-83.4656, 55.2133],
                        [-83.437, 55.2092],
                        [-83.412, 55.21],
                        [-83.4194, 55.2159],
                        [-83.4115, 55.2216],
                        [-83.3738, 55.2106],
                        [-83.3641, 55.2047],
                        [-83.3387, 55.1986],
                        [-83.3286, 55.1984],
                        [-83.3098, 55.2025],
                        [-83.312, 55.2087],
                        [-83.3054, 55.2125],
                        [-83.289, 55.2126],
                        [-83.272, 55.2097],
                        [-83.2564, 55.2115],
                        [-83.2542, 55.217],
                        [-83.2261, 55.2173],
                        [-83.2087, 55.2135],
                        [-83.1895, 55.2023],
                        [-83.1734, 55.1988],
                        [-83.1423, 55.1978],
                        [-83.0903, 55.2034],
                        [-83.0822, 55.207],
                        [-83.0721, 55.2047],
                        [-83.0582, 55.2059],
                        [-83.0385, 55.2122],
                        [-82.9868, 55.2193],
                        [-82.9721, 55.2239],
                        [-82.944, 55.2185],
                        [-82.9234, 55.2092],
                        [-82.911, 55.1974],
                        [-82.8863, 55.1578],
                        [-82.8863, 55.1483],
                        [-82.8759, 55.143],
                        [-82.862, 55.1431],
                        [-82.8464, 55.1374],
                        [-82.8196, 55.1303],
                        [-82.8033, 55.1283],
                        [-82.7807, 55.1285],
                        [-82.7628, 55.1317],
                        [-82.7451, 55.1386],
                        [-82.7309, 55.1408],
                        [-82.7084, 55.1404],
                        [-82.7206, 55.1545],
                        [-82.7146, 55.1617],
                        [-82.7073, 55.163],
                        [-82.6853, 55.1605],
                        [-82.6741, 55.1701],
                        [-82.6678, 55.1719],
                        [-82.6383, 55.1625],
                        [-82.6284, 55.1524],
                        [-82.63, 55.1448],
                        [-82.6101, 55.1431],
                        [-82.5927, 55.146],
                        [-82.577, 55.1418],
                        [-82.5651, 55.1345],
                        [-82.5415, 55.1147],
                        [-82.5194, 55.0944],
                        [-82.51, 55.0916],
                        [-82.5031, 55.1011],
                        [-82.4882, 55.1086],
                        [-82.4677, 55.1039],
                        [-82.4607, 55.1133],
                        [-82.4533, 55.1162],
                        [-82.4378, 55.1079],
                        [-82.4276, 55.0996],
                        [-82.4173, 55.0756],
                        [-82.3811, 55.0641],
                        [-82.3582, 55.0678],
                        [-82.3426, 55.0647],
                        [-82.3194, 55.0562],
                        [-82.3081, 55.0543],
                        [-82.3057, 55.0589],
                        [-82.3102, 55.0685],
                        [-82.3312, 55.0931],
                        [-82.3296, 55.1019],
                        [-82.3109, 55.1014],
                        [-82.3098, 55.116],
                        [-82.3141, 55.1244],
                        [-82.3267, 55.1327],
                        [-82.3361, 55.133],
                        [-82.3491, 55.142],
                        [-82.331, 55.1398],
                        [-82.3349, 55.1484],
                        [-82.3218, 55.1493],
                        [-82.3175, 55.1335],
                        [-82.3086, 55.1331],
                        [-82.3026, 55.1284],
                        [-82.3025, 55.1207],
                        [-82.2918, 55.1179],
                        [-82.2829, 55.1221],
                        [-82.2763, 55.117],
                        [-82.2532, 55.1095],
                        [-82.2499, 55.1052],
                        [-82.2504, 55.093],
                        [-82.2439, 55.0768],
                        [-82.2425, 55.0622],
                        [-82.2496, 55.0561],
                        [-82.2679, 55.0511],
                        [-82.2738, 55.0457],
                        [-82.2755, 55.0231],
                        [-82.2739, 55.0169],
                        [-82.2614, 55.0029],
                        [-82.2581, 54.9961],
                        [-82.2572, 54.9669],
                        [-82.2537, 54.9483],
                        [-82.2555, 54.9364],
                        [-82.2652, 54.9373],
                        [-82.2675, 54.9314],
                        [-82.2672, 54.9129],
                        [-82.2614, 54.8888],
                        [-82.2527, 54.8726],
                        [-82.2398, 54.8603],
                        [-82.232, 54.8603],
                        [-82.2292, 54.8715],
                        [-82.2166, 54.8764],
                        [-82.2059, 54.8694],
                        [-82.2092, 54.8635],
                        [-82.2019, 54.8481],
                        [-82.199, 54.8365],
                        [-82.1971, 54.8104],
                        [-82.2002, 54.7941],
                        [-82.2126, 54.7729],
                        [-82.2149, 54.7604],
                        [-82.2348, 54.7418],
                        [-82.2427, 54.7277],
                        [-82.2552, 54.7176],
                        [-82.2666, 54.6931],
                        [-82.277, 54.6782],
                        [-82.2901, 54.6512],
                        [-82.2839, 54.6445],
                        [-82.284, 54.627],
                        [-82.2905, 54.6217],
                        [-82.298, 54.608],
                        [-82.3015, 54.5896],
                        [-82.3053, 54.5846],
                        [-82.3111, 54.5431],
                        [-82.3125, 54.5252],
                        [-82.3162, 54.5089],
                        [-82.3205, 54.5011],
                        [-82.3465, 54.4642],
                        [-82.3604, 54.4392],
                        [-82.3748, 54.41],
                        [-82.3815, 54.4001],
                        [-82.3848, 54.3867],
                        [-82.4024, 54.3543],
                        [-82.4298, 54.3489],
                        [-82.4261, 54.3424],
                        [-82.4286, 54.3369],
                        [-82.4245, 54.3219],
                        [-82.4324, 54.3023],
                        [-82.4343, 54.2918],
                        [-82.4366, 54.2547],
                        [-82.4307, 54.2376],
                        [-82.4259, 54.2098],
                        [-82.4331, 54.2031],
                        [-82.4541, 54.202],
                        [-82.4191, 54.195],
                        [-82.4028, 54.1758],
                        [-82.3949, 54.1641],
                        [-82.3812, 54.1496],
                        [-82.3576, 54.1297],
                        [-82.3654, 54.1165],
                        [-82.3494, 54.121],
                        [-82.3376, 54.1173],
                        [-82.326, 54.1101],
                        [-82.3211, 54.1027],
                        [-82.3117, 54.0994],
                        [-82.2968, 54.0891],
                        [-82.2807, 54.0743],
                        [-82.2708, 54.0625],
                        [-82.2514, 54.0463],
                        [-82.2343, 54.0291],
                        [-82.2207, 53.9957],
                        [-82.2142, 53.9868],
                        [-82.1995, 53.9539],
                        [-82.1825, 53.9355],
                        [-82.1737, 53.9123],
                        [-82.1681, 53.905],
                        [-82.1648, 53.8938],
                        [-82.1535, 53.882],
                        [-82.1503, 53.866],
                        [-82.1426, 53.8478],
                        [-82.1347, 53.8374],
                        [-82.1263, 53.8198],
                        [-82.1286, 53.799],
                        [-82.1276, 53.779],
                        [-82.1355, 53.7587],
                        [-82.1478, 53.7443],
                        [-82.1498, 53.7377],
                        [-82.1626, 53.7239],
                        [-82.1704, 53.7097],
                        [-82.1799, 53.7023],
                        [-82.1822, 53.6925],
                        [-82.19, 53.6828],
                        [-82.1939, 53.6699],
                        [-82.2001, 53.6577],
                        [-82.2008, 53.649],
                        [-82.2063, 53.636],
                        [-82.2086, 53.6101],
                        [-82.2137, 53.5929],
                        [-82.2179, 53.591],
                        [-82.2096, 53.5715],
                        [-82.2098, 53.5501],
                        [-82.2, 53.5179],
                        [-82.1859, 53.4967],
                        [-82.1764, 53.4777],
                        [-82.1723, 53.475],
                        [-82.1681, 53.4573],
                        [-82.1642, 53.4505],
                        [-82.1535, 53.4151],
                        [-82.1427, 53.3949],
                        [-82.1332, 53.3655],
                        [-82.13, 53.3476],
                        [-82.1255, 53.3348],
                        [-82.1125, 53.2739],
                        [-82.1148, 53.2641],
                        [-82.1614, 53.2452],
                        [-82.1755, 53.235],
                        [-82.1971, 53.222],
                        [-82.2153, 53.2067],
                        [-82.2238, 53.1922],
                        [-82.2418, 53.1904],
                        [-82.2501, 53.1834],
                        [-82.2608, 53.1692],
                        [-82.2603, 53.1644],
                        [-82.2701, 53.1518],
                        [-82.2749, 53.142],
                        [-82.2698, 53.1351],
                        [-82.2741, 53.1135],
                        [-82.2805, 53.0984],
                        [-82.2812, 53.0777],
                        [-82.2875, 53.0612],
                        [-82.2854, 53.0425],
                        [-82.2829, 53.0373],
                        [-82.281, 53.012],
                        [-82.2818, 53.0032],
                        [-82.2735, 52.9849],
                        [-82.2818, 52.9782],
                        [-82.2818, 52.9666],
                        [-82.2977, 52.9542],
                        [-82.2708, 52.9551],
                        [-82.2522, 52.9367],
                        [-82.2494, 52.9255],
                        [-82.2384, 52.9222],
                        [-82.2069, 52.9074],
                        [-82.1635, 52.8978],
                        [-82.1713, 52.889],
                        [-82.166, 52.8866],
                        [-82.1426, 52.8936],
                        [-82.1115, 52.8814],
                        [-82.0993, 52.8706],
                        [-82.0635, 52.8455],
                        [-82.0363, 52.8306],
                        [-82.0236, 52.8208],
                        [-82.0138, 52.8087],
                        [-81.9901, 52.7943],
                        [-81.9794, 52.7898],
                        [-81.9732, 52.784],
                        [-81.9596, 52.7779],
                        [-81.9675, 52.7714],
                        [-82, 52.7671],
                        [-81.9975, 52.7647],
                        [-81.973, 52.7676],
                        [-81.9603, 52.7659],
                        [-81.9513, 52.7595],
                        [-81.9355, 52.7205],
                        [-81.925, 52.7019],
                        [-81.892, 52.6717],
                        [-81.8779, 52.6607],
                        [-81.8455, 52.646],
                        [-81.8213, 52.6373],
                        [-81.8087, 52.6313],
                        [-81.7931, 52.6212],
                        [-81.772, 52.6114],
                        [-81.7648, 52.6063],
                        [-81.7489, 52.5872],
                        [-81.7179, 52.5615],
                        [-81.6898, 52.5321],
                        [-81.6596, 52.5066],
                        [-81.6274, 52.4824],
                        [-81.5926, 52.4605],
                        [-81.5759, 52.4539],
                        [-81.5525, 52.4467],
                        [-81.5423, 52.438],
                        [-81.543, 52.4272],
                        [-81.5567, 52.4092],
                        [-81.5628, 52.392],
                        [-81.5612, 52.3711],
                        [-81.5515, 52.3494],
                        [-81.5378, 52.339],
                        [-81.5248, 52.3327],
                        [-81.5199, 52.3259],
                        [-81.5266, 52.3181],
                        [-81.5469, 52.3079],
                        [-81.559, 52.2934],
                        [-81.5605, 52.2863],
                        [-81.5343, 52.2881],
                        [-81.51, 52.2816],
                        [-81.48, 52.257],
                        [-81.4651, 52.2357],
                        [-81.4719, 52.233],
                        [-81.4582, 52.2028],
                        [-81.4443, 52.19],
                        [-81.4424, 52.184],
                        [-81.4316, 52.1673],
                        [-81.422, 52.1601],
                        [-81.4172, 52.1515],
                        [-81.4226, 52.1452],
                        [-81.4048, 52.1352],
                        [-81.3983, 52.1274],
                        [-81.3845, 52.1161],
                        [-81.3622, 52.1027],
                        [-81.3383, 52.0947],
                        [-81.2987, 52.0854],
                        [-81.2654, 52.0743],
                        [-81.2339, 52.0678],
                        [-81.2219, 52.0619],
                        [-81.2108, 52.0625],
                        [-81.1706, 52.0446],
                        [-81.1601, 52.0413],
                        [-81.1362, 52.0403],
                        [-81.1224, 52.042],
                        [-81.1085, 52.0377],
                        [-81.098, 52.0446],
                        [-81.0948, 52.0569],
                        [-81.0893, 52.0606],
                        [-81.079, 52.0568],
                        [-81.08, 52.0418],
                        [-81.0602, 52.0273],
                        [-81.0517, 52.0256],
                        [-81.0424, 52.03],
                        [-81.0338, 52.0251],
                        [-81.0255, 52.0155],
                        [-81.009, 52.0134],
                        [-80.9958, 52.014],
                        [-80.9891, 52.0114],
                        [-80.9786, 52],
                        [-80.9686, 51.9843],
                        [-80.9591, 51.9814],
                        [-80.9498, 51.9673],
                        [-80.9346, 51.9519],
                        [-80.9119, 51.9202],
                        [-80.891, 51.9077],
                        [-80.8811, 51.8987],
                        [-80.8694, 51.8978],
                        [-80.8567, 51.9059],
                        [-80.8482, 51.9028],
                        [-80.8456, 51.8904],
                        [-80.8317, 51.8815],
                        [-80.8205, 51.8807],
                        [-80.8056, 51.8934],
                        [-80.7952, 51.8927],
                        [-80.7969, 51.882],
                        [-80.7935, 51.8723],
                        [-80.7862, 51.8613],
                        [-80.7716, 51.8572],
                        [-80.7718, 51.8501],
                        [-80.7637, 51.8447],
                        [-80.7566, 51.8444],
                        [-80.7504, 51.8378],
                        [-80.7402, 51.8397],
                        [-80.738, 51.8298],
                        [-80.7299, 51.8238],
                        [-80.7084, 51.8132],
                        [-80.6975, 51.8164],
                        [-80.6959, 51.8334],
                        [-80.6891, 51.831],
                        [-80.6923, 51.82],
                        [-80.6898, 51.8078],
                        [-80.6805, 51.8009],
                        [-80.6664, 51.7986],
                        [-80.6553, 51.7918],
                        [-80.6526, 51.7805],
                        [-80.6402, 51.7662],
                        [-80.6295, 51.757],
                        [-80.6141, 51.7481],
                        [-80.5973, 51.7314],
                        [-80.5743, 51.7119],
                        [-80.5649, 51.6997],
                        [-80.5634, 51.6893],
                        [-80.5719, 51.675],
                        [-80.5702, 51.6655],
                        [-80.5592, 51.6472],
                        [-80.5506, 51.6292],
                        [-80.5328, 51.6072],
                        [-80.5233, 51.5871],
                        [-80.514, 51.5509],
                        [-80.5075, 51.5422],
                        [-80.4985, 51.524],
                        [-80.4848, 51.5129],
                        [-80.476, 51.5089],
                        [-80.4625, 51.4992],
                        [-80.4468, 51.4928],
                        [-80.438, 51.4784],
                        [-80.428, 51.4518],
                        [-80.4357, 51.4169],
                        [-80.4321, 51.3721],
                        [-80.421, 51.3733],
                        [-80.4137, 51.3618],
                        [-80.3925, 51.3437],
                        [-80.3844, 51.3393],
                        [-80.3504, 51.3324],
                        [-80.3183, 51.324],
                        [-80.3208, 51.3184],
                        [-80.3089, 51.3186],
                        [-80.2921, 51.3142],
                        [-80.2867, 51.3099],
                        [-80.2754, 51.3105],
                        [-80.2527, 51.3046],
                        [-80.2118, 51.2964],
                        [-80.1963, 51.2965],
                        [-80.1762, 51.2926],
                        [-80.1485, 51.2921],
                        [-80.1327, 51.2895],
                        [-80.1207, 51.2921],
                        [-80.1134, 51.2909],
                        [-80.1074, 51.2838],
                        [-80.0975, 51.2775],
                        [-80.0796, 51.273],
                        [-80.0713, 51.265],
                        [-80.06, 51.2587],
                        [-80.0394, 51.2551],
                        [-80.0225, 51.2549],
                        [-79.9902, 51.2439],
                        [-79.9755, 51.2328],
                        [-79.9617, 51.2175],
                        [-79.949, 51.2091],
                        [-79.9471, 51.2034],
                        [-79.9348, 51.1974],
                        [-79.9039, 51.1762],
                        [-79.8877, 51.1678],
                        [-79.855, 51.1616],
                        [-79.8277, 51.1603],
                        [-79.8068, 51.1568],
                        [-79.7983, 51.1576],
                        [-79.7897, 51.1743],
                        [-79.7684, 51.182],
                        [-79.7539, 51.1855],
                        [-79.734, 51.2191],
                        [-79.7232, 51.2279],
                        [-79.7106, 51.2313],
                        [-79.707, 51.2458],
                        [-79.6975, 51.2461],
                        [-79.6813, 51.2527],
                        [-79.6799, 51.2572],
                        [-79.6928, 51.2666],
                        [-79.6868, 51.2826],
                        [-79.7056, 51.2948],
                        [-79.7167, 51.2983],
                        [-79.7235, 51.3069],
                        [-79.7326, 51.3122],
                        [-79.7446, 51.3295],
                        [-79.7386, 51.3381],
                        [-79.7419, 51.3424],
                        [-79.7415, 51.3518],
                        [-79.7367, 51.3561],
                        [-79.7262, 51.3582],
                        [-79.7356, 51.3664],
                        [-79.714, 51.3821],
                        [-79.7078, 51.3807],
                        [-79.7126, 51.3689],
                        [-79.7084, 51.3642],
                        [-79.7226, 51.3531],
                        [-79.7081, 51.3478],
                        [-79.7076, 51.3542],
                        [-79.6992, 51.3609],
                        [-79.6753, 51.3435],
                        [-79.6724, 51.3523],
                        [-79.6812, 51.3622],
                        [-79.6884, 51.3791],
                        [-79.6781, 51.3954],
                        [-79.6798, 51.4022],
                        [-79.6561, 51.4044],
                        [-79.645, 51.3991],
                        [-79.6358, 51.4049],
                        [-79.6331, 51.4144],
                        [-79.6166, 51.4139],
                        [-79.6064, 51.4204],
                        [-79.6024, 51.4312],
                        [-79.5786, 51.4405],
                        [-79.5726, 51.4578],
                        [-79.567, 51.4653],
                        [-79.5594, 51.4598],
                        [-79.5324, 51.4567],
                        [-79.5156, 51.4608]
                    ],
                ]
            },
        },
        {
            type: "Feature",
            id: "33",
            properties: { cityName: "Quebec", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-64.1278, 49.9446],
                        [-64.1157, 49.9425],
                        [-64.0864, 49.9315],
                        [-64.0772, 49.926],
                        [-64.0547, 49.9306],
                        [-64.038, 49.929],
                        [-64.0218, 49.925],
                        [-64.0138, 49.92],
                        [-64, 49.9188],
                        [-63.993, 49.912],
                        [-63.978, 49.9068],
                        [-63.9638, 49.9059],
                        [-63.9603, 49.9017],
                        [-63.9228, 49.881],
                        [-63.9155, 49.879],
                        [-63.882, 49.8758],
                        [-63.8699, 49.8702],
                        [-63.8603, 49.8703],
                        [-63.8451, 49.8747],
                        [-63.832, 49.8749],
                        [-63.8136, 49.8707],
                        [-63.7817, 49.8661],
                        [-63.7485, 49.8665],
                        [-63.6908, 49.8571],
                        [-63.6668, 49.8485],
                        [-63.6422, 49.8465],
                        [-63.6164, 49.8423],
                        [-63.5893, 49.8441],
                        [-63.5541, 49.8365],
                        [-63.496, 49.8348],
                        [-63.4759, 49.8313],
                        [-63.4564, 49.8303],
                        [-63.4096, 49.8228],
                        [-63.3865, 49.8223],
                        [-63.3633, 49.8182],
                        [-63.3304, 49.8079],
                        [-63.3101, 49.806],
                        [-63.2989, 49.8018],
                        [-63.2642, 49.7963],
                        [-63.2483, 49.7908],
                        [-63.2338, 49.7897],
                        [-63.2251, 49.7867],
                        [-63.2032, 49.7866],
                        [-63.1887, 49.7809],
                        [-63.1721, 49.7808],
                        [-63.1489, 49.7723],
                        [-63.1302, 49.7676],
                        [-63.0847, 49.7624],
                        [-63.0692, 49.7571],
                        [-63.0566, 49.7494],
                        [-63.0267, 49.7489],
                        [-63.0166, 49.7452],
                        [-63.0059, 49.7322],
                        [-62.9883, 49.7274],
                        [-62.9481, 49.728],
                        [-62.9416, 49.7248],
                        [-62.9266, 49.7039],
                        [-62.9172, 49.7009],
                        [-62.8936, 49.7001],
                        [-62.8849, 49.6962],
                        [-62.8746, 49.6954],
                        [-62.8581, 49.6969],
                        [-62.8502, 49.6875],
                        [-62.8418, 49.6829],
                        [-62.8149, 49.6811],
                        [-62.7979, 49.6732],
                        [-62.7833, 49.6708],
                        [-62.7732, 49.6601],
                        [-62.7633, 49.6537],
                        [-62.7117, 49.6565],
                        [-62.7042, 49.6551],
                        [-62.6937, 49.6423],
                        [-62.6882, 49.639],
                        [-62.6765, 49.6382],
                        [-62.6665, 49.6295],
                        [-62.6429, 49.6152],
                        [-62.6361, 49.6135],
                        [-62.6069, 49.6126],
                        [-62.5859, 49.603],
                        [-62.5792, 49.602],
                        [-62.5646, 49.6053],
                        [-62.5589, 49.5976],
                        [-62.5415, 49.5873],
                        [-62.5207, 49.5857],
                        [-62.5153, 49.5828],
                        [-62.5102, 49.5729],
                        [-62.4837, 49.5645],
                        [-62.4776, 49.5539],
                        [-62.4669, 49.5521],
                        [-62.444, 49.543],
                        [-62.4435, 49.5405],
                        [-62.4605, 49.5358],
                        [-62.4536, 49.5252],
                        [-62.4407, 49.5193],
                        [-62.447, 49.5121],
                        [-62.4397, 49.507],
                        [-62.4196, 49.5051],
                        [-62.4227, 49.4968],
                        [-62.4157, 49.4899],
                        [-62.4013, 49.4817],
                        [-62.3918, 49.481],
                        [-62.3732, 49.4878],
                        [-62.3566, 49.4872],
                        [-62.3307, 49.4804],
                        [-62.3303, 49.4722],
                        [-62.3262, 49.4666],
                        [-62.3136, 49.4593],
                        [-62.3011, 49.4629],
                        [-62.292, 49.4627],
                        [-62.2802, 49.457],
                        [-62.2567, 49.4501],
                        [-62.2512, 49.4363],
                        [-62.2531, 49.4246],
                        [-62.2435, 49.4143],
                        [-62.2252, 49.4121],
                        [-62.2111, 49.403],
                        [-62.1952, 49.3994],
                        [-62.179, 49.3904],
                        [-62.1699, 49.3908],
                        [-62.1604, 49.3964],
                        [-62.1472, 49.4001],
                        [-62.1197, 49.3855],
                        [-62.1059, 49.3743],
                        [-62.0893, 49.3721],
                        [-62.0745, 49.3772],
                        [-62.0691, 49.3828],
                        [-62.0513, 49.3828],
                        [-62.0386, 49.3801],
                        [-62.0285, 49.3723],
                        [-62.0125, 49.3666],
                        [-61.9957, 49.3665],
                        [-61.991, 49.3633],
                        [-61.9798, 49.3628],
                        [-61.9761, 49.3595],
                        [-61.981, 49.3489],
                        [-61.9697, 49.3395],
                        [-61.9614, 49.3396],
                        [-61.9469, 49.3444],
                        [-61.9325, 49.3431],
                        [-61.9141, 49.3473],
                        [-61.8994, 49.3464],
                        [-61.8859, 49.3235],
                        [-61.8812, 49.3201],
                        [-61.8597, 49.3152],
                        [-61.8487, 49.3099],
                        [-61.8346, 49.3063],
                        [-61.8335, 49.2958],
                        [-61.8297, 49.2885],
                        [-61.8385, 49.2813],
                        [-61.8418, 49.2751],
                        [-61.8146, 49.2753],
                        [-61.8068, 49.2713],
                        [-61.8021, 49.2593],
                        [-61.806, 49.2514],
                        [-61.7933, 49.2372],
                        [-61.7714, 49.23],
                        [-61.7636, 49.2251],
                        [-61.7568, 49.2113],
                        [-61.7499, 49.2036],
                        [-61.738, 49.2015],
                        [-61.7227, 49.1928],
                        [-61.7156, 49.1706],
                        [-61.7051, 49.1495],
                        [-61.6977, 49.1452],
                        [-61.6752, 49.1387],
                        [-61.671, 49.1296],
                        [-61.6807, 49.1179],
                        [-61.6927, 49.1127],
                        [-61.7076, 49.1006],
                        [-61.713, 49.0936],
                        [-61.7018, 49.0831],
                        [-61.7082, 49.0773],
                        [-61.7272, 49.0843],
                        [-61.7463, 49.0815],
                        [-61.7734, 49.0815],
                        [-61.7876, 49.0713],
                        [-61.8126, 49.0711],
                        [-61.8216, 49.0582],
                        [-61.8292, 49.0576],
                        [-61.8441, 49.0649],
                        [-61.8666, 49.0646],
                        [-61.8769, 49.0699],
                        [-61.895, 49.0687],
                        [-61.9153, 49.0722],
                        [-61.926, 49.0718],
                        [-61.9427, 49.0633],
                        [-61.9587, 49.0626],
                        [-61.9654, 49.0659],
                        [-61.9794, 49.0675],
                        [-61.9919, 49.0764],
                        [-62.0009, 49.0753],
                        [-62.0024, 49.0696],
                        [-62.01, 49.0672],
                        [-62.0146, 49.0614],
                        [-62.0215, 49.0608],
                        [-62.052, 49.0674],
                        [-62.0662, 49.0639],
                        [-62.0816, 49.068],
                        [-62.0944, 49.0661],
                        [-62.107, 49.0673],
                        [-62.1215, 49.0645],
                        [-62.1316, 49.0664],
                        [-62.1629, 49.0651],
                        [-62.1704, 49.0634],
                        [-62.2085, 49.0668],
                        [-62.2279, 49.0584],
                        [-62.2501, 49.0568],
                        [-62.267, 49.0611],
                        [-62.2915, 49.0715],
                        [-62.3026, 49.0725],
                        [-62.3136, 49.0775],
                        [-62.3408, 49.0859],
                        [-62.3552, 49.0888],
                        [-62.3762, 49.0904],
                        [-62.3995, 49.0984],
                        [-62.4173, 49.0994],
                        [-62.4423, 49.1052],
                        [-62.4492, 49.1103],
                        [-62.4729, 49.1174],
                        [-62.493, 49.1156],
                        [-62.5006, 49.1213],
                        [-62.5214, 49.1268],
                        [-62.5353, 49.1267],
                        [-62.5452, 49.1293],
                        [-62.5562, 49.1274],
                        [-62.5749, 49.1313],
                        [-62.5864, 49.1309],
                        [-62.6178, 49.1393],
                        [-62.639, 49.1381],
                        [-62.6496, 49.1408],
                        [-62.6596, 49.1386],
                        [-62.6764, 49.1402],
                        [-62.6885, 49.1447],
                        [-62.7059, 49.1457],
                        [-62.7182, 49.1527],
                        [-62.7392, 49.1492],
                        [-62.7568, 49.1513],
                        [-62.7756, 49.158],
                        [-62.7851, 49.1586],
                        [-62.8294, 49.1726],
                        [-62.8531, 49.178],
                        [-62.8695, 49.1783],
                        [-62.8922, 49.1841],
                        [-62.9061, 49.1847],
                        [-62.9193, 49.1827],
                        [-62.9363, 49.1871],
                        [-62.9506, 49.1938],
                        [-62.9641, 49.1971],
                        [-62.978, 49.197],
                        [-62.9934, 49.203],
                        [-63.0344, 49.2122],
                        [-63.0463, 49.2178],
                        [-63.0576, 49.2198],
                        [-63.0697, 49.219],
                        [-63.0812, 49.2233],
                        [-63.1087, 49.2287],
                        [-63.1189, 49.235],
                        [-63.1409, 49.2404],
                        [-63.1677, 49.2502],
                        [-63.1819, 49.2527],
                        [-63.1981, 49.261],
                        [-63.2145, 49.2649],
                        [-63.2257, 49.2714],
                        [-63.239, 49.2758],
                        [-63.2505, 49.2872],
                        [-63.2655, 49.292],
                        [-63.2737, 49.2984],
                        [-63.2783, 49.3099],
                        [-63.2897, 49.3172],
                        [-63.3056, 49.33],
                        [-63.3179, 49.3289],
                        [-63.3178, 49.3232],
                        [-63.3294, 49.3162],
                        [-63.3358, 49.3167],
                        [-63.3525, 49.3265],
                        [-63.4001, 49.3415],
                        [-63.4299, 49.3521],
                        [-63.4466, 49.3502],
                        [-63.4612, 49.3591],
                        [-63.4785, 49.3614],
                        [-63.5281, 49.3736],
                        [-63.5358, 49.374],
                        [-63.5446, 49.379],
                        [-63.554, 49.3789],
                        [-63.5666, 49.3833],
                        [-63.589, 49.3785],
                        [-63.5943, 49.3841],
                        [-63.5802, 49.3916],
                        [-63.5768, 49.3983],
                        [-63.583, 49.4107],
                        [-63.605, 49.4288],
                        [-63.6192, 49.4544],
                        [-63.607, 49.4733],
                        [-63.6183, 49.4789],
                        [-63.6211, 49.4889],
                        [-63.6358, 49.511],
                        [-63.6452, 49.5151],
                        [-63.6601, 49.5308],
                        [-63.6716, 49.538],
                        [-63.6835, 49.5418],
                        [-63.695, 49.5505],
                        [-63.7369, 49.5695],
                        [-63.747, 49.577],
                        [-63.768, 49.585],
                        [-63.7949, 49.6083],
                        [-63.8315, 49.6357],
                        [-63.8606, 49.642],
                        [-63.8686, 49.6459],
                        [-63.9128, 49.6602],
                        [-63.9238, 49.6701],
                        [-63.9373, 49.6717],
                        [-63.9667, 49.6816],
                        [-63.9777, 49.6906],
                        [-64, 49.7002],
                        [-64.0212, 49.7043],
                        [-64.0446, 49.707],
                        [-64.0548, 49.7168],
                        [-64.0633, 49.7107],
                        [-64.0958, 49.7151],
                        [-64.1118, 49.7194],
                        [-64.1174, 49.7233],
                        [-64.1331, 49.7281],
                        [-64.1425, 49.7284],
                        [-64.158, 49.7361],
                        [-64.1702, 49.7369],
                        [-64.179, 49.7345],
                        [-64.2223, 49.7461],
                        [-64.2361, 49.7479],
                        [-64.2636, 49.7573],
                        [-64.2779, 49.7605],
                        [-64.2841, 49.7674],
                        [-64.3185, 49.7801],
                        [-64.3284, 49.7914],
                        [-64.329, 49.806],
                        [-64.3443, 49.8109],
                        [-64.3552, 49.8175],
                        [-64.3559, 49.8212],
                        [-64.3747, 49.8244],
                        [-64.3791, 49.8306],
                        [-64.3928, 49.829],
                        [-64.3928, 49.8116],
                        [-64.3797, 49.798],
                        [-64.3867, 49.792],
                        [-64.407, 49.7966],
                        [-64.4196, 49.8011],
                        [-64.4229, 49.8069],
                        [-64.4429, 49.8232],
                        [-64.4525, 49.838],
                        [-64.4631, 49.8416],
                        [-64.4822, 49.8452],
                        [-64.5096, 49.8541],
                        [-64.5224, 49.8616],
                        [-64.5203, 49.8769],
                        [-64.5176, 49.8819],
                        [-64.5063, 49.8886],
                        [-64.4953, 49.8912],
                        [-64.4918, 49.9003],
                        [-64.4741, 49.9043],
                        [-64.4553, 49.9124],
                        [-64.4387, 49.9156],
                        [-64.4081, 49.9167],
                        [-64.39, 49.9223],
                        [-64.3797, 49.9238],
                        [-64.3525, 49.9231],
                        [-64.3354, 49.9311],
                        [-64.3072, 49.9387],
                        [-64.2845, 49.9387],
                        [-64.2685, 49.9351],
                        [-64.2585, 49.9369],
                        [-64.2535, 49.9415],
                        [-64.2313, 49.9465],
                        [-64.2021, 49.9469],
                        [-64.1726, 49.9445],
                        [-64.1484, 49.9508],
                        [-64.1278, 49.9446]
                    ]
                ]
            },
        },
        {
            type: "Feature",
            id: "34",
            properties: { cityName: "Quebec", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-66.3259, 48.0608],
                        [-66.3489, 48.0771],
                        [-66.3644, 48.0833],
                        [-66.3821, 48.0882],
                        [-66.3984, 48.0893],
                        [-66.4292, 48.0887],
                        [-66.4698, 48.0856],
                        [-66.4822, 48.0835],
                        [-66.5024, 48.077],
                        [-66.5248, 48.0501],
                        [-66.5315, 48.0473],
                        [-66.6326, 48.0221],
                        [-66.6765, 48.0122],
                        [-66.6936, 48.0068],
                        [-66.7267, 48.0094],
                        [-66.7434, 48.0034],
                        [-66.7648, 48.0016],
                        [-66.7869, 47.9919],
                        [-66.8009, 47.9921],
                        [-66.8346, 48.0007],
                        [-66.8506, 48.0012],
                        [-66.8644, 47.9985],
                        [-66.8755, 47.9938],
                        [-66.8906, 47.9927],
                        [-66.9213, 47.9846],
                        [-66.9289, 47.9767],
                        [-66.9419, 47.971],
                        [-66.9454, 47.957],
                        [-66.9524, 47.9503],
                        [-66.9625, 47.9449],
                        [-66.9688, 47.9138],
                        [-66.9505, 47.9094],
                        [-66.9447, 47.9049],
                        [-66.9476, 47.897],
                        [-66.9574, 47.8921],
                        [-66.9758, 47.8932],
                        [-66.9978, 47.9032],
                        [-67.0066, 47.9109],
                        [-67.0182, 47.9144],
                        [-67.0431, 47.9256],
                        [-67.049, 47.9335],
                        [-67.0622, 47.935],
                        [-67.0691, 47.9236],
                        [-67.0951, 47.9191],
                        [-67.1066, 47.9134],
                        [-67.133, 47.9153],
                        [-67.1358, 47.9121],
                        [-67.1326, 47.9038],
                        [-67.1375, 47.8946],
                        [-67.15, 47.8904],
                        [-67.1612, 47.8962],
                        [-67.1685, 47.8958],
                        [-67.1751, 47.8827],
                        [-67.192, 47.8813],
                        [-67.2034, 47.8767],
                        [-67.2171, 47.8774],
                        [-67.2249, 47.8854],
                        [-67.2316, 47.8874],
                        [-67.2398, 47.8827],
                        [-67.2474, 47.8824],
                        [-67.2584, 47.891],
                        [-67.269, 47.8904],
                        [-67.2789, 47.893],
                        [-67.2861, 47.8986],
                        [-67.2985, 47.8963],
                        [-67.2947, 47.8886],
                        [-67.3048, 47.8857],
                        [-67.3156, 47.8888],
                        [-67.3361, 47.8908],
                        [-67.3383, 47.8882],
                        [-67.3367, 47.8728],
                        [-67.3471, 47.874],
                        [-67.3519, 47.8667],
                        [-67.3647, 47.8562],
                        [-67.3575, 47.8491],
                        [-67.3605, 47.8459],
                        [-67.3724, 47.8453],
                        [-67.385, 47.8477],
                        [-67.3817, 47.8585],
                        [-67.3943, 47.862],
                        [-67.3925, 47.8676],
                        [-67.4005, 47.8694],
                        [-67.4217, 47.8783],
                        [-67.4383, 47.8807],
                        [-67.4415, 47.8854],
                        [-67.4592, 47.8896],
                        [-67.4722, 47.8944],
                        [-67.4823, 47.9026],
                        [-67.5054, 47.9068],
                        [-67.5135, 47.9127],
                        [-67.5287, 47.9129],
                        [-67.5475, 47.9202],
                        [-67.5596, 47.919],
                        [-67.5821, 47.9265],
                        [-67.5947, 47.925],
                        [-67.5968, 47.9317],
                        [-67.6073, 47.9357],
                        [-67.6087, 47.9473],
                        [-67.6023, 47.9532],
                        [-67.6066, 47.9612],
                        [-67.5985, 47.9682],
                        [-67.6052, 47.9772],
                        [-67.6129, 47.9785],
                        [-67.6111, 47.9891],
                        [-67.598, 47.9957],
                        [-67.5981, 47.9999],
                        [-67.7981, 47.9999],
                        [-67.9761, 47.9998],
                        [-68.1226, 47.9997],
                        [-68.1228, 47.9163],
                        [-68.2601, 47.9162],
                        [-68.3469, 47.9164],
                        [-68.3823, 47.9161],
                        [-68.3827, 47.8651],
                        [-68.3824, 47.7786],
                        [-68.383, 47.6607],
                        [-68.3835, 47.5532],
                        [-68.4878, 47.4829],
                        [-68.5693, 47.4271],
                        [-68.7996, 47.3489],
                        [-69.0507, 47.3001],
                        [-69.0489, 47.3052],
                        [-69.055, 47.3149],
                        [-69.0538, 47.3298],
                        [-69.0545, 47.3757],
                        [-69.0463, 47.384],
                        [-69.0398, 47.4039],
                        [-69.0342, 47.4028],
                        [-69.0354, 47.415],
                        [-69.0435, 47.4274],
                        [-69.0484, 47.4247],
                        [-69.0642, 47.4331],
                        [-69.0682, 47.4276],
                        [-69.0812, 47.424],
                        [-69.1216, 47.4426],
                        [-69.1333, 47.4442],
                        [-69.1562, 47.4515],
                        [-69.1633, 47.4501],
                        [-69.1762, 47.4567],
                        [-69.1971, 47.4552],
                        [-69.2023, 47.4523],
                        [-69.2239, 47.4595],
                        [-69.3686, 47.3193],
                        [-69.5025, 47.1882],
                        [-69.6025, 47.0893],
                        [-69.7079, 46.9848],
                        [-69.7716, 46.9213],
                        [-69.8643, 46.8288],
                        [-69.9768, 46.7159],
                        [-69.9971, 46.6958],
                        [-70.0254, 46.5632],
                        [-70.0565, 46.4161],
                        [-70.0765, 46.4096],
                        [-70.0951, 46.4099],
                        [-70.1007, 46.3989],
                        [-70.1112, 46.386],
                        [-70.1162, 46.386],
                        [-70.1282, 46.379],
                        [-70.1268, 46.3711],
                        [-70.1376, 46.3692],
                        [-70.1405, 46.3636],
                        [-70.149, 46.359],
                        [-70.1565, 46.3615],
                        [-70.1708, 46.3595],
                        [-70.1921, 46.3494],
                        [-70.1958, 46.3409],
                        [-70.209, 46.3295],
                        [-70.2073, 46.3194],
                        [-70.2031, 46.3144],
                        [-70.2061, 46.3099],
                        [-70.2067, 46.2993],
                        [-70.2166, 46.2945],
                        [-70.2317, 46.291],
                        [-70.2326, 46.2848],
                        [-70.2399, 46.2792],
                        [-70.2409, 46.2729],
                        [-70.2487, 46.2671],
                        [-70.2546, 46.2538],
                        [-70.2552, 46.2464],
                        [-70.261, 46.2297],
                        [-70.2698, 46.2173],
                        [-70.2709, 46.2115],
                        [-70.289, 46.193],
                        [-70.2858, 46.1845],
                        [-70.2776, 46.1746],
                        [-70.2649, 46.1678],
                        [-70.2484, 46.152],
                        [-70.24, 46.1498],
                        [-70.2365, 46.145],
                        [-70.2446, 46.1273],
                        [-70.2552, 46.1088],
                        [-70.2531, 46.1001],
                        [-70.2733, 46.1022],
                        [-70.2901, 46.0939],
                        [-70.2928, 46.0861],
                        [-70.3011, 46.0831],
                        [-70.3028, 46.0707],
                        [-70.3106, 46.0641],
                        [-70.3066, 46.0613],
                        [-70.2788, 46.0603],
                        [-70.2809, 46.0513],
                        [-70.3001, 46.0369],
                        [-70.3058, 46.0255],
                        [-70.3176, 46.0197],
                        [-70.3106, 46.015],
                        [-70.305, 46.0071],
                        [-70.3034, 45.9992],
                        [-70.2919, 45.9968],
                        [-70.2886, 45.991],
                        [-70.3078, 45.9823],
                        [-70.3121, 45.9621],
                        [-70.3051, 45.9651],
                        [-70.2975, 45.9629],
                        [-70.2815, 45.964],
                        [-70.2758, 45.9673],
                        [-70.2663, 45.9643],
                        [-70.2523, 45.9552],
                        [-70.2421, 45.9467],
                        [-70.2402, 45.9397],
                        [-70.2507, 45.934],
                        [-70.2622, 45.9244],
                        [-70.2573, 45.9159],
                        [-70.2587, 45.909],
                        [-70.2536, 45.9032],
                        [-70.2652, 45.8931],
                        [-70.2593, 45.8895],
                        [-70.273, 45.8821],
                        [-70.2843, 45.8718],
                        [-70.3028, 45.8631],
                        [-70.3086, 45.8583],
                        [-70.342, 45.8528],
                        [-70.3605, 45.8349],
                        [-70.3678, 45.8351],
                        [-70.373, 45.8278],
                        [-70.3876, 45.8204],
                        [-70.3908, 45.812],
                        [-70.3966, 45.808],
                        [-70.3982, 45.7972],
                        [-70.4171, 45.7951],
                        [-70.4065, 45.7758],
                        [-70.4069, 45.7614],
                        [-70.3881, 45.7514],
                        [-70.3945, 45.7428],
                        [-70.3847, 45.7332],
                        [-70.3958, 45.7296],
                        [-70.4002, 45.72],
                        [-70.4285, 45.7101],
                        [-70.4391, 45.7039],
                        [-70.4509, 45.7036],
                        [-70.454, 45.7065],
                        [-70.4663, 45.706],
                        [-70.4816, 45.6939],
                        [-70.5097, 45.6796],
                        [-70.5255, 45.6667],
                        [-70.5336, 45.6714],
                        [-70.5405, 45.6671],
                        [-70.5577, 45.667],
                        [-70.5629, 45.6633],
                        [-70.5649, 45.6546],
                        [-70.5757, 45.6476],
                        [-70.5899, 45.6335],
                        [-70.5909, 45.6302],
                        [-70.6054, 45.6262],
                        [-70.6146, 45.6189],
                        [-70.6185, 45.612],
                        [-70.645, 45.6067],
                        [-70.6499, 45.5967],
                        [-70.6596, 45.5859],
                        [-70.6693, 45.5802],
                        [-70.679, 45.5697],
                        [-70.6897, 45.5619],
                        [-70.6787, 45.5505],
                        [-70.6864, 45.5496],
                        [-70.6957, 45.5406],
                        [-70.7115, 45.5193],
                        [-70.7235, 45.5125],
                        [-70.7224, 45.5061],
                        [-70.7112, 45.4987],
                        [-70.7175, 45.4894],
                        [-70.7051, 45.4792],
                        [-70.6916, 45.4738],
                        [-70.6933, 45.4663],
                        [-70.6826, 45.4619],
                        [-70.6802, 45.452],
                        [-70.6742, 45.4515],
                        [-70.6574, 45.4453],
                        [-70.6509, 45.4453],
                        [-70.6426, 45.4329],
                        [-70.6282, 45.4213],
                        [-70.635, 45.4116],
                        [-70.622, 45.405],
                        [-70.625, 45.4002],
                        [-70.6352, 45.3967],
                        [-70.6382, 45.3904],
                        [-70.637, 45.3823],
                        [-70.6504, 45.3774],
                        [-70.6598, 45.3775],
                        [-70.6606, 45.3851],
                        [-70.6813, 45.3949],
                        [-70.6933, 45.3924],
                        [-70.7002, 45.3945],
                        [-70.7102, 45.3901],
                        [-70.7303, 45.3993],
                        [-70.7301, 45.4041],
                        [-70.7433, 45.4117],
                        [-70.744, 45.4209],
                        [-70.7568, 45.4286],
                        [-70.7673, 45.4279],
                        [-70.7814, 45.4307],
                        [-70.7871, 45.4268],
                        [-70.7984, 45.4258],
                        [-70.8041, 45.4173],
                        [-70.811, 45.4157],
                        [-70.8186, 45.405],
                        [-70.8252, 45.4001],
                        [-70.8248, 45.3925],
                        [-70.8162, 45.3901],
                        [-70.8069, 45.3777],
                        [-70.8021, 45.3667],
                        [-70.8138, 45.3551],
                        [-70.8096, 45.3506],
                        [-70.8178, 45.3444],
                        [-70.8184, 45.3329],
                        [-70.8067, 45.3211],
                        [-70.809, 45.3118],
                        [-70.8152, 45.308],
                        [-70.8145, 45.2997],
                        [-70.8344, 45.2935],
                        [-70.8288, 45.2879],
                        [-70.8328, 45.2777],
                        [-70.8419, 45.2664],
                        [-70.849, 45.2621],
                        [-70.8463, 45.2532],
                        [-70.8494, 45.2447],
                        [-70.8372, 45.237],
                        [-70.8579, 45.2286],
                        [-70.8741, 45.2334],
                        [-70.8855, 45.2348],
                        [-70.8985, 45.2437],
                        [-70.8957, 45.2516],
                        [-70.899, 45.2584],
                        [-70.9216, 45.2803],
                        [-70.912, 45.2968],
                        [-70.9189, 45.3042],
                        [-70.9189, 45.3125],
                        [-70.9371, 45.3195],
                        [-70.9448, 45.3249],
                        [-70.9536, 45.3389],
                        [-70.9675, 45.3322],
                        [-70.9799, 45.3319],
                        [-71.0104, 45.3475],
                        [-71.0128, 45.3439],
                        [-71.0114, 45.3341],
                        [-71.0034, 45.3271],
                        [-71.0089, 45.3192],
                        [-71.0165, 45.3153],
                        [-71.025, 45.3153],
                        [-71.0343, 45.3103],
                        [-71.0406, 45.313],
                        [-71.0536, 45.3114],
                        [-71.065, 45.3126],
                        [-71.0756, 45.3069],
                        [-71.0808, 45.3072],
                        [-71.0976, 45.3018],
                        [-71.1051, 45.2937],
                        [-71.1103, 45.2838],
                        [-71.1081, 45.2773],
                        [-71.1163, 45.274],
                        [-71.1191, 45.2625],
                        [-71.1299, 45.2517],
                        [-71.1332, 45.2445],
                        [-71.1454, 45.2414],
                        [-71.1613, 45.25],
                        [-71.1731, 45.2466],
                        [-71.1802, 45.2403],
                        [-71.1977, 45.2542],
                        [-71.2097, 45.2507],
                        [-71.23, 45.2495],
                        [-71.2358, 45.2607],
                        [-71.2624, 45.2762],
                        [-71.2661, 45.2909],
                        [-71.2735, 45.2963],
                        [-71.2802, 45.2945],
                        [-71.2856, 45.3005],
                        [-71.3013, 45.2964],
                        [-71.3083, 45.2871],
                        [-71.3161, 45.2861],
                        [-71.3213, 45.2815],
                        [-71.3297, 45.2809],
                        [-71.3344, 45.2741],
                        [-71.3634, 45.2657],
                        [-71.3572, 45.2572],
                        [-71.3593, 45.2505],
                        [-71.3782, 45.2439],
                        [-71.3861, 45.2331],
                        [-71.3949, 45.241],
                        [-71.4034, 45.2426],
                        [-71.4177, 45.2348],
                        [-71.4284, 45.2339],
                        [-71.4347, 45.2385],
                        [-71.4409, 45.235],
                        [-71.4317, 45.2286],
                        [-71.4056, 45.2165],
                        [-71.3973, 45.2046],
                        [-71.4044, 45.1951],
                        [-71.4226, 45.1667],
                        [-71.4264, 45.1527],
                        [-71.4376, 45.1413],
                        [-71.4261, 45.1291],
                        [-71.4318, 45.1199],
                        [-71.4459, 45.1134],
                        [-71.4518, 45.1025],
                        [-71.4645, 45.0934],
                        [-71.4694, 45.0839],
                        [-71.4788, 45.0836],
                        [-71.4865, 45.0792],
                        [-71.4899, 45.0708],
                        [-71.4961, 45.0645],
                        [-71.5033, 45.0466],
                        [-71.491, 45.0429],
                        [-71.493, 45.0348],
                        [-71.5003, 45.0264],
                        [-71.5006, 45.0141],
                        [-71.553, 45.0129],
                        [-71.6136, 45.0127],
                        [-71.6944, 45.0111],
                        [-71.7217, 45.0114],
                        [-71.7988, 45.0105],
                        [-71.9142, 45.0075],
                        [-71.9528, 45.0083],
                        [-72.0064, 45.0069],
                        [-72.0982, 45.0056],
                        [-72.1885, 45.0057],
                        [-72.2554, 45.0044],
                        [-72.3146, 45.0039],
                        [-72.3623, 45.006],
                        [-72.4462, 45.0082],
                        [-72.4858, 45.0088],
                        [-72.5161, 45.0081],
                        [-72.5554, 45.0081],
                        [-72.5996, 45.0128],
                        [-72.6326, 45.0144],
                        [-72.699, 45.0157],
                        [-72.789, 45.016],
                        [-72.8446, 45.0168],
                        [-72.8934, 45.0161],
                        [-72.9624, 45.0144],
                        [-73.0525, 45.0157],
                        [-73.0908, 45.0153],
                        [-73.0899, 45.027],
                        [-73.0852, 45.035],
                        [-73.0759, 45.0427],
                        [-73.0773, 45.059],
                        [-73.0866, 45.0663],
                        [-73.0973, 45.0717],
                        [-73.1143, 45.0733],
                        [-73.1265, 45.072],
                        [-73.1377, 45.0583],
                        [-73.1643, 45.0592],
                        [-73.1691, 45.056],
                        [-73.1694, 45.046],
                        [-73.1745, 45.0312],
                        [-73.1815, 45.0271],
                        [-73.1926, 45.0134],
                        [-73.3304, 45.0109],
                        [-73.4119, 45.0098],
                        [-73.4581, 45.0087],
                        [-73.5384, 45.0061],
                        [-73.6525, 45.003],
                        [-73.7472, 45.0031],
                        [-73.8583, 45.0018],
                        [-73.9617, 44.9979],
                        [-74, 44.9968],
                        [-74.1263, 44.9917],
                        [-74.1501, 44.9911],
                        [-74.2574, 44.9923],
                        [-74.3309, 44.9918],
                        [-74.3966, 44.9947],
                        [-74.4998, 44.998],
                        [-74.5456, 44.9983],
                        [-74.5964, 44.9994],
                        [-74.6375, 44.9993],
                        [-74.6164, 45.0045],
                        [-74.615, 45.0086],
                        [-74.6026, 45.0181],
                        [-74.5747, 45.0194],
                        [-74.5526, 45.0265],
                        [-74.5521, 45.0304],
                        [-74.5412, 45.0319],
                        [-74.5383, 45.0355],
                        [-74.5244, 45.0354],
                        [-74.5154, 45.0395],
                        [-74.4949, 45.0361],
                        [-74.4811, 45.0386],
                        [-74.4633, 45.0481],
                        [-74.4627, 45.0515],
                        [-74.4519, 45.0553],
                        [-74.4491, 45.0608],
                        [-74.442, 45.0642],
                        [-74.4393, 45.0744],
                        [-74.4239, 45.0824],
                        [-74.4268, 45.0937],
                        [-74.4159, 45.0942],
                        [-74.4069, 45.0989],
                        [-74.4053, 45.1053],
                        [-74.4103, 45.1116],
                        [-74.4054, 45.1151],
                        [-74.4082, 45.1258],
                        [-74.4009, 45.1286],
                        [-74.3905, 45.1277],
                        [-74.3784, 45.1327],
                        [-74.3671, 45.135],
                        [-74.3615, 45.1418],
                        [-74.3561, 45.14],
                        [-74.3493, 45.1457],
                        [-74.3487, 45.1509],
                        [-74.3388, 45.1574],
                        [-74.323, 45.1627],
                        [-74.3115, 45.1647],
                        [-74.2948, 45.173],
                        [-74.2881, 45.1712],
                        [-74.2804, 45.1749],
                        [-74.2654, 45.173],
                        [-74.2461, 45.179],
                        [-74.2401, 45.1753],
                        [-74.2233, 45.1754],
                        [-74.211, 45.1832],
                        [-74.2066, 45.1826],
                        [-74.1901, 45.1894],
                        [-74.1814, 45.1961],
                        [-74.1744, 45.1958],
                        [-74.1633, 45.2015],
                        [-74.1633, 45.2113],
                        [-74.1584, 45.2146],
                        [-74.1579, 45.2243],
                        [-74.1412, 45.2241],
                        [-74.1014, 45.2158],
                        [-74.0874, 45.2149],
                        [-74.0493, 45.2147],
                        [-74.0278, 45.2178],
                        [-74.0004, 45.2253],
                        [-73.9903, 45.2298],
                        [-73.9602, 45.25],
                        [-73.9262, 45.2843],
                        [-73.9221, 45.2913],
                        [-73.9066, 45.3031],
                        [-73.9021, 45.3104],
                        [-73.904, 45.3146],
                        [-73.881, 45.3194],
                        [-73.8762, 45.3165],
                        [-73.8499, 45.3197],
                        [-73.8535, 45.3249],
                        [-73.8465, 45.3285],
                        [-73.8285, 45.3287],
                        [-73.8214, 45.3325],
                        [-73.8116, 45.3433],
                        [-73.7971, 45.3536],
                        [-73.7941, 45.3597],
                        [-73.7831, 45.3657],
                        [-73.775, 45.3768],
                        [-73.7721, 45.3847],
                        [-73.7621, 45.3997],
                        [-73.7369, 45.4027],
                        [-73.7253, 45.3981],
                        [-73.7189, 45.3996],
                        [-73.7148, 45.4059],
                        [-73.7041, 45.4064],
                        [-73.6861, 45.4135],
                        [-73.6853, 45.417],
                        [-73.6698, 45.4144],
                        [-73.6476, 45.4041],
                        [-73.6388, 45.4021],
                        [-73.6243, 45.4019],
                        [-73.5924, 45.4072],
                        [-73.5669, 45.4063],
                        [-73.5516, 45.4012],
                        [-73.5391, 45.4029],
                        [-73.5284, 45.4004],
                        [-73.5117, 45.4066],
                        [-73.501, 45.4155],
                        [-73.4911, 45.4312],
                        [-73.4962, 45.4448],
                        [-73.4967, 45.4646],
                        [-73.5014, 45.4759],
                        [-73.5077, 45.4799],
                        [-73.5204, 45.5063],
                        [-73.5269, 45.5315],
                        [-73.5064, 45.5461],
                        [-73.4858, 45.5708],
                        [-73.462, 45.5935],
                        [-73.4568, 45.6072],
                        [-73.4573, 45.6188],
                        [-73.4525, 45.6326],
                        [-73.4449, 45.6452],
                        [-73.4461, 45.6518],
                        [-73.4426, 45.6622],
                        [-73.4427, 45.6828],
                        [-73.4402, 45.6946],
                        [-73.4312, 45.7086],
                        [-73.4324, 45.7153],
                        [-73.4155, 45.7297],
                        [-73.4037, 45.7414],
                        [-73.3663, 45.7741],
                        [-73.3399, 45.786],
                        [-73.3304, 45.7954],
                        [-73.3226, 45.7992],
                        [-73.3087, 45.8103],
                        [-73.3039, 45.8178],
                        [-73.2846, 45.8287],
                        [-73.2833, 45.8327],
                        [-73.2651, 45.8424],
                        [-73.2484, 45.8494],
                        [-73.2462, 45.854],
                        [-73.2305, 45.8651],
                        [-73.2275, 45.8715],
                        [-73.2188, 45.8804],
                        [-73.211, 45.8978],
                        [-73.2081, 45.8989],
                        [-73.2095, 45.9182],
                        [-73.2036, 45.9487],
                        [-73.196, 45.9609],
                        [-73.1845, 45.9741],
                        [-73.1728, 45.998],
                        [-73.1658, 46.0095],
                        [-73.1531, 46.0367],
                        [-73.1427, 46.046],
                        [-73.127, 46.0492],
                        [-73.118, 46.0466],
                        [-73.0985, 46.0481],
                        [-73.0828, 46.0526],
                        [-73.0702, 46.0585],
                        [-73.0461, 46.0588],
                        [-73.0287, 46.0641],
                        [-73.0167, 46.0622],
                        [-72.9802, 46.072],
                        [-72.9619, 46.0844],
                        [-72.9522, 46.0894],
                        [-72.947, 46.0841],
                        [-72.9513, 46.0724],
                        [-72.9479, 46.0648],
                        [-72.9436, 46.0616],
                        [-72.9395, 46.061],
                        [-72.9393, 46.0642],
                        [-72.942, 46.0676],
                        [-72.9377, 46.0708],
                        [-72.9328, 46.0679],
                        [-72.9197, 46.0679],
                        [-72.9085, 46.0745],
                        [-72.9081, 46.0839],
                        [-72.9217, 46.0904],
                        [-72.9205, 46.0996],
                        [-72.9249, 46.1164],
                        [-72.9215, 46.1244],
                        [-72.9136, 46.1185],
                        [-72.9023, 46.1164],
                        [-72.8892, 46.1218],
                        [-72.8811, 46.1282],
                        [-72.8683, 46.127],
                        [-72.8543, 46.1294],
                        [-72.8394, 46.1371],
                        [-72.8216, 46.1417],
                        [-72.8113, 46.1385],
                        [-72.8023, 46.1389],
                        [-72.798, 46.1449],
                        [-72.773, 46.1471],
                        [-72.7549, 46.1616],
                        [-72.7168, 46.1724],
                        [-72.6951, 46.1903],
                        [-72.664, 46.1952],
                        [-72.6573, 46.1992],
                        [-72.6523, 46.2134],
                        [-72.6597, 46.2212],
                        [-72.6667, 46.2219],
                        [-72.6675, 46.2378],
                        [-72.656, 46.2469],
                        [-72.6534, 46.2524],
                        [-72.6446, 46.2575],
                        [-72.6383, 46.2546],
                        [-72.6278, 46.2613],
                        [-72.6185, 46.2715],
                        [-72.6149, 46.2715],
                        [-72.5868, 46.2824],
                        [-72.5611, 46.295],
                        [-72.5287, 46.3205],
                        [-72.5241, 46.3289],
                        [-72.5045, 46.3382],
                        [-72.4918, 46.3462],
                        [-72.4768, 46.3579],
                        [-72.4584, 46.3658],
                        [-72.4411, 46.378],
                        [-72.4196, 46.3784],
                        [-72.4054, 46.382],
                        [-72.3895, 46.392],
                        [-72.3828, 46.3922],
                        [-72.3795, 46.4003],
                        [-72.371, 46.3997],
                        [-72.3673, 46.3935],
                        [-72.3584, 46.3966],
                        [-72.35, 46.3961],
                        [-72.3406, 46.4004],
                        [-72.3313, 46.3966],
                        [-72.3202, 46.3999],
                        [-72.3153, 46.4056],
                        [-72.2917, 46.4053],
                        [-72.2862, 46.4083],
                        [-72.2605, 46.4166],
                        [-72.2496, 46.417],
                        [-72.2356, 46.4293],
                        [-72.2358, 46.4367],
                        [-72.2254, 46.4484],
                        [-72.2133, 46.4875],
                        [-72.207, 46.4962],
                        [-72.2058, 46.5075],
                        [-72.1882, 46.5152],
                        [-72.1648, 46.5384],
                        [-72.1291, 46.5518],
                        [-72.1267, 46.555],
                        [-72.1115, 46.5599],
                        [-72.1031, 46.5607],
                        [-72.0806, 46.5563],
                        [-72.0695, 46.5608],
                        [-72.0473, 46.561],
                        [-72.0369, 46.5574],
                        [-72.0239, 46.56],
                        [-72.008, 46.5665],
                        [-71.9975, 46.5789],
                        [-71.9798, 46.5912],
                        [-71.9713, 46.6011],
                        [-71.9583, 46.611],
                        [-71.9168, 46.6233],
                        [-71.9048, 46.6317],
                        [-71.8931, 46.6482],
                        [-71.8758, 46.6607],
                        [-71.8609, 46.6667],
                        [-71.8441, 46.6661],
                        [-71.8209, 46.6509],
                        [-71.8123, 46.6415],
                        [-71.7949, 46.6349],
                        [-71.7775, 46.63],
                        [-71.7641, 46.628],
                        [-71.7524, 46.6301],
                        [-71.7406, 46.6274],
                        [-71.6941, 46.6289],
                        [-71.6695, 46.6339],
                        [-71.6423, 46.6377],
                        [-71.6154, 46.6504],
                        [-71.599, 46.6619],
                        [-71.585, 46.6655],
                        [-71.5532, 46.6682],
                        [-71.5363, 46.675],
                        [-71.5129, 46.6826],
                        [-71.4894, 46.6928],
                        [-71.4689, 46.6977],
                        [-71.4525, 46.6995],
                        [-71.4486, 46.7023],
                        [-71.4254, 46.7027],
                        [-71.414, 46.7045],
                        [-71.3965, 46.7046],
                        [-71.3793, 46.7113],
                        [-71.3677, 46.7126],
                        [-71.3372, 46.7228],
                        [-71.3312, 46.7304],
                        [-71.308, 46.7364],
                        [-71.2863, 46.7434],
                        [-71.2762, 46.744],
                        [-71.2517, 46.7514],
                        [-71.2379, 46.7621],
                        [-71.2202, 46.7669],
                        [-71.2066, 46.7741],
                        [-71.1983, 46.7829],
                        [-71.1889, 46.803],
                        [-71.1874, 46.8132],
                        [-71.1823, 46.8206],
                        [-71.1687, 46.8292],
                        [-71.1577, 46.8325],
                        [-71.1543, 46.8289],
                        [-71.1423, 46.8278],
                        [-71.1141, 46.8317],
                        [-71.0987, 46.8278],
                        [-71.0736, 46.8297],
                        [-71.0518, 46.8344],
                        [-71.0402, 46.8325],
                        [-71.0317, 46.8349],
                        [-71.0134, 46.8334],
                        [-70.9878, 46.8346],
                        [-70.9473, 46.8579],
                        [-70.9458, 46.8623],
                        [-70.9295, 46.8733],
                        [-70.9101, 46.8761],
                        [-70.8835, 46.8906],
                        [-70.867, 46.8894],
                        [-70.8584, 46.8861],
                        [-70.8393, 46.8852],
                        [-70.829, 46.8961],
                        [-70.8183, 46.9002],
                        [-70.8062, 46.9106],
                        [-70.7955, 46.9159],
                        [-70.787, 46.9082],
                        [-70.7798, 46.9056],
                        [-70.7688, 46.9061],
                        [-70.7562, 46.9151],
                        [-70.7541, 46.9256],
                        [-70.7296, 46.9373],
                        [-70.7144, 46.9346],
                        [-70.6851, 46.9506],
                        [-70.6479, 46.9672],
                        [-70.6407, 46.9726],
                        [-70.6171, 46.9836],
                        [-70.5993, 46.9864],
                        [-70.5852, 46.9908],
                        [-70.5477, 46.9898],
                        [-70.5493, 46.994],
                        [-70.5359, 46.9963],
                        [-70.516, 47.0026],
                        [-70.4924, 47.0146],
                        [-70.4754, 47.0269],
                        [-70.4685, 47.0357],
                        [-70.4695, 47.0427],
                        [-70.4632, 47.0476],
                        [-70.4541, 47.0631],
                        [-70.4013, 47.1049],
                        [-70.3805, 47.1188],
                        [-70.379, 47.1254],
                        [-70.3441, 47.1444],
                        [-70.3206, 47.1607],
                        [-70.3114, 47.1694],
                        [-70.3116, 47.1743],
                        [-70.3025, 47.1836],
                        [-70.2819, 47.1989],
                        [-70.272, 47.2113],
                        [-70.2717, 47.2157],
                        [-70.2575, 47.2247],
                        [-70.2409, 47.2382],
                        [-70.2342, 47.2485],
                        [-70.2347, 47.258],
                        [-70.2281, 47.2615],
                        [-70.2295, 47.2711],
                        [-70.221, 47.2781],
                        [-70.2165, 47.286],
                        [-70.1943, 47.3091],
                        [-70.1863, 47.3095],
                        [-70.1708, 47.3147],
                        [-70.1562, 47.3139],
                        [-70.1425, 47.3211],
                        [-70.1068, 47.3339],
                        [-70.0858, 47.3497],
                        [-70.0551, 47.3769],
                        [-70.043, 47.3977],
                        [-70.0417, 47.4097],
                        [-70.0471, 47.4268],
                        [-70.0604, 47.4281],
                        [-70.0518, 47.435],
                        [-70.0445, 47.4373],
                        [-70.0326, 47.4462],
                        [-70.0231, 47.4591],
                        [-70.0263, 47.4662],
                        [-70.0331, 47.4688],
                        [-70.0179, 47.4824],
                        [-70.0217, 47.4869],
                        [-69.9978, 47.4985],
                        [-69.9854, 47.4941],
                        [-69.9734, 47.4948],
                        [-69.9486, 47.5164],
                        [-69.941, 47.5211],
                        [-69.9415, 47.5293],
                        [-69.9204, 47.5202],
                        [-69.9042, 47.525],
                        [-69.891, 47.5357],
                        [-69.8846, 47.5433],
                        [-69.8764, 47.5601],
                        [-69.858, 47.5796],
                        [-69.8486, 47.5841],
                        [-69.8287, 47.5881],
                        [-69.8194, 47.592],
                        [-69.8068, 47.6061],
                        [-69.7975, 47.6064],
                        [-69.7782, 47.6214],
                        [-69.7694, 47.6312],
                        [-69.7661, 47.6383],
                        [-69.7443, 47.6576],
                        [-69.7428, 47.6676],
                        [-69.7271, 47.6758],
                        [-69.7157, 47.6862],
                        [-69.7069, 47.6996],
                        [-69.6948, 47.7112],
                        [-69.6809, 47.7198],
                        [-69.6766, 47.7258],
                        [-69.6629, 47.7267],
                        [-69.6478, 47.7316],
                        [-69.629, 47.7477],
                        [-69.6168, 47.7634],
                        [-69.6006, 47.7787],
                        [-69.59, 47.7909],
                        [-69.5973, 47.7942],
                        [-69.587, 47.8023],
                        [-69.5845, 47.8126],
                        [-69.575, 47.8167],
                        [-69.561, 47.8307],
                        [-69.5504, 47.8443],
                        [-69.5594, 47.8527],
                        [-69.5419, 47.8742],
                        [-69.5503, 47.8779],
                        [-69.523, 47.9018],
                        [-69.5136, 47.908],
                        [-69.5072, 47.9199],
                        [-69.5094, 47.9348],
                        [-69.5136, 47.9412],
                        [-69.4956, 47.951],
                        [-69.4877, 47.9471],
                        [-69.4792, 47.949],
                        [-69.4763, 47.9576],
                        [-69.467, 47.9673],
                        [-69.4448, 47.9858],
                        [-69.4333, 47.9966],
                        [-69.4224, 48.0016],
                        [-69.4081, 48.0043],
                        [-69.3928, 48.0181],
                        [-69.3861, 48.0152],
                        [-69.3726, 48.015],
                        [-69.3595, 48.0188],
                        [-69.3498, 48.0138],
                        [-69.3415, 48.021],
                        [-69.342, 48.0283],
                        [-69.3331, 48.0276],
                        [-69.3164, 48.0388],
                        [-69.2745, 48.0696],
                        [-69.2749, 48.0768],
                        [-69.2589, 48.0913],
                        [-69.2446, 48.091],
                        [-69.229, 48.0954],
                        [-69.2181, 48.0954],
                        [-69.2188, 48.1067],
                        [-69.206, 48.1139],
                        [-69.2022, 48.1242],
                        [-69.1891, 48.1313],
                        [-69.1834, 48.1293],
                        [-69.1721, 48.131],
                        [-69.1653, 48.1367],
                        [-69.1649, 48.1418],
                        [-69.1454, 48.1515],
                        [-69.133, 48.1538],
                        [-69.1286, 48.1618],
                        [-69.1333, 48.1699],
                        [-69.116, 48.1796],
                        [-69.1079, 48.1858],
                        [-69.1057, 48.192],
                        [-69.0798, 48.2058],
                        [-69.0602, 48.219],
                        [-69.0564, 48.2244],
                        [-69.0292, 48.239],
                        [-69.0113, 48.25],
                        [-68.9848, 48.2646],
                        [-68.9648, 48.279],
                        [-68.9531, 48.2909],
                        [-68.9327, 48.2998],
                        [-68.9205, 48.3032],
                        [-68.8859, 48.3162],
                        [-68.8754, 48.315],
                        [-68.8585, 48.3216],
                        [-68.844, 48.3365],
                        [-68.8054, 48.3471],
                        [-68.8075, 48.3548],
                        [-68.8274, 48.3506],
                        [-68.8267, 48.3556],
                        [-68.8153, 48.3625],
                        [-68.816, 48.3673],
                        [-68.8076, 48.3693],
                        [-68.7976, 48.3649],
                        [-68.7945, 48.3553],
                        [-68.7778, 48.3569],
                        [-68.7807, 48.3614],
                        [-68.77, 48.364],
                        [-68.769, 48.3712],
                        [-68.7556, 48.3756],
                        [-68.7591, 48.3656],
                        [-68.7563, 48.3563],
                        [-68.73, 48.3669],
                        [-68.7256, 48.3713],
                        [-68.7277, 48.3783],
                        [-68.6827, 48.3962],
                        [-68.6696, 48.393],
                        [-68.6624, 48.3977],
                        [-68.6177, 48.4195],
                        [-68.5966, 48.4281],
                        [-68.5915, 48.4364],
                        [-68.5797, 48.4427],
                        [-68.5673, 48.4405],
                        [-68.5536, 48.4447],
                        [-68.5396, 48.4455],
                        [-68.5293, 48.452],
                        [-68.5209, 48.4601],
                        [-68.5167, 48.4723],
                        [-68.5116, 48.4776],
                        [-68.4938, 48.4872],
                        [-68.4937, 48.4941],
                        [-68.4877, 48.4983],
                        [-68.4635, 48.5096],
                        [-68.4635, 48.518],
                        [-68.4414, 48.5276],
                        [-68.4253, 48.5291],
                        [-68.4127, 48.5384],
                        [-68.3974, 48.5468],
                        [-68.3848, 48.5508],
                        [-68.3708, 48.5464],
                        [-68.3552, 48.5481],
                        [-68.3468, 48.5547],
                        [-68.3298, 48.5593],
                        [-68.302, 48.5797],
                        [-68.2882, 48.5874],
                        [-68.2696, 48.5935],
                        [-68.2495, 48.6023],
                        [-68.2104, 48.6223],
                        [-68.1821, 48.6347],
                        [-68.1642, 48.6404],
                        [-68.1538, 48.6416],
                        [-68.1486, 48.6339],
                        [-68.1342, 48.6303],
                        [-68.1099, 48.6378],
                        [-68.0827, 48.648],
                        [-68.0716, 48.658],
                        [-68.0749, 48.6647],
                        [-68.0631, 48.6734],
                        [-68.0486, 48.6752],
                        [-68.0401, 48.6783],
                        [-68.0364, 48.6729],
                        [-68.0212, 48.6724],
                        [-68.019, 48.6657],
                        [-67.9946, 48.6748],
                        [-67.9844, 48.6804],
                        [-67.957, 48.6865],
                        [-67.9507, 48.6909],
                        [-67.9023, 48.7176],
                        [-67.8823, 48.7308],
                        [-67.8609, 48.74],
                        [-67.8476, 48.7436],
                        [-67.8333, 48.7508],
                        [-67.8097, 48.7557],
                        [-67.7948, 48.7559],
                        [-67.7773, 48.7663],
                        [-67.7526, 48.7722],
                        [-67.7366, 48.7789],
                        [-67.7188, 48.7843],
                        [-67.6964, 48.7883],
                        [-67.6782, 48.7972],
                        [-67.6637, 48.8007],
                        [-67.6383, 48.8111],
                        [-67.604, 48.8181],
                        [-67.5932, 48.8222],
                        [-67.5749, 48.8363],
                        [-67.5753, 48.838],
                        [-67.5522, 48.8497],
                        [-67.5347, 48.8541],
                        [-67.4956, 48.8543],
                        [-67.4815, 48.856],
                        [-67.4551, 48.8634],
                        [-67.4511, 48.8678],
                        [-67.4372, 48.8699],
                        [-67.4173, 48.8772],
                        [-67.3968, 48.8833],
                        [-67.3761, 48.8929],
                        [-67.371, 48.8987],
                        [-67.3532, 48.9014],
                        [-67.3283, 48.9025],
                        [-67.287, 48.9085],
                        [-67.2745, 48.9193],
                        [-67.2347, 48.9254],
                        [-67.2125, 48.9331],
                        [-67.1937, 48.9374],
                        [-67.162, 48.9426],
                        [-67.1276, 48.9563],
                        [-67.1196, 48.9562],
                        [-67.101, 48.9601],
                        [-67.0732, 48.9685],
                        [-67.0639, 48.9752],
                        [-67.0462, 48.9787],
                        [-67.0194, 48.9913],
                        [-66.984, 49.0017],
                        [-66.9788, 49.005],
                        [-66.9697, 49.0029],
                        [-66.9629, 49.0062],
                        [-66.9457, 49.0195],
                        [-66.9294, 49.0212],
                        [-66.9204, 49.0295],
                        [-66.9101, 49.0339],
                        [-66.8906, 49.0377],
                        [-66.856, 49.0495],
                        [-66.8589, 49.0425],
                        [-66.8458, 49.0436],
                        [-66.842, 49.0522],
                        [-66.816, 49.0525],
                        [-66.8054, 49.0554],
                        [-66.7964, 49.0644],
                        [-66.7743, 49.0703],
                        [-66.7532, 49.0786],
                        [-66.7415, 49.0897],
                        [-66.7077, 49.0924],
                        [-66.687, 49.0991],
                        [-66.6757, 49.0991],
                        [-66.6646, 49.1024],
                        [-66.6512, 49.1133],
                        [-66.6248, 49.1099],
                        [-66.6118, 49.1125],
                        [-66.6012, 49.1123],
                        [-66.5805, 49.1195],
                        [-66.5695, 49.1252],
                        [-66.5495, 49.1296],
                        [-66.5367, 49.1254],
                        [-66.5196, 49.1245],
                        [-66.4977, 49.127],
                        [-66.4883, 49.1312],
                        [-66.4767, 49.1305],
                        [-66.453, 49.1397],
                        [-66.4317, 49.1549],
                        [-66.4203, 49.1562],
                        [-66.4159, 49.1598],
                        [-66.3725, 49.1675],
                        [-66.3462, 49.1736],
                        [-66.3301, 49.1787],
                        [-66.304, 49.1812],
                        [-66.2707, 49.1876],
                        [-66.2376, 49.1972],
                        [-66.223, 49.1972],
                        [-66.2038, 49.1997],
                        [-66.1701, 49.207],
                        [-66.1627, 49.2059],
                        [-66.0994, 49.2131],
                        [-66.0867, 49.2164],
                        [-66.0704, 49.216],
                        [-66.0524, 49.2211],
                        [-66.0386, 49.2204],
                        [-65.96, 49.2281],
                        [-65.9421, 49.2269],
                        [-65.9084, 49.228],
                        [-65.8944, 49.2199],
                        [-65.885, 49.2211],
                        [-65.8699, 49.2265],
                        [-65.8508, 49.2314],
                        [-65.8331, 49.2336],
                        [-65.8207, 49.2323],
                        [-65.8183, 49.2269],
                        [-65.8072, 49.2228],
                        [-65.7947, 49.2257],
                        [-65.7859, 49.2341],
                        [-65.7611, 49.2395],
                        [-65.7491, 49.2396],
                        [-65.7349, 49.231],
                        [-65.7298, 49.2305],
                        [-65.7039, 49.2414],
                        [-65.6671, 49.2488],
                        [-65.6504, 49.2422],
                        [-65.6404, 49.2446],
                        [-65.6302, 49.251],
                        [-65.6064, 49.254],
                        [-65.5957, 49.2537],
                        [-65.5563, 49.257],
                        [-65.543, 49.2527],
                        [-65.5272, 49.2551],
                        [-65.5114, 49.2516],
                        [-65.4769, 49.2538],
                        [-65.4604, 49.2539],
                        [-65.436, 49.2514],
                        [-65.4302, 49.2536],
                        [-65.3814, 49.2516],
                        [-65.3645, 49.2525],
                        [-65.3535, 49.249],
                        [-65.3411, 49.2512],
                        [-65.3249, 49.2507],
                        [-65.3278, 49.2455],
                        [-65.321, 49.24],
                        [-65.3051, 49.2398],
                        [-65.2986, 49.2416],
                        [-65.2855, 49.2389],
                        [-65.2781, 49.2331],
                        [-65.27, 49.2351],
                        [-65.2594, 49.2336],
                        [-65.2481, 49.2349],
                        [-65.224, 49.2324],
                        [-65.2015, 49.2284],
                        [-65.1925, 49.2303],
                        [-65.1515, 49.2296],
                        [-65.1366, 49.2301],
                        [-65.1325, 49.2247],
                        [-65.1248, 49.2274],
                        [-65.1071, 49.2292],
                        [-65.0787, 49.2251],
                        [-65.0521, 49.2229],
                        [-65.0476, 49.2237],
                        [-65.0252, 49.2176],
                        [-65.0164, 49.218],
                        [-64.994, 49.2154],
                        [-64.9814, 49.2116],
                        [-64.9489, 49.2084],
                        [-64.9264, 49.2053],
                        [-64.9042, 49.1971],
                        [-64.8813, 49.1939],
                        [-64.8515, 49.1868],
                        [-64.8458, 49.1805],
                        [-64.8346, 49.1779],
                        [-64.8255, 49.1794],
                        [-64.8127, 49.1734],
                        [-64.7968, 49.1609],
                        [-64.7883, 49.1628],
                        [-64.7771, 49.1531],
                        [-64.769, 49.1516],
                        [-64.7471, 49.1417],
                        [-64.7087, 49.1337],
                        [-64.6636, 49.1206],
                        [-64.6429, 49.1188],
                        [-64.6326, 49.1131],
                        [-64.6206, 49.1141],
                        [-64.6016, 49.1088],
                        [-64.5943, 49.1039],
                        [-64.5765, 49.0977],
                        [-64.5666, 49.0909],
                        [-64.5463, 49.0826],
                        [-64.5406, 49.0778],
                        [-64.5243, 49.0747],
                        [-64.507, 49.0683],
                        [-64.4977, 49.0614],
                        [-64.4742, 49.0531],
                        [-64.4723, 49.0465],
                        [-64.4498, 49.0374],
                        [-64.427, 49.0186],
                        [-64.4158, 49.0136],
                        [-64.4087, 49.0136],
                        [-64.3946, 49.0084],
                        [-64.3934, 48.9976],
                        [-64.3784, 48.9961],
                        [-64.3696, 48.9891],
                        [-64.3688, 48.9843],
                        [-64.3593, 48.9777],
                        [-64.3411, 48.9699],
                        [-64.3047, 48.9474],
                        [-64.3101, 48.9427],
                        [-64.3051, 48.9342],
                        [-64.2783, 48.9272],
                        [-64.2717, 48.9236],
                        [-64.2552, 48.9083],
                        [-64.2408, 48.8998],
                        [-64.2228, 48.8814],
                        [-64.2128, 48.8735],
                        [-64.2103, 48.8655],
                        [-64.2006, 48.8562],
                        [-64.2064, 48.8535],
                        [-64.2119, 48.8365],
                        [-64.222, 48.8239],
                        [-64.2216, 48.8112],
                        [-64.216, 48.7976],
                        [-64.2106, 48.7926],
                        [-64.1988, 48.791],
                        [-64.1932, 48.7829],
                        [-64.1865, 48.7795],
                        [-64.1589, 48.7525],
                        [-64.1633, 48.7486],
                        [-64.1896, 48.7634],
                        [-64.2015, 48.7738],
                        [-64.2076, 48.7757],
                        [-64.219, 48.788],
                        [-64.2427, 48.805],
                        [-64.2544, 48.8049],
                        [-64.2762, 48.8171],
                        [-64.3017, 48.8272],
                        [-64.3156, 48.825],
                        [-64.3242, 48.8278],
                        [-64.3286, 48.8332],
                        [-64.3412, 48.8353],
                        [-64.3492, 48.841],
                        [-64.3755, 48.8496],
                        [-64.4133, 48.8556],
                        [-64.4301, 48.8495],
                        [-64.4376, 48.8488],
                        [-64.44, 48.8573],
                        [-64.4569, 48.8615],
                        [-64.4746, 48.8377],
                        [-64.4799, 48.8279],
                        [-64.4609, 48.8238],
                        [-64.4487, 48.8275],
                        [-64.4354, 48.8225],
                        [-64.4108, 48.8174],
                        [-64.401, 48.8233],
                        [-64.4001, 48.8128],
                        [-64.3794, 48.8008],
                        [-64.3773, 48.7963],
                        [-64.3821, 48.7795],
                        [-64.3758, 48.7684],
                        [-64.3489, 48.7587],
                        [-64.3343, 48.7523],
                        [-64.3209, 48.7362],
                        [-64.304, 48.732],
                        [-64.2942, 48.7274],
                        [-64.2915, 48.722],
                        [-64.2809, 48.7188],
                        [-64.2724, 48.7099],
                        [-64.2614, 48.7049],
                        [-64.2535, 48.6857],
                        [-64.2355, 48.6679],
                        [-64.1968, 48.6491],
                        [-64.1889, 48.647],
                        [-64.1741, 48.6274],
                        [-64.168, 48.6261],
                        [-64.1743, 48.6172],
                        [-64.192, 48.6186],
                        [-64.1992, 48.6228],
                        [-64.2419, 48.6246],
                        [-64.2557, 48.6227],
                        [-64.2724, 48.6174],
                        [-64.2956, 48.6173],
                        [-64.3069, 48.6121],
                        [-64.3231, 48.6104],
                        [-64.3127, 48.6051],
                        [-64.3186, 48.5996],
                        [-64.2976, 48.5978],
                        [-64.3018, 48.5916],
                        [-64.2949, 48.5875],
                        [-64.3034, 48.5791],
                        [-64.2949, 48.5721],
                        [-64.2882, 48.5523],
                        [-64.2783, 48.547],
                        [-64.268, 48.5467],
                        [-64.2463, 48.5425],
                        [-64.2306, 48.5357],
                        [-64.2159, 48.5316],
                        [-64.213, 48.5211],
                        [-64.2202, 48.51],
                        [-64.2168, 48.5015],
                        [-64.2283, 48.5014],
                        [-64.2443, 48.4939],
                        [-64.2776, 48.4866],
                        [-64.2883, 48.4831],
                        [-64.3062, 48.4734],
                        [-64.3222, 48.4507],
                        [-64.3292, 48.4342],
                        [-64.325, 48.4283],
                        [-64.3168, 48.4245],
                        [-64.3208, 48.4164],
                        [-64.3356, 48.416],
                        [-64.342, 48.4191],
                        [-64.371, 48.4128],
                        [-64.3883, 48.417],
                        [-64.4155, 48.4084],
                        [-64.432, 48.4053],
                        [-64.4535, 48.4056],
                        [-64.4698, 48.4034],
                        [-64.4923, 48.3928],
                        [-64.4962, 48.3965],
                        [-64.507, 48.3958],
                        [-64.5158, 48.3889],
                        [-64.5267, 48.3852],
                        [-64.5412, 48.3834],
                        [-64.5564, 48.3783],
                        [-64.5815, 48.3752],
                        [-64.5977, 48.3806],
                        [-64.5927, 48.3686],
                        [-64.5986, 48.3644],
                        [-64.6171, 48.3588],
                        [-64.6325, 48.3577],
                        [-64.6488, 48.353],
                        [-64.6575, 48.3457],
                        [-64.6757, 48.3458],
                        [-64.6885, 48.3348],
                        [-64.701, 48.3429],
                        [-64.7102, 48.3384],
                        [-64.7314, 48.3397],
                        [-64.7187, 48.3247],
                        [-64.7095, 48.3212],
                        [-64.7034, 48.3252],
                        [-64.6945, 48.3234],
                        [-64.7002, 48.3137],
                        [-64.6968, 48.3067],
                        [-64.7176, 48.2932],
                        [-64.717, 48.2872],
                        [-64.7298, 48.2857],
                        [-64.7394, 48.2815],
                        [-64.7391, 48.2684],
                        [-64.752, 48.2562],
                        [-64.7621, 48.2401],
                        [-64.7586, 48.2319],
                        [-64.7608, 48.2258],
                        [-64.7724, 48.2203],
                        [-64.7742, 48.2056],
                        [-64.7837, 48.202],
                        [-64.7976, 48.2006],
                        [-64.8041, 48.2028],
                        [-64.8223, 48.202],
                        [-64.8447, 48.192],
                        [-64.8503, 48.1942],
                        [-64.8605, 48.1919],
                        [-64.866, 48.1858],
                        [-64.883, 48.1853],
                        [-64.8942, 48.1872],
                        [-64.9199, 48.183],
                        [-64.9356, 48.1769],
                        [-64.9402, 48.1798],
                        [-64.9518, 48.1785],
                        [-64.9632, 48.1849],
                        [-64.9629, 48.1915],
                        [-64.9768, 48.1972],
                        [-64.9746, 48.1844],
                        [-64.9658, 48.1813],
                        [-64.9782, 48.1656],
                        [-64.9759, 48.1581],
                        [-64.9582, 48.1531],
                        [-64.9566, 48.1491],
                        [-65.0016, 48.1253],
                        [-65.0095, 48.1191],
                        [-65.0257, 48.1119],
                        [-65.0355, 48.1053],
                        [-65.0461, 48.1048],
                        [-65.0622, 48.0987],
                        [-65.071, 48.0922],
                        [-65.0789, 48.0936],
                        [-65.1042, 48.0771],
                        [-65.1166, 48.0751],
                        [-65.1285, 48.0674],
                        [-65.1339, 48.0585],
                        [-65.1671, 48.0434],
                        [-65.1992, 48.0365],
                        [-65.2215, 48.0329],
                        [-65.2352, 48.0274],
                        [-65.2475, 48.0149],
                        [-65.262, 48.0235],
                        [-65.2831, 48.0214],
                        [-65.3028, 48.0166],
                        [-65.3127, 48.0125],
                        [-65.3205, 48.0041],
                        [-65.339, 48.0023],
                        [-65.3508, 48.0042],
                        [-65.4007, 48.002],
                        [-65.4043, 48.0004],
                        [-65.4225, 48.0059],
                        [-65.4396, 48.0063],
                        [-65.4493, 48.0045],
                        [-65.46, 48.0081],
                        [-65.4692, 48.0154],
                        [-65.4718, 48.0232],
                        [-65.4798, 48.0356],
                        [-65.5128, 48.0572],
                        [-65.5281, 48.0648],
                        [-65.5419, 48.0677],
                        [-65.5525, 48.0678],
                        [-65.5873, 48.0732],
                        [-65.5924, 48.0756],
                        [-65.6156, 48.0795],
                        [-65.6288, 48.086],
                        [-65.6758, 48.1005],
                        [-65.6854, 48.1016],
                        [-65.7012, 48.1075],
                        [-65.7298, 48.1141],
                        [-65.7492, 48.1167],
                        [-65.7819, 48.1176],
                        [-65.7867, 48.1194],
                        [-65.8129, 48.1226],
                        [-65.8252, 48.1363],
                        [-65.8538, 48.1534],
                        [-65.8465, 48.1584],
                        [-65.8387, 48.1593],
                        [-65.8411, 48.1677],
                        [-65.8477, 48.168],
                        [-65.854, 48.1609],
                        [-65.8603, 48.1611],
                        [-65.882, 48.1756],
                        [-65.8972, 48.1703],
                        [-65.9019, 48.1757],
                        [-65.8999, 48.1843],
                        [-65.9025, 48.1916],
                        [-65.9132, 48.1978],
                        [-65.9289, 48.1943],
                        [-65.9359, 48.1998],
                        [-65.9474, 48.1957],
                        [-65.9583, 48.195],
                        [-65.9657, 48.1888],
                        [-65.9678, 48.1814],
                        [-65.9967, 48.1644],
                        [-66.014, 48.1529],
                        [-66.0269, 48.1394],
                        [-66.0409, 48.1188],
                        [-66.0507, 48.1103],
                        [-66.0722, 48.1006],
                        [-66.0922, 48.0934],
                        [-66.1073, 48.1003],
                        [-66.1225, 48.0999],
                        [-66.1366, 48.1091],
                        [-66.153, 48.11],
                        [-66.1721, 48.1138],
                        [-66.1826, 48.1129],
                        [-66.1998, 48.1066],
                        [-66.2214, 48.1076],
                        [-66.2329, 48.1108],
                        [-66.245, 48.1075],
                        [-66.2612, 48.1065],
                        [-66.2906, 48.1148],
                        [-66.3026, 48.1139],
                        [-66.3061, 48.1106],
                        [-66.299, 48.0998],
                        [-66.2773, 48.0942],
                        [-66.2722, 48.0893],
                        [-66.2804, 48.0675],
                        [-66.3259, 48.0608]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "35",
            properties: { cityName: "Quebec", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-58.64, 51.2],
                        [-58.6292, 51.1861],
                        [-58.6133, 51.182],
                        [-58.591, 51.1731],
                        [-58.5641, 51.1722],
                        [-58.5739, 51.1621],
                        [-58.5876, 51.1655],
                        [-58.5944, 51.1704],
                        [-58.6043, 51.1675],
                        [-58.6085, 51.1598],
                        [-58.6023, 51.1515],
                        [-58.6198, 51.1371],
                        [-58.647, 51.126],
                        [-58.6555, 51.1209],
                        [-58.6529, 51.1373],
                        [-58.659, 51.1376],
                        [-58.6647, 51.129],
                        [-58.6672, 51.1189],
                        [-58.6768, 51.1159],
                        [-58.6868, 51.1091],
                        [-58.6899, 51.1021],
                        [-58.7186, 51.0901],
                        [-58.7431, 51.0833],
                        [-58.749, 51.0925],
                        [-58.7559, 51.0903],
                        [-58.7529, 51.0816],
                        [-58.7654, 51.0749],
                        [-58.7814, 51.0748],
                        [-58.7869, 51.0868],
                        [-58.7959, 51.0878],
                        [-58.7907, 51.0783],
                        [-58.7948, 51.0628],
                        [-58.8043, 51.0618],
                        [-58.8183, 51.0537],
                        [-58.8186, 51.0684],
                        [-58.8274, 51.0653],
                        [-58.8385, 51.0569],
                        [-58.8329, 51.0489],
                        [-58.8352, 51.0448],
                        [-58.858, 51.0341],
                        [-58.8402, 51.0352],
                        [-58.8286, 51.0333],
                        [-58.8281, 51.0401],
                        [-58.8145, 51.0315],
                        [-58.8178, 51.0188],
                        [-58.829, 51.0189],
                        [-58.8449, 51.0072],
                        [-58.8533, 51.0057],
                        [-58.8622, 51.009],
                        [-58.8633, 50.9948],
                        [-58.8751, 50.9897],
                        [-58.8778, 50.9835],
                        [-58.8904, 50.9722],
                        [-58.9091, 50.9759],
                        [-58.9094, 50.993],
                        [-58.9028, 50.9957],
                        [-58.8933, 51.0132],
                        [-58.8753, 51.0235],
                        [-58.8813, 51.027],
                        [-58.8821, 51.0391],
                        [-58.8902, 51.0502],
                        [-58.9001, 51.0466],
                        [-58.8919, 51.0401],
                        [-58.8967, 51.0349],
                        [-58.9122, 51.0408],
                        [-58.9178, 51.0461],
                        [-58.932, 51.0321],
                        [-58.9343, 51.0239],
                        [-58.9472, 51.0167],
                        [-58.9502, 51.0259],
                        [-58.9571, 51.0278],
                        [-58.9584, 51.0177],
                        [-58.9553, 51.0113],
                        [-58.9642, 51.0038],
                        [-58.9769, 51.0014],
                        [-58.9924, 51.0059],
                        [-58.9997, 51.0019],
                        [-58.9948, 50.9961],
                        [-58.9803, 50.9953],
                        [-58.9764, 50.9912],
                        [-58.9809, 50.9859],
                        [-59.0004, 50.9736],
                        [-59.0031, 50.9816],
                        [-59.0198, 50.9811],
                        [-59.0326, 50.9899],
                        [-59.0362, 50.9892],
                        [-59.0405, 50.9771],
                        [-59.0312, 50.9729],
                        [-59.0145, 50.9723],
                        [-59.0293, 50.9606],
                        [-59.0228, 50.9601],
                        [-59.0114, 50.9661],
                        [-59.0017, 50.9617],
                        [-58.9946, 50.9724],
                        [-58.9784, 50.9845],
                        [-58.9517, 50.9941],
                        [-58.9411, 50.9837],
                        [-58.9492, 50.9779],
                        [-58.9524, 50.9669],
                        [-58.958, 50.9606],
                        [-58.9607, 50.9501],
                        [-58.973, 50.9367],
                        [-58.9636, 50.938],
                        [-58.9477, 50.9358],
                        [-58.9517, 50.9198],
                        [-58.9573, 50.9142],
                        [-58.9502, 50.9061],
                        [-58.9566, 50.8982],
                        [-58.9761, 50.8831],
                        [-58.9624, 50.8829],
                        [-58.9516, 50.8789],
                        [-58.9426, 50.8795],
                        [-58.9444, 50.8624],
                        [-58.953, 50.8673],
                        [-58.9713, 50.8581],
                        [-58.9772, 50.8511],
                        [-58.9716, 50.8446],
                        [-58.9675, 50.8336],
                        [-58.9803, 50.8266],
                        [-58.9765, 50.8193],
                        [-58.9697, 50.8181],
                        [-58.9691, 50.8272],
                        [-58.9416, 50.8299],
                        [-58.9433, 50.819],
                        [-58.953, 50.8202],
                        [-58.9536, 50.8111],
                        [-58.9682, 50.8039],
                        [-58.9746, 50.7912],
                        [-58.9849, 50.7853],
                        [-58.9868, 50.7785],
                        [-58.9931, 50.7726],
                        [-59.0096, 50.7627],
                        [-59.0249, 50.7704],
                        [-59.0269, 50.777],
                        [-59.0362, 50.7787],
                        [-59.0497, 50.7713],
                        [-59.0465, 50.769],
                        [-59.025, 50.7658],
                        [-59.0173, 50.7557],
                        [-59.0164, 50.747],
                        [-59.0014, 50.7414],
                        [-59.0074, 50.7323],
                        [-59.0209, 50.7379],
                        [-59.046, 50.7436],
                        [-59.0531, 50.7485],
                        [-59.0619, 50.7602],
                        [-59.0556, 50.7659],
                        [-59.0685, 50.7683],
                        [-59.0773, 50.7629],
                        [-59.0834, 50.7789],
                        [-59.0776, 50.7846],
                        [-59.0869, 50.7874],
                        [-59.0732, 50.8012],
                        [-59.0876, 50.803],
                        [-59.0806, 50.812],
                        [-59.083, 50.8187],
                        [-59.0712, 50.8279],
                        [-59.0654, 50.8295],
                        [-59.0617, 50.8514],
                        [-59.0486, 50.8556],
                        [-59.0444, 50.8666],
                        [-59.048, 50.8828],
                        [-59.0578, 50.8783],
                        [-59.0711, 50.8819],
                        [-59.0814, 50.8772],
                        [-59.0895, 50.8679],
                        [-59.0883, 50.8555],
                        [-59.0713, 50.8574],
                        [-59.0716, 50.8458],
                        [-59.0834, 50.8342],
                        [-59.0824, 50.8274],
                        [-59.089, 50.8217],
                        [-59.091, 50.813],
                        [-59.1014, 50.8008],
                        [-59.0953, 50.7969],
                        [-59.105, 50.7839],
                        [-59.1132, 50.7858],
                        [-59.1227, 50.7813],
                        [-59.1203, 50.7767],
                        [-59.1308, 50.7738],
                        [-59.135, 50.7618],
                        [-59.1498, 50.7594],
                        [-59.1738, 50.7502],
                        [-59.1997, 50.7527],
                        [-59.1851, 50.7432],
                        [-59.1768, 50.7409],
                        [-59.1763, 50.731],
                        [-59.1875, 50.7275],
                        [-59.188, 50.7224],
                        [-59.1963, 50.7193],
                        [-59.1928, 50.7337],
                        [-59.2088, 50.7276],
                        [-59.2126, 50.7354],
                        [-59.2226, 50.7373],
                        [-59.2186, 50.7426],
                        [-59.2206, 50.7506],
                        [-59.2308, 50.7449],
                        [-59.2501, 50.7517],
                        [-59.2575, 50.741],
                        [-59.2491, 50.7298],
                        [-59.246, 50.7185],
                        [-59.2487, 50.7062],
                        [-59.2617, 50.7036],
                        [-59.2734, 50.6954],
                        [-59.2926, 50.6896],
                        [-59.2972, 50.6938],
                        [-59.3119, 50.695],
                        [-59.3193, 50.6624],
                        [-59.3303, 50.6474],
                        [-59.3429, 50.647],
                        [-59.3493, 50.6509],
                        [-59.363, 50.6471],
                        [-59.381, 50.6446],
                        [-59.4026, 50.6261],
                        [-59.4044, 50.6179],
                        [-59.4296, 50.6153],
                        [-59.435, 50.617],
                        [-59.4508, 50.6125],
                        [-59.4474, 50.5999],
                        [-59.4518, 50.5944],
                        [-59.4596, 50.576],
                        [-59.4686, 50.5696],
                        [-59.4622, 50.5645],
                        [-59.4656, 50.5534],
                        [-59.4746, 50.5486],
                        [-59.4836, 50.5482],
                        [-59.486, 50.5553],
                        [-59.508, 50.5479],
                        [-59.5184, 50.5148],
                        [-59.5388, 50.5163],
                        [-59.5457, 50.5145],
                        [-59.5558, 50.5022],
                        [-59.5505, 50.4976],
                        [-59.5574, 50.4926],
                        [-59.5585, 50.4853],
                        [-59.5672, 50.4812],
                        [-59.5712, 50.4666],
                        [-59.5885, 50.4596],
                        [-59.5921, 50.4628],
                        [-59.5797, 50.4772],
                        [-59.5767, 50.4915],
                        [-59.5922, 50.5174],
                        [-59.6001, 50.5223],
                        [-59.5978, 50.5307],
                        [-59.6042, 50.5376],
                        [-59.6002, 50.5444],
                        [-59.5901, 50.5512],
                        [-59.5861, 50.5576],
                        [-59.5734, 50.5658],
                        [-59.5705, 50.572],
                        [-59.5736, 50.5806],
                        [-59.586, 50.5659],
                        [-59.5986, 50.5581],
                        [-59.6034, 50.5486],
                        [-59.6163, 50.5424],
                        [-59.6189, 50.5351],
                        [-59.6097, 50.5296],
                        [-59.6072, 50.5223],
                        [-59.593, 50.5097],
                        [-59.5903, 50.4957],
                        [-59.5836, 50.4888],
                        [-59.5894, 50.4818],
                        [-59.5923, 50.4724],
                        [-59.6014, 50.4631],
                        [-59.61, 50.4597],
                        [-59.6333, 50.4545],
                        [-59.6444, 50.4571],
                        [-59.6505, 50.4532],
                        [-59.6677, 50.4506],
                        [-59.6781, 50.4449],
                        [-59.6953, 50.4481],
                        [-59.7057, 50.4379],
                        [-59.7114, 50.4416],
                        [-59.7243, 50.4437],
                        [-59.7291, 50.4275],
                        [-59.7348, 50.4245],
                        [-59.7477, 50.4259],
                        [-59.7552, 50.4241],
                        [-59.7645, 50.4173],
                        [-59.7684, 50.4237],
                        [-59.7752, 50.426],
                        [-59.7867, 50.4212],
                        [-59.7963, 50.4242],
                        [-59.7773, 50.4372],
                        [-59.7733, 50.4431],
                        [-59.7819, 50.4448],
                        [-59.7852, 50.4379],
                        [-59.7931, 50.4309],
                        [-59.8067, 50.4254],
                        [-59.8165, 50.4065],
                        [-59.8244, 50.3999],
                        [-59.8448, 50.3887],
                        [-59.8584, 50.3846],
                        [-59.8438, 50.38],
                        [-59.8534, 50.3734],
                        [-59.866, 50.368],
                        [-59.8856, 50.3671],
                        [-59.8746, 50.3593],
                        [-59.8689, 50.3517],
                        [-59.8553, 50.3542],
                        [-59.8439, 50.3535],
                        [-59.8282, 50.3569],
                        [-59.8189, 50.3516],
                        [-59.8439, 50.3446],
                        [-59.8582, 50.3462],
                        [-59.8593, 50.3419],
                        [-59.8493, 50.3398],
                        [-59.8422, 50.3325],
                        [-59.8565, 50.3264],
                        [-59.8426, 50.3215],
                        [-59.8394, 50.3147],
                        [-59.8169, 50.3256],
                        [-59.8276, 50.3279],
                        [-59.8204, 50.3336],
                        [-59.8265, 50.3395],
                        [-59.8154, 50.3448],
                        [-59.8104, 50.3429],
                        [-59.8004, 50.3299],
                        [-59.8077, 50.3178],
                        [-59.8155, 50.3128],
                        [-59.828, 50.3094],
                        [-59.8445, 50.3074],
                        [-59.867, 50.2878],
                        [-59.8834, 50.2853],
                        [-59.8927, 50.2803],
                        [-59.9055, 50.2856],
                        [-59.907, 50.2769],
                        [-59.9131, 50.2689],
                        [-59.9206, 50.2714],
                        [-59.9388, 50.2641],
                        [-59.9374, 50.2456],
                        [-59.9498, 50.2518],
                        [-59.9466, 50.2657],
                        [-59.9377, 50.2708],
                        [-59.9352, 50.2781],
                        [-59.9491, 50.2833],
                        [-59.9505, 50.2742],
                        [-59.9591, 50.2735],
                        [-59.9662, 50.2898],
                        [-59.9594, 50.306],
                        [-59.9494, 50.3114],
                        [-59.9429, 50.3219],
                        [-59.9336, 50.3281],
                        [-59.9275, 50.3359],
                        [-59.9371, 50.3393],
                        [-59.9447, 50.3315],
                        [-59.9582, 50.3293],
                        [-59.965, 50.345],
                        [-59.9768, 50.3403],
                        [-59.9828, 50.351],
                        [-60.0068, 50.3481],
                        [-60.0132, 50.3373],
                        [-60.0139, 50.3298],
                        [-59.9979, 50.3291],
                        [-59.9857, 50.3332],
                        [-59.9814, 50.3302],
                        [-59.9907, 50.3229],
                        [-59.9665, 50.3247],
                        [-59.9615, 50.3224],
                        [-59.9608, 50.3085],
                        [-59.9708, 50.3048],
                        [-59.9676, 50.2987],
                        [-59.9783, 50.2831],
                        [-59.9665, 50.2827],
                        [-59.9669, 50.2719],
                        [-59.9605, 50.268],
                        [-59.9677, 50.2562],
                        [-59.9798, 50.2505],
                        [-59.9716, 50.2445],
                        [-59.9866, 50.2384],
                        [-60.0008, 50.2445],
                        [-60.0162, 50.2409],
                        [-60.0218, 50.2452],
                        [-60.0104, 50.2587],
                        [-59.9991, 50.2628],
                        [-59.9937, 50.2706],
                        [-59.9954, 50.2756],
                        [-60.0086, 50.2754],
                        [-60.0201, 50.2555],
                        [-60.0279, 50.2494],
                        [-60.0361, 50.2485],
                        [-60.0361, 50.2402],
                        [-60.0254, 50.2379],
                        [-60.0361, 50.2263],
                        [-60.039, 50.219],
                        [-60.0479, 50.2247],
                        [-60.0553, 50.2375],
                        [-60.066, 50.2393],
                        [-60.0739, 50.2345],
                        [-60.0824, 50.2418],
                        [-60.092, 50.2334],
                        [-60.0853, 50.2268],
                        [-60.087, 50.2204],
                        [-60.0963, 50.2185],
                        [-60.1059, 50.2268],
                        [-60.0988, 50.2359],
                        [-60.1109, 50.2345],
                        [-60.1173, 50.2284],
                        [-60.1191, 50.2215],
                        [-60.1358, 50.2146],
                        [-60.1262, 50.2105],
                        [-60.1344, 50.2055],
                        [-60.1354, 50.1996],
                        [-60.145, 50.1971],
                        [-60.1514, 50.1902],
                        [-60.1632, 50.1879],
                        [-60.1749, 50.1931],
                        [-60.1725, 50.2064],
                        [-60.1629, 50.2034],
                        [-60.1515, 50.2133],
                        [-60.1551, 50.2215],
                        [-60.165, 50.219],
                        [-60.1725, 50.2126],
                        [-60.1814, 50.2126],
                        [-60.1942, 50.2048],
                        [-60.1829, 50.2254],
                        [-60.1725, 50.2292],
                        [-60.148, 50.2443],
                        [-60.1768, 50.2377],
                        [-60.179, 50.2434],
                        [-60.1879, 50.2452],
                        [-60.1872, 50.2505],
                        [-60.174, 50.2628],
                        [-60.1619, 50.2681],
                        [-60.1741, 50.2708],
                        [-60.1826, 50.2649],
                        [-60.1929, 50.2646],
                        [-60.1812, 50.2752],
                        [-60.1994, 50.2754],
                        [-60.2047, 50.2676],
                        [-60.2136, 50.2639],
                        [-60.2445, 50.2264],
                        [-60.2526, 50.22],
                        [-60.2637, 50.2168],
                        [-60.2875, 50.2177],
                        [-60.2911, 50.2213],
                        [-60.2848, 50.2314],
                        [-60.2709, 50.2376],
                        [-60.2563, 50.249],
                        [-60.2428, 50.2634],
                        [-60.2688, 50.252],
                        [-60.2674, 50.2467],
                        [-60.2791, 50.241],
                        [-60.2859, 50.2529],
                        [-60.2792, 50.2593],
                        [-60.2888, 50.2606],
                        [-60.2924, 50.2663],
                        [-60.2935, 50.2812],
                        [-60.2883, 50.2949],
                        [-60.2658, 50.3061],
                        [-60.2498, 50.3201],
                        [-60.2531, 50.3276],
                        [-60.2631, 50.3315],
                        [-60.2809, 50.3292],
                        [-60.2874, 50.3388],
                        [-60.3035, 50.3362],
                        [-60.3023, 50.3278],
                        [-60.3134, 50.3182],
                        [-60.3122, 50.3104],
                        [-60.2997, 50.3072],
                        [-60.3011, 50.2979],
                        [-60.309, 50.2946],
                        [-60.3135, 50.2786],
                        [-60.301, 50.2748],
                        [-60.3003, 50.27],
                        [-60.308, 50.2551],
                        [-60.2988, 50.2526],
                        [-60.2969, 50.2483],
                        [-60.3107, 50.2263],
                        [-60.3193, 50.2297],
                        [-60.3243, 50.2363],
                        [-60.3384, 50.2025],
                        [-60.3441, 50.2068],
                        [-60.3371, 50.2208],
                        [-60.3428, 50.2279],
                        [-60.3588, 50.2301],
                        [-60.3688, 50.2278],
                        [-60.373, 50.2157],
                        [-60.3626, 50.2043],
                        [-60.3733, 50.2038],
                        [-60.3879, 50.2111],
                        [-60.3951, 50.2206],
                        [-60.4019, 50.2227],
                        [-60.4159, 50.2389],
                        [-60.4262, 50.2329],
                        [-60.4337, 50.2317],
                        [-60.4411, 50.2219],
                        [-60.4486, 50.2237],
                        [-60.4447, 50.2376],
                        [-60.4682, 50.2344],
                        [-60.4818, 50.2366],
                        [-60.4967, 50.2336],
                        [-60.5036, 50.2459],
                        [-60.511, 50.2328],
                        [-60.5305, 50.2291],
                        [-60.5421, 50.2154],
                        [-60.5449, 50.2067],
                        [-60.5559, 50.2041],
                        [-60.5929, 50.2033],
                        [-60.5971, 50.1943],
                        [-60.6074, 50.1963],
                        [-60.63, 50.2074],
                        [-60.6269, 50.2129],
                        [-60.6278, 50.2287],
                        [-60.6382, 50.235],
                        [-60.6451, 50.2174],
                        [-60.6446, 50.2101],
                        [-60.6592, 50.2103],
                        [-60.6695, 50.2075],
                        [-60.6859, 50.2085],
                        [-60.6765, 50.2227],
                        [-60.6861, 50.2227],
                        [-60.697, 50.208],
                        [-60.7056, 50.216],
                        [-60.723, 50.215],
                        [-60.7252, 50.2197],
                        [-60.7208, 50.2323],
                        [-60.7332, 50.2304],
                        [-60.7447, 50.2313],
                        [-60.7578, 50.2287],
                        [-60.7655, 50.2234],
                        [-60.7704, 50.2156],
                        [-60.7731, 50.203],
                        [-60.7863, 50.2064],
                        [-60.7876, 50.2002],
                        [-60.7996, 50.1949],
                        [-60.8111, 50.1982],
                        [-60.8238, 50.1917],
                        [-60.8284, 50.2132],
                        [-60.8198, 50.2207],
                        [-60.7842, 50.2403],
                        [-60.8037, 50.2369],
                        [-60.8269, 50.2193],
                        [-60.8351, 50.2054],
                        [-60.8419, 50.209],
                        [-60.8645, 50.1981],
                        [-60.8541, 50.2137],
                        [-60.8439, 50.224],
                        [-60.8365, 50.2255],
                        [-60.8309, 50.234],
                        [-60.8139, 50.2405],
                        [-60.7919, 50.2463],
                        [-60.7881, 50.2527],
                        [-60.793, 50.2692],
                        [-60.7848, 50.2717],
                        [-60.7634, 50.2709],
                        [-60.7523, 50.2678],
                        [-60.7492, 50.2728],
                        [-60.735, 50.2768],
                        [-60.7398, 50.2871],
                        [-60.7486, 50.2815],
                        [-60.7621, 50.2801],
                        [-60.7669, 50.2908],
                        [-60.7723, 50.2903],
                        [-60.7793, 50.2807],
                        [-60.8017, 50.278],
                        [-60.8051, 50.2894],
                        [-60.8215, 50.2886],
                        [-60.8335, 50.2819],
                        [-60.85, 50.288],
                        [-60.8626, 50.2947],
                        [-60.8621, 50.2856],
                        [-60.8495, 50.28],
                        [-60.8352, 50.2785],
                        [-60.8343, 50.268],
                        [-60.8275, 50.2634],
                        [-60.8125, 50.261],
                        [-60.8029, 50.2641],
                        [-60.7956, 50.2518],
                        [-60.8051, 50.2458],
                        [-60.8222, 50.2454],
                        [-60.8385, 50.2398],
                        [-60.8412, 50.2343],
                        [-60.8533, 50.2283],
                        [-60.8621, 50.2264],
                        [-60.867, 50.2184],
                        [-60.8751, 50.2142],
                        [-60.8824, 50.2023],
                        [-60.8925, 50.2098],
                        [-60.9031, 50.2083],
                        [-60.9087, 50.2039],
                        [-60.9205, 50.2064],
                        [-60.9197, 50.1982],
                        [-60.9276, 50.1823],
                        [-60.9356, 50.177],
                        [-60.9501, 50.189],
                        [-60.946, 50.1957],
                        [-60.9518, 50.2039],
                        [-60.9459, 50.2121],
                        [-60.9339, 50.2161],
                        [-60.9343, 50.2211],
                        [-60.9412, 50.2277],
                        [-60.9314, 50.2335],
                        [-60.9171, 50.2327],
                        [-60.9147, 50.2375],
                        [-60.9293, 50.2374],
                        [-60.9461, 50.2398],
                        [-60.9538, 50.2324],
                        [-60.9648, 50.2316],
                        [-60.9823, 50.2333],
                        [-60.9975, 50.2282],
                        [-61.0016, 50.2364],
                        [-61.0183, 50.2518],
                        [-61.0236, 50.2472],
                        [-61.0098, 50.2352],
                        [-61.0079, 50.2313],
                        [-60.9877, 50.2193],
                        [-60.9792, 50.2196],
                        [-60.9615, 50.2273],
                        [-60.9526, 50.2283],
                        [-60.949, 50.2228],
                        [-60.9662, 50.1937],
                        [-60.9689, 50.2019],
                        [-60.9781, 50.1995],
                        [-60.9895, 50.2033],
                        [-60.9973, 50.1996],
                        [-60.9996, 50.2081],
                        [-61.0102, 50.2052],
                        [-61.0232, 50.2115],
                        [-61.0294, 50.2179],
                        [-61.0383, 50.2182],
                        [-61.0471, 50.2134],
                        [-61.0519, 50.2053],
                        [-61.0631, 50.2142],
                        [-61.0615, 50.2212],
                        [-61.0708, 50.2248],
                        [-61.0767, 50.233],
                        [-61.0762, 50.2417],
                        [-61.093, 50.2591],
                        [-61.104, 50.2599],
                        [-61.1106, 50.2681],
                        [-61.1224, 50.2698],
                        [-61.13, 50.2737],
                        [-61.1393, 50.2729],
                        [-61.1573, 50.267],
                        [-61.1688, 50.2708],
                        [-61.1686, 50.2779],
                        [-61.1803, 50.2757],
                        [-61.1916, 50.2834],
                        [-61.2087, 50.2836],
                        [-61.2217, 50.2881],
                        [-61.2251, 50.2961],
                        [-61.2446, 50.307],
                        [-61.2525, 50.3088],
                        [-61.2649, 50.3201],
                        [-61.2644, 50.3286],
                        [-61.2595, 50.3304],
                        [-61.258, 50.3419],
                        [-61.24, 50.3517],
                        [-61.234, 50.3689],
                        [-61.2359, 50.3732],
                        [-61.2498, 50.3712],
                        [-61.2543, 50.3632],
                        [-61.2672, 50.3671],
                        [-61.2742, 50.3593],
                        [-61.2832, 50.3638],
                        [-61.2851, 50.3521],
                        [-61.2896, 50.3468],
                        [-61.2735, 50.3454],
                        [-61.266, 50.3347],
                        [-61.2744, 50.3257],
                        [-61.2829, 50.3247],
                        [-61.2718, 50.3107],
                        [-61.2622, 50.3098],
                        [-61.2516, 50.2997],
                        [-61.2111, 50.2788],
                        [-61.206, 50.2745],
                        [-61.2141, 50.2694],
                        [-61.2097, 50.2649],
                        [-61.1956, 50.2742],
                        [-61.1891, 50.2742],
                        [-61.1658, 50.264],
                        [-61.1377, 50.2647],
                        [-61.1295, 50.2677],
                        [-61.0946, 50.2525],
                        [-61.0829, 50.2403],
                        [-61.0948, 50.2274],
                        [-61.0897, 50.2221],
                        [-61.0765, 50.2239],
                        [-61.0724, 50.2136],
                        [-61.0588, 50.1968],
                        [-61.0626, 50.1895],
                        [-61.0566, 50.174],
                        [-61.0691, 50.1762],
                        [-61.0803, 50.1868],
                        [-61.0892, 50.1867],
                        [-61.0923, 50.1965],
                        [-61.1055, 50.198],
                        [-61.1167, 50.1899],
                        [-61.1162, 50.1817],
                        [-61.1224, 50.1759],
                        [-61.1386, 50.1831],
                        [-61.1542, 50.1811],
                        [-61.1555, 50.188],
                        [-61.1627, 50.1934],
                        [-61.1774, 50.196],
                        [-61.1815, 50.1918],
                        [-61.1722, 50.1878],
                        [-61.1667, 50.1812],
                        [-61.1676, 50.1755],
                        [-61.1785, 50.1681],
                        [-61.1853, 50.1719],
                        [-61.1874, 50.1824],
                        [-61.2023, 50.1802],
                        [-61.2085, 50.1902],
                        [-61.2306, 50.1931],
                        [-61.2331, 50.1879],
                        [-61.2623, 50.1896],
                        [-61.2685, 50.1832],
                        [-61.2852, 50.181],
                        [-61.2903, 50.1871],
                        [-61.3038, 50.1835],
                        [-61.3223, 50.1835],
                        [-61.33, 50.1786],
                        [-61.341, 50.1792],
                        [-61.3519, 50.1752],
                        [-61.3502, 50.1701],
                        [-61.3855, 50.1673],
                        [-61.3916, 50.1688],
                        [-61.4008, 50.1653],
                        [-61.4154, 50.1674],
                        [-61.4892, 50.1491],
                        [-61.5477, 50.1308],
                        [-61.5782, 50.1183],
                        [-61.6212, 50.1068],
                        [-61.6464, 50.0968],
                        [-61.7226, 50.0816],
                        [-61.7443, 50.081],
                        [-61.7716, 50.0891],
                        [-61.7932, 50.0981],
                        [-61.7941, 50.1226],
                        [-61.8042, 50.1252],
                        [-61.8012, 50.141],
                        [-61.8014, 50.1563],
                        [-61.8075, 50.1674],
                        [-61.8152, 50.1723],
                        [-61.8181, 50.1837],
                        [-61.8465, 50.1917],
                        [-61.8618, 50.1903],
                        [-61.865, 50.1999],
                        [-61.8406, 50.2039],
                        [-61.8523, 50.2115],
                        [-61.8615, 50.21],
                        [-61.8678, 50.217],
                        [-61.8773, 50.2141],
                        [-61.8964, 50.2195],
                        [-61.9103, 50.2193],
                        [-61.9222, 50.2147],
                        [-61.9138, 50.2279],
                        [-61.9296, 50.221],
                        [-61.9312, 50.2164],
                        [-61.9488, 50.2198],
                        [-61.9529, 50.2252],
                        [-61.9636, 50.2243],
                        [-61.9733, 50.2182],
                        [-61.9763, 50.2127],
                        [-61.9898, 50.2125],
                        [-61.9912, 50.2026],
                        [-62.0007, 50.2077],
                        [-62.0121, 50.2091],
                        [-62.0216, 50.214],
                        [-62.0395, 50.2176],
                        [-62.0385, 50.21],
                        [-62.0632, 50.2126],
                        [-62.0752, 50.2094],
                        [-62.0816, 50.2107],
                        [-62.0938, 50.2198],
                        [-62.1114, 50.2236],
                        [-62.1105, 50.2184],
                        [-62.1001, 50.2165],
                        [-62.0977, 50.212],
                        [-62.117, 50.213],
                        [-62.1324, 50.2223],
                        [-62.146, 50.2235],
                        [-62.1579, 50.228],
                        [-62.1758, 50.2286],
                        [-62.1918, 50.2274],
                        [-62.2093, 50.2212],
                        [-62.2372, 50.2321],
                        [-62.2495, 50.2419],
                        [-62.2603, 50.246],
                        [-62.2938, 50.2534],
                        [-62.2999, 50.2459],
                        [-62.3099, 50.2462],
                        [-62.312, 50.2535],
                        [-62.3211, 50.2574],
                        [-62.325, 50.2626],
                        [-62.3399, 50.2689],
                        [-62.3454, 50.2866],
                        [-62.3514, 50.2856],
                        [-62.3505, 50.2662],
                        [-62.36, 50.2571],
                        [-62.3702, 50.2619],
                        [-62.3791, 50.2608],
                        [-62.4018, 50.2668],
                        [-62.3984, 50.2762],
                        [-62.4098, 50.2831],
                        [-62.421, 50.2783],
                        [-62.4224, 50.2716],
                        [-62.4406, 50.2573],
                        [-62.4472, 50.2466],
                        [-62.4611, 50.2461],
                        [-62.4687, 50.2485],
                        [-62.4832, 50.2601],
                        [-62.5015, 50.2629],
                        [-62.5051, 50.2557],
                        [-62.5199, 50.2533],
                        [-62.5295, 50.2588],
                        [-62.528, 50.2717],
                        [-62.539, 50.2705],
                        [-62.5449, 50.2734],
                        [-62.558, 50.2724],
                        [-62.5632, 50.2695],
                        [-62.5666, 50.2596],
                        [-62.5914, 50.2634],
                        [-62.6022, 50.2579],
                        [-62.6108, 50.2582],
                        [-62.6269, 50.2656],
                        [-62.6517, 50.2632],
                        [-62.6594, 50.2672],
                        [-62.6771, 50.264],
                        [-62.6971, 50.2645],
                        [-62.7097, 50.2676],
                        [-62.718, 50.2633],
                        [-62.7237, 50.2678],
                        [-62.7401, 50.2678],
                        [-62.7348, 50.276],
                        [-62.7407, 50.2788],
                        [-62.7078, 50.3026],
                        [-62.7231, 50.3025],
                        [-62.7314, 50.2968],
                        [-62.7433, 50.2927],
                        [-62.755, 50.2922],
                        [-62.7646, 50.2853],
                        [-62.7732, 50.2911],
                        [-62.7635, 50.3025],
                        [-62.772, 50.3023],
                        [-62.7863, 50.2827],
                        [-62.7956, 50.2773],
                        [-62.8037, 50.2816],
                        [-62.8047, 50.299],
                        [-62.8006, 50.3078],
                        [-62.803, 50.3128],
                        [-62.8204, 50.3055],
                        [-62.8113, 50.2965],
                        [-62.8131, 50.2899],
                        [-62.8096, 50.2794],
                        [-62.8203, 50.2739],
                        [-62.8257, 50.2681],
                        [-62.8369, 50.2708],
                        [-62.8468, 50.2683],
                        [-62.8546, 50.274],
                        [-62.87, 50.2691],
                        [-62.876, 50.2739],
                        [-62.8788, 50.2839],
                        [-62.8878, 50.2803],
                        [-62.9317, 50.2751],
                        [-62.9367, 50.2695],
                        [-62.9463, 50.2685],
                        [-62.9495, 50.2735],
                        [-62.9605, 50.2787],
                        [-62.9696, 50.275],
                        [-62.9868, 50.2773],
                        [-62.9812, 50.2843],
                        [-62.9926, 50.2889],
                        [-62.9841, 50.2964],
                        [-62.988, 50.3011],
                        [-63.0062, 50.3013],
                        [-63.0184, 50.3081],
                        [-63.027, 50.2969],
                        [-63.0346, 50.2935],
                        [-63.0457, 50.2941],
                        [-63.0529, 50.2887],
                        [-63.036, 50.2864],
                        [-63.042, 50.2807],
                        [-63.0535, 50.2816],
                        [-63.0579, 50.2725],
                        [-63.0688, 50.2693],
                        [-63.0865, 50.2729],
                        [-63.1034, 50.2691],
                        [-63.1109, 50.2702],
                        [-63.1124, 50.277],
                        [-63.1411, 50.2786],
                        [-63.1556, 50.2823],
                        [-63.1916, 50.271],
                        [-63.1912, 50.2653],
                        [-63.1762, 50.2598],
                        [-63.1636, 50.2471],
                        [-63.1553, 50.2448],
                        [-63.1564, 50.24],
                        [-63.1667, 50.2392],
                        [-63.1774, 50.2341],
                        [-63.1887, 50.2334],
                        [-63.2038, 50.2293],
                        [-63.2137, 50.2338],
                        [-63.2305, 50.2242],
                        [-63.2212, 50.2178],
                        [-63.2234, 50.2139],
                        [-63.2412, 50.2187],
                        [-63.253, 50.2247],
                        [-63.2623, 50.2242],
                        [-63.2712, 50.2194],
                        [-63.2974, 50.2159],
                        [-63.3072, 50.2191],
                        [-63.3166, 50.2255],
                        [-63.3244, 50.2252],
                        [-63.3319, 50.2207],
                        [-63.3408, 50.2209],
                        [-63.3491, 50.2275],
                        [-63.3648, 50.227],
                        [-63.3893, 50.2158],
                        [-63.3962, 50.2069],
                        [-63.3995, 50.1942],
                        [-63.4179, 50.1969],
                        [-63.427, 50.2044],
                        [-63.4366, 50.2039],
                        [-63.4461, 50.1977],
                        [-63.457, 50.2054],
                        [-63.4592, 50.216],
                        [-63.4537, 50.2243],
                        [-63.4636, 50.2324],
                        [-63.4715, 50.2424],
                        [-63.4925, 50.2464],
                        [-63.5078, 50.2468],
                        [-63.5209, 50.2506],
                        [-63.5618, 50.2477],
                        [-63.5969, 50.2311],
                        [-63.6109, 50.2289],
                        [-63.6151, 50.2309],
                        [-63.6144, 50.2431],
                        [-63.6199, 50.2475],
                        [-63.6293, 50.2488],
                        [-63.6411, 50.2587],
                        [-63.6493, 50.2544],
                        [-63.6576, 50.2456],
                        [-63.6814, 50.2444],
                        [-63.6858, 50.2459],
                        [-63.6895, 50.2618],
                        [-63.694, 50.2692],
                        [-63.7036, 50.278],
                        [-63.7102, 50.2803],
                        [-63.7425, 50.2869],
                        [-63.7611, 50.287],
                        [-63.7745, 50.2845],
                        [-63.7847, 50.2786],
                        [-63.7936, 50.2811],
                        [-63.8456, 50.2867],
                        [-63.8509, 50.2847],
                        [-63.8702, 50.2932],
                        [-63.8814, 50.2905],
                        [-63.9021, 50.2898],
                        [-63.9132, 50.2947],
                        [-63.9364, 50.2942],
                        [-63.9455, 50.2964],
                        [-63.9795, 50.2893],
                        [-63.9809, 50.2972],
                        [-63.9979, 50.3059],
                        [-63.9959, 50.297],
                        [-64.0012, 50.2926],
                        [-64.0251, 50.2895],
                        [-64.0294, 50.2867],
                        [-64.0576, 50.2888],
                        [-64.0816, 50.2918],
                        [-64.0974, 50.2882],
                        [-64.1255, 50.2752],
                        [-64.1507, 50.2602],
                        [-64.1618, 50.2553],
                        [-64.2037, 50.265],
                        [-64.2312, 50.2662],
                        [-64.2583, 50.2687],
                        [-64.2874, 50.2733],
                        [-64.3196, 50.2812],
                        [-64.3425, 50.2838],
                        [-64.3494, 50.2862],
                        [-64.3804, 50.3013],
                        [-64.4213, 50.317],
                        [-64.4474, 50.3182],
                        [-64.4637, 50.3151],
                        [-64.4849, 50.3157],
                        [-64.5002, 50.3065],
                        [-64.5016, 50.3006],
                        [-64.5309, 50.2908],
                        [-64.5395, 50.2854],
                        [-64.5597, 50.2833],
                        [-64.5694, 50.2858],
                        [-64.6163, 50.2841],
                        [-64.6315, 50.2752],
                        [-64.6485, 50.2764],
                        [-64.6622, 50.274],
                        [-64.679, 50.2732],
                        [-64.6961, 50.2791],
                        [-64.7243, 50.2739],
                        [-64.7353, 50.2693],
                        [-64.7537, 50.2726],
                        [-64.7817, 50.2726],
                        [-64.7862, 50.2742],
                        [-64.8014, 50.2702],
                        [-64.825, 50.2697],
                        [-64.8512, 50.2633],
                        [-64.8709, 50.2627],
                        [-64.8761, 50.2599],
                        [-64.8877, 50.2644],
                        [-64.9009, 50.2632],
                        [-64.9302, 50.2726],
                        [-64.9458, 50.27],
                        [-64.961, 50.2638],
                        [-64.9724, 50.2654],
                        [-65.0109, 50.2756],
                        [-65.0228, 50.2763],
                        [-65.025, 50.281],
                        [-65.0534, 50.2769],
                        [-65.0686, 50.2788],
                        [-65.0832, 50.284],
                        [-65.0961, 50.2819],
                        [-65.1113, 50.2823],
                        [-65.1207, 50.2858],
                        [-65.136, 50.2853],
                        [-65.1476, 50.2886],
                        [-65.1608, 50.2867],
                        [-65.1756, 50.2869],
                        [-65.1832, 50.2842],
                        [-65.1979, 50.2842],
                        [-65.2124, 50.2886],
                        [-65.2254, 50.2873],
                        [-65.2354, 50.2904],
                        [-65.2395, 50.2962],
                        [-65.2498, 50.2967],
                        [-65.2726, 50.3017],
                        [-65.2852, 50.3004],
                        [-65.2928, 50.2959],
                        [-65.31, 50.296],
                        [-65.3176, 50.2935],
                        [-65.3347, 50.3007],
                        [-65.3504, 50.3027],
                        [-65.3784, 50.2987],
                        [-65.3898, 50.3002],
                        [-65.4084, 50.2974],
                        [-65.4172, 50.2941],
                        [-65.4372, 50.2915],
                        [-65.4482, 50.292],
                        [-65.4663, 50.2879],
                        [-65.4749, 50.2898],
                        [-65.4967, 50.2849],
                        [-65.5041, 50.2863],
                        [-65.542, 50.2716],
                        [-65.561, 50.2739],
                        [-65.5675, 50.2714],
                        [-65.5843, 50.2738],
                        [-65.5981, 50.274],
                        [-65.6092, 50.2681],
                        [-65.6308, 50.2657],
                        [-65.6388, 50.2681],
                        [-65.6471, 50.2638],
                        [-65.6562, 50.2671],
                        [-65.6606, 50.2623],
                        [-65.6713, 50.2587],
                        [-65.6923, 50.2549],
                        [-65.7023, 50.2553],
                        [-65.7084, 50.2612],
                        [-65.74, 50.257],
                        [-65.7622, 50.2556],
                        [-65.7654, 50.2596],
                        [-65.7761, 50.2573],
                        [-65.7817, 50.2613],
                        [-65.7941, 50.2584],
                        [-65.8125, 50.2616],
                        [-65.8098, 50.2539],
                        [-65.818, 50.2501],
                        [-65.8345, 50.2539],
                        [-65.8438, 50.253],
                        [-65.8534, 50.2615],
                        [-65.8993, 50.2783],
                        [-65.9049, 50.278],
                        [-65.9147, 50.2846],
                        [-65.9317, 50.2853],
                        [-65.9452, 50.282],
                        [-65.9659, 50.2839],
                        [-65.9804, 50.2786],
                        [-66.0027, 50.2661],
                        [-66.0186, 50.2508],
                        [-66.0417, 50.2214],
                        [-66.0551, 50.2059],
                        [-66.0681, 50.1991],
                        [-66.071, 50.1948],
                        [-66.0853, 50.1897],
                        [-66.1038, 50.1857],
                        [-66.1367, 50.1868],
                        [-66.1544, 50.19],
                        [-66.2072, 50.2081],
                        [-66.227, 50.213],
                        [-66.2582, 50.2129],
                        [-66.2866, 50.2057],
                        [-66.3212, 50.2029],
                        [-66.3602, 50.1867],
                        [-66.3657, 50.1865],
                        [-66.392, 50.2063],
                        [-66.3991, 50.2133],
                        [-66.412, 50.2188],
                        [-66.4061, 50.2255],
                        [-66.3937, 50.2287],
                        [-66.3988, 50.2434],
                        [-66.4117, 50.2632],
                        [-66.4293, 50.2656],
                        [-66.4357, 50.2633],
                        [-66.4534, 50.2687],
                        [-66.4635, 50.2644],
                        [-66.4719, 50.2557],
                        [-66.4953, 50.2489],
                        [-66.5135, 50.2385],
                        [-66.5173, 50.2384],
                        [-66.5306, 50.2285],
                        [-66.5345, 50.2165],
                        [-66.5411, 50.2119],
                        [-66.5361, 50.2012],
                        [-66.5334, 50.1848],
                        [-66.5252, 50.1704],
                        [-66.5168, 50.1615],
                        [-66.5045, 50.1588],
                        [-66.4808, 50.1644],
                        [-66.4642, 50.1583],
                        [-66.4481, 50.1604],
                        [-66.4334, 50.1652],
                        [-66.4262, 50.1641],
                        [-66.424, 50.1549],
                        [-66.4264, 50.1499],
                        [-66.4381, 50.1524],
                        [-66.4539, 50.1413],
                        [-66.4561, 50.1257],
                        [-66.4726, 50.1275],
                        [-66.4764, 50.1325],
                        [-66.5012, 50.1416],
                        [-66.5038, 50.1464],
                        [-66.5249, 50.1426],
                        [-66.5219, 50.1486],
                        [-66.5315, 50.1511],
                        [-66.5589, 50.1505],
                        [-66.5764, 50.1475],
                        [-66.6132, 50.1361],
                        [-66.6195, 50.1319],
                        [-66.6544, 50.1265],
                        [-66.6718, 50.1219],
                        [-66.6832, 50.1166],
                        [-66.7001, 50.1059],
                        [-66.7111, 50.0941],
                        [-66.7234, 50.0777],
                        [-66.7278, 50.0617],
                        [-66.7269, 50.0518],
                        [-66.7346, 50.0436],
                        [-66.7477, 50.0432],
                        [-66.7559, 50.0358],
                        [-66.7669, 50.036],
                        [-66.7712, 50.0305],
                        [-66.7859, 50.0296],
                        [-66.7972, 50.0255],
                        [-66.8114, 50.031],
                        [-66.8144, 50.0213],
                        [-66.8279, 50.0208],
                        [-66.8313, 50.0262],
                        [-66.8533, 50.0218],
                        [-66.8752, 50.0243],
                        [-66.8779, 50.0156],
                        [-66.8946, 50.0192],
                        [-66.8912, 50.0126],
                        [-66.9087, 50.0056],
                        [-66.9343, 49.9987],
                        [-66.9362, 49.9943],
                        [-66.9578, 49.9812],
                        [-66.9628, 49.9733],
                        [-66.9595, 49.9636],
                        [-66.967, 49.9629],
                        [-66.9728, 49.9536],
                        [-66.9711, 49.9461],
                        [-66.9646, 49.9362],
                        [-66.9719, 49.9289],
                        [-66.9813, 49.9258],
                        [-66.9769, 49.9192],
                        [-66.9654, 49.9175],
                        [-66.9833, 49.9028],
                        [-66.9931, 49.8985],
                        [-66.9964, 49.8926],
                        [-66.9915, 49.8872],
                        [-66.999, 49.8802],
                        [-67.0018, 49.8625],
                        [-67.0205, 49.8473],
                        [-67.0222, 49.8407],
                        [-67.0337, 49.8311],
                        [-67.0429, 49.8297],
                        [-67.0483, 49.821],
                        [-67.0587, 49.8164],
                        [-67.0637, 49.8278],
                        [-67.0604, 49.84],
                        [-67.0695, 49.8466],
                        [-67.09, 49.8478],
                        [-67.1093, 49.8397],
                        [-67.1071, 49.8296],
                        [-67.1359, 49.8138],
                        [-67.1525, 49.7958],
                        [-67.1546, 49.7809],
                        [-67.1641, 49.7786],
                        [-67.167, 49.7735],
                        [-67.1702, 49.7572],
                        [-67.1715, 49.7204],
                        [-67.1663, 49.6795],
                        [-67.1686, 49.6655],
                        [-67.1764, 49.6654],
                        [-67.1919, 49.6499],
                        [-67.1945, 49.6429],
                        [-67.2043, 49.6342],
                        [-67.2041, 49.628],
                        [-67.2292, 49.5971],
                        [-67.2341, 49.5942],
                        [-67.2337, 49.5755],
                        [-67.2369, 49.5684],
                        [-67.233, 49.5284],
                        [-67.2295, 49.5146],
                        [-67.2325, 49.4864],
                        [-67.2312, 49.4756],
                        [-67.2356, 49.4738],
                        [-67.2388, 49.4585],
                        [-67.2523, 49.452],
                        [-67.2653, 49.442],
                        [-67.2656, 49.4384],
                        [-67.2752, 49.4328],
                        [-67.2779, 49.4232],
                        [-67.294, 49.423],
                        [-67.3029, 49.4182],
                        [-67.3056, 49.4114],
                        [-67.3069, 49.3952],
                        [-67.3045, 49.3911],
                        [-67.3174, 49.3845],
                        [-67.32, 49.3762],
                        [-67.3283, 49.3738],
                        [-67.3363, 49.3654],
                        [-67.3396, 49.3545],
                        [-67.3491, 49.3495],
                        [-67.3562, 49.3374],
                        [-67.3714, 49.323],
                        [-67.3805, 49.3185],
                        [-67.4045, 49.3177],
                        [-67.4072, 49.3239],
                        [-67.4161, 49.3252],
                        [-67.4228, 49.3206],
                        [-67.4477, 49.3204],
                        [-67.456, 49.3232],
                        [-67.4655, 49.3215],
                        [-67.4941, 49.325],
                        [-67.5084, 49.3249],
                        [-67.5159, 49.3226],
                        [-67.5489, 49.3247],
                        [-67.5754, 49.3217],
                        [-67.5841, 49.3257],
                        [-67.5963, 49.3218],
                        [-67.6057, 49.3067],
                        [-67.6126, 49.3094],
                        [-67.6423, 49.3147],
                        [-67.6471, 49.3141],
                        [-67.66, 49.3031],
                        [-67.6739, 49.3008],
                        [-67.6867, 49.3058],
                        [-67.6969, 49.3059],
                        [-67.7118, 49.3104],
                        [-67.7195, 49.3048],
                        [-67.741, 49.3023],
                        [-67.7685, 49.3045],
                        [-67.7742, 49.2948],
                        [-67.7873, 49.2862],
                        [-67.7924, 49.2795],
                        [-67.8037, 49.2714],
                        [-67.8189, 49.2689],
                        [-67.8512, 49.2803],
                        [-67.8691, 49.2842],
                        [-67.8952, 49.2918],
                        [-67.9211, 49.2905],
                        [-67.9325, 49.2868],
                        [-67.9371, 49.2808],
                        [-67.9486, 49.2831],
                        [-67.9556, 49.2788],
                        [-67.993, 49.2824],
                        [-68.0194, 49.2814],
                        [-68.0282, 49.2798],
                        [-68.0383, 49.2836],
                        [-68.0462, 49.2807],
                        [-68.0516, 49.2718],
                        [-68.0613, 49.2692],
                        [-68.0791, 49.2534],
                        [-68.0847, 49.2527],
                        [-68.1016, 49.2621],
                        [-68.1274, 49.2712],
                        [-68.1332, 49.2682],
                        [-68.1309, 49.2575],
                        [-68.1383, 49.2506],
                        [-68.1326, 49.2445],
                        [-68.1347, 49.2332],
                        [-68.1432, 49.2264],
                        [-68.1394, 49.2167],
                        [-68.1442, 49.2032],
                        [-68.152, 49.2005],
                        [-68.1708, 49.1997],
                        [-68.1816, 49.2011],
                        [-68.2024, 49.1959],
                        [-68.206, 49.1909],
                        [-68.2236, 49.1861],
                        [-68.2378, 49.1851],
                        [-68.2502, 49.1876],
                        [-68.2647, 49.1794],
                        [-68.2796, 49.1876],
                        [-68.2975, 49.1911],
                        [-68.316, 49.1881],
                        [-68.3162, 49.1804],
                        [-68.295, 49.1787],
                        [-68.2819, 49.1818],
                        [-68.2767, 49.1747],
                        [-68.2691, 49.1724],
                        [-68.2554, 49.1642],
                        [-68.2309, 49.1563],
                        [-68.2181, 49.1554],
                        [-68.1951, 49.1613],
                        [-68.1826, 49.1548],
                        [-68.1781, 49.1431],
                        [-68.1772, 49.1326],
                        [-68.1797, 49.1159],
                        [-68.189, 49.0998],
                        [-68.2091, 49.0984],
                        [-68.2194, 49.0962],
                        [-68.23, 49.0897],
                        [-68.2532, 49.0909],
                        [-68.2823, 49.0902],
                        [-68.3053, 49.094],
                        [-68.3259, 49.0886],
                        [-68.3478, 49.0782],
                        [-68.3665, 49.0624],
                        [-68.3972, 49.0462],
                        [-68.4229, 49.0418],
                        [-68.4491, 49.0414],
                        [-68.4606, 49.0425],
                        [-68.4665, 49.049],
                        [-68.4788, 49.0706],
                        [-68.485, 49.0691],
                        [-68.5049, 49.0698],
                        [-68.513, 49.0657],
                        [-68.528, 49.0704],
                        [-68.5407, 49.0648],
                        [-68.5501, 49.053],
                        [-68.5605, 49.0583],
                        [-68.5697, 49.0575],
                        [-68.583, 49.0524],
                        [-68.5907, 49.0449],
                        [-68.599, 49.0454],
                        [-68.6058, 49.0353],
                        [-68.6132, 49.0321],
                        [-68.6236, 49.0162],
                        [-68.6238, 49.0059],
                        [-68.6374, 49.0001],
                        [-68.634, 48.9934],
                        [-68.64, 48.9718],
                        [-68.6347, 48.9411],
                        [-68.6358, 48.9387],
                        [-68.6194, 48.9176],
                        [-68.6255, 48.9149],
                        [-68.6372, 48.8997],
                        [-68.6431, 48.8977],
                        [-68.6609, 48.8984],
                        [-68.6919, 48.9017],
                        [-68.7117, 48.8999],
                        [-68.7251, 48.8958],
                        [-68.7655, 48.8903],
                        [-68.7801, 48.887],
                        [-68.7856, 48.8906],
                        [-68.7942, 48.8838],
                        [-68.7917, 48.877],
                        [-68.8072, 48.8684],
                        [-68.8184, 48.8668],
                        [-68.8217, 48.8586],
                        [-68.8299, 48.8592],
                        [-68.8456, 48.8462],
                        [-68.8503, 48.8385],
                        [-68.8685, 48.8398],
                        [-68.8696, 48.8325],
                        [-68.8805, 48.8278],
                        [-68.8722, 48.8236],
                        [-68.879, 48.8191],
                        [-68.8857, 48.8255],
                        [-68.8963, 48.8259],
                        [-68.9062, 48.8199],
                        [-68.9134, 48.8219],
                        [-68.9252, 48.8156],
                        [-68.9458, 48.8146],
                        [-68.942, 48.8051],
                        [-68.9458, 48.8016],
                        [-68.9489, 48.7878],
                        [-68.9598, 48.7794],
                        [-68.9815, 48.7686],
                        [-68.9812, 48.7734],
                        [-68.988, 48.785],
                        [-68.9966, 48.7806],
                        [-68.9953, 48.7753],
                        [-68.9996, 48.7644],
                        [-69.012, 48.764],
                        [-69.0004, 48.7738],
                        [-69.0091, 48.7747],
                        [-69.0194, 48.7677],
                        [-69.0268, 48.7658],
                        [-69.0335, 48.771],
                        [-69.045, 48.7635],
                        [-69.0506, 48.7727],
                        [-69.0604, 48.7689],
                        [-69.0662, 48.7603],
                        [-69.0618, 48.7483],
                        [-69.049, 48.7498],
                        [-69.0447, 48.7453],
                        [-69.0485, 48.7387],
                        [-69.0604, 48.7385],
                        [-69.068, 48.7252],
                        [-69.0729, 48.7245],
                        [-69.082, 48.7049],
                        [-69.0856, 48.6884],
                        [-69.0861, 48.663],
                        [-69.0849, 48.6485],
                        [-69.0868, 48.6391],
                        [-69.0919, 48.6342],
                        [-69.1002, 48.6197],
                        [-69.0996, 48.6152],
                        [-69.1073, 48.6079],
                        [-69.1167, 48.5933],
                        [-69.1318, 48.574],
                        [-69.1407, 48.5663],
                        [-69.1535, 48.5658],
                        [-69.1709, 48.5705],
                        [-69.1939, 48.5711],
                        [-69.191, 48.5794],
                        [-69.198, 48.5833],
                        [-69.2158, 48.5814],
                        [-69.2334, 48.5707],
                        [-69.2406, 48.5591],
                        [-69.2415, 48.551],
                        [-69.2491, 48.5405],
                        [-69.261, 48.5284],
                        [-69.2696, 48.525],
                        [-69.2814, 48.5148],
                        [-69.2779, 48.4908],
                        [-69.281, 48.4847],
                        [-69.2844, 48.4674],
                        [-69.2914, 48.4551],
                        [-69.3037, 48.4419],
                        [-69.315, 48.4352],
                        [-69.3209, 48.4146],
                        [-69.3377, 48.4041],
                        [-69.3391, 48.3998],
                        [-69.3517, 48.393],
                        [-69.3569, 48.3787],
                        [-69.3886, 48.3675],
                        [-69.3945, 48.3584],
                        [-69.4054, 48.3498],
                        [-69.4001, 48.3458],
                        [-69.3901, 48.346],
                        [-69.3924, 48.3351],
                        [-69.404, 48.325],
                        [-69.4105, 48.3226],
                        [-69.4277, 48.2965],
                        [-69.4445, 48.2873],
                        [-69.4574, 48.2856],
                        [-69.4581, 48.2786],
                        [-69.4666, 48.2692],
                        [-69.4792, 48.2745],
                        [-69.4997, 48.2635],
                        [-69.5149, 48.2412],
                        [-69.5415, 48.2303],
                        [-69.5544, 48.2288],
                        [-69.5628, 48.2308],
                        [-69.5679, 48.218],
                        [-69.585, 48.2211],
                        [-69.5824, 48.2142],
                        [-69.5938, 48.2054],
                        [-69.6092, 48.1888],
                        [-69.6111, 48.1845],
                        [-69.6301, 48.1721],
                        [-69.6463, 48.1596],
                        [-69.6645, 48.1558],
                        [-69.6668, 48.1475],
                        [-69.6796, 48.1377],
                        [-69.701, 48.1353],
                        [-69.717, 48.1235],
                        [-69.728, 48.1082],
                        [-69.7222, 48.1037],
                        [-69.7073, 48.0991],
                        [-69.7192, 48.0914],
                        [-69.7251, 48.0814],
                        [-69.7323, 48.0764],
                        [-69.747, 48.0787],
                        [-69.7563, 48.0678],
                        [-69.7598, 48.0572],
                        [-69.7643, 48.055],
                        [-69.7648, 48.0396],
                        [-69.7724, 48.0272],
                        [-69.7634, 48.0115],
                        [-69.7627, 48.0045],
                        [-69.7667, 47.9953],
                        [-69.7734, 47.9904],
                        [-69.7784, 47.98],
                        [-69.7774, 47.9743],
                        [-69.7844, 47.9642],
                        [-69.804, 47.9601],
                        [-69.8016, 47.9532],
                        [-69.7946, 47.9486],
                        [-69.7955, 47.9336],
                        [-69.8021, 47.9147],
                        [-69.8059, 47.9102],
                        [-69.8167, 47.9063],
                        [-69.827, 47.8934],
                        [-69.8378, 47.8911],
                        [-69.8474, 47.8738],
                        [-69.86, 47.868],
                        [-69.8737, 47.8484],
                        [-69.8743, 47.8373],
                        [-69.8783, 47.8274],
                        [-69.8929, 47.8093],
                        [-69.8999, 47.8058],
                        [-69.8988, 47.7881],
                        [-69.9052, 47.7796],
                        [-69.9056, 47.77],
                        [-69.9227, 47.7591],
                        [-69.9342, 47.758],
                        [-69.9423, 47.7544],
                        [-69.9585, 47.754],
                        [-69.9622, 47.7461],
                        [-69.9724, 47.7327],
                        [-69.9789, 47.716],
                        [-69.9914, 47.7033],
                        [-70.0031, 47.7033],
                        [-70.0273, 47.694],
                        [-70.0596, 47.6784],
                        [-70.0939, 47.6666],
                        [-70.0977, 47.6605],
                        [-70.1079, 47.6583],
                        [-70.1267, 47.6509],
                        [-70.1472, 47.6542],
                        [-70.1487, 47.643],
                        [-70.1434, 47.6391],
                        [-70.139, 47.6279],
                        [-70.1403, 47.623],
                        [-70.1684, 47.5993],
                        [-70.1822, 47.59],
                        [-70.191, 47.5865],
                        [-70.1993, 47.5784],
                        [-70.2046, 47.5677],
                        [-70.2002, 47.5372],
                        [-70.2027, 47.5241],
                        [-70.2183, 47.504],
                        [-70.2277, 47.5047],
                        [-70.2315, 47.4959],
                        [-70.2274, 47.4914],
                        [-70.2346, 47.4875],
                        [-70.2439, 47.4885],
                        [-70.2641, 47.4848],
                        [-70.2793, 47.4753],
                        [-70.2943, 47.4681],
                        [-70.3163, 47.464],
                        [-70.3491, 47.4627],
                        [-70.3624, 47.4567],
                        [-70.3895, 47.4503],
                        [-70.3975, 47.4459],
                        [-70.4212, 47.4401],
                        [-70.4339, 47.4324],
                        [-70.4529, 47.4262],
                        [-70.4628, 47.4271],
                        [-70.4795, 47.4341],
                        [-70.4877, 47.4336],
                        [-70.494, 47.4244],
                        [-70.503, 47.4208],
                        [-70.4968, 47.4135],
                        [-70.4948, 47.4031],
                        [-70.4978, 47.3852],
                        [-70.505, 47.3747],
                        [-70.5312, 47.354],
                        [-70.5331, 47.3484],
                        [-70.5515, 47.3302],
                        [-70.5584, 47.3144],
                        [-70.5658, 47.3055],
                        [-70.5696, 47.2913],
                        [-70.5674, 47.2823],
                        [-70.5723, 47.2735],
                        [-70.5838, 47.2603],
                        [-70.5867, 47.2541],
                        [-70.5991, 47.2453],
                        [-70.6042, 47.2388],
                        [-70.6203, 47.2259],
                        [-70.6297, 47.2068],
                        [-70.6364, 47.1976],
                        [-70.6478, 47.1888],
                        [-70.6634, 47.1697],
                        [-70.6738, 47.1626],
                        [-70.6856, 47.1412],
                        [-70.6968, 47.1366],
                        [-70.7015, 47.1253],
                        [-70.7087, 47.1213],
                        [-70.716, 47.1082],
                        [-70.7236, 47.1056],
                        [-70.7353, 47.0972],
                        [-70.7417, 47.0881],
                        [-70.7618, 47.0831],
                        [-70.7916, 47.0637],
                        [-70.8064, 47.0499],
                        [-70.829, 47.0486],
                        [-70.8614, 47.0452],
                        [-70.8817, 47.0406],
                        [-70.8875, 47.0362],
                        [-70.9451, 47.0149],
                        [-70.9608, 47.0069],
                        [-70.9817, 46.9897],
                        [-71.007, 46.9759],
                        [-71.0251, 46.9644],
                        [-71.0469, 46.9463],
                        [-71.0506, 46.9358],
                        [-71.0559, 46.9308],
                        [-71.0755, 46.919],
                        [-71.0868, 46.9149],
                        [-71.1081, 46.9032],
                        [-71.1278, 46.8954],
                        [-71.142, 46.8863],
                        [-71.1676, 46.8596],
                        [-71.181, 46.8539],
                        [-71.1995, 46.8505],
                        [-71.2061, 46.8379],
                        [-71.1957, 46.839],
                        [-71.1914, 46.8359],
                        [-71.2028, 46.8077],
                        [-71.2114, 46.7994],
                        [-71.2264, 46.7923],
                        [-71.2418, 46.7784],
                        [-71.2419, 46.7734],
                        [-71.2603, 46.765],
                        [-71.2721, 46.7551],
                        [-71.292, 46.748],
                        [-71.3163, 46.7502],
                        [-71.3368, 46.7465],
                        [-71.3439, 46.7475],
                        [-71.3536, 46.7398],
                        [-71.3705, 46.7332],
                        [-71.3829, 46.7346],
                        [-71.3935, 46.7325],
                        [-71.4082, 46.7355],
                        [-71.4256, 46.7339],
                        [-71.4329, 46.7299],
                        [-71.4604, 46.7273],
                        [-71.4871, 46.7236],
                        [-71.5124, 46.7165],
                        [-71.5371, 46.7141],
                        [-71.56, 46.7064],
                        [-71.5731, 46.6981],
                        [-71.5892, 46.6951],
                        [-71.6068, 46.698],
                        [-71.6186, 46.6944],
                        [-71.628, 46.6941],
                        [-71.639, 46.6903],
                        [-71.6455, 46.6842],
                        [-71.6541, 46.6842],
                        [-71.6676, 46.6751],
                        [-71.6774, 46.6747],
                        [-71.6941, 46.6681],
                        [-71.7118, 46.6704],
                        [-71.7168, 46.6692],
                        [-71.7586, 46.6681],
                        [-71.7665, 46.6669],
                        [-71.7894, 46.6695],
                        [-71.8233, 46.6862],
                        [-71.8437, 46.6921],
                        [-71.8653, 46.6895],
                        [-71.8825, 46.6893],
                        [-71.899, 46.6821],
                        [-71.9102, 46.6681],
                        [-71.9153, 46.6592],
                        [-71.9246, 46.6534],
                        [-71.9276, 46.6461],
                        [-71.9395, 46.6456],
                        [-71.9471, 46.64],
                        [-71.962, 46.6364],
                        [-71.9803, 46.6257],
                        [-71.9857, 46.625],
                        [-72.0154, 46.6067],
                        [-72.0311, 46.5918],
                        [-72.0376, 46.5878],
                        [-72.0693, 46.5816],
                        [-72.089, 46.5854],
                        [-72.1065, 46.5845],
                        [-72.1414, 46.5788],
                        [-72.161, 46.5708],
                        [-72.1715, 46.5681],
                        [-72.1823, 46.5577],
                        [-72.1981, 46.5525],
                        [-72.2033, 46.5478],
                        [-72.2172, 46.5455],
                        [-72.2279, 46.5288],
                        [-72.2371, 46.5215],
                        [-72.2379, 46.5129],
                        [-72.2501, 46.4963],
                        [-72.2537, 46.4795],
                        [-72.2585, 46.4712],
                        [-72.2614, 46.4589],
                        [-72.2703, 46.4495],
                        [-72.2899, 46.4447],
                        [-72.3066, 46.4429],
                        [-72.337, 46.4413],
                        [-72.3477, 46.4389],
                        [-72.3671, 46.4294],
                        [-72.3818, 46.4161],
                        [-72.4036, 46.4125],
                        [-72.4193, 46.4083],
                        [-72.4448, 46.3968],
                        [-72.4652, 46.392],
                        [-72.4865, 46.3799],
                        [-72.4982, 46.3647],
                        [-72.5398, 46.34],
                        [-72.549, 46.3275],
                        [-72.5583, 46.3212],
                        [-72.5685, 46.3112],
                        [-72.5832, 46.3002],
                        [-72.5936, 46.2963],
                        [-72.623, 46.2923],
                        [-72.6344, 46.2889],
                        [-72.6517, 46.281],
                        [-72.6704, 46.2778],
                        [-72.6827, 46.2834],
                        [-72.7086, 46.2911],
                        [-72.7283, 46.2911],
                        [-72.7484, 46.2816],
                        [-72.7808, 46.2698],
                        [-72.7948, 46.2668],
                        [-72.8125, 46.258],
                        [-72.8189, 46.2655],
                        [-72.8389, 46.2677],
                        [-72.8534, 46.2648],
                        [-72.8501, 46.2599],
                        [-72.8558, 46.2534],
                        [-72.89, 46.242],
                        [-72.9189, 46.2187],
                        [-72.9355, 46.2191],
                        [-72.9431, 46.2169],
                        [-72.9448, 46.2104],
                        [-72.9775, 46.2013],
                        [-72.9885, 46.2013],
                        [-73.0073, 46.1902],
                        [-72.9914, 46.185],
                        [-73.0047, 46.1661],
                        [-73.0195, 46.1611],
                        [-73.0367, 46.1589],
                        [-73.0487, 46.1536],
                        [-73.0819, 46.1465],
                        [-73.1035, 46.138],
                        [-73.1111, 46.1333],
                        [-73.1187, 46.1233],
                        [-73.1277, 46.1181],
                        [-73.1452, 46.1116],
                        [-73.1536, 46.1061],
                        [-73.1607, 46.092],
                        [-73.175, 46.08],
                        [-73.1821, 46.057],
                        [-73.1789, 46.0395],
                        [-73.1876, 46.0019],
                        [-73.1953, 45.9807],
                        [-73.2039, 45.9696],
                        [-73.2197, 45.9566],
                        [-73.2333, 45.938],
                        [-73.2394, 45.9328],
                        [-73.2537, 45.9105],
                        [-73.2656, 45.8959],
                        [-73.27, 45.8866],
                        [-73.2775, 45.8804],
                        [-73.2855, 45.8692],
                        [-73.3096, 45.8501],
                        [-73.3248, 45.8359],
                        [-73.3581, 45.8252],
                        [-73.3656, 45.82],
                        [-73.3793, 45.8],
                        [-73.3949, 45.7895],
                        [-73.4062, 45.7753],
                        [-73.4148, 45.7702],
                        [-73.4295, 45.7508],
                        [-73.4351, 45.7463],
                        [-73.4437, 45.7346],
                        [-73.4498, 45.7319],
                        [-73.4711, 45.7132],
                        [-73.4899, 45.7137],
                        [-73.5055, 45.706],
                        [-73.5276, 45.7011],
                        [-73.5444, 45.6998],
                        [-73.5555, 45.7021],
                        [-73.5989, 45.691],
                        [-73.6136, 45.6945],
                        [-73.632, 45.6904],
                        [-73.6488, 45.6979],
                        [-73.6585, 45.692],
                        [-73.6718, 45.6942],
                        [-73.687, 45.6945],
                        [-73.6957, 45.6905],
                        [-73.6983, 45.6855],
                        [-73.7096, 45.6812],
                        [-73.7258, 45.6829],
                        [-73.741, 45.6756],
                        [-73.752, 45.663],
                        [-73.7646, 45.6575],
                        [-73.7742, 45.6454],
                        [-73.7815, 45.6425],
                        [-73.7803, 45.6369],
                        [-73.7907, 45.6293],
                        [-73.8003, 45.6192],
                        [-73.8089, 45.6194],
                        [-73.8144, 45.6152],
                        [-73.8292, 45.6094],
                        [-73.8289, 45.6006],
                        [-73.8369, 45.593],
                        [-73.8335, 45.5884],
                        [-73.8414, 45.5826],
                        [-73.8563, 45.5801],
                        [-73.8586, 45.5738],
                        [-73.8655, 45.5716],
                        [-73.8761, 45.5641],
                        [-73.8843, 45.5624],
                        [-73.8903, 45.5521],
                        [-73.8898, 45.5433],
                        [-73.8817, 45.5363],
                        [-73.8917, 45.5305],
                        [-73.9047, 45.5319],
                        [-73.9181, 45.5284],
                        [-73.9248, 45.522],
                        [-73.947, 45.5183],
                        [-73.9594, 45.5018],
                        [-73.9582, 45.4986],
                        [-73.9856, 45.485],
                        [-73.9923, 45.4769],
                        [-74.003, 45.4779],
                        [-74.0297, 45.4668],
                        [-74.0347, 45.4669],
                        [-74.054, 45.4584],
                        [-74.0618, 45.4653],
                        [-74.0697, 45.4655],
                        [-74.0888, 45.4584],
                        [-74.1164, 45.4657],
                        [-74.1303, 45.4745],
                        [-74.1347, 45.4922],
                        [-74.1486, 45.4972],
                        [-74.1728, 45.4971],
                        [-74.1691, 45.5029],
                        [-74.1696, 45.5108],
                        [-74.1742, 45.5231],
                        [-74.1856, 45.5289],
                        [-74.2077, 45.5247],
                        [-74.2242, 45.5248],
                        [-74.2374, 45.5302],
                        [-74.2428, 45.529],
                        [-74.2542, 45.5325],
                        [-74.2736, 45.5275],
                        [-74.2874, 45.5268],
                        [-74.3008, 45.5227],
                        [-74.3093, 45.5277],
                        [-74.3074, 45.532],
                        [-74.3215, 45.5317],
                        [-74.322, 45.5237],
                        [-74.334, 45.5248],
                        [-74.3369, 45.5203],
                        [-74.3461, 45.5176],
                        [-74.3399, 45.5088],
                        [-74.3352, 45.4964],
                        [-74.323, 45.4975],
                        [-74.3112, 45.4913],
                        [-74.3097, 45.4992],
                        [-74.2936, 45.5027],
                        [-74.2797, 45.5],
                        [-74.2634, 45.4948],
                        [-74.2577, 45.4913],
                        [-74.241, 45.4948],
                        [-74.245, 45.4835],
                        [-74.2367, 45.4785],
                        [-74.2085, 45.4847],
                        [-74.1985, 45.4787],
                        [-74.1643, 45.4689],
                        [-74.1581, 45.4691],
                        [-74.1124, 45.4522],
                        [-74.104, 45.4435],
                        [-74.0933, 45.4402],
                        [-74.0725, 45.4424],
                        [-74.0694, 45.4353],
                        [-74.0607, 45.4251],
                        [-74.0449, 45.4184],
                        [-74.0362, 45.4261],
                        [-74.0283, 45.4259],
                        [-74.017, 45.4218],
                        [-74.0125, 45.4243],
                        [-74.0044, 45.4184],
                        [-74.0131, 45.4155],
                        [-74.0242, 45.398],
                        [-74.021, 45.3932],
                        [-74.0038, 45.3876],
                        [-74.0084, 45.384],
                        [-74.0093, 45.3757],
                        [-74.0063, 45.3648],
                        [-73.9972, 45.3562],
                        [-73.9704, 45.3349],
                        [-73.9559, 45.3349],
                        [-73.9851, 45.3221],
                        [-73.9872, 45.3159],
                        [-74.0154, 45.3148],
                        [-74.039, 45.306],
                        [-74.0544, 45.3038],
                        [-74.058, 45.3082],
                        [-74.0734, 45.306],
                        [-74.0781, 45.3092],
                        [-74.0933, 45.3112],
                        [-74.1192, 45.31],
                        [-74.1337, 45.3074],
                        [-74.1548, 45.2985],
                        [-74.1667, 45.2986],
                        [-74.1738, 45.288],
                        [-74.1912, 45.2749],
                        [-74.1917, 45.2653],
                        [-74.1957, 45.2591],
                        [-74.2132, 45.2553],
                        [-74.2189, 45.256],
                        [-74.2498, 45.239],
                        [-74.2723, 45.2291],
                        [-74.2734, 45.2248],
                        [-74.2837, 45.2233],
                        [-74.2979, 45.2187],
                        [-74.3045, 45.2122],
                        [-74.3162, 45.2076],
                        [-74.3221, 45.1976],
                        [-74.3357, 45.2049],
                        [-74.344, 45.2052],
                        [-74.4325, 45.2732],
                        [-74.4722, 45.3027],
                        [-74.4339, 45.4055],
                        [-74.4147, 45.4592],
                        [-74.3943, 45.5212],
                        [-74.3813, 45.5661],
                        [-74.3962, 45.5705],
                        [-74.4177, 45.5708],
                        [-74.4323, 45.5723],
                        [-74.4433, 45.5763],
                        [-74.4608, 45.5887],
                        [-74.4724, 45.5937],
                        [-74.4895, 45.5953],
                        [-74.51, 45.5922],
                        [-74.5261, 45.5926],
                        [-74.5351, 45.5948],
                        [-74.5628, 45.6066],
                        [-74.5985, 45.6181],
                        [-74.6099, 45.6227],
                        [-74.6255, 45.6346],
                        [-74.6381, 45.6388],
                        [-74.6783, 45.6325],
                        [-74.7168, 45.6285],
                        [-74.7293, 45.6283],
                        [-74.7528, 45.6336],
                        [-74.7864, 45.6388],
                        [-74.8013, 45.6392],
                        [-74.8352, 45.6358],
                        [-74.8481, 45.6368],
                        [-74.8784, 45.6419],
                        [-74.9283, 45.6457],
                        [-74.943, 45.6434],
                        [-74.9552, 45.6356],
                        [-74.9672, 45.6246],
                        [-74.989, 45.6108],
                        [-75.0162, 45.5989],
                        [-75.0355, 45.5938],
                        [-75.0527, 45.5913],
                        [-75.0686, 45.5909],
                        [-75.0875, 45.588],
                        [-75.1135, 45.5786],
                        [-75.1326, 45.5766],
                        [-75.1555, 45.5785],
                        [-75.1735, 45.577],
                        [-75.1983, 45.5854],
                        [-75.2223, 45.5875],
                        [-75.2426, 45.5855],
                        [-75.3003, 45.5583],
                        [-75.3046, 45.553],
                        [-75.317, 45.5462],
                        [-75.3425, 45.537],
                        [-75.3834, 45.5272],
                        [-75.391, 45.5242],
                        [-75.4153, 45.5231],
                        [-75.4236, 45.5199],
                        [-75.4454, 45.5211],
                        [-75.4569, 45.5194],
                        [-75.4779, 45.5135],
                        [-75.5204, 45.4988],
                        [-75.5562, 45.4845],
                        [-75.5756, 45.4748],
                        [-75.5913, 45.4728],
                        [-75.6182, 45.4717],
                        [-75.6394, 45.4659],
                        [-75.6649, 45.4634],
                        [-75.6776, 45.4608],
                        [-75.6889, 45.4558],
                        [-75.6961, 45.4495],
                        [-75.7026, 45.4376],
                        [-75.7037, 45.4266],
                        [-75.7083, 45.4233],
                        [-75.722, 45.4207],
                        [-75.7296, 45.417],
                        [-75.7564, 45.4124],
                        [-75.7789, 45.3903],
                        [-75.797, 45.3783],
                        [-75.8178, 45.3727],
                        [-75.8298, 45.3732],
                        [-75.8426, 45.376],
                        [-75.884, 45.395],
                        [-75.8952, 45.4016],
                        [-75.916, 45.4174],
                        [-75.9287, 45.4288],
                        [-75.9547, 45.4603],
                        [-75.969, 45.4699],
                        [-75.9872, 45.4796],
                        [-76.0232, 45.4891],
                        [-76.0482, 45.5001],
                        [-76.0899, 45.5139],
                        [-76.1056, 45.5162],
                        [-76.1404, 45.5182],
                        [-76.1662, 45.5173],
                        [-76.201, 45.5189],
                        [-76.2278, 45.5118],
                        [-76.2335, 45.5078],
                        [-76.2385, 45.4753],
                        [-76.2451, 45.4686],
                        [-76.2913, 45.4659],
                        [-76.317, 45.457],
                        [-76.3617, 45.4571],
                        [-76.3862, 45.4647],
                        [-76.4092, 45.4771],
                        [-76.4335, 45.4921],
                        [-76.4536, 45.5004],
                        [-76.4815, 45.5048],
                        [-76.5011, 45.519],
                        [-76.5179, 45.516],
                        [-76.5726, 45.5276],
                        [-76.608, 45.5318],
                        [-76.6294, 45.5431],
                        [-76.66, 45.5606],
                        [-76.6738, 45.5815],
                        [-76.6727, 45.6074],
                        [-76.6698, 45.6213],
                        [-76.6726, 45.6267],
                        [-76.6815, 45.632],
                        [-76.699, 45.6534],
                        [-76.7089, 45.6595],
                        [-76.7119, 45.6655],
                        [-76.7032, 45.676],
                        [-76.6908, 45.6794],
                        [-76.687, 45.6873],
                        [-76.6961, 45.701],
                        [-76.6924, 45.7071],
                        [-76.7036, 45.7174],
                        [-76.7109, 45.7211],
                        [-76.7204, 45.7191],
                        [-76.7446, 45.7229],
                        [-76.7555, 45.7265],
                        [-76.7678, 45.7335],
                        [-76.7658, 45.7468],
                        [-76.7713, 45.7504],
                        [-76.7707, 45.7582],
                        [-76.7752, 45.7745],
                        [-76.7814, 45.7853],
                        [-76.7694, 45.8022],
                        [-76.7658, 45.811],
                        [-76.77, 45.8297],
                        [-76.7647, 45.851],
                        [-76.7734, 45.8595],
                        [-76.7787, 45.8714],
                        [-76.7871, 45.8744],
                        [-76.8074, 45.8773],
                        [-76.8332, 45.8881],
                        [-76.8416, 45.8904],
                        [-76.8754, 45.8926],
                        [-76.8948, 45.8981],
                        [-76.9276, 45.8923],
                        [-76.9299, 45.8878],
                        [-76.9235, 45.8764],
                        [-76.9228, 45.8701],
                        [-76.9092, 45.8614],
                        [-76.9019, 45.8501],
                        [-76.9104, 45.8473],
                        [-76.9256, 45.8489],
                        [-76.9277, 45.845],
                        [-76.912, 45.8097],
                        [-76.9184, 45.7984],
                        [-76.9353, 45.7891],
                        [-76.9479, 45.7877],
                        [-76.985, 45.7861],
                        [-76.9943, 45.7903],
                        [-77.0112, 45.8042],
                        [-77.0248, 45.8086],
                        [-77.0424, 45.8073],
                        [-77.0537, 45.8106],
                        [-77.0668, 45.8279],
                        [-77.0816, 45.8356],
                        [-77.098, 45.8392],
                        [-77.1108, 45.8398],
                        [-77.1315, 45.844],
                        [-77.1543, 45.8547],
                        [-77.1903, 45.8639],
                        [-77.1985, 45.8704],
                        [-77.2071, 45.8818],
                        [-77.2304, 45.9055],
                        [-77.2363, 45.9129],
                        [-77.2387, 45.9229],
                        [-77.25, 45.9257],
                        [-77.2729, 45.9411],
                        [-77.2784, 45.9489],
                        [-77.2857, 45.9762],
                        [-77.2867, 45.9865],
                        [-77.2835, 45.9969],
                        [-77.2763, 46.0085],
                        [-77.2831, 46.0177],
                        [-77.3147, 46.0269],
                        [-77.3324, 46.0419],
                        [-77.3624, 46.0595],
                        [-77.3896, 46.0683],
                        [-77.4188, 46.083],
                        [-77.4419, 46.0933],
                        [-77.4894, 46.1175],
                        [-77.5661, 46.1592],
                        [-77.609, 46.1732],
                        [-77.6184, 46.174],
                        [-77.6567, 46.1903],
                        [-77.6691, 46.1965],
                        [-77.6804, 46.1983],
                        [-77.6905, 46.184],
                        [-77.6978, 46.1829],
                        [-77.7107, 46.1886],
                        [-77.7295, 46.1931],
                        [-77.7839, 46.1959],
                        [-77.7956, 46.1976],
                        [-77.8161, 46.2035],
                        [-77.8451, 46.2083],
                        [-77.8633, 46.2094],
                        [-77.8809, 46.2164],
                        [-77.9061, 46.2218],
                        [-77.9268, 46.2241],
                        [-77.9467, 46.231],
                        [-77.9766, 46.243],
                        [-77.9999, 46.249],
                        [-78.0281, 46.2461],
                        [-78.0427, 46.2425],
                        [-78.0559, 46.2439],
                        [-78.0736, 46.2496],
                        [-78.089, 46.2565],
                        [-78.1028, 46.2595],
                        [-78.1166, 46.2648],
                        [-78.1299, 46.2745],
                        [-78.1702, 46.2763],
                        [-78.1942, 46.274],
                        [-78.2164, 46.2692],
                        [-78.2397, 46.275],
                        [-78.2605, 46.2739],
                        [-78.2811, 46.2642],
                        [-78.2881, 46.2586],
                        [-78.3124, 46.2524],
                        [-78.3217, 46.2582],
                        [-78.3334, 46.2615],
                        [-78.3528, 46.2715],
                        [-78.3659, 46.2801],
                        [-78.3884, 46.2917],
                        [-78.4264, 46.2952],
                        [-78.4579, 46.2947],
                        [-78.4701, 46.2935],
                        [-78.4815, 46.2948],
                        [-78.5027, 46.2946],
                        [-78.5242, 46.2999],
                        [-78.5438, 46.308],
                        [-78.5649, 46.3114],
                        [-78.5971, 46.3189],
                        [-78.631, 46.3212],
                        [-78.6484, 46.3208],
                        [-78.6758, 46.3182],
                        [-78.6973, 46.3197],
                        [-78.7115, 46.326],
                        [-78.7188, 46.3315],
                        [-78.7282, 46.3423],
                        [-78.7294, 46.3483],
                        [-78.7242, 46.3604],
                        [-78.7225, 46.3708],
                        [-78.7271, 46.3829],
                        [-78.7395, 46.3892],
                        [-78.7582, 46.3934],
                        [-78.7864, 46.4136],
                        [-78.8113, 46.4266],
                        [-78.8374, 46.4369],
                        [-78.863, 46.4456],
                        [-78.8793, 46.4557],
                        [-78.8927, 46.4619],
                        [-78.8996, 46.4699],
                        [-78.9171, 46.4822],
                        [-78.9325, 46.4969],
                        [-78.9465, 46.5133],
                        [-78.9555, 46.5167],
                        [-78.9693, 46.5288],
                        [-78.9749, 46.5359],
                        [-78.9899, 46.5489],
                        [-78.9972, 46.5659],
                        [-78.9957, 46.5909],
                        [-79.0092, 46.6044],
                        [-79.0153, 46.6269],
                        [-79.0274, 46.6382],
                        [-79.0531, 46.6499],
                        [-79.0719, 46.6642],
                        [-79.0943, 46.6883],
                        [-79.0971, 46.6991],
                        [-79.1028, 46.7109],
                        [-79.1211, 46.7344],
                        [-79.1232, 46.7442],
                        [-79.1416, 46.7772],
                        [-79.1431, 46.786],
                        [-79.1501, 46.7946],
                        [-79.1548, 46.8048],
                        [-79.1708, 46.8252],
                        [-79.2138, 46.8333],
                        [-79.224, 46.8433],
                        [-79.2388, 46.8626],
                        [-79.2574, 46.8808],
                        [-79.2768, 46.8953],
                        [-79.3056, 46.9278],
                        [-79.3297, 46.9572],
                        [-79.3375, 46.9709],
                        [-79.338, 46.9844],
                        [-79.3468, 47.0079],
                        [-79.3538, 47.0144],
                        [-79.3767, 47.0289],
                        [-79.4209, 47.0697],
                        [-79.435, 47.0842],
                        [-79.443, 47.101],
                        [-79.4422, 47.1385],
                        [-79.441, 47.1501],
                        [-79.432, 47.18],
                        [-79.4321, 47.1913],
                        [-79.4375, 47.2124],
                        [-79.4375, 47.2193],
                        [-79.4266, 47.2368],
                        [-79.4268, 47.2501],
                        [-79.4501, 47.2772],
                        [-79.4662, 47.2885],
                        [-79.4778, 47.2985],
                        [-79.483, 47.3111],
                        [-79.488, 47.3172],
                        [-79.5017, 47.328],
                        [-79.5145, 47.3433],
                        [-79.5401, 47.3662],
                        [-79.5668, 47.3989],
                        [-79.581, 47.4151],
                        [-79.5869, 47.4331],
                        [-79.5865, 47.4456],
                        [-79.5788, 47.4583],
                        [-79.5607, 47.4777],
                        [-79.5563, 47.4911],
                        [-79.5562, 47.504],
                        [-79.5474, 47.5178],
                        [-79.5171, 47.5332],
                        [-79.5105, 47.5436],
                        [-79.5174, 47.5572],
                        [-79.5175, 47.6908],
                        [-79.518, 47.7413],
                        [-79.5177, 47.802],
                        [-79.5178, 47.892],
                        [-79.5174, 47.9964],
                        [-79.5177, 48.1026],
                        [-79.5171, 48.1733],
                        [-79.5176, 48.1897],
                        [-79.5174, 48.2878],
                        [-79.5174, 48.4167],
                        [-79.5175, 48.5626],
                        [-79.5177, 48.6691],
                        [-79.5174, 48.7599],
                        [-79.5173, 48.9581],
                        [-79.518, 49.0089],
                        [-79.5179, 49.1906],
                        [-79.5179, 49.3131],
                        [-79.5178, 49.4001],
                        [-79.5178, 49.5257],
                        [-79.5177, 49.677],
                        [-79.5176, 49.8225],
                        [-79.5175, 50],
                        [-79.5175, 50.1654],
                        [-79.518, 50.1947],
                        [-79.5179, 50.3828],
                        [-79.5179, 50.5],
                        [-79.5179, 50.6317],
                        [-79.5179, 50.762],
                        [-79.5179, 50.886],
                        [-79.5179, 51.0096],
                        [-79.519, 51.0623],
                        [-79.5175, 51.2712],
                        [-79.5156, 51.4608],
                        [-79.5043, 51.4677],
                        [-79.5031, 51.4785],
                        [-79.5069, 51.4874],
                        [-79.5132, 51.493],
                        [-79.5137, 51.5002],
                        [-79.5223, 51.5123],
                        [-79.5204, 51.5212],
                        [-79.5277, 51.5316],
                        [-79.5348, 51.536],
                        [-79.5414, 51.5487],
                        [-79.5376, 51.554],
                        [-79.5054, 51.554],
                        [-79.4883, 51.5688],
                        [-79.477, 51.5712],
                        [-79.4721, 51.5819],
                        [-79.4593, 51.5798],
                        [-79.4525, 51.5823],
                        [-79.4526, 51.5908],
                        [-79.4444, 51.5948],
                        [-79.4313, 51.5956],
                        [-79.4172, 51.6072],
                        [-79.4143, 51.6154],
                        [-79.3953, 51.6201],
                        [-79.3738, 51.6323],
                        [-79.3802, 51.6378],
                        [-79.3771, 51.6437],
                        [-79.3669, 51.6442],
                        [-79.3609, 51.648],
                        [-79.3604, 51.6551],
                        [-79.349, 51.6582],
                        [-79.3112, 51.6636],
                        [-79.3103, 51.6544],
                        [-79.2852, 51.64],
                        [-79.2616, 51.6416],
                        [-79.2492, 51.6394],
                        [-79.2285, 51.6446],
                        [-79.2285, 51.6349],
                        [-79.2247, 51.6274],
                        [-79.2276, 51.6218],
                        [-79.2484, 51.6008],
                        [-79.2571, 51.5804],
                        [-79.2585, 51.568],
                        [-79.2499, 51.5576],
                        [-79.2477, 51.551],
                        [-79.2495, 51.5359],
                        [-79.2398, 51.5268],
                        [-79.2273, 51.5323],
                        [-79.2181, 51.5317],
                        [-79.1975, 51.5238],
                        [-79.1697, 51.5195],
                        [-79.1518, 51.5212],
                        [-79.1329, 51.5262],
                        [-79.1249, 51.5302],
                        [-79.103, 51.5327],
                        [-79.0949, 51.5314],
                        [-79.0871, 51.524],
                        [-79.0634, 51.5104],
                        [-79.051, 51.5069],
                        [-79.027, 51.4902],
                        [-78.9969, 51.4724],
                        [-78.981, 51.4587],
                        [-78.9793, 51.4387],
                        [-78.9798, 51.4192],
                        [-78.9745, 51.3981],
                        [-78.9673, 51.3872],
                        [-78.9634, 51.3704],
                        [-78.9521, 51.346],
                        [-78.9489, 51.3326],
                        [-78.9375, 51.3322],
                        [-78.9355, 51.3211],
                        [-78.9258, 51.3135],
                        [-78.9207, 51.3316],
                        [-78.9053, 51.331],
                        [-78.8848, 51.3348],
                        [-78.872, 51.3428],
                        [-78.8813, 51.3596],
                        [-78.9004, 51.3769],
                        [-78.8977, 51.3881],
                        [-78.8913, 51.3969],
                        [-78.8658, 51.4091],
                        [-78.8513, 51.4139],
                        [-78.8197, 51.4428],
                        [-78.7955, 51.4598],
                        [-78.7869, 51.4702],
                        [-78.7588, 51.4869],
                        [-78.7627, 51.4917],
                        [-78.787, 51.4939],
                        [-78.7979, 51.4966],
                        [-78.8249, 51.5068],
                        [-78.8296, 51.5154],
                        [-78.8205, 51.5207],
                        [-78.8147, 51.5309],
                        [-78.8171, 51.5451],
                        [-78.8207, 51.55],
                        [-78.8261, 51.5725],
                        [-78.8132, 51.5857],
                        [-78.8093, 51.5977],
                        [-78.8134, 51.6011],
                        [-78.8249, 51.5997],
                        [-78.8426, 51.6001],
                        [-78.846, 51.6097],
                        [-78.857, 51.6226],
                        [-78.8814, 51.6387],
                        [-78.8939, 51.6441],
                        [-78.905, 51.6461],
                        [-78.928, 51.6465],
                        [-78.9454, 51.6637],
                        [-78.9393, 51.6743],
                        [-78.9377, 51.685],
                        [-78.9481, 51.696],
                        [-78.9535, 51.6979],
                        [-78.9686, 51.6961],
                        [-78.9684, 51.7011],
                        [-78.9932, 51.7042],
                        [-78.991, 51.7109],
                        [-78.998, 51.7248],
                        [-78.9969, 51.7344],
                        [-79.0021, 51.7409],
                        [-79.0112, 51.7446],
                        [-79.0331, 51.7487],
                        [-79.0345, 51.7589],
                        [-79.0388, 51.7631],
                        [-79.0249, 51.773],
                        [-78.975, 51.7989],
                        [-78.95, 51.7939],
                        [-78.9416, 51.7901],
                        [-78.9331, 51.7906],
                        [-78.9076, 51.7983],
                        [-78.8984, 51.7997],
                        [-78.8914, 51.8062],
                        [-78.8796, 51.809],
                        [-78.8555, 51.8227],
                        [-78.837, 51.8388],
                        [-78.8329, 51.8557],
                        [-78.8353, 51.8657],
                        [-78.8449, 51.8704],
                        [-78.8442, 51.8803],
                        [-78.8577, 51.8826],
                        [-78.8656, 51.8882],
                        [-78.8661, 51.8984],
                        [-78.8743, 51.9034],
                        [-78.8967, 51.9044],
                        [-78.8969, 51.91],
                        [-78.8862, 51.9152],
                        [-78.8775, 51.9146],
                        [-78.8674, 51.9179],
                        [-78.8758, 51.9242],
                        [-78.8719, 51.9301],
                        [-78.8609, 51.9379],
                        [-78.8464, 51.9432],
                        [-78.8057, 51.9533],
                        [-78.7988, 51.9513],
                        [-78.7819, 51.9587],
                        [-78.7617, 51.9619],
                        [-78.7202, 51.9741],
                        [-78.6988, 51.9907],
                        [-78.7124, 51.9998],
                        [-78.7159, 52.0058],
                        [-78.7085, 52.0117],
                        [-78.6926, 52.0173],
                        [-78.6878, 52.0218],
                        [-78.6898, 52.031],
                        [-78.7074, 52.0469],
                        [-78.7015, 52.0481],
                        [-78.6846, 52.0439],
                        [-78.6682, 52.0448],
                        [-78.6583, 52.0503],
                        [-78.6441, 52.0521],
                        [-78.6338, 52.0609],
                        [-78.6374, 52.0701],
                        [-78.6525, 52.0741],
                        [-78.6468, 52.0825],
                        [-78.6242, 52.082],
                        [-78.6019, 52.0919],
                        [-78.6018, 52.0972],
                        [-78.5831, 52.0995],
                        [-78.5729, 52.1151],
                        [-78.576, 52.1213],
                        [-78.5665, 52.1257],
                        [-78.564, 52.1311],
                        [-78.5719, 52.1368],
                        [-78.5681, 52.1423],
                        [-78.5579, 52.1473],
                        [-78.5467, 52.1582],
                        [-78.5423, 52.17],
                        [-78.542, 52.1815],
                        [-78.5362, 52.1907],
                        [-78.5369, 52.1972],
                        [-78.5497, 52.2113],
                        [-78.5579, 52.212],
                        [-78.563, 52.2178],
                        [-78.5577, 52.222],
                        [-78.5579, 52.2337],
                        [-78.5675, 52.2437],
                        [-78.5747, 52.2556],
                        [-78.5611, 52.2601],
                        [-78.5531, 52.2727],
                        [-78.5638, 52.29],
                        [-78.5406, 52.3],
                        [-78.5333, 52.3012],
                        [-78.5092, 52.3188],
                        [-78.4993, 52.3416],
                        [-78.5044, 52.3555],
                        [-78.5102, 52.3614],
                        [-78.5221, 52.3646],
                        [-78.5281, 52.3715],
                        [-78.5181, 52.3736],
                        [-78.5169, 52.3833],
                        [-78.5257, 52.3936],
                        [-78.5069, 52.4043],
                        [-78.508, 52.4107],
                        [-78.515, 52.4166],
                        [-78.5245, 52.4143],
                        [-78.5414, 52.4178],
                        [-78.5485, 52.4218],
                        [-78.5441, 52.4294],
                        [-78.5286, 52.4421],
                        [-78.5104, 52.4447],
                        [-78.5065, 52.4487],
                        [-78.5086, 52.4605],
                        [-78.5153, 52.4713],
                        [-78.53, 52.4837],
                        [-78.5393, 52.4785],
                        [-78.5448, 52.4807],
                        [-78.5302, 52.4876],
                        [-78.5357, 52.4949],
                        [-78.5565, 52.5033],
                        [-78.5633, 52.5153],
                        [-78.5792, 52.5236],
                        [-78.5861, 52.5295],
                        [-78.5993, 52.5313],
                        [-78.6106, 52.5279],
                        [-78.6111, 52.5235],
                        [-78.6219, 52.5139],
                        [-78.6363, 52.5158],
                        [-78.6298, 52.5208],
                        [-78.6349, 52.5263],
                        [-78.653, 52.525],
                        [-78.6521, 52.5325],
                        [-78.6378, 52.5303],
                        [-78.6366, 52.5351],
                        [-78.6691, 52.5364],
                        [-78.6785, 52.5433],
                        [-78.6963, 52.5419],
                        [-78.7066, 52.5437],
                        [-78.7057, 52.5491],
                        [-78.7278, 52.5466],
                        [-78.7397, 52.5507],
                        [-78.7205, 52.5546],
                        [-78.7048, 52.5638],
                        [-78.7341, 52.5602],
                        [-78.7512, 52.5562],
                        [-78.7666, 52.5571],
                        [-78.7612, 52.5628],
                        [-78.754, 52.5616],
                        [-78.7451, 52.5679],
                        [-78.7318, 52.573],
                        [-78.7151, 52.5834],
                        [-78.697, 52.587],
                        [-78.6879, 52.5866],
                        [-78.6838, 52.5922],
                        [-78.6952, 52.5946],
                        [-78.6975, 52.5992],
                        [-78.685, 52.6055],
                        [-78.6932, 52.6087],
                        [-78.7136, 52.6119],
                        [-78.7019, 52.6164],
                        [-78.7107, 52.6224],
                        [-78.7184, 52.619],
                        [-78.7323, 52.6251],
                        [-78.7167, 52.6311],
                        [-78.7146, 52.6429],
                        [-78.7263, 52.6439],
                        [-78.7353, 52.6277],
                        [-78.7595, 52.6246],
                        [-78.7709, 52.6219],
                        [-78.774, 52.6285],
                        [-78.7599, 52.6316],
                        [-78.7653, 52.6395],
                        [-78.7878, 52.6415],
                        [-78.7711, 52.6483],
                        [-78.7502, 52.6524],
                        [-78.73, 52.6612],
                        [-78.712, 52.6773],
                        [-78.7311, 52.6898],
                        [-78.7599, 52.6882],
                        [-78.7661, 52.6846],
                        [-78.7973, 52.6846],
                        [-78.8097, 52.6831],
                        [-78.8231, 52.6909],
                        [-78.8274, 52.6994],
                        [-78.8148, 52.7016],
                        [-78.8025, 52.6976],
                        [-78.7818, 52.7014],
                        [-78.7768, 52.6994],
                        [-78.7678, 52.7074],
                        [-78.7654, 52.7178],
                        [-78.7813, 52.7215],
                        [-78.7915, 52.7276],
                        [-78.7851, 52.7345],
                        [-78.792, 52.7405],
                        [-78.8105, 52.7261],
                        [-78.813, 52.7309],
                        [-78.8283, 52.7276],
                        [-78.8481, 52.7435],
                        [-78.8538, 52.7455],
                        [-78.8663, 52.7398],
                        [-78.872, 52.7421],
                        [-78.8561, 52.7496],
                        [-78.855, 52.7546],
                        [-78.8451, 52.7567],
                        [-78.8383, 52.7633],
                        [-78.8438, 52.7682],
                        [-78.8238, 52.7784],
                        [-78.8152, 52.7775],
                        [-78.7901, 52.7629],
                        [-78.7979, 52.7578],
                        [-78.7826, 52.7549],
                        [-78.7757, 52.7562],
                        [-78.7646, 52.7656],
                        [-78.7475, 52.7715],
                        [-78.7158, 52.7756],
                        [-78.7046, 52.7822],
                        [-78.7208, 52.7839],
                        [-78.7311, 52.7763],
                        [-78.7354, 52.7832],
                        [-78.7279, 52.7845],
                        [-78.7201, 52.7907],
                        [-78.743, 52.7986],
                        [-78.7294, 52.8053],
                        [-78.7285, 52.8146],
                        [-78.7131, 52.8227],
                        [-78.7307, 52.8299],
                        [-78.7436, 52.8263],
                        [-78.7449, 52.8196],
                        [-78.7359, 52.8129],
                        [-78.7461, 52.8095],
                        [-78.7649, 52.8102],
                        [-78.7678, 52.8064],
                        [-78.7881, 52.814],
                        [-78.7601, 52.8237],
                        [-78.759, 52.831],
                        [-78.745, 52.8355],
                        [-78.7359, 52.8342],
                        [-78.7254, 52.837],
                        [-78.7236, 52.8422],
                        [-78.7414, 52.8429],
                        [-78.7443, 52.8483],
                        [-78.7198, 52.8517],
                        [-78.7043, 52.859],
                        [-78.7027, 52.8641],
                        [-78.7095, 52.8715],
                        [-78.7206, 52.8666],
                        [-78.746, 52.8614],
                        [-78.7574, 52.8614],
                        [-78.7686, 52.8548],
                        [-78.7969, 52.8537],
                        [-78.8025, 52.8572],
                        [-78.8114, 52.8545],
                        [-78.8203, 52.8606],
                        [-78.8377, 52.8663],
                        [-78.835, 52.8733],
                        [-78.8418, 52.8745],
                        [-78.8494, 52.8809],
                        [-78.8677, 52.8799],
                        [-78.8792, 52.8844],
                        [-78.8831, 52.8891],
                        [-78.8696, 52.9019],
                        [-78.8788, 52.9067],
                        [-78.8982, 52.908],
                        [-78.9054, 52.9139],
                        [-78.8927, 52.9197],
                        [-78.888, 52.9245],
                        [-78.8641, 52.9317],
                        [-78.8717, 52.94],
                        [-78.8635, 52.9452],
                        [-78.8466, 52.944],
                        [-78.8371, 52.9495],
                        [-78.8258, 52.9522],
                        [-78.8273, 52.9572],
                        [-78.81, 52.9585],
                        [-78.8025, 52.9502],
                        [-78.7906, 52.9544],
                        [-78.7841, 52.9531],
                        [-78.7728, 52.9566],
                        [-78.7822, 52.9677],
                        [-78.8092, 52.9679],
                        [-78.8247, 52.9667],
                        [-78.8382, 52.9737],
                        [-78.8126, 52.978],
                        [-78.7985, 52.9878],
                        [-78.8251, 52.9867],
                        [-78.8552, 52.9877],
                        [-78.8468, 52.9927],
                        [-78.8387, 52.991],
                        [-78.8145, 52.9959],
                        [-78.8398, 53],
                        [-78.8556, 53],
                        [-78.8721, 52.9979],
                        [-78.8623, 52.9879],
                        [-78.8802, 52.9816],
                        [-78.8899, 52.9876],
                        [-78.9212, 52.9852],
                        [-78.9145, 52.9953],
                        [-78.9329, 52.9925],
                        [-78.9565, 52.9987],
                        [-78.9783, 53.0114],
                        [-78.9881, 53.0126],
                        [-78.9849, 53.0256],
                        [-78.9553, 53.0278],
                        [-78.958, 53.0395],
                        [-78.9478, 53.0412],
                        [-78.9398, 53.0385],
                        [-78.9284, 53.0402],
                        [-78.9211, 53.0491],
                        [-78.9202, 53.057],
                        [-78.9273, 53.0652],
                        [-78.9395, 53.0713],
                        [-78.9652, 53.07],
                        [-78.9768, 53.0636],
                        [-78.9895, 53.0663],
                        [-78.9871, 53.0748],
                        [-78.9732, 53.079],
                        [-78.9633, 53.0924],
                        [-78.9631, 53.1011],
                        [-78.9506, 53.1109],
                        [-78.9558, 53.1206],
                        [-78.9475, 53.1225],
                        [-78.9499, 53.1322],
                        [-78.9451, 53.1384],
                        [-78.9366, 53.1391],
                        [-78.9495, 53.1501],
                        [-78.9453, 53.1574],
                        [-78.9352, 53.1615],
                        [-78.9332, 53.1677],
                        [-78.953, 53.1718],
                        [-78.9604, 53.1757],
                        [-78.9527, 53.1848],
                        [-78.9446, 53.1892],
                        [-78.91, 53.1954],
                        [-78.9045, 53.2067],
                        [-78.9184, 53.2099],
                        [-78.9304, 53.2094],
                        [-78.9208, 53.2191],
                        [-78.9073, 53.2155],
                        [-78.9016, 53.2196],
                        [-78.8976, 53.2394],
                        [-78.9194, 53.2398],
                        [-78.9295, 53.2345],
                        [-78.9324, 53.2442],
                        [-78.9045, 53.2626],
                        [-78.9108, 53.2657],
                        [-78.9243, 53.2658],
                        [-78.9257, 53.2724],
                        [-78.9466, 53.2735],
                        [-78.9634, 53.2769],
                        [-78.9673, 53.2874],
                        [-78.9476, 53.299],
                        [-78.941, 53.3106],
                        [-78.9567, 53.314],
                        [-78.9464, 53.3216],
                        [-78.9572, 53.3255],
                        [-78.9751, 53.324],
                        [-78.9703, 53.3375],
                        [-78.9591, 53.3444],
                        [-78.9753, 53.3442],
                        [-78.9788, 53.35],
                        [-78.9552, 53.3532],
                        [-78.9495, 53.3516],
                        [-78.9337, 53.3565],
                        [-78.9356, 53.3612],
                        [-78.9544, 53.3639],
                        [-78.9415, 53.3865],
                        [-78.9532, 53.3856],
                        [-78.9523, 53.4007],
                        [-78.9777, 53.3996],
                        [-78.9855, 53.4033],
                        [-78.9721, 53.4109],
                        [-78.974, 53.4204],
                        [-78.9796, 53.4249],
                        [-79.0267, 53.4194],
                        [-79.0298, 53.4161],
                        [-79.0448, 53.4126],
                        [-79.0483, 53.4151],
                        [-79.0343, 53.426],
                        [-79.0316, 53.4324],
                        [-79.0213, 53.4295],
                        [-79.0095, 53.4329],
                        [-78.9939, 53.4313],
                        [-78.9903, 53.443],
                        [-79.0057, 53.4453],
                        [-79.0127, 53.4489],
                        [-79.0393, 53.4499],
                        [-79.0433, 53.4445],
                        [-79.0599, 53.4363],
                        [-79.0742, 53.4375],
                        [-79.0764, 53.443],
                        [-79.0456, 53.453],
                        [-79.0559, 53.459],
                        [-79.07, 53.4547],
                        [-79.0812, 53.4542],
                        [-79.0932, 53.457],
                        [-79.0897, 53.4623],
                        [-79.0921, 53.4772],
                        [-79.105, 53.4778],
                        [-79.0993, 53.4855],
                        [-79.109, 53.4909],
                        [-79.1205, 53.4912],
                        [-79.1188, 53.4984],
                        [-79.1334, 53.4973],
                        [-79.1341, 53.5039],
                        [-79.1198, 53.5032],
                        [-79.0995, 53.5185],
                        [-79.0815, 53.5226],
                        [-79.0743, 53.5189],
                        [-79.059, 53.5296],
                        [-79.0339, 53.5303],
                        [-79.0234, 53.5271],
                        [-79.0068, 53.5286],
                        [-79.0062, 53.5365],
                        [-79.0256, 53.5381],
                        [-79.029, 53.5465],
                        [-79.0302, 53.5604],
                        [-79.0225, 53.5629],
                        [-78.9983, 53.5554],
                        [-78.9931, 53.5561],
                        [-78.9812, 53.5452],
                        [-78.9813, 53.5391],
                        [-78.9606, 53.537],
                        [-78.9463, 53.5388],
                        [-78.9131, 53.5493],
                        [-78.9013, 53.549],
                        [-78.8851, 53.557],
                        [-78.8953, 53.5663],
                        [-78.9062, 53.5661],
                        [-78.9262, 53.5695],
                        [-78.9361, 53.5634],
                        [-78.9424, 53.5741],
                        [-78.9557, 53.5742],
                        [-78.9551, 53.5842],
                        [-78.968, 53.5847],
                        [-78.9552, 53.5928],
                        [-78.9567, 53.5989],
                        [-78.9854, 53.5986],
                        [-78.9802, 53.6032],
                        [-78.9912, 53.6087],
                        [-78.9967, 53.6047],
                        [-79.0063, 53.6078],
                        [-79.0256, 53.5873],
                        [-79.0449, 53.5931],
                        [-79.0549, 53.5885],
                        [-79.0682, 53.5898],
                        [-79.0691, 53.596],
                        [-79.0514, 53.6016],
                        [-79.0597, 53.614],
                        [-79.0526, 53.6167],
                        [-79.041, 53.6295],
                        [-79.0321, 53.6275],
                        [-79.0395, 53.6144],
                        [-79.018, 53.6133],
                        [-79.0103, 53.6194],
                        [-79.0142, 53.6319],
                        [-78.997, 53.6366],
                        [-79.0025, 53.6425],
                        [-79.0352, 53.6424],
                        [-79.0397, 53.6467],
                        [-79.0241, 53.6491],
                        [-79.024, 53.6559],
                        [-79.037, 53.6548],
                        [-79.0395, 53.666],
                        [-79.0138, 53.6641],
                        [-79.004, 53.6718],
                        [-78.9996, 53.6793],
                        [-79.0149, 53.6869],
                        [-79.0484, 53.6869],
                        [-79.0485, 53.6937],
                        [-79.0601, 53.6933],
                        [-79.0812, 53.7013],
                        [-79.0883, 53.7088],
                        [-79.0616, 53.7098],
                        [-79.0527, 53.704],
                        [-79.0462, 53.7057],
                        [-79.0539, 53.713],
                        [-79.0613, 53.7121],
                        [-79.081, 53.7141],
                        [-79.0771, 53.7201],
                        [-79.0668, 53.7206],
                        [-79.0621, 53.7166],
                        [-79.0472, 53.7238],
                        [-79.0311, 53.7233],
                        [-79.0243, 53.7298],
                        [-79.0421, 53.7308],
                        [-79.0593, 53.7354],
                        [-79.0709, 53.7411],
                        [-79.0705, 53.7518],
                        [-79.0826, 53.7575],
                        [-79.072, 53.7636],
                        [-79.0664, 53.7591],
                        [-79.0527, 53.7622],
                        [-79.0549, 53.7684],
                        [-79.0426, 53.7697],
                        [-79.0406, 53.7823],
                        [-79.0358, 53.7881],
                        [-79.0531, 53.7878],
                        [-79.0684, 53.7908],
                        [-79.0715, 53.7963],
                        [-79.0541, 53.7958],
                        [-79.053, 53.804],
                        [-79.0419, 53.8133],
                        [-79.0583, 53.8247],
                        [-79.0586, 53.8422],
                        [-79.0678, 53.8586],
                        [-79.0573, 53.8753],
                        [-79.066, 53.8859],
                        [-79.0918, 53.8886],
                        [-79.102, 53.8983],
                        [-79.0789, 53.8959],
                        [-79.0607, 53.8972],
                        [-79.0541, 53.9015],
                        [-79.041, 53.9028],
                        [-79.0378, 53.8951],
                        [-79.0144, 53.8932],
                        [-79.0095, 53.8905],
                        [-78.9973, 53.8929],
                        [-78.9788, 53.886],
                        [-78.9543, 53.89],
                        [-78.9571, 53.9027],
                        [-78.9662, 53.9049],
                        [-78.9813, 53.9037],
                        [-79.0041, 53.9061],
                        [-79.0081, 53.9115],
                        [-79.0046, 53.9168],
                        [-79.0173, 53.9178],
                        [-79.0436, 53.9263],
                        [-79.0466, 53.9247],
                        [-79.0932, 53.9301],
                        [-79.0901, 53.9358],
                        [-79.1223, 53.9451],
                        [-79.1329, 53.9421],
                        [-79.1551, 53.949],
                        [-79.15, 53.9524],
                        [-79.1347, 53.9465],
                        [-79.1192, 53.9489],
                        [-79.1092, 53.9461],
                        [-79.0811, 53.9428],
                        [-79.0642, 53.9387],
                        [-79.0679, 53.9458],
                        [-79.0873, 53.9468],
                        [-79.0877, 53.9542],
                        [-79.0701, 53.9537],
                        [-79.0537, 53.951],
                        [-79.0399, 53.956],
                        [-79.0361, 53.9631],
                        [-79.0529, 53.9702],
                        [-79.0937, 53.9696],
                        [-79.0916, 53.9739],
                        [-79.0623, 53.9748],
                        [-79.0617, 53.9874],
                        [-79.0722, 53.995],
                        [-79.064, 53.9971],
                        [-79.0438, 53.9974],
                        [-79.0329, 53.9955],
                        [-79.0099, 53.9982],
                        [-78.9989, 53.9953],
                        [-78.9958, 53.988],
                        [-78.9742, 53.9913],
                        [-78.9694, 53.9875],
                        [-78.9519, 53.991],
                        [-78.9631, 54.0029],
                        [-78.9643, 54.0089],
                        [-78.9745, 54.0082],
                        [-78.9888, 54.0146],
                        [-79.006, 54.0187],
                        [-79.0207, 54.0168],
                        [-79.0389, 54.0254],
                        [-79.0286, 54.029],
                        [-79.0249, 54.0375],
                        [-79.0682, 54.034],
                        [-79.0849, 54.03],
                        [-79.0888, 54.0251],
                        [-79.1003, 54.0311],
                        [-79.091, 54.0332],
                        [-79.0772, 54.0442],
                        [-79.0755, 54.0495],
                        [-79.0904, 54.0509],
                        [-79.0948, 54.045],
                        [-79.1078, 54.0463],
                        [-79.1079, 54.0513],
                        [-79.1002, 54.0571],
                        [-79.107, 54.0658],
                        [-79.1155, 54.0641],
                        [-79.1248, 54.067],
                        [-79.1382, 54.0658],
                        [-79.1288, 54.0792],
                        [-79.1497, 54.0848],
                        [-79.1662, 54.0822],
                        [-79.1603, 54.0946],
                        [-79.1484, 54.0939],
                        [-79.1351, 54.0885],
                        [-79.1247, 54.0912],
                        [-79.1001, 54.0941],
                        [-79.0927, 54.0976],
                        [-79.0832, 54.0927],
                        [-79.0653, 54.0952],
                        [-79.0593, 54.0857],
                        [-79.0508, 54.0886],
                        [-79.0524, 54.096],
                        [-79.0472, 54.0995],
                        [-79.0512, 54.1061],
                        [-79.0436, 54.114],
                        [-79.0679, 54.1123],
                        [-79.078, 54.1192],
                        [-79.1012, 54.1291],
                        [-79.0889, 54.1307],
                        [-79.0785, 54.1268],
                        [-79.0725, 54.1352],
                        [-79.0777, 54.1378],
                        [-79.1088, 54.1317],
                        [-79.1124, 54.1418],
                        [-79.0964, 54.1418],
                        [-79.0776, 54.1479],
                        [-79.0744, 54.1557],
                        [-79.0627, 54.1612],
                        [-79.0581, 54.1701],
                        [-79.0876, 54.1695],
                        [-79.0867, 54.165],
                        [-79.1062, 54.1628],
                        [-79.1536, 54.1628],
                        [-79.1591, 54.1653],
                        [-79.1755, 54.1653],
                        [-79.1902, 54.1607],
                        [-79.2031, 54.1617],
                        [-79.2053, 54.1494],
                        [-79.2093, 54.1434],
                        [-79.2186, 54.1431],
                        [-79.2288, 54.1465],
                        [-79.2442, 54.1455],
                        [-79.2542, 54.1483],
                        [-79.2454, 54.1556],
                        [-79.226, 54.1663],
                        [-79.2156, 54.1695],
                        [-79.2216, 54.1752],
                        [-79.2304, 54.1755],
                        [-79.2393, 54.1848],
                        [-79.2495, 54.189],
                        [-79.2405, 54.2024],
                        [-79.2274, 54.2096],
                        [-79.2497, 54.2094],
                        [-79.256, 54.2033],
                        [-79.2607, 54.1814],
                        [-79.2862, 54.1759],
                        [-79.2984, 54.1768],
                        [-79.3015, 54.1806],
                        [-79.3133, 54.1799],
                        [-79.3229, 54.1743],
                        [-79.3417, 54.1836],
                        [-79.3611, 54.1845],
                        [-79.3735, 54.1769],
                        [-79.382, 54.19],
                        [-79.3625, 54.1883],
                        [-79.3533, 54.1945],
                        [-79.3552, 54.1995],
                        [-79.3494, 54.2067],
                        [-79.3348, 54.2086],
                        [-79.3274, 54.2028],
                        [-79.3179, 54.2073],
                        [-79.3256, 54.213],
                        [-79.3145, 54.2173],
                        [-79.2927, 54.2215],
                        [-79.2819, 54.2208],
                        [-79.2802, 54.2269],
                        [-79.2883, 54.2285],
                        [-79.287, 54.2356],
                        [-79.3031, 54.2446],
                        [-79.3146, 54.2441],
                        [-79.3214, 54.2503],
                        [-79.3376, 54.2514],
                        [-79.3427, 54.2477],
                        [-79.3555, 54.2497],
                        [-79.3659, 54.2548],
                        [-79.377, 54.2534],
                        [-79.3721, 54.2634],
                        [-79.373, 54.2703],
                        [-79.36, 54.2738],
                        [-79.3704, 54.2783],
                        [-79.3605, 54.2835],
                        [-79.371, 54.2871],
                        [-79.3858, 54.2861],
                        [-79.3823, 54.2765],
                        [-79.3895, 54.2708],
                        [-79.4023, 54.2714],
                        [-79.4171, 54.2802],
                        [-79.4037, 54.2844],
                        [-79.4092, 54.295],
                        [-79.4218, 54.293],
                        [-79.4263, 54.3007],
                        [-79.4391, 54.3046],
                        [-79.4628, 54.2971],
                        [-79.4678, 54.3047],
                        [-79.4844, 54.3051],
                        [-79.4816, 54.3108],
                        [-79.4636, 54.3131],
                        [-79.4619, 54.3247],
                        [-79.4554, 54.3277],
                        [-79.4203, 54.3331],
                        [-79.4049, 54.3374],
                        [-79.3991, 54.3323],
                        [-79.3877, 54.3301],
                        [-79.3731, 54.3183],
                        [-79.3668, 54.3162],
                        [-79.3445, 54.3172],
                        [-79.3366, 54.3148],
                        [-79.3306, 54.3292],
                        [-79.3159, 54.3413],
                        [-79.3045, 54.3363],
                        [-79.287, 54.3416],
                        [-79.2825, 54.3527],
                        [-79.3051, 54.3502],
                        [-79.3227, 54.341],
                        [-79.3385, 54.3347],
                        [-79.3392, 54.3302],
                        [-79.3486, 54.3226],
                        [-79.3718, 54.3288],
                        [-79.3799, 54.3282],
                        [-79.3883, 54.3352],
                        [-79.3982, 54.3351],
                        [-79.4036, 54.339],
                        [-79.4214, 54.3349],
                        [-79.4555, 54.3297],
                        [-79.46, 54.3276],
                        [-79.4718, 54.3379],
                        [-79.4526, 54.343],
                        [-79.4515, 54.3477],
                        [-79.4704, 54.3497],
                        [-79.4788, 54.3538],
                        [-79.4763, 54.3679],
                        [-79.4862, 54.367],
                        [-79.494, 54.3739],
                        [-79.4854, 54.376],
                        [-79.493, 54.3878],
                        [-79.4791, 54.394],
                        [-79.4963, 54.4063],
                        [-79.4771, 54.4042],
                        [-79.4656, 54.4001],
                        [-79.4598, 54.4058],
                        [-79.4654, 54.4122],
                        [-79.4813, 54.4184],
                        [-79.49, 54.414],
                        [-79.5012, 54.4174],
                        [-79.488, 54.4228],
                        [-79.4787, 54.4221],
                        [-79.4718, 54.4267],
                        [-79.4761, 54.4379],
                        [-79.4717, 54.4406],
                        [-79.4772, 54.448],
                        [-79.4737, 54.4532],
                        [-79.4918, 54.461],
                        [-79.513, 54.4568],
                        [-79.5115, 54.464],
                        [-79.5006, 54.4682],
                        [-79.5054, 54.4753],
                        [-79.5015, 54.4839],
                        [-79.5125, 54.4886],
                        [-79.4885, 54.492],
                        [-79.4902, 54.4965],
                        [-79.5075, 54.4972],
                        [-79.5217, 54.4997],
                        [-79.5057, 54.5046],
                        [-79.5037, 54.5145],
                        [-79.516, 54.5152],
                        [-79.5105, 54.5259],
                        [-79.5154, 54.5324],
                        [-79.5288, 54.5283],
                        [-79.5312, 54.5327],
                        [-79.5132, 54.5384],
                        [-79.5431, 54.5407],
                        [-79.5497, 54.5478],
                        [-79.5594, 54.5474],
                        [-79.5859, 54.5542],
                        [-79.5704, 54.5683],
                        [-79.5406, 54.5532],
                        [-79.5277, 54.565],
                        [-79.5184, 54.5666],
                        [-79.5124, 54.5741],
                        [-79.5187, 54.5778],
                        [-79.5053, 54.5844],
                        [-79.5024, 54.5887],
                        [-79.5116, 54.592],
                        [-79.5257, 54.586],
                        [-79.5434, 54.5927],
                        [-79.5549, 54.6047],
                        [-79.5438, 54.6093],
                        [-79.5328, 54.6101],
                        [-79.5322, 54.6163],
                        [-79.5427, 54.6188],
                        [-79.5541, 54.612],
                        [-79.5625, 54.6037],
                        [-79.5668, 54.6141],
                        [-79.5563, 54.6199],
                        [-79.5697, 54.6218],
                        [-79.5827, 54.6157],
                        [-79.5926, 54.6219],
                        [-79.5817, 54.6255],
                        [-79.5918, 54.6323],
                        [-79.6078, 54.6285],
                        [-79.5954, 54.6243],
                        [-79.6042, 54.6143],
                        [-79.6152, 54.6191],
                        [-79.6505, 54.6134],
                        [-79.6549, 54.6284],
                        [-79.6432, 54.6363],
                        [-79.6494, 54.638],
                        [-79.6668, 54.6298],
                        [-79.6827, 54.6322],
                        [-79.6889, 54.6363],
                        [-79.6989, 54.6332],
                        [-79.715, 54.6361],
                        [-79.7327, 54.6291],
                        [-79.7462, 54.6287],
                        [-79.7503, 54.6352],
                        [-79.7466, 54.6428],
                        [-79.7312, 54.6354],
                        [-79.7164, 54.6462],
                        [-79.7124, 54.6545],
                        [-79.7004, 54.6541],
                        [-79.6773, 54.6622],
                        [-79.6549, 54.6794],
                        [-79.6432, 54.6823],
                        [-79.6191, 54.6927],
                        [-79.613, 54.6968],
                        [-79.5979, 54.7001],
                        [-79.5796, 54.7107],
                        [-79.5758, 54.7173],
                        [-79.5653, 54.7225],
                        [-79.5538, 54.7249],
                        [-79.535, 54.7205],
                        [-79.5024, 54.7306],
                        [-79.4791, 54.7357],
                        [-79.4669, 54.7469],
                        [-79.4507, 54.7379],
                        [-79.4369, 54.7392],
                        [-79.4162, 54.7489],
                        [-79.4092, 54.7502],
                        [-79.3875, 54.7642],
                        [-79.3773, 54.7584],
                        [-79.3598, 54.7568],
                        [-79.3406, 54.7582],
                        [-79.3168, 54.7687],
                        [-79.3087, 54.768],
                        [-79.2908, 54.774],
                        [-79.2821, 54.782],
                        [-79.2677, 54.7824],
                        [-79.2438, 54.7886],
                        [-79.2262, 54.798],
                        [-79.1891, 54.7979],
                        [-79.1277, 54.8122],
                        [-79.1208, 54.8116],
                        [-79.1023, 54.8185],
                        [-79.0849, 54.8188],
                        [-79.0787, 54.821],
                        [-79.063, 54.8206],
                        [-79.0515, 54.8247],
                        [-79.043, 54.8241],
                        [-79.0267, 54.8291],
                        [-79.0095, 54.8385],
                        [-78.9918, 54.8329],
                        [-78.9803, 54.8338],
                        [-78.9543, 54.8446],
                        [-78.9386, 54.8564],
                        [-78.9356, 54.8734],
                        [-78.905, 54.8836],
                        [-78.8896, 54.8844],
                        [-78.8934, 54.8937],
                        [-78.8789, 54.8916],
                        [-78.8679, 54.901],
                        [-78.8607, 54.897],
                        [-78.8362, 54.9115],
                        [-78.825, 54.9073],
                        [-78.7879, 54.9067],
                        [-78.7668, 54.9086],
                        [-78.7649, 54.9126],
                        [-78.7497, 54.9147],
                        [-78.7206, 54.927],
                        [-78.7125, 54.9341],
                        [-78.7023, 54.9321],
                        [-78.6807, 54.9341],
                        [-78.6597, 54.9431],
                        [-78.6467, 54.947],
                        [-78.6165, 54.9503],
                        [-78.6061, 54.9557],
                        [-78.6076, 54.9602],
                        [-78.5811, 54.9601],
                        [-78.5605, 54.971],
                        [-78.5644, 54.9757],
                        [-78.5466, 54.9808],
                        [-78.546, 54.9886],
                        [-78.5216, 54.9922],
                        [-78.4927, 55.0041],
                        [-78.4838, 55.0028],
                        [-78.4502, 55.0094],
                        [-78.4243, 55.0204],
                        [-78.4066, 55.0164],
                        [-78.4001, 55.0206],
                        [-78.3873, 55.0233],
                        [-78.3642, 55.0312],
                        [-78.3513, 55.0323],
                        [-78.3294, 55.0418],
                        [-78.3176, 55.0425],
                        [-78.3103, 55.046],
                        [-78.3032, 55.0545],
                        [-78.2931, 55.0553],
                        [-78.2671, 55.0704],
                        [-78.2619, 55.077],
                        [-78.2721, 55.0809],
                        [-78.2613, 55.0863],
                        [-78.2547, 55.0807],
                        [-78.2255, 55.0939],
                        [-78.2312, 55.0983],
                        [-78.2207, 55.104],
                        [-78.2048, 55.1065],
                        [-78.1907, 55.117],
                        [-78.1928, 55.1202],
                        [-78.1821, 55.127],
                        [-78.1766, 55.122],
                        [-78.1595, 55.1283],
                        [-78.1578, 55.1357],
                        [-78.1262, 55.1505],
                        [-78.1191, 55.1501],
                        [-78.0905, 55.1594],
                        [-78.0782, 55.1665],
                        [-78.0407, 55.1757],
                        [-78.0325, 55.1765],
                        [-78.0146, 55.1877],
                        [-78.0098, 55.1878],
                        [-77.9872, 55.1991],
                        [-77.9703, 55.2056],
                        [-77.9522, 55.2103],
                        [-77.9385, 55.2116],
                        [-77.9319, 55.216],
                        [-77.9177, 55.2174],
                        [-77.9029, 55.2248],
                        [-77.9007, 55.2291],
                        [-77.8867, 55.2323],
                        [-77.8692, 55.2431],
                        [-77.8487, 55.2531],
                        [-77.8484, 55.257],
                        [-77.7833, 55.2716],
                        [-77.7706, 55.2794],
                        [-77.7589, 55.2952],
                        [-77.7492, 55.2984],
                        [-77.7374, 55.3138],
                        [-77.7089, 55.3328],
                        [-77.6803, 55.347],
                        [-77.6772, 55.3542],
                        [-77.6449, 55.3727],
                        [-77.6314, 55.3793],
                        [-77.6146, 55.3775],
                        [-77.6058, 55.3849],
                        [-77.5768, 55.3845],
                        [-77.5601, 55.3961],
                        [-77.5543, 55.3959],
                        [-77.5451, 55.4083],
                        [-77.5337, 55.4157],
                        [-77.5072, 55.4278],
                        [-77.503, 55.4331],
                        [-77.4901, 55.438],
                        [-77.4789, 55.4487],
                        [-77.4695, 55.4513],
                        [-77.4597, 55.4584],
                        [-77.4565, 55.4672],
                        [-77.439, 55.4721],
                        [-77.4279, 55.4785],
                        [-77.4256, 55.486],
                        [-77.4169, 55.4922],
                        [-77.4052, 55.4915],
                        [-77.3999, 55.4994],
                        [-77.3862, 55.5019],
                        [-77.3738, 55.5069],
                        [-77.3662, 55.5143],
                        [-77.3491, 55.5177],
                        [-77.3433, 55.5267],
                        [-77.3195, 55.5388],
                        [-77.317, 55.5474],
                        [-77.3076, 55.5502],
                        [-77.3038, 55.56],
                        [-77.2932, 55.5651],
                        [-77.2843, 55.5658],
                        [-77.2578, 55.5751],
                        [-77.2387, 55.586],
                        [-77.2337, 55.5984],
                        [-77.2276, 55.6025],
                        [-77.2069, 55.6083],
                        [-77.1979, 55.6142],
                        [-77.1967, 55.6221],
                        [-77.1885, 55.6247],
                        [-77.1865, 55.6312],
                        [-77.1781, 55.6353],
                        [-77.1643, 55.6524],
                        [-77.1509, 55.6597],
                        [-77.1394, 55.6576],
                        [-77.1209, 55.6628],
                        [-77.1139, 55.675],
                        [-77.111, 55.6868],
                        [-77.0832, 55.7112],
                        [-77.0783, 55.7198],
                        [-77.0991, 55.7159],
                        [-77.1069, 55.7053],
                        [-77.1133, 55.7016],
                        [-77.1438, 55.6918],
                        [-77.1468, 55.6846],
                        [-77.1597, 55.6797],
                        [-77.1708, 55.6785],
                        [-77.1729, 55.6732],
                        [-77.1966, 55.6664],
                        [-77.1843, 55.6811],
                        [-77.1737, 55.6831],
                        [-77.1669, 55.6904],
                        [-77.1444, 55.7013],
                        [-77.1304, 55.71],
                        [-77.1187, 55.723],
                        [-77.1081, 55.7253],
                        [-77.1063, 55.7306],
                        [-77.0937, 55.742],
                        [-77.0841, 55.7477],
                        [-77.0699, 55.7474],
                        [-77.0706, 55.7572],
                        [-77.0647, 55.7573],
                        [-77.05, 55.7686],
                        [-77.0353, 55.7828],
                        [-76.9987, 55.8058],
                        [-76.9921, 55.8154],
                        [-76.994, 55.8219],
                        [-76.978, 55.8265],
                        [-76.9699, 55.835],
                        [-76.9567, 55.8353],
                        [-76.9601, 55.846],
                        [-76.9397, 55.8601],
                        [-76.9331, 55.8719],
                        [-76.9222, 55.8755],
                        [-76.9042, 55.8857],
                        [-76.8859, 55.9009],
                        [-76.8835, 55.9118],
                        [-76.8771, 55.9155],
                        [-76.8579, 55.9184],
                        [-76.8593, 55.9246],
                        [-76.8359, 55.9272],
                        [-76.8302, 55.9298],
                        [-76.8222, 55.9491],
                        [-76.8097, 55.9567],
                        [-76.8025, 55.9759],
                        [-76.8079, 55.9781],
                        [-76.7972, 55.9854],
                        [-76.799, 56.0012],
                        [-76.7806, 56.0013],
                        [-76.7648, 56.0116],
                        [-76.7419, 56.0123],
                        [-76.7212, 56.0213],
                        [-76.7138, 56.0305],
                        [-76.7069, 56.0339],
                        [-76.7005, 56.0431],
                        [-76.69, 56.0492],
                        [-76.6865, 56.0663],
                        [-76.6824, 56.074],
                        [-76.6878, 56.0834],
                        [-76.6721, 56.1062],
                        [-76.66, 56.1142],
                        [-76.6495, 56.1246],
                        [-76.6507, 56.1329],
                        [-76.6423, 56.1466],
                        [-76.6566, 56.1652],
                        [-76.651, 56.1733],
                        [-76.6519, 56.1944],
                        [-76.6403, 56.1959],
                        [-76.6258, 56.194],
                        [-76.6152, 56.2028],
                        [-76.6261, 56.2275],
                        [-76.6265, 56.2369],
                        [-76.6154, 56.2488],
                        [-76.5992, 56.2546],
                        [-76.5795, 56.2676],
                        [-76.5632, 56.2807],
                        [-76.5611, 56.2875],
                        [-76.5481, 56.301],
                        [-76.5445, 56.3352],
                        [-76.5512, 56.3376],
                        [-76.5424, 56.3444],
                        [-76.5505, 56.3512],
                        [-76.5428, 56.3563],
                        [-76.5293, 56.3731],
                        [-76.5271, 56.3811],
                        [-76.5346, 56.3952],
                        [-76.5339, 56.4133],
                        [-76.5225, 56.4285],
                        [-76.5276, 56.4432],
                        [-76.5192, 56.454],
                        [-76.5192, 56.4595],
                        [-76.5283, 56.4671],
                        [-76.5432, 56.4704],
                        [-76.551, 56.4751],
                        [-76.5479, 56.4808],
                        [-76.5503, 56.4993],
                        [-76.5451, 56.5064],
                        [-76.5548, 56.5186],
                        [-76.5418, 56.5286],
                        [-76.5404, 56.536],
                        [-76.5458, 56.5454],
                        [-76.5565, 56.5567],
                        [-76.5446, 56.5651],
                        [-76.5345, 56.5985],
                        [-76.5489, 56.6031],
                        [-76.5462, 56.6151],
                        [-76.5325, 56.6346],
                        [-76.5306, 56.6494],
                        [-76.5396, 56.6587],
                        [-76.5349, 56.691],
                        [-76.5364, 56.6939],
                        [-76.5315, 56.7186],
                        [-76.5353, 56.7307],
                        [-76.5365, 56.7434],
                        [-76.5323, 56.7469],
                        [-76.533, 56.7617],
                        [-76.537, 56.7676],
                        [-76.5348, 56.7783],
                        [-76.5361, 56.7886],
                        [-76.5332, 56.7986],
                        [-76.5351, 56.8061],
                        [-76.5337, 56.8218],
                        [-76.5364, 56.8341],
                        [-76.5422, 56.8379],
                        [-76.542, 56.8586],
                        [-76.549, 56.8893],
                        [-76.5525, 56.914],
                        [-76.561, 56.9237],
                        [-76.5709, 56.9424],
                        [-76.5651, 56.9514],
                        [-76.5686, 56.9699],
                        [-76.5683, 56.9796],
                        [-76.5594, 56.9877],
                        [-76.5658, 56.9965],
                        [-76.5619, 57.0007],
                        [-76.5721, 57.0154],
                        [-76.5698, 57.0366],
                        [-76.5748, 57.0493],
                        [-76.5726, 57.0568],
                        [-76.5625, 57.0576],
                        [-76.5547, 57.0733],
                        [-76.5602, 57.0827],
                        [-76.5535, 57.089],
                        [-76.5519, 57.0966],
                        [-76.5594, 57.1089],
                        [-76.5654, 57.1102],
                        [-76.5763, 57.1274],
                        [-76.5729, 57.132],
                        [-76.5803, 57.1436],
                        [-76.5771, 57.1503],
                        [-76.5832, 57.1604],
                        [-76.5837, 57.176],
                        [-76.5751, 57.1859],
                        [-76.5895, 57.1933],
                        [-76.5953, 57.21],
                        [-76.6016, 57.2143],
                        [-76.6013, 57.2311],
                        [-76.6111, 57.2342],
                        [-76.6078, 57.2448],
                        [-76.6174, 57.2502],
                        [-76.6154, 57.2579],
                        [-76.623, 57.2812],
                        [-76.6315, 57.2974],
                        [-76.6288, 57.3121],
                        [-76.6513, 57.3315],
                        [-76.6466, 57.3344],
                        [-76.6638, 57.3516],
                        [-76.6573, 57.3602],
                        [-76.66, 57.3682],
                        [-76.6749, 57.3858],
                        [-76.6837, 57.3875],
                        [-76.6741, 57.3992],
                        [-76.6786, 57.4071],
                        [-76.696, 57.4097],
                        [-76.7015, 57.4289],
                        [-76.7001, 57.4375],
                        [-76.7089, 57.4382],
                        [-76.7136, 57.448],
                        [-76.7297, 57.4484],
                        [-76.7397, 57.4577],
                        [-76.7472, 57.4561],
                        [-76.7596, 57.4594],
                        [-76.7549, 57.4716],
                        [-76.7761, 57.4923],
                        [-76.7798, 57.4998],
                        [-76.7732, 57.5079],
                        [-76.7913, 57.5393],
                        [-76.8022, 57.5463],
                        [-76.8024, 57.5501],
                        [-76.8147, 57.5628],
                        [-76.8224, 57.5648],
                        [-76.8205, 57.571],
                        [-76.8487, 57.6044],
                        [-76.8473, 57.6129],
                        [-76.8564, 57.6149],
                        [-76.8566, 57.63],
                        [-76.8443, 57.6295],
                        [-76.8385, 57.6388],
                        [-76.8432, 57.6504],
                        [-76.8533, 57.6514],
                        [-76.8591, 57.6615],
                        [-76.8422, 57.6628],
                        [-76.8536, 57.6736],
                        [-76.8732, 57.6863],
                        [-76.8741, 57.6921],
                        [-76.885, 57.7008],
                        [-76.8915, 57.7011],
                        [-76.89, 57.7112],
                        [-76.8991, 57.7229],
                        [-76.9105, 57.7312],
                        [-76.918, 57.7439],
                        [-76.9172, 57.7481],
                        [-76.9278, 57.7591],
                        [-76.9296, 57.7671],
                        [-76.9468, 57.7707],
                        [-76.9606, 57.7791],
                        [-76.9675, 57.7907],
                        [-76.9774, 57.7974],
                        [-76.978, 57.8031],
                        [-76.9906, 57.8159],
                        [-76.9867, 57.8197],
                        [-76.9993, 57.8244],
                        [-76.9928, 57.8331],
                        [-77.0044, 57.8366],
                        [-76.9972, 57.8423],
                        [-77.0123, 57.8479],
                        [-77.015, 57.8542],
                        [-77.0352, 57.8554],
                        [-77.0427, 57.8648],
                        [-77.0508, 57.8678],
                        [-77.0468, 57.8816],
                        [-77.0487, 57.8938],
                        [-77.0619, 57.8937],
                        [-77.0827, 57.9038],
                        [-77.0792, 57.9071],
                        [-77.0934, 57.9257],
                        [-77.0937, 57.9421],
                        [-77.1232, 57.9495],
                        [-77.133, 57.9462],
                        [-77.147, 57.9557],
                        [-77.1393, 57.9643],
                        [-77.1429, 57.9704],
                        [-77.1613, 57.9678],
                        [-77.1721, 57.9766],
                        [-77.1623, 57.9806],
                        [-77.1724, 57.9853],
                        [-77.1684, 57.9895],
                        [-77.156, 57.983],
                        [-77.1503, 57.9879],
                        [-77.1731, 57.992],
                        [-77.1868, 57.9964],
                        [-77.1947, 58.0098],
                        [-77.1879, 58.0183],
                        [-77.1958, 58.0236],
                        [-77.2162, 58.0216],
                        [-77.2225, 58.0266],
                        [-77.2366, 58.0298],
                        [-77.2335, 58.0375],
                        [-77.2402, 58.042],
                        [-77.2516, 58.0401],
                        [-77.253, 58.0496],
                        [-77.2925, 58.0564],
                        [-77.287, 58.0602],
                        [-77.2946, 58.0695],
                        [-77.307, 58.0741],
                        [-77.3222, 58.0764],
                        [-77.3313, 58.0804],
                        [-77.3331, 58.0877],
                        [-77.3532, 58.0924],
                        [-77.3614, 58.088],
                        [-77.369, 58.095],
                        [-77.3786, 58.0987],
                        [-77.3914, 58.0968],
                        [-77.3975, 58.1029],
                        [-77.3895, 58.1093],
                        [-77.3981, 58.1149],
                        [-77.4082, 58.127],
                        [-77.4467, 58.1405],
                        [-77.4562, 58.1462],
                        [-77.4709, 58.1429],
                        [-77.503, 58.1535],
                        [-77.5072, 58.1608],
                        [-77.4852, 58.1627],
                        [-77.4717, 58.1587],
                        [-77.4629, 58.1635],
                        [-77.4502, 58.163],
                        [-77.4848, 58.1831],
                        [-77.4955, 58.1855],
                        [-77.5096, 58.1928],
                        [-77.5116, 58.1968],
                        [-77.5045, 58.2142],
                        [-77.5194, 58.2271],
                        [-77.5397, 58.2275],
                        [-77.554, 58.223],
                        [-77.5871, 58.2254],
                        [-77.6034, 58.2344],
                        [-77.592, 58.2385],
                        [-77.6211, 58.2433],
                        [-77.6301, 58.2489],
                        [-77.635, 58.2564],
                        [-77.6513, 58.2559],
                        [-77.6506, 58.2635],
                        [-77.6813, 58.2755],
                        [-77.6917, 58.2727],
                        [-77.7062, 58.278],
                        [-77.7187, 58.2757],
                        [-77.7278, 58.2794],
                        [-77.7447, 58.2808],
                        [-77.7681, 58.2882],
                        [-77.7743, 58.2958],
                        [-77.8023, 58.3],
                        [-77.8108, 58.3063],
                        [-77.8145, 58.3134],
                        [-77.8426, 58.3125],
                        [-77.8677, 58.3187],
                        [-77.8796, 58.3316],
                        [-77.8896, 58.3317],
                        [-77.8993, 58.3274],
                        [-77.9105, 58.3323],
                        [-77.9106, 58.3378],
                        [-77.9357, 58.3386],
                        [-77.95, 58.3372],
                        [-77.9631, 58.3395],
                        [-77.966, 58.3444],
                        [-77.9778, 58.3448],
                        [-77.9913, 58.3523],
                        [-78.0286, 58.364],
                        [-78.0379, 58.3743],
                        [-78.057, 58.3759],
                        [-78.0639, 58.3842],
                        [-78.0637, 58.39],
                        [-78.0396, 58.3922],
                        [-78.0238, 58.3913],
                        [-78.0287, 58.4002],
                        [-78.0373, 58.4004],
                        [-78.0536, 58.4113],
                        [-78.0451, 58.4208],
                        [-78.0504, 58.424],
                        [-78.0669, 58.4259],
                        [-78.0775, 58.4314],
                        [-78.0875, 58.43],
                        [-78.0983, 58.4358],
                        [-78.0898, 58.4446],
                        [-78.1016, 58.4532],
                        [-78.1166, 58.45],
                        [-78.1344, 58.4507],
                        [-78.1373, 58.4554],
                        [-78.1477, 58.4559],
                        [-78.1492, 58.4467],
                        [-78.1666, 58.4486],
                        [-78.1633, 58.4553],
                        [-78.1793, 58.467],
                        [-78.2005, 58.4705],
                        [-78.1949, 58.4767],
                        [-78.2023, 58.4829],
                        [-78.2344, 58.493],
                        [-78.2364, 58.4987],
                        [-78.2269, 58.5036],
                        [-78.2322, 58.508],
                        [-78.2557, 58.5085],
                        [-78.2391, 58.5172],
                        [-78.247, 58.5201],
                        [-78.2329, 58.5354],
                        [-78.2199, 58.5389],
                        [-78.232, 58.5487],
                        [-78.2452, 58.5481],
                        [-78.2516, 58.5527],
                        [-78.2449, 58.5665],
                        [-78.2649, 58.5693],
                        [-78.2672, 58.5637],
                        [-78.2783, 58.5603],
                        [-78.2686, 58.5527],
                        [-78.2561, 58.5475],
                        [-78.2367, 58.5453],
                        [-78.2252, 58.5408],
                        [-78.243, 58.5345],
                        [-78.2408, 58.5296],
                        [-78.2511, 58.525],
                        [-78.26, 58.5251],
                        [-78.2892, 58.5313],
                        [-78.2866, 58.5236],
                        [-78.27, 58.5215],
                        [-78.2678, 58.5162],
                        [-78.2912, 58.5085],
                        [-78.3089, 58.5123],
                        [-78.316, 58.5183],
                        [-78.307, 58.5254],
                        [-78.3138, 58.5304],
                        [-78.3143, 58.5367],
                        [-78.3278, 58.5406],
                        [-78.3215, 58.5477],
                        [-78.3252, 58.5532],
                        [-78.3366, 58.5523],
                        [-78.356, 58.5687],
                        [-78.3533, 58.5757],
                        [-78.3722, 58.5831],
                        [-78.3859, 58.5827],
                        [-78.3955, 58.5904],
                        [-78.3885, 58.5965],
                        [-78.3781, 58.5988],
                        [-78.3685, 58.6095],
                        [-78.3444, 58.6065],
                        [-78.3247, 58.6135],
                        [-78.3274, 58.6193],
                        [-78.3387, 58.6184],
                        [-78.3475, 58.6232],
                        [-78.3637, 58.614],
                        [-78.3919, 58.6193],
                        [-78.3945, 58.6133],
                        [-78.4149, 58.614],
                        [-78.4438, 58.6198],
                        [-78.4559, 58.6113],
                        [-78.4469, 58.6039],
                        [-78.4368, 58.6029],
                        [-78.4336, 58.5901],
                        [-78.4471, 58.5859],
                        [-78.4341, 58.582],
                        [-78.4415, 58.5753],
                        [-78.43, 58.564],
                        [-78.4143, 58.5648],
                        [-78.4115, 58.5699],
                        [-78.3975, 58.5711],
                        [-78.3736, 58.5515],
                        [-78.375, 58.5463],
                        [-78.3659, 58.5438],
                        [-78.3485, 58.5333],
                        [-78.3652, 58.531],
                        [-78.3841, 58.5334],
                        [-78.3982, 58.5397],
                        [-78.4241, 58.5407],
                        [-78.4268, 58.5451],
                        [-78.4428, 58.5497],
                        [-78.4489, 58.5584],
                        [-78.4608, 58.5632],
                        [-78.4601, 58.5671],
                        [-78.4706, 58.5728],
                        [-78.4639, 58.5789],
                        [-78.4686, 58.5879],
                        [-78.4801, 58.5933],
                        [-78.479, 58.6044],
                        [-78.4882, 58.6169],
                        [-78.4968, 58.6227],
                        [-78.5083, 58.6248],
                        [-78.5078, 58.6299],
                        [-78.527, 58.6378],
                        [-78.5489, 58.643],
                        [-78.5838, 58.6431],
                        [-78.5806, 58.6584],
                        [-78.5699, 58.6607],
                        [-78.5676, 58.666],
                        [-78.5895, 58.6727],
                        [-78.5887, 58.6786],
                        [-78.5688, 58.675],
                        [-78.5564, 58.6653],
                        [-78.5458, 58.6678],
                        [-78.5367, 58.6554],
                        [-78.5281, 58.6562],
                        [-78.5025, 58.6515],
                        [-78.5024, 58.6689],
                        [-78.527, 58.6759],
                        [-78.5377, 58.6748],
                        [-78.5441, 58.6869],
                        [-78.5562, 58.6927],
                        [-78.5497, 58.6956],
                        [-78.5376, 58.6933],
                        [-78.5249, 58.6957],
                        [-78.511, 58.6875],
                        [-78.4911, 58.6868],
                        [-78.5085, 58.7031],
                        [-78.5011, 58.717],
                        [-78.5204, 58.7281],
                        [-78.5125, 58.7353],
                        [-78.5238, 58.7409],
                        [-78.5405, 58.7429],
                        [-78.5394, 58.7498],
                        [-78.5595, 58.7558],
                        [-78.5613, 58.7655],
                        [-78.5675, 58.7704],
                        [-78.5799, 58.7713],
                        [-78.578, 58.7838],
                        [-78.5589, 58.7932],
                        [-78.5468, 58.7919],
                        [-78.5421, 58.7854],
                        [-78.5421, 58.7772],
                        [-78.5605, 58.7809],
                        [-78.5601, 58.7762],
                        [-78.5431, 58.7725],
                        [-78.5345, 58.7767],
                        [-78.5231, 58.7767],
                        [-78.5097, 58.7729],
                        [-78.5062, 58.7798],
                        [-78.5157, 58.7815],
                        [-78.5139, 58.788],
                        [-78.5317, 58.7968],
                        [-78.5252, 58.8019],
                        [-78.5202, 58.8122],
                        [-78.5264, 58.8192],
                        [-78.5123, 58.8214],
                        [-78.5223, 58.8291],
                        [-78.5282, 58.8218],
                        [-78.5369, 58.824],
                        [-78.5423, 58.8315],
                        [-78.5379, 58.8382],
                        [-78.5554, 58.8428],
                        [-78.5599, 58.8548],
                        [-78.5537, 58.8616],
                        [-78.5668, 58.8671],
                        [-78.5704, 58.8808],
                        [-78.5569, 58.8898],
                        [-78.5617, 58.8948],
                        [-78.5739, 58.8946],
                        [-78.5813, 58.8982],
                        [-78.5808, 58.9148],
                        [-78.5716, 58.9198],
                        [-78.5616, 58.9152],
                        [-78.548, 58.9203],
                        [-78.5468, 58.9261],
                        [-78.5528, 58.9312],
                        [-78.5345, 58.9379],
                        [-78.5149, 58.936],
                        [-78.5063, 58.9293],
                        [-78.5055, 58.9137],
                        [-78.515, 58.9042],
                        [-78.5131, 58.897],
                        [-78.5183, 58.8928],
                        [-78.5224, 58.8804],
                        [-78.532, 58.8736],
                        [-78.532, 58.866],
                        [-78.5207, 58.8682],
                        [-78.5175, 58.8868],
                        [-78.5104, 58.8955],
                        [-78.5038, 58.8969],
                        [-78.4821, 58.8953],
                        [-78.4846, 58.9016],
                        [-78.4693, 58.9088],
                        [-78.4713, 58.9142],
                        [-78.455, 58.9136],
                        [-78.4413, 58.9053],
                        [-78.4366, 58.8996],
                        [-78.4243, 58.9078],
                        [-78.4138, 58.9104],
                        [-78.3939, 58.9098],
                        [-78.3955, 58.9028],
                        [-78.3892, 58.898],
                        [-78.3779, 58.9051],
                        [-78.3678, 58.9069],
                        [-78.3514, 58.9228],
                        [-78.3308, 58.9289],
                        [-78.3418, 58.9327],
                        [-78.3315, 58.9414],
                        [-78.3494, 58.9438],
                        [-78.3465, 58.9518],
                        [-78.3376, 58.9594],
                        [-78.3214, 58.9688],
                        [-78.3184, 58.9734],
                        [-78.3036, 58.9787],
                        [-78.2962, 58.9757],
                        [-78.2841, 58.9776],
                        [-78.2841, 58.9879],
                        [-78.2938, 58.9911],
                        [-78.2882, 59],
                        [-78.2962, 59.0157],
                        [-78.2967, 59.0219],
                        [-78.3132, 59.0358],
                        [-78.3173, 59.0498],
                        [-78.2868, 59.0591],
                        [-78.2741, 59.0456],
                        [-78.248, 59.0483],
                        [-78.2294, 59.0451],
                        [-78.2222, 59.0471],
                        [-78.2244, 59.0596],
                        [-78.2319, 59.0631],
                        [-78.2353, 59.0787],
                        [-78.2418, 59.0852],
                        [-78.2426, 59.095],
                        [-78.2504, 59.1012],
                        [-78.2473, 59.1056],
                        [-78.2302, 59.1053],
                        [-78.2245, 59.0903],
                        [-78.2302, 59.0868],
                        [-78.2265, 59.0809],
                        [-78.2134, 59.0787],
                        [-78.1814, 59.0908],
                        [-78.1673, 59.0925],
                        [-78.1229, 59.0939],
                        [-78.086, 59.0848],
                        [-78.0815, 59.0888],
                        [-78.1017, 59.0906],
                        [-78.1192, 59.0955],
                        [-78.1114, 59.1035],
                        [-78.1072, 59.1173],
                        [-78.1231, 59.116],
                        [-78.1206, 59.1041],
                        [-78.1377, 59.1027],
                        [-78.1406, 59.1085],
                        [-78.1504, 59.1128],
                        [-78.1702, 59.116],
                        [-78.1633, 59.1274],
                        [-78.175, 59.1357],
                        [-78.1887, 59.1362],
                        [-78.1885, 59.1456],
                        [-78.1838, 59.1525],
                        [-78.1729, 59.1511],
                        [-78.1583, 59.1544],
                        [-78.1482, 59.1478],
                        [-78.1402, 59.1496],
                        [-78.1195, 59.1492],
                        [-78.1258, 59.1603],
                        [-78.1355, 59.1668],
                        [-78.1398, 59.1786],
                        [-78.1274, 59.18],
                        [-78.1333, 59.1919],
                        [-78.1501, 59.1937],
                        [-78.1436, 59.1984],
                        [-78.1284, 59.1948],
                        [-78.1185, 59.2092],
                        [-78.1129, 59.2035],
                        [-78.1029, 59.2036],
                        [-78.088, 59.2121],
                        [-78.0922, 59.2206],
                        [-78.0608, 59.2213],
                        [-78.064, 59.2286],
                        [-78.0513, 59.2293],
                        [-78.0555, 59.2211],
                        [-78.0475, 59.2186],
                        [-78.0349, 59.2204],
                        [-78.0377, 59.2291],
                        [-78.0064, 59.2225],
                        [-78.0032, 59.2133],
                        [-77.9817, 59.2134],
                        [-77.9732, 59.2111],
                        [-77.9664, 59.2035],
                        [-77.9718, 59.1939],
                        [-77.9633, 59.1916],
                        [-77.9432, 59.1813],
                        [-77.936, 59.1744],
                        [-77.9265, 59.1777],
                        [-77.9446, 59.1843],
                        [-77.9667, 59.2011],
                        [-77.9591, 59.2107],
                        [-77.9665, 59.2203],
                        [-77.955, 59.2242],
                        [-77.9356, 59.2175],
                        [-77.9247, 59.2253],
                        [-77.9334, 59.2282],
                        [-77.9652, 59.2267],
                        [-77.9811, 59.2286],
                        [-78.0026, 59.2418],
                        [-78.0048, 59.2494],
                        [-77.9953, 59.25],
                        [-77.9776, 59.2589],
                        [-77.9634, 59.25],
                        [-77.9533, 59.2539],
                        [-77.9372, 59.253],
                        [-77.9348, 59.2423],
                        [-77.9203, 59.2398],
                        [-77.9298, 59.2502],
                        [-77.9233, 59.2538],
                        [-77.9477, 59.2568],
                        [-77.933, 59.2626],
                        [-77.9345, 59.2675],
                        [-77.9193, 59.2713],
                        [-77.9085, 59.2651],
                        [-77.8921, 59.2607],
                        [-77.8761, 59.2647],
                        [-77.8818, 59.2735],
                        [-77.8466, 59.28],
                        [-77.8638, 59.2859],
                        [-77.8634, 59.2921],
                        [-77.882, 59.2956],
                        [-77.8859, 59.2931],
                        [-77.9075, 59.2949],
                        [-77.9201, 59.3016],
                        [-77.9209, 59.3071],
                        [-77.9074, 59.3072],
                        [-77.8977, 59.3108],
                        [-77.8756, 59.306],
                        [-77.8644, 59.2974],
                        [-77.8504, 59.2973],
                        [-77.8473, 59.2919],
                        [-77.831, 59.2802],
                        [-77.8208, 59.2913],
                        [-77.827, 59.2964],
                        [-77.8157, 59.3039],
                        [-77.8325, 59.3036],
                        [-77.8422, 59.3066],
                        [-77.8476, 59.3165],
                        [-77.8314, 59.3194],
                        [-77.8178, 59.3091],
                        [-77.8079, 59.3078],
                        [-77.8017, 59.3023],
                        [-77.7897, 59.2998],
                        [-77.7853, 59.3057],
                        [-77.7973, 59.3125],
                        [-77.7883, 59.317],
                        [-77.8076, 59.3222],
                        [-77.78, 59.3408],
                        [-77.7874, 59.3429],
                        [-77.8071, 59.3411],
                        [-77.8153, 59.3515],
                        [-77.833, 59.3555],
                        [-77.8263, 59.3623],
                        [-77.8094, 59.3633],
                        [-77.7892, 59.3563],
                        [-77.7733, 59.3613],
                        [-77.7656, 59.3573],
                        [-77.7563, 59.3603],
                        [-77.7495, 59.3673],
                        [-77.7535, 59.3774],
                        [-77.733, 59.3743],
                        [-77.7297, 59.3814],
                        [-77.7165, 59.3839],
                        [-77.6917, 59.3843],
                        [-77.6868, 59.3803],
                        [-77.6686, 59.3758],
                        [-77.6538, 59.3695],
                        [-77.6429, 59.3689],
                        [-77.6562, 59.3819],
                        [-77.6777, 59.388],
                        [-77.6771, 59.3909],
                        [-77.6968, 59.3991],
                        [-77.7278, 59.3973],
                        [-77.7421, 59.3992],
                        [-77.7519, 59.4044],
                        [-77.7387, 59.415],
                        [-77.7564, 59.421],
                        [-77.7674, 59.4152],
                        [-77.7969, 59.4191],
                        [-77.8022, 59.4148],
                        [-77.8212, 59.4178],
                        [-77.829, 59.4153],
                        [-77.8511, 59.4174],
                        [-77.8536, 59.4123],
                        [-77.8706, 59.41],
                        [-77.8762, 59.4053],
                        [-77.8925, 59.4024],
                        [-77.8931, 59.4082],
                        [-77.908, 59.4096],
                        [-77.9021, 59.4002],
                        [-77.9263, 59.399],
                        [-77.9313, 59.3884],
                        [-77.9421, 59.3912],
                        [-77.9406, 59.3977],
                        [-77.9528, 59.4056],
                        [-77.9409, 59.4067],
                        [-77.9361, 59.4145],
                        [-77.9258, 59.4131],
                        [-77.9226, 59.4272],
                        [-77.9112, 59.4339],
                        [-77.8938, 59.4279],
                        [-77.8642, 59.4234],
                        [-77.8599, 59.4279],
                        [-77.8436, 59.4309],
                        [-77.8404, 59.4357],
                        [-77.8554, 59.4467],
                        [-77.8715, 59.4468],
                        [-77.8717, 59.451],
                        [-77.8834, 59.4622],
                        [-77.8959, 59.4791],
                        [-77.8853, 59.4892],
                        [-77.898, 59.4958],
                        [-77.8913, 59.4992],
                        [-77.8886, 59.5069],
                        [-77.8594, 59.5052],
                        [-77.8571, 59.5157],
                        [-77.8443, 59.5214],
                        [-77.817, 59.5242],
                        [-77.7972, 59.5229],
                        [-77.791, 59.5263],
                        [-77.7654, 59.5262],
                        [-77.7517, 59.5307],
                        [-77.7589, 59.5491],
                        [-77.7429, 59.5612],
                        [-77.7342, 59.5628],
                        [-77.7357, 59.5716],
                        [-77.7603, 59.5754],
                        [-77.747, 59.5857],
                        [-77.7456, 59.5958],
                        [-77.7563, 59.6009],
                        [-77.7534, 59.6059],
                        [-77.7382, 59.6101],
                        [-77.753, 59.6153],
                        [-77.7787, 59.6207],
                        [-77.79, 59.631],
                        [-77.7873, 59.6363],
                        [-77.7963, 59.6409],
                        [-77.7918, 59.652],
                        [-77.8034, 59.6555],
                        [-77.8039, 59.6642],
                        [-77.8197, 59.6734],
                        [-77.8077, 59.6782],
                        [-77.7668, 59.6822],
                        [-77.7815, 59.6993],
                        [-77.7901, 59.6995],
                        [-77.8013, 59.7057],
                        [-77.7746, 59.7063],
                        [-77.7711, 59.699],
                        [-77.7627, 59.6964],
                        [-77.7426, 59.7001],
                        [-77.7429, 59.7093],
                        [-77.7266, 59.707],
                        [-77.7281, 59.6961],
                        [-77.7127, 59.6965],
                        [-77.6978, 59.7037],
                        [-77.6823, 59.7027],
                        [-77.6805, 59.6988],
                        [-77.6539, 59.7062],
                        [-77.6281, 59.7048],
                        [-77.6215, 59.6951],
                        [-77.6155, 59.6936],
                        [-77.5976, 59.6962],
                        [-77.5897, 59.6923],
                        [-77.5731, 59.6932],
                        [-77.562, 59.6979],
                        [-77.5418, 59.6923],
                        [-77.5198, 59.7055],
                        [-77.5098, 59.706],
                        [-77.4978, 59.6903],
                        [-77.4833, 59.6897],
                        [-77.4744, 59.6985],
                        [-77.5051, 59.7052],
                        [-77.5101, 59.7079],
                        [-77.54, 59.7124],
                        [-77.5606, 59.7143],
                        [-77.5618, 59.7232],
                        [-77.5701, 59.7334],
                        [-77.5667, 59.7378],
                        [-77.5734, 59.7461],
                        [-77.569, 59.7543],
                        [-77.5536, 59.7578],
                        [-77.5268, 59.7535],
                        [-77.5207, 59.7446],
                        [-77.4915, 59.7439],
                        [-77.4704, 59.7504],
                        [-77.4877, 59.7682],
                        [-77.4772, 59.77],
                        [-77.463, 59.7776],
                        [-77.4435, 59.7753],
                        [-77.4215, 59.7866],
                        [-77.4039, 59.7839],
                        [-77.3958, 59.7887],
                        [-77.3866, 59.7851],
                        [-77.3592, 59.7879],
                        [-77.3451, 59.7827],
                        [-77.327, 59.783],
                        [-77.3229, 59.7869],
                        [-77.3048, 59.7859],
                        [-77.2883, 59.7913],
                        [-77.2829, 59.7978],
                        [-77.2914, 59.8042],
                        [-77.3235, 59.8042],
                        [-77.3277, 59.8095],
                        [-77.3413, 59.8055],
                        [-77.3546, 59.8077],
                        [-77.3387, 59.8141],
                        [-77.3296, 59.8208],
                        [-77.3174, 59.8203],
                        [-77.2999, 59.8233],
                        [-77.3129, 59.8322],
                        [-77.3334, 59.8238],
                        [-77.3411, 59.8291],
                        [-77.3369, 59.8357],
                        [-77.3495, 59.8437],
                        [-77.361, 59.845],
                        [-77.3735, 59.8522],
                        [-77.3756, 59.8581],
                        [-77.3704, 59.8631],
                        [-77.3857, 59.8728],
                        [-77.3933, 59.8809],
                        [-77.3955, 59.8967],
                        [-77.4076, 59.9016],
                        [-77.4222, 59.8972],
                        [-77.4328, 59.9039],
                        [-77.4482, 59.9035],
                        [-77.453, 59.907],
                        [-77.4379, 59.9172],
                        [-77.4206, 59.9114],
                        [-77.3881, 59.9136],
                        [-77.3792, 59.9192],
                        [-77.3966, 59.921],
                        [-77.402, 59.9248],
                        [-77.3906, 59.9321],
                        [-77.3628, 59.9334],
                        [-77.3382, 59.9384],
                        [-77.3396, 59.9411],
                        [-77.3751, 59.9362],
                        [-77.389, 59.9386],
                        [-77.3923, 59.933],
                        [-77.4094, 59.9355],
                        [-77.4089, 59.9419],
                        [-77.3987, 59.9429],
                        [-77.3757, 59.9379],
                        [-77.3822, 59.9504],
                        [-77.3662, 59.9495],
                        [-77.3597, 59.9538],
                        [-77.3414, 59.9583],
                        [-77.3356, 59.9518],
                        [-77.3187, 59.9523],
                        [-77.3067, 59.9589],
                        [-77.3292, 59.9755],
                        [-77.3112, 59.9768],
                        [-77.3047, 59.9841],
                        [-77.2924, 59.9863],
                        [-77.2808, 59.9735],
                        [-77.2722, 59.9758],
                        [-77.2836, 59.9844],
                        [-77.2766, 59.9889],
                        [-77.2649, 59.9879],
                        [-77.2547, 59.9773],
                        [-77.2398, 59.9847],
                        [-77.2425, 59.9917],
                        [-77.2548, 59.9962],
                        [-77.2669, 59.9906],
                        [-77.2966, 59.9925],
                        [-77.3118, 59.9975],
                        [-77.3252, 59.998],
                        [-77.3359, 60.0034],
                        [-77.3324, 60.0082],
                        [-77.309, 60.0149],
                        [-77.2838, 60.0156],
                        [-77.281, 60.02],
                        [-77.2628, 60.0202],
                        [-77.2572, 60.0143],
                        [-77.2397, 60.0116],
                        [-77.226, 60.0343],
                        [-77.2431, 60.0448],
                        [-77.2586, 60.0396],
                        [-77.2554, 60.0357],
                        [-77.2904, 60.0309],
                        [-77.3132, 60.0308],
                        [-77.3282, 60.0199],
                        [-77.3657, 60.0216],
                        [-77.3759, 60.027],
                        [-77.3734, 60.0305],
                        [-77.3865, 60.0425],
                        [-77.3756, 60.0502],
                        [-77.3924, 60.0584],
                        [-77.4131, 60.0568],
                        [-77.4216, 60.054],
                        [-77.426, 60.0455],
                        [-77.4487, 60.0444],
                        [-77.4471, 60.0338],
                        [-77.468, 60.0308],
                        [-77.4816, 60.0432],
                        [-77.4968, 60.044],
                        [-77.508, 60.0411],
                        [-77.5062, 60.0293],
                        [-77.521, 60.0357],
                        [-77.5233, 60.0414],
                        [-77.5352, 60.0445],
                        [-77.5512, 60.0445],
                        [-77.5597, 60.0406],
                        [-77.5852, 60.0454],
                        [-77.5927, 60.0512],
                        [-77.6013, 60.0501],
                        [-77.6107, 60.0585],
                        [-77.6203, 60.0559],
                        [-77.6372, 60.0606],
                        [-77.626, 60.0678],
                        [-77.595, 60.0671],
                        [-77.6016, 60.085],
                        [-77.61, 60.0916],
                        [-77.6073, 60.0983],
                        [-77.6144, 60.1036],
                        [-77.609, 60.1115],
                        [-77.5993, 60.1133],
                        [-77.589, 60.1094],
                        [-77.5738, 60.1143],
                        [-77.5701, 60.1217],
                        [-77.5427, 60.1229],
                        [-77.5327, 60.1143],
                        [-77.513, 60.1165],
                        [-77.5007, 60.1157],
                        [-77.5003, 60.1241],
                        [-77.4801, 60.1219],
                        [-77.4905, 60.1408],
                        [-77.4967, 60.144],
                        [-77.5257, 60.1495],
                        [-77.5296, 60.1545],
                        [-77.5492, 60.1652],
                        [-77.5489, 60.1698],
                        [-77.5605, 60.1737],
                        [-77.5595, 60.179],
                        [-77.5674, 60.1852],
                        [-77.5751, 60.2011],
                        [-77.5619, 60.197],
                        [-77.5301, 60.1948],
                        [-77.523, 60.199],
                        [-77.5043, 60.197],
                        [-77.4901, 60.2036],
                        [-77.5138, 60.21],
                        [-77.5148, 60.2199],
                        [-77.5254, 60.2214],
                        [-77.5283, 60.2296],
                        [-77.5087, 60.2331],
                        [-77.5353, 60.255],
                        [-77.5474, 60.2547],
                        [-77.5562, 60.2602],
                        [-77.5688, 60.2584],
                        [-77.5749, 60.2648],
                        [-77.5488, 60.2624],
                        [-77.5468, 60.2691],
                        [-77.558, 60.2728],
                        [-77.5398, 60.2772],
                        [-77.5398, 60.2823],
                        [-77.5566, 60.2885],
                        [-77.569, 60.2871],
                        [-77.5692, 60.2801],
                        [-77.5907, 60.2827],
                        [-77.6021, 60.2884],
                        [-77.6059, 60.2961],
                        [-77.5947, 60.302],
                        [-77.5998, 60.3131],
                        [-77.5931, 60.3138],
                        [-77.5941, 60.3236],
                        [-77.6087, 60.3247],
                        [-77.6212, 60.3336],
                        [-77.6334, 60.3318],
                        [-77.6488, 60.3392],
                        [-77.6387, 60.35],
                        [-77.6542, 60.3614],
                        [-77.6531, 60.3667],
                        [-77.6791, 60.3683],
                        [-77.6874, 60.3611],
                        [-77.7, 60.3646],
                        [-77.7169, 60.3658],
                        [-77.7296, 60.3721],
                        [-77.7146, 60.3803],
                        [-77.7438, 60.3783],
                        [-77.7581, 60.3856],
                        [-77.7765, 60.3859],
                        [-77.775, 60.3942],
                        [-77.7687, 60.3968],
                        [-77.7739, 60.4053],
                        [-77.745, 60.4112],
                        [-77.7343, 60.4251],
                        [-77.6994, 60.4367],
                        [-77.7106, 60.4484],
                        [-77.6971, 60.4492],
                        [-77.6974, 60.4609],
                        [-77.6773, 60.4633],
                        [-77.661, 60.477],
                        [-77.6749, 60.4805],
                        [-77.6494, 60.4883],
                        [-77.6335, 60.4876],
                        [-77.6205, 60.4962],
                        [-77.599, 60.4988],
                        [-77.5861, 60.4971],
                        [-77.5875, 60.5119],
                        [-77.5727, 60.5168],
                        [-77.5718, 60.5208],
                        [-77.5859, 60.5296],
                        [-77.5875, 60.5342],
                        [-77.5715, 60.54],
                        [-77.5267, 60.5371],
                        [-77.5053, 60.5456],
                        [-77.4906, 60.5412],
                        [-77.4625, 60.5405],
                        [-77.4621, 60.5479],
                        [-77.4768, 60.5516],
                        [-77.4859, 60.549],
                        [-77.5126, 60.5611],
                        [-77.5333, 60.5579],
                        [-77.5544, 60.5579],
                        [-77.567, 60.5612],
                        [-77.5935, 60.5621],
                        [-77.6028, 60.5573],
                        [-77.62, 60.5539],
                        [-77.6168, 60.5447],
                        [-77.6309, 60.5444],
                        [-77.6588, 60.5404],
                        [-77.6764, 60.5424],
                        [-77.6787, 60.5483],
                        [-77.6963, 60.5579],
                        [-77.7184, 60.562],
                        [-77.7246, 60.5674],
                        [-77.7299, 60.5811],
                        [-77.747, 60.5822],
                        [-77.7556, 60.5854],
                        [-77.7841, 60.5883],
                        [-77.8029, 60.5965],
                        [-77.8009, 60.5995],
                        [-77.8124, 60.6179],
                        [-77.8286, 60.6263],
                        [-77.839, 60.6435],
                        [-77.832, 60.6471],
                        [-77.8358, 60.6538],
                        [-77.83, 60.6598],
                        [-77.8042, 60.6611],
                        [-77.7945, 60.6656],
                        [-77.7943, 60.6725],
                        [-77.7736, 60.6743],
                        [-77.7333, 60.6836],
                        [-77.7284, 60.6879],
                        [-77.7102, 60.6907],
                        [-77.6998, 60.699],
                        [-77.7053, 60.7136],
                        [-77.6847, 60.7198],
                        [-77.6724, 60.7341],
                        [-77.6695, 60.7438],
                        [-77.6558, 60.7415],
                        [-77.6449, 60.7508],
                        [-77.6283, 60.7494],
                        [-77.6219, 60.7546],
                        [-77.6227, 60.7618],
                        [-77.648, 60.7574],
                        [-77.6523, 60.7591],
                        [-77.6204, 60.7703],
                        [-77.6273, 60.7715],
                        [-77.6571, 60.7652],
                        [-77.6799, 60.7667],
                        [-77.7206, 60.7564],
                        [-77.7259, 60.7606],
                        [-77.6883, 60.7738],
                        [-77.6566, 60.7814],
                        [-77.6646, 60.7837],
                        [-77.7036, 60.7729],
                        [-77.7261, 60.7697],
                        [-77.7189, 60.7764],
                        [-77.6821, 60.7863],
                        [-77.6597, 60.7902],
                        [-77.6259, 60.7997],
                        [-77.5869, 60.8119],
                        [-77.5455, 60.822],
                        [-77.5172, 60.8309],
                        [-77.5229, 60.8379],
                        [-77.5382, 60.8392],
                        [-77.558, 60.8322],
                        [-77.5892, 60.8252],
                        [-77.6194, 60.8165],
                        [-77.6266, 60.8127],
                        [-77.6613, 60.8049],
                        [-77.6819, 60.804],
                        [-77.7034, 60.7966],
                        [-77.7538, 60.7867],
                        [-77.775, 60.7897],
                        [-77.8011, 60.7797],
                        [-77.8308, 60.7699],
                        [-77.8578, 60.7638],
                        [-77.8667, 60.7684],
                        [-77.8827, 60.7692],
                        [-77.8862, 60.7791],
                        [-77.8542, 60.7927],
                        [-77.8632, 60.7952],
                        [-77.895, 60.7873],
                        [-77.9016, 60.7913],
                        [-77.9475, 60.7843],
                        [-77.9561, 60.7852],
                        [-77.9934, 60.7766],
                        [-78.0043, 60.7908],
                        [-78.0403, 60.7884],
                        [-78.0436, 60.7971],
                        [-78.0626, 60.7935],
                        [-78.0844, 60.7919],
                        [-78.0929, 60.7949],
                        [-78.0722, 60.813],
                        [-78.1072, 60.8067],
                        [-78.1317, 60.7978],
                        [-78.1839, 60.7909],
                        [-78.1895, 60.7843],
                        [-78.2018, 60.7852],
                        [-78.213, 60.7796],
                        [-78.2262, 60.7837],
                        [-78.2265, 60.7891],
                        [-78.201, 60.7996],
                        [-78.1938, 60.7968],
                        [-78.1831, 60.8015],
                        [-78.1764, 60.8191],
                        [-78.2091, 60.8098],
                        [-78.2251, 60.8119],
                        [-78.211, 60.8208],
                        [-78.2059, 60.8345],
                        [-78.1945, 60.8393],
                        [-78.1876, 60.849],
                        [-78.1982, 60.8504],
                        [-78.1937, 60.8589],
                        [-78.1675, 60.8683],
                        [-78.1401, 60.8804],
                        [-78.1405, 60.8925],
                        [-78.1363, 60.897],
                        [-78.119, 60.8996],
                        [-78.1164, 60.9067],
                        [-78.1041, 60.9195],
                        [-78.0961, 60.9202],
                        [-78.088, 60.9277],
                        [-78.0801, 60.9288],
                        [-78.0644, 60.9369],
                        [-78.0475, 60.9408],
                        [-78.0311, 60.9505],
                        [-78.0288, 60.9646],
                        [-78.0181, 60.9682],
                        [-77.9931, 60.9704],
                        [-77.9878, 60.9761],
                        [-77.9887, 60.9933],
                        [-77.969, 60.9983],
                        [-77.9352, 61.0209],
                        [-77.922, 61.025],
                        [-77.9002, 61.0258],
                        [-77.9083, 61.0386],
                        [-77.8831, 61.0434],
                        [-77.8639, 61.0522],
                        [-77.8524, 61.0514],
                        [-77.8566, 61.0446],
                        [-77.8425, 61.0438],
                        [-77.8451, 61.0515],
                        [-77.8254, 61.055],
                        [-77.8258, 61.0763],
                        [-77.831, 61.0847],
                        [-77.8172, 61.1034],
                        [-77.8195, 61.1128],
                        [-77.8262, 61.1192],
                        [-77.8264, 61.129],
                        [-77.8161, 61.1351],
                        [-77.8238, 61.1435],
                        [-77.8197, 61.1497],
                        [-77.8087, 61.1506],
                        [-77.8036, 61.1598],
                        [-77.7919, 61.1663],
                        [-77.7882, 61.1718],
                        [-77.7758, 61.1794],
                        [-77.7696, 61.1743],
                        [-77.7509, 61.179],
                        [-77.7655, 61.1843],
                        [-77.7546, 61.1922],
                        [-77.7431, 61.1875],
                        [-77.7225, 61.1865],
                        [-77.7045, 61.1885],
                        [-77.7022, 61.2017],
                        [-77.715, 61.2185],
                        [-77.7297, 61.2254],
                        [-77.7448, 61.2233],
                        [-77.7543, 61.2268],
                        [-77.7716, 61.2394],
                        [-77.7593, 61.2397],
                        [-77.7452, 61.2519],
                        [-77.7427, 61.2585],
                        [-77.7466, 61.2671],
                        [-77.7628, 61.2741],
                        [-77.7699, 61.2827],
                        [-77.7605, 61.2891],
                        [-77.7584, 61.3074],
                        [-77.7687, 61.3124],
                        [-77.7486, 61.3147],
                        [-77.7625, 61.3221],
                        [-77.7755, 61.3218],
                        [-77.7699, 61.3445],
                        [-77.7649, 61.3494],
                        [-77.7681, 61.3583],
                        [-77.7604, 61.3759],
                        [-77.7822, 61.3842],
                        [-77.7878, 61.3899],
                        [-77.7825, 61.4007],
                        [-77.7881, 61.4026],
                        [-77.7833, 61.4117],
                        [-77.7912, 61.4127],
                        [-77.8043, 61.4238],
                        [-77.8081, 61.4362],
                        [-77.8165, 61.4428],
                        [-77.8107, 61.4516],
                        [-77.8098, 61.4632],
                        [-77.7971, 61.4615],
                        [-77.7905, 61.4652],
                        [-77.7711, 61.454],
                        [-77.7762, 61.45],
                        [-77.7392, 61.4431],
                        [-77.7219, 61.4376],
                        [-77.7348, 61.432],
                        [-77.724, 61.423],
                        [-77.708, 61.4205],
                        [-77.6844, 61.4106],
                        [-77.6802, 61.4015],
                        [-77.6656, 61.4003],
                        [-77.6746, 61.3863],
                        [-77.6486, 61.3856],
                        [-77.6299, 61.39],
                        [-77.6155, 61.3956],
                        [-77.6295, 61.4072],
                        [-77.6477, 61.4111],
                        [-77.6564, 61.4086],
                        [-77.6897, 61.419],
                        [-77.6892, 61.4225],
                        [-77.7148, 61.4247],
                        [-77.7142, 61.4299],
                        [-77.7045, 61.4378],
                        [-77.7004, 61.4464],
                        [-77.7017, 61.4546],
                        [-77.6908, 61.4597],
                        [-77.6984, 61.4648],
                        [-77.6698, 61.4634],
                        [-77.6404, 61.4645],
                        [-77.6339, 61.4602],
                        [-77.6199, 61.4599],
                        [-77.5684, 61.4708],
                        [-77.5588, 61.4774],
                        [-77.5691, 61.484],
                        [-77.5859, 61.4857],
                        [-77.6218, 61.4999],
                        [-77.6125, 61.5116],
                        [-77.6218, 61.5118],
                        [-77.6382, 61.5056],
                        [-77.6526, 61.5102],
                        [-77.6668, 61.51],
                        [-77.6771, 61.516],
                        [-77.6942, 61.5139],
                        [-77.7055, 61.518],
                        [-77.6921, 61.522],
                        [-77.6926, 61.5338],
                        [-77.7126, 61.5393],
                        [-77.7001, 61.5443],
                        [-77.67, 61.54],
                        [-77.6511, 61.5346],
                        [-77.6411, 61.5374],
                        [-77.6448, 61.5458],
                        [-77.6292, 61.5495],
                        [-77.6383, 61.5587],
                        [-77.596, 61.5575],
                        [-77.5995, 61.5542],
                        [-77.5757, 61.5445],
                        [-77.5623, 61.5442],
                        [-77.5399, 61.5485],
                        [-77.541, 61.5404],
                        [-77.5294, 61.5329],
                        [-77.5073, 61.5266],
                        [-77.4808, 61.5321],
                        [-77.5012, 61.5359],
                        [-77.5064, 61.5464],
                        [-77.5047, 61.552],
                        [-77.5462, 61.5612],
                        [-77.5748, 61.5622],
                        [-77.5794, 61.5653],
                        [-77.61, 61.5684],
                        [-77.639, 61.57],
                        [-77.6428, 61.5783],
                        [-77.6352, 61.5835],
                        [-77.6113, 61.5788],
                        [-77.6022, 61.575],
                        [-77.5763, 61.576],
                        [-77.5842, 61.5834],
                        [-77.6023, 61.5883],
                        [-77.6067, 61.5934],
                        [-77.6274, 61.5961],
                        [-77.6513, 61.593],
                        [-77.6691, 61.5883],
                        [-77.6796, 61.5907],
                        [-77.6992, 61.5899],
                        [-77.7097, 61.5928],
                        [-77.7296, 61.5938],
                        [-77.7381, 61.6021],
                        [-77.737, 61.6103],
                        [-77.7311, 61.6159],
                        [-77.7381, 61.6252],
                        [-77.7553, 61.6287],
                        [-77.762, 61.6335],
                        [-77.766, 61.6436],
                        [-77.7854, 61.6454],
                        [-77.7761, 61.6599],
                        [-77.7777, 61.6699],
                        [-77.7951, 61.6792],
                        [-77.787, 61.6843],
                        [-77.8074, 61.6922],
                        [-77.8108, 61.6859],
                        [-77.8402, 61.681],
                        [-77.8508, 61.6842],
                        [-77.8704, 61.6828],
                        [-77.8954, 61.6861],
                        [-77.9144, 61.681],
                        [-77.9437, 61.6799],
                        [-77.9664, 61.6852],
                        [-77.9716, 61.6946],
                        [-77.9965, 61.7021],
                        [-78.0026, 61.7069],
                        [-77.996, 61.7153],
                        [-78.012, 61.7202],
                        [-78.0247, 61.7314],
                        [-78.0156, 61.7353],
                        [-78.0167, 61.742],
                        [-78.0257, 61.75],
                        [-78.0178, 61.7542],
                        [-78.0147, 61.7627],
                        [-78.0303, 61.7696],
                        [-78.0327, 61.7768],
                        [-78.0271, 61.7893],
                        [-78.0417, 61.7914],
                        [-78.0496, 61.7961],
                        [-78.0448, 61.8037],
                        [-78.0562, 61.8115],
                        [-78.0568, 61.8297],
                        [-78.0645, 61.8414],
                        [-78.0836, 61.8498],
                        [-78.0881, 61.8551],
                        [-78.0872, 61.8693],
                        [-78.0963, 61.8851],
                        [-78.0785, 61.9058],
                        [-78.0896, 61.9211],
                        [-78.0845, 61.9267],
                        [-78.097, 61.9427],
                        [-78.0978, 61.9509],
                        [-78.1038, 61.9613],
                        [-78.1107, 61.9655],
                        [-78.1171, 61.9863],
                        [-78.128, 61.9927],
                        [-78.1535, 61.9975],
                        [-78.1484, 62.0025],
                        [-78.1585, 62.0141],
                        [-78.1433, 62.0223],
                        [-78.1492, 62.0329],
                        [-78.146, 62.0367],
                        [-78.1482, 62.0561],
                        [-78.1591, 62.0863],
                        [-78.1534, 62.0904],
                        [-78.1564, 62.1011],
                        [-78.1524, 62.1114],
                        [-78.1586, 62.1224],
                        [-78.1786, 62.133],
                        [-78.1829, 62.1457],
                        [-78.1783, 62.1559],
                        [-78.1657, 62.1616],
                        [-78.1754, 62.168],
                        [-78.1671, 62.1716],
                        [-78.1676, 62.1804],
                        [-78.1769, 62.1844],
                        [-78.1724, 62.195],
                        [-78.1617, 62.1972],
                        [-78.1534, 62.2046],
                        [-78.1494, 62.2154],
                        [-78.1646, 62.2346],
                        [-78.1708, 62.2357],
                        [-78.1537, 62.2518],
                        [-78.156, 62.2609],
                        [-78.1441, 62.2738],
                        [-78.1371, 62.2861],
                        [-78.1499, 62.285],
                        [-78.1521, 62.2751],
                        [-78.169, 62.2546],
                        [-78.1698, 62.2666],
                        [-78.161, 62.277],
                        [-78.1628, 62.2833],
                        [-78.1489, 62.2925],
                        [-78.1579, 62.2985],
                        [-78.123, 62.3035],
                        [-78.1014, 62.3007],
                        [-78.1052, 62.3132],
                        [-78.1239, 62.3109],
                        [-78.1386, 62.3142],
                        [-78.1219, 62.3222],
                        [-78.1071, 62.3337],
                        [-78.1078, 62.3407],
                        [-78.1006, 62.3449],
                        [-78.091, 62.3623],
                        [-78.0512, 62.3648],
                        [-78.0445, 62.3575],
                        [-78.0308, 62.3554],
                        [-78.03, 62.3637],
                        [-78.0169, 62.364],
                        [-78.0061, 62.3711],
                        [-78.0041, 62.3779],
                        [-77.9888, 62.38],
                        [-77.9767, 62.3863],
                        [-77.9395, 62.3929],
                        [-77.9253, 62.3883],
                        [-77.9479, 62.3752],
                        [-77.9152, 62.3845],
                        [-77.9041, 62.3847],
                        [-77.9383, 62.405],
                        [-77.9392, 62.4175],
                        [-77.926, 62.4247],
                        [-77.9131, 62.4282],
                        [-77.9065, 62.4175],
                        [-77.8776, 62.4109],
                        [-77.8917, 62.4228],
                        [-77.8917, 62.4268],
                        [-77.8767, 62.4305],
                        [-77.842, 62.431],
                        [-77.8318, 62.4293],
                        [-77.8181, 62.4325],
                        [-77.8171, 62.443],
                        [-77.7904, 62.4491],
                        [-77.7919, 62.4528],
                        [-77.7718, 62.462],
                        [-77.7606, 62.4631],
                        [-77.7496, 62.4599],
                        [-77.7376, 62.4669],
                        [-77.7186, 62.4677],
                        [-77.704, 62.4803],
                        [-77.6928, 62.4841],
                        [-77.6925, 62.4969],
                        [-77.6743, 62.51],
                        [-77.6666, 62.5129],
                        [-77.6504, 62.5079],
                        [-77.6273, 62.5201],
                        [-77.6162, 62.5192],
                        [-77.5936, 62.5261],
                        [-77.5812, 62.5163],
                        [-77.5703, 62.5162],
                        [-77.5763, 62.5248],
                        [-77.5623, 62.5284],
                        [-77.567, 62.5323],
                        [-77.5579, 62.5395],
                        [-77.538, 62.5473],
                        [-77.5341, 62.5527],
                        [-77.5187, 62.5556],
                        [-77.5186, 62.5691],
                        [-77.507, 62.5757],
                        [-77.4837, 62.5804],
                        [-77.4769, 62.577],
                        [-77.4521, 62.5811],
                        [-77.4446, 62.5791],
                        [-77.4197, 62.5793],
                        [-77.4114, 62.5719],
                        [-77.4171, 62.5598],
                        [-77.412, 62.5469],
                        [-77.4071, 62.5257],
                        [-77.3903, 62.5247],
                        [-77.3862, 62.5441],
                        [-77.3719, 62.5575],
                        [-77.3563, 62.5583],
                        [-77.3358, 62.5478],
                        [-77.3104, 62.5571],
                        [-77.2911, 62.5539],
                        [-77.2875, 62.5474],
                        [-77.2666, 62.5505],
                        [-77.2527, 62.5458],
                        [-77.2299, 62.5462],
                        [-77.2109, 62.5398],
                        [-77.1979, 62.5432],
                        [-77.1646, 62.5367],
                        [-77.1542, 62.5219],
                        [-77.1603, 62.5169],
                        [-77.1474, 62.5141],
                        [-77.1285, 62.5328],
                        [-77.1126, 62.5355],
                        [-77.081, 62.532],
                        [-77.0796, 62.5255],
                        [-77.0684, 62.516],
                        [-77.0685, 62.5004],
                        [-77.0594, 62.4983],
                        [-77.0574, 62.5159],
                        [-77.0625, 62.5267],
                        [-77.0543, 62.5323],
                        [-77.0448, 62.5196],
                        [-77.0371, 62.5281],
                        [-77.0261, 62.527],
                        [-77.0146, 62.5325],
                        [-76.9916, 62.5318],
                        [-76.9821, 62.5342],
                        [-76.9458, 62.5372],
                        [-76.9239, 62.5369],
                        [-76.9239, 62.5293],
                        [-76.9388, 62.5202],
                        [-76.955, 62.5132],
                        [-76.9578, 62.5063],
                        [-76.9337, 62.514],
                        [-76.9031, 62.5304],
                        [-76.8893, 62.5288],
                        [-76.8907, 62.5202],
                        [-76.8994, 62.5133],
                        [-76.8987, 62.5003],
                        [-76.8921, 62.4943],
                        [-76.881, 62.4974],
                        [-76.8847, 62.5105],
                        [-76.8643, 62.5253],
                        [-76.8549, 62.525],
                        [-76.8524, 62.5141],
                        [-76.8424, 62.513],
                        [-76.84, 62.5214],
                        [-76.812, 62.5237],
                        [-76.7935, 62.5222],
                        [-76.773, 62.5123],
                        [-76.7667, 62.5169],
                        [-76.7558, 62.5142],
                        [-76.7639, 62.4993],
                        [-76.7532, 62.4978],
                        [-76.7327, 62.5075],
                        [-76.7166, 62.5038],
                        [-76.7022, 62.4973],
                        [-76.7029, 62.487],
                        [-76.6879, 62.4793],
                        [-76.6729, 62.4873],
                        [-76.6644, 62.4888],
                        [-76.6398, 62.4792],
                        [-76.6295, 62.478],
                        [-76.6333, 62.4702],
                        [-76.6576, 62.4682],
                        [-76.6561, 62.4624],
                        [-76.6632, 62.4489],
                        [-76.6575, 62.4393],
                        [-76.6492, 62.4397],
                        [-76.6477, 62.4536],
                        [-76.639, 62.4579],
                        [-76.6194, 62.4592],
                        [-76.6046, 62.4576],
                        [-76.5846, 62.4429],
                        [-76.5837, 62.4326],
                        [-76.5731, 62.4308],
                        [-76.5677, 62.4444],
                        [-76.5728, 62.454],
                        [-76.5543, 62.4579],
                        [-76.5367, 62.4515],
                        [-76.5333, 62.4422],
                        [-76.5035, 62.4481],
                        [-76.4933, 62.4419],
                        [-76.4822, 62.4457],
                        [-76.4666, 62.4411],
                        [-76.4545, 62.4406],
                        [-76.4485, 62.4343],
                        [-76.4364, 62.432],
                        [-76.4295, 62.4215],
                        [-76.4134, 62.4293],
                        [-76.3857, 62.431],
                        [-76.3752, 62.4255],
                        [-76.3649, 62.4263],
                        [-76.3454, 62.421],
                        [-76.3329, 62.4153],
                        [-76.3399, 62.406],
                        [-76.3298, 62.4051],
                        [-76.3112, 62.4189],
                        [-76.2891, 62.4163],
                        [-76.2743, 62.4119],
                        [-76.2695, 62.4032],
                        [-76.2551, 62.4018],
                        [-76.2476, 62.3954],
                        [-76.2123, 62.3964],
                        [-76.1874, 62.3837],
                        [-76.1682, 62.3818],
                        [-76.1515, 62.3851],
                        [-76.101, 62.3764],
                        [-76.0878, 62.359],
                        [-76.0991, 62.3535],
                        [-76.0997, 62.3436],
                        [-76.1093, 62.3332],
                        [-76.0751, 62.3388],
                        [-76.0514, 62.3517],
                        [-76.035, 62.3554],
                        [-76.0186, 62.3547],
                        [-76.0105, 62.3398],
                        [-76.0254, 62.3251],
                        [-76.0208, 62.3143],
                        [-76.0011, 62.3271],
                        [-75.9992, 62.335],
                        [-75.9796, 62.3475],
                        [-75.9662, 62.3452],
                        [-75.9507, 62.3511],
                        [-75.9201, 62.3454],
                        [-75.8896, 62.3293],
                        [-75.8785, 62.3284],
                        [-75.874, 62.3209],
                        [-75.8566, 62.3182],
                        [-75.8364, 62.3181],
                        [-75.8267, 62.3139],
                        [-75.8082, 62.3129],
                        [-75.8093, 62.3086],
                        [-75.789, 62.3079],
                        [-75.7771, 62.3023],
                        [-75.7521, 62.3067],
                        [-75.734, 62.3055],
                        [-75.7247, 62.3018],
                        [-75.7124, 62.3043],
                        [-75.6801, 62.2969],
                        [-75.66, 62.2973],
                        [-75.646, 62.2922],
                        [-75.6235, 62.2908],
                        [-75.6154, 62.2824],
                        [-75.62, 62.2784],
                        [-75.5993, 62.275],
                        [-75.5882, 62.2771],
                        [-75.57, 62.2764],
                        [-75.5607, 62.2727],
                        [-75.5119, 62.2706],
                        [-75.4955, 62.2798],
                        [-75.4864, 62.2897],
                        [-75.4773, 62.2904],
                        [-75.4667, 62.2989],
                        [-75.4523, 62.3028],
                        [-75.4451, 62.3001],
                        [-75.4336, 62.2808],
                        [-75.4257, 62.263],
                        [-75.4071, 62.2589],
                        [-75.3949, 62.259],
                        [-75.4095, 62.2699],
                        [-75.407, 62.2789],
                        [-75.4156, 62.2876],
                        [-75.4157, 62.3017],
                        [-75.4015, 62.3036],
                        [-75.3892, 62.3117],
                        [-75.3482, 62.3162],
                        [-75.3347, 62.3128],
                        [-75.3184, 62.3135],
                        [-75.3011, 62.3058],
                        [-75.2934, 62.305],
                        [-75.2739, 62.2966],
                        [-75.2384, 62.296],
                        [-75.2311, 62.2926],
                        [-75.207, 62.2942],
                        [-75.1885, 62.2856],
                        [-75.1568, 62.2835],
                        [-75.1435, 62.2845],
                        [-75.1222, 62.2807],
                        [-75.1126, 62.2756],
                        [-75.109, 62.2619],
                        [-75.12, 62.2497],
                        [-75.118, 62.242],
                        [-75.1331, 62.2253],
                        [-75.1338, 62.2197],
                        [-75.113, 62.2246],
                        [-75.1118, 62.2296],
                        [-75.09, 62.2442],
                        [-75.0897, 62.2574],
                        [-75.0764, 62.2705],
                        [-75.0575, 62.2726],
                        [-75.0243, 62.2658],
                        [-74.9936, 62.2679],
                        [-74.9861, 62.2642],
                        [-74.9691, 62.2658],
                        [-74.942, 62.257],
                        [-74.9342, 62.2572],
                        [-74.913, 62.2519],
                        [-74.8992, 62.2411],
                        [-74.8737, 62.2336],
                        [-74.8629, 62.2219],
                        [-74.85, 62.221],
                        [-74.8452, 62.2142],
                        [-74.8475, 62.2031],
                        [-74.8552, 62.1997],
                        [-74.8464, 62.193],
                        [-74.8351, 62.2001],
                        [-74.815, 62.2012],
                        [-74.8019, 62.1937],
                        [-74.7878, 62.1815],
                        [-74.7758, 62.1783],
                        [-74.7579, 62.1778],
                        [-74.745, 62.1708],
                        [-74.7403, 62.155],
                        [-74.751, 62.1457],
                        [-74.7692, 62.1383],
                        [-74.7613, 62.134],
                        [-74.7417, 62.1408],
                        [-74.7258, 62.1513],
                        [-74.7105, 62.1543],
                        [-74.6944, 62.1407],
                        [-74.667, 62.1325],
                        [-74.6533, 62.1247],
                        [-74.6354, 62.118],
                        [-74.6197, 62.1158],
                        [-74.6082, 62.1186],
                        [-74.5977, 62.1154],
                        [-74.5817, 62.1158],
                        [-74.556, 62.1063],
                        [-74.5307, 62.11],
                        [-74.5609, 62.1226],
                        [-74.5615, 62.1262],
                        [-74.5847, 62.1297],
                        [-74.601, 62.1346],
                        [-74.6358, 62.1413],
                        [-74.6652, 62.1558],
                        [-74.6715, 62.1629],
                        [-74.6865, 62.1723],
                        [-74.7013, 62.1906],
                        [-74.716, 62.1991],
                        [-74.7354, 62.2058],
                        [-74.7503, 62.2086],
                        [-74.718, 62.2229],
                        [-74.7217, 62.2281],
                        [-74.7079, 62.2433],
                        [-74.7154, 62.2502],
                        [-74.7074, 62.2545],
                        [-74.6932, 62.2521],
                        [-74.6761, 62.2547],
                        [-74.6546, 62.2516],
                        [-74.6256, 62.2578],
                        [-74.6106, 62.2584],
                        [-74.5952, 62.2522],
                        [-74.5686, 62.252],
                        [-74.5531, 62.2467],
                        [-74.5322, 62.2437],
                        [-74.5052, 62.2422],
                        [-74.4806, 62.2427],
                        [-74.4467, 62.2467],
                        [-74.4186, 62.2425],
                        [-74.3863, 62.2514],
                        [-74.3806, 62.2578],
                        [-74.3471, 62.2575],
                        [-74.3319, 62.2666],
                        [-74.3387, 62.2753],
                        [-74.3236, 62.2801],
                        [-74.3146, 62.2773],
                        [-74.3077, 62.2702],
                        [-74.2927, 62.2811],
                        [-74.2829, 62.2842],
                        [-74.2885, 62.2897],
                        [-74.2685, 62.2955],
                        [-74.2604, 62.3157],
                        [-74.2487, 62.3185],
                        [-74.2388, 62.3256],
                        [-74.2235, 62.3233],
                        [-74.215, 62.3311],
                        [-74.2001, 62.3329],
                        [-74.1932, 62.3381],
                        [-74.1812, 62.3258],
                        [-74.1655, 62.324],
                        [-74.1322, 62.3364],
                        [-74.1017, 62.3371],
                        [-74.0804, 62.3455],
                        [-74.0783, 62.354],
                        [-74.0565, 62.3559],
                        [-74.0405, 62.3529],
                        [-74.0328, 62.3569],
                        [-74.0025, 62.3643],
                        [-73.9977, 62.3588],
                        [-73.9789, 62.364],
                        [-73.967, 62.37],
                        [-73.9722, 62.3792],
                        [-73.9514, 62.3929],
                        [-73.9541, 62.4025],
                        [-73.9421, 62.4076],
                        [-73.9304, 62.4087],
                        [-73.9195, 62.4137],
                        [-73.9157, 62.4273],
                        [-73.9095, 62.4324],
                        [-73.8927, 62.4374],
                        [-73.8678, 62.4481],
                        [-73.8614, 62.4576],
                        [-73.8468, 62.4633],
                        [-73.8359, 62.4627],
                        [-73.8254, 62.4576],
                        [-73.7872, 62.4648],
                        [-73.7792, 62.4689],
                        [-73.7672, 62.4692],
                        [-73.7316, 62.4767],
                        [-73.7092, 62.474],
                        [-73.7076, 62.4826],
                        [-73.6914, 62.4796],
                        [-73.6737, 62.4795],
                        [-73.6625, 62.4763],
                        [-73.6408, 62.4519],
                        [-73.6308, 62.4523],
                        [-73.6124, 62.4409],
                        [-73.6201, 62.4307],
                        [-73.5923, 62.4158],
                        [-73.6152, 62.4101],
                        [-73.6073, 62.4038],
                        [-73.5764, 62.4067],
                        [-73.5604, 62.3985],
                        [-73.559, 62.3925],
                        [-73.5452, 62.3862],
                        [-73.5556, 62.3807],
                        [-73.538, 62.3774],
                        [-73.5301, 62.3815],
                        [-73.5131, 62.3821],
                        [-73.5043, 62.3908],
                        [-73.4638, 62.3864],
                        [-73.4585, 62.3807],
                        [-73.4408, 62.3783],
                        [-73.4396, 62.3724],
                        [-73.4502, 62.3642],
                        [-73.4517, 62.3536],
                        [-73.4385, 62.3552],
                        [-73.4306, 62.3637],
                        [-73.4151, 62.3692],
                        [-73.3847, 62.3706],
                        [-73.3684, 62.3584],
                        [-73.3531, 62.3578],
                        [-73.3294, 62.3481],
                        [-73.318, 62.336],
                        [-73.3167, 62.3295],
                        [-73.2862, 62.3303],
                        [-73.2652, 62.3233],
                        [-73.2528, 62.3253],
                        [-73.2314, 62.3246],
                        [-73.2073, 62.3198],
                        [-73.1962, 62.3158],
                        [-73.1916, 62.2987],
                        [-73.2061, 62.2958],
                        [-73.1949, 62.2808],
                        [-73.1984, 62.277],
                        [-73.2137, 62.2763],
                        [-73.2119, 62.2684],
                        [-73.1919, 62.2703],
                        [-73.1851, 62.2638],
                        [-73.1698, 62.2616],
                        [-73.1675, 62.255],
                        [-73.1545, 62.2545],
                        [-73.1538, 62.2493],
                        [-73.1253, 62.2357],
                        [-73.118, 62.2358],
                        [-73.1003, 62.2142],
                        [-73.0804, 62.2124],
                        [-73.0731, 62.2088],
                        [-73.0598, 62.2084],
                        [-73.0562, 62.2037],
                        [-73.0597, 62.1836],
                        [-73.0443, 62.179],
                        [-73.014, 62.1842],
                        [-72.997, 62.1763],
                        [-72.9879, 62.1797],
                        [-72.9724, 62.1766],
                        [-72.9658, 62.1662],
                        [-72.9367, 62.1575],
                        [-72.9096, 62.1595],
                        [-72.9133, 62.1542],
                        [-72.9023, 62.1518],
                        [-72.9014, 62.1417],
                        [-72.8909, 62.144],
                        [-72.8741, 62.14],
                        [-72.8802, 62.1317],
                        [-72.9237, 62.1188],
                        [-72.8984, 62.1114],
                        [-72.8675, 62.1104],
                        [-72.8585, 62.1156],
                        [-72.8424, 62.1196],
                        [-72.845, 62.1229],
                        [-72.8211, 62.1283],
                        [-72.8203, 62.1209],
                        [-72.7903, 62.1121],
                        [-72.7843, 62.1154],
                        [-72.7974, 62.1333],
                        [-72.7729, 62.1358],
                        [-72.7528, 62.1459],
                        [-72.7312, 62.1444],
                        [-72.6964, 62.1384],
                        [-72.6911, 62.131],
                        [-72.669, 62.1331],
                        [-72.6531, 62.1258],
                        [-72.6399, 62.125],
                        [-72.6248, 62.1154],
                        [-72.6093, 62.1134],
                        [-72.6045, 62.0936],
                        [-72.5978, 62.0846],
                        [-72.6141, 62.0806],
                        [-72.6156, 62.0703],
                        [-72.6327, 62.0678],
                        [-72.6373, 62.0631],
                        [-72.6529, 62.0637],
                        [-72.6406, 62.0535],
                        [-72.6158, 62.0586],
                        [-72.5947, 62.0582],
                        [-72.5819, 62.055],
                        [-72.5905, 62.0432],
                        [-72.6229, 62.0428],
                        [-72.6076, 62.0294],
                        [-72.5941, 62.0296],
                        [-72.5887, 62.023],
                        [-72.5884, 62.0142],
                        [-72.6012, 62.011],
                        [-72.597, 62.0007],
                        [-72.5801, 61.996],
                        [-72.5824, 61.9907],
                        [-72.5955, 61.9838],
                        [-72.5797, 61.9715],
                        [-72.5849, 61.9675],
                        [-72.6073, 61.9652],
                        [-72.632, 61.966],
                        [-72.6557, 61.9624],
                        [-72.6712, 61.955],
                        [-72.6757, 61.9425],
                        [-72.6692, 61.9348],
                        [-72.6267, 61.9331],
                        [-72.5953, 61.9393],
                        [-72.5913, 61.9469],
                        [-72.5787, 61.9516],
                        [-72.5617, 61.9499],
                        [-72.5517, 61.9446],
                        [-72.5494, 61.9367],
                        [-72.5272, 61.9324],
                        [-72.5165, 61.9244],
                        [-72.4862, 61.9301],
                        [-72.4683, 61.925],
                        [-72.4688, 61.9198],
                        [-72.4497, 61.915],
                        [-72.4421, 61.9105],
                        [-72.4474, 61.9058],
                        [-72.4402, 61.8966],
                        [-72.4219, 61.9047],
                        [-72.4067, 61.9028],
                        [-72.3899, 61.8976],
                        [-72.3816, 61.8871],
                        [-72.3694, 61.8874],
                        [-72.3664, 61.8951],
                        [-72.3429, 61.8942],
                        [-72.3315, 61.8908],
                        [-72.318, 61.8824],
                        [-72.3127, 61.888],
                        [-72.2635, 61.8886],
                        [-72.2499, 61.8814],
                        [-72.2275, 61.8803],
                        [-72.208, 61.874],
                        [-72.2045, 61.8678],
                        [-72.1798, 61.8556],
                        [-72.1792, 61.8333],
                        [-72.1728, 61.8265],
                        [-72.1645, 61.8277],
                        [-72.1371, 61.8093],
                        [-72.1093, 61.7943],
                        [-72.1239, 61.7943],
                        [-72.1396, 61.7983],
                        [-72.161, 61.7943],
                        [-72.1694, 61.7898],
                        [-72.1915, 61.7898],
                        [-72.2055, 61.792],
                        [-72.2363, 61.7903],
                        [-72.2589, 61.7876],
                        [-72.2505, 61.7827],
                        [-72.2218, 61.7822],
                        [-72.2137, 61.785],
                        [-72.1753, 61.7766],
                        [-72.1937, 61.7737],
                        [-72.2145, 61.7682],
                        [-72.2198, 61.7532],
                        [-72.2016, 61.7496],
                        [-72.1945, 61.7426],
                        [-72.1757, 61.7455],
                        [-72.1572, 61.7451],
                        [-72.1506, 61.7537],
                        [-72.1327, 61.7607],
                        [-72.1288, 61.765],
                        [-72.1127, 61.7671],
                        [-72.0896, 61.7609],
                        [-72.0798, 61.7601],
                        [-72.0787, 61.7445],
                        [-72.06, 61.744],
                        [-72.0522, 61.7492],
                        [-72.0431, 61.7459],
                        [-72.0466, 61.7405],
                        [-72.0348, 61.7317],
                        [-72.0143, 61.7293],
                        [-72.0042, 61.7218],
                        [-72.0147, 61.7091],
                        [-72.0086, 61.7022],
                        [-72.0147, 61.6892],
                        [-71.9956, 61.6882],
                        [-71.9485, 61.6972],
                        [-71.9492, 61.7026],
                        [-71.9384, 61.7078],
                        [-71.9234, 61.7088],
                        [-71.9102, 61.7043],
                        [-71.8954, 61.706],
                        [-71.8914, 61.7025],
                        [-71.8961, 61.6947],
                        [-71.8822, 61.6945],
                        [-71.8589, 61.7007],
                        [-71.8389, 61.6995],
                        [-71.807, 61.6946],
                        [-71.8014, 61.6869],
                        [-71.8102, 61.6738],
                        [-71.803, 61.6727],
                        [-71.781, 61.6828],
                        [-71.7717, 61.6836],
                        [-71.7638, 61.6785],
                        [-71.7684, 61.6699],
                        [-71.7539, 61.6718],
                        [-71.748, 61.6764],
                        [-71.7263, 61.6711],
                        [-71.7132, 61.6656],
                        [-71.7011, 61.6672],
                        [-71.6697, 61.6552],
                        [-71.6603, 61.6583],
                        [-71.6445, 61.6571],
                        [-71.6285, 61.6527],
                        [-71.6201, 61.6437],
                        [-71.5859, 61.6322],
                        [-71.5632, 61.622],
                        [-71.5494, 61.613],
                        [-71.5502, 61.6045],
                        [-71.5567, 61.5905],
                        [-71.5422, 61.5913],
                        [-71.5228, 61.5778],
                        [-71.5304, 61.5696],
                        [-71.5454, 61.5626],
                        [-71.5657, 61.5681],
                        [-71.5711, 61.5741],
                        [-71.5872, 61.5709],
                        [-71.5907, 61.5657],
                        [-71.6162, 61.5682],
                        [-71.6138, 61.5631],
                        [-71.6018, 61.5581],
                        [-71.6069, 61.5522],
                        [-71.6312, 61.5549],
                        [-71.6418, 61.5522],
                        [-71.6666, 61.5516],
                        [-71.6779, 61.5472],
                        [-71.6881, 61.551],
                        [-71.7091, 61.5449],
                        [-71.7307, 61.5507],
                        [-71.7456, 61.5484],
                        [-71.7411, 61.5432],
                        [-71.7749, 61.5451],
                        [-71.8008, 61.5424],
                        [-71.8289, 61.5364],
                        [-71.8289, 61.5309],
                        [-71.8043, 61.532],
                        [-71.7835, 61.5313],
                        [-71.7687, 61.5238],
                        [-71.7775, 61.5185],
                        [-71.7726, 61.5139],
                        [-71.7489, 61.5079],
                        [-71.7604, 61.5008],
                        [-71.7787, 61.4988],
                        [-71.7946, 61.4995],
                        [-71.8073, 61.497],
                        [-71.766, 61.4849],
                        [-71.7628, 61.4802],
                        [-71.726, 61.4722],
                        [-71.7684, 61.4657],
                        [-71.7814, 61.4599],
                        [-71.7888, 61.4517],
                        [-71.807, 61.4472],
                        [-71.8255, 61.4451],
                        [-71.8381, 61.447],
                        [-71.8421, 61.4395],
                        [-71.8714, 61.4344],
                        [-71.8577, 61.4286],
                        [-71.8615, 61.4201],
                        [-71.8397, 61.4227],
                        [-71.8247, 61.4196],
                        [-71.8017, 61.426],
                        [-71.7774, 61.4271],
                        [-71.7638, 61.4224],
                        [-71.7871, 61.419],
                        [-71.7813, 61.4156],
                        [-71.7605, 61.4154],
                        [-71.7468, 61.4208],
                        [-71.7238, 61.4132],
                        [-71.6917, 61.4063],
                        [-71.6759, 61.4106],
                        [-71.6413, 61.407],
                        [-71.6451, 61.4124],
                        [-71.634, 61.4188],
                        [-71.6081, 61.4163],
                        [-71.5995, 61.4119],
                        [-71.5809, 61.4124],
                        [-71.5674, 61.4065],
                        [-71.6021, 61.4024],
                        [-71.6286, 61.4099],
                        [-71.6374, 61.4025],
                        [-71.6141, 61.4017],
                        [-71.6208, 61.3949],
                        [-71.6491, 61.4028],
                        [-71.6552, 61.3938],
                        [-71.6549, 61.3875],
                        [-71.6416, 61.384],
                        [-71.6439, 61.3753],
                        [-71.6687, 61.3752],
                        [-71.7044, 61.3701],
                        [-71.723, 61.3713],
                        [-71.732, 61.3756],
                        [-71.7647, 61.3768],
                        [-71.7782, 61.3822],
                        [-71.8168, 61.3852],
                        [-71.8176, 61.3805],
                        [-71.8371, 61.3775],
                        [-71.8566, 61.3831],
                        [-71.8798, 61.385],
                        [-71.8946, 61.392],
                        [-71.9098, 61.3905],
                        [-71.8974, 61.3823],
                        [-71.8568, 61.3738],
                        [-71.8485, 61.377],
                        [-71.8261, 61.3719],
                        [-71.8065, 61.3757],
                        [-71.7936, 61.3739],
                        [-71.774, 61.3673],
                        [-71.7693, 61.3617],
                        [-71.791, 61.3592],
                        [-71.8187, 61.3508],
                        [-71.8122, 61.3441],
                        [-71.786, 61.3477],
                        [-71.7767, 61.3525],
                        [-71.7465, 61.356],
                        [-71.7084, 61.3504],
                        [-71.6798, 61.3505],
                        [-71.6834, 61.3449],
                        [-71.6942, 61.3445],
                        [-71.6826, 61.3309],
                        [-71.6638, 61.3261],
                        [-71.6637, 61.3198],
                        [-71.6748, 61.3189],
                        [-71.6896, 61.3271],
                        [-71.7256, 61.3306],
                        [-71.7444, 61.3306],
                        [-71.7373, 61.3217],
                        [-71.7144, 61.3201],
                        [-71.7046, 61.322],
                        [-71.6984, 61.3139],
                        [-71.7054, 61.3103],
                        [-71.7094, 61.3018],
                        [-71.7212, 61.2966],
                        [-71.7434, 61.2917],
                        [-71.7751, 61.2983],
                        [-71.7742, 61.2932],
                        [-71.7979, 61.2814],
                        [-71.7934, 61.2767],
                        [-71.7778, 61.2813],
                        [-71.7684, 61.29],
                        [-71.7501, 61.2896],
                        [-71.7465, 61.2854],
                        [-71.7127, 61.2804],
                        [-71.6807, 61.2771],
                        [-71.6653, 61.2719],
                        [-71.6645, 61.2558],
                        [-71.673, 61.2538],
                        [-71.6901, 61.242],
                        [-71.6846, 61.2387],
                        [-71.6632, 61.2512],
                        [-71.6635, 61.2612],
                        [-71.6578, 61.2679],
                        [-71.6358, 61.2728],
                        [-71.6147, 61.2643],
                        [-71.584, 61.2546],
                        [-71.5715, 61.2448],
                        [-71.5807, 61.2384],
                        [-71.5951, 61.238],
                        [-71.608, 61.2267],
                        [-71.6199, 61.2104],
                        [-71.6269, 61.1964],
                        [-71.6151, 61.1922],
                        [-71.6128, 61.2028],
                        [-71.5951, 61.2109],
                        [-71.5754, 61.2129],
                        [-71.5593, 61.222],
                        [-71.5427, 61.2191],
                        [-71.5366, 61.2078],
                        [-71.5493, 61.1928],
                        [-71.5399, 61.1885],
                        [-71.5401, 61.1815],
                        [-71.5555, 61.1752],
                        [-71.5511, 61.1703],
                        [-71.5688, 61.1696],
                        [-71.5789, 61.164],
                        [-71.558, 61.1602],
                        [-71.5129, 61.1646],
                        [-71.4962, 61.1696],
                        [-71.481, 61.1768],
                        [-71.5042, 61.1789],
                        [-71.5212, 61.1898],
                        [-71.5168, 61.209],
                        [-71.5221, 61.2159],
                        [-71.5118, 61.2221],
                        [-71.502, 61.2157],
                        [-71.4892, 61.2228],
                        [-71.4486, 61.2262],
                        [-71.4326, 61.2232],
                        [-71.4314, 61.213],
                        [-71.4437, 61.1952],
                        [-71.4363, 61.1803],
                        [-71.4437, 61.1715],
                        [-71.4311, 61.1642],
                        [-71.4147, 61.1663],
                        [-71.4151, 61.1733],
                        [-71.3989, 61.1758],
                        [-71.3937, 61.167],
                        [-71.3843, 61.1646],
                        [-71.3879, 61.1587],
                        [-71.4068, 61.1469],
                        [-71.4314, 61.1366],
                        [-71.4246, 61.1285],
                        [-71.4232, 61.1183],
                        [-71.4106, 61.1236],
                        [-71.4188, 61.1346],
                        [-71.4136, 61.1405],
                        [-71.4039, 61.1411],
                        [-71.3966, 61.1464],
                        [-71.3781, 61.1463],
                        [-71.3876, 61.1325],
                        [-71.3949, 61.1305],
                        [-71.3953, 61.1233],
                        [-71.3716, 61.1165],
                        [-71.3744, 61.1261],
                        [-71.3676, 61.14],
                        [-71.3436, 61.1574],
                        [-71.3113, 61.1544],
                        [-71.2924, 61.1598],
                        [-71.2821, 61.1469],
                        [-71.2762, 61.1468],
                        [-71.2657, 61.1573],
                        [-71.2476, 61.161],
                        [-71.2205, 61.1626],
                        [-71.2128, 61.1607],
                        [-71.2221, 61.1424],
                        [-71.2186, 61.1352],
                        [-71.2037, 61.1313],
                        [-71.1982, 61.1165],
                        [-71.1752, 61.1092],
                        [-71.1791, 61.1234],
                        [-71.171, 61.1246],
                        [-71.169, 61.1346],
                        [-71.1585, 61.1437],
                        [-71.1305, 61.137],
                        [-71.1168, 61.1356],
                        [-71.0973, 61.118],
                        [-71.0851, 61.1276],
                        [-71.0534, 61.1322],
                        [-71.0386, 61.1302],
                        [-71.0269, 61.1342],
                        [-71.0117, 61.1292],
                        [-71.0015, 61.1302],
                        [-70.981, 61.1271],
                        [-70.9674, 61.1162],
                        [-70.9558, 61.1103],
                        [-70.9487, 61.1176],
                        [-70.9331, 61.1162],
                        [-70.9289, 61.1103],
                        [-70.919, 61.1069],
                        [-70.9092, 61.097],
                        [-70.9252, 61.0829],
                        [-70.9255, 61.0792],
                        [-70.9381, 61.0666],
                        [-70.9396, 61.0572],
                        [-70.9451, 61.0538],
                        [-70.9378, 61.0455],
                        [-70.9296, 61.0544],
                        [-70.9318, 61.062],
                        [-70.9278, 61.0715],
                        [-70.9188, 61.077],
                        [-70.9086, 61.0727],
                        [-70.8889, 61.0855],
                        [-70.879, 61.0793],
                        [-70.8711, 61.0798],
                        [-70.8632, 61.0691],
                        [-70.8536, 61.0702],
                        [-70.8431, 61.0792],
                        [-70.8293, 61.0779],
                        [-70.8275, 61.0739],
                        [-70.8097, 61.0724],
                        [-70.8088, 61.0659],
                        [-70.7977, 61.0653],
                        [-70.7941, 61.0528],
                        [-70.7772, 61.057],
                        [-70.7624, 61.0486],
                        [-70.7535, 61.0358],
                        [-70.7455, 61.0332],
                        [-70.7384, 61.0202],
                        [-70.7195, 61.005],
                        [-70.7333, 61.0286],
                        [-70.7407, 61.0371],
                        [-70.7497, 61.0559],
                        [-70.7675, 61.0726],
                        [-70.7685, 61.0776],
                        [-70.7596, 61.0837],
                        [-70.7455, 61.0855],
                        [-70.7321, 61.0812],
                        [-70.744, 61.0698],
                        [-70.7107, 61.0787],
                        [-70.7162, 61.0666],
                        [-70.7057, 61.0608],
                        [-70.7003, 61.0703],
                        [-70.68, 61.0598],
                        [-70.6691, 61.0567],
                        [-70.6529, 61.0589],
                        [-70.6444, 61.055],
                        [-70.6507, 61.0429],
                        [-70.6617, 61.0445],
                        [-70.6766, 61.0319],
                        [-70.6783, 61.0207],
                        [-70.662, 61.0229],
                        [-70.6503, 61.0302],
                        [-70.6165, 61.0397],
                        [-70.6081, 61.0303],
                        [-70.6011, 61.0281],
                        [-70.5936, 61.017],
                        [-70.5665, 61.0216],
                        [-70.5483, 61.0192],
                        [-70.5346, 61.0102],
                        [-70.5251, 61.011],
                        [-70.5424, 61.0319],
                        [-70.5521, 61.0411],
                        [-70.5541, 61.0474],
                        [-70.5467, 61.0499],
                        [-70.5401, 61.0412],
                        [-70.5262, 61.0449],
                        [-70.5153, 61.0539],
                        [-70.5164, 61.0596],
                        [-70.505, 61.0609],
                        [-70.4953, 61.0704],
                        [-70.4883, 61.0676],
                        [-70.4806, 61.056],
                        [-70.46, 61.0438],
                        [-70.4625, 61.0379],
                        [-70.4602, 61.0261],
                        [-70.4321, 61.0263],
                        [-70.4175, 61.0159],
                        [-70.4175, 61.0074],
                        [-70.4049, 61.0095],
                        [-70.4053, 61.0237],
                        [-70.4104, 61.0338],
                        [-70.4192, 61.0364],
                        [-70.4288, 61.0568],
                        [-70.4297, 61.0695],
                        [-70.4232, 61.0703],
                        [-70.3945, 61.0555],
                        [-70.3792, 61.0495],
                        [-70.3631, 61.0555],
                        [-70.3738, 61.0603],
                        [-70.3932, 61.0727],
                        [-70.4122, 61.0878],
                        [-70.3814, 61.0897],
                        [-70.3666, 61.0786],
                        [-70.3642, 61.0723],
                        [-70.3544, 61.0716],
                        [-70.3454, 61.062],
                        [-70.3302, 61.0575],
                        [-70.3377, 61.0747],
                        [-70.3491, 61.0857],
                        [-70.3318, 61.0822],
                        [-70.3257, 61.091],
                        [-70.3181, 61.0908],
                        [-70.3013, 61.1016],
                        [-70.2925, 61.0927],
                        [-70.2706, 61.0931],
                        [-70.2694, 61.0993],
                        [-70.2552, 61.098],
                        [-70.2368, 61.0882],
                        [-70.2373, 61.067],
                        [-70.2264, 61.0613],
                        [-70.2148, 61.0681],
                        [-70.2103, 61.0779],
                        [-70.1826, 61.0766],
                        [-70.1803, 61.0815],
                        [-70.156, 61.0925],
                        [-70.1457, 61.0892],
                        [-70.1284, 61.0899],
                        [-70.131, 61.0811],
                        [-70.1464, 61.0674],
                        [-70.1407, 61.061],
                        [-70.1184, 61.0697],
                        [-70.1068, 61.0697],
                        [-70.1067, 61.0544],
                        [-70.1157, 61.0442],
                        [-70.1304, 61.0393],
                        [-70.1481, 61.0299],
                        [-70.1474, 61.0232],
                        [-70.1543, 61.0183],
                        [-70.154, 61.0072],
                        [-70.1593, 60.9987],
                        [-70.1461, 61],
                        [-70.133, 60.997],
                        [-70.1154, 60.9871],
                        [-70.1107, 60.9803],
                        [-70.116, 60.967],
                        [-70.1147, 60.9602],
                        [-70.1051, 60.9598],
                        [-70.0952, 60.964],
                        [-70.0863, 60.9611],
                        [-70.0862, 60.9473],
                        [-70.0813, 60.9354],
                        [-70.0948, 60.9266],
                        [-70.0899, 60.9146],
                        [-70.0821, 60.9088],
                        [-70.0782, 60.9003],
                        [-70.079, 60.8894],
                        [-70.1076, 60.8888],
                        [-70.1219, 60.8916],
                        [-70.1405, 60.8883],
                        [-70.144, 60.8819],
                        [-70.1298, 60.8733],
                        [-70.1087, 60.8768],
                        [-70.0907, 60.8822],
                        [-70.0752, 60.879],
                        [-70.07, 60.8739],
                        [-70.0567, 60.8706],
                        [-70.0616, 60.8654],
                        [-70.0786, 60.8592],
                        [-70.0745, 60.8539],
                        [-70.0612, 60.8511],
                        [-70.0631, 60.8374],
                        [-70.0449, 60.8389],
                        [-70.0449, 60.8436],
                        [-70.0109, 60.8459],
                        [-69.9886, 60.8443],
                        [-69.985, 60.8354],
                        [-69.9711, 60.8344],
                        [-69.965, 60.8237],
                        [-69.9334, 60.8161],
                        [-69.9094, 60.8022],
                        [-69.8965, 60.7996],
                        [-69.8804, 60.8033],
                        [-69.884, 60.813],
                        [-69.8757, 60.8213],
                        [-69.8635, 60.8237],
                        [-69.8567, 60.8285],
                        [-69.8369, 60.8524],
                        [-69.8487, 60.8556],
                        [-69.8555, 60.8488],
                        [-69.8713, 60.8529],
                        [-69.8596, 60.8592],
                        [-69.847, 60.8617],
                        [-69.8286, 60.8806],
                        [-69.8348, 60.8863],
                        [-69.8255, 60.8931],
                        [-69.7998, 60.8896],
                        [-69.7959, 60.8997],
                        [-69.7877, 60.9038],
                        [-69.768, 60.905],
                        [-69.7518, 60.9179],
                        [-69.7393, 60.9122],
                        [-69.7342, 60.9044],
                        [-69.7416, 60.9024],
                        [-69.75, 60.8937],
                        [-69.7412, 60.8871],
                        [-69.7418, 60.8767],
                        [-69.737, 60.8746],
                        [-69.7174, 60.8762],
                        [-69.7095, 60.8798],
                        [-69.6948, 60.8709],
                        [-69.6754, 60.874],
                        [-69.6493, 60.8743],
                        [-69.6401, 60.8817],
                        [-69.6391, 60.8912],
                        [-69.6321, 60.8918],
                        [-69.6404, 60.9046],
                        [-69.6307, 60.912],
                        [-69.6359, 60.9194],
                        [-69.6522, 60.9225],
                        [-69.6639, 60.9292],
                        [-69.6621, 60.9329],
                        [-69.6745, 60.9434],
                        [-69.6839, 60.9457],
                        [-69.6827, 60.9584],
                        [-69.6656, 60.9678],
                        [-69.6847, 60.9836],
                        [-69.6725, 60.9859],
                        [-69.6784, 60.9961],
                        [-69.6616, 60.999],
                        [-69.6615, 61.0026],
                        [-69.6797, 61.0121],
                        [-69.6749, 61.0144],
                        [-69.6739, 61.026],
                        [-69.6469, 61.0244],
                        [-69.6384, 61.0344],
                        [-69.6484, 61.0416],
                        [-69.6347, 61.0476],
                        [-69.6456, 61.0512],
                        [-69.6332, 61.0586],
                        [-69.6252, 61.0575],
                        [-69.6112, 61.0652],
                        [-69.6047, 61.0728],
                        [-69.6116, 61.0789],
                        [-69.5946, 61.0812],
                        [-69.5727, 61.08],
                        [-69.5673, 61.0766],
                        [-69.5504, 61.079],
                        [-69.5187, 61.0726],
                        [-69.5023, 61.0672],
                        [-69.4893, 61.0474],
                        [-69.482, 61.0295],
                        [-69.4819, 61.0137],
                        [-69.4648, 60.9977],
                        [-69.4684, 60.9916],
                        [-69.4538, 60.9828],
                        [-69.4535, 60.972],
                        [-69.4476, 60.9597],
                        [-69.4393, 60.9523],
                        [-69.4249, 60.951],
                        [-69.4178, 60.9463],
                        [-69.4369, 60.9382],
                        [-69.4402, 60.9301],
                        [-69.4132, 60.9309],
                        [-69.394, 60.9293],
                        [-69.3739, 60.9203],
                        [-69.3754, 60.9108],
                        [-69.3831, 60.9082],
                        [-69.3885, 60.896],
                        [-69.3768, 60.8864],
                        [-69.403, 60.8874],
                        [-69.3896, 60.8781],
                        [-69.3997, 60.8748],
                        [-69.3877, 60.8556],
                        [-69.3966, 60.8492],
                        [-69.3793, 60.8316],
                        [-69.375, 60.8234],
                        [-69.3596, 60.8156],
                        [-69.3777, 60.8084],
                        [-69.3715, 60.8023],
                        [-69.3787, 60.7967],
                        [-69.3899, 60.8049],
                        [-69.3965, 60.8148],
                        [-69.4254, 60.8152],
                        [-69.4235, 60.8052],
                        [-69.4266, 60.7969],
                        [-69.417, 60.7926],
                        [-69.4161, 60.7859],
                        [-69.4237, 60.7822],
                        [-69.452, 60.7815],
                        [-69.4548, 60.7734],
                        [-69.4819, 60.7754],
                        [-69.4901, 60.7673],
                        [-69.5009, 60.7622],
                        [-69.5225, 60.7631],
                        [-69.5335, 60.76],
                        [-69.5365, 60.7524],
                        [-69.5461, 60.7517],
                        [-69.5506, 60.7463],
                        [-69.563, 60.7448],
                        [-69.5678, 60.7377],
                        [-69.5939, 60.7332],
                        [-69.6118, 60.7327],
                        [-69.6279, 60.7353],
                        [-69.6275, 60.7221],
                        [-69.6218, 60.7078],
                        [-69.6434, 60.7033],
                        [-69.6517, 60.6971],
                        [-69.6621, 60.7009],
                        [-69.6864, 60.6977],
                        [-69.6992, 60.6997],
                        [-69.6956, 60.6905],
                        [-69.6997, 60.6869],
                        [-69.6762, 60.674],
                        [-69.6705, 60.6528],
                        [-69.6806, 60.6517],
                        [-69.6889, 60.6393],
                        [-69.6618, 60.6328],
                        [-69.6503, 60.6345],
                        [-69.6454, 60.6281],
                        [-69.6536, 60.6154],
                        [-69.6653, 60.6108],
                        [-69.6731, 60.6013],
                        [-69.6649, 60.5941],
                        [-69.6449, 60.5949],
                        [-69.6265, 60.5883],
                        [-69.6289, 60.5846],
                        [-69.6482, 60.5801],
                        [-69.6651, 60.5722],
                        [-69.6537, 60.5659],
                        [-69.6523, 60.5598],
                        [-69.6917, 60.5578],
                        [-69.6949, 60.5481],
                        [-69.7094, 60.54],
                        [-69.7326, 60.5392],
                        [-69.767, 60.5453],
                        [-69.7785, 60.5446],
                        [-69.791, 60.5389],
                        [-69.7982, 60.5271],
                        [-69.8131, 60.5154],
                        [-69.7985, 60.5061],
                        [-69.7994, 60.4991],
                        [-69.7917, 60.4963],
                        [-69.7832, 60.4869],
                        [-69.7582, 60.4801],
                        [-69.7613, 60.4771],
                        [-69.7476, 60.4685],
                        [-69.7512, 60.4651],
                        [-69.7309, 60.4609],
                        [-69.7166, 60.4601],
                        [-69.7166, 60.4549],
                        [-69.7258, 60.4424],
                        [-69.7352, 60.4372],
                        [-69.7527, 60.4381],
                        [-69.7519, 60.4299],
                        [-69.7598, 60.4207],
                        [-69.7509, 60.407],
                        [-69.7265, 60.4082],
                        [-69.7107, 60.4],
                        [-69.7203, 60.3832],
                        [-69.6993, 60.3729],
                        [-69.6889, 60.3712],
                        [-69.6895, 60.3649],
                        [-69.6734, 60.3638],
                        [-69.6627, 60.356],
                        [-69.6695, 60.351],
                        [-69.6808, 60.3535],
                        [-69.6895, 60.3398],
                        [-69.7096, 60.3475],
                        [-69.7303, 60.3382],
                        [-69.744, 60.3258],
                        [-69.7423, 60.3214],
                        [-69.7603, 60.316],
                        [-69.7628, 60.3122],
                        [-69.7429, 60.3042],
                        [-69.7113, 60.3016],
                        [-69.7042, 60.2937],
                        [-69.6904, 60.289],
                        [-69.6795, 60.2887],
                        [-69.658, 60.2827],
                        [-69.6652, 60.2758],
                        [-69.6857, 60.2704],
                        [-69.6866, 60.2641],
                        [-69.6811, 60.2564],
                        [-69.6731, 60.2551],
                        [-69.6524, 60.2625],
                        [-69.6462, 60.2621],
                        [-69.642, 60.2519],
                        [-69.6347, 60.2487],
                        [-69.6397, 60.2385],
                        [-69.6172, 60.2291],
                        [-69.5853, 60.2243],
                        [-69.5984, 60.215],
                        [-69.5899, 60.2091],
                        [-69.6035, 60.1997],
                        [-69.6069, 60.1914],
                        [-69.5902, 60.1803],
                        [-69.5959, 60.1649],
                        [-69.6108, 60.1675],
                        [-69.6115, 60.1587],
                        [-69.617, 60.1513],
                        [-69.6255, 60.1504],
                        [-69.6288, 60.1437],
                        [-69.6384, 60.1383],
                        [-69.6161, 60.1292],
                        [-69.6194, 60.1229],
                        [-69.6099, 60.1199],
                        [-69.6088, 60.111],
                        [-69.6197, 60.1069],
                        [-69.631, 60.0969],
                        [-69.6591, 60.0924],
                        [-69.6465, 60.086],
                        [-69.6184, 60.0769],
                        [-69.6191, 60.0716],
                        [-69.6319, 60.0693],
                        [-69.6415, 60.0711],
                        [-69.6487, 60.0665],
                        [-69.6617, 60.066],
                        [-69.6661, 60.0793],
                        [-69.6871, 60.0689],
                        [-69.7035, 60.0693],
                        [-69.7248, 60.0658],
                        [-69.7233, 60.0623],
                        [-69.7086, 60.0558],
                        [-69.7065, 60.0441],
                        [-69.7155, 60.0404],
                        [-69.725, 60.0436],
                        [-69.7378, 60.0549],
                        [-69.7555, 60.0587],
                        [-69.7679, 60.057],
                        [-69.7804, 60.0655],
                        [-69.8078, 60.0642],
                        [-69.8226, 60.0739],
                        [-69.8392, 60.0715],
                        [-69.8329, 60.0665],
                        [-69.8309, 60.048],
                        [-69.847, 60.0268],
                        [-69.8374, 60.0186],
                        [-69.815, 60.0192],
                        [-69.8011, 60.0149],
                        [-69.7782, 60.0149],
                        [-69.7789, 60.0087],
                        [-69.7724, 59.9992],
                        [-69.7756, 59.9945],
                        [-69.7818, 59.968],
                        [-69.7641, 59.9658],
                        [-69.7435, 59.967],
                        [-69.7372, 59.9645],
                        [-69.7182, 59.9669],
                        [-69.7129, 59.9652],
                        [-69.7065, 59.9546],
                        [-69.7132, 59.9454],
                        [-69.7086, 59.9424],
                        [-69.7051, 59.9286],
                        [-69.7085, 59.9208],
                        [-69.7159, 59.9163],
                        [-69.7459, 59.9162],
                        [-69.7626, 59.9215],
                        [-69.7665, 59.9146],
                        [-69.7549, 59.9079],
                        [-69.7237, 59.9016],
                        [-69.7207, 59.8959],
                        [-69.7082, 59.8952],
                        [-69.6994, 59.8876],
                        [-69.6701, 59.888],
                        [-69.6622, 59.8835],
                        [-69.6669, 59.8771],
                        [-69.644, 59.8804],
                        [-69.6278, 59.8799],
                        [-69.6054, 59.8824],
                        [-69.6004, 59.8799],
                        [-69.5666, 59.8744],
                        [-69.5615, 59.8688],
                        [-69.5499, 59.8681],
                        [-69.5438, 59.8636],
                        [-69.5657, 59.8551],
                        [-69.5745, 59.8468],
                        [-69.5939, 59.8384],
                        [-69.5826, 59.8359],
                        [-69.5847, 59.8301],
                        [-69.5754, 59.8197],
                        [-69.5835, 59.8152],
                        [-69.5815, 59.8021],
                        [-69.599, 59.7952],
                        [-69.6036, 59.7891],
                        [-69.5911, 59.7853],
                        [-69.5907, 59.7793],
                        [-69.571, 59.7737],
                        [-69.5663, 59.7698],
                        [-69.5496, 59.7665],
                        [-69.5804, 59.7585],
                        [-69.5801, 59.7528],
                        [-69.5556, 59.742],
                        [-69.5678, 59.7321],
                        [-69.5801, 59.7419],
                        [-69.5946, 59.7333],
                        [-69.5878, 59.727],
                        [-69.6016, 59.7195],
                        [-69.6225, 59.715],
                        [-69.6339, 59.7095],
                        [-69.6372, 59.6995],
                        [-69.6318, 59.6902],
                        [-69.6081, 59.6918],
                        [-69.6004, 59.6946],
                        [-69.5888, 59.6917],
                        [-69.5997, 59.6813],
                        [-69.5727, 59.6815],
                        [-69.565, 59.6834],
                        [-69.5516, 59.6804],
                        [-69.5451, 59.6844],
                        [-69.5346, 59.6717],
                        [-69.5132, 59.6774],
                        [-69.5035, 59.6754],
                        [-69.5034, 59.656],
                        [-69.5074, 59.6501],
                        [-69.5006, 59.6395],
                        [-69.508, 59.6334],
                        [-69.5171, 59.6173],
                        [-69.5271, 59.6125],
                        [-69.5452, 59.6098],
                        [-69.5576, 59.6006],
                        [-69.5572, 59.5947],
                        [-69.5721, 59.5931],
                        [-69.5886, 59.5883],
                        [-69.5961, 59.5966],
                        [-69.61, 59.5945],
                        [-69.6151, 59.5904],
                        [-69.6264, 59.5917],
                        [-69.6379, 59.5881],
                        [-69.6549, 59.5736],
                        [-69.665, 59.5682],
                        [-69.6783, 59.5506],
                        [-69.7019, 59.5396],
                        [-69.7151, 59.5282],
                        [-69.729, 59.5229],
                        [-69.7438, 59.5096],
                        [-69.7381, 59.4969],
                        [-69.7466, 59.4886],
                        [-69.7383, 59.4835],
                        [-69.7138, 59.4852],
                        [-69.7048, 59.4821],
                        [-69.6927, 59.4855],
                        [-69.6743, 59.4775],
                        [-69.6954, 59.4675],
                        [-69.6824, 59.4613],
                        [-69.6576, 59.4639],
                        [-69.6508, 59.454],
                        [-69.653, 59.4362],
                        [-69.6373, 59.4303],
                        [-69.6341, 59.421],
                        [-69.6478, 59.3989],
                        [-69.6592, 59.3979],
                        [-69.6607, 59.3862],
                        [-69.6437, 59.3862],
                        [-69.6228, 59.3922],
                        [-69.6233, 59.3774],
                        [-69.6289, 59.374],
                        [-69.6253, 59.3626],
                        [-69.6281, 59.3539],
                        [-69.6433, 59.3547],
                        [-69.6601, 59.3583],
                        [-69.6767, 59.3523],
                        [-69.6831, 59.3436],
                        [-69.7011, 59.3455],
                        [-69.7144, 59.349],
                        [-69.7266, 59.3462],
                        [-69.7428, 59.3372],
                        [-69.7606, 59.3223],
                        [-69.7529, 59.3137],
                        [-69.7325, 59.3134],
                        [-69.7206, 59.3096],
                        [-69.6927, 59.3085],
                        [-69.6713, 59.3163],
                        [-69.639, 59.3088],
                        [-69.6422, 59.3012],
                        [-69.6287, 59.2947],
                        [-69.61, 59.296],
                        [-69.6007, 59.3174],
                        [-69.5799, 59.3261],
                        [-69.5572, 59.3308],
                        [-69.5475, 59.3252],
                        [-69.5328, 59.3309],
                        [-69.5194, 59.3317],
                        [-69.5128, 59.3354],
                        [-69.4897, 59.3401],
                        [-69.4853, 59.3443],
                        [-69.45, 59.3598],
                        [-69.4248, 59.3562],
                        [-69.4115, 59.3586],
                        [-69.3833, 59.3531],
                        [-69.3792, 59.3466],
                        [-69.3606, 59.3499],
                        [-69.3637, 59.3423],
                        [-69.3513, 59.3425],
                        [-69.3477, 59.3312],
                        [-69.3523, 59.3275],
                        [-69.3526, 59.3159],
                        [-69.3455, 59.3106],
                        [-69.3235, 59.3148],
                        [-69.3215, 59.3113],
                        [-69.3073, 59.3094],
                        [-69.2901, 59.3216],
                        [-69.2853, 59.3284],
                        [-69.2761, 59.3291],
                        [-69.2584, 59.3358],
                        [-69.2397, 59.3308],
                        [-69.2415, 59.3126],
                        [-69.2349, 59.3089],
                        [-69.2592, 59.2957],
                        [-69.2584, 59.2837],
                        [-69.2634, 59.2803],
                        [-69.2534, 59.275],
                        [-69.2388, 59.2731],
                        [-69.236, 59.255],
                        [-69.239, 59.2473],
                        [-69.2296, 59.2443],
                        [-69.2246, 59.2295],
                        [-69.2349, 59.2327],
                        [-69.2474, 59.2314],
                        [-69.2434, 59.2228],
                        [-69.2809, 59.229],
                        [-69.2749, 59.2204],
                        [-69.2827, 59.2105],
                        [-69.3224, 59.2255],
                        [-69.3371, 59.2419],
                        [-69.3506, 59.2444],
                        [-69.3624, 59.2397],
                        [-69.3718, 59.2326],
                        [-69.3707, 59.2237],
                        [-69.3577, 59.2135],
                        [-69.3548, 59.1984],
                        [-69.3739, 59.19],
                        [-69.39, 59.1952],
                        [-69.4066, 59.1941],
                        [-69.4136, 59.2023],
                        [-69.4252, 59.2088],
                        [-69.4083, 59.2201],
                        [-69.4147, 59.2269],
                        [-69.4394, 59.2277],
                        [-69.4607, 59.2217],
                        [-69.4625, 59.215],
                        [-69.4797, 59.2139],
                        [-69.4866, 59.2056],
                        [-69.4971, 59.1996],
                        [-69.5022, 59.1836],
                        [-69.509, 59.1748],
                        [-69.5206, 59.1722],
                        [-69.5299, 59.1577],
                        [-69.5314, 59.145],
                        [-69.5217, 59.1395],
                        [-69.5186, 59.1317],
                        [-69.4997, 59.109],
                        [-69.4837, 59.1132],
                        [-69.4855, 59.1258],
                        [-69.4653, 59.1386],
                        [-69.4509, 59.1382],
                        [-69.4385, 59.1332],
                        [-69.4346, 59.1281],
                        [-69.4356, 59.1175],
                        [-69.4423, 59.1074],
                        [-69.4306, 59.1055],
                        [-69.4034, 59.115],
                        [-69.3906, 59.1167],
                        [-69.375, 59.123],
                        [-69.348, 59.1142],
                        [-69.3431, 59.1067],
                        [-69.3468, 59.1023],
                        [-69.3432, 59.0946],
                        [-69.3663, 59.0809],
                        [-69.3803, 59.07],
                        [-69.3947, 59.0715],
                        [-69.4066, 59.062],
                        [-69.421, 59.0454],
                        [-69.4351, 59.0219],
                        [-69.4534, 59.0307],
                        [-69.4603, 59.0361],
                        [-69.4714, 59.0542],
                        [-69.4791, 59.0618],
                        [-69.4922, 59.0588],
                        [-69.4932, 59.0518],
                        [-69.4823, 59.0409],
                        [-69.4683, 59.0199],
                        [-69.4643, 59.0032],
                        [-69.4678, 58.9995],
                        [-69.461, 58.9861],
                        [-69.4612, 58.9704],
                        [-69.4673, 58.9536],
                        [-69.4568, 58.9299],
                        [-69.4511, 58.9212],
                        [-69.4416, 58.9175],
                        [-69.4409, 58.9058],
                        [-69.4497, 58.905],
                        [-69.4524, 58.8981],
                        [-69.4472, 58.8864],
                        [-69.4642, 58.8784],
                        [-69.4874, 58.8772],
                        [-69.5085, 58.9016],
                        [-69.5174, 58.9019],
                        [-69.5171, 58.8938],
                        [-69.5226, 58.8828],
                        [-69.5202, 58.8613],
                        [-69.5336, 58.861],
                        [-69.5472, 58.851],
                        [-69.5361, 58.849],
                        [-69.5303, 58.8417],
                        [-69.5387, 58.835],
                        [-69.5378, 58.8194],
                        [-69.5436, 58.8112],
                        [-69.5539, 58.8084],
                        [-69.5685, 58.8124],
                        [-69.5717, 58.8037],
                        [-69.5901, 58.8041],
                        [-69.6204, 58.7963],
                        [-69.6302, 58.799],
                        [-69.6417, 58.8065],
                        [-69.6481, 58.8029],
                        [-69.6468, 58.794],
                        [-69.6553, 58.7923],
                        [-69.674, 58.8093],
                        [-69.6793, 58.8179],
                        [-69.6902, 58.8227],
                        [-69.6832, 58.8404],
                        [-69.6955, 58.8676],
                        [-69.6941, 58.881],
                        [-69.6852, 58.8924],
                        [-69.6722, 58.8913],
                        [-69.6554, 58.9026],
                        [-69.6627, 58.907],
                        [-69.6664, 58.9184],
                        [-69.6575, 58.9332],
                        [-69.657, 58.9415],
                        [-69.6643, 58.9419],
                        [-69.6819, 58.92],
                        [-69.6789, 58.9106],
                        [-69.6814, 58.8999],
                        [-69.7008, 58.917],
                        [-69.7055, 58.9296],
                        [-69.6874, 58.9459],
                        [-69.6966, 58.9578],
                        [-69.6898, 58.9625],
                        [-69.703, 58.9865],
                        [-69.696, 58.9879],
                        [-69.7131, 59.0038],
                        [-69.719, 58.9932],
                        [-69.7092, 58.9805],
                        [-69.7091, 58.9647],
                        [-69.7266, 58.9622],
                        [-69.7297, 58.9808],
                        [-69.7412, 58.9837],
                        [-69.7483, 58.9897],
                        [-69.7472, 58.9998],
                        [-69.754, 59.0256],
                        [-69.7609, 59.0194],
                        [-69.7585, 59.0053],
                        [-69.7645, 58.9915],
                        [-69.7558, 58.9782],
                        [-69.7581, 58.9681],
                        [-69.7697, 58.9698],
                        [-69.7792, 58.9758],
                        [-69.7887, 58.9884],
                        [-69.7889, 58.9958],
                        [-69.795, 58.9984],
                        [-69.801, 59.0078],
                        [-69.8103, 59.0106],
                        [-69.8043, 59.0173],
                        [-69.817, 59.0253],
                        [-69.8252, 59.0214],
                        [-69.8289, 59.0341],
                        [-69.8199, 59.0394],
                        [-69.8306, 59.0517],
                        [-69.8387, 59.0557],
                        [-69.8558, 59.0545],
                        [-69.866, 59.0466],
                        [-69.8614, 59.032],
                        [-69.8616, 59.0228],
                        [-69.8489, 59.0087],
                        [-69.857, 58.9795],
                        [-69.8796, 58.9743],
                        [-69.8889, 58.9586],
                        [-69.8699, 58.9616],
                        [-69.8452, 58.96],
                        [-69.8364, 58.9514],
                        [-69.8262, 58.957],
                        [-69.8102, 58.9509],
                        [-69.8077, 58.9372],
                        [-69.8081, 58.922],
                        [-69.7983, 58.9195],
                        [-69.7935, 58.9067],
                        [-69.8031, 58.8999],
                        [-69.7963, 58.8846],
                        [-69.7967, 58.8721],
                        [-69.792, 58.868],
                        [-69.7946, 58.8597],
                        [-69.7883, 58.8583],
                        [-69.7852, 58.8322],
                        [-69.7901, 58.8282],
                        [-69.8028, 58.8295],
                        [-69.8068, 58.8359],
                        [-69.8174, 58.8354],
                        [-69.8302, 58.8313],
                        [-69.8374, 58.8397],
                        [-69.8443, 58.8413],
                        [-69.8476, 58.8492],
                        [-69.8625, 58.8535],
                        [-69.8801, 58.8724],
                        [-69.8816, 58.8784],
                        [-69.8976, 58.8784],
                        [-69.891, 58.8519],
                        [-69.879, 58.8413],
                        [-69.8832, 58.834],
                        [-69.8775, 58.8265],
                        [-69.899, 58.8286],
                        [-69.9135, 58.8196],
                        [-69.9216, 58.8253],
                        [-69.9306, 58.823],
                        [-69.9459, 58.8125],
                        [-69.9674, 58.8082],
                        [-69.9755, 58.8083],
                        [-69.9862, 58.8149],
                        [-70.0147, 58.8192],
                        [-70.0302, 58.8287],
                        [-70.0403, 58.8225],
                        [-70.0352, 58.8147],
                        [-70.0362, 58.8062],
                        [-70.0465, 58.8042],
                        [-70.0575, 58.7951],
                        [-70.0793, 58.7866],
                        [-70.0724, 58.767],
                        [-70.0601, 58.7645],
                        [-70.0489, 58.7512],
                        [-70.0416, 58.7586],
                        [-70.0331, 58.7544],
                        [-70.0332, 58.7491],
                        [-70.0427, 58.745],
                        [-70.0302, 58.7378],
                        [-70.0192, 58.7345],
                        [-70.0077, 58.7396],
                        [-69.9997, 58.7396],
                        [-69.9914, 58.7492],
                        [-69.9675, 58.762],
                        [-69.9442, 58.7724],
                        [-69.9213, 58.7783],
                        [-69.9094, 58.7849],
                        [-69.8982, 58.7738],
                        [-69.9125, 58.7439],
                        [-69.9101, 58.7396],
                        [-69.9278, 58.7079],
                        [-69.9273, 58.6887],
                        [-69.9137, 58.6872],
                        [-69.8965, 58.6907],
                        [-69.8813, 58.6915],
                        [-69.8379, 58.7108],
                        [-69.8309, 58.7175],
                        [-69.8396, 58.7339],
                        [-69.8292, 58.73],
                        [-69.8256, 58.7216],
                        [-69.8141, 58.7173],
                        [-69.8093, 58.7046],
                        [-69.7971, 58.7052],
                        [-69.7879, 58.7099],
                        [-69.779, 58.7093],
                        [-69.7791, 58.6989],
                        [-69.7858, 58.6893],
                        [-69.7973, 58.6833],
                        [-69.8125, 58.6825],
                        [-69.8391, 58.6743],
                        [-69.8643, 58.6435],
                        [-69.8497, 58.6302],
                        [-69.858, 58.6171],
                        [-69.8515, 58.6103],
                        [-69.841, 58.6121],
                        [-69.8239, 58.6054],
                        [-69.8219, 58.599],
                        [-69.8117, 58.5879],
                        [-69.7914, 58.5982],
                        [-69.7732, 58.6182],
                        [-69.7512, 58.6283],
                        [-69.7419, 58.6397],
                        [-69.7355, 58.6578],
                        [-69.731, 58.66],
                        [-69.7242, 58.6802],
                        [-69.7039, 58.6868],
                        [-69.7021, 58.7009],
                        [-69.6915, 58.705],
                        [-69.6891, 58.7109],
                        [-69.6773, 58.7166],
                        [-69.6746, 58.6954],
                        [-69.6668, 58.6992],
                        [-69.6568, 58.6982],
                        [-69.6533, 58.6925],
                        [-69.6595, 58.6849],
                        [-69.6489, 58.6679],
                        [-69.6406, 58.6703],
                        [-69.642, 58.7056],
                        [-69.6343, 58.7201],
                        [-69.6269, 58.7425],
                        [-69.6203, 58.7457],
                        [-69.6114, 58.7559],
                        [-69.6112, 58.7623],
                        [-69.5887, 58.7743],
                        [-69.5765, 58.772],
                        [-69.5682, 58.7829],
                        [-69.5499, 58.7812],
                        [-69.5258, 58.7857],
                        [-69.4924, 58.7804],
                        [-69.4727, 58.7994],
                        [-69.4598, 58.7893],
                        [-69.4586, 58.7996],
                        [-69.4388, 58.8051],
                        [-69.4422, 58.8195],
                        [-69.4182, 58.8265],
                        [-69.4086, 58.8266],
                        [-69.4024, 58.8322],
                        [-69.411, 58.8387],
                        [-69.4141, 58.8494],
                        [-69.4056, 58.8551],
                        [-69.3926, 58.8553],
                        [-69.3907, 58.8713],
                        [-69.3781, 58.8729],
                        [-69.3521, 58.8673],
                        [-69.3397, 58.8696],
                        [-69.3292, 58.8753],
                        [-69.2972, 58.8777],
                        [-69.2834, 58.8813],
                        [-69.2736, 58.8897],
                        [-69.2588, 58.8943],
                        [-69.2631, 58.9014],
                        [-69.2508, 58.9048],
                        [-69.2338, 58.9043],
                        [-69.2256, 58.8983],
                        [-69.2203, 58.8841],
                        [-69.2091, 58.8921],
                        [-69.1971, 58.8952],
                        [-69.1968, 58.8839],
                        [-69.2066, 58.8753],
                        [-69.1874, 58.8783],
                        [-69.1544, 58.9016],
                        [-69.142, 58.9043],
                        [-69.1289, 58.9021],
                        [-69.1149, 58.9047],
                        [-69.0974, 58.9013],
                        [-69.0871, 58.9053],
                        [-69.0764, 58.9051],
                        [-69.0721, 58.8967],
                        [-69.056, 58.8944],
                        [-69.0457, 58.9056],
                        [-69.0319, 58.904],
                        [-69.0388, 58.8956],
                        [-69.0124, 58.9012],
                        [-69.0031, 58.9003],
                        [-68.9878, 58.9065],
                        [-68.9892, 58.8981],
                        [-69.0022, 58.8808],
                        [-69.0109, 58.8747],
                        [-68.9997, 58.8724],
                        [-68.9899, 58.8784],
                        [-68.9733, 58.883],
                        [-68.9561, 58.8813],
                        [-68.9453, 58.8769],
                        [-68.9382, 58.883],
                        [-68.924, 58.8799],
                        [-68.8923, 58.8845],
                        [-68.8893, 58.8821],
                        [-68.87, 58.8857],
                        [-68.8624, 58.8812],
                        [-68.8514, 58.8902],
                        [-68.8336, 58.9008],
                        [-68.8161, 58.9015],
                        [-68.7908, 58.9159],
                        [-68.7726, 58.9183],
                        [-68.7593, 58.9169],
                        [-68.7556, 58.9107],
                        [-68.7367, 58.8954],
                        [-68.7156, 58.8944],
                        [-68.6925, 58.9002],
                        [-68.6806, 58.9008],
                        [-68.6724, 58.9046],
                        [-68.6609, 58.9026],
                        [-68.6472, 58.9068],
                        [-68.635, 58.9077],
                        [-68.6334, 58.8971],
                        [-68.6221, 58.9035],
                        [-68.6029, 58.9007],
                        [-68.5936, 58.8944],
                        [-68.5797, 58.8969],
                        [-68.5803, 58.8905],
                        [-68.5622, 58.8805],
                        [-68.5467, 58.8666],
                        [-68.5307, 58.8613],
                        [-68.5153, 58.8619],
                        [-68.5064, 58.8433],
                        [-68.4974, 58.8362],
                        [-68.5021, 58.8202],
                        [-68.4916, 58.8237],
                        [-68.4804, 58.8325],
                        [-68.4671, 58.8311],
                        [-68.4735, 58.8254],
                        [-68.4593, 58.8215],
                        [-68.447, 58.8153],
                        [-68.3927, 58.82],
                        [-68.3872, 58.8087],
                        [-68.397, 58.8041],
                        [-68.3877, 58.8006],
                        [-68.3965, 58.7924],
                        [-68.3872, 58.7831],
                        [-68.372, 58.7881],
                        [-68.3591, 58.7835],
                        [-68.347, 58.7825],
                        [-68.3448, 58.7714],
                        [-68.3558, 58.766],
                        [-68.3633, 58.7578],
                        [-68.367, 58.7463],
                        [-68.378, 58.7435],
                        [-68.375, 58.7391],
                        [-68.3756, 58.7273],
                        [-68.3815, 58.7241],
                        [-68.3769, 58.7101],
                        [-68.3616, 58.7002],
                        [-68.3592, 58.6928],
                        [-68.3498, 58.6879],
                        [-68.3311, 58.6839],
                        [-68.3303, 58.6743],
                        [-68.3342, 58.6703],
                        [-68.3298, 58.6633],
                        [-68.3334, 58.6427],
                        [-68.337, 58.6391],
                        [-68.3298, 58.6257],
                        [-68.3376, 58.6069],
                        [-68.3424, 58.6029],
                        [-68.3386, 58.5944],
                        [-68.3296, 58.5864],
                        [-68.2831, 58.5866],
                        [-68.2774, 58.5754],
                        [-68.2707, 58.5726],
                        [-68.2766, 58.5664],
                        [-68.2689, 58.5607],
                        [-68.277, 58.5532],
                        [-68.2788, 58.5401],
                        [-68.2924, 58.5334],
                        [-68.2919, 58.5286],
                        [-68.2982, 58.5262],
                        [-68.3078, 58.5173],
                        [-68.3138, 58.5181],
                        [-68.3232, 58.5164],
                        [-68.3218, 58.5109],
                        [-68.3389, 58.5056],
                        [-68.3343, 58.4997],
                        [-68.344, 58.4961],
                        [-68.3542, 58.4781],
                        [-68.3311, 58.4887],
                        [-68.3187, 58.5063],
                        [-68.3193, 58.513],
                        [-68.3135, 58.5171],
                        [-68.3068, 58.5163],
                        [-68.3019, 58.5181],
                        [-68.2991, 58.5238],
                        [-68.2707, 58.5271],
                        [-68.2654, 58.5326],
                        [-68.2473, 58.528],
                        [-68.2291, 58.5384],
                        [-68.2263, 58.5439],
                        [-68.2134, 58.5469],
                        [-68.2091, 58.5548],
                        [-68.1974, 58.557],
                        [-68.1868, 58.5517],
                        [-68.1255, 58.5301],
                        [-68.1172, 58.5298],
                        [-68.1127, 58.522],
                        [-68.1042, 58.525],
                        [-68.0822, 58.5241],
                        [-68.0759, 58.5154],
                        [-68.0643, 58.5063],
                        [-68.0264, 58.5187],
                        [-68.0237, 58.5424],
                        [-68.0159, 58.5477],
                        [-68.0231, 58.5528],
                        [-68.0153, 58.5586],
                        [-68.0162, 58.5653],
                        [-68.0102, 58.57],
                        [-68.0043, 58.5832],
                        [-67.994, 58.5756],
                        [-67.982, 58.5745],
                        [-67.9676, 58.5674],
                        [-67.9616, 58.5618],
                        [-67.9646, 58.5464],
                        [-67.9515, 58.5367],
                        [-67.9665, 58.5318],
                        [-67.9754, 58.5245],
                        [-67.972, 58.5149],
                        [-67.9572, 58.5171],
                        [-67.9273, 58.5105],
                        [-67.8039, 58.4715],
                        [-67.7828, 58.4722],
                        [-67.7424, 58.4632],
                        [-67.7365, 58.4546],
                        [-67.7263, 58.451],
                        [-67.7148, 58.4513],
                        [-67.7176, 58.4323],
                        [-67.7028, 58.4331],
                        [-67.6895, 58.4288],
                        [-67.6832, 58.4201],
                        [-67.6939, 58.4169],
                        [-67.7071, 58.4067],
                        [-67.7054, 58.401],
                        [-67.7105, 58.3934],
                        [-67.7224, 58.3962],
                        [-67.7221, 58.3879],
                        [-67.7137, 58.3824],
                        [-67.7185, 58.3728],
                        [-67.7245, 58.3712],
                        [-67.7269, 58.3573],
                        [-67.7099, 58.3563],
                        [-67.7062, 58.345],
                        [-67.7201, 58.3432],
                        [-67.7262, 58.3324],
                        [-67.744, 58.3252],
                        [-67.7462, 58.3163],
                        [-67.7418, 58.3062],
                        [-67.7225, 58.2972],
                        [-67.7142, 58.2854],
                        [-67.7283, 58.2797],
                        [-67.7304, 58.2657],
                        [-67.701, 58.2768],
                        [-67.7026, 58.2685],
                        [-67.6783, 58.2702],
                        [-67.6588, 58.2673],
                        [-67.6426, 58.2587],
                        [-67.5267, 58.254],
                        [-67.5184, 58.2632],
                        [-67.4743, 58.2841],
                        [-67.4587, 58.2895],
                        [-67.4293, 58.2835],
                        [-67.428, 58.2782],
                        [-67.4082, 58.2701],
                        [-67.3938, 58.2691],
                        [-67.3793, 58.2731],
                        [-67.3686, 58.2832],
                        [-67.3604, 58.2852],
                        [-67.35, 58.2929],
                        [-67.3515, 58.3002],
                        [-67.3452, 58.3032],
                        [-67.344, 58.313],
                        [-67.3265, 58.3231],
                        [-67.3092, 58.3223],
                        [-67.2814, 58.3311],
                        [-67.2794, 58.3359],
                        [-67.2638, 58.3432],
                        [-67.2537, 58.3356],
                        [-67.2534, 58.3298],
                        [-67.2364, 58.3301],
                        [-67.2367, 58.3398],
                        [-67.2192, 58.3384],
                        [-67.2054, 58.3531],
                        [-67.2064, 58.3577],
                        [-67.1991, 58.3641],
                        [-67.178, 58.3714],
                        [-67.1841, 58.3787],
                        [-67.1871, 58.3894],
                        [-67.1541, 58.3756],
                        [-67.1385, 58.3778],
                        [-67.1247, 58.3688],
                        [-67.1099, 58.3628],
                        [-67.0895, 58.3647],
                        [-67.0803, 58.3757],
                        [-67.0658, 58.3801],
                        [-67.061, 58.3913],
                        [-67.0667, 58.3928],
                        [-67.0692, 58.4025],
                        [-67.0833, 58.4142],
                        [-67.08, 58.4205],
                        [-67.0396, 58.4155],
                        [-67.0188, 58.4222],
                        [-67.0061, 58.4373],
                        [-67.0113, 58.4412],
                        [-67.0026, 58.4536],
                        [-66.994, 58.4616],
                        [-66.9837, 58.4652],
                        [-66.9726, 58.4785],
                        [-66.9605, 58.4792],
                        [-66.953, 58.4872],
                        [-66.9609, 58.4969],
                        [-66.9297, 58.495],
                        [-66.9415, 58.507],
                        [-66.9313, 58.512],
                        [-66.9136, 58.4977],
                        [-66.9044, 58.5],
                        [-66.8989, 58.4855],
                        [-66.8851, 58.4874],
                        [-66.8703, 58.4752],
                        [-66.8977, 58.465],
                        [-66.9022, 58.4584],
                        [-66.8968, 58.449],
                        [-66.8892, 58.4429],
                        [-66.8635, 58.4347],
                        [-66.8525, 58.4281],
                        [-66.8442, 58.4483],
                        [-66.8517, 58.4536],
                        [-66.8403, 58.4625],
                        [-66.8352, 58.4561],
                        [-66.8161, 58.4489],
                        [-66.7902, 58.4755],
                        [-66.8111, 58.4821],
                        [-66.7999, 58.4841],
                        [-66.8021, 58.4969],
                        [-66.7925, 58.4949],
                        [-66.7661, 58.4954],
                        [-66.7495, 58.5016],
                        [-66.7473, 58.5066],
                        [-66.732, 58.5099],
                        [-66.7315, 58.5022],
                        [-66.7106, 58.499],
                        [-66.7003, 58.4939],
                        [-66.7, 58.4896],
                        [-66.6807, 58.4816],
                        [-66.6599, 58.4916],
                        [-66.6444, 58.4905],
                        [-66.6296, 58.4829],
                        [-66.6229, 58.473],
                        [-66.6096, 58.4731],
                        [-66.6014, 58.4701],
                        [-66.599, 58.4616],
                        [-66.5901, 58.4607],
                        [-66.5805, 58.4543],
                        [-66.5692, 58.4547],
                        [-66.5328, 58.4443],
                        [-66.5263, 58.4537],
                        [-66.5391, 58.4596],
                        [-66.5459, 58.4712],
                        [-66.5553, 58.4749],
                        [-66.5608, 58.4675],
                        [-66.5804, 58.4644],
                        [-66.5857, 58.4703],
                        [-66.6006, 58.4711],
                        [-66.6098, 58.4754],
                        [-66.621, 58.4764],
                        [-66.6224, 58.5004],
                        [-66.6319, 58.5105],
                        [-66.6492, 58.5172],
                        [-66.6524, 58.5319],
                        [-66.6596, 58.5367],
                        [-66.6609, 58.5461],
                        [-66.6512, 58.5456],
                        [-66.6396, 58.5565],
                        [-66.6272, 58.5512],
                        [-66.6243, 58.5447],
                        [-66.6053, 58.5477],
                        [-66.6107, 58.5538],
                        [-66.6078, 58.5621],
                        [-66.6185, 58.5687],
                        [-66.6214, 58.5799],
                        [-66.6145, 58.5916],
                        [-66.6177, 58.5986],
                        [-66.6098, 58.6222],
                        [-66.5876, 58.6176],
                        [-66.5848, 58.625],
                        [-66.5615, 58.6303],
                        [-66.5715, 58.6409],
                        [-66.5656, 58.6498],
                        [-66.5785, 58.656],
                        [-66.5461, 58.6707],
                        [-66.5494, 58.6809],
                        [-66.5441, 58.6831],
                        [-66.5423, 58.6939],
                        [-66.5321, 58.6938],
                        [-66.5294, 58.7058],
                        [-66.5425, 58.7164],
                        [-66.5237, 58.7201],
                        [-66.5198, 58.711],
                        [-66.5042, 58.713],
                        [-66.4935, 58.7206],
                        [-66.4818, 58.7198],
                        [-66.4687, 58.7121],
                        [-66.4548, 58.7158],
                        [-66.4457, 58.7216],
                        [-66.4506, 58.7303],
                        [-66.4391, 58.7341],
                        [-66.4371, 58.7411],
                        [-66.452, 58.7442],
                        [-66.4496, 58.7494],
                        [-66.459, 58.7521],
                        [-66.4692, 58.7676],
                        [-66.4671, 58.7748],
                        [-66.4543, 58.7734],
                        [-66.4476, 58.7778],
                        [-66.455, 58.7869],
                        [-66.4468, 58.7967],
                        [-66.4512, 58.8046],
                        [-66.4455, 58.8109],
                        [-66.4593, 58.8189],
                        [-66.4467, 58.8287],
                        [-66.4181, 58.8243],
                        [-66.4146, 58.8339],
                        [-66.404, 58.8352],
                        [-66.3869, 58.8466],
                        [-66.3744, 58.8468],
                        [-66.3734, 58.853],
                        [-66.355, 58.8527],
                        [-66.3406, 58.847],
                        [-66.3347, 58.8404],
                        [-66.3357, 58.8302],
                        [-66.3548, 58.8207],
                        [-66.3468, 58.8118],
                        [-66.3222, 58.8127],
                        [-66.307, 58.8224],
                        [-66.2921, 58.8187],
                        [-66.2785, 58.8087],
                        [-66.2666, 58.8083],
                        [-66.2642, 58.7966],
                        [-66.2492, 58.8032],
                        [-66.2296, 58.7935],
                        [-66.2104, 58.7752],
                        [-66.1905, 58.7632],
                        [-66.1643, 58.7428],
                        [-66.1661, 58.7337],
                        [-66.1571, 58.7275],
                        [-66.1419, 58.7412],
                        [-66.1168, 58.7277],
                        [-66.102, 58.7181],
                        [-66.1123, 58.7147],
                        [-66.1118, 58.6989],
                        [-66.1045, 58.6912],
                        [-66.0816, 58.6803],
                        [-66.03, 58.7044],
                        [-66.0383, 58.7082],
                        [-66.0407, 58.7142],
                        [-66.0227, 58.7133],
                        [-66.0209, 58.7187],
                        [-66.0567, 58.7347],
                        [-66.0517, 58.7394],
                        [-66.0377, 58.7327],
                        [-66.023, 58.7344],
                        [-66.0319, 58.7443],
                        [-66.0331, 58.7639],
                        [-66.0668, 58.7748],
                        [-66.0733, 58.7635],
                        [-66.0936, 58.7697],
                        [-66.1027, 58.7756],
                        [-66.0942, 58.7794],
                        [-66.0981, 58.7854],
                        [-66.0871, 58.7879],
                        [-66.0769, 58.8012],
                        [-66.0842, 58.8068],
                        [-66.0841, 58.8174],
                        [-66.0652, 58.8168],
                        [-66.0611, 58.8261],
                        [-66.069, 58.8344],
                        [-66.0539, 58.8383],
                        [-66.0382, 58.8349],
                        [-66.0269, 58.8429],
                        [-66.0245, 58.8506],
                        [-66.0285, 58.8576],
                        [-66.0122, 58.8581],
                        [-66.0068, 58.8628],
                        [-65.9926, 58.8654],
                        [-65.9745, 58.8593],
                        [-65.9752, 58.8497],
                        [-65.9596, 58.8254],
                        [-65.9429, 58.8268],
                        [-65.9382, 58.8388],
                        [-65.9299, 58.8446],
                        [-65.9163, 58.8375],
                        [-65.8889, 58.8339],
                        [-65.8747, 58.8273],
                        [-65.8689, 58.84],
                        [-65.8505, 58.8244],
                        [-65.8502, 58.8176],
                        [-65.8318, 58.805],
                        [-65.8303, 58.7897],
                        [-65.8165, 58.7936],
                        [-65.817, 58.8111],
                        [-65.8253, 58.8142],
                        [-65.8338, 58.8231],
                        [-65.834, 58.8339],
                        [-65.8184, 58.8385],
                        [-65.8124, 58.8476],
                        [-65.8191, 58.8542],
                        [-65.8112, 58.8584],
                        [-65.8003, 58.8575],
                        [-65.792, 58.8493],
                        [-65.7964, 58.8432],
                        [-65.7877, 58.834],
                        [-65.779, 58.8364],
                        [-65.7646, 58.8319],
                        [-65.7583, 58.8231],
                        [-65.7497, 58.8229],
                        [-65.746, 58.8163],
                        [-65.754, 58.8091],
                        [-65.7465, 58.8065],
                        [-65.7287, 58.8058],
                        [-65.7159, 58.813],
                        [-65.7, 58.8144],
                        [-65.6996, 58.8064],
                        [-65.6856, 58.7996],
                        [-65.6647, 58.7937],
                        [-65.6632, 58.7846],
                        [-65.6571, 58.7781],
                        [-65.6443, 58.7721],
                        [-65.6097, 58.7701],
                        [-65.5986, 58.7598],
                        [-65.6032, 58.7485],
                        [-65.596, 58.7376],
                        [-65.5794, 58.7299],
                        [-65.5646, 58.7259],
                        [-65.5232, 58.7204],
                        [-65.5151, 58.7166],
                        [-65.5082, 58.7036],
                        [-65.4876, 58.6998],
                        [-65.4534, 58.689],
                        [-65.4225, 58.6664],
                        [-65.4147, 58.656],
                        [-65.4165, 58.6491],
                        [-65.4057, 58.6417],
                        [-65.388, 58.6402],
                        [-65.3661, 58.6404],
                        [-65.3475, 58.6441],
                        [-65.3374, 58.6503],
                        [-65.3215, 58.651],
                        [-65.3104, 58.6464],
                        [-65.2875, 58.6442],
                        [-65.2772, 58.6457],
                        [-65.259, 58.6403],
                        [-65.2546, 58.6307],
                        [-65.2435, 58.6292],
                        [-65.2398, 58.6207],
                        [-65.2306, 58.6175],
                        [-65.2056, 58.623],
                        [-65.1885, 58.6222],
                        [-65.1795, 58.6155],
                        [-65.1673, 58.6181],
                        [-65.147, 58.6114],
                        [-65.1361, 58.6122],
                        [-65.1341, 58.606],
                        [-65.1257, 58.6012],
                        [-65.1034, 58.5963],
                        [-65.0646, 58.5945],
                        [-65.0449, 58.6006],
                        [-65.0078, 58.6008],
                        [-64.9888, 58.5929],
                        [-64.9667, 58.5929],
                        [-64.9406, 58.5884],
                        [-64.9343, 58.5848],
                        [-64.9221, 58.5885],
                        [-64.8984, 58.6009],
                        [-64.8833, 58.6005],
                        [-64.8748, 58.5975],
                        [-64.8558, 58.5978],
                        [-64.8416, 58.5938],
                        [-64.8209, 58.5955],
                        [-64.8062, 58.6032],
                        [-64.7846, 58.6039],
                        [-64.7691, 58.6071],
                        [-64.7394, 58.6021],
                        [-64.7206, 58.6073],
                        [-64.7006, 58.6048],
                        [-64.6544, 58.6102],
                        [-64.6403, 58.6096],
                        [-64.6323, 58.6095],
                        [-64.6249, 58.6075],
                        [-64.5964, 58.6061],
                        [-64.5782, 58.6077],
                        [-64.5669, 58.612],
                        [-64.5537, 58.6101],
                        [-64.5455, 58.6132],
                        [-64.5369, 58.6088],
                        [-64.4825, 58.6079],
                        [-64.4615, 58.6117],
                        [-64.4554, 58.6195],
                        [-64.4437, 58.6165],
                        [-64.4363, 58.6083],
                        [-64.4229, 58.6014],
                        [-64.4081, 58.5969],
                        [-64.3958, 58.5997],
                        [-64.4082, 58.6047],
                        [-64.4341, 58.6089],
                        [-64.4347, 58.6129],
                        [-64.4486, 58.6192],
                        [-64.4583, 58.6203],
                        [-64.4633, 58.6125],
                        [-64.481, 58.6091],
                        [-64.53, 58.6094],
                        [-64.5513, 58.6148],
                        [-64.5834, 58.6111],
                        [-64.5945, 58.6076],
                        [-64.6208, 58.6112],
                        [-64.6313, 58.6111],
                        [-64.6391, 58.6109],
                        [-64.644, 58.6098],
                        [-64.6525, 58.6117],
                        [-64.6589, 58.6145],
                        [-64.6649, 58.6152],
                        [-64.6698, 58.6103],
                        [-64.6963, 58.6068],
                        [-64.7172, 58.6086],
                        [-64.7362, 58.6065],
                        [-64.749, 58.6095],
                        [-64.7846, 58.6069],
                        [-64.793, 58.605],
                        [-64.8172, 58.6051],
                        [-64.8224, 58.6017],
                        [-64.8488, 58.6],
                        [-64.9008, 58.6018],
                        [-64.9282, 58.5875],
                        [-64.9463, 58.5918],
                        [-64.9667, 58.5943],
                        [-64.9883, 58.5948],
                        [-65.0049, 58.6019],
                        [-65.0315, 58.6048],
                        [-65.0401, 58.6043],
                        [-65.0657, 58.5964],
                        [-65.1072, 58.5987],
                        [-65.1283, 58.603],
                        [-65.1345, 58.6151],
                        [-65.1509, 58.6191],
                        [-65.1694, 58.6205],
                        [-65.1774, 58.6169],
                        [-65.1899, 58.6237],
                        [-65.2114, 58.6253],
                        [-65.2183, 58.6218],
                        [-65.2352, 58.6213],
                        [-65.2438, 58.6303],
                        [-65.2536, 58.6329],
                        [-65.2556, 58.6404],
                        [-65.274, 58.6475],
                        [-65.2887, 58.6466],
                        [-65.3126, 58.6488],
                        [-65.3191, 58.6525],
                        [-65.3348, 58.6538],
                        [-65.3465, 58.6473],
                        [-65.3659, 58.6419],
                        [-65.3864, 58.6418],
                        [-65.4023, 58.6441],
                        [-65.4114, 58.6492],
                        [-65.4104, 58.6537],
                        [-65.4169, 58.6665],
                        [-65.4394, 58.6809],
                        [-65.4436, 58.6875],
                        [-65.4756, 58.6989],
                        [-65.4898, 58.7072],
                        [-65.4927, 58.7154],
                        [-65.4997, 58.7208],
                        [-65.516, 58.7272],
                        [-65.5462, 58.734],
                        [-65.5658, 58.7363],
                        [-65.583, 58.7409],
                        [-65.5868, 58.7464],
                        [-65.5829, 58.7534],
                        [-65.5851, 58.7594],
                        [-65.5824, 58.7695],
                        [-65.5886, 58.7754],
                        [-65.6026, 58.7785],
                        [-65.5965, 58.7881],
                        [-65.605, 58.7944],
                        [-65.6175, 58.79],
                        [-65.6123, 58.78],
                        [-65.6314, 58.7803],
                        [-65.64, 58.7856],
                        [-65.6438, 58.7988],
                        [-65.6629, 58.8093],
                        [-65.6741, 58.8099],
                        [-65.7014, 58.8184],
                        [-65.7223, 58.8146],
                        [-65.7357, 58.8166],
                        [-65.7433, 58.8264],
                        [-65.7548, 58.8295],
                        [-65.7607, 58.8348],
                        [-65.7744, 58.8385],
                        [-65.7818, 58.8456],
                        [-65.7784, 58.8685],
                        [-65.789, 58.8708],
                        [-65.807, 58.8683],
                        [-65.8171, 58.8729],
                        [-65.8621, 58.8705],
                        [-65.8947, 58.8819],
                        [-65.9088, 58.8854],
                        [-65.9128, 58.8748],
                        [-65.926, 58.8751],
                        [-65.9346, 58.8843],
                        [-65.9488, 58.8868],
                        [-65.9652, 58.8935],
                        [-65.9692, 58.9008],
                        [-65.997, 58.9069],
                        [-65.996, 58.9126],
                        [-65.9783, 58.9192],
                        [-65.9627, 58.9184],
                        [-65.9479, 58.9203],
                        [-65.9327, 58.927],
                        [-65.9229, 58.9258],
                        [-65.9037, 58.9318],
                        [-65.8829, 58.9502],
                        [-65.8732, 58.9462],
                        [-65.8565, 58.9454],
                        [-65.8449, 58.9533],
                        [-65.8293, 58.9545],
                        [-65.8248, 58.9599],
                        [-65.8568, 58.9888],
                        [-65.8247, 58.9853],
                        [-65.8112, 58.9898],
                        [-65.7932, 58.9855],
                        [-65.7707, 58.9839],
                        [-65.7614, 58.9784],
                        [-65.7403, 58.9722],
                        [-65.7093, 58.9557],
                        [-65.6769, 58.9465],
                        [-65.6691, 58.937],
                        [-65.6609, 58.9351],
                        [-65.6523, 58.9432],
                        [-65.6526, 58.9611],
                        [-65.6682, 58.9655],
                        [-65.6599, 58.9746],
                        [-65.6732, 58.9762],
                        [-65.6793, 58.9837],
                        [-65.6945, 58.9856],
                        [-65.7113, 58.9904],
                        [-65.7276, 58.9882],
                        [-65.7642, 58.9993],
                        [-65.7801, 59.0197],
                        [-65.7515, 59.0187],
                        [-65.7402, 59.0209],
                        [-65.7266, 59.02],
                        [-65.7024, 59.0246],
                        [-65.6935, 59.0226],
                        [-65.6866, 59.0282],
                        [-65.6739, 59.0295],
                        [-65.6754, 59.0358],
                        [-65.7096, 59.0308],
                        [-65.7412, 59.0299],
                        [-65.7703, 59.0351],
                        [-65.7694, 59.0458],
                        [-65.7387, 59.0391],
                        [-65.7082, 59.0454],
                        [-65.6916, 59.0537],
                        [-65.6849, 59.0608],
                        [-65.6607, 59.0582],
                        [-65.6227, 59.045],
                        [-65.6117, 59.0542],
                        [-65.5857, 59.0487],
                        [-65.5907, 59.0576],
                        [-65.5859, 59.0602],
                        [-65.5959, 59.0705],
                        [-65.6288, 59.0714],
                        [-65.637, 59.074],
                        [-65.6451, 59.0826],
                        [-65.6397, 59.0843],
                        [-65.6523, 59.0963],
                        [-65.6444, 59.1005],
                        [-65.6501, 59.1107],
                        [-65.6751, 59.1298],
                        [-65.6884, 59.1422],
                        [-65.7017, 59.1482],
                        [-65.709, 59.1557],
                        [-65.7185, 59.1566],
                        [-65.7298, 59.1682],
                        [-65.7274, 59.172],
                        [-65.7372, 59.1792],
                        [-65.7371, 59.1858],
                        [-65.7274, 59.1866],
                        [-65.7241, 59.1731],
                        [-65.7108, 59.1726],
                        [-65.7023, 59.1828],
                        [-65.7071, 59.1906],
                        [-65.6951, 59.2006],
                        [-65.6871, 59.1938],
                        [-65.6535, 59.1983],
                        [-65.6637, 59.202],
                        [-65.6788, 59.2007],
                        [-65.7351, 59.2125],
                        [-65.7265, 59.2256],
                        [-65.7319, 59.2308],
                        [-65.7487, 59.2324],
                        [-65.75, 59.2363],
                        [-65.709, 59.2373],
                        [-65.7111, 59.2443],
                        [-65.7245, 59.2468],
                        [-65.7333, 59.2535],
                        [-65.7344, 59.2625],
                        [-65.7225, 59.2696],
                        [-65.6987, 59.2672],
                        [-65.6985, 59.2757],
                        [-65.6726, 59.2678],
                        [-65.6562, 59.2651],
                        [-65.6426, 59.2605],
                        [-65.6157, 59.2377],
                        [-65.6018, 59.2058],
                        [-65.5977, 59.181],
                        [-65.5786, 59.1639],
                        [-65.5773, 59.1599],
                        [-65.5565, 59.1484],
                        [-65.5434, 59.1541],
                        [-65.546, 59.1647],
                        [-65.5573, 59.1702],
                        [-65.5738, 59.1863],
                        [-65.5902, 59.1942],
                        [-65.5763, 59.206],
                        [-65.5875, 59.2118],
                        [-65.586, 59.2185],
                        [-65.5913, 59.2255],
                        [-65.5909, 59.2356],
                        [-65.5855, 59.245],
                        [-65.5947, 59.2522],
                        [-65.5975, 59.26],
                        [-65.5814, 59.2588],
                        [-65.5585, 59.2405],
                        [-65.5516, 59.2309],
                        [-65.5435, 59.2131],
                        [-65.5416, 59.2022],
                        [-65.5262, 59.1903],
                        [-65.5143, 59.1677],
                        [-65.5051, 59.1588],
                        [-65.5045, 59.1466],
                        [-65.4905, 59.1411],
                        [-65.4822, 59.1443],
                        [-65.4936, 59.1534],
                        [-65.4845, 59.1656],
                        [-65.4919, 59.1711],
                        [-65.4882, 59.1753],
                        [-65.508, 59.1946],
                        [-65.5166, 59.1979],
                        [-65.5245, 59.216],
                        [-65.5334, 59.2297],
                        [-65.55, 59.2432],
                        [-65.5552, 59.2516],
                        [-65.5458, 59.2598],
                        [-65.5305, 59.2685],
                        [-65.5143, 59.2593],
                        [-65.5102, 59.2643],
                        [-65.4886, 59.2546],
                        [-65.4774, 59.2575],
                        [-65.4759, 59.2698],
                        [-65.4883, 59.2748],
                        [-65.4955, 59.2723],
                        [-65.5191, 59.2712],
                        [-65.5283, 59.2796],
                        [-65.5134, 59.2803],
                        [-65.5054, 59.2905],
                        [-65.5421, 59.3002],
                        [-65.5564, 59.2993],
                        [-65.5561, 59.3042],
                        [-65.5432, 59.3054],
                        [-65.5448, 59.3104],
                        [-65.5591, 59.316],
                        [-65.5537, 59.3225],
                        [-65.564, 59.3302],
                        [-65.5692, 59.3402],
                        [-65.5396, 59.345],
                        [-65.5636, 59.364],
                        [-65.5734, 59.374],
                        [-65.568, 59.3768],
                        [-65.5604, 59.3741],
                        [-65.5671, 59.3832],
                        [-65.5524, 59.3858],
                        [-65.5434, 59.3826],
                        [-65.5322, 59.3682],
                        [-65.5063, 59.3612],
                        [-65.5164, 59.3717],
                        [-65.4938, 59.368],
                        [-65.4769, 59.3572],
                        [-65.4616, 59.3432],
                        [-65.4705, 59.3366],
                        [-65.4479, 59.329],
                        [-65.4266, 59.3136],
                        [-65.4149, 59.312],
                        [-65.3837, 59.2916],
                        [-65.3749, 59.2816],
                        [-65.36, 59.2706],
                        [-65.3437, 59.2617],
                        [-65.3295, 59.2486],
                        [-65.3166, 59.2435],
                        [-65.3101, 59.2484],
                        [-65.3237, 59.2552],
                        [-65.3351, 59.2707],
                        [-65.3335, 59.2769],
                        [-65.325, 59.279],
                        [-65.3056, 59.2743],
                        [-65.2946, 59.2751],
                        [-65.2965, 59.2832],
                        [-65.3187, 59.2876],
                        [-65.3436, 59.2809],
                        [-65.3512, 59.2839],
                        [-65.3698, 59.3058],
                        [-65.3836, 59.3124],
                        [-65.3967, 59.3324],
                        [-65.3933, 59.3395],
                        [-65.4138, 59.3385],
                        [-65.4252, 59.3453],
                        [-65.4437, 59.3434],
                        [-65.4508, 59.349],
                        [-65.4333, 59.3637],
                        [-65.4373, 59.3795],
                        [-65.4256, 59.378],
                        [-65.421, 59.3867],
                        [-65.4132, 59.3886],
                        [-65.4358, 59.4036],
                        [-65.4219, 59.4077],
                        [-65.4074, 59.4014],
                        [-65.3879, 59.4063],
                        [-65.3709, 59.4005],
                        [-65.3716, 59.4104],
                        [-65.3885, 59.4167],
                        [-65.3974, 59.4156],
                        [-65.4312, 59.4216],
                        [-65.4429, 59.4192],
                        [-65.4651, 59.4212],
                        [-65.4871, 59.4268],
                        [-65.5011, 59.435],
                        [-65.4834, 59.4356],
                        [-65.4956, 59.4448],
                        [-65.4876, 59.4475],
                        [-65.4652, 59.4375],
                        [-65.4459, 59.4322],
                        [-65.4426, 59.4389],
                        [-65.4599, 59.4503],
                        [-65.4645, 59.4558],
                        [-65.4838, 59.4648],
                        [-65.4922, 59.4621],
                        [-65.5124, 59.463],
                        [-65.5061, 59.4717],
                        [-65.5225, 59.4765],
                        [-65.5112, 59.4835],
                        [-65.5061, 59.4762],
                        [-65.4691, 59.4747],
                        [-65.441, 59.4773],
                        [-65.4189, 59.4669],
                        [-65.4186, 59.4573],
                        [-65.4037, 59.4547],
                        [-65.398, 59.4615],
                        [-65.4146, 59.4679],
                        [-65.4189, 59.4734],
                        [-65.4027, 59.4781],
                        [-65.3918, 59.4743],
                        [-65.3882, 59.481],
                        [-65.3948, 59.4914],
                        [-65.3877, 59.4997],
                        [-65.3959, 59.5051],
                        [-65.4049, 59.5156],
                        [-65.4011, 59.5204],
                        [-65.4217, 59.5402],
                        [-65.4344, 59.5573],
                        [-65.44, 59.5679],
                        [-65.4284, 59.5756],
                        [-65.4289, 59.5851],
                        [-65.4439, 59.5918],
                        [-65.4558, 59.6032],
                        [-65.4598, 59.6165],
                        [-65.4694, 59.6235],
                        [-65.4684, 59.6307],
                        [-65.4805, 59.6392],
                        [-65.483, 59.6484],
                        [-65.4969, 59.6509],
                        [-65.5014, 59.6585],
                        [-65.4852, 59.6608],
                        [-65.4905, 59.6653],
                        [-65.4764, 59.6703],
                        [-65.4675, 59.6606],
                        [-65.4539, 59.6501],
                        [-65.4515, 59.6395],
                        [-65.4373, 59.6394],
                        [-65.4462, 59.6494],
                        [-65.4584, 59.656],
                        [-65.4505, 59.6631],
                        [-65.4653, 59.6709],
                        [-65.4799, 59.6752],
                        [-65.4816, 59.6798],
                        [-65.5116, 59.6779],
                        [-65.524, 59.694],
                        [-65.5318, 59.7009],
                        [-65.5234, 59.7139],
                        [-65.5248, 59.7246],
                        [-65.5365, 59.746],
                        [-65.5246, 59.7437],
                        [-65.5029, 59.7483],
                        [-65.489, 59.7545],
                        [-65.4566, 59.7542],
                        [-65.4614, 59.7641],
                        [-65.456, 59.7691],
                        [-65.4668, 59.7741],
                        [-65.4637, 59.7809],
                        [-65.4743, 59.7838],
                        [-65.4827, 59.7914],
                        [-65.4645, 59.7972],
                        [-65.4489, 59.7862],
                        [-65.4396, 59.7898],
                        [-65.4222, 59.7917],
                        [-65.4263, 59.7962],
                        [-65.4215, 59.8097],
                        [-65.3924, 59.8038],
                        [-65.3907, 59.7985],
                        [-65.3679, 59.8047],
                        [-65.3594, 59.8004],
                        [-65.352, 59.8074],
                        [-65.3724, 59.8118],
                        [-65.3794, 59.8076],
                        [-65.3876, 59.8158],
                        [-65.3857, 59.8239],
                        [-65.3731, 59.823],
                        [-65.36, 59.8339],
                        [-65.3308, 59.8427],
                        [-65.3398, 59.8562],
                        [-65.3261, 59.8604],
                        [-65.3097, 59.8524],
                        [-65.2895, 59.8355],
                        [-65.2766, 59.8314],
                        [-65.2607, 59.8358],
                        [-65.2283, 59.8351],
                        [-65.2243, 59.8513],
                        [-65.2389, 59.8571],
                        [-65.2539, 59.8667],
                        [-65.2589, 59.8807],
                        [-65.2368, 59.872],
                        [-65.2379, 59.8692],
                        [-65.2205, 59.8586],
                        [-65.2095, 59.8622],
                        [-65.2143, 59.8711],
                        [-65.2328, 59.8804],
                        [-65.2333, 59.887],
                        [-65.2139, 59.8913],
                        [-65.1808, 59.8842],
                        [-65.1689, 59.8789],
                        [-65.1658, 59.8701],
                        [-65.133, 59.8754],
                        [-65.1339, 59.8822],
                        [-65.1546, 59.8846],
                        [-65.1507, 59.8925],
                        [-65.1694, 59.8952],
                        [-65.1746, 59.9039],
                        [-65.1922, 59.902],
                        [-65.2062, 59.907],
                        [-65.2076, 59.9131],
                        [-65.2275, 59.9141],
                        [-65.2327, 59.9221],
                        [-65.2306, 59.9282],
                        [-65.2001, 59.9272],
                        [-65.1774, 59.9236],
                        [-65.1608, 59.925],
                        [-65.1691, 59.9334],
                        [-65.1566, 59.9365],
                        [-65.1452, 59.9502],
                        [-65.1535, 59.9559],
                        [-65.1472, 59.9634],
                        [-65.1301, 59.9594],
                        [-65.1037, 59.9435],
                        [-65.0818, 59.9348],
                        [-65.0546, 59.9221],
                        [-65.0334, 59.9087],
                        [-65.0289, 59.8987],
                        [-65.0212, 59.8926],
                        [-65.0018, 59.8964],
                        [-65.0211, 59.9027],
                        [-65.0236, 59.9097],
                        [-65.0159, 59.915],
                        [-65.0553, 59.9326],
                        [-65.0885, 59.9425],
                        [-65.0921, 59.9484],
                        [-65.0806, 59.9527],
                        [-65.0863, 59.9594],
                        [-65.097, 59.9563],
                        [-65.1122, 59.9625],
                        [-65.1185, 59.9717],
                        [-65.1186, 59.9813],
                        [-65.1307, 59.9841],
                        [-65.1279, 59.9918],
                        [-65.118, 59.9917],
                        [-65.0956, 59.981],
                        [-65.083, 59.9729],
                        [-65.083, 59.969],
                        [-65.0708, 59.9597],
                        [-65.0531, 59.9562],
                        [-65.0481, 59.962],
                        [-65.0655, 59.9652],
                        [-65.0765, 59.9702],
                        [-65.0762, 59.9802],
                        [-65.0908, 59.9883],
                        [-65.0927, 59.9961],
                        [-65.1172, 59.9969],
                        [-65.1312, 60.002],
                        [-65.1293, 60.0106],
                        [-65.1187, 60.018],
                        [-65.1295, 60.0279],
                        [-65.1244, 60.0341],
                        [-65.1373, 60.0419],
                        [-65.1318, 60.0466],
                        [-65.1257, 60.059],
                        [-65.0961, 60.0632],
                        [-65.0868, 60.0542],
                        [-65.0675, 60.044],
                        [-65.0575, 60.0506],
                        [-65.0653, 60.0582],
                        [-65.0558, 60.0593],
                        [-65.0407, 60.0481],
                        [-65.0346, 60.0494],
                        [-65.0459, 60.0598],
                        [-65.0348, 60.0742],
                        [-65.0217, 60.0732],
                        [-65.0199, 60.0778],
                        [-65.0441, 60.0809],
                        [-65.0615, 60.0807],
                        [-65.0717, 60.0915],
                        [-65.0622, 60.1056],
                        [-65.0498, 60.1005],
                        [-65.0439, 60.1057],
                        [-65.0495, 60.1114],
                        [-65.0288, 60.1257],
                        [-65.0112, 60.1177],
                        [-65.002, 60.1075],
                        [-64.9914, 60.1037],
                        [-64.9888, 60.0929],
                        [-64.9698, 60.0871],
                        [-64.9555, 60.0955],
                        [-64.9678, 60.1015],
                        [-64.9849, 60.1147],
                        [-65.0035, 60.1245],
                        [-64.9987, 60.133],
                        [-65.0082, 60.1377],
                        [-64.9969, 60.1441],
                        [-64.9982, 60.1526],
                        [-65.0057, 60.1591],
                        [-64.975, 60.1602],
                        [-64.9762, 60.1515],
                        [-64.9632, 60.1452],
                        [-64.968, 60.1591],
                        [-64.9597, 60.1647],
                        [-64.97, 60.1713],
                        [-64.9642, 60.1867],
                        [-64.9449, 60.1894],
                        [-64.9389, 60.1947],
                        [-64.921, 60.1975],
                        [-64.9138, 60.204],
                        [-64.8972, 60.2008],
                        [-64.8608, 60.1971],
                        [-64.8631, 60.2012],
                        [-64.8921, 60.2043],
                        [-64.9018, 60.2113],
                        [-64.9044, 60.2179],
                        [-64.8908, 60.2188],
                        [-64.877, 60.2105],
                        [-64.8604, 60.2095],
                        [-64.8456, 60.2197],
                        [-64.869, 60.2176],
                        [-64.8798, 60.2196],
                        [-64.8849, 60.2304],
                        [-64.9053, 60.2286],
                        [-64.9235, 60.2197],
                        [-64.9374, 60.2167],
                        [-64.949, 60.2178],
                        [-64.9506, 60.237],
                        [-64.9471, 60.2422],
                        [-64.93, 60.2424],
                        [-64.9285, 60.2476],
                        [-64.9561, 60.2456],
                        [-64.9598, 60.2539],
                        [-64.9519, 60.2596],
                        [-64.9452, 60.2701],
                        [-64.9285, 60.2687],
                        [-64.9157, 60.2804],
                        [-64.9068, 60.2727],
                        [-64.9031, 60.2646],
                        [-64.8919, 60.2662],
                        [-64.8993, 60.274],
                        [-64.8938, 60.2831],
                        [-64.883, 60.2818],
                        [-64.8707, 60.2899],
                        [-64.851, 60.2952],
                        [-64.8464, 60.3135],
                        [-64.8314, 60.3093],
                        [-64.8189, 60.3134],
                        [-64.8155, 60.3201],
                        [-64.8049, 60.3173],
                        [-64.8093, 60.3051],
                        [-64.7977, 60.2895],
                        [-64.7891, 60.2878],
                        [-64.785, 60.3016],
                        [-64.8007, 60.3046],
                        [-64.7977, 60.3157],
                        [-64.7848, 60.3303],
                        [-64.7922, 60.3301],
                        [-64.7977, 60.3206],
                        [-64.8178, 60.3249],
                        [-64.8202, 60.3334],
                        [-64.8304, 60.338],
                        [-64.8383, 60.351],
                        [-64.8449, 60.3522],
                        [-64.8557, 60.3607],
                        [-64.8477, 60.3703],
                        [-64.8255, 60.3693],
                        [-64.8116, 60.3661],
                        [-64.7995, 60.3675],
                        [-64.7834, 60.3606],
                        [-64.7536, 60.3551],
                        [-64.7366, 60.3489],
                        [-64.7307, 60.3411],
                        [-64.7197, 60.3426],
                        [-64.6944, 60.3299],
                        [-64.6786, 60.3285],
                        [-64.6659, 60.3229],
                        [-64.6524, 60.3209],
                        [-64.6371, 60.3107],
                        [-64.6303, 60.3118],
                        [-64.6078, 60.307],
                        [-64.6105, 60.3136],
                        [-64.6335, 60.3202],
                        [-64.6347, 60.326],
                        [-64.6434, 60.3339],
                        [-64.6627, 60.3362],
                        [-64.6675, 60.3335],
                        [-64.691, 60.3356],
                        [-64.698, 60.3387],
                        [-64.7001, 60.3491],
                        [-64.7154, 60.3512],
                        [-64.7244, 60.3569],
                        [-64.6755, 60.3506],
                        [-64.6634, 60.3431],
                        [-64.6532, 60.3424],
                        [-64.6431, 60.3461],
                        [-64.6243, 60.3435],
                        [-64.6159, 60.3391],
                        [-64.5948, 60.3344],
                        [-64.582, 60.3297],
                        [-64.5665, 60.3208],
                        [-64.554, 60.3177],
                        [-64.5462, 60.3116],
                        [-64.5372, 60.3103],
                        [-64.5248, 60.2998],
                        [-64.5297, 60.2967],
                        [-64.5633, 60.3069],
                        [-64.5812, 60.3084],
                        [-64.5973, 60.3006],
                        [-64.6437, 60.2977],
                        [-64.6552, 60.2948],
                        [-64.681, 60.2966],
                        [-64.7074, 60.3033],
                        [-64.7234, 60.2915],
                        [-64.7535, 60.2869],
                        [-64.8073, 60.2758],
                        [-64.8416, 60.2722],
                        [-64.8538, 60.2694],
                        [-64.8623, 60.2585],
                        [-64.8528, 60.2527],
                        [-64.8694, 60.2441],
                        [-64.8734, 60.2373],
                        [-64.8639, 60.2282],
                        [-64.8407, 60.223],
                        [-64.7841, 60.204],
                        [-64.7802, 60.1961],
                        [-64.7499, 60.1882],
                        [-64.7456, 60.1686],
                        [-64.7332, 60.1644],
                        [-64.6989, 60.1713],
                        [-64.6923, 60.1801],
                        [-64.6825, 60.1812],
                        [-64.6616, 60.1702],
                        [-64.6412, 60.1674],
                        [-64.6145, 60.1708],
                        [-64.6025, 60.167],
                        [-64.6025, 60.1558],
                        [-64.5942, 60.1495],
                        [-64.5988, 60.1428],
                        [-64.5937, 60.117],
                        [-64.6034, 60.1097],
                        [-64.6331, 60.1054],
                        [-64.672, 60.1135],
                        [-64.6812, 60.1075],
                        [-64.7055, 60.1076],
                        [-64.7353, 60.1095],
                        [-64.7673, 60.1084],
                        [-64.7575, 60.1014],
                        [-64.7267, 60.0947],
                        [-64.708, 60.0846],
                        [-64.7063, 60.0743],
                        [-64.7179, 60.0641],
                        [-64.7427, 60.0561],
                        [-64.7832, 60.0576],
                        [-64.8041, 60.0524],
                        [-64.8198, 60.0505],
                        [-64.8647, 60.0584],
                        [-64.8959, 60.0662],
                        [-64.9204, 60.0659],
                        [-64.9236, 60.0615],
                        [-64.8877, 60.042],
                        [-64.8707, 60.0169],
                        [-64.861, 59.9952],
                        [-64.8402, 59.9703],
                        [-64.8068, 59.9708],
                        [-64.7828, 59.9804],
                        [-64.7471, 59.9745],
                        [-64.7416, 59.9652],
                        [-64.7474, 59.9547],
                        [-64.7322, 59.9505],
                        [-64.7095, 59.9538],
                        [-64.6931, 59.9589],
                        [-64.6622, 59.9526],
                        [-64.6615, 59.9397],
                        [-64.6694, 59.9301],
                        [-64.6636, 59.9277],
                        [-64.6341, 59.936],
                        [-64.6268, 59.9303],
                        [-64.637, 59.919],
                        [-64.641, 59.9074],
                        [-64.6587, 59.9016],
                        [-64.6664, 59.8883],
                        [-64.6838, 59.8772],
                        [-64.7042, 59.8837],
                        [-64.7415, 59.8893],
                        [-64.7562, 59.886],
                        [-64.7666, 59.8697],
                        [-64.7392, 59.862],
                        [-64.7369, 59.8556],
                        [-64.7781, 59.8528],
                        [-64.797, 59.8408],
                        [-64.795, 59.8341],
                        [-64.8167, 59.835],
                        [-64.8252, 59.8256],
                        [-64.7921, 59.8122],
                        [-64.7885, 59.8033],
                        [-64.814, 59.7952],
                        [-64.814, 59.783],
                        [-64.79, 59.7603],
                        [-64.7972, 59.7385],
                        [-64.8102, 59.7297],
                        [-64.8015, 59.7248],
                        [-64.7743, 59.7251],
                        [-64.7494, 59.7172],
                        [-64.7605, 59.7066],
                        [-64.7876, 59.6926],
                        [-64.8249, 59.676],
                        [-64.8717, 59.6683],
                        [-64.8895, 59.6622],
                        [-64.8983, 59.6418],
                        [-64.8825, 59.6107],
                        [-64.8598, 59.5915],
                        [-64.8687, 59.5864],
                        [-64.9271, 59.5932],
                        [-64.9394, 59.567],
                        [-64.8922, 59.5625],
                        [-64.8692, 59.5469],
                        [-64.8396, 59.5533],
                        [-64.8228, 59.5407],
                        [-64.8031, 59.5343],
                        [-64.8042, 59.525],
                        [-64.7641, 59.5149],
                        [-64.7631, 59.4966],
                        [-64.7539, 59.4883],
                        [-64.7369, 59.4807],
                        [-64.7286, 59.4666],
                        [-64.7173, 59.4615],
                        [-64.6944, 59.458],
                        [-64.6798, 59.4469],
                        [-64.6428, 59.4592],
                        [-64.6361, 59.4772],
                        [-64.6268, 59.482],
                        [-64.5897, 59.4662],
                        [-64.5788, 59.471],
                        [-64.5615, 59.4879],
                        [-64.5322, 59.4995],
                        [-64.5053, 59.5013],
                        [-64.5094, 59.5136],
                        [-64.5031, 59.5214],
                        [-64.4629, 59.5234],
                        [-64.4338, 59.542],
                        [-64.419, 59.5326],
                        [-64.3858, 59.5238],
                        [-64.3241, 59.5128],
                        [-64.3279, 59.4975],
                        [-64.3497, 59.503],
                        [-64.3694, 59.4884],
                        [-64.3808, 59.4838],
                        [-64.387, 59.4738],
                        [-64.4072, 59.4778],
                        [-64.4261, 59.4649],
                        [-64.458, 59.4592],
                        [-64.4668, 59.4502],
                        [-64.5116, 59.423],
                        [-64.4726, 59.4183],
                        [-64.4607, 59.4122],
                        [-64.496, 59.4043],
                        [-64.5165, 59.4039],
                        [-64.5283, 59.3956],
                        [-64.5241, 59.3851],
                        [-64.5383, 59.3708],
                        [-64.5257, 59.3616],
                        [-64.4933, 59.3496],
                        [-64.4944, 59.3443],
                        [-64.5114, 59.3351],
                        [-64.5278, 59.32],
                        [-64.5426, 59.3187],
                        [-64.5435, 59.3104],
                        [-64.517, 59.2799],
                        [-64.4886, 59.2571],
                        [-64.5114, 59.2389],
                        [-64.5218, 59.1967],
                        [-64.5034, 59.1957],
                        [-64.4727, 59.1804],
                        [-64.4867, 59.1528],
                        [-64.4913, 59.1364],
                        [-64.484, 59.1182],
                        [-64.498, 59.1064],
                        [-64.4903, 59.0991],
                        [-64.4505, 59.1029],
                        [-64.417, 59.0876],
                        [-64.376, 59.0926],
                        [-64.3635, 59.0874],
                        [-64.3608, 59.0673],
                        [-64.3152, 59.0656],
                        [-64.2736, 59.022],
                        [-64.2618, 59.013],
                        [-64.2944, 59.0005],
                        [-64.3453, 59.0049],
                        [-64.3877, 59.0062],
                        [-64.4242, 58.9917],
                        [-64.4368, 58.9943],
                        [-64.4603, 58.9815],
                        [-64.4813, 58.9883],
                        [-64.4713, 59],
                        [-64.4978, 59.0194],
                        [-64.5068, 59.0197],
                        [-64.5228, 59.0026],
                        [-64.545, 59.0096],
                        [-64.5706, 59.0273],
                        [-64.5958, 59.035],
                        [-64.6166, 59.0341],
                        [-64.6307, 59.0407],
                        [-64.6502, 59.0412],
                        [-64.6703, 59.0386],
                        [-64.6793, 59.047],
                        [-64.6833, 59.0747],
                        [-64.7249, 59.076],
                        [-64.7525, 59.0705],
                        [-64.7792, 59.075],
                        [-64.8217, 59.0493],
                        [-64.832, 59.0352],
                        [-64.8408, 59.0325],
                        [-64.8294, 59.0203],
                        [-64.8436, 59.0163],
                        [-64.865, 59.0154],
                        [-64.8701, 58.9983],
                        [-64.8622, 58.9862],
                        [-64.8404, 58.9803],
                        [-64.8421, 58.967],
                        [-64.8329, 58.9564],
                        [-64.8486, 58.9531],
                        [-64.8794, 58.9519],
                        [-64.8891, 58.9384],
                        [-64.8685, 58.9416],
                        [-64.857, 58.9386],
                        [-64.8563, 58.9329],
                        [-64.8393, 58.937],
                        [-64.8321, 58.9217],
                        [-64.8207, 58.9169],
                        [-64.7657, 58.9238],
                        [-64.7503, 58.9341],
                        [-64.7562, 58.9403],
                        [-64.7307, 58.9508],
                        [-64.7026, 58.9464],
                        [-64.6813, 58.948],
                        [-64.6517, 58.9438],
                        [-64.6324, 58.9333],
                        [-64.6148, 58.9075],
                        [-64.5796, 58.9047],
                        [-64.5572, 58.8972],
                        [-64.5502, 58.8875],
                        [-64.5089, 58.8876],
                        [-64.4764, 58.9034],
                        [-64.4558, 58.9082],
                        [-64.4264, 58.9045],
                        [-64.4206, 58.8903],
                        [-64.4059, 58.8908],
                        [-64.4003, 58.9027],
                        [-64.3787, 58.9038],
                        [-64.3503, 58.8919],
                        [-64.3325, 58.9046],
                        [-64.2876, 58.8968],
                        [-64.2874, 58.8835],
                        [-64.2337, 58.8641],
                        [-64.2266, 58.8458],
                        [-64.2375, 58.805],
                        [-64.2297, 58.7836],
                        [-64.2001, 58.7795],
                        [-64.1788, 58.789],
                        [-64.1583, 58.7581],
                        [-64.1378, 58.7462],
                        [-64.0941, 58.7569],
                        [-64.0871, 58.756],
                        [-64.0697, 58.7691],
                        [-64.0719, 58.7832],
                        [-64.0463, 58.7896],
                        [-64.0386, 58.8021],
                        [-64.0453, 58.8104],
                        [-64.0197, 58.8167],
                        [-63.9954, 58.8137],
                        [-63.9755, 58.8303],
                        [-63.9572, 58.8269],
                        [-63.8979, 58.8374],
                        [-63.8622, 58.832],
                        [-63.8508, 58.8351],
                        [-63.8353, 58.849],
                        [-63.8121, 58.8471],
                        [-63.7993, 58.8565],
                        [-63.7777, 58.8841],
                        [-63.7255, 58.8772],
                        [-63.71, 58.8871],
                        [-63.6799, 58.8786],
                        [-63.6803, 58.8665],
                        [-63.6611, 58.8547],
                        [-63.636, 58.8487],
                        [-63.6178, 58.8553],
                        [-63.5953, 58.8572],
                        [-63.5508, 58.8366],
                        [-63.5503, 58.8224],
                        [-63.5345, 58.8162],
                        [-63.5128, 58.7957],
                        [-63.4922, 58.7685],
                        [-63.4675, 58.762],
                        [-63.4948, 58.754],
                        [-63.5068, 58.7426],
                        [-63.5533, 58.7338],
                        [-63.5617, 58.7277],
                        [-63.5725, 58.7315],
                        [-63.6193, 58.7239],
                        [-63.6298, 58.7146],
                        [-63.6607, 58.7252],
                        [-63.6696, 58.7171],
                        [-63.6949, 58.7196],
                        [-63.7184, 58.7064],
                        [-63.7288, 58.7068],
                        [-63.7365, 58.7145],
                        [-63.7544, 58.7167],
                        [-63.7943, 58.7033],
                        [-63.811, 58.7122],
                        [-63.8356, 58.7101],
                        [-63.8407, 58.7014],
                        [-63.8551, 58.6964],
                        [-63.875, 58.7058],
                        [-63.91, 58.7103],
                        [-63.9311, 58.701],
                        [-63.9503, 58.6852],
                        [-63.9648, 58.6903],
                        [-63.9842, 58.6876],
                        [-64.0162, 58.6954],
                        [-64.0335, 58.7045],
                        [-64.0472, 58.7026],
                        [-64.06, 58.6836],
                        [-64.0766, 58.6659],
                        [-64.0789, 58.6539],
                        [-64.1079, 58.6397],
                        [-64.1093, 58.6199],
                        [-64.1159, 58.6024],
                        [-64.0928, 58.5879],
                        [-64.1111, 58.5817],
                        [-64.1049, 58.5632],
                        [-64.0747, 58.5474],
                        [-64.0672, 58.5345],
                        [-64.0469, 58.5351],
                        [-64.0341, 58.5235],
                        [-64.0088, 58.5313],
                        [-63.9736, 58.5296],
                        [-63.9519, 58.5413],
                        [-63.9467, 58.5588],
                        [-63.9074, 58.5735],
                        [-63.8871, 58.5717],
                        [-63.8582, 58.5638],
                        [-63.8626, 58.5527],
                        [-63.8497, 58.5466],
                        [-63.8488, 58.5335],
                        [-63.8592, 58.5212],
                        [-63.8587, 58.5045],
                        [-63.8236, 58.4973],
                        [-63.8079, 58.4897],
                        [-63.8223, 58.4737],
                        [-63.8431, 58.4678],
                        [-63.8472, 58.45],
                        [-63.8811, 58.4294],
                        [-63.9104, 58.4415],
                        [-63.9242, 58.4449],
                        [-63.9411, 58.4402],
                        [-63.9641, 58.4388],
                        [-63.9866, 58.4444],
                        [-63.9985, 58.4255],
                        [-64.0132, 58.424],
                        [-64.021, 58.409],
                        [-64.0286, 58.3894],
                        [-64.0225, 58.371],
                        [-64.0305, 58.366],
                        [-64.0617, 58.3609],
                        [-64.098, 58.3768],
                        [-64.1192, 58.3647],
                        [-64.1391, 58.3692],
                        [-64.1585, 58.371],
                        [-64.1722, 58.3619],
                        [-64.1755, 58.3439],
                        [-64.1868, 58.3367],
                        [-64.1782, 58.3306],
                        [-64.1846, 58.3171],
                        [-64.1982, 58.3119],
                        [-64.1969, 58.2936],
                        [-64.217, 58.2875],
                        [-64.2085, 58.2756],
                        [-64.2171, 58.2619],
                        [-64.2178, 58.2477],
                        [-64.2413, 58.2377],
                        [-64.2475, 58.2274],
                        [-64.2712, 58.2211],
                        [-64.3191, 58.2181],
                        [-64.3736, 58.2084],
                        [-64.3895, 58.1965],
                        [-64.4217, 58.1867],
                        [-64.4218, 58.1738],
                        [-64.4309, 58.1562],
                        [-64.4251, 58.1302],
                        [-64.4337, 58.1163],
                        [-64.4354, 58.0984],
                        [-64.4269, 58.0882],
                        [-64.4331, 58.0699],
                        [-64.4123, 58.0612],
                        [-64.3871, 58.0693],
                        [-64.3539, 58.0693],
                        [-64.3205, 58.0615],
                        [-64.2993, 58.0445],
                        [-64.2777, 58.047],
                        [-64.2693, 58.0614],
                        [-64.251, 58.0387],
                        [-64.2337, 58.0418],
                        [-64.2185, 58.037],
                        [-64.2387, 58.025],
                        [-64.2382, 58.0161],
                        [-64.228, 58.0055],
                        [-64.2274, 57.9895],
                        [-64.2047, 57.9736],
                        [-64.1988, 57.9376],
                        [-64.1847, 57.926],
                        [-64.1625, 57.9229],
                        [-64.158, 57.9163],
                        [-64.1566, 57.8902],
                        [-64.1214, 57.8263],
                        [-64.1167, 57.8111],
                        [-64.0921, 57.7943],
                        [-64.072, 57.77],
                        [-64.026, 57.7644],
                        [-64.0233, 57.7785],
                        [-64.034, 57.7914],
                        [-64.0298, 57.804],
                        [-64.0209, 57.8095],
                        [-64.0119, 57.8025],
                        [-63.9972, 57.8121],
                        [-63.9723, 57.7996],
                        [-63.9536, 57.7997],
                        [-63.9386, 57.7886],
                        [-63.9124, 57.8011],
                        [-63.9038, 57.7982],
                        [-63.9082, 57.7769],
                        [-63.9011, 57.7629],
                        [-63.9072, 57.7514],
                        [-63.8834, 57.7126],
                        [-63.8661, 57.7112],
                        [-63.8475, 57.719],
                        [-63.8207, 57.7056],
                        [-63.8134, 57.6898],
                        [-63.8042, 57.6845],
                        [-63.7899, 57.6875],
                        [-63.7731, 57.7002],
                        [-63.7536, 57.7207],
                        [-63.7514, 57.6999],
                        [-63.7448, 57.6886],
                        [-63.7486, 57.6743],
                        [-63.7353, 57.6628],
                        [-63.7028, 57.6638],
                        [-63.6926, 57.6527],
                        [-63.6854, 57.6833],
                        [-63.6851, 57.6969],
                        [-63.6724, 57.6926],
                        [-63.6594, 57.7018],
                        [-63.66, 57.716],
                        [-63.6681, 57.7239],
                        [-63.6525, 57.7329],
                        [-63.6326, 57.7289],
                        [-63.6232, 57.7395],
                        [-63.5968, 57.7412],
                        [-63.5949, 57.7193],
                        [-63.597, 57.7077],
                        [-63.6095, 57.6921],
                        [-63.5945, 57.6857],
                        [-63.5987, 57.6638],
                        [-63.6166, 57.6507],
                        [-63.623, 57.642],
                        [-63.6449, 57.6343],
                        [-63.65, 57.6201],
                        [-63.6703, 57.6212],
                        [-63.6846, 57.6034],
                        [-63.6995, 57.6153],
                        [-63.7306, 57.6011],
                        [-63.7326, 57.5887],
                        [-63.7456, 57.5795],
                        [-63.7583, 57.5916],
                        [-63.7719, 57.5924],
                        [-63.7711, 57.5793],
                        [-63.7655, 57.5608],
                        [-63.7512, 57.5357],
                        [-63.7534, 57.5153],
                        [-63.7675, 57.5148],
                        [-63.7752, 57.4936],
                        [-63.7657, 57.4646],
                        [-63.7583, 57.4544],
                        [-63.7613, 57.4393],
                        [-63.7315, 57.4266],
                        [-63.7164, 57.4382],
                        [-63.7103, 57.4226],
                        [-63.7234, 57.3983],
                        [-63.7083, 57.3845],
                        [-63.7013, 57.3683],
                        [-63.7146, 57.3633],
                        [-63.742, 57.3577],
                        [-63.7682, 57.3582],
                        [-63.7855, 57.3532],
                        [-63.7974, 57.3404],
                        [-63.8017, 57.3323],
                        [-63.8243, 57.3392],
                        [-63.8558, 57.3343],
                        [-63.8657, 57.3136],
                        [-63.8694, 57.2976],
                        [-63.8834, 57.2776],
                        [-63.8814, 57.265],
                        [-63.867, 57.2261],
                        [-63.8734, 57.2094],
                        [-63.8841, 57.1963],
                        [-63.8724, 57.1925],
                        [-63.8612, 57.2015],
                        [-63.846, 57.2042],
                        [-63.8416, 57.2108],
                        [-63.8447, 57.226],
                        [-63.8261, 57.2411],
                        [-63.8187, 57.255],
                        [-63.8091, 57.2622],
                        [-63.7796, 57.2594],
                        [-63.7598, 57.2517],
                        [-63.7415, 57.2408],
                        [-63.7371, 57.2216],
                        [-63.7501, 57.2091],
                        [-63.771, 57.196],
                        [-63.7794, 57.1794],
                        [-63.7797, 57.1618],
                        [-63.7649, 57.1399],
                        [-63.7858, 57.1175],
                        [-63.7987, 57.1064],
                        [-63.8074, 57.091],
                        [-63.8118, 57.0789],
                        [-63.8201, 57.0755],
                        [-63.8398, 57.0802],
                        [-63.8817, 57.088],
                        [-63.8901, 57.0861],
                        [-63.8915, 57.0625],
                        [-63.8986, 57.0551],
                        [-63.8897, 57.0459],
                        [-63.8919, 57.0128],
                        [-63.8578, 56.9992],
                        [-63.887, 56.981],
                        [-63.9079, 56.9841],
                        [-63.9141, 56.9776],
                        [-63.9118, 56.9553],
                        [-63.9144, 56.9398],
                        [-63.9081, 56.9275],
                        [-63.9173, 56.9168],
                        [-63.9181, 56.9042],
                        [-63.908, 56.8979],
                        [-63.8999, 56.9057],
                        [-63.8786, 56.9039],
                        [-63.8639, 56.8968],
                        [-63.8674, 56.8834],
                        [-63.8571, 56.8726],
                        [-63.8995, 56.8773],
                        [-63.9188, 56.8712],
                        [-63.948, 56.8677],
                        [-63.9839, 56.8611],
                        [-64.0035, 56.8617],
                        [-64.013, 56.8498],
                        [-64.0099, 56.8369],
                        [-64.0089, 56.8067],
                        [-64.002, 56.7976],
                        [-64.0128, 56.7787],
                        [-64.0789, 56.767],
                        [-64.0854, 56.761],
                        [-64.074, 56.7555],
                        [-64.0763, 56.7465],
                        [-64.0994, 56.7436],
                        [-64.1124, 56.7339],
                        [-64.1181, 56.724],
                        [-64.1453, 56.6998],
                        [-64.134, 56.695],
                        [-64.1282, 56.6829],
                        [-64.0945, 56.6629],
                        [-64.0908, 56.6538],
                        [-64.051, 56.6575],
                        [-64.0249, 56.653],
                        [-64.0399, 56.6407],
                        [-64.0337, 56.6332],
                        [-64.0533, 56.6155],
                        [-64.0505, 56.6063],
                        [-64.004, 56.5912],
                        [-64.0016, 56.5792],
                        [-63.9826, 56.5647],
                        [-63.9642, 56.5574],
                        [-63.9559, 56.5468],
                        [-63.9311, 56.5338],
                        [-63.9414, 56.5114],
                        [-63.9391, 56.5024],
                        [-63.9443, 56.4931],
                        [-63.9312, 56.475],
                        [-63.9306, 56.4662],
                        [-63.9128, 56.463],
                        [-63.8977, 56.469],
                        [-63.8837, 56.4691],
                        [-63.8854, 56.4592],
                        [-63.8729, 56.449],
                        [-63.8742, 56.442],
                        [-63.8865, 56.433],
                        [-63.9018, 56.4407],
                        [-63.952, 56.4214],
                        [-63.9658, 56.4107],
                        [-63.993, 56.4082],
                        [-64.0111, 56.4111],
                        [-64.0206, 56.4179],
                        [-64.0448, 56.4198],
                        [-64.0644, 56.4241],
                        [-64.0955, 56.4241],
                        [-64.1373, 56.4335],
                        [-64.1527, 56.4411],
                        [-64.1643, 56.4313],
                        [-64.178, 56.4252],
                        [-64.1581, 56.4198],
                        [-64.1437, 56.4054],
                        [-64.1328, 56.4018],
                        [-64.1154, 56.4021],
                        [-64.1104, 56.386],
                        [-64.1278, 56.3699],
                        [-64.1159, 56.3661],
                        [-64.0994, 56.3519],
                        [-64.1075, 56.343],
                        [-64.0982, 56.3336],
                        [-64.1094, 56.3226],
                        [-64.1434, 56.312],
                        [-64.1486, 56.3034],
                        [-64.1304, 56.3023],
                        [-64.1173, 56.2967],
                        [-64.1113, 56.289],
                        [-64.0905, 56.2799],
                        [-64.0874, 56.2569],
                        [-64.0756, 56.256],
                        [-64.0222, 56.2678],
                        [-63.9671, 56.2564],
                        [-63.9531, 56.2652],
                        [-63.9349, 56.2711],
                        [-63.9129, 56.2635],
                        [-63.9142, 56.2448],
                        [-63.9115, 56.2308],
                        [-63.8954, 56.2226],
                        [-63.8744, 56.2248],
                        [-63.8629, 56.2126],
                        [-63.9, 56.1992],
                        [-63.95, 56.1851],
                        [-63.9789, 56.1798],
                        [-64.0047, 56.1733],
                        [-64.0153, 56.1662],
                        [-64.0195, 56.1596],
                        [-64.036, 56.1549],
                        [-64.0239, 56.1426],
                        [-64.0086, 56.1382],
                        [-64.0142, 56.1275],
                        [-64.0053, 56.1193],
                        [-64.0021, 56.1027],
                        [-64.0143, 56.1008],
                        [-64.0358, 56.0822],
                        [-64.0347, 56.0701],
                        [-64.0181, 56.0673],
                        [-63.9932, 56.0758],
                        [-63.969, 56.0867],
                        [-63.9615, 56.0924],
                        [-63.9481, 56.0911],
                        [-63.9332, 56.0972],
                        [-63.9253, 56.109],
                        [-63.9057, 56.1237],
                        [-63.8801, 56.1361],
                        [-63.8562, 56.1371],
                        [-63.8383, 56.1225],
                        [-63.863, 56.1088],
                        [-63.8876, 56.0929],
                        [-63.8826, 56.0871],
                        [-63.8639, 56.0921],
                        [-63.8396, 56.1006],
                        [-63.8354, 56.0877],
                        [-63.8187, 56.0889],
                        [-63.8111, 56.0846],
                        [-63.8228, 56.0738],
                        [-63.8214, 56.0638],
                        [-63.8379, 56.045],
                        [-63.8233, 56.0452],
                        [-63.7912, 56.0529],
                        [-63.7742, 56.0536],
                        [-63.7501, 56.0619],
                        [-63.7313, 56.0702],
                        [-63.7148, 56.0717],
                        [-63.7144, 56.066],
                        [-63.6965, 56.0646],
                        [-63.683, 56.0596],
                        [-63.6904, 56.0439],
                        [-63.6367, 56.0235],
                        [-63.6033, 56.0312],
                        [-63.601, 56.0261],
                        [-63.5852, 56.0293],
                        [-63.5776, 56.0251],
                        [-63.5486, 56.0313],
                        [-63.521, 56.039],
                        [-63.5105, 56.0359],
                        [-63.4958, 56.04],
                        [-63.4435, 56.0295],
                        [-63.4598, 56.0213],
                        [-63.4672, 56.0119],
                        [-63.4864, 56.0156],
                        [-63.4912, 56.0099],
                        [-63.4702, 55.9983],
                        [-63.472, 55.9878],
                        [-63.4874, 55.9875],
                        [-63.505, 56.0014],
                        [-63.5266, 56.0054],
                        [-63.563, 56.0059],
                        [-63.574, 56.0007],
                        [-63.5845, 55.9899],
                        [-63.5994, 55.9917],
                        [-63.6058, 55.9878],
                        [-63.5983, 55.9822],
                        [-63.6271, 55.9737],
                        [-63.6245, 55.9675],
                        [-63.6356, 55.9653],
                        [-63.6547, 55.9722],
                        [-63.6681, 55.9695],
                        [-63.6755, 55.9616],
                        [-63.6865, 55.9617],
                        [-63.6814, 55.9538],
                        [-63.6881, 55.9482],
                        [-63.7124, 55.9535],
                        [-63.7132, 55.9479],
                        [-63.7036, 55.9461],
                        [-63.7066, 55.9402],
                        [-63.7174, 55.9376],
                        [-63.724, 55.9305],
                        [-63.7339, 55.928],
                        [-63.7508, 55.9336],
                        [-63.7716, 55.9292],
                        [-63.7926, 55.9315],
                        [-63.8097, 55.9228],
                        [-63.8494, 55.9168],
                        [-63.8544, 55.91],
                        [-63.8251, 55.9044],
                        [-63.8301, 55.8962],
                        [-63.8437, 55.8877],
                        [-63.8451, 55.8791],
                        [-63.813, 55.8636],
                        [-63.7955, 55.8611],
                        [-63.7736, 55.8561],
                        [-63.7715, 55.8485],
                        [-63.7529, 55.8465],
                        [-63.7666, 55.8395],
                        [-63.7529, 55.8333],
                        [-63.75, 55.8251],
                        [-63.7554, 55.8211],
                        [-63.7728, 55.822],
                        [-63.7804, 55.813],
                        [-63.7702, 55.8081],
                        [-63.777, 55.8015],
                        [-63.7613, 55.7969],
                        [-63.7684, 55.79],
                        [-63.7501, 55.7877],
                        [-63.7407, 55.7826],
                        [-63.7171, 55.7804],
                        [-63.7055, 55.7872],
                        [-63.6754, 55.792],
                        [-63.6665, 55.7878],
                        [-63.6795, 55.774],
                        [-63.6742, 55.7593],
                        [-63.6821, 55.753],
                        [-63.7054, 55.7555],
                        [-63.701, 55.743],
                        [-63.714, 55.7391],
                        [-63.6982, 55.7316],
                        [-63.7025, 55.7272],
                        [-63.7251, 55.7266],
                        [-63.7196, 55.7053],
                        [-63.7279, 55.6954],
                        [-63.71, 55.6888],
                        [-63.7049, 55.6786],
                        [-63.7231, 55.6794],
                        [-63.7357, 55.6729],
                        [-63.7512, 55.6715],
                        [-63.752, 55.6589],
                        [-63.7652, 55.6587],
                        [-63.7646, 55.6516],
                        [-63.7383, 55.6504],
                        [-63.716, 55.6577],
                        [-63.6685, 55.6523],
                        [-63.6437, 55.6416],
                        [-63.6487, 55.6341],
                        [-63.6617, 55.6372],
                        [-63.6802, 55.6352],
                        [-63.6877, 55.6255],
                        [-63.6827, 55.6091],
                        [-63.6732, 55.6072],
                        [-63.6626, 55.5989],
                        [-63.6749, 55.5897],
                        [-63.6815, 55.5757],
                        [-63.6649, 55.5675],
                        [-63.6655, 55.546],
                        [-63.6707, 55.5375],
                        [-63.684, 55.5378],
                        [-63.6891, 55.5255],
                        [-63.6865, 55.5186],
                        [-63.6915, 55.5087],
                        [-63.7164, 55.5044],
                        [-63.726, 55.493],
                        [-63.7408, 55.4915],
                        [-63.7561, 55.4817],
                        [-63.7755, 55.4817],
                        [-63.7801, 55.4738],
                        [-63.7927, 55.4672],
                        [-63.7806, 55.4655],
                        [-63.7674, 55.4549],
                        [-63.7494, 55.4549],
                        [-63.7384, 55.4479],
                        [-63.7367, 55.4382],
                        [-63.6654, 55.4231],
                        [-63.6445, 55.4233],
                        [-63.6309, 55.4303],
                        [-63.604, 55.4285],
                        [-63.5784, 55.4205],
                        [-63.5663, 55.4271],
                        [-63.549, 55.4208],
                        [-63.5344, 55.4275],
                        [-63.5107, 55.4203],
                        [-63.4786, 55.4292],
                        [-63.471, 55.4198],
                        [-63.4501, 55.4207],
                        [-63.4491, 55.412],
                        [-63.4325, 55.4086],
                        [-63.4349, 55.4004],
                        [-63.4036, 55.4077],
                        [-63.393, 55.4048],
                        [-63.3733, 55.4097],
                        [-63.3518, 55.4184],
                        [-63.337, 55.4099],
                        [-63.3198, 55.4118],
                        [-63.3337, 55.3839],
                        [-63.3248, 55.366],
                        [-63.3414, 55.3625],
                        [-63.3507, 55.3569],
                        [-63.3578, 55.3622],
                        [-63.3998, 55.3545],
                        [-63.4422, 55.3441],
                        [-63.4707, 55.3456],
                        [-63.482, 55.3498],
                        [-63.5077, 55.3476],
                        [-63.5299, 55.3419],
                        [-63.5519, 55.3449],
                        [-63.5608, 55.3389],
                        [-63.5744, 55.3396],
                        [-63.5804, 55.3348],
                        [-63.5907, 55.3332],
                        [-63.5983, 55.3257],
                        [-63.5908, 55.3207],
                        [-63.5539, 55.321],
                        [-63.5327, 55.3255],
                        [-63.5226, 55.3099],
                        [-63.5334, 55.3062],
                        [-63.5421, 55.3097],
                        [-63.5517, 55.3013],
                        [-63.5695, 55.2977],
                        [-63.5879, 55.3026],
                        [-63.6158, 55.2963],
                        [-63.6441, 55.2878],
                        [-63.6401, 55.2786],
                        [-63.6573, 55.2781],
                        [-63.6795, 55.2669],
                        [-63.6527, 55.2519],
                        [-63.6386, 55.251],
                        [-63.6305, 55.2562],
                        [-63.6012, 55.2512],
                        [-63.6083, 55.2457],
                        [-63.6225, 55.2426],
                        [-63.6241, 55.2361],
                        [-63.6091, 55.2301],
                        [-63.5977, 55.2384],
                        [-63.5781, 55.2364],
                        [-63.5633, 55.2434],
                        [-63.5474, 55.2255],
                        [-63.5084, 55.2321],
                        [-63.4904, 55.2445],
                        [-63.4748, 55.2454],
                        [-63.4626, 55.2553],
                        [-63.4459, 55.2514],
                        [-63.4324, 55.2553],
                        [-63.4026, 55.2578],
                        [-63.4028, 55.249],
                        [-63.4227, 55.243],
                        [-63.4125, 55.2336],
                        [-63.4258, 55.2262],
                        [-63.4319, 55.2113],
                        [-63.4459, 55.2108],
                        [-63.4526, 55.2019],
                        [-63.4899, 55.2004],
                        [-63.503, 55.2025],
                        [-63.5131, 55.1964],
                        [-63.5629, 55.1876],
                        [-63.556, 55.1729],
                        [-63.5314, 55.1746],
                        [-63.5239, 55.1681],
                        [-63.5415, 55.1601],
                        [-63.5734, 55.1558],
                        [-63.5651, 55.1403],
                        [-63.5737, 55.1331],
                        [-63.6023, 55.1287],
                        [-63.5861, 55.1159],
                        [-63.5854, 55.1036],
                        [-63.5916, 55.0953],
                        [-63.6052, 55.0983],
                        [-63.6114, 55.0908],
                        [-63.6029, 55.0764],
                        [-63.6161, 55.0689],
                        [-63.6065, 55.0612],
                        [-63.5876, 55.0608],
                        [-63.5734, 55.054],
                        [-63.5755, 55.0422],
                        [-63.5564, 55.0344],
                        [-63.5757, 55.0258],
                        [-63.596, 55.0214],
                        [-63.6061, 55.0084],
                        [-63.5576, 54.9999],
                        [-63.5575, 54.9899],
                        [-63.5763, 54.9881],
                        [-63.5732, 54.9788],
                        [-63.5869, 54.9738],
                        [-63.611, 54.977],
                        [-63.6125, 54.969],
                        [-63.5975, 54.9594],
                        [-63.596, 54.9458],
                        [-63.602, 54.9393],
                        [-63.5779, 54.93],
                        [-63.5836, 54.9238],
                        [-63.6045, 54.9263],
                        [-63.6123, 54.9199],
                        [-63.6044, 54.9079],
                        [-63.5914, 54.8966],
                        [-63.6196, 54.8995],
                        [-63.6307, 54.9092],
                        [-63.6458, 54.9056],
                        [-63.6635, 54.9105],
                        [-63.6613, 54.9],
                        [-63.6849, 54.9046],
                        [-63.6938, 54.9099],
                        [-63.6964, 54.9253],
                        [-63.716, 54.9255],
                        [-63.7519, 54.9423],
                        [-63.7843, 54.9478],
                        [-63.7917, 54.9559],
                        [-63.8205, 54.948],
                        [-63.8298, 54.9421],
                        [-63.8241, 54.9343],
                        [-63.7996, 54.9261],
                        [-63.8127, 54.9194],
                        [-63.8377, 54.9145],
                        [-63.829, 54.9011],
                        [-63.8462, 54.8948],
                        [-63.838, 54.8831],
                        [-63.8523, 54.8851],
                        [-63.8527, 54.8761],
                        [-63.8422, 54.871],
                        [-63.849, 54.8626],
                        [-63.8279, 54.8473],
                        [-63.8181, 54.8289],
                        [-63.8172, 54.8169],
                        [-63.8296, 54.8126],
                        [-63.8482, 54.8124],
                        [-63.848, 54.8072],
                        [-63.869, 54.807],
                        [-63.8718, 54.8005],
                        [-63.9037, 54.8011],
                        [-63.8908, 54.7888],
                        [-63.9101, 54.7885],
                        [-63.924, 54.7784],
                        [-63.8897, 54.7684],
                        [-63.8958, 54.7601],
                        [-63.8797, 54.755],
                        [-63.8607, 54.7527],
                        [-63.8328, 54.7364],
                        [-63.803, 54.7256],
                        [-63.8039, 54.7167],
                        [-63.7689, 54.6998],
                        [-63.7597, 54.6856],
                        [-63.7681, 54.6831],
                        [-63.7631, 54.6737],
                        [-63.744, 54.6699],
                        [-63.7238, 54.6598],
                        [-63.7253, 54.6518],
                        [-63.7126, 54.625],
                        [-63.7277, 54.6271],
                        [-63.7326, 54.6231],
                        [-63.7428, 54.6264],
                        [-63.7645, 54.6374],
                        [-63.7805, 54.6408],
                        [-63.8017, 54.649],
                        [-63.8134, 54.6508],
                        [-63.8196, 54.6407],
                        [-63.8344, 54.6411],
                        [-63.8307, 54.6329],
                        [-63.8774, 54.619],
                        [-63.8775, 54.613],
                        [-63.8928, 54.6103],
                        [-63.8998, 54.6056],
                        [-63.9114, 54.6042],
                        [-63.9343, 54.6066],
                        [-63.9665, 54.6193],
                        [-63.9744, 54.6201],
                        [-63.9849, 54.6086],
                        [-64.004, 54.6021],
                        [-64.029, 54.6049],
                        [-64.0351, 54.607],
                        [-64.0476, 54.6009],
                        [-64.0543, 54.6009],
                        [-64.0719, 54.617],
                        [-64.0861, 54.623],
                        [-64.0984, 54.6125],
                        [-64.1073, 54.6132],
                        [-64.113, 54.6335],
                        [-64.1052, 54.6467],
                        [-64.1028, 54.6556],
                        [-64.1062, 54.6604],
                        [-64.129, 54.6656],
                        [-64.1418, 54.667],
                        [-64.1453, 54.6761],
                        [-64.1586, 54.6808],
                        [-64.1713, 54.6907],
                        [-64.1807, 54.6936],
                        [-64.176, 54.7012],
                        [-64.1878, 54.7108],
                        [-64.1787, 54.717],
                        [-64.1902, 54.728],
                        [-64.2038, 54.7281],
                        [-64.2261, 54.7194],
                        [-64.2378, 54.7201],
                        [-64.2389, 54.7291],
                        [-64.2756, 54.728],
                        [-64.2882, 54.731],
                        [-64.3007, 54.7415],
                        [-64.2945, 54.7533],
                        [-64.3066, 54.7593],
                        [-64.3439, 54.7726],
                        [-64.3626, 54.7778],
                        [-64.3841, 54.7802],
                        [-64.3975, 54.7905],
                        [-64.4218, 54.7879],
                        [-64.4853, 54.7937],
                        [-64.4925, 54.7873],
                        [-64.4798, 54.7842],
                        [-64.4805, 54.7779],
                        [-64.4715, 54.7703],
                        [-64.4855, 54.771],
                        [-64.4748, 54.7522],
                        [-64.5142, 54.7513],
                        [-64.5342, 54.7618],
                        [-64.5548, 54.7449],
                        [-64.529, 54.7375],
                        [-64.5307, 54.7335],
                        [-64.547, 54.7231],
                        [-64.5569, 54.7137],
                        [-64.5863, 54.7256],
                        [-64.6003, 54.7289],
                        [-64.6229, 54.7219],
                        [-64.6503, 54.7306],
                        [-64.68, 54.7357],
                        [-64.6878, 54.726],
                        [-64.7111, 54.723],
                        [-64.731, 54.7256],
                        [-64.7349, 54.736],
                        [-64.7637, 54.7292],
                        [-64.7781, 54.7335],
                        [-64.8, 54.7563],
                        [-64.7852, 54.7539],
                        [-64.7821, 54.7633],
                        [-64.772, 54.7625],
                        [-64.7523, 54.7673],
                        [-64.7653, 54.7741],
                        [-64.7762, 54.7881],
                        [-64.7684, 54.7905],
                        [-64.7698, 54.7972],
                        [-64.7595, 54.7997],
                        [-64.7479, 54.814],
                        [-64.7611, 54.8295],
                        [-64.7839, 54.8428],
                        [-64.7905, 54.8341],
                        [-64.8007, 54.8326],
                        [-64.8159, 54.849],
                        [-64.8248, 54.8462],
                        [-64.8272, 54.837],
                        [-64.8381, 54.8403],
                        [-64.8393, 54.846],
                        [-64.8568, 54.8401],
                        [-64.8666, 54.8448],
                        [-64.8584, 54.8488],
                        [-64.8617, 54.8549],
                        [-64.8469, 54.8738],
                        [-64.8561, 54.8829],
                        [-64.8717, 54.8926],
                        [-64.8897, 54.8942],
                        [-64.9026, 54.9016],
                        [-64.9065, 54.9106],
                        [-64.9123, 54.9119],
                        [-64.915, 54.92],
                        [-64.9269, 54.9213],
                        [-64.9539, 54.9322],
                        [-64.9719, 54.9374],
                        [-65.0096, 54.931],
                        [-65.0177, 54.9326],
                        [-65.0402, 54.9437],
                        [-65.0434, 54.9523],
                        [-65.0527, 54.956],
                        [-65.0706, 54.9536],
                        [-65.0764, 54.9681],
                        [-65.0858, 54.9696],
                        [-65.105, 54.9644],
                        [-65.1264, 54.9615],
                        [-65.1229, 54.9414],
                        [-65.1345, 54.9282],
                        [-65.1729, 54.9336],
                        [-65.1803, 54.9308],
                        [-65.1736, 54.9179],
                        [-65.1788, 54.9053],
                        [-65.2017, 54.8805],
                        [-65.2006, 54.8578],
                        [-65.2096, 54.848],
                        [-65.2425, 54.8499],
                        [-65.2672, 54.8484],
                        [-65.2911, 54.8401],
                        [-65.288, 54.8254],
                        [-65.293, 54.8189],
                        [-65.3049, 54.8197],
                        [-65.3257, 54.8487],
                        [-65.3358, 54.852],
                        [-65.3412, 54.8483],
                        [-65.3384, 54.8417],
                        [-65.3454, 54.8254],
                        [-65.3558, 54.8188],
                        [-65.3684, 54.8255],
                        [-65.3846, 54.8299],
                        [-65.4006, 54.8191],
                        [-65.4117, 54.8231],
                        [-65.4137, 54.8297],
                        [-65.4114, 54.8418],
                        [-65.4563, 54.8492],
                        [-65.4638, 54.8412],
                        [-65.4804, 54.8382],
                        [-65.4873, 54.8344],
                        [-65.4867, 54.8201],
                        [-65.4669, 54.8019],
                        [-65.4486, 54.7945],
                        [-65.4346, 54.7842],
                        [-65.4339, 54.7707],
                        [-65.4291, 54.7481],
                        [-65.4482, 54.744],
                        [-65.4535, 54.7374],
                        [-65.4555, 54.7286],
                        [-65.4634, 54.7272],
                        [-65.4874, 54.7285],
                        [-65.4978, 54.7348],
                        [-65.5136, 54.736],
                        [-65.5357, 54.7301],
                        [-65.5488, 54.7292],
                        [-65.5624, 54.7331],
                        [-65.5621, 54.7397],
                        [-65.5926, 54.743],
                        [-65.6016, 54.7378],
                        [-65.6213, 54.7385],
                        [-65.6321, 54.7545],
                        [-65.647, 54.7532],
                        [-65.651, 54.7479],
                        [-65.6485, 54.7352],
                        [-65.6697, 54.7188],
                        [-65.6901, 54.7115],
                        [-65.7062, 54.7179],
                        [-65.7205, 54.7316],
                        [-65.7136, 54.741],
                        [-65.7325, 54.7591],
                        [-65.7331, 54.7637],
                        [-65.743, 54.7695],
                        [-65.757, 54.7868],
                        [-65.7657, 54.7892],
                        [-65.7774, 54.8028],
                        [-65.787, 54.8091],
                        [-65.8191, 54.824],
                        [-65.8383, 54.8365],
                        [-65.8531, 54.8559],
                        [-65.8447, 54.8615],
                        [-65.8364, 54.8747],
                        [-65.8366, 54.882],
                        [-65.8572, 54.893],
                        [-65.8386, 54.908],
                        [-65.8393, 54.9143],
                        [-65.8498, 54.9272],
                        [-65.8712, 54.9235],
                        [-65.888, 54.9253],
                        [-65.9247, 54.9401],
                        [-65.9305, 54.9409],
                        [-65.946, 54.9343],
                        [-65.9566, 54.9368],
                        [-65.9651, 54.9298],
                        [-65.9766, 54.9314],
                        [-65.9966, 54.931],
                        [-66.0234, 54.942],
                        [-66.0424, 54.9406],
                        [-66.049, 54.9374],
                        [-66.0364, 54.9233],
                        [-66.0484, 54.9204],
                        [-66.0645, 54.9291],
                        [-66.0745, 54.9395],
                        [-66.0877, 54.9497],
                        [-66.0992, 54.9543],
                        [-66.1167, 54.9679],
                        [-66.137, 54.9773],
                        [-66.1436, 54.9823],
                        [-66.1616, 54.9877],
                        [-66.1607, 54.979],
                        [-66.168, 54.973],
                        [-66.1886, 54.9808],
                        [-66.2101, 54.9791],
                        [-66.2173, 54.9845],
                        [-66.2355, 54.9918],
                        [-66.2395, 54.9779],
                        [-66.2478, 54.9783],
                        [-66.2505, 54.9954],
                        [-66.2603, 55.0002],
                        [-66.268, 55.0148],
                        [-66.2796, 55.0251],
                        [-66.2941, 55.0339],
                        [-66.3036, 55.0355],
                        [-66.3102, 55.0408],
                        [-66.3217, 55.0569],
                        [-66.3331, 55.067],
                        [-66.355, 55.0832],
                        [-66.3723, 55.0932],
                        [-66.3846, 55.1027],
                        [-66.4021, 55.1131],
                        [-66.4197, 55.1312],
                        [-66.4341, 55.1351],
                        [-66.4447, 55.1409],
                        [-66.4664, 55.1603],
                        [-66.4805, 55.1783],
                        [-66.4883, 55.1973],
                        [-66.4953, 55.2051],
                        [-66.5074, 55.2047],
                        [-66.5164, 55.1953],
                        [-66.5319, 55.2196],
                        [-66.5456, 55.2354],
                        [-66.5558, 55.2351],
                        [-66.5657, 55.2384],
                        [-66.5811, 55.2521],
                        [-66.6028, 55.269],
                        [-66.6075, 55.2748],
                        [-66.618, 55.2782],
                        [-66.6266, 55.266],
                        [-66.6444, 55.2671],
                        [-66.6538, 55.2562],
                        [-66.6586, 55.2551],
                        [-66.6698, 55.2694],
                        [-66.6896, 55.2808],
                        [-66.7006, 55.29],
                        [-66.6969, 55.3053],
                        [-66.7003, 55.312],
                        [-66.7085, 55.3166],
                        [-66.7276, 55.3184],
                        [-66.74, 55.3279],
                        [-66.7626, 55.3401],
                        [-66.7785, 55.3524],
                        [-66.7893, 55.3525],
                        [-66.8078, 55.3567],
                        [-66.8187, 55.3553],
                        [-66.815, 55.3409],
                        [-66.8025, 55.3317],
                        [-66.7999, 55.3233],
                        [-66.8047, 55.3213],
                        [-66.8277, 55.3249],
                        [-66.823, 55.3137],
                        [-66.8237, 55.307],
                        [-66.7864, 55.2731],
                        [-66.7803, 55.269],
                        [-66.7402, 55.248],
                        [-66.7265, 55.2355],
                        [-66.7072, 55.2271],
                        [-66.6894, 55.2097],
                        [-66.6718, 55.1815],
                        [-66.6836, 55.1851],
                        [-66.7106, 55.2052],
                        [-66.7197, 55.2095],
                        [-66.7489, 55.2157],
                        [-66.757, 55.2134],
                        [-66.7553, 55.2057],
                        [-66.7394, 55.1941],
                        [-66.742, 55.1882],
                        [-66.7606, 55.1878],
                        [-66.757, 55.1719],
                        [-66.7467, 55.1585],
                        [-66.7486, 55.1485],
                        [-66.7677, 55.1497],
                        [-66.7797, 55.1389],
                        [-66.7676, 55.1351],
                        [-66.786, 55.1224],
                        [-66.7887, 55.1184],
                        [-66.7797, 55.1108],
                        [-66.7717, 55.1142],
                        [-66.7549, 55.1136],
                        [-66.7413, 55.1033],
                        [-66.7328, 55.0927],
                        [-66.7075, 55.0781],
                        [-66.6949, 55.0695],
                        [-66.6993, 55.0632],
                        [-66.7314, 55.0816],
                        [-66.7468, 55.0921],
                        [-66.7556, 55.0868],
                        [-66.7466, 55.0741],
                        [-66.7459, 55.0661],
                        [-66.7329, 55.0586],
                        [-66.735, 55.0441],
                        [-66.7244, 55.0414],
                        [-66.7164, 55.0341],
                        [-66.6979, 55.0279],
                        [-66.6821, 55.0297],
                        [-66.6717, 55.0255],
                        [-66.6597, 55.0159],
                        [-66.6564, 55.0079],
                        [-66.6279, 54.9884],
                        [-66.6256, 54.9839],
                        [-66.6501, 54.9746],
                        [-66.6664, 54.9854],
                        [-66.6854, 54.9928],
                        [-66.6894, 55.0001],
                        [-66.7369, 55],
                        [-66.74, 55.0072],
                        [-66.7504, 55.0068],
                        [-66.7494, 55],
                        [-66.7602, 54.9957],
                        [-66.7402, 54.9851],
                        [-66.7349, 54.9781],
                        [-66.7422, 54.9695],
                        [-66.7132, 54.9555],
                        [-66.7112, 54.9474],
                        [-66.6814, 54.9237],
                        [-66.6881, 54.9135],
                        [-66.6897, 54.898],
                        [-66.6636, 54.882],
                        [-66.6562, 54.8709],
                        [-66.6511, 54.8577],
                        [-66.6517, 54.85],
                        [-66.6333, 54.8468],
                        [-66.632, 54.8357],
                        [-66.6183, 54.8225],
                        [-66.6027, 54.8135],
                        [-66.6096, 54.8034],
                        [-66.6257, 54.799],
                        [-66.624, 54.79],
                        [-66.6382, 54.795],
                        [-66.6473, 54.785],
                        [-66.6446, 54.7788],
                        [-66.6594, 54.7702],
                        [-66.6683, 54.7742],
                        [-66.7071, 54.7977],
                        [-66.7329, 54.8115],
                        [-66.7405, 54.8055],
                        [-66.7237, 54.7886],
                        [-66.7321, 54.7789],
                        [-66.7305, 54.7667],
                        [-66.7158, 54.7574],
                        [-66.6997, 54.755],
                        [-66.6795, 54.7449],
                        [-66.6746, 54.7349],
                        [-66.6646, 54.727],
                        [-66.6673, 54.711],
                        [-66.6753, 54.7092],
                        [-66.6963, 54.726],
                        [-66.6992, 54.7342],
                        [-66.7142, 54.732],
                        [-66.7442, 54.75],
                        [-66.7585, 54.7481],
                        [-66.7688, 54.7562],
                        [-66.7732, 54.766],
                        [-66.7876, 54.7698],
                        [-66.7999, 54.7764],
                        [-66.8127, 54.7794],
                        [-66.8344, 54.7918],
                        [-66.8429, 54.7995],
                        [-66.8757, 54.816],
                        [-66.8874, 54.8108],
                        [-66.9113, 54.8094],
                        [-66.9185, 54.8024],
                        [-66.927, 54.8022],
                        [-66.9394, 54.8161],
                        [-66.9478, 54.8223],
                        [-66.977, 54.8331],
                        [-66.997, 54.8427],
                        [-67.013, 54.854],
                        [-67.033, 54.8619],
                        [-67.0399, 54.8681],
                        [-67.0414, 54.8817],
                        [-67.0565, 54.8949],
                        [-67.0764, 54.9055],
                        [-67.1184, 54.9315],
                        [-67.1393, 54.9509],
                        [-67.1572, 54.9565],
                        [-67.1735, 54.9706],
                        [-67.1878, 54.9804],
                        [-67.2092, 54.9842],
                        [-67.2193, 55.0001],
                        [-67.2327, 55.0087],
                        [-67.2575, 55.0213],
                        [-67.2728, 55.0242],
                        [-67.2627, 55.0344],
                        [-67.2633, 55.0455],
                        [-67.2363, 55.044],
                        [-67.234, 55.0512],
                        [-67.2437, 55.0596],
                        [-67.2728, 55.0764],
                        [-67.2862, 55.0789],
                        [-67.3228, 55.0891],
                        [-67.3459, 55.0867],
                        [-67.3684, 55.0913],
                        [-67.3953, 55.0843],
                        [-67.4096, 55.0886],
                        [-67.4281, 55.0839],
                        [-67.438, 55.0778],
                        [-67.4432, 55.0649],
                        [-67.4637, 55.0629],
                        [-67.4706, 55.049],
                        [-67.4492, 55.0389],
                        [-67.4396, 55.0325],
                        [-67.4443, 55.021],
                        [-67.4459, 55.0085],
                        [-67.4276, 55.0059],
                        [-67.4217, 54.999],
                        [-67.4019, 54.989],
                        [-67.4144, 54.9761],
                        [-67.4133, 54.972],
                        [-67.3989, 54.9654],
                        [-67.3998, 54.9579],
                        [-67.3845, 54.9517],
                        [-67.3703, 54.9417],
                        [-67.3577, 54.9397],
                        [-67.3541, 54.9298],
                        [-67.3399, 54.9165],
                        [-67.3351, 54.9035],
                        [-67.3157, 54.8954],
                        [-67.3092, 54.8865],
                        [-67.3107, 54.8804],
                        [-67.2827, 54.8677],
                        [-67.283, 54.8626],
                        [-67.2716, 54.8557],
                        [-67.2671, 54.8495],
                        [-67.2681, 54.8433],
                        [-67.2592, 54.8338],
                        [-67.2447, 54.8298],
                        [-67.2436, 54.8266],
                        [-67.2214, 54.8249],
                        [-67.2176, 54.8176],
                        [-67.2259, 54.8083],
                        [-67.2221, 54.8009],
                        [-67.2134, 54.7927],
                        [-67.1802, 54.7759],
                        [-67.1759, 54.7716],
                        [-67.1775, 54.764],
                        [-67.1581, 54.759],
                        [-67.1573, 54.7563],
                        [-67.1377, 54.75],
                        [-67.098, 54.7235],
                        [-67.086, 54.7129],
                        [-67.0732, 54.7051],
                        [-67.0742, 54.6998],
                        [-67.0629, 54.6929],
                        [-67.0775, 54.6858],
                        [-67.0828, 54.6806],
                        [-67.0715, 54.6737],
                        [-67.0767, 54.6711],
                        [-67.1055, 54.6666],
                        [-67.129, 54.6577],
                        [-67.1235, 54.6451],
                        [-67.1426, 54.6361],
                        [-67.1386, 54.6245],
                        [-67.1535, 54.6223],
                        [-67.1576, 54.6172],
                        [-67.1758, 54.6095],
                        [-67.1938, 54.6154],
                        [-67.2202, 54.6181],
                        [-67.2349, 54.6123],
                        [-67.2312, 54.5876],
                        [-67.2417, 54.5852],
                        [-67.2682, 54.5909],
                        [-67.2803, 54.5918],
                        [-67.2799, 54.5853],
                        [-67.2685, 54.5652],
                        [-67.2744, 54.5614],
                        [-67.302, 54.5593],
                        [-67.2971, 54.5536],
                        [-67.2778, 54.5487],
                        [-67.2632, 54.5475],
                        [-67.2678, 54.5425],
                        [-67.2595, 54.5337],
                        [-67.2278, 54.5253],
                        [-67.2293, 54.516],
                        [-67.2446, 54.5148],
                        [-67.2483, 54.5102],
                        [-67.2449, 54.5],
                        [-67.2497, 54.4963],
                        [-67.2628, 54.4975],
                        [-67.2696, 54.4885],
                        [-67.2821, 54.4873],
                        [-67.2866, 54.4903],
                        [-67.2898, 54.5087],
                        [-67.3143, 54.5132],
                        [-67.3295, 54.5136],
                        [-67.3353, 54.5211],
                        [-67.3492, 54.517],
                        [-67.3577, 54.52],
                        [-67.3686, 54.5296],
                        [-67.3829, 54.533],
                        [-67.3865, 54.539],
                        [-67.4061, 54.5529],
                        [-67.4231, 54.5579],
                        [-67.4234, 54.5653],
                        [-67.429, 54.5692],
                        [-67.4676, 54.5838],
                        [-67.4917, 54.5849],
                        [-67.5009, 54.5781],
                        [-67.5243, 54.5755],
                        [-67.5269, 54.571],
                        [-67.5169, 54.5622],
                        [-67.5183, 54.5522],
                        [-67.5089, 54.5475],
                        [-67.4855, 54.5397],
                        [-67.4741, 54.5274],
                        [-67.4731, 54.5201],
                        [-67.4935, 54.5114],
                        [-67.5, 54.5069],
                        [-67.5, 54.4946],
                        [-67.495, 54.4886],
                        [-67.5004, 54.4815],
                        [-67.5183, 54.4828],
                        [-67.5319, 54.4902],
                        [-67.5509, 54.4859],
                        [-67.5684, 54.4933],
                        [-67.5817, 54.4941],
                        [-67.5863, 54.4897],
                        [-67.5817, 54.4829],
                        [-67.5926, 54.469],
                        [-67.6095, 54.4658],
                        [-67.6209, 54.4682],
                        [-67.6279, 54.4736],
                        [-67.637, 54.4923],
                        [-67.6427, 54.508],
                        [-67.6474, 54.5094],
                        [-67.6666, 54.4793],
                        [-67.6624, 54.469],
                        [-67.6736, 54.4623],
                        [-67.6651, 54.4587],
                        [-67.6678, 54.4538],
                        [-67.6858, 54.4461],
                        [-67.7067, 54.4446],
                        [-67.7055, 54.4563],
                        [-67.7275, 54.4574],
                        [-67.7343, 54.4443],
                        [-67.7501, 54.4394],
                        [-67.767, 54.4368],
                        [-67.7711, 54.4304],
                        [-67.769, 54.4196],
                        [-67.7542, 54.4088],
                        [-67.7443, 54.3975],
                        [-67.7247, 54.3869],
                        [-67.7315, 54.3785],
                        [-67.732, 54.3642],
                        [-67.748, 54.3503],
                        [-67.7337, 54.3447],
                        [-67.7289, 54.3343],
                        [-67.6983, 54.3241],
                        [-67.68, 54.3119],
                        [-67.6767, 54.2932],
                        [-67.6687, 54.2775],
                        [-67.6757, 54.2645],
                        [-67.6849, 54.2634],
                        [-67.6808, 54.247],
                        [-67.6773, 54.2417],
                        [-67.664, 54.2331],
                        [-67.6518, 54.2215],
                        [-67.6283, 54.2128],
                        [-67.6382, 54.2052],
                        [-67.6315, 54.1998],
                        [-67.6156, 54.1958],
                        [-67.6243, 54.1911],
                        [-67.6332, 54.1941],
                        [-67.6443, 54.1938],
                        [-67.6534, 54.1886],
                        [-67.6535, 54.17],
                        [-67.6406, 54.1631],
                        [-67.6753, 54.1588],
                        [-67.6963, 54.1624],
                        [-67.706, 54.1611],
                        [-67.7296, 54.1513],
                        [-67.7483, 54.1576],
                        [-67.765, 54.157],
                        [-67.7818, 54.1484],
                        [-67.8103, 54.1281],
                        [-67.7982, 54.1186],
                        [-67.8055, 54.1062],
                        [-67.7919, 54.0956],
                        [-67.7819, 54.0959],
                        [-67.7677, 54.0999],
                        [-67.7581, 54.0916],
                        [-67.7618, 54.086],
                        [-67.7884, 54.0855],
                        [-67.7788, 54.0714],
                        [-67.7858, 54.0586],
                        [-67.7941, 54.0545],
                        [-67.791, 54.0413],
                        [-67.8147, 54.0321],
                        [-67.8217, 54.0257],
                        [-67.816, 54.0148],
                        [-67.7831, 54.0087],
                        [-67.7873, 54],
                        [-67.7808, 53.9943],
                        [-67.7665, 53.9939],
                        [-67.7623, 54],
                        [-67.7545, 54],
                        [-67.7434, 53.9862],
                        [-67.7336, 53.9797],
                        [-67.7252, 53.9778],
                        [-67.6998, 53.9766],
                        [-67.6813, 53.9719],
                        [-67.6845, 53.9663],
                        [-67.662, 53.9523],
                        [-67.6769, 53.9455],
                        [-67.6796, 53.9407],
                        [-67.6693, 53.9303],
                        [-67.6533, 53.9281],
                        [-67.6371, 53.9304],
                        [-67.6324, 53.9238],
                        [-67.6111, 53.9255],
                        [-67.5963, 53.9217],
                        [-67.5982, 53.9157],
                        [-67.6092, 53.902],
                        [-67.5938, 53.8958],
                        [-67.6127, 53.8951],
                        [-67.6142, 53.8849],
                        [-67.6053, 53.8773],
                        [-67.6091, 53.8692],
                        [-67.5928, 53.8622],
                        [-67.5805, 53.8682],
                        [-67.5795, 53.8609],
                        [-67.5643, 53.8586],
                        [-67.5715, 53.8456],
                        [-67.5691, 53.8411],
                        [-67.5559, 53.8374],
                        [-67.5366, 53.8402],
                        [-67.5094, 53.834],
                        [-67.4978, 53.8367],
                        [-67.4996, 53.8269],
                        [-67.5221, 53.8162],
                        [-67.536, 53.8012],
                        [-67.5451, 53.7982],
                        [-67.6022, 53.7955],
                        [-67.6042, 53.7902],
                        [-67.5758, 53.7825],
                        [-67.5573, 53.7803],
                        [-67.5675, 53.7709],
                        [-67.5993, 53.7662],
                        [-67.6033, 53.7627],
                        [-67.5922, 53.7575],
                        [-67.5668, 53.7559],
                        [-67.539, 53.7586],
                        [-67.5167, 53.7555],
                        [-67.5, 53.7573],
                        [-67.5, 53.746],
                        [-67.5057, 53.7446],
                        [-67.5175, 53.7352],
                        [-67.516, 53.7287],
                        [-67.5057, 53.7247],
                        [-67.5063, 53.7191],
                        [-67.4651, 53.7089],
                        [-67.4463, 53.7063],
                        [-67.4337, 53.7065],
                        [-67.4328, 53.7127],
                        [-67.4406, 53.7242],
                        [-67.4178, 53.7251],
                        [-67.4194, 53.7188],
                        [-67.4275, 53.7133],
                        [-67.4216, 53.6965],
                        [-67.4138, 53.6905],
                        [-67.3968, 53.6829],
                        [-67.3963, 53.6773],
                        [-67.4142, 53.6749],
                        [-67.4131, 53.6669],
                        [-67.404, 53.6589],
                        [-67.3894, 53.6423],
                        [-67.3926, 53.6392],
                        [-67.3872, 53.6289],
                        [-67.3951, 53.6189],
                        [-67.3947, 53.6147],
                        [-67.3737, 53.6083],
                        [-67.3793, 53.6016],
                        [-67.3769, 53.5972],
                        [-67.3534, 53.5954],
                        [-67.335, 53.5868],
                        [-67.3135, 53.5842],
                        [-67.3237, 53.559],
                        [-67.3082, 53.5462],
                        [-67.2984, 53.5432],
                        [-67.2863, 53.5435],
                        [-67.2724, 53.552],
                        [-67.2574, 53.5574],
                        [-67.2409, 53.5437],
                        [-67.2188, 53.5303],
                        [-67.2062, 53.5337],
                        [-67.1891, 53.5328],
                        [-67.1891, 53.5508],
                        [-67.18, 53.553],
                        [-67.1577, 53.5376],
                        [-67.1299, 53.5302],
                        [-67.1188, 53.5307],
                        [-67.0999, 53.5267],
                        [-67.0843, 53.529],
                        [-67.0721, 53.535],
                        [-67.062, 53.5371],
                        [-67.0537, 53.5296],
                        [-67.0428, 53.5266],
                        [-67.0303, 53.5267],
                        [-67.0204, 53.5137],
                        [-67.0114, 53.5096],
                        [-67.0067, 53.5],
                        [-67, 53.496],
                        [-66.9856, 53.4961],
                        [-66.9838, 53.4925],
                        [-66.9963, 53.4846],
                        [-66.999, 53.4737],
                        [-66.9958, 53.4642],
                        [-66.9711, 53.4665],
                        [-66.9445, 53.4761],
                        [-66.9379, 53.4731],
                        [-66.9346, 53.4607],
                        [-66.925, 53.4545],
                        [-66.935, 53.4415],
                        [-66.9244, 53.4187],
                        [-66.9079, 53.4146],
                        [-66.8945, 53.4199],
                        [-66.8905, 53.4093],
                        [-66.9067, 53.3953],
                        [-66.9229, 53.3999],
                        [-66.9333, 53.3919],
                        [-66.9463, 53.3909],
                        [-66.9535, 53.3837],
                        [-66.9541, 53.3726],
                        [-66.9661, 53.3646],
                        [-66.9847, 53.3727],
                        [-66.996, 53.3727],
                        [-67.0137, 53.3619],
                        [-67.0093, 53.3573],
                        [-66.9971, 53.3571],
                        [-66.997, 53.3526],
                        [-67.0213, 53.3422],
                        [-67.0271, 53.3346],
                        [-67.0253, 53.3262],
                        [-67.0188, 53.3201],
                        [-66.9955, 53.3204],
                        [-66.9584, 53.3065],
                        [-66.9534, 53.2904],
                        [-66.9543, 53.2836],
                        [-66.9647, 53.2739],
                        [-66.9762, 53.2456],
                        [-66.9756, 53.2371],
                        [-66.9781, 53.2133],
                        [-66.9736, 53.2003],
                        [-66.9634, 53.191],
                        [-66.9596, 53.1817],
                        [-66.9826, 53.1778],
                        [-67.0061, 53.1685],
                        [-67.0135, 53.16],
                        [-67.0141, 53.1521],
                        [-67.0072, 53.1443],
                        [-66.9952, 53.1385],
                        [-66.9587, 53.1371],
                        [-66.96, 53.1329],
                        [-66.9849, 53.123],
                        [-66.9909, 53.1175],
                        [-66.9844, 53.0879],
                        [-66.9895, 53.0844],
                        [-67.0013, 53.0844],
                        [-67.024, 53.0784],
                        [-67.0416, 53.0761],
                        [-67.0568, 53.0789],
                        [-67.0685, 53.0789],
                        [-67.0653, 53.089],
                        [-67.069, 53.1008],
                        [-67.0531, 53.1101],
                        [-67.0468, 53.1185],
                        [-67.0525, 53.1327],
                        [-67.0489, 53.1395],
                        [-67.0612, 53.1419],
                        [-67.0753, 53.1393],
                        [-67.0887, 53.1259],
                        [-67.1153, 53.1119],
                        [-67.1251, 53.125],
                        [-67.1411, 53.1289],
                        [-67.1344, 53.1379],
                        [-67.1542, 53.1472],
                        [-67.2177, 53.1497],
                        [-67.249, 53.1657],
                        [-67.2544, 53.1782],
                        [-67.263, 53.1813],
                        [-67.2739, 53.1801],
                        [-67.294, 53.1639],
                        [-67.3027, 53.1589],
                        [-67.2968, 53.1511],
                        [-67.2872, 53.1439],
                        [-67.2931, 53.1383],
                        [-67.3044, 53.1382],
                        [-67.318, 53.13],
                        [-67.3321, 53.1241],
                        [-67.3464, 53.1321],
                        [-67.365, 53.1219],
                        [-67.3619, 53.132],
                        [-67.3714, 53.1353],
                        [-67.3854, 53.132],
                        [-67.3923, 53.1209],
                        [-67.3884, 53.1077],
                        [-67.393, 53.1008],
                        [-67.3905, 53.0768],
                        [-67.387, 53.0702],
                        [-67.3808, 53.0463],
                        [-67.3639, 53.0255],
                        [-67.3554, 53.009],
                        [-67.3689, 53.0023],
                        [-67.3538, 52.9868],
                        [-67.3454, 52.9812],
                        [-67.3083, 52.9779],
                        [-67.2833, 52.9842],
                        [-67.2553, 52.9793],
                        [-67.2485, 52.976],
                        [-67.2758, 52.9638],
                        [-67.2975, 52.9656],
                        [-67.3069, 52.9689],
                        [-67.3215, 52.9654],
                        [-67.3327, 52.9598],
                        [-67.3442, 52.9418],
                        [-67.3519, 52.9367],
                        [-67.3557, 52.9286],
                        [-67.3508, 52.9226],
                        [-67.3387, 52.916],
                        [-67.3329, 52.9072],
                        [-67.3419, 52.8962],
                        [-67.3361, 52.8896],
                        [-67.3168, 52.8754],
                        [-67.2998, 52.8845],
                        [-67.2685, 52.8777],
                        [-67.2599, 52.8732],
                        [-67.2399, 52.8589],
                        [-67.2409, 52.8539],
                        [-67.2275, 52.8418],
                        [-67.2148, 52.8436],
                        [-67.204, 52.8356],
                        [-67.1855, 52.832],
                        [-67.1754, 52.8214],
                        [-67.1611, 52.8175],
                        [-67.1549, 52.8228],
                        [-67.1556, 52.8498],
                        [-67.1495, 52.8544],
                        [-67.1342, 52.851],
                        [-67.1226, 52.8624],
                        [-67.1062, 52.8654],
                        [-67.0956, 52.8699],
                        [-67.0743, 52.8755],
                        [-67.0632, 52.8755],
                        [-67.0541, 52.8681],
                        [-67.0559, 52.8573],
                        [-67.0484, 52.8463],
                        [-67.0477, 52.8372],
                        [-67.0623, 52.8182],
                        [-67.0578, 52.8064],
                        [-67.0627, 52.7839],
                        [-67.067, 52.7751],
                        [-67.0492, 52.774],
                        [-67.0396, 52.7675],
                        [-67.0383, 52.7555],
                        [-67.0343, 52.747],
                        [-67.0248, 52.7557],
                        [-67.0171, 52.7522],
                        [-67.0161, 52.7441],
                        [-66.9996, 52.7593],
                        [-66.9898, 52.7622],
                        [-66.979, 52.7531],
                        [-66.9496, 52.7596],
                        [-66.9492, 52.7472],
                        [-66.9442, 52.7369],
                        [-66.9298, 52.7289],
                        [-66.9176, 52.7152],
                        [-66.902, 52.688],
                        [-66.9029, 52.6839],
                        [-66.8948, 52.6767],
                        [-66.8864, 52.6752],
                        [-66.8727, 52.681],
                        [-66.8637, 52.6736],
                        [-66.8494, 52.6779],
                        [-66.8579, 52.6921],
                        [-66.8768, 52.7135],
                        [-66.8979, 52.7275],
                        [-66.8915, 52.7326],
                        [-66.8671, 52.7187],
                        [-66.854, 52.7212],
                        [-66.8604, 52.7481],
                        [-66.8664, 52.764],
                        [-66.8602, 52.7684],
                        [-66.8365, 52.7458],
                        [-66.8263, 52.7231],
                        [-66.8171, 52.7113],
                        [-66.8046, 52.7125],
                        [-66.8014, 52.7087],
                        [-66.7924, 52.6842],
                        [-66.7864, 52.673],
                        [-66.765, 52.6662],
                        [-66.7579, 52.6733],
                        [-66.7724, 52.6962],
                        [-66.7639, 52.7002],
                        [-66.7479, 52.7031],
                        [-66.7508, 52.7141],
                        [-66.7558, 52.7205],
                        [-66.7541, 52.7515],
                        [-66.7753, 52.7753],
                        [-66.78, 52.7873],
                        [-66.7871, 52.7974],
                        [-66.7732, 52.7973],
                        [-66.7654, 52.7916],
                        [-66.758, 52.7814],
                        [-66.7466, 52.7733],
                        [-66.7197, 52.7636],
                        [-66.6932, 52.7622],
                        [-66.6866, 52.7637],
                        [-66.6867, 52.7835],
                        [-66.6805, 52.7911],
                        [-66.6671, 52.7883],
                        [-66.6605, 52.7822],
                        [-66.6511, 52.7806],
                        [-66.6464, 52.7889],
                        [-66.6619, 52.8081],
                        [-66.6652, 52.8147],
                        [-66.6651, 52.8309],
                        [-66.6482, 52.8478],
                        [-66.6454, 52.8528],
                        [-66.6559, 52.8664],
                        [-66.6454, 52.8726],
                        [-66.6495, 52.8776],
                        [-66.664, 52.8855],
                        [-66.6636, 52.8903],
                        [-66.6496, 52.8997],
                        [-66.6423, 52.8974],
                        [-66.6324, 52.9008],
                        [-66.6424, 52.9143],
                        [-66.6506, 52.9219],
                        [-66.6332, 52.9314],
                        [-66.6325, 52.9476],
                        [-66.6259, 52.9543],
                        [-66.6021, 52.9498],
                        [-66.5939, 52.9412],
                        [-66.5773, 52.9368],
                        [-66.5564, 52.9418],
                        [-66.5481, 52.9411],
                        [-66.5309, 52.9484],
                        [-66.5264, 52.9613],
                        [-66.5146, 52.9609],
                        [-66.5073, 52.9501],
                        [-66.4951, 52.9502],
                        [-66.4886, 52.9605],
                        [-66.4901, 52.9743],
                        [-66.4956, 52.9811],
                        [-66.489, 52.9857],
                        [-66.4848, 52.9979],
                        [-66.4699, 52.991],
                        [-66.4622, 53.0001],
                        [-66.4746, 53.0223],
                        [-66.4703, 53.0286],
                        [-66.4753, 53.0425],
                        [-66.462, 53.0469],
                        [-66.4595, 53.0552],
                        [-66.4429, 53.0474],
                        [-66.4321, 53.0482],
                        [-66.4064, 53.0282],
                        [-66.3938, 53.0212],
                        [-66.3735, 53.0206],
                        [-66.3611, 53.0144],
                        [-66.3552, 53.0001],
                        [-66.3501, 53.0001],
                        [-66.3433, 52.9877],
                        [-66.327, 52.9726],
                        [-66.3271, 52.9564],
                        [-66.3223, 52.9389],
                        [-66.3119, 52.9267],
                        [-66.2954, 52.9186],
                        [-66.2891, 52.9101],
                        [-66.2807, 52.9052],
                        [-66.2731, 52.896],
                        [-66.2765, 52.8838],
                        [-66.2612, 52.8743],
                        [-66.2601, 52.8659],
                        [-66.2705, 52.8658],
                        [-66.2949, 52.8601],
                        [-66.2884, 52.8538],
                        [-66.274, 52.8461],
                        [-66.2753, 52.8403],
                        [-66.2835, 52.8334],
                        [-66.3053, 52.841],
                        [-66.3209, 52.8567],
                        [-66.34, 52.8638],
                        [-66.3569, 52.8591],
                        [-66.3691, 52.8631],
                        [-66.3797, 52.8775],
                        [-66.3989, 52.8789],
                        [-66.411, 52.8724],
                        [-66.4166, 52.8583],
                        [-66.4142, 52.8487],
                        [-66.3887, 52.8268],
                        [-66.3778, 52.8141],
                        [-66.3858, 52.7996],
                        [-66.3818, 52.7945],
                        [-66.3545, 52.8026],
                        [-66.3446, 52.7954],
                        [-66.3516, 52.7841],
                        [-66.3312, 52.7612],
                        [-66.3276, 52.7532],
                        [-66.3321, 52.7415],
                        [-66.3268, 52.7366],
                        [-66.3086, 52.7286],
                        [-66.3085, 52.7249],
                        [-66.3213, 52.7188],
                        [-66.3238, 52.7126],
                        [-66.3377, 52.7014],
                        [-66.3364, 52.6889],
                        [-66.3391, 52.6846],
                        [-66.3289, 52.6767],
                        [-66.314, 52.6697],
                        [-66.3081, 52.663],
                        [-66.3118, 52.659],
                        [-66.297, 52.6454],
                        [-66.304, 52.6372],
                        [-66.2961, 52.6346],
                        [-66.2777, 52.6345],
                        [-66.2812, 52.6243],
                        [-66.2802, 52.6174],
                        [-66.2849, 52.6042],
                        [-66.2908, 52.6001],
                        [-66.3093, 52.6046],
                        [-66.32, 52.6134],
                        [-66.3238, 52.6219],
                        [-66.3408, 52.6454],
                        [-66.3508, 52.6504],
                        [-66.3632, 52.66],
                        [-66.3661, 52.6674],
                        [-66.3829, 52.6801],
                        [-66.3887, 52.6823],
                        [-66.4022, 52.6745],
                        [-66.4052, 52.6672],
                        [-66.4176, 52.6512],
                        [-66.4164, 52.642],
                        [-66.4328, 52.6398],
                        [-66.4403, 52.6364],
                        [-66.4399, 52.6295],
                        [-66.4274, 52.6183],
                        [-66.4208, 52.607],
                        [-66.4041, 52.593],
                        [-66.3908, 52.5765],
                        [-66.3782, 52.5647],
                        [-66.3711, 52.552],
                        [-66.3681, 52.5309],
                        [-66.3901, 52.5315],
                        [-66.3955, 52.5244],
                        [-66.3703, 52.513],
                        [-66.3646, 52.5072],
                        [-66.3639, 52.4943],
                        [-66.3581, 52.4883],
                        [-66.3556, 52.4732],
                        [-66.3606, 52.4664],
                        [-66.3725, 52.4732],
                        [-66.3816, 52.4841],
                        [-66.3939, 52.4864],
                        [-66.3908, 52.4659],
                        [-66.3588, 52.4308],
                        [-66.3615, 52.4256],
                        [-66.3728, 52.4167],
                        [-66.3729, 52.413],
                        [-66.3564, 52.4034],
                        [-66.3417, 52.3972],
                        [-66.3401, 52.3926],
                        [-66.3465, 52.3808],
                        [-66.3437, 52.3749],
                        [-66.344, 52.3604],
                        [-66.3545, 52.3536],
                        [-66.3638, 52.3514],
                        [-66.3702, 52.3539],
                        [-66.3749, 52.3633],
                        [-66.3871, 52.3698],
                        [-66.4098, 52.3745],
                        [-66.4199, 52.383],
                        [-66.4411, 52.3758],
                        [-66.4366, 52.3588],
                        [-66.4533, 52.3484],
                        [-66.4673, 52.3545],
                        [-66.4769, 52.3516],
                        [-66.4905, 52.3416],
                        [-66.4853, 52.3261],
                        [-66.4881, 52.3215],
                        [-66.4798, 52.3155],
                        [-66.4677, 52.3002],
                        [-66.4554, 52.2977],
                        [-66.4688, 52.2847],
                        [-66.4563, 52.2796],
                        [-66.4479, 52.2815],
                        [-66.4394, 52.2772],
                        [-66.4329, 52.2662],
                        [-66.4551, 52.2627],
                        [-66.4704, 52.2557],
                        [-66.4541, 52.2463],
                        [-66.4455, 52.2362],
                        [-66.4381, 52.2235],
                        [-66.426, 52.2174],
                        [-66.4175, 52.2273],
                        [-66.411, 52.2304],
                        [-66.4027, 52.2266],
                        [-66.4027, 52.2169],
                        [-66.3896, 52.2126],
                        [-66.3755, 52.1983],
                        [-66.3713, 52.1874],
                        [-66.3568, 52.1766],
                        [-66.3624, 52.1691],
                        [-66.3798, 52.1626],
                        [-66.3764, 52.1528],
                        [-66.358, 52.1466],
                        [-66.3332, 52.1507],
                        [-66.3301, 52.1359],
                        [-66.3236, 52.1318],
                        [-66.3068, 52.1368],
                        [-66.2917, 52.1468],
                        [-66.2694, 52.1492],
                        [-66.2651, 52.155],
                        [-66.2641, 52.1652],
                        [-66.2674, 52.1735],
                        [-66.278, 52.19],
                        [-66.2867, 52.2085],
                        [-66.2929, 52.2318],
                        [-66.2994, 52.2432],
                        [-66.2982, 52.2613],
                        [-66.3094, 52.2759],
                        [-66.3126, 52.2855],
                        [-66.2969, 52.2835],
                        [-66.2835, 52.2854],
                        [-66.2796, 52.2916],
                        [-66.2896, 52.2967],
                        [-66.2818, 52.3036],
                        [-66.2871, 52.3091],
                        [-66.26, 52.3093],
                        [-66.2537, 52.3041],
                        [-66.2539, 52.2974],
                        [-66.2487, 52.2896],
                        [-66.2521, 52.2802],
                        [-66.2496, 52.274],
                        [-66.2373, 52.2628],
                        [-66.2181, 52.2571],
                        [-66.2057, 52.2439],
                        [-66.2084, 52.2341],
                        [-66.2008, 52.227],
                        [-66.177, 52.2133],
                        [-66.1697, 52.2156],
                        [-66.16, 52.2317],
                        [-66.144, 52.2279],
                        [-66.1384, 52.2246],
                        [-66.128, 52.2102],
                        [-66.1091, 52.202],
                        [-66.1008, 52.1921],
                        [-66.0874, 52.1723],
                        [-66.0826, 52.1617],
                        [-66.0759, 52.155],
                        [-66.0787, 52.1413],
                        [-66.0758, 52.1308],
                        [-66.0782, 52.1247],
                        [-66.0964, 52.1078],
                        [-66.0969, 52.0907],
                        [-66.0905, 52.0907],
                        [-66.0771, 52.0969],
                        [-66.0663, 52.0978],
                        [-66.0496, 52.0854],
                        [-66.0351, 52.0851],
                        [-66.0156, 52.0799],
                        [-66.0165, 52.0727],
                        [-66.0029, 52.0672],
                        [-65.9962, 52.0547],
                        [-65.9788, 52.0527],
                        [-65.9704, 52.0565],
                        [-65.966, 52.0709],
                        [-65.9614, 52.0774],
                        [-65.9679, 52.0931],
                        [-65.9613, 52.1046],
                        [-65.9548, 52.0999],
                        [-65.9193, 52.0911],
                        [-65.9133, 52.0814],
                        [-65.8977, 52.0761],
                        [-65.8876, 52.0878],
                        [-65.889, 52.0971],
                        [-65.8857, 52.1034],
                        [-65.877, 52.1104],
                        [-65.8669, 52.1145],
                        [-65.842, 52.1165],
                        [-65.8222, 52.1102],
                        [-65.81, 52.1189],
                        [-65.8054, 52.1083],
                        [-65.7974, 52.1057],
                        [-65.7762, 52.1045],
                        [-65.7713, 52.1115],
                        [-65.7616, 52.1043],
                        [-65.753, 52.1078],
                        [-65.7383, 52.0852],
                        [-65.7293, 52.0835],
                        [-65.7139, 52.0962],
                        [-65.6866, 52.1038],
                        [-65.6748, 52.1176],
                        [-65.6645, 52.1151],
                        [-65.6669, 52.1064],
                        [-65.6624, 52.0924],
                        [-65.6486, 52.0809],
                        [-65.6537, 52.0678],
                        [-65.6512, 52.0553],
                        [-65.6581, 52.0499],
                        [-65.6799, 52.0415],
                        [-65.6695, 52.0322],
                        [-65.6687, 52.0263],
                        [-65.6782, 52.0135],
                        [-65.6774, 52.0077],
                        [-65.6686, 51.9944],
                        [-65.6597, 51.9894],
                        [-65.6468, 51.9873],
                        [-65.6382, 51.9925],
                        [-65.634, 52],
                        [-65.6367, 52.0117],
                        [-65.6268, 52.0267],
                        [-65.6205, 52.043],
                        [-65.5983, 52.0349],
                        [-65.5793, 52.0328],
                        [-65.5642, 52.0339],
                        [-65.5484, 52.0389],
                        [-65.5399, 52.0446],
                        [-65.5418, 52.0493],
                        [-65.5311, 52.0606],
                        [-65.5307, 52.0708],
                        [-65.5138, 52.0828],
                        [-65.5018, 52.098],
                        [-65.499, 52.1062],
                        [-65.4901, 52.1021],
                        [-65.494, 52.0861],
                        [-65.4972, 52.0603],
                        [-65.4845, 52.0569],
                        [-65.4961, 52.0502],
                        [-65.4562, 52.0111],
                        [-65.4512, 52.0086],
                        [-65.4268, 52.004],
                        [-65.4249, 51.9934],
                        [-65.411, 51.9864],
                        [-65.4123, 51.9713],
                        [-65.39, 51.9634],
                        [-65.3712, 51.9673],
                        [-65.3749, 51.9765],
                        [-65.3645, 51.9809],
                        [-65.3839, 51.9888],
                        [-65.3714, 51.9903],
                        [-65.3591, 51.9811],
                        [-65.3604, 51.956],
                        [-65.3497, 51.9446],
                        [-65.3548, 51.9427],
                        [-65.3741, 51.9158],
                        [-65.3932, 51.9149],
                        [-65.3995, 51.9019],
                        [-65.3976, 51.8908],
                        [-65.3922, 51.881],
                        [-65.3851, 51.8739],
                        [-65.3834, 51.8673],
                        [-65.3728, 51.8642],
                        [-65.3626, 51.8642],
                        [-65.3568, 51.8444],
                        [-65.355, 51.8299],
                        [-65.3598, 51.8228],
                        [-65.3461, 51.8197],
                        [-65.3368, 51.8232],
                        [-65.327, 51.8364],
                        [-65.3252, 51.8432],
                        [-65.3178, 51.8508],
                        [-65.3233, 51.87],
                        [-65.3002, 51.878],
                        [-65.2907, 51.8697],
                        [-65.2835, 51.8738],
                        [-65.2539, 51.8615],
                        [-65.2531, 51.8573],
                        [-65.2644, 51.8367],
                        [-65.2632, 51.828],
                        [-65.2548, 51.8234],
                        [-65.2477, 51.8296],
                        [-65.2405, 51.8227],
                        [-65.2289, 51.8216],
                        [-65.2102, 51.8129],
                        [-65.2046, 51.8128],
                        [-65.1954, 51.8215],
                        [-65.1886, 51.8173],
                        [-65.1802, 51.8231],
                        [-65.1696, 51.8221],
                        [-65.1729, 51.8096],
                        [-65.1672, 51.7966],
                        [-65.1687, 51.7898],
                        [-65.1861, 51.7756],
                        [-65.1867, 51.7699],
                        [-65.1804, 51.7656],
                        [-65.1623, 51.7646],
                        [-65.1423, 51.7579],
                        [-65.1196, 51.7537],
                        [-65.1077, 51.7487],
                        [-65.0826, 51.7462],
                        [-65.071, 51.7622],
                        [-65.0492, 51.7696],
                        [-65.0426, 51.7645],
                        [-65.0204, 51.7614],
                        [-65.0205, 51.7586],
                        [-65.0083, 51.75],
                        [-64.9992, 51.75],
                        [-64.9963, 51.7419],
                        [-64.987, 51.7314],
                        [-64.9724, 51.7216],
                        [-64.9577, 51.7166],
                        [-64.9307, 51.7143],
                        [-64.9249, 51.7186],
                        [-64.9164, 51.735],
                        [-64.9098, 51.7367],
                        [-64.9164, 51.744],
                        [-64.9276, 51.7464],
                        [-64.9184, 51.755],
                        [-64.9198, 51.7608],
                        [-64.9386, 51.7644],
                        [-64.9247, 51.7779],
                        [-64.8922, 51.7688],
                        [-64.8722, 51.77],
                        [-64.8598, 51.764],
                        [-64.8478, 51.7625],
                        [-64.8384, 51.7696],
                        [-64.8118, 51.7778],
                        [-64.8019, 51.7755],
                        [-64.794, 51.7612],
                        [-64.7731, 51.7531],
                        [-64.7555, 51.7589],
                        [-64.7394, 51.7615],
                        [-64.7309, 51.7522],
                        [-64.7098, 51.756],
                        [-64.7109, 51.7456],
                        [-64.7079, 51.7293],
                        [-64.7031, 51.7232],
                        [-64.6881, 51.717],
                        [-64.6916, 51.7076],
                        [-64.6811, 51.7098],
                        [-64.6774, 51.7014],
                        [-64.6535, 51.7015],
                        [-64.6399, 51.705],
                        [-64.6447, 51.6917],
                        [-64.6378, 51.6792],
                        [-64.6404, 51.674],
                        [-64.6211, 51.6681],
                        [-64.6169, 51.6514],
                        [-64.626, 51.6469],
                        [-64.6237, 51.6422],
                        [-64.6242, 51.629],
                        [-64.6144, 51.6267],
                        [-64.5969, 51.6276],
                        [-64.5873, 51.6095],
                        [-64.5754, 51.6018],
                        [-64.5544, 51.5758],
                        [-64.5485, 51.5739],
                        [-64.5416, 51.5864],
                        [-64.5076, 51.6055],
                        [-64.4909, 51.6091],
                        [-64.4553, 51.6246],
                        [-64.4478, 51.6322],
                        [-64.4308, 51.6578],
                        [-64.4299, 51.6678],
                        [-64.423, 51.6761],
                        [-64.4173, 51.675],
                        [-64.3956, 51.664],
                        [-64.3775, 51.664],
                        [-64.3589, 51.6716],
                        [-64.3371, 51.6653],
                        [-64.3343, 51.6716],
                        [-64.3221, 51.685],
                        [-64.3256, 51.6942],
                        [-64.3224, 51.698],
                        [-64.3052, 51.7036],
                        [-64.2965, 51.7137],
                        [-64.294, 51.7228],
                        [-64.2764, 51.7365],
                        [-64.2783, 51.7464],
                        [-64.2988, 51.7549],
                        [-64.3048, 51.7628],
                        [-64.3043, 51.781],
                        [-64.3072, 51.7881],
                        [-64.3012, 51.7966],
                        [-64.3025, 51.8036],
                        [-64.3209, 51.8139],
                        [-64.3387, 51.8288],
                        [-64.3527, 51.8493],
                        [-64.352, 51.8636],
                        [-64.353, 51.887],
                        [-64.3401, 51.8905],
                        [-64.3443, 51.9127],
                        [-64.3498, 51.9219],
                        [-64.3573, 51.9274],
                        [-64.3451, 51.9375],
                        [-64.3496, 51.9443],
                        [-64.3445, 51.9507],
                        [-64.343, 51.9677],
                        [-64.3453, 51.9733],
                        [-64.3588, 51.9791],
                        [-64.3417, 51.9859],
                        [-64.348, 52.0013],
                        [-64.3385, 52.0072],
                        [-64.3229, 52.0049],
                        [-64.3269, 51.9984],
                        [-64.323, 51.9944],
                        [-64.2992, 51.9864],
                        [-64.2879, 51.9767],
                        [-64.2801, 51.9748],
                        [-64.2371, 51.978],
                        [-64.234, 51.9909],
                        [-64.2465, 51.9974],
                        [-64.2465, 52.0009],
                        [-64.2662, 52.0216],
                        [-64.2794, 52.0376],
                        [-64.2693, 52.0469],
                        [-64.2811, 52.0559],
                        [-64.2873, 52.0656],
                        [-64.2919, 52.0776],
                        [-64.2787, 52.0761],
                        [-64.2724, 52.0793],
                        [-64.2515, 52.0797],
                        [-64.2497, 52.0873],
                        [-64.2566, 52.0972],
                        [-64.246, 52.103],
                        [-64.2564, 52.1129],
                        [-64.2539, 52.1215],
                        [-64.2363, 52.1294],
                        [-64.2424, 52.1428],
                        [-64.2112, 52.1322],
                        [-64.193, 52.1244],
                        [-64.1911, 52.1208],
                        [-64.173, 52.1129],
                        [-64.1664, 52.1161],
                        [-64.1647, 52.1239],
                        [-64.1774, 52.1338],
                        [-64.1647, 52.1388],
                        [-64.1608, 52.146],
                        [-64.1883, 52.1641],
                        [-64.2007, 52.177],
                        [-64.1984, 52.1888],
                        [-64.2099, 52.2028],
                        [-64.2097, 52.2185],
                        [-64.2135, 52.2249],
                        [-64.224, 52.2295],
                        [-64.2198, 52.2377],
                        [-64.2236, 52.2404],
                        [-64.2176, 52.2571],
                        [-64.2212, 52.261],
                        [-64.2365, 52.2639],
                        [-64.2466, 52.2754],
                        [-64.2388, 52.2775],
                        [-64.2327, 52.2879],
                        [-64.2219, 52.2833],
                        [-64.2098, 52.2845],
                        [-64.2069, 52.2942],
                        [-64.2256, 52.3025],
                        [-64.2256, 52.3077],
                        [-64.2102, 52.3136],
                        [-64.1996, 52.3056],
                        [-64.1946, 52.2913],
                        [-64.1916, 52.2897],
                        [-64.1801, 52.3064],
                        [-64.1695, 52.3067],
                        [-64.1647, 52.3144],
                        [-64.1733, 52.3245],
                        [-64.172, 52.3308],
                        [-64.1615, 52.3339],
                        [-64.1584, 52.339],
                        [-64.1664, 52.3445],
                        [-64.1666, 52.3602],
                        [-64.138, 52.3669],
                        [-64.127, 52.3838],
                        [-64.1155, 52.3883],
                        [-64.1092, 52.3941],
                        [-64.119, 52.4032],
                        [-64.1074, 52.4083],
                        [-64.1147, 52.42],
                        [-64.128, 52.4206],
                        [-64.135, 52.4245],
                        [-64.1278, 52.4343],
                        [-64.1352, 52.4449],
                        [-64.1371, 52.4565],
                        [-64.1426, 52.4679],
                        [-64.1447, 52.4916],
                        [-64.1529, 52.5022],
                        [-64.1485, 52.5138],
                        [-64.1519, 52.5199],
                        [-64.1671, 52.5288],
                        [-64.1702, 52.5436],
                        [-64.1914, 52.5543],
                        [-64.2111, 52.5736],
                        [-64.1991, 52.5817],
                        [-64.2042, 52.5891],
                        [-64.1947, 52.5958],
                        [-64.1875, 52.5908],
                        [-64.1715, 52.5843],
                        [-64.1677, 52.596],
                        [-64.1546, 52.6034],
                        [-64.1685, 52.6121],
                        [-64.1701, 52.6237],
                        [-64.1633, 52.6274],
                        [-64.1531, 52.6404],
                        [-64.153, 52.6473],
                        [-64.1589, 52.6585],
                        [-64.173, 52.6706],
                        [-64.1676, 52.6783],
                        [-64.1535, 52.6731],
                        [-64.145, 52.6754],
                        [-64.1277, 52.6864],
                        [-64.1244, 52.6993],
                        [-64.1461, 52.7145],
                        [-64.1405, 52.7267],
                        [-64.1242, 52.732],
                        [-64.0896, 52.7332],
                        [-64.0795, 52.7366],
                        [-64.0629, 52.725],
                        [-64.0535, 52.7232],
                        [-64.0393, 52.7259],
                        [-64.0354, 52.7322],
                        [-64.0373, 52.7382],
                        [-64.007, 52.737],
                        [-63.9827, 52.7254],
                        [-63.9592, 52.7293],
                        [-63.9374, 52.731],
                        [-63.9212, 52.7359],
                        [-63.9182, 52.7401],
                        [-63.9213, 52.7564],
                        [-63.9147, 52.7577],
                        [-63.893, 52.7501],
                        [-63.8833, 52.7533],
                        [-63.8939, 52.7639],
                        [-63.8788, 52.7664],
                        [-63.8729, 52.774],
                        [-63.8541, 52.7734],
                        [-63.8347, 52.7637],
                        [-63.819, 52.7585],
                        [-63.8099, 52.7599],
                        [-63.798, 52.7665],
                        [-63.7782, 52.7699],
                        [-63.7644, 52.7741],
                        [-63.7516, 52.7684],
                        [-63.7215, 52.7703],
                        [-63.7194, 52.7766],
                        [-63.7319, 52.782],
                        [-63.7307, 52.7874],
                        [-63.7141, 52.7864],
                        [-63.7195, 52.799],
                        [-63.7119, 52.8033],
                        [-63.7007, 52.8035],
                        [-63.6898, 52.8084],
                        [-63.6804, 52.8081],
                        [-63.6601, 52.8112],
                        [-63.653, 52.8142],
                        [-63.6441, 52.8238],
                        [-63.6394, 52.8337],
                        [-63.6417, 52.8373],
                        [-63.6761, 52.8491],
                        [-63.6718, 52.8551],
                        [-63.6521, 52.8529],
                        [-63.6379, 52.8469],
                        [-63.6164, 52.8416],
                        [-63.6066, 52.8499],
                        [-63.61, 52.8549],
                        [-63.6242, 52.8624],
                        [-63.6194, 52.87],
                        [-63.6233, 52.8751],
                        [-63.6144, 52.8775],
                        [-63.6079, 52.8593],
                        [-63.5941, 52.8515],
                        [-63.5871, 52.8395],
                        [-63.5892, 52.8331],
                        [-63.5989, 52.8269],
                        [-63.595, 52.8164],
                        [-63.6006, 52.8058],
                        [-63.5964, 52.7956],
                        [-63.6157, 52.7918],
                        [-63.6165, 52.7742],
                        [-63.6098, 52.7703],
                        [-63.5853, 52.7659],
                        [-63.5701, 52.7598],
                        [-63.5572, 52.7489],
                        [-63.5509, 52.7411],
                        [-63.528, 52.7276],
                        [-63.5184, 52.7253],
                        [-63.5065, 52.7271],
                        [-63.4594, 52.7181],
                        [-63.45, 52.7136],
                        [-63.4358, 52.6995],
                        [-63.4232, 52.6923],
                        [-63.3965, 52.6823],
                        [-63.3836, 52.6671],
                        [-63.3838, 52.6594],
                        [-63.3913, 52.6582],
                        [-63.4081, 52.6617],
                        [-63.4164, 52.6525],
                        [-63.4261, 52.652],
                        [-63.4294, 52.6455],
                        [-63.4631, 52.6482],
                        [-63.4668, 52.647],
                        [-63.5019, 52.6496],
                        [-63.5145, 52.6461],
                        [-63.5233, 52.6488],
                        [-63.5835, 52.6487],
                        [-63.6134, 52.6471],
                        [-63.653, 52.6388],
                        [-63.7139, 52.6331],
                        [-63.7367, 52.632],
                        [-63.7532, 52.6353],
                        [-63.7666, 52.6347],
                        [-63.7739, 52.6315],
                        [-63.7967, 52.6303],
                        [-63.8108, 52.6281],
                        [-63.8258, 52.6226],
                        [-63.851, 52.6225],
                        [-63.8717, 52.6188],
                        [-63.8799, 52.6138],
                        [-63.8945, 52.6126],
                        [-63.9076, 52.6083],
                        [-63.9117, 52.6034],
                        [-63.9397, 52.6057],
                        [-63.9464, 52.5963],
                        [-63.9596, 52.5961],
                        [-63.9699, 52.5894],
                        [-63.9927, 52.5838],
                        [-63.996, 52.5791],
                        [-64.0125, 52.5708],
                        [-64.0299, 52.567],
                        [-64.038, 52.5611],
                        [-64.0448, 52.5416],
                        [-64.0538, 52.5333],
                        [-64.072, 52.5001],
                        [-64.0846, 52.5041],
                        [-64.0855, 52.4933],
                        [-64.0935, 52.4661],
                        [-64.0902, 52.4543],
                        [-64.0741, 52.4383],
                        [-64.0455, 52.4233],
                        [-64.0206, 52.401],
                        [-64.0301, 52.3897],
                        [-64.0183, 52.3745],
                        [-64.0127, 52.3626],
                        [-63.9983, 52.3596],
                        [-63.9886, 52.3602],
                        [-63.9749, 52.3535],
                        [-63.9652, 52.3577],
                        [-63.9424, 52.3454],
                        [-63.9125, 52.3409],
                        [-63.8998, 52.3363],
                        [-63.8732, 52.3225],
                        [-63.8597, 52.3222],
                        [-63.8546, 52.3192],
                        [-63.8366, 52.3223],
                        [-63.8259, 52.3197],
                        [-63.8269, 52.3308],
                        [-63.7695, 52.3281],
                        [-63.7634, 52.3218],
                        [-63.7476, 52.3164],
                        [-63.7432, 52.308],
                        [-63.766, 52.3089],
                        [-63.7747, 52.3139],
                        [-63.7829, 52.3093],
                        [-63.789, 52.3155],
                        [-63.796, 52.3103],
                        [-63.8081, 52.312],
                        [-63.8176, 52.3183],
                        [-63.8197, 52.3034],
                        [-63.7941, 52.2969],
                        [-63.7707, 52.2839],
                        [-63.7685, 52.2761],
                        [-63.7846, 52.2761],
                        [-63.7919, 52.2704],
                        [-63.7657, 52.2694],
                        [-63.7516, 52.2607],
                        [-63.7482, 52.2555],
                        [-63.7581, 52.249],
                        [-63.7528, 52.235],
                        [-63.726, 52.2129],
                        [-63.7108, 52.191],
                        [-63.7125, 52.1804],
                        [-63.7002, 52.1655],
                        [-63.7033, 52.1542],
                        [-63.6967, 52.1365],
                        [-63.6906, 52.1298],
                        [-63.6971, 52.1141],
                        [-63.702, 52.0876],
                        [-63.6924, 52.0785],
                        [-63.6889, 52.0862],
                        [-63.6775, 52.0831],
                        [-63.6703, 52.0719],
                        [-63.6606, 52.0669],
                        [-63.6595, 52.0576],
                        [-63.648, 52.0488],
                        [-63.66, 52.0408],
                        [-63.672, 52.0515],
                        [-63.6845, 52.055],
                        [-63.6893, 52.0496],
                        [-63.7063, 52.0497],
                        [-63.7258, 52.0606],
                        [-63.7443, 52.0648],
                        [-63.7717, 52.0813],
                        [-63.7841, 52.0837],
                        [-63.7874, 52.0783],
                        [-63.8103, 52.0854],
                        [-63.824, 52.0847],
                        [-63.8322, 52.0815],
                        [-63.8401, 52.065],
                        [-63.8309, 52.0481],
                        [-63.8252, 52.0284],
                        [-63.8161, 52.0346],
                        [-63.809, 52.0298],
                        [-63.8092, 52.0241],
                        [-63.8027, 52.0174],
                        [-63.8017, 52.0099],
                        [-63.8081, 51.9978],
                        [-63.533, 51.9973],
                        [-63.2255, 51.9974],
                        [-63.0002, 51.9973],
                        [-62.8442, 51.997],
                        [-62.6988, 51.9972],
                        [-62.5, 51.9973],
                        [-62.3329, 51.9974],
                        [-62.1605, 51.9972],
                        [-62, 51.9967],
                        [-61.8065, 51.9958],
                        [-61.6434, 51.9963],
                        [-61.3686, 51.9966],
                        [-61.1299, 51.9962],
                        [-61, 51.9968],
                        [-60.7868, 51.9973],
                        [-60.4417, 51.9965],
                        [-60.2135, 51.9972],
                        [-60.0928, 51.9974],
                        [-59.8834, 51.9977],
                        [-59.7408, 51.9976],
                        [-59.5, 51.9975],
                        [-59.3881, 51.9973],
                        [-59.2499, 51.9973],
                        [-59.0344, 51.997],
                        [-58.7866, 51.9976],
                        [-58.5, 51.9975],
                        [-58.3229, 51.9971],
                        [-58.1651, 51.9969],
                        [-57.9647, 51.9964],
                        [-57.786, 51.9972],
                        [-57.6314, 51.9977],
                        [-57.3194, 51.9979],
                        [-57.1078, 51.9985],
                        [-57.1075, 51.9235],
                        [-57.1063, 51.7898],
                        [-57.1063, 51.7478],
                        [-57.1055, 51.6565],
                        [-57.1059, 51.591],
                        [-57.1079, 51.5909],
                        [-57.1075, 51.5205],
                        [-57.1085, 51.4154],
                        [-57.1359, 51.4259],
                        [-57.1486, 51.4222],
                        [-57.1508, 51.4161],
                        [-57.1657, 51.4089],
                        [-57.1788, 51.4088],
                        [-57.2068, 51.4115],
                        [-57.212, 51.4189],
                        [-57.2057, 51.4245],
                        [-57.2063, 51.4297],
                        [-57.2141, 51.4359],
                        [-57.2238, 51.4361],
                        [-57.2358, 51.4411],
                        [-57.2395, 51.4532],
                        [-57.2472, 51.4572],
                        [-57.2428, 51.4702],
                        [-57.2409, 51.4842],
                        [-57.2347, 51.4928],
                        [-57.2429, 51.4991],
                        [-57.2574, 51.4985],
                        [-57.2811, 51.4918],
                        [-57.2819, 51.4952],
                        [-57.303, 51.493],
                        [-57.3455, 51.4844],
                        [-57.3764, 51.4801],
                        [-57.3799, 51.481],
                        [-57.4064, 51.4773],
                        [-57.4114, 51.4734],
                        [-57.4126, 51.4633],
                        [-57.4275, 51.4637],
                        [-57.4269, 51.476],
                        [-57.4352, 51.4812],
                        [-57.4461, 51.4811],
                        [-57.4472, 51.4757],
                        [-57.4574, 51.4683],
                        [-57.447, 51.4669],
                        [-57.4369, 51.4592],
                        [-57.4354, 51.448],
                        [-57.4453, 51.4403],
                        [-57.4534, 51.4415],
                        [-57.4676, 51.4341],
                        [-57.4752, 51.4349],
                        [-57.4753, 51.4443],
                        [-57.4797, 51.4511],
                        [-57.4681, 51.4581],
                        [-57.4854, 51.4585],
                        [-57.4927, 51.4656],
                        [-57.4973, 51.4433],
                        [-57.5054, 51.4351],
                        [-57.515, 51.432],
                        [-57.5144, 51.4407],
                        [-57.5225, 51.4441],
                        [-57.5307, 51.4422],
                        [-57.5334, 51.4363],
                        [-57.5419, 51.4377],
                        [-57.5481, 51.4343],
                        [-57.563, 51.4396],
                        [-57.5686, 51.4354],
                        [-57.5836, 51.4347],
                        [-57.599, 51.4196],
                        [-57.6083, 51.4184],
                        [-57.6191, 51.4206],
                        [-57.609, 51.4335],
                        [-57.5917, 51.4428],
                        [-57.5826, 51.4553],
                        [-57.5954, 51.4581],
                        [-57.6079, 51.4444],
                        [-57.6255, 51.4283],
                        [-57.6311, 51.4348],
                        [-57.6479, 51.4277],
                        [-57.6566, 51.4317],
                        [-57.6681, 51.4249],
                        [-57.6911, 51.4199],
                        [-57.6986, 51.4281],
                        [-57.6965, 51.4314],
                        [-57.7022, 51.4406],
                        [-57.702, 51.4545],
                        [-57.731, 51.4568],
                        [-57.7398, 51.46],
                        [-57.7266, 51.468],
                        [-57.7329, 51.4721],
                        [-57.7429, 51.4721],
                        [-57.7573, 51.4641],
                        [-57.7495, 51.4619],
                        [-57.7558, 51.4509],
                        [-57.746, 51.4477],
                        [-57.7641, 51.4356],
                        [-57.7662, 51.4255],
                        [-57.7784, 51.4257],
                        [-57.7887, 51.4226],
                        [-57.7867, 51.4159],
                        [-57.7743, 51.4201],
                        [-57.7711, 51.411],
                        [-57.7837, 51.403],
                        [-57.8009, 51.3965],
                        [-57.8127, 51.408],
                        [-57.8093, 51.4172],
                        [-57.7978, 51.4255],
                        [-57.7887, 51.4277],
                        [-57.785, 51.4351],
                        [-57.8065, 51.4248],
                        [-57.8253, 51.4235],
                        [-57.8325, 51.4164],
                        [-57.8268, 51.4089],
                        [-57.8347, 51.4033],
                        [-57.8488, 51.3986],
                        [-57.866, 51.3954],
                        [-57.8786, 51.3873],
                        [-57.8883, 51.3845],
                        [-57.8953, 51.3789],
                        [-57.8889, 51.3695],
                        [-57.9003, 51.3617],
                        [-57.9183, 51.3572],
                        [-57.9416, 51.3477],
                        [-57.9553, 51.3457],
                        [-57.9842, 51.3202],
                        [-58, 51.3144],
                        [-58.0126, 51.2978],
                        [-58.0182, 51.2952],
                        [-58.0283, 51.2995],
                        [-58.0155, 51.3157],
                        [-58.0197, 51.3183],
                        [-58.0354, 51.3019],
                        [-58.0503, 51.2955],
                        [-58.0454, 51.308],
                        [-58.0421, 51.34],
                        [-58.0366, 51.3502],
                        [-58.0454, 51.3496],
                        [-58.0501, 51.3406],
                        [-58.0502, 51.3301],
                        [-58.0562, 51.319],
                        [-58.0592, 51.3016],
                        [-58.0662, 51.2896],
                        [-58.076, 51.2923],
                        [-58.0865, 51.2845],
                        [-58.0945, 51.2853],
                        [-58.1176, 51.2834],
                        [-58.1223, 51.276],
                        [-58.1334, 51.2702],
                        [-58.1398, 51.2749],
                        [-58.1319, 51.2903],
                        [-58.1277, 51.3101],
                        [-58.1218, 51.3212],
                        [-58.121, 51.3299],
                        [-58.1294, 51.3301],
                        [-58.1366, 51.3085],
                        [-58.1496, 51.2854],
                        [-58.1608, 51.2702],
                        [-58.1887, 51.2634],
                        [-58.1962, 51.2601],
                        [-58.2052, 51.2625],
                        [-58.1986, 51.2798],
                        [-58.1994, 51.2967],
                        [-58.2066, 51.2986],
                        [-58.2123, 51.2937],
                        [-58.2066, 51.281],
                        [-58.2107, 51.2612],
                        [-58.2189, 51.2556],
                        [-58.2346, 51.2648],
                        [-58.2376, 51.2715],
                        [-58.2493, 51.2799],
                        [-58.2468, 51.297],
                        [-58.2396, 51.304],
                        [-58.2444, 51.3114],
                        [-58.2398, 51.3168],
                        [-58.2475, 51.3254],
                        [-58.268, 51.3239],
                        [-58.2779, 51.3309],
                        [-58.2755, 51.3464],
                        [-58.2933, 51.3588],
                        [-58.2894, 51.3635],
                        [-58.2719, 51.3733],
                        [-58.2587, 51.3749],
                        [-58.2623, 51.3839],
                        [-58.297, 51.3671],
                        [-58.3066, 51.3638],
                        [-58.3227, 51.3622],
                        [-58.3266, 51.3572],
                        [-58.3214, 51.3494],
                        [-58.3038, 51.3427],
                        [-58.2935, 51.3428],
                        [-58.2811, 51.3257],
                        [-58.2875, 51.32],
                        [-58.2741, 51.3173],
                        [-58.2642, 51.3089],
                        [-58.2549, 51.3145],
                        [-58.2506, 51.303],
                        [-58.2622, 51.305],
                        [-58.2703, 51.3017],
                        [-58.2756, 51.3077],
                        [-58.2865, 51.3072],
                        [-58.2717, 51.2947],
                        [-58.2799, 51.2909],
                        [-58.2844, 51.2955],
                        [-58.2944, 51.2838],
                        [-58.2824, 51.2818],
                        [-58.2827, 51.2745],
                        [-58.2734, 51.2704],
                        [-58.2794, 51.2564],
                        [-58.2955, 51.2541],
                        [-58.3083, 51.2621],
                        [-58.317, 51.2634],
                        [-58.327, 51.2692],
                        [-58.3395, 51.2655],
                        [-58.3615, 51.2821],
                        [-58.3669, 51.2746],
                        [-58.375, 51.2729],
                        [-58.3765, 51.2816],
                        [-58.389, 51.2875],
                        [-58.3934, 51.2778],
                        [-58.4075, 51.28],
                        [-58.4072, 51.2983],
                        [-58.4188, 51.2992],
                        [-58.4205, 51.3047],
                        [-58.4424, 51.302],
                        [-58.4591, 51.2952],
                        [-58.4682, 51.2964],
                        [-58.4682, 51.3053],
                        [-58.4843, 51.2948],
                        [-58.4753, 51.2894],
                        [-58.4876, 51.2837],
                        [-58.5017, 51.2745],
                        [-58.5169, 51.2676],
                        [-58.5238, 51.2595],
                        [-58.5349, 51.2537],
                        [-58.5483, 51.2436],
                        [-58.572, 51.2316],
                        [-58.5721, 51.2517],
                        [-58.5769, 51.2497],
                        [-58.5802, 51.2383],
                        [-58.5787, 51.2271],
                        [-58.5893, 51.2277],
                        [-58.59, 51.238],
                        [-58.5971, 51.2458],
                        [-58.5961, 51.252],
                        [-58.6117, 51.265],
                        [-58.6271, 51.2645],
                        [-58.6288, 51.2556],
                        [-58.6148, 51.2499],
                        [-58.6168, 51.2433],
                        [-58.6094, 51.2371],
                        [-58.6093, 51.2275],
                        [-58.6168, 51.2205],
                        [-58.6298, 51.2134],
                        [-58.641, 51.2031],
                        [-58.64, 51.2]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "36",
            properties: { cityName: "New Brunswick ", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-66.3259, 48.0608],
                        [-66.3523, 48.0573],
                        [-66.3668, 48.0542],
                        [-66.3714, 48.0464],
                        [-66.3708, 48.0357],
                        [-66.3744, 48.0303],
                        [-66.3898, 48.031],
                        [-66.3987, 48.0294],
                        [-66.3849, 48.0198],
                        [-66.3672, 48.0182],
                        [-66.3403, 48.0057],
                        [-66.3052, 47.9975],
                        [-66.2788, 47.9951],
                        [-66.2785, 47.9883],
                        [-66.2619, 47.9913],
                        [-66.2493, 47.9807],
                        [-66.2353, 47.9824],
                        [-66.2343, 47.9785],
                        [-66.2132, 47.9728],
                        [-66.1958, 47.9744],
                        [-66.174, 47.9675],
                        [-66.159, 47.9682],
                        [-66.1437, 47.9643],
                        [-66.1317, 47.9525],
                        [-66.119, 47.9525],
                        [-66.1099, 47.9559],
                        [-66.1065, 47.9436],
                        [-66.0985, 47.9347],
                        [-66.0854, 47.9256],
                        [-66.0706, 47.9257],
                        [-66.065, 47.928],
                        [-66.0485, 47.9278],
                        [-66.0347, 47.9245],
                        [-66.0156, 47.9279],
                        [-65.9893, 47.9202],
                        [-65.9534, 47.9186],
                        [-65.9444, 47.9206],
                        [-65.9382, 47.9174],
                        [-65.9081, 47.9193],
                        [-65.8994, 47.9212],
                        [-65.8897, 47.9151],
                        [-65.8752, 47.9104],
                        [-65.8587, 47.9092],
                        [-65.8407, 47.905],
                        [-65.8308, 47.9081],
                        [-65.8267, 47.9005],
                        [-65.8046, 47.8841],
                        [-65.7941, 47.8806],
                        [-65.785, 47.8731],
                        [-65.7648, 47.8641],
                        [-65.7515, 47.8457],
                        [-65.733, 47.8304],
                        [-65.7296, 47.8178],
                        [-65.7241, 47.812],
                        [-65.7255, 47.8014],
                        [-65.7148, 47.7873],
                        [-65.7089, 47.7672],
                        [-65.7113, 47.7611],
                        [-65.71, 47.7507],
                        [-65.7056, 47.7418],
                        [-65.7058, 47.7259],
                        [-65.6988, 47.7117],
                        [-65.6735, 47.6893],
                        [-65.6556, 47.6773],
                        [-65.6286, 47.6639],
                        [-65.6386, 47.6587],
                        [-65.65, 47.6569],
                        [-65.6575, 47.6475],
                        [-65.673, 47.6397],
                        [-65.6606, 47.6266],
                        [-65.6663, 47.6243],
                        [-65.6655, 47.6166],
                        [-65.6688, 47.6061],
                        [-65.6562, 47.61],
                        [-65.6586, 47.622],
                        [-65.6352, 47.6193],
                        [-65.6209, 47.6267],
                        [-65.6264, 47.6368],
                        [-65.6052, 47.6444],
                        [-65.6231, 47.6514],
                        [-65.6102, 47.6539],
                        [-65.5963, 47.6603],
                        [-65.5687, 47.6692],
                        [-65.5336, 47.6666],
                        [-65.5158, 47.6681],
                        [-65.4979, 47.6716],
                        [-65.4701, 47.6833],
                        [-65.4353, 47.7003],
                        [-65.4207, 47.7125],
                        [-65.4048, 47.7205],
                        [-65.3857, 47.7347],
                        [-65.3725, 47.7427],
                        [-65.3591, 47.7553],
                        [-65.3518, 47.7547],
                        [-65.34, 47.7581],
                        [-65.3243, 47.7581],
                        [-65.3137, 47.7613],
                        [-65.291, 47.7721],
                        [-65.2636, 47.7879],
                        [-65.253, 47.7884],
                        [-65.2451, 47.7972],
                        [-65.1815, 47.8194],
                        [-65.1723, 47.8161],
                        [-65.1564, 47.8206],
                        [-65.1493, 47.8247],
                        [-65.1417, 47.8233],
                        [-65.1055, 47.83],
                        [-65.0896, 47.8309],
                        [-65.0729, 47.8295],
                        [-65.053, 47.8328],
                        [-65.0029, 47.8376],
                        [-64.9832, 47.8339],
                        [-64.9783, 47.8292],
                        [-64.9858, 47.8219],
                        [-65.007, 47.8139],
                        [-65.0161, 47.8142],
                        [-65.0235, 47.8081],
                        [-65.0513, 47.7948],
                        [-65.0566, 47.7962],
                        [-65.0632, 47.7896],
                        [-65.0711, 47.7925],
                        [-65.076, 47.7845],
                        [-65.0575, 47.7812],
                        [-65.0706, 47.7771],
                        [-65.0656, 47.773],
                        [-65.0495, 47.7708],
                        [-65.0342, 47.7782],
                        [-65.0212, 47.7753],
                        [-65.0153, 47.7766],
                        [-65.0011, 47.7727],
                        [-64.9875, 47.7847],
                        [-64.9648, 47.793],
                        [-64.9477, 47.7937],
                        [-64.9374, 47.7966],
                        [-64.9293, 47.7945],
                        [-64.9134, 47.7963],
                        [-64.8879, 47.8042],
                        [-64.877, 47.8],
                        [-64.8553, 47.8086],
                        [-64.8446, 47.8093],
                        [-64.8258, 47.8066],
                        [-64.8159, 47.8068],
                        [-64.8035, 47.8011],
                        [-64.7936, 47.7939],
                        [-64.7867, 47.781],
                        [-64.7939, 47.7734],
                        [-64.7829, 47.7666],
                        [-64.7947, 47.7564],
                        [-64.8041, 47.7522],
                        [-64.82, 47.751],
                        [-64.8322, 47.7536],
                        [-64.8565, 47.7467],
                        [-64.8415, 47.7393],
                        [-64.8313, 47.7433],
                        [-64.8265, 47.739],
                        [-64.8163, 47.7376],
                        [-64.8082, 47.7419],
                        [-64.7965, 47.7404],
                        [-64.7886, 47.7336],
                        [-64.784, 47.7228],
                        [-64.7854, 47.7108],
                        [-64.7745, 47.7062],
                        [-64.7572, 47.7027],
                        [-64.7491, 47.7112],
                        [-64.7576, 47.7137],
                        [-64.7553, 47.7255],
                        [-64.7725, 47.7258],
                        [-64.7749, 47.7374],
                        [-64.7686, 47.743],
                        [-64.7762, 47.7457],
                        [-64.7698, 47.7561],
                        [-64.7608, 47.7588],
                        [-64.7549, 47.7491],
                        [-64.7447, 47.7504],
                        [-64.741, 47.7649],
                        [-64.7205, 47.7576],
                        [-64.7267, 47.7536],
                        [-64.7268, 47.7451],
                        [-64.712, 47.7476],
                        [-64.7034, 47.7464],
                        [-64.697, 47.7338],
                        [-64.6876, 47.7329],
                        [-64.6749, 47.7276],
                        [-64.6791, 47.7217],
                        [-64.6747, 47.714],
                        [-64.6932, 47.7045],
                        [-64.72, 47.6948],
                        [-64.732, 47.6989],
                        [-64.744, 47.6967],
                        [-64.7564, 47.6919],
                        [-64.7629, 47.6792],
                        [-64.7545, 47.6751],
                        [-64.7693, 47.6625],
                        [-64.777, 47.6602],
                        [-64.7841, 47.6663],
                        [-64.7911, 47.6665],
                        [-64.7987, 47.6739],
                        [-64.8066, 47.6703],
                        [-64.8243, 47.6814],
                        [-64.8227, 47.6875],
                        [-64.8576, 47.6853],
                        [-64.847, 47.6747],
                        [-64.828, 47.6713],
                        [-64.8208, 47.6738],
                        [-64.8161, 47.6691],
                        [-64.8204, 47.6638],
                        [-64.8026, 47.6573],
                        [-64.8031, 47.6522],
                        [-64.8118, 47.6478],
                        [-64.8143, 47.6397],
                        [-64.7985, 47.6324],
                        [-64.8115, 47.6119],
                        [-64.8432, 47.5856],
                        [-64.8644, 47.5855],
                        [-64.8768, 47.5822],
                        [-64.8901, 47.5689],
                        [-64.886, 47.5654],
                        [-64.9035, 47.5573],
                        [-64.9067, 47.5501],
                        [-64.8987, 47.5412],
                        [-64.8999, 47.5354],
                        [-64.895, 47.5295],
                        [-64.8947, 47.5179],
                        [-64.9077, 47.5165],
                        [-64.9043, 47.5081],
                        [-64.8825, 47.5005],
                        [-64.8891, 47.4937],
                        [-64.8814, 47.4858],
                        [-64.8811, 47.4786],
                        [-64.9017, 47.4786],
                        [-64.894, 47.4715],
                        [-64.8949, 47.4619],
                        [-64.8714, 47.4684],
                        [-64.8846, 47.4418],
                        [-64.8851, 47.4341],
                        [-64.8912, 47.4295],
                        [-64.8994, 47.4112],
                        [-64.905, 47.3784],
                        [-64.911, 47.3816],
                        [-64.9058, 47.3918],
                        [-64.9127, 47.3952],
                        [-64.9269, 47.3943],
                        [-64.9285, 47.3848],
                        [-64.9448, 47.3701],
                        [-64.9426, 47.365],
                        [-64.9512, 47.3591],
                        [-64.9516, 47.3481],
                        [-64.9655, 47.3469],
                        [-64.9667, 47.3411],
                        [-64.9559, 47.335],
                        [-64.9589, 47.3259],
                        [-64.9678, 47.3154],
                        [-64.9626, 47.3131],
                        [-64.9597, 47.3047],
                        [-64.9657, 47.2962],
                        [-64.9749, 47.2769],
                        [-64.9888, 47.2813],
                        [-64.998, 47.2791],
                        [-65.0175, 47.2779],
                        [-65.0267, 47.2695],
                        [-65.0347, 47.2655],
                        [-65.0477, 47.2633],
                        [-65.0719, 47.2489],
                        [-65.0758, 47.2429],
                        [-65.0898, 47.2311],
                        [-65.1009, 47.2287],
                        [-65.1082, 47.2201],
                        [-65.1015, 47.2168],
                        [-65.1156, 47.2111],
                        [-65.1133, 47.2067],
                        [-65.1442, 47.1892],
                        [-65.1512, 47.1829],
                        [-65.1616, 47.1775],
                        [-65.1799, 47.1653],
                        [-65.191, 47.1602],
                        [-65.2343, 47.1362],
                        [-65.2561, 47.1274],
                        [-65.2666, 47.1164],
                        [-65.282, 47.1118],
                        [-65.2993, 47.1106],
                        [-65.3173, 47.1042],
                        [-65.3269, 47.0983],
                        [-65.3449, 47.0908],
                        [-65.3406, 47.0774],
                        [-65.3297, 47.0727],
                        [-65.3155, 47.0724],
                        [-65.3064, 47.0671],
                        [-65.3017, 47.0608],
                        [-65.2834, 47.0651],
                        [-65.2812, 47.072],
                        [-65.2747, 47.0796],
                        [-65.2578, 47.07],
                        [-65.2418, 47.0723],
                        [-65.218, 47.0813],
                        [-65.2055, 47.0821],
                        [-65.2073, 47.0745],
                        [-65.2045, 47.062],
                        [-65.214, 47.0471],
                        [-65.2073, 47.0395],
                        [-65.1905, 47.0388],
                        [-65.1847, 47.0413],
                        [-65.1651, 47.0386],
                        [-65.1528, 47.0404],
                        [-65.1396, 47.0468],
                        [-65.1401, 47.0531],
                        [-65.1331, 47.0583],
                        [-65.1221, 47.0614],
                        [-65.1015, 47.0743],
                        [-65.0792, 47.0703],
                        [-65.0636, 47.0735],
                        [-65.0555, 47.0792],
                        [-65.0449, 47.0793],
                        [-65.0313, 47.0853],
                        [-65.0162, 47.0855],
                        [-65.014, 47.0805],
                        [-65.0035, 47.075],
                        [-65.0035, 47.0638],
                        [-64.9939, 47.0552],
                        [-64.9828, 47.0508],
                        [-64.9786, 47.053],
                        [-64.954, 47.055],
                        [-64.9458, 47.0608],
                        [-64.9456, 47.0684],
                        [-64.9354, 47.0715],
                        [-64.9382, 47.0771],
                        [-64.8961, 47.0806],
                        [-64.8616, 47.0817],
                        [-64.8262, 47.0796],
                        [-64.8162, 47.0766],
                        [-64.8047, 47.0768],
                        [-64.7967, 47.0704],
                        [-64.8144, 47.0649],
                        [-64.8235, 47.0585],
                        [-64.8269, 47.0493],
                        [-64.8253, 47.04],
                        [-64.8198, 47.0313],
                        [-64.8202, 47.018],
                        [-64.8086, 46.9919],
                        [-64.8123, 46.9754],
                        [-64.8295, 46.9624],
                        [-64.8415, 46.9571],
                        [-64.8493, 46.9485],
                        [-64.8572, 46.9447],
                        [-64.8657, 46.9371],
                        [-64.8823, 46.9283],
                        [-64.8916, 46.9126],
                        [-64.8934, 46.9053],
                        [-64.9055, 46.8958],
                        [-64.8999, 46.8891],
                        [-64.9093, 46.8759],
                        [-64.9127, 46.8631],
                        [-64.9095, 46.8439],
                        [-64.9151, 46.8445],
                        [-64.9141, 46.8717],
                        [-64.9305, 46.8682],
                        [-64.9371, 46.8629],
                        [-64.9416, 46.8532],
                        [-64.9557, 46.8481],
                        [-64.9611, 46.8439],
                        [-64.9703, 46.8442],
                        [-64.9762, 46.8366],
                        [-64.9451, 46.841],
                        [-64.9355, 46.8375],
                        [-64.929, 46.8321],
                        [-64.9187, 46.8333],
                        [-64.9099, 46.8264],
                        [-64.912, 46.8136],
                        [-64.9059, 46.8084],
                        [-64.9087, 46.8036],
                        [-64.9052, 46.7957],
                        [-64.912, 46.7888],
                        [-64.9043, 46.7799],
                        [-64.8887, 46.7791],
                        [-64.8801, 46.7682],
                        [-64.8794, 46.7609],
                        [-64.8865, 46.7525],
                        [-64.9016, 46.7482],
                        [-64.873, 46.743],
                        [-64.8242, 46.7302],
                        [-64.8149, 46.7244],
                        [-64.8262, 46.7088],
                        [-64.8406, 46.7066],
                        [-64.8497, 46.6948],
                        [-64.8354, 46.6907],
                        [-64.8243, 46.6925],
                        [-64.7936, 46.6916],
                        [-64.7866, 46.6878],
                        [-64.7768, 46.6891],
                        [-64.7657, 46.6869],
                        [-64.7744, 46.6817],
                        [-64.774, 46.6707],
                        [-64.7518, 46.6687],
                        [-64.7573, 46.6748],
                        [-64.7558, 46.6833],
                        [-64.7433, 46.6877],
                        [-64.7333, 46.696],
                        [-64.7216, 46.6908],
                        [-64.7075, 46.66],
                        [-64.7113, 46.6518],
                        [-64.7092, 46.639],
                        [-64.712, 46.6328],
                        [-64.7077, 46.6235],
                        [-64.7135, 46.6179],
                        [-64.7208, 46.6048],
                        [-64.7218, 46.5914],
                        [-64.7197, 46.5757],
                        [-64.715, 46.5586],
                        [-64.7073, 46.5487],
                        [-64.6928, 46.5339],
                        [-64.673, 46.5227],
                        [-64.665, 46.5158],
                        [-64.6536, 46.5099],
                        [-64.6378, 46.4945],
                        [-64.6163, 46.4802],
                        [-64.6107, 46.4693],
                        [-64.6174, 46.4694],
                        [-64.6275, 46.484],
                        [-64.6325, 46.4842],
                        [-64.6414, 46.4965],
                        [-64.6527, 46.5072],
                        [-64.6662, 46.5103],
                        [-64.6751, 46.5209],
                        [-64.6824, 46.5226],
                        [-64.683, 46.5141],
                        [-64.6722, 46.4925],
                        [-64.6747, 46.4864],
                        [-64.6951, 46.4825],
                        [-64.695, 46.4789],
                        [-64.7085, 46.4707],
                        [-64.7064, 46.4681],
                        [-64.6803, 46.4572],
                        [-64.6671, 46.4663],
                        [-64.6507, 46.4567],
                        [-64.6257, 46.4326],
                        [-64.6185, 46.4143],
                        [-64.6211, 46.4116],
                        [-64.6143, 46.4021],
                        [-64.6165, 46.389],
                        [-64.6123, 46.3784],
                        [-64.6173, 46.3685],
                        [-64.6119, 46.347],
                        [-64.6161, 46.3362],
                        [-64.6055, 46.3333],
                        [-64.5724, 46.3299],
                        [-64.5675, 46.3332],
                        [-64.5744, 46.34],
                        [-64.5747, 46.3559],
                        [-64.5624, 46.3668],
                        [-64.55, 46.3627],
                        [-64.536, 46.3552],
                        [-64.5351, 46.3484],
                        [-64.5274, 46.3406],
                        [-64.5277, 46.336],
                        [-64.5119, 46.3229],
                        [-64.5102, 46.314],
                        [-64.5174, 46.3125],
                        [-64.5256, 46.3002],
                        [-64.5559, 46.295],
                        [-64.5696, 46.2821],
                        [-64.5708, 46.275],
                        [-64.5625, 46.272],
                        [-64.5642, 46.2618],
                        [-64.5696, 46.253],
                        [-64.5645, 46.2495],
                        [-64.5658, 46.2433],
                        [-64.5614, 46.239],
                        [-64.5598, 46.2235],
                        [-64.5551, 46.219],
                        [-64.5371, 46.2294],
                        [-64.5227, 46.2289],
                        [-64.5242, 46.2396],
                        [-64.5125, 46.2423],
                        [-64.4973, 46.2381],
                        [-64.4797, 46.2365],
                        [-64.4582, 46.2378],
                        [-64.4426, 46.23],
                        [-64.4374, 46.2377],
                        [-64.4139, 46.2373],
                        [-64.4038, 46.2333],
                        [-64.3921, 46.2237],
                        [-64.3736, 46.2223],
                        [-64.3519, 46.2242],
                        [-64.3427, 46.2277],
                        [-64.3289, 46.2298],
                        [-64.3053, 46.2309],
                        [-64.2778, 46.2356],
                        [-64.2657, 46.2365],
                        [-64.2292, 46.2289],
                        [-64.2267, 46.222],
                        [-64.2104, 46.2131],
                        [-64.1822, 46.2056],
                        [-64.159, 46.1951],
                        [-64.144, 46.1826],
                        [-64.1577, 46.1805],
                        [-64.1718, 46.1652],
                        [-64.1695, 46.1555],
                        [-64.1582, 46.1599],
                        [-64.1577, 46.1664],
                        [-64.1513, 46.1719],
                        [-64.1445, 46.1646],
                        [-64.1468, 46.1608],
                        [-64.1349, 46.1524],
                        [-64.132, 46.1573],
                        [-64.1385, 46.1673],
                        [-64.1215, 46.1764],
                        [-64.1159, 46.1825],
                        [-64.1075, 46.1757],
                        [-64.0897, 46.1721],
                        [-64.0868, 46.1618],
                        [-64.0736, 46.1571],
                        [-64.0711, 46.1536],
                        [-64.0602, 46.1556],
                        [-64.0677, 46.1702],
                        [-64.0393, 46.1818],
                        [-64.0162, 46.1815],
                        [-64.0031, 46.1787],
                        [-63.9876, 46.1795],
                        [-63.9796, 46.1828],
                        [-63.96, 46.173],
                        [-63.9499, 46.1727],
                        [-63.9249, 46.1653],
                        [-63.8995, 46.1501],
                        [-63.8847, 46.1534],
                        [-63.8652, 46.1455],
                        [-63.8581, 46.1535],
                        [-63.848, 46.1537],
                        [-63.8429, 46.1501],
                        [-63.8335, 46.1504],
                        [-63.8309, 46.1562],
                        [-63.8157, 46.1642],
                        [-63.8061, 46.1569],
                        [-63.8089, 46.1528],
                        [-63.8198, 46.159],
                        [-63.8238, 46.156],
                        [-63.8163, 46.1497],
                        [-63.8332, 46.1484],
                        [-63.8266, 46.1383],
                        [-63.8154, 46.1419],
                        [-63.8086, 46.1368],
                        [-63.7947, 46.1362],
                        [-63.7833, 46.1322],
                        [-63.7835, 46.1245],
                        [-63.7743, 46.1162],
                        [-63.772, 46.1049],
                        [-63.7759, 46.1],
                        [-63.7901, 46.0962],
                        [-63.8027, 46.0863],
                        [-63.8254, 46.0855],
                        [-63.8395, 46.0812],
                        [-63.8473, 46.0816],
                        [-63.8831, 46.0694],
                        [-63.8895, 46.0622],
                        [-63.8983, 46.0583],
                        [-63.9084, 46.0615],
                        [-63.9303, 46.0552],
                        [-63.9465, 46.0573],
                        [-63.9647, 46.0546],
                        [-63.9778, 46.055],
                        [-63.9975, 46.0532],
                        [-64.0141, 46.0568],
                        [-64.0349, 46.0486],
                        [-64.0412, 46.051],
                        [-64.0553, 46.051],
                        [-64.0628, 46.0537],
                        [-64.0724, 46.0529],
                        [-64.0749, 46.0463],
                        [-64.0712, 46.0426],
                        [-64.0766, 46.0366],
                        [-64.091, 46.0297],
                        [-64.0996, 46.0235],
                        [-64.0879, 46.016],
                        [-64.0834, 46.0205],
                        [-64.0733, 46.0199],
                        [-64.0788, 46.0095],
                        [-64.0598, 46.0005],
                        [-64.057, 45.9951],
                        [-64.0426, 45.9934],
                        [-64.0504, 45.9794],
                        [-64.0701, 45.9772],
                        [-64.1496, 45.9799],
                        [-64.1591, 45.9753],
                        [-64.1646, 45.9608],
                        [-64.2062, 45.9161],
                        [-64.2166, 45.9112],
                        [-64.2193, 45.9038],
                        [-64.2277, 45.8952],
                        [-64.2431, 45.8901],
                        [-64.2545, 45.8798],
                        [-64.2665, 45.8595],
                        [-64.2723, 45.8541],
                        [-64.275, 45.8415],
                        [-64.294, 45.8496],
                        [-64.3036, 45.8606],
                        [-64.3354, 45.8675],
                        [-64.3606, 45.8578],
                        [-64.3753, 45.8381],
                        [-64.3945, 45.8314],
                        [-64.405, 45.8217],
                        [-64.4221, 45.7965],
                        [-64.4305, 45.7877],
                        [-64.4444, 45.7832],
                        [-64.4529, 45.7779],
                        [-64.4869, 45.7607],
                        [-64.4916, 45.7489],
                        [-64.4805, 45.7412],
                        [-64.4944, 45.7331],
                        [-64.4958, 45.7247],
                        [-64.5024, 45.7254],
                        [-64.5116, 45.721],
                        [-64.5224, 45.7211],
                        [-64.526, 45.725],
                        [-64.537, 45.7222],
                        [-64.5526, 45.7272],
                        [-64.5473, 45.734],
                        [-64.5373, 45.7359],
                        [-64.5289, 45.7413],
                        [-64.5199, 45.752],
                        [-64.5224, 45.765],
                        [-64.5134, 45.7728],
                        [-64.5121, 45.7785],
                        [-64.505, 45.7865],
                        [-64.5041, 45.7935],
                        [-64.4889, 45.8063],
                        [-64.5, 45.8167],
                        [-64.5106, 45.8323],
                        [-64.5182, 45.8388],
                        [-64.5275, 45.8411],
                        [-64.5681, 45.8346],
                        [-64.5758, 45.8176],
                        [-64.5848, 45.8158],
                        [-64.5925, 45.8079],
                        [-64.6067, 45.798],
                        [-64.6275, 45.7804],
                        [-64.6552, 45.7535],
                        [-64.666, 45.7449],
                        [-64.6678, 45.7379],
                        [-64.6544, 45.7233],
                        [-64.6701, 45.7257],
                        [-64.6857, 45.7186],
                        [-64.6924, 45.71],
                        [-64.6946, 45.7024],
                        [-64.6884, 45.6993],
                        [-64.6907, 45.6879],
                        [-64.7019, 45.6728],
                        [-64.7197, 45.655],
                        [-64.7247, 45.6572],
                        [-64.744, 45.6414],
                        [-64.7426, 45.6372],
                        [-64.7495, 45.6322],
                        [-64.7706, 45.6076],
                        [-64.7777, 45.595],
                        [-64.7857, 45.5986],
                        [-64.7892, 45.6046],
                        [-64.7866, 45.6116],
                        [-64.7928, 45.6189],
                        [-64.819, 45.6298],
                        [-64.8321, 45.6246],
                        [-64.8575, 45.6319],
                        [-64.866, 45.6321],
                        [-64.8893, 45.6191],
                        [-64.8926, 45.6146],
                        [-64.9091, 45.6051],
                        [-64.9153, 45.6051],
                        [-64.9338, 45.5996],
                        [-64.9471, 45.5982],
                        [-64.9531, 45.589],
                        [-64.9673, 45.5767],
                        [-64.9675, 45.566],
                        [-64.9755, 45.5609],
                        [-65.0044, 45.5513],
                        [-65.0152, 45.5441],
                        [-65.0342, 45.5376],
                        [-65.0617, 45.5337],
                        [-65.0795, 45.5274],
                        [-65.0983, 45.5234],
                        [-65.1199, 45.5247],
                        [-65.1387, 45.5201],
                        [-65.1472, 45.5127],
                        [-65.159, 45.5092],
                        [-65.1629, 45.5116],
                        [-65.1891, 45.5],
                        [-65.1965, 45.4944],
                        [-65.2079, 45.4927],
                        [-65.2252, 45.486],
                        [-65.2327, 45.4847],
                        [-65.2568, 45.4738],
                        [-65.2688, 45.4732],
                        [-65.2923, 45.4683],
                        [-65.3085, 45.4577],
                        [-65.3237, 45.4542],
                        [-65.3423, 45.4479],
                        [-65.3452, 45.4431],
                        [-65.3587, 45.432],
                        [-65.3713, 45.4266],
                        [-65.3816, 45.426],
                        [-65.3877, 45.4214],
                        [-65.4004, 45.4203],
                        [-65.4144, 45.4107],
                        [-65.43, 45.407],
                        [-65.4534, 45.3964],
                        [-65.4525, 45.3884],
                        [-65.4581, 45.3813],
                        [-65.4789, 45.3712],
                        [-65.5057, 45.3661],
                        [-65.5168, 45.3596],
                        [-65.5329, 45.3563],
                        [-65.5413, 45.3471],
                        [-65.5442, 45.3353],
                        [-65.5353, 45.3234],
                        [-65.5499, 45.3203],
                        [-65.5549, 45.3128],
                        [-65.5784, 45.3045],
                        [-65.592, 45.3091],
                        [-65.6005, 45.3047],
                        [-65.6121, 45.3031],
                        [-65.6306, 45.2946],
                        [-65.6566, 45.2923],
                        [-65.6637, 45.2853],
                        [-65.6848, 45.2774],
                        [-65.6912, 45.2805],
                        [-65.7247, 45.2755],
                        [-65.7295, 45.2718],
                        [-65.7336, 45.2612],
                        [-65.7326, 45.2545],
                        [-65.7495, 45.2515],
                        [-65.7619, 45.2577],
                        [-65.7676, 45.2552],
                        [-65.7794, 45.2573],
                        [-65.7881, 45.2642],
                        [-65.8052, 45.2617],
                        [-65.8169, 45.2533],
                        [-65.8166, 45.2501],
                        [-65.8271, 45.2449],
                        [-65.8431, 45.232],
                        [-65.8417, 45.2269],
                        [-65.8539, 45.215],
                        [-65.8667, 45.2085],
                        [-65.8887, 45.1998],
                        [-65.9065, 45.1954],
                        [-65.9175, 45.1945],
                        [-65.928, 45.2012],
                        [-65.9282, 45.2092],
                        [-65.9526, 45.214],
                        [-65.9493, 45.2198],
                        [-65.9572, 45.2238],
                        [-65.9661, 45.2195],
                        [-65.9807, 45.2081],
                        [-65.9869, 45.2067],
                        [-65.9984, 45.2164],
                        [-66.0087, 45.2172],
                        [-66.0084, 45.2282],
                        [-66.0007, 45.2326],
                        [-66, 45.2432],
                        [-66.01, 45.2501],
                        [-66.018, 45.2526],
                        [-66.0153, 45.2578],
                        [-66.023, 45.2631],
                        [-66.0214, 45.2714],
                        [-66.0353, 45.2712],
                        [-66.0412, 45.2647],
                        [-66.0577, 45.2629],
                        [-66.065, 45.2527],
                        [-66.0626, 45.2465],
                        [-66.0804, 45.2455],
                        [-66.0924, 45.2431],
                        [-66.1014, 45.2373],
                        [-66.096, 45.2261],
                        [-66.1192, 45.2248],
                        [-66.1322, 45.2214],
                        [-66.1412, 45.2233],
                        [-66.1514, 45.2203],
                        [-66.1575, 45.2141],
                        [-66.1635, 45.2029],
                        [-66.1497, 45.1915],
                        [-66.1456, 45.1829],
                        [-66.1875, 45.1565],
                        [-66.1999, 45.1555],
                        [-66.2155, 45.1451],
                        [-66.2568, 45.143],
                        [-66.2635, 45.1446],
                        [-66.2766, 45.1363],
                        [-66.2873, 45.1343],
                        [-66.2928, 45.1407],
                        [-66.2983, 45.1339],
                        [-66.3214, 45.1277],
                        [-66.3324, 45.1225],
                        [-66.338, 45.126],
                        [-66.345, 45.1355],
                        [-66.3506, 45.1311],
                        [-66.3485, 45.126],
                        [-66.3548, 45.1202],
                        [-66.3705, 45.1195],
                        [-66.3679, 45.1056],
                        [-66.3807, 45.1087],
                        [-66.3875, 45.1026],
                        [-66.4084, 45.0993],
                        [-66.4257, 45.0993],
                        [-66.4173, 45.0875],
                        [-66.416, 45.081],
                        [-66.4264, 45.0744],
                        [-66.4351, 45.0756],
                        [-66.4499, 45.0728],
                        [-66.4543, 45.0645],
                        [-66.4669, 45.0666],
                        [-66.4707, 45.0792],
                        [-66.4678, 45.0827],
                        [-66.4708, 45.0937],
                        [-66.4748, 45.0955],
                        [-66.4797, 45.106],
                        [-66.475, 45.1225],
                        [-66.4646, 45.1246],
                        [-66.4707, 45.1325],
                        [-66.4801, 45.1253],
                        [-66.5007, 45.1282],
                        [-66.4905, 45.1393],
                        [-66.5, 45.1437],
                        [-66.515, 45.1389],
                        [-66.5191, 45.1345],
                        [-66.515, 45.1281],
                        [-66.5208, 45.1179],
                        [-66.5281, 45.1296],
                        [-66.5278, 45.135],
                        [-66.5389, 45.1355],
                        [-66.552, 45.1282],
                        [-66.5712, 45.1285],
                        [-66.59, 45.1217],
                        [-66.5938, 45.1178],
                        [-66.5831, 45.1079],
                        [-66.5841, 45.0983],
                        [-66.5972, 45.1008],
                        [-66.607, 45.1151],
                        [-66.6102, 45.1119],
                        [-66.6066, 45.105],
                        [-66.6157, 45.1034],
                        [-66.6177, 45.0957],
                        [-66.6249, 45.0928],
                        [-66.6401, 45.097],
                        [-66.6546, 45.0846],
                        [-66.6498, 45.0759],
                        [-66.6713, 45.0743],
                        [-66.6912, 45.0708],
                        [-66.6964, 45.0623],
                        [-66.7145, 45.0582],
                        [-66.7174, 45.0614],
                        [-66.716, 45.0718],
                        [-66.7271, 45.0794],
                        [-66.732, 45.0745],
                        [-66.7393, 45.0763],
                        [-66.7422, 45.0696],
                        [-66.7342, 45.063],
                        [-66.7365, 45.0556],
                        [-66.7513, 45.0493],
                        [-66.7692, 45.0494],
                        [-66.78, 45.052],
                        [-66.8005, 45.0414],
                        [-66.8188, 45.0508],
                        [-66.807, 45.0617],
                        [-66.8134, 45.0662],
                        [-66.8183, 45.0609],
                        [-66.827, 45.0653],
                        [-66.8287, 45.078],
                        [-66.8377, 45.0709],
                        [-66.8644, 45.0622],
                        [-66.8664, 45.0499],
                        [-66.8879, 45.0404],
                        [-66.8967, 45.0573],
                        [-66.9123, 45.061],
                        [-66.9117, 45.0675],
                        [-66.9204, 45.0717],
                        [-66.9155, 45.0843],
                        [-66.9221, 45.0901],
                        [-66.9149, 45.1126],
                        [-66.9036, 45.113],
                        [-66.8981, 45.1023],
                        [-66.8866, 45.1069],
                        [-66.8748, 45.1077],
                        [-66.8815, 45.1182],
                        [-66.8975, 45.1158],
                        [-66.9007, 45.1227],
                        [-66.8958, 45.1303],
                        [-66.9206, 45.1368],
                        [-66.9248, 45.1411],
                        [-66.9401, 45.1484],
                        [-66.9531, 45.1575],
                        [-66.9553, 45.1713],
                        [-66.9584, 45.1777],
                        [-66.9686, 45.1768],
                        [-66.9679, 45.1625],
                        [-66.9703, 45.1546],
                        [-66.984, 45.1465],
                        [-66.9899, 45.145],
                        [-66.9965, 45.1517],
                        [-67.0065, 45.1516],
                        [-67.0173, 45.1631],
                        [-67.0278, 45.168],
                        [-67.0359, 45.1669],
                        [-67.0317, 45.1529],
                        [-67.045, 45.1531],
                        [-67.0435, 45.1478],
                        [-67.034, 45.1472],
                        [-67.0311, 45.129],
                        [-67.0485, 45.1304],
                        [-67.0529, 45.1224],
                        [-67.0588, 45.1267],
                        [-67.0654, 45.125],
                        [-67.0632, 45.1181],
                        [-67.0645, 45.1051],
                        [-67.0432, 45.0857],
                        [-67.0369, 45.0744],
                        [-67.0454, 45.0688],
                        [-67.0646, 45.0779],
                        [-67.082, 45.0759],
                        [-67.0811, 45.0853],
                        [-67.097, 45.0982],
                        [-67.0976, 45.1044],
                        [-67.1064, 45.1192],
                        [-67.1148, 45.1243],
                        [-67.122, 45.1369],
                        [-67.1314, 45.1399],
                        [-67.1403, 45.1591],
                        [-67.1399, 45.1728],
                        [-67.1431, 45.1788],
                        [-67.1421, 45.1901],
                        [-67.1383, 45.1996],
                        [-67.1449, 45.2082],
                        [-67.1512, 45.208],
                        [-67.1437, 45.1921],
                        [-67.1547, 45.184],
                        [-67.1642, 45.2134],
                        [-67.1721, 45.2228],
                        [-67.1878, 45.227],
                        [-67.1947, 45.2152],
                        [-67.1814, 45.2043],
                        [-67.1854, 45.1993],
                        [-67.1852, 45.1889],
                        [-67.1918, 45.1843],
                        [-67.1777, 45.1813],
                        [-67.1606, 45.1749],
                        [-67.1613, 45.1685],
                        [-67.1856, 45.1674],
                        [-67.202, 45.173],
                        [-67.2246, 45.1666],
                        [-67.237, 45.1738],
                        [-67.2422, 45.1791],
                        [-67.2561, 45.1879],
                        [-67.2654, 45.1917],
                        [-67.2861, 45.1925],
                        [-67.2906, 45.1883],
                        [-67.2944, 45.1768],
                        [-67.2914, 45.171],
                        [-67.2994, 45.1692],
                        [-67.3016, 45.157],
                        [-67.2942, 45.1536],
                        [-67.296, 45.1478],
                        [-67.3098, 45.1437],
                        [-67.3177, 45.138],
                        [-67.3208, 45.1313],
                        [-67.3305, 45.1318],
                        [-67.3409, 45.125],
                        [-67.3566, 45.133],
                        [-67.3707, 45.142],
                        [-67.3807, 45.1521],
                        [-67.3929, 45.1544],
                        [-67.405, 45.1601],
                        [-67.4048, 45.1668],
                        [-67.3992, 45.169],
                        [-67.4005, 45.1777],
                        [-67.4066, 45.1789],
                        [-67.4181, 45.19],
                        [-67.4293, 45.1935],
                        [-67.428, 45.2018],
                        [-67.4405, 45.2277],
                        [-67.4539, 45.2412],
                        [-67.4642, 45.2451],
                        [-67.4731, 45.2586],
                        [-67.4764, 45.2754],
                        [-67.4897, 45.2821],
                        [-67.4851, 45.2911],
                        [-67.478, 45.2883],
                        [-67.4665, 45.2936],
                        [-67.4607, 45.2998],
                        [-67.4575, 45.31],
                        [-67.4533, 45.3143],
                        [-67.4552, 45.3269],
                        [-67.4426, 45.3316],
                        [-67.4414, 45.3352],
                        [-67.4285, 45.3447],
                        [-67.4315, 45.3498],
                        [-67.4255, 45.3572],
                        [-67.4344, 45.3641],
                        [-67.4267, 45.3736],
                        [-67.4203, 45.3745],
                        [-67.4268, 45.3898],
                        [-67.4591, 45.4158],
                        [-67.4624, 45.4226],
                        [-67.4727, 45.4232],
                        [-67.4775, 45.4305],
                        [-67.4804, 45.4409],
                        [-67.4779, 45.4464],
                        [-67.484, 45.4494],
                        [-67.4821, 45.4594],
                        [-67.4872, 45.4677],
                        [-67.501, 45.4789],
                        [-67.5037, 45.4867],
                        [-67.5001, 45.4909],
                        [-67.4776, 45.497],
                        [-67.4714, 45.4972],
                        [-67.4699, 45.5058],
                        [-67.4636, 45.5083],
                        [-67.4539, 45.5002],
                        [-67.448, 45.5098],
                        [-67.4419, 45.5052],
                        [-67.4273, 45.5009],
                        [-67.4209, 45.5118],
                        [-67.4264, 45.5152],
                        [-67.4349, 45.5281],
                        [-67.4348, 45.5359],
                        [-67.4207, 45.5497],
                        [-67.4287, 45.5687],
                        [-67.422, 45.5732],
                        [-67.431, 45.5841],
                        [-67.4473, 45.5966],
                        [-67.45, 45.6025],
                        [-67.4567, 45.6043],
                        [-67.4725, 45.6005],
                        [-67.4772, 45.6039],
                        [-67.4908, 45.6008],
                        [-67.4878, 45.597],
                        [-67.4915, 45.5909],
                        [-67.499, 45.5866],
                        [-67.5192, 45.5877],
                        [-67.5352, 45.595],
                        [-67.548, 45.5978],
                        [-67.5617, 45.5947],
                        [-67.5832, 45.6023],
                        [-67.5899, 45.6068],
                        [-67.6084, 45.6061],
                        [-67.6391, 45.6251],
                        [-67.644, 45.6231],
                        [-67.6398, 45.6161],
                        [-67.6456, 45.6133],
                        [-67.6633, 45.6254],
                        [-67.6752, 45.6307],
                        [-67.6798, 45.6382],
                        [-67.6924, 45.6501],
                        [-67.7014, 45.6691],
                        [-67.7131, 45.6812],
                        [-67.7178, 45.6794],
                        [-67.7283, 45.6892],
                        [-67.7346, 45.6889],
                        [-67.73, 45.6767],
                        [-67.718, 45.6677],
                        [-67.72, 45.6622],
                        [-67.7325, 45.6634],
                        [-67.7444, 45.6729],
                        [-67.7543, 45.6676],
                        [-67.7687, 45.6775],
                        [-67.8036, 45.6776],
                        [-67.8181, 45.6937],
                        [-67.8031, 45.696],
                        [-67.8097, 45.7294],
                        [-67.782, 45.7311],
                        [-67.7932, 45.7505],
                        [-67.8063, 45.7552],
                        [-67.8093, 45.7674],
                        [-67.8055, 45.7695],
                        [-67.8039, 45.7811],
                        [-67.8064, 45.7943],
                        [-67.8021, 45.803],
                        [-67.7815, 45.8155],
                        [-67.7781, 45.8192],
                        [-67.7675, 45.8184],
                        [-67.7553, 45.8233],
                        [-67.7638, 45.8288],
                        [-67.7719, 45.8415],
                        [-67.7873, 45.8496],
                        [-67.7971, 45.86],
                        [-67.7961, 45.8652],
                        [-67.8043, 45.8691],
                        [-67.8039, 45.8829],
                        [-67.7844, 45.8957],
                        [-67.7793, 45.8946],
                        [-67.7688, 45.8989],
                        [-67.7648, 45.908],
                        [-67.7558, 45.9117],
                        [-67.7512, 45.9178],
                        [-67.7586, 45.9264],
                        [-67.7677, 45.9319],
                        [-67.7778, 45.9342],
                        [-67.7806, 45.9393],
                        [-67.7813, 45.9566],
                        [-67.7811, 46.073],
                        [-67.7819, 46.2768],
                        [-67.7852, 46.4783],
                        [-67.7879, 46.5859],
                        [-67.7896, 46.7534],
                        [-67.7896, 46.8327],
                        [-67.7899, 46.9798],
                        [-67.7902, 47.0672],
                        [-67.8112, 47.0761],
                        [-67.8211, 47.0834],
                        [-67.8383, 47.0877],
                        [-67.8487, 47.0933],
                        [-67.8684, 47.1005],
                        [-67.8829, 47.1044],
                        [-67.8882, 47.1098],
                        [-67.8907, 47.1265],
                        [-67.9035, 47.1365],
                        [-67.9124, 47.1488],
                        [-67.9287, 47.1562],
                        [-67.9388, 47.1709],
                        [-67.9515, 47.1835],
                        [-67.952, 47.1945],
                        [-67.9572, 47.1996],
                        [-67.9732, 47.2068],
                        [-67.9897, 47.21],
                        [-68.0197, 47.2385],
                        [-68.0253, 47.2399],
                        [-68.0649, 47.2573],
                        [-68.074, 47.2592],
                        [-68.082, 47.271],
                        [-68.0923, 47.2766],
                        [-68.1209, 47.2874],
                        [-68.1275, 47.294],
                        [-68.1359, 47.2957],
                        [-68.1522, 47.3097],
                        [-68.1523, 47.3221],
                        [-68.16, 47.3272],
                        [-68.1761, 47.3289],
                        [-68.2024, 47.3394],
                        [-68.2114, 47.3389],
                        [-68.2237, 47.3447],
                        [-68.2247, 47.3503],
                        [-68.2345, 47.3553],
                        [-68.248, 47.3521],
                        [-68.2649, 47.3522],
                        [-68.2806, 47.3596],
                        [-68.2904, 47.3599],
                        [-68.3052, 47.3553],
                        [-68.3224, 47.3594],
                        [-68.3356, 47.3597],
                        [-68.3631, 47.3547],
                        [-68.3719, 47.3494],
                        [-68.3802, 47.3397],
                        [-68.3847, 47.3241],
                        [-68.3809, 47.3082],
                        [-68.3839, 47.3012],
                        [-68.3763, 47.2952],
                        [-68.3779, 47.2874],
                        [-68.39, 47.286],
                        [-68.4151, 47.2875],
                        [-68.4298, 47.2813],
                        [-68.4585, 47.2844],
                        [-68.4646, 47.2936],
                        [-68.475, 47.2972],
                        [-68.4902, 47.2939],
                        [-68.5035, 47.296],
                        [-68.5168, 47.2961],
                        [-68.5302, 47.292],
                        [-68.5388, 47.2853],
                        [-68.5498, 47.2822],
                        [-68.5719, 47.2872],
                        [-68.5801, 47.2871],
                        [-68.5895, 47.2806],
                        [-68.5977, 47.2702],
                        [-68.5933, 47.2611],
                        [-68.6062, 47.2477],
                        [-68.6209, 47.2428],
                        [-68.6638, 47.2365],
                        [-68.6746, 47.2417],
                        [-68.6883, 47.244],
                        [-68.7059, 47.2377],
                        [-68.7153, 47.2408],
                        [-68.7343, 47.2357],
                        [-68.7506, 47.2271],
                        [-68.769, 47.2212],
                        [-68.7813, 47.2211],
                        [-68.8089, 47.2142],
                        [-68.8325, 47.202],
                        [-68.8555, 47.1917],
                        [-68.8761, 47.1873],
                        [-68.8827, 47.183],
                        [-68.8979, 47.1822],
                        [-68.9028, 47.1795],
                        [-68.9179, 47.1898],
                        [-68.92, 47.1958],
                        [-68.932, 47.1997],
                        [-68.9408, 47.2057],
                        [-68.9504, 47.2021],
                        [-68.9596, 47.2053],
                        [-68.9672, 47.2134],
                        [-68.9883, 47.2236],
                        [-69.0219, 47.2367],
                        [-69.0338, 47.2404],
                        [-69.0409, 47.245],
                        [-69.0505, 47.2563],
                        [-69.0468, 47.2715],
                        [-69.0533, 47.2974],
                        [-69.0507, 47.3001],
                        [-68.7996, 47.3489],
                        [-68.5693, 47.4271],
                        [-68.4878, 47.4829],
                        [-68.3835, 47.5532],
                        [-68.383, 47.6607],
                        [-68.3824, 47.7786],
                        [-68.3827, 47.8651],
                        [-68.3823, 47.9161],
                        [-68.3469, 47.9164],
                        [-68.2601, 47.9162],
                        [-68.1228, 47.9163],
                        [-68.1226, 47.9997],
                        [-67.9761, 47.9998],
                        [-67.7981, 47.9999],
                        [-67.5981, 47.9999],
                        [-67.598, 47.9957],
                        [-67.6111, 47.9891],
                        [-67.6129, 47.9785],
                        [-67.6052, 47.9772],
                        [-67.5985, 47.9682],
                        [-67.6066, 47.9612],
                        [-67.6023, 47.9532],
                        [-67.6087, 47.9473],
                        [-67.6073, 47.9357],
                        [-67.5968, 47.9317],
                        [-67.5947, 47.925],
                        [-67.5821, 47.9265],
                        [-67.5596, 47.919],
                        [-67.5475, 47.9202],
                        [-67.5287, 47.9129],
                        [-67.5135, 47.9127],
                        [-67.5054, 47.9068],
                        [-67.4823, 47.9026],
                        [-67.4722, 47.8944],
                        [-67.4592, 47.8896],
                        [-67.4415, 47.8854],
                        [-67.4383, 47.8807],
                        [-67.4217, 47.8783],
                        [-67.4005, 47.8694],
                        [-67.3925, 47.8676],
                        [-67.3943, 47.862],
                        [-67.3817, 47.8585],
                        [-67.385, 47.8477],
                        [-67.3724, 47.8453],
                        [-67.3605, 47.8459],
                        [-67.3575, 47.8491],
                        [-67.3647, 47.8562],
                        [-67.3519, 47.8667],
                        [-67.3471, 47.874],
                        [-67.3367, 47.8728],
                        [-67.3383, 47.8882],
                        [-67.3361, 47.8908],
                        [-67.3156, 47.8888],
                        [-67.3048, 47.8857],
                        [-67.2947, 47.8886],
                        [-67.2985, 47.8963],
                        [-67.2861, 47.8986],
                        [-67.2789, 47.893],
                        [-67.269, 47.8904],
                        [-67.2584, 47.891],
                        [-67.2474, 47.8824],
                        [-67.2398, 47.8827],
                        [-67.2316, 47.8874],
                        [-67.2249, 47.8854],
                        [-67.2171, 47.8774],
                        [-67.2034, 47.8767],
                        [-67.192, 47.8813],
                        [-67.1751, 47.8827],
                        [-67.1685, 47.8958],
                        [-67.1612, 47.8962],
                        [-67.15, 47.8904],
                        [-67.1375, 47.8946],
                        [-67.1326, 47.9038],
                        [-67.1358, 47.9121],
                        [-67.133, 47.9153],
                        [-67.1066, 47.9134],
                        [-67.0951, 47.9191],
                        [-67.0691, 47.9236],
                        [-67.0622, 47.935],
                        [-67.049, 47.9335],
                        [-67.0431, 47.9256],
                        [-67.0182, 47.9144],
                        [-67.0066, 47.9109],
                        [-66.9978, 47.9032],
                        [-66.9758, 47.8932],
                        [-66.9574, 47.8921],
                        [-66.9476, 47.897],
                        [-66.9447, 47.9049],
                        [-66.9505, 47.9094],
                        [-66.9688, 47.9138],
                        [-66.9625, 47.9449],
                        [-66.9524, 47.9503],
                        [-66.9454, 47.957],
                        [-66.9419, 47.971],
                        [-66.9289, 47.9767],
                        [-66.9213, 47.9846],
                        [-66.8906, 47.9927],
                        [-66.8755, 47.9938],
                        [-66.8644, 47.9985],
                        [-66.8506, 48.0012],
                        [-66.8346, 48.0007],
                        [-66.8009, 47.9921],
                        [-66.7869, 47.9919],
                        [-66.7648, 48.0016],
                        [-66.7434, 48.0034],
                        [-66.7267, 48.0094],
                        [-66.6936, 48.0068],
                        [-66.6765, 48.0122],
                        [-66.6326, 48.0221],
                        [-66.5315, 48.0473],
                        [-66.5248, 48.0501],
                        [-66.5024, 48.077],
                        [-66.4822, 48.0835],
                        [-66.4698, 48.0856],
                        [-66.4292, 48.0887],
                        [-66.3984, 48.0893],
                        [-66.3821, 48.0882],
                        [-66.3644, 48.0833],
                        [-66.3489, 48.0771],
                        [-66.3259, 48.0608]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "37",
            properties: { cityName: "Nova Scotia", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-60.599, 47.0429],
                        [-60.5885, 47.0406],
                        [-60.5829, 47.036],
                        [-60.5638, 47.0319],
                        [-60.557, 47.0263],
                        [-60.5422, 47.0255],
                        [-60.5334, 47.0291],
                        [-60.5154, 47.0146],
                        [-60.5122, 47.0044],
                        [-60.4915, 47.0022],
                        [-60.4695, 47.0041],
                        [-60.4746, 46.9977],
                        [-60.4668, 46.9959],
                        [-60.4675, 47.0047],
                        [-60.4534, 47.0097],
                        [-60.4394, 47.0181],
                        [-60.4388, 47.0251],
                        [-60.4235, 47.0348],
                        [-60.4131, 47.0352],
                        [-60.3995, 47.0304],
                        [-60.3905, 47.0304],
                        [-60.3857, 47.0203],
                        [-60.3945, 47.0096],
                        [-60.4021, 46.9973],
                        [-60.4129, 46.9924],
                        [-60.4367, 46.9704],
                        [-60.4536, 46.9582],
                        [-60.4594, 46.9498],
                        [-60.4618, 46.9294],
                        [-60.4673, 46.9326],
                        [-60.4777, 46.9277],
                        [-60.4789, 46.9227],
                        [-60.4905, 46.9152],
                        [-60.5035, 46.9119],
                        [-60.5141, 46.9064],
                        [-60.5055, 46.8995],
                        [-60.4938, 46.8978],
                        [-60.4862, 46.9044],
                        [-60.4606, 46.906],
                        [-60.4555, 46.9076],
                        [-60.442, 46.8948],
                        [-60.4398, 46.888],
                        [-60.4533, 46.8856],
                        [-60.4543, 46.8799],
                        [-60.4682, 46.8771],
                        [-60.4506, 46.8677],
                        [-60.425, 46.8758],
                        [-60.4039, 46.8759],
                        [-60.4013, 46.8715],
                        [-60.3921, 46.8734],
                        [-60.3679, 46.87],
                        [-60.3648, 46.875],
                        [-60.3519, 46.8746],
                        [-60.3504, 46.8847],
                        [-60.3335, 46.8743],
                        [-60.3355, 46.8718],
                        [-60.3171, 46.864],
                        [-60.311, 46.8541],
                        [-60.304, 46.8517],
                        [-60.3064, 46.8349],
                        [-60.3153, 46.8225],
                        [-60.3152, 46.8155],
                        [-60.3258, 46.8045],
                        [-60.3181, 46.7927],
                        [-60.3161, 46.785],
                        [-60.333, 46.7746],
                        [-60.3205, 46.7678],
                        [-60.3255, 46.762],
                        [-60.3205, 46.7537],
                        [-60.3359, 46.7305],
                        [-60.3492, 46.7232],
                        [-60.3512, 46.7091],
                        [-60.3425, 46.7008],
                        [-60.3529, 46.6978],
                        [-60.355, 46.6941],
                        [-60.3801, 46.6878],
                        [-60.3934, 46.6879],
                        [-60.4018, 46.6819],
                        [-60.4058, 46.672],
                        [-60.4063, 46.6605],
                        [-60.3953, 46.6614],
                        [-60.3758, 46.6552],
                        [-60.3888, 46.6525],
                        [-60.3905, 46.6384],
                        [-60.4036, 46.6374],
                        [-60.4037, 46.6303],
                        [-60.3958, 46.6282],
                        [-60.3879, 46.633],
                        [-60.3726, 46.6299],
                        [-60.3499, 46.6274],
                        [-60.3548, 46.612],
                        [-60.3593, 46.6105],
                        [-60.3762, 46.5925],
                        [-60.3787, 46.5853],
                        [-60.3894, 46.5743],
                        [-60.3996, 46.5586],
                        [-60.407, 46.5511],
                        [-60.4118, 46.5363],
                        [-60.4161, 46.5324],
                        [-60.4172, 46.5207],
                        [-60.4292, 46.5094],
                        [-60.4339, 46.5025],
                        [-60.439, 46.484],
                        [-60.4443, 46.4803],
                        [-60.4548, 46.459],
                        [-60.4595, 46.4536],
                        [-60.4604, 46.443],
                        [-60.458, 46.4312],
                        [-60.4749, 46.4135],
                        [-60.4809, 46.4042],
                        [-60.4945, 46.3926],
                        [-60.504, 46.3888],
                        [-60.5223, 46.3741],
                        [-60.5239, 46.3653],
                        [-60.5177, 46.3483],
                        [-60.5343, 46.3316],
                        [-60.5447, 46.3168],
                        [-60.5484, 46.3047],
                        [-60.5663, 46.2831],
                        [-60.5723, 46.2776],
                        [-60.5906, 46.2695],
                        [-60.6078, 46.2676],
                        [-60.6141, 46.263],
                        [-60.5931, 46.2573],
                        [-60.5989, 46.2377],
                        [-60.6069, 46.234],
                        [-60.6082, 46.2256],
                        [-60.5979, 46.2242],
                        [-60.5939, 46.2206],
                        [-60.599, 46.2114],
                        [-60.5928, 46.2094],
                        [-60.5764, 46.2329],
                        [-60.5629, 46.2448],
                        [-60.5497, 46.2665],
                        [-60.5501, 46.2731],
                        [-60.5409, 46.289],
                        [-60.532, 46.298],
                        [-60.5149, 46.3052],
                        [-60.5131, 46.3087],
                        [-60.4961, 46.3128],
                        [-60.4762, 46.3277],
                        [-60.4659, 46.337],
                        [-60.4469, 46.3459],
                        [-60.4342, 46.3439],
                        [-60.4275, 46.3457],
                        [-60.415, 46.3422],
                        [-60.4147, 46.3332],
                        [-60.4196, 46.3254],
                        [-60.4164, 46.317],
                        [-60.4197, 46.3057],
                        [-60.4167, 46.2933],
                        [-60.4323, 46.2865],
                        [-60.4379, 46.2912],
                        [-60.4607, 46.2688],
                        [-60.4721, 46.2656],
                        [-60.4883, 46.2503],
                        [-60.5037, 46.2336],
                        [-60.504, 46.2278],
                        [-60.5183, 46.2237],
                        [-60.5221, 46.225],
                        [-60.5331, 46.2187],
                        [-60.5342, 46.2127],
                        [-60.5447, 46.21],
                        [-60.5684, 46.1798],
                        [-60.5754, 46.1747],
                        [-60.5893, 46.1543],
                        [-60.6064, 46.145],
                        [-60.6153, 46.1381],
                        [-60.6145, 46.1349],
                        [-60.6497, 46.116],
                        [-60.6551, 46.117],
                        [-60.6751, 46.1121],
                        [-60.6807, 46.1054],
                        [-60.7029, 46.1006],
                        [-60.7144, 46.0942],
                        [-60.7202, 46.0998],
                        [-60.7133, 46.1186],
                        [-60.7192, 46.1207],
                        [-60.7361, 46.1054],
                        [-60.7484, 46.0999],
                        [-60.7526, 46.0939],
                        [-60.7718, 46.088],
                        [-60.7825, 46.0863],
                        [-60.8025, 46.0782],
                        [-60.8171, 46.0772],
                        [-60.8404, 46.0709],
                        [-60.8499, 46.0715],
                        [-60.8615, 46.0789],
                        [-60.8742, 46.0759],
                        [-60.8736, 46.0709],
                        [-60.8799, 46.0649],
                        [-60.8905, 46.0646],
                        [-60.8892, 46.0742],
                        [-60.8737, 46.0821],
                        [-60.8682, 46.0806],
                        [-60.8614, 46.0864],
                        [-60.8729, 46.0943],
                        [-60.8822, 46.0944],
                        [-60.8982, 46.0881],
                        [-60.9064, 46.0831],
                        [-60.9103, 46.0741],
                        [-60.9068, 46.0634],
                        [-60.9145, 46.0547],
                        [-60.933, 46.0521],
                        [-60.9415, 46.0483],
                        [-60.9531, 46.0334],
                        [-60.9606, 46.0302],
                        [-60.9643, 46.0222],
                        [-60.9758, 46.0148],
                        [-60.9667, 46.0071],
                        [-60.9756, 46.0003],
                        [-60.9869, 45.9961],
                        [-60.9856, 45.9928],
                        [-61.0039, 45.989],
                        [-61.0164, 45.9835],
                        [-61.0211, 45.9847],
                        [-61.0296, 45.9791],
                        [-61.0444, 45.9768],
                        [-61.0651, 45.969],
                        [-61.0779, 45.9611],
                        [-61.0972, 45.9603],
                        [-61.1094, 45.9638],
                        [-61.1125, 45.9712],
                        [-61.1219, 45.9724],
                        [-61.1309, 45.9676],
                        [-61.1249, 45.9583],
                        [-61.126, 45.952],
                        [-61.1433, 45.9434],
                        [-61.1397, 45.9387],
                        [-61.1266, 45.9379],
                        [-61.1058, 45.9395],
                        [-61.1001, 45.9424],
                        [-61.0891, 45.9317],
                        [-61.0817, 45.9396],
                        [-61.0709, 45.9406],
                        [-61.0645, 45.9372],
                        [-61.0541, 45.9393],
                        [-61.0523, 45.9485],
                        [-61.0392, 45.9544],
                        [-61.011, 45.9567],
                        [-61.0084, 45.96],
                        [-60.9863, 45.9542],
                        [-60.9912, 45.9679],
                        [-60.9868, 45.9894],
                        [-60.9781, 45.997],
                        [-60.9712, 45.9959],
                        [-60.9465, 46.0075],
                        [-60.9292, 46.018],
                        [-60.9141, 46.0213],
                        [-60.8976, 46.023],
                        [-60.912, 46.0314],
                        [-60.924, 46.0332],
                        [-60.9188, 46.0411],
                        [-60.9121, 46.038],
                        [-60.8975, 46.0405],
                        [-60.8938, 46.0469],
                        [-60.88, 46.0482],
                        [-60.8524, 46.0541],
                        [-60.8503, 46.0507],
                        [-60.8586, 46.0373],
                        [-60.8537, 46.0301],
                        [-60.8447, 46.033],
                        [-60.8365, 46.0439],
                        [-60.8201, 46.0444],
                        [-60.8092, 46.0523],
                        [-60.7768, 46.0653],
                        [-60.7715, 46.0691],
                        [-60.7544, 46.0724],
                        [-60.7508, 46.0764],
                        [-60.737, 46.0689],
                        [-60.7398, 46.0582],
                        [-60.7578, 46.0473],
                        [-60.7704, 46.0287],
                        [-60.7811, 46.025],
                        [-60.7789, 46.0192],
                        [-60.797, 46.0055],
                        [-60.8054, 46.0012],
                        [-60.8037, 45.9949],
                        [-60.8101, 45.9847],
                        [-60.8064, 45.9773],
                        [-60.8112, 45.9726],
                        [-60.8016, 45.9603],
                        [-60.8106, 45.9529],
                        [-60.8122, 45.946],
                        [-60.8186, 45.944],
                        [-60.8366, 45.9461],
                        [-60.8554, 45.9412],
                        [-60.8651, 45.9418],
                        [-60.8753, 45.9303],
                        [-60.8945, 45.9235],
                        [-60.9013, 45.9254],
                        [-60.909, 45.9198],
                        [-60.9203, 45.9238],
                        [-60.9355, 45.9232],
                        [-60.9364, 45.9259],
                        [-60.9133, 45.9277],
                        [-60.9097, 45.9369],
                        [-60.9239, 45.93],
                        [-60.9326, 45.9305],
                        [-60.9482, 45.9253],
                        [-60.9521, 45.9204],
                        [-60.9619, 45.9216],
                        [-60.9747, 45.9178],
                        [-60.9888, 45.9324],
                        [-60.9969, 45.929],
                        [-60.9911, 45.9231],
                        [-60.9994, 45.9101],
                        [-61.0097, 45.9057],
                        [-61.0252, 45.9024],
                        [-61.0338, 45.9028],
                        [-61.0407, 45.9071],
                        [-61.0505, 45.9062],
                        [-61.0557, 45.9094],
                        [-61.0732, 45.9026],
                        [-61.0741, 45.897],
                        [-61.0628, 45.8947],
                        [-61.0584, 45.8993],
                        [-61.0286, 45.8948],
                        [-61.0169, 45.903],
                        [-60.9993, 45.9014],
                        [-60.9741, 45.9039],
                        [-60.9616, 45.8895],
                        [-60.9601, 45.8843],
                        [-60.9446, 45.8846],
                        [-60.9377, 45.8715],
                        [-60.926, 45.8675],
                        [-60.9285, 45.8578],
                        [-60.9388, 45.8575],
                        [-60.9514, 45.8602],
                        [-60.9551, 45.8742],
                        [-60.9598, 45.8768],
                        [-60.9801, 45.88],
                        [-60.991, 45.8774],
                        [-60.9851, 45.8668],
                        [-60.9689, 45.8734],
                        [-60.9669, 45.8667],
                        [-60.9768, 45.8654],
                        [-60.9626, 45.8572],
                        [-60.9554, 45.8574],
                        [-60.9364, 45.8503],
                        [-60.9315, 45.8446],
                        [-60.9464, 45.8428],
                        [-60.9476, 45.8342],
                        [-60.9593, 45.8359],
                        [-60.9715, 45.8416],
                        [-60.9614, 45.8545],
                        [-60.9715, 45.8549],
                        [-60.9779, 45.8495],
                        [-60.973, 45.8423],
                        [-60.9764, 45.8393],
                        [-60.9942, 45.833],
                        [-61.0067, 45.8237],
                        [-61.0224, 45.8311],
                        [-61.0248, 45.8276],
                        [-61.0393, 45.8217],
                        [-61.0397, 45.8164],
                        [-61.0652, 45.8051],
                        [-61.0877, 45.799],
                        [-61.1026, 45.7922],
                        [-61.1117, 45.7817],
                        [-61.1321, 45.7712],
                        [-61.1472, 45.76],
                        [-61.1515, 45.7506],
                        [-61.1429, 45.7447],
                        [-61.1336, 45.7498],
                        [-61.1291, 45.7581],
                        [-61.1204, 45.7538],
                        [-61.126, 45.7501],
                        [-61.1284, 45.7432],
                        [-61.1209, 45.7416],
                        [-61.1247, 45.7329],
                        [-61.1315, 45.7315],
                        [-61.139, 45.7256],
                        [-61.1515, 45.7238],
                        [-61.1572, 45.7133],
                        [-61.1495, 45.7036],
                        [-61.132, 45.6996],
                        [-61.1199, 45.7065],
                        [-61.1156, 45.7021],
                        [-61.0982, 45.6938],
                        [-61.0819, 45.703],
                        [-61.076, 45.71],
                        [-61.0611, 45.7131],
                        [-61.0281, 45.7263],
                        [-61.0105, 45.7356],
                        [-61.0043, 45.7427],
                        [-60.9945, 45.7457],
                        [-60.9906, 45.752],
                        [-60.9553, 45.7656],
                        [-60.9445, 45.7652],
                        [-60.9369, 45.7689],
                        [-60.9153, 45.7644],
                        [-60.9022, 45.7661],
                        [-60.8788, 45.7568],
                        [-60.8682, 45.7574],
                        [-60.8503, 45.7537],
                        [-60.8441, 45.7471],
                        [-60.8315, 45.7385],
                        [-60.8216, 45.7354],
                        [-60.8135, 45.737],
                        [-60.8093, 45.7333],
                        [-60.8129, 45.7249],
                        [-60.8064, 45.7175],
                        [-60.811, 45.7066],
                        [-60.8189, 45.7068],
                        [-60.8208, 45.6922],
                        [-60.8301, 45.688],
                        [-60.8349, 45.6823],
                        [-60.8524, 45.6794],
                        [-60.8496, 45.6725],
                        [-60.8555, 45.6695],
                        [-60.8673, 45.6684],
                        [-60.8681, 45.6637],
                        [-60.8604, 45.6535],
                        [-60.846, 45.6499],
                        [-60.8399, 45.6535],
                        [-60.8392, 45.6651],
                        [-60.8506, 45.6647],
                        [-60.8519, 45.6702],
                        [-60.8296, 45.6729],
                        [-60.8184, 45.6723],
                        [-60.825, 45.6819],
                        [-60.8183, 45.6875],
                        [-60.7889, 45.6869],
                        [-60.7772, 45.6921],
                        [-60.7824, 45.7013],
                        [-60.7752, 45.7066],
                        [-60.7561, 45.7045],
                        [-60.7494, 45.6945],
                        [-60.7406, 45.6989],
                        [-60.7487, 45.7067],
                        [-60.7453, 45.7179],
                        [-60.7467, 45.7314],
                        [-60.7295, 45.7285],
                        [-60.731, 45.7344],
                        [-60.7585, 45.7359],
                        [-60.7597, 45.7407],
                        [-60.7766, 45.7422],
                        [-60.7801, 45.7482],
                        [-60.7634, 45.7534],
                        [-60.7512, 45.7436],
                        [-60.7443, 45.7478],
                        [-60.7577, 45.7552],
                        [-60.7511, 45.7751],
                        [-60.7452, 45.778],
                        [-60.7458, 45.7883],
                        [-60.7391, 45.798],
                        [-60.7313, 45.8001],
                        [-60.7211, 45.8092],
                        [-60.7019, 45.817],
                        [-60.6866, 45.8186],
                        [-60.6746, 45.8223],
                        [-60.6652, 45.8291],
                        [-60.661, 45.8366],
                        [-60.6481, 45.8516],
                        [-60.6401, 45.8585],
                        [-60.6247, 45.8659],
                        [-60.6109, 45.8747],
                        [-60.6066, 45.8726],
                        [-60.5959, 45.8811],
                        [-60.5848, 45.8871],
                        [-60.5832, 45.8919],
                        [-60.5641, 45.8953],
                        [-60.5521, 45.9034],
                        [-60.5356, 45.9118],
                        [-60.5296, 45.9229],
                        [-60.5219, 45.9316],
                        [-60.511, 45.934],
                        [-60.5024, 45.9404],
                        [-60.4935, 45.9432],
                        [-60.4821, 45.9511],
                        [-60.4793, 45.9573],
                        [-60.4689, 45.9613],
                        [-60.4511, 45.9712],
                        [-60.4358, 45.9768],
                        [-60.4235, 45.9841],
                        [-60.4076, 45.9993],
                        [-60.4083, 46.0038],
                        [-60.4016, 46.0083],
                        [-60.3795, 46.0177],
                        [-60.382, 46.0215],
                        [-60.4023, 46.0124],
                        [-60.4099, 46.011],
                        [-60.4255, 46.0004],
                        [-60.4539, 45.9862],
                        [-60.4686, 45.9843],
                        [-60.4847, 45.976],
                        [-60.4962, 45.9741],
                        [-60.5129, 45.9656],
                        [-60.5336, 45.9594],
                        [-60.5427, 45.9625],
                        [-60.5611, 45.962],
                        [-60.5808, 45.9557],
                        [-60.5802, 45.9479],
                        [-60.5579, 45.949],
                        [-60.5486, 45.9423],
                        [-60.5579, 45.9392],
                        [-60.5593, 45.9478],
                        [-60.5885, 45.9463],
                        [-60.6218, 45.9384],
                        [-60.6272, 45.9305],
                        [-60.6354, 45.9294],
                        [-60.6326, 45.922],
                        [-60.6476, 45.9202],
                        [-60.6528, 45.9127],
                        [-60.6654, 45.9069],
                        [-60.6727, 45.9085],
                        [-60.6961, 45.9015],
                        [-60.7186, 45.8994],
                        [-60.7337, 45.9055],
                        [-60.7322, 45.9149],
                        [-60.7388, 45.9303],
                        [-60.7447, 45.9346],
                        [-60.7557, 45.9376],
                        [-60.7634, 45.9335],
                        [-60.7776, 45.9344],
                        [-60.7863, 45.9322],
                        [-60.7964, 45.9358],
                        [-60.7999, 45.9426],
                        [-60.7933, 45.9545],
                        [-60.7823, 45.9627],
                        [-60.7694, 45.9612],
                        [-60.7545, 45.9714],
                        [-60.7434, 45.9759],
                        [-60.7171, 45.9928],
                        [-60.7105, 46.0005],
                        [-60.6932, 46.0126],
                        [-60.6803, 46.0151],
                        [-60.6695, 46.0216],
                        [-60.6609, 46.019],
                        [-60.6471, 46.0223],
                        [-60.6416, 46.0294],
                        [-60.622, 46.0385],
                        [-60.6082, 46.0475],
                        [-60.5808, 46.0595],
                        [-60.5704, 46.0691],
                        [-60.5465, 46.0765],
                        [-60.5263, 46.0869],
                        [-60.5203, 46.0932],
                        [-60.5119, 46.0945],
                        [-60.5013, 46.1039],
                        [-60.4955, 46.106],
                        [-60.4702, 46.1292],
                        [-60.4488, 46.1443],
                        [-60.425, 46.1493],
                        [-60.4105, 46.1686],
                        [-60.4102, 46.1738],
                        [-60.4013, 46.1875],
                        [-60.3892, 46.2018],
                        [-60.3637, 46.2107],
                        [-60.3533, 46.2121],
                        [-60.3167, 46.2123],
                        [-60.3039, 46.2295],
                        [-60.2932, 46.2503],
                        [-60.294, 46.2558],
                        [-60.2967, 46.2595],
                        [-60.2928, 46.2625],
                        [-60.2949, 46.2725],
                        [-60.3009, 46.2815],
                        [-60.2911, 46.289],
                        [-60.2868, 46.2889],
                        [-60.2839, 46.2934],
                        [-60.2863, 46.2954],
                        [-60.2847, 46.3096],
                        [-60.2792, 46.3144],
                        [-60.2717, 46.3102],
                        [-60.2678, 46.2925],
                        [-60.2598, 46.2854],
                        [-60.2531, 46.2751],
                        [-60.2541, 46.269],
                        [-60.2451, 46.2693],
                        [-60.2423, 46.2638],
                        [-60.2351, 46.2611],
                        [-60.2237, 46.2614],
                        [-60.2066, 46.2559],
                        [-60.2111, 46.2512],
                        [-60.2134, 46.2401],
                        [-60.2181, 46.232],
                        [-60.2259, 46.2289],
                        [-60.2384, 46.2132],
                        [-60.2524, 46.2059],
                        [-60.2614, 46.1949],
                        [-60.2744, 46.1883],
                        [-60.2792, 46.1832],
                        [-60.2906, 46.1789],
                        [-60.2968, 46.1729],
                        [-60.3043, 46.1714],
                        [-60.3071, 46.1664],
                        [-60.3021, 46.1613],
                        [-60.3076, 46.1503],
                        [-60.293, 46.1541],
                        [-60.2844, 46.1619],
                        [-60.2699, 46.1651],
                        [-60.2709, 46.1702],
                        [-60.2585, 46.1786],
                        [-60.2439, 46.1846],
                        [-60.2285, 46.1875],
                        [-60.2247, 46.1823],
                        [-60.2275, 46.1774],
                        [-60.2187, 46.1651],
                        [-60.2204, 46.1505],
                        [-60.2077, 46.1408],
                        [-60.2023, 46.1438],
                        [-60.2064, 46.154],
                        [-60.1984, 46.1618],
                        [-60.1947, 46.173],
                        [-60.2042, 46.1875],
                        [-60.2032, 46.2011],
                        [-60.1982, 46.2111],
                        [-60.1907, 46.2157],
                        [-60.179, 46.2324],
                        [-60.1686, 46.2426],
                        [-60.1546, 46.2503],
                        [-60.1383, 46.2555],
                        [-60.1351, 46.2619],
                        [-60.127, 46.2674],
                        [-60.1149, 46.2619],
                        [-60.1048, 46.2622],
                        [-60.0891, 46.2588],
                        [-60.0801, 46.2543],
                        [-60.0628, 46.2532],
                        [-60.0473, 46.2497],
                        [-60.0325, 46.2435],
                        [-60.0369, 46.2354],
                        [-60.0352, 46.2301],
                        [-60.0551, 46.2296],
                        [-60.0568, 46.2268],
                        [-60.0727, 46.2224],
                        [-60.0806, 46.2172],
                        [-60.0714, 46.2094],
                        [-60.0607, 46.212],
                        [-60.0551, 46.2092],
                        [-60.035, 46.2152],
                        [-60.0209, 46.2148],
                        [-60.0034, 46.2202],
                        [-59.9953, 46.2165],
                        [-59.9786, 46.2215],
                        [-59.963, 46.2174],
                        [-59.9544, 46.2176],
                        [-59.9504, 46.1991],
                        [-59.9398, 46.1959],
                        [-59.9348, 46.186],
                        [-59.9512, 46.1682],
                        [-59.9443, 46.1614],
                        [-59.9266, 46.1733],
                        [-59.9274, 46.1794],
                        [-59.9159, 46.178],
                        [-59.9055, 46.1814],
                        [-59.9025, 46.1872],
                        [-59.8801, 46.1921],
                        [-59.8678, 46.1916],
                        [-59.8584, 46.1841],
                        [-59.8442, 46.1777],
                        [-59.8337, 46.1826],
                        [-59.8165, 46.1809],
                        [-59.8123, 46.1742],
                        [-59.8151, 46.17],
                        [-59.8396, 46.1681],
                        [-59.8524, 46.1609],
                        [-59.8521, 46.1556],
                        [-59.8616, 46.1498],
                        [-59.8622, 46.1424],
                        [-59.8755, 46.13],
                        [-59.8787, 46.1238],
                        [-59.8967, 46.1138],
                        [-59.9024, 46.1065],
                        [-59.8966, 46.0998],
                        [-59.8957, 46.0933],
                        [-59.8904, 46.091],
                        [-59.8889, 46.0813],
                        [-59.892, 46.0744],
                        [-59.8904, 46.0589],
                        [-59.8958, 46.0541],
                        [-59.9135, 46.0508],
                        [-59.9218, 46.0467],
                        [-59.9304, 46.0525],
                        [-59.9389, 46.0496],
                        [-59.9543, 46.048],
                        [-59.9589, 46.0456],
                        [-59.9682, 46.034],
                        [-59.9667, 46.0234],
                        [-59.963, 46.0182],
                        [-59.9511, 46.0106],
                        [-59.9388, 46.012],
                        [-59.9286, 46.006],
                        [-59.91, 46.0073],
                        [-59.8951, 46.0039],
                        [-59.8834, 46.0081],
                        [-59.8584, 46.0138],
                        [-59.8408, 46.016],
                        [-59.8294, 46.0125],
                        [-59.8293, 46.006],
                        [-59.8466, 46.0026],
                        [-59.8466, 45.9933],
                        [-59.8157, 45.9774],
                        [-59.8071, 45.9622],
                        [-59.7996, 45.956],
                        [-59.7902, 45.9548],
                        [-59.7919, 45.9496],
                        [-59.801, 45.9516],
                        [-59.8134, 45.9425],
                        [-59.836, 45.9446],
                        [-59.827, 45.9506],
                        [-59.8302, 45.9589],
                        [-59.838, 45.9518],
                        [-59.8437, 45.9525],
                        [-59.8629, 45.9472],
                        [-59.8676, 45.9408],
                        [-59.8842, 45.9423],
                        [-59.8871, 45.9363],
                        [-59.9055, 45.9362],
                        [-59.9096, 45.9288],
                        [-59.9167, 45.9276],
                        [-59.9173, 45.9198],
                        [-59.9316, 45.9152],
                        [-59.9435, 45.9171],
                        [-59.9441, 45.912],
                        [-59.9565, 45.906],
                        [-59.9689, 45.9102],
                        [-59.9614, 45.923],
                        [-59.9769, 45.9167],
                        [-59.9798, 45.9093],
                        [-59.9946, 45.8965],
                        [-59.9868, 45.8932],
                        [-59.9782, 45.8939],
                        [-59.9812, 45.8843],
                        [-59.9968, 45.8788],
                        [-60.0141, 45.8755],
                        [-60.0271, 45.8751],
                        [-60.0547, 45.8779],
                        [-60.0671, 45.8715],
                        [-60.0906, 45.8775],
                        [-60.1015, 45.8757],
                        [-60.1158, 45.8842],
                        [-60.1311, 45.8836],
                        [-60.1419, 45.877],
                        [-60.1489, 45.8773],
                        [-60.1585, 45.8713],
                        [-60.1636, 45.8609],
                        [-60.1718, 45.8557],
                        [-60.1699, 45.8457],
                        [-60.1656, 45.8401],
                        [-60.1554, 45.8369],
                        [-60.1434, 45.8418],
                        [-60.1262, 45.8263],
                        [-60.1187, 45.8219],
                        [-60.1073, 45.8223],
                        [-60.0918, 45.8156],
                        [-60.0806, 45.8135],
                        [-60.0807, 45.8026],
                        [-60.0944, 45.7977],
                        [-60.1041, 45.7886],
                        [-60.1151, 45.7814],
                        [-60.1333, 45.7858],
                        [-60.1532, 45.7823],
                        [-60.1672, 45.7821],
                        [-60.1924, 45.7733],
                        [-60.2021, 45.7678],
                        [-60.211, 45.7532],
                        [-60.2205, 45.7462],
                        [-60.229, 45.7297],
                        [-60.2269, 45.7252],
                        [-60.2371, 45.7231],
                        [-60.2456, 45.7185],
                        [-60.255, 45.7196],
                        [-60.2537, 45.7091],
                        [-60.2579, 45.7068],
                        [-60.2801, 45.7021],
                        [-60.2932, 45.7089],
                        [-60.2891, 45.7149],
                        [-60.3039, 45.7151],
                        [-60.3085, 45.7197],
                        [-60.3202, 45.7149],
                        [-60.3203, 45.71],
                        [-60.3325, 45.7003],
                        [-60.3341, 45.6947],
                        [-60.3425, 45.6919],
                        [-60.3444, 45.6846],
                        [-60.3529, 45.6741],
                        [-60.3802, 45.6636],
                        [-60.3828, 45.6597],
                        [-60.3949, 45.6567],
                        [-60.401, 45.6528],
                        [-60.4139, 45.656],
                        [-60.4186, 45.6447],
                        [-60.4271, 45.6423],
                        [-60.4343, 45.6448],
                        [-60.4397, 45.6394],
                        [-60.4752, 45.6399],
                        [-60.4883, 45.6443],
                        [-60.4944, 45.6542],
                        [-60.5078, 45.6488],
                        [-60.4965, 45.645],
                        [-60.4977, 45.6318],
                        [-60.5172, 45.6282],
                        [-60.5286, 45.6312],
                        [-60.5438, 45.6305],
                        [-60.5515, 45.6273],
                        [-60.5766, 45.6244],
                        [-60.5904, 45.6195],
                        [-60.5985, 45.613],
                        [-60.6114, 45.6153],
                        [-60.6217, 45.6136],
                        [-60.6267, 45.6028],
                        [-60.6362, 45.599],
                        [-60.6451, 45.5987],
                        [-60.6626, 45.5926],
                        [-60.6791, 45.5919],
                        [-60.6872, 45.5857],
                        [-60.6898, 45.5742],
                        [-60.7046, 45.5774],
                        [-60.7404, 45.5741],
                        [-60.7384, 45.5881],
                        [-60.7492, 45.5979],
                        [-60.7646, 45.6079],
                        [-60.7791, 45.6059],
                        [-60.7843, 45.6089],
                        [-60.7932, 45.6076],
                        [-60.7962, 45.6125],
                        [-60.8167, 45.611],
                        [-60.8355, 45.6163],
                        [-60.8648, 45.6207],
                        [-60.8721, 45.6198],
                        [-60.8681, 45.6315],
                        [-60.8619, 45.629],
                        [-60.8561, 45.6344],
                        [-60.8607, 45.6458],
                        [-60.8724, 45.6479],
                        [-60.8764, 45.6537],
                        [-60.8918, 45.6519],
                        [-60.8967, 45.6437],
                        [-60.8965, 45.6378],
                        [-60.9227, 45.6219],
                        [-60.9357, 45.6228],
                        [-60.9302, 45.6303],
                        [-60.9529, 45.632],
                        [-60.9497, 45.6249],
                        [-60.9697, 45.619],
                        [-60.9789, 45.6138],
                        [-60.9908, 45.6149],
                        [-60.9923, 45.6229],
                        [-60.9893, 45.6283],
                        [-60.9974, 45.6319],
                        [-61.0125, 45.6296],
                        [-61.0007, 45.6232],
                        [-61.0074, 45.6185],
                        [-61.0202, 45.6188],
                        [-61.0226, 45.6238],
                        [-61.0308, 45.629],
                        [-61.0393, 45.6275],
                        [-61.0429, 45.6213],
                        [-61.0281, 45.6234],
                        [-61.0262, 45.6114],
                        [-61.0138, 45.61],
                        [-61.0253, 45.6018],
                        [-61.0253, 45.599],
                        [-61.0663, 45.5958],
                        [-61.0643, 45.6063],
                        [-61.0801, 45.6052],
                        [-61.0686, 45.5983],
                        [-61.0723, 45.5937],
                        [-61.0823, 45.5924],
                        [-61.0881, 45.5851],
                        [-61.1017, 45.5826],
                        [-61.1096, 45.5783],
                        [-61.1278, 45.5733],
                        [-61.1474, 45.5713],
                        [-61.1626, 45.5655],
                        [-61.1816, 45.5647],
                        [-61.1743, 45.5817],
                        [-61.1632, 45.5803],
                        [-61.1558, 45.5872],
                        [-61.1648, 45.5954],
                        [-61.1713, 45.5956],
                        [-61.1761, 45.6018],
                        [-61.1992, 45.6035],
                        [-61.2081, 45.5984],
                        [-61.2217, 45.5998],
                        [-61.2305, 45.598],
                        [-61.2314, 45.5913],
                        [-61.2482, 45.5894],
                        [-61.2588, 45.5857],
                        [-61.2665, 45.5777],
                        [-61.2752, 45.5799],
                        [-61.2944, 45.5718],
                        [-61.2821, 45.5681],
                        [-61.2773, 45.5621],
                        [-61.2813, 45.5537],
                        [-61.3041, 45.5549],
                        [-61.331, 45.5647],
                        [-61.3418, 45.5746],
                        [-61.3418, 45.5803],
                        [-61.3512, 45.5851],
                        [-61.3509, 45.5903],
                        [-61.363, 45.5965],
                        [-61.372, 45.6086],
                        [-61.3583, 45.6068],
                        [-61.3725, 45.6259],
                        [-61.3835, 45.6368],
                        [-61.4092, 45.6473],
                        [-61.4146, 45.6469],
                        [-61.4203, 45.6544],
                        [-61.427, 45.6721],
                        [-61.4403, 45.6803],
                        [-61.4433, 45.6916],
                        [-61.4508, 45.6995],
                        [-61.458, 45.7031],
                        [-61.4668, 45.7219],
                        [-61.4626, 45.7275],
                        [-61.4638, 45.7442],
                        [-61.4687, 45.75],
                        [-61.4693, 45.7577],
                        [-61.4762, 45.7717],
                        [-61.4774, 45.7814],
                        [-61.4963, 45.8042],
                        [-61.493, 45.8169],
                        [-61.4959, 45.8385],
                        [-61.5024, 45.8494],
                        [-61.5048, 45.8618],
                        [-61.4942, 45.8903],
                        [-61.494, 45.8992],
                        [-61.5004, 45.9063],
                        [-61.5124, 45.9088],
                        [-61.512, 45.9133],
                        [-61.5239, 45.9255],
                        [-61.5214, 45.9363],
                        [-61.5329, 45.945],
                        [-61.5335, 45.9568],
                        [-61.5275, 45.96],
                        [-61.5318, 45.9684],
                        [-61.5305, 45.9864],
                        [-61.5317, 45.9941],
                        [-61.538, 46.0007],
                        [-61.5307, 46.0063],
                        [-61.5386, 46.0186],
                        [-61.539, 46.0248],
                        [-61.5492, 46.0283],
                        [-61.5516, 46.0355],
                        [-61.5458, 46.0479],
                        [-61.5363, 46.0546],
                        [-61.5255, 46.057],
                        [-61.516, 46.0642],
                        [-61.5, 46.0689],
                        [-61.4802, 46.0809],
                        [-61.4763, 46.0874],
                        [-61.4655, 46.0795],
                        [-61.462, 46.0813],
                        [-61.4488, 46.0744],
                        [-61.4348, 46.0771],
                        [-61.4287, 46.0698],
                        [-61.4355, 46.065],
                        [-61.4249, 46.0566],
                        [-61.4166, 46.0643],
                        [-61.4193, 46.0709],
                        [-61.4115, 46.0722],
                        [-61.4068, 46.0771],
                        [-61.4266, 46.0829],
                        [-61.4462, 46.0819],
                        [-61.4506, 46.0859],
                        [-61.4657, 46.0858],
                        [-61.4838, 46.0931],
                        [-61.4847, 46.0962],
                        [-61.4748, 46.1021],
                        [-61.4772, 46.1087],
                        [-61.4708, 46.1157],
                        [-61.4694, 46.1232],
                        [-61.4611, 46.129],
                        [-61.4656, 46.1355],
                        [-61.4508, 46.1489],
                        [-61.4479, 46.1608],
                        [-61.4317, 46.1716],
                        [-61.4273, 46.1841],
                        [-61.4199, 46.1904],
                        [-61.3786, 46.2057],
                        [-61.3702, 46.2103],
                        [-61.3476, 46.2175],
                        [-61.3256, 46.2282],
                        [-61.3213, 46.2269],
                        [-61.3124, 46.2351],
                        [-61.2826, 46.2572],
                        [-61.2704, 46.2685],
                        [-61.2735, 46.2744],
                        [-61.2701, 46.2843],
                        [-61.2639, 46.2872],
                        [-61.2558, 46.2965],
                        [-61.2582, 46.3035],
                        [-61.2463, 46.3097],
                        [-61.231, 46.3246],
                        [-61.194, 46.3519],
                        [-61.1826, 46.3617],
                        [-61.1807, 46.3742],
                        [-61.1714, 46.3925],
                        [-61.1646, 46.3921],
                        [-61.1519, 46.4081],
                        [-61.1446, 46.4235],
                        [-61.1308, 46.4304],
                        [-61.1286, 46.4395],
                        [-61.1116, 46.4405],
                        [-61.1076, 46.4387],
                        [-61.103, 46.4262],
                        [-61.0979, 46.431],
                        [-61.1015, 46.4366],
                        [-61.1026, 46.4476],
                        [-61.087, 46.477],
                        [-61.0783, 46.4896],
                        [-61.0741, 46.5083],
                        [-61.078, 46.519],
                        [-61.0728, 46.5246],
                        [-61.0701, 46.5339],
                        [-61.0618, 46.538],
                        [-61.0431, 46.5549],
                        [-61.0318, 46.5731],
                        [-61.0295, 46.5809],
                        [-61.0332, 46.5991],
                        [-61.0437, 46.6069],
                        [-61.0512, 46.6073],
                        [-61.0551, 46.619],
                        [-61.0456, 46.6271],
                        [-61.0311, 46.646],
                        [-61.0288, 46.6509],
                        [-61.0168, 46.6491],
                        [-61.022, 46.6339],
                        [-61.0192, 46.6252],
                        [-61.0048, 46.6433],
                        [-61.0023, 46.65],
                        [-60.9837, 46.6577],
                        [-60.9722, 46.6666],
                        [-60.9647, 46.6686],
                        [-60.9598, 46.6767],
                        [-60.9632, 46.6884],
                        [-60.9551, 46.6953],
                        [-60.9409, 46.7125],
                        [-60.9289, 46.7242],
                        [-60.916, 46.7521],
                        [-60.9068, 46.7694],
                        [-60.8965, 46.7766],
                        [-60.8965, 46.7824],
                        [-60.8912, 46.7904],
                        [-60.879, 46.7987],
                        [-60.8761, 46.8047],
                        [-60.8671, 46.8075],
                        [-60.8631, 46.8124],
                        [-60.8475, 46.8222],
                        [-60.8144, 46.8272],
                        [-60.7987, 46.8317],
                        [-60.7746, 46.8503],
                        [-60.7701, 46.8601],
                        [-60.7638, 46.8648],
                        [-60.7423, 46.8752],
                        [-60.7291, 46.8835],
                        [-60.7046, 46.9037],
                        [-60.6894, 46.9217],
                        [-60.6891, 46.9298],
                        [-60.684, 46.9373],
                        [-60.6824, 46.9569],
                        [-60.6711, 46.9707],
                        [-60.6711, 46.9757],
                        [-60.6542, 46.9959],
                        [-60.6357, 47.0087],
                        [-60.6334, 47.0142],
                        [-60.6231, 47.016],
                        [-60.6228, 47.0257],
                        [-60.617, 47.0325],
                        [-60.6025, 47.0391],
                        [-60.599, 47.0429]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "38",
            properties: { cityName: "Nova Scotia", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-64.275, 45.8415],
                        [-64.2723, 45.8541],
                        [-64.2665, 45.8595],
                        [-64.2545, 45.8798],
                        [-64.2431, 45.8901],
                        [-64.2277, 45.8952],
                        [-64.2193, 45.9038],
                        [-64.2166, 45.9112],
                        [-64.2062, 45.9161],
                        [-64.1646, 45.9608],
                        [-64.1591, 45.9753],
                        [-64.1496, 45.9799],
                        [-64.0701, 45.9772],
                        [-64.0504, 45.9794],
                        [-64.0426, 45.9934],
                        [-64.0377, 45.999],
                        [-64.0253, 46.005],
                        [-64.0032, 45.9942],
                        [-63.9912, 45.9969],
                        [-63.972, 45.9974],
                        [-63.9587, 45.9896],
                        [-63.9429, 45.9911],
                        [-63.937, 45.9867],
                        [-63.9214, 45.9817],
                        [-63.9096, 45.9828],
                        [-63.8894, 45.9677],
                        [-63.8772, 45.9685],
                        [-63.8738, 45.9641],
                        [-63.8626, 45.9613],
                        [-63.8679, 45.9524],
                        [-63.865, 45.942],
                        [-63.8693, 45.9345],
                        [-63.8479, 45.9308],
                        [-63.8308, 45.9212],
                        [-63.8189, 45.9196],
                        [-63.8202, 45.9145],
                        [-63.814, 45.9053],
                        [-63.8076, 45.8912],
                        [-63.789, 45.8915],
                        [-63.7845, 45.8866],
                        [-63.7598, 45.8758],
                        [-63.7285, 45.8725],
                        [-63.7118, 45.8624],
                        [-63.6959, 45.8556],
                        [-63.6764, 45.8541],
                        [-63.6713, 45.845],
                        [-63.6779, 45.841],
                        [-63.6894, 45.8383],
                        [-63.6812, 45.8276],
                        [-63.6735, 45.8261],
                        [-63.6647, 45.8291],
                        [-63.6635, 45.8345],
                        [-63.6558, 45.8457],
                        [-63.6641, 45.8505],
                        [-63.6678, 45.8644],
                        [-63.6766, 45.8711],
                        [-63.6735, 45.8746],
                        [-63.6555, 45.8747],
                        [-63.6423, 45.871],
                        [-63.6166, 45.878],
                        [-63.5992, 45.8763],
                        [-63.5655, 45.875],
                        [-63.5566, 45.8736],
                        [-63.536, 45.8777],
                        [-63.5027, 45.8786],
                        [-63.4871, 45.8764],
                        [-63.4685, 45.8783],
                        [-63.4536, 45.8725],
                        [-63.4342, 45.869],
                        [-63.4149, 45.8607],
                        [-63.4122, 45.852],
                        [-63.4244, 45.8503],
                        [-63.4415, 45.8534],
                        [-63.4692, 45.8538],
                        [-63.4759, 45.8477],
                        [-63.4598, 45.8401],
                        [-63.4285, 45.8355],
                        [-63.4239, 45.8253],
                        [-63.4284, 45.8217],
                        [-63.4519, 45.823],
                        [-63.4841, 45.8226],
                        [-63.4963, 45.8212],
                        [-63.4987, 45.8273],
                        [-63.5079, 45.8307],
                        [-63.518, 45.8244],
                        [-63.5258, 45.8264],
                        [-63.5363, 45.8344],
                        [-63.5489, 45.8386],
                        [-63.5553, 45.8328],
                        [-63.5668, 45.8302],
                        [-63.586, 45.829],
                        [-63.5907, 45.8252],
                        [-63.5752, 45.8212],
                        [-63.5702, 45.8283],
                        [-63.5504, 45.8306],
                        [-63.5363, 45.8231],
                        [-63.5289, 45.823],
                        [-63.5217, 45.8162],
                        [-63.4999, 45.8112],
                        [-63.4905, 45.815],
                        [-63.4798, 45.8134],
                        [-63.4637, 45.814],
                        [-63.4427, 45.8021],
                        [-63.4167, 45.8013],
                        [-63.4241, 45.8089],
                        [-63.4255, 45.8143],
                        [-63.4081, 45.8144],
                        [-63.3961, 45.811],
                        [-63.3894, 45.8054],
                        [-63.3732, 45.7987],
                        [-63.3542, 45.7988],
                        [-63.3282, 45.7969],
                        [-63.3089, 45.8016],
                        [-63.2961, 45.8093],
                        [-63.2748, 45.8101],
                        [-63.2558, 45.8054],
                        [-63.233, 45.8023],
                        [-63.2355, 45.7982],
                        [-63.25, 45.7919],
                        [-63.2574, 45.7928],
                        [-63.2728, 45.7853],
                        [-63.2892, 45.7808],
                        [-63.2959, 45.7751],
                        [-63.3033, 45.7744],
                        [-63.3138, 45.7667],
                        [-63.3302, 45.7658],
                        [-63.336, 45.7622],
                        [-63.3331, 45.7563],
                        [-63.3479, 45.7588],
                        [-63.3489, 45.7635],
                        [-63.3784, 45.7618],
                        [-63.3819, 45.7584],
                        [-63.3626, 45.7509],
                        [-63.3689, 45.744],
                        [-63.3662, 45.7408],
                        [-63.3185, 45.7373],
                        [-63.3149, 45.7303],
                        [-63.2961, 45.7273],
                        [-63.2892, 45.7403],
                        [-63.2729, 45.7511],
                        [-63.2639, 45.7518],
                        [-63.2672, 45.7393],
                        [-63.2659, 45.7329],
                        [-63.2505, 45.7351],
                        [-63.2377, 45.7462],
                        [-63.218, 45.7498],
                        [-63.21, 45.7562],
                        [-63.2045, 45.7674],
                        [-63.1868, 45.7616],
                        [-63.1987, 45.7501],
                        [-63.154, 45.7501],
                        [-63.1309, 45.7536],
                        [-63.1135, 45.7635],
                        [-63.0975, 45.7651],
                        [-63.0801, 45.7536],
                        [-63.0714, 45.7545],
                        [-63.0674, 45.7593],
                        [-63.0701, 45.7687],
                        [-63.0804, 45.7806],
                        [-63.0962, 45.7858],
                        [-63.1043, 45.7914],
                        [-63.1258, 45.8002],
                        [-63.1291, 45.804],
                        [-63.1094, 45.8072],
                        [-63.1046, 45.8004],
                        [-63.0933, 45.7971],
                        [-63.0522, 45.7955],
                        [-63.0431, 45.7935],
                        [-63.0332, 45.7964],
                        [-63.0264, 45.7924],
                        [-63.002, 45.7944],
                        [-62.9923, 45.7937],
                        [-62.9872, 45.789],
                        [-62.9708, 45.7863],
                        [-62.9524, 45.7809],
                        [-62.92, 45.7793],
                        [-62.9019, 45.7772],
                        [-62.8798, 45.7711],
                        [-62.8576, 45.7729],
                        [-62.8438, 45.7702],
                        [-62.8274, 45.7711],
                        [-62.8248, 45.7688],
                        [-62.8026, 45.7663],
                        [-62.7917, 45.7665],
                        [-62.7798, 45.7627],
                        [-62.7653, 45.7662],
                        [-62.7522, 45.7619],
                        [-62.7197, 45.7616],
                        [-62.702, 45.7665],
                        [-62.6914, 45.7627],
                        [-62.6953, 45.7551],
                        [-62.7049, 45.7625],
                        [-62.7065, 45.7544],
                        [-62.7137, 45.7477],
                        [-62.7288, 45.7458],
                        [-62.7297, 45.7502],
                        [-62.7416, 45.7597],
                        [-62.7519, 45.7586],
                        [-62.774, 45.7606],
                        [-62.7744, 45.7557],
                        [-62.7573, 45.7514],
                        [-62.7462, 45.7449],
                        [-62.7443, 45.738],
                        [-62.7347, 45.7275],
                        [-62.7239, 45.732],
                        [-62.7044, 45.7367],
                        [-62.6799, 45.7364],
                        [-62.6722, 45.7296],
                        [-62.6628, 45.727],
                        [-62.6558, 45.7285],
                        [-62.6386, 45.7154],
                        [-62.6522, 45.6964],
                        [-62.6724, 45.6927],
                        [-62.6846, 45.6841],
                        [-62.6975, 45.6806],
                        [-62.7076, 45.6744],
                        [-62.719, 45.6761],
                        [-62.7223, 45.6709],
                        [-62.7387, 45.6706],
                        [-62.742, 45.6763],
                        [-62.7502, 45.6727],
                        [-62.7624, 45.6758],
                        [-62.7688, 45.6742],
                        [-62.7871, 45.665],
                        [-62.7941, 45.6532],
                        [-62.7861, 45.6475],
                        [-62.7716, 45.6554],
                        [-62.7586, 45.6598],
                        [-62.7485, 45.6587],
                        [-62.7489, 45.6536],
                        [-62.7417, 45.6471],
                        [-62.73, 45.6464],
                        [-62.7236, 45.6554],
                        [-62.7171, 45.6567],
                        [-62.7073, 45.6521],
                        [-62.6987, 45.6377],
                        [-62.6793, 45.6319],
                        [-62.6713, 45.6226],
                        [-62.6606, 45.6227],
                        [-62.6494, 45.6256],
                        [-62.6508, 45.6359],
                        [-62.674, 45.6388],
                        [-62.689, 45.6455],
                        [-62.6856, 45.6536],
                        [-62.6936, 45.6612],
                        [-62.6936, 45.6669],
                        [-62.6688, 45.6834],
                        [-62.6572, 45.6765],
                        [-62.64, 45.6767],
                        [-62.6421, 45.6671],
                        [-62.6607, 45.662],
                        [-62.6525, 45.6569],
                        [-62.6425, 45.6638],
                        [-62.6363, 45.6762],
                        [-62.6213, 45.6853],
                        [-62.6052, 45.6795],
                        [-62.5981, 45.6799],
                        [-62.5753, 45.674],
                        [-62.5653, 45.6763],
                        [-62.5549, 45.672],
                        [-62.5553, 45.6599],
                        [-62.5778, 45.6596],
                        [-62.5739, 45.6552],
                        [-62.5574, 45.6546],
                        [-62.5431, 45.6438],
                        [-62.5324, 45.6496],
                        [-62.5295, 45.6461],
                        [-62.5016, 45.6514],
                        [-62.5164, 45.6612],
                        [-62.5296, 45.6562],
                        [-62.5371, 45.6618],
                        [-62.5162, 45.6635],
                        [-62.5109, 45.6592],
                        [-62.4978, 45.6546],
                        [-62.4775, 45.6511],
                        [-62.4601, 45.6531],
                        [-62.4465, 45.6593],
                        [-62.4363, 45.6682],
                        [-62.4009, 45.6761],
                        [-62.3867, 45.6753],
                        [-62.3643, 45.677],
                        [-62.3354, 45.6857],
                        [-62.3232, 45.6865],
                        [-62.2941, 45.6936],
                        [-62.2782, 45.7021],
                        [-62.2642, 45.7123],
                        [-62.2448, 45.7202],
                        [-62.2243, 45.7254],
                        [-62.2034, 45.7426],
                        [-62.1863, 45.7512],
                        [-62.1762, 45.7545],
                        [-62.1677, 45.7628],
                        [-62.136, 45.7758],
                        [-62.1307, 45.7803],
                        [-62.1188, 45.7844],
                        [-62.0953, 45.7847],
                        [-62.0879, 45.7865],
                        [-62.0673, 45.8059],
                        [-62.0504, 45.8165],
                        [-62.036, 45.8272],
                        [-61.9988, 45.8499],
                        [-61.9802, 45.8654],
                        [-61.9787, 45.8727],
                        [-61.9669, 45.8781],
                        [-61.9551, 45.8805],
                        [-61.939, 45.8868],
                        [-61.9319, 45.8873],
                        [-61.9083, 45.8817],
                        [-61.8945, 45.872],
                        [-61.9066, 45.8615],
                        [-61.9199, 45.8578],
                        [-61.9245, 45.8417],
                        [-61.9229, 45.8323],
                        [-61.9175, 45.8247],
                        [-61.9144, 45.8085],
                        [-61.908, 45.7985],
                        [-61.9104, 45.7934],
                        [-61.9078, 45.7786],
                        [-61.9119, 45.764],
                        [-61.9039, 45.7626],
                        [-61.8946, 45.7573],
                        [-61.9007, 45.7501],
                        [-61.8917, 45.7434],
                        [-61.8891, 45.7358],
                        [-61.8983, 45.7206],
                        [-61.9001, 45.7051],
                        [-61.8897, 45.6914],
                        [-61.8984, 45.6924],
                        [-61.8985, 45.6993],
                        [-61.9046, 45.7004],
                        [-61.9094, 45.6895],
                        [-61.915, 45.6834],
                        [-61.9091, 45.6771],
                        [-61.8929, 45.6737],
                        [-61.9014, 45.6661],
                        [-61.9094, 45.6668],
                        [-61.9153, 45.6596],
                        [-61.9274, 45.6533],
                        [-61.9278, 45.6473],
                        [-61.9376, 45.6478],
                        [-61.947, 45.6431],
                        [-61.9536, 45.6344],
                        [-61.951, 45.6271],
                        [-61.938, 45.637],
                        [-61.9283, 45.6388],
                        [-61.9167, 45.6216],
                        [-61.9054, 45.6234],
                        [-61.9068, 45.6328],
                        [-61.904, 45.6379],
                        [-61.9077, 45.6533],
                        [-61.9041, 45.658],
                        [-61.89, 45.6612],
                        [-61.883, 45.6688],
                        [-61.884, 45.6807],
                        [-61.8631, 45.6816],
                        [-61.8385, 45.6764],
                        [-61.8244, 45.6692],
                        [-61.8192, 45.662],
                        [-61.8212, 45.6559],
                        [-61.8109, 45.6496],
                        [-61.7867, 45.644],
                        [-61.8005, 45.6398],
                        [-61.8171, 45.6441],
                        [-61.8253, 45.6423],
                        [-61.8344, 45.646],
                        [-61.8512, 45.6386],
                        [-61.8449, 45.6311],
                        [-61.8389, 45.636],
                        [-61.8169, 45.6429],
                        [-61.8026, 45.6382],
                        [-61.8004, 45.6331],
                        [-61.809, 45.6207],
                        [-61.8148, 45.6059],
                        [-61.8081, 45.6049],
                        [-61.7933, 45.6176],
                        [-61.7928, 45.6242],
                        [-61.799, 45.6268],
                        [-61.7876, 45.641],
                        [-61.7796, 45.6451],
                        [-61.7598, 45.6474],
                        [-61.7549, 45.637],
                        [-61.7384, 45.6292],
                        [-61.7294, 45.6338],
                        [-61.7193, 45.6338],
                        [-61.6999, 45.6386],
                        [-61.6942, 45.6356],
                        [-61.6829, 45.6367],
                        [-61.6641, 45.6346],
                        [-61.6558, 45.6361],
                        [-61.6537, 45.6308],
                        [-61.642, 45.6319],
                        [-61.6375, 45.6236],
                        [-61.625, 45.6237],
                        [-61.6314, 45.6316],
                        [-61.6253, 45.6421],
                        [-61.626, 45.6463],
                        [-61.6086, 45.6519],
                        [-61.5988, 45.6498],
                        [-61.5958, 45.6451],
                        [-61.583, 45.6392],
                        [-61.5845, 45.6496],
                        [-61.5803, 45.6521],
                        [-61.5936, 45.6715],
                        [-61.5898, 45.6766],
                        [-61.5749, 45.6826],
                        [-61.5689, 45.6922],
                        [-61.5563, 45.688],
                        [-61.5443, 45.6889],
                        [-61.5278, 45.6932],
                        [-61.5295, 45.6868],
                        [-61.5199, 45.6772],
                        [-61.5129, 45.677],
                        [-61.5153, 45.6919],
                        [-61.4984, 45.6936],
                        [-61.4881, 45.6928],
                        [-61.4667, 45.6812],
                        [-61.4557, 45.6678],
                        [-61.4396, 45.6561],
                        [-61.4434, 45.6538],
                        [-61.4344, 45.6452],
                        [-61.4246, 45.641],
                        [-61.4157, 45.6405],
                        [-61.4058, 45.6333],
                        [-61.3998, 45.6321],
                        [-61.3884, 45.6167],
                        [-61.3865, 45.5964],
                        [-61.3771, 45.5857],
                        [-61.3553, 45.5712],
                        [-61.3543, 45.5667],
                        [-61.3347, 45.5475],
                        [-61.3128, 45.5384],
                        [-61.3006, 45.5365],
                        [-61.2884, 45.5281],
                        [-61.273, 45.522],
                        [-61.2547, 45.5197],
                        [-61.2494, 45.5225],
                        [-61.2388, 45.509],
                        [-61.2429, 45.4969],
                        [-61.2393, 45.4902],
                        [-61.2328, 45.487],
                        [-61.2429, 45.4741],
                        [-61.2518, 45.4678],
                        [-61.2548, 45.4598],
                        [-61.2622, 45.4549],
                        [-61.2706, 45.4566],
                        [-61.2828, 45.4507],
                        [-61.2863, 45.4442],
                        [-61.302, 45.4491],
                        [-61.3036, 45.4412],
                        [-61.3209, 45.4309],
                        [-61.3298, 45.4333],
                        [-61.3419, 45.4275],
                        [-61.3559, 45.4274],
                        [-61.3689, 45.4212],
                        [-61.3926, 45.4238],
                        [-61.415, 45.4187],
                        [-61.4278, 45.4098],
                        [-61.4339, 45.402],
                        [-61.4418, 45.4031],
                        [-61.4654, 45.3985],
                        [-61.4761, 45.3917],
                        [-61.4766, 45.3992],
                        [-61.4892, 45.4053],
                        [-61.4976, 45.4005],
                        [-61.4994, 45.3951],
                        [-61.4931, 45.3854],
                        [-61.4853, 45.3671],
                        [-61.4757, 45.3669],
                        [-61.4652, 45.3532],
                        [-61.4369, 45.3477],
                        [-61.4246, 45.3501],
                        [-61.3983, 45.351],
                        [-61.3823, 45.3499],
                        [-61.3743, 45.3468],
                        [-61.355, 45.3506],
                        [-61.3249, 45.3531],
                        [-61.3064, 45.3522],
                        [-61.2893, 45.3492],
                        [-61.2665, 45.3392],
                        [-61.2516, 45.3435],
                        [-61.2604, 45.3494],
                        [-61.2501, 45.3529],
                        [-61.2357, 45.3545],
                        [-61.2253, 45.3526],
                        [-61.213, 45.3554],
                        [-61.2018, 45.3486],
                        [-61.1935, 45.353],
                        [-61.1865, 45.3511],
                        [-61.1584, 45.3548],
                        [-61.153, 45.3582],
                        [-61.144, 45.3573],
                        [-61.1368, 45.3512],
                        [-61.1028, 45.3426],
                        [-61.0945, 45.3469],
                        [-61.0875, 45.346],
                        [-61.0678, 45.3498],
                        [-61.0495, 45.346],
                        [-61.035, 45.3448],
                        [-61.0141, 45.3387],
                        [-61.0012, 45.34],
                        [-60.9787, 45.332],
                        [-60.9609, 45.3229],
                        [-60.9698, 45.3166],
                        [-60.9768, 45.3208],
                        [-60.9846, 45.3165],
                        [-60.9851, 45.3092],
                        [-60.9741, 45.3075],
                        [-60.9774, 45.2964],
                        [-60.9853, 45.2911],
                        [-60.9864, 45.2835],
                        [-60.9786, 45.2789],
                        [-60.9814, 45.2727],
                        [-60.9908, 45.2744],
                        [-60.9977, 45.2801],
                        [-61.0007, 45.2872],
                        [-61.0117, 45.2912],
                        [-61.0417, 45.296],
                        [-61.0474, 45.3003],
                        [-61.0564, 45.2992],
                        [-61.0473, 45.2873],
                        [-61.031, 45.2789],
                        [-61.0241, 45.2728],
                        [-61.0359, 45.2692],
                        [-61.0318, 45.2617],
                        [-61.0332, 45.2555],
                        [-61.0388, 45.2531],
                        [-61.0487, 45.2571],
                        [-61.0572, 45.2554],
                        [-61.0528, 45.2434],
                        [-61.0463, 45.2418],
                        [-61.0504, 45.235],
                        [-61.0652, 45.2352],
                        [-61.0853, 45.2397],
                        [-61.0758, 45.2291],
                        [-61.0979, 45.2186],
                        [-61.1331, 45.2088],
                        [-61.1304, 45.2161],
                        [-61.1414, 45.2139],
                        [-61.1463, 45.2166],
                        [-61.1535, 45.2119],
                        [-61.1599, 45.218],
                        [-61.1526, 45.2361],
                        [-61.145, 45.2412],
                        [-61.1434, 45.2483],
                        [-61.1372, 45.2568],
                        [-61.1342, 45.2723],
                        [-61.145, 45.2697],
                        [-61.1545, 45.2718],
                        [-61.1591, 45.263],
                        [-61.1724, 45.2587],
                        [-61.1812, 45.2584],
                        [-61.1851, 45.2517],
                        [-61.1791, 45.2449],
                        [-61.1881, 45.2365],
                        [-61.1811, 45.233],
                        [-61.1751, 45.2218],
                        [-61.1879, 45.2133],
                        [-61.1968, 45.2132],
                        [-61.1952, 45.2203],
                        [-61.1989, 45.2296],
                        [-61.2096, 45.2327],
                        [-61.1944, 45.2403],
                        [-61.1906, 45.2464],
                        [-61.1979, 45.2506],
                        [-61.2156, 45.2535],
                        [-61.2144, 45.2482],
                        [-61.2232, 45.2459],
                        [-61.2396, 45.2467],
                        [-61.2444, 45.2507],
                        [-61.2612, 45.2434],
                        [-61.2775, 45.2485],
                        [-61.2843, 45.259],
                        [-61.3023, 45.2568],
                        [-61.3039, 45.2515],
                        [-61.3136, 45.2505],
                        [-61.319, 45.2544],
                        [-61.3305, 45.2397],
                        [-61.3427, 45.2428],
                        [-61.3548, 45.2336],
                        [-61.3752, 45.2103],
                        [-61.3726, 45.2037],
                        [-61.3666, 45.1999],
                        [-61.3558, 45.1981],
                        [-61.3284, 45.2002],
                        [-61.3131, 45.1946],
                        [-61.3172, 45.1888],
                        [-61.3402, 45.1894],
                        [-61.3524, 45.1858],
                        [-61.3569, 45.1787],
                        [-61.3704, 45.1718],
                        [-61.3836, 45.1799],
                        [-61.3847, 45.1692],
                        [-61.4087, 45.1636],
                        [-61.4232, 45.1633],
                        [-61.4354, 45.1736],
                        [-61.4541, 45.1798],
                        [-61.4575, 45.1745],
                        [-61.4504, 45.1709],
                        [-61.448, 45.1633],
                        [-61.4545, 45.1616],
                        [-61.4621, 45.1509],
                        [-61.4748, 45.1502],
                        [-61.4855, 45.1579],
                        [-61.4947, 45.1616],
                        [-61.5048, 45.1612],
                        [-61.5152, 45.1636],
                        [-61.5265, 45.1633],
                        [-61.5423, 45.1665],
                        [-61.556, 45.1502],
                        [-61.5815, 45.1511],
                        [-61.5893, 45.1472],
                        [-61.6078, 45.1475],
                        [-61.6312, 45.1574],
                        [-61.6391, 45.159],
                        [-61.6407, 45.1652],
                        [-61.6572, 45.1885],
                        [-61.6653, 45.1855],
                        [-61.6518, 45.1717],
                        [-61.6536, 45.1653],
                        [-61.6628, 45.1586],
                        [-61.6855, 45.1622],
                        [-61.6964, 45.1729],
                        [-61.6999, 45.1806],
                        [-61.7169, 45.1965],
                        [-61.7189, 45.2143],
                        [-61.7316, 45.2203],
                        [-61.74, 45.2194],
                        [-61.729, 45.2098],
                        [-61.727, 45.1923],
                        [-61.7183, 45.1834],
                        [-61.7108, 45.1802],
                        [-61.7107, 45.1747],
                        [-61.6963, 45.1618],
                        [-61.6728, 45.1476],
                        [-61.6458, 45.1242],
                        [-61.6501, 45.1195],
                        [-61.6807, 45.1202],
                        [-61.6878, 45.1151],
                        [-61.6791, 45.1086],
                        [-61.6747, 45.1092],
                        [-61.6555, 45.0977],
                        [-61.6533, 45.0918],
                        [-61.6644, 45.091],
                        [-61.6828, 45.0958],
                        [-61.7036, 45.0943],
                        [-61.7188, 45.102],
                        [-61.7267, 45.0982],
                        [-61.7313, 45.0907],
                        [-61.7409, 45.0901],
                        [-61.7625, 45.0962],
                        [-61.777, 45.096],
                        [-61.7844, 45.0934],
                        [-61.7696, 45.0869],
                        [-61.7617, 45.0868],
                        [-61.7541, 45.0803],
                        [-61.7643, 45.0775],
                        [-61.77, 45.0792],
                        [-61.7699, 45.0866],
                        [-61.7791, 45.0861],
                        [-61.8077, 45.0931],
                        [-61.8134, 45.0966],
                        [-61.8221, 45.0966],
                        [-61.837, 45.1026],
                        [-61.8447, 45.099],
                        [-61.8363, 45.0943],
                        [-61.827, 45.0849],
                        [-61.8269, 45.0748],
                        [-61.8221, 45.0702],
                        [-61.826, 45.0634],
                        [-61.8381, 45.0661],
                        [-61.8624, 45.0783],
                        [-61.8681, 45.0735],
                        [-61.8534, 45.0649],
                        [-61.8417, 45.0474],
                        [-61.8462, 45.0403],
                        [-61.854, 45.0364],
                        [-61.8715, 45.0453],
                        [-61.8815, 45.0468],
                        [-61.8875, 45.0532],
                        [-61.9046, 45.058],
                        [-61.9121, 45.0669],
                        [-61.9103, 45.0698],
                        [-61.9417, 45.0835],
                        [-61.9499, 45.0844],
                        [-61.9682, 45.1094],
                        [-61.9665, 45.1173],
                        [-61.9799, 45.1277],
                        [-61.9774, 45.1305],
                        [-61.9979, 45.1406],
                        [-61.9973, 45.1343],
                        [-61.9724, 45.1191],
                        [-61.9703, 45.1096],
                        [-61.9495, 45.0797],
                        [-61.9345, 45.0739],
                        [-61.9302, 45.0694],
                        [-61.9102, 45.0579],
                        [-61.9034, 45.0496],
                        [-61.8962, 45.0468],
                        [-61.8909, 45.036],
                        [-61.8853, 45.031],
                        [-61.8997, 45.0233],
                        [-61.9084, 45.0297],
                        [-61.9149, 45.0278],
                        [-61.9291, 45.0354],
                        [-61.9345, 45.0353],
                        [-61.9455, 45.0446],
                        [-61.9522, 45.0542],
                        [-61.9529, 45.0592],
                        [-61.9698, 45.0632],
                        [-61.9708, 45.0587],
                        [-61.9532, 45.0497],
                        [-61.9577, 45.0387],
                        [-61.9481, 45.0299],
                        [-61.9481, 45.0205],
                        [-61.9586, 45.0208],
                        [-61.9754, 45.0257],
                        [-61.9843, 45.0247],
                        [-61.9975, 45.03],
                        [-61.9987, 45.0195],
                        [-62.0064, 45.0103],
                        [-62.0177, 45.0133],
                        [-62.0116, 45.022],
                        [-62.0245, 45.0246],
                        [-62.0287, 45.0206],
                        [-62.0273, 45.0125],
                        [-62.0411, 45.0027],
                        [-62.0854, 45.0044],
                        [-62.0962, 45.0065],
                        [-62.0976, 45.0001],
                        [-62.0633, 45.0004],
                        [-62.0355, 44.9943],
                        [-62.0238, 44.9979],
                        [-62.0184, 44.996],
                        [-62.0007, 44.9949],
                        [-61.9874, 44.9924],
                        [-61.9876, 44.987],
                        [-61.9801, 44.9808],
                        [-61.9822, 44.9769],
                        [-61.998, 44.9775],
                        [-62.0165, 44.9758],
                        [-62.0265, 44.9792],
                        [-62.0409, 44.9731],
                        [-62.0491, 44.9723],
                        [-62.0573, 44.9672],
                        [-62.063, 44.9741],
                        [-62.0768, 44.9744],
                        [-62.0671, 44.9664],
                        [-62.0824, 44.963],
                        [-62.0886, 44.9688],
                        [-62.1082, 44.9642],
                        [-62.1231, 44.9678],
                        [-62.1239, 44.9605],
                        [-62.1294, 44.9581],
                        [-62.1366, 44.9658],
                        [-62.1515, 44.9647],
                        [-62.1607, 44.9727],
                        [-62.1676, 44.9536],
                        [-62.1727, 44.9508],
                        [-62.1762, 44.9426],
                        [-62.1724, 44.9365],
                        [-62.1824, 44.9362],
                        [-62.1863, 44.944],
                        [-62.1825, 44.9524],
                        [-62.1863, 44.955],
                        [-62.2005, 44.9502],
                        [-62.2123, 44.9515],
                        [-62.2101, 44.9594],
                        [-62.2157, 44.9617],
                        [-62.2244, 44.9542],
                        [-62.2368, 44.9562],
                        [-62.2469, 44.9619],
                        [-62.2494, 44.9521],
                        [-62.2428, 44.9478],
                        [-62.2445, 44.9429],
                        [-62.2644, 44.942],
                        [-62.2629, 44.9343],
                        [-62.2675, 44.9286],
                        [-62.2859, 44.9268],
                        [-62.2881, 44.9226],
                        [-62.3064, 44.919],
                        [-62.3054, 44.9109],
                        [-62.3103, 44.9075],
                        [-62.3179, 44.9132],
                        [-62.3298, 44.9122],
                        [-62.3356, 44.9082],
                        [-62.3421, 44.9225],
                        [-62.3491, 44.9196],
                        [-62.3452, 44.912],
                        [-62.351, 44.9034],
                        [-62.3432, 44.8994],
                        [-62.3404, 44.8934],
                        [-62.3457, 44.8846],
                        [-62.3519, 44.8884],
                        [-62.352, 44.8941],
                        [-62.3731, 44.8932],
                        [-62.3761, 44.8964],
                        [-62.3808, 44.9136],
                        [-62.3897, 44.9027],
                        [-62.4008, 44.8998],
                        [-62.4086, 44.9024],
                        [-62.4163, 44.8983],
                        [-62.4186, 44.8856],
                        [-62.4102, 44.883],
                        [-62.3948, 44.8721],
                        [-62.407, 44.8682],
                        [-62.4171, 44.8702],
                        [-62.4307, 44.8631],
                        [-62.4263, 44.8572],
                        [-62.4544, 44.8577],
                        [-62.4729, 44.8534],
                        [-62.472, 44.8478],
                        [-62.4589, 44.8477],
                        [-62.4573, 44.8395],
                        [-62.4502, 44.8373],
                        [-62.4576, 44.8313],
                        [-62.4612, 44.8231],
                        [-62.4695, 44.8288],
                        [-62.4845, 44.8524],
                        [-62.4694, 44.8581],
                        [-62.4782, 44.872],
                        [-62.4754, 44.8928],
                        [-62.4836, 44.8966],
                        [-62.5086, 44.9126],
                        [-62.5151, 44.9111],
                        [-62.5328, 44.9183],
                        [-62.5322, 44.9119],
                        [-62.5131, 44.9076],
                        [-62.511, 44.9043],
                        [-62.4979, 44.8993],
                        [-62.4884, 44.8896],
                        [-62.4993, 44.8699],
                        [-62.5085, 44.8628],
                        [-62.5111, 44.8559],
                        [-62.5198, 44.8569],
                        [-62.5335, 44.8446],
                        [-62.5421, 44.8476],
                        [-62.5453, 44.8565],
                        [-62.5547, 44.8526],
                        [-62.5597, 44.862],
                        [-62.5676, 44.8634],
                        [-62.5603, 44.8429],
                        [-62.5669, 44.8378],
                        [-62.5752, 44.8371],
                        [-62.5735, 44.8306],
                        [-62.5552, 44.8211],
                        [-62.5619, 44.8134],
                        [-62.5433, 44.7898],
                        [-62.5582, 44.794],
                        [-62.5673, 44.8033],
                        [-62.566, 44.8093],
                        [-62.5706, 44.8128],
                        [-62.5828, 44.8369],
                        [-62.59, 44.8335],
                        [-62.5908, 44.824],
                        [-62.596, 44.8217],
                        [-62.6079, 44.8255],
                        [-62.6203, 44.8272],
                        [-62.6325, 44.8216],
                        [-62.6258, 44.8125],
                        [-62.65, 44.8047],
                        [-62.6485, 44.7989],
                        [-62.6697, 44.803],
                        [-62.6709, 44.796],
                        [-62.6637, 44.7912],
                        [-62.6569, 44.7914],
                        [-62.6526, 44.7838],
                        [-62.6613, 44.7818],
                        [-62.6749, 44.7942],
                        [-62.7058, 44.801],
                        [-62.7077, 44.7901],
                        [-62.7205, 44.7861],
                        [-62.7396, 44.7893],
                        [-62.75, 44.7864],
                        [-62.761, 44.7868],
                        [-62.7569, 44.7737],
                        [-62.7788, 44.7747],
                        [-62.8031, 44.7779],
                        [-62.8133, 44.7808],
                        [-62.821, 44.7893],
                        [-62.8276, 44.8044],
                        [-62.8338, 44.805],
                        [-62.8426, 44.813],
                        [-62.8543, 44.8115],
                        [-62.8739, 44.8138],
                        [-62.8685, 44.8063],
                        [-62.8452, 44.8016],
                        [-62.835, 44.7865],
                        [-62.8138, 44.7755],
                        [-62.8087, 44.7754],
                        [-62.7984, 44.7659],
                        [-62.8021, 44.7568],
                        [-62.8113, 44.7527],
                        [-62.8132, 44.7469],
                        [-62.8241, 44.7498],
                        [-62.8379, 44.7452],
                        [-62.8291, 44.7342],
                        [-62.8194, 44.7269],
                        [-62.8084, 44.7253],
                        [-62.8078, 44.714],
                        [-62.8172, 44.71],
                        [-62.8314, 44.7119],
                        [-62.8379, 44.7066],
                        [-62.8522, 44.7044],
                        [-62.8622, 44.7193],
                        [-62.8582, 44.7247],
                        [-62.8652, 44.7304],
                        [-62.882, 44.7325],
                        [-62.8843, 44.724],
                        [-62.8999, 44.7345],
                        [-62.9011, 44.7283],
                        [-62.9127, 44.7254],
                        [-62.9411, 44.7262],
                        [-62.9577, 44.7229],
                        [-62.9683, 44.7153],
                        [-62.9824, 44.7023],
                        [-62.989, 44.6981],
                        [-62.999, 44.6965],
                        [-63.0063, 44.7066],
                        [-62.9959, 44.7195],
                        [-63.0012, 44.7269],
                        [-63.0082, 44.7293],
                        [-63.011, 44.7386],
                        [-63.0175, 44.7416],
                        [-63.0141, 44.7556],
                        [-63.0061, 44.7595],
                        [-63.005, 44.7653],
                        [-63.0093, 44.7709],
                        [-63.0075, 44.7803],
                        [-63.0204, 44.7753],
                        [-63.0339, 44.7767],
                        [-63.0403, 44.7725],
                        [-63.0335, 44.7667],
                        [-63.0398, 44.7577],
                        [-63.0501, 44.7621],
                        [-63.0488, 44.7702],
                        [-63.0607, 44.777],
                        [-63.0709, 44.7718],
                        [-63.0691, 44.7671],
                        [-63.0585, 44.7599],
                        [-63.059, 44.7529],
                        [-63.0538, 44.7484],
                        [-63.0432, 44.7502],
                        [-63.0394, 44.7341],
                        [-63.0329, 44.7304],
                        [-63.0165, 44.7271],
                        [-63.0083, 44.7223],
                        [-63.0143, 44.7075],
                        [-63.0151, 44.6999],
                        [-63.0237, 44.6892],
                        [-63.0396, 44.6764],
                        [-63.048, 44.6728],
                        [-63.0553, 44.6784],
                        [-63.0598, 44.6869],
                        [-63.0841, 44.6916],
                        [-63.1014, 44.6827],
                        [-63.1049, 44.6888],
                        [-63.1113, 44.6909],
                        [-63.1314, 44.692],
                        [-63.1438, 44.6897],
                        [-63.1612, 44.6784],
                        [-63.1707, 44.6835],
                        [-63.1666, 44.6882],
                        [-63.1483, 44.6917],
                        [-63.1578, 44.6954],
                        [-63.1655, 44.7023],
                        [-63.1652, 44.7185],
                        [-63.1613, 44.727],
                        [-63.1515, 44.7398],
                        [-63.1497, 44.7482],
                        [-63.1544, 44.7532],
                        [-63.1492, 44.7732],
                        [-63.1577, 44.7686],
                        [-63.1635, 44.7607],
                        [-63.1643, 44.748],
                        [-63.1682, 44.741],
                        [-63.1588, 44.7391],
                        [-63.1579, 44.7329],
                        [-63.1661, 44.7311],
                        [-63.1731, 44.726],
                        [-63.1704, 44.7217],
                        [-63.1779, 44.7132],
                        [-63.1827, 44.7122],
                        [-63.1837, 44.7021],
                        [-63.1776, 44.6969],
                        [-63.1886, 44.6844],
                        [-63.1958, 44.6856],
                        [-63.2072, 44.6806],
                        [-63.2173, 44.6808],
                        [-63.218, 44.6868],
                        [-63.2127, 44.6968],
                        [-63.2191, 44.7089],
                        [-63.2255, 44.7062],
                        [-63.2346, 44.7087],
                        [-63.2337, 44.7169],
                        [-63.2382, 44.7196],
                        [-63.2484, 44.7389],
                        [-63.2538, 44.7331],
                        [-63.2622, 44.7191],
                        [-63.2569, 44.7147],
                        [-63.2592, 44.7097],
                        [-63.2535, 44.7058],
                        [-63.2541, 44.6934],
                        [-63.2404, 44.6837],
                        [-63.25, 44.6756],
                        [-63.2538, 44.6648],
                        [-63.2602, 44.6604],
                        [-63.2565, 44.6564],
                        [-63.2732, 44.655],
                        [-63.2807, 44.6488],
                        [-63.2893, 44.6461],
                        [-63.2869, 44.6358],
                        [-63.3055, 44.6385],
                        [-63.3246, 44.6385],
                        [-63.3257, 44.6419],
                        [-63.3407, 44.6436],
                        [-63.3546, 44.6416],
                        [-63.3611, 44.6469],
                        [-63.3754, 44.6514],
                        [-63.3687, 44.6561],
                        [-63.3747, 44.6598],
                        [-63.3798, 44.6547],
                        [-63.3744, 44.6467],
                        [-63.3919, 44.6432],
                        [-63.4113, 44.6478],
                        [-63.4107, 44.6653],
                        [-63.4054, 44.6735],
                        [-63.4046, 44.6799],
                        [-63.4197, 44.681],
                        [-63.4248, 44.6745],
                        [-63.4531, 44.6796],
                        [-63.4531, 44.6735],
                        [-63.4615, 44.6729],
                        [-63.4594, 44.6659],
                        [-63.4411, 44.6596],
                        [-63.4407, 44.6544],
                        [-63.4323, 44.6529],
                        [-63.4197, 44.6456],
                        [-63.4157, 44.6327],
                        [-63.421, 44.6218],
                        [-63.4171, 44.6178],
                        [-63.4363, 44.6133],
                        [-63.4392, 44.6186],
                        [-63.4478, 44.6216],
                        [-63.4488, 44.6116],
                        [-63.4438, 44.608],
                        [-63.4408, 44.6001],
                        [-63.4438, 44.5924],
                        [-63.4519, 44.589],
                        [-63.4634, 44.5991],
                        [-63.4828, 44.606],
                        [-63.4883, 44.606],
                        [-63.4968, 44.6131],
                        [-63.5092, 44.6201],
                        [-63.5116, 44.624],
                        [-63.521, 44.6264],
                        [-63.5242, 44.6323],
                        [-63.5413, 44.6391],
                        [-63.5533, 44.6523],
                        [-63.5535, 44.6566],
                        [-63.5623, 44.663],
                        [-63.5827, 44.6656],
                        [-63.5963, 44.6753],
                        [-63.6131, 44.6822],
                        [-63.617, 44.6967],
                        [-63.6231, 44.7032],
                        [-63.6374, 44.7087],
                        [-63.6427, 44.7138],
                        [-63.65, 44.7121],
                        [-63.6564, 44.7155],
                        [-63.6639, 44.7085],
                        [-63.6599, 44.6997],
                        [-63.6589, 44.6822],
                        [-63.6512, 44.6804],
                        [-63.6347, 44.6655],
                        [-63.6159, 44.6761],
                        [-63.601, 44.6731],
                        [-63.5908, 44.6636],
                        [-63.5818, 44.6593],
                        [-63.5725, 44.6511],
                        [-63.5628, 44.6348],
                        [-63.5596, 44.6261],
                        [-63.568, 44.6175],
                        [-63.5719, 44.6095],
                        [-63.5641, 44.6097],
                        [-63.5517, 44.5985],
                        [-63.5438, 44.5862],
                        [-63.5491, 44.5741],
                        [-63.5581, 44.5632],
                        [-63.5591, 44.5567],
                        [-63.5465, 44.5435],
                        [-63.5288, 44.5165],
                        [-63.5189, 44.5031],
                        [-63.5239, 44.4939],
                        [-63.5381, 44.4809],
                        [-63.546, 44.4835],
                        [-63.553, 44.4674],
                        [-63.5631, 44.468],
                        [-63.5682, 44.461],
                        [-63.5769, 44.4575],
                        [-63.5874, 44.4578],
                        [-63.5831, 44.4695],
                        [-63.5774, 44.4713],
                        [-63.5779, 44.4797],
                        [-63.5838, 44.4833],
                        [-63.6034, 44.4779],
                        [-63.6028, 44.4663],
                        [-63.6202, 44.459],
                        [-63.6178, 44.4545],
                        [-63.6281, 44.4404],
                        [-63.6494, 44.4328],
                        [-63.6494, 44.4424],
                        [-63.6435, 44.4467],
                        [-63.6356, 44.467],
                        [-63.6566, 44.4644],
                        [-63.6589, 44.4686],
                        [-63.6811, 44.4668],
                        [-63.6878, 44.4699],
                        [-63.7027, 44.4713],
                        [-63.7187, 44.4771],
                        [-63.7176, 44.4698],
                        [-63.7055, 44.4616],
                        [-63.7093, 44.4566],
                        [-63.7241, 44.4581],
                        [-63.723, 44.4629],
                        [-63.7346, 44.473],
                        [-63.741, 44.4665],
                        [-63.7598, 44.4739],
                        [-63.7664, 44.4782],
                        [-63.773, 44.4869],
                        [-63.7701, 44.4955],
                        [-63.7786, 44.5116],
                        [-63.786, 44.4925],
                        [-63.7805, 44.4873],
                        [-63.7735, 44.4751],
                        [-63.7846, 44.4734],
                        [-63.7875, 44.469],
                        [-63.8005, 44.47],
                        [-63.8049, 44.473],
                        [-63.8111, 44.4833],
                        [-63.8179, 44.4842],
                        [-63.8099, 44.4963],
                        [-63.805, 44.5001],
                        [-63.795, 44.5141],
                        [-63.7926, 44.5228],
                        [-63.7877, 44.5234],
                        [-63.788, 44.5337],
                        [-63.8002, 44.5333],
                        [-63.8048, 44.5281],
                        [-63.8028, 44.5172],
                        [-63.8108, 44.5092],
                        [-63.8156, 44.5008],
                        [-63.825, 44.5019],
                        [-63.8214, 44.5112],
                        [-63.826, 44.524],
                        [-63.8458, 44.5234],
                        [-63.8436, 44.5153],
                        [-63.8484, 44.5068],
                        [-63.8435, 44.4957],
                        [-63.8517, 44.4913],
                        [-63.8657, 44.4993],
                        [-63.8716, 44.5049],
                        [-63.8811, 44.5035],
                        [-63.8794, 44.4985],
                        [-63.8668, 44.4917],
                        [-63.872, 44.4875],
                        [-63.8932, 44.4887],
                        [-63.9044, 44.4936],
                        [-63.9168, 44.4903],
                        [-63.9182, 44.4951],
                        [-63.9297, 44.5034],
                        [-63.9384, 44.5055],
                        [-63.9352, 44.5148],
                        [-63.9409, 44.5254],
                        [-63.9473, 44.5314],
                        [-63.9384, 44.536],
                        [-63.9429, 44.5489],
                        [-63.9419, 44.5566],
                        [-63.9286, 44.5727],
                        [-63.9286, 44.5787],
                        [-63.916, 44.5818],
                        [-63.9229, 44.5932],
                        [-63.9314, 44.5963],
                        [-63.9395, 44.6039],
                        [-63.9345, 44.6163],
                        [-63.9228, 44.63],
                        [-63.9152, 44.6322],
                        [-63.9194, 44.6406],
                        [-63.9187, 44.6462],
                        [-63.9304, 44.6467],
                        [-63.9238, 44.6563],
                        [-63.9066, 44.6596],
                        [-63.8979, 44.6641],
                        [-63.8998, 44.6715],
                        [-63.8948, 44.6811],
                        [-63.8892, 44.6806],
                        [-63.8886, 44.6908],
                        [-63.8987, 44.6832],
                        [-63.9122, 44.6836],
                        [-63.9109, 44.6772],
                        [-63.9193, 44.6724],
                        [-63.9085, 44.6687],
                        [-63.9125, 44.6625],
                        [-63.9238, 44.6708],
                        [-63.928, 44.6646],
                        [-63.9466, 44.657],
                        [-63.9585, 44.6537],
                        [-63.9595, 44.6635],
                        [-63.9667, 44.6644],
                        [-63.9694, 44.6523],
                        [-63.994, 44.6538],
                        [-64.0031, 44.6432],
                        [-64.0133, 44.6421],
                        [-64.0176, 44.6376],
                        [-64.0301, 44.6331],
                        [-64.0379, 44.6266],
                        [-64.0473, 44.6303],
                        [-64.063, 44.6281],
                        [-64.0542, 44.6219],
                        [-64.0584, 44.6107],
                        [-64.0538, 44.6017],
                        [-64.0629, 44.5962],
                        [-64.0614, 44.5848],
                        [-64.0446, 44.5762],
                        [-64.0374, 44.57],
                        [-64.0304, 44.5551],
                        [-64.0184, 44.5423],
                        [-64.0168, 44.5307],
                        [-64.0084, 44.5209],
                        [-64.006, 44.5078],
                        [-64.0174, 44.5094],
                        [-64.0227, 44.5156],
                        [-64.0315, 44.5142],
                        [-64.0471, 44.5212],
                        [-64.0471, 44.5114],
                        [-64.0648, 44.5036],
                        [-64.0673, 44.5004],
                        [-64.0642, 44.4862],
                        [-64.0676, 44.4807],
                        [-64.0807, 44.47],
                        [-64.0998, 44.4783],
                        [-64.1129, 44.4781],
                        [-64.1139, 44.4883],
                        [-64.1094, 44.4937],
                        [-64.1104, 44.4996],
                        [-64.1166, 44.5038],
                        [-64.1253, 44.5049],
                        [-64.1267, 44.5141],
                        [-64.1211, 44.5247],
                        [-64.1247, 44.5302],
                        [-64.1142, 44.5349],
                        [-64.1171, 44.5396],
                        [-64.1389, 44.5584],
                        [-64.1414, 44.566],
                        [-64.1541, 44.5735],
                        [-64.168, 44.5702],
                        [-64.1709, 44.5739],
                        [-64.1684, 44.5857],
                        [-64.1783, 44.5887],
                        [-64.1819, 44.5793],
                        [-64.1873, 44.5765],
                        [-64.1962, 44.5796],
                        [-64.2095, 44.5754],
                        [-64.2087, 44.5657],
                        [-64.2177, 44.5616],
                        [-64.2141, 44.551],
                        [-64.2129, 44.5376],
                        [-64.22, 44.5329],
                        [-64.228, 44.5369],
                        [-64.253, 44.5386],
                        [-64.2557, 44.5419],
                        [-64.2691, 44.5443],
                        [-64.2745, 44.5512],
                        [-64.2842, 44.5565],
                        [-64.2946, 44.5589],
                        [-64.3032, 44.5672],
                        [-64.3082, 44.5577],
                        [-64.2954, 44.5447],
                        [-64.3167, 44.5429],
                        [-64.311, 44.5359],
                        [-64.3058, 44.5206],
                        [-64.3102, 44.5115],
                        [-64.3006, 44.5097],
                        [-64.3069, 44.5039],
                        [-64.3092, 44.4901],
                        [-64.3164, 44.4763],
                        [-64.3268, 44.4714],
                        [-64.3042, 44.4671],
                        [-64.3194, 44.4541],
                        [-64.3294, 44.4521],
                        [-64.3318, 44.4472],
                        [-64.3494, 44.4469],
                        [-64.3657, 44.4506],
                        [-64.3661, 44.4434],
                        [-64.3441, 44.4369],
                        [-64.3398, 44.429],
                        [-64.3397, 44.4224],
                        [-64.3339, 44.4142],
                        [-64.3363, 44.4088],
                        [-64.3251, 44.4046],
                        [-64.2926, 44.4075],
                        [-64.2788, 44.4057],
                        [-64.2583, 44.4072],
                        [-64.2534, 44.4024],
                        [-64.2609, 44.3993],
                        [-64.2843, 44.3968],
                        [-64.2912, 44.4018],
                        [-64.299, 44.4014],
                        [-64.2971, 44.3897],
                        [-64.2917, 44.3839],
                        [-64.2995, 44.3774],
                        [-64.29, 44.3756],
                        [-64.2827, 44.3794],
                        [-64.2806, 44.3849],
                        [-64.2656, 44.3797],
                        [-64.2588, 44.3821],
                        [-64.2289, 44.3749],
                        [-64.2161, 44.3697],
                        [-64.2125, 44.3639],
                        [-64.2213, 44.3578],
                        [-64.2393, 44.3571],
                        [-64.2459, 44.3539],
                        [-64.2517, 44.3579],
                        [-64.274, 44.359],
                        [-64.2851, 44.3651],
                        [-64.2974, 44.3638],
                        [-64.3005, 44.3732],
                        [-64.3161, 44.3768],
                        [-64.3193, 44.3651],
                        [-64.3271, 44.3613],
                        [-64.3413, 44.3619],
                        [-64.339, 44.3561],
                        [-64.342, 44.3477],
                        [-64.3362, 44.339],
                        [-64.3384, 44.3226],
                        [-64.3309, 44.3235],
                        [-64.3341, 44.334],
                        [-64.3198, 44.3315],
                        [-64.3129, 44.3273],
                        [-64.3143, 44.3229],
                        [-64.3064, 44.3179],
                        [-64.2958, 44.3156],
                        [-64.2883, 44.3232],
                        [-64.3062, 44.3282],
                        [-64.3025, 44.335],
                        [-64.2856, 44.3323],
                        [-64.273, 44.3249],
                        [-64.2574, 44.3248],
                        [-64.2607, 44.3172],
                        [-64.2745, 44.3134],
                        [-64.293, 44.3125],
                        [-64.2954, 44.3032],
                        [-64.3033, 44.2987],
                        [-64.3007, 44.2926],
                        [-64.2924, 44.2911],
                        [-64.2832, 44.2849],
                        [-64.2693, 44.285],
                        [-64.2673, 44.2892],
                        [-64.2418, 44.2916],
                        [-64.2392, 44.2863],
                        [-64.256, 44.2783],
                        [-64.2522, 44.2682],
                        [-64.2417, 44.2671],
                        [-64.2442, 44.2606],
                        [-64.2565, 44.2663],
                        [-64.2658, 44.2665],
                        [-64.2807, 44.2607],
                        [-64.2844, 44.2549],
                        [-64.2935, 44.262],
                        [-64.3016, 44.2637],
                        [-64.3253, 44.2638],
                        [-64.3385, 44.2736],
                        [-64.3392, 44.2806],
                        [-64.3466, 44.289],
                        [-64.3556, 44.2839],
                        [-64.3585, 44.2718],
                        [-64.3672, 44.2679],
                        [-64.3688, 44.2599],
                        [-64.3776, 44.2557],
                        [-64.3884, 44.2551],
                        [-64.4005, 44.2513],
                        [-64.3955, 44.2467],
                        [-64.4035, 44.2435],
                        [-64.4105, 44.236],
                        [-64.4012, 44.2354],
                        [-64.3818, 44.2303],
                        [-64.3825, 44.2265],
                        [-64.3995, 44.2329],
                        [-64.4147, 44.2345],
                        [-64.4181, 44.2314],
                        [-64.4327, 44.2292],
                        [-64.4332, 44.2182],
                        [-64.4386, 44.2091],
                        [-64.4365, 44.1986],
                        [-64.4544, 44.1891],
                        [-64.4546, 44.1864],
                        [-64.4698, 44.1788],
                        [-64.4781, 44.1772],
                        [-64.4765, 44.1699],
                        [-64.4795, 44.1627],
                        [-64.4796, 44.1531],
                        [-64.4938, 44.1487],
                        [-64.491, 44.1418],
                        [-64.5222, 44.1453],
                        [-64.5319, 44.1564],
                        [-64.5423, 44.1519],
                        [-64.554, 44.1602],
                        [-64.5609, 44.1567],
                        [-64.5591, 44.1462],
                        [-64.5627, 44.1421],
                        [-64.5725, 44.1439],
                        [-64.5719, 44.1498],
                        [-64.5814, 44.1519],
                        [-64.5919, 44.1597],
                        [-64.6162, 44.1539],
                        [-64.6053, 44.143],
                        [-64.5984, 44.1431],
                        [-64.5988, 44.1335],
                        [-64.6162, 44.1402],
                        [-64.6246, 44.136],
                        [-64.607, 44.1292],
                        [-64.5966, 44.1291],
                        [-64.5866, 44.1256],
                        [-64.5726, 44.1297],
                        [-64.5594, 44.1234],
                        [-64.5449, 44.1208],
                        [-64.546, 44.1172],
                        [-64.5392, 44.102],
                        [-64.5526, 44.0962],
                        [-64.561, 44.1073],
                        [-64.5695, 44.099],
                        [-64.5564, 44.092],
                        [-64.5618, 44.0821],
                        [-64.5522, 44.0758],
                        [-64.5528, 44.0695],
                        [-64.5679, 44.0669],
                        [-64.5791, 44.0612],
                        [-64.5814, 44.0711],
                        [-64.591, 44.0721],
                        [-64.5961, 44.0616],
                        [-64.6098, 44.065],
                        [-64.6138, 44.0733],
                        [-64.6215, 44.0687],
                        [-64.6306, 44.0594],
                        [-64.6367, 44.0612],
                        [-64.6421, 44.0501],
                        [-64.6496, 44.0468],
                        [-64.6548, 44.0404],
                        [-64.668, 44.0466],
                        [-64.6715, 44.043],
                        [-64.6894, 44.0462],
                        [-64.6982, 44.0512],
                        [-64.7101, 44.0509],
                        [-64.7125, 44.0473],
                        [-64.7066, 44.0419],
                        [-64.6938, 44.0376],
                        [-64.6666, 44.0217],
                        [-64.6622, 44.0122],
                        [-64.6667, 44.004],
                        [-64.661, 43.9979],
                        [-64.6597, 43.9885],
                        [-64.6746, 43.9876],
                        [-64.6857, 43.9921],
                        [-64.6997, 43.9867],
                        [-64.7124, 43.9757],
                        [-64.713, 43.9686],
                        [-64.7314, 43.9658],
                        [-64.7366, 43.9603],
                        [-64.7324, 43.9536],
                        [-64.7485, 43.9543],
                        [-64.7606, 43.9503],
                        [-64.7809, 43.9506],
                        [-64.7945, 43.9481],
                        [-64.8104, 43.9528],
                        [-64.8287, 43.9508],
                        [-64.8212, 43.944],
                        [-64.8191, 43.9381],
                        [-64.838, 43.9261],
                        [-64.8477, 43.9242],
                        [-64.8467, 43.9189],
                        [-64.8349, 43.9131],
                        [-64.8067, 43.9023],
                        [-64.8061, 43.8969],
                        [-64.7942, 43.8921],
                        [-64.7884, 43.8845],
                        [-64.7832, 43.8635],
                        [-64.7973, 43.862],
                        [-64.8028, 43.8675],
                        [-64.8211, 43.8706],
                        [-64.8091, 43.8501],
                        [-64.8185, 43.8466],
                        [-64.8309, 43.8374],
                        [-64.8351, 43.8273],
                        [-64.8315, 43.8223],
                        [-64.837, 43.813],
                        [-64.8466, 43.8211],
                        [-64.8558, 43.8257],
                        [-64.862, 43.8424],
                        [-64.8587, 43.8537],
                        [-64.8656, 43.8573],
                        [-64.87, 43.8665],
                        [-64.8809, 43.8651],
                        [-64.9003, 43.8768],
                        [-64.9171, 43.878],
                        [-64.9126, 43.8651],
                        [-64.8818, 43.848],
                        [-64.8751, 43.8323],
                        [-64.8767, 43.8251],
                        [-64.8879, 43.8224],
                        [-64.8916, 43.8189],
                        [-64.8835, 43.802],
                        [-64.8849, 43.7952],
                        [-64.8928, 43.7975],
                        [-64.911, 43.7917],
                        [-64.9157, 43.8055],
                        [-64.926, 43.8161],
                        [-64.9267, 43.8199],
                        [-64.9373, 43.8341],
                        [-64.9347, 43.8363],
                        [-64.9401, 43.8484],
                        [-64.961, 43.8675],
                        [-64.9712, 43.8714],
                        [-64.9751, 43.8612],
                        [-64.9676, 43.8464],
                        [-64.9507, 43.8422],
                        [-64.9381, 43.8213],
                        [-64.9383, 43.8164],
                        [-64.9287, 43.8082],
                        [-64.923, 43.7965],
                        [-64.9323, 43.7815],
                        [-64.944, 43.7787],
                        [-64.9479, 43.775],
                        [-64.9457, 43.7623],
                        [-64.9515, 43.753],
                        [-64.9691, 43.7535],
                        [-64.9742, 43.7452],
                        [-64.9857, 43.7467],
                        [-64.9902, 43.7518],
                        [-64.9892, 43.7608],
                        [-65.0027, 43.7667],
                        [-65.0101, 43.7655],
                        [-65.0173, 43.7584],
                        [-65.012, 43.7415],
                        [-65.0063, 43.7355],
                        [-65.0118, 43.7321],
                        [-65.0099, 43.7234],
                        [-65.0193, 43.7263],
                        [-65.0296, 43.7169],
                        [-65.0212, 43.7057],
                        [-65.0264, 43.6965],
                        [-65.0328, 43.6949],
                        [-65.0423, 43.7004],
                        [-65.057, 43.7],
                        [-65.0675, 43.6968],
                        [-65.0658, 43.689],
                        [-65.0715, 43.6872],
                        [-65.0764, 43.6968],
                        [-65.0767, 43.7144],
                        [-65.0807, 43.719],
                        [-65.0804, 43.7299],
                        [-65.0757, 43.7518],
                        [-65.0927, 43.7496],
                        [-65.0981, 43.7406],
                        [-65.0944, 43.732],
                        [-65.0962, 43.7187],
                        [-65.1003, 43.7152],
                        [-65.1131, 43.7147],
                        [-65.1231, 43.7053],
                        [-65.1214, 43.6962],
                        [-65.1124, 43.7024],
                        [-65.1063, 43.6978],
                        [-65.1234, 43.6943],
                        [-65.1275, 43.6831],
                        [-65.1243, 43.6763],
                        [-65.1256, 43.6612],
                        [-65.1398, 43.6673],
                        [-65.1459, 43.6722],
                        [-65.1472, 43.6832],
                        [-65.1453, 43.7102],
                        [-65.142, 43.7194],
                        [-65.1376, 43.722],
                        [-65.1381, 43.7305],
                        [-65.1425, 43.7404],
                        [-65.154, 43.7341],
                        [-65.1617, 43.7363],
                        [-65.1655, 43.7312],
                        [-65.1684, 43.7137],
                        [-65.1629, 43.7081],
                        [-65.1705, 43.7009],
                        [-65.178, 43.7019],
                        [-65.1868, 43.7095],
                        [-65.1912, 43.7232],
                        [-65.1979, 43.7305],
                        [-65.2048, 43.7428],
                        [-65.2138, 43.7544],
                        [-65.2177, 43.7703],
                        [-65.226, 43.7751],
                        [-65.2289, 43.7906],
                        [-65.2415, 43.7947],
                        [-65.2398, 43.783],
                        [-65.2336, 43.7781],
                        [-65.2339, 43.765],
                        [-65.2478, 43.7711],
                        [-65.2576, 43.772],
                        [-65.2447, 43.7602],
                        [-65.239, 43.7522],
                        [-65.2372, 43.7434],
                        [-65.2262, 43.7249],
                        [-65.2257, 43.7119],
                        [-65.217, 43.6994],
                        [-65.2281, 43.7035],
                        [-65.2345, 43.7017],
                        [-65.2331, 43.691],
                        [-65.2391, 43.6887],
                        [-65.2324, 43.6754],
                        [-65.2467, 43.6688],
                        [-65.2575, 43.6575],
                        [-65.2665, 43.6641],
                        [-65.2931, 43.6715],
                        [-65.3, 43.6593],
                        [-65.2913, 43.6521],
                        [-65.2768, 43.6462],
                        [-65.2626, 43.6268],
                        [-65.2769, 43.6154],
                        [-65.2991, 43.6263],
                        [-65.3036, 43.6333],
                        [-65.3051, 43.6451],
                        [-65.302, 43.6516],
                        [-65.3047, 43.6601],
                        [-65.313, 43.6599],
                        [-65.3277, 43.6634],
                        [-65.3288, 43.6476],
                        [-65.3456, 43.6342],
                        [-65.3414, 43.6264],
                        [-65.3424, 43.6197],
                        [-65.3317, 43.6111],
                        [-65.3467, 43.6013],
                        [-65.3574, 43.6075],
                        [-65.3665, 43.608],
                        [-65.3694, 43.6035],
                        [-65.3617, 43.5974],
                        [-65.3528, 43.5985],
                        [-65.3466, 43.585],
                        [-65.3366, 43.5812],
                        [-65.3343, 43.5761],
                        [-65.3449, 43.5716],
                        [-65.3505, 43.5633],
                        [-65.3478, 43.5526],
                        [-65.3396, 43.544],
                        [-65.3402, 43.537],
                        [-65.3458, 43.5346],
                        [-65.3557, 43.5446],
                        [-65.3537, 43.5507],
                        [-65.3631, 43.5652],
                        [-65.3722, 43.5713],
                        [-65.3785, 43.5663],
                        [-65.39, 43.5464],
                        [-65.3979, 43.5477],
                        [-65.4102, 43.5562],
                        [-65.4156, 43.5638],
                        [-65.4148, 43.5711],
                        [-65.4317, 43.5871],
                        [-65.4401, 43.5924],
                        [-65.4552, 43.5968],
                        [-65.4592, 43.594],
                        [-65.4541, 43.5841],
                        [-65.45, 43.5816],
                        [-65.4513, 43.5697],
                        [-65.4569, 43.5626],
                        [-65.4475, 43.5559],
                        [-65.4386, 43.5576],
                        [-65.437, 43.5501],
                        [-65.4443, 43.5485],
                        [-65.4542, 43.5503],
                        [-65.4542, 43.5403],
                        [-65.4473, 43.5343],
                        [-65.459, 43.5195],
                        [-65.458, 43.5149],
                        [-65.4767, 43.5053],
                        [-65.4696, 43.4911],
                        [-65.4633, 43.4832],
                        [-65.4712, 43.475],
                        [-65.4634, 43.464],
                        [-65.4675, 43.4519],
                        [-65.4751, 43.4537],
                        [-65.4912, 43.4648],
                        [-65.4856, 43.481],
                        [-65.4941, 43.4932],
                        [-65.5078, 43.4969],
                        [-65.5179, 43.4972],
                        [-65.5374, 43.508],
                        [-65.5481, 43.5179],
                        [-65.5517, 43.5277],
                        [-65.5458, 43.5328],
                        [-65.5599, 43.547],
                        [-65.5576, 43.5528],
                        [-65.563, 43.5618],
                        [-65.575, 43.5672],
                        [-65.5936, 43.5538],
                        [-65.5941, 43.5453],
                        [-65.6006, 43.5434],
                        [-65.5944, 43.5363],
                        [-65.6065, 43.5311],
                        [-65.6059, 43.5263],
                        [-65.6121, 43.519],
                        [-65.6306, 43.5193],
                        [-65.6298, 43.5107],
                        [-65.6405, 43.5077],
                        [-65.6511, 43.5121],
                        [-65.6576, 43.506],
                        [-65.6582, 43.4919],
                        [-65.6548, 43.4843],
                        [-65.6636, 43.484],
                        [-65.6729, 43.487],
                        [-65.6707, 43.4918],
                        [-65.6787, 43.5031],
                        [-65.698, 43.4995],
                        [-65.6993, 43.4946],
                        [-65.7276, 43.4905],
                        [-65.7334, 43.4994],
                        [-65.7387, 43.5175],
                        [-65.7353, 43.5222],
                        [-65.7385, 43.5327],
                        [-65.7343, 43.5469],
                        [-65.7396, 43.5571],
                        [-65.7379, 43.5627],
                        [-65.7457, 43.5759],
                        [-65.7531, 43.5719],
                        [-65.7538, 43.5646],
                        [-65.7502, 43.5542],
                        [-65.7465, 43.5525],
                        [-65.7464, 43.5377],
                        [-65.7536, 43.5339],
                        [-65.7616, 43.5511],
                        [-65.7792, 43.5534],
                        [-65.7865, 43.5669],
                        [-65.7793, 43.586],
                        [-65.7806, 43.5926],
                        [-65.7747, 43.6048],
                        [-65.7744, 43.6106],
                        [-65.7685, 43.632],
                        [-65.7684, 43.656],
                        [-65.764, 43.6631],
                        [-65.7642, 43.6721],
                        [-65.7728, 43.6787],
                        [-65.7737, 43.6907],
                        [-65.7823, 43.6955],
                        [-65.7847, 43.6858],
                        [-65.7928, 43.6865],
                        [-65.7932, 43.6693],
                        [-65.7987, 43.6616],
                        [-65.7993, 43.6515],
                        [-65.8029, 43.6403],
                        [-65.7924, 43.6308],
                        [-65.7878, 43.6176],
                        [-65.7914, 43.6116],
                        [-65.7912, 43.6003],
                        [-65.8027, 43.5914],
                        [-65.8126, 43.6019],
                        [-65.8152, 43.6209],
                        [-65.8103, 43.632],
                        [-65.8169, 43.6406],
                        [-65.8164, 43.6499],
                        [-65.8213, 43.6636],
                        [-65.8188, 43.675],
                        [-65.8203, 43.683],
                        [-65.8142, 43.6878],
                        [-65.8209, 43.7132],
                        [-65.8302, 43.7224],
                        [-65.8402, 43.7191],
                        [-65.837, 43.733],
                        [-65.8392, 43.7352],
                        [-65.8414, 43.7524],
                        [-65.853, 43.7516],
                        [-65.8491, 43.7587],
                        [-65.8416, 43.7616],
                        [-65.8421, 43.7674],
                        [-65.8557, 43.7864],
                        [-65.8601, 43.7955],
                        [-65.8599, 43.8066],
                        [-65.8647, 43.81],
                        [-65.8716, 43.7998],
                        [-65.8645, 43.78],
                        [-65.8565, 43.7745],
                        [-65.874, 43.7592],
                        [-65.8794, 43.7669],
                        [-65.888, 43.7673],
                        [-65.8955, 43.7771],
                        [-65.8993, 43.789],
                        [-65.9088, 43.8017],
                        [-65.9014, 43.8013],
                        [-65.8977, 43.8078],
                        [-65.9084, 43.8133],
                        [-65.9151, 43.8246],
                        [-65.932, 43.8213],
                        [-65.9323, 43.8322],
                        [-65.9365, 43.838],
                        [-65.9444, 43.8361],
                        [-65.9378, 43.8296],
                        [-65.934, 43.8187],
                        [-65.9495, 43.8105],
                        [-65.9338, 43.8058],
                        [-65.9413, 43.7947],
                        [-65.9469, 43.7983],
                        [-65.9621, 43.797],
                        [-65.9518, 43.7834],
                        [-65.9571, 43.7793],
                        [-65.9585, 43.7707],
                        [-65.9638, 43.7622],
                        [-65.9691, 43.7644],
                        [-65.9728, 43.7764],
                        [-65.9799, 43.7749],
                        [-65.9865, 43.7827],
                        [-65.9826, 43.7897],
                        [-65.9916, 43.7974],
                        [-65.9939, 43.8108],
                        [-65.9903, 43.8138],
                        [-65.994, 43.8256],
                        [-65.9902, 43.8374],
                        [-65.9826, 43.8414],
                        [-65.9756, 43.8559],
                        [-65.9842, 43.8529],
                        [-65.9865, 43.8449],
                        [-65.9987, 43.8419],
                        [-66.0078, 43.8438],
                        [-66.0103, 43.8351],
                        [-66.0061, 43.8205],
                        [-66.0145, 43.8225],
                        [-66.0142, 43.8148],
                        [-66.0085, 43.8141],
                        [-66.01, 43.8043],
                        [-65.9982, 43.788],
                        [-65.9971, 43.7744],
                        [-65.9873, 43.7739],
                        [-65.9775, 43.7622],
                        [-65.9743, 43.7553],
                        [-65.9798, 43.7513],
                        [-65.9659, 43.7479],
                        [-65.963, 43.7372],
                        [-65.9659, 43.725],
                        [-65.9739, 43.7032],
                        [-65.9837, 43.7064],
                        [-65.9824, 43.7264],
                        [-65.9916, 43.733],
                        [-65.9982, 43.7293],
                        [-66.0051, 43.712],
                        [-66.0128, 43.6993],
                        [-66.0097, 43.6903],
                        [-66.0152, 43.6807],
                        [-66.0208, 43.6824],
                        [-66.0323, 43.6943],
                        [-66.0338, 43.7028],
                        [-66.0301, 43.7093],
                        [-66.0285, 43.7323],
                        [-66.0347, 43.7344],
                        [-66.0453, 43.7471],
                        [-66.0534, 43.7404],
                        [-66.0624, 43.7396],
                        [-66.0605, 43.7195],
                        [-66.0532, 43.7165],
                        [-66.055, 43.7044],
                        [-66.0643, 43.7047],
                        [-66.0675, 43.7229],
                        [-66.0794, 43.7298],
                        [-66.0829, 43.7394],
                        [-66.0755, 43.7427],
                        [-66.0793, 43.766],
                        [-66.0878, 43.7767],
                        [-66.0926, 43.7783],
                        [-66.1074, 43.7685],
                        [-66.1089, 43.7621],
                        [-66.1155, 43.7582],
                        [-66.11, 43.7507],
                        [-66.1112, 43.741],
                        [-66.1197, 43.7354],
                        [-66.1242, 43.7406],
                        [-66.1303, 43.765],
                        [-66.1279, 43.7723],
                        [-66.1331, 43.7781],
                        [-66.1267, 43.7859],
                        [-66.1398, 43.7878],
                        [-66.1385, 43.7962],
                        [-66.1326, 43.7993],
                        [-66.1293, 43.8104],
                        [-66.1391, 43.8152],
                        [-66.146, 43.8221],
                        [-66.1508, 43.8309],
                        [-66.1511, 43.8598],
                        [-66.1683, 43.8545],
                        [-66.1708, 43.8635],
                        [-66.1624, 43.8774],
                        [-66.1615, 43.8829],
                        [-66.1542, 43.8879],
                        [-66.168, 43.8948],
                        [-66.1693, 43.9049],
                        [-66.1666, 43.911],
                        [-66.1528, 43.9204],
                        [-66.1511, 43.9266],
                        [-66.1522, 43.9395],
                        [-66.1635, 43.9572],
                        [-66.1629, 43.9633],
                        [-66.1685, 43.9734],
                        [-66.1612, 43.9838],
                        [-66.1531, 43.9906],
                        [-66.1542, 44.0032],
                        [-66.1588, 44.0179],
                        [-66.1656, 44.0257],
                        [-66.1742, 44.0287],
                        [-66.1731, 44.0333],
                        [-66.1654, 44.0388],
                        [-66.1694, 44.0533],
                        [-66.1798, 44.0598],
                        [-66.1782, 44.0734],
                        [-66.1848, 44.0824],
                        [-66.1939, 44.0904],
                        [-66.2121, 44.0863],
                        [-66.2139, 44.098],
                        [-66.2085, 44.1061],
                        [-66.213, 44.1088],
                        [-66.2042, 44.1178],
                        [-66.1998, 44.1402],
                        [-66.1948, 44.1445],
                        [-66.1788, 44.1801],
                        [-66.1601, 44.1916],
                        [-66.1554, 44.1962],
                        [-66.1531, 44.2141],
                        [-66.1411, 44.2216],
                        [-66.1337, 44.2488],
                        [-66.136, 44.2622],
                        [-66.1331, 44.2636],
                        [-66.1368, 44.2779],
                        [-66.1331, 44.2898],
                        [-66.1258, 44.2986],
                        [-66.1204, 44.3148],
                        [-66.1223, 44.3255],
                        [-66.1259, 44.331],
                        [-66.1203, 44.3423],
                        [-66.1104, 44.3451],
                        [-66.1087, 44.3544],
                        [-66.1003, 44.3619],
                        [-66.0854, 44.3714],
                        [-66.086, 44.3759],
                        [-66.0813, 44.3864],
                        [-66.066, 44.3862],
                        [-66.0613, 44.3944],
                        [-66.0523, 44.4],
                        [-66.0483, 44.4066],
                        [-66.0487, 44.4128],
                        [-66.0389, 44.4207],
                        [-66.027, 44.4378],
                        [-66.0156, 44.4405],
                        [-66.0126, 44.4505],
                        [-66.0017, 44.4586],
                        [-65.9946, 44.4612],
                        [-65.985, 44.4736],
                        [-65.9746, 44.4831],
                        [-65.9655, 44.4856],
                        [-65.9591, 44.4926],
                        [-65.9517, 44.4951],
                        [-65.9495, 44.4861],
                        [-65.9368, 44.492],
                        [-65.91, 44.5135],
                        [-65.8948, 44.5205],
                        [-65.8859, 44.5344],
                        [-65.8786, 44.5325],
                        [-65.8759, 44.5396],
                        [-65.869, 44.5471],
                        [-65.855, 44.5572],
                        [-65.8455, 44.5694],
                        [-65.8619, 44.5879],
                        [-65.8824, 44.5866],
                        [-65.9003, 44.5724],
                        [-65.9104, 44.5702],
                        [-65.9183, 44.5741],
                        [-65.9365, 44.5792],
                        [-65.9602, 44.5644],
                        [-65.9813, 44.5582],
                        [-65.9847, 44.551],
                        [-65.9988, 44.5392],
                        [-66.0048, 44.5393],
                        [-66.0217, 44.5302],
                        [-66.0365, 44.5171],
                        [-66.0483, 44.5117],
                        [-66.0548, 44.5001],
                        [-66.0641, 44.4945],
                        [-66.0742, 44.4844],
                        [-66.0851, 44.4871],
                        [-66.082, 44.4788],
                        [-66.0985, 44.4637],
                        [-66.1079, 44.4645],
                        [-66.1094, 44.4572],
                        [-66.1292, 44.4445],
                        [-66.1438, 44.4245],
                        [-66.1534, 44.4215],
                        [-66.1613, 44.4123],
                        [-66.1845, 44.3892],
                        [-66.1909, 44.386],
                        [-66.1973, 44.3773],
                        [-66.2038, 44.3758],
                        [-66.203, 44.394],
                        [-66.2056, 44.4065],
                        [-66.1961, 44.4201],
                        [-66.1823, 44.4332],
                        [-66.1753, 44.4364],
                        [-66.157, 44.4532],
                        [-66.1461, 44.4616],
                        [-66.1384, 44.4712],
                        [-66.1185, 44.4898],
                        [-66.1074, 44.4981],
                        [-66.0985, 44.4976],
                        [-66.096, 44.5045],
                        [-66.0798, 44.516],
                        [-66.079, 44.5212],
                        [-66.0722, 44.5236],
                        [-66.0474, 44.5456],
                        [-66.0043, 44.5752],
                        [-65.9949, 44.5796],
                        [-65.9521, 44.6083],
                        [-65.9428, 44.6091],
                        [-65.9262, 44.6139],
                        [-65.9191, 44.6092],
                        [-65.9144, 44.6159],
                        [-65.8975, 44.6245],
                        [-65.8777, 44.6391],
                        [-65.8626, 44.6481],
                        [-65.8529, 44.6484],
                        [-65.8457, 44.6557],
                        [-65.8392, 44.658],
                        [-65.8348, 44.665],
                        [-65.8283, 44.6653],
                        [-65.8254, 44.6709],
                        [-65.8123, 44.6729],
                        [-65.806, 44.6814],
                        [-65.7983, 44.6871],
                        [-65.7847, 44.6912],
                        [-65.7696, 44.6828],
                        [-65.7655, 44.6719],
                        [-65.7566, 44.6597],
                        [-65.7362, 44.6682],
                        [-65.7542, 44.676],
                        [-65.76, 44.6863],
                        [-65.7548, 44.6974],
                        [-65.7424, 44.7115],
                        [-65.7187, 44.7243],
                        [-65.7067, 44.733],
                        [-65.6835, 44.7453],
                        [-65.6446, 44.7681],
                        [-65.6348, 44.7705],
                        [-65.6143, 44.7817],
                        [-65.6041, 44.7848],
                        [-65.5824, 44.7946],
                        [-65.5497, 44.8109],
                        [-65.5249, 44.8188],
                        [-65.5095, 44.8253],
                        [-65.4922, 44.8364],
                        [-65.4776, 44.8477],
                        [-65.4483, 44.8637],
                        [-65.4233, 44.876],
                        [-65.397, 44.8852],
                        [-65.3918, 44.8896],
                        [-65.3779, 44.8958],
                        [-65.3637, 44.904],
                        [-65.3558, 44.9045],
                        [-65.3446, 44.9127],
                        [-65.3349, 44.916],
                        [-65.3234, 44.9138],
                        [-65.3162, 44.9151],
                        [-65.3082, 44.9213],
                        [-65.3003, 44.9348],
                        [-65.2771, 44.9458],
                        [-65.2531, 44.9509],
                        [-65.2267, 44.9636],
                        [-65.2161, 44.9702],
                        [-65.2112, 44.9767],
                        [-65.1975, 44.9816],
                        [-65.1916, 44.9872],
                        [-65.1535, 45.006],
                        [-65.1449, 45.0055],
                        [-65.1348, 45.0083],
                        [-65.1215, 45.0151],
                        [-65.0837, 45.0302],
                        [-65.0728, 45.0401],
                        [-65.0673, 45.0481],
                        [-65.0497, 45.0511],
                        [-65.0019, 45.0765],
                        [-64.9778, 45.0854],
                        [-64.9685, 45.0933],
                        [-64.9485, 45.1046],
                        [-64.937, 45.1048],
                        [-64.9069, 45.1175],
                        [-64.8852, 45.1287],
                        [-64.8663, 45.1359],
                        [-64.8375, 45.1438],
                        [-64.8299, 45.1503],
                        [-64.8, 45.1531],
                        [-64.7924, 45.1571],
                        [-64.7733, 45.1633],
                        [-64.762, 45.1704],
                        [-64.7429, 45.1714],
                        [-64.7189, 45.1757],
                        [-64.7065, 45.1804],
                        [-64.6794, 45.186],
                        [-64.6678, 45.1869],
                        [-64.6547, 45.1909],
                        [-64.6461, 45.196],
                        [-64.6226, 45.1996],
                        [-64.6169, 45.2027],
                        [-64.6054, 45.201],
                        [-64.5869, 45.2086],
                        [-64.561, 45.213],
                        [-64.5525, 45.2189],
                        [-64.5318, 45.2265],
                        [-64.5251, 45.2266],
                        [-64.4907, 45.2373],
                        [-64.4748, 45.2398],
                        [-64.4598, 45.2441],
                        [-64.4419, 45.2547],
                        [-64.4356, 45.2559],
                        [-64.4249, 45.2632],
                        [-64.4123, 45.2686],
                        [-64.3993, 45.2802],
                        [-64.3972, 45.2849],
                        [-64.3989, 45.3],
                        [-64.4027, 45.3075],
                        [-64.4231, 45.3145],
                        [-64.449, 45.3166],
                        [-64.4699, 45.3204],
                        [-64.4923, 45.3292],
                        [-64.4954, 45.3339],
                        [-64.4601, 45.3277],
                        [-64.4397, 45.3268],
                        [-64.42, 45.3226],
                        [-64.3938, 45.3216],
                        [-64.3698, 45.316],
                        [-64.3525, 45.3062],
                        [-64.3337, 45.299],
                        [-64.3269, 45.2881],
                        [-64.3263, 45.2757],
                        [-64.3338, 45.2632],
                        [-64.3519, 45.2546],
                        [-64.3558, 45.2394],
                        [-64.3549, 45.2316],
                        [-64.3621, 45.2175],
                        [-64.3686, 45.2146],
                        [-64.3758, 45.2038],
                        [-64.3848, 45.1999],
                        [-64.3863, 45.1933],
                        [-64.3668, 45.1979],
                        [-64.3572, 45.1922],
                        [-64.3563, 45.185],
                        [-64.3597, 45.1788],
                        [-64.3512, 45.1655],
                        [-64.3592, 45.1593],
                        [-64.3774, 45.1597],
                        [-64.39, 45.1525],
                        [-64.3924, 45.1488],
                        [-64.404, 45.1509],
                        [-64.3981, 45.1421],
                        [-64.3805, 45.1432],
                        [-64.3785, 45.1365],
                        [-64.391, 45.1348],
                        [-64.3991, 45.1308],
                        [-64.3944, 45.1267],
                        [-64.3946, 45.1197],
                        [-64.3863, 45.1185],
                        [-64.367, 45.1238],
                        [-64.3697, 45.1118],
                        [-64.3663, 45.1069],
                        [-64.3746, 45.1038],
                        [-64.3745, 45.0995],
                        [-64.3617, 45.0964],
                        [-64.3594, 45.0927],
                        [-64.3464, 45.1014],
                        [-64.3355, 45.1032],
                        [-64.3374, 45.1122],
                        [-64.3327, 45.1201],
                        [-64.3382, 45.1214],
                        [-64.3349, 45.1309],
                        [-64.3295, 45.1359],
                        [-64.3198, 45.1383],
                        [-64.3009, 45.1377],
                        [-64.2828, 45.1318],
                        [-64.2812, 45.125],
                        [-64.2852, 45.1191],
                        [-64.2818, 45.1146],
                        [-64.271, 45.1097],
                        [-64.2646, 45.1158],
                        [-64.2739, 45.1189],
                        [-64.2655, 45.1239],
                        [-64.2563, 45.1196],
                        [-64.2499, 45.1205],
                        [-64.2409, 45.1108],
                        [-64.2297, 45.1099],
                        [-64.2022, 45.1303],
                        [-64.1974, 45.138],
                        [-64.1974, 45.1478],
                        [-64.1894, 45.1549],
                        [-64.1829, 45.154],
                        [-64.1683, 45.1616],
                        [-64.164, 45.1735],
                        [-64.1655, 45.1793],
                        [-64.1735, 45.1863],
                        [-64.1552, 45.196],
                        [-64.1132, 45.2107],
                        [-64.1094, 45.2145],
                        [-64.0972, 45.2147],
                        [-64.07, 45.2208],
                        [-64.0674, 45.2261],
                        [-64.0571, 45.2319],
                        [-64.0523, 45.2308],
                        [-64.0313, 45.2338],
                        [-64.0226, 45.2297],
                        [-64.0136, 45.231],
                        [-64.0036, 45.2401],
                        [-64.0027, 45.244],
                        [-63.9796, 45.2506],
                        [-63.9493, 45.2608],
                        [-63.892, 45.2789],
                        [-63.8737, 45.2687],
                        [-63.8611, 45.2785],
                        [-63.8429, 45.2855],
                        [-63.8271, 45.2892],
                        [-63.8209, 45.2876],
                        [-63.8075, 45.2908],
                        [-63.8045, 45.2957],
                        [-63.8128, 45.2996],
                        [-63.8122, 45.3055],
                        [-63.8042, 45.3128],
                        [-63.7748, 45.3162],
                        [-63.7653, 45.3158],
                        [-63.7591, 45.3072],
                        [-63.7438, 45.3019],
                        [-63.7296, 45.3035],
                        [-63.7226, 45.3083],
                        [-63.727, 45.319],
                        [-63.7154, 45.3233],
                        [-63.703, 45.3241],
                        [-63.6955, 45.3184],
                        [-63.6758, 45.3149],
                        [-63.6714, 45.3185],
                        [-63.6506, 45.3148],
                        [-63.6428, 45.3176],
                        [-63.6293, 45.3143],
                        [-63.6139, 45.317],
                        [-63.5988, 45.315],
                        [-63.5611, 45.3158],
                        [-63.547, 45.3233],
                        [-63.5397, 45.3353],
                        [-63.5267, 45.3328],
                        [-63.5025, 45.3238],
                        [-63.4897, 45.315],
                        [-63.4788, 45.3171],
                        [-63.4763, 45.3321],
                        [-63.4716, 45.3359],
                        [-63.4551, 45.3394],
                        [-63.4371, 45.3495],
                        [-63.4087, 45.3527],
                        [-63.4036, 45.3503],
                        [-63.3903, 45.3521],
                        [-63.3762, 45.3631],
                        [-63.3881, 45.3615],
                        [-63.4114, 45.3633],
                        [-63.4187, 45.3677],
                        [-63.432, 45.3641],
                        [-63.4523, 45.3644],
                        [-63.4729, 45.3663],
                        [-63.4944, 45.3724],
                        [-63.502, 45.37],
                        [-63.5164, 45.3704],
                        [-63.5406, 45.3765],
                        [-63.56, 45.3789],
                        [-63.5831, 45.3846],
                        [-63.6016, 45.3923],
                        [-63.6233, 45.391],
                        [-63.631, 45.387],
                        [-63.6504, 45.3946],
                        [-63.671, 45.3943],
                        [-63.6835, 45.392],
                        [-63.6885, 45.3881],
                        [-63.6984, 45.3881],
                        [-63.7111, 45.3947],
                        [-63.7174, 45.3893],
                        [-63.7484, 45.4005],
                        [-63.7606, 45.3995],
                        [-63.7751, 45.4006],
                        [-63.7808, 45.3947],
                        [-63.8042, 45.3989],
                        [-63.8103, 45.3941],
                        [-63.8383, 45.3881],
                        [-63.8684, 45.3803],
                        [-63.8696, 45.3758],
                        [-63.8817, 45.3665],
                        [-63.8885, 45.3503],
                        [-63.9052, 45.3506],
                        [-63.9171, 45.356],
                        [-63.9191, 45.3633],
                        [-63.9147, 45.3761],
                        [-63.925, 45.3794],
                        [-63.9467, 45.3941],
                        [-63.9645, 45.3962],
                        [-63.9774, 45.3998],
                        [-63.9927, 45.3978],
                        [-63.9982, 45.3887],
                        [-64.012, 45.3857],
                        [-64.0396, 45.3842],
                        [-64.0608, 45.3894],
                        [-64.0621, 45.3941],
                        [-64.0528, 45.4014],
                        [-64.0593, 45.4031],
                        [-64.0705, 45.4004],
                        [-64.068, 45.4077],
                        [-64.0832, 45.4074],
                        [-64.0908, 45.4052],
                        [-64.0983, 45.4099],
                        [-64.115, 45.4026],
                        [-64.1263, 45.4028],
                        [-64.1444, 45.3994],
                        [-64.1631, 45.3991],
                        [-64.1766, 45.4012],
                        [-64.1965, 45.3968],
                        [-64.2069, 45.3971],
                        [-64.217, 45.3939],
                        [-64.2269, 45.3948],
                        [-64.2471, 45.3903],
                        [-64.2516, 45.3861],
                        [-64.2511, 45.3781],
                        [-64.2616, 45.3785],
                        [-64.2736, 45.3826],
                        [-64.2971, 45.3843],
                        [-64.3049, 45.3906],
                        [-64.3176, 45.3907],
                        [-64.3273, 45.3934],
                        [-64.3273, 45.3863],
                        [-64.3201, 45.3838],
                        [-64.3219, 45.378],
                        [-64.3349, 45.3705],
                        [-64.3461, 45.3769],
                        [-64.357, 45.379],
                        [-64.3774, 45.3776],
                        [-64.3913, 45.3693],
                        [-64.4274, 45.3768],
                        [-64.4449, 45.3818],
                        [-64.4564, 45.3955],
                        [-64.4813, 45.3978],
                        [-64.4873, 45.3925],
                        [-64.4973, 45.3914],
                        [-64.5475, 45.4072],
                        [-64.5646, 45.408],
                        [-64.5788, 45.4065],
                        [-64.6128, 45.3996],
                        [-64.6353, 45.3965],
                        [-64.6721, 45.3861],
                        [-64.6986, 45.3689],
                        [-64.7031, 45.3677],
                        [-64.7098, 45.3582],
                        [-64.7116, 45.3497],
                        [-64.7067, 45.3384],
                        [-64.7101, 45.3161],
                        [-64.7311, 45.3166],
                        [-64.7614, 45.3079],
                        [-64.7704, 45.3023],
                        [-64.7672, 45.2953],
                        [-64.7751, 45.2914],
                        [-64.7876, 45.3068],
                        [-64.786, 45.3121],
                        [-64.7727, 45.3255],
                        [-64.7755, 45.3333],
                        [-64.7986, 45.3383],
                        [-64.8013, 45.335],
                        [-64.8125, 45.3441],
                        [-64.8341, 45.3538],
                        [-64.8492, 45.3554],
                        [-64.8634, 45.3521],
                        [-64.8735, 45.3477],
                        [-64.9041, 45.34],
                        [-64.9151, 45.3342],
                        [-64.9289, 45.3304],
                        [-64.9436, 45.3288],
                        [-64.9443, 45.3419],
                        [-64.9372, 45.3562],
                        [-64.9396, 45.3602],
                        [-64.9321, 45.3668],
                        [-64.9258, 45.3889],
                        [-64.9241, 45.3995],
                        [-64.9275, 45.4092],
                        [-64.9185, 45.4162],
                        [-64.914, 45.4305],
                        [-64.8957, 45.4302],
                        [-64.89, 45.4424],
                        [-64.8817, 45.4472],
                        [-64.8686, 45.4509],
                        [-64.8653, 45.4625],
                        [-64.852, 45.4564],
                        [-64.8383, 45.4592],
                        [-64.8305, 45.4669],
                        [-64.8046, 45.473],
                        [-64.802, 45.478],
                        [-64.8295, 45.4767],
                        [-64.8423, 45.4815],
                        [-64.8323, 45.4879],
                        [-64.8228, 45.4873],
                        [-64.8171, 45.4925],
                        [-64.7978, 45.503],
                        [-64.7868, 45.5024],
                        [-64.7758, 45.508],
                        [-64.7589, 45.5133],
                        [-64.7425, 45.5218],
                        [-64.7105, 45.5334],
                        [-64.6854, 45.5367],
                        [-64.6827, 45.5447],
                        [-64.675, 45.5525],
                        [-64.6618, 45.5575],
                        [-64.6529, 45.5653],
                        [-64.6553, 45.5759],
                        [-64.6502, 45.5816],
                        [-64.6394, 45.5845],
                        [-64.6369, 45.5887],
                        [-64.619, 45.5955],
                        [-64.6022, 45.5969],
                        [-64.581, 45.6079],
                        [-64.575, 45.6037],
                        [-64.5656, 45.6157],
                        [-64.5427, 45.6332],
                        [-64.5318, 45.6388],
                        [-64.5282, 45.6469],
                        [-64.518, 45.652],
                        [-64.5043, 45.6628],
                        [-64.4925, 45.6606],
                        [-64.4873, 45.6626],
                        [-64.4858, 45.6715],
                        [-64.4675, 45.6808],
                        [-64.4539, 45.6837],
                        [-64.4498, 45.7008],
                        [-64.4379, 45.7073],
                        [-64.434, 45.7174],
                        [-64.437, 45.7243],
                        [-64.4497, 45.7338],
                        [-64.4422, 45.743],
                        [-64.4333, 45.7452],
                        [-64.4333, 45.7494],
                        [-64.4068, 45.7545],
                        [-64.3919, 45.7618],
                        [-64.3818, 45.7699],
                        [-64.3789, 45.7868],
                        [-64.3851, 45.7933],
                        [-64.3671, 45.7981],
                        [-64.3566, 45.7982],
                        [-64.3489, 45.8043],
                        [-64.3451, 45.8127],
                        [-64.3389, 45.812],
                        [-64.3327, 45.8196],
                        [-64.3175, 45.8243],
                        [-64.3062, 45.8238],
                        [-64.2952, 45.8183],
                        [-64.2954, 45.8056],
                        [-64.3243, 45.7891],
                        [-64.3283, 45.7793],
                        [-64.3391, 45.7728],
                        [-64.3415, 45.7677],
                        [-64.3337, 45.7562],
                        [-64.3234, 45.755],
                        [-64.3026, 45.7671],
                        [-64.298, 45.7809],
                        [-64.287, 45.7856],
                        [-64.2771, 45.7925],
                        [-64.2674, 45.8036],
                        [-64.2551, 45.8119],
                        [-64.2454, 45.821],
                        [-64.2735, 45.8356],
                        [-64.275, 45.8415]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "39",
            properties: { cityName: "Prince Edward Island", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-63.9886, 47.0312],
                        [-63.9877, 47.0264],
                        [-63.9895, 47.0035],
                        [-63.9912, 46.998],
                        [-63.9893, 46.9835],
                        [-63.9942, 46.9646],
                        [-63.9915, 46.9377],
                        [-63.9847, 46.9245],
                        [-63.9749, 46.9102],
                        [-63.9768, 46.9061],
                        [-63.9739, 46.8809],
                        [-63.9864, 46.8744],
                        [-64.0084, 46.8554],
                        [-64.0266, 46.8302],
                        [-64.0291, 46.8077],
                        [-64.0543, 46.8045],
                        [-64.0618, 46.795],
                        [-64.0591, 46.7869],
                        [-64.0648, 46.7827],
                        [-64.0714, 46.7957],
                        [-64.0806, 46.7764],
                        [-64.0731, 46.7772],
                        [-64.0791, 46.7646],
                        [-64.0774, 46.7529],
                        [-64.0558, 46.7466],
                        [-64.047, 46.737],
                        [-64.0294, 46.7291],
                        [-64.0176, 46.7406],
                        [-64.0086, 46.7343],
                        [-63.9994, 46.7337],
                        [-63.9845, 46.7293],
                        [-63.9862, 46.7261],
                        [-63.9793, 46.7159],
                        [-63.9673, 46.7074],
                        [-63.9603, 46.7001],
                        [-63.9522, 46.6972],
                        [-63.942, 46.6897],
                        [-63.9431, 46.6837],
                        [-63.9296, 46.6822],
                        [-63.9233, 46.679],
                        [-63.9144, 46.669],
                        [-63.9086, 46.6575],
                        [-63.8975, 46.6514],
                        [-63.8879, 46.6492],
                        [-63.8791, 46.639],
                        [-63.8817, 46.6253],
                        [-63.8781, 46.6229],
                        [-63.8777, 46.6062],
                        [-63.8595, 46.5903],
                        [-63.8508, 46.5857],
                        [-63.8325, 46.5797],
                        [-63.836, 46.5745],
                        [-63.8478, 46.5723],
                        [-63.8517, 46.5583],
                        [-63.8665, 46.5487],
                        [-63.8623, 46.5351],
                        [-63.8668, 46.5248],
                        [-63.8822, 46.5133],
                        [-63.8705, 46.5029],
                        [-63.8569, 46.5043],
                        [-63.8468, 46.5031],
                        [-63.831, 46.5152],
                        [-63.8205, 46.5014],
                        [-63.8089, 46.4965],
                        [-63.8071, 46.4867],
                        [-63.8124, 46.481],
                        [-63.8117, 46.4674],
                        [-63.8287, 46.4716],
                        [-63.8346, 46.4694],
                        [-63.8324, 46.4629],
                        [-63.8486, 46.46],
                        [-63.8122, 46.4552],
                        [-63.7984, 46.4506],
                        [-63.7925, 46.445],
                        [-63.7805, 46.4446],
                        [-63.7589, 46.448],
                        [-63.7396, 46.4416],
                        [-63.7277, 46.4353],
                        [-63.7154, 46.4363],
                        [-63.7087, 46.4419],
                        [-63.7176, 46.448],
                        [-63.7137, 46.4548],
                        [-63.6997, 46.455],
                        [-63.6952, 46.4596],
                        [-63.6956, 46.4666],
                        [-63.7009, 46.474],
                        [-63.7236, 46.4793],
                        [-63.7229, 46.4866],
                        [-63.7355, 46.489],
                        [-63.7482, 46.4877],
                        [-63.7472, 46.4978],
                        [-63.7173, 46.5152],
                        [-63.6963, 46.5163],
                        [-63.6982, 46.5273],
                        [-63.714, 46.5324],
                        [-63.7218, 46.5307],
                        [-63.7339, 46.5334],
                        [-63.7243, 46.5393],
                        [-63.7194, 46.5482],
                        [-63.7185, 46.5561],
                        [-63.7076, 46.5619],
                        [-63.701, 46.5602],
                        [-63.6999, 46.5536],
                        [-63.6939, 46.5497],
                        [-63.6862, 46.5502],
                        [-63.6784, 46.5464],
                        [-63.6698, 46.5365],
                        [-63.6568, 46.542],
                        [-63.6574, 46.5503],
                        [-63.6622, 46.5564],
                        [-63.6805, 46.5613],
                        [-63.6685, 46.5677],
                        [-63.6198, 46.5585],
                        [-63.5556, 46.5407],
                        [-63.5345, 46.5367],
                        [-63.5257, 46.5369],
                        [-63.5177, 46.5338],
                        [-63.5088, 46.5349],
                        [-63.5012, 46.5285],
                        [-63.4911, 46.5241],
                        [-63.4853, 46.5175],
                        [-63.4852, 46.5109],
                        [-63.4945, 46.5059],
                        [-63.4956, 46.4971],
                        [-63.4805, 46.4962],
                        [-63.4819, 46.49],
                        [-63.479, 46.4812],
                        [-63.4722, 46.4815],
                        [-63.4653, 46.4758],
                        [-63.4578, 46.4635],
                        [-63.4496, 46.4694],
                        [-63.4541, 46.4769],
                        [-63.4389, 46.478],
                        [-63.4432, 46.487],
                        [-63.4241, 46.4918],
                        [-63.4235, 46.5001],
                        [-63.4108, 46.503],
                        [-63.3848, 46.4998],
                        [-63.3745, 46.5021],
                        [-63.3659, 46.4993],
                        [-63.3383, 46.4968],
                        [-63.3122, 46.49],
                        [-63.3126, 46.4856],
                        [-63.3034, 46.4784],
                        [-63.2984, 46.4696],
                        [-63.2997, 46.4653],
                        [-63.2919, 46.4588],
                        [-63.288, 46.447],
                        [-63.276, 46.4398],
                        [-63.2796, 46.4358],
                        [-63.2696, 46.4291],
                        [-63.2676, 46.4232],
                        [-63.2438, 46.4229],
                        [-63.2386, 46.4202],
                        [-63.253, 46.405],
                        [-63.2461, 46.4021],
                        [-63.2365, 46.4075],
                        [-63.2259, 46.4057],
                        [-63.2154, 46.4108],
                        [-63.211, 46.4259],
                        [-63.2295, 46.4304],
                        [-63.2421, 46.436],
                        [-63.2541, 46.4333],
                        [-63.2678, 46.4459],
                        [-63.2411, 46.4381],
                        [-63.2346, 46.4378],
                        [-63.2303, 46.432],
                        [-63.2122, 46.4319],
                        [-63.1856, 46.4302],
                        [-63.1615, 46.4306],
                        [-63.1565, 46.425],
                        [-63.164, 46.4223],
                        [-63.1561, 46.4152],
                        [-63.1328, 46.4131],
                        [-63.1265, 46.4005],
                        [-63.1202, 46.4027],
                        [-63.1163, 46.4151],
                        [-63.1249, 46.4197],
                        [-63.147, 46.423],
                        [-63.1457, 46.4301],
                        [-63.1335, 46.4303],
                        [-63.1194, 46.4254],
                        [-63.0892, 46.4191],
                        [-63.0427, 46.4134],
                        [-63.0241, 46.3986],
                        [-63.0104, 46.3972],
                        [-63.0035, 46.3943],
                        [-63.0044, 46.3812],
                        [-62.9881, 46.3599],
                        [-62.9825, 46.3594],
                        [-62.9826, 46.37],
                        [-62.9769, 46.3861],
                        [-62.9793, 46.3929],
                        [-62.9705, 46.4031],
                        [-62.9709, 46.4124],
                        [-62.9813, 46.4142],
                        [-63.006, 46.4132],
                        [-63.0166, 46.4142],
                        [-63.0358, 46.4118],
                        [-63.0373, 46.4153],
                        [-62.9908, 46.4171],
                        [-62.9561, 46.4223],
                        [-62.942, 46.4235],
                        [-62.9064, 46.4314],
                        [-62.8804, 46.4326],
                        [-62.8572, 46.4349],
                        [-62.8374, 46.4337],
                        [-62.8414, 46.4187],
                        [-62.847, 46.4141],
                        [-62.847, 46.4078],
                        [-62.8387, 46.4074],
                        [-62.8184, 46.4123],
                        [-62.8209, 46.4188],
                        [-62.8329, 46.4329],
                        [-62.8222, 46.433],
                        [-62.7887, 46.4375],
                        [-62.77, 46.4418],
                        [-62.7416, 46.4436],
                        [-62.722, 46.4307],
                        [-62.7073, 46.4315],
                        [-62.7007, 46.4275],
                        [-62.6872, 46.4235],
                        [-62.6723, 46.4277],
                        [-62.6598, 46.422],
                        [-62.6541, 46.4166],
                        [-62.6372, 46.4251],
                        [-62.6286, 46.4223],
                        [-62.626, 46.4167],
                        [-62.614, 46.4191],
                        [-62.6015, 46.4177],
                        [-62.5916, 46.4143],
                        [-62.5822, 46.4174],
                        [-62.6099, 46.4307],
                        [-62.623, 46.4323],
                        [-62.6477, 46.4319],
                        [-62.6558, 46.4329],
                        [-62.6622, 46.4374],
                        [-62.6814, 46.4429],
                        [-62.6949, 46.4401],
                        [-62.7101, 46.4405],
                        [-62.7276, 46.4458],
                        [-62.6901, 46.4566],
                        [-62.6746, 46.4577],
                        [-62.6444, 46.4633],
                        [-62.6289, 46.4676],
                        [-62.6132, 46.4653],
                        [-62.5604, 46.4692],
                        [-62.5378, 46.468],
                        [-62.5173, 46.4714],
                        [-62.4723, 46.4749],
                        [-62.4452, 46.4706],
                        [-62.4248, 46.4713],
                        [-62.4115, 46.4677],
                        [-62.3745, 46.4682],
                        [-62.3605, 46.4708],
                        [-62.3337, 46.4707],
                        [-62.3188, 46.4727],
                        [-62.2595, 46.4758],
                        [-62.2393, 46.4795],
                        [-62.2238, 46.4793],
                        [-62.2112, 46.482],
                        [-62.1802, 46.4816],
                        [-62.1643, 46.4844],
                        [-62.1601, 46.4821],
                        [-62.1466, 46.4841],
                        [-62.1375, 46.4798],
                        [-62.1158, 46.4782],
                        [-62.11, 46.4751],
                        [-62.0891, 46.4735],
                        [-62.0685, 46.4683],
                        [-62.0219, 46.4665],
                        [-62.0064, 46.4627],
                        [-61.9901, 46.4645],
                        [-61.9814, 46.457],
                        [-61.9707, 46.4518],
                        [-62.0057, 46.4299],
                        [-62.023, 46.4289],
                        [-62.0348, 46.4205],
                        [-62.0571, 46.4113],
                        [-62.0633, 46.4034],
                        [-62.0817, 46.3939],
                        [-62.0894, 46.3878],
                        [-62.107, 46.378],
                        [-62.1333, 46.3675],
                        [-62.1576, 46.3666],
                        [-62.1726, 46.3582],
                        [-62.1708, 46.3542],
                        [-62.1832, 46.3477],
                        [-62.2048, 46.3504],
                        [-62.2117, 46.3453],
                        [-62.2297, 46.345],
                        [-62.239, 46.3427],
                        [-62.2462, 46.3449],
                        [-62.2502, 46.3516],
                        [-62.2637, 46.3566],
                        [-62.2796, 46.3554],
                        [-62.2798, 46.3511],
                        [-62.2705, 46.3435],
                        [-62.2816, 46.3373],
                        [-62.2934, 46.3338],
                        [-62.3181, 46.35],
                        [-62.3331, 46.3506],
                        [-62.3388, 46.3535],
                        [-62.364, 46.3514],
                        [-62.3572, 46.3455],
                        [-62.3388, 46.342],
                        [-62.3451, 46.3334],
                        [-62.3622, 46.3314],
                        [-62.3544, 46.3271],
                        [-62.3501, 46.3208],
                        [-62.367, 46.3181],
                        [-62.3499, 46.3123],
                        [-62.3434, 46.3081],
                        [-62.3476, 46.3025],
                        [-62.3579, 46.3042],
                        [-62.3741, 46.3039],
                        [-62.3717, 46.2871],
                        [-62.3667, 46.2856],
                        [-62.3576, 46.2765],
                        [-62.3758, 46.2743],
                        [-62.3747, 46.2711],
                        [-62.385, 46.2597],
                        [-62.3955, 46.2635],
                        [-62.4193, 46.2655],
                        [-62.4237, 46.2592],
                        [-62.4184, 46.229],
                        [-62.4117, 46.2233],
                        [-62.41, 46.2123],
                        [-62.4433, 46.2083],
                        [-62.4647, 46.2089],
                        [-62.471, 46.2072],
                        [-62.4853, 46.2116],
                        [-62.491, 46.2154],
                        [-62.5099, 46.2095],
                        [-62.518, 46.2019],
                        [-62.5167, 46.1962],
                        [-62.5035, 46.1889],
                        [-62.4977, 46.1749],
                        [-62.515, 46.1728],
                        [-62.5241, 46.1808],
                        [-62.5357, 46.1797],
                        [-62.5502, 46.1871],
                        [-62.5618, 46.1799],
                        [-62.5423, 46.1679],
                        [-62.5251, 46.1591],
                        [-62.5307, 46.151],
                        [-62.5414, 46.1496],
                        [-62.5472, 46.1421],
                        [-62.532, 46.1401],
                        [-62.5296, 46.1331],
                        [-62.5408, 46.1316],
                        [-62.5488, 46.1276],
                        [-62.5389, 46.1198],
                        [-62.5316, 46.1233],
                        [-62.5152, 46.1207],
                        [-62.5158, 46.1167],
                        [-62.5074, 46.1115],
                        [-62.5, 46.1113],
                        [-62.496, 46.1204],
                        [-62.4869, 46.1212],
                        [-62.4806, 46.1182],
                        [-62.4691, 46.1221],
                        [-62.4704, 46.1335],
                        [-62.4837, 46.1352],
                        [-62.5013, 46.1319],
                        [-62.508, 46.1369],
                        [-62.4937, 46.1466],
                        [-62.4847, 46.1492],
                        [-62.4678, 46.1456],
                        [-62.4666, 46.1235],
                        [-62.4485, 46.1041],
                        [-62.453, 46.0981],
                        [-62.4499, 46.0896],
                        [-62.4528, 46.0818],
                        [-62.4658, 46.0706],
                        [-62.4703, 46.0542],
                        [-62.4818, 46.0453],
                        [-62.4924, 46.0489],
                        [-62.5044, 46.0482],
                        [-62.514, 46.044],
                        [-62.5354, 46.0393],
                        [-62.5369, 46.0367],
                        [-62.5341, 46.0222],
                        [-62.5238, 46.0231],
                        [-62.5026, 46.0118],
                        [-62.4958, 46.0153],
                        [-62.4824, 46.0166],
                        [-62.4758, 46.0208],
                        [-62.4536, 46.0152],
                        [-62.4576, 46.0036],
                        [-62.4738, 45.9932],
                        [-62.5072, 45.9751],
                        [-62.5312, 45.9705],
                        [-62.5635, 45.9659],
                        [-62.6235, 45.9643],
                        [-62.6632, 45.9591],
                        [-62.6984, 45.9621],
                        [-62.7058, 45.9592],
                        [-62.7132, 45.9626],
                        [-62.7261, 45.9644],
                        [-62.7426, 45.9599],
                        [-62.7491, 45.9554],
                        [-62.7548, 45.9602],
                        [-62.7623, 45.9577],
                        [-62.7771, 45.9593],
                        [-62.7916, 45.963],
                        [-62.8242, 45.965],
                        [-62.8432, 45.9732],
                        [-62.8503, 45.9743],
                        [-62.8638, 45.9874],
                        [-62.8712, 45.9883],
                        [-62.8816, 45.9964],
                        [-62.8906, 45.9982],
                        [-62.884, 46.0076],
                        [-62.8844, 46.0133],
                        [-62.8919, 46.0178],
                        [-62.9092, 46.0223],
                        [-62.9242, 46.0363],
                        [-62.9404, 46.0433],
                        [-62.9555, 46.0546],
                        [-62.9818, 46.0562],
                        [-62.993, 46.0534],
                        [-63.014, 46.0517],
                        [-63.0235, 46.0528],
                        [-63.0324, 46.0495],
                        [-63.0404, 46.0538],
                        [-63.0254, 46.058],
                        [-63.0184, 46.0626],
                        [-63.0163, 46.0689],
                        [-63.0049, 46.0693],
                        [-62.9943, 46.0672],
                        [-62.9714, 46.0747],
                        [-62.9653, 46.0754],
                        [-62.943, 46.0852],
                        [-62.9384, 46.0916],
                        [-62.9165, 46.0909],
                        [-62.9148, 46.0964],
                        [-62.9059, 46.1029],
                        [-62.8866, 46.109],
                        [-62.8846, 46.1196],
                        [-62.8875, 46.1275],
                        [-62.8775, 46.1324],
                        [-62.875, 46.1428],
                        [-62.8863, 46.1389],
                        [-62.8897, 46.1421],
                        [-62.9009, 46.1383],
                        [-62.9005, 46.1341],
                        [-62.9092, 46.1295],
                        [-62.9154, 46.1338],
                        [-62.9276, 46.1239],
                        [-62.9324, 46.1259],
                        [-62.9451, 46.1239],
                        [-62.9529, 46.1266],
                        [-62.9627, 46.1263],
                        [-62.9658, 46.131],
                        [-62.9633, 46.1491],
                        [-62.9591, 46.1503],
                        [-62.9544, 46.1623],
                        [-62.9489, 46.1645],
                        [-62.9456, 46.1718],
                        [-62.9375, 46.1694],
                        [-62.9325, 46.1725],
                        [-62.9344, 46.1786],
                        [-62.9425, 46.1866],
                        [-62.9546, 46.1915],
                        [-62.9687, 46.1943],
                        [-62.9729, 46.1986],
                        [-62.9873, 46.1933],
                        [-62.9945, 46.1852],
                        [-62.987, 46.1826],
                        [-62.9942, 46.1729],
                        [-63.023, 46.175],
                        [-63.0229, 46.1824],
                        [-63.0137, 46.1831],
                        [-63.0128, 46.1883],
                        [-63.0252, 46.1925],
                        [-63.0378, 46.1879],
                        [-63.044, 46.1915],
                        [-63.0526, 46.1865],
                        [-63.0631, 46.1942],
                        [-63.0726, 46.1936],
                        [-63.0839, 46.1991],
                        [-63.0994, 46.1925],
                        [-63.1115, 46.1956],
                        [-63.1174, 46.1941],
                        [-63.1208, 46.206],
                        [-63.1201, 46.214],
                        [-63.1378, 46.2274],
                        [-63.143, 46.2272],
                        [-63.1611, 46.2172],
                        [-63.1629, 46.2135],
                        [-63.1565, 46.2064],
                        [-63.1436, 46.2083],
                        [-63.1401, 46.1996],
                        [-63.1345, 46.1989],
                        [-63.1293, 46.1897],
                        [-63.1402, 46.1785],
                        [-63.1555, 46.1696],
                        [-63.1797, 46.1609],
                        [-63.1925, 46.1595],
                        [-63.2018, 46.1539],
                        [-63.2234, 46.1526],
                        [-63.2278, 46.136],
                        [-63.2218, 46.1318],
                        [-63.2463, 46.13],
                        [-63.2577, 46.1308],
                        [-63.2836, 46.1406],
                        [-63.294, 46.1459],
                        [-63.3087, 46.1505],
                        [-63.3169, 46.1477],
                        [-63.3353, 46.156],
                        [-63.3452, 46.1569],
                        [-63.3617, 46.1654],
                        [-63.3854, 46.1704],
                        [-63.4011, 46.1698],
                        [-63.4125, 46.1747],
                        [-63.4341, 46.1888],
                        [-63.4501, 46.186],
                        [-63.464, 46.1945],
                        [-63.4646, 46.1981],
                        [-63.4778, 46.2072],
                        [-63.4773, 46.2149],
                        [-63.4871, 46.2149],
                        [-63.5021, 46.2067],
                        [-63.526, 46.2031],
                        [-63.5346, 46.2142],
                        [-63.5417, 46.2162],
                        [-63.5439, 46.207],
                        [-63.5583, 46.2068],
                        [-63.5675, 46.2157],
                        [-63.5799, 46.2156],
                        [-63.5856, 46.2078],
                        [-63.5992, 46.212],
                        [-63.61, 46.2199],
                        [-63.6174, 46.2154],
                        [-63.6309, 46.2231],
                        [-63.6392, 46.225],
                        [-63.6505, 46.2215],
                        [-63.6503, 46.236],
                        [-63.6588, 46.2381],
                        [-63.6741, 46.2468],
                        [-63.6839, 46.2411],
                        [-63.695, 46.2502],
                        [-63.7014, 46.2515],
                        [-63.7024, 46.2685],
                        [-63.6994, 46.2709],
                        [-63.7065, 46.2785],
                        [-63.7192, 46.2769],
                        [-63.7278, 46.2896],
                        [-63.7347, 46.2958],
                        [-63.7439, 46.2958],
                        [-63.7508, 46.3058],
                        [-63.7738, 46.3085],
                        [-63.7841, 46.3179],
                        [-63.7947, 46.3201],
                        [-63.8087, 46.3146],
                        [-63.8161, 46.3268],
                        [-63.815, 46.3388],
                        [-63.804, 46.3416],
                        [-63.7917, 46.3372],
                        [-63.7765, 46.3409],
                        [-63.7846, 46.3448],
                        [-63.7894, 46.3525],
                        [-63.8033, 46.3613],
                        [-63.8108, 46.3718],
                        [-63.8012, 46.3715],
                        [-63.7976, 46.3662],
                        [-63.78, 46.355],
                        [-63.764, 46.3549],
                        [-63.7619, 46.3491],
                        [-63.7492, 46.346],
                        [-63.7374, 46.3519],
                        [-63.7317, 46.3487],
                        [-63.7252, 46.3541],
                        [-63.7574, 46.3692],
                        [-63.7659, 46.3692],
                        [-63.765, 46.3811],
                        [-63.7517, 46.3838],
                        [-63.745, 46.3901],
                        [-63.7602, 46.3917],
                        [-63.7685, 46.3891],
                        [-63.7905, 46.3895],
                        [-63.8044, 46.3961],
                        [-63.8218, 46.3944],
                        [-63.832, 46.3904],
                        [-63.8403, 46.3915],
                        [-63.8596, 46.3988],
                        [-63.8658, 46.4028],
                        [-63.8808, 46.4012],
                        [-63.881, 46.3944],
                        [-63.8895, 46.395],
                        [-63.9034, 46.4097],
                        [-63.927, 46.4181],
                        [-63.939, 46.4187],
                        [-63.9496, 46.4107],
                        [-63.953, 46.4051],
                        [-63.941, 46.4004],
                        [-63.9569, 46.3965],
                        [-63.9819, 46.388],
                        [-64.0027, 46.3852],
                        [-64.0322, 46.3885],
                        [-64.05, 46.3957],
                        [-64.0745, 46.3985],
                        [-64.0986, 46.3992],
                        [-64.1159, 46.4017],
                        [-64.1344, 46.4018],
                        [-64.1333, 46.4155],
                        [-64.1399, 46.4205],
                        [-64.1393, 46.4309],
                        [-64.1092, 46.4565],
                        [-64.1017, 46.4692],
                        [-64.1054, 46.4998],
                        [-64.1132, 46.5198],
                        [-64.1112, 46.5257],
                        [-64.1, 46.5346],
                        [-64.0967, 46.5412],
                        [-64.0873, 46.5491],
                        [-64.0783, 46.553],
                        [-64.0759, 46.5597],
                        [-64.0668, 46.569],
                        [-64.0597, 46.57],
                        [-64.0556, 46.5764],
                        [-64.0628, 46.5815],
                        [-64.084, 46.5859],
                        [-64.0746, 46.5943],
                        [-64.0788, 46.6038],
                        [-64.0822, 46.6201],
                        [-64.0805, 46.6248],
                        [-64.0674, 46.6305],
                        [-64.0731, 46.634],
                        [-64.0848, 46.6329],
                        [-64.0966, 46.6201],
                        [-64.0987, 46.6139],
                        [-64.1072, 46.6027],
                        [-64.1132, 46.6007],
                        [-64.1172, 46.5926],
                        [-64.1275, 46.5881],
                        [-64.1526, 46.5965],
                        [-64.1814, 46.6132],
                        [-64.1882, 46.615],
                        [-64.1915, 46.6252],
                        [-64.2097, 46.6269],
                        [-64.2167, 46.6302],
                        [-64.2321, 46.6294],
                        [-64.2398, 46.6259],
                        [-64.2608, 46.6256],
                        [-64.3009, 46.6307],
                        [-64.3228, 46.6216],
                        [-64.3431, 46.6196],
                        [-64.3698, 46.6208],
                        [-64.383, 46.6155],
                        [-64.3925, 46.6274],
                        [-64.3975, 46.6393],
                        [-64.4114, 46.658],
                        [-64.4134, 46.6819],
                        [-64.4096, 46.7076],
                        [-64.3992, 46.7239],
                        [-64.3875, 46.7298],
                        [-64.3772, 46.7387],
                        [-64.3745, 46.7471],
                        [-64.3579, 46.7556],
                        [-64.3359, 46.7731],
                        [-64.3052, 46.7931],
                        [-64.2843, 46.8086],
                        [-64.2602, 46.8341],
                        [-64.2539, 46.8482],
                        [-64.2394, 46.865],
                        [-64.2342, 46.8798],
                        [-64.226, 46.8882],
                        [-64.2265, 46.8953],
                        [-64.2152, 46.9041],
                        [-64.1891, 46.9273],
                        [-64.1845, 46.9357],
                        [-64.1657, 46.95],
                        [-64.1507, 46.9568],
                        [-64.1344, 46.9612],
                        [-64.1201, 46.9679],
                        [-64.108, 46.977],
                        [-64.088, 46.988],
                        [-64.0682, 46.9945],
                        [-64.0475, 47.0067],
                        [-64.0396, 47.0159],
                        [-64.0217, 47.0275],
                        [-64.0148, 47.0381],
                        [-63.9955, 47.061],
                        [-63.9915, 47.0606],
                        [-63.9876, 47.0456],
                        [-63.9886, 47.0312]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "40",
            properties: { cityName: "New Foundland", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [

                    [
                        [-55.8962, 51.6305],
                        [-55.8918, 51.6159],
                        [-55.8958, 51.6087],
                        [-55.8764, 51.6104],
                        [-55.8699, 51.6137],
                        [-55.8426, 51.6165],
                        [-55.8528, 51.6095],
                        [-55.8613, 51.608],
                        [-55.8667, 51.5994],
                        [-55.8766, 51.5896],
                        [-55.8826, 51.5735],
                        [-55.8953, 51.5598],
                        [-55.9004, 51.5491],
                        [-55.892, 51.5462],
                        [-55.8654, 51.5439],
                        [-55.8801, 51.5343],
                        [-55.8747, 51.5227],
                        [-55.8854, 51.5156],
                        [-55.8979, 51.5132],
                        [-55.8962, 51.4984],
                        [-55.8803, 51.4958],
                        [-55.8663, 51.496],
                        [-55.855, 51.5019],
                        [-55.8441, 51.5016],
                        [-55.8323, 51.4963],
                        [-55.8215, 51.5042],
                        [-55.8154, 51.4937],
                        [-55.8086, 51.4918],
                        [-55.7891, 51.4996],
                        [-55.7672, 51.4978],
                        [-55.757, 51.4997],
                        [-55.7364, 51.4994],
                        [-55.7153, 51.4872],
                        [-55.6815, 51.4828],
                        [-55.6778, 51.4751],
                        [-55.664, 51.4736],
                        [-55.6481, 51.4771],
                        [-55.6449, 51.4808],
                        [-55.6321, 51.4801],
                        [-55.6252, 51.4859],
                        [-55.6584, 51.485],
                        [-55.6657, 51.4821],
                        [-55.6847, 51.4919],
                        [-55.6706, 51.497],
                        [-55.6803, 51.5036],
                        [-55.702, 51.5014],
                        [-55.71, 51.5023],
                        [-55.711, 51.5147],
                        [-55.7087, 51.5274],
                        [-55.7168, 51.5391],
                        [-55.7283, 51.5364],
                        [-55.7362, 51.5398],
                        [-55.7455, 51.5532],
                        [-55.7548, 51.5551],
                        [-55.7619, 51.5639],
                        [-55.7523, 51.5798],
                        [-55.7403, 51.5883],
                        [-55.7333, 51.5892],
                        [-55.7326, 51.5808],
                        [-55.7401, 51.5696],
                        [-55.7454, 51.5555],
                        [-55.7326, 51.5556],
                        [-55.7284, 51.5614],
                        [-55.7188, 51.5621],
                        [-55.7097, 51.5678],
                        [-55.7056, 51.5829],
                        [-55.7019, 51.5868],
                        [-55.6914, 51.5864],
                        [-55.6862, 51.5909],
                        [-55.6662, 51.5994],
                        [-55.6444, 51.6139],
                        [-55.6272, 51.6148],
                        [-55.6191, 51.6062],
                        [-55.6381, 51.5987],
                        [-55.6521, 51.6008],
                        [-55.6628, 51.5907],
                        [-55.652, 51.5859],
                        [-55.6361, 51.5724],
                        [-55.6452, 51.5665],
                        [-55.6379, 51.563],
                        [-55.6127, 51.563],
                        [-55.6077, 51.5581],
                        [-55.5986, 51.562],
                        [-55.5971, 51.5737],
                        [-55.6056, 51.572],
                        [-55.6134, 51.578],
                        [-55.6019, 51.5804],
                        [-55.5937, 51.5779],
                        [-55.5727, 51.5894],
                        [-55.5598, 51.5905],
                        [-55.543, 51.595],
                        [-55.5376, 51.599],
                        [-55.5127, 51.6064],
                        [-55.5217, 51.5945],
                        [-55.4906, 51.5978],
                        [-55.4876, 51.5958],
                        [-55.4862, 51.58],
                        [-55.4764, 51.5871],
                        [-55.4778, 51.5937],
                        [-55.4698, 51.5992],
                        [-55.4673, 51.5884],
                        [-55.4581, 51.5876],
                        [-55.4577, 51.581],
                        [-55.4229, 51.5818],
                        [-55.4314, 51.575],
                        [-55.4272, 51.5621],
                        [-55.4344, 51.5474],
                        [-55.4453, 51.5453],
                        [-55.4472, 51.5549],
                        [-55.4576, 51.5456],
                        [-55.4701, 51.5506],
                        [-55.4707, 51.5558],
                        [-55.4628, 51.5611],
                        [-55.4868, 51.5603],
                        [-55.4825, 51.5532],
                        [-55.4748, 51.5478],
                        [-55.4668, 51.5374],
                        [-55.4654, 51.5284],
                        [-55.4585, 51.5213],
                        [-55.465, 51.5139],
                        [-55.4608, 51.5085],
                        [-55.4515, 51.5107],
                        [-55.445, 51.5081],
                        [-55.4527, 51.4953],
                        [-55.4592, 51.49],
                        [-55.4739, 51.4971],
                        [-55.4761, 51.5021],
                        [-55.4948, 51.5011],
                        [-55.5026, 51.4967],
                        [-55.5281, 51.4967],
                        [-55.526, 51.4897],
                        [-55.5104, 51.4876],
                        [-55.5034, 51.4908],
                        [-55.4988, 51.4776],
                        [-55.4903, 51.4725],
                        [-55.4696, 51.4699],
                        [-55.47, 51.4539],
                        [-55.477, 51.4535],
                        [-55.4739, 51.4361],
                        [-55.4904, 51.4297],
                        [-55.4884, 51.4084],
                        [-55.4844, 51.4031],
                        [-55.4933, 51.4001],
                        [-55.4905, 51.3925],
                        [-55.496, 51.3818],
                        [-55.5109, 51.3696],
                        [-55.5189, 51.3573],
                        [-55.5465, 51.3816],
                        [-55.5534, 51.3811],
                        [-55.5597, 51.3923],
                        [-55.5714, 51.3929],
                        [-55.5723, 51.3876],
                        [-55.5809, 51.3863],
                        [-55.5819, 51.3804],
                        [-55.567, 51.3709],
                        [-55.5632, 51.3601],
                        [-55.572, 51.3614],
                        [-55.587, 51.3738],
                        [-55.5931, 51.3695],
                        [-55.584, 51.3624],
                        [-55.5602, 51.3542],
                        [-55.5617, 51.3452],
                        [-55.5716, 51.3428],
                        [-55.5861, 51.3361],
                        [-55.6033, 51.3449],
                        [-55.625, 51.3433],
                        [-55.6192, 51.338],
                        [-55.6061, 51.3341],
                        [-55.6011, 51.3246],
                        [-55.6061, 51.3229],
                        [-55.6067, 51.3149],
                        [-55.6143, 51.3087],
                        [-55.5998, 51.3062],
                        [-55.6041, 51.2995],
                        [-55.6323, 51.3001],
                        [-55.6277, 51.3114],
                        [-55.6521, 51.316],
                        [-55.6639, 51.3251],
                        [-55.6775, 51.3207],
                        [-55.7201, 51.3265],
                        [-55.7325, 51.3242],
                        [-55.7497, 51.3263],
                        [-55.7555, 51.3329],
                        [-55.7565, 51.3439],
                        [-55.7952, 51.3457],
                        [-55.8126, 51.3491],
                        [-55.8491, 51.3431],
                        [-55.8651, 51.3425],
                        [-55.8768, 51.3393],
                        [-55.8834, 51.3316],
                        [-55.8942, 51.3322],
                        [-55.9175, 51.3378],
                        [-55.9207, 51.3487],
                        [-55.9354, 51.3597],
                        [-55.9568, 51.3646],
                        [-55.956, 51.3594],
                        [-55.9461, 51.3537],
                        [-55.9438, 51.3488],
                        [-55.9354, 51.3463],
                        [-55.9351, 51.3398],
                        [-55.9486, 51.3413],
                        [-55.9548, 51.3344],
                        [-55.9711, 51.3368],
                        [-55.9801, 51.3359],
                        [-55.9913, 51.3436],
                        [-56.0099, 51.3532],
                        [-56.0164, 51.3705],
                        [-56.0314, 51.3782],
                        [-56.041, 51.3761],
                        [-56.0381, 51.3569],
                        [-56.0537, 51.3599],
                        [-56.0577, 51.3702],
                        [-56.071, 51.3668],
                        [-56.0768, 51.3618],
                        [-56.0877, 51.3467],
                        [-56.1005, 51.3425],
                        [-56.0944, 51.3351],
                        [-56.1012, 51.3199],
                        [-56.0944, 51.3153],
                        [-56.0803, 51.3143],
                        [-56.0829, 51.3059],
                        [-56.0708, 51.3065],
                        [-56.0507, 51.301],
                        [-56.041, 51.3078],
                        [-56.0296, 51.2999],
                        [-56.0269, 51.2936],
                        [-56.0317, 51.2872],
                        [-56.0238, 51.2826],
                        [-56.0298, 51.2766],
                        [-56.0228, 51.2741],
                        [-56.0263, 51.2653],
                        [-56.034, 51.2653],
                        [-56.0583, 51.2563],
                        [-56.0372, 51.2463],
                        [-56.0259, 51.2479],
                        [-56.0223, 51.2411],
                        [-56.0281, 51.2368],
                        [-56.0458, 51.2299],
                        [-56.0703, 51.2281],
                        [-56.0741, 51.2203],
                        [-56.0387, 51.22],
                        [-56.0265, 51.2118],
                        [-56.0333, 51.2024],
                        [-56.0105, 51.1986],
                        [-56.005, 51.1897],
                        [-56.0207, 51.1776],
                        [-56.0242, 51.1671],
                        [-56.0412, 51.1572],
                        [-56.0572, 51.1556],
                        [-56.0671, 51.1428],
                        [-56.0486, 51.1496],
                        [-56.0403, 51.156],
                        [-56.0235, 51.1649],
                        [-56.0208, 51.1732],
                        [-56.0143, 51.176],
                        [-55.9987, 51.1747],
                        [-56.0005, 51.1652],
                        [-55.9686, 51.153],
                        [-55.9628, 51.1638],
                        [-55.9719, 51.1662],
                        [-55.9747, 51.1714],
                        [-55.9868, 51.1792],
                        [-55.9742, 51.182],
                        [-55.975, 51.1881],
                        [-55.9701, 51.193],
                        [-55.9766, 51.198],
                        [-55.9592, 51.201],
                        [-55.94, 51.1932],
                        [-55.9239, 51.1972],
                        [-55.9185, 51.2019],
                        [-55.9205, 51.207],
                        [-55.914, 51.2138],
                        [-55.8963, 51.2165],
                        [-55.8865, 51.2226],
                        [-55.8598, 51.2253],
                        [-55.8465, 51.2191],
                        [-55.8453, 51.2082],
                        [-55.8214, 51.2173],
                        [-55.8144, 51.2122],
                        [-55.7853, 51.2151],
                        [-55.7948, 51.2035],
                        [-55.7873, 51.2011],
                        [-55.7769, 51.204],
                        [-55.7573, 51.2004],
                        [-55.7476, 51.2008],
                        [-55.7358, 51.1952],
                        [-55.7399, 51.1911],
                        [-55.7213, 51.1883],
                        [-55.73, 51.1753],
                        [-55.7504, 51.1668],
                        [-55.7592, 51.1686],
                        [-55.7669, 51.1602],
                        [-55.7577, 51.1548],
                        [-55.7354, 51.1478],
                        [-55.7282, 51.132],
                        [-55.7322, 51.1215],
                        [-55.7448, 51.1053],
                        [-55.7414, 51.0999],
                        [-55.7297, 51.0946],
                        [-55.7334, 51.0876],
                        [-55.7458, 51.0832],
                        [-55.755, 51.0689],
                        [-55.7683, 51.0582],
                        [-55.7814, 51.0517],
                        [-55.7879, 51.0553],
                        [-55.7965, 51.0529],
                        [-55.8132, 51.0548],
                        [-55.8113, 51.0453],
                        [-55.8017, 51.0442],
                        [-55.8032, 51.032],
                        [-55.7852, 51.0405],
                        [-55.7797, 51.0396],
                        [-55.7949, 51.0243],
                        [-55.822, 51.0155],
                        [-55.817, 51.0075],
                        [-55.8289, 50.9956],
                        [-55.8279, 50.9858],
                        [-55.8333, 50.9802],
                        [-55.8539, 50.9693],
                        [-55.8528, 50.9583],
                        [-55.8435, 50.9615],
                        [-55.8244, 50.9619],
                        [-55.826, 50.9432],
                        [-55.8307, 50.9378],
                        [-55.8287, 50.9269],
                        [-55.8331, 50.9215],
                        [-55.851, 50.9217],
                        [-55.8624, 50.9307],
                        [-55.8667, 50.9369],
                        [-55.8656, 50.9452],
                        [-55.8709, 50.9502],
                        [-55.8784, 50.9395],
                        [-55.8801, 50.9328],
                        [-55.8904, 50.921],
                        [-55.887, 50.9119],
                        [-55.8941, 50.9038],
                        [-55.8736, 50.9061],
                        [-55.8609, 50.9105],
                        [-55.8561, 50.9087],
                        [-55.8652, 50.895],
                        [-55.8704, 50.8955],
                        [-55.8795, 50.8887],
                        [-55.883, 50.8751],
                        [-55.8887, 50.8641],
                        [-55.8946, 50.8616],
                        [-55.9014, 50.8814],
                        [-55.8953, 50.8901],
                        [-55.9015, 50.8952],
                        [-55.8991, 50.903],
                        [-55.9085, 50.9021],
                        [-55.9267, 50.8792],
                        [-55.9409, 50.8705],
                        [-55.9566, 50.8637],
                        [-55.9685, 50.8616],
                        [-55.9664, 50.8524],
                        [-55.973, 50.8446],
                        [-55.9806, 50.8413],
                        [-55.9771, 50.8354],
                        [-55.9818, 50.8312],
                        [-55.9817, 50.8238],
                        [-55.9912, 50.8121],
                        [-55.9971, 50.8093],
                        [-55.9992, 50.8022],
                        [-56.0168, 50.792],
                        [-56.0235, 50.7816],
                        [-56.0322, 50.7794],
                        [-56.0322, 50.7657],
                        [-56.0519, 50.7573],
                        [-56.06, 50.7554],
                        [-56.0635, 50.7499],
                        [-56.0721, 50.7472],
                        [-56.0693, 50.7425],
                        [-56.0803, 50.7306],
                        [-56.0896, 50.7243],
                        [-56.1034, 50.7227],
                        [-56.115, 50.7327],
                        [-56.1045, 50.7374],
                        [-56.1081, 50.7571],
                        [-56.1021, 50.7773],
                        [-56.1029, 50.7871],
                        [-56.094, 50.8114],
                        [-56.102, 50.8134],
                        [-56.106, 50.7985],
                        [-56.1115, 50.7903],
                        [-56.1084, 50.7871],
                        [-56.1137, 50.7789],
                        [-56.12, 50.7607],
                        [-56.1186, 50.7528],
                        [-56.1238, 50.7459],
                        [-56.1308, 50.7441],
                        [-56.1387, 50.7527],
                        [-56.1516, 50.7598],
                        [-56.1492, 50.7636],
                        [-56.1527, 50.7775],
                        [-56.145, 50.7886],
                        [-56.1504, 50.7979],
                        [-56.1467, 50.8011],
                        [-56.1457, 50.8119],
                        [-56.1351, 50.8332],
                        [-56.1407, 50.849],
                        [-56.126, 50.8582],
                        [-56.1233, 50.8682],
                        [-56.131, 50.8703],
                        [-56.1296, 50.8758],
                        [-56.121, 50.8796],
                        [-56.1203, 50.8894],
                        [-56.1267, 50.907],
                        [-56.1424, 50.8934],
                        [-56.1546, 50.9],
                        [-56.1748, 50.8838],
                        [-56.1827, 50.8839],
                        [-56.1905, 50.8769],
                        [-56.1819, 50.8682],
                        [-56.1874, 50.8558],
                        [-56.2027, 50.8557],
                        [-56.2145, 50.8639],
                        [-56.2203, 50.8737],
                        [-56.238, 50.8856],
                        [-56.2319, 50.8728],
                        [-56.2217, 50.8635],
                        [-56.2175, 50.8547],
                        [-56.2065, 50.8572],
                        [-56.2022, 50.8524],
                        [-56.2038, 50.8456],
                        [-56.2121, 50.8419],
                        [-56.2164, 50.8319],
                        [-56.2028, 50.8355],
                        [-56.2016, 50.8467],
                        [-56.1895, 50.8494],
                        [-56.1601, 50.8677],
                        [-56.1625, 50.8485],
                        [-56.169, 50.8441],
                        [-56.1559, 50.8368],
                        [-56.1626, 50.8236],
                        [-56.1586, 50.8193],
                        [-56.1574, 50.801],
                        [-56.1657, 50.7991],
                        [-56.1671, 50.7799],
                        [-56.1777, 50.7747],
                        [-56.1907, 50.777],
                        [-56.1782, 50.7603],
                        [-56.1794, 50.7494],
                        [-56.188, 50.7397],
                        [-56.1805, 50.7226],
                        [-56.1789, 50.7038],
                        [-56.1744, 50.6877],
                        [-56.1666, 50.6939],
                        [-56.1471, 50.6926],
                        [-56.1422, 50.6879],
                        [-56.1172, 50.6918],
                        [-56.1113, 50.6867],
                        [-56.1109, 50.6746],
                        [-56.1225, 50.6504],
                        [-56.1307, 50.647],
                        [-56.1316, 50.6374],
                        [-56.1489, 50.6318],
                        [-56.1537, 50.6334],
                        [-56.1696, 50.6251],
                        [-56.1687, 50.6189],
                        [-56.1874, 50.614],
                        [-56.1875, 50.6081],
                        [-56.2036, 50.5966],
                        [-56.2102, 50.6151],
                        [-56.2262, 50.6143],
                        [-56.2534, 50.6225],
                        [-56.2656, 50.6164],
                        [-56.233, 50.6061],
                        [-56.2225, 50.6044],
                        [-56.2225, 50.596],
                        [-56.2317, 50.5947],
                        [-56.227, 50.5878],
                        [-56.2279, 50.5764],
                        [-56.2445, 50.578],
                        [-56.2517, 50.574],
                        [-56.243, 50.5671],
                        [-56.2446, 50.5538],
                        [-56.2421, 50.5384],
                        [-56.2511, 50.5345],
                        [-56.2521, 50.5302],
                        [-56.2621, 50.5211],
                        [-56.2785, 50.525],
                        [-56.2968, 50.524],
                        [-56.3309, 50.5199],
                        [-56.3273, 50.5138],
                        [-56.302, 50.5103],
                        [-56.2783, 50.5131],
                        [-56.2777, 50.5002],
                        [-56.2997, 50.4912],
                        [-56.2962, 50.4827],
                        [-56.3018, 50.4798],
                        [-56.3136, 50.4813],
                        [-56.3138, 50.4689],
                        [-56.3181, 50.4646],
                        [-56.3397, 50.4618],
                        [-56.3303, 50.4562],
                        [-56.3316, 50.4491],
                        [-56.3518, 50.4386],
                        [-56.3591, 50.4331],
                        [-56.3674, 50.4404],
                        [-56.3744, 50.4371],
                        [-56.3673, 50.4282],
                        [-56.3672, 50.4233],
                        [-56.3816, 50.418],
                        [-56.3824, 50.4129],
                        [-56.399, 50.4074],
                        [-56.4023, 50.3959],
                        [-56.4138, 50.3926],
                        [-56.4065, 50.3874],
                        [-56.4102, 50.3783],
                        [-56.4246, 50.3714],
                        [-56.4312, 50.3773],
                        [-56.4495, 50.3863],
                        [-56.4642, 50.3835],
                        [-56.4623, 50.3934],
                        [-56.4719, 50.3979],
                        [-56.4698, 50.4069],
                        [-56.4773, 50.4114],
                        [-56.5008, 50.4062],
                        [-56.5013, 50.4033],
                        [-56.4807, 50.4033],
                        [-56.4831, 50.3983],
                        [-56.4794, 50.3914],
                        [-56.4813, 50.3843],
                        [-56.4918, 50.3816],
                        [-56.5147, 50.3796],
                        [-56.5184, 50.372],
                        [-56.4816, 50.3738],
                        [-56.4745, 50.3763],
                        [-56.4597, 50.3723],
                        [-56.4531, 50.3635],
                        [-56.4434, 50.3603],
                        [-56.4316, 50.3515],
                        [-56.4351, 50.3487],
                        [-56.434, 50.3324],
                        [-56.4495, 50.324],
                        [-56.4545, 50.3072],
                        [-56.4702, 50.2947],
                        [-56.4715, 50.2822],
                        [-56.4787, 50.2713],
                        [-56.4927, 50.2617],
                        [-56.5082, 50.2632],
                        [-56.5012, 50.2555],
                        [-56.5007, 50.248],
                        [-56.5104, 50.2405],
                        [-56.526, 50.2432],
                        [-56.5402, 50.2505],
                        [-56.5396, 50.2435],
                        [-56.5247, 50.2309],
                        [-56.5142, 50.2265],
                        [-56.5217, 50.2158],
                        [-56.5423, 50.2002],
                        [-56.5448, 50.1923],
                        [-56.557, 50.1808],
                        [-56.5628, 50.1786],
                        [-56.5711, 50.1658],
                        [-56.5931, 50.1492],
                        [-56.6077, 50.1561],
                        [-56.6038, 50.1446],
                        [-56.6042, 50.1376],
                        [-56.6142, 50.127],
                        [-56.6205, 50.1239],
                        [-56.6452, 50.128],
                        [-56.6533, 50.133],
                        [-56.6651, 50.1358],
                        [-56.6947, 50.1364],
                        [-56.7126, 50.1257],
                        [-56.7256, 50.1332],
                        [-56.7276, 50.1275],
                        [-56.7169, 50.1213],
                        [-56.7055, 50.1213],
                        [-56.7012, 50.1255],
                        [-56.6874, 50.1291],
                        [-56.6665, 50.1266],
                        [-56.6373, 50.1106],
                        [-56.6444, 50.1042],
                        [-56.6538, 50.1049],
                        [-56.6608, 50.0933],
                        [-56.6699, 50.0845],
                        [-56.6824, 50.0783],
                        [-56.6877, 50.072],
                        [-56.7056, 50.0583],
                        [-56.7096, 50.0512],
                        [-56.7228, 50.0436],
                        [-56.7368, 50.0331],
                        [-56.7549, 50.0238],
                        [-56.7632, 50.0227],
                        [-56.7584, 50.0152],
                        [-56.7586, 50.0078],
                        [-56.7679, 49.9987],
                        [-56.7616, 49.992],
                        [-56.7651, 49.984],
                        [-56.7733, 49.9741],
                        [-56.7726, 49.9601],
                        [-56.7801, 49.9482],
                        [-56.7831, 49.9391],
                        [-56.7891, 49.9334],
                        [-56.7835, 49.9257],
                        [-56.7753, 49.9384],
                        [-56.7659, 49.9444],
                        [-56.7515, 49.959],
                        [-56.7441, 49.9612],
                        [-56.7296, 49.954],
                        [-56.7337, 49.9395],
                        [-56.7315, 49.9342],
                        [-56.7405, 49.9177],
                        [-56.739, 49.9147],
                        [-56.7444, 49.8969],
                        [-56.7487, 49.8925],
                        [-56.7655, 49.8951],
                        [-56.76, 49.8856],
                        [-56.7477, 49.8876],
                        [-56.7445, 49.8817],
                        [-56.7475, 49.8744],
                        [-56.7588, 49.8659],
                        [-56.7702, 49.866],
                        [-56.7894, 49.8611],
                        [-56.7843, 49.8561],
                        [-56.7718, 49.8554],
                        [-56.7624, 49.8491],
                        [-56.769, 49.8453],
                        [-56.7771, 49.8347],
                        [-56.773, 49.83],
                        [-56.7947, 49.8184],
                        [-56.8116, 49.804],
                        [-56.8235, 49.8011],
                        [-56.8346, 49.7848],
                        [-56.8602, 49.7805],
                        [-56.871, 49.7835],
                        [-56.8747, 49.7709],
                        [-56.8879, 49.7701],
                        [-56.8947, 49.7667],
                        [-56.9022, 49.7696],
                        [-56.9091, 49.7565],
                        [-56.916, 49.755],
                        [-56.9177, 49.7491],
                        [-56.9105, 49.7443],
                        [-56.8999, 49.7491],
                        [-56.8905, 49.7491],
                        [-56.8823, 49.745],
                        [-56.8761, 49.7521],
                        [-56.87, 49.7493],
                        [-56.8496, 49.7504],
                        [-56.8275, 49.7495],
                        [-56.8254, 49.743],
                        [-56.83, 49.7324],
                        [-56.8045, 49.7333],
                        [-56.7985, 49.728],
                        [-56.7911, 49.7152],
                        [-56.8022, 49.7033],
                        [-56.8003, 49.6954],
                        [-56.7854, 49.6993],
                        [-56.7935, 49.6832],
                        [-56.8022, 49.6713],
                        [-56.8043, 49.6608],
                        [-56.8234, 49.6232],
                        [-56.82, 49.6174],
                        [-56.8288, 49.6009],
                        [-56.8346, 49.5967],
                        [-56.836, 49.5894],
                        [-56.8486, 49.5681],
                        [-56.8549, 49.5683],
                        [-56.872, 49.5436],
                        [-56.8673, 49.5408],
                        [-56.8541, 49.5448],
                        [-56.8463, 49.5543],
                        [-56.8369, 49.5589],
                        [-56.828, 49.5759],
                        [-56.8116, 49.5958],
                        [-56.7907, 49.6117],
                        [-56.7859, 49.6219],
                        [-56.7698, 49.6358],
                        [-56.7682, 49.6411],
                        [-56.7507, 49.6671],
                        [-56.7485, 49.6731],
                        [-56.7278, 49.6928],
                        [-56.716, 49.7092],
                        [-56.7093, 49.7101],
                        [-56.707, 49.7178],
                        [-56.691, 49.7301],
                        [-56.6788, 49.7365],
                        [-56.6787, 49.7421],
                        [-56.6712, 49.7493],
                        [-56.6604, 49.7474],
                        [-56.654, 49.7422],
                        [-56.6477, 49.7483],
                        [-56.6557, 49.7513],
                        [-56.6522, 49.7595],
                        [-56.6543, 49.7674],
                        [-56.6506, 49.7753],
                        [-56.6385, 49.7816],
                        [-56.6363, 49.7881],
                        [-56.6272, 49.7999],
                        [-56.6254, 49.8068],
                        [-56.619, 49.8148],
                        [-56.6016, 49.8267],
                        [-56.5752, 49.8308],
                        [-56.5599, 49.8319],
                        [-56.5509, 49.8296],
                        [-56.5302, 49.8201],
                        [-56.5292, 49.826],
                        [-56.5476, 49.834],
                        [-56.5749, 49.8366],
                        [-56.5849, 49.8427],
                        [-56.5817, 49.8471],
                        [-56.5615, 49.852],
                        [-56.5517, 49.8577],
                        [-56.545, 49.8583],
                        [-56.5312, 49.8681],
                        [-56.5177, 49.8685],
                        [-56.5178, 49.8726],
                        [-56.502, 49.8841],
                        [-56.4917, 49.8962],
                        [-56.4758, 49.9032],
                        [-56.4645, 49.9028],
                        [-56.4542, 49.8874],
                        [-56.4582, 49.8838],
                        [-56.4558, 49.8723],
                        [-56.443, 49.8624],
                        [-56.4433, 49.8755],
                        [-56.4525, 49.8956],
                        [-56.4383, 49.9035],
                        [-56.4356, 49.9103],
                        [-56.4279, 49.9135],
                        [-56.4249, 49.9201],
                        [-56.4148, 49.9226],
                        [-56.4103, 49.9176],
                        [-56.3947, 49.915],
                        [-56.3863, 49.9164],
                        [-56.3893, 49.9234],
                        [-56.4028, 49.9277],
                        [-56.3896, 49.9378],
                        [-56.4047, 49.9389],
                        [-56.4065, 49.9476],
                        [-56.4004, 49.9478],
                        [-56.3965, 49.9627],
                        [-56.3786, 49.9639],
                        [-56.3724, 49.9708],
                        [-56.3724, 49.9793],
                        [-56.3488, 49.9975],
                        [-56.3475, 50.0058],
                        [-56.3418, 50.012],
                        [-56.3423, 50.0189],
                        [-56.3336, 50.033],
                        [-56.3258, 50.0352],
                        [-56.3131, 50.0465],
                        [-56.3092, 50.0456],
                        [-56.2902, 50.0622],
                        [-56.2804, 50.0632],
                        [-56.2737, 50.0777],
                        [-56.268, 50.0799],
                        [-56.2426, 50.1017],
                        [-56.2429, 50.105],
                        [-56.2328, 50.113],
                        [-56.2276, 50.1222],
                        [-56.2165, 50.1244],
                        [-56.2102, 50.13],
                        [-56.1997, 50.1325],
                        [-56.1897, 50.138],
                        [-56.1878, 50.1434],
                        [-56.1758, 50.1495],
                        [-56.1682, 50.1561],
                        [-56.143, 50.1535],
                        [-56.1364, 50.148],
                        [-56.1434, 50.1451],
                        [-56.1408, 50.1397],
                        [-56.1241, 50.1332],
                        [-56.1132, 50.1257],
                        [-56.1252, 50.1079],
                        [-56.1073, 50.1002],
                        [-56.0916, 50.1015],
                        [-56.0926, 50.0884],
                        [-56.0896, 50.0804],
                        [-56.077, 50.0788],
                        [-56.0851, 50.072],
                        [-56.1061, 50.0706],
                        [-56.111, 50.063],
                        [-56.1204, 50.06],
                        [-56.1158, 50.0519],
                        [-56.1108, 50.0507],
                        [-56.114, 50.0417],
                        [-56.124, 50.0322],
                        [-56.1391, 50.0251],
                        [-56.1305, 50.0203],
                        [-56.1414, 50.0105],
                        [-56.136, 50.0043],
                        [-56.1533, 49.9922],
                        [-56.1468, 49.9829],
                        [-56.1501, 49.9775],
                        [-56.1643, 49.9687],
                        [-56.1668, 49.9549],
                        [-56.1786, 49.9425],
                        [-56.1984, 49.9359],
                        [-56.2112, 49.9269],
                        [-56.1987, 49.9271],
                        [-56.1682, 49.9377],
                        [-56.1524, 49.9444],
                        [-56.136, 49.9666],
                        [-56.1374, 49.9751],
                        [-56.1311, 49.9834],
                        [-56.1106, 49.989],
                        [-56.1113, 49.996],
                        [-56.096, 50.0052],
                        [-56.0943, 50.0118],
                        [-56.0719, 50.0187],
                        [-56.0581, 50.0192],
                        [-56.0504, 50.0222],
                        [-56.0409, 50.0323],
                        [-56.0272, 50.0348],
                        [-56.0149, 50.0289],
                        [-56.02, 50.0252],
                        [-56.0191, 50.0128],
                        [-56.0162, 50.0075],
                        [-56.0222, 49.9972],
                        [-56.0361, 49.9877],
                        [-56.0438, 49.976],
                        [-56.0305, 49.9775],
                        [-56.0252, 49.9808],
                        [-56.0129, 49.9823],
                        [-55.9997, 49.9886],
                        [-55.9934, 50.0019],
                        [-55.9802, 50.0174],
                        [-55.9834, 50.0295],
                        [-55.9777, 50.0323],
                        [-55.9678, 50.0281],
                        [-55.9553, 50.0363],
                        [-55.9482, 50.036],
                        [-55.9302, 50.0396],
                        [-55.9179, 50.0328],
                        [-55.9053, 50.0308],
                        [-55.8975, 50.0209],
                        [-55.8812, 50.02],
                        [-55.8818, 50.0077],
                        [-55.8624, 49.9961],
                        [-55.8591, 49.9896],
                        [-55.8717, 49.9806],
                        [-55.8629, 49.9762],
                        [-55.8472, 49.9769],
                        [-55.8519, 49.9704],
                        [-55.8488, 49.9663],
                        [-55.8388, 49.9642],
                        [-55.8327, 49.9594],
                        [-55.8197, 49.9599],
                        [-55.8213, 49.954],
                        [-55.8053, 49.9524],
                        [-55.7846, 49.955],
                        [-55.7777, 49.954],
                        [-55.7871, 49.9399],
                        [-55.7771, 49.9342],
                        [-55.7749, 49.928],
                        [-55.7786, 49.9174],
                        [-55.7694, 49.9174],
                        [-55.7623, 49.9213],
                        [-55.7562, 49.9328],
                        [-55.7445, 49.9333],
                        [-55.7328, 49.9291],
                        [-55.7223, 49.9399],
                        [-55.7118, 49.9411],
                        [-55.7059, 49.9477],
                        [-55.7093, 49.9647],
                        [-55.6992, 49.9699],
                        [-55.6827, 49.9726],
                        [-55.679, 49.9685],
                        [-55.6579, 49.9679],
                        [-55.6493, 49.9709],
                        [-55.6347, 49.97],
                        [-55.6208, 49.9647],
                        [-55.6113, 49.9641],
                        [-55.5981, 49.974],
                        [-55.5856, 49.9782],
                        [-55.5783, 49.9849],
                        [-55.5722, 49.9823],
                        [-55.561, 49.9867],
                        [-55.5494, 49.9956],
                        [-55.5362, 49.9999],
                        [-55.5329, 50.0058],
                        [-55.5127, 50.0042],
                        [-55.5086, 49.9989],
                        [-55.5115, 49.9914],
                        [-55.4913, 49.9752],
                        [-55.4953, 49.9657],
                        [-55.479, 49.9718],
                        [-55.4747, 49.9667],
                        [-55.4767, 49.9605],
                        [-55.4902, 49.9524],
                        [-55.4897, 49.9467],
                        [-55.4811, 49.9417],
                        [-55.4897, 49.9376],
                        [-55.4974, 49.9268],
                        [-55.5034, 49.9259],
                        [-55.5138, 49.9168],
                        [-55.5377, 49.9113],
                        [-55.5437, 49.9084],
                        [-55.5523, 49.9144],
                        [-55.5638, 49.9131],
                        [-55.5796, 49.9036],
                        [-55.5811, 49.8961],
                        [-55.6008, 49.8903],
                        [-55.603, 49.886],
                        [-55.6151, 49.8797],
                        [-55.6257, 49.8798],
                        [-55.6261, 49.8718],
                        [-55.6377, 49.8714],
                        [-55.6539, 49.8666],
                        [-55.6651, 49.8597],
                        [-55.6625, 49.8504],
                        [-55.6734, 49.8503],
                        [-55.6947, 49.853],
                        [-55.6988, 49.8483],
                        [-55.6904, 49.8417],
                        [-55.6815, 49.8396],
                        [-55.688, 49.8326],
                        [-55.6947, 49.8309],
                        [-55.7042, 49.8365],
                        [-55.7209, 49.8403],
                        [-55.7216, 49.8313],
                        [-55.7169, 49.8266],
                        [-55.7314, 49.8241],
                        [-55.7382, 49.8253],
                        [-55.7562, 49.8231],
                        [-55.754, 49.818],
                        [-55.7698, 49.8153],
                        [-55.7792, 49.8158],
                        [-55.7921, 49.8048],
                        [-55.8106, 49.8015],
                        [-55.8124, 49.7983],
                        [-55.8379, 49.7944],
                        [-55.8461, 49.7975],
                        [-55.8553, 49.7929],
                        [-55.8618, 49.7837],
                        [-55.8703, 49.7849],
                        [-55.8742, 49.7777],
                        [-55.8862, 49.7708],
                        [-55.8945, 49.7694],
                        [-55.9049, 49.7626],
                        [-55.9088, 49.7564],
                        [-55.9325, 49.7466],
                        [-55.9467, 49.7504],
                        [-55.9649, 49.7448],
                        [-55.9654, 49.739],
                        [-55.9907, 49.7323],
                        [-55.9922, 49.7422],
                        [-56.0109, 49.7495],
                        [-56.02, 49.7466],
                        [-56.0226, 49.7355],
                        [-56.0057, 49.734],
                        [-56.0047, 49.7267],
                        [-56.0307, 49.7118],
                        [-56.0599, 49.7066],
                        [-56.0971, 49.7029],
                        [-56.1273, 49.697],
                        [-56.1237, 49.6942],
                        [-56.1054, 49.695],
                        [-56.0851, 49.6979],
                        [-56.0842, 49.6947],
                        [-56.0549, 49.695],
                        [-56.0466, 49.6887],
                        [-56.0578, 49.6853],
                        [-56.0597, 49.6815],
                        [-56.1004, 49.6617],
                        [-56.1096, 49.6503],
                        [-56.1405, 49.6332],
                        [-56.1527, 49.628],
                        [-56.1691, 49.6245],
                        [-56.1801, 49.6176],
                        [-56.175, 49.6085],
                        [-56.175, 49.5969],
                        [-56.1776, 49.5821],
                        [-56.1863, 49.5801],
                        [-56.1872, 49.5741],
                        [-56.177, 49.5739],
                        [-56.1658, 49.5774],
                        [-56.1522, 49.5861],
                        [-56.1603, 49.59],
                        [-56.1484, 49.6034],
                        [-56.1437, 49.614],
                        [-56.1173, 49.6312],
                        [-56.1106, 49.6337],
                        [-56.0938, 49.646],
                        [-56.0839, 49.6572],
                        [-56.0747, 49.6626],
                        [-56.0496, 49.6706],
                        [-56.0376, 49.6817],
                        [-56.0294, 49.6842],
                        [-56.0211, 49.6827],
                        [-56.0076, 49.6886],
                        [-55.9986, 49.685],
                        [-55.9921, 49.6926],
                        [-55.983, 49.6986],
                        [-55.9788, 49.6947],
                        [-55.968, 49.693],
                        [-55.9532, 49.7018],
                        [-55.9427, 49.7004],
                        [-55.9314, 49.7073],
                        [-55.9232, 49.7026],
                        [-55.919, 49.7076],
                        [-55.8999, 49.7135],
                        [-55.9012, 49.7075],
                        [-55.9115, 49.6997],
                        [-55.9278, 49.6996],
                        [-55.9417, 49.6938],
                        [-55.9447, 49.6882],
                        [-55.9641, 49.6795],
                        [-55.9863, 49.676],
                        [-56.0075, 49.666],
                        [-56.0037, 49.6602],
                        [-55.9938, 49.6647],
                        [-55.9777, 49.6669],
                        [-55.9705, 49.6729],
                        [-55.9587, 49.6709],
                        [-55.9435, 49.6743],
                        [-55.9414, 49.6806],
                        [-55.9162, 49.693],
                        [-55.9046, 49.6938],
                        [-55.9021, 49.69],
                        [-55.9084, 49.6819],
                        [-55.9303, 49.6743],
                        [-55.9276, 49.6722],
                        [-55.8868, 49.6862],
                        [-55.8792, 49.6823],
                        [-55.9033, 49.671],
                        [-55.9103, 49.6705],
                        [-55.9197, 49.6609],
                        [-55.9319, 49.6522],
                        [-55.9553, 49.6444],
                        [-55.9513, 49.6402],
                        [-55.9423, 49.645],
                        [-55.9294, 49.647],
                        [-55.9183, 49.6567],
                        [-55.903, 49.6661],
                        [-55.8726, 49.6791],
                        [-55.8636, 49.6874],
                        [-55.8498, 49.6889],
                        [-55.856, 49.6764],
                        [-55.855, 49.6714],
                        [-55.8658, 49.6688],
                        [-55.8847, 49.6603],
                        [-55.8933, 49.6526],
                        [-55.8911, 49.6389],
                        [-55.9044, 49.6318],
                        [-55.9265, 49.6171],
                        [-55.9448, 49.6065],
                        [-55.9456, 49.6001],
                        [-55.9257, 49.6066],
                        [-55.9219, 49.6011],
                        [-55.9278, 49.5938],
                        [-55.9396, 49.5874],
                        [-55.9747, 49.5801],
                        [-55.9686, 49.5771],
                        [-55.948, 49.5771],
                        [-55.9398, 49.5839],
                        [-55.9258, 49.585],
                        [-55.9246, 49.5917],
                        [-55.9078, 49.6029],
                        [-55.901, 49.6043],
                        [-55.8972, 49.5982],
                        [-55.8796, 49.5947],
                        [-55.8805, 49.6001],
                        [-55.8722, 49.6122],
                        [-55.8618, 49.6185],
                        [-55.8319, 49.6171],
                        [-55.8247, 49.6108],
                        [-55.8274, 49.5978],
                        [-55.8335, 49.5945],
                        [-55.8413, 49.5825],
                        [-55.8422, 49.5726],
                        [-55.867, 49.5702],
                        [-55.8796, 49.5669],
                        [-55.896, 49.5582],
                        [-55.9105, 49.5634],
                        [-55.8916, 49.5808],
                        [-55.8803, 49.585],
                        [-55.8816, 49.5888],
                        [-55.9016, 49.5772],
                        [-55.9089, 49.5685],
                        [-55.9147, 49.5673],
                        [-55.9224, 49.5547],
                        [-55.9244, 49.5468],
                        [-55.9413, 49.5371],
                        [-55.9449, 49.5316],
                        [-55.9573, 49.5264],
                        [-55.9707, 49.5244],
                        [-55.9762, 49.5262],
                        [-56.0065, 49.5197],
                        [-56.0218, 49.5112],
                        [-56.0395, 49.509],
                        [-56.0549, 49.503],
                        [-56.0659, 49.5016],
                        [-56.0706, 49.4864],
                        [-56.0811, 49.494],
                        [-56.0869, 49.4948],
                        [-56.096, 49.4879],
                        [-56.089, 49.4822],
                        [-56.0811, 49.4824],
                        [-56.0772, 49.4762],
                        [-56.0937, 49.4653],
                        [-56.0951, 49.4596],
                        [-56.1035, 49.4556],
                        [-56.1167, 49.445],
                        [-56.1419, 49.4309],
                        [-56.1204, 49.4226],
                        [-56.1048, 49.433],
                        [-56.0861, 49.432],
                        [-56.0706, 49.4494],
                        [-56.0605, 49.4545],
                        [-56.0531, 49.4549],
                        [-56.0265, 49.4814],
                        [-56.0074, 49.4931],
                        [-56.0042, 49.4975],
                        [-55.9906, 49.4943],
                        [-55.9768, 49.4984],
                        [-55.9643, 49.5],
                        [-55.9577, 49.5047],
                        [-55.9422, 49.4998],
                        [-55.9371, 49.5009],
                        [-55.926, 49.5189],
                        [-55.9155, 49.5206],
                        [-55.9148, 49.5148],
                        [-55.9062, 49.5117],
                        [-55.8886, 49.5201],
                        [-55.89, 49.5071],
                        [-55.8731, 49.5127],
                        [-55.8574, 49.5145],
                        [-55.8577, 49.5227],
                        [-55.8502, 49.5248],
                        [-55.8493, 49.5311],
                        [-55.8365, 49.5291],
                        [-55.8303, 49.5246],
                        [-55.8203, 49.5223],
                        [-55.818, 49.5169],
                        [-55.807, 49.5167],
                        [-55.7953, 49.5083],
                        [-55.782, 49.512],
                        [-55.7815, 49.5054],
                        [-55.7911, 49.5038],
                        [-55.7975, 49.4971],
                        [-55.7984, 49.4899],
                        [-55.7776, 49.4914],
                        [-55.7547, 49.4836],
                        [-55.7314, 49.4804],
                        [-55.7323, 49.4727],
                        [-55.7486, 49.4713],
                        [-55.76, 49.4735],
                        [-55.7896, 49.4702],
                        [-55.7986, 49.4632],
                        [-55.7633, 49.4665],
                        [-55.8078, 49.4462],
                        [-55.8032, 49.442],
                        [-55.7805, 49.4477],
                        [-55.7703, 49.4535],
                        [-55.7563, 49.4544],
                        [-55.7421, 49.453],
                        [-55.7155, 49.4671],
                        [-55.7001, 49.468],
                        [-55.6898, 49.4614],
                        [-55.6824, 49.4676],
                        [-55.6696, 49.4706],
                        [-55.6748, 49.4602],
                        [-55.7108, 49.4484],
                        [-55.7161, 49.4447],
                        [-55.7182, 49.4373],
                        [-55.706, 49.4389],
                        [-55.7226, 49.4269],
                        [-55.7488, 49.4146],
                        [-55.7435, 49.4102],
                        [-55.7359, 49.417],
                        [-55.7257, 49.4215],
                        [-55.7007, 49.4246],
                        [-55.6951, 49.4171],
                        [-55.704, 49.4102],
                        [-55.6844, 49.4072],
                        [-55.6834, 49.3989],
                        [-55.6933, 49.392],
                        [-55.6969, 49.3817],
                        [-55.6837, 49.3803],
                        [-55.6665, 49.3868],
                        [-55.6574, 49.392],
                        [-55.6617, 49.4084],
                        [-55.6489, 49.4175],
                        [-55.6448, 49.4246],
                        [-55.6366, 49.4294],
                        [-55.6263, 49.4475],
                        [-55.6151, 49.46],
                        [-55.6043, 49.4655],
                        [-55.5958, 49.467],
                        [-55.589, 49.4807],
                        [-55.5797, 49.4841],
                        [-55.5776, 49.4888],
                        [-55.5645, 49.4859],
                        [-55.5497, 49.4899],
                        [-55.5493, 49.4842],
                        [-55.5364, 49.4831],
                        [-55.5524, 49.4732],
                        [-55.5438, 49.4683],
                        [-55.5653, 49.4517],
                        [-55.5667, 49.4443],
                        [-55.5785, 49.4304],
                        [-55.5908, 49.4267],
                        [-55.6035, 49.4158],
                        [-55.601, 49.4087],
                        [-55.5814, 49.4206],
                        [-55.5798, 49.4027],
                        [-55.5826, 49.3993],
                        [-55.5804, 49.3873],
                        [-55.5929, 49.3698],
                        [-55.5928, 49.358],
                        [-55.5837, 49.3588],
                        [-55.5757, 49.3727],
                        [-55.567, 49.3747],
                        [-55.559, 49.3893],
                        [-55.5618, 49.3953],
                        [-55.5548, 49.3977],
                        [-55.5557, 49.4105],
                        [-55.5538, 49.4198],
                        [-55.5385, 49.4391],
                        [-55.5263, 49.4393],
                        [-55.5195, 49.4438],
                        [-55.5087, 49.4449],
                        [-55.5101, 49.4591],
                        [-55.5151, 49.4634],
                        [-55.5146, 49.4708],
                        [-55.4974, 49.4767],
                        [-55.4994, 49.4599],
                        [-55.4876, 49.4635],
                        [-55.4811, 49.4617],
                        [-55.4895, 49.4533],
                        [-55.4773, 49.4533],
                        [-55.4729, 49.4691],
                        [-55.4676, 49.472],
                        [-55.4598, 49.4945],
                        [-55.441, 49.5024],
                        [-55.4311, 49.4996],
                        [-55.4074, 49.5065],
                        [-55.3964, 49.5033],
                        [-55.3896, 49.4866],
                        [-55.3796, 49.4947],
                        [-55.3653, 49.4965],
                        [-55.3742, 49.483],
                        [-55.3954, 49.4694],
                        [-55.4098, 49.4647],
                        [-55.4087, 49.4589],
                        [-55.4146, 49.4501],
                        [-55.4237, 49.4458],
                        [-55.4443, 49.4243],
                        [-55.4289, 49.422],
                        [-55.4115, 49.4347],
                        [-55.3969, 49.4423],
                        [-55.3766, 49.4501],
                        [-55.3778, 49.4528],
                        [-55.3648, 49.4587],
                        [-55.3626, 49.4672],
                        [-55.3532, 49.4706],
                        [-55.3415, 49.467],
                        [-55.3578, 49.4582],
                        [-55.3545, 49.4548],
                        [-55.3309, 49.4602],
                        [-55.3359, 49.4491],
                        [-55.3463, 49.4417],
                        [-55.3486, 49.4356],
                        [-55.3598, 49.4364],
                        [-55.3632, 49.4251],
                        [-55.3793, 49.4158],
                        [-55.3963, 49.4014],
                        [-55.4026, 49.4009],
                        [-55.4048, 49.3924],
                        [-55.4142, 49.3904],
                        [-55.4466, 49.3723],
                        [-55.4544, 49.3705],
                        [-55.4574, 49.3623],
                        [-55.4659, 49.3575],
                        [-55.4638, 49.3492],
                        [-55.4514, 49.3484],
                        [-55.439, 49.3598],
                        [-55.4351, 49.3686],
                        [-55.4143, 49.3741],
                        [-55.4056, 49.3706],
                        [-55.3932, 49.3595],
                        [-55.3951, 49.3434],
                        [-55.3987, 49.333],
                        [-55.3988, 49.3217],
                        [-55.3852, 49.3309],
                        [-55.3835, 49.3413],
                        [-55.3775, 49.3593],
                        [-55.3706, 49.3612],
                        [-55.3714, 49.3763],
                        [-55.3655, 49.3816],
                        [-55.369, 49.3865],
                        [-55.3644, 49.3974],
                        [-55.3459, 49.4085],
                        [-55.336, 49.408],
                        [-55.3444, 49.3991],
                        [-55.3474, 49.3813],
                        [-55.3336, 49.3731],
                        [-55.3329, 49.3918],
                        [-55.3309, 49.397],
                        [-55.3225, 49.3994],
                        [-55.318, 49.4081],
                        [-55.3016, 49.403],
                        [-55.3127, 49.3868],
                        [-55.3091, 49.3804],
                        [-55.3091, 49.3704],
                        [-55.322, 49.359],
                        [-55.3293, 49.3569],
                        [-55.3411, 49.3597],
                        [-55.356, 49.3485],
                        [-55.3446, 49.3291],
                        [-55.3378, 49.328],
                        [-55.3263, 49.3151],
                        [-55.3218, 49.3308],
                        [-55.3234, 49.3443],
                        [-55.315, 49.3515],
                        [-55.2937, 49.3483],
                        [-55.2907, 49.355],
                        [-55.2831, 49.3563],
                        [-55.2793, 49.3644],
                        [-55.2726, 49.3696],
                        [-55.2757, 49.3809],
                        [-55.2585, 49.3724],
                        [-55.2636, 49.3848],
                        [-55.2617, 49.3938],
                        [-55.2486, 49.404],
                        [-55.257, 49.4068],
                        [-55.2816, 49.4039],
                        [-55.2772, 49.4189],
                        [-55.2848, 49.4313],
                        [-55.2834, 49.4358],
                        [-55.2929, 49.4473],
                        [-55.285, 49.4668],
                        [-55.2732, 49.4692],
                        [-55.2815, 49.4742],
                        [-55.3014, 49.4789],
                        [-55.3033, 49.4873],
                        [-55.2981, 49.4891],
                        [-55.2869, 49.4826],
                        [-55.2838, 49.489],
                        [-55.2969, 49.4929],
                        [-55.31, 49.4996],
                        [-55.3222, 49.512],
                        [-55.3301, 49.5245],
                        [-55.3377, 49.5302],
                        [-55.3335, 49.5364],
                        [-55.322, 49.5326],
                        [-55.3117, 49.5346],
                        [-55.3092, 49.5289],
                        [-55.2958, 49.5237],
                        [-55.2986, 49.5336],
                        [-55.2916, 49.5354],
                        [-55.2853, 49.5443],
                        [-55.2761, 49.5444],
                        [-55.2581, 49.5392],
                        [-55.2513, 49.5417],
                        [-55.2389, 49.5397],
                        [-55.2368, 49.5337],
                        [-55.2466, 49.5275],
                        [-55.2495, 49.5223],
                        [-55.2671, 49.5228],
                        [-55.2569, 49.5149],
                        [-55.2414, 49.5146],
                        [-55.2408, 49.5102],
                        [-55.2267, 49.5062],
                        [-55.2327, 49.5247],
                        [-55.2203, 49.5296],
                        [-55.2213, 49.5369],
                        [-55.2119, 49.5415],
                        [-55.1952, 49.5447],
                        [-55.1739, 49.542],
                        [-55.1658, 49.5445],
                        [-55.1597, 49.5396],
                        [-55.1711, 49.5306],
                        [-55.1508, 49.5096],
                        [-55.1453, 49.5009],
                        [-55.1454, 49.4947],
                        [-55.1359, 49.4867],
                        [-55.1446, 49.4772],
                        [-55.1439, 49.4705],
                        [-55.1341, 49.4654],
                        [-55.1472, 49.462],
                        [-55.1493, 49.4514],
                        [-55.1659, 49.4401],
                        [-55.1726, 49.4383],
                        [-55.1816, 49.4261],
                        [-55.2019, 49.426],
                        [-55.1952, 49.4156],
                        [-55.1755, 49.4243],
                        [-55.1739, 49.4118],
                        [-55.1848, 49.4099],
                        [-55.1895, 49.4009],
                        [-55.2059, 49.3848],
                        [-55.2134, 49.3826],
                        [-55.2186, 49.3927],
                        [-55.225, 49.3952],
                        [-55.2074, 49.4107],
                        [-55.2102, 49.4134],
                        [-55.2308, 49.3981],
                        [-55.2334, 49.3759],
                        [-55.2309, 49.3696],
                        [-55.2378, 49.3655],
                        [-55.2394, 49.3545],
                        [-55.2485, 49.3431],
                        [-55.2507, 49.3369],
                        [-55.2429, 49.3259],
                        [-55.2365, 49.3249],
                        [-55.2404, 49.3137],
                        [-55.2498, 49.303],
                        [-55.2449, 49.2981],
                        [-55.2448, 49.2912],
                        [-55.2389, 49.2696],
                        [-55.2436, 49.2565],
                        [-55.2539, 49.2521],
                        [-55.2583, 49.247],
                        [-55.2812, 49.2348],
                        [-55.2978, 49.2292],
                        [-55.2993, 49.225],
                        [-55.3127, 49.2238],
                        [-55.3288, 49.2134],
                        [-55.3444, 49.1975],
                        [-55.3525, 49.1847],
                        [-55.3674, 49.1781],
                        [-55.3812, 49.17],
                        [-55.3802, 49.1644],
                        [-55.3903, 49.1622],
                        [-55.3849, 49.1517],
                        [-55.3768, 49.1512],
                        [-55.3548, 49.1583],
                        [-55.3381, 49.153],
                        [-55.3374, 49.1465],
                        [-55.3419, 49.1389],
                        [-55.3583, 49.1283],
                        [-55.3615, 49.1202],
                        [-55.3508, 49.1204],
                        [-55.3361, 49.1273],
                        [-55.3322, 49.1192],
                        [-55.3228, 49.1081],
                        [-55.3224, 49.1012],
                        [-55.3162, 49.0943],
                        [-55.3244, 49.0882],
                        [-55.3204, 49.0785],
                        [-55.3072, 49.0724],
                        [-55.2944, 49.0751],
                        [-55.2826, 49.0829],
                        [-55.2621, 49.091],
                        [-55.2495, 49.0985],
                        [-55.2099, 49.1149],
                        [-55.1944, 49.1235],
                        [-55.2005, 49.1252],
                        [-55.2291, 49.1183],
                        [-55.2551, 49.1076],
                        [-55.2765, 49.0934],
                        [-55.2906, 49.099],
                        [-55.2986, 49.1099],
                        [-55.3022, 49.1222],
                        [-55.3013, 49.1391],
                        [-55.296, 49.1562],
                        [-55.2925, 49.1603],
                        [-55.2601, 49.1811],
                        [-55.2667, 49.1876],
                        [-55.2763, 49.1847],
                        [-55.2856, 49.1734],
                        [-55.2932, 49.1678],
                        [-55.311, 49.1704],
                        [-55.3107, 49.1896],
                        [-55.3003, 49.1985],
                        [-55.2951, 49.2101],
                        [-55.2833, 49.2085],
                        [-55.2792, 49.1959],
                        [-55.2732, 49.1967],
                        [-55.2648, 49.2076],
                        [-55.2515, 49.2176],
                        [-55.238, 49.2233],
                        [-55.2201, 49.2379],
                        [-55.2058, 49.2405],
                        [-55.19, 49.2517],
                        [-55.1922, 49.2563],
                        [-55.1674, 49.2619],
                        [-55.1571, 49.258],
                        [-55.1513, 49.2655],
                        [-55.1314, 49.2736],
                        [-55.1196, 49.2763],
                        [-55.115, 49.2686],
                        [-55.1055, 49.2662],
                        [-55.1028, 49.2725],
                        [-55.1015, 49.2861],
                        [-55.091, 49.2866],
                        [-55.0855, 49.2829],
                        [-55.0737, 49.2831],
                        [-55.0748, 49.2894],
                        [-55.0704, 49.3004],
                        [-55.0768, 49.3088],
                        [-55.0751, 49.3224],
                        [-55.0769, 49.3273],
                        [-55.0861, 49.3265],
                        [-55.1002, 49.3421],
                        [-55.0995, 49.3592],
                        [-55.0861, 49.364],
                        [-55.0806, 49.3616],
                        [-55.0804, 49.355],
                        [-55.0738, 49.3511],
                        [-55.0675, 49.3393],
                        [-55.074, 49.3366],
                        [-55.0701, 49.3308],
                        [-55.0562, 49.3289],
                        [-55.0558, 49.3203],
                        [-55.0413, 49.3231],
                        [-55.0366, 49.3352],
                        [-55.0395, 49.3437],
                        [-55.0329, 49.3501],
                        [-55.0262, 49.3507],
                        [-55.0304, 49.335],
                        [-55.0367, 49.3233],
                        [-55.0185, 49.3007],
                        [-55.0201, 49.2941],
                        [-55.0122, 49.2798],
                        [-55.0188, 49.2718],
                        [-55.028, 49.2686],
                        [-55.0312, 49.2604],
                        [-55.0373, 49.2549],
                        [-55.0486, 49.2504],
                        [-55.058, 49.2415],
                        [-55.0584, 49.2279],
                        [-55.0528, 49.22],
                        [-55.0436, 49.2182],
                        [-55.0364, 49.2274],
                        [-55.0342, 49.2372],
                        [-55.0365, 49.2441],
                        [-55.0111, 49.2535],
                        [-54.9952, 49.2636],
                        [-54.9927, 49.2701],
                        [-54.9938, 49.2796],
                        [-54.9781, 49.3004],
                        [-54.9634, 49.3094],
                        [-54.9499, 49.3057],
                        [-54.9388, 49.3086],
                        [-54.9329, 49.3026],
                        [-54.9337, 49.2827],
                        [-54.9243, 49.2799],
                        [-54.9149, 49.2821],
                        [-54.9071, 49.2871],
                        [-54.8934, 49.2908],
                        [-54.8897, 49.2949],
                        [-54.8904, 49.3108],
                        [-54.8833, 49.3193],
                        [-54.8865, 49.3241],
                        [-54.874, 49.329],
                        [-54.8707, 49.3332],
                        [-54.8745, 49.3412],
                        [-54.8924, 49.3465],
                        [-54.9033, 49.3427],
                        [-54.9094, 49.3494],
                        [-54.904, 49.3579],
                        [-54.9159, 49.3621],
                        [-54.9071, 49.3683],
                        [-54.8972, 49.3836],
                        [-54.8845, 49.39],
                        [-54.8779, 49.4063],
                        [-54.8698, 49.407],
                        [-54.8594, 49.4157],
                        [-54.8544, 49.4134],
                        [-54.8569, 49.4043],
                        [-54.8464, 49.4058],
                        [-54.8395, 49.4001],
                        [-54.8511, 49.3907],
                        [-54.8589, 49.3902],
                        [-54.8587, 49.3793],
                        [-54.8652, 49.3552],
                        [-54.8625, 49.34],
                        [-54.8565, 49.3343],
                        [-54.8601, 49.329],
                        [-54.8466, 49.3237],
                        [-54.8458, 49.3175],
                        [-54.8522, 49.312],
                        [-54.8556, 49.2967],
                        [-54.8452, 49.2864],
                        [-54.8434, 49.2796],
                        [-54.8504, 49.2718],
                        [-54.8433, 49.2693],
                        [-54.8291, 49.2814],
                        [-54.8216, 49.2924],
                        [-54.8032, 49.2982],
                        [-54.7969, 49.3105],
                        [-54.803, 49.323],
                        [-54.8083, 49.3259],
                        [-54.8071, 49.3324],
                        [-54.7985, 49.3418],
                        [-54.7968, 49.3559],
                        [-54.785, 49.3607],
                        [-54.7775, 49.3538],
                        [-54.7656, 49.3491],
                        [-54.762, 49.3301],
                        [-54.7661, 49.3178],
                        [-54.7561, 49.3202],
                        [-54.7502, 49.3311],
                        [-54.742, 49.3351],
                        [-54.7378, 49.3441],
                        [-54.747, 49.3513],
                        [-54.7359, 49.3602],
                        [-54.7268, 49.3636],
                        [-54.7263, 49.3768],
                        [-54.7029, 49.3707],
                        [-54.6971, 49.3744],
                        [-54.6957, 49.3809],
                        [-54.6877, 49.3869],
                        [-54.685, 49.394],
                        [-54.6715, 49.3981],
                        [-54.6707, 49.4153],
                        [-54.6627, 49.419],
                        [-54.6577, 49.4301],
                        [-54.6668, 49.4285],
                        [-54.6809, 49.43],
                        [-54.6821, 49.4356],
                        [-54.6738, 49.4425],
                        [-54.6649, 49.4397],
                        [-54.6596, 49.4434],
                        [-54.6665, 49.4503],
                        [-54.646, 49.4496],
                        [-54.6417, 49.4551],
                        [-54.6307, 49.4616],
                        [-54.6251, 49.4725],
                        [-54.616, 49.4781],
                        [-54.605, 49.4909],
                        [-54.5989, 49.4944],
                        [-54.5808, 49.5159],
                        [-54.5709, 49.516],
                        [-54.571, 49.5236],
                        [-54.5583, 49.5331],
                        [-54.5552, 49.5403],
                        [-54.5456, 49.5451],
                        [-54.5354, 49.5462],
                        [-54.5239, 49.5438],
                        [-54.5025, 49.5579],
                        [-54.4763, 49.5687],
                        [-54.4672, 49.5651],
                        [-54.4823, 49.5511],
                        [-54.4996, 49.5457],
                        [-54.5061, 49.5485],
                        [-54.5132, 49.5453],
                        [-54.5145, 49.5391],
                        [-54.4976, 49.5441],
                        [-54.4927, 49.5426],
                        [-54.4793, 49.5512],
                        [-54.4661, 49.5496],
                        [-54.4618, 49.5447],
                        [-54.4668, 49.5353],
                        [-54.4732, 49.5303],
                        [-54.4879, 49.5301],
                        [-54.5, 49.5156],
                        [-54.5151, 49.5074],
                        [-54.5196, 49.4988],
                        [-54.5148, 49.4939],
                        [-54.5189, 49.4855],
                        [-54.5359, 49.4646],
                        [-54.5537, 49.4485],
                        [-54.5505, 49.4432],
                        [-54.5418, 49.4439],
                        [-54.5346, 49.4542],
                        [-54.517, 49.4613],
                        [-54.5096, 49.4687],
                        [-54.494, 49.4727],
                        [-54.4864, 49.4721],
                        [-54.4615, 49.4765],
                        [-54.4505, 49.4817],
                        [-54.4468, 49.4754],
                        [-54.4521, 49.4658],
                        [-54.4734, 49.4626],
                        [-54.4812, 49.4483],
                        [-54.4735, 49.4462],
                        [-54.4731, 49.437],
                        [-54.4649, 49.4276],
                        [-54.4693, 49.4143],
                        [-54.4782, 49.4034],
                        [-54.4898, 49.4016],
                        [-54.4977, 49.3894],
                        [-54.496, 49.3773],
                        [-54.5072, 49.3678],
                        [-54.4925, 49.3624],
                        [-54.4924, 49.3494],
                        [-54.4748, 49.3452],
                        [-54.4863, 49.3377],
                        [-54.4734, 49.334],
                        [-54.4833, 49.3216],
                        [-54.4831, 49.3143],
                        [-54.4899, 49.304],
                        [-54.4944, 49.2925],
                        [-54.5042, 49.2811],
                        [-54.5064, 49.2624],
                        [-54.4921, 49.2653],
                        [-54.4933, 49.2734],
                        [-54.4879, 49.2787],
                        [-54.4882, 49.285],
                        [-54.4829, 49.2895],
                        [-54.4856, 49.2944],
                        [-54.4739, 49.302],
                        [-54.4648, 49.303],
                        [-54.4486, 49.3168],
                        [-54.4368, 49.3173],
                        [-54.4279, 49.3204],
                        [-54.417, 49.3278],
                        [-54.4226, 49.3364],
                        [-54.4354, 49.3364],
                        [-54.4225, 49.3483],
                        [-54.4238, 49.353],
                        [-54.4349, 49.3574],
                        [-54.435, 49.3637],
                        [-54.4247, 49.3723],
                        [-54.4235, 49.3829],
                        [-54.4256, 49.3902],
                        [-54.4169, 49.3932],
                        [-54.4131, 49.4011],
                        [-54.3927, 49.4134],
                        [-54.3808, 49.4226],
                        [-54.3771, 49.439],
                        [-54.3665, 49.4392],
                        [-54.3567, 49.4288],
                        [-54.3191, 49.4411],
                        [-54.3141, 49.4378],
                        [-54.3155, 49.4251],
                        [-54.3215, 49.4224],
                        [-54.3109, 49.4131],
                        [-54.2995, 49.4152],
                        [-54.2905, 49.4274],
                        [-54.2807, 49.4223],
                        [-54.2798, 49.4146],
                        [-54.2867, 49.4029],
                        [-54.2863, 49.3904],
                        [-54.2764, 49.3998],
                        [-54.267, 49.3979],
                        [-54.2582, 49.4046],
                        [-54.2499, 49.4147],
                        [-54.2353, 49.4179],
                        [-54.2424, 49.4055],
                        [-54.2533, 49.3969],
                        [-54.2557, 49.3845],
                        [-54.2513, 49.3801],
                        [-54.2581, 49.375],
                        [-54.2529, 49.3696],
                        [-54.2406, 49.3708],
                        [-54.2411, 49.3804],
                        [-54.2337, 49.3932],
                        [-54.2341, 49.4021],
                        [-54.2183, 49.4111],
                        [-54.2018, 49.4034],
                        [-54.1981, 49.3966],
                        [-54.2043, 49.3933],
                        [-54.2015, 49.3736],
                        [-54.1947, 49.3806],
                        [-54.1817, 49.3834],
                        [-54.1823, 49.3885],
                        [-54.1752, 49.4016],
                        [-54.1759, 49.4106],
                        [-54.1723, 49.4236],
                        [-54.1767, 49.427],
                        [-54.1723, 49.4385],
                        [-54.1656, 49.4432],
                        [-54.1642, 49.456],
                        [-54.1475, 49.4556],
                        [-54.1119, 49.4643],
                        [-54.1018, 49.4586],
                        [-54.086, 49.4593],
                        [-54.0816, 49.4616],
                        [-54.0828, 49.4692],
                        [-54.0789, 49.4757],
                        [-54.069, 49.4816],
                        [-54.0561, 49.4814],
                        [-54.0596, 49.4729],
                        [-54.0537, 49.4696],
                        [-54.024, 49.4687],
                        [-54.0059, 49.4723],
                        [-54.0082, 49.4589],
                        [-54.017, 49.4578],
                        [-54.0295, 49.4509],
                        [-54.0254, 49.4444],
                        [-54.0333, 49.4405],
                        [-54.0489, 49.4376],
                        [-54.0472, 49.4295],
                        [-54.0277, 49.4344],
                        [-54.0055, 49.445],
                        [-53.9895, 49.4464],
                        [-53.969, 49.4575],
                        [-53.9532, 49.4529],
                        [-53.9411, 49.4584],
                        [-53.9384, 49.448],
                        [-53.9313, 49.4447],
                        [-53.9029, 49.4388],
                        [-53.8947, 49.4355],
                        [-53.8882, 49.4274],
                        [-53.8794, 49.4247],
                        [-53.87, 49.4143],
                        [-53.8539, 49.412],
                        [-53.8419, 49.4059],
                        [-53.8318, 49.4063],
                        [-53.8181, 49.4022],
                        [-53.8079, 49.3952],
                        [-53.7854, 49.3983],
                        [-53.7667, 49.3954],
                        [-53.7446, 49.3867],
                        [-53.7326, 49.3757],
                        [-53.7142, 49.3751],
                        [-53.6808, 49.3607],
                        [-53.6819, 49.3546],
                        [-53.6981, 49.3464],
                        [-53.7032, 49.335],
                        [-53.6938, 49.3256],
                        [-53.6823, 49.3268],
                        [-53.6695, 49.325],
                        [-53.6555, 49.3174],
                        [-53.6456, 49.3184],
                        [-53.6317, 49.3148],
                        [-53.6135, 49.3179],
                        [-53.6016, 49.3029],
                        [-53.5867, 49.3006],
                        [-53.571, 49.2951],
                        [-53.5647, 49.2813],
                        [-53.5562, 49.2782],
                        [-53.5459, 49.2805],
                        [-53.5328, 49.2761],
                        [-53.5062, 49.2829],
                        [-53.5059, 49.274],
                        [-53.4996, 49.2694],
                        [-53.492, 49.2558],
                        [-53.4684, 49.2544],
                        [-53.4712, 49.2452],
                        [-53.4803, 49.2445],
                        [-53.4828, 49.2362],
                        [-53.4729, 49.23],
                        [-53.4766, 49.2261],
                        [-53.4865, 49.2298],
                        [-53.5018, 49.2246],
                        [-53.514, 49.2253],
                        [-53.5292, 49.2209],
                        [-53.521, 49.2109],
                        [-53.5197, 49.2035],
                        [-53.5418, 49.1987],
                        [-53.5458, 49.1922],
                        [-53.5544, 49.1895],
                        [-53.5493, 49.1817],
                        [-53.5564, 49.1773],
                        [-53.5501, 49.1714],
                        [-53.5527, 49.1629],
                        [-53.5487, 49.1482],
                        [-53.5608, 49.1439],
                        [-53.5809, 49.1397],
                        [-53.5805, 49.1326],
                        [-53.5722, 49.1324],
                        [-53.5805, 49.1233],
                        [-53.603, 49.121],
                        [-53.6092, 49.1224],
                        [-53.6257, 49.1334],
                        [-53.6387, 49.135],
                        [-53.6435, 49.1411],
                        [-53.6536, 49.131],
                        [-53.6355, 49.1295],
                        [-53.6231, 49.1235],
                        [-53.6223, 49.1162],
                        [-53.6371, 49.1142],
                        [-53.6479, 49.1091],
                        [-53.6683, 49.1052],
                        [-53.6843, 49.1045],
                        [-53.6963, 49.101],
                        [-53.701, 49.0869],
                        [-53.6825, 49.0845],
                        [-53.6796, 49.0922],
                        [-53.6838, 49.0951],
                        [-53.6831, 49.1036],
                        [-53.6694, 49.1027],
                        [-53.6527, 49.1055],
                        [-53.6291, 49.106],
                        [-53.6336, 49.1125],
                        [-53.6116, 49.115],
                        [-53.6097, 49.1071],
                        [-53.6007, 49.0866],
                        [-53.6162, 49.0845],
                        [-53.6057, 49.0788],
                        [-53.5998, 49.0714],
                        [-53.5956, 49.0547],
                        [-53.599, 49.05],
                        [-53.6081, 49.0467],
                        [-53.6105, 49.0386],
                        [-53.625, 49.0344],
                        [-53.6645, 49.032],
                        [-53.6768, 49.0344],
                        [-53.682, 49.0313],
                        [-53.695, 49.034],
                        [-53.7074, 49.0306],
                        [-53.7368, 49.0285],
                        [-53.7403, 49.0302],
                        [-53.7692, 49.0328],
                        [-53.7819, 49.0243],
                        [-53.8013, 49.0269],
                        [-53.8202, 49.024],
                        [-53.8396, 49.0259],
                        [-53.8692, 49.0391],
                        [-53.8763, 49.0349],
                        [-53.8609, 49.0249],
                        [-53.8603, 49.0153],
                        [-53.816, 49.016],
                        [-53.7815, 49.0144],
                        [-53.7604, 49.0151],
                        [-53.7474, 49.0087],
                        [-53.7575, 49.0054],
                        [-53.7677, 49.0075],
                        [-53.7691, 49.0007],
                        [-53.7785, 48.9894],
                        [-53.7985, 48.9856],
                        [-53.8061, 48.9882],
                        [-53.8186, 48.9782],
                        [-53.8242, 48.985],
                        [-53.8515, 48.975],
                        [-53.8645, 48.9835],
                        [-53.875, 48.977],
                        [-53.884, 48.9758],
                        [-53.8979, 48.9906],
                        [-53.9047, 48.9879],
                        [-53.9063, 48.9819],
                        [-53.916, 48.9762],
                        [-53.9141, 48.9705],
                        [-53.8959, 48.9702],
                        [-53.8896, 48.9684],
                        [-53.8644, 48.9711],
                        [-53.8565, 48.9623],
                        [-53.8668, 48.956],
                        [-53.8426, 48.9503],
                        [-53.8329, 48.9505],
                        [-53.818, 48.9458],
                        [-53.815, 48.9361],
                        [-53.8567, 48.9179],
                        [-53.8719, 48.9192],
                        [-53.879, 48.9154],
                        [-53.9026, 48.9152],
                        [-53.9111, 48.912],
                        [-53.9344, 48.9148],
                        [-53.9541, 48.9133],
                        [-53.9621, 48.9057],
                        [-53.9851, 48.9017],
                        [-54.0039, 48.9038],
                        [-53.9986, 48.8906],
                        [-53.9873, 48.8864],
                        [-53.9785, 48.8906],
                        [-53.9675, 48.889],
                        [-53.9619, 48.8944],
                        [-53.9505, 48.8952],
                        [-53.9393, 48.9016],
                        [-53.9166, 48.9024],
                        [-53.9052, 48.8961],
                        [-53.9105, 48.8883],
                        [-53.9259, 48.8895],
                        [-53.9413, 48.8846],
                        [-53.9632, 48.8824],
                        [-53.9596, 48.8771],
                        [-53.9681, 48.8713],
                        [-53.988, 48.8626],
                        [-53.9988, 48.8614],
                        [-54.0089, 48.857],
                        [-53.9935, 48.8456],
                        [-53.9808, 48.8435],
                        [-53.9881, 48.8368],
                        [-53.9925, 48.8282],
                        [-53.9983, 48.8241],
                        [-54.0151, 48.8238],
                        [-54.0163, 48.8318],
                        [-54.0074, 48.8416],
                        [-54.0164, 48.8421],
                        [-54.0222, 48.836],
                        [-54.0404, 48.8311],
                        [-54.0706, 48.8305],
                        [-54.0846, 48.8322],
                        [-54.0909, 48.824],
                        [-54.1109, 48.8158],
                        [-54.1285, 48.8172],
                        [-54.1361, 48.8268],
                        [-54.1553, 48.8249],
                        [-54.1706, 48.8108],
                        [-54.1806, 48.8065],
                        [-54.2077, 48.8054],
                        [-54.1933, 48.7958],
                        [-54.1898, 48.7887],
                        [-54.2068, 48.7891],
                        [-54.2105, 48.7794],
                        [-54.22, 48.7661],
                        [-54.2077, 48.763],
                        [-54.2033, 48.7589],
                        [-54.1802, 48.7627],
                        [-54.1702, 48.7669],
                        [-54.1699, 48.7729],
                        [-54.16, 48.7811],
                        [-54.1406, 48.7857],
                        [-54.125, 48.7925],
                        [-54.1043, 48.7967],
                        [-54.0889, 48.796],
                        [-54.08, 48.7987],
                        [-54.0582, 48.8123],
                        [-54.0461, 48.8136],
                        [-54.0292, 48.8112],
                        [-54.0163, 48.815],
                        [-54.0056, 48.8098],
                        [-53.9959, 48.8104],
                        [-54.0107, 48.7936],
                        [-54.0135, 48.7803],
                        [-54.0222, 48.7752],
                        [-54.0186, 48.7678],
                        [-54.0119, 48.7659],
                        [-54.0037, 48.7711],
                        [-53.9767, 48.7698],
                        [-53.962, 48.7788],
                        [-53.9772, 48.7778],
                        [-53.9815, 48.782],
                        [-53.9661, 48.7952],
                        [-53.9748, 48.7967],
                        [-53.9911, 48.7854],
                        [-54.0104, 48.7784],
                        [-54.0052, 48.7881],
                        [-54.0065, 48.7932],
                        [-53.9883, 48.8119],
                        [-53.9711, 48.8251],
                        [-53.9568, 48.8317],
                        [-53.9451, 48.8419],
                        [-53.9219, 48.8397],
                        [-53.916, 48.8445],
                        [-53.9034, 48.8424],
                        [-53.8845, 48.8314],
                        [-53.8732, 48.8292],
                        [-53.8623, 48.8375],
                        [-53.8491, 48.836],
                        [-53.8392, 48.8247],
                        [-53.8267, 48.828],
                        [-53.8198, 48.8184],
                        [-53.8247, 48.8124],
                        [-53.823, 48.8049],
                        [-53.8323, 48.8061],
                        [-53.8431, 48.8035],
                        [-53.8586, 48.7952],
                        [-53.8692, 48.7982],
                        [-53.8838, 48.7895],
                        [-53.8931, 48.7924],
                        [-53.9257, 48.7958],
                        [-53.9328, 48.8],
                        [-53.9389, 48.7977],
                        [-53.9457, 48.7885],
                        [-53.929, 48.7923],
                        [-53.9233, 48.7918],
                        [-53.9205, 48.7838],
                        [-53.9082, 48.7807],
                        [-53.9232, 48.7643],
                        [-53.9021, 48.7622],
                        [-53.8988, 48.7688],
                        [-53.8885, 48.7712],
                        [-53.872, 48.7798],
                        [-53.8645, 48.7788],
                        [-53.8696, 48.7674],
                        [-53.8825, 48.7532],
                        [-53.89, 48.748],
                        [-53.9092, 48.7413],
                        [-53.9135, 48.7327],
                        [-53.9274, 48.7374],
                        [-53.9361, 48.7345],
                        [-53.9446, 48.7392],
                        [-53.9607, 48.7438],
                        [-53.9662, 48.7491],
                        [-53.984, 48.75],
                        [-54.0051, 48.7461],
                        [-54.0363, 48.7446],
                        [-54.0389, 48.7378],
                        [-54.0294, 48.7329],
                        [-54.0145, 48.7369],
                        [-54.0044, 48.7346],
                        [-53.9919, 48.7394],
                        [-53.9891, 48.7257],
                        [-53.9783, 48.7284],
                        [-53.9567, 48.7191],
                        [-53.9567, 48.7134],
                        [-53.9644, 48.7031],
                        [-53.9791, 48.7007],
                        [-54.0016, 48.7032],
                        [-54.0188, 48.6957],
                        [-54.0272, 48.6845],
                        [-54.0374, 48.6781],
                        [-54.0243, 48.6734],
                        [-54.015, 48.6743],
                        [-54.0002, 48.6719],
                        [-53.9958, 48.681],
                        [-53.9846, 48.6862],
                        [-53.9671, 48.6875],
                        [-53.9592, 48.6913],
                        [-53.9585, 48.7042],
                        [-53.9505, 48.7102],
                        [-53.9414, 48.7095],
                        [-53.9506, 48.6956],
                        [-53.9558, 48.692],
                        [-53.9464, 48.6846],
                        [-53.9349, 48.6896],
                        [-53.9347, 48.6958],
                        [-53.9267, 48.7032],
                        [-53.9205, 48.7034],
                        [-53.8993, 48.7124],
                        [-53.894, 48.7209],
                        [-53.8816, 48.7335],
                        [-53.8587, 48.7507],
                        [-53.8512, 48.754],
                        [-53.8416, 48.7508],
                        [-53.8543, 48.7359],
                        [-53.8639, 48.7316],
                        [-53.8663, 48.7252],
                        [-53.8784, 48.7097],
                        [-53.8728, 48.698],
                        [-53.8634, 48.6979],
                        [-53.8657, 48.7049],
                        [-53.8529, 48.7062],
                        [-53.837, 48.7017],
                        [-53.8058, 48.7173],
                        [-53.7948, 48.7197],
                        [-53.7713, 48.7171],
                        [-53.7606, 48.72],
                        [-53.7551, 48.7153],
                        [-53.7562, 48.7054],
                        [-53.7625, 48.6983],
                        [-53.7603, 48.6949],
                        [-53.7429, 48.6939],
                        [-53.7357, 48.6859],
                        [-53.7255, 48.6837],
                        [-53.7365, 48.6765],
                        [-53.7599, 48.6642],
                        [-53.749, 48.651],
                        [-53.7298, 48.6564],
                        [-53.7254, 48.6601],
                        [-53.7143, 48.6606],
                        [-53.7003, 48.6702],
                        [-53.6853, 48.678],
                        [-53.6767, 48.6792],
                        [-53.6574, 48.6904],
                        [-53.6622, 48.699],
                        [-53.6514, 48.7011],
                        [-53.6477, 48.6957],
                        [-53.6528, 48.6906],
                        [-53.645, 48.6867],
                        [-53.6425, 48.6944],
                        [-53.6221, 48.6931],
                        [-53.6256, 48.6855],
                        [-53.6171, 48.6817],
                        [-53.6226, 48.6739],
                        [-53.6434, 48.6648],
                        [-53.6565, 48.6619],
                        [-53.6557, 48.6571],
                        [-53.6611, 48.6501],
                        [-53.6972, 48.6389],
                        [-53.7143, 48.6394],
                        [-53.7237, 48.6365],
                        [-53.7294, 48.6409],
                        [-53.7376, 48.6387],
                        [-53.7389, 48.6332],
                        [-53.7609, 48.6313],
                        [-53.7724, 48.622],
                        [-53.7852, 48.6214],
                        [-53.809, 48.6264],
                        [-53.8203, 48.6261],
                        [-53.823, 48.6228],
                        [-53.8134, 48.6127],
                        [-53.8146, 48.6066],
                        [-53.8273, 48.6044],
                        [-53.8485, 48.5924],
                        [-53.8592, 48.5886],
                        [-53.8746, 48.5865],
                        [-53.902, 48.5863],
                        [-53.9112, 48.5832],
                        [-53.9133, 48.5908],
                        [-53.9346, 48.5795],
                        [-53.9459, 48.5805],
                        [-53.9637, 48.5615],
                        [-53.9688, 48.5468],
                        [-53.9661, 48.5411],
                        [-53.9515, 48.5487],
                        [-53.947, 48.5563],
                        [-53.9342, 48.5671],
                        [-53.9215, 48.5675],
                        [-53.911, 48.5702],
                        [-53.9006, 48.5692],
                        [-53.8932, 48.5636],
                        [-53.8776, 48.5678],
                        [-53.8702, 48.5746],
                        [-53.8635, 48.5693],
                        [-53.8501, 48.572],
                        [-53.8544, 48.562],
                        [-53.8474, 48.5587],
                        [-53.8403, 48.5724],
                        [-53.8274, 48.573],
                        [-53.8189, 48.576],
                        [-53.8076, 48.5703],
                        [-53.8018, 48.5725],
                        [-53.7887, 48.569],
                        [-53.78, 48.5701],
                        [-53.7602, 48.565],
                        [-53.7441, 48.5674],
                        [-53.7379, 48.5605],
                        [-53.7572, 48.5559],
                        [-53.7663, 48.557],
                        [-53.7783, 48.5551],
                        [-53.7894, 48.5562],
                        [-53.8016, 48.5537],
                        [-53.8004, 48.5495],
                        [-53.8099, 48.5455],
                        [-53.809, 48.5362],
                        [-53.8035, 48.5341],
                        [-53.7694, 48.5466],
                        [-53.7703, 48.539],
                        [-53.7754, 48.534],
                        [-53.7706, 48.5293],
                        [-53.7737, 48.5243],
                        [-53.7741, 48.5114],
                        [-53.7854, 48.506],
                        [-53.797, 48.5087],
                        [-53.8103, 48.501],
                        [-53.8276, 48.4974],
                        [-53.8683, 48.4924],
                        [-53.879, 48.4851],
                        [-53.8866, 48.483],
                        [-53.9061, 48.485],
                        [-53.9137, 48.4769],
                        [-53.9246, 48.4821],
                        [-53.9258, 48.4728],
                        [-53.9344, 48.4718],
                        [-53.9419, 48.4674],
                        [-53.9452, 48.4582],
                        [-53.9533, 48.4523],
                        [-53.9789, 48.4485],
                        [-54.0082, 48.4375],
                        [-54.0037, 48.4316],
                        [-54.0157, 48.42],
                        [-54.0388, 48.4157],
                        [-54.0483, 48.4174],
                        [-54.0659, 48.411],
                        [-54.0797, 48.4161],
                        [-54.0877, 48.4138],
                        [-54.0938, 48.4182],
                        [-54.1144, 48.4279],
                        [-54.1252, 48.4223],
                        [-54.1495, 48.4036],
                        [-54.1605, 48.3934],
                        [-54.1704, 48.3891],
                        [-54.1942, 48.3936],
                        [-54.1977, 48.3843],
                        [-54.1721, 48.3736],
                        [-54.1619, 48.3626],
                        [-54.1626, 48.3528],
                        [-54.1577, 48.3468],
                        [-54.1567, 48.3351],
                        [-54.1492, 48.3369],
                        [-54.1491, 48.345],
                        [-54.143, 48.3519],
                        [-54.1404, 48.3625],
                        [-54.1327, 48.3714],
                        [-54.1316, 48.3792],
                        [-54.0966, 48.4018],
                        [-54.0886, 48.4057],
                        [-54.0765, 48.4023],
                        [-54.0669, 48.4021],
                        [-54.0473, 48.3956],
                        [-54.0342, 48.4032],
                        [-54.0285, 48.4005],
                        [-53.9933, 48.4055],
                        [-53.98, 48.4163],
                        [-53.9635, 48.4264],
                        [-53.9296, 48.4419],
                        [-53.8887, 48.459],
                        [-53.8816, 48.467],
                        [-53.8742, 48.4676],
                        [-53.852, 48.4761],
                        [-53.8388, 48.4737],
                        [-53.8274, 48.4752],
                        [-53.8235, 48.4683],
                        [-53.8329, 48.458],
                        [-53.8392, 48.4553],
                        [-53.8507, 48.4394],
                        [-53.8504, 48.4269],
                        [-53.8624, 48.4216],
                        [-53.8701, 48.4139],
                        [-53.885, 48.4071],
                        [-53.8901, 48.4003],
                        [-53.9035, 48.391],
                        [-53.9038, 48.3858],
                        [-53.9102, 48.3808],
                        [-53.8889, 48.3765],
                        [-53.8885, 48.3721],
                        [-53.8691, 48.3694],
                        [-53.8561, 48.3725],
                        [-53.8483, 48.369],
                        [-53.8436, 48.3738],
                        [-53.8514, 48.3785],
                        [-53.8615, 48.3771],
                        [-53.8703, 48.3839],
                        [-53.8693, 48.3976],
                        [-53.861, 48.4014],
                        [-53.8501, 48.3998],
                        [-53.8346, 48.4066],
                        [-53.8344, 48.4143],
                        [-53.8296, 48.4227],
                        [-53.8132, 48.4372],
                        [-53.8183, 48.4486],
                        [-53.8017, 48.4427],
                        [-53.7951, 48.4465],
                        [-53.7944, 48.4554],
                        [-53.7896, 48.4624],
                        [-53.7923, 48.4657],
                        [-53.7898, 48.4757],
                        [-53.7635, 48.4905],
                        [-53.7451, 48.4967],
                        [-53.7403, 48.5027],
                        [-53.7261, 48.5109],
                        [-53.7182, 48.5204],
                        [-53.703, 48.5284],
                        [-53.6985, 48.5252],
                        [-53.676, 48.536],
                        [-53.6469, 48.5405],
                        [-53.6353, 48.5346],
                        [-53.654, 48.5259],
                        [-53.6639, 48.5197],
                        [-53.6735, 48.5166],
                        [-53.6912, 48.5076],
                        [-53.6947, 48.4964],
                        [-53.7051, 48.4978],
                        [-53.714, 48.4918],
                        [-53.7067, 48.4867],
                        [-53.6925, 48.4935],
                        [-53.6902, 48.4994],
                        [-53.6831, 48.5041],
                        [-53.6836, 48.5087],
                        [-53.6741, 48.514],
                        [-53.6604, 48.5167],
                        [-53.6514, 48.514],
                        [-53.6432, 48.5049],
                        [-53.6528, 48.4987],
                        [-53.647, 48.4939],
                        [-53.6478, 48.4878],
                        [-53.6631, 48.4801],
                        [-53.6837, 48.4647],
                        [-53.693, 48.4537],
                        [-53.7076, 48.4448],
                        [-53.7103, 48.4411],
                        [-53.7286, 48.4399],
                        [-53.7364, 48.4352],
                        [-53.7428, 48.4249],
                        [-53.743, 48.4193],
                        [-53.7195, 48.4265],
                        [-53.6922, 48.4374],
                        [-53.6832, 48.4464],
                        [-53.6641, 48.4564],
                        [-53.6623, 48.4488],
                        [-53.6757, 48.4272],
                        [-53.6521, 48.4374],
                        [-53.6513, 48.4468],
                        [-53.6373, 48.4694],
                        [-53.6262, 48.48],
                        [-53.6138, 48.498],
                        [-53.6061, 48.5053],
                        [-53.6044, 48.5158],
                        [-53.5924, 48.5016],
                        [-53.5847, 48.4978],
                        [-53.5956, 48.4892],
                        [-53.5958, 48.4828],
                        [-53.6071, 48.4744],
                        [-53.6003, 48.4696],
                        [-53.5822, 48.4774],
                        [-53.5734, 48.489],
                        [-53.5631, 48.4927],
                        [-53.5609, 48.4859],
                        [-53.5724, 48.4739],
                        [-53.5811, 48.4711],
                        [-53.6011, 48.459],
                        [-53.5877, 48.4583],
                        [-53.597, 48.4424],
                        [-53.5961, 48.4345],
                        [-53.608, 48.4224],
                        [-53.6172, 48.4182],
                        [-53.6269, 48.4082],
                        [-53.6467, 48.3934],
                        [-53.6324, 48.3959],
                        [-53.6131, 48.4118],
                        [-53.601, 48.4183],
                        [-53.5923, 48.419],
                        [-53.5883, 48.4273],
                        [-53.5784, 48.4321],
                        [-53.5778, 48.4445],
                        [-53.5743, 48.4515],
                        [-53.5589, 48.4555],
                        [-53.5593, 48.4623],
                        [-53.5524, 48.4678],
                        [-53.5465, 48.4666],
                        [-53.5487, 48.4591],
                        [-53.5563, 48.4543],
                        [-53.5453, 48.4432],
                        [-53.5381, 48.4556],
                        [-53.5332, 48.4678],
                        [-53.5311, 48.4862],
                        [-53.5242, 48.4929],
                        [-53.5143, 48.5137],
                        [-53.5063, 48.5083],
                        [-53.5049, 48.4981],
                        [-53.4947, 48.5045],
                        [-53.488, 48.5158],
                        [-53.4985, 48.5183],
                        [-53.4868, 48.5327],
                        [-53.4787, 48.546],
                        [-53.4925, 48.5509],
                        [-53.4915, 48.5587],
                        [-53.4949, 48.5697],
                        [-53.4927, 48.5825],
                        [-53.4849, 48.5839],
                        [-53.4752, 48.5923],
                        [-53.4625, 48.6065],
                        [-53.4582, 48.6179],
                        [-53.449, 48.6192],
                        [-53.4352, 48.6116],
                        [-53.425, 48.6126],
                        [-53.4177, 48.6081],
                        [-53.4075, 48.606],
                        [-53.4048, 48.6148],
                        [-53.3894, 48.6063],
                        [-53.3768, 48.6136],
                        [-53.362, 48.6115],
                        [-53.3633, 48.5968],
                        [-53.347, 48.6008],
                        [-53.3404, 48.6001],
                        [-53.3287, 48.5926],
                        [-53.3241, 48.5787],
                        [-53.3261, 48.5659],
                        [-53.3347, 48.5526],
                        [-53.3414, 48.5476],
                        [-53.3441, 48.5405],
                        [-53.3285, 48.5413],
                        [-53.3145, 48.5536],
                        [-53.3053, 48.5579],
                        [-53.2976, 48.5533],
                        [-53.2944, 48.5471],
                        [-53.2963, 48.5336],
                        [-53.2929, 48.5276],
                        [-53.2768, 48.522],
                        [-53.2709, 48.5245],
                        [-53.2616, 48.5216],
                        [-53.2581, 48.5279],
                        [-53.2484, 48.5357],
                        [-53.2407, 48.5513],
                        [-53.2347, 48.5542],
                        [-53.2391, 48.5622],
                        [-53.2372, 48.5679],
                        [-53.2292, 48.5718],
                        [-53.2129, 48.5721],
                        [-53.2097, 48.5845],
                        [-53.1929, 48.5861],
                        [-53.1937, 48.5898],
                        [-53.1796, 48.5953],
                        [-53.1849, 48.6044],
                        [-53.1836, 48.6125],
                        [-53.1742, 48.6146],
                        [-53.1747, 48.621],
                        [-53.1704, 48.6268],
                        [-53.1454, 48.6331],
                        [-53.1387, 48.6304],
                        [-53.1206, 48.6461],
                        [-53.1126, 48.6605],
                        [-53.1186, 48.6658],
                        [-53.1163, 48.6735],
                        [-53.1083, 48.671],
                        [-53.0996, 48.6742],
                        [-53.1026, 48.6805],
                        [-53.0977, 48.6929],
                        [-53.0863, 48.6992],
                        [-53.0818, 48.6936],
                        [-53.0859, 48.6868],
                        [-53.0944, 48.6805],
                        [-53.0852, 48.6761],
                        [-53.0843, 48.669],
                        [-53.0786, 48.6653],
                        [-53.0636, 48.663],
                        [-53.0632, 48.6689],
                        [-53.052, 48.6674],
                        [-53.0561, 48.6599],
                        [-53.0393, 48.6592],
                        [-53.032, 48.6565],
                        [-53.0285, 48.6484],
                        [-53.0388, 48.6428],
                        [-53.044, 48.6361],
                        [-53.0329, 48.6332],
                        [-53.0305, 48.6241],
                        [-53.0194, 48.6282],
                        [-53.0085, 48.6161],
                        [-53.0082, 48.6099],
                        [-53, 48.6057],
                        [-52.9971, 48.5985],
                        [-53.0046, 48.5802],
                        [-53.0226, 48.5737],
                        [-53.0199, 48.563],
                        [-53.0122, 48.5589],
                        [-53.0027, 48.5483],
                        [-53.009, 48.5405],
                        [-53.017, 48.5372],
                        [-53.0267, 48.5383],
                        [-53.0376, 48.5348],
                        [-53.0382, 48.5454],
                        [-53.0468, 48.5432],
                        [-53.0448, 48.5384],
                        [-53.0508, 48.5274],
                        [-53.0541, 48.5156],
                        [-53.0746, 48.5175],
                        [-53.0746, 48.507],
                        [-53.0782, 48.5006],
                        [-53.0518, 48.5092],
                        [-53.0523, 48.502],
                        [-53.0625, 48.4982],
                        [-53.0661, 48.4914],
                        [-53.0613, 48.4857],
                        [-53.0723, 48.4779],
                        [-53.0635, 48.4727],
                        [-53.0684, 48.4656],
                        [-53.0522, 48.4606],
                        [-53.0661, 48.4533],
                        [-53.073, 48.4409],
                        [-53.0836, 48.4327],
                        [-53.104, 48.4101],
                        [-53.1212, 48.4019],
                        [-53.13, 48.401],
                        [-53.1395, 48.393],
                        [-53.147, 48.3923],
                        [-53.1612, 48.3971],
                        [-53.1696, 48.3827],
                        [-53.1951, 48.3793],
                        [-53.1931, 48.3741],
                        [-53.1988, 48.3672],
                        [-53.1945, 48.3611],
                        [-53.2018, 48.3523],
                        [-53.212, 48.3492],
                        [-53.2529, 48.3582],
                        [-53.2697, 48.3652],
                        [-53.2662, 48.3728],
                        [-53.2719, 48.3786],
                        [-53.2862, 48.3791],
                        [-53.2884, 48.3897],
                        [-53.2982, 48.3857],
                        [-53.2995, 48.3768],
                        [-53.3112, 48.3785],
                        [-53.321, 48.3926],
                        [-53.3292, 48.3912],
                        [-53.3254, 48.3829],
                        [-53.3293, 48.3794],
                        [-53.3282, 48.365],
                        [-53.345, 48.3662],
                        [-53.3484, 48.3503],
                        [-53.3657, 48.3533],
                        [-53.3805, 48.3423],
                        [-53.3775, 48.337],
                        [-53.3912, 48.3257],
                        [-53.3984, 48.3281],
                        [-53.4041, 48.3222],
                        [-53.3905, 48.3198],
                        [-53.3868, 48.3152],
                        [-53.4009, 48.3072],
                        [-53.4107, 48.3074],
                        [-53.4098, 48.3004],
                        [-53.3987, 48.2992],
                        [-53.3903, 48.2935],
                        [-53.3929, 48.2882],
                        [-53.3841, 48.2836],
                        [-53.3934, 48.2721],
                        [-53.4039, 48.2705],
                        [-53.4129, 48.2778],
                        [-53.4371, 48.2724],
                        [-53.442, 48.2764],
                        [-53.4454, 48.286],
                        [-53.4667, 48.2707],
                        [-53.4706, 48.2633],
                        [-53.4952, 48.248],
                        [-53.4997, 48.2488],
                        [-53.5124, 48.2378],
                        [-53.5164, 48.2386],
                        [-53.5407, 48.2304],
                        [-53.5591, 48.2366],
                        [-53.5601, 48.2294],
                        [-53.566, 48.2206],
                        [-53.5735, 48.2152],
                        [-53.5827, 48.2131],
                        [-53.5858, 48.2035],
                        [-53.5951, 48.1975],
                        [-53.6237, 48.1873],
                        [-53.6447, 48.1751],
                        [-53.6711, 48.1761],
                        [-53.6827, 48.1675],
                        [-53.7026, 48.1647],
                        [-53.7118, 48.165],
                        [-53.7251, 48.1734],
                        [-53.7254, 48.178],
                        [-53.7359, 48.1823],
                        [-53.7457, 48.1897],
                        [-53.7626, 48.1949],
                        [-53.7935, 48.1969],
                        [-53.8032, 48.1997],
                        [-53.812, 48.1982],
                        [-53.8447, 48.1864],
                        [-53.858, 48.1917],
                        [-53.8588, 48.1962],
                        [-53.8687, 48.1982],
                        [-53.8803, 48.2137],
                        [-53.8982, 48.2216],
                        [-53.9122, 48.2206],
                        [-53.9296, 48.2271],
                        [-53.9403, 48.2334],
                        [-53.9495, 48.2321],
                        [-53.9596, 48.2349],
                        [-53.9634, 48.2276],
                        [-53.9566, 48.2229],
                        [-53.961, 48.217],
                        [-53.9531, 48.207],
                        [-53.964, 48.1968],
                        [-53.9645, 48.1893],
                        [-53.9803, 48.1852],
                        [-53.9584, 48.1733],
                        [-53.9607, 48.167],
                        [-53.9612, 48.1519],
                        [-53.9519, 48.151],
                        [-53.9435, 48.1426],
                        [-53.9416, 48.1364],
                        [-53.9303, 48.1295],
                        [-53.9266, 48.1119],
                        [-53.932, 48.0997],
                        [-53.9392, 48.0968],
                        [-53.939, 48.0813],
                        [-53.9328, 48.0761],
                        [-53.9258, 48.0799],
                        [-53.8969, 48.0781],
                        [-53.8759, 48.0778],
                        [-53.8611, 48.0705],
                        [-53.8301, 48.075],
                        [-53.8165, 48.0786],
                        [-53.7879, 48.0798],
                        [-53.7628, 48.076],
                        [-53.7323, 48.0803],
                        [-53.7254, 48.078],
                        [-53.7152, 48.0841],
                        [-53.6959, 48.0783],
                        [-53.6883, 48.0787],
                        [-53.6863, 48.0722],
                        [-53.6805, 48.0701],
                        [-53.6747, 48.0596],
                        [-53.6882, 48.0501],
                        [-53.6956, 48.0536],
                        [-53.699, 48.045],
                        [-53.7129, 48.0438],
                        [-53.7289, 48.0362],
                        [-53.7351, 48.0445],
                        [-53.7395, 48.0335],
                        [-53.7503, 48.0274],
                        [-53.7552, 48.0297],
                        [-53.7639, 48.0257],
                        [-53.7817, 48.0254],
                        [-53.7931, 48.0234],
                        [-53.8127, 48.0157],
                        [-53.8301, 48.0159],
                        [-53.8502, 48.0195],
                        [-53.8869, 48.0229],
                        [-53.9025, 48.0229],
                        [-53.9151, 48.0283],
                        [-53.9226, 48.0354],
                        [-53.938, 48.0271],
                        [-53.9463, 48.0298],
                        [-53.9585, 48.0237],
                        [-53.9532, 48.0211],
                        [-53.9413, 48.0212],
                        [-53.9384, 48.0147],
                        [-53.922, 48.0107],
                        [-53.8819, 48.0088],
                        [-53.871, 48.0065],
                        [-53.851, 48.0061],
                        [-53.8409, 48.0014],
                        [-53.8251, 48.0007],
                        [-53.8115, 47.9962],
                        [-53.7786, 48.0072],
                        [-53.7716, 48.0064],
                        [-53.7636, 48.0112],
                        [-53.7503, 48.0142],
                        [-53.7464, 48.0098],
                        [-53.7366, 48.0119],
                        [-53.7328, 48.016],
                        [-53.7207, 48.0153],
                        [-53.7024, 48.0231],
                        [-53.6905, 48.0212],
                        [-53.6892, 48.0253],
                        [-53.6722, 48.03],
                        [-53.6616, 48.0377],
                        [-53.6436, 48.0429],
                        [-53.6392, 48.0482],
                        [-53.6199, 48.054],
                        [-53.6357, 48.0275],
                        [-53.6335, 48.0082],
                        [-53.6422, 47.9986],
                        [-53.6348, 47.9942],
                        [-53.6358, 47.9871],
                        [-53.645, 47.9854],
                        [-53.657, 47.9734],
                        [-53.6615, 47.9568],
                        [-53.6764, 47.954],
                        [-53.6824, 47.9327],
                        [-53.6872, 47.9228],
                        [-53.6961, 47.9154],
                        [-53.6951, 47.9117],
                        [-53.7045, 47.9065],
                        [-53.6971, 47.9004],
                        [-53.7001, 47.8932],
                        [-53.7264, 47.8982],
                        [-53.7261, 47.9065],
                        [-53.7314, 47.9129],
                        [-53.7414, 47.9033],
                        [-53.7662, 47.9009],
                        [-53.7756, 47.8971],
                        [-53.784, 47.9003],
                        [-53.7923, 47.8965],
                        [-53.7826, 47.8916],
                        [-53.7676, 47.892],
                        [-53.7543, 47.8973],
                        [-53.748, 47.8912],
                        [-53.7397, 47.8924],
                        [-53.7268, 47.8866],
                        [-53.7386, 47.8616],
                        [-53.7311, 47.8513],
                        [-53.7356, 47.8405],
                        [-53.748, 47.8318],
                        [-53.7503, 47.8235],
                        [-53.7559, 47.8236],
                        [-53.7593, 47.8103],
                        [-53.7674, 47.8056],
                        [-53.7718, 47.7928],
                        [-53.7801, 47.7898],
                        [-53.7915, 47.7787],
                        [-53.7992, 47.7773],
                        [-53.8005, 47.772],
                        [-53.8145, 47.7827],
                        [-53.823, 47.7848],
                        [-53.8283, 47.794],
                        [-53.8394, 47.8066],
                        [-53.856, 47.8284],
                        [-53.868, 47.8349],
                        [-53.8733, 47.8404],
                        [-53.882, 47.8557],
                        [-53.886, 47.8532],
                        [-53.9092, 47.8583],
                        [-53.9271, 47.8592],
                        [-53.9333, 47.8643],
                        [-53.9406, 47.859],
                        [-53.9406, 47.8544],
                        [-53.8969, 47.8464],
                        [-53.8948, 47.8417],
                        [-53.8828, 47.8354],
                        [-53.8776, 47.8237],
                        [-53.8704, 47.8156],
                        [-53.888, 47.8105],
                        [-53.8655, 47.8061],
                        [-53.8602, 47.7993],
                        [-53.863, 47.7961],
                        [-53.8592, 47.7856],
                        [-53.8681, 47.7804],
                        [-53.8533, 47.7608],
                        [-53.8546, 47.7567],
                        [-53.8413, 47.7463],
                        [-53.8375, 47.7408],
                        [-53.8385, 47.7336],
                        [-53.8352, 47.7216],
                        [-53.844, 47.7171],
                        [-53.8569, 47.7249],
                        [-53.857, 47.718],
                        [-53.8702, 47.7189],
                        [-53.8686, 47.7133],
                        [-53.8555, 47.7104],
                        [-53.8565, 47.7055],
                        [-53.8483, 47.6999],
                        [-53.8409, 47.7034],
                        [-53.8303, 47.6973],
                        [-53.8293, 47.6915],
                        [-53.8206, 47.6834],
                        [-53.8287, 47.6785],
                        [-53.8165, 47.674],
                        [-53.8017, 47.6713],
                        [-53.7977, 47.6544],
                        [-53.7923, 47.647],
                        [-53.7769, 47.635],
                        [-53.7714, 47.6214],
                        [-53.7728, 47.6093],
                        [-53.7602, 47.6123],
                        [-53.7626, 47.62],
                        [-53.7425, 47.6323],
                        [-53.7347, 47.6302],
                        [-53.7304, 47.638],
                        [-53.7396, 47.6423],
                        [-53.7387, 47.646],
                        [-53.7287, 47.6492],
                        [-53.7233, 47.6592],
                        [-53.7224, 47.6694],
                        [-53.7177, 47.6823],
                        [-53.7063, 47.6869],
                        [-53.7059, 47.6917],
                        [-53.6967, 47.6973],
                        [-53.6907, 47.6891],
                        [-53.6812, 47.688],
                        [-53.6766, 47.6764],
                        [-53.6781, 47.6631],
                        [-53.6846, 47.6498],
                        [-53.6953, 47.634],
                        [-53.7006, 47.63],
                        [-53.7023, 47.6219],
                        [-53.7196, 47.6008],
                        [-53.717, 47.5962],
                        [-53.7205, 47.5799],
                        [-53.7119, 47.5725],
                        [-53.6968, 47.5877],
                        [-53.6825, 47.5964],
                        [-53.6738, 47.5949],
                        [-53.68, 47.5838],
                        [-53.6751, 47.5747],
                        [-53.6551, 47.5707],
                        [-53.661, 47.5645],
                        [-53.6613, 47.5545],
                        [-53.6695, 47.538],
                        [-53.672, 47.522],
                        [-53.6639, 47.5143],
                        [-53.6522, 47.5255],
                        [-53.65, 47.5307],
                        [-53.6261, 47.5628],
                        [-53.6157, 47.558],
                        [-53.6127, 47.5383],
                        [-53.6042, 47.5434],
                        [-53.5972, 47.5342],
                        [-53.5858, 47.5421],
                        [-53.5772, 47.5455],
                        [-53.5742, 47.5387],
                        [-53.5747, 47.5254],
                        [-53.5636, 47.5274],
                        [-53.5594, 47.5192],
                        [-53.5516, 47.5266],
                        [-53.5473, 47.5569],
                        [-53.5571, 47.5693],
                        [-53.5705, 47.5663],
                        [-53.5664, 47.5769],
                        [-53.5559, 47.5858],
                        [-53.5412, 47.5863],
                        [-53.5551, 47.6032],
                        [-53.5676, 47.6119],
                        [-53.5704, 47.6193],
                        [-53.5668, 47.6309],
                        [-53.55, 47.63],
                        [-53.5249, 47.6111],
                        [-53.5168, 47.6096],
                        [-53.5095, 47.6151],
                        [-53.5204, 47.6224],
                        [-53.5205, 47.6299],
                        [-53.5286, 47.6391],
                        [-53.5242, 47.6444],
                        [-53.5131, 47.6346],
                        [-53.5061, 47.634],
                        [-53.5045, 47.6438],
                        [-53.5116, 47.6537],
                        [-53.5177, 47.6567],
                        [-53.5182, 47.6638],
                        [-53.5308, 47.6705],
                        [-53.5225, 47.6754],
                        [-53.5162, 47.667],
                        [-53.5056, 47.6718],
                        [-53.5006, 47.6822],
                        [-53.488, 47.6805],
                        [-53.4798, 47.6964],
                        [-53.4946, 47.7054],
                        [-53.5002, 47.7047],
                        [-53.5053, 47.713],
                        [-53.4933, 47.7185],
                        [-53.5025, 47.7309],
                        [-53.5062, 47.7495],
                        [-53.496, 47.7501],
                        [-53.4801, 47.7628],
                        [-53.479, 47.7705],
                        [-53.4641, 47.7712],
                        [-53.4654, 47.7782],
                        [-53.4755, 47.7826],
                        [-53.4758, 47.7877],
                        [-53.4672, 47.7988],
                        [-53.4624, 47.8121],
                        [-53.4445, 47.8113],
                        [-53.4406, 47.8184],
                        [-53.4443, 47.8355],
                        [-53.4331, 47.856],
                        [-53.4178, 47.8711],
                        [-53.4082, 47.8729],
                        [-53.4017, 47.8801],
                        [-53.3923, 47.8764],
                        [-53.3864, 47.8675],
                        [-53.3759, 47.8623],
                        [-53.3647, 47.8686],
                        [-53.3702, 47.8742],
                        [-53.3681, 47.8787],
                        [-53.3862, 47.8815],
                        [-53.3876, 47.8908],
                        [-53.3827, 47.8933],
                        [-53.3816, 47.904],
                        [-53.3763, 47.9139],
                        [-53.3694, 47.9079],
                        [-53.3643, 47.9135],
                        [-53.3549, 47.9138],
                        [-53.353, 47.9195],
                        [-53.3648, 47.9267],
                        [-53.3545, 47.9329],
                        [-53.3472, 47.9342],
                        [-53.3507, 47.9437],
                        [-53.3429, 47.9561],
                        [-53.3354, 47.9566],
                        [-53.3306, 47.972],
                        [-53.3206, 47.9829],
                        [-53.319, 47.9885],
                        [-53.3067, 47.9995],
                        [-53.3065, 48.0049],
                        [-53.2832, 48.0195],
                        [-53.2558, 48.0176],
                        [-53.2408, 48.0294],
                        [-53.221, 48.0374],
                        [-53.2178, 48.028],
                        [-53.2073, 48.0316],
                        [-53.1941, 48.0407],
                        [-53.1906, 48.0515],
                        [-53.1809, 48.059],
                        [-53.1699, 48.0629],
                        [-53.1561, 48.0608],
                        [-53.1519, 48.055],
                        [-53.1546, 48.0493],
                        [-53.1485, 48.0434],
                        [-53.1353, 48.0443],
                        [-53.1269, 48.0398],
                        [-53.1104, 48.0361],
                        [-53.1072, 48.0434],
                        [-53.0931, 48.0496],
                        [-53.0814, 48.0465],
                        [-53.0697, 48.0491],
                        [-53.058, 48.056],
                        [-53.053, 48.0673],
                        [-53.0382, 48.0751],
                        [-53.0162, 48.0789],
                        [-53.007, 48.0833],
                        [-53.0062, 48.0895],
                        [-53.0127, 48.1014],
                        [-52.9998, 48.1014],
                        [-52.9951, 48.1178],
                        [-52.9864, 48.1221],
                        [-52.9785, 48.134],
                        [-52.9722, 48.1495],
                        [-52.9687, 48.1636],
                        [-52.9581, 48.1666],
                        [-52.9519, 48.1722],
                        [-52.9427, 48.1752],
                        [-52.9267, 48.1667],
                        [-52.9201, 48.1713],
                        [-52.9102, 48.1569],
                        [-52.8934, 48.1529],
                        [-52.8883, 48.1469],
                        [-52.892, 48.1407],
                        [-52.8868, 48.1381],
                        [-52.894, 48.1319],
                        [-52.8824, 48.1306],
                        [-52.8842, 48.1252],
                        [-52.8752, 48.1206],
                        [-52.8728, 48.1157],
                        [-52.8592, 48.1158],
                        [-52.849, 48.1013],
                        [-52.8612, 48.0932],
                        [-52.879, 48.0953],
                        [-52.8887, 48.0937],
                        [-52.8942, 48.0879],
                        [-52.8852, 48.0827],
                        [-52.8902, 48.0777],
                        [-52.8972, 48.0784],
                        [-52.9019, 48.0837],
                        [-52.9011, 48.0906],
                        [-52.9088, 48.0931],
                        [-52.92, 48.0928],
                        [-52.929, 48.0894],
                        [-52.9416, 48.0773],
                        [-52.9529, 48.0743],
                        [-52.9609, 48.0679],
                        [-52.9529, 48.059],
                        [-52.9641, 48.0466],
                        [-52.9712, 48.0476],
                        [-52.9824, 48.0406],
                        [-52.9742, 48.0349],
                        [-52.9708, 48.0272],
                        [-52.9696, 48.0142],
                        [-52.9784, 48],
                        [-52.9776, 47.9977],
                        [-52.9922, 47.9832],
                        [-53.001, 47.9808],
                        [-53.0142, 47.9689],
                        [-53.0214, 47.9708],
                        [-53.0326, 47.9641],
                        [-53.0322, 47.9581],
                        [-53.0421, 47.9508],
                        [-53.0575, 47.9424],
                        [-53.0745, 47.9409],
                        [-53.0632, 47.925],
                        [-53.07, 47.9125],
                        [-53.0632, 47.9077],
                        [-53.067, 47.8967],
                        [-53.0772, 47.8934],
                        [-53.0832, 47.8876],
                        [-53.0787, 47.8829],
                        [-53.0553, 47.8873],
                        [-53.0572, 47.8791],
                        [-53.0661, 47.8743],
                        [-53.0659, 47.8703],
                        [-53.0757, 47.8635],
                        [-53.088, 47.8591],
                        [-53.0845, 47.8548],
                        [-53.0923, 47.8459],
                        [-53.0881, 47.8392],
                        [-53.0922, 47.8315],
                        [-53.1011, 47.831],
                        [-53.1045, 47.8238],
                        [-53.1155, 47.8238],
                        [-53.1258, 47.8195],
                        [-53.1364, 47.8093],
                        [-53.1476, 47.8042],
                        [-53.1445, 47.7934],
                        [-53.1512, 47.7859],
                        [-53.1506, 47.7769],
                        [-53.1719, 47.7634],
                        [-53.2003, 47.7399],
                        [-53.2275, 47.7378],
                        [-53.2275, 47.733],
                        [-53.2179, 47.7273],
                        [-53.1872, 47.7295],
                        [-53.1766, 47.7164],
                        [-53.1912, 47.7068],
                        [-53.2008, 47.7029],
                        [-53.2111, 47.6932],
                        [-53.2272, 47.6876],
                        [-53.2324, 47.6831],
                        [-53.2432, 47.6804],
                        [-53.2524, 47.675],
                        [-53.2578, 47.6675],
                        [-53.2388, 47.6733],
                        [-53.1864, 47.6927],
                        [-53.1723, 47.691],
                        [-53.1831, 47.6836],
                        [-53.1862, 47.6774],
                        [-53.1761, 47.6746],
                        [-53.1927, 47.6615],
                        [-53.1954, 47.6525],
                        [-53.2023, 47.6506],
                        [-53.2037, 47.6452],
                        [-53.2159, 47.6382],
                        [-53.2286, 47.636],
                        [-53.2607, 47.6239],
                        [-53.2741, 47.6218],
                        [-53.2792, 47.6159],
                        [-53.2774, 47.61],
                        [-53.2872, 47.6053],
                        [-53.2866, 47.5995],
                        [-53.2743, 47.5989],
                        [-53.2684, 47.6016],
                        [-53.2522, 47.6033],
                        [-53.2371, 47.6106],
                        [-53.2077, 47.6219],
                        [-53.2078, 47.6143],
                        [-53.2199, 47.612],
                        [-53.242, 47.5973],
                        [-53.2586, 47.5955],
                        [-53.2699, 47.5903],
                        [-53.255, 47.5857],
                        [-53.2661, 47.5797],
                        [-53.2587, 47.5734],
                        [-53.2385, 47.5781],
                        [-53.2218, 47.5885],
                        [-53.2167, 47.5878],
                        [-53.2095, 47.5944],
                        [-53.194, 47.6044],
                        [-53.1768, 47.6121],
                        [-53.1748, 47.6068],
                        [-53.1816, 47.6043],
                        [-53.1829, 47.5982],
                        [-53.1935, 47.5922],
                        [-53.2076, 47.5879],
                        [-53.2186, 47.5809],
                        [-53.2442, 47.5742],
                        [-53.2588, 47.5646],
                        [-53.2705, 47.5544],
                        [-53.2819, 47.5503],
                        [-53.2723, 47.5411],
                        [-53.264, 47.5437],
                        [-53.2518, 47.5525],
                        [-53.2302, 47.5623],
                        [-53.226, 47.5541],
                        [-53.2169, 47.5574],
                        [-53.2051, 47.5668],
                        [-53.1932, 47.5728],
                        [-53.181, 47.5537],
                        [-53.1811, 47.5485],
                        [-53.1965, 47.5447],
                        [-53.2067, 47.5383],
                        [-53.1994, 47.5344],
                        [-53.1904, 47.5379],
                        [-53.1813, 47.5314],
                        [-53.1861, 47.5195],
                        [-53.1995, 47.5112],
                        [-53.2024, 47.5066],
                        [-53.1961, 47.5029],
                        [-53.2025, 47.4947],
                        [-53.2114, 47.4787],
                        [-53.2241, 47.4626],
                        [-53.2189, 47.4584],
                        [-53.2102, 47.4615],
                        [-53.1936, 47.4736],
                        [-53.1855, 47.4868],
                        [-53.1781, 47.5042],
                        [-53.1665, 47.5073],
                        [-53.1629, 47.4955],
                        [-53.1659, 47.4841],
                        [-53.1613, 47.4742],
                        [-53.1651, 47.4692],
                        [-53.1883, 47.4525],
                        [-53.2072, 47.4446],
                        [-53.1931, 47.437],
                        [-53.1865, 47.4262],
                        [-53.1745, 47.4453],
                        [-53.1674, 47.4521],
                        [-53.1572, 47.4537],
                        [-53.1527, 47.449],
                        [-53.1593, 47.4345],
                        [-53.1501, 47.4353],
                        [-53.1366, 47.4488],
                        [-53.1325, 47.4449],
                        [-53.127, 47.429],
                        [-53.1402, 47.4108],
                        [-53.1496, 47.4018],
                        [-53.1374, 47.3978],
                        [-53.134, 47.3863],
                        [-53.1251, 47.3901],
                        [-53.1181, 47.4194],
                        [-53.1114, 47.4298],
                        [-53.105, 47.4467],
                        [-53.0988, 47.4551],
                        [-53.0883, 47.4617],
                        [-53.0833, 47.4707],
                        [-53.0725, 47.4767],
                        [-53.0612, 47.478],
                        [-53.0561, 47.4837],
                        [-53.0456, 47.4883],
                        [-53.0285, 47.4989],
                        [-53.0239, 47.5041],
                        [-53.0006, 47.5135],
                        [-52.9845, 47.5151],
                        [-52.9666, 47.5243],
                        [-52.9514, 47.5363],
                        [-52.9391, 47.5403],
                        [-52.9222, 47.5422],
                        [-52.9176, 47.5451],
                        [-52.9103, 47.5639],
                        [-52.9039, 47.5706],
                        [-52.8913, 47.5921],
                        [-52.8848, 47.5937],
                        [-52.8847, 47.6013],
                        [-52.869, 47.622],
                        [-52.8577, 47.6238],
                        [-52.8583, 47.6337],
                        [-52.8559, 47.6537],
                        [-52.8516, 47.6654],
                        [-52.8506, 47.6917],
                        [-52.8451, 47.7059],
                        [-52.8297, 47.7303],
                        [-52.8226, 47.7438],
                        [-52.8238, 47.7469],
                        [-52.8178, 47.7574],
                        [-52.8144, 47.7856],
                        [-52.806, 47.8037],
                        [-52.7807, 47.8004],
                        [-52.7803, 47.7948],
                        [-52.7653, 47.7869],
                        [-52.7604, 47.7786],
                        [-52.7629, 47.763],
                        [-52.7481, 47.7628],
                        [-52.7414, 47.7598],
                        [-52.7188, 47.76],
                        [-52.7105, 47.7559],
                        [-52.7095, 47.7459],
                        [-52.7155, 47.7419],
                        [-52.7161, 47.7335],
                        [-52.7002, 47.7188],
                        [-52.7097, 47.7025],
                        [-52.6993, 47.6952],
                        [-52.7068, 47.69],
                        [-52.7105, 47.6802],
                        [-52.7184, 47.676],
                        [-52.7211, 47.668],
                        [-52.7309, 47.6624],
                        [-52.729, 47.6593],
                        [-52.7116, 47.6643],
                        [-52.7036, 47.6625],
                        [-52.6938, 47.6506],
                        [-52.67, 47.6629],
                        [-52.6667, 47.6539],
                        [-52.6599, 47.6464],
                        [-52.6643, 47.6381],
                        [-52.667, 47.6253],
                        [-52.6589, 47.6261],
                        [-52.652, 47.6201],
                        [-52.66, 47.612],
                        [-52.6539, 47.6017],
                        [-52.6628, 47.5949],
                        [-52.6701, 47.5766],
                        [-52.6762, 47.5761],
                        [-52.6807, 47.5642],
                        [-52.6861, 47.5426],
                        [-52.676, 47.5408],
                        [-52.6717, 47.5281],
                        [-52.6637, 47.5308],
                        [-52.6564, 47.5267],
                        [-52.644, 47.5331],
                        [-52.637, 47.5283],
                        [-52.6351, 47.5223],
                        [-52.6194, 47.5232],
                        [-52.632, 47.5058],
                        [-52.632, 47.4979],
                        [-52.6411, 47.4886],
                        [-52.6624, 47.4839],
                        [-52.6679, 47.4789],
                        [-52.677, 47.4778],
                        [-52.6799, 47.4734],
                        [-52.6893, 47.4703],
                        [-52.7011, 47.4734],
                        [-52.7048, 47.4704],
                        [-52.697, 47.4591],
                        [-52.6866, 47.454],
                        [-52.6752, 47.4403],
                        [-52.6693, 47.441],
                        [-52.6595, 47.4354],
                        [-52.6617, 47.4312],
                        [-52.6736, 47.4244],
                        [-52.6833, 47.4244],
                        [-52.69, 47.416],
                        [-52.7041, 47.4079],
                        [-52.7043, 47.4022],
                        [-52.7186, 47.3883],
                        [-52.7143, 47.3779],
                        [-52.7219, 47.3738],
                        [-52.73, 47.3527],
                        [-52.7398, 47.3339],
                        [-52.74, 47.3241],
                        [-52.7458, 47.318],
                        [-52.7463, 47.3102],
                        [-52.7525, 47.307],
                        [-52.7655, 47.3122],
                        [-52.7947, 47.3089],
                        [-52.8089, 47.315],
                        [-52.8123, 47.3109],
                        [-52.8088, 47.3043],
                        [-52.7732, 47.296],
                        [-52.7662, 47.2871],
                        [-52.7734, 47.2832],
                        [-52.7954, 47.2838],
                        [-52.8198, 47.2788],
                        [-52.8325, 47.2788],
                        [-52.8263, 47.2721],
                        [-52.8111, 47.2634],
                        [-52.7984, 47.2441],
                        [-52.8091, 47.2406],
                        [-52.8212, 47.2459],
                        [-52.8371, 47.2495],
                        [-52.8311, 47.2365],
                        [-52.8253, 47.2295],
                        [-52.8283, 47.2247],
                        [-52.8402, 47.2231],
                        [-52.8414, 47.2165],
                        [-52.8488, 47.2102],
                        [-52.845, 47.1952],
                        [-52.8398, 47.1835],
                        [-52.8439, 47.1751],
                        [-52.8604, 47.1664],
                        [-52.8652, 47.1656],
                        [-52.8566, 47.1528],
                        [-52.8512, 47.1489],
                        [-52.861, 47.1417],
                        [-52.8664, 47.1438],
                        [-52.8672, 47.1279],
                        [-52.8741, 47.1174],
                        [-52.8839, 47.1091],
                        [-52.8728, 47.1057],
                        [-52.8686, 47.099],
                        [-52.8758, 47.0948],
                        [-52.8936, 47.1014],
                        [-52.901, 47.0936],
                        [-52.9111, 47.0965],
                        [-52.9272, 47.0915],
                        [-52.929, 47.0938],
                        [-52.9531, 47.095],
                        [-52.9493, 47.089],
                        [-52.9263, 47.0881],
                        [-52.9047, 47.0828],
                        [-52.8919, 47.074],
                        [-52.88, 47.076],
                        [-52.8663, 47.0657],
                        [-52.8616, 47.0706],
                        [-52.8527, 47.0736],
                        [-52.8525, 47.0621],
                        [-52.8595, 47.0491],
                        [-52.8718, 47.0528],
                        [-52.8818, 47.0528],
                        [-52.9048, 47.0584],
                        [-52.9103, 47.0547],
                        [-52.8937, 47.0484],
                        [-52.8818, 47.041],
                        [-52.8843, 47.0368],
                        [-52.8815, 47.0227],
                        [-52.86, 47.0229],
                        [-52.8586, 47.0186],
                        [-52.8858, 47.0218],
                        [-52.8931, 47.0098],
                        [-52.8991, 47.007],
                        [-52.9246, 47.0104],
                        [-52.934, 47.0077],
                        [-52.9421, 47.0085],
                        [-52.9602, 47.0059],
                        [-52.9589, 47.0029],
                        [-52.939, 47.0025],
                        [-52.9104, 47.0001],
                        [-52.9038, 47.0005],
                        [-52.8999, 46.9944],
                        [-52.8924, 46.9905],
                        [-52.9005, 46.982],
                        [-52.8898, 46.9755],
                        [-52.9057, 46.9654],
                        [-52.9449, 46.9737],
                        [-52.936, 46.9644],
                        [-52.9218, 46.9648],
                        [-52.9072, 46.961],
                        [-52.8958, 46.9541],
                        [-52.8962, 46.9417],
                        [-52.8923, 46.9398],
                        [-52.9057, 46.9285],
                        [-52.91, 46.9221],
                        [-52.92, 46.9182],
                        [-52.9302, 46.9196],
                        [-52.9404, 46.9278],
                        [-52.9464, 46.9232],
                        [-52.9392, 46.9169],
                        [-52.9247, 46.9082],
                        [-52.9168, 46.9064],
                        [-52.9227, 46.8925],
                        [-52.9348, 46.8819],
                        [-52.9394, 46.8735],
                        [-52.9349, 46.8636],
                        [-52.9429, 46.8613],
                        [-52.9413, 46.8506],
                        [-52.9474, 46.8454],
                        [-52.9437, 46.8344],
                        [-52.937, 46.8303],
                        [-52.9399, 46.8257],
                        [-52.9377, 46.8166],
                        [-52.9503, 46.8002],
                        [-52.9513, 46.7864],
                        [-52.9678, 46.7832],
                        [-52.9738, 46.7843],
                        [-52.9805, 46.7728],
                        [-52.9782, 46.768],
                        [-52.9821, 46.7593],
                        [-52.9971, 46.7561],
                        [-53.0019, 46.7655],
                        [-53.0102, 46.7626],
                        [-53.0159, 46.7503],
                        [-53.0207, 46.745],
                        [-53.0273, 46.7511],
                        [-53.0334, 46.749],
                        [-53.0368, 46.7376],
                        [-53.0442, 46.7328],
                        [-53.0482, 46.7203],
                        [-53.0462, 46.713],
                        [-53.0555, 46.6955],
                        [-53.0635, 46.6908],
                        [-53.0661, 46.6811],
                        [-53.0758, 46.6697],
                        [-53.0724, 46.6584],
                        [-53.0856, 46.6539],
                        [-53.0917, 46.6463],
                        [-53.105, 46.6441],
                        [-53.122, 46.6365],
                        [-53.1316, 46.636],
                        [-53.1395, 46.6403],
                        [-53.1454, 46.6362],
                        [-53.1663, 46.6286],
                        [-53.1768, 46.6308],
                        [-53.1939, 46.6302],
                        [-53.2148, 46.6344],
                        [-53.2236, 46.6385],
                        [-53.2218, 46.6458],
                        [-53.232, 46.6516],
                        [-53.2363, 46.6597],
                        [-53.2439, 46.6654],
                        [-53.2437, 46.673],
                        [-53.2529, 46.6734],
                        [-53.2602, 46.6857],
                        [-53.2565, 46.6957],
                        [-53.2613, 46.7115],
                        [-53.2729, 46.7139],
                        [-53.2793, 46.7081],
                        [-53.2848, 46.7115],
                        [-53.2795, 46.7212],
                        [-53.276, 46.7378],
                        [-53.2774, 46.7423],
                        [-53.2902, 46.7418],
                        [-53.2946, 46.7328],
                        [-53.305, 46.72],
                        [-53.3107, 46.71],
                        [-53.3246, 46.705],
                        [-53.3323, 46.6953],
                        [-53.3423, 46.6931],
                        [-53.3406, 46.7059],
                        [-53.3469, 46.7083],
                        [-53.3447, 46.7195],
                        [-53.358, 46.7256],
                        [-53.3728, 46.7238],
                        [-53.3819, 46.7065],
                        [-53.3824, 46.7001],
                        [-53.4039, 46.6963],
                        [-53.3869, 46.7137],
                        [-53.3829, 46.7245],
                        [-53.3718, 46.7274],
                        [-53.3669, 46.7453],
                        [-53.3822, 46.7455],
                        [-53.3864, 46.7307],
                        [-53.4014, 46.7124],
                        [-53.4164, 46.7016],
                        [-53.4184, 46.698],
                        [-53.4348, 46.6873],
                        [-53.4464, 46.6723],
                        [-53.457, 46.6641],
                        [-53.4786, 46.6567],
                        [-53.4785, 46.6512],
                        [-53.4905, 46.6448],
                        [-53.5052, 46.6322],
                        [-53.5162, 46.6278],
                        [-53.527, 46.6168],
                        [-53.5427, 46.6141],
                        [-53.5455, 46.6192],
                        [-53.5591, 46.6111],
                        [-53.5683, 46.6138],
                        [-53.578, 46.6126],
                        [-53.599, 46.6218],
                        [-53.5897, 46.6307],
                        [-53.6155, 46.6356],
                        [-53.6255, 46.6464],
                        [-53.6249, 46.6574],
                        [-53.6432, 46.6842],
                        [-53.6418, 46.6952],
                        [-53.6516, 46.7068],
                        [-53.6426, 46.7171],
                        [-53.6474, 46.7235],
                        [-53.6259, 46.7368],
                        [-53.6273, 46.7428],
                        [-53.6174, 46.7535],
                        [-53.6104, 46.7579],
                        [-53.6216, 46.7763],
                        [-53.6374, 46.7915],
                        [-53.6456, 46.7948],
                        [-53.6613, 46.7895],
                        [-53.6642, 46.798],
                        [-53.6562, 46.8147],
                        [-53.6598, 46.8225],
                        [-53.6488, 46.8389],
                        [-53.6421, 46.8422],
                        [-53.623, 46.8708],
                        [-53.6081, 46.8824],
                        [-53.6018, 46.8948],
                        [-53.6155, 46.9062],
                        [-53.6088, 46.9132],
                        [-53.5895, 46.9248],
                        [-53.5801, 46.9219],
                        [-53.578, 46.9175],
                        [-53.5694, 46.9163],
                        [-53.5488, 46.9261],
                        [-53.5391, 46.9339],
                        [-53.5253, 46.9414],
                        [-53.5227, 46.9455],
                        [-53.5258, 46.9583],
                        [-53.543, 46.9467],
                        [-53.5648, 46.9388],
                        [-53.5768, 46.9388],
                        [-53.5765, 46.9438],
                        [-53.5676, 46.9575],
                        [-53.5778, 46.9634],
                        [-53.577, 46.9767],
                        [-53.5738, 46.9847],
                        [-53.5806, 46.9858],
                        [-53.5871, 46.9738],
                        [-53.5914, 46.9598],
                        [-53.5968, 46.9512],
                        [-53.6071, 46.9434],
                        [-53.6239, 46.9411],
                        [-53.6282, 46.9571],
                        [-53.6222, 46.9724],
                        [-53.622, 46.9801],
                        [-53.6151, 46.9899],
                        [-53.6243, 46.9976],
                        [-53.6334, 46.9908],
                        [-53.6511, 46.9823],
                        [-53.6536, 46.9887],
                        [-53.6453, 47.0033],
                        [-53.6133, 47.03],
                        [-53.6011, 47.0431],
                        [-53.5687, 47.0698],
                        [-53.5758, 47.0765],
                        [-53.5951, 47.0708],
                        [-53.597, 47.0738],
                        [-53.5912, 47.0838],
                        [-53.5801, 47.0942],
                        [-53.5531, 47.1106],
                        [-53.5464, 47.1135],
                        [-53.5223, 47.1188],
                        [-53.5024, 47.1211],
                        [-53.4848, 47.1286],
                        [-53.4708, 47.1425],
                        [-53.4704, 47.1453],
                        [-53.4556, 47.1592],
                        [-53.4588, 47.1662],
                        [-53.4698, 47.159],
                        [-53.4712, 47.1522],
                        [-53.4794, 47.1471],
                        [-53.4953, 47.1313],
                        [-53.5084, 47.1293],
                        [-53.5197, 47.1307],
                        [-53.5407, 47.1259],
                        [-53.5536, 47.1281],
                        [-53.5378, 47.1491],
                        [-53.5298, 47.1625],
                        [-53.5384, 47.1695],
                        [-53.5477, 47.1681],
                        [-53.5682, 47.1495],
                        [-53.5732, 47.1523],
                        [-53.5667, 47.1605],
                        [-53.5649, 47.1721],
                        [-53.56, 47.1853],
                        [-53.5615, 47.1981],
                        [-53.5567, 47.2029],
                        [-53.5529, 47.2181],
                        [-53.5652, 47.2136],
                        [-53.5785, 47.197],
                        [-53.5995, 47.1809],
                        [-53.6079, 47.1692],
                        [-53.6182, 47.1484],
                        [-53.6167, 47.1318],
                        [-53.6246, 47.1207],
                        [-53.6406, 47.1089],
                        [-53.6617, 47.0988],
                        [-53.6623, 47.1135],
                        [-53.6583, 47.1232],
                        [-53.6544, 47.1483],
                        [-53.6491, 47.1579],
                        [-53.6358, 47.1718],
                        [-53.6396, 47.1761],
                        [-53.6609, 47.156],
                        [-53.6692, 47.1384],
                        [-53.6736, 47.1333],
                        [-53.6865, 47.1024],
                        [-53.6864, 47.0931],
                        [-53.6944, 47.0836],
                        [-53.7003, 47.0894],
                        [-53.7098, 47.0896],
                        [-53.7141, 47.0664],
                        [-53.7243, 47.0503],
                        [-53.73, 47.0508],
                        [-53.7371, 47.0424],
                        [-53.7491, 47.0345],
                        [-53.7593, 47.0437],
                        [-53.7681, 47.0392],
                        [-53.7809, 47.0402],
                        [-53.7813, 47.0336],
                        [-53.7893, 47.0218],
                        [-53.7969, 47.0172],
                        [-53.7993, 47.0084],
                        [-53.8111, 46.9984],
                        [-53.8101, 46.9845],
                        [-53.8214, 46.9772],
                        [-53.8295, 46.9666],
                        [-53.8368, 46.9612],
                        [-53.8431, 46.9521],
                        [-53.8517, 46.9479],
                        [-53.8591, 46.9498],
                        [-53.8688, 46.9442],
                        [-53.877, 46.9242],
                        [-53.8757, 46.9176],
                        [-53.8843, 46.9147],
                        [-53.9, 46.8957],
                        [-53.9086, 46.8933],
                        [-53.9138, 46.8867],
                        [-53.923, 46.881],
                        [-53.9346, 46.8802],
                        [-53.9475, 46.883],
                        [-53.9543, 46.8753],
                        [-53.9481, 46.8644],
                        [-53.9526, 46.8559],
                        [-53.9601, 46.8498],
                        [-53.9715, 46.8517],
                        [-53.9857, 46.8413],
                        [-53.9876, 46.8339],
                        [-53.9947, 46.8349],
                        [-54.0038, 46.8428],
                        [-54.0165, 46.8358],
                        [-54.0274, 46.8235],
                        [-54.0329, 46.8139],
                        [-54.0444, 46.8117],
                        [-54.0556, 46.8038],
                        [-54.0602, 46.7979],
                        [-54.0685, 46.7972],
                        [-54.0674, 46.805],
                        [-54.0765, 46.8081],
                        [-54.0896, 46.8084],
                        [-54.0971, 46.8027],
                        [-54.0978, 46.7979],
                        [-54.1094, 46.7969],
                        [-54.1095, 46.8057],
                        [-54.1138, 46.8092],
                        [-54.1321, 46.8131],
                        [-54.1422, 46.8252],
                        [-54.1528, 46.8261],
                        [-54.1749, 46.8202],
                        [-54.1827, 46.8161],
                        [-54.188, 46.8217],
                        [-54.1992, 46.8223],
                        [-54.1969, 46.833],
                        [-54.1859, 46.8414],
                        [-54.1867, 46.8543],
                        [-54.195, 46.8646],
                        [-54.1934, 46.8743],
                        [-54.1958, 46.879],
                        [-54.1944, 46.8904],
                        [-54.1895, 46.8938],
                        [-54.188, 46.9058],
                        [-54.1825, 46.9076],
                        [-54.1812, 46.9149],
                        [-54.1758, 46.9212],
                        [-54.1829, 46.9274],
                        [-54.1828, 46.9338],
                        [-54.1909, 46.9365],
                        [-54.1777, 46.9526],
                        [-54.1709, 46.9548],
                        [-54.1716, 46.9655],
                        [-54.1676, 46.9708],
                        [-54.1654, 46.9843],
                        [-54.168, 46.9903],
                        [-54.1577, 46.9936],
                        [-54.1474, 47.002],
                        [-54.1476, 47.0108],
                        [-54.1422, 47.0207],
                        [-54.1178, 47.0385],
                        [-54.1219, 47.0456],
                        [-54.1104, 47.0539],
                        [-54.1092, 47.0585],
                        [-54.0952, 47.0718],
                        [-54.0979, 47.0796],
                        [-54.0877, 47.0923],
                        [-54.0769, 47.0953],
                        [-54.081, 47.1018],
                        [-54.0765, 47.1061],
                        [-54.0776, 47.1127],
                        [-54.0721, 47.1199],
                        [-54.0715, 47.1276],
                        [-54.0654, 47.1323],
                        [-54.0666, 47.1455],
                        [-54.0607, 47.1547],
                        [-54.0616, 47.1621],
                        [-54.057, 47.1693],
                        [-54.0452, 47.1776],
                        [-54.0427, 47.1877],
                        [-54.0439, 47.1945],
                        [-54.0319, 47.2064],
                        [-54.0271, 47.215],
                        [-54.0179, 47.224],
                        [-54.017, 47.2392],
                        [-53.9993, 47.2363],
                        [-53.9845, 47.2396],
                        [-53.9747, 47.2378],
                        [-53.9624, 47.2498],
                        [-53.9582, 47.2491],
                        [-53.9278, 47.2646],
                        [-53.9197, 47.2597],
                        [-53.8905, 47.2638],
                        [-53.8813, 47.2625],
                        [-53.8703, 47.2664],
                        [-53.8617, 47.2629],
                        [-53.8505, 47.2645],
                        [-53.8507, 47.2697],
                        [-53.8841, 47.2654],
                        [-53.892, 47.2676],
                        [-53.9191, 47.2675],
                        [-53.9357, 47.2686],
                        [-53.9436, 47.2629],
                        [-53.9716, 47.2495],
                        [-53.9937, 47.2532],
                        [-53.9959, 47.2586],
                        [-54.0062, 47.2649],
                        [-54.0037, 47.2729],
                        [-54.0068, 47.2806],
                        [-53.9962, 47.2857],
                        [-53.9961, 47.2978],
                        [-54.0052, 47.3164],
                        [-53.993, 47.3179],
                        [-53.9862, 47.3149],
                        [-53.9753, 47.3184],
                        [-53.9714, 47.315],
                        [-53.973, 47.307],
                        [-53.9841, 47.3033],
                        [-53.9835, 47.2973],
                        [-53.9905, 47.2933],
                        [-53.9848, 47.2877],
                        [-53.9548, 47.3048],
                        [-53.9312, 47.3043],
                        [-53.9226, 47.3],
                        [-53.9064, 47.3012],
                        [-53.918, 47.3089],
                        [-53.9295, 47.314],
                        [-53.9247, 47.3186],
                        [-53.9115, 47.3184],
                        [-53.9217, 47.3287],
                        [-53.9385, 47.3207],
                        [-53.9358, 47.3296],
                        [-53.9206, 47.33],
                        [-53.9042, 47.3423],
                        [-53.8801, 47.3508],
                        [-53.8888, 47.3534],
                        [-53.8777, 47.3752],
                        [-53.884, 47.373],
                        [-53.901, 47.3557],
                        [-53.9129, 47.349],
                        [-53.932, 47.3541],
                        [-53.9281, 47.3601],
                        [-53.9141, 47.3626],
                        [-53.9164, 47.3745],
                        [-53.9282, 47.375],
                        [-53.9274, 47.3843],
                        [-53.9203, 47.3891],
                        [-53.9022, 47.3967],
                        [-53.8917, 47.3978],
                        [-53.8835, 47.4031],
                        [-53.8744, 47.4044],
                        [-53.8588, 47.4102],
                        [-53.8415, 47.4117],
                        [-53.8164, 47.421],
                        [-53.8218, 47.4235],
                        [-53.8078, 47.4314],
                        [-53.8006, 47.4324],
                        [-53.7949, 47.4406],
                        [-53.8162, 47.431],
                        [-53.8294, 47.4266],
                        [-53.8423, 47.4258],
                        [-53.8498, 47.4285],
                        [-53.87, 47.4288],
                        [-53.8657, 47.4364],
                        [-53.8597, 47.4383],
                        [-53.8532, 47.4467],
                        [-53.8605, 47.4533],
                        [-53.8682, 47.444],
                        [-53.8812, 47.4351],
                        [-53.8913, 47.4311],
                        [-53.9205, 47.4241],
                        [-53.9243, 47.4252],
                        [-53.9147, 47.4384],
                        [-53.9259, 47.4443],
                        [-53.9151, 47.4567],
                        [-53.9353, 47.4507],
                        [-53.9325, 47.4643],
                        [-53.9264, 47.4702],
                        [-53.9213, 47.4801],
                        [-53.9113, 47.488],
                        [-53.915, 47.4961],
                        [-53.9064, 47.5094],
                        [-53.9069, 47.5158],
                        [-53.896, 47.5295],
                        [-53.8997, 47.5315],
                        [-53.9122, 47.5263],
                        [-53.9137, 47.5306],
                        [-53.9069, 47.5392],
                        [-53.9122, 47.5455],
                        [-53.9059, 47.5526],
                        [-53.9091, 47.5602],
                        [-53.9022, 47.567],
                        [-53.8969, 47.5765],
                        [-53.8965, 47.5839],
                        [-53.9008, 47.5873],
                        [-53.9009, 47.6036],
                        [-53.9103, 47.6111],
                        [-53.9176, 47.6122],
                        [-53.9301, 47.6082],
                        [-53.9383, 47.6229],
                        [-53.9478, 47.628],
                        [-53.9355, 47.6365],
                        [-53.9396, 47.6436],
                        [-53.9524, 47.6507],
                        [-53.9552, 47.6614],
                        [-53.9503, 47.6643],
                        [-53.9398, 47.678],
                        [-53.9424, 47.6878],
                        [-53.9399, 47.6925],
                        [-53.95, 47.6962],
                        [-53.9548, 47.6874],
                        [-53.9651, 47.6867],
                        [-53.9641, 47.694],
                        [-53.969, 47.6985],
                        [-53.9587, 47.7035],
                        [-53.9542, 47.7104],
                        [-53.9599, 47.7229],
                        [-53.9678, 47.7152],
                        [-53.9702, 47.7081],
                        [-53.9789, 47.7075],
                        [-53.9653, 47.7256],
                        [-53.9653, 47.7416],
                        [-53.9712, 47.7589],
                        [-53.9777, 47.7646],
                        [-53.9849, 47.7655],
                        [-54.0127, 47.7629],
                        [-54.0117, 47.7585],
                        [-54.0265, 47.7531],
                        [-54.0263, 47.7599],
                        [-54.0157, 47.7667],
                        [-54.0087, 47.7769],
                        [-54.0022, 47.7957],
                        [-54.0104, 47.8058],
                        [-54.0077, 47.8173],
                        [-54.0037, 47.8204],
                        [-54.0004, 47.8307],
                        [-53.9918, 47.8354],
                        [-53.9945, 47.843],
                        [-54.003, 47.838],
                        [-54.0023, 47.8306],
                        [-54.0185, 47.8237],
                        [-54.0307, 47.812],
                        [-54.0335, 47.8057],
                        [-54.0466, 47.7935],
                        [-54.0554, 47.8001],
                        [-54.0639, 47.8011],
                        [-54.0792, 47.8114],
                        [-54.0769, 47.8174],
                        [-54.0649, 47.8316],
                        [-54.0832, 47.8476],
                        [-54.0806, 47.8555],
                        [-54.0811, 47.8661],
                        [-54.085, 47.8814],
                        [-54.0957, 47.8701],
                        [-54.0963, 47.8598],
                        [-54.1019, 47.848],
                        [-54.0954, 47.8434],
                        [-54.0933, 47.8296],
                        [-54.0995, 47.8202],
                        [-54.1069, 47.8249],
                        [-54.1085, 47.8316],
                        [-54.1231, 47.8329],
                        [-54.1321, 47.8372],
                        [-54.1339, 47.8424],
                        [-54.1469, 47.8466],
                        [-54.1534, 47.8439],
                        [-54.1609, 47.8531],
                        [-54.1704, 47.8599],
                        [-54.1729, 47.8697],
                        [-54.1693, 47.8792],
                        [-54.1863, 47.8773],
                        [-54.1941, 47.881],
                        [-54.2088, 47.879],
                        [-54.2224, 47.8865],
                        [-54.227, 47.8862],
                        [-54.2443, 47.8963],
                        [-54.2558, 47.9068],
                        [-54.2639, 47.9106],
                        [-54.2728, 47.9029],
                        [-54.2569, 47.8913],
                        [-54.2347, 47.8877],
                        [-54.2256, 47.8835],
                        [-54.2231, 47.8745],
                        [-54.2091, 47.8742],
                        [-54.1989, 47.8673],
                        [-54.2003, 47.8621],
                        [-54.1891, 47.8603],
                        [-54.1936, 47.8428],
                        [-54.1977, 47.8375],
                        [-54.2023, 47.8197],
                        [-54.2064, 47.8149],
                        [-54.2075, 47.7951],
                        [-54.2126, 47.783],
                        [-54.2249, 47.7679],
                        [-54.2325, 47.766],
                        [-54.2443, 47.7492],
                        [-54.2559, 47.735],
                        [-54.2532, 47.732],
                        [-54.2624, 47.7143],
                        [-54.2696, 47.7061],
                        [-54.2835, 47.7035],
                        [-54.2983, 47.6823],
                        [-54.308, 47.6865],
                        [-54.3179, 47.684],
                        [-54.3259, 47.6899],
                        [-54.3299, 47.6831],
                        [-54.3275, 47.6666],
                        [-54.3193, 47.6642],
                        [-54.3206, 47.6482],
                        [-54.3245, 47.6384],
                        [-54.3438, 47.6348],
                        [-54.3392, 47.6282],
                        [-54.3417, 47.6206],
                        [-54.3507, 47.6172],
                        [-54.349, 47.611],
                        [-54.3611, 47.6018],
                        [-54.3782, 47.5718],
                        [-54.3873, 47.5577],
                        [-54.3961, 47.5488],
                        [-54.4006, 47.5386],
                        [-54.4096, 47.531],
                        [-54.4104, 47.5261],
                        [-54.4228, 47.5182],
                        [-54.43, 47.5105],
                        [-54.4395, 47.5044],
                        [-54.4532, 47.501],
                        [-54.4775, 47.4909],
                        [-54.4742, 47.489],
                        [-54.4592, 47.4952],
                        [-54.4522, 47.4801],
                        [-54.4477, 47.4812],
                        [-54.4354, 47.4971],
                        [-54.4262, 47.4946],
                        [-54.4272, 47.4879],
                        [-54.4333, 47.4799],
                        [-54.4344, 47.4723],
                        [-54.4277, 47.4645],
                        [-54.4178, 47.4623],
                        [-54.4048, 47.4518],
                        [-54.4141, 47.4461],
                        [-54.4183, 47.4399],
                        [-54.4334, 47.4291],
                        [-54.4432, 47.4201],
                        [-54.4493, 47.4197],
                        [-54.4562, 47.4134],
                        [-54.4647, 47.4205],
                        [-54.4632, 47.4349],
                        [-54.4459, 47.4545],
                        [-54.4532, 47.4601],
                        [-54.4618, 47.4462],
                        [-54.4775, 47.4378],
                        [-54.486, 47.424],
                        [-54.4935, 47.416],
                        [-54.5098, 47.4046],
                        [-54.5155, 47.3942],
                        [-54.4965, 47.3911],
                        [-54.4812, 47.408],
                        [-54.4784, 47.3952],
                        [-54.4905, 47.3871],
                        [-54.5015, 47.3828],
                        [-54.5038, 47.3791],
                        [-54.5186, 47.3715],
                        [-54.5201, 47.366],
                        [-54.5311, 47.3686],
                        [-54.521, 47.383],
                        [-54.5309, 47.3923],
                        [-54.5582, 47.3671],
                        [-54.5813, 47.3552],
                        [-54.5872, 47.3491],
                        [-54.6038, 47.3545],
                        [-54.6029, 47.3637],
                        [-54.6137, 47.3628],
                        [-54.6134, 47.3704],
                        [-54.6076, 47.3817],
                        [-54.6022, 47.3836],
                        [-54.5966, 47.3924],
                        [-54.5881, 47.397],
                        [-54.5844, 47.3915],
                        [-54.5701, 47.3996],
                        [-54.572, 47.4078],
                        [-54.5633, 47.4183],
                        [-54.5497, 47.4263],
                        [-54.5499, 47.4289],
                        [-54.5312, 47.4415],
                        [-54.5171, 47.4578],
                        [-54.517, 47.4616],
                        [-54.5011, 47.4775],
                        [-54.5037, 47.481],
                        [-54.5007, 47.4897],
                        [-54.4899, 47.5055],
                        [-54.4808, 47.5161],
                        [-54.4687, 47.5356],
                        [-54.464, 47.5408],
                        [-54.446, 47.5661],
                        [-54.439, 47.5706],
                        [-54.4375, 47.5763],
                        [-54.4292, 47.5795],
                        [-54.4289, 47.5874],
                        [-54.4225, 47.5919],
                        [-54.4089, 47.6072],
                        [-54.4141, 47.6105],
                        [-54.4223, 47.609],
                        [-54.4237, 47.6031],
                        [-54.4414, 47.582],
                        [-54.4526, 47.576],
                        [-54.4628, 47.5653],
                        [-54.4691, 47.5624],
                        [-54.4787, 47.5501],
                        [-54.4861, 47.5479],
                        [-54.4964, 47.5318],
                        [-54.495, 47.5269],
                        [-54.5084, 47.5117],
                        [-54.5196, 47.4921],
                        [-54.5288, 47.4836],
                        [-54.5309, 47.4737],
                        [-54.5358, 47.4712],
                        [-54.5429, 47.4588],
                        [-54.5564, 47.4434],
                        [-54.5787, 47.4253],
                        [-54.5883, 47.4245],
                        [-54.5997, 47.4109],
                        [-54.6313, 47.3892],
                        [-54.6399, 47.391],
                        [-54.6576, 47.386],
                        [-54.6535, 47.3978],
                        [-54.6544, 47.4093],
                        [-54.6699, 47.3953],
                        [-54.6642, 47.3868],
                        [-54.6691, 47.3822],
                        [-54.6693, 47.3751],
                        [-54.6845, 47.3802],
                        [-54.6849, 47.3874],
                        [-54.6762, 47.401],
                        [-54.687, 47.3978],
                        [-54.6785, 47.4099],
                        [-54.6701, 47.4175],
                        [-54.6597, 47.423],
                        [-54.6548, 47.4313],
                        [-54.6615, 47.4327],
                        [-54.665, 47.4259],
                        [-54.6764, 47.4146],
                        [-54.6825, 47.4158],
                        [-54.6875, 47.4038],
                        [-54.7077, 47.3865],
                        [-54.7168, 47.3823],
                        [-54.7078, 47.3776],
                        [-54.7079, 47.3675],
                        [-54.7134, 47.3623],
                        [-54.7344, 47.3556],
                        [-54.7384, 47.36],
                        [-54.7337, 47.3644],
                        [-54.7304, 47.3744],
                        [-54.7311, 47.3825],
                        [-54.7229, 47.4037],
                        [-54.7286, 47.4044],
                        [-54.7323, 47.391],
                        [-54.7402, 47.3834],
                        [-54.7398, 47.3757],
                        [-54.7456, 47.3702],
                        [-54.7707, 47.3615],
                        [-54.7796, 47.3675],
                        [-54.7719, 47.3706],
                        [-54.7799, 47.3821],
                        [-54.7893, 47.3733],
                        [-54.7963, 47.3741],
                        [-54.8087, 47.3669],
                        [-54.8214, 47.3677],
                        [-54.824, 47.3746],
                        [-54.808, 47.3842],
                        [-54.8039, 47.403],
                        [-54.79, 47.4232],
                        [-54.8013, 47.4209],
                        [-54.8082, 47.4099],
                        [-54.8179, 47.4001],
                        [-54.8245, 47.3823],
                        [-54.8345, 47.3757],
                        [-54.8409, 47.3826],
                        [-54.8394, 47.3878],
                        [-54.8319, 47.3938],
                        [-54.8279, 47.409],
                        [-54.818, 47.4211],
                        [-54.8286, 47.4225],
                        [-54.8403, 47.4082],
                        [-54.8448, 47.3979],
                        [-54.8551, 47.3938],
                        [-54.8527, 47.3856],
                        [-54.8643, 47.3801],
                        [-54.8691, 47.3735],
                        [-54.8801, 47.371],
                        [-54.8816, 47.3643],
                        [-54.8894, 47.3597],
                        [-54.8905, 47.3507],
                        [-54.9066, 47.3405],
                        [-54.9118, 47.3476],
                        [-54.9203, 47.345],
                        [-54.9198, 47.3385],
                        [-54.9297, 47.319],
                        [-54.9262, 47.3141],
                        [-54.9178, 47.3142],
                        [-54.9223, 47.3021],
                        [-54.934, 47.3131],
                        [-54.942, 47.3073],
                        [-54.9457, 47.298],
                        [-54.9549, 47.2935],
                        [-54.9686, 47.2833],
                        [-54.9731, 47.2774],
                        [-54.9809, 47.2775],
                        [-54.9813, 47.2895],
                        [-54.9845, 47.2938],
                        [-54.9946, 47.296],
                        [-54.9922, 47.2848],
                        [-55.0068, 47.2698],
                        [-55.008, 47.2622],
                        [-55.0172, 47.25],
                        [-55.0362, 47.2392],
                        [-55.0446, 47.2369],
                        [-55.0481, 47.2268],
                        [-55.0598, 47.2267],
                        [-55.0566, 47.2181],
                        [-55.0636, 47.2132],
                        [-55.0573, 47.2066],
                        [-55.0433, 47.2097],
                        [-55.0395, 47.2012],
                        [-55.0309, 47.1995],
                        [-55.0248, 47.1906],
                        [-55.0169, 47.1875],
                        [-55.0166, 47.1801],
                        [-55.0323, 47.1761],
                        [-55.0332, 47.1645],
                        [-55.0385, 47.1657],
                        [-55.0424, 47.1744],
                        [-55.0578, 47.1584],
                        [-55.057, 47.1497],
                        [-55.0678, 47.1439],
                        [-55.0738, 47.1457],
                        [-55.0775, 47.1526],
                        [-55.0711, 47.1722],
                        [-55.0746, 47.1766],
                        [-55.0702, 47.1891],
                        [-55.0785, 47.1981],
                        [-55.0922, 47.1983],
                        [-55.0946, 47.1955],
                        [-55.0804, 47.1882],
                        [-55.0866, 47.1827],
                        [-55.0993, 47.2022],
                        [-55.1274, 47.1926],
                        [-55.1361, 47.1811],
                        [-55.1389, 47.172],
                        [-55.1461, 47.1634],
                        [-55.1208, 47.17],
                        [-55.1122, 47.1669],
                        [-55.1081, 47.1713],
                        [-55.0883, 47.1646],
                        [-55.0891, 47.1523],
                        [-55.0921, 47.1459],
                        [-55.088, 47.1404],
                        [-55.0908, 47.1326],
                        [-55.0844, 47.1245],
                        [-55.0913, 47.1193],
                        [-55.0843, 47.1078],
                        [-55.0698, 47.1014],
                        [-55.0779, 47.0911],
                        [-55.0776, 47.0846],
                        [-55.0922, 47.0799],
                        [-55.1017, 47.0839],
                        [-55.1041, 47.0923],
                        [-55.1127, 47.0848],
                        [-55.1028, 47.0747],
                        [-55.1096, 47.0676],
                        [-55.1037, 47.062],
                        [-55.1084, 47.0557],
                        [-55.1242, 47.0517],
                        [-55.1323, 47.0557],
                        [-55.1396, 47.0506],
                        [-55.1482, 47.0511],
                        [-55.1546, 47.0456],
                        [-55.1619, 47.0496],
                        [-55.1701, 47.0463],
                        [-55.1661, 47.0398],
                        [-55.1645, 47.0196],
                        [-55.1742, 47.018],
                        [-55.1862, 47.0344],
                        [-55.182, 47.0436],
                        [-55.1743, 47.0471],
                        [-55.1836, 47.0546],
                        [-55.1867, 47.0611],
                        [-55.1799, 47.0723],
                        [-55.1992, 47.0816],
                        [-55.2021, 47.0912],
                        [-55.2129, 47.0871],
                        [-55.2015, 47.0708],
                        [-55.2059, 47.0638],
                        [-55.2049, 47.0586],
                        [-55.1919, 47.0505],
                        [-55.1937, 47.0471],
                        [-55.191, 47.0349],
                        [-55.196, 47.0283],
                        [-55.1886, 47.0246],
                        [-55.1916, 47.0122],
                        [-55.2017, 47.0058],
                        [-55.1997, 46.9997],
                        [-55.2055, 46.9895],
                        [-55.2026, 46.9824],
                        [-55.2249, 46.967],
                        [-55.2243, 46.9604],
                        [-55.2315, 46.9593],
                        [-55.2353, 46.949],
                        [-55.2393, 46.9466],
                        [-55.2332, 46.9396],
                        [-55.2404, 46.933],
                        [-55.2619, 46.924],
                        [-55.269, 46.9181],
                        [-55.2882, 46.9064],
                        [-55.3016, 46.9012],
                        [-55.3217, 46.9072],
                        [-55.3223, 46.9042],
                        [-55.3361, 46.9026],
                        [-55.3367, 46.9071],
                        [-55.3445, 46.9175],
                        [-55.3539, 46.9241],
                        [-55.3603, 46.9198],
                        [-55.3473, 46.902],
                        [-55.3547, 46.898],
                        [-55.3722, 46.9032],
                        [-55.384, 46.9153],
                        [-55.3886, 46.9134],
                        [-55.3845, 46.8989],
                        [-55.368, 46.8922],
                        [-55.3764, 46.8864],
                        [-55.3752, 46.8785],
                        [-55.3839, 46.8742],
                        [-55.3971, 46.8779],
                        [-55.4031, 46.8828],
                        [-55.4099, 46.8732],
                        [-55.4203, 46.8801],
                        [-55.432, 46.8761],
                        [-55.4449, 46.8786],
                        [-55.4785, 46.8775],
                        [-55.4867, 46.8841],
                        [-55.4933, 46.9006],
                        [-55.491, 46.9072],
                        [-55.4805, 46.9242],
                        [-55.4741, 46.9309],
                        [-55.4947, 46.9278],
                        [-55.5065, 46.9219],
                        [-55.5112, 46.914],
                        [-55.5349, 46.9185],
                        [-55.5442, 46.923],
                        [-55.5396, 46.9312],
                        [-55.5472, 46.9343],
                        [-55.5534, 46.9248],
                        [-55.554, 46.9148],
                        [-55.56, 46.9049],
                        [-55.5715, 46.901],
                        [-55.5736, 46.9063],
                        [-55.5694, 46.9157],
                        [-55.5742, 46.9207],
                        [-55.5872, 46.9109],
                        [-55.608, 46.8982],
                        [-55.6201, 46.8983],
                        [-55.6282, 46.888],
                        [-55.6256, 46.8792],
                        [-55.6334, 46.8755],
                        [-55.6399, 46.8809],
                        [-55.6472, 46.8818],
                        [-55.653, 46.8761],
                        [-55.6695, 46.8761],
                        [-55.6803, 46.874],
                        [-55.6983, 46.8624],
                        [-55.7095, 46.8682],
                        [-55.7094, 46.8739],
                        [-55.7165, 46.8794],
                        [-55.7352, 46.8666],
                        [-55.7347, 46.8596],
                        [-55.7494, 46.8585],
                        [-55.7569, 46.8675],
                        [-55.7755, 46.8706],
                        [-55.7786, 46.8673],
                        [-55.7921, 46.8625],
                        [-55.7986, 46.8699],
                        [-55.813, 46.8607],
                        [-55.8319, 46.8632],
                        [-55.8439, 46.8733],
                        [-55.8538, 46.8764],
                        [-55.8614, 46.8753],
                        [-55.8665, 46.8797],
                        [-55.8849, 46.8834],
                        [-55.8902, 46.8887],
                        [-55.9073, 46.8899],
                        [-55.9174, 46.8944],
                        [-55.9355, 46.8948],
                        [-55.9359, 46.8978],
                        [-55.9516, 46.9066],
                        [-55.9611, 46.9068],
                        [-55.9708, 46.9111],
                        [-55.9722, 46.9262],
                        [-55.9817, 46.9403],
                        [-55.981, 46.9481],
                        [-55.9874, 46.9495],
                        [-55.988, 46.959],
                        [-55.9821, 46.9715],
                        [-55.9737, 46.9761],
                        [-55.9729, 46.9837],
                        [-55.9771, 46.9894],
                        [-55.9739, 46.9965],
                        [-55.9573, 47.004],
                        [-55.9483, 47.0128],
                        [-55.9386, 47.0134],
                        [-55.9232, 47.0229],
                        [-55.9103, 47.0328],
                        [-55.8912, 47.0529],
                        [-55.8783, 47.0644],
                        [-55.8669, 47.072],
                        [-55.8427, 47.0796],
                        [-55.8252, 47.0752],
                        [-55.813, 47.0789],
                        [-55.7943, 47.0923],
                        [-55.7907, 47.0969],
                        [-55.7741, 47.11],
                        [-55.7648, 47.1025],
                        [-55.7538, 47.104],
                        [-55.7473, 47.0987],
                        [-55.738, 47.0985],
                        [-55.7177, 47.1024],
                        [-55.7057, 47.0954],
                        [-55.6814, 47.0879],
                        [-55.6712, 47.0954],
                        [-55.6612, 47.1069],
                        [-55.6528, 47.1058],
                        [-55.6393, 47.1105],
                        [-55.6325, 47.1098],
                        [-55.6205, 47.1157],
                        [-55.6072, 47.1192],
                        [-55.5906, 47.1172],
                        [-55.573, 47.1223],
                        [-55.5562, 47.1316],
                        [-55.5428, 47.1337],
                        [-55.5225, 47.1423],
                        [-55.5075, 47.1379],
                        [-55.5013, 47.1323],
                        [-55.4927, 47.1309],
                        [-55.4825, 47.1334],
                        [-55.4741, 47.1417],
                        [-55.4738, 47.1489],
                        [-55.4851, 47.1502],
                        [-55.4771, 47.1681],
                        [-55.4697, 47.1768],
                        [-55.4627, 47.189],
                        [-55.4362, 47.192],
                        [-55.432, 47.2002],
                        [-55.4207, 47.205],
                        [-55.4116, 47.2196],
                        [-55.4006, 47.2171],
                        [-55.3864, 47.2189],
                        [-55.3777, 47.2279],
                        [-55.3661, 47.2347],
                        [-55.3567, 47.2359],
                        [-55.3478, 47.2434],
                        [-55.3388, 47.2441],
                        [-55.3331, 47.2518],
                        [-55.3272, 47.2693],
                        [-55.3111, 47.2704],
                        [-55.3089, 47.2753],
                        [-55.3117, 47.2872],
                        [-55.3035, 47.2978],
                        [-55.3061, 47.3071],
                        [-55.3012, 47.3204],
                        [-55.3, 47.3374],
                        [-55.2977, 47.3452],
                        [-55.2866, 47.3691],
                        [-55.2888, 47.3813],
                        [-55.2937, 47.3862],
                        [-55.2856, 47.3965],
                        [-55.2785, 47.4007],
                        [-55.2737, 47.4079],
                        [-55.2638, 47.4135],
                        [-55.2498, 47.4164],
                        [-55.2428, 47.423],
                        [-55.2207, 47.4344],
                        [-55.2203, 47.4407],
                        [-55.2141, 47.4423],
                        [-55.193, 47.4595],
                        [-55.1707, 47.4721],
                        [-55.1508, 47.4706],
                        [-55.1424, 47.4733],
                        [-55.1305, 47.4824],
                        [-55.1102, 47.4892],
                        [-55.1034, 47.4899],
                        [-55.0837, 47.4863],
                        [-55.0759, 47.4913],
                        [-55.0651, 47.4945],
                        [-55.0399, 47.4944],
                        [-55.0329, 47.5015],
                        [-55.0244, 47.5046],
                        [-55.017, 47.5018],
                        [-54.9893, 47.5064],
                        [-54.9684, 47.5069],
                        [-54.9582, 47.5038],
                        [-54.9521, 47.5109],
                        [-54.9626, 47.5233],
                        [-54.9474, 47.5293],
                        [-54.9318, 47.529],
                        [-54.9276, 47.5249],
                        [-54.9166, 47.527],
                        [-54.8971, 47.5359],
                        [-54.8808, 47.5392],
                        [-54.8872, 47.5428],
                        [-54.9026, 47.5384],
                        [-54.9069, 47.544],
                        [-54.8939, 47.5506],
                        [-54.8832, 47.5518],
                        [-54.8775, 47.5416],
                        [-54.8623, 47.5513],
                        [-54.8757, 47.5593],
                        [-54.8586, 47.5595],
                        [-54.8465, 47.5544],
                        [-54.8403, 47.5594],
                        [-54.8437, 47.5681],
                        [-54.8641, 47.5659],
                        [-54.853, 47.574],
                        [-54.8545, 47.5817],
                        [-54.8403, 47.5905],
                        [-54.8439, 47.5954],
                        [-54.8522, 47.5913],
                        [-54.8667, 47.5891],
                        [-54.8795, 47.5704],
                        [-54.887, 47.5703],
                        [-54.8858, 47.5836],
                        [-54.8691, 47.5933],
                        [-54.8743, 47.6028],
                        [-54.8633, 47.6051],
                        [-54.8502, 47.6136],
                        [-54.8354, 47.6206],
                        [-54.8156, 47.6265],
                        [-54.8033, 47.6338],
                        [-54.7888, 47.6387],
                        [-54.7711, 47.6473],
                        [-54.7288, 47.6634],
                        [-54.7248, 47.6677],
                        [-54.7106, 47.6663],
                        [-54.7022, 47.6729],
                        [-54.7274, 47.6791],
                        [-54.7388, 47.6774],
                        [-54.7736, 47.6659],
                        [-54.7811, 47.6752],
                        [-54.7893, 47.6759],
                        [-54.7869, 47.6666],
                        [-54.7903, 47.6605],
                        [-54.8164, 47.6519],
                        [-54.8317, 47.6525],
                        [-54.8386, 47.6467],
                        [-54.8544, 47.6403],
                        [-54.865, 47.6385],
                        [-54.8727, 47.6295],
                        [-54.8861, 47.6329],
                        [-54.8823, 47.6253],
                        [-54.9143, 47.6175],
                        [-54.9225, 47.621],
                        [-54.9318, 47.6141],
                        [-54.9337, 47.6079],
                        [-54.9458, 47.606],
                        [-54.9598, 47.6013],
                        [-54.9647, 47.5962],
                        [-54.9787, 47.5938],
                        [-54.9857, 47.5995],
                        [-54.9853, 47.6077],
                        [-54.977, 47.6187],
                        [-54.9906, 47.6145],
                        [-54.9902, 47.6058],
                        [-55.0035, 47.6018],
                        [-55.0124, 47.5952],
                        [-55.0313, 47.5921],
                        [-55.0278, 47.6004],
                        [-55.039, 47.6014],
                        [-55.0597, 47.5878],
                        [-55.0784, 47.5847],
                        [-55.0927, 47.5922],
                        [-55.0882, 47.6002],
                        [-55.093, 47.6067],
                        [-55.093, 47.6137],
                        [-55.0745, 47.6294],
                        [-55.066, 47.6403],
                        [-55.0637, 47.6479],
                        [-55.0559, 47.658],
                        [-55.0373, 47.6662],
                        [-55.027, 47.6771],
                        [-55.0232, 47.6862],
                        [-55.012, 47.6889],
                        [-55.0135, 47.6989],
                        [-55.0178, 47.7044],
                        [-55.015, 47.7107],
                        [-55.0063, 47.7168],
                        [-54.995, 47.7204],
                        [-54.9886, 47.7305],
                        [-54.9698, 47.7504],
                        [-54.9612, 47.7528],
                        [-54.9501, 47.7626],
                        [-54.9517, 47.7654],
                        [-54.9407, 47.782],
                        [-54.9479, 47.7847],
                        [-54.959, 47.7788],
                        [-54.9718, 47.7625],
                        [-54.9875, 47.7509],
                        [-54.9948, 47.7403],
                        [-54.9966, 47.7327],
                        [-55.0097, 47.7268],
                        [-55.0206, 47.7192],
                        [-55.0303, 47.7084],
                        [-55.0307, 47.6912],
                        [-55.0345, 47.6807],
                        [-55.0499, 47.674],
                        [-55.0496, 47.6692],
                        [-55.0592, 47.6667],
                        [-55.0678, 47.6585],
                        [-55.0763, 47.6545],
                        [-55.0708, 47.6404],
                        [-55.0838, 47.6409],
                        [-55.0914, 47.6334],
                        [-55.1034, 47.6264],
                        [-55.1051, 47.6143],
                        [-55.1122, 47.6037],
                        [-55.1034, 47.6002],
                        [-55.1075, 47.589],
                        [-55.1375, 47.5771],
                        [-55.1467, 47.5844],
                        [-55.1427, 47.597],
                        [-55.1558, 47.5984],
                        [-55.1546, 47.6077],
                        [-55.1629, 47.6124],
                        [-55.1532, 47.6177],
                        [-55.1424, 47.6296],
                        [-55.1501, 47.6392],
                        [-55.1447, 47.641],
                        [-55.1292, 47.6594],
                        [-55.1357, 47.6678],
                        [-55.1449, 47.6605],
                        [-55.1507, 47.6512],
                        [-55.1626, 47.6452],
                        [-55.1652, 47.6391],
                        [-55.1795, 47.637],
                        [-55.1817, 47.6328],
                        [-55.2035, 47.6325],
                        [-55.2064, 47.6353],
                        [-55.2246, 47.637],
                        [-55.2357, 47.6425],
                        [-55.2237, 47.6551],
                        [-55.24, 47.6559],
                        [-55.2451, 47.6483],
                        [-55.2565, 47.6499],
                        [-55.2667, 47.6468],
                        [-55.2881, 47.6454],
                        [-55.2943, 47.6474],
                        [-55.3182, 47.6611],
                        [-55.3278, 47.6708],
                        [-55.3161, 47.686],
                        [-55.3354, 47.6732],
                        [-55.3337, 47.6674],
                        [-55.3452, 47.6576],
                        [-55.3473, 47.6505],
                        [-55.3599, 47.6493],
                        [-55.3686, 47.6527],
                        [-55.3743, 47.6467],
                        [-55.3845, 47.6464],
                        [-55.3788, 47.6549],
                        [-55.3676, 47.6564],
                        [-55.3626, 47.662],
                        [-55.3708, 47.6661],
                        [-55.3819, 47.6667],
                        [-55.3867, 47.6784],
                        [-55.3666, 47.6909],
                        [-55.3641, 47.6974],
                        [-55.3522, 47.708],
                        [-55.3571, 47.7141],
                        [-55.3714, 47.7106],
                        [-55.3645, 47.7262],
                        [-55.374, 47.7274],
                        [-55.3856, 47.7235],
                        [-55.3941, 47.7238],
                        [-55.4006, 47.7197],
                        [-55.3983, 47.7137],
                        [-55.3879, 47.7034],
                        [-55.3898, 47.6967],
                        [-55.3981, 47.6888],
                        [-55.409, 47.6908],
                        [-55.4146, 47.6965],
                        [-55.4118, 47.7039],
                        [-55.4264, 47.7113],
                        [-55.4418, 47.7085],
                        [-55.4426, 47.703],
                        [-55.4339, 47.6862],
                        [-55.4262, 47.6761],
                        [-55.4296, 47.6702],
                        [-55.4493, 47.6515],
                        [-55.4524, 47.6456],
                        [-55.4631, 47.6438],
                        [-55.4694, 47.6381],
                        [-55.4691, 47.6335],
                        [-55.4764, 47.6248],
                        [-55.4635, 47.6116],
                        [-55.4441, 47.6273],
                        [-55.4308, 47.6312],
                        [-55.4198, 47.6301],
                        [-55.4167, 47.634],
                        [-55.4012, 47.6203],
                        [-55.4101, 47.6074],
                        [-55.4418, 47.5928],
                        [-55.4481, 47.5865],
                        [-55.4287, 47.5838],
                        [-55.4286, 47.5881],
                        [-55.4177, 47.593],
                        [-55.4065, 47.5821],
                        [-55.4002, 47.5933],
                        [-55.392, 47.5914],
                        [-55.3917, 47.5814],
                        [-55.4007, 47.5796],
                        [-55.41, 47.5699],
                        [-55.4011, 47.5607],
                        [-55.422, 47.5454],
                        [-55.4191, 47.5301],
                        [-55.402, 47.5217],
                        [-55.4049, 47.5164],
                        [-55.4096, 47.4892],
                        [-55.4134, 47.4819],
                        [-55.4205, 47.483],
                        [-55.4218, 47.4911],
                        [-55.4316, 47.489],
                        [-55.4258, 47.4753],
                        [-55.4301, 47.4703],
                        [-55.4412, 47.4659],
                        [-55.466, 47.4668],
                        [-55.4693, 47.4781],
                        [-55.4766, 47.4809],
                        [-55.477, 47.4708],
                        [-55.4742, 47.4605],
                        [-55.5045, 47.4541],
                        [-55.5141, 47.4572],
                        [-55.5264, 47.4571],
                        [-55.5277, 47.4648],
                        [-55.5338, 47.4646],
                        [-55.5347, 47.4566],
                        [-55.5457, 47.4485],
                        [-55.5474, 47.4568],
                        [-55.555, 47.4562],
                        [-55.5632, 47.4465],
                        [-55.5734, 47.4383],
                        [-55.5761, 47.4323],
                        [-55.5747, 47.4185],
                        [-55.5801, 47.4133],
                        [-55.5832, 47.4042],
                        [-55.6025, 47.3997],
                        [-55.6105, 47.4008],
                        [-55.617, 47.4061],
                        [-55.6068, 47.4122],
                        [-55.597, 47.4213],
                        [-55.5948, 47.4396],
                        [-55.6093, 47.4391],
                        [-55.609, 47.4458],
                        [-55.5901, 47.4559],
                        [-55.5956, 47.4608],
                        [-55.6071, 47.4536],
                        [-55.6135, 47.4564],
                        [-55.6219, 47.4525],
                        [-55.6396, 47.4374],
                        [-55.6525, 47.4321],
                        [-55.6625, 47.44],
                        [-55.6532, 47.443],
                        [-55.6428, 47.4497],
                        [-55.6289, 47.4545],
                        [-55.6346, 47.4586],
                        [-55.629, 47.471],
                        [-55.6164, 47.479],
                        [-55.6192, 47.4829],
                        [-55.6152, 47.4956],
                        [-55.6054, 47.4973],
                        [-55.6108, 47.5044],
                        [-55.6011, 47.509],
                        [-55.5878, 47.5107],
                        [-55.5935, 47.5196],
                        [-55.6049, 47.5228],
                        [-55.6074, 47.5387],
                        [-55.6186, 47.5308],
                        [-55.6242, 47.5383],
                        [-55.6302, 47.541],
                        [-55.6083, 47.5539],
                        [-55.5896, 47.5622],
                        [-55.5845, 47.5682],
                        [-55.5811, 47.5796],
                        [-55.5922, 47.5747],
                        [-55.5975, 47.5637],
                        [-55.6219, 47.5558],
                        [-55.6317, 47.5455],
                        [-55.6419, 47.5549],
                        [-55.6432, 47.5426],
                        [-55.6466, 47.5364],
                        [-55.6606, 47.5243],
                        [-55.6483, 47.5067],
                        [-55.6622, 47.4937],
                        [-55.6676, 47.4962],
                        [-55.6728, 47.4878],
                        [-55.6828, 47.4862],
                        [-55.6831, 47.4975],
                        [-55.6862, 47.5026],
                        [-55.7155, 47.506],
                        [-55.7102, 47.4859],
                        [-55.7073, 47.482],
                        [-55.7156, 47.475],
                        [-55.7253, 47.4741],
                        [-55.731, 47.4671],
                        [-55.7526, 47.4614],
                        [-55.7732, 47.4504],
                        [-55.7793, 47.4522],
                        [-55.7749, 47.4614],
                        [-55.7692, 47.4665],
                        [-55.7668, 47.4744],
                        [-55.773, 47.4777],
                        [-55.7704, 47.4845],
                        [-55.7584, 47.4899],
                        [-55.7675, 47.4985],
                        [-55.7706, 47.4891],
                        [-55.7792, 47.487],
                        [-55.7841, 47.4934],
                        [-55.7683, 47.5111],
                        [-55.7685, 47.519],
                        [-55.7572, 47.5351],
                        [-55.7527, 47.5386],
                        [-55.7478, 47.549],
                        [-55.731, 47.5674],
                        [-55.738, 47.5695],
                        [-55.7507, 47.5621],
                        [-55.7567, 47.5539],
                        [-55.7607, 47.5431],
                        [-55.7715, 47.5398],
                        [-55.7816, 47.523],
                        [-55.7936, 47.5066],
                        [-55.7971, 47.4884],
                        [-55.8016, 47.4827],
                        [-55.7927, 47.479],
                        [-55.7963, 47.4744],
                        [-55.7973, 47.4645],
                        [-55.8047, 47.462],
                        [-55.8124, 47.4553],
                        [-55.8259, 47.4599],
                        [-55.8362, 47.4586],
                        [-55.8542, 47.4704],
                        [-55.8623, 47.4718],
                        [-55.8744, 47.4654],
                        [-55.8872, 47.4649],
                        [-55.891, 47.4585],
                        [-55.9057, 47.4566],
                        [-55.9188, 47.4477],
                        [-55.9253, 47.4392],
                        [-55.9323, 47.4501],
                        [-55.9218, 47.455],
                        [-55.9087, 47.4648],
                        [-55.9024, 47.4774],
                        [-55.8752, 47.4895],
                        [-55.8648, 47.4901],
                        [-55.8322, 47.5053],
                        [-55.8319, 47.5123],
                        [-55.8442, 47.509],
                        [-55.8444, 47.515],
                        [-55.8243, 47.5338],
                        [-55.8016, 47.5435],
                        [-55.8037, 47.5495],
                        [-55.7915, 47.5554],
                        [-55.7842, 47.5655],
                        [-55.7714, 47.5727],
                        [-55.7658, 47.579],
                        [-55.7466, 47.5868],
                        [-55.7465, 47.5933],
                        [-55.7647, 47.5864],
                        [-55.7814, 47.5827],
                        [-55.7917, 47.5783],
                        [-55.8032, 47.5611],
                        [-55.8096, 47.5582],
                        [-55.8204, 47.5447],
                        [-55.827, 47.5439],
                        [-55.8302, 47.5553],
                        [-55.8212, 47.5615],
                        [-55.8267, 47.566],
                        [-55.8365, 47.5667],
                        [-55.8425, 47.5585],
                        [-55.856, 47.55],
                        [-55.8591, 47.5427],
                        [-55.8703, 47.5433],
                        [-55.8756, 47.5364],
                        [-55.8881, 47.5377],
                        [-55.8874, 47.5456],
                        [-55.8968, 47.5426],
                        [-55.902, 47.5357],
                        [-55.9144, 47.5391],
                        [-55.9234, 47.5309],
                        [-55.9344, 47.529],
                        [-55.9451, 47.5307],
                        [-55.9555, 47.5093],
                        [-55.9692, 47.5077],
                        [-55.9801, 47.5],
                        [-56.0275, 47.4977],
                        [-56.0391, 47.4995],
                        [-56.0504, 47.4917],
                        [-56.0497, 47.4844],
                        [-56.0608, 47.4823],
                        [-56.0688, 47.4777],
                        [-56.0774, 47.4799],
                        [-56.0935, 47.4723],
                        [-56.1059, 47.4706],
                        [-56.1165, 47.4644],
                        [-56.1279, 47.465],
                        [-56.1335, 47.4713],
                        [-56.149, 47.4783],
                        [-56.1577, 47.4784],
                        [-56.1695, 47.4883],
                        [-56.1686, 47.4965],
                        [-56.1883, 47.4997],
                        [-56.1832, 47.5071],
                        [-56.1733, 47.5137],
                        [-56.1559, 47.5199],
                        [-56.119, 47.523],
                        [-56.1124, 47.5253],
                        [-56.0949, 47.5245],
                        [-56.0877, 47.5292],
                        [-56.0591, 47.5345],
                        [-56.0434, 47.5395],
                        [-56.0333, 47.54],
                        [-56.0204, 47.5443],
                        [-56, 47.5487],
                        [-55.9907, 47.5476],
                        [-55.9779, 47.5528],
                        [-55.9619, 47.554],
                        [-55.9564, 47.5592],
                        [-55.9464, 47.5611],
                        [-55.9378, 47.566],
                        [-55.9272, 47.5602],
                        [-55.9199, 47.5603],
                        [-55.9244, 47.5696],
                        [-55.9141, 47.5761],
                        [-55.9024, 47.5781],
                        [-55.898, 47.5837],
                        [-55.8849, 47.5874],
                        [-55.8615, 47.6],
                        [-55.841, 47.6063],
                        [-55.8334, 47.6026],
                        [-55.826, 47.6095],
                        [-55.8031, 47.619],
                        [-55.765, 47.63],
                        [-55.7586, 47.6358],
                        [-55.7409, 47.6438],
                        [-55.7314, 47.6458],
                        [-55.716, 47.6566],
                        [-55.6973, 47.6566],
                        [-55.6729, 47.6635],
                        [-55.6645, 47.6715],
                        [-55.6416, 47.6725],
                        [-55.6316, 47.6773],
                        [-55.6501, 47.6792],
                        [-55.6654, 47.6765],
                        [-55.6741, 47.6837],
                        [-55.6836, 47.6743],
                        [-55.7013, 47.6674],
                        [-55.7264, 47.6649],
                        [-55.7566, 47.6512],
                        [-55.7683, 47.6475],
                        [-55.7871, 47.6346],
                        [-55.8104, 47.6299],
                        [-55.8294, 47.6224],
                        [-55.8424, 47.6221],
                        [-55.8611, 47.6158],
                        [-55.8693, 47.6161],
                        [-55.8754, 47.6213],
                        [-55.8763, 47.6288],
                        [-55.8878, 47.6348],
                        [-55.9016, 47.6355],
                        [-55.9198, 47.6424],
                        [-55.9227, 47.6566],
                        [-55.9318, 47.6571],
                        [-55.9282, 47.6777],
                        [-55.899, 47.7009],
                        [-55.8975, 47.7065],
                        [-55.889, 47.7058],
                        [-55.8738, 47.714],
                        [-55.8611, 47.7171],
                        [-55.8446, 47.7284],
                        [-55.8317, 47.7323],
                        [-55.8301, 47.7358],
                        [-55.8477, 47.741],
                        [-55.8357, 47.7509],
                        [-55.8435, 47.7539],
                        [-55.8384, 47.7612],
                        [-55.8206, 47.7606],
                        [-55.8155, 47.7699],
                        [-55.8075, 47.7767],
                        [-55.7635, 47.7927],
                        [-55.7336, 47.8111],
                        [-55.7267, 47.8178],
                        [-55.7305, 47.8204],
                        [-55.715, 47.8271],
                        [-55.7004, 47.8402],
                        [-55.7084, 47.8451],
                        [-55.7112, 47.836],
                        [-55.7168, 47.8295],
                        [-55.7321, 47.8245],
                        [-55.7352, 47.8144],
                        [-55.7622, 47.8045],
                        [-55.7799, 47.804],
                        [-55.7863, 47.8002],
                        [-55.7838, 47.7938],
                        [-55.7732, 47.797],
                        [-55.766, 47.7948],
                        [-55.7958, 47.7824],
                        [-55.8171, 47.7763],
                        [-55.822, 47.7837],
                        [-55.8192, 47.7886],
                        [-55.8055, 47.7952],
                        [-55.7949, 47.7953],
                        [-55.791, 47.8017],
                        [-55.8077, 47.8012],
                        [-55.8184, 47.7984],
                        [-55.8261, 47.7997],
                        [-55.8305, 47.8057],
                        [-55.8261, 47.8138],
                        [-55.8307, 47.8218],
                        [-55.8287, 47.8331],
                        [-55.8176, 47.8431],
                        [-55.7962, 47.8572],
                        [-55.7867, 47.8607],
                        [-55.7747, 47.8611],
                        [-55.7554, 47.8657],
                        [-55.751, 47.8635],
                        [-55.7279, 47.8763],
                        [-55.7144, 47.8798],
                        [-55.7179, 47.8871],
                        [-55.7098, 47.8909],
                        [-55.7112, 47.8963],
                        [-55.7316, 47.8821],
                        [-55.7529, 47.8727],
                        [-55.7569, 47.868],
                        [-55.768, 47.8714],
                        [-55.7776, 47.8673],
                        [-55.7899, 47.8723],
                        [-55.8006, 47.8743],
                        [-55.8068, 47.886],
                        [-55.7903, 47.9003],
                        [-55.7741, 47.9018],
                        [-55.7516, 47.9134],
                        [-55.7453, 47.9196],
                        [-55.7535, 47.9276],
                        [-55.7625, 47.9418],
                        [-55.7709, 47.9446],
                        [-55.7712, 47.9517],
                        [-55.7823, 47.9523],
                        [-55.7801, 47.9433],
                        [-55.7725, 47.9379],
                        [-55.7716, 47.9226],
                        [-55.7804, 47.9138],
                        [-55.7883, 47.9106],
                        [-55.7977, 47.9164],
                        [-55.8163, 47.9018],
                        [-55.825, 47.8969],
                        [-55.8419, 47.8927],
                        [-55.8358, 47.8864],
                        [-55.8222, 47.8901],
                        [-55.8151, 47.8787],
                        [-55.816, 47.8685],
                        [-55.8217, 47.8667],
                        [-55.8398, 47.8745],
                        [-55.8454, 47.8702],
                        [-55.8305, 47.8586],
                        [-55.832, 47.8487],
                        [-55.8458, 47.8445],
                        [-55.8487, 47.8296],
                        [-55.8585, 47.8175],
                        [-55.8576, 47.8112],
                        [-55.8485, 47.8034],
                        [-55.849, 47.7943],
                        [-55.8453, 47.7896],
                        [-55.8555, 47.7864],
                        [-55.8612, 47.7947],
                        [-55.8709, 47.7984],
                        [-55.8859, 47.8082],
                        [-55.8993, 47.8093],
                        [-55.8923, 47.7992],
                        [-55.8809, 47.792],
                        [-55.8731, 47.792],
                        [-55.8639, 47.7879],
                        [-55.8657, 47.784],
                        [-55.9142, 47.7662],
                        [-55.9302, 47.7634],
                        [-55.9334, 47.7657],
                        [-55.954, 47.7628],
                        [-55.9616, 47.7658],
                        [-55.9719, 47.7648],
                        [-55.9794, 47.76],
                        [-55.9984, 47.7595],
                        [-56.0126, 47.7524],
                        [-56.0053, 47.7478],
                        [-56.0037, 47.7401],
                        [-56.0458, 47.7154],
                        [-56.0432, 47.7072],
                        [-56.048, 47.702],
                        [-56.0747, 47.6965],
                        [-56.0849, 47.6977],
                        [-56.1151, 47.6914],
                        [-56.1197, 47.7006],
                        [-56.1048, 47.7063],
                        [-56.0962, 47.7072],
                        [-56.0912, 47.7125],
                        [-56.0746, 47.7211],
                        [-56.0754, 47.7264],
                        [-56.0912, 47.7213],
                        [-56.0957, 47.7148],
                        [-56.1109, 47.7132],
                        [-56.1182, 47.7161],
                        [-56.1242, 47.7266],
                        [-56.1209, 47.7342],
                        [-56.1112, 47.7408],
                        [-56.0962, 47.7433],
                        [-56.088, 47.7491],
                        [-56.0602, 47.7756],
                        [-56.0444, 47.7866],
                        [-56.0669, 47.7866],
                        [-56.0685, 47.7929],
                        [-56.0759, 47.7927],
                        [-56.0712, 47.7755],
                        [-56.0775, 47.7732],
                        [-56.0764, 47.7673],
                        [-56.0845, 47.7649],
                        [-56.0906, 47.7564],
                        [-56.1109, 47.7488],
                        [-56.1253, 47.7602],
                        [-56.13, 47.7764],
                        [-56.1276, 47.7805],
                        [-56.136, 47.7951],
                        [-56.1354, 47.8042],
                        [-56.1423, 47.8118],
                        [-56.1384, 47.8204],
                        [-56.1471, 47.8256],
                        [-56.1577, 47.8546],
                        [-56.1627, 47.8631],
                        [-56.1706, 47.8651],
                        [-56.1715, 47.8529],
                        [-56.1599, 47.8257],
                        [-56.1526, 47.8161],
                        [-56.1552, 47.8105],
                        [-56.1535, 47.8021],
                        [-56.1459, 47.7903],
                        [-56.141, 47.7763],
                        [-56.1404, 47.7671],
                        [-56.1351, 47.7627],
                        [-56.1341, 47.75],
                        [-56.1368, 47.7455],
                        [-56.1482, 47.7427],
                        [-56.1631, 47.7312],
                        [-56.1879, 47.7253],
                        [-56.1572, 47.7236],
                        [-56.1586, 47.7122],
                        [-56.1564, 47.693],
                        [-56.1535, 47.6861],
                        [-56.159, 47.6787],
                        [-56.1778, 47.6869],
                        [-56.1886, 47.6792],
                        [-56.1818, 47.6712],
                        [-56.1699, 47.6736],
                        [-56.1591, 47.6658],
                        [-56.1566, 47.6584],
                        [-56.173, 47.6587],
                        [-56.1792, 47.6615],
                        [-56.1844, 47.6555],
                        [-56.1802, 47.6455],
                        [-56.1721, 47.6419],
                        [-56.1685, 47.6356],
                        [-56.1919, 47.6334],
                        [-56.2027, 47.6403],
                        [-56.2027, 47.6519],
                        [-56.2127, 47.6523],
                        [-56.2215, 47.6559],
                        [-56.2247, 47.6494],
                        [-56.2208, 47.6421],
                        [-56.2264, 47.625],
                        [-56.2368, 47.6354],
                        [-56.2453, 47.6412],
                        [-56.2495, 47.6319],
                        [-56.2798, 47.6283],
                        [-56.2998, 47.6234],
                        [-56.3132, 47.6285],
                        [-56.3157, 47.6351],
                        [-56.3073, 47.6481],
                        [-56.3059, 47.6641],
                        [-56.31, 47.667],
                        [-56.3084, 47.6872],
                        [-56.3098, 47.6941],
                        [-56.3043, 47.7038],
                        [-56.315, 47.7099],
                        [-56.3144, 47.7152],
                        [-56.3191, 47.7273],
                        [-56.3179, 47.7389],
                        [-56.314, 47.7449],
                        [-56.3199, 47.7535],
                        [-56.3209, 47.7677],
                        [-56.3167, 47.777],
                        [-56.3181, 47.7884],
                        [-56.327, 47.7901],
                        [-56.3238, 47.7788],
                        [-56.3244, 47.769],
                        [-56.3321, 47.7633],
                        [-56.3284, 47.7474],
                        [-56.3296, 47.738],
                        [-56.3335, 47.7333],
                        [-56.3246, 47.7217],
                        [-56.3227, 47.7098],
                        [-56.3301, 47.7039],
                        [-56.3445, 47.7008],
                        [-56.3482, 47.697],
                        [-56.3277, 47.6948],
                        [-56.3241, 47.6868],
                        [-56.3265, 47.6797],
                        [-56.323, 47.6718],
                        [-56.3237, 47.6627],
                        [-56.3302, 47.6539],
                        [-56.3257, 47.6481],
                        [-56.328, 47.6359],
                        [-56.3236, 47.6273],
                        [-56.3371, 47.6271],
                        [-56.3512, 47.6294],
                        [-56.3636, 47.6341],
                        [-56.3703, 47.6301],
                        [-56.3546, 47.6257],
                        [-56.3359, 47.6225],
                        [-56.3464, 47.6189],
                        [-56.3536, 47.6133],
                        [-56.3669, 47.6087],
                        [-56.3745, 47.6084],
                        [-56.3853, 47.6034],
                        [-56.4003, 47.6045],
                        [-56.4082, 47.6089],
                        [-56.4124, 47.6032],
                        [-56.4389, 47.6023],
                        [-56.4491, 47.6078],
                        [-56.4706, 47.6072],
                        [-56.4903, 47.6145],
                        [-56.5031, 47.6116],
                        [-56.5126, 47.615],
                        [-56.5175, 47.6201],
                        [-56.5143, 47.6296],
                        [-56.517, 47.6384],
                        [-56.5155, 47.6494],
                        [-56.518, 47.655],
                        [-56.5104, 47.6673],
                        [-56.5068, 47.6822],
                        [-56.5009, 47.69],
                        [-56.5065, 47.6954],
                        [-56.5152, 47.6974],
                        [-56.5153, 47.7069],
                        [-56.5186, 47.7134],
                        [-56.5267, 47.7122],
                        [-56.5227, 47.7051],
                        [-56.5267, 47.7008],
                        [-56.5461, 47.7075],
                        [-56.5567, 47.7074],
                        [-56.5572, 47.7024],
                        [-56.5352, 47.6945],
                        [-56.5178, 47.6803],
                        [-56.5244, 47.6714],
                        [-56.5254, 47.6588],
                        [-56.5217, 47.6513],
                        [-56.5268, 47.6363],
                        [-56.522, 47.6204],
                        [-56.5261, 47.616],
                        [-56.5384, 47.6208],
                        [-56.5399, 47.6137],
                        [-56.5614, 47.6164],
                        [-56.5648, 47.615],
                        [-56.589, 47.6186],
                        [-56.6022, 47.6144],
                        [-56.6182, 47.615],
                        [-56.6184, 47.6206],
                        [-56.6089, 47.6274],
                        [-56.6092, 47.6344],
                        [-56.616, 47.6474],
                        [-56.6273, 47.6528],
                        [-56.6197, 47.6388],
                        [-56.6175, 47.6298],
                        [-56.6266, 47.6229],
                        [-56.6244, 47.6156],
                        [-56.6298, 47.6133],
                        [-56.6461, 47.6139],
                        [-56.6617, 47.6198],
                        [-56.663, 47.6409],
                        [-56.6714, 47.6332],
                        [-56.6668, 47.6232],
                        [-56.674, 47.6197],
                        [-56.6829, 47.6272],
                        [-56.6896, 47.6234],
                        [-56.6988, 47.6251],
                        [-56.6979, 47.6323],
                        [-56.7058, 47.6469],
                        [-56.7137, 47.6449],
                        [-56.7045, 47.6299],
                        [-56.7085, 47.6142],
                        [-56.6734, 47.6138],
                        [-56.6528, 47.6051],
                        [-56.655, 47.5943],
                        [-56.6481, 47.5927],
                        [-56.6463, 47.5867],
                        [-56.6626, 47.5781],
                        [-56.6727, 47.5865],
                        [-56.6801, 47.5802],
                        [-56.6976, 47.582],
                        [-56.7007, 47.5865],
                        [-56.7124, 47.5854],
                        [-56.741, 47.6015],
                        [-56.7423, 47.6082],
                        [-56.7356, 47.6163],
                        [-56.7482, 47.6268],
                        [-56.7639, 47.6283],
                        [-56.7692, 47.6367],
                        [-56.7771, 47.6387],
                        [-56.7702, 47.624],
                        [-56.7501, 47.6176],
                        [-56.7596, 47.6069],
                        [-56.7512, 47.5961],
                        [-56.7267, 47.5814],
                        [-56.7138, 47.5791],
                        [-56.7146, 47.5668],
                        [-56.7242, 47.5683],
                        [-56.7362, 47.5573],
                        [-56.7505, 47.5569],
                        [-56.7507, 47.551],
                        [-56.7625, 47.551],
                        [-56.7718, 47.5548],
                        [-56.7828, 47.5453],
                        [-56.7873, 47.5381],
                        [-56.8046, 47.5351],
                        [-56.8114, 47.5446],
                        [-56.8184, 47.5484],
                        [-56.802, 47.5622],
                        [-56.8001, 47.574],
                        [-56.8055, 47.5874],
                        [-56.8129, 47.5861],
                        [-56.809, 47.5741],
                        [-56.8089, 47.5656],
                        [-56.8183, 47.5594],
                        [-56.8266, 47.5578],
                        [-56.8252, 47.5478],
                        [-56.8424, 47.5371],
                        [-56.8504, 47.5388],
                        [-56.8547, 47.5277],
                        [-56.8628, 47.5309],
                        [-56.853, 47.5374],
                        [-56.8584, 47.5488],
                        [-56.8533, 47.5573],
                        [-56.8583, 47.5646],
                        [-56.862, 47.5902],
                        [-56.8649, 47.5957],
                        [-56.8632, 47.6124],
                        [-56.8584, 47.6218],
                        [-56.8755, 47.6222],
                        [-56.8741, 47.6149],
                        [-56.8763, 47.6049],
                        [-56.8746, 47.5908],
                        [-56.8698, 47.5824],
                        [-56.864, 47.5614],
                        [-56.872, 47.5573],
                        [-56.872, 47.5503],
                        [-56.8793, 47.5407],
                        [-56.8867, 47.5412],
                        [-56.883, 47.5532],
                        [-56.9068, 47.5581],
                        [-56.9254, 47.5654],
                        [-56.9354, 47.5647],
                        [-56.9472, 47.5712],
                        [-56.9505, 47.5756],
                        [-56.9653, 47.5787],
                        [-57.0094, 47.5912],
                        [-57.0103, 47.5948],
                        [-57.0357, 47.5901],
                        [-57.0525, 47.5816],
                        [-57.0713, 47.579],
                        [-57.0815, 47.5743],
                        [-57.097, 47.5724],
                        [-57.1052, 47.574],
                        [-57.1186, 47.5721],
                        [-57.1361, 47.5746],
                        [-57.1455, 47.5807],
                        [-57.1777, 47.5987],
                        [-57.1519, 47.6053],
                        [-57.1485, 47.6103],
                        [-57.1566, 47.6159],
                        [-57.1594, 47.6316],
                        [-57.167, 47.6353],
                        [-57.1675, 47.6446],
                        [-57.1752, 47.6543],
                        [-57.1725, 47.6676],
                        [-57.1815, 47.6709],
                        [-57.1798, 47.6759],
                        [-57.182, 47.694],
                        [-57.1907, 47.6878],
                        [-57.1893, 47.6737],
                        [-57.1855, 47.658],
                        [-57.1807, 47.6473],
                        [-57.1843, 47.6424],
                        [-57.1777, 47.633],
                        [-57.1738, 47.6205],
                        [-57.1812, 47.6137],
                        [-57.197, 47.608],
                        [-57.2066, 47.5975],
                        [-57.2281, 47.5961],
                        [-57.2538, 47.5994],
                        [-57.2595, 47.6044],
                        [-57.2683, 47.6042],
                        [-57.2705, 47.5973],
                        [-57.2768, 47.5942],
                        [-57.2894, 47.5947],
                        [-57.3044, 47.592],
                        [-57.3121, 47.5871],
                        [-57.329, 47.5847],
                        [-57.3422, 47.5924],
                        [-57.3411, 47.5989],
                        [-57.3464, 47.6055],
                        [-57.3388, 47.6256],
                        [-57.3335, 47.6338],
                        [-57.3245, 47.639],
                        [-57.3258, 47.645],
                        [-57.3102, 47.6701],
                        [-57.3145, 47.6743],
                        [-57.3079, 47.6808],
                        [-57.3066, 47.6869],
                        [-57.3, 47.6926],
                        [-57.3019, 47.7104],
                        [-57.292, 47.7179],
                        [-57.2862, 47.7253],
                        [-57.2898, 47.7319],
                        [-57.2977, 47.7375],
                        [-57.3048, 47.7518],
                        [-57.2825, 47.7699],
                        [-57.3078, 47.7618],
                        [-57.3153, 47.7419],
                        [-57.3121, 47.7333],
                        [-57.3047, 47.7248],
                        [-57.3233, 47.7081],
                        [-57.3238, 47.6978],
                        [-57.3174, 47.6922],
                        [-57.3176, 47.6836],
                        [-57.3229, 47.6778],
                        [-57.3249, 47.6696],
                        [-57.3331, 47.6577],
                        [-57.3386, 47.6565],
                        [-57.3398, 47.6491],
                        [-57.3482, 47.6429],
                        [-57.3751, 47.6395],
                        [-57.3828, 47.6346],
                        [-57.3898, 47.6257],
                        [-57.3972, 47.6238],
                        [-57.4082, 47.6277],
                        [-57.407, 47.635],
                        [-57.4145, 47.6381],
                        [-57.4304, 47.6371],
                        [-57.4408, 47.6322],
                        [-57.4487, 47.6378],
                        [-57.463, 47.635],
                        [-57.4706, 47.6281],
                        [-57.482, 47.6311],
                        [-57.4872, 47.638],
                        [-57.4958, 47.6393],
                        [-57.4987, 47.6332],
                        [-57.5216, 47.6311],
                        [-57.5473, 47.6332],
                        [-57.5448, 47.6443],
                        [-57.535, 47.6522],
                        [-57.5495, 47.6514],
                        [-57.5543, 47.6429],
                        [-57.5643, 47.6436],
                        [-57.5792, 47.6384],
                        [-57.5866, 47.6278],
                        [-57.5931, 47.6275],
                        [-57.5959, 47.6211],
                        [-57.6143, 47.6228],
                        [-57.6393, 47.621],
                        [-57.6375, 47.6135],
                        [-57.6296, 47.608],
                        [-57.6433, 47.6059],
                        [-57.6541, 47.5986],
                        [-57.6569, 47.6113],
                        [-57.6716, 47.6162],
                        [-57.66, 47.628],
                        [-57.6727, 47.6265],
                        [-57.6836, 47.633],
                        [-57.6827, 47.6388],
                        [-57.7071, 47.6404],
                        [-57.7237, 47.6561],
                        [-57.7258, 47.6616],
                        [-57.7368, 47.663],
                        [-57.7531, 47.6614],
                        [-57.7575, 47.6584],
                        [-57.7627, 47.6468],
                        [-57.7678, 47.6415],
                        [-57.7594, 47.6381],
                        [-57.772, 47.6233],
                        [-57.7799, 47.6207],
                        [-57.778, 47.6352],
                        [-57.8007, 47.6299],
                        [-57.8122, 47.6374],
                        [-57.8207, 47.6339],
                        [-57.8303, 47.6361],
                        [-57.8399, 47.6456],
                        [-57.8463, 47.649],
                        [-57.8472, 47.6582],
                        [-57.8693, 47.6535],
                        [-57.8785, 47.6582],
                        [-57.888, 47.6555],
                        [-57.9129, 47.6543],
                        [-57.9006, 47.6788],
                        [-57.8907, 47.6912],
                        [-57.88, 47.6952],
                        [-57.868, 47.7069],
                        [-57.865, 47.7181],
                        [-57.8682, 47.7205],
                        [-57.878, 47.704],
                        [-57.8901, 47.699],
                        [-57.8953, 47.7012],
                        [-57.9075, 47.6947],
                        [-57.9114, 47.6994],
                        [-57.9087, 47.7207],
                        [-57.9053, 47.7246],
                        [-57.9056, 47.7429],
                        [-57.9111, 47.7399],
                        [-57.9122, 47.7175],
                        [-57.9201, 47.7125],
                        [-57.9323, 47.6973],
                        [-57.9288, 47.6911],
                        [-57.9384, 47.6702],
                        [-57.9509, 47.6676],
                        [-57.9557, 47.6615],
                        [-57.968, 47.6605],
                        [-57.9706, 47.6706],
                        [-57.9757, 47.6782],
                        [-57.9962, 47.6799],
                        [-57.9891, 47.6679],
                        [-57.9909, 47.6646],
                        [-58.0044, 47.6668],
                        [-58.0023, 47.6734],
                        [-58.0107, 47.6763],
                        [-58.0068, 47.6836],
                        [-58.0339, 47.6889],
                        [-58.0347, 47.7052],
                        [-58.0326, 47.7088],
                        [-58.0493, 47.7153],
                        [-58.0469, 47.6994],
                        [-58.0526, 47.6935],
                        [-58.0631, 47.6903],
                        [-58.0679, 47.68],
                        [-58.0793, 47.6738],
                        [-58.0905, 47.6764],
                        [-58.0845, 47.6811],
                        [-58.0955, 47.686],
                        [-58.079, 47.699],
                        [-58.0866, 47.7039],
                        [-58.0917, 47.6977],
                        [-58.1007, 47.6953],
                        [-58.1078, 47.6996],
                        [-58.1182, 47.6996],
                        [-58.1348, 47.6968],
                        [-58.134, 47.6934],
                        [-58.1454, 47.6808],
                        [-58.1638, 47.6827],
                        [-58.174, 47.6858],
                        [-58.1808, 47.6836],
                        [-58.2126, 47.6787],
                        [-58.2279, 47.6701],
                        [-58.2328, 47.664],
                        [-58.2426, 47.6642],
                        [-58.2441, 47.6725],
                        [-58.262, 47.6777],
                        [-58.2871, 47.6763],
                        [-58.3029, 47.6801],
                        [-58.3107, 47.6749],
                        [-58.3242, 47.6709],
                        [-58.3355, 47.6637],
                        [-58.3407, 47.6635],
                        [-58.3475, 47.6516],
                        [-58.3637, 47.6552],
                        [-58.3588, 47.6623],
                        [-58.3512, 47.6637],
                        [-58.3445, 47.6761],
                        [-58.3392, 47.6921],
                        [-58.3276, 47.7039],
                        [-58.3212, 47.7143],
                        [-58.3196, 47.7263],
                        [-58.3068, 47.738],
                        [-58.3017, 47.7401],
                        [-58.2921, 47.7503],
                        [-58.2776, 47.7525],
                        [-58.2686, 47.757],
                        [-58.2569, 47.7677],
                        [-58.2925, 47.7589],
                        [-58.3074, 47.7518],
                        [-58.3136, 47.762],
                        [-58.3221, 47.7562],
                        [-58.3442, 47.7472],
                        [-58.3511, 47.7313],
                        [-58.3627, 47.7249],
                        [-58.3738, 47.7239],
                        [-58.3714, 47.7093],
                        [-58.3741, 47.6991],
                        [-58.3719, 47.6909],
                        [-58.3781, 47.6851],
                        [-58.3894, 47.6846],
                        [-58.3869, 47.6789],
                        [-58.3979, 47.6717],
                        [-58.4006, 47.6576],
                        [-58.4118, 47.649],
                        [-58.4193, 47.6476],
                        [-58.4231, 47.6419],
                        [-58.4368, 47.639],
                        [-58.4447, 47.6489],
                        [-58.458, 47.6548],
                        [-58.4643, 47.6515],
                        [-58.4741, 47.656],
                        [-58.4957, 47.6541],
                        [-58.5006, 47.6578],
                        [-58.5012, 47.6651],
                        [-58.4955, 47.6718],
                        [-58.4832, 47.6686],
                        [-58.4792, 47.6751],
                        [-58.4882, 47.6765],
                        [-58.5, 47.6741],
                        [-58.5021, 47.6695],
                        [-58.5013, 47.6639],
                        [-58.5094, 47.6605],
                        [-58.5194, 47.6508],
                        [-58.5267, 47.6537],
                        [-58.5248, 47.6585],
                        [-58.5357, 47.6685],
                        [-58.5348, 47.6735],
                        [-58.5458, 47.6761],
                        [-58.5526, 47.6808],
                        [-58.5596, 47.6941],
                        [-58.5576, 47.7015],
                        [-58.5459, 47.7133],
                        [-58.551, 47.7181],
                        [-58.5663, 47.7045],
                        [-58.5696, 47.6983],
                        [-58.5624, 47.6932],
                        [-58.5644, 47.6878],
                        [-58.5581, 47.6748],
                        [-58.5482, 47.6694],
                        [-58.5428, 47.6627],
                        [-58.5446, 47.6584],
                        [-58.5395, 47.6522],
                        [-58.5438, 47.643],
                        [-58.5554, 47.6456],
                        [-58.556, 47.64],
                        [-58.5665, 47.642],
                        [-58.5721, 47.6472],
                        [-58.5844, 47.6534],
                        [-58.587, 47.6469],
                        [-58.5793, 47.6415],
                        [-58.5878, 47.631],
                        [-58.6055, 47.6292],
                        [-58.6148, 47.6225],
                        [-58.6273, 47.6215],
                        [-58.6293, 47.6161],
                        [-58.6383, 47.6169],
                        [-58.6441, 47.6213],
                        [-58.6349, 47.6303],
                        [-58.6324, 47.6365],
                        [-58.6197, 47.6479],
                        [-58.6126, 47.6603],
                        [-58.5995, 47.6715],
                        [-58.6067, 47.6734],
                        [-58.6155, 47.6655],
                        [-58.6187, 47.6581],
                        [-58.6271, 47.6486],
                        [-58.6488, 47.6372],
                        [-58.6543, 47.6294],
                        [-58.6479, 47.6266],
                        [-58.66, 47.6206],
                        [-58.6678, 47.6275],
                        [-58.6756, 47.6253],
                        [-58.6806, 47.6095],
                        [-58.6964, 47.6087],
                        [-58.7008, 47.6149],
                        [-58.72, 47.6052],
                        [-58.7381, 47.606],
                        [-58.7716, 47.6005],
                        [-58.7758, 47.6017],
                        [-58.801, 47.5972],
                        [-58.8149, 47.6004],
                        [-58.8254, 47.5971],
                        [-58.8323, 47.605],
                        [-58.8422, 47.6094],
                        [-58.8487, 47.604],
                        [-58.8614, 47.6048],
                        [-58.8791, 47.5952],
                        [-58.8947, 47.6003],
                        [-58.9159, 47.5963],
                        [-58.9277, 47.5903],
                        [-58.9503, 47.592],
                        [-58.9614, 47.5836],
                        [-58.9741, 47.5821],
                        [-58.9802, 47.5895],
                        [-58.9927, 47.5844],
                        [-59.004, 47.5869],
                        [-59.0067, 47.5812],
                        [-59.0419, 47.5679],
                        [-59.0465, 47.5724],
                        [-59.0568, 47.5717],
                        [-59.0633, 47.5746],
                        [-59.0764, 47.5742],
                        [-59.081, 47.5791],
                        [-59.118, 47.5746],
                        [-59.1263, 47.5849],
                        [-59.1409, 47.5799],
                        [-59.1404, 47.5742],
                        [-59.1321, 47.573],
                        [-59.1243, 47.5676],
                        [-59.1388, 47.5647],
                        [-59.1447, 47.5738],
                        [-59.1538, 47.5682],
                        [-59.1709, 47.566],
                        [-59.1719, 47.5773],
                        [-59.1752, 47.5858],
                        [-59.1637, 47.591],
                        [-59.1603, 47.5957],
                        [-59.1745, 47.6],
                        [-59.185, 47.5923],
                        [-59.1825, 47.5877],
                        [-59.1858, 47.5797],
                        [-59.1967, 47.5881],
                        [-59.2091, 47.5897],
                        [-59.2158, 47.581],
                        [-59.2326, 47.5843],
                        [-59.2297, 47.589],
                        [-59.244, 47.6137],
                        [-59.2563, 47.617],
                        [-59.2693, 47.6237],
                        [-59.2841, 47.6262],
                        [-59.2921, 47.6204],
                        [-59.3015, 47.6179],
                        [-59.3117, 47.6243],
                        [-59.3117, 47.6324],
                        [-59.3072, 47.635],
                        [-59.3074, 47.6442],
                        [-59.3143, 47.6687],
                        [-59.3123, 47.6854],
                        [-59.3156, 47.6968],
                        [-59.3078, 47.717],
                        [-59.2993, 47.7335],
                        [-59.3009, 47.743],
                        [-59.3067, 47.7493],
                        [-59.3071, 47.7591],
                        [-59.3135, 47.765],
                        [-59.3267, 47.7683],
                        [-59.3224, 47.7723],
                        [-59.3308, 47.7785],
                        [-59.3312, 47.797],
                        [-59.3288, 47.8112],
                        [-59.3312, 47.8169],
                        [-59.3291, 47.829],
                        [-59.3458, 47.8384],
                        [-59.3686, 47.8385],
                        [-59.3648, 47.8477],
                        [-59.3706, 47.8527],
                        [-59.3667, 47.8581],
                        [-59.3752, 47.8713],
                        [-59.3808, 47.8755],
                        [-59.3958, 47.8798],
                        [-59.4072, 47.8884],
                        [-59.4115, 47.8975],
                        [-59.4076, 47.908],
                        [-59.4002, 47.9179],
                        [-59.3937, 47.9225],
                        [-59.3622, 47.9322],
                        [-59.3578, 47.9349],
                        [-59.318, 47.9437],
                        [-59.3133, 47.9462],
                        [-59.3089, 47.9614],
                        [-59.3023, 47.9698],
                        [-59.2833, 47.9866],
                        [-59.2737, 47.9884],
                        [-59.2754, 47.9949],
                        [-59.2691, 48.0032],
                        [-59.2537, 48.0136],
                        [-59.2445, 48.0162],
                        [-59.2261, 48.0274],
                        [-59.2205, 48.0289],
                        [-59.2066, 48.0395],
                        [-59.2018, 48.04],
                        [-59.1887, 48.0487],
                        [-59.1755, 48.0548],
                        [-59.1671, 48.0562],
                        [-59.136, 48.0729],
                        [-59.1329, 48.0759],
                        [-59.1144, 48.0802],
                        [-59.1125, 48.0851],
                        [-59.0869, 48.0991],
                        [-59.0658, 48.105],
                        [-59.059, 48.11],
                        [-59.0222, 48.1228],
                        [-59.0089, 48.1263],
                        [-58.9797, 48.1302],
                        [-58.9729, 48.1353],
                        [-58.9601, 48.1511],
                        [-58.9449, 48.1571],
                        [-58.9403, 48.1704],
                        [-58.9276, 48.1819],
                        [-58.9074, 48.1911],
                        [-58.8929, 48.1944],
                        [-58.8861, 48.1986],
                        [-58.8693, 48.2174],
                        [-58.8491, 48.2262],
                        [-58.8411, 48.2362],
                        [-58.8181, 48.2491],
                        [-58.8156, 48.2554],
                        [-58.8078, 48.2619],
                        [-58.7935, 48.2633],
                        [-58.7781, 48.2766],
                        [-58.7666, 48.2806],
                        [-58.7515, 48.2907],
                        [-58.7417, 48.2952],
                        [-58.7312, 48.3059],
                        [-58.7099, 48.3176],
                        [-58.7078, 48.3223],
                        [-58.6981, 48.3255],
                        [-58.6872, 48.3379],
                        [-58.6884, 48.3635],
                        [-58.678, 48.3723],
                        [-58.6236, 48.3971],
                        [-58.6179, 48.4096],
                        [-58.6013, 48.4207],
                        [-58.5955, 48.4179],
                        [-58.6142, 48.4096],
                        [-58.6211, 48.3994],
                        [-58.6014, 48.4006],
                        [-58.5845, 48.4069],
                        [-58.5622, 48.4019],
                        [-58.5609, 48.4049],
                        [-58.5742, 48.4113],
                        [-58.5514, 48.4168],
                        [-58.5233, 48.4209],
                        [-58.4986, 48.4283],
                        [-58.4773, 48.4312],
                        [-58.4694, 48.4385],
                        [-58.4524, 48.4441],
                        [-58.4404, 48.4499],
                        [-58.4341, 48.4575],
                        [-58.4315, 48.4654],
                        [-58.4298, 48.4824],
                        [-58.432, 48.4886],
                        [-58.4441, 48.5052],
                        [-58.4503, 48.511],
                        [-58.4663, 48.5179],
                        [-58.4888, 48.5125],
                        [-58.4928, 48.5045],
                        [-58.5081, 48.4994],
                        [-58.5313, 48.5],
                        [-58.5383, 48.5092],
                        [-58.535, 48.5145],
                        [-58.5219, 48.5252],
                        [-58.535, 48.5394],
                        [-58.542, 48.5423],
                        [-58.551, 48.5364],
                        [-58.5356, 48.5166],
                        [-58.5406, 48.5106],
                        [-58.5575, 48.5298],
                        [-58.5703, 48.5377],
                        [-58.5847, 48.5429],
                        [-58.6122, 48.5441],
                        [-58.6224, 48.5429],
                        [-58.6444, 48.5444],
                        [-58.6583, 48.5468],
                        [-58.6845, 48.554],
                        [-58.7049, 48.5513],
                        [-58.7211, 48.5551],
                        [-58.73, 48.5532],
                        [-58.7368, 48.5435],
                        [-58.7498, 48.5385],
                        [-58.7575, 48.5386],
                        [-58.7766, 48.531],
                        [-58.7852, 48.5296],
                        [-58.8018, 48.531],
                        [-58.8288, 48.5252],
                        [-58.848, 48.523],
                        [-58.8592, 48.5252],
                        [-58.8661, 48.5208],
                        [-58.8804, 48.5218],
                        [-58.9021, 48.5159],
                        [-58.9142, 48.5189],
                        [-58.9212, 48.5169],
                        [-58.929, 48.5104],
                        [-58.9431, 48.5082],
                        [-58.9624, 48.5121],
                        [-58.9722, 48.5171],
                        [-58.9896, 48.5141],
                        [-58.9938, 48.516],
                        [-59.0099, 48.5142],
                        [-59.0065, 48.5225],
                        [-59.0186, 48.5255],
                        [-59.029, 48.523],
                        [-59.0445, 48.5221],
                        [-59.0479, 48.5188],
                        [-59.061, 48.5153],
                        [-59.0861, 48.514],
                        [-59.1069, 48.5085],
                        [-59.1209, 48.5023],
                        [-59.1234, 48.4985],
                        [-59.1437, 48.4961],
                        [-59.1804, 48.4846],
                        [-59.1945, 48.4813],
                        [-59.2221, 48.4716],
                        [-59.2425, 48.4686],
                        [-59.2513, 48.4647],
                        [-59.2687, 48.4659],
                        [-59.249, 48.494],
                        [-59.2406, 48.4956],
                        [-59.234, 48.504],
                        [-59.239, 48.5166],
                        [-59.233, 48.5293],
                        [-59.226, 48.5357],
                        [-59.2189, 48.5473],
                        [-59.1996, 48.5517],
                        [-59.1799, 48.5678],
                        [-59.171, 48.5822],
                        [-59.1549, 48.5904],
                        [-59.1442, 48.5998],
                        [-59.118, 48.608],
                        [-59.1039, 48.6195],
                        [-59.098, 48.627],
                        [-59.0887, 48.6273],
                        [-59.0544, 48.6379],
                        [-59.0359, 48.6467],
                        [-59.0233, 48.6487],
                        [-58.999, 48.6561],
                        [-58.9688, 48.6696],
                        [-58.9397, 48.6874],
                        [-58.9177, 48.7021],
                        [-58.9001, 48.7022],
                        [-58.8892, 48.7074],
                        [-58.8537, 48.7295],
                        [-58.8205, 48.7521],
                        [-58.8154, 48.7565],
                        [-58.79, 48.7703],
                        [-58.7912, 48.765],
                        [-58.8131, 48.7537],
                        [-58.844, 48.7336],
                        [-58.8697, 48.7153],
                        [-58.9048, 48.6924],
                        [-58.9644, 48.6449],
                        [-58.9698, 48.6323],
                        [-58.9656, 48.6162],
                        [-58.9527, 48.6047],
                        [-58.9319, 48.5962],
                        [-58.9049, 48.5915],
                        [-58.9041, 48.582],
                        [-58.9117, 48.5621],
                        [-58.9082, 48.5582],
                        [-58.8886, 48.5579],
                        [-58.8821, 48.5605],
                        [-58.8614, 48.5763],
                        [-58.8492, 48.6023],
                        [-58.8509, 48.6158],
                        [-58.8427, 48.6426],
                        [-58.8377, 48.6322],
                        [-58.841, 48.6141],
                        [-58.8345, 48.5862],
                        [-58.8311, 48.5794],
                        [-58.8193, 48.5705],
                        [-58.8008, 48.5647],
                        [-58.7836, 48.5625],
                        [-58.7703, 48.5631],
                        [-58.7458, 48.5605],
                        [-58.7377, 48.5619],
                        [-58.7305, 48.5586],
                        [-58.714, 48.561],
                        [-58.7043, 48.5727],
                        [-58.6884, 48.5957],
                        [-58.6864, 48.6041],
                        [-58.6788, 48.6098],
                        [-58.6686, 48.6259],
                        [-58.6718, 48.6345],
                        [-58.6557, 48.643],
                        [-58.6538, 48.6503],
                        [-58.6619, 48.6503],
                        [-58.6763, 48.658],
                        [-58.6876, 48.6768],
                        [-58.6869, 48.6859],
                        [-58.6794, 48.6977],
                        [-58.674, 48.7021],
                        [-58.6693, 48.7127],
                        [-58.655, 48.7319],
                        [-58.6484, 48.7547],
                        [-58.6385, 48.7562],
                        [-58.6166, 48.7842],
                        [-58.6171, 48.7943],
                        [-58.6135, 48.8051],
                        [-58.609, 48.8099],
                        [-58.5881, 48.8199],
                        [-58.5783, 48.8292],
                        [-58.5564, 48.8601],
                        [-58.5464, 48.8667],
                        [-58.5385, 48.8762],
                        [-58.5333, 48.8998],
                        [-58.5226, 48.9131],
                        [-58.5222, 48.924],
                        [-58.5097, 48.936],
                        [-58.5044, 48.9464],
                        [-58.5119, 48.9523],
                        [-58.5101, 48.9653],
                        [-58.5119, 48.9745],
                        [-58.5055, 48.9827],
                        [-58.5074, 48.9897],
                        [-58.5034, 48.9934],
                        [-58.5, 49.0075],
                        [-58.4878, 49.0142],
                        [-58.4697, 49.0168],
                        [-58.4631, 49.0231],
                        [-58.4561, 49.036],
                        [-58.4526, 49.0546],
                        [-58.441, 49.0622],
                        [-58.4372, 49.0762],
                        [-58.4237, 49.0906],
                        [-58.4314, 49.1058],
                        [-58.4212, 49.1072],
                        [-58.418, 49.1122],
                        [-58.4094, 49.1125],
                        [-58.4075, 49.1285],
                        [-58.4008, 49.1329],
                        [-58.3881, 49.1337],
                        [-58.3635, 49.147],
                        [-58.3517, 49.1421],
                        [-58.3526, 49.1378],
                        [-58.3484, 49.1241],
                        [-58.3502, 49.1154],
                        [-58.3576, 49.1058],
                        [-58.3831, 49.0998],
                        [-58.3776, 49.0935],
                        [-58.3619, 49.0943],
                        [-58.3476, 49.105],
                        [-58.3381, 49.1013],
                        [-58.3491, 49.0941],
                        [-58.3612, 49.0909],
                        [-58.3631, 49.0843],
                        [-58.3752, 49.0703],
                        [-58.3765, 49.0623],
                        [-58.3641, 49.0541],
                        [-58.3445, 49.0524],
                        [-58.338, 49.0561],
                        [-58.3084, 49.0632],
                        [-58.2953, 49.0647],
                        [-58.2907, 49.0692],
                        [-58.2779, 49.0719],
                        [-58.2619, 49.0717],
                        [-58.2392, 49.066],
                        [-58.2353, 49.0633],
                        [-58.2151, 49.0644],
                        [-58.1985, 49.0618],
                        [-58.1888, 49.0634],
                        [-58.1758, 49.056],
                        [-58.1624, 49.0608],
                        [-58.1494, 49.045],
                        [-58.1388, 49.0155],
                        [-58.129, 49.0077],
                        [-58.1181, 49.0076],
                        [-58.1078, 48.9994],
                        [-58.098, 48.9952],
                        [-58.0956, 48.9906],
                        [-58.0783, 48.9777],
                        [-58.0674, 48.9739],
                        [-58.0485, 48.9741],
                        [-58.0272, 48.9655],
                        [-58.004, 48.9627],
                        [-57.9989, 48.96],
                        [-57.9753, 48.9579],
                        [-57.9447, 48.9568],
                        [-57.935, 48.9644],
                        [-57.9205, 48.967],
                        [-57.9163, 48.9653],
                        [-57.891, 48.9676],
                        [-57.8869, 48.9723],
                        [-57.9013, 48.9743],
                        [-57.9101, 48.9868],
                        [-57.9416, 48.982],
                        [-57.9663, 48.974],
                        [-57.9811, 48.9794],
                        [-57.9897, 48.978],
                        [-58.003, 48.9828],
                        [-58.0074, 48.9815],
                        [-58.036, 48.9893],
                        [-58.0609, 48.9924],
                        [-58.0669, 48.9974],
                        [-58.0708, 49.0071],
                        [-58.0718, 49.0169],
                        [-58.0891, 49.0224],
                        [-58.0937, 49.0273],
                        [-58.1093, 49.0335],
                        [-58.1192, 49.0417],
                        [-58.1142, 49.0559],
                        [-58.115, 49.0638],
                        [-58.1264, 49.0738],
                        [-58.1466, 49.0794],
                        [-58.1431, 49.086],
                        [-58.1418, 49.0977],
                        [-58.1458, 49.1105],
                        [-58.1515, 49.1174],
                        [-58.1447, 49.1295],
                        [-58.1302, 49.1389],
                        [-58.1196, 49.1396],
                        [-58.1042, 49.1361],
                        [-58.0971, 49.1266],
                        [-58.0801, 49.1295],
                        [-58.0777, 49.1226],
                        [-58.066, 49.1164],
                        [-58.0445, 49.1131],
                        [-58.0285, 49.1213],
                        [-58.0179, 49.1219],
                        [-58.005, 49.1321],
                        [-57.9894, 49.1468],
                        [-57.9882, 49.1503],
                        [-58.0016, 49.1582],
                        [-58.0213, 49.1513],
                        [-58.0405, 49.1419],
                        [-58.0752, 49.1428],
                        [-58.083, 49.15],
                        [-58.0897, 49.1485],
                        [-58.1045, 49.1536],
                        [-58.114, 49.1599],
                        [-58.1156, 49.1725],
                        [-58.1115, 49.1797],
                        [-58.0775, 49.1794],
                        [-58.0626, 49.1742],
                        [-58.0435, 49.1729],
                        [-57.9985, 49.1788],
                        [-57.9884, 49.1815],
                        [-57.9888, 49.1928],
                        [-57.9835, 49.2014],
                        [-57.9634, 49.2156],
                        [-57.9518, 49.2207],
                        [-57.948, 49.2267],
                        [-57.9372, 49.23],
                        [-57.9408, 49.2375],
                        [-57.9622, 49.2337],
                        [-57.9669, 49.2296],
                        [-57.9819, 49.2263],
                        [-57.9938, 49.2183],
                        [-58.0061, 49.2147],
                        [-58.0171, 49.2086],
                        [-58.0324, 49.2073],
                        [-58.0635, 49.2098],
                        [-58.0842, 49.2139],
                        [-58.1075, 49.2202],
                        [-58.119, 49.2248],
                        [-58.1381, 49.2294],
                        [-58.1487, 49.2268],
                        [-58.1655, 49.2364],
                        [-58.1831, 49.2345],
                        [-58.2038, 49.2303],
                        [-58.2158, 49.2361],
                        [-58.2254, 49.2454],
                        [-58.2252, 49.255],
                        [-58.2313, 49.2577],
                        [-58.2362, 49.2661],
                        [-58.2548, 49.285],
                        [-58.2554, 49.2965],
                        [-58.2515, 49.3069],
                        [-58.2549, 49.3126],
                        [-58.2441, 49.3232],
                        [-58.2418, 49.3324],
                        [-58.2313, 49.347],
                        [-58.2263, 49.3661],
                        [-58.2357, 49.3812],
                        [-58.2355, 49.3923],
                        [-58.2248, 49.4051],
                        [-58.221, 49.4168],
                        [-58.2088, 49.4289],
                        [-58.1923, 49.439],
                        [-58.1783, 49.4524],
                        [-58.1705, 49.4625],
                        [-58.1591, 49.4663],
                        [-58.1576, 49.4715],
                        [-58.1439, 49.4764],
                        [-58.1366, 49.4827],
                        [-58.131, 49.4805],
                        [-58.123, 49.4846],
                        [-58.1281, 49.4914],
                        [-58.1146, 49.5001],
                        [-58.1032, 49.505],
                        [-58.1015, 49.5093],
                        [-58.0811, 49.5173],
                        [-58.0693, 49.5256],
                        [-58.0461, 49.5334],
                        [-58.0368, 49.5441],
                        [-58.0215, 49.5545],
                        [-58.0109, 49.557],
                        [-57.9971, 49.5568],
                        [-57.9797, 49.5594],
                        [-57.9465, 49.5448],
                        [-57.9259, 49.5334],
                        [-57.9198, 49.5229],
                        [-57.9175, 49.5077],
                        [-57.9123, 49.5042],
                        [-57.8961, 49.4991],
                        [-57.8753, 49.5119],
                        [-57.886, 49.5242],
                        [-57.8859, 49.5327],
                        [-57.8933, 49.5348],
                        [-57.896, 49.5414],
                        [-57.9043, 49.5456],
                        [-57.904, 49.552],
                        [-57.9086, 49.5586],
                        [-57.9186, 49.5651],
                        [-57.9285, 49.5769],
                        [-57.9393, 49.5814],
                        [-57.9386, 49.5867],
                        [-57.9248, 49.5876],
                        [-57.9188, 49.5935],
                        [-57.9295, 49.6011],
                        [-57.9335, 49.5997],
                        [-57.953, 49.6013],
                        [-57.9504, 49.6079],
                        [-57.956, 49.6116],
                        [-57.9593, 49.6346],
                        [-57.9645, 49.6419],
                        [-57.9607, 49.6557],
                        [-57.9671, 49.6674],
                        [-57.96, 49.6807],
                        [-57.9594, 49.6868],
                        [-57.9509, 49.6986],
                        [-57.9486, 49.7182],
                        [-57.9441, 49.7267],
                        [-57.932, 49.7331],
                        [-57.9202, 49.7452],
                        [-57.9181, 49.7579],
                        [-57.9119, 49.7709],
                        [-57.9052, 49.7695],
                        [-57.8865, 49.7839],
                        [-57.8722, 49.7909],
                        [-57.8722, 49.8104],
                        [-57.8685, 49.8181],
                        [-57.861, 49.8215],
                        [-57.859, 49.8288],
                        [-57.8686, 49.8365],
                        [-57.8637, 49.8411],
                        [-57.8515, 49.8415],
                        [-57.8319, 49.8475],
                        [-57.8253, 49.8597],
                        [-57.8203, 49.8604],
                        [-57.807, 49.8555],
                        [-57.799, 49.858],
                        [-57.7877, 49.8661],
                        [-57.7854, 49.8731],
                        [-57.7958, 49.8749],
                        [-57.8037, 49.8794],
                        [-57.8097, 49.8775],
                        [-57.8195, 49.882],
                        [-57.8375, 49.8813],
                        [-57.8353, 49.8879],
                        [-57.8263, 49.8974],
                        [-57.8018, 49.9086],
                        [-57.8005, 49.9131],
                        [-57.8062, 49.9196],
                        [-57.8262, 49.9146],
                        [-57.8245, 49.9232],
                        [-57.8092, 49.9236],
                        [-57.801, 49.9158],
                        [-57.789, 49.9156],
                        [-57.7787, 49.9216],
                        [-57.7776, 49.9283],
                        [-57.7809, 49.9355],
                        [-57.7708, 49.9355],
                        [-57.7628, 49.939],
                        [-57.758, 49.9463],
                        [-57.7602, 49.9566],
                        [-57.7706, 49.9622],
                        [-57.7729, 49.9666],
                        [-57.7639, 49.981],
                        [-57.7546, 49.9921],
                        [-57.7297, 50.0158],
                        [-57.7227, 50.027],
                        [-57.7129, 50.032],
                        [-57.7105, 50.0449],
                        [-57.6988, 50.0723],
                        [-57.6763, 50.1005],
                        [-57.6708, 50.1047],
                        [-57.6645, 50.1151],
                        [-57.6634, 50.124],
                        [-57.6545, 50.1336],
                        [-57.6443, 50.1491],
                        [-57.6195, 50.1629],
                        [-57.6101, 50.1697],
                        [-57.6071, 50.181],
                        [-57.6199, 50.1826],
                        [-57.6131, 50.1896],
                        [-57.6078, 50.2018],
                        [-57.6115, 50.2052],
                        [-57.6073, 50.2126],
                        [-57.5932, 50.2269],
                        [-57.5917, 50.2385],
                        [-57.5841, 50.2431],
                        [-57.5768, 50.2523],
                        [-57.5612, 50.2842],
                        [-57.5592, 50.2986],
                        [-57.5465, 50.323],
                        [-57.5386, 50.3339],
                        [-57.5314, 50.348],
                        [-57.5391, 50.3628],
                        [-57.5291, 50.3738],
                        [-57.529, 50.3822],
                        [-57.5012, 50.4224],
                        [-57.4996, 50.4268],
                        [-57.487, 50.4375],
                        [-57.4759, 50.4554],
                        [-57.4747, 50.4765],
                        [-57.4412, 50.5031],
                        [-57.4064, 50.526],
                        [-57.3954, 50.5285],
                        [-57.3873, 50.5347],
                        [-57.3878, 50.5415],
                        [-57.3797, 50.5385],
                        [-57.3798, 50.5237],
                        [-57.3735, 50.5214],
                        [-57.359, 50.5071],
                        [-57.3541, 50.5093],
                        [-57.3611, 50.5175],
                        [-57.3678, 50.5166],
                        [-57.3768, 50.529],
                        [-57.3774, 50.5371],
                        [-57.3808, 50.5398],
                        [-57.3878, 50.5418],
                        [-57.3932, 50.5473],
                        [-57.3925, 50.5568],
                        [-57.3962, 50.5727],
                        [-57.3781, 50.5922],
                        [-57.3759, 50.6004],
                        [-57.3561, 50.6065],
                        [-57.349, 50.6136],
                        [-57.3396, 50.6106],
                        [-57.3326, 50.6166],
                        [-57.3215, 50.6193],
                        [-57.3105, 50.6169],
                        [-57.3031, 50.6087],
                        [-57.2952, 50.6094],
                        [-57.2822, 50.6063],
                        [-57.2762, 50.6008],
                        [-57.261, 50.5958],
                        [-57.2512, 50.5983],
                        [-57.231, 50.5966],
                        [-57.2184, 50.5938],
                        [-57.2048, 50.5948],
                        [-57.2027, 50.6017],
                        [-57.1842, 50.6036],
                        [-57.177, 50.6022],
                        [-57.1678, 50.6171],
                        [-57.1581, 50.6215],
                        [-57.1572, 50.6307],
                        [-57.1743, 50.6332],
                        [-57.2008, 50.625],
                        [-57.2097, 50.6263],
                        [-57.2219, 50.621],
                        [-57.2471, 50.6136],
                        [-57.2621, 50.6155],
                        [-57.269, 50.6077],
                        [-57.2905, 50.621],
                        [-57.2833, 50.628],
                        [-57.2842, 50.6395],
                        [-57.2702, 50.6353],
                        [-57.2592, 50.6384],
                        [-57.2447, 50.6394],
                        [-57.2426, 50.645],
                        [-57.2613, 50.6444],
                        [-57.2799, 50.6487],
                        [-57.291, 50.6471],
                        [-57.3082, 50.6398],
                        [-57.3141, 50.6478],
                        [-57.3088, 50.653],
                        [-57.3075, 50.6602],
                        [-57.3132, 50.6705],
                        [-57.3294, 50.6808],
                        [-57.3404, 50.6855],
                        [-57.3459, 50.6922],
                        [-57.3542, 50.6963],
                        [-57.3585, 50.7031],
                        [-57.3693, 50.6971],
                        [-57.3945, 50.6922],
                        [-57.4078, 50.6937],
                        [-57.4125, 50.6973],
                        [-57.3932, 50.7157],
                        [-57.3722, 50.7214],
                        [-57.3674, 50.7262],
                        [-57.3479, 50.7312],
                        [-57.3385, 50.7223],
                        [-57.3425, 50.7176],
                        [-57.3618, 50.712],
                        [-57.3522, 50.705],
                        [-57.3433, 50.7125],
                        [-57.3177, 50.72],
                        [-57.3104, 50.7202],
                        [-57.2972, 50.7297],
                        [-57.2759, 50.7324],
                        [-57.2616, 50.7294],
                        [-57.2549, 50.7316],
                        [-57.2386, 50.7318],
                        [-57.2348, 50.7359],
                        [-57.2172, 50.7398],
                        [-57.2181, 50.7506],
                        [-57.2223, 50.7561],
                        [-57.2064, 50.7574],
                        [-57.1994, 50.7532],
                        [-57.1703, 50.7511],
                        [-57.1512, 50.7679],
                        [-57.1412, 50.7661],
                        [-57.1328, 50.7764],
                        [-57.123, 50.7787],
                        [-57.1108, 50.7763],
                        [-57.098, 50.7816],
                        [-57.0756, 50.7962],
                        [-57.071, 50.812],
                        [-57.0559, 50.8244],
                        [-57.0514, 50.8324],
                        [-57.0311, 50.8438],
                        [-56.9994, 50.8661],
                        [-56.9937, 50.874],
                        [-56.9824, 50.8829],
                        [-56.9725, 50.8876],
                        [-56.9666, 50.8952],
                        [-56.9963, 50.9007],
                        [-56.9917, 50.9126],
                        [-56.9857, 50.9114],
                        [-56.9616, 50.9229],
                        [-56.9427, 50.9115],
                        [-56.9254, 50.9144],
                        [-56.933, 50.9221],
                        [-56.9505, 50.919],
                        [-56.9468, 50.9263],
                        [-56.9584, 50.934],
                        [-56.9842, 50.931],
                        [-56.9963, 50.9316],
                        [-56.9909, 50.9377],
                        [-57.0025, 50.9473],
                        [-57.0006, 50.9623],
                        [-57.012, 50.9677],
                        [-57.0159, 50.9741],
                        [-57.0308, 50.9704],
                        [-57.0379, 50.9736],
                        [-57.0347, 50.9924],
                        [-57.045, 51.0023],
                        [-57.0735, 51.0194],
                        [-57.0871, 51.0162],
                        [-57.0942, 51.0189],
                        [-57.0937, 51.0244],
                        [-57.082, 51.029],
                        [-57.0567, 51.0329],
                        [-57.0628, 51.026],
                        [-57.061, 51.02],
                        [-57.0483, 51.024],
                        [-57.0405, 51.0215],
                        [-57.0274, 51.0239],
                        [-57.0218, 51.0279],
                        [-57.011, 51.024],
                        [-57.01, 51.0055],
                        [-57.0031, 51.0017],
                        [-56.9841, 50.9861],
                        [-56.9752, 50.9879],
                        [-56.9729, 51],
                        [-56.9594, 51],
                        [-56.9558, 50.9967],
                        [-56.9397, 50.9959],
                        [-56.9255, 51.0089],
                        [-56.9228, 51.0143],
                        [-56.9064, 51.0253],
                        [-56.9107, 51.0339],
                        [-56.9293, 51.03],
                        [-56.9352, 51.0354],
                        [-56.9456, 51.0379],
                        [-56.9645, 51.0367],
                        [-56.9638, 51.049],
                        [-56.9709, 51.047],
                        [-56.9856, 51.0524],
                        [-56.9765, 51.0556],
                        [-56.9695, 51.0504],
                        [-56.9556, 51.0519],
                        [-56.9478, 51.0568],
                        [-56.9372, 51.0512],
                        [-56.9232, 51.0568],
                        [-56.9164, 51.0649],
                        [-56.9106, 51.0604],
                        [-56.8998, 51.0608],
                        [-56.8883, 51.0725],
                        [-56.8828, 51.0815],
                        [-56.8728, 51.0856],
                        [-56.8741, 51.0924],
                        [-56.8706, 51.1131],
                        [-56.8633, 51.1137],
                        [-56.8698, 51.1288],
                        [-56.8589, 51.1282],
                        [-56.8459, 51.1221],
                        [-56.8178, 51.1281],
                        [-56.807, 51.1346],
                        [-56.8009, 51.135],
                        [-56.7975, 51.1468],
                        [-56.7918, 51.1502],
                        [-56.7948, 51.1575],
                        [-56.8031, 51.1597],
                        [-56.8018, 51.1748],
                        [-56.8164, 51.1838],
                        [-56.813, 51.1911],
                        [-56.8049, 51.1963],
                        [-56.796, 51.1973],
                        [-56.7892, 51.2057],
                        [-56.7806, 51.2055],
                        [-56.766, 51.2],
                        [-56.7665, 51.2127],
                        [-56.7628, 51.2203],
                        [-56.7722, 51.2199],
                        [-56.7867, 51.2231],
                        [-56.7878, 51.2314],
                        [-56.8025, 51.2328],
                        [-56.7999, 51.2437],
                        [-56.7857, 51.2547],
                        [-56.7841, 51.2607],
                        [-56.7658, 51.2621],
                        [-56.7508, 51.2714],
                        [-56.7554, 51.2844],
                        [-56.7486, 51.2913],
                        [-56.7486, 51.3011],
                        [-56.7309, 51.3048],
                        [-56.7359, 51.3112],
                        [-56.7225, 51.3192],
                        [-56.7227, 51.3263],
                        [-56.7101, 51.3287],
                        [-56.7006, 51.3263],
                        [-56.6936, 51.3291],
                        [-56.7047, 51.3352],
                        [-56.7007, 51.3391],
                        [-56.6849, 51.344],
                        [-56.6726, 51.3425],
                        [-56.6625, 51.3556],
                        [-56.6494, 51.3585],
                        [-56.6414, 51.3669],
                        [-56.6122, 51.3744],
                        [-56.5888, 51.3839],
                        [-56.5787, 51.3837],
                        [-56.5696, 51.3904],
                        [-56.5551, 51.3956],
                        [-56.5439, 51.3956],
                        [-56.5319, 51.401],
                        [-56.5201, 51.4],
                        [-56.5105, 51.4081],
                        [-56.4943, 51.4155],
                        [-56.4697, 51.4198],
                        [-56.4419, 51.4129],
                        [-56.4342, 51.4142],
                        [-56.3952, 51.4354],
                        [-56.344, 51.4582],
                        [-56.3227, 51.4651],
                        [-56.3141, 51.4663],
                        [-56.2802, 51.4768],
                        [-56.2369, 51.4966],
                        [-56.202, 51.5139],
                        [-56.1833, 51.5187],
                        [-56.1612, 51.526],
                        [-56.151, 51.5255],
                        [-56.1465, 51.5212],
                        [-56.1356, 51.528],
                        [-56.1077, 51.5333],
                        [-56.085, 51.5439],
                        [-56.0751, 51.544],
                        [-56.0457, 51.562],
                        [-56.0307, 51.5723],
                        [-56.0214, 51.5715],
                        [-56.0101, 51.5747],
                        [-55.9972, 51.582],
                        [-56.002, 51.5857],
                        [-55.9967, 51.5955],
                        [-55.9854, 51.5907],
                        [-55.9732, 51.5952],
                        [-55.9612, 51.6063],
                        [-55.9451, 51.613],
                        [-55.9291, 51.6164],
                        [-55.9226, 51.6222],
                        [-55.9077, 51.6292],
                        [-55.8962, 51.6305]
                    ]
                ],
            },
        },
        {
            type: "Feature",
            id: "41",
            properties: { cityName: "New Foundland", builderName: "Louis Simone ", phoneNo: "1-416-887-5108", email: "lsimone@dec-tec.com", builderType: "National Territory Builder - Canada", singleBuilder: true },
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-57.3843, 54.1492],
                        [-57.3986, 54.1381],
                        [-57.4111, 54.1377],
                        [-57.4106, 54.1329],
                        [-57.3878, 54.1253],
                        [-57.3784, 54.1327],
                        [-57.362, 54.1396],
                        [-57.348, 54.1395],
                        [-57.3463, 54.129],
                        [-57.3575, 54.1299],
                        [-57.3679, 54.1256],
                        [-57.3697, 54.1211],
                        [-57.3571, 54.0936],
                        [-57.3382, 54.0792],
                        [-57.3361, 54.0698],
                        [-57.3046, 54.0463],
                        [-57.3003, 54.0341],
                        [-57.2643, 53.9912],
                        [-57.2465, 53.9791],
                        [-57.2351, 53.9761],
                        [-57.2059, 53.9544],
                        [-57.1957, 53.9505],
                        [-57.1699, 53.9536],
                        [-57.1559, 53.948],
                        [-57.1357, 53.9436],
                        [-57.1168, 53.9375],
                        [-57.1183, 53.9322],
                        [-57.1539, 53.9326],
                        [-57.1813, 53.9397],
                        [-57.1964, 53.9339],
                        [-57.2071, 53.9193],
                        [-57.2084, 53.9102],
                        [-57.2011, 53.8922],
                        [-57.1927, 53.8794],
                        [-57.1786, 53.864],
                        [-57.1529, 53.8504],
                        [-57.1413, 53.8393],
                        [-57.1288, 53.8352],
                        [-57.0808, 53.8308],
                        [-57.061, 53.8212],
                        [-57.056, 53.8094],
                        [-57.0829, 53.8123],
                        [-57.105, 53.8168],
                        [-57.1188, 53.8226],
                        [-57.1336, 53.8218],
                        [-57.1513, 53.811],
                        [-57.1595, 53.8092],
                        [-57.1834, 53.8168],
                        [-57.2063, 53.8208],
                        [-57.2131, 53.8159],
                        [-57.194, 53.8132],
                        [-57.1587, 53.8043],
                        [-57.1342, 53.8129],
                        [-57.1277, 53.8168],
                        [-57.0794, 53.8058],
                        [-57.0821, 53.8004],
                        [-57.0935, 53.7984],
                        [-57.1166, 53.7871],
                        [-57.1278, 53.7869],
                        [-57.1478, 53.7824],
                        [-57.1468, 53.7753],
                        [-57.1364, 53.7695],
                        [-57.1438, 53.756],
                        [-57.1292, 53.7499],
                        [-57.1185, 53.7487],
                        [-57.1075, 53.7407],
                        [-57.1166, 53.7309],
                        [-57.1361, 53.7339],
                        [-57.1421, 53.7246],
                        [-57.1545, 53.7185],
                        [-57.1646, 53.717],
                        [-57.177, 53.7102],
                        [-57.1886, 53.7093],
                        [-57.1981, 53.7054],
                        [-57.2146, 53.7058],
                        [-57.2347, 53.6884],
                        [-57.2484, 53.6837],
                        [-57.2557, 53.6784],
                        [-57.294, 53.6702],
                        [-57.3127, 53.6608],
                        [-57.3217, 53.6633],
                        [-57.3386, 53.6584],
                        [-57.3463, 53.6528],
                        [-57.4044, 53.6388],
                        [-57.4104, 53.6355],
                        [-57.4188, 53.6247],
                        [-57.4335, 53.6292],
                        [-57.4434, 53.6296],
                        [-57.4603, 53.6176],
                        [-57.4745, 53.6113],
                        [-57.4823, 53.6103],
                        [-57.4916, 53.6023],
                        [-57.4978, 53.6006],
                        [-57.4992, 53.5899],
                        [-57.5044, 53.5804],
                        [-57.5, 53.5764],
                        [-57.4903, 53.5915],
                        [-57.4704, 53.5971],
                        [-57.4627, 53.5964],
                        [-57.4572, 53.6059],
                        [-57.4453, 53.6052],
                        [-57.4369, 53.611],
                        [-57.4143, 53.6164],
                        [-57.4006, 53.6136],
                        [-57.4115, 53.6045],
                        [-57.4173, 53.5861],
                        [-57.4311, 53.58],
                        [-57.4377, 53.5674],
                        [-57.4137, 53.5696],
                        [-57.3998, 53.5896],
                        [-57.3672, 53.5939],
                        [-57.3464, 53.5889],
                        [-57.3389, 53.5919],
                        [-57.3434, 53.6009],
                        [-57.3354, 53.6055],
                        [-57.3237, 53.5899],
                        [-57.3118, 53.589],
                        [-57.318, 53.5823],
                        [-57.3091, 53.5773],
                        [-57.3081, 53.5688],
                        [-57.3015, 53.5638],
                        [-57.3027, 53.5524],
                        [-57.3139, 53.5445],
                        [-57.3144, 53.5372],
                        [-57.3032, 53.5253],
                        [-57.2917, 53.5261],
                        [-57.2872, 53.5301],
                        [-57.2717, 53.5332],
                        [-57.2834, 53.5113],
                        [-57.2827, 53.5047],
                        [-57.2952, 53.4826],
                        [-57.2908, 53.4735],
                        [-57.2904, 53.4557],
                        [-57.2832, 53.4521],
                        [-57.2775, 53.4609],
                        [-57.2593, 53.4694],
                        [-57.2409, 53.4806],
                        [-57.2296, 53.484],
                        [-57.2221, 53.4927],
                        [-57.2158, 53.4956],
                        [-57.1873, 53.4983],
                        [-57.1725, 53.5073],
                        [-57.1572, 53.5127],
                        [-57.1458, 53.5225],
                        [-57.1262, 53.5334],
                        [-57.1219, 53.5411],
                        [-57.1087, 53.5536],
                        [-57.106, 53.5597],
                        [-57.109, 53.5659],
                        [-57.147, 53.5554],
                        [-57.1509, 53.5603],
                        [-57.1402, 53.5653],
                        [-57.1355, 53.5716],
                        [-57.1252, 53.5775],
                        [-57.131, 53.5822],
                        [-57.1167, 53.5885],
                        [-57.1215, 53.5978],
                        [-57.1329, 53.5989],
                        [-57.1368, 53.6043],
                        [-57.1306, 53.6105],
                        [-57.1284, 53.6203],
                        [-57.1114, 53.6265],
                        [-57.0974, 53.6232],
                        [-57.0802, 53.6283],
                        [-57.0721, 53.6285],
                        [-57.0436, 53.6338],
                        [-57.0365, 53.6373],
                        [-57.033, 53.6477],
                        [-57.0517, 53.6509],
                        [-57.0521, 53.6664],
                        [-57.0394, 53.6723],
                        [-57.0232, 53.6836],
                        [-57.013, 53.686],
                        [-56.9947, 53.6952],
                        [-56.9915, 53.7024],
                        [-57.0064, 53.7092],
                        [-56.997, 53.711],
                        [-56.9794, 53.709],
                        [-56.9733, 53.7195],
                        [-56.9538, 53.7282],
                        [-56.9422, 53.7283],
                        [-56.9355, 53.7252],
                        [-56.9209, 53.7244],
                        [-56.9102, 53.7287],
                        [-56.8917, 53.7236],
                        [-56.8853, 53.7157],
                        [-56.8756, 53.7118],
                        [-56.864, 53.7124],
                        [-56.848, 53.709],
                        [-56.8436, 53.7166],
                        [-56.8345, 53.7194],
                        [-56.8251, 53.7326],
                        [-56.8072, 53.7394],
                        [-56.7943, 53.7263],
                        [-56.7928, 53.7205],
                        [-56.784, 53.7203],
                        [-56.7755, 53.7299],
                        [-56.7619, 53.7265],
                        [-56.7716, 53.7206],
                        [-56.7712, 53.7158],
                        [-56.7514, 53.7176],
                        [-56.7363, 53.7233],
                        [-56.7205, 53.7238],
                        [-56.7081, 53.734],
                        [-56.7215, 53.7364],
                        [-56.7352, 53.743],
                        [-56.7244, 53.7477],
                        [-56.7164, 53.747],
                        [-56.7092, 53.7406],
                        [-56.697, 53.7531],
                        [-56.6833, 53.7555],
                        [-56.662, 53.7421],
                        [-56.6723, 53.7339],
                        [-56.6754, 53.7244],
                        [-56.6645, 53.7204],
                        [-56.6554, 53.7229],
                        [-56.6434, 53.7187],
                        [-56.646, 53.7101],
                        [-56.6205, 53.711],
                        [-56.6186, 53.7066],
                        [-56.5966, 53.7014],
                        [-56.5847, 53.7008],
                        [-56.5881, 53.7116],
                        [-56.5988, 53.7179],
                        [-56.6055, 53.7254],
                        [-56.6158, 53.7312],
                        [-56.6119, 53.742],
                        [-56.5843, 53.7447],
                        [-56.5988, 53.7563],
                        [-56.5845, 53.7561],
                        [-56.554, 53.7472],
                        [-56.5284, 53.7483],
                        [-56.5052, 53.7401],
                        [-56.5095, 53.7491],
                        [-56.5253, 53.7539],
                        [-56.5357, 53.7542],
                        [-56.5455, 53.7616],
                        [-56.5337, 53.7647],
                        [-56.5005, 53.7649],
                        [-56.4782, 53.7727],
                        [-56.4848, 53.7772],
                        [-56.4755, 53.7818],
                        [-56.4643, 53.7819],
                        [-56.4608, 53.7733],
                        [-56.4411, 53.7734],
                        [-56.4216, 53.766],
                        [-56.4148, 53.7603],
                        [-56.4071, 53.7468],
                        [-56.4247, 53.7347],
                        [-56.4315, 53.7361],
                        [-56.4412, 53.7307],
                        [-56.4373, 53.7269],
                        [-56.4195, 53.727],
                        [-56.401, 53.7228],
                        [-56.3933, 53.718],
                        [-56.3947, 53.7103],
                        [-56.4028, 53.7108],
                        [-56.415, 53.7164],
                        [-56.4423, 53.7126],
                        [-56.4718, 53.7151],
                        [-56.48, 53.7138],
                        [-56.5062, 53.7145],
                        [-56.5069, 53.7061],
                        [-56.5228, 53.7091],
                        [-56.5269, 53.7019],
                        [-56.5399, 53.6977],
                        [-56.5591, 53.6985],
                        [-56.559, 53.6902],
                        [-56.5683, 53.6846],
                        [-56.5753, 53.6699],
                        [-56.5895, 53.665],
                        [-56.5998, 53.6761],
                        [-56.6198, 53.6762],
                        [-56.6283, 53.671],
                        [-56.6427, 53.6743],
                        [-56.6326, 53.6798],
                        [-56.6617, 53.6822],
                        [-56.6755, 53.6784],
                        [-56.6859, 53.6888],
                        [-56.6987, 53.6875],
                        [-56.7091, 53.6769],
                        [-56.6976, 53.6768],
                        [-56.6699, 53.6669],
                        [-56.653, 53.671],
                        [-56.6348, 53.6618],
                        [-56.6142, 53.6598],
                        [-56.6205, 53.6538],
                        [-56.5949, 53.6513],
                        [-56.5695, 53.6469],
                        [-56.5458, 53.6398],
                        [-56.5384, 53.6354],
                        [-56.5192, 53.6305],
                        [-56.515, 53.6215],
                        [-56.4988, 53.6213],
                        [-56.4833, 53.619],
                        [-56.4755, 53.6153],
                        [-56.4657, 53.6175],
                        [-56.4459, 53.6151],
                        [-56.4209, 53.6151],
                        [-56.4167, 53.6053],
                        [-56.4045, 53.6047],
                        [-56.3995, 53.6094],
                        [-56.3902, 53.6096],
                        [-56.3786, 53.6145],
                        [-56.3668, 53.6226],
                        [-56.3532, 53.6281],
                        [-56.3296, 53.6299],
                        [-56.3061, 53.6262],
                        [-56.2865, 53.6167],
                        [-56.2854, 53.612],
                        [-56.2642, 53.6086],
                        [-56.2593, 53.6025],
                        [-56.2473, 53.5951],
                        [-56.2589, 53.5936],
                        [-56.2748, 53.5879],
                        [-56.284, 53.5882],
                        [-56.2963, 53.5925],
                        [-56.3166, 53.5936],
                        [-56.3365, 53.5853],
                        [-56.3439, 53.58],
                        [-56.3627, 53.571],
                        [-56.348, 53.5678],
                        [-56.3473, 53.563],
                        [-56.3609, 53.5563],
                        [-56.3767, 53.5517],
                        [-56.3852, 53.5424],
                        [-56.3959, 53.5377],
                        [-56.4147, 53.5337],
                        [-56.4354, 53.5334],
                        [-56.4504, 53.5245],
                        [-56.4551, 53.5182],
                        [-56.4689, 53.5188],
                        [-56.4764, 53.5067],
                        [-56.426, 53.5221],
                        [-56.412, 53.5297],
                        [-56.3901, 53.5341],
                        [-56.3813, 53.5379],
                        [-56.366, 53.5512],
                        [-56.3472, 53.5554],
                        [-56.3303, 53.564],
                        [-56.3136, 53.5663],
                        [-56.3132, 53.5711],
                        [-56.3313, 53.5746],
                        [-56.337, 53.5796],
                        [-56.3253, 53.5822],
                        [-56.3185, 53.5801],
                        [-56.2953, 53.5817],
                        [-56.2771, 53.5789],
                        [-56.2631, 53.5816],
                        [-56.252, 53.5804],
                        [-56.2278, 53.5723],
                        [-56.2097, 53.5728],
                        [-56.2062, 53.5684],
                        [-56.1887, 53.5669],
                        [-56.1893, 53.5772],
                        [-56.18, 53.5822],
                        [-56.1642, 53.5959],
                        [-56.1309, 53.5856],
                        [-56.1341, 53.58],
                        [-56.1289, 53.5732],
                        [-56.0919, 53.5704],
                        [-56.0823, 53.5744],
                        [-56.0724, 53.5737],
                        [-56.0654, 53.5789],
                        [-56.0474, 53.5742],
                        [-56.0459, 53.5689],
                        [-56.0294, 53.5645],
                        [-56.0225, 53.5693],
                        [-56.0065, 53.5715],
                        [-56.0016, 53.5622],
                        [-55.9902, 53.5531],
                        [-55.9806, 53.5528],
                        [-55.9754, 53.5466],
                        [-55.9536, 53.5415],
                        [-55.9536, 53.5377],
                        [-55.9815, 53.5391],
                        [-55.9826, 53.5313],
                        [-55.9784, 53.5227],
                        [-55.9807, 53.5152],
                        [-55.9728, 53.5097],
                        [-55.9833, 53.5067],
                        [-55.9921, 53.4962],
                        [-56.0038, 53.4938],
                        [-56.0159, 53.4997],
                        [-56.0036, 53.508],
                        [-56.0132, 53.5118],
                        [-56.0355, 53.504],
                        [-56.0458, 53.5167],
                        [-56.035, 53.5212],
                        [-56.0132, 53.5166],
                        [-56.0202, 53.5241],
                        [-56.0464, 53.527],
                        [-56.0494, 53.5358],
                        [-56.0615, 53.534],
                        [-56.0647, 53.5286],
                        [-56.1074, 53.5311],
                        [-56.1227, 53.5366],
                        [-56.1319, 53.5443],
                        [-56.1584, 53.5529],
                        [-56.1843, 53.5514],
                        [-56.2012, 53.5518],
                        [-56.2124, 53.5546],
                        [-56.2527, 53.5559],
                        [-56.2608, 53.5511],
                        [-56.2481, 53.5425],
                        [-56.2387, 53.5395],
                        [-56.2455, 53.533],
                        [-56.2097, 53.5298],
                        [-56.1977, 53.526],
                        [-56.1899, 53.5192],
                        [-56.1746, 53.5143],
                        [-56.1557, 53.4977],
                        [-56.1185, 53.4896],
                        [-56.1042, 53.4912],
                        [-56.0774, 53.4819],
                        [-56.07, 53.475],
                        [-56.0483, 53.4686],
                        [-56.0263, 53.466],
                        [-56.0226, 53.457],
                        [-56.0136, 53.4502],
                        [-56.0204, 53.4429],
                        [-56.0284, 53.4443],
                        [-56.036, 53.4368],
                        [-56.0221, 53.4338],
                        [-56.01, 53.4231],
                        [-55.9839, 53.4204],
                        [-55.979, 53.4068],
                        [-55.9589, 53.4006],
                        [-55.9641, 53.3944],
                        [-55.9775, 53.3866],
                        [-55.9885, 53.3876],
                        [-55.9968, 53.3808],
                        [-55.997, 53.3747],
                        [-56.0065, 53.3667],
                        [-55.996, 53.3641],
                        [-55.9837, 53.3689],
                        [-55.9721, 53.366],
                        [-55.9671, 53.3704],
                        [-55.966, 53.3789],
                        [-55.9589, 53.3846],
                        [-55.9434, 53.3829],
                        [-55.9331, 53.3903],
                        [-55.9153, 53.3922],
                        [-55.9052, 53.3901],
                        [-55.9034, 53.3845],
                        [-55.8776, 53.3745],
                        [-55.8673, 53.3668],
                        [-55.8474, 53.3668],
                        [-55.849, 53.3616],
                        [-55.8662, 53.3574],
                        [-55.8556, 53.3488],
                        [-55.8881, 53.3405],
                        [-55.8989, 53.3402],
                        [-55.9165, 53.3316],
                        [-55.8682, 53.3332],
                        [-55.8585, 53.3271],
                        [-55.8442, 53.3326],
                        [-55.8195, 53.3386],
                        [-55.8096, 53.338],
                        [-55.7882, 53.3304],
                        [-55.7882, 53.3221],
                        [-55.7976, 53.3245],
                        [-55.8164, 53.3218],
                        [-55.8235, 53.3151],
                        [-55.834, 53.3155],
                        [-55.8421, 53.3105],
                        [-55.848, 53.3133],
                        [-55.8663, 53.3095],
                        [-55.8684, 53.3032],
                        [-55.8821, 53.3035],
                        [-55.9046, 53.2923],
                        [-55.8797, 53.2894],
                        [-55.8627, 53.2948],
                        [-55.8388, 53.293],
                        [-55.822, 53.286],
                        [-55.8098, 53.2854],
                        [-55.8083, 53.2812],
                        [-55.7932, 53.2754],
                        [-55.8026, 53.2693],
                        [-55.8172, 53.2685],
                        [-55.812, 53.2594],
                        [-55.8032, 53.2549],
                        [-55.8091, 53.2494],
                        [-55.8475, 53.251],
                        [-55.8437, 53.2431],
                        [-55.8359, 53.2451],
                        [-55.8209, 53.2416],
                        [-55.8083, 53.2306],
                        [-55.7868, 53.2246],
                        [-55.7769, 53.224],
                        [-55.7689, 53.2281],
                        [-55.7826, 53.2357],
                        [-55.7781, 53.2428],
                        [-55.771, 53.2414],
                        [-55.766, 53.2342],
                        [-55.7502, 53.2391],
                        [-55.7557, 53.2459],
                        [-55.7672, 53.2452],
                        [-55.7759, 53.2531],
                        [-55.7671, 53.2655],
                        [-55.7584, 53.2607],
                        [-55.7483, 53.2589],
                        [-55.7391, 53.2474],
                        [-55.7286, 53.2456],
                        [-55.7292, 53.2363],
                        [-55.7374, 53.2299],
                        [-55.7241, 53.2292],
                        [-55.7337, 53.2209],
                        [-55.7532, 53.2204],
                        [-55.7631, 53.2174],
                        [-55.7781, 53.208],
                        [-55.7693, 53.2048],
                        [-55.7639, 53.1931],
                        [-55.7813, 53.1944],
                        [-55.807, 53.2006],
                        [-55.8137, 53.2066],
                        [-55.8267, 53.2063],
                        [-55.8291, 53.2007],
                        [-55.8024, 53.189],
                        [-55.7889, 53.191],
                        [-55.786, 53.1774],
                        [-55.7603, 53.1838],
                        [-55.7459, 53.1792],
                        [-55.7421, 53.1676],
                        [-55.7544, 53.1587],
                        [-55.7661, 53.1596],
                        [-55.7786, 53.1559],
                        [-55.7801, 53.1484],
                        [-55.7648, 53.1495],
                        [-55.7605, 53.1431],
                        [-55.7396, 53.1426],
                        [-55.7396, 53.1348],
                        [-55.756, 53.1301],
                        [-55.7623, 53.1212],
                        [-55.7765, 53.1151],
                        [-55.7849, 53.1152],
                        [-55.7871, 53.1086],
                        [-55.8038, 53.1051],
                        [-55.8124, 53.0983],
                        [-55.8218, 53.0961],
                        [-55.8357, 53.0971],
                        [-55.8845, 53.1081],
                        [-55.8929, 53.1116],
                        [-55.9113, 53.1143],
                        [-55.9088, 53.12],
                        [-55.8954, 53.1209],
                        [-55.8894, 53.128],
                        [-55.8991, 53.1306],
                        [-55.9061, 53.1249],
                        [-55.9439, 53.1114],
                        [-55.9501, 53.1061],
                        [-55.9463, 53.1023],
                        [-55.9352, 53.1108],
                        [-55.9209, 53.1131],
                        [-55.8956, 53.1072],
                        [-55.8816, 53.1026],
                        [-55.8722, 53.097],
                        [-55.8557, 53.0914],
                        [-55.8592, 53.0782],
                        [-55.8649, 53.067],
                        [-55.8735, 53.0563],
                        [-55.8928, 53.0364],
                        [-55.8818, 53.0328],
                        [-55.8746, 53.0247],
                        [-55.8806, 53.0176],
                        [-55.8949, 53.0227],
                        [-55.9003, 53.0293],
                        [-55.9415, 53.0115],
                        [-55.9493, 53.0173],
                        [-55.9561, 53.0138],
                        [-55.9684, 53.0163],
                        [-55.9732, 53.0131],
                        [-55.9879, 53.0142],
                        [-56.0094, 53.0202],
                        [-56.0157, 53.0255],
                        [-56.0167, 53.0317],
                        [-56.0275, 53.0298],
                        [-56.037, 53.0328],
                        [-56.0359, 53.0413],
                        [-56.0477, 53.041],
                        [-56.0548, 53.0337],
                        [-56.0675, 53.0364],
                        [-56.0773, 53.0321],
                        [-56.0948, 53.0368],
                        [-56.1242, 53.03],
                        [-56.1359, 53.0311],
                        [-56.14, 53.0356],
                        [-56.1671, 53.0346],
                        [-56.1561, 53.0254],
                        [-56.14, 53.0232],
                        [-56.1343, 53.0272],
                        [-56.1189, 53.0221],
                        [-56.1165, 53.0268],
                        [-56.1045, 53.0262],
                        [-56.0942, 53.0217],
                        [-56.0722, 53.018],
                        [-56.0801, 53.0265],
                        [-56.0709, 53.0278],
                        [-56.0478, 53.0188],
                        [-56.0444, 53.0269],
                        [-56.0359, 53.0246],
                        [-56.0288, 53.0147],
                        [-56.0412, 53.008],
                        [-56.0598, 53.0084],
                        [-56.0655, 52.9951],
                        [-56.0801, 52.9931],
                        [-56.0853, 52.9866],
                        [-56.0973, 52.9797],
                        [-56.1079, 52.9883],
                        [-56.1135, 52.9764],
                        [-56.1118, 52.9653],
                        [-56.1167, 52.9579],
                        [-56.1242, 52.9538],
                        [-56.1373, 52.9515],
                        [-56.1319, 52.9449],
                        [-56.1306, 52.9374],
                        [-56.1066, 52.9599],
                        [-56.0886, 52.9746],
                        [-56.0761, 52.9788],
                        [-56.0699, 52.9843],
                        [-56.0526, 52.9917],
                        [-56.0288, 52.9944],
                        [-56.0039, 53.0017],
                        [-55.9933, 53.0009],
                        [-55.9786, 52.9961],
                        [-55.9769, 52.9896],
                        [-55.9391, 52.9968],
                        [-55.9297, 53.0074],
                        [-55.9193, 53.0098],
                        [-55.9013, 53.0078],
                        [-55.8945, 53.0032],
                        [-55.8873, 53.0067],
                        [-55.874, 53.0069],
                        [-55.8672, 52.9986],
                        [-55.8502, 52.9866],
                        [-55.8555, 52.982],
                        [-55.8773, 52.9846],
                        [-55.8955, 52.993],
                        [-55.9099, 52.9928],
                        [-55.9, 52.9806],
                        [-55.9016, 52.9724],
                        [-55.8865, 52.967],
                        [-55.8735, 52.9684],
                        [-55.8607, 52.9602],
                        [-55.8808, 52.9559],
                        [-55.8861, 52.9515],
                        [-55.8806, 52.946],
                        [-55.8874, 52.9426],
                        [-55.8748, 52.9351],
                        [-55.8646, 52.9349],
                        [-55.8483, 52.9275],
                        [-55.8329, 52.9258],
                        [-55.841, 52.9121],
                        [-55.8533, 52.9032],
                        [-55.8497, 52.9003],
                        [-55.8348, 52.9101],
                        [-55.8255, 52.9047],
                        [-55.8353, 52.8888],
                        [-55.8254, 52.8825],
                        [-55.8214, 52.8956],
                        [-55.8106, 52.8866],
                        [-55.8024, 52.8853],
                        [-55.7965, 52.8643],
                        [-55.7983, 52.8573],
                        [-55.7959, 52.8389],
                        [-55.7991, 52.833],
                        [-55.8154, 52.8364],
                        [-55.8351, 52.8481],
                        [-55.8476, 52.8478],
                        [-55.8615, 52.8524],
                        [-55.858, 52.8415],
                        [-55.8506, 52.8443],
                        [-55.835, 52.8375],
                        [-55.8392, 52.829],
                        [-55.8794, 52.8393],
                        [-55.9017, 52.8472],
                        [-55.9026, 52.842],
                        [-55.8961, 52.8303],
                        [-55.9097, 52.8184],
                        [-55.9285, 52.82],
                        [-55.9478, 52.8234],
                        [-55.9552, 52.817],
                        [-55.9759, 52.8214],
                        [-55.9855, 52.8123],
                        [-55.9749, 52.8122],
                        [-55.968, 52.793],
                        [-55.9504, 52.7919],
                        [-55.9413, 52.8003],
                        [-55.9287, 52.8017],
                        [-55.9177, 52.7977],
                        [-55.9293, 52.7825],
                        [-55.9371, 52.7844],
                        [-55.953, 52.7833],
                        [-55.9655, 52.7874],
                        [-55.9808, 52.7856],
                        [-55.9801, 52.781],
                        [-56.006, 52.7778],
                        [-56.0282, 52.7873],
                        [-56.032, 52.7947],
                        [-56.0598, 52.8043],
                        [-56.0673, 52.8007],
                        [-56.0809, 52.8014],
                        [-56.1103, 52.806],
                        [-56.1342, 52.8178],
                        [-56.1461, 52.8189],
                        [-56.1533, 52.8228],
                        [-56.1594, 52.8308],
                        [-56.1732, 52.833],
                        [-56.1637, 52.8211],
                        [-56.1719, 52.8058],
                        [-56.1478, 52.8002],
                        [-56.1416, 52.8016],
                        [-56.127, 52.7967],
                        [-56.1145, 52.7886],
                        [-56.1136, 52.7778],
                        [-56.1091, 52.7689],
                        [-56.0918, 52.772],
                        [-56.0781, 52.7649],
                        [-56.0703, 52.7759],
                        [-56.0696, 52.7839],
                        [-56.0509, 52.7652],
                        [-56.0439, 52.7638],
                        [-56.0285, 52.7546],
                        [-56.0292, 52.7473],
                        [-56.0148, 52.7477],
                        [-56.0153, 52.7388],
                        [-56.0112, 52.7336],
                        [-56.0251, 52.7263],
                        [-56.0088, 52.7152],
                        [-55.9996, 52.716],
                        [-55.9775, 52.7109],
                        [-55.9689, 52.6976],
                        [-55.9615, 52.6921],
                        [-55.9511, 52.6949],
                        [-55.9485, 52.6868],
                        [-55.9505, 52.6768],
                        [-55.906, 52.6853],
                        [-55.8958, 52.6946],
                        [-55.8779, 52.6921],
                        [-55.8723, 52.6878],
                        [-55.8898, 52.6822],
                        [-55.8834, 52.6781],
                        [-55.8594, 52.6853],
                        [-55.853, 52.6885],
                        [-55.8315, 52.6848],
                        [-55.8182, 52.6946],
                        [-55.805, 52.6985],
                        [-55.7898, 52.692],
                        [-55.7798, 52.6934],
                        [-55.7631, 52.6878],
                        [-55.7563, 52.6802],
                        [-55.7604, 52.673],
                        [-55.7849, 52.6641],
                        [-55.8034, 52.6483],
                        [-55.8162, 52.6483],
                        [-55.8327, 52.6409],
                        [-55.8462, 52.6418],
                        [-55.8548, 52.6382],
                        [-55.8831, 52.6374],
                        [-55.8973, 52.6425],
                        [-55.9093, 52.6383],
                        [-55.9041, 52.6345],
                        [-55.8824, 52.633],
                        [-55.8718, 52.6297],
                        [-55.8406, 52.6368],
                        [-55.8097, 52.638],
                        [-55.7841, 52.6538],
                        [-55.7687, 52.661],
                        [-55.7536, 52.6614],
                        [-55.7434, 52.6543],
                        [-55.7349, 52.652],
                        [-55.7364, 52.6445],
                        [-55.7496, 52.6445],
                        [-55.7551, 52.6415],
                        [-55.7701, 52.6425],
                        [-55.7768, 52.6398],
                        [-55.7762, 52.6338],
                        [-55.7498, 52.6347],
                        [-55.7515, 52.6288],
                        [-55.7439, 52.6262],
                        [-55.7483, 52.6156],
                        [-55.7546, 52.6088],
                        [-55.7648, 52.6041],
                        [-55.7882, 52.61],
                        [-55.7772, 52.6],
                        [-55.7913, 52.5982],
                        [-55.8216, 52.6043],
                        [-55.8442, 52.6193],
                        [-55.8741, 52.6128],
                        [-55.8901, 52.6106],
                        [-55.9284, 52.62],
                        [-55.9371, 52.6276],
                        [-55.9367, 52.6356],
                        [-55.9226, 52.6376],
                        [-55.985, 52.6503],
                        [-55.9811, 52.6431],
                        [-56.0175, 52.6446],
                        [-56.0292, 52.6523],
                        [-56.0487, 52.6573],
                        [-56.059, 52.6558],
                        [-56.0906, 52.656],
                        [-56.1013, 52.6584],
                        [-56.1096, 52.6541],
                        [-56.1356, 52.6538],
                        [-56.1374, 52.6461],
                        [-56.1166, 52.6472],
                        [-56.0932, 52.6442],
                        [-56.0807, 52.649],
                        [-56.0396, 52.641],
                        [-56.0296, 52.6356],
                        [-56.0211, 52.6344],
                        [-55.9979, 52.6249],
                        [-55.9836, 52.6242],
                        [-55.9739, 52.6195],
                        [-55.964, 52.6208],
                        [-55.9584, 52.6128],
                        [-55.9649, 52.6077],
                        [-55.9741, 52.609],
                        [-55.9987, 52.6201],
                        [-56.0191, 52.6233],
                        [-56.0175, 52.6162],
                        [-56.0063, 52.6153],
                        [-55.9897, 52.6111],
                        [-55.9667, 52.6003],
                        [-55.969, 52.5951],
                        [-56.0071, 52.5936],
                        [-56.012, 52.5901],
                        [-56.0264, 52.5879],
                        [-56.0512, 52.5805],
                        [-56.0495, 52.5736],
                        [-56.0396, 52.571],
                        [-56.0343, 52.5758],
                        [-56.0238, 52.5752],
                        [-56.0167, 52.5715],
                        [-55.9839, 52.5679],
                        [-55.9811, 52.5577],
                        [-55.9872, 52.5534],
                        [-56.0015, 52.552],
                        [-56.0136, 52.5561],
                        [-56.028, 52.5582],
                        [-56.0416, 52.5577],
                        [-56.0558, 52.5619],
                        [-56.0593, 52.5693],
                        [-56.0924, 52.565],
                        [-56.0991, 52.5613],
                        [-56.118, 52.5686],
                        [-56.1215, 52.5579],
                        [-56.1326, 52.5523],
                        [-56.1775, 52.5412],
                        [-56.2506, 52.5418],
                        [-56.2816, 52.5526],
                        [-56.2921, 52.5616],
                        [-56.3129, 52.5726],
                        [-56.326, 52.5771],
                        [-56.351, 52.5795],
                        [-56.3945, 52.572],
                        [-56.4038, 52.5725],
                        [-56.4404, 52.5796],
                        [-56.4685, 52.5902],
                        [-56.5109, 52.5995],
                        [-56.5261, 52.5979],
                        [-56.4712, 52.5839],
                        [-56.4435, 52.5735],
                        [-56.4197, 52.5705],
                        [-56.3886, 52.5606],
                        [-56.3653, 52.5619],
                        [-56.3441, 52.5674],
                        [-56.3233, 52.5649],
                        [-56.3079, 52.5608],
                        [-56.2892, 52.5513],
                        [-56.279, 52.5349],
                        [-56.2462, 52.5279],
                        [-56.2353, 52.5271],
                        [-56.219, 52.5293],
                        [-56.2101, 52.5327],
                        [-56.1792, 52.5337],
                        [-56.1545, 52.5251],
                        [-56.1444, 52.5282],
                        [-56.1344, 52.5398],
                        [-56.1179, 52.5438],
                        [-56.1028, 52.541],
                        [-56.0829, 52.5321],
                        [-56.0672, 52.5318],
                        [-56.0492, 52.5363],
                        [-56.0375, 52.537],
                        [-56.0258, 52.5329],
                        [-56.0363, 52.5294],
                        [-56.0163, 52.5219],
                        [-56.0163, 52.5139],
                        [-55.9967, 52.5189],
                        [-55.9909, 52.513],
                        [-55.9943, 52.5088],
                        [-55.9728, 52.5018],
                        [-55.957, 52.502],
                        [-55.9473, 52.506],
                        [-55.9286, 52.509],
                        [-55.9104, 52.5072],
                        [-55.9043, 52.5036],
                        [-55.8898, 52.5067],
                        [-55.8795, 52.505],
                        [-55.8684, 52.5105],
                        [-55.8491, 52.5089],
                        [-55.8455, 52.5152],
                        [-55.8322, 52.5168],
                        [-55.8274, 52.5123],
                        [-55.8319, 52.4964],
                        [-55.847, 52.491],
                        [-55.8406, 52.4871],
                        [-55.8406, 52.4802],
                        [-55.8253, 52.487],
                        [-55.8129, 52.5028],
                        [-55.8037, 52.5013],
                        [-55.8069, 52.4951],
                        [-55.7866, 52.4913],
                        [-55.784, 52.4949],
                        [-55.7652, 52.4958],
                        [-55.7414, 52.4824],
                        [-55.7429, 52.4776],
                        [-55.7183, 52.4731],
                        [-55.7176, 52.465],
                        [-55.7467, 52.4698],
                        [-55.749, 52.4659],
                        [-55.7703, 52.4589],
                        [-55.7667, 52.4568],
                        [-55.7403, 52.4627],
                        [-55.732, 52.4589],
                        [-55.7392, 52.4544],
                        [-55.7509, 52.4541],
                        [-55.7546, 52.4508],
                        [-55.7501, 52.4431],
                        [-55.7409, 52.4409],
                        [-55.7282, 52.4446],
                        [-55.7256, 52.4516],
                        [-55.7176, 52.4575],
                        [-55.7155, 52.4437],
                        [-55.7036, 52.443],
                        [-55.6969, 52.4464],
                        [-55.6859, 52.4325],
                        [-55.6691, 52.4316],
                        [-55.6571, 52.4365],
                        [-55.6648, 52.4416],
                        [-55.6762, 52.4358],
                        [-55.6863, 52.4398],
                        [-55.6878, 52.4518],
                        [-55.6847, 52.4563],
                        [-55.6698, 52.455],
                        [-55.653, 52.4505],
                        [-55.6331, 52.4405],
                        [-55.6472, 52.4377],
                        [-55.6406, 52.4326],
                        [-55.6271, 52.4328],
                        [-55.6191, 52.4261],
                        [-55.641, 52.4251],
                        [-55.6328, 52.417],
                        [-55.6248, 52.403],
                        [-55.6381, 52.4046],
                        [-55.6549, 52.4014],
                        [-55.6626, 52.403],
                        [-55.6762, 52.4017],
                        [-55.6803, 52.3979],
                        [-55.6527, 52.3974],
                        [-55.6415, 52.3935],
                        [-55.625, 52.3826],
                        [-55.6286, 52.3692],
                        [-55.6194, 52.3634],
                        [-55.6249, 52.357],
                        [-55.6385, 52.3481],
                        [-55.6541, 52.3457],
                        [-55.6729, 52.3487],
                        [-55.6798, 52.3542],
                        [-55.6686, 52.3616],
                        [-55.6754, 52.3641],
                        [-55.6992, 52.3584],
                        [-55.7073, 52.3635],
                        [-55.7057, 52.3682],
                        [-55.7222, 52.3716],
                        [-55.7215, 52.3789],
                        [-55.733, 52.3798],
                        [-55.7366, 52.3732],
                        [-55.7461, 52.3761],
                        [-55.7926, 52.3783],
                        [-55.8324, 52.3813],
                        [-55.8302, 52.3769],
                        [-55.7951, 52.3724],
                        [-55.7753, 52.3637],
                        [-55.7689, 52.3566],
                        [-55.7741, 52.3533],
                        [-55.7992, 52.3531],
                        [-55.806, 52.3556],
                        [-55.8336, 52.3564],
                        [-55.8494, 52.3593],
                        [-55.8633, 52.3584],
                        [-55.8424, 52.3505],
                        [-55.7788, 52.343],
                        [-55.7672, 52.3439],
                        [-55.7564, 52.3387],
                        [-55.758, 52.334],
                        [-55.7697, 52.3335],
                        [-55.7877, 52.3362],
                        [-55.8033, 52.3333],
                        [-55.8378, 52.344],
                        [-55.8519, 52.3447],
                        [-55.8664, 52.3502],
                        [-55.9031, 52.3529],
                        [-55.9482, 52.3749],
                        [-55.9567, 52.3756],
                        [-55.9943, 52.3856],
                        [-56.0263, 52.3998],
                        [-56.0516, 52.4018],
                        [-56.0618, 52.4083],
                        [-56.0881, 52.4163],
                        [-56.1058, 52.4189],
                        [-56.1202, 52.4256],
                        [-56.1305, 52.428],
                        [-56.1476, 52.4286],
                        [-56.1692, 52.435],
                        [-56.2019, 52.4464],
                        [-56.2256, 52.449],
                        [-56.191, 52.4378],
                        [-56.1831, 52.4336],
                        [-56.1761, 52.424],
                        [-56.1675, 52.4196],
                        [-56.1504, 52.4188],
                        [-56.1327, 52.4155],
                        [-56.125, 52.4091],
                        [-56.0977, 52.3969],
                        [-56.0509, 52.3928],
                        [-56.0132, 52.3796],
                        [-56.0032, 52.3786],
                        [-55.9726, 52.3659],
                        [-55.9685, 52.3692],
                        [-55.9471, 52.3542],
                        [-55.9613, 52.3545],
                        [-55.9716, 52.3523],
                        [-55.9797, 52.3455],
                        [-55.9579, 52.3468],
                        [-55.9304, 52.3445],
                        [-55.9148, 52.3396],
                        [-55.907, 52.3311],
                        [-55.8969, 52.3278],
                        [-55.866, 52.3301],
                        [-55.8679, 52.3254],
                        [-55.8602, 52.3203],
                        [-55.8493, 52.3208],
                        [-55.843, 52.3171],
                        [-55.8259, 52.3197],
                        [-55.8156, 52.3182],
                        [-55.8122, 52.3142],
                        [-55.8179, 52.3052],
                        [-55.7928, 52.2991],
                        [-55.7907, 52.2933],
                        [-55.8049, 52.2888],
                        [-55.8237, 52.2766],
                        [-55.8059, 52.2753],
                        [-55.7823, 52.2872],
                        [-55.7756, 52.2872],
                        [-55.7612, 52.2817],
                        [-55.7695, 52.2726],
                        [-55.7627, 52.2659],
                        [-55.7545, 52.2665],
                        [-55.7481, 52.2743],
                        [-55.7146, 52.2884],
                        [-55.699, 52.284],
                        [-55.6945, 52.2765],
                        [-55.7073, 52.2714],
                        [-55.7163, 52.2713],
                        [-55.7201, 52.2628],
                        [-55.726, 52.2595],
                        [-55.7437, 52.2616],
                        [-55.7503, 52.252],
                        [-55.7625, 52.2446],
                        [-55.8055, 52.2288],
                        [-55.7851, 52.2274],
                        [-55.7704, 52.2362],
                        [-55.7517, 52.241],
                        [-55.7378, 52.2421],
                        [-55.7224, 52.2468],
                        [-55.7049, 52.2466],
                        [-55.6951, 52.2405],
                        [-55.7055, 52.2293],
                        [-55.6789, 52.2308],
                        [-55.6559, 52.229],
                        [-55.6489, 52.221],
                        [-55.6425, 52.2219],
                        [-55.6329, 52.2169],
                        [-55.6158, 52.2126],
                        [-55.6175, 52.2067],
                        [-55.6287, 52.2062],
                        [-55.6336, 52.1952],
                        [-55.6397, 52.1899],
                        [-55.6503, 52.1956],
                        [-55.6566, 52.1917],
                        [-55.677, 52.1964],
                        [-55.683, 52.2035],
                        [-55.697, 52.2127],
                        [-55.7044, 52.2056],
                        [-55.708, 52.1964],
                        [-55.7303, 52.1961],
                        [-55.7292, 52.2036],
                        [-55.7364, 52.21],
                        [-55.7398, 52.2177],
                        [-55.7504, 52.2078],
                        [-55.7725, 52.2029],
                        [-55.7708, 52.198],
                        [-55.7616, 52.1926],
                        [-55.7474, 52.1928],
                        [-55.742, 52.1876],
                        [-55.7468, 52.1839],
                        [-55.7419, 52.1766],
                        [-55.7162, 52.1802],
                        [-55.7099, 52.1729],
                        [-55.6988, 52.1715],
                        [-55.6822, 52.1631],
                        [-55.7037, 52.1525],
                        [-55.6989, 52.1477],
                        [-55.7013, 52.1275],
                        [-55.6918, 52.1221],
                        [-55.6809, 52.1196],
                        [-55.6868, 52.1081],
                        [-55.6858, 52.0999],
                        [-55.6879, 52.0858],
                        [-55.6989, 52.0761],
                        [-55.7089, 52.0762],
                        [-55.7167, 52.08],
                        [-55.7571, 52.0769],
                        [-55.7674, 52.0779],
                        [-55.791, 52.0877],
                        [-55.7868, 52.0734],
                        [-55.7767, 52.0698],
                        [-55.7598, 52.0603],
                        [-55.7637, 52.0547],
                        [-55.7672, 52.0379],
                        [-55.7742, 52.034],
                        [-55.7782, 52.0236],
                        [-55.8017, 52.0185],
                        [-55.8057, 52.0233],
                        [-55.8157, 52.0236],
                        [-55.8345, 52.017],
                        [-55.8313, 52.0072],
                        [-55.8236, 52.0065],
                        [-55.8194, 52.0002],
                        [-55.8488, 52.0001],
                        [-55.8542, 52.0088],
                        [-55.8647, 52.0078],
                        [-55.8812, 52.0173],
                        [-55.8856, 52.0138],
                        [-55.8812, 51.9991],
                        [-55.9016, 51.9969],
                        [-55.9244, 52.0158],
                        [-55.9442, 52.0218],
                        [-55.9646, 52.0223],
                        [-55.9792, 52.0253],
                        [-55.978, 52.0177],
                        [-55.9497, 52.0103],
                        [-55.9357, 51.996],
                        [-55.9238, 51.9932],
                        [-55.9213, 51.9882],
                        [-55.9028, 51.9798],
                        [-55.8941, 51.9823],
                        [-55.8974, 51.9662],
                        [-55.8939, 51.9604],
                        [-55.9307, 51.9342],
                        [-55.9602, 51.9189],
                        [-55.9647, 51.9145],
                        [-55.9962, 51.9009],
                        [-56.0119, 51.9014],
                        [-56.0348, 51.8903],
                        [-56.0452, 51.8896],
                        [-56.0436, 51.8835],
                        [-56.0626, 51.8736],
                        [-56.0725, 51.8701],
                        [-56.0759, 51.8651],
                        [-56.0941, 51.8535],
                        [-56.1011, 51.8558],
                        [-56.122, 51.8507],
                        [-56.1311, 51.8538],
                        [-56.1369, 51.8476],
                        [-56.1375, 51.8374],
                        [-56.1912, 51.8078],
                        [-56.2056, 51.8106],
                        [-56.203, 51.7961],
                        [-56.2142, 51.7847],
                        [-56.2295, 51.7824],
                        [-56.2364, 51.7785],
                        [-56.2585, 51.7793],
                        [-56.2948, 51.7706],
                        [-56.3104, 51.7744],
                        [-56.3343, 51.7751],
                        [-56.3492, 51.7775],
                        [-56.3483, 51.7719],
                        [-56.3303, 51.7619],
                        [-56.3356, 51.7481],
                        [-56.3567, 51.7403],
                        [-56.3667, 51.7407],
                        [-56.3774, 51.7339],
                        [-56.3889, 51.7307],
                        [-56.3978, 51.7342],
                        [-56.4046, 51.728],
                        [-56.4207, 51.7261],
                        [-56.4303, 51.7331],
                        [-56.4258, 51.7398],
                        [-56.4375, 51.7445],
                        [-56.4411, 51.737],
                        [-56.4376, 51.7299],
                        [-56.4486, 51.7207],
                        [-56.4615, 51.7173],
                        [-56.4575, 51.7072],
                        [-56.4682, 51.7025],
                        [-56.4991, 51.6833],
                        [-56.5065, 51.6841],
                        [-56.5084, 51.677],
                        [-56.5164, 51.6728],
                        [-56.5252, 51.6757],
                        [-56.5484, 51.6709],
                        [-56.554, 51.6642],
                        [-56.5739, 51.6515],
                        [-56.6109, 51.6391],
                        [-56.6276, 51.6366],
                        [-56.6346, 51.6383],
                        [-56.66, 51.6343],
                        [-56.6839, 51.635],
                        [-56.6976, 51.6315],
                        [-56.7054, 51.6252],
                        [-56.713, 51.6148],
                        [-56.7026, 51.6014],
                        [-56.7079, 51.5806],
                        [-56.7154, 51.573],
                        [-56.7304, 51.5691],
                        [-56.7467, 51.5602],
                        [-56.7618, 51.5588],
                        [-56.7726, 51.534],
                        [-56.7913, 51.5217],
                        [-56.7971, 51.5208],
                        [-56.8154, 51.5263],
                        [-56.8302, 51.5199],
                        [-56.8247, 51.4979],
                        [-56.8159, 51.4947],
                        [-56.8196, 51.4835],
                        [-56.8391, 51.4693],
                        [-56.8623, 51.4587],
                        [-56.8732, 51.462],
                        [-56.87, 51.4717],
                        [-56.8781, 51.4768],
                        [-56.887, 51.4776],
                        [-56.8975, 51.4747],
                        [-56.9107, 51.474],
                        [-56.9366, 51.4903],
                        [-56.9472, 51.4882],
                        [-56.9442, 51.4822],
                        [-56.9551, 51.473],
                        [-56.9453, 51.456],
                        [-56.9452, 51.4366],
                        [-56.9551, 51.4259],
                        [-56.9989, 51.4166],
                        [-57.0089, 51.4182],
                        [-57.0346, 51.4128],
                        [-57.0507, 51.4139],
                        [-57.054, 51.4232],
                        [-57.0633, 51.4276],
                        [-57.0754, 51.4151],
                        [-57.087, 51.4164],
                        [-57.0992, 51.4138],
                        [-57.1085, 51.4154],
                        [-57.1075, 51.5205],
                        [-57.1079, 51.5909],
                        [-57.1059, 51.591],
                        [-57.1055, 51.6565],
                        [-57.1063, 51.7478],
                        [-57.1063, 51.7898],
                        [-57.1075, 51.9235],
                        [-57.1078, 51.9985],
                        [-57.3194, 51.9979],
                        [-57.6314, 51.9977],
                        [-57.786, 51.9972],
                        [-57.9647, 51.9964],
                        [-58.1651, 51.9969],
                        [-58.3229, 51.9971],
                        [-58.5, 51.9975],
                        [-58.7866, 51.9976],
                        [-59.0344, 51.997],
                        [-59.2499, 51.9973],
                        [-59.3881, 51.9973],
                        [-59.5, 51.9975],
                        [-59.7408, 51.9976],
                        [-59.8834, 51.9977],
                        [-60.0928, 51.9974],
                        [-60.2135, 51.9972],
                        [-60.4417, 51.9965],
                        [-60.7868, 51.9973],
                        [-61, 51.9968],
                        [-61.1299, 51.9962],
                        [-61.3686, 51.9966],
                        [-61.6434, 51.9963],
                        [-61.8065, 51.9958],
                        [-62, 51.9967],
                        [-62.1605, 51.9972],
                        [-62.3329, 51.9974],
                        [-62.5, 51.9973],
                        [-62.6988, 51.9972],
                        [-62.8442, 51.997],
                        [-63.0002, 51.9973],
                        [-63.2255, 51.9974],
                        [-63.533, 51.9973],
                        [-63.8081, 51.9978],
                        [-63.8017, 52.0099],
                        [-63.8027, 52.0174],
                        [-63.8092, 52.0241],
                        [-63.809, 52.0298],
                        [-63.8161, 52.0346],
                        [-63.8252, 52.0284],
                        [-63.8309, 52.0481],
                        [-63.8401, 52.065],
                        [-63.8322, 52.0815],
                        [-63.824, 52.0847],
                        [-63.8103, 52.0854],
                        [-63.7874, 52.0783],
                        [-63.7841, 52.0837],
                        [-63.7717, 52.0813],
                        [-63.7443, 52.0648],
                        [-63.7258, 52.0606],
                        [-63.7063, 52.0497],
                        [-63.6893, 52.0496],
                        [-63.6845, 52.055],
                        [-63.672, 52.0515],
                        [-63.66, 52.0408],
                        [-63.648, 52.0488],
                        [-63.6595, 52.0576],
                        [-63.6606, 52.0669],
                        [-63.6703, 52.0719],
                        [-63.6775, 52.0831],
                        [-63.6889, 52.0862],
                        [-63.6924, 52.0785],
                        [-63.702, 52.0876],
                        [-63.6971, 52.1141],
                        [-63.6906, 52.1298],
                        [-63.6967, 52.1365],
                        [-63.7033, 52.1542],
                        [-63.7002, 52.1655],
                        [-63.7125, 52.1804],
                        [-63.7108, 52.191],
                        [-63.726, 52.2129],
                        [-63.7528, 52.235],
                        [-63.7581, 52.249],
                        [-63.7482, 52.2555],
                        [-63.7516, 52.2607],
                        [-63.7657, 52.2694],
                        [-63.7919, 52.2704],
                        [-63.7846, 52.2761],
                        [-63.7685, 52.2761],
                        [-63.7707, 52.2839],
                        [-63.7941, 52.2969],
                        [-63.8197, 52.3034],
                        [-63.8176, 52.3183],
                        [-63.8081, 52.312],
                        [-63.796, 52.3103],
                        [-63.789, 52.3155],
                        [-63.7829, 52.3093],
                        [-63.7747, 52.3139],
                        [-63.766, 52.3089],
                        [-63.7432, 52.308],
                        [-63.7476, 52.3164],
                        [-63.7634, 52.3218],
                        [-63.7695, 52.3281],
                        [-63.8269, 52.3308],
                        [-63.8259, 52.3197],
                        [-63.8366, 52.3223],
                        [-63.8546, 52.3192],
                        [-63.8597, 52.3222],
                        [-63.8732, 52.3225],
                        [-63.8998, 52.3363],
                        [-63.9125, 52.3409],
                        [-63.9424, 52.3454],
                        [-63.9652, 52.3577],
                        [-63.9749, 52.3535],
                        [-63.9886, 52.3602],
                        [-63.9983, 52.3596],
                        [-64.0127, 52.3626],
                        [-64.0183, 52.3745],
                        [-64.0301, 52.3897],
                        [-64.0206, 52.401],
                        [-64.0455, 52.4233],
                        [-64.0741, 52.4383],
                        [-64.0902, 52.4543],
                        [-64.0935, 52.4661],
                        [-64.0855, 52.4933],
                        [-64.0846, 52.5041],
                        [-64.072, 52.5001],
                        [-64.0538, 52.5333],
                        [-64.0448, 52.5416],
                        [-64.038, 52.5611],
                        [-64.0299, 52.567],
                        [-64.0125, 52.5708],
                        [-63.996, 52.5791],
                        [-63.9927, 52.5838],
                        [-63.9699, 52.5894],
                        [-63.9596, 52.5961],
                        [-63.9464, 52.5963],
                        [-63.9397, 52.6057],
                        [-63.9117, 52.6034],
                        [-63.9076, 52.6083],
                        [-63.8945, 52.6126],
                        [-63.8799, 52.6138],
                        [-63.8717, 52.6188],
                        [-63.851, 52.6225],
                        [-63.8258, 52.6226],
                        [-63.8108, 52.6281],
                        [-63.7967, 52.6303],
                        [-63.7739, 52.6315],
                        [-63.7666, 52.6347],
                        [-63.7532, 52.6353],
                        [-63.7367, 52.632],
                        [-63.7139, 52.6331],
                        [-63.653, 52.6388],
                        [-63.6134, 52.6471],
                        [-63.5835, 52.6487],
                        [-63.5233, 52.6488],
                        [-63.5145, 52.6461],
                        [-63.5019, 52.6496],
                        [-63.4668, 52.647],
                        [-63.4631, 52.6482],
                        [-63.4294, 52.6455],
                        [-63.4261, 52.652],
                        [-63.4164, 52.6525],
                        [-63.4081, 52.6617],
                        [-63.3913, 52.6582],
                        [-63.3838, 52.6594],
                        [-63.3836, 52.6671],
                        [-63.3965, 52.6823],
                        [-63.4232, 52.6923],
                        [-63.4358, 52.6995],
                        [-63.45, 52.7136],
                        [-63.4594, 52.7181],
                        [-63.5065, 52.7271],
                        [-63.5184, 52.7253],
                        [-63.528, 52.7276],
                        [-63.5509, 52.7411],
                        [-63.5572, 52.7489],
                        [-63.5701, 52.7598],
                        [-63.5853, 52.7659],
                        [-63.6098, 52.7703],
                        [-63.6165, 52.7742],
                        [-63.6157, 52.7918],
                        [-63.5964, 52.7956],
                        [-63.6006, 52.8058],
                        [-63.595, 52.8164],
                        [-63.5989, 52.8269],
                        [-63.5892, 52.8331],
                        [-63.5871, 52.8395],
                        [-63.5941, 52.8515],
                        [-63.6079, 52.8593],
                        [-63.6144, 52.8775],
                        [-63.6233, 52.8751],
                        [-63.6194, 52.87],
                        [-63.6242, 52.8624],
                        [-63.61, 52.8549],
                        [-63.6066, 52.8499],
                        [-63.6164, 52.8416],
                        [-63.6379, 52.8469],
                        [-63.6521, 52.8529],
                        [-63.6718, 52.8551],
                        [-63.6761, 52.8491],
                        [-63.6417, 52.8373],
                        [-63.6394, 52.8337],
                        [-63.6441, 52.8238],
                        [-63.653, 52.8142],
                        [-63.6601, 52.8112],
                        [-63.6804, 52.8081],
                        [-63.6898, 52.8084],
                        [-63.7007, 52.8035],
                        [-63.7119, 52.8033],
                        [-63.7195, 52.799],
                        [-63.7141, 52.7864],
                        [-63.7307, 52.7874],
                        [-63.7319, 52.782],
                        [-63.7194, 52.7766],
                        [-63.7215, 52.7703],
                        [-63.7516, 52.7684],
                        [-63.7644, 52.7741],
                        [-63.7782, 52.7699],
                        [-63.798, 52.7665],
                        [-63.8099, 52.7599],
                        [-63.819, 52.7585],
                        [-63.8347, 52.7637],
                        [-63.8541, 52.7734],
                        [-63.8729, 52.774],
                        [-63.8788, 52.7664],
                        [-63.8939, 52.7639],
                        [-63.8833, 52.7533],
                        [-63.893, 52.7501],
                        [-63.9147, 52.7577],
                        [-63.9213, 52.7564],
                        [-63.9182, 52.7401],
                        [-63.9212, 52.7359],
                        [-63.9374, 52.731],
                        [-63.9592, 52.7293],
                        [-63.9827, 52.7254],
                        [-64.007, 52.737],
                        [-64.0373, 52.7382],
                        [-64.0354, 52.7322],
                        [-64.0393, 52.7259],
                        [-64.0535, 52.7232],
                        [-64.0629, 52.725],
                        [-64.0795, 52.7366],
                        [-64.0896, 52.7332],
                        [-64.1242, 52.732],
                        [-64.1405, 52.7267],
                        [-64.1461, 52.7145],
                        [-64.1244, 52.6993],
                        [-64.1277, 52.6864],
                        [-64.145, 52.6754],
                        [-64.1535, 52.6731],
                        [-64.1676, 52.6783],
                        [-64.173, 52.6706],
                        [-64.1589, 52.6585],
                        [-64.153, 52.6473],
                        [-64.1531, 52.6404],
                        [-64.1633, 52.6274],
                        [-64.1701, 52.6237],
                        [-64.1685, 52.6121],
                        [-64.1546, 52.6034],
                        [-64.1677, 52.596],
                        [-64.1715, 52.5843],
                        [-64.1875, 52.5908],
                        [-64.1947, 52.5958],
                        [-64.2042, 52.5891],
                        [-64.1991, 52.5817],
                        [-64.2111, 52.5736],
                        [-64.1914, 52.5543],
                        [-64.1702, 52.5436],
                        [-64.1671, 52.5288],
                        [-64.1519, 52.5199],
                        [-64.1485, 52.5138],
                        [-64.1529, 52.5022],
                        [-64.1447, 52.4916],
                        [-64.1426, 52.4679],
                        [-64.1371, 52.4565],
                        [-64.1352, 52.4449],
                        [-64.1278, 52.4343],
                        [-64.135, 52.4245],
                        [-64.128, 52.4206],
                        [-64.1147, 52.42],
                        [-64.1074, 52.4083],
                        [-64.119, 52.4032],
                        [-64.1092, 52.3941],
                        [-64.1155, 52.3883],
                        [-64.127, 52.3838],
                        [-64.138, 52.3669],
                        [-64.1666, 52.3602],
                        [-64.1664, 52.3445],
                        [-64.1584, 52.339],
                        [-64.1615, 52.3339],
                        [-64.172, 52.3308],
                        [-64.1733, 52.3245],
                        [-64.1647, 52.3144],
                        [-64.1695, 52.3067],
                        [-64.1801, 52.3064],
                        [-64.1916, 52.2897],
                        [-64.1946, 52.2913],
                        [-64.1996, 52.3056],
                        [-64.2102, 52.3136],
                        [-64.2256, 52.3077],
                        [-64.2256, 52.3025],
                        [-64.2069, 52.2942],
                        [-64.2098, 52.2845],
                        [-64.2219, 52.2833],
                        [-64.2327, 52.2879],
                        [-64.2388, 52.2775],
                        [-64.2466, 52.2754],
                        [-64.2365, 52.2639],
                        [-64.2212, 52.261],
                        [-64.2176, 52.2571],
                        [-64.2236, 52.2404],
                        [-64.2198, 52.2377],
                        [-64.224, 52.2295],
                        [-64.2135, 52.2249],
                        [-64.2097, 52.2185],
                        [-64.2099, 52.2028],
                        [-64.1984, 52.1888],
                        [-64.2007, 52.177],
                        [-64.1883, 52.1641],
                        [-64.1608, 52.146],
                        [-64.1647, 52.1388],
                        [-64.1774, 52.1338],
                        [-64.1647, 52.1239],
                        [-64.1664, 52.1161],
                        [-64.173, 52.1129],
                        [-64.1911, 52.1208],
                        [-64.193, 52.1244],
                        [-64.2112, 52.1322],
                        [-64.2424, 52.1428],
                        [-64.2363, 52.1294],
                        [-64.2539, 52.1215],
                        [-64.2564, 52.1129],
                        [-64.246, 52.103],
                        [-64.2566, 52.0972],
                        [-64.2497, 52.0873],
                        [-64.2515, 52.0797],
                        [-64.2724, 52.0793],
                        [-64.2787, 52.0761],
                        [-64.2919, 52.0776],
                        [-64.2873, 52.0656],
                        [-64.2811, 52.0559],
                        [-64.2693, 52.0469],
                        [-64.2794, 52.0376],
                        [-64.2662, 52.0216],
                        [-64.2465, 52.0009],
                        [-64.2465, 51.9974],
                        [-64.234, 51.9909],
                        [-64.2371, 51.978],
                        [-64.2801, 51.9748],
                        [-64.2879, 51.9767],
                        [-64.2992, 51.9864],
                        [-64.323, 51.9944],
                        [-64.3269, 51.9984],
                        [-64.3229, 52.0049],
                        [-64.3385, 52.0072],
                        [-64.348, 52.0013],
                        [-64.3417, 51.9859],
                        [-64.3588, 51.9791],
                        [-64.3453, 51.9733],
                        [-64.343, 51.9677],
                        [-64.3445, 51.9507],
                        [-64.3496, 51.9443],
                        [-64.3451, 51.9375],
                        [-64.3573, 51.9274],
                        [-64.3498, 51.9219],
                        [-64.3443, 51.9127],
                        [-64.3401, 51.8905],
                        [-64.353, 51.887],
                        [-64.352, 51.8636],
                        [-64.3527, 51.8493],
                        [-64.3387, 51.8288],
                        [-64.3209, 51.8139],
                        [-64.3025, 51.8036],
                        [-64.3012, 51.7966],
                        [-64.3072, 51.7881],
                        [-64.3043, 51.781],
                        [-64.3048, 51.7628],
                        [-64.2988, 51.7549],
                        [-64.2783, 51.7464],
                        [-64.2764, 51.7365],
                        [-64.294, 51.7228],
                        [-64.2965, 51.7137],
                        [-64.3052, 51.7036],
                        [-64.3224, 51.698],
                        [-64.3256, 51.6942],
                        [-64.3221, 51.685],
                        [-64.3343, 51.6716],
                        [-64.3371, 51.6653],
                        [-64.3589, 51.6716],
                        [-64.3775, 51.664],
                        [-64.3956, 51.664],
                        [-64.4173, 51.675],
                        [-64.423, 51.6761],
                        [-64.4299, 51.6678],
                        [-64.4308, 51.6578],
                        [-64.4478, 51.6322],
                        [-64.4553, 51.6246],
                        [-64.4909, 51.6091],
                        [-64.5076, 51.6055],
                        [-64.5416, 51.5864],
                        [-64.5485, 51.5739],
                        [-64.5544, 51.5758],
                        [-64.5754, 51.6018],
                        [-64.5873, 51.6095],
                        [-64.5969, 51.6276],
                        [-64.6144, 51.6267],
                        [-64.6242, 51.629],
                        [-64.6237, 51.6422],
                        [-64.626, 51.6469],
                        [-64.6169, 51.6514],
                        [-64.6211, 51.6681],
                        [-64.6404, 51.674],
                        [-64.6378, 51.6792],
                        [-64.6447, 51.6917],
                        [-64.6399, 51.705],
                        [-64.6535, 51.7015],
                        [-64.6774, 51.7014],
                        [-64.6811, 51.7098],
                        [-64.6916, 51.7076],
                        [-64.6881, 51.717],
                        [-64.7031, 51.7232],
                        [-64.7079, 51.7293],
                        [-64.7109, 51.7456],
                        [-64.7098, 51.756],
                        [-64.7309, 51.7522],
                        [-64.7394, 51.7615],
                        [-64.7555, 51.7589],
                        [-64.7731, 51.7531],
                        [-64.794, 51.7612],
                        [-64.8019, 51.7755],
                        [-64.8118, 51.7778],
                        [-64.8384, 51.7696],
                        [-64.8478, 51.7625],
                        [-64.8598, 51.764],
                        [-64.8722, 51.77],
                        [-64.8922, 51.7688],
                        [-64.9247, 51.7779],
                        [-64.9386, 51.7644],
                        [-64.9198, 51.7608],
                        [-64.9184, 51.755],
                        [-64.9276, 51.7464],
                        [-64.9164, 51.744],
                        [-64.9098, 51.7367],
                        [-64.9164, 51.735],
                        [-64.9249, 51.7186],
                        [-64.9307, 51.7143],
                        [-64.9577, 51.7166],
                        [-64.9724, 51.7216],
                        [-64.987, 51.7314],
                        [-64.9963, 51.7419],
                        [-64.9992, 51.75],
                        [-65.0083, 51.75],
                        [-65.0205, 51.7586],
                        [-65.0204, 51.7614],
                        [-65.0426, 51.7645],
                        [-65.0492, 51.7696],
                        [-65.071, 51.7622],
                        [-65.0826, 51.7462],
                        [-65.1077, 51.7487],
                        [-65.1196, 51.7537],
                        [-65.1423, 51.7579],
                        [-65.1623, 51.7646],
                        [-65.1804, 51.7656],
                        [-65.1867, 51.7699],
                        [-65.1861, 51.7756],
                        [-65.1687, 51.7898],
                        [-65.1672, 51.7966],
                        [-65.1729, 51.8096],
                        [-65.1696, 51.8221],
                        [-65.1802, 51.8231],
                        [-65.1886, 51.8173],
                        [-65.1954, 51.8215],
                        [-65.2046, 51.8128],
                        [-65.2102, 51.8129],
                        [-65.2289, 51.8216],
                        [-65.2405, 51.8227],
                        [-65.2477, 51.8296],
                        [-65.2548, 51.8234],
                        [-65.2632, 51.828],
                        [-65.2644, 51.8367],
                        [-65.2531, 51.8573],
                        [-65.2539, 51.8615],
                        [-65.2835, 51.8738],
                        [-65.2907, 51.8697],
                        [-65.3002, 51.878],
                        [-65.3233, 51.87],
                        [-65.3178, 51.8508],
                        [-65.3252, 51.8432],
                        [-65.327, 51.8364],
                        [-65.3368, 51.8232],
                        [-65.3461, 51.8197],
                        [-65.3598, 51.8228],
                        [-65.355, 51.8299],
                        [-65.3568, 51.8444],
                        [-65.3626, 51.8642],
                        [-65.3728, 51.8642],
                        [-65.3834, 51.8673],
                        [-65.3851, 51.8739],
                        [-65.3922, 51.881],
                        [-65.3976, 51.8908],
                        [-65.3995, 51.9019],
                        [-65.3932, 51.9149],
                        [-65.3741, 51.9158],
                        [-65.3548, 51.9427],
                        [-65.3497, 51.9446],
                        [-65.3604, 51.956],
                        [-65.3591, 51.9811],
                        [-65.3714, 51.9903],
                        [-65.3839, 51.9888],
                        [-65.3645, 51.9809],
                        [-65.3749, 51.9765],
                        [-65.3712, 51.9673],
                        [-65.39, 51.9634],
                        [-65.4123, 51.9713],
                        [-65.411, 51.9864],
                        [-65.4249, 51.9934],
                        [-65.4268, 52.004],
                        [-65.4512, 52.0086],
                        [-65.4562, 52.0111],
                        [-65.4961, 52.0502],
                        [-65.4845, 52.0569],
                        [-65.4972, 52.0603],
                        [-65.494, 52.0861],
                        [-65.4901, 52.1021],
                        [-65.499, 52.1062],
                        [-65.5018, 52.098],
                        [-65.5138, 52.0828],
                        [-65.5307, 52.0708],
                        [-65.5311, 52.0606],
                        [-65.5418, 52.0493],
                        [-65.5399, 52.0446],
                        [-65.5484, 52.0389],
                        [-65.5642, 52.0339],
                        [-65.5793, 52.0328],
                        [-65.5983, 52.0349],
                        [-65.6205, 52.043],
                        [-65.6268, 52.0267],
                        [-65.6367, 52.0117],
                        [-65.634, 52],
                        [-65.6382, 51.9925],
                        [-65.6468, 51.9873],
                        [-65.6597, 51.9894],
                        [-65.6686, 51.9944],
                        [-65.6774, 52.0077],
                        [-65.6782, 52.0135],
                        [-65.6687, 52.0263],
                        [-65.6695, 52.0322],
                        [-65.6799, 52.0415],
                        [-65.6581, 52.0499],
                        [-65.6512, 52.0553],
                        [-65.6537, 52.0678],
                        [-65.6486, 52.0809],
                        [-65.6624, 52.0924],
                        [-65.6669, 52.1064],
                        [-65.6645, 52.1151],
                        [-65.6748, 52.1176],
                        [-65.6866, 52.1038],
                        [-65.7139, 52.0962],
                        [-65.7293, 52.0835],
                        [-65.7383, 52.0852],
                        [-65.753, 52.1078],
                        [-65.7616, 52.1043],
                        [-65.7713, 52.1115],
                        [-65.7762, 52.1045],
                        [-65.7974, 52.1057],
                        [-65.8054, 52.1083],
                        [-65.81, 52.1189],
                        [-65.8222, 52.1102],
                        [-65.842, 52.1165],
                        [-65.8669, 52.1145],
                        [-65.877, 52.1104],
                        [-65.8857, 52.1034],
                        [-65.889, 52.0971],
                        [-65.8876, 52.0878],
                        [-65.8977, 52.0761],
                        [-65.9133, 52.0814],
                        [-65.9193, 52.0911],
                        [-65.9548, 52.0999],
                        [-65.9613, 52.1046],
                        [-65.9679, 52.0931],
                        [-65.9614, 52.0774],
                        [-65.966, 52.0709],
                        [-65.9704, 52.0565],
                        [-65.9788, 52.0527],
                        [-65.9962, 52.0547],
                        [-66.0029, 52.0672],
                        [-66.0165, 52.0727],
                        [-66.0156, 52.0799],
                        [-66.0351, 52.0851],
                        [-66.0496, 52.0854],
                        [-66.0663, 52.0978],
                        [-66.0771, 52.0969],
                        [-66.0905, 52.0907],
                        [-66.0969, 52.0907],
                        [-66.0964, 52.1078],
                        [-66.0782, 52.1247],
                        [-66.0758, 52.1308],
                        [-66.0787, 52.1413],
                        [-66.0759, 52.155],
                        [-66.0826, 52.1617],
                        [-66.0874, 52.1723],
                        [-66.1008, 52.1921],
                        [-66.1091, 52.202],
                        [-66.128, 52.2102],
                        [-66.1384, 52.2246],
                        [-66.144, 52.2279],
                        [-66.16, 52.2317],
                        [-66.1697, 52.2156],
                        [-66.177, 52.2133],
                        [-66.2008, 52.227],
                        [-66.2084, 52.2341],
                        [-66.2057, 52.2439],
                        [-66.2181, 52.2571],
                        [-66.2373, 52.2628],
                        [-66.2496, 52.274],
                        [-66.2521, 52.2802],
                        [-66.2487, 52.2896],
                        [-66.2539, 52.2974],
                        [-66.2537, 52.3041],
                        [-66.26, 52.3093],
                        [-66.2871, 52.3091],
                        [-66.2818, 52.3036],
                        [-66.2896, 52.2967],
                        [-66.2796, 52.2916],
                        [-66.2835, 52.2854],
                        [-66.2969, 52.2835],
                        [-66.3126, 52.2855],
                        [-66.3094, 52.2759],
                        [-66.2982, 52.2613],
                        [-66.2994, 52.2432],
                        [-66.2929, 52.2318],
                        [-66.2867, 52.2085],
                        [-66.278, 52.19],
                        [-66.2674, 52.1735],
                        [-66.2641, 52.1652],
                        [-66.2651, 52.155],
                        [-66.2694, 52.1492],
                        [-66.2917, 52.1468],
                        [-66.3068, 52.1368],
                        [-66.3236, 52.1318],
                        [-66.3301, 52.1359],
                        [-66.3332, 52.1507],
                        [-66.358, 52.1466],
                        [-66.3764, 52.1528],
                        [-66.3798, 52.1626],
                        [-66.3624, 52.1691],
                        [-66.3568, 52.1766],
                        [-66.3713, 52.1874],
                        [-66.3755, 52.1983],
                        [-66.3896, 52.2126],
                        [-66.4027, 52.2169],
                        [-66.4027, 52.2266],
                        [-66.411, 52.2304],
                        [-66.4175, 52.2273],
                        [-66.426, 52.2174],
                        [-66.4381, 52.2235],
                        [-66.4455, 52.2362],
                        [-66.4541, 52.2463],
                        [-66.4704, 52.2557],
                        [-66.4551, 52.2627],
                        [-66.4329, 52.2662],
                        [-66.4394, 52.2772],
                        [-66.4479, 52.2815],
                        [-66.4563, 52.2796],
                        [-66.4688, 52.2847],
                        [-66.4554, 52.2977],
                        [-66.4677, 52.3002],
                        [-66.4798, 52.3155],
                        [-66.4881, 52.3215],
                        [-66.4853, 52.3261],
                        [-66.4905, 52.3416],
                        [-66.4769, 52.3516],
                        [-66.4673, 52.3545],
                        [-66.4533, 52.3484],
                        [-66.4366, 52.3588],
                        [-66.4411, 52.3758],
                        [-66.4199, 52.383],
                        [-66.4098, 52.3745],
                        [-66.3871, 52.3698],
                        [-66.3749, 52.3633],
                        [-66.3702, 52.3539],
                        [-66.3638, 52.3514],
                        [-66.3545, 52.3536],
                        [-66.344, 52.3604],
                        [-66.3437, 52.3749],
                        [-66.3465, 52.3808],
                        [-66.3401, 52.3926],
                        [-66.3417, 52.3972],
                        [-66.3564, 52.4034],
                        [-66.3729, 52.413],
                        [-66.3728, 52.4167],
                        [-66.3615, 52.4256],
                        [-66.3588, 52.4308],
                        [-66.3908, 52.4659],
                        [-66.3939, 52.4864],
                        [-66.3816, 52.4841],
                        [-66.3725, 52.4732],
                        [-66.3606, 52.4664],
                        [-66.3556, 52.4732],
                        [-66.3581, 52.4883],
                        [-66.3639, 52.4943],
                        [-66.3646, 52.5072],
                        [-66.3703, 52.513],
                        [-66.3955, 52.5244],
                        [-66.3901, 52.5315],
                        [-66.3681, 52.5309],
                        [-66.3711, 52.552],
                        [-66.3782, 52.5647],
                        [-66.3908, 52.5765],
                        [-66.4041, 52.593],
                        [-66.4208, 52.607],
                        [-66.4274, 52.6183],
                        [-66.4399, 52.6295],
                        [-66.4403, 52.6364],
                        [-66.4328, 52.6398],
                        [-66.4164, 52.642],
                        [-66.4176, 52.6512],
                        [-66.4052, 52.6672],
                        [-66.4022, 52.6745],
                        [-66.3887, 52.6823],
                        [-66.3829, 52.6801],
                        [-66.3661, 52.6674],
                        [-66.3632, 52.66],
                        [-66.3508, 52.6504],
                        [-66.3408, 52.6454],
                        [-66.3238, 52.6219],
                        [-66.32, 52.6134],
                        [-66.3093, 52.6046],
                        [-66.2908, 52.6001],
                        [-66.2849, 52.6042],
                        [-66.2802, 52.6174],
                        [-66.2812, 52.6243],
                        [-66.2777, 52.6345],
                        [-66.2961, 52.6346],
                        [-66.304, 52.6372],
                        [-66.297, 52.6454],
                        [-66.3118, 52.659],
                        [-66.3081, 52.663],
                        [-66.314, 52.6697],
                        [-66.3289, 52.6767],
                        [-66.3391, 52.6846],
                        [-66.3364, 52.6889],
                        [-66.3377, 52.7014],
                        [-66.3238, 52.7126],
                        [-66.3213, 52.7188],
                        [-66.3085, 52.7249],
                        [-66.3086, 52.7286],
                        [-66.3268, 52.7366],
                        [-66.3321, 52.7415],
                        [-66.3276, 52.7532],
                        [-66.3312, 52.7612],
                        [-66.3516, 52.7841],
                        [-66.3446, 52.7954],
                        [-66.3545, 52.8026],
                        [-66.3818, 52.7945],
                        [-66.3858, 52.7996],
                        [-66.3778, 52.8141],
                        [-66.3887, 52.8268],
                        [-66.4142, 52.8487],
                        [-66.4166, 52.8583],
                        [-66.411, 52.8724],
                        [-66.3989, 52.8789],
                        [-66.3797, 52.8775],
                        [-66.3691, 52.8631],
                        [-66.3569, 52.8591],
                        [-66.34, 52.8638],
                        [-66.3209, 52.8567],
                        [-66.3053, 52.841],
                        [-66.2835, 52.8334],
                        [-66.2753, 52.8403],
                        [-66.274, 52.8461],
                        [-66.2884, 52.8538],
                        [-66.2949, 52.8601],
                        [-66.2705, 52.8658],
                        [-66.2601, 52.8659],
                        [-66.2612, 52.8743],
                        [-66.2765, 52.8838],
                        [-66.2731, 52.896],
                        [-66.2807, 52.9052],
                        [-66.2891, 52.9101],
                        [-66.2954, 52.9186],
                        [-66.3119, 52.9267],
                        [-66.3223, 52.9389],
                        [-66.3271, 52.9564],
                        [-66.327, 52.9726],
                        [-66.3433, 52.9877],
                        [-66.3501, 53.0001],
                        [-66.3552, 53.0001],
                        [-66.3611, 53.0144],
                        [-66.3735, 53.0206],
                        [-66.3938, 53.0212],
                        [-66.4064, 53.0282],
                        [-66.4321, 53.0482],
                        [-66.4429, 53.0474],
                        [-66.4595, 53.0552],
                        [-66.462, 53.0469],
                        [-66.4753, 53.0425],
                        [-66.4703, 53.0286],
                        [-66.4746, 53.0223],
                        [-66.4622, 53.0001],
                        [-66.4699, 52.991],
                        [-66.4848, 52.9979],
                        [-66.489, 52.9857],
                        [-66.4956, 52.9811],
                        [-66.4901, 52.9743],
                        [-66.4886, 52.9605],
                        [-66.4951, 52.9502],
                        [-66.5073, 52.9501],
                        [-66.5146, 52.9609],
                        [-66.5264, 52.9613],
                        [-66.5309, 52.9484],
                        [-66.5481, 52.9411],
                        [-66.5564, 52.9418],
                        [-66.5773, 52.9368],
                        [-66.5939, 52.9412],
                        [-66.6021, 52.9498],
                        [-66.6259, 52.9543],
                        [-66.6325, 52.9476],
                        [-66.6332, 52.9314],
                        [-66.6506, 52.9219],
                        [-66.6424, 52.9143],
                        [-66.6324, 52.9008],
                        [-66.6423, 52.8974],
                        [-66.6496, 52.8997],
                        [-66.6636, 52.8903],
                        [-66.664, 52.8855],
                        [-66.6495, 52.8776],
                        [-66.6454, 52.8726],
                        [-66.6559, 52.8664],
                        [-66.6454, 52.8528],
                        [-66.6482, 52.8478],
                        [-66.6651, 52.8309],
                        [-66.6652, 52.8147],
                        [-66.6619, 52.8081],
                        [-66.6464, 52.7889],
                        [-66.6511, 52.7806],
                        [-66.6605, 52.7822],
                        [-66.6671, 52.7883],
                        [-66.6805, 52.7911],
                        [-66.6867, 52.7835],
                        [-66.6866, 52.7637],
                        [-66.6932, 52.7622],
                        [-66.7197, 52.7636],
                        [-66.7466, 52.7733],
                        [-66.758, 52.7814],
                        [-66.7654, 52.7916],
                        [-66.7732, 52.7973],
                        [-66.7871, 52.7974],
                        [-66.78, 52.7873],
                        [-66.7753, 52.7753],
                        [-66.7541, 52.7515],
                        [-66.7558, 52.7205],
                        [-66.7508, 52.7141],
                        [-66.7479, 52.7031],
                        [-66.7639, 52.7002],
                        [-66.7724, 52.6962],
                        [-66.7579, 52.6733],
                        [-66.765, 52.6662],
                        [-66.7864, 52.673],
                        [-66.7924, 52.6842],
                        [-66.8014, 52.7087],
                        [-66.8046, 52.7125],
                        [-66.8171, 52.7113],
                        [-66.8263, 52.7231],
                        [-66.8365, 52.7458],
                        [-66.8602, 52.7684],
                        [-66.8664, 52.764],
                        [-66.8604, 52.7481],
                        [-66.854, 52.7212],
                        [-66.8671, 52.7187],
                        [-66.8915, 52.7326],
                        [-66.8979, 52.7275],
                        [-66.8768, 52.7135],
                        [-66.8579, 52.6921],
                        [-66.8494, 52.6779],
                        [-66.8637, 52.6736],
                        [-66.8727, 52.681],
                        [-66.8864, 52.6752],
                        [-66.8948, 52.6767],
                        [-66.9029, 52.6839],
                        [-66.902, 52.688],
                        [-66.9176, 52.7152],
                        [-66.9298, 52.7289],
                        [-66.9442, 52.7369],
                        [-66.9492, 52.7472],
                        [-66.9496, 52.7596],
                        [-66.979, 52.7531],
                        [-66.9898, 52.7622],
                        [-66.9996, 52.7593],
                        [-67.0161, 52.7441],
                        [-67.0171, 52.7522],
                        [-67.0248, 52.7557],
                        [-67.0343, 52.747],
                        [-67.0383, 52.7555],
                        [-67.0396, 52.7675],
                        [-67.0492, 52.774],
                        [-67.067, 52.7751],
                        [-67.0627, 52.7839],
                        [-67.0578, 52.8064],
                        [-67.0623, 52.8182],
                        [-67.0477, 52.8372],
                        [-67.0484, 52.8463],
                        [-67.0559, 52.8573],
                        [-67.0541, 52.8681],
                        [-67.0632, 52.8755],
                        [-67.0743, 52.8755],
                        [-67.0956, 52.8699],
                        [-67.1062, 52.8654],
                        [-67.1226, 52.8624],
                        [-67.1342, 52.851],
                        [-67.1495, 52.8544],
                        [-67.1556, 52.8498],
                        [-67.1549, 52.8228],
                        [-67.1611, 52.8175],
                        [-67.1754, 52.8214],
                        [-67.1855, 52.832],
                        [-67.204, 52.8356],
                        [-67.2148, 52.8436],
                        [-67.2275, 52.8418],
                        [-67.2409, 52.8539],
                        [-67.2399, 52.8589],
                        [-67.2599, 52.8732],
                        [-67.2685, 52.8777],
                        [-67.2998, 52.8845],
                        [-67.3168, 52.8754],
                        [-67.3361, 52.8896],
                        [-67.3419, 52.8962],
                        [-67.3329, 52.9072],
                        [-67.3387, 52.916],
                        [-67.3508, 52.9226],
                        [-67.3557, 52.9286],
                        [-67.3519, 52.9367],
                        [-67.3442, 52.9418],
                        [-67.3327, 52.9598],
                        [-67.3215, 52.9654],
                        [-67.3069, 52.9689],
                        [-67.2975, 52.9656],
                        [-67.2758, 52.9638],
                        [-67.2485, 52.976],
                        [-67.2553, 52.9793],
                        [-67.2833, 52.9842],
                        [-67.3083, 52.9779],
                        [-67.3454, 52.9812],
                        [-67.3538, 52.9868],
                        [-67.3689, 53.0023],
                        [-67.3554, 53.009],
                        [-67.3639, 53.0255],
                        [-67.3808, 53.0463],
                        [-67.387, 53.0702],
                        [-67.3905, 53.0768],
                        [-67.393, 53.1008],
                        [-67.3884, 53.1077],
                        [-67.3923, 53.1209],
                        [-67.3854, 53.132],
                        [-67.3714, 53.1353],
                        [-67.3619, 53.132],
                        [-67.365, 53.1219],
                        [-67.3464, 53.1321],
                        [-67.3321, 53.1241],
                        [-67.318, 53.13],
                        [-67.3044, 53.1382],
                        [-67.2931, 53.1383],
                        [-67.2872, 53.1439],
                        [-67.2968, 53.1511],
                        [-67.3027, 53.1589],
                        [-67.294, 53.1639],
                        [-67.2739, 53.1801],
                        [-67.263, 53.1813],
                        [-67.2544, 53.1782],
                        [-67.249, 53.1657],
                        [-67.2177, 53.1497],
                        [-67.1542, 53.1472],
                        [-67.1344, 53.1379],
                        [-67.1411, 53.1289],
                        [-67.1251, 53.125],
                        [-67.1153, 53.1119],
                        [-67.0887, 53.1259],
                        [-67.0753, 53.1393],
                        [-67.0612, 53.1419],
                        [-67.0489, 53.1395],
                        [-67.0525, 53.1327],
                        [-67.0468, 53.1185],
                        [-67.0531, 53.1101],
                        [-67.069, 53.1008],
                        [-67.0653, 53.089],
                        [-67.0685, 53.0789],
                        [-67.0568, 53.0789],
                        [-67.0416, 53.0761],
                        [-67.024, 53.0784],
                        [-67.0013, 53.0844],
                        [-66.9895, 53.0844],
                        [-66.9844, 53.0879],
                        [-66.9909, 53.1175],
                        [-66.9849, 53.123],
                        [-66.96, 53.1329],
                        [-66.9587, 53.1371],
                        [-66.9952, 53.1385],
                        [-67.0072, 53.1443],
                        [-67.0141, 53.1521],
                        [-67.0135, 53.16],
                        [-67.0061, 53.1685],
                        [-66.9826, 53.1778],
                        [-66.9596, 53.1817],
                        [-66.9634, 53.191],
                        [-66.9736, 53.2003],
                        [-66.9781, 53.2133],
                        [-66.9756, 53.2371],
                        [-66.9762, 53.2456],
                        [-66.9647, 53.2739],
                        [-66.9543, 53.2836],
                        [-66.9534, 53.2904],
                        [-66.9584, 53.3065],
                        [-66.9955, 53.3204],
                        [-67.0188, 53.3201],
                        [-67.0253, 53.3262],
                        [-67.0271, 53.3346],
                        [-67.0213, 53.3422],
                        [-66.997, 53.3526],
                        [-66.9971, 53.3571],
                        [-67.0093, 53.3573],
                        [-67.0137, 53.3619],
                        [-66.996, 53.3727],
                        [-66.9847, 53.3727],
                        [-66.9661, 53.3646],
                        [-66.9541, 53.3726],
                        [-66.9535, 53.3837],
                        [-66.9463, 53.3909],
                        [-66.9333, 53.3919],
                        [-66.9229, 53.3999],
                        [-66.9067, 53.3953],
                        [-66.8905, 53.4093],
                        [-66.8945, 53.4199],
                        [-66.9079, 53.4146],
                        [-66.9244, 53.4187],
                        [-66.935, 53.4415],
                        [-66.925, 53.4545],
                        [-66.9346, 53.4607],
                        [-66.9379, 53.4731],
                        [-66.9445, 53.4761],
                        [-66.9711, 53.4665],
                        [-66.9958, 53.4642],
                        [-66.999, 53.4737],
                        [-66.9963, 53.4846],
                        [-66.9838, 53.4925],
                        [-66.9856, 53.4961],
                        [-67, 53.496],
                        [-67.0067, 53.5],
                        [-67.0114, 53.5096],
                        [-67.0204, 53.5137],
                        [-67.0303, 53.5267],
                        [-67.0428, 53.5266],
                        [-67.0537, 53.5296],
                        [-67.062, 53.5371],
                        [-67.0721, 53.535],
                        [-67.0843, 53.529],
                        [-67.0999, 53.5267],
                        [-67.1188, 53.5307],
                        [-67.1299, 53.5302],
                        [-67.1577, 53.5376],
                        [-67.18, 53.553],
                        [-67.1891, 53.5508],
                        [-67.1891, 53.5328],
                        [-67.2062, 53.5337],
                        [-67.2188, 53.5303],
                        [-67.2409, 53.5437],
                        [-67.2574, 53.5574],
                        [-67.2724, 53.552],
                        [-67.2863, 53.5435],
                        [-67.2984, 53.5432],
                        [-67.3082, 53.5462],
                        [-67.3237, 53.559],
                        [-67.3135, 53.5842],
                        [-67.335, 53.5868],
                        [-67.3534, 53.5954],
                        [-67.3769, 53.5972],
                        [-67.3793, 53.6016],
                        [-67.3737, 53.6083],
                        [-67.3947, 53.6147],
                        [-67.3951, 53.6189],
                        [-67.3872, 53.6289],
                        [-67.3926, 53.6392],
                        [-67.3894, 53.6423],
                        [-67.404, 53.6589],
                        [-67.4131, 53.6669],
                        [-67.4142, 53.6749],
                        [-67.3963, 53.6773],
                        [-67.3968, 53.6829],
                        [-67.4138, 53.6905],
                        [-67.4216, 53.6965],
                        [-67.4275, 53.7133],
                        [-67.4194, 53.7188],
                        [-67.4178, 53.7251],
                        [-67.4406, 53.7242],
                        [-67.4328, 53.7127],
                        [-67.4337, 53.7065],
                        [-67.4463, 53.7063],
                        [-67.4651, 53.7089],
                        [-67.5063, 53.7191],
                        [-67.5057, 53.7247],
                        [-67.516, 53.7287],
                        [-67.5175, 53.7352],
                        [-67.5057, 53.7446],
                        [-67.5, 53.746],
                        [-67.5, 53.7573],
                        [-67.5167, 53.7555],
                        [-67.539, 53.7586],
                        [-67.5668, 53.7559],
                        [-67.5922, 53.7575],
                        [-67.6033, 53.7627],
                        [-67.5993, 53.7662],
                        [-67.5675, 53.7709],
                        [-67.5573, 53.7803],
                        [-67.5758, 53.7825],
                        [-67.6042, 53.7902],
                        [-67.6022, 53.7955],
                        [-67.5451, 53.7982],
                        [-67.536, 53.8012],
                        [-67.5221, 53.8162],
                        [-67.4996, 53.8269],
                        [-67.4978, 53.8367],
                        [-67.5094, 53.834],
                        [-67.5366, 53.8402],
                        [-67.5559, 53.8374],
                        [-67.5691, 53.8411],
                        [-67.5715, 53.8456],
                        [-67.5643, 53.8586],
                        [-67.5795, 53.8609],
                        [-67.5805, 53.8682],
                        [-67.5928, 53.8622],
                        [-67.6091, 53.8692],
                        [-67.6053, 53.8773],
                        [-67.6142, 53.8849],
                        [-67.6127, 53.8951],
                        [-67.5938, 53.8958],
                        [-67.6092, 53.902],
                        [-67.5982, 53.9157],
                        [-67.5963, 53.9217],
                        [-67.6111, 53.9255],
                        [-67.6324, 53.9238],
                        [-67.6371, 53.9304],
                        [-67.6533, 53.9281],
                        [-67.6693, 53.9303],
                        [-67.6796, 53.9407],
                        [-67.6769, 53.9455],
                        [-67.662, 53.9523],
                        [-67.6845, 53.9663],
                        [-67.6813, 53.9719],
                        [-67.6998, 53.9766],
                        [-67.7252, 53.9778],
                        [-67.7336, 53.9797],
                        [-67.7434, 53.9862],
                        [-67.7545, 54],
                        [-67.7623, 54],
                        [-67.7665, 53.9939],
                        [-67.7808, 53.9943],
                        [-67.7873, 54],
                        [-67.7831, 54.0087],
                        [-67.816, 54.0148],
                        [-67.8217, 54.0257],
                        [-67.8147, 54.0321],
                        [-67.791, 54.0413],
                        [-67.7941, 54.0545],
                        [-67.7858, 54.0586],
                        [-67.7788, 54.0714],
                        [-67.7884, 54.0855],
                        [-67.7618, 54.086],
                        [-67.7581, 54.0916],
                        [-67.7677, 54.0999],
                        [-67.7819, 54.0959],
                        [-67.7919, 54.0956],
                        [-67.8055, 54.1062],
                        [-67.7982, 54.1186],
                        [-67.8103, 54.1281],
                        [-67.7818, 54.1484],
                        [-67.765, 54.157],
                        [-67.7483, 54.1576],
                        [-67.7296, 54.1513],
                        [-67.706, 54.1611],
                        [-67.6963, 54.1624],
                        [-67.6753, 54.1588],
                        [-67.6406, 54.1631],
                        [-67.6535, 54.17],
                        [-67.6534, 54.1886],
                        [-67.6443, 54.1938],
                        [-67.6332, 54.1941],
                        [-67.6243, 54.1911],
                        [-67.6156, 54.1958],
                        [-67.6315, 54.1998],
                        [-67.6382, 54.2052],
                        [-67.6283, 54.2128],
                        [-67.6518, 54.2215],
                        [-67.664, 54.2331],
                        [-67.6773, 54.2417],
                        [-67.6808, 54.247],
                        [-67.6849, 54.2634],
                        [-67.6757, 54.2645],
                        [-67.6687, 54.2775],
                        [-67.6767, 54.2932],
                        [-67.68, 54.3119],
                        [-67.6983, 54.3241],
                        [-67.7289, 54.3343],
                        [-67.7337, 54.3447],
                        [-67.748, 54.3503],
                        [-67.732, 54.3642],
                        [-67.7315, 54.3785],
                        [-67.7247, 54.3869],
                        [-67.7443, 54.3975],
                        [-67.7542, 54.4088],
                        [-67.769, 54.4196],
                        [-67.7711, 54.4304],
                        [-67.767, 54.4368],
                        [-67.7501, 54.4394],
                        [-67.7343, 54.4443],
                        [-67.7275, 54.4574],
                        [-67.7055, 54.4563],
                        [-67.7067, 54.4446],
                        [-67.6858, 54.4461],
                        [-67.6678, 54.4538],
                        [-67.6651, 54.4587],
                        [-67.6736, 54.4623],
                        [-67.6624, 54.469],
                        [-67.6666, 54.4793],
                        [-67.6474, 54.5094],
                        [-67.6427, 54.508],
                        [-67.637, 54.4923],
                        [-67.6279, 54.4736],
                        [-67.6209, 54.4682],
                        [-67.6095, 54.4658],
                        [-67.5926, 54.469],
                        [-67.5817, 54.4829],
                        [-67.5863, 54.4897],
                        [-67.5817, 54.4941],
                        [-67.5684, 54.4933],
                        [-67.5509, 54.4859],
                        [-67.5319, 54.4902],
                        [-67.5183, 54.4828],
                        [-67.5004, 54.4815],
                        [-67.495, 54.4886],
                        [-67.5, 54.4946],
                        [-67.5, 54.5069],
                        [-67.4935, 54.5114],
                        [-67.4731, 54.5201],
                        [-67.4741, 54.5274],
                        [-67.4855, 54.5397],
                        [-67.5089, 54.5475],
                        [-67.5183, 54.5522],
                        [-67.5169, 54.5622],
                        [-67.5269, 54.571],
                        [-67.5243, 54.5755],
                        [-67.5009, 54.5781],
                        [-67.4917, 54.5849],
                        [-67.4676, 54.5838],
                        [-67.429, 54.5692],
                        [-67.4234, 54.5653],
                        [-67.4231, 54.5579],
                        [-67.4061, 54.5529],
                        [-67.3865, 54.539],
                        [-67.3829, 54.533],
                        [-67.3686, 54.5296],
                        [-67.3577, 54.52],
                        [-67.3492, 54.517],
                        [-67.3353, 54.5211],
                        [-67.3295, 54.5136],
                        [-67.3143, 54.5132],
                        [-67.2898, 54.5087],
                        [-67.2866, 54.4903],
                        [-67.2821, 54.4873],
                        [-67.2696, 54.4885],
                        [-67.2628, 54.4975],
                        [-67.2497, 54.4963],
                        [-67.2449, 54.5],
                        [-67.2483, 54.5102],
                        [-67.2446, 54.5148],
                        [-67.2293, 54.516],
                        [-67.2278, 54.5253],
                        [-67.2595, 54.5337],
                        [-67.2678, 54.5425],
                        [-67.2632, 54.5475],
                        [-67.2778, 54.5487],
                        [-67.2971, 54.5536],
                        [-67.302, 54.5593],
                        [-67.2744, 54.5614],
                        [-67.2685, 54.5652],
                        [-67.2799, 54.5853],
                        [-67.2803, 54.5918],
                        [-67.2682, 54.5909],
                        [-67.2417, 54.5852],
                        [-67.2312, 54.5876],
                        [-67.2349, 54.6123],
                        [-67.2202, 54.6181],
                        [-67.1938, 54.6154],
                        [-67.1758, 54.6095],
                        [-67.1576, 54.6172],
                        [-67.1535, 54.6223],
                        [-67.1386, 54.6245],
                        [-67.1426, 54.6361],
                        [-67.1235, 54.6451],
                        [-67.129, 54.6577],
                        [-67.1055, 54.6666],
                        [-67.0767, 54.6711],
                        [-67.0715, 54.6737],
                        [-67.0828, 54.6806],
                        [-67.0775, 54.6858],
                        [-67.0629, 54.6929],
                        [-67.0742, 54.6998],
                        [-67.0732, 54.7051],
                        [-67.086, 54.7129],
                        [-67.098, 54.7235],
                        [-67.1377, 54.75],
                        [-67.1573, 54.7563],
                        [-67.1581, 54.759],
                        [-67.1775, 54.764],
                        [-67.1759, 54.7716],
                        [-67.1802, 54.7759],
                        [-67.2134, 54.7927],
                        [-67.2221, 54.8009],
                        [-67.2259, 54.8083],
                        [-67.2176, 54.8176],
                        [-67.2214, 54.8249],
                        [-67.2436, 54.8266],
                        [-67.2447, 54.8298],
                        [-67.2592, 54.8338],
                        [-67.2681, 54.8433],
                        [-67.2671, 54.8495],
                        [-67.2716, 54.8557],
                        [-67.283, 54.8626],
                        [-67.2827, 54.8677],
                        [-67.3107, 54.8804],
                        [-67.3092, 54.8865],
                        [-67.3157, 54.8954],
                        [-67.3351, 54.9035],
                        [-67.3399, 54.9165],
                        [-67.3541, 54.9298],
                        [-67.3577, 54.9397],
                        [-67.3703, 54.9417],
                        [-67.3845, 54.9517],
                        [-67.3998, 54.9579],
                        [-67.3989, 54.9654],
                        [-67.4133, 54.972],
                        [-67.4144, 54.9761],
                        [-67.4019, 54.989],
                        [-67.4217, 54.999],
                        [-67.4276, 55.0059],
                        [-67.4459, 55.0085],
                        [-67.4443, 55.021],
                        [-67.4396, 55.0325],
                        [-67.4492, 55.0389],
                        [-67.4706, 55.049],
                        [-67.4637, 55.0629],
                        [-67.4432, 55.0649],
                        [-67.438, 55.0778],
                        [-67.4281, 55.0839],
                        [-67.4096, 55.0886],
                        [-67.3953, 55.0843],
                        [-67.3684, 55.0913],
                        [-67.3459, 55.0867],
                        [-67.3228, 55.0891],
                        [-67.2862, 55.0789],
                        [-67.2728, 55.0764],
                        [-67.2437, 55.0596],
                        [-67.234, 55.0512],
                        [-67.2363, 55.044],
                        [-67.2633, 55.0455],
                        [-67.2627, 55.0344],
                        [-67.2728, 55.0242],
                        [-67.2575, 55.0213],
                        [-67.2327, 55.0087],
                        [-67.2193, 55.0001],
                        [-67.2092, 54.9842],
                        [-67.1878, 54.9804],
                        [-67.1735, 54.9706],
                        [-67.1572, 54.9565],
                        [-67.1393, 54.9509],
                        [-67.1184, 54.9315],
                        [-67.0764, 54.9055],
                        [-67.0565, 54.8949],
                        [-67.0414, 54.8817],
                        [-67.0399, 54.8681],
                        [-67.033, 54.8619],
                        [-67.013, 54.854],
                        [-66.997, 54.8427],
                        [-66.977, 54.8331],
                        [-66.9478, 54.8223],
                        [-66.9394, 54.8161],
                        [-66.927, 54.8022],
                        [-66.9185, 54.8024],
                        [-66.9113, 54.8094],
                        [-66.8874, 54.8108],
                        [-66.8757, 54.816],
                        [-66.8429, 54.7995],
                        [-66.8344, 54.7918],
                        [-66.8127, 54.7794],
                        [-66.7999, 54.7764],
                        [-66.7876, 54.7698],
                        [-66.7732, 54.766],
                        [-66.7688, 54.7562],
                        [-66.7585, 54.7481],
                        [-66.7442, 54.75],
                        [-66.7142, 54.732],
                        [-66.6992, 54.7342],
                        [-66.6963, 54.726],
                        [-66.6753, 54.7092],
                        [-66.6673, 54.711],
                        [-66.6646, 54.727],
                        [-66.6746, 54.7349],
                        [-66.6795, 54.7449],
                        [-66.6997, 54.755],
                        [-66.7158, 54.7574],
                        [-66.7305, 54.7667],
                        [-66.7321, 54.7789],
                        [-66.7237, 54.7886],
                        [-66.7405, 54.8055],
                        [-66.7329, 54.8115],
                        [-66.7071, 54.7977],
                        [-66.6683, 54.7742],
                        [-66.6594, 54.7702],
                        [-66.6446, 54.7788],
                        [-66.6473, 54.785],
                        [-66.6382, 54.795],
                        [-66.624, 54.79],
                        [-66.6257, 54.799],
                        [-66.6096, 54.8034],
                        [-66.6027, 54.8135],
                        [-66.6183, 54.8225],
                        [-66.632, 54.8357],
                        [-66.6333, 54.8468],
                        [-66.6517, 54.85],
                        [-66.6511, 54.8577],
                        [-66.6562, 54.8709],
                        [-66.6636, 54.882],
                        [-66.6897, 54.898],
                        [-66.6881, 54.9135],
                        [-66.6814, 54.9237],
                        [-66.7112, 54.9474],
                        [-66.7132, 54.9555],
                        [-66.7422, 54.9695],
                        [-66.7349, 54.9781],
                        [-66.7402, 54.9851],
                        [-66.7602, 54.9957],
                        [-66.7494, 55],
                        [-66.7504, 55.0068],
                        [-66.74, 55.0072],
                        [-66.7369, 55],
                        [-66.6894, 55.0001],
                        [-66.6854, 54.9928],
                        [-66.6664, 54.9854],
                        [-66.6501, 54.9746],
                        [-66.6256, 54.9839],
                        [-66.6279, 54.9884],
                        [-66.6564, 55.0079],
                        [-66.6597, 55.0159],
                        [-66.6717, 55.0255],
                        [-66.6821, 55.0297],
                        [-66.6979, 55.0279],
                        [-66.7164, 55.0341],
                        [-66.7244, 55.0414],
                        [-66.735, 55.0441],
                        [-66.7329, 55.0586],
                        [-66.7459, 55.0661],
                        [-66.7466, 55.0741],
                        [-66.7556, 55.0868],
                        [-66.7468, 55.0921],
                        [-66.7314, 55.0816],
                        [-66.6993, 55.0632],
                        [-66.6949, 55.0695],
                        [-66.7075, 55.0781],
                        [-66.7328, 55.0927],
                        [-66.7413, 55.1033],
                        [-66.7549, 55.1136],
                        [-66.7717, 55.1142],
                        [-66.7797, 55.1108],
                        [-66.7887, 55.1184],
                        [-66.786, 55.1224],
                        [-66.7676, 55.1351],
                        [-66.7797, 55.1389],
                        [-66.7677, 55.1497],
                        [-66.7486, 55.1485],
                        [-66.7467, 55.1585],
                        [-66.757, 55.1719],
                        [-66.7606, 55.1878],
                        [-66.742, 55.1882],
                        [-66.7394, 55.1941],
                        [-66.7553, 55.2057],
                        [-66.757, 55.2134],
                        [-66.7489, 55.2157],
                        [-66.7197, 55.2095],
                        [-66.7106, 55.2052],
                        [-66.6836, 55.1851],
                        [-66.6718, 55.1815],
                        [-66.6894, 55.2097],
                        [-66.7072, 55.2271],
                        [-66.7265, 55.2355],
                        [-66.7402, 55.248],
                        [-66.7803, 55.269],
                        [-66.7864, 55.2731],
                        [-66.8237, 55.307],
                        [-66.823, 55.3137],
                        [-66.8277, 55.3249],
                        [-66.8047, 55.3213],
                        [-66.7999, 55.3233],
                        [-66.8025, 55.3317],
                        [-66.815, 55.3409],
                        [-66.8187, 55.3553],
                        [-66.8078, 55.3567],
                        [-66.7893, 55.3525],
                        [-66.7785, 55.3524],
                        [-66.7626, 55.3401],
                        [-66.74, 55.3279],
                        [-66.7276, 55.3184],
                        [-66.7085, 55.3166],
                        [-66.7003, 55.312],
                        [-66.6969, 55.3053],
                        [-66.7006, 55.29],
                        [-66.6896, 55.2808],
                        [-66.6698, 55.2694],
                        [-66.6586, 55.2551],
                        [-66.6538, 55.2562],
                        [-66.6444, 55.2671],
                        [-66.6266, 55.266],
                        [-66.618, 55.2782],
                        [-66.6075, 55.2748],
                        [-66.6028, 55.269],
                        [-66.5811, 55.2521],
                        [-66.5657, 55.2384],
                        [-66.5558, 55.2351],
                        [-66.5456, 55.2354],
                        [-66.5319, 55.2196],
                        [-66.5164, 55.1953],
                        [-66.5074, 55.2047],
                        [-66.4953, 55.2051],
                        [-66.4883, 55.1973],
                        [-66.4805, 55.1783],
                        [-66.4664, 55.1603],
                        [-66.4447, 55.1409],
                        [-66.4341, 55.1351],
                        [-66.4197, 55.1312],
                        [-66.4021, 55.1131],
                        [-66.3846, 55.1027],
                        [-66.3723, 55.0932],
                        [-66.355, 55.0832],
                        [-66.3331, 55.067],
                        [-66.3217, 55.0569],
                        [-66.3102, 55.0408],
                        [-66.3036, 55.0355],
                        [-66.2941, 55.0339],
                        [-66.2796, 55.0251],
                        [-66.268, 55.0148],
                        [-66.2603, 55.0002],
                        [-66.2505, 54.9954],
                        [-66.2478, 54.9783],
                        [-66.2395, 54.9779],
                        [-66.2355, 54.9918],
                        [-66.2173, 54.9845],
                        [-66.2101, 54.9791],
                        [-66.1886, 54.9808],
                        [-66.168, 54.973],
                        [-66.1607, 54.979],
                        [-66.1616, 54.9877],
                        [-66.1436, 54.9823],
                        [-66.137, 54.9773],
                        [-66.1167, 54.9679],
                        [-66.0992, 54.9543],
                        [-66.0877, 54.9497],
                        [-66.0745, 54.9395],
                        [-66.0645, 54.9291],
                        [-66.0484, 54.9204],
                        [-66.0364, 54.9233],
                        [-66.049, 54.9374],
                        [-66.0424, 54.9406],
                        [-66.0234, 54.942],
                        [-65.9966, 54.931],
                        [-65.9766, 54.9314],
                        [-65.9651, 54.9298],
                        [-65.9566, 54.9368],
                        [-65.946, 54.9343],
                        [-65.9305, 54.9409],
                        [-65.9247, 54.9401],
                        [-65.888, 54.9253],
                        [-65.8712, 54.9235],
                        [-65.8498, 54.9272],
                        [-65.8393, 54.9143],
                        [-65.8386, 54.908],
                        [-65.8572, 54.893],
                        [-65.8366, 54.882],
                        [-65.8364, 54.8747],
                        [-65.8447, 54.8615],
                        [-65.8531, 54.8559],
                        [-65.8383, 54.8365],
                        [-65.8191, 54.824],
                        [-65.787, 54.8091],
                        [-65.7774, 54.8028],
                        [-65.7657, 54.7892],
                        [-65.757, 54.7868],
                        [-65.743, 54.7695],
                        [-65.7331, 54.7637],
                        [-65.7325, 54.7591],
                        [-65.7136, 54.741],
                        [-65.7205, 54.7316],
                        [-65.7062, 54.7179],
                        [-65.6901, 54.7115],
                        [-65.6697, 54.7188],
                        [-65.6485, 54.7352],
                        [-65.651, 54.7479],
                        [-65.647, 54.7532],
                        [-65.6321, 54.7545],
                        [-65.6213, 54.7385],
                        [-65.6016, 54.7378],
                        [-65.5926, 54.743],
                        [-65.5621, 54.7397],
                        [-65.5624, 54.7331],
                        [-65.5488, 54.7292],
                        [-65.5357, 54.7301],
                        [-65.5136, 54.736],
                        [-65.4978, 54.7348],
                        [-65.4874, 54.7285],
                        [-65.4634, 54.7272],
                        [-65.4555, 54.7286],
                        [-65.4535, 54.7374],
                        [-65.4482, 54.744],
                        [-65.4291, 54.7481],
                        [-65.4339, 54.7707],
                        [-65.4346, 54.7842],
                        [-65.4486, 54.7945],
                        [-65.4669, 54.8019],
                        [-65.4867, 54.8201],
                        [-65.4873, 54.8344],
                        [-65.4804, 54.8382],
                        [-65.4638, 54.8412],
                        [-65.4563, 54.8492],
                        [-65.4114, 54.8418],
                        [-65.4137, 54.8297],
                        [-65.4117, 54.8231],
                        [-65.4006, 54.8191],
                        [-65.3846, 54.8299],
                        [-65.3684, 54.8255],
                        [-65.3558, 54.8188],
                        [-65.3454, 54.8254],
                        [-65.3384, 54.8417],
                        [-65.3412, 54.8483],
                        [-65.3358, 54.852],
                        [-65.3257, 54.8487],
                        [-65.3049, 54.8197],
                        [-65.293, 54.8189],
                        [-65.288, 54.8254],
                        [-65.2911, 54.8401],
                        [-65.2672, 54.8484],
                        [-65.2425, 54.8499],
                        [-65.2096, 54.848],
                        [-65.2006, 54.8578],
                        [-65.2017, 54.8805],
                        [-65.1788, 54.9053],
                        [-65.1736, 54.9179],
                        [-65.1803, 54.9308],
                        [-65.1729, 54.9336],
                        [-65.1345, 54.9282],
                        [-65.1229, 54.9414],
                        [-65.1264, 54.9615],
                        [-65.105, 54.9644],
                        [-65.0858, 54.9696],
                        [-65.0764, 54.9681],
                        [-65.0706, 54.9536],
                        [-65.0527, 54.956],
                        [-65.0434, 54.9523],
                        [-65.0402, 54.9437],
                        [-65.0177, 54.9326],
                        [-65.0096, 54.931],
                        [-64.9719, 54.9374],
                        [-64.9539, 54.9322],
                        [-64.9269, 54.9213],
                        [-64.915, 54.92],
                        [-64.9123, 54.9119],
                        [-64.9065, 54.9106],
                        [-64.9026, 54.9016],
                        [-64.8897, 54.8942],
                        [-64.8717, 54.8926],
                        [-64.8561, 54.8829],
                        [-64.8469, 54.8738],
                        [-64.8617, 54.8549],
                        [-64.8584, 54.8488],
                        [-64.8666, 54.8448],
                        [-64.8568, 54.8401],
                        [-64.8393, 54.846],
                        [-64.8381, 54.8403],
                        [-64.8272, 54.837],
                        [-64.8248, 54.8462],
                        [-64.8159, 54.849],
                        [-64.8007, 54.8326],
                        [-64.7905, 54.8341],
                        [-64.7839, 54.8428],
                        [-64.7611, 54.8295],
                        [-64.7479, 54.814],
                        [-64.7595, 54.7997],
                        [-64.7698, 54.7972],
                        [-64.7684, 54.7905],
                        [-64.7762, 54.7881],
                        [-64.7653, 54.7741],
                        [-64.7523, 54.7673],
                        [-64.772, 54.7625],
                        [-64.7821, 54.7633],
                        [-64.7852, 54.7539],
                        [-64.8, 54.7563],
                        [-64.7781, 54.7335],
                        [-64.7637, 54.7292],
                        [-64.7349, 54.736],
                        [-64.731, 54.7256],
                        [-64.7111, 54.723],
                        [-64.6878, 54.726],
                        [-64.68, 54.7357],
                        [-64.6503, 54.7306],
                        [-64.6229, 54.7219],
                        [-64.6003, 54.7289],
                        [-64.5863, 54.7256],
                        [-64.5569, 54.7137],
                        [-64.547, 54.7231],
                        [-64.5307, 54.7335],
                        [-64.529, 54.7375],
                        [-64.5548, 54.7449],
                        [-64.5342, 54.7618],
                        [-64.5142, 54.7513],
                        [-64.4748, 54.7522],
                        [-64.4855, 54.771],
                        [-64.4715, 54.7703],
                        [-64.4805, 54.7779],
                        [-64.4798, 54.7842],
                        [-64.4925, 54.7873],
                        [-64.4853, 54.7937],
                        [-64.4218, 54.7879],
                        [-64.3975, 54.7905],
                        [-64.3841, 54.7802],
                        [-64.3626, 54.7778],
                        [-64.3439, 54.7726],
                        [-64.3066, 54.7593],
                        [-64.2945, 54.7533],
                        [-64.3007, 54.7415],
                        [-64.2882, 54.731],
                        [-64.2756, 54.728],
                        [-64.2389, 54.7291],
                        [-64.2378, 54.7201],
                        [-64.2261, 54.7194],
                        [-64.2038, 54.7281],
                        [-64.1902, 54.728],
                        [-64.1787, 54.717],
                        [-64.1878, 54.7108],
                        [-64.176, 54.7012],
                        [-64.1807, 54.6936],
                        [-64.1713, 54.6907],
                        [-64.1586, 54.6808],
                        [-64.1453, 54.6761],
                        [-64.1418, 54.667],
                        [-64.129, 54.6656],
                        [-64.1062, 54.6604],
                        [-64.1028, 54.6556],
                        [-64.1052, 54.6467],
                        [-64.113, 54.6335],
                        [-64.1073, 54.6132],
                        [-64.0984, 54.6125],
                        [-64.0861, 54.623],
                        [-64.0719, 54.617],
                        [-64.0543, 54.6009],
                        [-64.0476, 54.6009],
                        [-64.0351, 54.607],
                        [-64.029, 54.6049],
                        [-64.004, 54.6021],
                        [-63.9849, 54.6086],
                        [-63.9744, 54.6201],
                        [-63.9665, 54.6193],
                        [-63.9343, 54.6066],
                        [-63.9114, 54.6042],
                        [-63.8998, 54.6056],
                        [-63.8928, 54.6103],
                        [-63.8775, 54.613],
                        [-63.8774, 54.619],
                        [-63.8307, 54.6329],
                        [-63.8344, 54.6411],
                        [-63.8196, 54.6407],
                        [-63.8134, 54.6508],
                        [-63.8017, 54.649],
                        [-63.7805, 54.6408],
                        [-63.7645, 54.6374],
                        [-63.7428, 54.6264],
                        [-63.7326, 54.6231],
                        [-63.7277, 54.6271],
                        [-63.7126, 54.625],
                        [-63.7253, 54.6518],
                        [-63.7238, 54.6598],
                        [-63.744, 54.6699],
                        [-63.7631, 54.6737],
                        [-63.7681, 54.6831],
                        [-63.7597, 54.6856],
                        [-63.7689, 54.6998],
                        [-63.8039, 54.7167],
                        [-63.803, 54.7256],
                        [-63.8328, 54.7364],
                        [-63.8607, 54.7527],
                        [-63.8797, 54.755],
                        [-63.8958, 54.7601],
                        [-63.8897, 54.7684],
                        [-63.924, 54.7784],
                        [-63.9101, 54.7885],
                        [-63.8908, 54.7888],
                        [-63.9037, 54.8011],
                        [-63.8718, 54.8005],
                        [-63.869, 54.807],
                        [-63.848, 54.8072],
                        [-63.8482, 54.8124],
                        [-63.8296, 54.8126],
                        [-63.8172, 54.8169],
                        [-63.8181, 54.8289],
                        [-63.8279, 54.8473],
                        [-63.849, 54.8626],
                        [-63.8422, 54.871],
                        [-63.8527, 54.8761],
                        [-63.8523, 54.8851],
                        [-63.838, 54.8831],
                        [-63.8462, 54.8948],
                        [-63.829, 54.9011],
                        [-63.8377, 54.9145],
                        [-63.8127, 54.9194],
                        [-63.7996, 54.9261],
                        [-63.8241, 54.9343],
                        [-63.8298, 54.9421],
                        [-63.8205, 54.948],
                        [-63.7917, 54.9559],
                        [-63.7843, 54.9478],
                        [-63.7519, 54.9423],
                        [-63.716, 54.9255],
                        [-63.6964, 54.9253],
                        [-63.6938, 54.9099],
                        [-63.6849, 54.9046],
                        [-63.6613, 54.9],
                        [-63.6635, 54.9105],
                        [-63.6458, 54.9056],
                        [-63.6307, 54.9092],
                        [-63.6196, 54.8995],
                        [-63.5914, 54.8966],
                        [-63.6044, 54.9079],
                        [-63.6123, 54.9199],
                        [-63.6045, 54.9263],
                        [-63.5836, 54.9238],
                        [-63.5779, 54.93],
                        [-63.602, 54.9393],
                        [-63.596, 54.9458],
                        [-63.5975, 54.9594],
                        [-63.6125, 54.969],
                        [-63.611, 54.977],
                        [-63.5869, 54.9738],
                        [-63.5732, 54.9788],
                        [-63.5763, 54.9881],
                        [-63.5575, 54.9899],
                        [-63.5576, 54.9999],
                        [-63.6061, 55.0084],
                        [-63.596, 55.0214],
                        [-63.5757, 55.0258],
                        [-63.5564, 55.0344],
                        [-63.5755, 55.0422],
                        [-63.5734, 55.054],
                        [-63.5876, 55.0608],
                        [-63.6065, 55.0612],
                        [-63.6161, 55.0689],
                        [-63.6029, 55.0764],
                        [-63.6114, 55.0908],
                        [-63.6052, 55.0983],
                        [-63.5916, 55.0953],
                        [-63.5854, 55.1036],
                        [-63.5861, 55.1159],
                        [-63.6023, 55.1287],
                        [-63.5737, 55.1331],
                        [-63.5651, 55.1403],
                        [-63.5734, 55.1558],
                        [-63.5415, 55.1601],
                        [-63.5239, 55.1681],
                        [-63.5314, 55.1746],
                        [-63.556, 55.1729],
                        [-63.5629, 55.1876],
                        [-63.5131, 55.1964],
                        [-63.503, 55.2025],
                        [-63.4899, 55.2004],
                        [-63.4526, 55.2019],
                        [-63.4459, 55.2108],
                        [-63.4319, 55.2113],
                        [-63.4258, 55.2262],
                        [-63.4125, 55.2336],
                        [-63.4227, 55.243],
                        [-63.4028, 55.249],
                        [-63.4026, 55.2578],
                        [-63.4324, 55.2553],
                        [-63.4459, 55.2514],
                        [-63.4626, 55.2553],
                        [-63.4748, 55.2454],
                        [-63.4904, 55.2445],
                        [-63.5084, 55.2321],
                        [-63.5474, 55.2255],
                        [-63.5633, 55.2434],
                        [-63.5781, 55.2364],
                        [-63.5977, 55.2384],
                        [-63.6091, 55.2301],
                        [-63.6241, 55.2361],
                        [-63.6225, 55.2426],
                        [-63.6083, 55.2457],
                        [-63.6012, 55.2512],
                        [-63.6305, 55.2562],
                        [-63.6386, 55.251],
                        [-63.6527, 55.2519],
                        [-63.6795, 55.2669],
                        [-63.6573, 55.2781],
                        [-63.6401, 55.2786],
                        [-63.6441, 55.2878],
                        [-63.6158, 55.2963],
                        [-63.5879, 55.3026],
                        [-63.5695, 55.2977],
                        [-63.5517, 55.3013],
                        [-63.5421, 55.3097],
                        [-63.5334, 55.3062],
                        [-63.5226, 55.3099],
                        [-63.5327, 55.3255],
                        [-63.5539, 55.321],
                        [-63.5908, 55.3207],
                        [-63.5983, 55.3257],
                        [-63.5907, 55.3332],
                        [-63.5804, 55.3348],
                        [-63.5744, 55.3396],
                        [-63.5608, 55.3389],
                        [-63.5519, 55.3449],
                        [-63.5299, 55.3419],
                        [-63.5077, 55.3476],
                        [-63.482, 55.3498],
                        [-63.4707, 55.3456],
                        [-63.4422, 55.3441],
                        [-63.3998, 55.3545],
                        [-63.3578, 55.3622],
                        [-63.3507, 55.3569],
                        [-63.3414, 55.3625],
                        [-63.3248, 55.366],
                        [-63.3337, 55.3839],
                        [-63.3198, 55.4118],
                        [-63.337, 55.4099],
                        [-63.3518, 55.4184],
                        [-63.3733, 55.4097],
                        [-63.393, 55.4048],
                        [-63.4036, 55.4077],
                        [-63.4349, 55.4004],
                        [-63.4325, 55.4086],
                        [-63.4491, 55.412],
                        [-63.4501, 55.4207],
                        [-63.471, 55.4198],
                        [-63.4786, 55.4292],
                        [-63.5107, 55.4203],
                        [-63.5344, 55.4275],
                        [-63.549, 55.4208],
                        [-63.5663, 55.4271],
                        [-63.5784, 55.4205],
                        [-63.604, 55.4285],
                        [-63.6309, 55.4303],
                        [-63.6445, 55.4233],
                        [-63.6654, 55.4231],
                        [-63.7367, 55.4382],
                        [-63.7384, 55.4479],
                        [-63.7494, 55.4549],
                        [-63.7674, 55.4549],
                        [-63.7806, 55.4655],
                        [-63.7927, 55.4672],
                        [-63.7801, 55.4738],
                        [-63.7755, 55.4817],
                        [-63.7561, 55.4817],
                        [-63.7408, 55.4915],
                        [-63.726, 55.493],
                        [-63.7164, 55.5044],
                        [-63.6915, 55.5087],
                        [-63.6865, 55.5186],
                        [-63.6891, 55.5255],
                        [-63.684, 55.5378],
                        [-63.6707, 55.5375],
                        [-63.6655, 55.546],
                        [-63.6649, 55.5675],
                        [-63.6815, 55.5757],
                        [-63.6749, 55.5897],
                        [-63.6626, 55.5989],
                        [-63.6732, 55.6072],
                        [-63.6827, 55.6091],
                        [-63.6877, 55.6255],
                        [-63.6802, 55.6352],
                        [-63.6617, 55.6372],
                        [-63.6487, 55.6341],
                        [-63.6437, 55.6416],
                        [-63.6685, 55.6523],
                        [-63.716, 55.6577],
                        [-63.7383, 55.6504],
                        [-63.7646, 55.6516],
                        [-63.7652, 55.6587],
                        [-63.752, 55.6589],
                        [-63.7512, 55.6715],
                        [-63.7357, 55.6729],
                        [-63.7231, 55.6794],
                        [-63.7049, 55.6786],
                        [-63.71, 55.6888],
                        [-63.7279, 55.6954],
                        [-63.7196, 55.7053],
                        [-63.7251, 55.7266],
                        [-63.7025, 55.7272],
                        [-63.6982, 55.7316],
                        [-63.714, 55.7391],
                        [-63.701, 55.743],
                        [-63.7054, 55.7555],
                        [-63.6821, 55.753],
                        [-63.6742, 55.7593],
                        [-63.6795, 55.774],
                        [-63.6665, 55.7878],
                        [-63.6754, 55.792],
                        [-63.7055, 55.7872],
                        [-63.7171, 55.7804],
                        [-63.7407, 55.7826],
                        [-63.7501, 55.7877],
                        [-63.7684, 55.79],
                        [-63.7613, 55.7969],
                        [-63.777, 55.8015],
                        [-63.7702, 55.8081],
                        [-63.7804, 55.813],
                        [-63.7728, 55.822],
                        [-63.7554, 55.8211],
                        [-63.75, 55.8251],
                        [-63.7529, 55.8333],
                        [-63.7666, 55.8395],
                        [-63.7529, 55.8465],
                        [-63.7715, 55.8485],
                        [-63.7736, 55.8561],
                        [-63.7955, 55.8611],
                        [-63.813, 55.8636],
                        [-63.8451, 55.8791],
                        [-63.8437, 55.8877],
                        [-63.8301, 55.8962],
                        [-63.8251, 55.9044],
                        [-63.8544, 55.91],
                        [-63.8494, 55.9168],
                        [-63.8097, 55.9228],
                        [-63.7926, 55.9315],
                        [-63.7716, 55.9292],
                        [-63.7508, 55.9336],
                        [-63.7339, 55.928],
                        [-63.724, 55.9305],
                        [-63.7174, 55.9376],
                        [-63.7066, 55.9402],
                        [-63.7036, 55.9461],
                        [-63.7132, 55.9479],
                        [-63.7124, 55.9535],
                        [-63.6881, 55.9482],
                        [-63.6814, 55.9538],
                        [-63.6865, 55.9617],
                        [-63.6755, 55.9616],
                        [-63.6681, 55.9695],
                        [-63.6547, 55.9722],
                        [-63.6356, 55.9653],
                        [-63.6245, 55.9675],
                        [-63.6271, 55.9737],
                        [-63.5983, 55.9822],
                        [-63.6058, 55.9878],
                        [-63.5994, 55.9917],
                        [-63.5845, 55.9899],
                        [-63.574, 56.0007],
                        [-63.563, 56.0059],
                        [-63.5266, 56.0054],
                        [-63.505, 56.0014],
                        [-63.4874, 55.9875],
                        [-63.472, 55.9878],
                        [-63.4702, 55.9983],
                        [-63.4912, 56.0099],
                        [-63.4864, 56.0156],
                        [-63.4672, 56.0119],
                        [-63.4598, 56.0213],
                        [-63.4435, 56.0295],
                        [-63.4958, 56.04],
                        [-63.5105, 56.0359],
                        [-63.521, 56.039],
                        [-63.5486, 56.0313],
                        [-63.5776, 56.0251],
                        [-63.5852, 56.0293],
                        [-63.601, 56.0261],
                        [-63.6033, 56.0312],
                        [-63.6367, 56.0235],
                        [-63.6904, 56.0439],
                        [-63.683, 56.0596],
                        [-63.6965, 56.0646],
                        [-63.7144, 56.066],
                        [-63.7148, 56.0717],
                        [-63.7313, 56.0702],
                        [-63.7501, 56.0619],
                        [-63.7742, 56.0536],
                        [-63.7912, 56.0529],
                        [-63.8233, 56.0452],
                        [-63.8379, 56.045],
                        [-63.8214, 56.0638],
                        [-63.8228, 56.0738],
                        [-63.8111, 56.0846],
                        [-63.8187, 56.0889],
                        [-63.8354, 56.0877],
                        [-63.8396, 56.1006],
                        [-63.8639, 56.0921],
                        [-63.8826, 56.0871],
                        [-63.8876, 56.0929],
                        [-63.863, 56.1088],
                        [-63.8383, 56.1225],
                        [-63.8562, 56.1371],
                        [-63.8801, 56.1361],
                        [-63.9057, 56.1237],
                        [-63.9253, 56.109],
                        [-63.9332, 56.0972],
                        [-63.9481, 56.0911],
                        [-63.9615, 56.0924],
                        [-63.969, 56.0867],
                        [-63.9932, 56.0758],
                        [-64.0181, 56.0673],
                        [-64.0347, 56.0701],
                        [-64.0358, 56.0822],
                        [-64.0143, 56.1008],
                        [-64.0021, 56.1027],
                        [-64.0053, 56.1193],
                        [-64.0142, 56.1275],
                        [-64.0086, 56.1382],
                        [-64.0239, 56.1426],
                        [-64.036, 56.1549],
                        [-64.0195, 56.1596],
                        [-64.0153, 56.1662],
                        [-64.0047, 56.1733],
                        [-63.9789, 56.1798],
                        [-63.95, 56.1851],
                        [-63.9, 56.1992],
                        [-63.8629, 56.2126],
                        [-63.8744, 56.2248],
                        [-63.8954, 56.2226],
                        [-63.9115, 56.2308],
                        [-63.9142, 56.2448],
                        [-63.9129, 56.2635],
                        [-63.9349, 56.2711],
                        [-63.9531, 56.2652],
                        [-63.9671, 56.2564],
                        [-64.0222, 56.2678],
                        [-64.0756, 56.256],
                        [-64.0874, 56.2569],
                        [-64.0905, 56.2799],
                        [-64.1113, 56.289],
                        [-64.1173, 56.2967],
                        [-64.1304, 56.3023],
                        [-64.1486, 56.3034],
                        [-64.1434, 56.312],
                        [-64.1094, 56.3226],
                        [-64.0982, 56.3336],
                        [-64.1075, 56.343],
                        [-64.0994, 56.3519],
                        [-64.1159, 56.3661],
                        [-64.1278, 56.3699],
                        [-64.1104, 56.386],
                        [-64.1154, 56.4021],
                        [-64.1328, 56.4018],
                        [-64.1437, 56.4054],
                        [-64.1581, 56.4198],
                        [-64.178, 56.4252],
                        [-64.1643, 56.4313],
                        [-64.1527, 56.4411],
                        [-64.1373, 56.4335],
                        [-64.0955, 56.4241],
                        [-64.0644, 56.4241],
                        [-64.0448, 56.4198],
                        [-64.0206, 56.4179],
                        [-64.0111, 56.4111],
                        [-63.993, 56.4082],
                        [-63.9658, 56.4107],
                        [-63.952, 56.4214],
                        [-63.9018, 56.4407],
                        [-63.8865, 56.433],
                        [-63.8742, 56.442],
                        [-63.8729, 56.449],
                        [-63.8854, 56.4592],
                        [-63.8837, 56.4691],
                        [-63.8977, 56.469],
                        [-63.9128, 56.463],
                        [-63.9306, 56.4662],
                        [-63.9312, 56.475],
                        [-63.9443, 56.4931],
                        [-63.9391, 56.5024],
                        [-63.9414, 56.5114],
                        [-63.9311, 56.5338],
                        [-63.9559, 56.5468],
                        [-63.9642, 56.5574],
                        [-63.9826, 56.5647],
                        [-64.0016, 56.5792],
                        [-64.004, 56.5912],
                        [-64.0505, 56.6063],
                        [-64.0533, 56.6155],
                        [-64.0337, 56.6332],
                        [-64.0399, 56.6407],
                        [-64.0249, 56.653],
                        [-64.051, 56.6575],
                        [-64.0908, 56.6538],
                        [-64.0945, 56.6629],
                        [-64.1282, 56.6829],
                        [-64.134, 56.695],
                        [-64.1453, 56.6998],
                        [-64.1181, 56.724],
                        [-64.1124, 56.7339],
                        [-64.0994, 56.7436],
                        [-64.0763, 56.7465],
                        [-64.074, 56.7555],
                        [-64.0854, 56.761],
                        [-64.0789, 56.767],
                        [-64.0128, 56.7787],
                        [-64.002, 56.7976],
                        [-64.0089, 56.8067],
                        [-64.0099, 56.8369],
                        [-64.013, 56.8498],
                        [-64.0035, 56.8617],
                        [-63.9839, 56.8611],
                        [-63.948, 56.8677],
                        [-63.9188, 56.8712],
                        [-63.8995, 56.8773],
                        [-63.8571, 56.8726],
                        [-63.8674, 56.8834],
                        [-63.8639, 56.8968],
                        [-63.8786, 56.9039],
                        [-63.8999, 56.9057],
                        [-63.908, 56.8979],
                        [-63.9181, 56.9042],
                        [-63.9173, 56.9168],
                        [-63.9081, 56.9275],
                        [-63.9144, 56.9398],
                        [-63.9118, 56.9553],
                        [-63.9141, 56.9776],
                        [-63.9079, 56.9841],
                        [-63.887, 56.981],
                        [-63.8578, 56.9992],
                        [-63.8919, 57.0128],
                        [-63.8897, 57.0459],
                        [-63.8986, 57.0551],
                        [-63.8915, 57.0625],
                        [-63.8901, 57.0861],
                        [-63.8817, 57.088],
                        [-63.8398, 57.0802],
                        [-63.8201, 57.0755],
                        [-63.8118, 57.0789],
                        [-63.8074, 57.091],
                        [-63.7987, 57.1064],
                        [-63.7858, 57.1175],
                        [-63.7649, 57.1399],
                        [-63.7797, 57.1618],
                        [-63.7794, 57.1794],
                        [-63.771, 57.196],
                        [-63.7501, 57.2091],
                        [-63.7371, 57.2216],
                        [-63.7415, 57.2408],
                        [-63.7598, 57.2517],
                        [-63.7796, 57.2594],
                        [-63.8091, 57.2622],
                        [-63.8187, 57.255],
                        [-63.8261, 57.2411],
                        [-63.8447, 57.226],
                        [-63.8416, 57.2108],
                        [-63.846, 57.2042],
                        [-63.8612, 57.2015],
                        [-63.8724, 57.1925],
                        [-63.8841, 57.1963],
                        [-63.8734, 57.2094],
                        [-63.867, 57.2261],
                        [-63.8814, 57.265],
                        [-63.8834, 57.2776],
                        [-63.8694, 57.2976],
                        [-63.8657, 57.3136],
                        [-63.8558, 57.3343],
                        [-63.8243, 57.3392],
                        [-63.8017, 57.3323],
                        [-63.7974, 57.3404],
                        [-63.7855, 57.3532],
                        [-63.7682, 57.3582],
                        [-63.742, 57.3577],
                        [-63.7146, 57.3633],
                        [-63.7013, 57.3683],
                        [-63.7083, 57.3845],
                        [-63.7234, 57.3983],
                        [-63.7103, 57.4226],
                        [-63.7164, 57.4382],
                        [-63.7315, 57.4266],
                        [-63.7613, 57.4393],
                        [-63.7583, 57.4544],
                        [-63.7657, 57.4646],
                        [-63.7752, 57.4936],
                        [-63.7675, 57.5148],
                        [-63.7534, 57.5153],
                        [-63.7512, 57.5357],
                        [-63.7655, 57.5608],
                        [-63.7711, 57.5793],
                        [-63.7719, 57.5924],
                        [-63.7583, 57.5916],
                        [-63.7456, 57.5795],
                        [-63.7326, 57.5887],
                        [-63.7306, 57.6011],
                        [-63.6995, 57.6153],
                        [-63.6846, 57.6034],
                        [-63.6703, 57.6212],
                        [-63.65, 57.6201],
                        [-63.6449, 57.6343],
                        [-63.623, 57.642],
                        [-63.6166, 57.6507],
                        [-63.5987, 57.6638],
                        [-63.5945, 57.6857],
                        [-63.6095, 57.6921],
                        [-63.597, 57.7077],
                        [-63.5949, 57.7193],
                        [-63.5968, 57.7412],
                        [-63.6232, 57.7395],
                        [-63.6326, 57.7289],
                        [-63.6525, 57.7329],
                        [-63.6681, 57.7239],
                        [-63.66, 57.716],
                        [-63.6594, 57.7018],
                        [-63.6724, 57.6926],
                        [-63.6851, 57.6969],
                        [-63.6854, 57.6833],
                        [-63.6926, 57.6527],
                        [-63.7028, 57.6638],
                        [-63.7353, 57.6628],
                        [-63.7486, 57.6743],
                        [-63.7448, 57.6886],
                        [-63.7514, 57.6999],
                        [-63.7536, 57.7207],
                        [-63.7731, 57.7002],
                        [-63.7899, 57.6875],
                        [-63.8042, 57.6845],
                        [-63.8134, 57.6898],
                        [-63.8207, 57.7056],
                        [-63.8475, 57.719],
                        [-63.8661, 57.7112],
                        [-63.8834, 57.7126],
                        [-63.9072, 57.7514],
                        [-63.9011, 57.7629],
                        [-63.9082, 57.7769],
                        [-63.9038, 57.7982],
                        [-63.9124, 57.8011],
                        [-63.9386, 57.7886],
                        [-63.9536, 57.7997],
                        [-63.9723, 57.7996],
                        [-63.9972, 57.8121],
                        [-64.0119, 57.8025],
                        [-64.0209, 57.8095],
                        [-64.0298, 57.804],
                        [-64.034, 57.7914],
                        [-64.0233, 57.7785],
                        [-64.026, 57.7644],
                        [-64.072, 57.77],
                        [-64.0921, 57.7943],
                        [-64.1167, 57.8111],
                        [-64.1214, 57.8263],
                        [-64.1566, 57.8902],
                        [-64.158, 57.9163],
                        [-64.1625, 57.9229],
                        [-64.1847, 57.926],
                        [-64.1988, 57.9376],
                        [-64.2047, 57.9736],
                        [-64.2274, 57.9895],
                        [-64.228, 58.0055],
                        [-64.2382, 58.0161],
                        [-64.2387, 58.025],
                        [-64.2185, 58.037],
                        [-64.2337, 58.0418],
                        [-64.251, 58.0387],
                        [-64.2693, 58.0614],
                        [-64.2777, 58.047],
                        [-64.2993, 58.0445],
                        [-64.3205, 58.0615],
                        [-64.3539, 58.0693],
                        [-64.3871, 58.0693],
                        [-64.4123, 58.0612],
                        [-64.4331, 58.0699],
                        [-64.4269, 58.0882],
                        [-64.4354, 58.0984],
                        [-64.4337, 58.1163],
                        [-64.4251, 58.1302],
                        [-64.4309, 58.1562],
                        [-64.4218, 58.1738],
                        [-64.4217, 58.1867],
                        [-64.3895, 58.1965],
                        [-64.3736, 58.2084],
                        [-64.3191, 58.2181],
                        [-64.2712, 58.2211],
                        [-64.2475, 58.2274],
                        [-64.2413, 58.2377],
                        [-64.2178, 58.2477],
                        [-64.2171, 58.2619],
                        [-64.2085, 58.2756],
                        [-64.217, 58.2875],
                        [-64.1969, 58.2936],
                        [-64.1982, 58.3119],
                        [-64.1846, 58.3171],
                        [-64.1782, 58.3306],
                        [-64.1868, 58.3367],
                        [-64.1755, 58.3439],
                        [-64.1722, 58.3619],
                        [-64.1585, 58.371],
                        [-64.1391, 58.3692],
                        [-64.1192, 58.3647],
                        [-64.098, 58.3768],
                        [-64.0617, 58.3609],
                        [-64.0305, 58.366],
                        [-64.0225, 58.371],
                        [-64.0286, 58.3894],
                        [-64.021, 58.409],
                        [-64.0132, 58.424],
                        [-63.9985, 58.4255],
                        [-63.9866, 58.4444],
                        [-63.9641, 58.4388],
                        [-63.9411, 58.4402],
                        [-63.9242, 58.4449],
                        [-63.9104, 58.4415],
                        [-63.8811, 58.4294],
                        [-63.8472, 58.45],
                        [-63.8431, 58.4678],
                        [-63.8223, 58.4737],
                        [-63.8079, 58.4897],
                        [-63.8236, 58.4973],
                        [-63.8587, 58.5045],
                        [-63.8592, 58.5212],
                        [-63.8488, 58.5335],
                        [-63.8497, 58.5466],
                        [-63.8626, 58.5527],
                        [-63.8582, 58.5638],
                        [-63.8871, 58.5717],
                        [-63.9074, 58.5735],
                        [-63.9467, 58.5588],
                        [-63.9519, 58.5413],
                        [-63.9736, 58.5296],
                        [-64.0088, 58.5313],
                        [-64.0341, 58.5235],
                        [-64.0469, 58.5351],
                        [-64.0672, 58.5345],
                        [-64.0747, 58.5474],
                        [-64.1049, 58.5632],
                        [-64.1111, 58.5817],
                        [-64.0928, 58.5879],
                        [-64.1159, 58.6024],
                        [-64.1093, 58.6199],
                        [-64.1079, 58.6397],
                        [-64.0789, 58.6539],
                        [-64.0766, 58.6659],
                        [-64.06, 58.6836],
                        [-64.0472, 58.7026],
                        [-64.0335, 58.7045],
                        [-64.0162, 58.6954],
                        [-63.9842, 58.6876],
                        [-63.9648, 58.6903],
                        [-63.9503, 58.6852],
                        [-63.9311, 58.701],
                        [-63.91, 58.7103],
                        [-63.875, 58.7058],
                        [-63.8551, 58.6964],
                        [-63.8407, 58.7014],
                        [-63.8356, 58.7101],
                        [-63.811, 58.7122],
                        [-63.7943, 58.7033],
                        [-63.7544, 58.7167],
                        [-63.7365, 58.7145],
                        [-63.7288, 58.7068],
                        [-63.7184, 58.7064],
                        [-63.6949, 58.7196],
                        [-63.6696, 58.7171],
                        [-63.6607, 58.7252],
                        [-63.6298, 58.7146],
                        [-63.6193, 58.7239],
                        [-63.5725, 58.7315],
                        [-63.5617, 58.7277],
                        [-63.5533, 58.7338],
                        [-63.5068, 58.7426],
                        [-63.4948, 58.754],
                        [-63.4675, 58.762],
                        [-63.4922, 58.7685],
                        [-63.5128, 58.7957],
                        [-63.5345, 58.8162],
                        [-63.5503, 58.8224],
                        [-63.5508, 58.8366],
                        [-63.5953, 58.8572],
                        [-63.6178, 58.8553],
                        [-63.636, 58.8487],
                        [-63.6611, 58.8547],
                        [-63.6803, 58.8665],
                        [-63.6799, 58.8786],
                        [-63.71, 58.8871],
                        [-63.7255, 58.8772],
                        [-63.7777, 58.8841],
                        [-63.7993, 58.8565],
                        [-63.8121, 58.8471],
                        [-63.8353, 58.849],
                        [-63.8508, 58.8351],
                        [-63.8622, 58.832],
                        [-63.8979, 58.8374],
                        [-63.9572, 58.8269],
                        [-63.9755, 58.8303],
                        [-63.9954, 58.8137],
                        [-64.0197, 58.8167],
                        [-64.0453, 58.8104],
                        [-64.0386, 58.8021],
                        [-64.0463, 58.7896],
                        [-64.0719, 58.7832],
                        [-64.0697, 58.7691],
                        [-64.0871, 58.756],
                        [-64.0941, 58.7569],
                        [-64.1378, 58.7462],
                        [-64.1583, 58.7581],
                        [-64.1788, 58.789],
                        [-64.2001, 58.7795],
                        [-64.2297, 58.7836],
                        [-64.2375, 58.805],
                        [-64.2266, 58.8458],
                        [-64.2337, 58.8641],
                        [-64.2874, 58.8835],
                        [-64.2876, 58.8968],
                        [-64.3325, 58.9046],
                        [-64.3503, 58.8919],
                        [-64.3787, 58.9038],
                        [-64.4003, 58.9027],
                        [-64.4059, 58.8908],
                        [-64.4206, 58.8903],
                        [-64.4264, 58.9045],
                        [-64.4558, 58.9082],
                        [-64.4764, 58.9034],
                        [-64.5089, 58.8876],
                        [-64.5502, 58.8875],
                        [-64.5572, 58.8972],
                        [-64.5796, 58.9047],
                        [-64.6148, 58.9075],
                        [-64.6324, 58.9333],
                        [-64.6517, 58.9438],
                        [-64.6813, 58.948],
                        [-64.7026, 58.9464],
                        [-64.7307, 58.9508],
                        [-64.7562, 58.9403],
                        [-64.7503, 58.9341],
                        [-64.7657, 58.9238],
                        [-64.8207, 58.9169],
                        [-64.8321, 58.9217],
                        [-64.8393, 58.937],
                        [-64.8563, 58.9329],
                        [-64.857, 58.9386],
                        [-64.8685, 58.9416],
                        [-64.8891, 58.9384],
                        [-64.8794, 58.9519],
                        [-64.8486, 58.9531],
                        [-64.8329, 58.9564],
                        [-64.8421, 58.967],
                        [-64.8404, 58.9803],
                        [-64.8622, 58.9862],
                        [-64.8701, 58.9983],
                        [-64.865, 59.0154],
                        [-64.8436, 59.0163],
                        [-64.8294, 59.0203],
                        [-64.8408, 59.0325],
                        [-64.832, 59.0352],
                        [-64.8217, 59.0493],
                        [-64.7792, 59.075],
                        [-64.7525, 59.0705],
                        [-64.7249, 59.076],
                        [-64.6833, 59.0747],
                        [-64.6793, 59.047],
                        [-64.6703, 59.0386],
                        [-64.6502, 59.0412],
                        [-64.6307, 59.0407],
                        [-64.6166, 59.0341],
                        [-64.5958, 59.035],
                        [-64.5706, 59.0273],
                        [-64.545, 59.0096],
                        [-64.5228, 59.0026],
                        [-64.5068, 59.0197],
                        [-64.4978, 59.0194],
                        [-64.4713, 59],
                        [-64.4813, 58.9883],
                        [-64.4603, 58.9815],
                        [-64.4368, 58.9943],
                        [-64.4242, 58.9917],
                        [-64.3877, 59.0062],
                        [-64.3453, 59.0049],
                        [-64.2944, 59.0005],
                        [-64.2618, 59.013],
                        [-64.2736, 59.022],
                        [-64.3152, 59.0656],
                        [-64.3608, 59.0673],
                        [-64.3635, 59.0874],
                        [-64.376, 59.0926],
                        [-64.417, 59.0876],
                        [-64.4505, 59.1029],
                        [-64.4903, 59.0991],
                        [-64.498, 59.1064],
                        [-64.484, 59.1182],
                        [-64.4913, 59.1364],
                        [-64.4867, 59.1528],
                        [-64.4727, 59.1804],
                        [-64.5034, 59.1957],
                        [-64.5218, 59.1967],
                        [-64.5114, 59.2389],
                        [-64.4886, 59.2571],
                        [-64.517, 59.2799],
                        [-64.5435, 59.3104],
                        [-64.5426, 59.3187],
                        [-64.5278, 59.32],
                        [-64.5114, 59.3351],
                        [-64.4944, 59.3443],
                        [-64.4933, 59.3496],
                        [-64.5257, 59.3616],
                        [-64.5383, 59.3708],
                        [-64.5241, 59.3851],
                        [-64.5283, 59.3956],
                        [-64.5165, 59.4039],
                        [-64.496, 59.4043],
                        [-64.4607, 59.4122],
                        [-64.4726, 59.4183],
                        [-64.5116, 59.423],
                        [-64.4668, 59.4502],
                        [-64.458, 59.4592],
                        [-64.4261, 59.4649],
                        [-64.4072, 59.4778],
                        [-64.387, 59.4738],
                        [-64.3808, 59.4838],
                        [-64.3694, 59.4884],
                        [-64.3497, 59.503],
                        [-64.3279, 59.4975],
                        [-64.3241, 59.5128],
                        [-64.3858, 59.5238],
                        [-64.419, 59.5326],
                        [-64.4338, 59.542],
                        [-64.4629, 59.5234],
                        [-64.5031, 59.5214],
                        [-64.5094, 59.5136],
                        [-64.5053, 59.5013],
                        [-64.5322, 59.4995],
                        [-64.5615, 59.4879],
                        [-64.5788, 59.471],
                        [-64.5897, 59.4662],
                        [-64.6268, 59.482],
                        [-64.6361, 59.4772],
                        [-64.6428, 59.4592],
                        [-64.6798, 59.4469],
                        [-64.6944, 59.458],
                        [-64.7173, 59.4615],
                        [-64.7286, 59.4666],
                        [-64.7369, 59.4807],
                        [-64.7539, 59.4883],
                        [-64.7631, 59.4966],
                        [-64.7641, 59.5149],
                        [-64.8042, 59.525],
                        [-64.8031, 59.5343],
                        [-64.8228, 59.5407],
                        [-64.8396, 59.5533],
                        [-64.8692, 59.5469],
                        [-64.8922, 59.5625],
                        [-64.9394, 59.567],
                        [-64.9271, 59.5932],
                        [-64.8687, 59.5864],
                        [-64.8598, 59.5915],
                        [-64.8825, 59.6107],
                        [-64.8983, 59.6418],
                        [-64.8895, 59.6622],
                        [-64.8717, 59.6683],
                        [-64.8249, 59.676],
                        [-64.7876, 59.6926],
                        [-64.7605, 59.7066],
                        [-64.7494, 59.7172],
                        [-64.7743, 59.7251],
                        [-64.8015, 59.7248],
                        [-64.8102, 59.7297],
                        [-64.7972, 59.7385],
                        [-64.79, 59.7603],
                        [-64.814, 59.783],
                        [-64.814, 59.7952],
                        [-64.7885, 59.8033],
                        [-64.7921, 59.8122],
                        [-64.8252, 59.8256],
                        [-64.8167, 59.835],
                        [-64.795, 59.8341],
                        [-64.797, 59.8408],
                        [-64.7781, 59.8528],
                        [-64.7369, 59.8556],
                        [-64.7392, 59.862],
                        [-64.7666, 59.8697],
                        [-64.7562, 59.886],
                        [-64.7415, 59.8893],
                        [-64.7042, 59.8837],
                        [-64.6838, 59.8772],
                        [-64.6664, 59.8883],
                        [-64.6587, 59.9016],
                        [-64.641, 59.9074],
                        [-64.637, 59.919],
                        [-64.6268, 59.9303],
                        [-64.6341, 59.936],
                        [-64.6636, 59.9277],
                        [-64.6694, 59.9301],
                        [-64.6615, 59.9397],
                        [-64.6622, 59.9526],
                        [-64.6931, 59.9589],
                        [-64.7095, 59.9538],
                        [-64.7322, 59.9505],
                        [-64.7474, 59.9547],
                        [-64.7416, 59.9652],
                        [-64.7471, 59.9745],
                        [-64.7828, 59.9804],
                        [-64.8068, 59.9708],
                        [-64.8402, 59.9703],
                        [-64.861, 59.9952],
                        [-64.8707, 60.0169],
                        [-64.8877, 60.042],
                        [-64.9236, 60.0615],
                        [-64.9204, 60.0659],
                        [-64.8959, 60.0662],
                        [-64.8647, 60.0584],
                        [-64.8198, 60.0505],
                        [-64.8041, 60.0524],
                        [-64.7832, 60.0576],
                        [-64.7427, 60.0561],
                        [-64.7179, 60.0641],
                        [-64.7063, 60.0743],
                        [-64.708, 60.0846],
                        [-64.7267, 60.0947],
                        [-64.7575, 60.1014],
                        [-64.7673, 60.1084],
                        [-64.7353, 60.1095],
                        [-64.7055, 60.1076],
                        [-64.6812, 60.1075],
                        [-64.672, 60.1135],
                        [-64.6331, 60.1054],
                        [-64.6034, 60.1097],
                        [-64.5937, 60.117],
                        [-64.5988, 60.1428],
                        [-64.5942, 60.1495],
                        [-64.6025, 60.1558],
                        [-64.6025, 60.167],
                        [-64.6145, 60.1708],
                        [-64.6412, 60.1674],
                        [-64.6616, 60.1702],
                        [-64.6825, 60.1812],
                        [-64.6923, 60.1801],
                        [-64.6989, 60.1713],
                        [-64.7332, 60.1644],
                        [-64.7456, 60.1686],
                        [-64.7499, 60.1882],
                        [-64.7802, 60.1961],
                        [-64.7841, 60.204],
                        [-64.8407, 60.223],
                        [-64.8639, 60.2282],
                        [-64.8734, 60.2373],
                        [-64.8694, 60.2441],
                        [-64.8528, 60.2527],
                        [-64.8623, 60.2585],
                        [-64.8538, 60.2694],
                        [-64.8416, 60.2722],
                        [-64.8073, 60.2758],
                        [-64.7535, 60.2869],
                        [-64.7234, 60.2915],
                        [-64.7074, 60.3033],
                        [-64.681, 60.2966],
                        [-64.6552, 60.2948],
                        [-64.6437, 60.2977],
                        [-64.5973, 60.3006],
                        [-64.5812, 60.3084],
                        [-64.5633, 60.3069],
                        [-64.5297, 60.2967],
                        [-64.5248, 60.2998],
                        [-64.5048, 60.2907],
                        [-64.4913, 60.294],
                        [-64.4826, 60.2929],
                        [-64.465, 60.2794],
                        [-64.4435, 60.2785],
                        [-64.436, 60.2692],
                        [-64.4162, 60.2709],
                        [-64.4121, 60.2538],
                        [-64.4027, 60.2478],
                        [-64.3856, 60.2511],
                        [-64.3773, 60.2449],
                        [-64.3894, 60.2264],
                        [-64.383, 60.2199],
                        [-64.3951, 60.208],
                        [-64.4159, 60.2058],
                        [-64.4263, 60.2006],
                        [-64.391, 60.1979],
                        [-64.3703, 60.1981],
                        [-64.3702, 60.1886],
                        [-64.3827, 60.1826],
                        [-64.3846, 60.175],
                        [-64.3649, 60.1676],
                        [-64.3845, 60.1629],
                        [-64.3958, 60.1652],
                        [-64.3914, 60.1535],
                        [-64.4033, 60.1472],
                        [-64.416, 60.1482],
                        [-64.4231, 60.1436],
                        [-64.4601, 60.1274],
                        [-64.4697, 60.1273],
                        [-64.4883, 60.1201],
                        [-64.4907, 60.1092],
                        [-64.4779, 60.0967],
                        [-64.4679, 60.1003],
                        [-64.4474, 60.1003],
                        [-64.4513, 60.0901],
                        [-64.4657, 60.0879],
                        [-64.4685, 60.0836],
                        [-64.4873, 60.0826],
                        [-64.4941, 60.08],
                        [-64.5058, 60.0849],
                        [-64.5279, 60.0867],
                        [-64.5356, 60.0813],
                        [-64.5572, 60.0792],
                        [-64.5591, 60.074],
                        [-64.5758, 60.0713],
                        [-64.5753, 60.0765],
                        [-64.5851, 60.0805],
                        [-64.599, 60.0727],
                        [-64.6122, 60.0692],
                        [-64.6188, 60.0621],
                        [-64.6433, 60.0479],
                        [-64.6607, 60.049],
                        [-64.6641, 60.0449],
                        [-64.6849, 60.041],
                        [-64.7016, 60.0275],
                        [-64.6973, 60.0239],
                        [-64.6763, 60.03],
                        [-64.661, 60.0383],
                        [-64.6431, 60.0393],
                        [-64.6403, 60.0431],
                        [-64.6134, 60.0506],
                        [-64.6032, 60.0605],
                        [-64.5856, 60.0632],
                        [-64.5798, 60.0701],
                        [-64.5717, 60.0704],
                        [-64.5638, 60.0603],
                        [-64.5448, 60.0617],
                        [-64.5365, 60.0712],
                        [-64.526, 60.0674],
                        [-64.5143, 60.0726],
                        [-64.4934, 60.0669],
                        [-64.4818, 60.0658],
                        [-64.4678, 60.0685],
                        [-64.4506, 60.0644],
                        [-64.4405, 60.0652],
                        [-64.4304, 60.0727],
                        [-64.4197, 60.0869],
                        [-64.4246, 60.1046],
                        [-64.4211, 60.1142],
                        [-64.4061, 60.1264],
                        [-64.3945, 60.124],
                        [-64.3778, 60.127],
                        [-64.3675, 60.1262],
                        [-64.3663, 60.1168],
                        [-64.3733, 60.1039],
                        [-64.3678, 60.0988],
                        [-64.3726, 60.0891],
                        [-64.3672, 60.0794],
                        [-64.3788, 60.0767],
                        [-64.3866, 60.0809],
                        [-64.3864, 60.0899],
                        [-64.3995, 60.0956],
                        [-64.4171, 60.0878],
                        [-64.4198, 60.0752],
                        [-64.4102, 60.0693],
                        [-64.4142, 60.0602],
                        [-64.4096, 60.0572],
                        [-64.3897, 60.0571],
                        [-64.3651, 60.0519],
                        [-64.3605, 60.0473],
                        [-64.3717, 60.0381],
                        [-64.3925, 60.0114],
                        [-64.415, 60.0016],
                        [-64.4426, 60.0083],
                        [-64.4564, 60.0053],
                        [-64.4698, 60.0053],
                        [-64.4879, 60.0021],
                        [-64.5128, 60.0076],
                        [-64.5342, 60.0071],
                        [-64.5372, 60.0044],
                        [-64.5747, 60.0043],
                        [-64.5798, 60.0001],
                        [-64.5608, 59.9962],
                        [-64.5372, 60.0022],
                        [-64.5066, 59.9981],
                        [-64.4826, 59.9933],
                        [-64.4574, 59.9969],
                        [-64.44, 59.9936],
                        [-64.4225, 59.9874],
                        [-64.3977, 59.9828],
                        [-64.3965, 59.967],
                        [-64.4054, 59.9579],
                        [-64.3897, 59.9508],
                        [-64.3945, 59.9376],
                        [-64.4036, 59.9363],
                        [-64.4184, 59.9405],
                        [-64.4289, 59.9407],
                        [-64.4578, 59.9288],
                        [-64.4675, 59.921],
                        [-64.4868, 59.914],
                        [-64.4911, 59.9082],
                        [-64.5036, 59.9046],
                        [-64.5222, 59.9038],
                        [-64.5269, 59.8992],
                        [-64.5182, 59.8938],
                        [-64.4977, 59.8911],
                        [-64.4841, 59.8912],
                        [-64.4714, 59.8959],
                        [-64.4668, 59.9064],
                        [-64.4463, 59.9085],
                        [-64.4365, 59.9131],
                        [-64.4216, 59.9263],
                        [-64.4097, 59.9265],
                        [-64.3981, 59.9202],
                        [-64.3809, 59.9243],
                        [-64.3718, 59.9241],
                        [-64.3709, 59.9377],
                        [-64.3761, 59.9536],
                        [-64.3604, 59.9718],
                        [-64.3528, 59.9757],
                        [-64.3378, 59.9924],
                        [-64.3104, 59.9989],
                        [-64.2862, 59.9977],
                        [-64.2739, 59.9946],
                        [-64.2539, 59.9947],
                        [-64.2208, 60.005],
                        [-64.217, 60.0135],
                        [-64.2243, 60.017],
                        [-64.2369, 60.0157],
                        [-64.2407, 60.0107],
                        [-64.2645, 60.0096],
                        [-64.275, 60.0027],
                        [-64.2865, 60.0068],
                        [-64.2964, 60.0064],
                        [-64.3214, 60.0126],
                        [-64.32, 60.021],
                        [-64.3287, 60.0299],
                        [-64.3228, 60.0541],
                        [-64.3131, 60.0555],
                        [-64.3043, 60.0455],
                        [-64.293, 60.0454],
                        [-64.2706, 60.0565],
                        [-64.2517, 60.0441],
                        [-64.216, 60.0385],
                        [-64.2122, 60.0242],
                        [-64.199, 60.0187],
                        [-64.1933, 60.0256],
                        [-64.1711, 60.026],
                        [-64.1569, 60.0138],
                        [-64.16, 60.0001],
                        [-64.1536, 59.9899],
                        [-64.154, 59.9831],
                        [-64.1613, 59.9765],
                        [-64.1813, 59.9733],
                        [-64.2146, 59.9778],
                        [-64.2258, 59.9717],
                        [-64.26, 59.967],
                        [-64.2902, 59.9576],
                        [-64.3044, 59.9486],
                        [-64.2985, 59.938],
                        [-64.2887, 59.936],
                        [-64.2586, 59.9435],
                        [-64.2381, 59.9472],
                        [-64.223, 59.9455],
                        [-64.209, 59.9352],
                        [-64.1952, 59.9326],
                        [-64.1865, 59.9254],
                        [-64.1697, 59.9173],
                        [-64.149, 59.9197],
                        [-64.1418, 59.9064],
                        [-64.1293, 59.9049],
                        [-64.1257, 59.8981],
                        [-64.1409, 59.8846],
                        [-64.1502, 59.873],
                        [-64.1499, 59.8687],
                        [-64.169, 59.861],
                        [-64.1774, 59.854],
                        [-64.1744, 59.8444],
                        [-64.1745, 59.8287],
                        [-64.1704, 59.82],
                        [-64.1636, 59.8163],
                        [-64.169, 59.8075],
                        [-64.1779, 59.8011],
                        [-64.1785, 59.7923],
                        [-64.1646, 59.791],
                        [-64.1431, 59.793],
                        [-64.1363, 59.7962],
                        [-64.1408, 59.8043],
                        [-64.128, 59.8303],
                        [-64.1211, 59.848],
                        [-64.1065, 59.8572],
                        [-64.0956, 59.8543],
                        [-64.065, 59.8731],
                        [-64.0603, 59.8686],
                        [-64.0332, 59.8522],
                        [-64.0372, 59.8451],
                        [-64.0547, 59.8309],
                        [-64.0559, 59.826],
                        [-64.0767, 59.8168],
                        [-64.0647, 59.8152],
                        [-64.0454, 59.8237],
                        [-64.0281, 59.8144],
                        [-64.0426, 59.8087],
                        [-64.0285, 59.7987],
                        [-64.0486, 59.7982],
                        [-64.0769, 59.8027],
                        [-64.0961, 59.7914],
                        [-64.0637, 59.7851],
                        [-64.027, 59.784],
                        [-64, 59.773],
                        [-63.9809, 59.7701],
                        [-63.967, 59.7652],
                        [-63.9534, 59.7657],
                        [-63.9347, 59.7555],
                        [-63.9506, 59.7392],
                        [-63.9685, 59.7325],
                        [-63.9859, 59.7213],
                        [-64, 59.7213],
                        [-64.0224, 59.7135],
                        [-64.0447, 59.7197],
                        [-64.0576, 59.7099],
                        [-64.0901, 59.7168],
                        [-64.0932, 59.7116],
                        [-64.0785, 59.7067],
                        [-64.0962, 59.7],
                        [-64.1016, 59.6943],
                        [-64.1219, 59.6955],
                        [-64.1336, 59.6943],
                        [-64.1594, 59.7019],
                        [-64.1633, 59.7104],
                        [-64.1522, 59.7189],
                        [-64.1324, 59.7187],
                        [-64.1293, 59.7219],
                        [-64.144, 59.7292],
                        [-64.1663, 59.7306],
                        [-64.183, 59.7342],
                        [-64.1908, 59.74],
                        [-64.1754, 59.754],
                        [-64.1781, 59.7603],
                        [-64.194, 59.762],
                        [-64.1971, 59.7678],
                        [-64.1802, 59.7721],
                        [-64.1519, 59.7726],
                        [-64.1481, 59.7873],
                        [-64.1863, 59.7792],
                        [-64.2034, 59.7718],
                        [-64.2228, 59.7751],
                        [-64.2404, 59.789],
                        [-64.2477, 59.7895],
                        [-64.262, 59.7962],
                        [-64.271, 59.7856],
                        [-64.2574, 59.7755],
                        [-64.2608, 59.7707],
                        [-64.2534, 59.7659],
                        [-64.2609, 59.7562],
                        [-64.2505, 59.7516],
                        [-64.2405, 59.7397],
                        [-64.2122, 59.7247],
                        [-64.2128, 59.7185],
                        [-64.19, 59.7147],
                        [-64.1888, 59.7051],
                        [-64.1736, 59.6893],
                        [-64.1668, 59.6869],
                        [-64.1299, 59.6842],
                        [-64.1108, 59.6813],
                        [-64.0878, 59.6823],
                        [-64.0788, 59.6867],
                        [-64.0601, 59.6803],
                        [-64.0447, 59.6787],
                        [-64.0203, 59.6851],
                        [-64.0023, 59.6861],
                        [-63.9819, 59.6808],
                        [-63.9642, 59.6843],
                        [-63.9516, 59.6819],
                        [-63.9388, 59.6866],
                        [-63.9398, 59.6695],
                        [-63.9472, 59.6645],
                        [-63.9791, 59.6565],
                        [-63.9807, 59.649],
                        [-63.9667, 59.6482],
                        [-63.9511, 59.6561],
                        [-63.9294, 59.6579],
                        [-63.9115, 59.6642],
                        [-63.9159, 59.6546],
                        [-63.9302, 59.6461],
                        [-63.9414, 59.6345],
                        [-63.9559, 59.6324],
                        [-63.976, 59.6403],
                        [-64.0034, 59.6487],
                        [-64.019, 59.6487],
                        [-64.0407, 59.6434],
                        [-64.051, 59.6351],
                        [-64.0577, 59.6253],
                        [-64.0701, 59.5988],
                        [-64.0677, 59.5914],
                        [-64.0691, 59.5795],
                        [-64.0786, 59.563],
                        [-64.1145, 59.5426],
                        [-64.1268, 59.5276],
                        [-64.1213, 59.5194],
                        [-64.1059, 59.524],
                        [-64.0983, 59.5347],
                        [-64.0856, 59.544],
                        [-64.062, 59.5531],
                        [-64.044, 59.5565],
                        [-64.0379, 59.5627],
                        [-64.0336, 59.5767],
                        [-64.0362, 59.5824],
                        [-64.0297, 59.6089],
                        [-64.03, 59.6168],
                        [-64.0234, 59.625],
                        [-64.0123, 59.6294],
                        [-64.0018, 59.6272],
                        [-63.9893, 59.6203],
                        [-63.9585, 59.6102],
                        [-63.9496, 59.6047],
                        [-63.9411, 59.6041],
                        [-63.9282, 59.61],
                        [-63.9103, 59.6084],
                        [-63.8951, 59.6161],
                        [-63.883, 59.6137],
                        [-63.8703, 59.6037],
                        [-63.8701, 59.5839],
                        [-63.8604, 59.5774],
                        [-63.8629, 59.5687],
                        [-63.8412, 59.5603],
                        [-63.8268, 59.5593],
                        [-63.8193, 59.5546],
                        [-63.7907, 59.5489],
                        [-63.7829, 59.5412],
                        [-63.7628, 59.5378],
                        [-63.7494, 59.5334],
                        [-63.7332, 59.5216],
                        [-63.7439, 59.5132],
                        [-63.7508, 59.4989],
                        [-63.7418, 59.4983],
                        [-63.7322, 59.5131],
                        [-63.722, 59.5109],
                        [-63.7204, 59.4933],
                        [-63.7384, 59.4807],
                        [-63.7575, 59.4745],
                        [-63.7599, 59.4599],
                        [-63.7749, 59.4441],
                        [-63.7701, 59.4388],
                        [-63.774, 59.4322],
                        [-63.7873, 59.4243],
                        [-63.7935, 59.4134],
                        [-63.8122, 59.4065],
                        [-63.8137, 59.4011],
                        [-63.8245, 59.3989],
                        [-63.835, 59.407],
                        [-63.8593, 59.4038],
                        [-63.9071, 59.4093],
                        [-63.9179, 59.408],
                        [-63.9334, 59.4129],
                        [-63.9411, 59.4127],
                        [-63.965, 59.407],
                        [-64, 59.4153],
                        [-64.0107, 59.4107],
                        [-64.0231, 59.4104],
                        [-64.0382, 59.4039],
                        [-64.0529, 59.4008],
                        [-64.0662, 59.3906],
                        [-64.0619, 59.385],
                        [-64.0647, 59.3773],
                        [-64.073, 59.3748],
                        [-64.0708, 59.3705],
                        [-64.0845, 59.3688],
                        [-64.0934, 59.3715],
                        [-64.1159, 59.3697],
                        [-64.0885, 59.3639],
                        [-64.0748, 59.3673],
                        [-64.0682, 59.3713],
                        [-64.0711, 59.3737],
                        [-64.0499, 59.3832],
                        [-64.0109, 59.3922],
                        [-64, 59.3958],
                        [-63.9879, 59.391],
                        [-63.9655, 59.3919],
                        [-63.9464, 59.3863],
                        [-63.9223, 59.3838],
                        [-63.9019, 59.3907],
                        [-63.8913, 59.3851],
                        [-63.8748, 59.3843],
                        [-63.8705, 59.3779],
                        [-63.8507, 59.3701],
                        [-63.8339, 59.3748],
                        [-63.8204, 59.3756],
                        [-63.8062, 59.3731],
                        [-63.8087, 59.3659],
                        [-63.8013, 59.3603],
                        [-63.7834, 59.3599],
                        [-63.7734, 59.3646],
                        [-63.7771, 59.3726],
                        [-63.7718, 59.3822],
                        [-63.7574, 59.3852],
                        [-63.7482, 59.378],
                        [-63.7559, 59.3656],
                        [-63.7628, 59.3604],
                        [-63.755, 59.3546],
                        [-63.7294, 59.3559],
                        [-63.7067, 59.3635],
                        [-63.7039, 59.3552],
                        [-63.7144, 59.3501],
                        [-63.7274, 59.339],
                        [-63.7198, 59.3275],
                        [-63.7279, 59.3146],
                        [-63.7259, 59.307],
                        [-63.7399, 59.2935],
                        [-63.7575, 59.2633],
                        [-63.769, 59.2561],
                        [-63.7835, 59.2505],
                        [-63.8251, 59.2461],
                        [-63.8338, 59.2416],
                        [-63.8031, 59.2381],
                        [-63.7939, 59.2346],
                        [-63.7728, 59.2376],
                        [-63.762, 59.2409],
                        [-63.7333, 59.2564],
                        [-63.723, 59.2684],
                        [-63.7145, 59.2902],
                        [-63.7008, 59.2969],
                        [-63.6916, 59.3158],
                        [-63.6938, 59.3284],
                        [-63.677, 59.3344],
                        [-63.6619, 59.3312],
                        [-63.6526, 59.3503],
                        [-63.6443, 59.3555],
                        [-63.6336, 59.3551],
                        [-63.6201, 59.3477],
                        [-63.6141, 59.341],
                        [-63.5947, 59.343],
                        [-63.5773, 59.3426],
                        [-63.5629, 59.3445],
                        [-63.5546, 59.3401],
                        [-63.5417, 59.3392],
                        [-63.5316, 59.3341],
                        [-63.527, 59.326],
                        [-63.5094, 59.3108],
                        [-63.5129, 59.3019],
                        [-63.5204, 59.2954],
                        [-63.5301, 59.2973],
                        [-63.541, 59.2938],
                        [-63.5354, 59.2891],
                        [-63.5225, 59.2874],
                        [-63.5252, 59.2771],
                        [-63.5244, 59.2652],
                        [-63.5178, 59.2636],
                        [-63.5331, 59.25],
                        [-63.5598, 59.241],
                        [-63.5619, 59.2351],
                        [-63.5778, 59.2179],
                        [-63.5972, 59.2133],
                        [-63.6311, 59.2133],
                        [-63.6576, 59.2174],
                        [-63.6671, 59.2155],
                        [-63.6282, 59.2077],
                        [-63.6064, 59.2066],
                        [-63.587, 59.2016],
                        [-63.5789, 59.2033],
                        [-63.5354, 59.2195],
                        [-63.5306, 59.2258],
                        [-63.5011, 59.2378],
                        [-63.4935, 59.2487],
                        [-63.472, 59.2692],
                        [-63.4522, 59.2719],
                        [-63.4412, 59.2779],
                        [-63.4257, 59.2745],
                        [-63.4122, 59.2778],
                        [-63.3854, 59.2775],
                        [-63.3752, 59.2675],
                        [-63.3832, 59.2587],
                        [-63.4031, 59.2439],
                        [-63.4065, 59.2373],
                        [-63.4247, 59.2307],
                        [-63.4445, 59.2355],
                        [-63.4535, 59.2342],
                        [-63.456, 59.2283],
                        [-63.4446, 59.2241],
                        [-63.4312, 59.2154],
                        [-63.4408, 59.2007],
                        [-63.4623, 59.1898],
                        [-63.4607, 59.1756],
                        [-63.4545, 59.176],
                        [-63.4496, 59.1858],
                        [-63.4346, 59.1915],
                        [-63.4167, 59.2029],
                        [-63.3868, 59.2023],
                        [-63.3798, 59.1995],
                        [-63.3612, 59.1983],
                        [-63.3509, 59.1909],
                        [-63.3614, 59.1858],
                        [-63.3756, 59.1681],
                        [-63.3829, 59.1639],
                        [-63.4038, 59.1379],
                        [-63.3993, 59.1298],
                        [-63.4073, 59.1194],
                        [-63.433, 59.1131],
                        [-63.4487, 59.0992],
                        [-63.4846, 59.0925],
                        [-63.5121, 59.0942],
                        [-63.5203, 59.0886],
                        [-63.5596, 59.0736],
                        [-63.5737, 59.0666],
                        [-63.5843, 59.0576],
                        [-63.5983, 59.0612],
                        [-63.6236, 59.0622],
                        [-63.6364, 59.0642],
                        [-63.6627, 59.0603],
                        [-63.6765, 59.0603],
                        [-63.7079, 59.0506],
                        [-63.7479, 59.0485],
                        [-63.7592, 59.0449],
                        [-63.7674, 59.0491],
                        [-63.8047, 59.0611],
                        [-63.826, 59.0607],
                        [-63.8458, 59.0659],
                        [-63.8566, 59.0623],
                        [-63.8755, 59.0675],
                        [-63.8884, 59.066],
                        [-63.9014, 59.0764],
                        [-63.9172, 59.0816],
                        [-63.9391, 59.0775],
                        [-63.9572, 59.079],
                        [-63.9667, 59.0738],
                        [-63.9837, 59.0708],
                        [-63.9932, 59.054],
                        [-64, 59.0501],
                        [-64.0122, 59.0368],
                        [-64.0217, 59.032],
                        [-64.0457, 59.0269],
                        [-64.0479, 59.0181],
                        [-64.0261, 59.0206],
                        [-64, 59.0293],
                        [-63.9907, 59.0304],
                        [-63.9816, 59.0365],
                        [-63.9783, 59.0433],
                        [-63.9688, 59.051],
                        [-63.9625, 59.0658],
                        [-63.943, 59.0654],
                        [-63.9374, 59.0599],
                        [-63.9276, 59.0604],
                        [-63.9047, 59.0554],
                        [-63.8944, 59.0458],
                        [-63.9009, 59.0369],
                        [-63.904, 59.0235],
                        [-63.9013, 59.0118],
                        [-63.904, 58.9986],
                        [-63.8955, 58.9862],
                        [-63.8987, 58.9718],
                        [-63.8911, 58.9615],
                        [-63.8874, 58.9509],
                        [-63.8718, 58.9553],
                        [-63.8816, 58.9924],
                        [-63.884, 59.0119],
                        [-63.8806, 59.0344],
                        [-63.8604, 59.042],
                        [-63.7998, 59.0371],
                        [-63.779, 59.0362],
                        [-63.7755, 59.0216],
                        [-63.7608, 59.0136],
                        [-63.7557, 59.0079],
                        [-63.7388, 59.0084],
                        [-63.7261, 59.016],
                        [-63.6987, 59.0262],
                        [-63.6835, 59.0297],
                        [-63.667, 59.0307],
                        [-63.6526, 59.0372],
                        [-63.6348, 59.0377],
                        [-63.6114, 59.0358],
                        [-63.5952, 59.0313],
                        [-63.5787, 59.0331],
                        [-63.5512, 59.0317],
                        [-63.5419, 59.0231],
                        [-63.5294, 59.0232],
                        [-63.5237, 59.0414],
                        [-63.5043, 59.0592],
                        [-63.4813, 59.0665],
                        [-63.4504, 59.0725],
                        [-63.4388, 59.0641],
                        [-63.4277, 59.0642],
                        [-63.411, 59.0717],
                        [-63.4112, 59.0763],
                        [-63.3954, 59.09],
                        [-63.3882, 59.0929],
                        [-63.3745, 59.0902],
                        [-63.3585, 59.0912],
                        [-63.3368, 59.089],
                        [-63.3291, 59.0844],
                        [-63.3195, 59.089],
                        [-63.3052, 59.0897],
                        [-63.2888, 59.0811],
                        [-63.2959, 59.0636],
                        [-63.282, 59.0584],
                        [-63.272, 59.0712],
                        [-63.255, 59.0742],
                        [-63.2381, 59.0664],
                        [-63.2268, 59.0537],
                        [-63.195, 59.0494],
                        [-63.1768, 59.0495],
                        [-63.1611, 59.0532],
                        [-63.1416, 59.0534],
                        [-63.1286, 59.047],
                        [-63.125, 59.0396],
                        [-63.1411, 59.0294],
                        [-63.158, 59.0299],
                        [-63.189, 59.0216],
                        [-63.1975, 59.0211],
                        [-63.2132, 59.025],
                        [-63.2389, 59.0251],
                        [-63.2895, 59.0324],
                        [-63.3381, 59.029],
                        [-63.3489, 59.0252],
                        [-63.3456, 59.0195],
                        [-63.3225, 59.0201],
                        [-63.2971, 59.0154],
                        [-63.2802, 59.0158],
                        [-63.2715, 59.0137],
                        [-63.2563, 59.0144],
                        [-63.2262, 59.0062],
                        [-63.2163, 58.9999],
                        [-63.2238, 58.9933],
                        [-63.2731, 58.973],
                        [-63.2775, 58.9646],
                        [-63.2895, 58.9559],
                        [-63.3007, 58.9531],
                        [-63.3095, 58.9461],
                        [-63.3094, 58.9379],
                        [-63.2923, 58.9356],
                        [-63.2578, 58.9445],
                        [-63.2503, 58.9528],
                        [-63.2475, 58.9633],
                        [-63.2367, 58.967],
                        [-63.2261, 58.9749],
                        [-63.1848, 58.9843],
                        [-63.1686, 58.9818],
                        [-63.1656, 58.9722],
                        [-63.1591, 58.9698],
                        [-63.1612, 58.9557],
                        [-63.1576, 58.9481],
                        [-63.1644, 58.9461],
                        [-63.2126, 58.9459],
                        [-63.2299, 58.9366],
                        [-63.2253, 58.9308],
                        [-63.2047, 58.9357],
                        [-63.1732, 58.9376],
                        [-63.1615, 58.9333],
                        [-63.1715, 58.9218],
                        [-63.15, 58.918],
                        [-63.158, 58.9099],
                        [-63.1735, 58.9085],
                        [-63.1805, 58.9013],
                        [-63.1908, 58.8999],
                        [-63.2, 58.8927],
                        [-63.2091, 58.8798],
                        [-63.2292, 58.8673],
                        [-63.2746, 58.8682],
                        [-63.2875, 58.865],
                        [-63.3242, 58.865],
                        [-63.3354, 58.8606],
                        [-63.3348, 58.8553],
                        [-63.3185, 58.8553],
                        [-63.3033, 58.8503],
                        [-63.2867, 58.8554],
                        [-63.2569, 58.8526],
                        [-63.2452, 58.8557],
                        [-63.2285, 58.855],
                        [-63.2186, 58.8587],
                        [-63.2005, 58.858],
                        [-63.2001, 58.8489],
                        [-63.1853, 58.8459],
                        [-63.1779, 58.8531],
                        [-63.1663, 58.8712],
                        [-63.158, 58.8763],
                        [-63.1428, 58.872],
                        [-63.1415, 58.8638],
                        [-63.1499, 58.8552],
                        [-63.1462, 58.8508],
                        [-63.158, 58.8453],
                        [-63.1683, 58.8349],
                        [-63.1653, 58.8255],
                        [-63.1767, 58.8101],
                        [-63.1696, 58.8032],
                        [-63.1798, 58.7857],
                        [-63.1796, 58.7652],
                        [-63.1701, 58.7607],
                        [-63.1642, 58.7652],
                        [-63.1659, 58.7734],
                        [-63.1611, 58.7809],
                        [-63.1692, 58.7936],
                        [-63.1607, 58.804],
                        [-63.1646, 58.8141],
                        [-63.1519, 58.8196],
                        [-63.133, 58.8424],
                        [-63.1154, 58.8423],
                        [-63.1041, 58.8538],
                        [-63.1151, 58.8631],
                        [-63.1069, 58.8758],
                        [-63.0938, 58.8799],
                        [-63.092, 58.8886],
                        [-63.0843, 58.8926],
                        [-63.0751, 58.8906],
                        [-63.0614, 58.9052],
                        [-63.0508, 58.898],
                        [-63.0465, 58.8895],
                        [-63.0299, 58.8938],
                        [-63.0197, 58.8932],
                        [-63.0156, 58.8878],
                        [-62.9925, 58.8758],
                        [-63.0046, 58.873],
                        [-63.0213, 58.874],
                        [-63.0559, 58.8631],
                        [-63.0532, 58.8565],
                        [-63.0425, 58.8597],
                        [-63.0149, 58.8622],
                        [-63.0028, 58.8582],
                        [-62.9965, 58.8526],
                        [-62.984, 58.8478],
                        [-62.968, 58.8503],
                        [-62.9642, 58.8475],
                        [-62.9757, 58.8394],
                        [-62.9696, 58.8318],
                        [-62.9695, 58.8249],
                        [-62.9535, 58.8272],
                        [-62.9206, 58.8246],
                        [-62.9018, 58.8149],
                        [-62.9091, 58.8041],
                        [-62.8931, 58.7931],
                        [-62.8943, 58.7798],
                        [-62.9044, 58.7814],
                        [-62.9137, 58.7728],
                        [-62.9098, 58.7442],
                        [-62.9188, 58.7334],
                        [-62.9202, 58.7247],
                        [-62.9391, 58.7321],
                        [-62.962, 58.7238],
                        [-62.9655, 58.7177],
                        [-62.9843, 58.7114],
                        [-62.9913, 58.7118],
                        [-63.0142, 58.719],
                        [-63.0251, 58.7198],
                        [-63.0469, 58.7149],
                        [-63.0591, 58.7103],
                        [-63.0613, 58.7015],
                        [-63.0699, 58.6902],
                        [-63.0726, 58.674],
                        [-63.0852, 58.6598],
                        [-63.0775, 58.6574],
                        [-63.0608, 58.6658],
                        [-63.0555, 58.6735],
                        [-63.0575, 58.6838],
                        [-63.049, 58.7015],
                        [-63.0309, 58.6966],
                        [-62.9917, 58.6976],
                        [-62.9739, 58.6948],
                        [-62.9456, 58.6973],
                        [-62.8922, 58.7078],
                        [-62.8768, 58.7165],
                        [-62.8738, 58.707],
                        [-62.8635, 58.7046],
                        [-62.853, 58.6955],
                        [-62.8534, 58.6829],
                        [-62.8451, 58.6682],
                        [-62.8567, 58.6595],
                        [-62.857, 58.6471],
                        [-62.87, 58.6352],
                        [-62.9155, 58.6232],
                        [-62.9244, 58.614],
                        [-62.9238, 58.6092],
                        [-62.9144, 58.6054],
                        [-62.9036, 58.5956],
                        [-62.9064, 58.5929],
                        [-62.9337, 58.5845],
                        [-62.9566, 58.5817],
                        [-62.9675, 58.5757],
                        [-62.9729, 58.5655],
                        [-62.9857, 58.5641],
                        [-62.9901, 58.5555],
                        [-63.005, 58.5553],
                        [-63.0161, 58.5525],
                        [-63.0496, 58.5551],
                        [-63.0678, 58.5508],
                        [-63.076, 58.5459],
                        [-63.086, 58.5456],
                        [-63.1026, 58.5381],
                        [-63.1118, 58.5147],
                        [-63.1237, 58.5135],
                        [-63.1348, 58.518],
                        [-63.1504, 58.5194],
                        [-63.1504, 58.5128],
                        [-63.1582, 58.5045],
                        [-63.1686, 58.4989],
                        [-63.1839, 58.4975],
                        [-63.2131, 58.4911],
                        [-63.2272, 58.4895],
                        [-63.2773, 58.4911],
                        [-63.2876, 58.4938],
                        [-63.2974, 58.5069],
                        [-63.3044, 58.5068],
                        [-63.2991, 58.4921],
                        [-63.3033, 58.4858],
                        [-63.3183, 58.4837],
                        [-63.3411, 58.489],
                        [-63.3846, 58.4926],
                        [-63.4084, 58.4982],
                        [-63.4207, 58.5045],
                        [-63.4361, 58.5296],
                        [-63.4412, 58.5429],
                        [-63.4542, 58.546],
                        [-63.4434, 58.5235],
                        [-63.4312, 58.5039],
                        [-63.4347, 58.4946],
                        [-63.4537, 58.4894],
                        [-63.464, 58.4845],
                        [-63.4754, 58.4833],
                        [-63.4862, 58.4761],
                        [-63.5123, 58.468],
                        [-63.5246, 58.4661],
                        [-63.5244, 58.4606],
                        [-63.4976, 58.4653],
                        [-63.4515, 58.4816],
                        [-63.425, 58.4874],
                        [-63.4088, 58.4893],
                        [-63.4037, 58.4864],
                        [-63.3517, 58.4782],
                        [-63.3272, 58.4701],
                        [-63.3219, 58.463],
                        [-63.3284, 58.4544],
                        [-63.3562, 58.4394],
                        [-63.3662, 58.4314],
                        [-63.3885, 58.4198],
                        [-63.3955, 58.4117],
                        [-63.3963, 58.4032],
                        [-63.41, 58.3897],
                        [-63.4265, 58.3801],
                        [-63.4535, 58.3702],
                        [-63.4923, 58.3597],
                        [-63.5238, 58.3461],
                        [-63.5438, 58.3357],
                        [-63.5496, 58.3218],
                        [-63.555, 58.3175],
                        [-63.5858, 58.3078],
                        [-63.5916, 58.3045],
                        [-63.6, 58.2888],
                        [-63.5871, 58.2868],
                        [-63.5737, 58.2956],
                        [-63.5527, 58.2988],
                        [-63.5387, 58.3034],
                        [-63.5235, 58.3114],
                        [-63.5026, 58.3331],
                        [-63.4724, 58.3482],
                        [-63.4232, 58.3581],
                        [-63.4126, 58.3621],
                        [-63.3768, 58.3833],
                        [-63.3717, 58.3901],
                        [-63.3628, 58.3936],
                        [-63.3354, 58.4193],
                        [-63.3243, 58.4244],
                        [-63.3174, 58.4309],
                        [-63.288, 58.4433],
                        [-63.2859, 58.4499],
                        [-63.2662, 58.4599],
                        [-63.2363, 58.4643],
                        [-63.2224, 58.4638],
                        [-63.1908, 58.4679],
                        [-63.1704, 58.4743],
                        [-63.1552, 58.4729],
                        [-63.1319, 58.475],
                        [-63.0905, 58.4584],
                        [-63.0883, 58.452],
                        [-63.1021, 58.4392],
                        [-63.1153, 58.4138],
                        [-63.1489, 58.3965],
                        [-63.1643, 58.3853],
                        [-63.1711, 58.3724],
                        [-63.1656, 58.3604],
                        [-63.1454, 58.3731],
                        [-63.1248, 58.3904],
                        [-63.1078, 58.3986],
                        [-63.096, 58.4005],
                        [-63.0914, 58.3881],
                        [-63.0798, 58.4087],
                        [-63.0772, 58.4246],
                        [-63.0692, 58.443],
                        [-63.054, 58.4485],
                        [-63.0306, 58.4458],
                        [-63.0289, 58.438],
                        [-63.018, 58.4312],
                        [-63.0187, 58.4189],
                        [-63.0095, 58.4123],
                        [-62.9898, 58.4205],
                        [-62.9822, 58.4318],
                        [-62.9663, 58.4416],
                        [-62.9451, 58.4465],
                        [-62.9263, 58.4626],
                        [-62.9222, 58.4717],
                        [-62.9109, 58.4717],
                        [-62.903, 58.4649],
                        [-62.8882, 58.4647],
                        [-62.879, 58.4718],
                        [-62.865, 58.4716],
                        [-62.8389, 58.4778],
                        [-62.8287, 58.4659],
                        [-62.8198, 58.4621],
                        [-62.8189, 58.4758],
                        [-62.8153, 58.4853],
                        [-62.802, 58.4956],
                        [-62.7841, 58.4887],
                        [-62.7898, 58.4748],
                        [-62.7909, 58.4643],
                        [-62.7978, 58.4521],
                        [-62.7884, 58.4488],
                        [-62.781, 58.4548],
                        [-62.7645, 58.4869],
                        [-62.7493, 58.493],
                        [-62.7358, 58.4926],
                        [-62.7381, 58.4842],
                        [-62.7173, 58.4815],
                        [-62.7, 58.4862],
                        [-62.6744, 58.4832],
                        [-62.6635, 58.4837],
                        [-62.6482, 58.4953],
                        [-62.633, 58.5011],
                        [-62.6312, 58.4954],
                        [-62.6085, 58.4959],
                        [-62.5796, 58.4905],
                        [-62.5685, 58.4864],
                        [-62.5601, 58.4755],
                        [-62.574, 58.4591],
                        [-62.5833, 58.4568],
                        [-62.5975, 58.4472],
                        [-62.5947, 58.4367],
                        [-62.5973, 58.4312],
                        [-62.5899, 58.4254],
                        [-62.6167, 58.4277],
                        [-62.6416, 58.4218],
                        [-62.6481, 58.4034],
                        [-62.656, 58.4013],
                        [-62.6654, 58.4047],
                        [-62.6875, 58.406],
                        [-62.6994, 58.4044],
                        [-62.6959, 58.3975],
                        [-62.6687, 58.4011],
                        [-62.6515, 58.3907],
                        [-62.6324, 58.3844],
                        [-62.6182, 58.3752],
                        [-62.6194, 58.3652],
                        [-62.6117, 58.3623],
                        [-62.6348, 58.3433],
                        [-62.6183, 58.3432],
                        [-62.6189, 58.3284],
                        [-62.6268, 58.3208],
                        [-62.6084, 58.3106],
                        [-62.6081, 58.3081],
                        [-62.6247, 58.2951],
                        [-62.6323, 58.2914],
                        [-62.6446, 58.2967],
                        [-62.6729, 58.2949],
                        [-62.6835, 58.286],
                        [-62.6843, 58.281],
                        [-62.6989, 58.2822],
                        [-62.7067, 58.2773],
                        [-62.698, 58.2705],
                        [-62.6966, 58.2586],
                        [-62.7103, 58.255],
                        [-62.7504, 58.2591],
                        [-62.8027, 58.2624],
                        [-62.8111, 58.2589],
                        [-62.8335, 58.2611],
                        [-62.8584, 58.2583],
                        [-62.8661, 58.2544],
                        [-62.8808, 58.2336],
                        [-62.8812, 58.2274],
                        [-62.8902, 58.2153],
                        [-62.9041, 58.207],
                        [-62.9265, 58.2026],
                        [-62.9512, 58.1948],
                        [-62.9392, 58.1921],
                        [-62.9229, 58.1966],
                        [-62.9028, 58.1924],
                        [-62.8937, 58.1859],
                        [-62.8828, 58.201],
                        [-62.8733, 58.2083],
                        [-62.8667, 58.224],
                        [-62.8525, 58.2432],
                        [-62.8412, 58.2498],
                        [-62.8298, 58.2514],
                        [-62.8214, 58.2474],
                        [-62.7992, 58.2415],
                        [-62.7888, 58.2425],
                        [-62.7714, 58.2492],
                        [-62.7503, 58.2475],
                        [-62.7222, 58.2392],
                        [-62.7001, 58.2397],
                        [-62.6759, 58.2504],
                        [-62.6668, 58.263],
                        [-62.6506, 58.2662],
                        [-62.6481, 58.251],
                        [-62.6405, 58.2479],
                        [-62.6249, 58.2483],
                        [-62.6276, 58.2413],
                        [-62.6526, 58.2363],
                        [-62.6663, 58.2258],
                        [-62.6544, 58.2228],
                        [-62.6479, 58.2298],
                        [-62.6281, 58.2269],
                        [-62.6204, 58.219],
                        [-62.6062, 58.2434],
                        [-62.5908, 58.2341],
                        [-62.5797, 58.2246],
                        [-62.5877, 58.2163],
                        [-62.5833, 58.2052],
                        [-62.5925, 58.2029],
                        [-62.6038, 58.2088],
                        [-62.625, 58.2097],
                        [-62.6216, 58.1961],
                        [-62.6291, 58.1817],
                        [-62.6427, 58.179],
                        [-62.6425, 58.1691],
                        [-62.6574, 58.1658],
                        [-62.6664, 58.1609],
                        [-62.6905, 58.163],
                        [-62.7119, 58.167],
                        [-62.7328, 58.1642],
                        [-62.7469, 58.167],
                        [-62.7682, 58.1662],
                        [-62.7839, 58.1711],
                        [-62.7977, 58.1716],
                        [-62.8275, 58.165],
                        [-62.854, 58.1628],
                        [-62.8695, 58.164],
                        [-62.8864, 58.1574],
                        [-62.9008, 58.1545],
                        [-62.9338, 58.1521],
                        [-62.9771, 58.1512],
                        [-63.0647, 58.1547],
                        [-63.0551, 58.1495],
                        [-63.0696, 58.1447],
                        [-63.0441, 58.1379],
                        [-63.0109, 58.1351],
                        [-63.0068, 58.1323],
                        [-63.0276, 58.123],
                        [-63.0314, 58.11],
                        [-63.0359, 58.1065],
                        [-63.0729, 58.0938],
                        [-63.0849, 58.0961],
                        [-63.1049, 58.0856],
                        [-63.1347, 58.0841],
                        [-63.145, 58.0851],
                        [-63.1666, 58.0777],
                        [-63.1954, 58.0621],
                        [-63.2072, 58.0607],
                        [-63.2143, 58.056],
                        [-63.2323, 58.0588],
                        [-63.2317, 58.0522],
                        [-63.2111, 58.0514],
                        [-63.1934, 58.0443],
                        [-63.1826, 58.0444],
                        [-63.1554, 58.0535],
                        [-63.1379, 58.0649],
                        [-63.1321, 58.0607],
                        [-63.1486, 58.0502],
                        [-63.1464, 58.0438],
                        [-63.1491, 58.0331],
                        [-63.1443, 58.0189],
                        [-63.1516, 58.0112],
                        [-63.1659, 58.0108],
                        [-63.1807, 58.0066],
                        [-63.2079, 58.0059],
                        [-63.2202, 58.0082],
                        [-63.2505, 58.0063],
                        [-63.2888, 57.9973],
                        [-63.3073, 57.99],
                        [-63.35, 57.979],
                        [-63.3453, 57.9686],
                        [-63.3362, 57.9692],
                        [-63.3227, 57.9742],
                        [-63.3089, 57.9749],
                        [-63.2717, 57.9861],
                        [-63.2417, 57.9932],
                        [-63.2283, 57.9937],
                        [-63.2091, 57.9904],
                        [-63.1719, 57.9949],
                        [-63.1605, 57.9897],
                        [-63.1315, 57.9938],
                        [-63.1244, 57.9964],
                        [-63.1113, 58.0079],
                        [-63.1074, 58.0167],
                        [-63.1092, 58.0378],
                        [-63.0977, 58.0515],
                        [-63.091, 58.0642],
                        [-63.0856, 58.0668],
                        [-63.0644, 58.0695],
                        [-63.0376, 58.0693],
                        [-62.9947, 58.08],
                        [-62.9805, 58.0941],
                        [-62.9724, 58.1077],
                        [-62.9665, 58.1122],
                        [-62.947, 58.1203],
                        [-62.9271, 58.1209],
                        [-62.8824, 58.1306],
                        [-62.873, 58.1293],
                        [-62.8602, 58.1332],
                        [-62.8312, 58.1336],
                        [-62.829, 58.1271],
                        [-62.8426, 58.1171],
                        [-62.8523, 58.1152],
                        [-62.8444, 58.1091],
                        [-62.8204, 58.1142],
                        [-62.7987, 58.128],
                        [-62.7856, 58.1307],
                        [-62.7581, 58.1213],
                        [-62.7558, 58.1101],
                        [-62.7462, 58.1128],
                        [-62.7333, 58.1304],
                        [-62.7195, 58.1366],
                        [-62.6983, 58.1369],
                        [-62.6897, 58.1307],
                        [-62.6769, 58.128],
                        [-62.6602, 58.1124],
                        [-62.6516, 58.112],
                        [-62.6354, 58.1213],
                        [-62.6267, 58.1343],
                        [-62.6134, 58.1398],
                        [-62.5957, 58.1394],
                        [-62.5925, 58.154],
                        [-62.5765, 58.1546],
                        [-62.5724, 58.163],
                        [-62.563, 58.1614],
                        [-62.5681, 58.151],
                        [-62.5616, 58.1442],
                        [-62.5721, 58.1367],
                        [-62.5699, 58.1312],
                        [-62.5794, 58.1254],
                        [-62.563, 58.1182],
                        [-62.5536, 58.1107],
                        [-62.5387, 58.1086],
                        [-62.5275, 58.1132],
                        [-62.5224, 58.1192],
                        [-62.5332, 58.1306],
                        [-62.5408, 58.163],
                        [-62.5348, 58.1745],
                        [-62.5173, 58.1717],
                        [-62.5045, 58.1748],
                        [-62.484, 58.1712],
                        [-62.4727, 58.1657],
                        [-62.4569, 58.1684],
                        [-62.4441, 58.1658],
                        [-62.4486, 58.1524],
                        [-62.4577, 58.146],
                        [-62.4601, 58.1376],
                        [-62.4753, 58.1308],
                        [-62.4819, 58.1232],
                        [-62.5005, 58.1165],
                        [-62.5154, 58.1083],
                        [-62.5224, 58.101],
                        [-62.5145, 58.0843],
                        [-62.5039, 58.0764],
                        [-62.5055, 58.0654],
                        [-62.5214, 58.0543],
                        [-62.5032, 58.056],
                        [-62.4919, 58.0626],
                        [-62.4896, 58.0708],
                        [-62.5035, 58.0928],
                        [-62.4915, 58.1037],
                        [-62.4824, 58.104],
                        [-62.4656, 58.0982],
                        [-62.4353, 58.1042],
                        [-62.4122, 58.1009],
                        [-62.3948, 58.1026],
                        [-62.3729, 58.1019],
                        [-62.3663, 58.092],
                        [-62.3713, 58.0882],
                        [-62.3959, 58.0885],
                        [-62.4149, 58.0768],
                        [-62.4105, 58.0721],
                        [-62.3758, 58.0746],
                        [-62.3698, 58.068],
                        [-62.3984, 58.0556],
                        [-62.4038, 58.0418],
                        [-62.4024, 58.0368],
                        [-62.3787, 58.0374],
                        [-62.3733, 58.04],
                        [-62.3689, 58.0523],
                        [-62.3497, 58.0553],
                        [-62.341, 58.0613],
                        [-62.331, 58.0569],
                        [-62.3298, 58.051],
                        [-62.3119, 58.0426],
                        [-62.3097, 58.0313],
                        [-62.3189, 58.0235],
                        [-62.3203, 58.0169],
                        [-62.3444, 58.0179],
                        [-62.3604, 58.0057],
                        [-62.3651, 57.9951],
                        [-62.3777, 57.9878],
                        [-62.4002, 57.9913],
                        [-62.4123, 57.9856],
                        [-62.4334, 57.9858],
                        [-62.4524, 57.9901],
                        [-62.4748, 57.9903],
                        [-62.4983, 57.9931],
                        [-62.5364, 57.9902],
                        [-62.5744, 57.9818],
                        [-62.5929, 57.9758],
                        [-62.6108, 57.9602],
                        [-62.6238, 57.9524],
                        [-62.6395, 57.9495],
                        [-62.6674, 57.9503],
                        [-62.6774, 57.9286],
                        [-62.6729, 57.9187],
                        [-62.6629, 57.921],
                        [-62.66, 57.9264],
                        [-62.6488, 57.9328],
                        [-62.6427, 57.9405],
                        [-62.624, 57.9369],
                        [-62.5994, 57.9444],
                        [-62.5595, 57.9674],
                        [-62.5329, 57.9671],
                        [-62.5057, 57.9731],
                        [-62.4898, 57.9675],
                        [-62.47, 57.9674],
                        [-62.4547, 57.9648],
                        [-62.4449, 57.9575],
                        [-62.4245, 57.955],
                        [-62.429, 57.935],
                        [-62.4235, 57.9278],
                        [-62.4251, 57.9123],
                        [-62.4213, 57.8878],
                        [-62.4175, 57.876],
                        [-62.4365, 57.8627],
                        [-62.4283, 57.8573],
                        [-62.4141, 57.8579],
                        [-62.4053, 57.8609],
                        [-62.3914, 57.8743],
                        [-62.3912, 57.878],
                        [-62.4038, 57.8924],
                        [-62.4059, 57.8991],
                        [-62.3995, 57.9104],
                        [-62.3873, 57.9238],
                        [-62.3935, 57.9376],
                        [-62.3918, 57.9436],
                        [-62.3732, 57.9564],
                        [-62.354, 57.9651],
                        [-62.3427, 57.9726],
                        [-62.3295, 57.9705],
                        [-62.3433, 57.9644],
                        [-62.3392, 57.9515],
                        [-62.3255, 57.9448],
                        [-62.3349, 57.9341],
                        [-62.3239, 57.9094],
                        [-62.3472, 57.8916],
                        [-62.3289, 57.8947],
                        [-62.3053, 57.906],
                        [-62.3028, 57.9268],
                        [-62.29, 57.9415],
                        [-62.266, 57.9412],
                        [-62.2634, 57.9357],
                        [-62.2395, 57.9226],
                        [-62.2268, 57.9207],
                        [-62.2263, 57.9113],
                        [-62.2204, 57.9098],
                        [-62.2199, 57.9],
                        [-62.2076, 57.9027],
                        [-62.2138, 57.9172],
                        [-62.2034, 57.9332],
                        [-62.1871, 57.9385],
                        [-62.1676, 57.9506],
                        [-62.164, 57.9636],
                        [-62.1457, 57.9716],
                        [-62.1427, 57.9768],
                        [-62.1304, 57.9738],
                        [-62.1483, 57.9607],
                        [-62.1416, 57.958],
                        [-62.1269, 57.9645],
                        [-62.1229, 57.9551],
                        [-62.0921, 57.9381],
                        [-62.086, 57.9288],
                        [-62.0858, 57.9105],
                        [-62.072, 57.8862],
                        [-62.0733, 57.881],
                        [-62.085, 57.8746],
                        [-62.084, 57.8662],
                        [-62.0654, 57.873],
                        [-62.0531, 57.881],
                        [-62.0395, 57.8996],
                        [-62.0264, 57.9027],
                        [-62.014, 57.896],
                        [-62.005, 57.9008],
                        [-62.0083, 57.9077],
                        [-62, 57.9142],
                        [-61.9879, 57.9146],
                        [-61.978, 57.91],
                        [-61.9665, 57.919],
                        [-61.9549, 57.9144],
                        [-61.9453, 57.9155],
                        [-61.9286, 57.9103],
                        [-61.9255, 57.897],
                        [-61.9505, 57.8766],
                        [-61.9573, 57.8674],
                        [-61.942, 57.8654],
                        [-61.9102, 57.8758],
                        [-61.8965, 57.8755],
                        [-61.8857, 57.8711],
                        [-61.8714, 57.8533],
                        [-61.8678, 57.8416],
                        [-61.8788, 57.8339],
                        [-61.8854, 57.8071],
                        [-61.8898, 57.8012],
                        [-61.9102, 57.7933],
                        [-61.9218, 57.7927],
                        [-61.9381, 57.7787],
                        [-61.946, 57.7757],
                        [-61.9599, 57.779],
                        [-61.9629, 57.7882],
                        [-61.9714, 57.7939],
                        [-61.9873, 57.7932],
                        [-62.0069, 57.8042],
                        [-62.0186, 57.8079],
                        [-62.0279, 57.8143],
                        [-62.0423, 57.8115],
                        [-62.0619, 57.8327],
                        [-62.076, 57.8239],
                        [-62.0913, 57.8252],
                        [-62.0796, 57.8158],
                        [-62.0827, 57.8104],
                        [-62.0763, 57.7997],
                        [-62.1142, 57.8068],
                        [-62.122, 57.8115],
                        [-62.1313, 57.8227],
                        [-62.1281, 57.8311],
                        [-62.1173, 57.8366],
                        [-62.1134, 57.8427],
                        [-62.0982, 57.8503],
                        [-62.0886, 57.8622],
                        [-62.0972, 57.8672],
                        [-62.1124, 57.8547],
                        [-62.1552, 57.8345],
                        [-62.1546, 57.826],
                        [-62.1384, 57.8202],
                        [-62.1341, 57.8155],
                        [-62.1467, 57.8083],
                        [-62.1313, 57.8019],
                        [-62.1086, 57.787],
                        [-62.1003, 57.7836],
                        [-62.0684, 57.781],
                        [-62.0391, 57.7892],
                        [-62.0221, 57.7877],
                        [-62.0157, 57.7825],
                        [-62.0086, 57.77],
                        [-61.9895, 57.7581],
                        [-61.998, 57.7439],
                        [-62.0084, 57.738],
                        [-61.9827, 57.7378],
                        [-61.9674, 57.7319],
                        [-61.9602, 57.7212],
                        [-61.9507, 57.7123],
                        [-61.9517, 57.7009],
                        [-61.9643, 57.6896],
                        [-61.9898, 57.6907],
                        [-61.9711, 57.6801],
                        [-61.9592, 57.6785],
                        [-61.9435, 57.6632],
                        [-61.9319, 57.6739],
                        [-61.9221, 57.6782],
                        [-61.9064, 57.6771],
                        [-61.9005, 57.6724],
                        [-61.9039, 57.667],
                        [-61.9189, 57.666],
                        [-61.9132, 57.6588],
                        [-61.8997, 57.6568],
                        [-61.8892, 57.6497],
                        [-61.9027, 57.6418],
                        [-61.8965, 57.6307],
                        [-61.89, 57.6264],
                        [-61.8966, 57.6197],
                        [-61.8984, 57.6091],
                        [-61.9106, 57.6153],
                        [-61.9286, 57.6144],
                        [-61.9399, 57.6163],
                        [-61.9514, 57.6116],
                        [-61.9492, 57.6033],
                        [-61.9787, 57.6063],
                        [-61.9861, 57.6051],
                        [-62.0048, 57.596],
                        [-62.0091, 57.5995],
                        [-61.9968, 57.6097],
                        [-62.0038, 57.6122],
                        [-62.0202, 57.6041],
                        [-62.0298, 57.5966],
                        [-62.0479, 57.5895],
                        [-62.073, 57.5842],
                        [-62.0821, 57.5778],
                        [-62.0951, 57.5744],
                        [-62.0846, 57.568],
                        [-62.0914, 57.5583],
                        [-62.0868, 57.5516],
                        [-62.0981, 57.5391],
                        [-62.1193, 57.5286],
                        [-62.1288, 57.5273],
                        [-62.1395, 57.5204],
                        [-62.174, 57.5197],
                        [-62.1859, 57.525],
                        [-62.1979, 57.5348],
                        [-62.2262, 57.5426],
                        [-62.2648, 57.5382],
                        [-62.284, 57.532],
                        [-62.2748, 57.5243],
                        [-62.276, 57.5195],
                        [-62.2862, 57.5138],
                        [-62.2947, 57.5033],
                        [-62.3017, 57.5002],
                        [-62.305, 57.4911],
                        [-62.3152, 57.485],
                        [-62.3268, 57.4727],
                        [-62.321, 57.4646],
                        [-62.3357, 57.456],
                        [-62.3519, 57.4547],
                        [-62.3485, 57.4494],
                        [-62.3581, 57.4448],
                        [-62.367, 57.4457],
                        [-62.3956, 57.46],
                        [-62.4042, 57.4696],
                        [-62.4143, 57.4762],
                        [-62.4348, 57.4807],
                        [-62.4471, 57.4751],
                        [-62.4575, 57.4828],
                        [-62.482, 57.4847],
                        [-62.5084, 57.5024],
                        [-62.5231, 57.5045],
                        [-62.5333, 57.5034],
                        [-62.5447, 57.5114],
                        [-62.5547, 57.502],
                        [-62.5472, 57.4934],
                        [-62.5223, 57.4846],
                        [-62.5258, 57.4783],
                        [-62.5038, 57.4763],
                        [-62.4854, 57.461],
                        [-62.476, 57.456],
                        [-62.4634, 57.4553],
                        [-62.4587, 57.4493],
                        [-62.439, 57.4459],
                        [-62.4282, 57.4495],
                        [-62.4161, 57.4413],
                        [-62.3959, 57.4329],
                        [-62.3895, 57.4224],
                        [-62.3686, 57.4186],
                        [-62.3491, 57.4181],
                        [-62.3311, 57.4211],
                        [-62.2889, 57.4357],
                        [-62.2596, 57.4421],
                        [-62.2479, 57.4391],
                        [-62.2273, 57.4373],
                        [-62.2148, 57.4448],
                        [-62.1973, 57.4631],
                        [-62.1899, 57.4654],
                        [-62.1743, 57.4639],
                        [-62.1472, 57.4573],
                        [-62.1036, 57.452],
                        [-62.0895, 57.4523],
                        [-62.0646, 57.4565],
                        [-62.0579, 57.4559],
                        [-62.0244, 57.4397],
                        [-61.9548, 57.4263],
                        [-61.9388, 57.4156],
                        [-61.9269, 57.411],
                        [-61.8978, 57.4043],
                        [-61.8784, 57.3944],
                        [-61.8849, 57.3825],
                        [-61.8652, 57.3809],
                        [-61.8548, 57.3781],
                        [-61.8364, 57.3806],
                        [-61.8272, 57.3748],
                        [-61.8068, 57.3709],
                        [-61.8035, 57.3651],
                        [-61.8086, 57.356],
                        [-61.8287, 57.349],
                        [-61.8573, 57.3503],
                        [-61.9032, 57.3578],
                        [-61.931, 57.3588],
                        [-61.9105, 57.3468],
                        [-61.8863, 57.3432],
                        [-61.8707, 57.3282],
                        [-61.8917, 57.3107],
                        [-61.8818, 57.3031],
                        [-61.884, 57.297],
                        [-61.8705, 57.2923],
                        [-61.8595, 57.2786],
                        [-61.8718, 57.2732],
                        [-61.8888, 57.2617],
                        [-61.9004, 57.2648],
                        [-61.9196, 57.252],
                        [-61.953, 57.2424],
                        [-61.972, 57.2468],
                        [-61.9985, 57.2479],
                        [-62.0317, 57.259],
                        [-62.0389, 57.2532],
                        [-62.0234, 57.2474],
                        [-62.0256, 57.2408],
                        [-62.0163, 57.2259],
                        [-62.0072, 57.2248],
                        [-61.989, 57.2303],
                        [-61.9545, 57.2246],
                        [-61.9583, 57.2194],
                        [-61.9797, 57.2159],
                        [-61.9809, 57.2113],
                        [-61.9674, 57.2019],
                        [-61.9569, 57.211],
                        [-61.9478, 57.2137],
                        [-61.9076, 57.2115],
                        [-61.8973, 57.1991],
                        [-61.8895, 57.1964],
                        [-61.8832, 57.1871],
                        [-61.8696, 57.1747],
                        [-61.8685, 57.1628],
                        [-61.8436, 57.1639],
                        [-61.842, 57.1719],
                        [-61.8312, 57.18],
                        [-61.8162, 57.1742],
                        [-61.8108, 57.1825],
                        [-61.7965, 57.1912],
                        [-61.7836, 57.1941],
                        [-61.7706, 57.1889],
                        [-61.7769, 57.185],
                        [-61.7803, 57.1705],
                        [-61.7894, 57.1625],
                        [-61.791, 57.1493],
                        [-61.7779, 57.1457],
                        [-61.7711, 57.1606],
                        [-61.7555, 57.1652],
                        [-61.7458, 57.1789],
                        [-61.7204, 57.1896],
                        [-61.7005, 57.2048],
                        [-61.6797, 57.2157],
                        [-61.6624, 57.2294],
                        [-61.6343, 57.2318],
                        [-61.6452, 57.2236],
                        [-61.6349, 57.2165],
                        [-61.6301, 57.2093],
                        [-61.6531, 57.2117],
                        [-61.6686, 57.2028],
                        [-61.697, 57.17],
                        [-61.7222, 57.1563],
                        [-61.7214, 57.1471],
                        [-61.7099, 57.1489],
                        [-61.7029, 57.1432],
                        [-61.6829, 57.1411],
                        [-61.6708, 57.1439],
                        [-61.6514, 57.1397],
                        [-61.6328, 57.1499],
                        [-61.6181, 57.1545],
                        [-61.5943, 57.1527],
                        [-61.5793, 57.1592],
                        [-61.5766, 57.1531],
                        [-61.5667, 57.1501],
                        [-61.5387, 57.1573],
                        [-61.5239, 57.1532],
                        [-61.514, 57.1561],
                        [-61.4777, 57.1569],
                        [-61.465, 57.1497],
                        [-61.4638, 57.1444],
                        [-61.4125, 57.1403],
                        [-61.3893, 57.1282],
                        [-61.3801, 57.1156],
                        [-61.3844, 57.1102],
                        [-61.3816, 57.1047],
                        [-61.3621, 57.0938],
                        [-61.3661, 57.0795],
                        [-61.3776, 57.0698],
                        [-61.3723, 57.06],
                        [-61.3834, 57.0462],
                        [-61.3781, 57.0399],
                        [-61.3594, 57.0308],
                        [-61.3568, 57.0193],
                        [-61.3623, 57.0101],
                        [-61.3812, 56.9936],
                        [-61.3762, 56.9852],
                        [-61.3615, 56.9854],
                        [-61.3509, 56.9911],
                        [-61.3438, 56.9824],
                        [-61.3527, 56.9813],
                        [-61.3634, 56.9745],
                        [-61.3639, 56.9704],
                        [-61.3523, 56.9648],
                        [-61.3506, 56.957],
                        [-61.3383, 56.9539],
                        [-61.3477, 56.9441],
                        [-61.3586, 56.9442],
                        [-61.3672, 56.9541],
                        [-61.3763, 56.9594],
                        [-61.378, 56.9752],
                        [-61.3976, 56.9763],
                        [-61.4256, 56.9689],
                        [-61.4455, 56.9702],
                        [-61.4621, 56.9761],
                        [-61.4819, 56.979],
                        [-61.4948, 56.9709],
                        [-61.5093, 56.9688],
                        [-61.5297, 56.9617],
                        [-61.538, 56.9624],
                        [-61.5621, 56.9466],
                        [-61.5586, 56.9308],
                        [-61.5516, 56.9258],
                        [-61.5578, 56.9218],
                        [-61.5758, 56.9176],
                        [-61.5692, 56.9078],
                        [-61.5799, 56.901],
                        [-61.6159, 56.9],
                        [-61.6257, 56.8939],
                        [-61.6186, 56.8891],
                        [-61.6237, 56.8833],
                        [-61.642, 56.8795],
                        [-61.6529, 56.8748],
                        [-61.6594, 56.8637],
                        [-61.6525, 56.853],
                        [-61.6566, 56.8486],
                        [-61.6578, 56.8276],
                        [-61.6529, 56.8218],
                        [-61.6561, 56.8049],
                        [-61.6826, 56.7977],
                        [-61.6927, 56.7927],
                        [-61.7219, 56.8054],
                        [-61.7382, 56.8002],
                        [-61.7484, 56.794],
                        [-61.7741, 56.7904],
                        [-61.7965, 56.7819],
                        [-61.8183, 56.785],
                        [-61.8392, 56.7842],
                        [-61.8896, 56.8036],
                        [-61.905, 56.804],
                        [-61.9245, 56.7949],
                        [-61.9231, 56.7818],
                        [-61.9102, 56.7724],
                        [-61.907, 56.7663],
                        [-61.9132, 56.7569],
                        [-61.9007, 56.7477],
                        [-61.9015, 56.7376],
                        [-61.9103, 56.7324],
                        [-61.9151, 56.7101],
                        [-61.9128, 56.6991],
                        [-61.9019, 56.7002],
                        [-61.8918, 56.7104],
                        [-61.8774, 56.7187],
                        [-61.8901, 56.7257],
                        [-61.8905, 56.7303],
                        [-61.8804, 56.7417],
                        [-61.877, 56.7523],
                        [-61.8656, 56.7586],
                        [-61.8408, 56.7573],
                        [-61.8278, 56.7583],
                        [-61.8191, 56.7524],
                        [-61.793, 56.7514],
                        [-61.7803, 56.7545],
                        [-61.7613, 56.7476],
                        [-61.741, 56.7475],
                        [-61.724, 56.7372],
                        [-61.7124, 56.7325],
                        [-61.7183, 56.7265],
                        [-61.734, 56.7283],
                        [-61.7436, 56.7318],
                        [-61.7471, 56.7255],
                        [-61.7263, 56.7217],
                        [-61.71, 56.7222],
                        [-61.6994, 56.7196],
                        [-61.7056, 56.7072],
                        [-61.7162, 56.6997],
                        [-61.7437, 56.6893],
                        [-61.7537, 56.6894],
                        [-61.7894, 56.6992],
                        [-61.7989, 56.7048],
                        [-61.818, 56.7087],
                        [-61.832, 56.7063],
                        [-61.8266, 56.7009],
                        [-61.8073, 56.6981],
                        [-61.8035, 56.6942],
                        [-61.8118, 56.6857],
                        [-61.7998, 56.6799],
                        [-61.7883, 56.6837],
                        [-61.7664, 56.6822],
                        [-61.7815, 56.6666],
                        [-61.7767, 56.6557],
                        [-61.7896, 56.6549],
                        [-61.818, 56.658],
                        [-61.8262, 56.6493],
                        [-61.8204, 56.6444],
                        [-61.8117, 56.6444],
                        [-61.8078, 56.6506],
                        [-61.7949, 56.6519],
                        [-61.7656, 56.6449],
                        [-61.7669, 56.6532],
                        [-61.7644, 56.6721],
                        [-61.7516, 56.6758],
                        [-61.7445, 56.6722],
                        [-61.7446, 56.6622],
                        [-61.7207, 56.6551],
                        [-61.6924, 56.652],
                        [-61.6683, 56.6523],
                        [-61.6656, 56.6458],
                        [-61.6547, 56.6418],
                        [-61.6617, 56.6336],
                        [-61.6723, 56.6314],
                        [-61.6786, 56.6261],
                        [-61.6773, 56.6178],
                        [-61.6858, 56.6107],
                        [-61.719, 56.6149],
                        [-61.7445, 56.6256],
                        [-61.7715, 56.6254],
                        [-61.7868, 56.6208],
                        [-61.7971, 56.622],
                        [-61.8165, 56.6278],
                        [-61.8438, 56.6235],
                        [-61.8702, 56.6254],
                        [-61.8831, 56.6291],
                        [-61.8991, 56.6366],
                        [-61.9326, 56.6378],
                        [-61.9506, 56.6396],
                        [-61.967, 56.6473],
                        [-61.9642, 56.6514],
                        [-61.9316, 56.6587],
                        [-61.9194, 56.6655],
                        [-61.9032, 56.6708],
                        [-61.9014, 56.6771],
                        [-61.9182, 56.6799],
                        [-61.9285, 56.6751],
                        [-61.9593, 56.6695],
                        [-61.9948, 56.6572],
                        [-62.0048, 56.6563],
                        [-62.0818, 56.679],
                        [-62.0953, 56.6809],
                        [-62.1177, 56.6815],
                        [-62.1242, 56.689],
                        [-62.1499, 56.6999],
                        [-62.1786, 56.6996],
                        [-62.2041, 56.7021],
                        [-62.2236, 56.7023],
                        [-62.2771, 56.7141],
                        [-62.2916, 56.7203],
                        [-62.3176, 56.7232],
                        [-62.3363, 56.7337],
                        [-62.3555, 56.7391],
                        [-62.3793, 56.7429],
                        [-62.4179, 56.7444],
                        [-62.4857, 56.7614],
                        [-62.5048, 56.762],
                        [-62.5125, 56.7643],
                        [-62.5254, 56.7581],
                        [-62.4962, 56.7534],
                        [-62.475, 56.7479],
                        [-62.4488, 56.7355],
                        [-62.435, 56.7311],
                        [-62.4052, 56.7295],
                        [-62.374, 56.7249],
                        [-62.3684, 56.7216],
                        [-62.3682, 56.7127],
                        [-62.3383, 56.7072],
                        [-62.327, 56.7022],
                        [-62.3188, 56.7063],
                        [-62.313, 56.7007],
                        [-62.2889, 56.7009],
                        [-62.2611, 56.6961],
                        [-62.2651, 56.6916],
                        [-62.2426, 56.6881],
                        [-62.2235, 56.6884],
                        [-62.2093, 56.6832],
                        [-62.1875, 56.6804],
                        [-62.1719, 56.684],
                        [-62.1558, 56.6781],
                        [-62.1421, 56.6771],
                        [-62.1309, 56.6672],
                        [-62.1082, 56.6607],
                        [-62.1177, 56.6559],
                        [-62.1198, 56.6505],
                        [-62.1037, 56.6499],
                        [-62.0952, 56.6471],
                        [-62.0666, 56.6311],
                        [-62.0462, 56.6224],
                        [-62.0206, 56.6215],
                        [-61.9915, 56.6327],
                        [-61.978, 56.6346],
                        [-61.9532, 56.6337],
                        [-61.9365, 56.6255],
                        [-61.922, 56.6261],
                        [-61.9215, 56.6165],
                        [-61.9122, 56.6144],
                        [-61.9068, 56.609],
                        [-61.9189, 56.6031],
                        [-61.9564, 56.6055],
                        [-61.966, 56.6046],
                        [-61.9853, 56.6078],
                        [-62.014, 56.6159],
                        [-62.0361, 56.6137],
                        [-62.0399, 56.6085],
                        [-62.0526, 56.6146],
                        [-62.093, 56.6172],
                        [-62.1323, 56.6136],
                        [-62.185, 56.6176],
                        [-62.1927, 56.6196],
                        [-62.2094, 56.6184],
                        [-62.2159, 56.6149],
                        [-62.2331, 56.6174],
                        [-62.2501, 56.6123],
                        [-62.2333, 56.6035],
                        [-62.2104, 56.6009],
                        [-62.1831, 56.5993],
                        [-62.1665, 56.5998],
                        [-62.1509, 56.5981],
                        [-62.1393, 56.593],
                        [-62.0766, 56.5953],
                        [-62.0624, 56.5913],
                        [-62.0002, 56.5874],
                        [-61.969, 56.58],
                        [-61.9627, 56.576],
                        [-61.9406, 56.5731],
                        [-61.9111, 56.5787],
                        [-61.9076, 56.586],
                        [-61.8782, 56.5904],
                        [-61.8636, 56.5864],
                        [-61.8517, 56.5856],
                        [-61.8448, 56.5797],
                        [-61.8264, 56.5767],
                        [-61.8121, 56.5697],
                        [-61.7866, 56.5628],
                        [-61.7833, 56.5538],
                        [-61.7644, 56.5574],
                        [-61.7617, 56.5638],
                        [-61.7662, 56.5694],
                        [-61.7305, 56.5702],
                        [-61.7195, 56.5637],
                        [-61.7024, 56.5603],
                        [-61.7178, 56.5552],
                        [-61.7049, 56.5476],
                        [-61.6878, 56.5484],
                        [-61.6953, 56.5391],
                        [-61.6877, 56.5315],
                        [-61.6756, 56.5374],
                        [-61.6553, 56.5375],
                        [-61.6474, 56.5288],
                        [-61.6474, 56.5208],
                        [-61.6675, 56.5177],
                        [-61.6628, 56.5059],
                        [-61.6694, 56.5011],
                        [-61.6982, 56.5033],
                        [-61.7128, 56.506],
                        [-61.7141, 56.5003],
                        [-61.708, 56.4956],
                        [-61.7263, 56.4895],
                        [-61.7425, 56.4883],
                        [-61.7546, 56.4909],
                        [-61.7612, 56.4866],
                        [-61.7858, 56.4802],
                        [-61.8049, 56.4834],
                        [-61.8132, 56.4875],
                        [-61.8289, 56.487],
                        [-61.8603, 56.492],
                        [-61.8711, 56.4962],
                        [-61.8804, 56.4941],
                        [-61.8776, 56.4879],
                        [-61.8982, 56.4889],
                        [-61.9023, 56.4938],
                        [-61.9218, 56.4968],
                        [-61.9383, 56.497],
                        [-61.9369, 56.5041],
                        [-61.946, 56.5137],
                        [-61.9672, 56.5172],
                        [-61.9852, 56.525],
                        [-62.0008, 56.526],
                        [-62.0141, 56.5298],
                        [-62.0261, 56.5251],
                        [-62.0204, 56.514],
                        [-62.0068, 56.5132],
                        [-61.9914, 56.5094],
                        [-61.9721, 56.513],
                        [-61.9597, 56.5083],
                        [-61.9617, 56.5047],
                        [-61.9493, 56.4991],
                        [-61.9451, 56.4908],
                        [-61.9644, 56.4922],
                        [-61.9839, 56.5007],
                        [-62.0014, 56.4987],
                        [-62.0353, 56.4984],
                        [-62.0557, 56.5021],
                        [-62.077, 56.5035],
                        [-62.0985, 56.4933],
                        [-62.1021, 56.485],
                        [-62.1158, 56.4789],
                        [-62.1361, 56.4826],
                        [-62.1374, 56.4767],
                        [-62.1212, 56.4736],
                        [-62.1088, 56.4735],
                        [-62.0859, 56.4805],
                        [-62.0768, 56.4809],
                        [-62.0615, 56.4767],
                        [-62.0483, 56.4766],
                        [-62.036, 56.4799],
                        [-62.0164, 56.4824],
                        [-62.0048, 56.4775],
                        [-61.9872, 56.4757],
                        [-61.9668, 56.4834],
                        [-61.9528, 56.4673],
                        [-61.9383, 56.4534],
                        [-61.9592, 56.4551],
                        [-62.0023, 56.4478],
                        [-62.047, 56.4469],
                        [-62.068, 56.4515],
                        [-62.0879, 56.4609],
                        [-62.1164, 56.4605],
                        [-62.1295, 56.4567],
                        [-62.171, 56.4588],
                        [-62.186, 56.4631],
                        [-62.1987, 56.4691],
                        [-62.2238, 56.4599],
                        [-62.2094, 56.4588],
                        [-62.1798, 56.4517],
                        [-62.1629, 56.4525],
                        [-62.1463, 56.4427],
                        [-62.1347, 56.4381],
                        [-62.1355, 56.4331],
                        [-62.1233, 56.4314],
                        [-62.1098, 56.4247],
                        [-62.0917, 56.4242],
                        [-62.0903, 56.4158],
                        [-62.076, 56.4099],
                        [-62.0472, 56.4238],
                        [-62.017, 56.4153],
                        [-62.001, 56.4142],
                        [-61.986, 56.4069],
                        [-61.9773, 56.4072],
                        [-61.9705, 56.4139],
                        [-61.951, 56.4204],
                        [-61.9676, 56.4245],
                        [-61.9685, 56.428],
                        [-61.9592, 56.4359],
                        [-61.9415, 56.4352],
                        [-61.9352, 56.4371],
                        [-61.9178, 56.433],
                        [-61.9318, 56.4279],
                        [-61.9257, 56.4232],
                        [-61.9241, 56.4122],
                        [-61.9008, 56.4104],
                        [-61.8827, 56.4054],
                        [-61.8626, 56.4037],
                        [-61.8232, 56.4022],
                        [-61.8201, 56.3952],
                        [-61.7978, 56.3912],
                        [-61.7786, 56.3804],
                        [-61.7892, 56.3766],
                        [-61.811, 56.3767],
                        [-61.8235, 56.38],
                        [-61.8633, 56.3818],
                        [-61.8627, 56.379],
                        [-61.844, 56.3767],
                        [-61.8379, 56.366],
                        [-61.8262, 56.3678],
                        [-61.8071, 56.3647],
                        [-61.7936, 56.3594],
                        [-61.7731, 56.3545],
                        [-61.7843, 56.3488],
                        [-61.7834, 56.3449],
                        [-61.8313, 56.3424],
                        [-61.8495, 56.3465],
                        [-61.8695, 56.3484],
                        [-61.8946, 56.3431],
                        [-61.9139, 56.3441],
                        [-61.9231, 56.3466],
                        [-61.9255, 56.3378],
                        [-61.8852, 56.3381],
                        [-61.8707, 56.3338],
                        [-61.8622, 56.3372],
                        [-61.8336, 56.3351],
                        [-61.8125, 56.3366],
                        [-61.8082, 56.3298],
                        [-61.7844, 56.3381],
                        [-61.7781, 56.3345],
                        [-61.7542, 56.3352],
                        [-61.7481, 56.3387],
                        [-61.7362, 56.3393],
                        [-61.7367, 56.3331],
                        [-61.7479, 56.327],
                        [-61.7796, 56.3272],
                        [-61.7998, 56.3219],
                        [-61.7946, 56.3153],
                        [-61.7679, 56.3159],
                        [-61.7612, 56.3219],
                        [-61.7502, 56.3157],
                        [-61.7245, 56.3138],
                        [-61.7043, 56.3187],
                        [-61.6944, 56.3143],
                        [-61.6746, 56.3147],
                        [-61.6588, 56.3174],
                        [-61.6544, 56.3114],
                        [-61.6317, 56.3039],
                        [-61.6369, 56.3004],
                        [-61.6569, 56.3019],
                        [-61.6696, 56.2977],
                        [-61.6727, 56.2911],
                        [-61.653, 56.2906],
                        [-61.6427, 56.2955],
                        [-61.6293, 56.289],
                        [-61.6209, 56.2963],
                        [-61.6069, 56.2961],
                        [-61.6033, 56.2907],
                        [-61.591, 56.2858],
                        [-61.5915, 56.2803],
                        [-61.5819, 56.278],
                        [-61.6163, 56.2706],
                        [-61.6375, 56.2723],
                        [-61.7072, 56.2869],
                        [-61.7635, 56.2914],
                        [-61.7762, 56.2956],
                        [-61.7903, 56.2951],
                        [-61.7922, 56.2869],
                        [-61.7454, 56.2872],
                        [-61.7321, 56.2844],
                        [-61.7281, 56.2779],
                        [-61.7098, 56.2767],
                        [-61.7001, 56.2791],
                        [-61.701, 56.2696],
                        [-61.676, 56.2686],
                        [-61.6645, 56.2644],
                        [-61.6711, 56.2591],
                        [-61.6911, 56.2556],
                        [-61.7058, 56.2482],
                        [-61.729, 56.2457],
                        [-61.74, 56.2421],
                        [-61.7724, 56.2417],
                        [-61.7791, 56.2446],
                        [-61.7977, 56.2437],
                        [-61.8156, 56.2454],
                        [-61.828, 56.2535],
                        [-61.8454, 56.256],
                        [-61.8465, 56.2626],
                        [-61.8284, 56.2635],
                        [-61.8162, 56.2616],
                        [-61.7997, 56.2536],
                        [-61.7892, 56.2556],
                        [-61.7906, 56.2629],
                        [-61.7858, 56.2715],
                        [-61.7569, 56.2732],
                        [-61.7783, 56.282],
                        [-61.7903, 56.2805],
                        [-61.7986, 56.2848],
                        [-61.8123, 56.2779],
                        [-61.8513, 56.2785],
                        [-61.881, 56.2866],
                        [-61.8899, 56.2928],
                        [-61.9066, 56.2946],
                        [-61.9102, 56.291],
                        [-61.9265, 56.2871],
                        [-61.9304, 56.2806],
                        [-61.9468, 56.2712],
                        [-61.9831, 56.2733],
                        [-62.0024, 56.2784],
                        [-62.0158, 56.2908],
                        [-62.0264, 56.2957],
                        [-62.0536, 56.3],
                        [-62.0659, 56.291],
                        [-62.0766, 56.2903],
                        [-62.0863, 56.2974],
                        [-62.1008, 56.29],
                        [-62.0819, 56.285],
                        [-62.0619, 56.2738],
                        [-62.0648, 56.2683],
                        [-62.0587, 56.2625],
                        [-62.0474, 56.2601],
                        [-62.0402, 56.2531],
                        [-62.0232, 56.2451],
                        [-62.0079, 56.2473],
                        [-62.0016, 56.2437],
                        [-62.0159, 56.2395],
                        [-62.0356, 56.2393],
                        [-62.0518, 56.2372],
                        [-62.0269, 56.2304],
                        [-62.0469, 56.2216],
                        [-62.0381, 56.2181],
                        [-62.0222, 56.2223],
                        [-61.9888, 56.2196],
                        [-61.974, 56.2151],
                        [-61.9572, 56.2156],
                        [-61.9534, 56.2114],
                        [-61.9387, 56.211],
                        [-61.9156, 56.2076],
                        [-61.9061, 56.214],
                        [-61.9012, 56.2232],
                        [-61.8847, 56.2292],
                        [-61.87, 56.2281],
                        [-61.8622, 56.2233],
                        [-61.8706, 56.2169],
                        [-61.8899, 56.2165],
                        [-61.8862, 56.2117],
                        [-61.8619, 56.2133],
                        [-61.8169, 56.2116],
                        [-61.7876, 56.2186],
                        [-61.7733, 56.2184],
                        [-61.7829, 56.207],
                        [-61.8027, 56.2012],
                        [-61.8078, 56.1945],
                        [-61.7927, 56.1888],
                        [-61.7677, 56.1931],
                        [-61.7623, 56.2035],
                        [-61.7375, 56.206],
                        [-61.7323, 56.1956],
                        [-61.7359, 56.1774],
                        [-61.7243, 56.1798],
                        [-61.719, 56.2114],
                        [-61.7113, 56.2148],
                        [-61.6861, 56.2212],
                        [-61.6673, 56.22],
                        [-61.6528, 56.2127],
                        [-61.6176, 56.2167],
                        [-61.6075, 56.2155],
                        [-61.5972, 56.2198],
                        [-61.5807, 56.2163],
                        [-61.5846, 56.2105],
                        [-61.595, 56.2037],
                        [-61.5958, 56.1953],
                        [-61.579, 56.1959],
                        [-61.5562, 56.1896],
                        [-61.5377, 56.1899],
                        [-61.5011, 56.1964],
                        [-61.4948, 56.206],
                        [-61.4767, 56.2061],
                        [-61.4651, 56.2083],
                        [-61.4429, 56.2088],
                        [-61.4276, 56.2056],
                        [-61.4171, 56.2203],
                        [-61.4011, 56.2244],
                        [-61.3911, 56.2222],
                        [-61.3808, 56.2267],
                        [-61.3641, 56.2292],
                        [-61.3408, 56.2287],
                        [-61.3586, 56.2187],
                        [-61.3661, 56.2098],
                        [-61.3777, 56.2042],
                        [-61.3728, 56.1997],
                        [-61.3578, 56.2029],
                        [-61.3586, 56.1943],
                        [-61.3528, 56.1829],
                        [-61.3419, 56.1727],
                        [-61.3591, 56.1687],
                        [-61.3937, 56.1755],
                        [-61.3997, 56.173],
                        [-61.4035, 56.1632],
                        [-61.4186, 56.1553],
                        [-61.438, 56.1492],
                        [-61.4389, 56.1442],
                        [-61.4306, 56.1413],
                        [-61.4238, 56.1273],
                        [-61.4323, 56.1205],
                        [-61.4301, 56.1088],
                        [-61.4248, 56.1084],
                        [-61.4173, 56.1182],
                        [-61.4069, 56.1209],
                        [-61.415, 56.1298],
                        [-61.3928, 56.1469],
                        [-61.3835, 56.1505],
                        [-61.3599, 56.1491],
                        [-61.3613, 56.1428],
                        [-61.3529, 56.1368],
                        [-61.3598, 56.129],
                        [-61.3743, 56.127],
                        [-61.3861, 56.128],
                        [-61.3909, 56.1208],
                        [-61.3717, 56.1207],
                        [-61.362, 56.1158],
                        [-61.3659, 56.1089],
                        [-61.3421, 56.1054],
                        [-61.3449, 56.0984],
                        [-61.3603, 56.0993],
                        [-61.3781, 56.093],
                        [-61.4005, 56.0942],
                        [-61.4183, 56.0918],
                        [-61.4499, 56.0733],
                        [-61.443, 56.0649],
                        [-61.4705, 56.0637],
                        [-61.4829, 56.0666],
                        [-61.4904, 56.0609],
                        [-61.4736, 56.0606],
                        [-61.4642, 56.0528],
                        [-61.4484, 56.0512],
                        [-61.4519, 56.0451],
                        [-61.4467, 56.0397],
                        [-61.4328, 56.0397],
                        [-61.4217, 56.049],
                        [-61.4125, 56.0514],
                        [-61.4099, 56.0566],
                        [-61.398, 56.0615],
                        [-61.3866, 56.0607],
                        [-61.3416, 56.0702],
                        [-61.3341, 56.0755],
                        [-61.3112, 56.0798],
                        [-61.3101, 56.0708],
                        [-61.3209, 56.0648],
                        [-61.3218, 56.0593],
                        [-61.3002, 56.0634],
                        [-61.2889, 56.0621],
                        [-61.2862, 56.0566],
                        [-61.2746, 56.0578],
                        [-61.2657, 56.0536],
                        [-61.2548, 56.0599],
                        [-61.2489, 56.0577],
                        [-61.2626, 56.0471],
                        [-61.3148, 56.044],
                        [-61.3278, 56.0447],
                        [-61.3352, 56.0481],
                        [-61.3528, 56.0475],
                        [-61.3488, 56.0392],
                        [-61.3512, 56.0317],
                        [-61.333, 56.0332],
                        [-61.3169, 56.0318],
                        [-61.3051, 56.0355],
                        [-61.2943, 56.0288],
                        [-61.2943, 56.021],
                        [-61.3508, 56.0228],
                        [-61.3597, 56.0192],
                        [-61.3718, 56.0207],
                        [-61.3875, 56.0189],
                        [-61.4078, 56.0202],
                        [-61.4447, 56.0266],
                        [-61.4749, 56.0266],
                        [-61.4982, 56.0305],
                        [-61.5072, 56.0297],
                        [-61.5182, 56.022],
                        [-61.5173, 56.0183],
                        [-61.4893, 56.0166],
                        [-61.4543, 56.0073],
                        [-61.4277, 56.0081],
                        [-61.4096, 56.0057],
                        [-61.4004, 55.9997],
                        [-61.378, 55.9905],
                        [-61.3884, 55.984],
                        [-61.378, 55.9777],
                        [-61.3839, 55.9722],
                        [-61.3964, 55.9691],
                        [-61.4095, 55.972],
                        [-61.4172, 55.9835],
                        [-61.4105, 55.9884],
                        [-61.4228, 55.9914],
                        [-61.431, 55.9997],
                        [-61.4527, 55.9997],
                        [-61.4581, 55.9913],
                        [-61.4523, 55.9749],
                        [-61.4316, 55.9774],
                        [-61.4213, 55.9723],
                        [-61.4136, 55.9608],
                        [-61.3953, 55.9591],
                        [-61.384, 55.9626],
                        [-61.369, 55.9621],
                        [-61.359, 55.9684],
                        [-61.3374, 55.9672],
                        [-61.3188, 55.9685],
                        [-61.3037, 55.9662],
                        [-61.2887, 55.967],
                        [-61.2771, 55.9723],
                        [-61.2575, 55.9714],
                        [-61.235, 55.9654],
                        [-61.2156, 55.9528],
                        [-61.2029, 55.95],
                        [-61.1973, 55.9546],
                        [-61.1969, 55.9669],
                        [-61.1763, 55.9746],
                        [-61.1334, 55.9788],
                        [-61.1121, 55.9715],
                        [-61.1069, 55.9617],
                        [-61.1124, 55.9532],
                        [-61.1021, 55.9487],
                        [-61.0908, 55.9486],
                        [-61.0776, 55.9416],
                        [-61.0693, 55.9335],
                        [-61.0743, 55.9248],
                        [-61.0672, 55.9148],
                        [-61.0707, 55.9091],
                        [-61.0589, 55.8892],
                        [-61.0677, 55.8843],
                        [-61.0889, 55.8894],
                        [-61.1046, 55.8856],
                        [-61.1666, 55.8842],
                        [-61.1826, 55.8905],
                        [-61.2069, 55.8777],
                        [-61.1887, 55.876],
                        [-61.194, 55.8635],
                        [-61.1745, 55.8641],
                        [-61.1538, 55.8615],
                        [-61.1338, 55.8544],
                        [-61.1308, 55.8505],
                        [-61.1141, 55.8475],
                        [-61.0937, 55.8392],
                        [-61.0851, 55.8379],
                        [-61.0451, 55.8382],
                        [-61.0298, 55.8436],
                        [-61.0324, 55.8506],
                        [-60.9899, 55.8539],
                        [-60.9721, 55.8542],
                        [-60.9453, 55.8512],
                        [-60.9336, 55.8518],
                        [-60.926, 55.858],
                        [-60.898, 55.8579],
                        [-60.8797, 55.8537],
                        [-60.8658, 55.8458],
                        [-60.8374, 55.8453],
                        [-60.7974, 55.8469],
                        [-60.7796, 55.8511],
                        [-60.7698, 55.8479],
                        [-60.7545, 55.8498],
                        [-60.732, 55.8397],
                        [-60.7125, 55.8332],
                        [-60.7217, 55.8049],
                        [-60.7298, 55.8023],
                        [-60.7361, 55.7893],
                        [-60.7271, 55.7841],
                        [-60.7523, 55.7669],
                        [-60.7716, 55.7622],
                        [-60.7759, 55.7524],
                        [-60.7823, 55.7483],
                        [-60.802, 55.7457],
                        [-60.8078, 55.7509],
                        [-60.7917, 55.7562],
                        [-60.8148, 55.7616],
                        [-60.8332, 55.7519],
                        [-60.8494, 55.7516],
                        [-60.8595, 55.749],
                        [-60.8613, 55.7419],
                        [-60.8706, 55.7382],
                        [-60.8848, 55.7447],
                        [-60.9038, 55.7462],
                        [-60.9171, 55.7425],
                        [-60.927, 55.7335],
                        [-60.9392, 55.7385],
                        [-60.9357, 55.7237],
                        [-60.9037, 55.7216],
                        [-60.8876, 55.7222],
                        [-60.8704, 55.7314],
                        [-60.8514, 55.7294],
                        [-60.8455, 55.7359],
                        [-60.8492, 55.742],
                        [-60.8363, 55.7425],
                        [-60.7997, 55.7286],
                        [-60.7913, 55.7323],
                        [-60.7878, 55.7405],
                        [-60.7794, 55.744],
                        [-60.77, 55.7404],
                        [-60.7731, 55.7342],
                        [-60.7649, 55.7244],
                        [-60.7483, 55.7209],
                        [-60.7407, 55.7287],
                        [-60.7171, 55.739],
                        [-60.6808, 55.775],
                        [-60.6664, 55.7862],
                        [-60.6574, 55.804],
                        [-60.6479, 55.8159],
                        [-60.6354, 55.8233],
                        [-60.6233, 55.8242],
                        [-60.6069, 55.8131],
                        [-60.581, 55.81],
                        [-60.5893, 55.7966],
                        [-60.6055, 55.794],
                        [-60.617, 55.7823],
                        [-60.6185, 55.7739],
                        [-60.6152, 55.7662],
                        [-60.5978, 55.7614],
                        [-60.6005, 55.7551],
                        [-60.5939, 55.7423],
                        [-60.6047, 55.7377],
                        [-60.6075, 55.7329],
                        [-60.6006, 55.7279],
                        [-60.59, 55.7273],
                        [-60.5875, 55.715],
                        [-60.5926, 55.7084],
                        [-60.5783, 55.6966],
                        [-60.5932, 55.6897],
                        [-60.6015, 55.6805],
                        [-60.6249, 55.6743],
                        [-60.6413, 55.6671],
                        [-60.6512, 55.6569],
                        [-60.639, 55.6482],
                        [-60.6434, 55.6415],
                        [-60.626, 55.6387],
                        [-60.6098, 55.6306],
                        [-60.6475, 55.5949],
                        [-60.6616, 55.5663],
                        [-60.6538, 55.5586],
                        [-60.6557, 55.5504],
                        [-60.6485, 55.5481],
                        [-60.6471, 55.5675],
                        [-60.6249, 55.5877],
                        [-60.6113, 55.5964],
                        [-60.5937, 55.6047],
                        [-60.5818, 55.6177],
                        [-60.5607, 55.6691],
                        [-60.5423, 55.6858],
                        [-60.5364, 55.7011],
                        [-60.5378, 55.7129],
                        [-60.523, 55.7282],
                        [-60.5103, 55.7485],
                        [-60.5177, 55.7567],
                        [-60.5086, 55.7745],
                        [-60.4873, 55.7878],
                        [-60.4789, 55.7954],
                        [-60.4672, 55.8015],
                        [-60.4542, 55.7977],
                        [-60.4622, 55.7879],
                        [-60.4549, 55.7811],
                        [-60.4456, 55.787],
                        [-60.4339, 55.7866],
                        [-60.4241, 55.7766],
                        [-60.4276, 55.7668],
                        [-60.411, 55.7639],
                        [-60.3941, 55.7742],
                        [-60.3748, 55.779],
                        [-60.3554, 55.7809],
                        [-60.3444, 55.7765],
                        [-60.3435, 55.7595],
                        [-60.3406, 55.7529],
                        [-60.331, 55.7552],
                        [-60.327, 55.7704],
                        [-60.3197, 55.7736],
                        [-60.3048, 55.7725],
                        [-60.307, 55.7454],
                        [-60.3187, 55.7374],
                        [-60.3377, 55.7371],
                        [-60.3437, 55.7323],
                        [-60.3408, 55.721],
                        [-60.3581, 55.7118],
                        [-60.3565, 55.7063],
                        [-60.3729, 55.6881],
                        [-60.3627, 55.6772],
                        [-60.3518, 55.6774],
                        [-60.353, 55.6683],
                        [-60.3735, 55.6666],
                        [-60.3993, 55.6611],
                        [-60.4311, 55.6604],
                        [-60.4468, 55.6514],
                        [-60.4565, 55.6591],
                        [-60.4695, 55.6625],
                        [-60.4689, 55.6518],
                        [-60.4841, 55.6342],
                        [-60.4952, 55.6171],
                        [-60.4947, 55.61],
                        [-60.5067, 55.5995],
                        [-60.5129, 55.5824],
                        [-60.4993, 55.5865],
                        [-60.475, 55.6083],
                        [-60.4638, 55.6245],
                        [-60.4401, 55.6225],
                        [-60.4163, 55.6253],
                        [-60.4054, 55.6205],
                        [-60.4001, 55.6142],
                        [-60.3823, 55.6083],
                        [-60.3775, 55.6026],
                        [-60.3737, 55.5903],
                        [-60.3757, 55.5791],
                        [-60.3909, 55.5741],
                        [-60.4017, 55.5645],
                        [-60.3924, 55.5631],
                        [-60.376, 55.5673],
                        [-60.4004, 55.5472],
                        [-60.4011, 55.5339],
                        [-60.4058, 55.5269],
                        [-60.4163, 55.5257],
                        [-60.4238, 55.5191],
                        [-60.4371, 55.5158],
                        [-60.437, 55.5113],
                        [-60.445, 55.5012],
                        [-60.4369, 55.4962],
                        [-60.4266, 55.509],
                        [-60.375, 55.5399],
                        [-60.3629, 55.545],
                        [-60.3546, 55.5575],
                        [-60.3449, 55.5589],
                        [-60.3357, 55.566],
                        [-60.3032, 55.5756],
                        [-60.2903, 55.574],
                        [-60.2903, 55.5622],
                        [-60.3003, 55.5585],
                        [-60.3055, 55.5515],
                        [-60.3074, 55.5385],
                        [-60.3167, 55.5364],
                        [-60.3279, 55.5273],
                        [-60.3226, 55.5216],
                        [-60.3322, 55.509],
                        [-60.3413, 55.5026],
                        [-60.3333, 55.4928],
                        [-60.3368, 55.486],
                        [-60.3589, 55.4811],
                        [-60.3692, 55.4718],
                        [-60.3881, 55.4658],
                        [-60.3904, 55.4624],
                        [-60.3807, 55.4551],
                        [-60.4124, 55.4466],
                        [-60.4204, 55.4502],
                        [-60.4331, 55.4379],
                        [-60.4319, 55.432],
                        [-60.4454, 55.4237],
                        [-60.4546, 55.4146],
                        [-60.4344, 55.3987],
                        [-60.4319, 55.3934],
                        [-60.4374, 55.3765],
                        [-60.456, 55.356],
                        [-60.4684, 55.3477],
                        [-60.4915, 55.3424],
                        [-60.4934, 55.3351],
                        [-60.4814, 55.3361],
                        [-60.4726, 55.3302],
                        [-60.4654, 55.3352],
                        [-60.4675, 55.3409],
                        [-60.4547, 55.3471],
                        [-60.4491, 55.3448],
                        [-60.4356, 55.3494],
                        [-60.4296, 55.3579],
                        [-60.4185, 55.3636],
                        [-60.4121, 55.3734],
                        [-60.3878, 55.3872],
                        [-60.3883, 55.3915],
                        [-60.4031, 55.3976],
                        [-60.41, 55.4069],
                        [-60.3997, 55.4138],
                        [-60.3966, 55.4307],
                        [-60.3786, 55.4376],
                        [-60.3746, 55.4437],
                        [-60.3606, 55.4488],
                        [-60.3583, 55.4554],
                        [-60.3448, 55.4723],
                        [-60.3164, 55.4938],
                        [-60.2935, 55.4933],
                        [-60.2875, 55.4899],
                        [-60.295, 55.4826],
                        [-60.3099, 55.4744],
                        [-60.3022, 55.4635],
                        [-60.3053, 55.4564],
                        [-60.3134, 55.4571],
                        [-60.3226, 55.4523],
                        [-60.3454, 55.4486],
                        [-60.351, 55.4461],
                        [-60.3517, 55.4299],
                        [-60.3492, 55.4178],
                        [-60.34, 55.4231],
                        [-60.3389, 55.4333],
                        [-60.3272, 55.4308],
                        [-60.3265, 55.4409],
                        [-60.3197, 55.4468],
                        [-60.3105, 55.4473],
                        [-60.2997, 55.4521],
                        [-60.292, 55.4448],
                        [-60.2952, 55.4411],
                        [-60.308, 55.4413],
                        [-60.3016, 55.4331],
                        [-60.2899, 55.4327],
                        [-60.2783, 55.4371],
                        [-60.2687, 55.4282],
                        [-60.2571, 55.4353],
                        [-60.272, 55.4421],
                        [-60.2784, 55.456],
                        [-60.2661, 55.4795],
                        [-60.2718, 55.4881],
                        [-60.2638, 55.4979],
                        [-60.2506, 55.505],
                        [-60.2277, 55.4964],
                        [-60.224, 55.4893],
                        [-60.2135, 55.4823],
                        [-60.1995, 55.4816],
                        [-60.1886, 55.4661],
                        [-60.1874, 55.4595],
                        [-60.1978, 55.459],
                        [-60.2018, 55.4513],
                        [-60.1866, 55.4502],
                        [-60.1805, 55.4438],
                        [-60.1933, 55.441],
                        [-60.209, 55.4476],
                        [-60.2005, 55.4326],
                        [-60.1913, 55.4312],
                        [-60.1893, 55.4262],
                        [-60.1736, 55.4255],
                        [-60.182, 55.418],
                        [-60.2025, 55.4173],
                        [-60.2121, 55.4262],
                        [-60.2298, 55.4216],
                        [-60.2389, 55.4123],
                        [-60.257, 55.4097],
                        [-60.2718, 55.4033],
                        [-60.2886, 55.4081],
                        [-60.3038, 55.4081],
                        [-60.3123, 55.4122],
                        [-60.3324, 55.4174],
                        [-60.3311, 55.4082],
                        [-60.3074, 55.4028],
                        [-60.3038, 55.3928],
                        [-60.3077, 55.3889],
                        [-60.3282, 55.3975],
                        [-60.3458, 55.3916],
                        [-60.3462, 55.3857],
                        [-60.3636, 55.363],
                        [-60.3699, 55.3509],
                        [-60.3627, 55.3402],
                        [-60.3673, 55.3229],
                        [-60.3769, 55.3249],
                        [-60.3868, 55.308],
                        [-60.3948, 55.3016],
                        [-60.3959, 55.2953],
                        [-60.3739, 55.2928],
                        [-60.385, 55.2871],
                        [-60.3905, 55.2727],
                        [-60.3973, 55.2686],
                        [-60.4241, 55.2696],
                        [-60.4388, 55.2689],
                        [-60.4317, 55.2765],
                        [-60.4387, 55.297],
                        [-60.4526, 55.2867],
                        [-60.4552, 55.2693],
                        [-60.4775, 55.2652],
                        [-60.4946, 55.2585],
                        [-60.4983, 55.2658],
                        [-60.5134, 55.2593],
                        [-60.5082, 55.2548],
                        [-60.5116, 55.2436],
                        [-60.4818, 55.2522],
                        [-60.4639, 55.2611],
                        [-60.4591, 55.255],
                        [-60.4669, 55.2447],
                        [-60.4841, 55.2398],
                        [-60.4995, 55.2304],
                        [-60.5147, 55.2304],
                        [-60.5238, 55.2228],
                        [-60.5285, 55.2141],
                        [-60.5272, 55.2062],
                        [-60.5326, 55.1945],
                        [-60.5505, 55.1842],
                        [-60.5719, 55.1805],
                        [-60.579, 55.1743],
                        [-60.6012, 55.1653],
                        [-60.6047, 55.1548],
                        [-60.5586, 55.1662],
                        [-60.5395, 55.169],
                        [-60.5253, 55.1797],
                        [-60.5088, 55.2024],
                        [-60.4981, 55.2113],
                        [-60.4846, 55.2173],
                        [-60.47, 55.2292],
                        [-60.4612, 55.2303],
                        [-60.444, 55.2283],
                        [-60.4347, 55.2147],
                        [-60.4224, 55.2126],
                        [-60.4097, 55.2225],
                        [-60.4165, 55.2327],
                        [-60.4074, 55.2382],
                        [-60.3986, 55.2394],
                        [-60.3955, 55.2458],
                        [-60.3804, 55.2485],
                        [-60.368, 55.2547],
                        [-60.3592, 55.255],
                        [-60.3508, 55.2502],
                        [-60.3547, 55.2352],
                        [-60.3678, 55.2198],
                        [-60.386, 55.2082],
                        [-60.4107, 55.1972],
                        [-60.4356, 55.1934],
                        [-60.4437, 55.19],
                        [-60.4452, 55.1829],
                        [-60.4599, 55.171],
                        [-60.47, 55.1464],
                        [-60.4798, 55.1329],
                        [-60.4904, 55.1237],
                        [-60.4987, 55.1112],
                        [-60.5176, 55.0981],
                        [-60.5291, 55.0931],
                        [-60.5735, 55.0822],
                        [-60.6012, 55.0718],
                        [-60.6123, 55.0651],
                        [-60.622, 55.0544],
                        [-60.6324, 55.0564],
                        [-60.6407, 55.0525],
                        [-60.6274, 55.0377],
                        [-60.6329, 55.037],
                        [-60.6459, 55.0287],
                        [-60.6407, 55.0244],
                        [-60.6522, 55.0198],
                        [-60.6702, 55.0022],
                        [-60.6779, 54.9891],
                        [-60.6656, 54.986],
                        [-60.6546, 54.9929],
                        [-60.6464, 55.005],
                        [-60.6378, 55.0128],
                        [-60.6267, 55.0186],
                        [-60.6125, 55.0223],
                        [-60.5962, 55.0239],
                        [-60.5964, 55.0344],
                        [-60.5846, 55.0468],
                        [-60.5776, 55.0589],
                        [-60.5666, 55.0669],
                        [-60.5519, 55.0724],
                        [-60.5186, 55.079],
                        [-60.5099, 55.0819],
                        [-60.5008, 55.089],
                        [-60.4854, 55.0971],
                        [-60.4679, 55.1026],
                        [-60.4652, 55.1074],
                        [-60.4705, 55.1181],
                        [-60.469, 55.1245],
                        [-60.4519, 55.1353],
                        [-60.4414, 55.1528],
                        [-60.4334, 55.1545],
                        [-60.4215, 55.1627],
                        [-60.4064, 55.1618],
                        [-60.398, 55.1653],
                        [-60.39, 55.1616],
                        [-60.396, 55.1532],
                        [-60.3963, 55.1422],
                        [-60.3863, 55.1407],
                        [-60.3709, 55.1553],
                        [-60.3467, 55.1674],
                        [-60.3372, 55.1843],
                        [-60.3095, 55.2102],
                        [-60.2669, 55.2342],
                        [-60.2506, 55.2469],
                        [-60.2391, 55.2584],
                        [-60.2391, 55.2693],
                        [-60.2235, 55.2698],
                        [-60.2156, 55.2789],
                        [-60.2076, 55.2732],
                        [-60.21, 55.2675],
                        [-60.239, 55.2497],
                        [-60.2207, 55.2531],
                        [-60.2143, 55.2411],
                        [-60.2067, 55.2379],
                        [-60.1963, 55.2486],
                        [-60.186, 55.2516],
                        [-60.1788, 55.2575],
                        [-60.1656, 55.2612],
                        [-60.17, 55.2678],
                        [-60.1672, 55.2744],
                        [-60.1413, 55.2774],
                        [-60.1273, 55.2714],
                        [-60.1285, 55.2662],
                        [-60.1532, 55.2518],
                        [-60.158, 55.2427],
                        [-60.1576, 55.2331],
                        [-60.1484, 55.2297],
                        [-60.15, 55.2233],
                        [-60.1671, 55.2158],
                        [-60.1731, 55.2078],
                        [-60.175, 55.1962],
                        [-60.2005, 55.1729],
                        [-60.2184, 55.1683],
                        [-60.2319, 55.1605],
                        [-60.2739, 55.1247],
                        [-60.2822, 55.1128],
                        [-60.2731, 55.1119],
                        [-60.2628, 55.1176],
                        [-60.2541, 55.1306],
                        [-60.2398, 55.1384],
                        [-60.2315, 55.1514],
                        [-60.1957, 55.1681],
                        [-60.1686, 55.1763],
                        [-60.1658, 55.1811],
                        [-60.1543, 55.1868],
                        [-60.1475, 55.1932],
                        [-60.1507, 55.203],
                        [-60.1471, 55.2087],
                        [-60.1348, 55.2169],
                        [-60.126, 55.2279],
                        [-60.1145, 55.2325],
                        [-60.1017, 55.2331],
                        [-60.0666, 55.2569],
                        [-60.041, 55.2646],
                        [-60.0167, 55.265],
                        [-60.0063, 55.2612],
                        [-60.0087, 55.252],
                        [-60.0275, 55.2557],
                        [-60.0319, 55.2466],
                        [-60.037, 55.2438],
                        [-60.0538, 55.248],
                        [-60.0526, 55.2402],
                        [-60.0626, 55.235],
                        [-60.0626, 55.2147],
                        [-60.0765, 55.2014],
                        [-60.0785, 55.1846],
                        [-60.0941, 55.1745],
                        [-60.1036, 55.159],
                        [-60.1406, 55.1285],
                        [-60.1593, 55.1166],
                        [-60.1752, 55.1134],
                        [-60.1831, 55.0974],
                        [-60.1911, 55.0999],
                        [-60.1851, 55.1084],
                        [-60.2015, 55.1193],
                        [-60.2074, 55.1024],
                        [-60.2125, 55.0976],
                        [-60.2265, 55.0965],
                        [-60.2403, 55.0892],
                        [-60.2351, 55.0828],
                        [-60.2411, 55.0739],
                        [-60.2581, 55.0611],
                        [-60.2744, 55.0592],
                        [-60.2887, 55.0537],
                        [-60.2847, 55.0455],
                        [-60.2933, 55.03],
                        [-60.2913, 55.0177],
                        [-60.2932, 55.0104],
                        [-60.3077, 54.9828],
                        [-60.2915, 54.994],
                        [-60.2844, 55.0054],
                        [-60.2497, 55.0387],
                        [-60.2239, 55.0543],
                        [-60.2041, 55.063],
                        [-60.1819, 55.078],
                        [-60.1632, 55.0863],
                        [-60.1004, 55.1187],
                        [-60.0857, 55.1269],
                        [-60.0757, 55.1371],
                        [-60.0634, 55.1729],
                        [-60.0538, 55.188],
                        [-60.0411, 55.1994],
                        [-60.0251, 55.2026],
                        [-60.0112, 55.201],
                        [-60.004, 55.213],
                        [-60.0028, 55.2206],
                        [-60.0147, 55.2178],
                        [-60.0143, 55.2254],
                        [-60.0071, 55.229],
                        [-59.9916, 55.2281],
                        [-59.9796, 55.2354],
                        [-59.9656, 55.2349],
                        [-59.9716, 55.2424],
                        [-59.9504, 55.2559],
                        [-59.942, 55.2559],
                        [-59.9464, 55.2433],
                        [-59.9544, 55.2356],
                        [-59.9197, 55.2362],
                        [-59.9094, 55.2305],
                        [-59.9006, 55.2323],
                        [-59.8982, 55.2398],
                        [-59.889, 55.2451],
                        [-59.9017, 55.2519],
                        [-59.9101, 55.2535],
                        [-59.8993, 55.262],
                        [-59.8869, 55.2642],
                        [-59.8789, 55.2585],
                        [-59.8709, 55.2642],
                        [-59.8701, 55.2713],
                        [-59.8545, 55.2787],
                        [-59.8432, 55.3002],
                        [-59.8351, 55.3056],
                        [-59.8067, 55.3131],
                        [-59.7935, 55.3142],
                        [-59.7891, 55.3222],
                        [-59.7622, 55.3289],
                        [-59.7671, 55.3171],
                        [-59.7723, 55.3121],
                        [-59.7572, 55.3061],
                        [-59.7488, 55.299],
                        [-59.7501, 55.2929],
                        [-59.7594, 55.2801],
                        [-59.757, 55.2717],
                        [-59.7446, 55.2762],
                        [-59.7298, 55.2851],
                        [-59.7242, 55.2844],
                        [-59.7147, 55.2754],
                        [-59.7029, 55.2553],
                        [-59.7065, 55.2469],
                        [-59.7158, 55.2381],
                        [-59.7313, 55.2454],
                        [-59.7457, 55.245],
                        [-59.7385, 55.2361],
                        [-59.7493, 55.2327],
                        [-59.7382, 55.2244],
                        [-59.7254, 55.2271],
                        [-59.7207, 55.2228],
                        [-59.7259, 55.2137],
                        [-59.7228, 55.2093],
                        [-59.7321, 55.1966],
                        [-59.7393, 55.1941],
                        [-59.7548, 55.1955],
                        [-59.7655, 55.2028],
                        [-59.7739, 55.2033],
                        [-59.7791, 55.1949],
                        [-59.7956, 55.1824],
                        [-59.7996, 55.1735],
                        [-59.8192, 55.1669],
                        [-59.8291, 55.1679],
                        [-59.8925, 55.1495],
                        [-59.9089, 55.1473],
                        [-59.9164, 55.1505],
                        [-59.9367, 55.1649],
                        [-59.9447, 55.1615],
                        [-59.9527, 55.1498],
                        [-59.9634, 55.1485],
                        [-59.9547, 55.1419],
                        [-59.9563, 55.1334],
                        [-59.9511, 55.1275],
                        [-59.9711, 55.1131],
                        [-59.9611, 55.1092],
                        [-59.9469, 55.0933],
                        [-59.9464, 55.112],
                        [-59.9436, 55.1186],
                        [-59.9221, 55.1263],
                        [-59.9177, 55.1352],
                        [-59.9033, 55.1363],
                        [-59.8811, 55.134],
                        [-59.8735, 55.1381],
                        [-59.8555, 55.1394],
                        [-59.8592, 55.1315],
                        [-59.8548, 55.123],
                        [-59.8744, 55.111],
                        [-59.8704, 55.1093],
                        [-59.8501, 55.1182],
                        [-59.8425, 55.1141],
                        [-59.8325, 55.1223],
                        [-59.8171, 55.1161],
                        [-59.8203, 55.1102],
                        [-59.8088, 55.106],
                        [-59.8031, 55.1145],
                        [-59.7924, 55.1099],
                        [-59.7828, 55.1151],
                        [-59.7808, 55.1231],
                        [-59.772, 55.1292],
                        [-59.7839, 55.1347],
                        [-59.7675, 55.1411],
                        [-59.754, 55.1417],
                        [-59.7221, 55.148],
                        [-59.7074, 55.1468],
                        [-59.6914, 55.1484],
                        [-59.6719, 55.1446],
                        [-59.6594, 55.1282],
                        [-59.6487, 55.1229],
                        [-59.6386, 55.1299],
                        [-59.6291, 55.1304],
                        [-59.6155, 55.1358],
                        [-59.6242, 55.1408],
                        [-59.6293, 55.1523],
                        [-59.62, 55.1627],
                        [-59.6275, 55.1725],
                        [-59.6214, 55.1789],
                        [-59.6225, 55.1903],
                        [-59.6044, 55.1996],
                        [-59.5901, 55.1984],
                        [-59.597, 55.1918],
                        [-59.5842, 55.1895],
                        [-59.5823, 55.1831],
                        [-59.5875, 55.1788],
                        [-59.5975, 55.1806],
                        [-59.6024, 55.1663],
                        [-59.5985, 55.161],
                        [-59.6081, 55.1563],
                        [-59.603, 55.1526],
                        [-59.6098, 55.1472],
                        [-59.5967, 55.1423],
                        [-59.593, 55.1544],
                        [-59.5822, 55.1587],
                        [-59.5489, 55.1754],
                        [-59.541, 55.1704],
                        [-59.5174, 55.1808],
                        [-59.5094, 55.1828],
                        [-59.524, 55.1908],
                        [-59.5267, 55.2],
                        [-59.5238, 55.205],
                        [-59.505, 55.2126],
                        [-59.4957, 55.2187],
                        [-59.4859, 55.2096],
                        [-59.4893, 55.1948],
                        [-59.4759, 55.1799],
                        [-59.4784, 55.171],
                        [-59.4918, 55.159],
                        [-59.5011, 55.1467],
                        [-59.5074, 55.1497],
                        [-59.5259, 55.1395],
                        [-59.5311, 55.1318],
                        [-59.5379, 55.1309],
                        [-59.5373, 55.1209],
                        [-59.5321, 55.1199],
                        [-59.5414, 55.1042],
                        [-59.5529, 55.0767],
                        [-59.5486, 55.0698],
                        [-59.5286, 55.1158],
                        [-59.5213, 55.1247],
                        [-59.506, 55.1353],
                        [-59.4736, 55.1479],
                        [-59.4499, 55.1624],
                        [-59.442, 55.1599],
                        [-59.428, 55.1623],
                        [-59.4252, 55.1584],
                        [-59.4021, 55.1624],
                        [-59.3977, 55.1596],
                        [-59.4181, 55.1547],
                        [-59.4342, 55.1222],
                        [-59.4406, 55.1161],
                        [-59.4522, 55.1127],
                        [-59.4629, 55.1132],
                        [-59.4785, 55.1078],
                        [-59.4794, 55.1017],
                        [-59.4963, 55.0865],
                        [-59.4897, 55.0796],
                        [-59.5068, 55.0478],
                        [-59.5139, 55.0482],
                        [-59.5276, 55.0332],
                        [-59.5415, 55.0329],
                        [-59.549, 55.0416],
                        [-59.538, 55.0609],
                        [-59.5431, 55.0641],
                        [-59.5517, 55.0475],
                        [-59.5593, 55.0389],
                        [-59.5685, 55.0332],
                        [-59.5794, 55.0202],
                        [-59.6078, 55.0016],
                        [-59.6177, 55.001],
                        [-59.6361, 54.9919],
                        [-59.6397, 54.9858],
                        [-59.6588, 54.9786],
                        [-59.6639, 54.9909],
                        [-59.6719, 54.98],
                        [-59.6827, 54.9707],
                        [-59.6958, 54.9734],
                        [-59.7244, 54.9655],
                        [-59.7347, 54.9615],
                        [-59.7237, 54.9548],
                        [-59.7125, 54.9589],
                        [-59.7022, 54.9566],
                        [-59.7174, 54.9425],
                        [-59.7239, 54.9295],
                        [-59.7477, 54.9173],
                        [-59.7681, 54.8993],
                        [-59.7895, 54.893],
                        [-59.8101, 54.8818],
                        [-59.826, 54.8805],
                        [-59.8394, 54.8737],
                        [-59.849, 54.865],
                        [-59.857, 54.8493],
                        [-59.8515, 54.8406],
                        [-59.8412, 54.8447],
                        [-59.8333, 54.8397],
                        [-59.8048, 54.8387],
                        [-59.8132, 54.8339],
                        [-59.8212, 54.8209],
                        [-59.818, 54.8157],
                        [-59.8288, 54.8023],
                        [-59.8438, 54.7893],
                        [-59.8913, 54.762],
                        [-59.8989, 54.7558],
                        [-59.9107, 54.7595],
                        [-59.9245, 54.7584],
                        [-59.9424, 54.7511],
                        [-59.919, 54.7465],
                        [-59.9167, 54.7404],
                        [-59.9017, 54.7408],
                        [-59.8657, 54.7503],
                        [-59.8396, 54.7614],
                        [-59.8325, 54.7619],
                        [-59.8226, 54.7696],
                        [-59.8167, 54.7701],
                        [-59.8068, 54.7783],
                        [-59.7914, 54.7789],
                        [-59.7843, 54.7821],
                        [-59.7894, 54.7876],
                        [-59.7992, 54.7912],
                        [-59.7964, 54.7983],
                        [-59.7991, 54.8086],
                        [-59.7967, 54.8166],
                        [-59.799, 54.8229],
                        [-59.7926, 54.8391],
                        [-59.7941, 54.8451],
                        [-59.7786, 54.8678],
                        [-59.7753, 54.882],
                        [-59.7689, 54.8867],
                        [-59.734, 54.9006],
                        [-59.709, 54.9078],
                        [-59.6975, 54.9139],
                        [-59.6832, 54.9091],
                        [-59.6773, 54.9111],
                        [-59.6721, 54.92],
                        [-59.6541, 54.9357],
                        [-59.629, 54.9449],
                        [-59.6175, 54.9467],
                        [-59.6029, 54.9457],
                        [-59.5908, 54.9612],
                        [-59.5752, 54.9716],
                        [-59.57, 54.9728],
                        [-59.5631, 54.9898],
                        [-59.5548, 54.9859],
                        [-59.5588, 54.9809],
                        [-59.553, 54.9731],
                        [-59.5389, 54.9875],
                        [-59.5352, 54.9984],
                        [-59.5165, 55.007],
                        [-59.5044, 55.0174],
                        [-59.4913, 55.0199],
                        [-59.4757, 55.0287],
                        [-59.4496, 55.0472],
                        [-59.431, 55.0686],
                        [-59.4185, 55.0772],
                        [-59.4054, 55.0764],
                        [-59.4083, 55.0705],
                        [-59.4017, 55.0618],
                        [-59.3863, 55.0558],
                        [-59.3975, 55.0477],
                        [-59.3944, 55.0417],
                        [-59.4005, 55.0344],
                        [-59.3931, 55.0292],
                        [-59.3808, 55.0266],
                        [-59.372, 55.0541],
                        [-59.3715, 55.0628],
                        [-59.3578, 55.0732],
                        [-59.3475, 55.0898],
                        [-59.3366, 55.1004],
                        [-59.2881, 55.1412],
                        [-59.284, 55.1503],
                        [-59.2943, 55.1524],
                        [-59.2659, 55.1769],
                        [-59.2666, 55.1848],
                        [-59.2584, 55.1951],
                        [-59.2383, 55.2031],
                        [-59.2299, 55.2033],
                        [-59.2274, 55.2099],
                        [-59.2082, 55.2136],
                        [-59.1982, 55.2129],
                        [-59.196, 55.2044],
                        [-59.2026, 55.196],
                        [-59.1983, 55.1928],
                        [-59.2024, 55.1837],
                        [-59.2084, 55.1824],
                        [-59.2074, 55.1746],
                        [-59.2012, 55.1659],
                        [-59.1921, 55.1618],
                        [-59.185, 55.1772],
                        [-59.1857, 55.1832],
                        [-59.1722, 55.2018],
                        [-59.1637, 55.2074],
                        [-59.1553, 55.2067],
                        [-59.1383, 55.2168],
                        [-59.1474, 55.2205],
                        [-59.1477, 55.2273],
                        [-59.1296, 55.2325],
                        [-59.1186, 55.2258],
                        [-59.1145, 55.2109],
                        [-59.1087, 55.2022],
                        [-59.1154, 55.1888],
                        [-59.1105, 55.1794],
                        [-59.1134, 55.1724],
                        [-59.1261, 55.1583],
                        [-59.1259, 55.1485],
                        [-59.1432, 55.1391],
                        [-59.1491, 55.1272],
                        [-59.1651, 55.1237],
                        [-59.1711, 55.1194],
                        [-59.203, 55.1178],
                        [-59.2246, 55.11],
                        [-59.2502, 55.0839],
                        [-59.2524, 55.0755],
                        [-59.2466, 55.0673],
                        [-59.2519, 55.0614],
                        [-59.2606, 55.0594],
                        [-59.2792, 55.0451],
                        [-59.2928, 55.0402],
                        [-59.2945, 55.0331],
                        [-59.3045, 55.0282],
                        [-59.3128, 55.0303],
                        [-59.3281, 55.0224],
                        [-59.3457, 55.0093],
                        [-59.3614, 55.0016],
                        [-59.3675, 54.9873],
                        [-59.3649, 54.9822],
                        [-59.3701, 54.9761],
                        [-59.3579, 54.9735],
                        [-59.3536, 54.9899],
                        [-59.3479, 54.9974],
                        [-59.3094, 55.0195],
                        [-59.3031, 55.02],
                        [-59.2961, 55.0128],
                        [-59.2866, 55.0096],
                        [-59.2753, 55.0191],
                        [-59.2506, 55.0249],
                        [-59.2397, 55.0344],
                        [-59.2455, 55.039],
                        [-59.228, 55.0411],
                        [-59.2155, 55.0522],
                        [-59.2265, 55.0591],
                        [-59.2274, 55.0726],
                        [-59.2209, 55.0794],
                        [-59.2092, 55.0864],
                        [-59.1876, 55.0908],
                        [-59.1778, 55.0885],
                        [-59.1644, 55.0977],
                        [-59.1431, 55.0729],
                        [-59.1311, 55.0776],
                        [-59.1381, 55.085],
                        [-59.1375, 55.0934],
                        [-59.1441, 55.0985],
                        [-59.1295, 55.1118],
                        [-59.1106, 55.1197],
                        [-59.1033, 55.1253],
                        [-59.0906, 55.1252],
                        [-59.092, 55.1163],
                        [-59.0834, 55.1087],
                        [-59.0817, 55.0975],
                        [-59.085, 55.0923],
                        [-59.1065, 55.0756],
                        [-59.0999, 55.0671],
                        [-59.0896, 55.0659],
                        [-59.0715, 55.076],
                        [-59.0613, 55.0844],
                        [-59.06, 55.0905],
                        [-59.0641, 55.1033],
                        [-59.058, 55.1249],
                        [-59.0455, 55.1449],
                        [-59.024, 55.1456],
                        [-59.0083, 55.1514],
                        [-58.9893, 55.1481],
                        [-58.9822, 55.1446],
                        [-58.98, 55.1377],
                        [-58.985, 55.1291],
                        [-58.9822, 55.1161],
                        [-58.974, 55.108],
                        [-58.9644, 55.1105],
                        [-58.9555, 55.1179],
                        [-58.9548, 55.1256],
                        [-58.9396, 55.1289],
                        [-58.9407, 55.1205],
                        [-58.9362, 55.1075],
                        [-58.9469, 55.0959],
                        [-58.9431, 55.0886],
                        [-58.9415, 55.0726],
                        [-58.9433, 55.0669],
                        [-58.9603, 55.0557],
                        [-58.974, 55.0496],
                        [-59.0026, 55.0321],
                        [-59.0335, 55.0023],
                        [-59.036, 54.997],
                        [-59.0254, 54.9953],
                        [-59.0133, 55.0023],
                        [-59.0084, 54.9897],
                        [-59.0027, 54.9924],
                        [-58.9921, 55.0062],
                        [-58.9841, 55.0105],
                        [-58.973, 55.0261],
                        [-58.9636, 55.0206],
                        [-58.9775, 55.0043],
                        [-58.9778, 54.9917],
                        [-58.9741, 54.9835],
                        [-58.961, 54.9836],
                        [-58.9361, 54.9656],
                        [-58.9255, 54.96],
                        [-58.9148, 54.9597],
                        [-58.9122, 54.9544],
                        [-58.8969, 54.9477],
                        [-58.9011, 54.9411],
                        [-58.8881, 54.9389],
                        [-58.885, 54.9334],
                        [-58.903, 54.9288],
                        [-58.9095, 54.9229],
                        [-58.9287, 54.9176],
                        [-58.9496, 54.9196],
                        [-58.969, 54.9196],
                        [-58.9612, 54.9138],
                        [-58.958, 54.9033],
                        [-58.9605, 54.8969],
                        [-58.9729, 54.8936],
                        [-58.9931, 54.8913],
                        [-59.0081, 54.8921],
                        [-59.0087, 54.8841],
                        [-58.9965, 54.8808],
                        [-59.005, 54.8756],
                        [-58.9984, 54.871],
                        [-58.9862, 54.8682],
                        [-58.9731, 54.8708],
                        [-58.9681, 54.8785],
                        [-58.9576, 54.8729],
                        [-58.9593, 54.8654],
                        [-58.9402, 54.8712],
                        [-58.9306, 54.9037],
                        [-58.91, 54.9051],
                        [-58.8867, 54.9038],
                        [-58.8802, 54.8934],
                        [-58.8808, 54.888],
                        [-58.8915, 54.8728],
                        [-58.8937, 54.8657],
                        [-58.9064, 54.8626],
                        [-58.9137, 54.8561],
                        [-58.8833, 54.8551],
                        [-58.8806, 54.8519],
                        [-58.9009, 54.8464],
                        [-58.9141, 54.8406],
                        [-58.9179, 54.8342],
                        [-58.9415, 54.824],
                        [-58.991, 54.8196],
                        [-59.0031, 54.8247],
                        [-59.0302, 54.832],
                        [-59.0678, 54.8294],
                        [-59.1056, 54.8201],
                        [-59.0838, 54.8204],
                        [-59.0616, 54.8257],
                        [-59.0311, 54.827],
                        [-59.0096, 54.8189],
                        [-58.9828, 54.8152],
                        [-58.9591, 54.8164],
                        [-58.9341, 54.8196],
                        [-58.9161, 54.8271],
                        [-58.9008, 54.8231],
                        [-58.8723, 54.8254],
                        [-58.8648, 54.8235],
                        [-58.8623, 54.8141],
                        [-58.8492, 54.8039],
                        [-58.8334, 54.8044],
                        [-58.82, 54.8137],
                        [-58.8221, 54.8233],
                        [-58.8128, 54.8316],
                        [-58.8074, 54.8411],
                        [-58.7931, 54.8428],
                        [-58.7953, 54.8358],
                        [-58.792, 54.8266],
                        [-58.7623, 54.8293],
                        [-58.7546, 54.8212],
                        [-58.7596, 54.8124],
                        [-58.7646, 54.7923],
                        [-58.7641, 54.7823],
                        [-58.7529, 54.789],
                        [-58.7425, 54.7912],
                        [-58.7283, 54.8175],
                        [-58.7204, 54.8274],
                        [-58.7132, 54.8306],
                        [-58.703, 54.8298],
                        [-58.6792, 54.8313],
                        [-58.6853, 54.815],
                        [-58.6775, 54.8096],
                        [-58.6752, 54.798],
                        [-58.6701, 54.7956],
                        [-58.6456, 54.796],
                        [-58.6233, 54.7908],
                        [-58.611, 54.7904],
                        [-58.5997, 54.7859],
                        [-58.5895, 54.7851],
                        [-58.5672, 54.7901],
                        [-58.553, 54.8009],
                        [-58.539, 54.7941],
                        [-58.5359, 54.7831],
                        [-58.5269, 54.7809],
                        [-58.5516, 54.7639],
                        [-58.559, 54.756],
                        [-58.5529, 54.7486],
                        [-58.5286, 54.7558],
                        [-58.5092, 54.7576],
                        [-58.5042, 54.7644],
                        [-58.4903, 54.767],
                        [-58.4837, 54.7633],
                        [-58.4537, 54.7626],
                        [-58.4468, 54.7587],
                        [-58.4361, 54.7594],
                        [-58.4251, 54.7687],
                        [-58.4178, 54.7626],
                        [-58.4196, 54.7569],
                        [-58.4293, 54.7505],
                        [-58.4357, 54.7396],
                        [-58.4282, 54.7395],
                        [-58.4183, 54.7298],
                        [-58.4066, 54.7266],
                        [-58.3877, 54.725],
                        [-58.3713, 54.7312],
                        [-58.3708, 54.7437],
                        [-58.3918, 54.7627],
                        [-58.401, 54.7688],
                        [-58.3988, 54.7747],
                        [-58.3801, 54.779],
                        [-58.3695, 54.7775],
                        [-58.3594, 54.7821],
                        [-58.3365, 54.783],
                        [-58.3276, 54.7865],
                        [-58.2811, 54.7852],
                        [-58.2536, 54.7813],
                        [-58.2367, 54.7886],
                        [-58.2276, 54.788],
                        [-58.2194, 54.7774],
                        [-58.2094, 54.7809],
                        [-58.2068, 54.7948],
                        [-58.1953, 54.8053],
                        [-58.1974, 54.8104],
                        [-58.209, 54.8172],
                        [-58.2129, 54.8624],
                        [-58.2121, 54.8715],
                        [-58.1992, 54.8688],
                        [-58.1795, 54.8574],
                        [-58.1757, 54.8459],
                        [-58.1634, 54.837],
                        [-58.1528, 54.8442],
                        [-58.1474, 54.8598],
                        [-58.1263, 54.8702],
                        [-58.1104, 54.8727],
                        [-58.0891, 54.8703],
                        [-58.0774, 54.874],
                        [-58.0557, 54.8728],
                        [-58.0451, 54.8879],
                        [-58.0336, 54.8888],
                        [-58.0242, 54.8939],
                        [-58.012, 54.894],
                        [-58.0037, 54.9004],
                        [-57.9871, 54.8992],
                        [-57.9729, 54.9074],
                        [-57.9613, 54.9168],
                        [-57.9508, 54.9215],
                        [-57.9373, 54.9208],
                        [-57.9342, 54.9134],
                        [-57.9239, 54.9116],
                        [-57.9257, 54.8772],
                        [-57.9275, 54.8731],
                        [-57.9441, 54.8732],
                        [-57.9619, 54.8662],
                        [-57.9788, 54.8674],
                        [-57.9861, 54.8639],
                        [-57.9691, 54.856],
                        [-57.9569, 54.8545],
                        [-57.9628, 54.8482],
                        [-57.9601, 54.8381],
                        [-57.9725, 54.8273],
                        [-57.9746, 54.81],
                        [-57.9602, 54.8201],
                        [-57.9505, 54.8176],
                        [-57.9637, 54.8066],
                        [-57.9593, 54.7922],
                        [-57.9685, 54.7898],
                        [-57.9658, 54.7791],
                        [-57.9456, 54.7881],
                        [-57.9241, 54.8153],
                        [-57.902, 54.8293],
                        [-57.8945, 54.8075],
                        [-57.8824, 54.8034],
                        [-57.8695, 54.7929],
                        [-57.8568, 54.7948],
                        [-57.8628, 54.8006],
                        [-57.8605, 54.8081],
                        [-57.8645, 54.8214],
                        [-57.8623, 54.8259],
                        [-57.8445, 54.8324],
                        [-57.8378, 54.8245],
                        [-57.8261, 54.8223],
                        [-57.8232, 54.8167],
                        [-57.8255, 54.8045],
                        [-57.83, 54.8016],
                        [-57.8442, 54.8014],
                        [-57.8487, 54.7985],
                        [-57.8484, 54.7887],
                        [-57.8618, 54.7823],
                        [-57.8858, 54.7761],
                        [-57.8959, 54.7804],
                        [-57.9047, 54.7776],
                        [-57.9169, 54.7787],
                        [-57.9344, 54.776],
                        [-57.9474, 54.7765],
                        [-57.958, 54.7696],
                        [-57.9741, 54.7708],
                        [-57.9811, 54.7654],
                        [-57.9927, 54.7624],
                        [-57.9969, 54.7504],
                        [-58.0029, 54.7471],
                        [-58.0215, 54.7467],
                        [-58.0276, 54.7434],
                        [-58.0365, 54.7465],
                        [-58.063, 54.7453],
                        [-58.0719, 54.7484],
                        [-58.1053, 54.7528],
                        [-58.1158, 54.7471],
                        [-58.1198, 54.7537],
                        [-58.1285, 54.7543],
                        [-58.1503, 54.7428],
                        [-58.1442, 54.7555],
                        [-58.1699, 54.7547],
                        [-58.1741, 54.7484],
                        [-58.1822, 54.7456],
                        [-58.1786, 54.7373],
                        [-58.1365, 54.7353],
                        [-58.1046, 54.7323],
                        [-58.0919, 54.7346],
                        [-58.0817, 54.7337],
                        [-58.0577, 54.7244],
                        [-58.0439, 54.7231],
                        [-58.0278, 54.731],
                        [-58.0053, 54.732],
                        [-58.0036, 54.7261],
                        [-57.9952, 54.7202],
                        [-58.011, 54.7123],
                        [-57.9979, 54.7054],
                        [-57.9869, 54.713],
                        [-57.9697, 54.71],
                        [-57.9662, 54.7159],
                        [-57.9706, 54.7225],
                        [-57.9601, 54.7354],
                        [-57.9503, 54.7345],
                        [-57.9301, 54.7442],
                        [-57.9197, 54.7383],
                        [-57.9039, 54.7326],
                        [-57.8798, 54.7404],
                        [-57.8723, 54.7327],
                        [-57.8597, 54.7316],
                        [-57.8449, 54.7352],
                        [-57.8262, 54.7312],
                        [-57.827, 54.7244],
                        [-57.8163, 54.7093],
                        [-57.8037, 54.7023],
                        [-57.7952, 54.7064],
                        [-57.7819, 54.7053],
                        [-57.7791, 54.6986],
                        [-57.7703, 54.6927],
                        [-57.7641, 54.6762],
                        [-57.7502, 54.6714],
                        [-57.732, 54.6738],
                        [-57.73, 54.6813],
                        [-57.7108, 54.6789],
                        [-57.7249, 54.6668],
                        [-57.7221, 54.6592],
                        [-57.7122, 54.6535],
                        [-57.7102, 54.64],
                        [-57.7063, 54.6331],
                        [-57.6861, 54.6277],
                        [-57.6712, 54.627],
                        [-57.6632, 54.6215],
                        [-57.6235, 54.6221],
                        [-57.6135, 54.6247],
                        [-57.6111, 54.6324],
                        [-57.5962, 54.6367],
                        [-57.5832, 54.6369],
                        [-57.5638, 54.6326],
                        [-57.5559, 54.6397],
                        [-57.5642, 54.6458],
                        [-57.5538, 54.6479],
                        [-57.5432, 54.6548],
                        [-57.5249, 54.6581],
                        [-57.5137, 54.6544],
                        [-57.5182, 54.6447],
                        [-57.4907, 54.65],
                        [-57.4631, 54.6513],
                        [-57.4399, 54.6504],
                        [-57.4275, 54.6464],
                        [-57.4235, 54.6416],
                        [-57.4326, 54.6354],
                        [-57.4451, 54.6231],
                        [-57.4355, 54.613],
                        [-57.4094, 54.6161],
                        [-57.395, 54.6129],
                        [-57.4186, 54.6013],
                        [-57.4199, 54.5931],
                        [-57.4102, 54.591],
                        [-57.3978, 54.5752],
                        [-57.3919, 54.5753],
                        [-57.3828, 54.5877],
                        [-57.3723, 54.5932],
                        [-57.3494, 54.5884],
                        [-57.3367, 54.578],
                        [-57.3211, 54.5754],
                        [-57.3371, 54.5657],
                        [-57.3442, 54.5531],
                        [-57.3684, 54.5431],
                        [-57.366, 54.5319],
                        [-57.3702, 54.5217],
                        [-57.3856, 54.5129],
                        [-57.3709, 54.5103],
                        [-57.3636, 54.501],
                        [-57.376, 54.4976],
                        [-57.3811, 54.492],
                        [-57.3915, 54.4874],
                        [-57.4126, 54.484],
                        [-57.5032, 54.4829],
                        [-57.5289, 54.4805],
                        [-57.5478, 54.4852],
                        [-57.5798, 54.4818],
                        [-57.5874, 54.4787],
                        [-57.6165, 54.4777],
                        [-57.6311, 54.4821],
                        [-57.6409, 54.4828],
                        [-57.6844, 54.4756],
                        [-57.6799, 54.4659],
                        [-57.663, 54.4594],
                        [-57.6321, 54.4591],
                        [-57.6267, 54.4635],
                        [-57.6178, 54.4624],
                        [-57.5585, 54.4695],
                        [-57.5561, 54.4651],
                        [-57.5406, 54.468],
                        [-57.5128, 54.4667],
                        [-57.4994, 54.4692],
                        [-57.4952, 54.4736],
                        [-57.481, 54.4749],
                        [-57.4638, 54.4734],
                        [-57.4172, 54.4654],
                        [-57.3982, 54.4563],
                        [-57.4028, 54.4518],
                        [-57.4182, 54.4508],
                        [-57.4227, 54.4477],
                        [-57.4233, 54.4372],
                        [-57.4351, 54.4365],
                        [-57.4385, 54.4444],
                        [-57.4539, 54.4493],
                        [-57.4526, 54.4579],
                        [-57.4636, 54.4638],
                        [-57.4819, 54.4663],
                        [-57.4957, 54.4643],
                        [-57.4766, 54.4573],
                        [-57.4808, 54.4526],
                        [-57.4903, 54.4509],
                        [-57.491, 54.4457],
                        [-57.502, 54.4446],
                        [-57.5263, 54.4505],
                        [-57.5341, 54.4388],
                        [-57.5155, 54.4291],
                        [-57.4945, 54.4257],
                        [-57.5384, 54.4067],
                        [-57.5531, 54.4031],
                        [-57.578, 54.4048],
                        [-57.5879, 54.397],
                        [-57.5947, 54.3866],
                        [-57.5788, 54.384],
                        [-57.5858, 54.3782],
                        [-57.6037, 54.3802],
                        [-57.6151, 54.3724],
                        [-57.6246, 54.3703],
                        [-57.6362, 54.3728],
                        [-57.6481, 54.3705],
                        [-57.6641, 54.3713],
                        [-57.6864, 54.364],
                        [-57.7253, 54.375],
                        [-57.769, 54.3767],
                        [-57.7834, 54.385],
                        [-57.7976, 54.3761],
                        [-57.8051, 54.3749],
                        [-57.8099, 54.3649],
                        [-57.8256, 54.365],
                        [-57.8494, 54.373],
                        [-57.851, 54.3796],
                        [-57.8421, 54.3925],
                        [-57.8592, 54.3955],
                        [-57.8743, 54.3915],
                        [-57.8815, 54.3872],
                        [-57.8929, 54.3856],
                        [-57.9073, 54.3872],
                        [-57.9154, 54.3819],
                        [-57.9223, 54.385],
                        [-57.931, 54.3756],
                        [-57.9394, 54.3727],
                        [-57.9527, 54.3725],
                        [-57.9768, 54.3757],
                        [-57.9913, 54.3743],
                        [-58.0018, 54.3681],
                        [-58.0198, 54.3666],
                        [-58.0353, 54.3627],
                        [-58.0399, 54.3726],
                        [-58.0518, 54.3781],
                        [-58.0621, 54.3743],
                        [-58.0754, 54.3748],
                        [-58.0788, 54.368],
                        [-58.093, 54.3657],
                        [-58.1027, 54.3665],
                        [-58.1268, 54.3628],
                        [-58.1388, 54.3571],
                        [-58.1641, 54.3577],
                        [-58.1804, 54.3516],
                        [-58.1882, 54.3439],
                        [-58.2003, 54.3436],
                        [-58.2018, 54.3366],
                        [-58.1675, 54.3345],
                        [-58.1704, 54.3306],
                        [-58.1917, 54.3275],
                        [-58.2013, 54.3313],
                        [-58.2054, 54.3261],
                        [-58.2335, 54.327],
                        [-58.2772, 54.3009],
                        [-58.2558, 54.2995],
                        [-58.235, 54.3006],
                        [-58.2113, 54.3077],
                        [-58.206, 54.3118],
                        [-58.1834, 54.3105],
                        [-58.0944, 54.3182],
                        [-58.0842, 54.3119],
                        [-58.1054, 54.3095],
                        [-58.1522, 54.2936],
                        [-58.1551, 54.2891],
                        [-58.1813, 54.2874],
                        [-58.189, 54.2905],
                        [-58.2, 54.29],
                        [-58.2474, 54.2759],
                        [-58.2538, 54.2709],
                        [-58.2277, 54.2619],
                        [-58.2271, 54.2582],
                        [-58.2149, 54.2518],
                        [-58.2135, 54.2479],
                        [-58.2289, 54.2429],
                        [-58.2708, 54.2378],
                        [-58.2915, 54.2388],
                        [-58.3189, 54.2353],
                        [-58.3398, 54.2382],
                        [-58.3626, 54.2349],
                        [-58.3742, 54.2354],
                        [-58.3842, 54.231],
                        [-58.3923, 54.2313],
                        [-58.4066, 54.2366],
                        [-58.4309, 54.2332],
                        [-58.4412, 54.2263],
                        [-58.4569, 54.2238],
                        [-58.4654, 54.2163],
                        [-58.4816, 54.2097],
                        [-58.5271, 54.2021],
                        [-58.5566, 54.2038],
                        [-58.5672, 54.2007],
                        [-58.5792, 54.192],
                        [-58.5774, 54.1881],
                        [-58.6125, 54.1636],
                        [-58.633, 54.1561],
                        [-58.6655, 54.1489],
                        [-58.6758, 54.1445],
                        [-58.6977, 54.14],
                        [-58.7273, 54.1387],
                        [-58.7414, 54.1356],
                        [-58.7499, 54.1362],
                        [-58.7695, 54.1323],
                        [-58.7821, 54.1276],
                        [-58.7891, 54.1279],
                        [-58.833, 54.1416],
                        [-58.8493, 54.1413],
                        [-58.8938, 54.1365],
                        [-58.9142, 54.1299],
                        [-58.9264, 54.1238],
                        [-58.9694, 54.1158],
                        [-58.9932, 54.1126],
                        [-59.004, 54.1127],
                        [-59.017, 54.1068],
                        [-59.0353, 54.1063],
                        [-59.0455, 54.1032],
                        [-59.0711, 54.1018],
                        [-59.1086, 54.0946],
                        [-59.1319, 54.0948],
                        [-59.1499, 54.0892],
                        [-59.1697, 54.088],
                        [-59.1974, 54.0816],
                        [-59.2231, 54.0806],
                        [-59.2316, 54.0788],
                        [-59.2434, 54.0723],
                        [-59.2532, 54.0703],
                        [-59.2903, 54.0585],
                        [-59.3016, 54.0526],
                        [-59.3578, 54.0354],
                        [-59.3792, 54.0312],
                        [-59.3886, 54.026],
                        [-59.4126, 54.0265],
                        [-59.4243, 54.0239],
                        [-59.4472, 54.0233],
                        [-59.4704, 54.0271],
                        [-59.492, 54.0338],
                        [-59.5126, 54.0328],
                        [-59.5467, 54.0334],
                        [-59.558, 54.0254],
                        [-59.5724, 54.026],
                        [-59.5756, 54.0164],
                        [-59.5551, 54.012],
                        [-59.5342, 54.0112],
                        [-59.5198, 54.0129],
                        [-59.5082, 54.0101],
                        [-59.5044, 54.006],
                        [-59.5142, 53.9997],
                        [-59.5158, 53.9951],
                        [-59.5077, 53.9882],
                        [-59.4914, 53.9888],
                        [-59.4844, 53.9924],
                        [-59.4634, 53.9962],
                        [-59.4494, 54.0007],
                        [-59.4394, 53.9982],
                        [-59.3611, 53.9973],
                        [-59.3136, 54.0086],
                        [-59.3004, 54.0097],
                        [-59.2662, 54.019],
                        [-59.2502, 54.0217],
                        [-59.2112, 54.0344],
                        [-59.131, 54.048],
                        [-59.1095, 54.0542],
                        [-59.0897, 54.0547],
                        [-59.0741, 54.058],
                        [-59.0512, 54.0596],
                        [-59.0264, 54.0567],
                        [-59.0026, 54.0592],
                        [-58.9852, 54.0579],
                        [-58.9731, 54.0622],
                        [-58.9667, 54.0678],
                        [-58.9321, 54.0716],
                        [-58.921, 54.0772],
                        [-58.8981, 54.0784],
                        [-58.8805, 54.0832],
                        [-58.8645, 54.0849],
                        [-58.8062, 54.1012],
                        [-58.7925, 54.1035],
                        [-58.7845, 54.1126],
                        [-58.775, 54.1168],
                        [-58.7434, 54.1222],
                        [-58.7312, 54.1257],
                        [-58.7275, 54.1202],
                        [-58.7096, 54.1198],
                        [-58.6946, 54.1166],
                        [-58.6801, 54.1197],
                        [-58.6595, 54.1181],
                        [-58.6858, 54.1357],
                        [-58.6674, 54.1378],
                        [-58.6231, 54.1498],
                        [-58.6093, 54.1549],
                        [-58.5966, 54.1623],
                        [-58.5877, 54.1709],
                        [-58.5779, 54.1755],
                        [-58.5677, 54.1766],
                        [-58.5464, 54.1845],
                        [-58.5301, 54.1834],
                        [-58.5171, 54.1869],
                        [-58.4675, 54.1926],
                        [-58.4556, 54.1973],
                        [-58.44, 54.2069],
                        [-58.4263, 54.2188],
                        [-58.4097, 54.225],
                        [-58.3988, 54.2266],
                        [-58.3729, 54.2199],
                        [-58.397, 54.2008],
                        [-58.3989, 54.1922],
                        [-58.4181, 54.1803],
                        [-58.4168, 54.1728],
                        [-58.4375, 54.1598],
                        [-58.4345, 54.1488],
                        [-58.4393, 54.1436],
                        [-58.4567, 54.1368],
                        [-58.4787, 54.132],
                        [-58.4962, 54.1211],
                        [-58.5231, 54.1169],
                        [-58.5338, 54.1133],
                        [-58.5096, 54.1039],
                        [-58.5493, 54.0818],
                        [-58.5569, 54.0737],
                        [-58.5652, 54.0697],
                        [-58.5627, 54.0644],
                        [-58.5683, 54.0583],
                        [-58.5849, 54.0514],
                        [-58.5745, 54.0485],
                        [-58.599, 54.0342],
                        [-58.6135, 54.0328],
                        [-58.6237, 54.0272],
                        [-58.6358, 54.0264],
                        [-58.6527, 54.0303],
                        [-58.6832, 54.0229],
                        [-58.7266, 54.0234],
                        [-58.7461, 54.0208],
                        [-58.7561, 54.0221],
                        [-58.7903, 54.0339],
                        [-58.8457, 54.0379],
                        [-58.8557, 54.0409],
                        [-58.8903, 54.0369],
                        [-58.9061, 54.0394],
                        [-58.9488, 54.0416],
                        [-59.0116, 54.0276],
                        [-59.0282, 54.0282],
                        [-59.0489, 54.0217],
                        [-59.0448, 54.0153],
                        [-59.0176, 54.0178],
                        [-58.9867, 54.0155],
                        [-58.9692, 54.0169],
                        [-58.9541, 54.015],
                        [-58.9243, 54.0144],
                        [-58.9205, 54.0119],
                        [-58.9173, 53.9991],
                        [-58.9359, 53.9995],
                        [-58.94, 53.9922],
                        [-58.949, 53.987],
                        [-58.9433, 53.9827],
                        [-58.9505, 53.9766],
                        [-58.9756, 53.9626],
                        [-58.9888, 53.9602],
                        [-59.0072, 53.9517],
                        [-59.0325, 53.9494],
                        [-59.043, 53.9461],
                        [-59.0585, 53.9462],
                        [-59.0765, 53.9516],
                        [-59.0908, 53.9534],
                        [-59.1134, 53.949],
                        [-59.1368, 53.9409],
                        [-59.167, 53.9384],
                        [-59.22, 53.9231],
                        [-59.2332, 53.9165],
                        [-59.3195, 53.8924],
                        [-59.3538, 53.8789],
                        [-59.3547, 53.8696],
                        [-59.3644, 53.8649],
                        [-59.3873, 53.8579],
                        [-59.4013, 53.8571],
                        [-59.4303, 53.852],
                        [-59.4493, 53.845],
                        [-59.4598, 53.8391],
                        [-59.4816, 53.8319],
                        [-59.5481, 53.8233],
                        [-59.5589, 53.8197],
                        [-59.5828, 53.8198],
                        [-59.5959, 53.8224],
                        [-59.6083, 53.822],
                        [-59.6329, 53.8273],
                        [-59.6746, 53.8282],
                        [-59.6923, 53.8339],
                        [-59.7155, 53.8336],
                        [-59.724, 53.8363],
                        [-59.7278, 53.845],
                        [-59.7598, 53.8396],
                        [-59.7683, 53.8399],
                        [-59.7807, 53.8452],
                        [-59.7899, 53.8447],
                        [-59.8413, 53.8467],
                        [-59.8575, 53.8435],
                        [-59.861, 53.8371],
                        [-59.878, 53.8344],
                        [-59.8831, 53.8246],
                        [-59.8912, 53.8171],
                        [-59.8704, 53.8179],
                        [-59.86, 53.8122],
                        [-59.8299, 53.8115],
                        [-59.8048, 53.8062],
                        [-59.7968, 53.8004],
                        [-59.7999, 53.7918],
                        [-59.8053, 53.7897],
                        [-59.8308, 53.787],
                        [-59.8474, 53.7873],
                        [-59.8809, 53.7954],
                        [-59.9052, 53.792],
                        [-59.9183, 53.7924],
                        [-59.9303, 53.7884],
                        [-59.9334, 53.7836],
                        [-59.9542, 53.7891],
                        [-59.9793, 53.7877],
                        [-59.9841, 53.7835],
                        [-60, 53.7835],
                        [-60.0037, 53.7774],
                        [-60.0165, 53.7765],
                        [-60.0287, 53.7715],
                        [-60.0326, 53.7655],
                        [-60.0549, 53.7655],
                        [-60.0727, 53.7579],
                        [-60.0962, 53.7599],
                        [-60.1111, 53.7664],
                        [-60.1178, 53.7579],
                        [-60.105, 53.7576],
                        [-60.1007, 53.7491],
                        [-60.094, 53.7456],
                        [-60.0673, 53.7385],
                        [-60.0623, 53.7309],
                        [-60.0657, 53.7248],
                        [-60.0425, 53.7176],
                        [-60.0263, 53.7161],
                        [-60.0297, 53.7097],
                        [-60.0508, 53.7095],
                        [-60.0733, 53.7162],
                        [-60.084, 53.7077],
                        [-60.084, 53.6961],
                        [-60.0926, 53.6955],
                        [-60.1043, 53.6887],
                        [-60.1106, 53.6809],
                        [-60.1125, 53.6698],
                        [-60.1062, 53.6641],
                        [-60.107, 53.6493],
                        [-60.1197, 53.642],
                        [-60.124, 53.6261],
                        [-60.1317, 53.6221],
                        [-60.1241, 53.6096],
                        [-60.1202, 53.5956],
                        [-60.1005, 53.5788],
                        [-60.0835, 53.5746],
                        [-60.0918, 53.5655],
                        [-60.086, 53.5581],
                        [-60.0995, 53.5517],
                        [-60.116, 53.5477],
                        [-60.1377, 53.5388],
                        [-60.1388, 53.5191],
                        [-60.1293, 53.506],
                        [-60.1223, 53.4998],
                        [-60.1114, 53.4958],
                        [-60.0975, 53.4982],
                        [-60.0902, 53.5031],
                        [-60.0812, 53.4996],
                        [-60.0669, 53.5028],
                        [-60.0497, 53.4869],
                        [-60.0439, 53.4749],
                        [-60.0476, 53.4675],
                        [-60.0411, 53.4556],
                        [-60.0357, 53.4407],
                        [-60.0279, 53.4377],
                        [-60.0023, 53.3962],
                        [-59.9907, 53.3947],
                        [-59.972, 53.3995],
                        [-59.9597, 53.4091],
                        [-59.9417, 53.4191],
                        [-59.9329, 53.4381],
                        [-59.9072, 53.4561],
                        [-59.9037, 53.4622],
                        [-59.9056, 53.4725],
                        [-59.8979, 53.472],
                        [-59.8842, 53.4642],
                        [-59.8792, 53.4583],
                        [-59.87, 53.4619],
                        [-59.8898, 53.4853],
                        [-59.868, 53.4944],
                        [-59.8489, 53.4852],
                        [-59.8508, 53.4761],
                        [-59.8432, 53.4726],
                        [-59.8252, 53.4781],
                        [-59.7984, 53.4753],
                        [-59.7903, 53.4762],
                        [-59.7837, 53.4862],
                        [-59.7959, 53.4951],
                        [-59.8193, 53.4945],
                        [-59.8365, 53.4982],
                        [-59.8561, 53.4987],
                        [-59.8717, 53.5129],
                        [-59.8847, 53.5156],
                        [-59.9047, 53.515],
                        [-59.9123, 53.5193],
                        [-59.9016, 53.5259],
                        [-59.8486, 53.5402],
                        [-59.8263, 53.5406],
                        [-59.8187, 53.5322],
                        [-59.7874, 53.5173],
                        [-59.7594, 53.511],
                        [-59.7499, 53.5103],
                        [-59.7257, 53.5134],
                        [-59.7157, 53.5189],
                        [-59.7114, 53.5301],
                        [-59.7029, 53.5348],
                        [-59.6731, 53.5249],
                        [-59.6505, 53.5221],
                        [-59.6291, 53.522],
                        [-59.5826, 53.5266],
                        [-59.5734, 53.5284],
                        [-59.5545, 53.5395],
                        [-59.5098, 53.5548],
                        [-59.4859, 53.5664],
                        [-59.4734, 53.5796],
                        [-59.4699, 53.5871],
                        [-59.4602, 53.5909],
                        [-59.4536, 53.6],
                        [-59.4412, 53.6088],
                        [-59.4195, 53.6195],
                        [-59.3821, 53.6304],
                        [-59.3747, 53.6343],
                        [-59.369, 53.6537],
                        [-59.3593, 53.6573],
                        [-59.3498, 53.6524],
                        [-59.3402, 53.6517],
                        [-59.3274, 53.6552],
                        [-59.3165, 53.6666],
                        [-59.3197, 53.6782],
                        [-59.3189, 53.6855],
                        [-59.3072, 53.6946],
                        [-59.2948, 53.6941],
                        [-59.2845, 53.6904],
                        [-59.2808, 53.6844],
                        [-59.2673, 53.685],
                        [-59.273, 53.6706],
                        [-59.2227, 53.6858],
                        [-59.2141, 53.6908],
                        [-59.2021, 53.693],
                        [-59.2008, 53.6845],
                        [-59.1929, 53.6756],
                        [-59.178, 53.6702],
                        [-59.1583, 53.6705],
                        [-59.134, 53.6774],
                        [-59.113, 53.6859],
                        [-59.098, 53.6844],
                        [-59.0888, 53.6857],
                        [-59.094, 53.6739],
                        [-59.0672, 53.6871],
                        [-59.0642, 53.6997],
                        [-59.0747, 53.7125],
                        [-59.0645, 53.7216],
                        [-59.0532, 53.7245],
                        [-59.0464, 53.7189],
                        [-59.0349, 53.7195],
                        [-59.0177, 53.7109],
                        [-59.0073, 53.711],
                        [-58.9988, 53.716],
                        [-59.0005, 53.7217],
                        [-58.9931, 53.7251],
                        [-59.0038, 53.73],
                        [-59.0055, 53.7403],
                        [-58.9992, 53.7429],
                        [-58.985, 53.7428],
                        [-58.9664, 53.7452],
                        [-58.979, 53.7503],
                        [-58.9968, 53.7495],
                        [-59.0055, 53.7567],
                        [-59.0235, 53.7598],
                        [-59.0328, 53.7588],
                        [-59.0341, 53.767],
                        [-59.0286, 53.7718],
                        [-59.0353, 53.7828],
                        [-59.0418, 53.7863],
                        [-59.0571, 53.788],
                        [-59.0414, 53.8018],
                        [-59.0274, 53.8071],
                        [-59.0218, 53.8155],
                        [-59.01, 53.8232],
                        [-58.968, 53.8345],
                        [-58.9605, 53.8438],
                        [-58.9503, 53.8494],
                        [-58.9202, 53.8603],
                        [-58.9029, 53.8711],
                        [-58.8912, 53.8753],
                        [-58.8918, 53.8835],
                        [-58.8739, 53.8884],
                        [-58.8507, 53.8891],
                        [-58.8551, 53.895],
                        [-58.8477, 53.9265],
                        [-58.8526, 53.9297],
                        [-58.8391, 53.9415],
                        [-58.8429, 53.9458],
                        [-58.8225, 53.9541],
                        [-58.8115, 53.9446],
                        [-58.8012, 53.9411],
                        [-58.7968, 53.9451],
                        [-58.7804, 53.9498],
                        [-58.7576, 53.9502],
                        [-58.7268, 53.9551],
                        [-58.7192, 53.9518],
                        [-58.704, 53.9539],
                        [-58.7023, 53.9592],
                        [-58.6856, 53.9615],
                        [-58.66, 53.9744],
                        [-58.6502, 53.9775],
                        [-58.6246, 53.9795],
                        [-58.6003, 53.9867],
                        [-58.584, 53.987],
                        [-58.5631, 53.9973],
                        [-58.5569, 54.007],
                        [-58.5228, 54.0082],
                        [-58.5023, 54.0065],
                        [-58.4841, 54.0161],
                        [-58.4718, 54.0132],
                        [-58.4483, 54.0202],
                        [-58.4438, 54.0249],
                        [-58.4167, 54.0244],
                        [-58.4092, 54.0188],
                        [-58.4005, 54.0225],
                        [-58.3795, 54.0229],
                        [-58.3622, 54.0302],
                        [-58.3583, 54.0384],
                        [-58.35, 54.0433],
                        [-58.3244, 54.0532],
                        [-58.3115, 54.0546],
                        [-58.2868, 54.0517],
                        [-58.2791, 54.0585],
                        [-58.2684, 54.056],
                        [-58.2472, 54.0617],
                        [-58.2379, 54.0606],
                        [-58.2079, 54.0627],
                        [-58.1914, 54.0681],
                        [-58.1794, 54.0746],
                        [-58.1769, 54.0796],
                        [-58.1496, 54.0912],
                        [-58.1382, 54.0938],
                        [-58.106, 54.0933],
                        [-58.0929, 54.0985],
                        [-58.0851, 54.098],
                        [-58.07, 54.0888],
                        [-58.046, 54.0868],
                        [-58.0226, 54.0887],
                        [-58.0044, 54.0793],
                        [-57.9885, 54.0788],
                        [-57.9706, 54.0732],
                        [-57.9516, 54.0722],
                        [-57.939, 54.0665],
                        [-57.9227, 54.0662],
                        [-57.9051, 54.0702],
                        [-57.8927, 54.0698],
                        [-57.8799, 54.0773],
                        [-57.8546, 54.0776],
                        [-57.8464, 54.0701],
                        [-57.8304, 54.073],
                        [-57.813, 54.0697],
                        [-57.806, 54.0632],
                        [-57.7808, 54.0634],
                        [-57.7676, 54.0691],
                        [-57.7714, 54.0795],
                        [-57.7849, 54.0811],
                        [-57.7881, 54.0864],
                        [-57.7805, 54.0904],
                        [-57.8019, 54.0962],
                        [-57.8259, 54.0987],
                        [-57.8408, 54.1045],
                        [-57.8473, 54.0989],
                        [-57.8574, 54.0993],
                        [-57.8691, 54.1059],
                        [-57.8799, 54.1068],
                        [-57.9072, 54.1057],
                        [-57.9228, 54.0955],
                        [-57.9349, 54.095],
                        [-57.9453, 54.0977],
                        [-57.9577, 54.1047],
                        [-57.956, 54.1093],
                        [-57.9817, 54.1163],
                        [-57.9921, 54.117],
                        [-58.0349, 54.1245],
                        [-58.1015, 54.1304],
                        [-58.114, 54.1288],
                        [-58.1331, 54.1291],
                        [-58.152, 54.1333],
                        [-58.1684, 54.131],
                        [-58.1812, 54.1221],
                        [-58.1895, 54.1197],
                        [-58.2271, 54.1132],
                        [-58.2448, 54.0998],
                        [-58.2498, 54.09],
                        [-58.2644, 54.0859],
                        [-58.3161, 54.0855],
                        [-58.3155, 54.1006],
                        [-58.3259, 54.1112],
                        [-58.3487, 54.1159],
                        [-58.3747, 54.1153],
                        [-58.3868, 54.1235],
                        [-58.401, 54.1285],
                        [-58.3866, 54.1587],
                        [-58.379, 54.1654],
                        [-58.373, 54.1872],
                        [-58.3607, 54.1951],
                        [-58.345, 54.198],
                        [-58.3192, 54.1988],
                        [-58.3074, 54.2012],
                        [-58.2854, 54.1976],
                        [-58.2553, 54.1993],
                        [-58.2474, 54.2028],
                        [-58.2342, 54.2017],
                        [-58.202, 54.208],
                        [-58.2194, 54.2104],
                        [-58.2231, 54.2154],
                        [-58.2002, 54.2219],
                        [-58.1938, 54.2257],
                        [-58.1843, 54.2201],
                        [-58.175, 54.2286],
                        [-58.153, 54.2306],
                        [-58.1295, 54.2259],
                        [-58.1208, 54.2193],
                        [-58.0924, 54.2193],
                        [-58.0759, 54.2211],
                        [-58.0675, 54.2251],
                        [-58.0554, 54.226],
                        [-58.0442, 54.2316],
                        [-58.0293, 54.227],
                        [-58.0099, 54.2248],
                        [-58.0016, 54.2192],
                        [-57.9846, 54.2246],
                        [-57.9617, 54.2142],
                        [-57.9516, 54.2131],
                        [-57.944, 54.2173],
                        [-57.9289, 54.2157],
                        [-57.9263, 54.212],
                        [-57.9049, 54.2114],
                        [-57.8778, 54.2088],
                        [-57.8561, 54.2144],
                        [-57.8459, 54.208],
                        [-57.8393, 54.2006],
                        [-57.8297, 54.1963],
                        [-57.8206, 54.2003],
                        [-57.814, 54.2077],
                        [-57.7972, 54.2081],
                        [-57.7926, 54.2139],
                        [-57.7761, 54.2154],
                        [-57.7458, 54.2146],
                        [-57.7405, 54.2111],
                        [-57.7491, 54.2037],
                        [-57.7581, 54.2021],
                        [-57.7706, 54.1882],
                        [-57.757, 54.1865],
                        [-57.7527, 54.1949],
                        [-57.7365, 54.1998],
                        [-57.6943, 54.202],
                        [-57.6883, 54.2049],
                        [-57.6672, 54.2051],
                        [-57.6622, 54.2032],
                        [-57.6403, 54.2046],
                        [-57.6167, 54.2028],
                        [-57.6037, 54.1988],
                        [-57.6084, 54.1909],
                        [-57.5866, 54.1848],
                        [-57.5958, 54.1802],
                        [-57.5939, 54.1726],
                        [-57.609, 54.1736],
                        [-57.6307, 54.1681],
                        [-57.603, 54.1637],
                        [-57.5814, 54.1655],
                        [-57.5757, 54.1779],
                        [-57.5561, 54.1803],
                        [-57.5495, 54.1918],
                        [-57.5289, 54.1857],
                        [-57.5274, 54.177],
                        [-57.5137, 54.178],
                        [-57.5054, 54.1866],
                        [-57.4968, 54.1873],
                        [-57.4882, 54.1942],
                        [-57.4614, 54.1929],
                        [-57.4523, 54.1957],
                        [-57.4416, 54.1854],
                        [-57.4312, 54.1847],
                        [-57.3982, 54.1763],
                        [-57.4046, 54.1675],
                        [-57.3877, 54.1637],
                        [-57.3912, 54.1569],
                        [-57.403, 54.156],
                        [-57.4026, 54.1496],
                        [-57.3843, 54.1492]
                    ]
                ],
            },
        },
    ],
}