import React from 'react'
import SliderGallery from '../components/SliderGallery'



const CoolstepGallery = () => {


  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-18.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-6-1.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-7-1.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-9.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-8.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-7.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-6.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-5.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-4.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-3.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-1.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-22.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-21.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-19.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-17.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-16.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-15.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-14.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-13.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-12.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-11.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-10.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_162096402_COOLSTEP-min.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_119844442_COOLSTEP-min.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_119843923_COOLSTEP-min.jpg"
    },

  ]

  return (
    <div>
      <SliderGallery
        title="Coolstep"
        para1="A light, neutral pattern, high reflectance, and high traction equals a product perfect to waterproof a rooftop or patio deck located in a warmer climate. Our original CoolStep can bring a lighter, airier feel to your living space."
        galleryImages={galleryImages}
        previouslink="/products/membranes/coolstep-line"
        nextlink="/coolstep-copper-gallery/"
      />
    </div>
  )
}

export default CoolstepGallery
