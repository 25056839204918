import React from 'react'
import SliderGallery from '../components/SliderGallery'



const CoolstepSlateGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_65741670-Slate.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_69012624-Slate.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Slate-5.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Slate-4.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Slate-3.jpg"
    },
    
  ]

  return (
    <div>
      <SliderGallery
        title="Coolstep Slate"
        para1="CoolStep Slate gives you the best of both worlds – combining a high solar reflective index with high definition tile prints – something the industry has never seen before! The large designer tiles of CoolStep Slate make it an excellent choice for warm sunny climates. Enjoy the outdoors all year long!."
        galleryImages={galleryImages}
        previouslink="/coolstep-sandalwood-gallery/"
      />
    </div>
  )
}

export default CoolstepSlateGallery
