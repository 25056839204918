import React from 'react'
import SliderGallery from '../components/SliderGallery'



const BronzeGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-24.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-3.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-4.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-2.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-1.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-23.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-21.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-20.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-19-rotated.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-18.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-17.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-16.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-15.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-14.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-13.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-14.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-12.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-5.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-11.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-10.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-9.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-10.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-8-rotated.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-10.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-7.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Bronze-6.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/iStock-175998824_bronze-edt.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_107481535_BRONZE-min.jpg"
    },
   
  ]

  return (
    <div>
      <SliderGallery
        title="Bronze"
        para1="A neutral winner, Bronze is another hit with customers. Beautiful against brick and stucco and perfect for contrast against cool colors, this vinyl decking product is a clear winner."
        galleryImages={galleryImages}
        previouslink="/ashdown-gallery/"
        nextlink="/brick-gallery/"
      />
    </div>
  )
}

export default BronzeGallery
