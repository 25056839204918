import React from 'react'
import { Container, Grid } from '@mui/material';
import TitleCard from '../components/TitleCard';

const PrivacyPolicy = () => {

    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/home/kotagauni-srinivas-481125-unsplash-min.jpg",
            firstText: 'Privacy',
            boldText: 'Policy',
            description: '',
            isImageOnlyFlag: true,
            textShow: true,
            textDirect: true,
            textAlign: true,
            bgOpacity: "rgba(0, 0, 0, 0.5)",
            cartHeight: "100vh",
            bgAttachment: 'initial',
            bgPosition: 'center right',
        },
    ]
    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>

            <Container maxWidth="lg" sx={{ padding: "10% 0% 0% 0%" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div>
                        <h3 className='heading-title' style={{ padding: "10px 9%", }}><span className='text-with-border'>PRIVACY POLICY</span><span className='title-bold '> SUMMARY</span></h3>

                        <div className='paragraph' style={{ padding: "40px 9%" }}>
                            <ol>
                                <li>Personal Information: Defined as personal information about an identifiable individual</li>
                                <li>How we collect personal information:
                                    <ul style={{listStyleType:"disc"}}>
                                        <li>Directly from you when you sign up for newsletters, training waitlists, request documentation, and/or contact us through the forms built into our site</li>
                                        <li>Certain data is automatically collected when you use our website.</li>
                                    </ul>

                                    </li>
                                  
                                    <p>&nbsp;</p>
                                
                                <li>Information we collect:
                                    <ul style={{listStyleType:"disc"}}>
                                            <li>Your name;</li>
                                            <li>E-mail address;</li>
                                            <li>Computer IP address;</li>
                                            <li>Geographical location when you enable location tracking in our app; and</li>
                                            <li>Information from cookies.</li></ul><p>&nbsp;</p></li>
                                <li>Why we need this information:
                                    <ul style={{listStyleType:"disc"}}>
                                        <li>For precise weather information and alerts;</li>
                                        <li>In order to provide you with the information requested;</li>
                                        <li>For use in academic research purposes</li></ul>
                                </li>
                            </ol>
                            <p>Please note that if you have never signed up for a newsletter, training waitlist, or submitted information on our website, we do not collect personal information about you and we have no way of identifying you.</p><p>&nbsp;</p><ol start="5">
                                <li>Aggregated information to third parties: We provide anonymous information to advertisers and advertising networks that require the data to select and serve ads that you will find more relevant.</li>
                                </ol>
                        </div>



                    </div>
                </Grid>
            </Container>

        </div>

    )
}

export default PrivacyPolicy
