import React from 'react'
import SliderGallery from '../components/SliderGallery'



const GraniteGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-24.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-18.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-17.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-16.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-15.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-14.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-13.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-12.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-11-rotated.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-5.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-15.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-22.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-21.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-19.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-17.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-16.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-15.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-14.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-13.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-12.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-11.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-10.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-10-rotated.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-9.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-19.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-8-rotated.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-7.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-6.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-5.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-4.jpg"
    },

  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-3.jpg"
  },
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-2.jpg"
  },
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-1-rotated.jpg"
  },
  
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-25.jpg"
  },
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-23.jpg"
  },
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-22.jpg"
  },
    
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-21.jpg"
  },
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Granite-20.jpg"
  },
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/DT-CL-Granite-1.jpg"
  },
  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_102098745_GRANITE-min.jpg"
  },

  {
    img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_35503998_GRANITE-min.jpg"
  },



  ]


  return (
    <div>
      <SliderGallery
        title="Granite"
        para1="One of our most popular membranes, Granite is a stunning design. Save time and maintenance of your current deck with granite’s cool colors, perfect for creating a contrast in warm color tones and complementing greys and blues."
        galleryImages={galleryImages}
        previouslink="/brick-gallery/"
      />
    </div>
  )
}

export default GraniteGallery
