import React from 'react'
import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import TitleCard from '../components/TitleCard';

const TechnicalGuides = () => {
    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/jenna-jacobs-533665-unsplash-min.jpg",
            firstText: 'TECHNICAL',
            boldText: 'GUIDES',
            description: 'We at Dec-Tec understand there is a need for detailed installation manuals and even more specific advanced installation guides. ',
            isImageOnlyFlag: true,
            textShow: true,
            textDirect: true,
            textAlign: true,
            cartHeight: '85vh',
            bgAttachment: 'initial',
            bgPosition: 'center bottom',
        },
    ];
    return (
        <div>

            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>

            <Container maxWidth='lg'>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='product-div-wrap'style={{padding:"10% 0"}}>
                            <div className='elementor'>
                                <h1 className='heading-title font-size'> <span className='title-bold'>INSTALLATION </span> </h1>
                                <div className='divider' style={{ width: '25%' }}></div>
                            </div>
                            <div className='paragraph ' style={{textAlign:"center"}}>
                                <p >
                                    The <b>CM-001: Basic Installation Guide – Wood Substrate</b> guide provides recommended installation practices for each Dec-Tec product line
                                </p>
                                <p >
                                    <b>Topics Covered :</b> Pre-Installation, Installation
                                </p>
                            </div>
                            <div className='elementor-button'>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/CM-001-Basic-Installation-Guide-Wood-Substrate-V.03.pdf" target={'_blank'} rel="noreferrer">
                                    <button className='btnSecond'>Download</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='product-div-wrap' style={{padding:"10% 0"}}>
                            <div className='elementor'>
                                <h1 className='heading-title font-size'> <span className='title-bold'>MAINTENANCE </span> </h1>
                                <div className='divider' style={{ width: '25%' }}></div>
                            </div>
                            <div className='paragraph ' style={{textAlign:"center"}}>
                                <p >
                                    The <b>AM-001: Care & Maintenance</b> guide shows you the proper steps to take to properly care for and maintain your Dec-Tec deck.
                                </p>
                                <p >
                                    <b>Topics Covered :</b> Maintenance
                                </p>
                            </div>
                            <div className='elementor-button'>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/AM001-Dec-Tec-Care-Maintenance-Guide-v.01.pdf" target={'_blank'} rel="noreferrer">
                                    <button className='btnSecond'>Download</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default TechnicalGuides
