import React from 'react'
import { Container, Grid } from '@mui/material';
import TitleCard from '../components/TitleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const InstallationVideoNew = () => {
    const navigate = useNavigate();

    const handleVideoClick = (video) => {
        navigate(`/video-detail/${video.id}`, { state: { video } });
    };

    const videoList = [
        {
            id:"1",
            title: 'Before You Start',
            url: "https://streams.bitmovin.com/cnu79atsrjnnig8japag/manifest.m3u8",
            maintitle: "Segment 1",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-103.png"
        },
        {
            id:"2",
            title: 'Structure and Substrate Inspection',
            url: "https://streams.bitmovin.com/cnu7autsrjnnig8japd0/manifest.m3u8",
            maintitle: "Segment 2",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-102.png"
        },
        {
            id:"3",
            title: 'Approved Substrate',
            url: "https://streams.bitmovin.com/cnu7afd100s5p8qiidl0/manifest.m3u8",
            maintitle: "Segment 3",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-104.png"
        },
        {
            id:"4",
            title: 'Dec-Drain',
             url: "https://streams.bitmovin.com/cnu79ot100s5p8qiidkg/manifest.m3u8",
            maintitle: "Segment 4",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-105.png"
        },
        {
            id:"5",
            title: 'Dec-Scupper',
            url: "https://streams.bitmovin.com/cnu7bft100s5p8qiidn0/manifest.m3u8",
            maintitle: "Segment 5",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-107.png"
        },
        {
            id:"6",
            title: 'Dec-Clad Drip Edge',
            url: "https://streams.bitmovin.com/cnu7a9dsrjnnig8japbg/manifest.m3u8",
            maintitle: "Segment 6",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-108.png"
        },
        {
            id:"7",
            title: 'Dec-Clad Kickout',
            url: "https://streams.bitmovin.com/cnu7bad100s5p8qiidmg/manifest.m3u8",
            maintitle: "Segment 7",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-109.png"
        },
        {
            id:"8",
            title: 'Dec-Corners',
            url: "https://streams.bitmovin.com/cnu7b75100s5p8qiidm0/manifest.m3u8",
            maintitle: "Segment 8",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-110.png"
        },
        {
            id:"9",
            title: 'Substrate Preparation',
            url: "https://streams.bitmovin.com/cnu79jd100s5p8qiidk0/manifest.m3u8",
            maintitle: "Segment 9",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-111.png"
        },
        {
            id:"10",
            title: 'Adhesives',
            url: "https://streams.bitmovin.com/cnu79fcd1q7m56d4aslg/manifest.m3u8",
            maintitle: "Segment 10",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-99.png"
        },
        {
            id:"11",
            title: 'Membrane Lay Out: Dry Fit',
            url: "https://streams.bitmovin.com/cnu7cblsrjnnig8japdg/manifest.m3u8",
            maintitle: "Segment 11",
            poster:"https://streams.bitmovin.com/cnu79fcd1q7m56d4aslg/manifest.m3u8"
        },
        {
            id:"12",
            title: 'Membrane Lay',
            url: "https://streams.bitmovin.com/cnu7antsrjnnig8japcg/manifest.m3u8",
            maintitle: "Segment 12",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-114.png"
        },
        {
            id:"13",
            title: 'Wall Application',
            url: "https://streams.bitmovin.com/cnu7925srjnnig8japa0/manifest.m3u8",
            maintitle: "Segment 13",
            poster:"https://streams.bitmovin.com/cnu7antsrjnnig8japcg/manifest.m3u8"
        },
        {
            id:"14",
            title: 'Outside Corner Detail',
            url: "https://streams.bitmovin.com/cnu79vlsrjnnig8japb0/manifest.m3u8",
            maintitle: "Segment 14",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-117.png"
        },
        {
            id:"15",
            title: 'Inside Corner Detail',
            url: "https://streams.bitmovin.com/cnu7c0t100s5p8qiidog/manifest.m3u8",
            maintitle: "Segment 15",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-121.png"
        },
        {
            id:"16",
            title: 'Door Sill Detail',
            url: "https://streams.bitmovin.com/cnu7ailsrjnnig8japc0/manifest.m3u8",
            maintitle: "Segment 16",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-122.png"
        },
        {
            id:"17",
            title: 'Post Wrap Detail',
            url: "https://streams.bitmovin.com/cnu7brt100s5p8qiido0/manifest.m3u8",
            maintitle: "Segment 17",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-123.png"
        },
        {
            id:"18",
            title: 'Trimming',
            url: "https://streams.bitmovin.com/cnu78td100s5p8qiidjg/manifest.m3u8",
            maintitle: "Segment 18",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-126.png"
        },
        {
            id:"19",
            title: 'Heat Welding',
            url: "https://streams.bitmovin.com/cnu7bnd100s5p8qiidng/manifest.m3u8",
            maintitle: "Segment 19",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-127.png"
        },
        {
            id:"20",
            title: 'Final Checks',
            url: "https://streams.bitmovin.com/cnu7b3d100s5p8qiidlg/manifest.m3u8",
            maintitle: "Segment 20",
            poster:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-128.png"
        },
    ];

    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/ross-sneddon-0MBt0sGU8UA-unsplash.jpg",
            firstText: 'INSTALLATION',
            boldText: 'VIDEOS',
            description: 'Our video clips walk you through the detailed steps and the specific products required during the installation process.',
            description2: 'Make sure to also refer to the other technical documentation to gain a full understanding of the installation process.',
            isImageOnlyFlag: true,
            textShow: false,
            textDirect: false,
            textAlign: false,
            cartHeight: '100vh',
            bgAttachment: 'initial',
            bgPosition: 'center center',
        },
    ];

    return (
        <div>
            {/* Title Card */}
            <Grid item xs={12} >
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>

            {/* Video Grid */}
            <Container maxWidth='lg' >
                <section style={{ textAlign: "left", alignItems: "center", padding: "100px 0px" }} >
                    <div className='Wrapper '>
                        <section className='classic-section-fourth'>
                            <div className='elementor'>
                                <h3 className='heading-title'> <span className='title-bold'>INSTALLATION </span> VIDEOS</h3>
                                <div className='divider' style={{ width: '5%' }}></div>
                            </div>
                        </section>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }} className='videolink-padding'>
                            {videoList.map((video) => (

                                <Grid item md={4} lg={4} sm={4} xs={12}>
                                    <div className='videolink' key={video.id} onClick={() => handleVideoClick(video)}>
                                        <FontAwesomeIcon icon={faCirclePlay} className='custom-icon' />
                                        {video.title}
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </section>
            </Container>
           
        </div>
    )
}

export default InstallationVideoNew;
