import React from 'react'
import { Grid } from '@mui/material';
import TitleCard from '../components/TitleCard';
import TechnicalCard from '../components/TechnicalCard';


const TechnicalLibrary = () => {
  const allCardDetails = [
    {
      id: 1,
      imagePath:"https://dectecstorageprod.blob.core.windows.net/website/images/technical/AdobeStock_81953062_CLASSIC-GREY-min.jpg",
      firstText: 'TECHNICAL',
      boldText: ' LIBRARY',
      description: 'Dec-Tec has an ever-growing library of technical documents to educate, answer questions, and provide solutions. Our technical team works around the clock to ensure we stay up-to-date with our literature and are constantly improving and updating our documents to better serve you. We know you care about the details, and we care too.',
      isImageOnlyFlag: true,
      textShow: true,
      textDirect: true,
      textAlign: true,
      cartHeight: '100vh',
      bgAttachment: 'initial',
      bgPosition: 'center center',
    },
  ];


  return (
    <div>
      <Grid item xs={12}>
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>
      <section className='wrapper1'>
        <TechnicalCard
          img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/scott-graham-5fNmWej4tAA-unsplash-pbuxf0sf9ztkcymn6ku03aw01dmp19bz2crj1dhz9g.jpg"
          title="SPECIFICATIONS"
          description="Superior products means superior performance. Here you will learn the process that supports Dec-Tec’s adherence to codes and standards."
          imglink="/technical/technical-library/specifications"
          headerlink="/technical/technical-library/specifications"
        />

        <TechnicalCard
          img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/bernd-klutsch-nE2HV5AUXFo-unsplash-pc3lz1cn3izd4ug5bbod0w8loxvlk51yqnsnn60uro.jpg"
          title="REPORTS"
          description="Compliancy reports demonstrate Dec-Tec meets relevant codes and standards proving it to be an approved product choice."
          imglink="/technical/technical-library/reports"
          headerlink="/technical/technical-library/reports"
        />

        <TechnicalCard
          img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/daniel-mccullough-348488-unsplash-min-o33ic32ut147rwlr65cvazzm70602hbf7bvmp9f3r8.jpg"
          title="DETAILED DRAWINGS"
          description="Our quality details can help broaden the foundation from which you assemble your design drawings and are available in a multitude of formats to ensure you have all our drawings at your disposal."
          imglink="/technical/technical-library/detailed-drawings"
          headerlink="/technical/technical-library/detailed-drawings"
        />

        <TechnicalCard
          img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/jenna-jacobs-533665-unsplash-min-o4rq9le8pfj9b35c7qz6mhjopbsd4wks4qe4wekd6s.jpg"
          title="TECHNICAL GUIDES"
          description="We at Dec-Tec understand that there is a need for detailed installation manuals and even more specific advanced installation guides. "
          imglink="/technical/technical-library/technical-guides"
          headerlink="/technical/technical-library/technical-guides"
        />

        <TechnicalCard
          img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/ross-sneddon-0MBt0sGU8UA-unsplash-pb8be8nac5s8fjgjixbjjrfn0f63s2dp15gphhzow4.jpg"
          title="INSTALLATION VIDEOS"
          description="We have an ever improving base of testing, installation, and demonstration videos available for reference. Broken into short, specific videos they are great for quick, visual reminders"
          imglink="/technical/technical-library/installation-videos"
          headerlink="/technical/technical-library/installation-videos"
        />

        <TechnicalCard
          img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/samantha-fortney-VqXio0EvV1A-unsplash-pbuxngealhd6j0dmxq2hyo9vwu293ht9w3hd1qztfo.jpg"
          title="CHECKLISTS"
          description="Dec-Tec products cannot reach their full potential without the quality craftsmanship of the installer, and using the right tools can help our installers reach that potential and produce a finished product that is satisfying for everyone."
          imglink="/technical/technical-library/checklists"
          headerlink="/technical/technical-library/checklists"
        />


        <TechnicalCard
          img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/pexels-pixabay-357514-pc3m1zkejt0rks5v33l7cnfothc2pxrmt9iivfnd8k.jpg"
          title="TECHNICAL BULLETINS"
          description="Our bulletins are designed to provide quick, concise literature on our best practices and considerations in areas ranging from pre-installation to repair. "
          imglink="/technical/technical-library/bulletins"
          headerlink="/technical/technical-library/bulletins"
        />

        <TechnicalCard
          img="https://dectecstorageprod.blob.core.windows.net/website/images/technical/trent-erwin-338084-unsplash-o3r9bictnxtty5rty82qlf397qav54hxzc3iiqgkhw.jpg"
          title="SAFETY DATA SHEETS"
          description="Your safety is our utmost concern, so we take it seriously. All of our products come with an SDS which can be found here."
          imglink="/technical/technical-library/safety-data-sheets"
          headerlink="/technical/technical-library/safety-data-sheets"
        />
      </section>
    </div>
  )
}

export default TechnicalLibrary;