import React from 'react'
import { Container, Grid } from '@mui/material'
import TitleCard from '../components/TitleCard';


const Checklist = () => {
    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/pexels-pixabay-357514.jpg",
            firstText: 'CHECKLISTS',
            boldText: '',
            description: 'Dec-Tec products cannot reach their full potential without the quality craftsmanship of the installer, and using the right tools can help our installers reach that potential and produce a finished product that is satisfying for everyone.',
            isImageOnlyFlag: true,
            textShow: true,
            textDirect: true,
            textAlign: true,
            cartHeight: '85vh',
            bgAttachment: 'initial',
            bgPosition: 'center center',
            bgOpacity: "rgba(0, 0, 0, 0.7)",
        },
    ];
    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap' style={{ padding: "5%",textAlign:"center" }}>
                            <h3 className='heading-title 'style={{fontSize:'2em'}}> <span className='title-bold'>COUNTERMANS <br/> CHECKLIST123</span></h3>
                            <div className='divider ' style={{ width: '25%' }} >
                            </div>
                            <div className='paragraph' style={{ padding: "0%  5% 5% 5%" }}>
                                <p>
                                    The<b> CL002: Countermans Checklist</b> provides an overview of all the Dec-Tec products to consider ordering, ensuring the contractor gets what they need to do the job right.
                                </p>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                            <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/CL002-Dec-Tec-Countermans-Checklist-v.03.pdf"  target={'_blank'} rel="noreferrer">
                                <button className='btnLearnMore '>Download</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='technical_img'>
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/jenna-jacobs-533665-unsplash-min.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/jenna-jacobs-533665-unsplash-min.jpg" alt='sectionThird' width={"100%"} className='first-image' />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='technical_img'>
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-103.png" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-103.png" alt='sectionThird' width={"100%"} className='first-image' />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap' style={{ padding: "5%" ,textAlign:"center"}}>
                        <h3 className='heading-title 'style={{fontSize:'2em'}}> <span className='title-bold'>DEC-TEC TOOLS & <br/> SUPPLIES CHECKLIST</span></h3>
                            <div className='divider ' style={{ width: '25%' }} >
                            </div>
                            <div className='paragraph' style={{ padding: "0%  5% 5% 5%" }}>
                                <p>
                                    The<b> CL003: Dec-Tec Tools & Supplies Checklist </b>provides our recommendations of the tools and supplies you will require for a successful Dec-Tec project. 
                                </p>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                            <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/CL003 - Dec-Tec Tools & Supplies Checklist v.02.pdf"  target={'_blank'} rel="noreferrer">
                                <button className='btnLearnMore '>Download</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap' style={{ padding: "5%",textAlign:"center" }}>
                        <h3 className='heading-title 'style={{fontSize:'2em'}}> <span className='title-bold'>CONTRACTORS <br/> CHECKLIST</span></h3>
                            <div className='divider ' style={{ width: '25%' }} >
                            </div>
                            <div className='paragraph' style={{ padding: "0%  5% 5% 5%" }}>
                                <p>
                                    The<b> CL004: Contractors Checklist </b>provides the steps to follow before and after the Dec-Tec installation process.
                                </p>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                            <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/CL004 - Dec-Tec Best Practices for Installers v.02.pdf"  target={'_blank'} rel="noreferrer">
                                <button className='btnLearnMore '>Download</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='technical_img'>
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/callum-hill-oamw52SCGi0-unsplash.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/callum-hill-oamw52SCGi0-unsplash.jpg" alt='sectionThird' width={"100%"} className='first-image' />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Checklist
