import React, { useEffect, useRef, useState } from 'react';
import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';
import { CircularProgress } from '@mui/material';
 
function InstallationVideoBitmovin(props) { 
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
 
  const playerConfig = {
    key: '64f18feb-ad5c-45d6-8205-36b4be80079b',
    ui: false
  };
 
  const playerSource = {
    hls: props?.videoUrl,
    poster: props?.poster
  };
 
  const playerDiv = useRef();
  useEffect(() => {

    function setupPlayer() {
      const playerInstance = new Player(playerDiv.current, playerConfig);
      playerInstance.load(playerSource).then(() => {
        setPlayer(playerInstance)
        setLoading(false);
        UIFactory.buildDefaultUI(playerInstance);    
      }, (error) => {
        console.log('Error while loading source', error);
      });     
    }
    setupPlayer();
    return () => {
      function destroyPlayer() {
        if (player != null) {
          player.destroy();
          setPlayer(null);
        }
      }
      destroyPlayer();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <div>
      {loading && <CircularProgress style={{position:"absolute",left:"50%",top:"50%",transform:"translate(-50px, -50px)"}}/>}
      <div id='player' ref={playerDiv} style={{ visibility: !loading ? 'visible' : 'hidden' }} />
    </div>
  )
}
 
export default InstallationVideoBitmovin;