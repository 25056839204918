import React from 'react';
import '../css/cardClassic.css';
import { HashLink } from 'react-router-hash-link';

const ClassicCard = (props) => {
	return (
		<div className='card-main-div'>
			{props.cardData.classicCardDetails.map((post, index) => (
				<div key={index.toString()} id={post.id} className={'card-main-div-first '+ (post.position)}>
					{post.isImageOnlyFlag === true ? (
						<div>
							<img className='card__image' srcSet={post.imagePath} src={post.imagePath} alt='dec-tec' />
						</div>
					) : (
						<div>
							<img className={'card__image '}  srcSet={post.imagePath} src={post.imagePath} alt='dec-tec' />
							<div className='card-containts'>
							<section id={"/products/membranes/classic-line/#" + props.postId}>
								<div className={'card-containts-icon-wrapper ' + (post.textShow === 'right' ? 'justify-content' : ' ')}>
								<div className='classic-card-icon pad-bottom' ></div>
								{/* {post.coolstep == false ? (
									<div className='classic-card-icon'>
										<FontAwesomeIcon icon={'star'} size={'3x'} />
										<span>
											<h1 className='classic-card-text'>NEW!</h1>
										</span>
									</div>
								) : (<div className='classic-card-icon pad-bottom' ></div>)}
							 */}
								</div>
								</section>
								<div className='card-containts-buttons'>
									{post.coolstep === true ? (
										<h3 className={"heading-title pr-0 " + (post.textShow === 'right' ? 'text-right' : ' ')}> <span className='underline-text text_border-bottom'>cool</span>step {post.title}</h3>
									) : (
										<>
											<h3 className={"heading-title pr-0 " + (post.textShow === 'right' ? 'text-right' : ' ')}><span className='underline-text text_border-bottom'>{post.title}</span></h3>
										</>
									)}
									<p className={'card__description-classic ' + (post.textShow === 'right' ? 'text-right' : ' ')}>
										{post.description}
									</p>

									{post.textShowLeft === true ? (
										<div className={'btn-wrap ' + (post.textbtn2 && post.textShow === 'right' ? 'justify-end' : 'justify-start')}>
											<div className={'accessories-Card-button2 22 ' + (post.textbtn2 && post.textShow === 'right' ? 'm-l-50' : 'm-r-50')}><span>
												<HashLink smooth to={post.link2}>
													<button className='btnLearnMore'>Membrane Info</button>
												</HashLink>
											</span>
											</div>
											{post.textbtn2 ?
												<div className='accessories-Card-button2 33'><span>

													<button className='btnLearnMore' onClick={() => props.toggleRoute(post.title)}>{post.textbtn2}</button></span>
												</div> : null}
										</div>
									) : (
										<div className={'btn-wrap ' + (post.textbtn2 !== null && post.textShow === 'right' ? 'justify-end' : 'justify-start')}>
											<div className={'accessories-Card-button2 44 ' + (post.textbtn2 !== null && post.textShow === 'right' ? 'm-l-50' : 'm-r-50')}><span>
												<HashLink smooth to={post.link2}>
													<button className='btnLearnMore' >Membrane Info</button>
												</HashLink>
											</span>
											</div>
											{post.textbtn2 ?
												<div className={'accessories-Card-button2 11 ' + (post.textbtn2 !== null && post.textShow === 'right' ? 'm-l-50' : 'm-r-50')}><span>
													<button className='btnLearnMore' onClick={() => props.toggleRoute(post.title)}>{post.textbtn2}</button></span>
												</div> : null}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			))}
		</div>
	);
};

export default ClassicCard;
