import React from 'react';
import './../css/faqs.css';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import TitleCard from '../components/TitleCard'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink, useNavigate } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Faqs = () => {

	const navigate = useNavigate();

    const handleVideoClick = (video) => {
        navigate(`/video-detail/${video.id}`, { state: { video } });
    };

	const videoList = [
		{
			id:"Flame Test",
			title: 'Flame Test',
			url: "https://streams.bitmovin.com/cnu7cilsrjnnig8jape0/manifest.m3u8",
			maintitle: "Fire Resistant",
			poster: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/fire-resistant.png"
		},

	];

	
	const allCardDetails = [
		{
			id: 1,
			imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/sharon-mccutcheon-532782-unsplash-min.jpg",
			firstText: 'FREQUENTLY ASKED',
			boldText: 'QUESTIONS',
			description: 'Have questions for us? We have answers.  Take a look through our frequently asked questions and learn more about Dec-Tec products, purchasing, installation, technical, and other questions people have asked. ',
			isImageOnlyFlag: true,
			textShow: false,
			textDirect: false,
			textAlign: true,
			cartHeight: '90vh',
			bgAttachment: 'initial',
			bgPosition: 'center center',
		},
	];
	return (
		<div>
			<section style={{ paddingBottom: '50px' }}>
				<Grid item xs={12}>
					<TitleCard cardData={{ allCardDetails }} />
				</Grid>
			</section>

			<Container maxWidth='lg' sx={{ padding: '50px 40px' }}>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item md={4} lg={4} sm={12} xs={12}>
						<div className=''>
							<div className='elementor'>
								<div className='text-wrap'>
									<h3 className='faq-Cart-heading-bold'>
										<b>General</b>
									</h3>
								</div>
								<div className='divider-faq'>
									<span></span>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item md={8} lg={8} xs={12} sm={12}>
						<section>
							<div className='accordation-wrapper'>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'> WHAT IS DEC-TEC?</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Dec-Tec, proudly manufactured in North America, is a PVC
												single-ply vinyl decking membrane with a full complement
												of Dec-Tec approved system accessories to ensure
												high-end aesthetics and absolute waterproofing of your
												outdoor living space surfaces.
											</p>
										</AccordionDetails>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												View our membranes
												<span className='cardLink'>
													{' '}
													<NavLink to='/products/membranes' className='cardLink-anchor' >
														here{' '}
													</NavLink>
												</span>
												and our full product line{' '}
												<span className='cardLink'>
													<NavLink to='/products' className='cardLink-anchor'>
														here
													</NavLink>
												</span>
												.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'> WHO IS DEC-TEC?</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												We are a business dedicated to providing superior
												performance, leading innovations, and proven products to
												the decking, roofing, and outdoor flooring industries.
												We serve those who know what they want, how they want it
												and when they want it. But above all else, we bring
												value to every deck.
											</p>
										</AccordionDetails>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Click
												<span className='cardLink'>
													{' '}
													<NavLink to='/about' className='cardLink-anchor'>
														here{' '}
													</NavLink>
												</span>
												to learn more about Dec-Tec.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												WHY CHOOSE DEC-TEC FOR MY DECK?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												There are numerous reasons to choose Dec-Tec as your
												decking solution. Tired of annual maintenance on your
												wood deck? Mold or mildew issues on old carpeting?
												Outdoor coatings flaking away or cracking? Concrete deck
												cracking, and looking like, well, old concrete? Then
												Dec-Tec is for you! It will provide a low maintenance,
												anti-mold, and mildew, non-cracking, superior looking
												surface. And remember, as a system, Dec-Tec waterproofs
												your deck.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>WHAT MAKES DEC-TEC UNIQUE?</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												It starts with the manufacturing method. Dec-Tec
												membranes are made via a process called spread coating.
												The plastisol (molten liquefied PVC) is spread directly
												through the reinforcement, multi-directional fiberglass,
												thus encapsulating the reinforcement and creating a
												fully monolithic membrane with internal reinforcement.
												There is no chance of delamination of the membrane.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												WHAT ARE PVC SINGLE-PLY VINYL DECKING AND ROOFING
												MEMBRANES?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												PVC single-ply vinyl decking and roofing membranes are a
												factory finished product that provides added value to
												any deck surface by being easy to install, has an
												incredible look, and functionally provide waterproofing,
												slip resistance, fire inhibition, solar reflectance and
												longevity through membrane construction and low
												maintenance.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												WHAT WARRANTY IS PROVIDED WITH THE DEC-TEC MEMBRANE?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												All of our 60 mil Dec-Tec membranes have a{' '}
												<span className='cardLink'>10-year warranty.</span>.
											</p>
										</AccordionDetails>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												All of our 80 mil Dec-Tec membranes have a{' '}
												<span className='cardLink'>10-year warranty.</span>.
											</p>
										</AccordionDetails>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Click
												<span className='cardLink'>
													{' '}
													<NavLink to='/our-warranty' className='cardLink-anchor'>
														here{' '}
													</NavLink>
												</span>
												for more information regarding our warranty.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>WHO INSTALLS DEC-TEC?</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Dec-Tec is sold through construction supply distribution
												channels and installed by their professional contractor
												base. Dec-Tec membranes are idealistically installed by
												Dec-Tec Professionals who earn that specific designation
												by participating in our Dec-Tec Theoretical and
												Practical Training seminar.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
							</div>
						</section>
					</Grid>
				</Grid>
			</Container>
			<Container maxWidth='lg' sx={{ padding: '50px 40px' }}>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item md={4} lg={4} xs={12} sm={12}>
						<div className=''>
							<div className='elementor'>
								<div className='text-wrap'>
									<h3 className='faq-Cart-heading-bold'>
										<b>Purchasing</b>
									</h3>
								</div>
								<div className='divider-faq'>
									<span></span>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item md={8} lg={8} xs={12} sm={12}>
						<section>
							<div className='accordation-wrapper'>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'> WHERE DO I BUY DEC-TEC?</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Dec-Tec is sold through our valued distribution partners
												local stores. Please{' '}
												<span className='cardLink'>
													<NavLink to='/contact-us' className='cardLink-anchor'>
														click here
													</NavLink>
												</span>
												. or call for a store near you.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												{' '}
												HOW DO I OBTAIN SAMPLES OF DEC-TEC MEMBRANES?{' '}
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												You can obtain a sample by filling out the form{' '}
												<span className='cardLink'>
													{' '}
													<NavLink to='/contact-us' className='cardLink-anchor'>
														here{' '}
													</NavLink>
												</span>
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
							</div>
						</section>
					</Grid>
				</Grid>
			</Container>
			<Container maxWidth='lg' sx={{ padding: '50px 40px' }}>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item md={4} lg={4} xs={12} sm={12}>
						<div className=''>
							<div className='elementor'>
								<div className='text-wrap'>
									<h3 className='faq-Cart-heading-bold'>
										<b>Installation</b>
									</h3>
								</div>
								<div className='divider-faq'>
									<span></span>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item md={8} lg={8} xs={12} sm={12}>
						<section>
							<div className='accordation-wrapper'>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												{' '}
												WHAT ARE ACCEPTABLE SUBSTRATES?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Acceptable substrates that have been tested and proven
												to work are:
											</p>
										</AccordionDetails>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord-list'>
												<ul>
													<li>Plywood, specification:</li>
												</ul>
												<ul><li style={{ marginLeft: "20px" }}>Min 5/8″ thick Tongue & Groove CDX</li></ul>
												<ul><li style={{ marginLeft: "20px" }}>Min. 3/4″ thick Tongue & Groove APA, Exterior 1 rated</li></ul>

												<ul>
													<li>1/2″ DensDeck Primed</li>
												</ul>
												<ul>
													<li>Concrete Non-Primed, Fully Cured</li>
												</ul>
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												{' '}
												CAN DEC-TEC ADHERE TO ITSELF?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												No, you must use one of the acceptable substrates that
												have been tested and proven to work efficiently as a
												whole with the Dec-Tec System.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												HOW DO YOU APPLY THE DEC-TEC SYSTEM?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												The Dec-Tec membrane is fully adhered to the substrate
												using an appropriate Dec-Tec adhesive. All membrane
												seams must overlap 2″ and are thermally heat welded
												together to create a watertight seal. Lastly, the
												Dec-Tec membrane is thermally welded to all of the PVC
												coated terminations and flashings, creating a truly
												monolithic system.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												IS THE METAL DEC-CLAD FLASHING MECHANICALLY FASTENED OR
												ADHERED TO THE SUBSTRATE?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												The Dec-Tec flashing is designed to be mechanically
												fastened (nailed using 1/4 inch roofing nails).
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												CAN I USE ACCESSORIES OTHER THAN DEC-TEC FOR THIS
												SYSTEM?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												The Dec-Tec accessories were developed and tested as a
												part of the Dec-Tec system as a whole. Any other
												accessories have not been proven to be compatible with
												the Dec-Tec system, therefore would void the warranty if
												used. You can view the accessories we offer{' '}
												<span className='cardLink'>
													<NavLink to='/products/accesories' className='cardLink-anchor'>
														here
													</NavLink>
												</span>
												.
											</p>
										</AccordionDetails>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Please call our technical division for further questions
												regarding compatibility:{' '}
												<span className='cardLink'>1-866-461-3914</span>.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												HOW DO YOU ATTACH THE MEMBRANE TO THE WALL?
												<br />
												HOW FAR UP THE WALL SHOULD THE MEMBRANE GO?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												The membrane is adhered to the wall using the Solvent
												Based Adhesive. See our installation section for more
												detailed information.
											</p>
										</AccordionDetails>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Always check with your local building code first.
												Dec-Tec recommends that there should be a minimum of 6″
												of the membrane to run up the wall of the adjoining
												structure. Be sure to check with the local building code
												to verify what specifically is required.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												IN WHAT TEMPERATURE CAN THE GLUE BE APPLIED?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Dec-Tec specifies a minimum application temperature of
												34°F/1°C for the DecTec SBA 100 and SBA 200 and 50°F
												/10°C for a minimum of 24 hours for the Dec-Tec WBA 100.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												DOES THE DECK HAVE TO BE DRY TO PUT THE GLUE DOWN?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Yes, the deck MUST be dry in order to accept the
												adhesive. See our installation section for more detailed
												information.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												SHOULD THE GLUE BE DRY BEFORE YOU PUT THE MEMBRANE DOWN?
												HOW LONG DOES IT TAKE TO DRY?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Dec-Tec membrane is laid down into the Water Based
												Adhesive when it is still WET, while the Solvent Based
												Adhesive is ready to accept membrane after the substrate
												has been double-primed, the first and second layer has
												dried and the membrane layer has become tacky to the
												touch. See our installation section for more detailed
												information.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
							</div>
						</section>
					</Grid>
				</Grid>
			</Container>
			<Container maxWidth='lg' sx={{ padding: '50px 40px' }} id="spreadcoat">
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item md={4} lg={4} xs={12} sm={12}>
						<div className=''>
							<div className='elementor'>
								<div className='text-wrap'>
									<h3 className='faq-Cart-heading-bold'>
										<b>Technical</b>
									</h3>
								</div>
								<div className='divider-faq'>
									<span></span>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item md={8} lg={8} xs={12} sm={12}>
						<section>
							<div className='accordation-wrapper'>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												{' '}
												WHAT IS THE SPREADCOAT METHOD?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												The spreadcoat method is a unique process used when
												manufacturing Dec-Tec membrane. The plastisol (molten
												liquefied PVC) is spread directly through the
												reinforcement, multi-directional fiberglass, thus
												encapsulating the reinforcement and creating a fully
												monolithic membrane with internal reinforcement. There
												is no chance of delamination of the membrane.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												CAN DEC-TEC MEMBRANES WITHSTAND FOOT TRAFFIC?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												Yes, the Dec-Tec membrane is a durable, walkable roof
												membrane, designed to high volumes of pedestrian
												traffic.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												IS THE MEMBRANE SLIP-RESISTANT?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											<p className='paragraph-faqs-accord'>
												All Dec-Tec membranes exceed the minimum suggested
												values per NFSI 101-A for wet walkway surfaces. Dec-Tec
												surfaces are appraised as “high traction”. The
												embossment on the membrane is created to provide better
												resistance to slippery conditions, however like many
												surfaces; Dec-Tec can still be slippery when wet or
												snow/frost covered. It is the responsibility of the
												owner to clean and maintain their Dec-Tec deck to enjoy
												the deck to the fullest.
											</p>
										</AccordionDetails>
									</Accordion>
								</div>
								<div className='accordation'>
									<Accordion sx={{ padding: '0% 1% ', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'>
											<p className='cart-heading'>
												IS THE MEMBRANE FIRE-RESISTANT?
											</p>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
											
												{videoList.map((video) => (
														<div className='videolink' style={{paddingLeft:"15px"}} key={video.id} onClick={() => handleVideoClick(video)}>
															<FontAwesomeIcon icon={faCirclePlay} className='custom-icon' />
															{video.title}
														</div>
												))}
									
										</AccordionDetails>
									</Accordion>
								</div>
							</div>
						</section>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};


export default Faqs;
