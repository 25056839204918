import React from 'react'
import SliderGallery from '../components/SliderGallery'

const AshdownGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Dec-7-Ashdown-image-1-v1.1-HighRes.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Dec-7-Ashdown-image-2-v2.0-HighRes.jpg"
    },
   
  ]

  return (
    <div>
      <SliderGallery
        title="Ashdown"
        para1="Elegance, performance, and protection, that’s what Ashdown offers. The plank wood design and brown coloring is cool to walk on the hottest of days and warm enough to entertain friends and family well into the evening."
        galleryImages={galleryImages}
        previouslink="/grafton-gallery-2/"
        nextlink="/bronze-gallery/"
      />
    </div>
  )
}

export default AshdownGallery
