import React from 'react'
import "../css/titlecard.css"
const TitleCard = (props) => {
    return (
        <div>
            {props.cardData.allCardDetails.map((post , index) => (
                <div  key={index.toString()} className='image-contianer' style={{ backgroundImage: `url(${post.imagePath})`, width:"100%", height:post.cartHeight , backgroundAttachment:post.bgAttachment, backgroundPosition:post.bgPosition, backgroundSize:'cover'}}>
                    {post.isImageOnlyFlag ? (
                        <div className='title-Card-overlay' style={{background:post.bgOpacity}}>
                        <div className={post.textAlign ? 'title-Card-Left' : 'title-Card-Right'}>
                            <div className={post.textAlign ? 'card-wrap-left':'card-wrap-right'}>
                                    <div className={post.textDirect ? 'card-heading-wrap_Row' : 'card-heading-wrap_Coloum'}>
                                    {post.textShow  ? (
                                        <>
                                       <h1 className='card-heading'><span className='underline-text'><b>{post.firstText} </b></span>  </h1>
                                       <h1 className='card-heading'> {post.boldText} </h1>
                                       </>
                                        ):
                                        (
                                            <>
                                            <h1 className='card-heading'> {post.firstText}</h1>
                                            <h1 className='card-heading'><span className='underline-text'><b> {post.boldText} </b></span>  </h1>
                                            </>
                                            )}
                                    </div>
                                    <div className={post.textAlign ?'card-paragraph-left':'card-paragraph-right'}>
                                    <p>{post.description}</p>
                                    <p>{post.description2}</p>
                                    </div>
                                </div>
                        </div>
                        </div>
                    ) : (
                        <div className={post.textAlign ? 'title-Card-Left' : 'title-Card-Right '}>
                            <div className={post.textAlign ? 'card-wrap-left':'card-wrap-right'}>
                                    <div className={post.textDirect ? 'card-heading-wrap_Row' : 'card-heading-wrap_Coloum'}>
                                    {post.textShow  ? (
                                        <>
                                       <h1 className='card-heading'><span className='underline-text'><b>{post.firstText} </b></span>  </h1>
                                       <h1 className='card-heading'> {post.boldText} </h1>
                                       </>
                                        ):
                                        (
                                            <>
                                            <h1 className='card-heading'> {post.firstText}</h1>
                                            <h1 className='card-heading'><span className='underline-text'><b> {post.boldText} </b></span>  </h1>
                                            </>
                                            )}
                                    </div>
                                    <div  className={post.textAlign ?'card-paragraph-left':'card-paragraph-right'}>
                                    <p>{post.description}</p>
                                    </div>
                                </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default TitleCard