import { Grid } from '@mui/material'
import React from 'react'

const AccesoriesCard = (props) => {

    return (
        <div>
            {props?.cardData?.map((post, index) => 
                (
                <div id={post.id} key={index.toString()}>
                    {post.isImageOnlyFlag === true ? (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={post.id}  sx={{ padding: "50px 0px" }} className='my-container'>
                            <Grid item md={7} lg={7} sm={12} xs={12} id="two">
                                <div className={(post.textShow === 'right' ? 'cardRight' : 'cardLeft')}>
                                <section id={"/products/adhesives/#" + props.postId}>
                                    {/* <h3 className='heading-title '>{post.title}</h3>
                                    <div className='divider accessories-card-margin'></div>
                                    <p className='card-paragraph'>{post.description}</p> */}
                                    {post.dec === true ? (
                                        <>
                                            <h3 className="heading-title pr-0 card-heading-font-size ">{post.title} <span className='underline-text text_border-bottom'>{post.titleSec}</span> </h3>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className={"heading-title  pr-0 card-heading-font-size" + (post.textShow === 'right' ? 'text-right' : ' ')}>{post.title} <span className='underline-text text_border-bottom'>{post.titleSec}</span></h3>
                                        </>
                                    )}
                                    <p className={'card-para-access ' + (post.textShow === 'right' ? 'text-left' : ' ')}>
                                        {post.description}
                                    </p>
                                    <div className={'btn-wrap ' +( post.textbtn2 !== null && post.textShow === 'right' ? 'justify-start' : 'justify-end') }>
                                        <div className={'accessories-Card-button 44 '  + ( post.textbtn2 !== null && post.textShow === 'right' ? 'm-l-10' : 'm-r-10')}><span>
                                            <button className='btnLearnMore btnLearnMore-res' onClick={()=>props.toggleRoute(post.titleSec)}>{post.textbtn}</button></span>
                                        </div>
                                        {post.textbtn2 ?
                                            <div className={'accessories-Card-button 11 ' + ( post.textbtn2 !== null && post.textShow === 'right' ? 'm-l-10' : 'm-r-10') }><span>
                                                <button  className='btnLearnMore btnLearnMore-res' onClick={()=>props.toggleRoute2(post.titleSec)}>{post.textbtn2}</button></span>
                                            </div> : null}
                                    </div>
                                    </section>
                                </div>
                            </Grid>
                            <Grid item md={5} lg={5} sm={12} xs={12}  id="one">
                                <img className='accessories-image-box' srcSet={post.imagePath}  src={post.imagePath} alt='dec-tec' />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ padding: "50px 0px" }}>
                            <Grid item md={5} lg={5} sm={12} xs={12}>
                                <img className='accessories-image-box' srcSet={post.imagePath} src={post.imagePath} alt='dec-tec' />
                            </Grid>
                            <Grid item md={7} lg={7} sm={12} xs={12}>
                                <div className={(post.textShow === 'left' ? 'cardLeft' : ' cardRight')}>
                                    {/* <h3 className='heading-title'>{post.title}</h3>
                                    <div className='divider accessories-card-margin'></div>
                                    <p className='card-paragraph'>{post.description}</p> */}
                                    {post.dec === true ? (
                                        <>
                                            <h3 className="heading-title pr-0 card-heading-font-size">{post.title} <span className='underline-text text_border-bottom'>{post.titleSec}</span> </h3>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className={"heading-title pr-0 card-heading-font-size" + (post.textShow === 'right' ? 'text-right' : ' ')}>{post.title} <span className='underline-text text_border-bottom'>{post.titleSec}</span></h3>
                                        </>
                                    )}
                                    <p className={'card-para-access ' + (post.textShow === 'right' ? 'text-left' : ' ')}>
                                        {post.description}
                                    </p>
                                    {post.textShowLeft === true ?(
                                        <div className={'btn-wrap ' +( post.textbtn2 && post.textShow === 'right' ? 'justify-start' : 'justify-end') }>
                                        <div className={'accessories-Card-button 22 '  + ( post.textbtn2 && post.textShow === 'right' ? 'm-l-10' : 'm-r-10')}><span>
                                            <button className='btnLearnMore btnLearnMore-res' onClick={()=>props.toggleRoute(post.titleSec)}>{post.textbtn}</button></span>
                                        </div>
                                        {post.textbtn2 ?
                                            <div className='accessories-Card-button 33'><span>
                                                <button className='btnLearnMore btnLearnMore-res' onClick={()=>props.toggleRoute2(post.titleSec)}>{post.textbtn2}</button></span>
                                            </div> : null}
                                    </div>
                                    ):(
                                        <div className={'btn-wrap ' +( post.textbtn2 !== null && post.textShow === 'right' ? 'justify-start' : 'justify-end') }>
                                        <div className={'accessories-Card-button 44 '  + ( post.textbtn2 !== null && post.textShow === 'right' ? 'm-l-10' : 'm-r-10')}><span>
                                            <button className='btnLearnMore btnLearnMore-res' onClick={()=>props.toggleRoute(post.titleSec)}>{post.textbtn}</button></span>
                                        </div>
                                        {post.textbtn2 ?
                                            <div className={'accessories-Card-button 11 ' + ( post.textbtn2 !== null && post.textShow === 'right' ? 'm-l-10' : 'm-r-10') }><span>
                                                <button  className='btnLearnMore btnLearnMore-res' onClick={()=>props.toggleRoute2(post.titleSec)}>{post.textbtn2}</button></span>
                                            </div> : null}
                                    </div>
                                    )}
                                    
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </div>
            ))}
        </div>
    )
}

export default AccesoriesCard