import React from 'react'
import SliderGallery from '../components/SliderGallery'



const CoolstepSandalwoodGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-3.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_65741670-Sandalwood.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-12.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-11.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-10.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-9.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-8.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-7.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-6.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-5.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-1.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-22.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-21.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-19.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-17.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-16.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-15.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-14.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-13.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-12.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-11.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-10.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-4.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-2.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/CoolStep-Sandalwood-1.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/COOLSTEP-SANDALWOOD-2-edt-1.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_151480473-Sandalwood.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_100199276-Sandalwood.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_69012624-Sandalwood.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_4116803-Sandalwood.jpg"
    },

  ]


  return (
    <div>
      <SliderGallery
        title="Coolstep Sandalwood"
        para1="As one of the newest members of our CoolStep Line, this membrane combines all the features of our 80 mil CoolStep with a high definition wood-grain pattern. Without a doubt, CoolStep Sandalwood is the coolest solution for locations that get a lot of sun! "
        galleryImages={galleryImages}
        previouslink="/coolstep-copper-gallery/"
        nextlink="/coolstep-slate-gallery/"
      />
    </div>
  )
}

export default CoolstepSandalwoodGallery
