import { Container, Grid } from '@mui/material'
import React from 'react'
import TitleCard from '../components/TitleCard';
import AccesoriesCard from '../components/AccesoriesCard';
import { NavLink} from 'react-router-dom'


const SafetyDataSheets = () => {

    function toggleRoute(type) {

        if (type === "Dec-Flash") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/SDS Requirement OSHA Article Explanation.pdf")
        }


        else if (type === "Dec-Clad Metal") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/SDS Requirement OSHA Article Explanation.pdf")
        }


        else if (type === "Dec-Scupper") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/SDS Requirement OSHA Article Explanation.pdf")
        }

        else if (type === "Dec-Patch II") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/DEC-PATCH II.pdf")
        }
        else if (type === "WBA 100") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/DEC-TEC WBA100.pdf")
        }

        else if (type === "SBA 100R") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec SBA-100R-E.pdf")
        }

        else if (type === "SBA 200 VC") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec_SBA-200VC-E.pdf")
        }

        else if (type === "Tremco 830") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Tremco-830-materials-safety.pdf")
        }

    }

    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/trent-erwin-338084-unsplash.jpg",
            firstText: 'SAFETY',
            boldText: 'DATA SHEETS',
            description: 'A good project is a safe one. Knowing the stringent requirements of construction we provide safety data sheets for our products so you have the support you need in the field.',
            isImageOnlyFlag: true,
            textShow: true,
            textDirect: true,
            textAlign: true,
            cartHeight: '100vh',
            bgOpacity: "rgba(0, 0, 0, 0.7)",
            bgAttachment: 'initial',
            bgPosition: 'center center',
        },
    ];

    const classicCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/IMG_0194.jpg",
            isImageOnlyFlag: false,
            titleSec: 'Dec-Flash',
            textShow: 'right',
            textbtn: 'Download',
            description: 'Our pre-fabricated membrane flashings simplify installation for any contractor. A controlled setting during in house production ensures each Dec-Flash is produced with the same dimensions, high quality, and fibreglass reinforcement which translates to high production in the field, and a stunning, uniform look to the finished details.',
            textShowLeft: false,
        },
        {
            id: 2,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecCladDrip-e1547059977457.jpg",
            isImageOnlyFlag: true,
            titleSec: 'Dec-Clad Metal',
            textShow: 'left',
            textbtn: 'Download',
            description: 'Dec-Clad PVC coated sheet metal allows you to take care of your own metal work and create custom profiles while maintaining the great vinyl to vinyl weldability and colour matching our many Dec-Tec membranes.',
        },
        {
            id: 3,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_Scupper.jpg",
            isImageOnlyFlag: false,
            titleSec: 'Dec-Scupper',
            textShow: 'right',
            textbtn: 'Download',
            description: 'The Dec-Scupper is the ideal through wall drain to use with Dec-Tec membranes. Dec-Scupper’s have a PVC coated flange to allow for excellent vinyl to vinyl weldability and improve the ease of use when working on critical leak points. The one-piece construction and pliability of the flange provide increased on-site workability and easier installation.',
            textShowLeft: false,
        },
    ]

    const installationCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecPatch-o33ia3n4dmephu5nxbtgb1xl8n67gffew91n8pmiew.jpg",
            titleSec: 'Dec-Patch II',
            isImageOnlyFlag: false,
            textShow: 'right',
            textbtn: 'Download',
            description: 'Our pre-fabricated membrane flashings simplify installation for any contractor. A controlled setting during in house production ensures each Dec-Flash is produced with the same dimensions, high quality, and fibreglass reinforcement which translates to high production in the field, and a stunning, uniform look to the finished details.',
        },
        {
            id: 2,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/WBA100-o33ilhgr5dzq3tmdg8ysi6ik8n28ogmdslhchcqv20.jpg",
            titleSec: 'WBA 100',
            isImageOnlyFlag: true,
            textShow: 'left',
            textbtn: 'Download',
            description: 'Dec-Clad Drip Edge is an easy and efficient way to terminate membrane details and eliminates the need for adhesives and clips. With multiple, membrane pairing colours our Dec-Clad Drip Edge’s PVC coating provides one of the strongest and longest lasting waterproof details and a great aesthetic finish.'
        },
        {
            id: 3,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/SBA100R-o33ikndx2oujsau2bvyqae3t8b6hu5az0glt4hzgl4.jpg",
            titleSec: 'SBA 100R',
            isImageOnlyFlag: false,
            textShow: 'right',
            textbtn: 'Download',
            description: 'The Dec-Clad Kickout introduces one of the easiest and most efficient ways to terminate Dec-Tec membrane to vertical surfaces. With a weld-able PVC coating, standard dimensions, and multiple colour options, our Kickout is able to pair with any one of our beautiful Dec-Tec membranes.',
        },
        {
            id: 4,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/SBA200VC-o33ikr59u0zp2qolpxl8kd5nlunyoxpwcz7r1ltvw8.jpg",
            titleSec: 'SBA 200 VC',
            isImageOnlyFlag: true,
            textShow: 'left',
            textbtn: 'Download',
            description: 'Dec-Clad PVC coated sheet metal allows you to take care of your own metal work and create custom profiles while maintaining the great vinyl to vinyl weldability and colour matching our many Dec-Tec membranes.',
        },
        {
            id: 5,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/tremco-o33il907fvo57byntnb5dqnew67xr6osrflz5v3em0.jpg",
            titleSec: 'Tremco 830',
            isImageOnlyFlag: false,
            textShow: 'right',
            textbtn: 'Download',
            description: 'The NDR 4-1 Dec-Drain is the ultimate in field flexibility. The multi-stepped design allows for attachment of various sizes of outlet pipes, while still fitting inside confined joist spaces. Dec-Tec membranes can be easily welded to the PVC coated flange, ensuring a long-lasting waterproof detail and great aesthetic finish.',
        },
    ]

    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
                    <Grid item lg={3} md={3} xs={12} sm={12}>
                        <div className='imagwrap'>
                        <NavLink to="/products/membranes/classic-line">
                                <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/NEW-EMBOSS-bronze-01-obot1aoatini0p6k0n70a5ttzfgwa56e9e4t78p8zs.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/NEW-EMBOSS-bronze-01-obot1aoatini0p6k0n70a5ttzfgwa56e9e4t78p8zs.jpg" alt="dec-tec" />
                            </NavLink>
                        </div>
                        <div className='paragraph' style={{ padding: "0%  5% 5% 5%", textAlign: "center" }}>
                            <p ><b>CLASSIC</b> LINE</p>
                        </div>

                    </Grid>

                    <Grid item lg={3} md={3} xs={12} sm={12}>
                        <div className='imagwrap'>
                        <NavLink to="/products/membranes/coolstep-line">
                                <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/NEW-EMBOSS-coolstep-01-obot1lyd3j2xw0q66s2j42zd41xauif6axymyk8ix4.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/NEW-EMBOSS-coolstep-01-obot1lyd3j2xw0q66s2j42zd41xauif6axymyk8ix4.jpg" alt="dec-tec" />
                            </NavLink>
                        </div>
                        <div className='paragraph' style={{ padding: "0%  5% 5% 5%", textAlign: "center" }}>
                            <p ><b>COOLSTEP</b> LINE</p>
                        </div>
                    </Grid>


                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ justifyContent: 'center', display: "flex" }}>
                        <div className='product-div-wrap' style={{ padding: "5%", textAlign: "center" }}>
                            <h3 className='heading-title ' style={{ fontSize: '2em' }}> <span className='title-bold'>DEC-TEC MEMBRANES</span></h3>
                            <div className='divider ' style={{ width: '25%' }} >
                            </div>
                            <div className='paragraph' >
                                <p>
                                    The Safety Data Sheet for our Dec-Tec Membranes.
                                </p>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/SDS Requirement OSHA Article Explanation.pdf" target={'_blank'} rel="noreferrer">
                                    <button className='btnLearnMore '>Download</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container >
            <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex", justifyContent: 'center' }}>
                        <div className='product-div-wrap' style={{ padding: "5%", textAlign: "center" }}>
                            <h3 className='heading-title ' style={{ fontSize: '2em' }}> <span className='title-bold'>DEC-TEC ACCESSORIES</span></h3>
                            <div className='divider ' style={{ width: '25%' }} >
                            </div>
                            <div className='paragraph'>
                                <p>
                                    The Safety Data Sheet for our Dec-Tec Accessories.
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div >
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-121.png" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-121.png" alt='secThird' width={"100%"} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" >
                <AccesoriesCard toggleRoute={toggleRoute} cardData={classicCardDetails} />
            </Container>
            <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex", justifyContent: 'center' }}>
                        <div className='product-div-wrap' style={{ padding: "5%", textAlign: "center" }}>
                            <h3 className='heading-title ' style={{ fontSize: '2em' }}> <span className='title-bold'>INSTALLATION PRODUCTS</span></h3>
                            <div className='divider ' style={{ width: '25%' }} >
                            </div>
                            <div className='paragraph' style={{ padding: "0%  5% 5% 5%" }}>
                                <p>
                                The Safety Data Sheet for our Dec-Tec Installation Products
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div >
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-100.png" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Screenshot-100.png" alt='secThird' width={"100%"} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" >
                <AccesoriesCard toggleRoute={toggleRoute} cardData={installationCardDetails} />
            </Container>
        </div >
    )
}

export default SafetyDataSheets
