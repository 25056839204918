import { restPost } from "./RestClient";

export let headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
}

let form_headers = {
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
    }

export async function saveContactUs(data) {
    const res = await restPost("Users/SaveContactUs", data, headers);
    return res.data;
}

export async function fetchState(data) {
    const res = await restPost("Users/FetchStates", data, headers);
    return res.data;
}


export async function fetchCountries(data) {
    const res = await restPost("Users/FetchCountries", data, headers);
    return res.data;
}

export async function fetchInquiryReasons(data) {
    const res = await restPost("Users/FetchInquiryReasons", data, headers);
    return res.data;
}

export async function fetchRequestorTypes(data) {
    const res = await restPost("Users/FetchRequestorTypes", data, headers);
    return res.data;
}

export async function fetchDecTecAbouts(data) {
    const res = await restPost("Users/FetchDecTecAbouts", data, headers);
    return res.data;
}

export async function fetchMembranes(data) {
    const res = await restPost("Users/FetchMembranes", data, headers);
    return res.data;
}

export async function SaveTechnicalDocuments(data) {
    const res = await restPost("Users/SaveTechnicalDocuments", data, headers);
    return res.data;
}

export async function SaveProTrainings(data) {
    const res = await restPost("Users/SaveProTrainings", data, headers);
    return res.data;
}

export async function saveWarrantyRequests(data) {
    const res = await restPost("Users/SaveWarrantyRequests", data, headers);
    return res.data;
}

export async function saveUsersProfile(data) {
    const res = await restPost("Users/SaveUsersProfile", data, headers);
    return res.data;
}


export async function savePurchaseInformationFiles(data) {
    const res = await restPost('Users/SavePurchaseInformationFiles' , data, form_headers );
    return res.data;
}
export async function saveProjectFiles(data) {
    const res = await restPost('Users/SaveProjectFiles' , data, form_headers );
    return res.data;
}
export async function sendEmailForWarranty(data) {
    const res = await restPost('Users/SendEmailForWarranty' , data, headers );
    return res;
}

