import React from 'react'
import '../css/detailDrawingCard.css'

const DetailDrawingCard = (props) => {
  return (
    
        <div className='card'>
        <div className='card__body'>

        <a href={props.link} target={'_blank'} rel="noreferrer"><img srcSet={props.img} src={props.img} className="card__img" alt="dec-tec" loading="lazy"/></a>
            <h4 className='card__title'>
                <a href={props.link} target={'_blank'} rel="noreferrer">
                <b>{props.title}</b>
                </a>
                </h4>
            <h4 className='card__description-technical'>{props.description}</h4>
        </div>
      </div>
  
  )
}

export default DetailDrawingCard
