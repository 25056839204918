export const hawaiiStatesData = {
    type: "FeatureCollection",
    features: [
        {
            type: "Feature",
            id: "01",
            properties: { cityName: "Hawaii", builderName: "John Chembers", phoneNo: "1-253-343-4565", email: "jchambers@dec-tec.com", builderType: "Territory Builder", singleBuilder: true  },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [-155.634835, 18.948267],
                  [-155.881297, 19.035898],
                  [-155.919636, 19.123529],
                  [-155.886774, 19.348084],
                  [-156.062036, 19.73147],
                  [-155.925113, 19.857439],
                  [-155.826528, 20.032702],
                  [-155.897728, 20.147717],
                  [-155.87582, 20.26821],
                  [-155.596496, 20.12581],
                  [-155.284311, 20.021748],
                  [-155.092618, 19.868393],
                  [-155.092618, 19.736947],
                  [-154.807817, 19.523346],
                  [-154.983079, 19.348084],
                  [-155.295265, 19.26593],
                  [-155.514342, 19.134483],
                  [-155.634835, 18.948267],
                ],
              ],
            },
          },
          {
            type: "Feature",
            id: "02",
            properties: { cityName: "Hawaii", builderName: "John Chembers", phoneNo: "1-253-343-4565", email: "jchambers@dec-tec.com", builderType: "Territory Builder", singleBuilder: true  },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [-156.587823, 21.029505],
                  [-156.472807, 20.892581],
                  [-156.324929, 20.952827],
                  [-156.00179, 20.793996],
                  [-156.051082, 20.651596],
                  [-156.379699, 20.580396],
                  [-156.445422, 20.60778],
                  [-156.461853, 20.783042],
                  [-156.631638, 20.821381],
                  [-156.697361, 20.919966],
                  [-156.587823, 21.029505],
                ],
              ],
            },
          },
          {
            type: "Feature",
            id: "03",
            properties: { cityName: "Hawaii", builderName: "John Chembers", phoneNo: "1-253-343-4565", email: "jchambers@dec-tec.com", builderType: "Territory Builder", singleBuilder: true  },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [-156.587823, 21.029505],
                  [-156.472807, 20.892581],
                  [-156.324929, 20.952827],
                  [-156.00179, 20.793996],
                  [-156.051082, 20.651596],
                  [-156.379699, 20.580396],
                  [-156.445422, 20.60778],
                  [-156.461853, 20.783042],
                  [-156.631638, 20.821381],
                  [-156.697361, 20.919966],
                  [-156.587823, 21.029505],
                ],
              ],
            },
          },
          {
            type: "Feature",
            id: "04",
            properties: { cityName: "Hawaii", builderName: "John Chembers", phoneNo: "1-253-343-4565", email: "jchambers@dec-tec.com", builderType: "Territory Builder", singleBuilder: true  },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
      
                  [-156.982162, 21.210244],
                  [-157.080747, 21.106182],
                  [-157.310779, 21.106182],
                  [-157.239579, 21.221198],
                  [-156.982162, 21.210244],
                ],
              ],
            },
          },
          {
            type: "Feature",
            id: "05",
            properties: { cityName: "Hawaii", builderName: "John Chembers", phoneNo: "1-253-343-4565", email: "jchambers@dec-tec.com", builderType: "Territory Builder", singleBuilder: true  },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [-157.951581, 21.697691],
                  [-157.842042, 21.462183],
                  [-157.896811, 21.325259],
                  [-158.110412, 21.303352],
                  [-158.252813, 21.582676],
                  [-158.126843, 21.588153],
                  [-157.951581, 21.697691],
                ],
              ],
            },
          },
          {
            type: "Feature",
            id: "06",
            properties: { cityName: "Hawaii", builderName: "John Chembers", phoneNo: "1-253-343-4565", email: "jchambers@dec-tec.com", builderType: "Territory Builder", singleBuilder: true  },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [-159.468693, 22.228955],
                  [-159.353678, 22.218001],
                  [-159.298908, 22.113939],
                  [-159.33177, 21.966061],
                  [-159.446786, 21.872953],
                  [-159.764448, 21.987969],
                  [-159.726109, 22.152277],
                  [-159.468693, 22.228955],
                ],
              ],
            },
          },
    ],
  };
  