import React, { useEffect, useState } from "react";
import "../css/menuBar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/fontawesome-free-solid";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Menubar = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };

  const location = useLocation();
  const [oldPathname, setOldPathname] = useState("");
  if (menuToggle) {
    let d = document.getElementById("main-menu");
    d.style.display = "unset";
  }
  useEffect(() => {
    if (oldPathname !== location.pathname && menuToggle) {
      setOldPathname(location.pathname);
      setMenuToggle(false);
      let d = document.getElementById("main-menu");
      d.style.display = "none";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, oldPathname]);

  function closeMenuFunction() {
    let d = document.getElementById("main-menu");
    d.style.display = "none";
  }

  return (
    <div className="App bg-image">
      <nav>
        <div id="logo">
          <img
          srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/logo/dectec-logo-header.png"
            src="https://dectecstorageprod.blob.core.windows.net/website/images/logo/dectec-logo-header.png"
            onClick={navigateToHome}
            alt="logo"
          />
        </div>
        {/* <div id="drop1" className="main-menu contact-no contact-no-mob"> <FontAwesomeIcon icon={faSearch} /></div> */}
        {menuToggle ? (
          <div
            className="toggle toggle-btn"
            onClick={() => {
              setMenuToggle(false);
              closeMenuFunction();
            }}
          >
            <label htmlFor="drop">
              <FontAwesomeIcon icon={faClose} style={{ fontSize: 22 }} />
            </label>
          </div>
        ) : (
          <div
            className="toggle toggle-btn"
            onClick={() => {
              setMenuToggle(true);
            }}
          >
            <label htmlFor="drop">
              <FontAwesomeIcon icon={faBars} style={{ fontSize: 22 }} />
            </label>
          </div>
        )}
        <input type="checkbox" id="drop" />

        <ul id="main-menu" className="menu">
          <li>
            <label htmlFor="drop-2" className="toggle ">
              <span>
                {" "}
                <NavLink to="/about">About Us</NavLink>
              </span>
              +
            </label>
            <NavLink
              className="main-menu menu-icon is-hidden-mobile  "
              to="/about"
            >
              About Us
            </NavLink>
            <input type="checkbox" id="drop-2" />
            <ul className="menu">
              <li>
                <NavLink to="/about/product-document">
                  Product Documents
                </NavLink>
              </li>
              <li>
                <NavLink to="/our-warranty">Our Warranty</NavLink>
              </li>
              <li>
                <NavLink to="/about/faqs">FAQS</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <label htmlFor="drop-3" className="toggle">
              <span>
                {" "}
                <NavLink to="/products">PRODUCTS</NavLink>
              </span>
              +
            </label>
            <NavLink
              className="main-menu menu-icon is-hidden-mobile  "
              to="/products"
            >
              PRODUCTS
            </NavLink>
            <input type="checkbox" id="drop-3" />
            <ul className="menu">
              <li>
                <label htmlFor="drop-4" className="toggle">
                  <span>
                    {" "}
                    <NavLink to="/products/membranes">Membranes</NavLink>{" "}
                  </span>
                  +{" "}
                </label>
                <NavLink
                  className="menu-icon is-hidden-mobile"
                  to="/products/membranes"
                >
                  Membranes
                </NavLink>
                <input type="checkbox" id="drop-4" />
                <ul className="sub-menu-hover">
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/products/membranes/classic-line"
                    >
                      classic Line
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/products/membranes/coolstep-line"
                    >
                      Coolstep Line
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/products/accesories">Accessories</NavLink>
              </li>
              <li>
                <NavLink to="/products/adhesives">
                  Installation Products
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <label htmlFor="drop-5" className="toggle">
              <span>
                {" "}
                <NavLink to="/technical">TECHNICAL</NavLink>
              </span>
              +
            </label>
            <NavLink
              className="main-menu menu-icon is-hidden-mobile "
              to="/technical"
            >
              TECHNICAL
            </NavLink>
            <input type="checkbox" id="drop-5" />
            <ul className="menu">
              <li>
                <NavLink to="/technical/dec-tec-professional">
                  dec-tec professional
                </NavLink>
              </li>
              <li>
                <label htmlFor="drop-6" className="toggle">
                  <span>
                    {" "}
                    <NavLink to="/technical/technical-library">
                      technical library
                    </NavLink>{" "}
                  </span>
                  +{" "}
                </label>
                <NavLink
                  className="menu-icon is-hidden-mobile"
                  to="/technical/technical-library"
                >
                  technical library
                </NavLink>
                <input type="checkbox" id="drop-6" />
                <ul className="sub-menu-hover">
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/technical/technical-library/specifications"
                    >
                      specifications
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/technical/technical-library/reports"
                    >
                      reports
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/technical/technical-library/detailed-drawings"
                    >
                      detailed drawings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/technical/technical-library/technical-guides"
                    >
                      technical guides
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/technical/technical-library/installation-videos"
                    >
                      installation videos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/technical/technical-library/checklists"
                    >
                      checklists
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/technical/technical-library/bulletins"
                    >
                      technical bulletins
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="child-menu"
                      to="/technical/technical-library/safety-data-sheets"
                    >
                      safety data sheets
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/technical/technical-requests">
                  technical requests
                </NavLink>
              </li>
              <li>
                <NavLink to="/technical/education">aia education</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink htmlFor="drop-5" className="toggle" to="/contact-us">
              CONTACT US
            </NavLink>
            <NavLink className="main-menu" to="/contact-us">
              CONTACT US
            </NavLink>
          </li>
          <li>
            <label
              htmlFor="drop-4"
              className="toggle contact-no is-hidden-mobile is-hidden-desktop"
            >
              1-866-461-3914
            </label>
            <label className="main-menu contact-no is-hidden-mobile">
              {" "}
              1-866-461-3914
            </label>
          </li>
          {/* <label className="search-icon is-hidden-mobile"><FontAwesomeIcon icon={faSearch} /></label> */}
        </ul>
      </nav>
    </div>
  );
};

export default Menubar;
