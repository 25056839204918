import React from 'react'
import SliderGallery from '../components/SliderGallery'



const GraftonGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Dec-7-Grafton-Night-image-1-v1.3-HighRes.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Dec-7-Grafton-image-2-v1.4-HighRes.jpg"
    },
 
 
  ]

  return (
    <div>
      <SliderGallery
        title="Grafton"
        para1="Step aside traditional wood, Grafton is here.  A cool gray color and plank wood design, will leave you with minimal maintenance, and more time for what matters in your outdoor living space – you, family and friends."
        galleryImages={galleryImages}
        previouslink="/rowan-gallery/"
        nextlink="/ashdown-gallery/"
      />
    </div>
  )
}

export default GraftonGallery
