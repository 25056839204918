import React from 'react'
import { Container, Grid } from '@mui/material';
import TitleCard from '../components/TitleCard';

const Specification = () => {
    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/jenna-jacobs-533665-unsplash-min.jpg",
            firstText: 'SPECIFICATIONS',
            boldText: '',
            description: 'Superior products means superior performance. Here you will learn the process that supports Dec-Tec’s adherence to codes and standards.',
            isImageOnlyFlag: true,
            textShow: true,
            textDirect: true,
            textAlign: true,
            cartHeight: '85vh',
            bgAttachment: 'initial',
            bgPosition: 'center center',
        },
    ];
    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "10% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='technical_img'>
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Granite-6.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/Granite-6.jpg" alt='sectionThird' width={"100%"} className='first-image' />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap'>
                            <h3 className='heading-title  '> <span className='title-bold'>SPECIFICATION</span></h3>
                            <div className='divider 'style={{width:'25%'}} >
                            </div>
                            <div className='paragraph' style={{ padding: "0%  5% 5% 5%" ,textAlign:"center"}}>
                                <p>
                                Our<b> Thermoplastic Membrane Deck Waterproofing Specification </b>document provides you with the guidelines to our three part process (General, Products, and Execution) for each different project requirement.
                                </p>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                                <a href="https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/07-18-19-Thermoplastic-Membrane-Deck-Waterproofing.pdf" target={'_blank'} rel="noreferrer">
                                <button className='btnLearnMore '>Download</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Specification
