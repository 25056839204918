import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/multiImageSlider.css"
import { HashLink } from 'react-router-hash-link';

const MultiImageSliders = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        centerPadding: '10px',
        speed: 500,
        slidesToShow: props.noOfImages,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className='image-slider'>
                <Slider {...settings}>
                    {
                        props.array.map((item, index) => {
                       
                            return (
                                
                                <div className='slider-box' key={index}>
                                    <HashLink smooth to={item.link2 + "#" + item.id}>
                                    <img srcSet={item.imagePath} src={item.imagePath} alt='1' className='image-box'/>
                                    
                                    <div className="text-box">
                                        <div className="slider-text">{item.titleSlider}</div>
                                    </div>
                                    </HashLink>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </>
    )
}

export default MultiImageSliders