import { Container, Grid } from '@mui/material';
import React from 'react';
import TitleCard from '../components/TitleCard';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProductDocument = () => {
	const allCardDetails = [
		{
			id: 1,
			imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/Bronze-6.jpg",
			firstText: 'Product',
			boldText: 'Documents',
			description: '',
			isImageOnlyFlag: true,
			textShow: false,
			textDirect: false,
			textAlign: true,
			cartHeight: '100vh',
			bgAttachment: 'initial',
			bgPosition: 'center right',
			bgOpacity: "rgba(0, 0, 0, 0.4)",
		},
	];
	return (
		<div>
			<Grid xs={12}>
				<TitleCard cardData={{ allCardDetails }} />
			</Grid>
			<Container maxWidth='lg'>
				<div className='container' style={{ padding: '15% 0%' }}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 0 }}>
						<Grid item sm={12} xs={12} md={4} lg={4} >
							<div className='elementor-wrap-div '>
								<div className='elementor-row'>
									<h3 className='heading-title' style={{ fontSize: '2em' }}> <span className='title-bold'>CATALOG</span></h3>
								</div>
								<div className='divider' style={{ width: '25%' }}></div>
								<div className='paragraph'>
									<p>A in-depth document showcasing all of Dec-Tec’s products, including membranes, adhesives, and accessories.</p>
								</div>
								<div className='elementor-button'>
									<a href="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/aboutpdf/DEC-9_ProductCatalogueUpdates-v4.0_Single.pdf" target={'_blank'} rel="noreferrer">
										<button className='btnSecond'>Download</button>
									</a>
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<div className='elementor-wrap-div '>
								<div className='elementor'>
									<h3 className='heading-title' style={{ fontSize: '2em' }}> <span className='title-bold'>COLOR PAIRING</span></h3>
								</div>
								<div className='divider' style={{ width: '25%' }}></div>
								<div className='paragraph'>
									<p>Documents created to help you decide the right Dec-Tec membrane and accessory combination for your outdoor living space.</p>
								</div>
								<div className='pdflink'>
									<FontAwesomeIcon icon={faDownload} className='custom-icon' />
									<a href="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/aboutpdf/Classic Line - Modern & Functional Style.pdf" target={'_blank'} rel="noreferrer">Classic Line - Modern & Functional Style</a>
								</div>
								<div className='pdflink'>
									<FontAwesomeIcon icon={faDownload} className='custom-icon' />
									<a href="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/aboutpdf/Classic Line - Traditional & Functional Style.pdf" target={'_blank'} rel="noreferrer">Classic Line - Traditional & Functional Style</a>
								</div>
								<div className='pdflink'>
									<FontAwesomeIcon icon={faDownload} className='custom-icon' />
									<a href="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/aboutpdf/Coolstep Line - Keeping it Cool.pdf" target={'_blank'} rel="noreferrer">Coolstep Line - Keeping it Cool</a>
								</div>


							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<div className='elementor-wrap-div '>
								<div className='elementor'>
									<h3 className='heading-title' style={{ fontSize: '2em' }}> <span className='title-bold'>MAINTENANCE</span></h3>
								</div>
								<div className='divider' style={{ width: '25%' }}></div>
								<div className='paragraph'>
									<p>A guide that shows homeowners how to properly care for and maintain their Dec-Tec deck.</p>
								</div>
								<div className='elementor-button'>
									<a href="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/aboutpdf/AM001 - Dec-Tec Care & Maintenance Guide v.03.pdf" target={'_blank'} rel="noreferrer">
										<button className='btnSecond'>Download</button>
									</a>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			</Container>
		</div>
	);
};

export default ProductDocument;
