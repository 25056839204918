import React from 'react'
import './../css/faqs.css';
import TitleCard from '../components/TitleCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, } from '@mui/material'

const TechnicalBulletins = () => {
  const allCardDetails = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/pexels-pixabay-357514.jpg",
      firstText: 'TECHNICAL',
      boldText: 'BULLETINS',
      description: 'We at Dec-Tec are committed to continuous improvement and our bulletins are designed to provide quick, concise literature on our best practices and considerations in areas ranging from pre-installation to repair. Our bulletins are always being improved so check back regularly to make sure you have the latest and best information at your disposal.',
      isImageOnlyFlag: true,
      textShow: false,
      textDirect: false,
      textAlign: true,
      cartHeight: '88vh',
      bgAttachment: 'initial',
      bgPosition: 'center center',
    },
  ];
  return (
    <div>
      <Grid item xs={12}>
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>

      <Container maxWidth="lg" sx={{ padding: '70px 40px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} xs={12} md={4} lg={4}>
            <div className=''>
              <div className='elementor'>
                <div className='text-wrap'>
                  <h3 className='faq-Cart-heading-bold'><b>TECHNICAL BULLETINS</b></h3>
                </div>
                <div className='divider-faq'>
                  <span></span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item sm={12} xs={12} md={8} lg={8}>
            <section>
              <div className='accordation-wrapper'>
                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"

                    >
                      <p className='cart-heading'>TB001: <br /> DEC-CLAD DRIP EDGE - JOINT DETAIL V.02</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        The recommended procedure for installation of Dec‐Clad Drip Edge (Joint Detail) on wood substrates.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB001 - DEC-CLAD DRIP EDGE - JOINT DETAIL v.03.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB002: <br /> (NOMINAL) THICKNESS & MEASUREMENTS V.03</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Product
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide clarity on what nominal thickness is; as it relates to Dec-Tec; a method for measurement.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB002 - (NOMINAL) THICKNESS & MEASUREMENTS v.04.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB003: <br /> MICRO HOLES ON THE BACKSIDE OF DEC-TEC MEMBRANES V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Product
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To explain what the randomly appearing micro holes on the backside of Dec-Tec membranes are.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB003 - MICRO HOLES ON THE BACKSIDE OF DEC-TEC MEMBRANES v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>


                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB004: <br /> BITUMINOUS PRODUCTS COMING IN CONTACT WITH DEC‐TEC MEMBRANES V.02</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Installation, Repair, Other
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Information regarding the incompatibility of bituminous products and PVC.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB004 - BITUMINOUS PRODUCTS COMING IN CONTACT WITH DEC-TEC MEMBRANES v.03.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>


                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB005: <br /> STRUCTURAL RECOMMENDATIONS – FOOTINGS & FRAMING V.02</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Structural design and construction recommendations.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB005 - STRUCTURAL RECOMMENDATIONS - FOOTINGS  FRAMING v.03.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>


                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB006: <br /> SUBSTRATE RECOMMENDATIONS - WOOD V.04</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Wood substrate recommendations.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB006 - SUBSTRATE RECOMMENDATIONS - WOOD v.05.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB007: <br /> SUBSTRATE RECOMMENDATIONS - COVERING UNSUITABLE SUBSTRATE V.03</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Pre-Installation, Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Recommendations for covering unsuitable substrate.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB007 - SUBSTRATE RECOMMENDATIONS - COVERING UNSUITABLE SUBSTRATE v.04.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB008: <br />SUBSTRATE RECOMMENDATIONS - CONCRETE V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation, Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Concrete substrate recommendations.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB008 - SUBSTRATE RECOMMENDATIONS - CONCRETE v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB009:
                        <br /> ORIENTED STRAND BOARD (OSB) V.02</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation, Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        What to expect if Oriented Strand Board (OSB) is used in conjunction with Dec‐Tec
                        membranes.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB009 - ORIENTED STRAND BOARD (OSB) v.03.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB010: <br /> STRIPPING IN TECHNIQUE V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation, Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide installation instructions on “Stripping In” technique of Dec-Tec membranes.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB010 - STRIPPING IN TECHNIQUE v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB011:
                        <br /> BUBBLES IN DEC‐TEC MEMBRANE V.02</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Repair
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Instructions on how to repair bubbles in Dec-Tec membrane.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB011 - BUBBLES IN  DEC-TEC MEMBRANE v.03.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB012: <br /> MOISTURE V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide focused awareness of the issues associated to moisture.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB012 - MOISTURE v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB013: <br /> ACCEPTABLE VS NON-ACCEPTABLE DEC-TEC ADHESIVE V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Product
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide clarity on acceptable and non-acceptable Dec-Tec adhesives.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB013- ACCEPTABLE VS NON-ACCEPTABLE DEC-TEC ADHESIVE v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB014: <br /> DEC-TEC MEMBRANES & ORTHO-PHTHALATES (PLASTICIZERS) V.02</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Other
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide clarity on the relationship between Dec-Tec Membranes and ortho-phthalates as it relates to health and safety laws in the state of California.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB014 - DEC-TEC MEMBRANES & ORTHO-PHTHALATES (PLASTICIZERS) v.03.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB017: <br /> SOALR REFLECTIVE INDEX (SRI) V.02</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Product
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide clarity on warmest and coolest Dec-Tec membranes.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB017 - SOLAR REFLECTIVE INDEX (SRI) V.03 .pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB018: <br /> DEC-TEC MEMBRANE - SPLICES IN MASTER ROLLS V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Product
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To explain why master rolls of Dec-Tec would have splices in them.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB018 - DEC-TEC MEMBRANE SPLICES IN MASTER ROLLS v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB019: <br /> SOLVENT BASED ADHESIVE (SBA) 100 R VS SOLVENT BASED ADHESIVE (SBA) 200 VC V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Product
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To describe the key differences between SBA 100 R and 200 VC.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB019 - SBA 100R vs SBA 200 VC v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB020: <br /> COLD WEATHER APPLICATION V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation


                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide clarity on cold weather deck preparation and considerations.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB020 - COLD WEATHER APPLICATION v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB021: <br />COLD WEATHER APPLICATION OF DEC-TEC ADHESIVES V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Product, Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide clarity on cold weather application of Dec-Tec adhesives.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB021 - COLD WEATHER APPLICATION OF DEC-TEC ADHESIVES v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB028: <br />NATIONAL FLOOR SAFETY INSTITUTE (NFSI) V.02</p>
                    </AccordionSummary>
                    {/* <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation, Installation
                      </p>
                    </AccordionDetails> */}
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide an explanation about National Floor Safety Institute (NFSI), their role and
                        how they relate to Dec-Tec.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB028 - NATIONAL FLOOR SAFETY INSTITUTE (NFSI) v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB029: <br />INTERTEK v.02</p>
                    </AccordionSummary>
                    {/* <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation, Installation
                      </p>
                    </AccordionDetails> */}
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide an explanation about Intertek, their role and how they relate to Dec-Tec
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB029 - INTERTEK v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>


                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB030: <br />SUBSTRATE PREPARATION - PLYWOOD KNOTS V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Pre-Installation, Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Recommendations for how to deal with plywood knots.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB030 - SUSBSTRATE PREPARATION - PLYWOOD KNOTS v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB033: <br /> DEC-TEC MEMBRANE - COOLSTEP SLATE SINGLE DROP APPLICATION V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Installation
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Confirmation that Coolstep Slate is best utilized in a single drop application.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB033 - DEC-TEC MEMBRANE - COOLSTEP SLATE SINGLE DROP APPLICATION v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB034: <br />CODE COMPLIANCE RESEARCH REPORT (CCRR) V.03</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Other
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Explanation about Code Compliance Research Report (CCRR).
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB034 - CODE COMPLIANCE RESEARCH REPORT v.03.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB035: <br /> UNDERWRITERS LABORATORIES (UL) / UNDERWRITERS LABORATORIES OF CANADA (ULC) V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b>Other
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Provide an explanation about Underwriters Laboratories (UL)/Underwriters Laboratories of Canada (ULC), their role and how they relate to Dec-Tec.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB035 - UL-ULC v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB036: <br />STAIN REMOVAL V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Maintenance, Other
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Dec-Tec suggestions for the removal of stains.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB036 - STAIN REMOVAL v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB038: <br /> PONDING WATER V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Installation, External, Internal Use, Other
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Overview on common causes of ponding water and recommended solutions.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB038 - PONDING WATER v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB040:
                        <br /> GALVANIC CORROSION V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Installation, External, Internal Use
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide an overview of what galvanic corrosion is and how to guard against it within the Dec-Tec system.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB040 - GALVANIC CORROSION V.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB041: <br /> TEXAS DEPARTMENT OF INSURANCE (TDI)</p>
                    </AccordionSummary>
                    {/* <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Product, External
                      </p>
                    </AccordionDetails> */}
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        To provide an explanation about Texas Department of Insurance (TDI), their role and
                        how they relate to Dec-Tec.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB041 TEXAS DEPARTMENT OF INSURANCE (TDI) v.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>


                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB045: <br /> PRODUCT NOTICE PHASING OUT DEC-CORNERS - EXTERNAL V.01</p>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Product, External
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Notice of Dec-Corner phase out.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB045 PRODUCT NOTICE PHASING OUT DEC-CORNERS - EXTERNAL v.02 .pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>



                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB046: <br /> DEC-TEC WBA 100 EXTREME HEAT APPLICATION AND PERFORMANCE V.02</p>
                    </AccordionSummary>
                    {/* <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Product, External
                      </p>
                    </AccordionDetails> */}
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        Typically, during peak times of the summer or in tropical locations, contractors
                        experience extremely hot conditions that directly affect the application of the DecTec WBA and its cure time.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB046 - DEC-TEC WBA 100 EXTREME HEAT APPLICATION AND PERFORMANCE V.02.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className='accordation'>
                  <Accordion sx={{ padding: "0% 1% ", boxShadow: 'none' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className='cart-heading'>TB047: <br /> ADHESIVE TROWEL v.01</p>
                    </AccordionSummary>
                    {/* <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <b>Topics: </b> Product, External
                      </p>
                    </AccordionDetails> */}
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                      The importance of using the supplied adhesive trowel #8 V-notch.
                      </p>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <p className='paragraph-faqs-accord'>
                        <span className='cardLink'> <a className='cardLink-anchor' href='https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/TB047 - ADHESIVE TROWEL v.01.pdf' target={'_blank'} rel="noreferrer"><b>Click here</b></a></span> to download the document.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>

              </div>
            </section>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default TechnicalBulletins
