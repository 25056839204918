import React from 'react'
import { Container, Grid } from '@mui/material';
import TitleCard from '../components/TitleCard';
import { NavLink} from 'react-router-dom'


const Technical = () => {
    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/become-an-installer-template-min.jpg",
            firstText: 'LETS GET',
            boldText: 'TECHNICAL',
            description: 'Our technical team is the cornerstone of our business because their efforts provide us with our most prized opportunity: the ability to exceed expectations. Dec-Tec’s Technical Team is a driving force, relentlessly pushing to ensure the Dec-Tec brand is recognized as the brand of choice and that technical considerations are always top of mind.',
            isImageOnlyFlag: true,
            textShow: false,
            textDirect: false,
            textAlign: true,
            cartHeight: '100vh',
            bgAttachment: 'initial',
            bgPosition: 'center center',
        },
    ];
    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "10% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div >
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/tyler-franta-603090-unsplash-min.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/tyler-franta-603090-unsplash-min.jpg" alt='sectionThird' width={"100%"} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap left-div' >
                            <h3 className='heading-title'>  <span className=' text-with-border'>OUR</span> <span className='title-bold'>TECHNICAL</span> TEAM</h3>
                          
                            <div className='paragraph leftTextAlign' style={{paddingTop:"40px"}}>
                                <p>
                                    The technical team leads the charge on several, crucial fronts providing guidance and support on any Dec-Technical related matter.
                                </p>
                                <ul style={{textAlign:"left"}}>
                                    <li>Development and Delivery of the <span style={{color:"#0078a1"}}>Dec-Tec Professional Training Program</span></li>
                                    <li>Research and Development</li>
                                    <li>Product Testing</li>
                                    <li>Technical Support</li>
                                    <li>Technical Services including on-site field support for that special project</li>
                                </ul>
                                <p>Documentation such as technical bulletins, installation manuals, product manuals, detailed drawings, specifications, installation videos and more.</p>
                            </div>
                            {/* <div style={{ marginLeft: "15px" }}>
                                <button className='btnLearnMore '>Learn More</button>
                            </div> */}
                        </div>
                    </Grid>
                </Grid>
            </Container>

            <section style={{ backgroundColor: '#404040' }}>
                <Container maxWidth="lg" sx={{ padding: "10% 0%" }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                        <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                            <div className='product-div-wrap right-div'>
                                <h3 className='heading-title textWhite'> BECOME A DEC-TEC <span className='title-bold'><span className='text-with-border'>PRO</span>FESSIONAL</span></h3>

                                <div className='paragraph right-div textWhite' style={{ paddingTop:"30px" }}>
                                    <p>
                                        Dec-Tec boasts a technical team focused on developing and growing our technical support and training initiatives. The first standardized,
                                        in-person training program developed by our team is the Dec-Tec Professional program. This is targeted toward installers and provides best practices,
                                        tips, tricks and a base of knowledge on many of our standard details which are broken down between two formats; theoretical and practical. Our training
                                        schedule is currently full but you can be included on a waitlist by clicking the button below.
                                    </p>
                                </div>
                                <div style={{ marginRight: "15px" }}>
                                    <NavLink to="/apply-for-training/" alt="form">
                                        <button className='btnLearnMore textWhite'>APPLY FOR WAITLIST</button>
                                    </NavLink>
                                </div>
                            </div>
                        </Grid>



                        <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                            <div >
                                <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/rawpixel-1137314-unsplash-min-1024x683.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/rawpixel-1137314-unsplash-min-1024x683.jpg" alt='sectionThird' width={'100%'} />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </section>

            <Container maxWidth="lg" sx={{ padding: "10% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div >
                            <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/technical/linkedin-sales-navigator-402866-unsplash-min-1024x683.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/linkedin-sales-navigator-402866-unsplash-min-1024x683.jpg" alt='sectionThird' width={"100%"} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} lg={6} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap left-div'>
                            <h3 className='heading-title '> AIA PROFESSIONAL <br /><span className='title-bold'><span className='text-with-border'>DEVELOP</span>MENT</span></h3>
                            <div className='accessories-card-margin' >
                            </div>
                            <div className='paragraph left-div'>
                                <p>
                                    Dec-Tec is continually expanding its reach to the architectural and design communities. Our CE courses, approved by professional associations such as AIA, allow us to provide the most current professional development while assisting members to maintain their mandatory continuing education requirements.
                                </p>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                                <a href="/technical/education">
                                    <button className='btnLearnMore '>Learn More</button>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Technical
