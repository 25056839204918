import React from 'react'
import { Container, Grid } from '@mui/material';
import TitleCard from '../components/TitleCard';
import ImageSlider from '../components/ImageSlider';
import { HashLink } from 'react-router-hash-link';


const About = () => {

  const slides = [
    { url: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/g_shutterstock_279325493-Coolstep-1.jpg", title: "1" },
    { url: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/DT-CSL-Slate-1.jpg", title: "2" },
    { url: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/daniel-mccullough-348488-unsplash-min-1.jpg", title: "3" },
    { url: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/DT-CL-Granite-1.jpg", title: "4" },
    { url: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/DT-CL-Bronze-1.jpg", title: "5" },
    { url: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/DT-CSL-Sandalwood-1.jpg", title: "6" },
  ];
  const containerStyles = {
    width: "100%",
    height: "499px",
    margin: "0 auto",
  };

  const allCardDetails = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/become-an-installer-template-min.jpg",
      firstText: 'About',
      boldText: 'us',
      description: 'As a system, Dec-Tec provides a sophisticated and beautiful way to waterproof any number of areas including balconies, boathouses, walkways, stairs, boats and more. Being a high traction rated and low maintenance product, Dec-Tec is the best choice in walkable deck surface solutions.',
      isImageOnlyFlag: false,
      textShow: true,
      textDirect: true,
      textAlign: true,
      cartHeight: "100vh",
      bgAttachment: 'initial',
      bgPosition: 'center right',
    },
  ]

  return (
    <div>
      <Grid item xs={12}>
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>
      <Container maxWidth="lg" >
        <div className='container'>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
            <Grid item xs={12} md={6} lg={6} sx={{ alignItems: 'center', display: "flex" }} >
              <div className='elementor-wrap-div '>
                <div className='elementor-col  elementor-m-col '>
                  <h3 className='heading-title'> THE DEC-TEC </h3>
                  <h3 className='heading-title'> <span className='title-bold'>DIFFERENCE</span></h3>
                </div>
                <div className='divider'></div>
                <div className='paragraph'>
                  <p > Dec-Tec was created in 1981, an original brand owned by Skyline Building Systems (since purchased by Thermal Systems KWC Ltd.,
                    western Canada’s leading building envelope subcontractor). The only product of its kind to be made via <HashLink to="/about/faqs/#spreadcoat"> spreadcoat method </HashLink>,we continued to innovate and improve.</p>
                  <p>Our dedication to technical excellence has consistently put Dec-Tec at the forefront of our industry: from our manufacturing processes to the introduction of Real Technology, we have always led the way.</p>
                  <p>Fast forward to today, we have a dedicated team of technical experts backed by a passionate team who is concerned with one thing only: being the best.</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} sm={12} lg={6} >
              <div className='widget-wrap'>
                <div style={containerStyles}>
                  <ImageSlider slides={slides} />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

    </div>
  )
}

export default About;