// import { Container, Grid } from '@mui/material'
// import React from 'react'
// import { HashLink } from 'react-router-hash-link';

// import "../css/newProduct.css"


// const NewProduct = () => {


//     return (
//         <div>
//             <Container maxWidth="lg" >
//                 <div className='container'>
//                     <Grid container spacing={5} columnSpacing={{ xs: 5, sm: 5, md: 3 }}>
//                         <Grid item xs={12} md={6} lg={6} sx={{ alignItems: 'center', display: "flex" }} >
//                             <div className='elementor-wrap-div '>
//                                 <div className='elementor-row elementor-m-col '>
//                                     <h3 className='heading-title'> <span className='title-bold'>NEW</span></h3>
//                                     <h3 className='heading-title'> PRODUCTS </h3>
//                                 </div>
//                                 <div className='divider'></div>
//                                 <h4><strong className='str'>We’ve added four new membrane colors to our most popular Classic Line that are sure to impress!</strong></h4>
//                                 <div className='paragraph'>
//                                     <p >
//                                         Harland, Rowan, Grafton, and Ashdown boast unique wood designs on a PVC walkable surface and offer the same benefits you’ve come to love from our Classic Line.
//                                     </p>
//                                 </div>
//                                 <div className='elementor-button'>
//                                     <HashLink to="/products/membranes/classic#harland1">
//                                         <button className='btnSecond'>Learn More</button>
//                                     </HashLink>
//                                 </div>
//                             </div>
//                         </Grid>
//                         <Grid item xs={12} md={6} lg={6}>
//                             <div style={{ marginLeft: "15px" }}>
//                                 <div className='gallery'>
//                                     <div className='imagwrap'>
//                                         <div className='herland'>
//                                             <img src="https://dectecstorageprod.blob.core.windows.net/website/images/products/Harland-Tile-square.jpg" alt='herland' className='first-image' />
//                                             <div className="overlay">
//                                                 <div className="middle">
//                                                     <div className="new-product-text">Harland</div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className='herland'>
//                                             <img src="https://dectecstorageprod.blob.core.windows.net/website/images/products/Ashdown-Tile-square.jpg" alt='ashdown' className='first-image' />
//                                             <div className="overlay">
//                                                 <div className="middle">
//                                                     <div className="new-product-text">Ashdown</div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className='imagwrap'>
//                                         <div className='herland'>
//                                             <img src="https://dectecstorageprod.blob.core.windows.net/website/images/products/Grafton-Tile-square.jpg" alt='garton' className='first-image' />
//                                             <div className="overlay">
//                                                 <div className="middle">
//                                                     <div className="new-product-text">Grafton</div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className='herland'>
//                                             <img src="https://dectecstorageprod.blob.core.windows.net/website/images/products/Rowan-Tile-square.jpg" alt='rowan' className='first-image' />
//                                             <div className="overlay">
//                                                 <div className="middle">
//                                                     <div className="new-product-text">Rowan</div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </div>
//             </Container>
//         </div>
//     )
// }

// export default NewProduct




import { Container, Grid } from '@mui/material'
import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import "../css/newProduct.css"


const NewProduct = () => {
    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(galleryImages.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image  
    const nextSlide = () => {
        slideNumber + 1 === galleryImages.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }


    const galleryImages = [

        {
            img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Harland-Tile-square.jpg",
            title:"Harland"
        },
        {
            img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Ashdown-Tile-square.jpg",
            title:"Ashdown"
        },
        {
            img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Grafton-Tile-square.jpg",
            title:"Grafton"
        },
        {
            img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Rowan-Tile-square.jpg",
            title:"Rowan"
        },


    ]


    return (
        <div>
            <Container maxWidth="lg" >
                <div className='container'>
                    <Grid container spacing={5} columnSpacing={{ xs: 5, sm: 5, md: 3 }}>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ alignItems: 'center', display: "flex" }} >
                            <div className='elementor-wrap-div '>
                                <div className='elementor-row elementor-m-col '>
                                    <h3 className='heading-title'> <span className='title-bold'>NEW</span></h3>
                                    <h3 className='heading-title'> <span className='text-with-border'>PRO</span>DUCTS </h3>
                                </div>
                                <div className='divider-without-underline'></div>
                                <h4><strong className='str'>We’ve added four new membrane colors to our most popular Classic Line that are sure to impress!</strong></h4>
                                <div className='paragraph'>
                                    <p >
                                        Harland, Rowan, Grafton, and Ashdown boast unique wood designs on a PVC walkable surface and offer the same benefits you’ve come to love from our Classic Line.
                                    </p>
                                </div>
                                <div className='elementor-button'>
                                    <HashLink to="/products/membranes/classic-line#harland1">
                                        <button className='btnSecond'>Learn More</button>
                                    </HashLink>
                                </div>
                            </div>
                        </Grid>



                        <Grid item xs={12} sm={12} md={6} lg={6}>


                            {openModal &&
                                <div className='sliderWrap'>
                                    <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal} />
                                    <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
                                    <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
                                    <div className='fullScreenImage'>
                                        <img srcSet={galleryImages[slideNumber].img} src={galleryImages[slideNumber].img} alt='' />
                                    </div>
                                </div>
                            }       
                            <div>
                                <div className='gallery'>
                                    <div className='imagwrap2'>
                                    {
                                      galleryImages && galleryImages.map((slide, index) => {
                                           return (
                                        <div className='herland2'
                                        key={index}
                                         onClick={() => handleOpenModal(index)}>
                                            <img srcSet={slide.img} src={slide.img} alt='herland' className='first-image' />
                                            <div className="overlay">
                                                <div className="middle">
                                                    <div className="new-product-text">{slide.title}</div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                                           
                                    </div>
                                
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default NewProduct