import React, { useEffect, useRef, useState } from "react";
import "./../css/homePage.css";
import "./../css/ourWarranty.css";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TitleCard from "../components/TitleCard";
import { Add, Minimize } from "@mui/icons-material";
import {
  fetchMembranes,
  fetchRequestorTypes,
  fetchState,
  saveProjectFiles,
  savePurchaseInformationFiles,
  saveUsersProfile,
  saveWarrantyRequests,
  sendEmailForWarranty,
} from "../api/UserAPI";
import moment from "moment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const OurWarranty = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [membraneList, setMembraneList] = useState([]);
  const [requesterTypeList, setRequesterTypeList] = useState([]);
  const [pictureListOfInvoice, setPictureListOfInvoice] = useState([]);
  const [pictureListOfProject, setPictureListOfProject] = useState([]);
  const [membraneThickness60mil, setMembraneThickness60mil] = useState(false);
  const [membraneThickness80mil, setMembraneThickness80mil] = useState(false);
  const [disableCheckBox, setDisableCheckBox] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [warrentyFormErrors, setWarrentyFormErrors] = useState({});
  const [showMultiFamliy, setShowMultiFamily] = useState(false);
  const [invoiceFileSelected, setInvoiceFileSelected] = useState(false);
  const inputRefInvoiceFile = useRef(null);
  const inputRefProjectFile = useRef(null);
  const userInformationValues = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    stateId: 0,
    zipCode: "",
    email: "",
    phoneNo: "",
    // cellNo: '',
    // faxNo: '',
  };
  const [userInformationDetails, setUserInformationDetails] = useState(
    userInformationValues
  );

  const warrantyFormValues = {
    requesterTypeId: 0,
    companyName: "",
    projectName: "",
    companyPurchaseFormId: "",
    purchasePhoneNo: "",
    purchaseAddress: "",
    purchaseCity: "",
    purchaseStateId: 0,
    purchaseZipCode: "",
    installationAddress: "",
    installationCity: "",
    installationStateId: 0,
    installationZipCode: "",
    salesPersonId: "",
    installationDate: "",
    installationCompanyName: "",
    installationEmail: "",
    installationPhoneNo: "",
    sizeOfMembrane: "",
    installationCost: "",
    membraneId: 0,
    family: "",
    phase: 0,
    units: "",
  };

  const [warrantyFormDetails, setWarrantyFormDetails] =
    useState(warrantyFormValues);

  const allCardDetails = [
    {
      id: 1,
      imagePath:
        "https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/CoolStep-10.jpg",
      firstText: "our",
      boldText: "Warranty",
      description: "",
      isImageOnlyFlag: false,
      textShow: false,
      textDirect: true,
      textAlign: true,
      cartHeight: "100vh",
      bgAttachment: "initial",
      bgPosition: "center center",
    },
  ];

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleChangeUserInfo(e) {
    const { name, value } = e.target;
    setUserInformationDetails({ ...userInformationDetails, [name]: value });
  }

  function handleChangeWarrantyForm(e) {
    const { name, value } = e.target;
    setWarrantyFormDetails({ ...warrantyFormDetails, [name]: value });
    if (value === "Multi Family") {
      setShowMultiFamily(true);
    } else if (value === "Single Family") {
      setShowMultiFamily(false);
    }
  }

  const handleChooseFile = (e) => {
    setPictureListOfInvoice([]);
    const files = e.target.files;
    const pictures = [];
    Array.from(files).forEach((file) => pictures.push(file));
    setPictureListOfInvoice(() => [...pictureListOfInvoice, ...pictures]);
    setInvoiceFileSelected(true);
  };

  const handleChooseProjectFile = (e) => {
    setPictureListOfProject([]);
    const files = e.target.files;
    const pictures = [];
    Array.from(files).forEach((file) => pictures.push(file));
    setPictureListOfProject(() => [...pictureListOfProject, ...pictures]);
  };

  const handleDateChange = (date) => {
    const newDate = moment(date.$d).format("YYYY-MM-DD");
    setWarrantyFormDetails({
      ...warrantyFormDetails,
      installationDate: newDate,
    });
  };

  async function fetchStateList() {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: 1,
        IncludeRecordNr: true,
        SearchList: [{}],
        SortList: [
          {
            FieldName: "StateName",
            Direction: "ASC",
          },
        ],
      };
      const stateRes = await fetchState(obj);
      setStateList(stateRes);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchMembranesList() {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: 1,
        IncludeRecordNr: false,
        SearchList: [{}],
      };
      const membraneResult = await fetchMembranes(obj);
      setMembraneList(membraneResult);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchIAmField() {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: 1,
      IncludeRecordNr: true,
      SearchList: [{}],
    };
    try {
      const fetchRequestorTypesRes = await fetchRequestorTypes(obj);
      setRequesterTypeList(fetchRequestorTypesRes);
    } catch (error) {
      console.log(error);
    }
  }

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstName) {
      errors.firstName = "FirstName is required!";
    }
    if (!values.lastName) {
      errors.lastName = "LastName is required!";
    }
    if (!values.address) {
      errors.address = "Address is required!";
    }
    if (!values.city) {
      errors.city = "City is required!";
    }
    if (!values.stateId) {
      errors.stateId = "State is required!";
    }
    if (!values.zipCode) {
      errors.zipCode = "ZipCode is required!";
    }
    if (!values.phoneNo) {
      errors.phoneNo = "Phone Number is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    return errors;
  };

  const validateWarrenty = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.requesterTypeId) {
      errors.requesterTypeId = "Requester Type is required!";
    }
    if (!values.family) {
      errors.family = "Family is required!";
    }
    if (!values.salesPersonId) {
      errors.salesPersonId =
        "Name Of Installer And/Or Salesperson is required!";
    }
    if (!values.installationDate) {
      errors.installationDate = "Installation Date is required!";
    }
    if (!values.sizeOfMembrane) {
      errors.sizeOfMembrane = "Size / Quantity Of Membrane is required!";
    }
    if (!values.installationCost) {
      errors.installationCost = "Cost Of Installation is required!";
    }
    if (!values.membraneId) {
      errors.membraneId = "Membrane Design / Name is required!";
    }
    if (!invoiceFileSelected) {
      errors.invoiceFile = "Please upload invoice!";
    }

    if (values.family === "Multi Family") {
      if (!values.phase) {
        errors.phase = "phase is required!";
      }
      if (!values.units) {
        errors.units = "unit is required!";
      }
    }

    if (!values.installationEmail) {
        errors.installationEmail = "Email is required!";
      } else if (!emailRegex.test(values.installationEmail)) {
        errors.installationEmail = "This is not a valid email format!";
      }

      if (!values.installationPhoneNo) {
        errors.installationPhoneNo = "Phone Number is required!";
      }

    return errors;
  };

  function checkMembraneThikness(value) {
    if (value.membraneName === "Classic: Harland") {
      setDisableCheckBox("60");
    } else if (value.membraneName === "Classic: Rowan") {
      setDisableCheckBox("60");
    } else if (value.membraneName === "Classic: Grafton") {
      setDisableCheckBox("60");
    } else if (value.membraneName === "Classic: Ashdown") {
      setDisableCheckBox("60");
    } else if (value.membraneName === "Classic: Bronze") {
      setDisableCheckBox("both");
    } else if (value.membraneName === "Classic: Solid Grey") {
      setDisableCheckBox("60");
    } else if (value.membraneName === "Classic: Brick") {
      setDisableCheckBox("both");
    } else if (value.membraneName === "Classic: Beige") {
      setDisableCheckBox("60");
    } else if (value.membraneName === "Classic: Granite") {
      setDisableCheckBox("both");
    } else if (value.membraneName === "Coolstep") {
      setDisableCheckBox("80");
    } else if (value.membraneName === "Coolstep Copper") {
      setDisableCheckBox("80");
    } else if (value.membraneName === "Coolstep Sandalwood") {
      setDisableCheckBox("80");
    } else if (value.membraneName === "Coolstep Slate") {
      setDisableCheckBox("80");
    }
  }

  async function submitHandleSend() {
    setFormErrors(validate(userInformationDetails));
    setWarrentyFormErrors(validateWarrenty(warrantyFormDetails));
    onSubmitcall();
    if (Object.keys(validateWarrenty(warrantyFormDetails)).length === 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    inputRefInvoiceFile.current.value = null;
    inputRefProjectFile.current.value = null;
  }

  async function getFinalApiCall() {
    try {
       setUserInformationDetails(userInformationValues);
      setWarrantyFormDetails(warrantyFormValues);
    } catch (err) {
      console.log("submitHandleSend --> ", err);
    }
  }


  const onSubmitcall = async () => {
    const usersProfileObject = {
      UserId: 1,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          FirstName: userInformationDetails.firstName,
          LastName: userInformationDetails.lastName,
          Address: userInformationDetails.address,
          City: userInformationDetails.city,
          StateId: userInformationDetails.stateId,
          PostalCode: userInformationDetails.zipCode,
          Email: userInformationDetails.email,
          PhoneNumber: userInformationDetails.phoneNo,
          // CellNumber: userInformationDetails.cellNo,
          // FaxNumber: userInformationDetails.faxNo,
          // ModifiedBy: 1
        },
      ],
    };

    const usersProfileResponces = await saveUsersProfile(usersProfileObject);
    if (usersProfileResponces[0].objData.id !== "") {
      const warrantyRequestsObject = {
        UserId: 1,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            UserId: usersProfileResponces[0].objData.id,
            RequestorTypeId: warrantyFormDetails?.requesterTypeId,
            CompanyName: warrantyFormDetails?.companyName,
            ProjectName: warrantyFormDetails?.projectName,
            CompanyPurchasedFrom: warrantyFormDetails?.companyPurchaseFormId,
            PurchasePhoneNumber: warrantyFormDetails?.purchasePhoneNo,
            PurchaseAddress: warrantyFormDetails?.purchaseAddress,
            PurchaseCity: warrantyFormDetails?.purchaseCity,
            PurchaseStateId: warrantyFormDetails?.purchaseStateId,
            PurchasePostalCode: warrantyFormDetails?.purchaseZipCode,
            InstallationAddress: warrantyFormDetails?.installationAddress,
            InstallationCity: warrantyFormDetails?.installationCity,
            InstallationStateId: warrantyFormDetails?.installationStateId,
            InstallationPostalCode: warrantyFormDetails?.installationZipCode,
            InstallerNameAndSalesPerson: warrantyFormDetails?.salesPersonId,
            InstallationDate: warrantyFormDetails?.installationDate,
            InstallationCompanyName: warrantyFormDetails?.installationCompanyName,
            InstallationEmail: warrantyFormDetails?.installationEmail,
            InstallationPhoneNo: warrantyFormDetails?.installationPhoneNo,
            SizeorQuantityofMembrane: warrantyFormDetails?.sizeOfMembrane,
            InstallationCost: warrantyFormDetails.installationCost,
            MembraneId: warrantyFormDetails.membraneId?.id,
            MembraneThickness60mil: membraneThickness60mil ? 1 : 0,
            MembraneThickness80mil: membraneThickness80mil ? 1 : 0,
            ModifiedBy: usersProfileResponces[0].objData.id,
            Family: warrantyFormDetails?.family,
            Phase: warrantyFormDetails?.phase,
            Units:
              warrantyFormDetails?.units === ""
                ? 0
                : warrantyFormDetails?.units,
          },
        ],
      };
      const warrantyRequestResponces = await saveWarrantyRequests(
        warrantyRequestsObject
      );

      if (warrantyRequestResponces.length !== 0) {
        pictureListOfInvoice.map(async (img) => {
          const formData1 = new FormData();
          formData1.append("Id", 0);
          formData1.append(
            "WarrantyId",
            warrantyRequestResponces[0].objData.id
          );
          formData1.append("FileName", img);
          formData1.append("ModifiedBy", usersProfileResponces[0].objData.id);
          const savePurchaseInformationFilesres =
            await savePurchaseInformationFiles(formData1);
        });
        pictureListOfProject.map(async (img) => {
          const formData2 = new FormData();
          formData2.append("Id", 0);
          formData2.append(
            "WarrantyId",
            warrantyRequestResponces[0].objData.id
          );
          formData2.append("FileName", img);
          formData2.append("IsPermissionGranted", checked);
          formData2.append("ModifiedBy", usersProfileResponces[0].objData.id);
          const saveProjectFilesres = await saveProjectFiles(formData2);
        });
      }
      setTimeout(() => {
        if (warrantyRequestResponces[0].objData.id !== "") {
          callEmailApi(warrantyRequestResponces[0].objData.id);
          setPictureListOfInvoice([]);
          setPictureListOfProject([]);
          setMembraneThickness60mil(false);
          setMembraneThickness80mil(false);
          setDisableCheckBox("");
        } else {
          console.log("NOT FOUND");
        }
      }, 3000);
    }
  }

  const callEmailApi = async (props) => {
    setLoading(false);
    const warrantyEmailObjectNew = { WarrantyId: props };
    const res = await sendEmailForWarranty(warrantyEmailObjectNew);
    if (res.message === "OK") {
      alert("Submitted Successfully...");
      window.location.reload();
    }
  };
  useEffect(() => {
    fetchMembranesList();
    fetchStateList();
    fetchIAmField();
  }, []);

  useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      Object.keys(warrentyFormErrors).length === 0
    ) {
      getFinalApiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors, warrentyFormErrors]);

  return (
    <div>
      <Grid item xs={12}>
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>

      <Container maxWidth="lg">
        <div style={{ padding: "10% 0%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 6 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sm={12}
              lg={6}
              sx={{ alignItems: "center", display: "block" }}
              className="my-container"
              id="one"
            >
              <div style={{ margin: "0% 10% 10% 10%" }}>
                <img
                  srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/iStock-175185702_coolstep-edt3-o33ihq4113nfqkl05m056bck4c97tovxr9mfjvbr0o.jpg"
                  src="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/iStock-175185702_coolstep-edt3-o33ihq4113nfqkl05m056bck4c97tovxr9mfjvbr0o.jpg"
                  alt="sectionThird"
                  width={"100%"}
                />
              </div>
            </Grid>
            <Grid
              item
              lg={6}
              sm={12}
              xs={12}
              md={6}
              sx={{ alignItems: "center" }}
              className="my-container"
              id="two"
            >
              <div className="elementor-wrap-div">
                <div className="elementor-col elementor-m-col">
                  <h3 className="heading-title"> OUR DEC-TEC</h3>
                  <h3 className="heading-title">
                    {" "}
                    <span className="title-bold">WARRANTY </span>{" "}
                  </h3>
                </div>
                <div className="divider"></div>
                <div className="paragraph">
                  <p>
                    Our warranties are close to our hearts because we know that
                    a product’s warranty is a nod to its integrity. We designed
                    our warranties to keep our customer’s mind at ease, but also
                    to show that we stand by our product.
                  </p>
                </div>
                <div className="elementor-button">
                  <a
                    href="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/aboutpdf/Dec-Tec_Warranty.pdf"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <button className="btnSecond ">Download</button>
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

      <section className="waranty-section-third padding-lg"></section>

      <Container maxWidth="lg" sx={{ padding: "30px 15px" }}>
        <div className="warranty-form ">
          <div className="elementor-wrap-div" style={{ textAlign: "center" }}>
            <h3 className="heading-title heading-title2">
              {" "}
              <span className="title-bold text-with-border">REGISTER</span> YOUR
              WARRANTY{" "}
            </h3>
            <div className="divider-without-underline"></div>
          </div>
          <div className="mb-50">
            <h1>
              <b>YOUR </b> <span className="thin-content">INFORMATION</span>
            </h1>
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item lg={6} sm={12} xs={12} md={6}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                I AM A... <span className="mandatoryField">*</span>
              </InputLabel>
              <FormControl style={{ width: "100%" }}>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  name="requesterTypeId"
                  value={warrantyFormDetails.requesterTypeId}
                  displayEmpty
                  onChange={handleChangeWarrantyForm}
                >
                  {requesterTypeList.map(({ id, requestorTypeName }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {requestorTypeName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <p style={{ color: "red" }}>
                {warrentyFormErrors.requesterTypeId}
              </p>
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={6}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                Select Family <span className="mandatoryField">*</span>
              </InputLabel>
              <FormControl style={{ width: "100%" }}>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  name="family"
                  value={warrantyFormDetails.family}
                  displayEmpty
                  onChange={handleChangeWarrantyForm}
                >
                  <MenuItem value={"Single Family"}>Single Family</MenuItem>
                  <MenuItem value={"Multi Family"}>Multi Family</MenuItem>
                </Select>
              </FormControl>
              <p style={{ color: "red" }}>{warrentyFormErrors.family}</p>
            </Grid>

            <Grid item md={6} lg={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                Homeowner/client first name{" "}
                <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="firstName"
                type="text"
                name="firstName"
                required
                value={userInformationDetails.firstName}
                fullWidth
                onChange={handleChangeUserInfo}
                autoComplete="off"
              />
              <p style={{ color: "red" }}>{formErrors.firstName}</p>
            </Grid>
            <Grid item md={6} lg={6} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                Homeowner/client Last Name{" "}
                <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="lastName"
                type="text"
                name="lastName"
                value={userInformationDetails.lastName}
                required
                fullWidth
                onChange={handleChangeUserInfo}
                autoComplete="off"
              />
              <p style={{ color: "red" }}>{formErrors.lastName}</p>
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                ADDRESS <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="123 Main Street"
                type="text"
                name="address"
                value={userInformationDetails.address}
                fullWidth
                onChange={handleChangeUserInfo}
              />
              <p style={{ color: "red" }}>{formErrors.address}</p>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                CITY <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="New York City"
                type="text"
                name="city"
                value={userInformationDetails.city}
                fullWidth
                onChange={handleChangeUserInfo}
              />
              <p style={{ color: "red" }}>{formErrors.city}</p>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                STATE/PROVINCE <span className="mandatoryField">*</span>
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  name="stateId"
                  value={userInformationDetails.stateId}
                  onChange={handleChangeUserInfo}
                >
                  {stateList.map((values, index) => {
                    return (
                      <MenuItem key={index} value={values.id}>
                        {values.stateName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <p style={{ color: "red" }}>{formErrors.stateId}</p>
              </FormControl>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                ZIP/POSTAL CODE <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="85001 or A1B 2C3"
                type="text"
                size="small"
                name="zipCode"
                value={userInformationDetails.zipCode}
                color="info"
                fullWidth
                onChange={handleChangeUserInfo}
              />
              <p style={{ color: "red" }}>{formErrors.zipCode}</p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                EMAIL <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="johndoe@email.com"
                type="email"
                size="small"
                color="info"
                value={userInformationDetails.email}
                name="email"
                fullWidth
                onChange={handleChangeUserInfo}
                autoComplete="off"
              />
              <p style={{ color: "red" }}>{formErrors.email}</p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                PHONE NUMBER <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="1-555-123-4567"
                type="number"
                inputProps={{ maxLength: 10 }}
                size="small"
                color="info"
                value={userInformationDetails.phoneNo}
                name="phoneNo"
                fullWidth
                onChange={handleChangeUserInfo}
                autoComplete="off"
              />
              <p style={{ color: "red" }}>{formErrors.phoneNo}</p>
            </Grid>

            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                            <InputLabel shrink htmlFor="bootstrap-input"> CELL NUMBER </InputLabel>
                            <TextField
                                placeholder="1-555-012-3456"
                                type="number"
                                size="small"
                                color="info"
                                value={userInformationDetails.cellNo}
                                name="cellNo"
                                fullWidth
                                onChange={handleChangeUserInfo}
                            />
                            <p style={{ color: 'red' }}></p>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> FAX NUMBER </InputLabel>
                            <TextField
                                placeholder="1-555-456-7890"
                                type="number"
                                size="small"
                                color="info"
                                value={userInformationDetails.faxNo}
                                name="faxNo"
                                fullWidth
                                onChange={handleChangeUserInfo}
                            />
                        </Grid> */}

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                COMPANY NAME (IF APPLICABLE){" "}
              </InputLabel>
              <TextField
                placeholder="Joe's Contractors Inc."
                type="text"
                name="companyName"
                value={warrantyFormDetails.companyName}
                fullWidth
                onChange={handleChangeWarrantyForm}
                autoComplete="off"
              />
              <p style={{ color: "red" }}></p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                PROJECT NAME (IF APPLICABLE)
              </InputLabel>
              <TextField
                placeholder="The Jones Residence"
                type="text"
                name="projectName"
                value={warrantyFormDetails.projectName}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
            </Grid>

            {showMultiFamliy && (
              <>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  {/* Render Phase Field */}
                  <InputLabel shrink htmlFor="bootstrap-input">
                    {" "}
                    Phase/buildings <span className="mandatoryField">*</span>
                  </InputLabel>
                  <FormControl style={{ width: "100%" }}>
                    <Select
                      inputProps={{ "aria-label": "Without label" }}
                      name="phase"
                      value={warrantyFormDetails.phase}
                      displayEmpty
                      onChange={handleChangeWarrantyForm}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                  <p style={{ color: "red" }}>{warrentyFormErrors.phase}</p>
                </Grid>

                <Grid item md={3} lg={3} sm={12} xs={12}>
                  {/* Render Units Field */}
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input"
                    className="textField-label"
                  >
                    {" "}
                    Units <span className="mandatoryField">*</span>
                  </InputLabel>
                  <TextField
                    placeholder="Units-between-1-244"
                    type="number"
                    size="small"
                    name="units"
                    color="info"
                    value={warrantyFormDetails.units}
                    fullWidth
                    onChange={handleChangeWarrantyForm}
                  />
                  <p style={{ color: "red" }}>{warrentyFormErrors.units}</p>
                </Grid>
              </>
            )}
          </Grid>
          <div className="mt-100 mb-50">
            <h1>
              <b>PURCHASE </b> <span className="thin-content">INFORMATION</span>
            </h1>
          </div>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                COMPANY PURCHASED FROM{" "}
              </InputLabel>
              <TextField
                placeholder="Joe's Decking Supplies"
                type="text"
                name="companyPurchaseFormId"
                value={warrantyFormDetails.companyPurchaseFormId}
                fullWidth
                onChange={handleChangeWarrantyForm}
                autoComplete="off"
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                PHONE NUMBER{" "}
              </InputLabel>
              <TextField
                placeholder="1-555-123-4567"
                type="number"
                size="small"
                color="info"
                name="purchasePhoneNo"
                value={warrantyFormDetails.purchasePhoneNo}
                fullWidth
                onChange={handleChangeWarrantyForm}
                autoComplete="off"
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                ADDRESS{" "}
              </InputLabel>
              <TextField
                placeholder="123 Main Street"
                type="text"
                name="purchaseAddress"
                value={warrantyFormDetails.purchaseAddress}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                CITY{" "}
              </InputLabel>
              <TextField
                placeholder="New York City"
                type="text"
                name="purchaseCity"
                value={warrantyFormDetails.purchaseCity}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                STATE/PROVINCE{" "}
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  name="purchaseStateId"
                  value={warrantyFormDetails.purchaseStateId}
                  displayEmpty
                  onChange={handleChangeWarrantyForm}
                >
                  {stateList.map(({ id, stateName }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {stateName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                ZIP/POSTAL CODE{" "}
              </InputLabel>
              <TextField
                placeholder="85001 or A1B 2C3"
                type="text"
                size="small"
                color="info"
                name="purchaseZipCode"
                value={warrantyFormDetails.purchaseZipCode}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                UPLOAD YOUR PURCHASE INVOICE{" "}
                <span className="mandatoryField">*</span>
              </InputLabel>
              <input
                id="imgInput"
                ref={inputRefInvoiceFile}
                accept="image/*"
                type="file"
                multiple
                onChange={(e) => handleChooseFile(e)}
              />
              <p style={{ color: "red" }}>{warrentyFormErrors.invoiceFile}</p>
            </Grid>
          </Grid>
          <div className="mt-100 mb-50">
            <h1>
              <b>INSTALLATION </b> <span className="thin-content">ADDRESS</span>
            </h1>
            <p>
              If installation address information is the same as above, please
              proceed to the next step.
            </p>
          </div>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                ADDRESS{" "}
              </InputLabel>
              <TextField
                placeholder="123 Main Street"
                type="text"
                name="installationAddress"
                value={warrantyFormDetails.installationAddress}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                CITY{" "}
              </InputLabel>
              <TextField
                placeholder="New York City"
                type="text"
                name="installationCity"
                value={warrantyFormDetails.installationCity}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                STATE/PROVINCE{" "}
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  name="installationStateId"
                  value={warrantyFormDetails.installationStateId}
                  displayEmpty
                  onChange={handleChangeWarrantyForm}
                >
                  {stateList.map(({ id, stateName }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {stateName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                ZIP/POSTAL CODE{" "}
              </InputLabel>
              <TextField
                placeholder="85001 or A1B 2C3"
                type="text"
                size="small"
                color="info"
                name="installationZipCode"
                value={warrantyFormDetails.installationZipCode}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
            </Grid>
          </Grid>

          <div className="mt-100 mb-50">
            <h1>
              <b>INSTALLATION </b> <span className="thin-content">DETAILS</span>
            </h1>
          </div>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                NAME OF INSTALLER<span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="John Smith (Installer) and Jane Doe (Salesperson)"
                type="text"
                name="salesPersonId"
                fullWidth
                value={warrantyFormDetails.salesPersonId}
                onChange={handleChangeWarrantyForm}
              />
              <p style={{ color: "red" }}>{warrentyFormErrors.salesPersonId}</p>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                COMPANY NAME (IF APPLICABLE){" "}
              </InputLabel>
              <TextField
                placeholder="Joe's Contractors Inc."
                type="text"
                name="installationCompanyName"
                value={warrantyFormDetails.installationCompanyName}
                fullWidth
                onChange={handleChangeWarrantyForm}
                autoComplete="off"
              />
              <p style={{ color: "red" }}></p>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                EMAIL <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="johndoe@email.com"
                type="email"
                size="small"
                color="info"
                value={warrantyFormDetails.installationEmail}
                name="installationEmail"
                fullWidth
                onChange={handleChangeWarrantyForm}
                autoComplete="off"
              />
              <p style={{ color: "red" }}>{warrentyFormErrors.installationEmail}</p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                PHONE NUMBER <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="1-555-123-4567"
                type="number"
                inputProps={{ maxLength: 10 }}
                size="small"
                color="info"
                value={warrantyFormDetails.installationPhoneNo}
                name="installationPhoneNo"
                fullWidth
                onChange={handleChangeWarrantyForm}
                autoComplete="off"
              />
              <p style={{ color: "red" }}>{warrentyFormErrors.installationPhoneNo}</p>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                DATE OF INSTALLATION <span className="mandatoryField">*</span>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="installationDate"
                  sx={{ width: "100%" }}
                  onChange={(e) => handleDateChange(e)}
                />
                <p style={{ color: "red" }}>
                  {warrentyFormErrors.installationDate}
                </p>
              </LocalizationProvider>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                SIZE / QUANTITY OF MEMBRANE (SQ FT){" "}
                <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="2000 square feet"
                type="number"
                size="small"
                color="info"
                name="sizeOfMembrane"
                value={warrantyFormDetails.sizeOfMembrane}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
              <p style={{ color: "red" }}>
                {warrentyFormErrors.sizeOfMembrane}
              </p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel
                className="textField-label"
                shrink
                htmlFor="bootstrap-input"
              >
                {" "}
                COST OF INSTALLATION ($){" "}
                <span className="mandatoryField">*</span>
              </InputLabel>
              <TextField
                placeholder="$5,000"
                type="number"
                size="small"
                color="info"
                name="installationCost"
                value={warrantyFormDetails.installationCost}
                fullWidth
                onChange={handleChangeWarrantyForm}
              />
              <p style={{ color: "red" }}>
                {warrentyFormErrors.installationCost}
              </p>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                MEMBRANE DESIGN / NAME <span className="mandatoryField">*</span>
              </InputLabel>
              <FormControl fullWidth>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  name="membraneId"
                  displayEmpty
                  value={warrantyFormDetails.membraneId}
                  onChange={(e) => {
                    handleChangeWarrantyForm(e);
                    checkMembraneThikness(e.target.value);
                  }}
                >
                  {membraneList.map((values, index) => {
                    return (
                      <MenuItem key={values.id} value={values}>
                        {values.membraneName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <p style={{ color: "red" }}>{warrentyFormErrors.membraneId}</p>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={0} xs={0}></Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                MEMBRANE THICKNESS{" "}
              </InputLabel>

              <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  disabled={
                    disableCheckBox === "60" ||
                    disableCheckBox === "both" ||
                    disableCheckBox === ""
                      ? false
                      : true
                  }
                  control={<Checkbox />}
                  label="60 mil"
                  name="membraneThickness60mil"
                  className="small-content"
                  checked={membraneThickness60mil}
                  onChange={(e) => setMembraneThickness60mil(e.target.checked)}
                />
                <FormControlLabel
                  disabled={
                    disableCheckBox === "80" ||
                    disableCheckBox === "both" ||
                    disableCheckBox === ""
                      ? false
                      : true
                  }
                  control={<Checkbox />}
                  label="80 mil"
                  name="membraneThickness80mil"
                  className="small-content"
                  checked={membraneThickness80mil}
                  onChange={(e) => setMembraneThickness80mil(e.target.checked)}
                />
              </FormGroup>
            </Grid>
          </Grid>
          {loading && (
            <div className="loader-overlay">
              <div className="loader-container">
                <div className="loader">
                  {/* <span className="loader-text">Please wait...</span> */}
                </div>
              </div>
            </div>
          )}
          <div align="center" className="mt-100 mb-50">
            <h6>
              We would love to see how you've used <b>Dec-Tec</b> in your
              projects. <br />
              <b>Submit photos</b> of your work below so we can show it off!
            </h6>
          </div>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                UPLOAD PHOTO(S) OF YOUR DEC-TEC PROJECTS{" "}
              </InputLabel>
              <input
                id="imgInput"
                ref={inputRefProjectFile}
                accept="image/*"
                type="file"
                multiple
                onChange={(e) => handleChooseProjectFile(e)}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InputLabel shrink htmlFor="bootstrap-input">
                {" "}
                PHOTO RELEASE{" "}
              </InputLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="I hereby grant my permission for Dec-Tec to use the photograph(s) submitted"
                  className="small-content"
                  value={checked}
                  onChange={() => setChecked(!checked)}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div
              className="elementor-button"
              style={{ justifyContent: "center" }}
            >
              <Button
                variant="contained"
                className="btnSecond"
                sx={{ bgcolor: "#00779f", width: "90%" }}
                onClick={(e) => submitHandleSend(e)}
              >
                Submit
              </Button>
            </div>
          </Grid>
        </div>
      </Container>

      <Container maxWidth="lg" style={{ marginTop: "100px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
          <Grid
            item
            lg={6}
            sm={12}
            xs={12}
            md={6}
            sx={{ alignItems: "center", display: "flex" }}
            className="my-container"
            id="three"
          >
            <div className="elementor-wrap-div2 leftTextAlign">
              <div className="elementor-col elementor-m-col leftTextAlign">
                <h3 className="heading-title"> NATURAL LINE</h3>
                <h3 className="heading-title">
                  {" "}
                  <span className="title-bold">WARRANTY ISSUES? </span>{" "}
                </h3>
              </div>
              <div className="divider"></div>
              <div className="paragraph left-div">
                <p>
                  {" "}
                  At Skyline Building Systems, product quality and customer
                  service are a top priority for us. We have become aware of an
                  issue impacting our Dec-Tec Natural Line offering where a
                  small percentage of the offering experiences an issue with
                  discoloration. If you believe that your installed Natural Line
                  product has experienced this quality issue, we want to hear
                  from you so that we can address your concerns.
                </p>
                <p>
                  Please send us an email to{" "}
                  <a href="mailto:warranty@dec-tec.com">warranty@dec-tec.com</a>{" "}
                  with the following information:
                </p>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            lg={6}
            sx={{ alignItems: "center", display: "block" }}
            className="my-container"
            id="four"
          >
            <div style={{ margin: "0% 10% 10% 10%" }}>
              <img
                srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/green-chameleon-21532-unsplash-min-o33ih893f8yzlzay1wa8cxusu0p8rfx1ct87fm28aw.jpg"
                src="https://dectecstorageprod.blob.core.windows.net/website/images/aboutus/green-chameleon-21532-unsplash-min-o33ih893f8yzlzay1wa8cxusu0p8rfx1ct87fm28aw.jpg"
                alt="sectionThird"
                width={"100%"}
              />
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ padding: "15px 0px 100px 0px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
          <Grid
            item
            lg={6}
            sm={12}
            xs={12}
            md={6}
            sx={{ alignItems: "center", display: "flex" }}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChangePanel("panel1")}
            >
              <AccordionSummary
                expandIcon={expanded === "panel1" ? <Minimize /> : <Add />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  <b>Information to Include</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="waranty-paragraph">
                  <p>1. Installation Address:</p>
                  <p>2. Installation Date:</p>
                  <p>3. Home Owner Information:</p>
                  <ul>
                    <li>Name:</li>
                    <li>Address:</li>
                    <li>Phone Number:</li>
                    <li>Email:</li>
                  </ul>
                  <p>4. Installing Contractor Information:</p>
                  <ul>
                    <li>Name:</li>
                    <li>Address:</li>
                    <li>Phone Number:</li>
                    <li>Email:</li>
                  </ul>
                  <p>5. Distributor / Supplier:</p>
                  <ul>
                    <li>Name:</li>
                    <li>Address:</li>
                    <li>Phone Number:</li>
                    <li>Email:</li>
                  </ul>
                  <p>6. Pictures</p>
                  <p>Please upload pictures that include the following:</p>
                  <ul>
                    <li>shows what the issue is</li>
                    <li>shows the entire deck surface</li>
                    <li>shows different vantage points of the deck</li>
                  </ul>
                  <p>7. Sketch (Deck Plan)</p>
                  <p>
                    Please upload the sketch plan of your deck. This does not
                    need to be to scale. Make sure the sketch includes the
                    following:&nbsp;
                  </p>
                  <ul>
                    <li>
                      measurements (ft/in) with total square footage identified
                    </li>
                    <li>
                      location of railing or any special features (if
                      applicable)
                    </li>
                  </ul>
                  <p>8. Invoices</p>
                  <p>Please upload the following invoices:&nbsp;</p>
                  <ul>
                    <li>Property Owner Invoice</li>
                    <li>Installers Purchase Invoice</li>
                  </ul>
                  <p>9. Warranty Registration Confirmation:</p>
                  <ul>
                    <li>Was a warranty registered? (Y/N)</li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default OurWarranty;
