import React from 'react'
import TitleCard from '../components/TitleCard'
import AccesoriesCard from '../components/AccesoriesCard'
import MultiImageSliders from '../components/MultiImageSliders'
import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import { faTrophy, faUsers, faWrench } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

const InstallationProducts = (props) => {

    const [postId,setPostId] = useState(0);

    function toggleRoute(type) {
        if (type === "Patch II") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Patch-II.pdf")
        }
        else if (type === "100") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/WBA-100-product-sheet.pdf")
        }

        else if (type === "100R") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/SBA-100R-product-sheet.pdf")
        }

        else if (type === "200 VC") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/SBA-200-product-sheet.pdf")
        }

        else if (type === "830") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Tremco-830-product-sheet.pdf")
        }
    }


    function toggleRoute2(type) {
        if (type === "Patch II") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/DEC-PATCH II.pdf")
        }
        else if (type === "100") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/DEC-TEC WBA100.pdf")
        }

        else if (type === "100R") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec SBA-100R-E.pdf")
        }

        else if (type === "200 VC") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Dec-Tec_SBA-200VC-E.pdf")
        }

        else if (type === "830") {
            window.open("https://dectecstorageprod.blob.core.windows.net/website/images/technical/technicalpdf/Tremco-830-materials-safety.pdf")
        }
    }

    const allCardDetails = [
        {

            id: 1,
			imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-Slate-4.jpg",
			firstText: 'OUR INSTALLATION',
			boldText: ' PRODUCTS',
			description: '',
			isImageOnlyFlag: true,
			textShow: false,
			textDirect: false,
			textAlign: true,
			cartHeight: '100vh',
			bgAttachment: 'initial',
			bgPosition: 'center center',
            
        },
    ]

    const sliderCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecPatch.jpg",
            titleSlider: 'Dec-Patch II',
            link2:`/products/adhesives/`
        },
        {
            id: 2,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/WBA100.jpg",
            titleSlider: 'WBA 100',
            link2:`/products/adhesives/`
        
        },
        {
            id: 3,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/SBA100R.jpg",
            titleSlider: 'SBA 100R',
            link2:`/products/adhesives/`
           
        },
        {
            id: 4,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/SBA200VC.jpg",
            titleSlider: 'SBA 200 VC',
            link2:`/products/adhesives/`
         
        },
        {
            id: 5,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/tremco.jpg",
            titleSlider: 'Tremco 830',
            link2:`/products/adhesives/`
  
        },
    ]
    const classicCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecPatch-o33ia3n4dmephu5nxbtgb1xl8n67gffew91n8pmiew.jpg",
            title: 'Dec-',
            titleSec: 'Patch II',
            isImageOnlyFlag: false,
            textShow: 'right',
            textbtn: 'PRODUCT DATASHEET',
            textbtn2: 'SAFETY DATA SHEET',
            description: 'Dec-Patch II is a high-performance skimcoating and patching compound used to smooth minor surface variations and defects on common substrates. It creates a strong, durable surface ready for almost any floor application. Its unique formulation mixes easily with water and is easy to apply with the supplied 1/16” x 3/32” V-notched Trowel. ',

        },
        {
            id: 2,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/WBA100-o33ilhgr5dzq3tmdg8ysi6ik8n28ogmdslhchcqv20.jpg",
            title: 'WBA',
            titleSec: '100',
            isImageOnlyFlag: true,
            textShow: 'left',
            textbtn: 'PRODUCT DATASHEET',
            textbtn2: 'SAFETY DATA SHEET',
            description: 'Dec-Tec WBA 100 is a high strength adhesive that offers excellent field workability and is compatible with all Dec-Tec membranes. Dec-Tec WBA 100 is a wet lay adhesive designed specifically for the installation of Dec-Tec PVC membrane roof/deck systems.'
        },
        {
            id: 3,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/SBA100R-o33ikndx2oujsau2bvyqae3t8b6hu5az0glt4hzgl4.jpg",
            title: 'SBA',
            titleSec: '100R',
            isImageOnlyFlag: false,
            textShow: 'right',
            textbtn: 'PRODUCT DATASHEET',
            textbtn2: 'SAFETY DATA SHEET',
            description: 'Dec-Tec SBA 100R is a high-quality solvent-based adhesive with excellent grab qualities. Whether on a flat or vertical surface, SBA 100R will adhere to any of Dec-Tec’s quality membranes, and preformed PVC accessories, easily and effectively. It also works well on clean metals and flashings.',
        },
        {
            id: 4,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/SBA200VC-o33ikr59u0zp2qolpxl8kd5nlunyoxpwcz7r1ltvw8.jpg",
            title: 'SBA',
            titleSec: '200 VC',
            isImageOnlyFlag: true,
            textShow: 'left',
            textbtn: 'PRODUCT DATASHEET',
            textbtn2: 'SAFETY DATA SHEET',
            description: 'Dec-Tec SBA 200 VC is a high-quality solvent-based adhesive with excellent grab qualities that has been formulated with EPA exempt solvents and has a VOC content of 0 g/L. Whether on a flat or vertical surface, SBA 100R will adhere to any of Dec-Tec’s quality membranes, and preformed PVC accessories, easily and effectively. It also works well on clean metals and flashings.',
        },
        {
            id: 5,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/tremco-o33il907fvo57byntnb5dqnew67xr6osrflz5v3em0.jpg",
            title: 'Tremco',
            titleSec: '830',
            isImageOnlyFlag: false,
            textShow: 'right',
            textbtn: 'PRODUCT DATASHEET',
            textbtn2: 'SAFETY DATA SHEET',
            description: 'Tremco 830 is a one-part, thermoplastic elastomer that provides a high performance, flexible seal. In sealing applications, Tremco 830 has proven to be 100% compatible with our membranes, wood, aluminum and PVC clad metal.',
        },
    ]

    return (
        <div>
            <Grid xs={12} >
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>

            <Container maxWidth="lg" sx={{ padding: "10% 0%" }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                
                <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignItems: 'center', display: "flex" }}>
                        <div className='product-div-wrap'>
                            <h3 className='heading-title' style={{ textAlign: 'center' }}>LEVELING PATCH, ADHESIVES, & SEALANTS</h3>
                            <div className='divider ' style={{ textAlign: 'center'}}></div>
                            <div className='paragraph ' style={{ textAlign: 'center',margin:"0 5%"}}>
                                <p >
                                    Dec-Tec is more than membrane: it’s a full system. We provide all of the necessary components to create a beautiful, waterproof deck.
                                    We offer a variety of adhesive options comprised of the highest –quality raw ingredients because we understand the stringent requirements
                                    in construction. We make the solutions easy for our contractors because we know their time and peace of mind is valuable
                                </p>
                                <p>
                                    For our homeowners, it means their decks are finished faster with standard, professional details so that they can get back to relaxing as soon as possible.
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} >
                        <div>
                            <MultiImageSliders array={sliderCardDetails} noOfImages={1} />
                        </div>
                    </Grid>
                </Grid>
            </Container>


            <section className='installation-section-third'>
                <Container maxWidth="lg" >
                    <Grid container spacing={2} sx={{ paddingTop: "10%" }}>
                        <div className='elementor' >
                            <h3 className='heading-title textWhite'>BENEFITS OF USING <span className='title-bold'>OUR SYSTEM </span></h3>
                            <div className='divider' style={{ width: '12%' }}></div>
                        </div>
                    </Grid>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid xs={12} sm={12} md={4} lg={4} >
                            <div className='Section-four-element-first'>
                                <div className='content-wrapper'>
                                    <div className='content-wrapper-icon'>
                                        <FontAwesomeIcon icon={faWrench} />
                                    </div>
                                    <div className='content-wrapper-heading'>
                                        <h3>FASTER INSTALLATION</h3>
                                    </div>
                                    <div className='content-wrapper-para'>
                                        <p>
                                            Pre-formed accessories means less time on job sites
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={4} lg={4}>
                            <div className='Section-four-element-first'>
                                <div className='content-wrapper'>
                                    <div className='content-wrapper-icon'>
                                        <FontAwesomeIcon icon={faUsers} />
                                    </div>
                                    <div className='content-wrapper-heading'>
                                        <h3>EASE OF USE</h3>
                                    </div>
                                    <div className='content-wrapper-para'>
                                        <p>
                                            Our line of accessories are designed to work flawlessly with Dec-Tec membranes
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={4} lg={4}>
                            <div className='Section-four-element-first'>
                                <div className='content-wrapper'>
                                    <div className='content-wrapper-icon'>
                                        <FontAwesomeIcon icon={faTrophy} />
                                    </div>
                                    <div className='content-wrapper-heading'>
                                        <h3>PROFESSIONAL DETAILS</h3>
                                    </div>
                                    <div className='content-wrapper-para'>
                                        <p>
                                            Our accessories provide consistent, high standard details for a beautiful installation
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                 <div className="elementor-shape elementor-shape-bottom" data-shape="triangle" data-negative="true">
          <svg viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path className="elementor-shape-fill" fill="#ffffff" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
          </svg>
        </div>
            </section>
            <section className='classic-section-fourth'>
                <div className='elementor' style={{ padding: "30px 0px" }}>
                    <h3 className='heading-title'> <span className='title-bold'>VIEW ALL </span> INSTALLATION PRODUCTS</h3>
                    <div className='divider' style={{ width: '5%' }}></div>
                </div>
            </section>
            <section className='accesories-section-fifth'>
                <Container maxWidth="lg" >
                    <AccesoriesCard postId={postId} toggleRoute={toggleRoute} toggleRoute2={toggleRoute2} cardData={classicCardDetails} />
                </Container>
            </section>
        </div >
    )
}

export default InstallationProducts













