import React from 'react'
import 'react-html5video/dist/styles.css';
// import { DefaultPlayer as Video } from 'react-html5video/dist';
import { Grid } from '@mui/material';
import TitleCard from '../components/TitleCard';
import InstallationVideoBitmovin from './InstallationVideoBitmovin';


const TestingVideos = () => {

    const videoList = [
		{
			title: 'Fire retardant test of Dec-Tec and other single-ply vinyl decking membranes',
			url: "https://streams.bitmovin.com/cnu78lsd1q7m56d4asl0/manifest.m3u8",
			maintitle: "FIRE",
			maintitleBold: "TESTING",
			poster: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/patrick-hendry-438653-unsplash-min.jpg"
		},
        {
			title: 'Video Description',
			url: "https://streams.bitmovin.com/cnu78dt100s5p8qiidj0/manifest.m3u8",
			maintitle: "UNDER",
            maintitleBold: "CONSTRUCTION",
			poster: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/roberto-nickson-g-694096-unsplash-min.jpg"
		},
        {
			title: 'Video Description',
			url: "https://streams.bitmovin.com/cnu7774d1q7m56d4askg/manifest.m3u8",
			maintitle: "UNDER",
            maintitleBold: "CONSTRUCTION",
			poster: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/fancycrave-219666-unsplash-min.jpg"
		},

	];

    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/technical/christin-hume-505823-unsplash-min.jpg",
            firstText: 'TESTING',
            boldText: 'VIDEOS',
            description: 'Below are a variety of testing videos include visual representation of our products undergoing controlled tests to illustrate the qualities of our products that set us apart from our competitors.',
            isImageOnlyFlag: true,
            textShow: false,
            textDirect: false,
            textAlign: true,
            cartHeight: '100vh',
            bgAttachment: 'initial',
            bgPosition: 'center center',
        },
    ];
  return (
    <div>
       <Grid item xs={12} >
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
      
                <section style={{ textAlign: "center" ,padding:"100px 80px"}} >
                    <div className='Wrapper'>
                    <Grid container  columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
                        {videoList.map((video) => (
                            <Grid item md={4} lg={4} sm={12} xs={12}> 
                                <InstallationVideoBitmovin videoUrl={video?.url} poster={video?.poster} />
                                <div className='title-container'>
                                    <h3 style={{ fontWeight: "lighter", marginTop:"20px" }} align="center" className='contentTitle'>{video?.maintitle}  <b>{video?.maintitleBold}</b></h3>
                                </div>
                                <p style={{ fontWeight: "lighter" }}>{video?.title}</p>
                            </Grid>
                            ))}
                            </Grid>
                        {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }}>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <div className='imagwrap'>
                                    <Video loop poster="https://dectecstorageprod.blob.core.windows.net/website/images/technical/patrick-hendry-438653-unsplash-min.jpg">
                                    <source src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/videos/video1.webm" type="video/webm"></source>
                                    </Video>
                                </div>
                                <div className='title-container'>
                                    <h3 style={{ fontWeight: "lighter", marginTop:"20px" }} align="center" className='contentTitle'>FIRE  <b>TESTING</b></h3>
                                </div>
                                <p style={{ fontWeight: "lighter" }}>Fire retardant test of Dec-Tec and other single-ply vinyl decking membranes</p>
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12} >
                                <div className='imagwrap'>
                                    <Video loop poster="https://dectecstorageprod.blob.core.windows.net/website/images/technical/roberto-nickson-g-694096-unsplash-min.jpg" >
                                    <source src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/videos/video2.mp4" type="video/mp4"></source>
                                    </Video>
                                </div>
                                <div className='title-container'>
                                    <h3 style={{ fontWeight: "lighter" , marginTop:"20px" }} align="center" className='contentTitle'>UNDER  <b>CONSTRUCTION</b></h3>
                                </div>
                                <p style={{ fontWeight: "lighter" }}>Video Description</p>
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <div className='imagwrap'>
                                    <Video loop poster="https://dectecstorageprod.blob.core.windows.net/website/images/technical/fancycrave-219666-unsplash-min.jpg">
                                    <source src="https://dectecstorageprod.blob.core.windows.net/website/images/technical/videos/video3.webm" type="video/webm"></source>
                                    </Video>
                                </div>
                                <div className='title-container'>
                                    <h3 style={{ fontWeight: "lighter" , marginTop:"20px" }} align="center" className='contentTitle'>UNDER  <b>CONSTRUCTION</b></h3>
                                </div>
                                <p style={{ fontWeight: "lighter" }}>Video Description</p>
                            </Grid>
                        </Grid> */}
                    </div>
                </section>
           
        </div>
   
  )
}

export default TestingVideos
