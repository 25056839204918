import React, { useState } from 'react'
import "../css/classicLine.css"
import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import TitleCard from '../components/TitleCard'
import ClassicCard from '../components/ClassicCard'
import MultiImageSliders from '../components/MultiImageSliders'
import { NavLink} from 'react-router-dom'

const ClassicLine = () => {

  
  const [postId,setPostId] = useState(0);

  function toggleRoute(type) {
    if (type === "harland") {
      window.open("/harland-gallery/")
    }
    else if (type === "rowan") {
      window.open("/rowan-gallery/")
    }

    else if (type === "grafton") {
      window.open("/grafton-gallery-2/")
    }

    else if (type === "ashdown") {
      window.open("/ashdown-gallery/")
    }

    else if (type === "bronze") {
      window.open("/bronze-gallery/")
    }

    else if (type === "brick") {
      window.open("/brick-gallery/")
    }

    else if (type === "granite") {
      window.open("/granite-gallery/")
    }
  }


  


  const allCardDetails = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Dec-7-Harland-Image-2-v1.2-HighRes.jpg",
      firstText: 'CLASSIC',
      boldText: 'LINE',
      description: '',
      isImageOnlyFlag: false,
      textShow: true,
      textDirect: true,
      textAlign: false,
      cartHeight: "100vh",
      bgAttachment: 'initial',
      bgPosition: 'bottom center',
    },
  ]

  const classicCardDetails = [
    {
      id: 11,
      position: 'one',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Dec-7-Harland-Image-2-v1.2-HighRes.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 1,
      position: 'two',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/i2M-HARLAND-Apr-30.jpg",
      isImageOnlyFlag: false,
      title: 'harland',
      textShow: 'left',
      textbtn2: 'Gallery',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'Modern style and functional performance. Invite Harland to your deck today. Combining both a cool gray color and slatted wood design, you’ll impress yourself and your guests.',
      coolstep:false,
    },
    {
      id: 2,
      position: 'three',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/i2M-ROWAN-Apr-30.jpg",
      isImageOnlyFlag: false,
      title: 'rowan',
      textShow: 'right',
      textbtn2: 'Gallery',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'Compliment your tan, brown, or beige tones on your house with Rowan. A flattering slatted wood design integrated with proven features and benefits will upgrade your outdoor living space and have your friends dropping ‘wow’ bombs.',
      coolstep:false,
    },
    {
      id: 22,
      position: 'four',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Dec-7-ROWAN-Balcony-Image-v1.2-HighRes.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 33,
      position: 'five',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Dec-7-Grafton-Night-image-1-v1.3-HighRes.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 3,
      position: 'six',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/i2M-GRAFTON-Apr-30.jpg",
      isImageOnlyFlag: false,
      title: 'grafton',
      textShow: 'left',
      textbtn2: 'Gallery',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'Step aside traditional wood, Grafton is here.  A cool gray color and plank wood design, will leave you with minimal maintenance, and more time for what matters in your outdoor living space – you, family and friends.',
      coolstep:false,
    },
    {
      id: 4,
      position: 'seven',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/i2M-ASHDOWN-Apr-30-1.jpg",
      isImageOnlyFlag: false,
      title: 'ashdown',
      textShow: 'right',
      textbtn2: 'Gallery',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'Elegance, performance, and protection, that’s what Ashdown offers. The plank wood design and brown coloring is cool to walk on the hottest of days and warm enough to entertain friends and family well into the evening.',
      coolstep:false,
    },
    {
      id: 44,
      position: 'eight',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Dec-7-Ashdown-image-1-v1.1-HighRes.jpg",
      isImageOnlyFlag: true,
      title: '',
      description: '',
      textShow: '',
    },

    {
      id: 55,
      position: 'nine',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/DT-CL-Bronze-1.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 5,
      position: 'ten',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-bronze-01.jpg",
      isImageOnlyFlag: false,
      title: 'bronze',
      textShow: 'left',
      textbtn2: 'Gallery',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'A neutral winner, Bronze is another hit with customers. Beautiful against brick and stucco and perfect for contrast against cool colors, this vinyl decking product is a clear winner..',
      coolstep:false,
    },
    {
      id: 6,
      position: 'eleven',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-solid-grey-01.jpg",
      isImageOnlyFlag: false,
      title: 'solid grey',
      textShow: 'right',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'If you’re looking for the ultimate neutral, Solid Grey is your answer. It is a cooler design that complements greys and blues, or can contrast with brick and stucco. We currently do not have any gallery pictures for this membrane. If you have any pictures of this membrane, please send them in to us!',
      coolstep:false,
    },
    {
      id: 66,
      position: 'twelve',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Dec-7-Harland-Image-1-v1.2-LowRes.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 77,
      position: 'thirteen',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/AdobeStock_193966827_BRICK-min.jpg",
      isImageOnlyFlag: true,
      title: '',
      textShow: '',
      description: '',
    },
    {
      id: 7,
      position: 'fourteen',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-brick-01.jpg",
      isImageOnlyFlag: false,
      title: 'brick',
      textShow: 'left',
      textbtn2: 'Gallery',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'A staple in our Classic Line, Brick is a popular, neutral design that we can’t keep on the shelves. It is a tough, clean and timeless waterproof decking product.',
      coolstep:false,
    },
    {
      id: 8,
      position: 'fifteen',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-beige.jpg",
      isImageOnlyFlag: false,
      title: 'beige',
      textShow: 'right',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'Simple, warm, and clean – Beige is all the above. Similar in design to solid grey, it is a warmer design that compliments brown and tan tones and pairs well with brick, stucco, and siding. We currently do not have any gallery pictures for this membrane. If you have any pictures of this membrane, please send them in to us!',
      coolstep:false,
    },
    {
      id: 88,
      position: 'sixteen',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/DT-CSL-Slate-1.jpg",
      isImageOnlyFlag: true,
      title: '',
      description: '',
      textShow: '',
    },
    {
      id: 99,
      position: 'seventeen',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/DT-CL-Granite-1.jpg",
      isImageOnlyFlag: true,
      title: '',
      description: '',
      textShow: '',
    },
    {
      id: 9,
      position: 'eighteen',
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-granite-01.jpg",
      isImageOnlyFlag: false,
      title: 'granite',
      textShow: 'left',
      textbtn2: 'Gallery',
      link2:"/products/membranes/classic-line/#classicmembraneinfo",
      description: 'One of our most popular membranes, Granite is a stunning design. Save time and maintenance of your current deck with granite’s cool colors, perfect for creating a contrast in warm color tones and complementing greys and blues.',
      coolstep:false,
    },
  ]
  const multiImages = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/i2M-HARLAND-Apr-30.jpg",
      titleSlider: 'Harland',
      link2:`/products/membranes/classic-line`,
    },
    {
      id: 2,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/i2M-ROWAN-Apr-30.jpg",
      titleSlider: 'Rowan',
      link2:`/products/membranes/classic-line`,
    },
    {
      id: 3,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/i2M-GRAFTON-Apr-30.jpg",
      titleSlider: 'Grafton',
      link2:`/products/membranes/classic-line`,
    },
    {
      id: 4,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/i2M-ASHDOWN-Apr-30-1.jpg",
      titleSlider: 'Ashdown',
      link2:`/products/membranes/classic-line`,
    },
    {
      id: 5,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-bronze-01.jpg",
      titleSlider: 'Bronze',
      link2:`/products/membranes/classic-line`,
    },
    {
      id: 6,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-solid-grey-01.jpg",
      titleSlider: 'Solid Grey',
      link2:`/products/membranes/classic-line`,
    },
    {
      id: 7,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-brick-01.jpg",
      titleSlider: 'Brick',
      link2:`/products/membranes/classic-line`,
    },
    {
      id: 8,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-beige.jpg",
      titleSlider: 'Beige',
      link2:`/products/membranes/classic-line`,
    },
    {
      id: 9,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-granite-01.jpg",
      titleSlider: 'Granite',
      link2:`/products/membranes/classic-line`,
    },
  ]

  return (
    <div>
      <Grid xs={12} >
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>

      <Container maxWidth="lg" sx={{ padding: "125px 0px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            <div className='product-div-wrap '>
              <div className='elementor'>
                <h3 className='heading-title'> <span className='title-bold'>FUNCTIONAL STYLE </span> </h3>
                <h3 className='heading-title'>  Traditional & Modern</h3>
                <div className='divider'></div>
              </div>
              <div className='paragraph' style={{ textAlign: "center"}}>
                <p >
                  The Classic Line is one of our most popular product lines. Each membrane offers a functional and traditional feel with
                  cutting edge membrane technology so you can enjoy style, comfort and protection.
                </p>
                <p >
                  Our classic colors compliment all décor styles and accentuate any outdoor space, whether it’s a deck, patio, boathouse,
                  or walkways. Designed to be neutral and durable, our Classic Line is stunning in any application, from commercial to residential.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item md={6} lg={6} xs={12} sm={12} >
            <div>
              <MultiImageSliders array={multiImages} noOfImages={3} />
            </div>
          </Grid>
        </Grid>
      </Container>
      
      <section className='classic-section-third parent' >
        <div className='child-overlay'></div>
        <Container maxWidth="lg" sx={{ paddingTop: "80px" }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: "20px" }}>
            <Grid item md={6} lg={6} xs={12} sm={12} >
              <div className='classline-img'>
                <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/products/iStock-175998824_bronze-edt-1024x681.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/products/iStock-175998824_bronze-edt-1024x681.jpg" alt='sectionThird' width={"100%"} />
              </div>
            </Grid>
            <Grid item md={6} lg={6} xs={12} sm={12} sx={{ alignItems: 'center', display: "flex" }}>
              <div className='product-div-wrap classline-items'>
                <h3 className='heading-title textWhite '> RELAX, WE'VE <br /> GOT YOU <span className='title-bold text-with-border'>COVERED</span> </h3>
                <div className='paragraph leftTextAlign'>
                  <p className='textWhite' style={{ paddingTop:"30px" }}>
                    Our warranties are close to our hearts because we know that a product’s warranty is a nod to its integrity. We designed our
                    warranties to keep our customer’s mind at ease, but also to show that we stand by our product.
                  </p>
                </div>
                <div style={{ marginLeft: "15px" }}>
                <NavLink to="/our-warranty">
                    <button className='btnLearnMore textWhite classlinebtn'>Learn More</button>
                  </NavLink>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="elementor-shape elementor-shape-bottom" data-shape="triangle" data-negative="true">
          <svg viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path className="elementor-shape-fill" fill="#ffffff" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
          </svg>
        </div>
      </section>
      <section className='classic-section-fourth' >
        <div className='elementor' style={{ padding: "50px 0px" }}>
          <h3 className='heading-title'> <span className='title-bold'>CLASSIC </span> LINE</h3>
          <div className='divider' style={{ width: '5%' }}></div>
        </div>
      </section>
      <section className='classic-section-fifth my-container' id="harland1">
        <ClassicCard postId={postId} toggleRoute={toggleRoute} cardData={{ classicCardDetails }} />
      </section>
      <section className='classic-section-sixth'>
      </section>
      <section id="classicmembraneinfo">
        <Container maxWidth="lg" sx={{ padding: "50px 0px"}} >
          <div className='elementor' style={{ padding: "" }}>
            <h3 className='heading-title'>MEMBRANE <span className='title-bold'>INFO </span></h3>
            <div className='divider' style={{ width: '5%' }}></div>
          </div>
        

          <Grid container spacing={2} sx={{ paddingBottom: "25px" }}>
            <Grid item md={3} lg={3} xs={12} sm={12} className="membrane-info-title">
              <span className='classic-section-sixth-text'>
                <span className='title-bold'>FEATURES </span>
              </span>
            </Grid>
            <Grid itemmd={4} lg={4} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>Spread coat construction eliminates delamination</li>
                </ul>
                <ul>
                  <li>Non-directional Fiberglass Core</li>
                </ul>
                <ul>
                  <li>High Traction – Slip Resistance</li>
                </ul>
                <ul>
                  <li>Excellent vinyl to vinyl weldability</li>
                </ul>
              </p>
            </Grid>
            <Grid item md={5} lg={5} xs={12} sm={12}>
            <p className='classic-section-sixth-text'>
                <ul>
                  <li>Widest membrane on the market at 76”</li>
                </ul>
                <ul>
                  <li>Complemented by a full line of color coordinated system accessories</li>
                </ul>
                <ul>
                  <li>Low maintenance</li>
                </ul>
              </p>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ paddingBottom: "25px" }}>
            <Grid item md={3} lg={3} xs={12} sm={12} className="membrane-info-title">
              <span className='classic-section-sixth-text'>
                <span className='title-bold'>THICKNESS </span>
              </span>
            </Grid>
            <Grid itemmd={4} lg={4} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>60 mil thickness (All Classic Line Membranes)</li>
                </ul>
                <ul>
                  <li>80 mil thickness (Only Bronze, Brick, Granite)</li>
                </ul>

              </p>
            </Grid>
            <Grid item md={5} lg={5} xs={12} sm={12}></Grid>
          </Grid>

          <Grid container spacing={2} sx={{ paddingBottom: "25px" }}>
            <Grid item md={3} lg={3} xs={12} sm={12} className="membrane-info-title">
              <span className='classic-section-sixth-text'>
                <span className='title-bold'>PACKAGING </span>
              </span>
            </Grid>
            <Grid itemmd={4} lg={4} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>Cut to Size</li>
                </ul>
                <ul>
                  <li>Master Rolls at 76″ x 600′</li>
                </ul>

              </p>
            </Grid>
            <Grid item md={5} lg={5} xs={12} sm={12}></Grid>
          </Grid>

          <Grid container spacing={2} sx={{ paddingBottom: "25px" }}>
            <Grid item md={3} lg={3} xs={12} sm={12} className="membrane-info-title">
              <span className='classic-section-sixth-text'>
                <span className='title-bold'>WARRANTY </span>
              </span>
            </Grid>
            <Grid itemmd={4} lg={4} xs={12} sm={12}>
              <p className='classic-section-sixth-text'>
                <ul>
                  <li>10 year limited warranty on 60 mil membrane</li>
                </ul>
                <ul>
                  <li>20 year limited warranty on 80 mil membrane</li>
                </ul>
                <ul>
                  <span className='cardLink'>  <NavLink to='/our-warranty' className='cardLink-anchor' ><b>(more info)</b> </NavLink></span>
                </ul>
              </p>
            </Grid>
            <Grid item md={5} lg={5} xs={12} sm={12}></Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item md={4} lg={4} xs={12} sm={4} sx={{ textAlign: "center", paddingBottom: "10px" }}>
              <a href="https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Classic_Product_Data_Sheet.pdf" rel="noreferrer" target={'_blank'}> <button className='btnLearnMore'>PRODUCT DATA SHEET</button></a>
            </Grid>
            <Grid item md={4} lg={4} xs={12} sm={4} sx={{ textAlign: "center", paddingBottom: "10px" }}>
              <a href="https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Tec-Membrane-SDS.pdf" rel="noreferrer" target={'_blank'}><button className='btnLearnMore'>SAFETY DATA SHEET</button></a>
            </Grid>
            <Grid item md={4} lg={4} xs={12} sm={4} sx={{ textAlign: "center" }}>
              <a href="https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/AM001-Dec-Tec-Care-Maintenance-Guide-v.02.pdf" rel="noreferrer" target={'_blank'}><button className='btnLearnMore'>MEMBRANE MAINTENANCE GUIDE</button></a>
            </Grid>
          </Grid>
        </Container>
      </section>

    </div >
  )
}

export default ClassicLine;