import React from 'react'
import SliderGallery from '../components/SliderGallery'



const HarlandGallery = () => {

  const galleryImages = [

    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/DT-CL-Granite-1.jpg"
    },
   
   
  ]

  return (
    <div>
      <SliderGallery
        title="Harland"
        para1="Modern style and functional performance. Invite Harland to your deck today. Combining both a cool gray color and slatted wood design, you’ll impress yourself and your guests."
        galleryImages={galleryImages}
        previouslink="/products/membranes/classic-line"
        nextlink="/rowan-gallery/"
      />
    </div>
  )
}

export default HarlandGallery
