import React from 'react'
import {  Button, Modal, Typography } from '@mui/material';

const SingleBuilderModal = (props) => {
    const onhHandleClose = () => {
        props.setOpenModal(false)
    }
    return (
        <div>
            {props.builderValue === true ? (
                <Modal
                    open={props.openModal}
                    onClose={onhHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className='resposiveBox'>
                        <div style={{ marginBottom: '2rem' }}>
                            <Typography id="modal-modal-title" variant="h6" fontWeight={"bold"} fontSize={'1em'}>
                                {props.builderName}
                            </Typography>
                            <Typography fontStyle={'italic'} >
                                {props.builderType}
                            </Typography>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                            <Typography id="modal-modal-title" variant="h6" fontSize={'1em'}>
                                <b>Phone: </b> {props.phoneNo}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: 7 }}>
                                <b>Email: </b> {props.email}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', backgroundColor: 'gray', justifyContent: 'center' }}>
                            <Button onClick={onhHandleClose} sx={{ color: 'white' }}>close</Button>
                        </div>
                    </div>
                </Modal>
            ) : (
                <Modal
                    open={props.openModal}
                    onClose={onhHandleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className='resposiveBox'>
                        <div style={{ marginBottom: '2rem' }}>
                            <Typography id="modal-modal-title" variant="h6" fontWeight={"bold"} fontSize={'1em'}>
                                {props.firstBuilderName}
                            </Typography>
                            <Typography fontStyle={'italic'}  >
                                {props.builderType}
                            </Typography>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                            <Typography id="modal-modal-title" variant="h6" fontSize={'1em'}>
                                <b>Phone: </b> {props.firstBuilderPhn}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: 7 }}>
                                <b>Email: </b> {props.firstBuilderEmail}
                            </Typography>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                            <Typography id="modal-modal-title" variant="h6" fontWeight={"bold"} fontSize={'1em'}>
                                {props.secondBuilderName}
                            </Typography>
                            <Typography fontStyle={'italic'}  >
                                {props.builderType}
                            </Typography>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                            <Typography id="modal-modal-title" variant="h6" fontSize={'1em'}>
                                <b>Phone: </b> {props.secondBuilderPhn}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: 7 }}>
                                <b>Email: </b> {props.secondBuilderEmail}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', backgroundColor: 'gray', justifyContent: 'center' }}>
                            <Button onClick={onhHandleClose} sx={{ color: 'white' }}>close</Button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default SingleBuilderModal