import React from 'react'
import "../css/homePage.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faTrophy, faUnlock } from '@fortawesome/fontawesome-free-solid'
import NewProduct from '../components/NewProduct'
import { Container } from '@mui/system'
import { Grid } from '@mui/material'
import { NavLink} from 'react-router-dom'

const Home = () => {
    return (
        <div>
            <section className='home-Section-First'>
                <div className='section-first-margin'>
                    <div className='section-first-name-first'>
                        <h1 className='text-with-border' style={{borderWidth:"25px"}}><span className='thin-content'>re</span><b>defining</b></h1>
                    </div>
                   
                    <div className='section-first-name-second'>
                        <h3 className='bannerSubtitle'>your outdoor surfaces.</h3>
                    </div>
                    <div className='element-row-second'>
                        <div className='section-first-text'>
                            <h2 className='textShadow'><b><i>Have Questions About Your Warranty?</i></b></h2>
                        </div>
                        <NavLink to="/our-warranty">
                        <button className='first-btn'>Learn More</button>
                        </NavLink>
                    </div>
                </div>
            </section>
            <section>
                <NewProduct />
            </section>
            <section className='home-Section-Third '>
                <Container maxWidth="lg" sx={{ padding: "2% 0%" }}>
                    <Grid item xs={12} md={12} sm={12} lg={12} >
                        <div className='product-div-wrap centeralignment' style={{ alignItems: 'flex-start' }} >
                            <h3 className='heading-title textWhite homeheading' >
                                WE ARE<br /><span className='underline'><b>EXPERTS</b></span></h3>
                            <div className='paragraph  textWhite homeparagraph'>
                                <p>
                                    From customer service to warranties, we are a team of experts, dedicated to providing our customers with everything they need. Technical documentation, certificates and test credentials are all available.
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12} >
                        <div className='product-div-wrap centeralignment' style={{ alignItems: 'flex-end' }} >
                            <h3 className='heading-title textWhite rightTextAlign homeheading'>
                                35 YEARS OF PROVEN<br /><span className='underline'><b>PERFORMANCE</b></span></h3>
                            <div className='paragraph rightTextAlign textWhite homeparagraph ' >
                                <p>
                                    Our dedication to technical excellence and premium design is what makes Dec-Tec an unprecedented product in the vinyl decking market.
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Container>
            </section>
            <section className='home-Section-Four'>
                <div className='section-four-main-div'>
                    <Container maxWidth="lg" sx={{ padding: "70px 0px" }}>
                        <Grid container columns={{ xs: 4, sm: 12, md: 12 }}>
                            <Grid lg={4} sm={4} md={4} xs={12}>
                                <div className='Section-four-element-first'>
                                    <div className='content-wrapper'>
                                        <div className='content-wrapper-icon'>
                                            <FontAwesomeIcon icon={faUnlock} />
                                        </div>
                                        <div>
                                            <h3 className='content-wrapper-heading'>WARRANTY</h3>
                                        </div>
                                        <div className='content-wrapper-parahome'>
                                            <p>
                                                10 and 20 year products warranties are available for our membranes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid lg={4} sm={4} md={4} xs={12}>
                                <div className='Section-four-element-first'>
                                    <div className='content-wrapper'>
                                        <div className='content-wrapper-icon'>
                                            <FontAwesomeIcon icon={faTrophy} />
                                        </div>
                                        <div>
                                            <h3 className='content-wrapper-heading'>STYLE</h3>
                                        </div>
                                        <div className='content-wrapper-parahome'>
                                            <p>
                                                Our artisan patterns and designs are beautifully crafted by professionals
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid lg={4} sm={4} md={4} xs={12}>
                                <div className='Section-four-element-first'>
                                    <div className='content-wrapper'>
                                        <div className='content-wrapper-icon'>
                                            <FontAwesomeIcon icon={faHome} />
                                        </div>
                                        <div >
                                            <h3 className='content-wrapper-heading'>COMFORT</h3>
                                        </div>
                                        <div className='content-wrapper-parahome'>
                                            <p>
                                                Whether you’re battling a hot, splintered or eroded deck, we’ve got the product for you
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </section>
        </div>
    )
}

export default Home