import React from 'react'
import SliderGallery from '../components/SliderGallery'



const BrickGallery = () => {

  const galleryImages = [
    // {
    //   img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_193966827_BRICK-min.jpg"
    // },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-12-rotated.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-11.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-9.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-10.jpeg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-6.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-5.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-4.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-3-rotated.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-2.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/Brick-8.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/shutterstock_1067482226_brick-edt-3-1.jpg"
    },
    {
      img: "https://dectecstorageprod.blob.core.windows.net/website/images/products/gallery/AdobeStock_46360603_BRICK-min-e1542137162671.jpg"
    },
  ]
  return (
    <div>
      <SliderGallery
        title="Brick"
        para1="A staple in our Classic Line, Brick is a popular, neutral design that we can’t keep on the shelves. It is a tough, clean, and timeless waterproof decking product."
        galleryImages={galleryImages}
        previouslink="/bronze-gallery/"
        nextlink="/granite-gallery/"
      />
    </div>
  )
}

export default BrickGallery
