import React, { useState } from 'react'
import "../css/accesories.css"
import { faTrophy, faUsers, faWrench } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import AccesoriesCard from '../components/AccesoriesCard'
import MultiImageSliders from '../components/MultiImageSliders'
import TitleCard from '../components/TitleCard'


const Accesories = (props) => {

  const [postId,setPostId] = useState(0);


  function toggleRoute(type) {
    if (type === "Flash") {
      window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Flash-Product-Catalog-Page-re-work.pdf")
    }
    else if (type === "Clad Drip Edge") {
      window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Clad-Drip-Edge.pdf")
    }

    else if (type === "Clad Kickout") {
      window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Clad-Kickout.pdf")
    }

    else if (type === "Clad Sheet Metal") {
      window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Clad-Sheet-Metal.pdf")
    }

    else if (type === "Drain NDR 4-1") {
      window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Drain-NDR-4-1.pdf")
    }

    else if (type === "Scupper") {
      window.open("https://dectecstorageprod.blob.core.windows.net/website/images/products/productpdf/Dec-Scupper.pdf")
    }
  }


  const allCardDetails = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-Slate-3.jpg",
      firstText: 'our',
      boldText: 'ACCESSORIES',
      description: '',
      isImageOnlyFlag: false,
      textShow: false,
      textDirect: true,
      textAlign: false,
      cartHeight: "100vh",
      bgAttachment: 'initial',
      bgPosition: 'bottom center',
    },
  ]
  const multiImages = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/IMG_0194.jpg",
      titleSlider: 'Dec-Flash',
      link2:`/products/accesories`,
    },
    {
      id: 2,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecCladDrip-e1547059977457.jpg",
      titleSlider: 'Dec-Clad Drip Edge',
      link2:`/products/accesories`,
    },
    {
      id: 3,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecKickout.jpg",
      titleSlider: 'Dec-Clad Kickout',
      link2:`/products/accesories`,
    },
    {
      id: 4,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_Sheet-metal.jpg",
      titleSlider: 'Dec-Clad Sheet Metal',
      link2:`/products/accesories`,
    },
    {
      id: 5,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecDrain.jpg",
      titleSlider: 'Dec-Drain NDR 4-1',
      link2:`/products/accesories`,
    },
    {
      id: 6,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_Scupper.jpg",
      titleSlider: 'Dec-Scupper',
      link2:`/products/accesories`,
    },
    {
      id: 7,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecToolsKit.jpg",
      titleSlider: 'Dec-Tools-Kit',
      link2:`/products/accesories`,
    },
    {
      id: 8,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_HeatWelder.jpg",
      titleSlider: 'HeatWelder',
      link2:`/products/accesories`,
    },
    {
      id: 9,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_Trowel.jpg",
      titleSlider: 'Trowel',
      link2:`/products/accesories`,
    },
  ]
  const classicCardDetails = [
    {
      id: 1,
      position:"one",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/IMG_0194.jpg",
      isImageOnlyFlag: true,
      title: 'Dec -',
      titleSec: 'Flash',
      textShow: 'left',
      textbtn: 'PRODUCT DATASHEET',
      description: 'Our pre-fabricated membrane flashings simplify installation for any contractor. A controlled setting during in house production ensures each Dec-Flash is produced with the same dimensions, high quality, and fibreglass reinforcement which translates to high production in the field, and a stunning, uniform look to the finished details.',
      dec:true,
    },
    {
      id: 2,
      position:"four",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecCladDrip-e1547059977457.jpg",
      isImageOnlyFlag: false,
      title: 'Dec -',
      titleSec: 'Clad Drip Edge',
      textShow: 'right',
      textbtn: 'PRODUCT DATASHEET',
      description: 'Dec-Clad Drip Edge is an easy and efficient way to terminate membrane details and eliminates the need for adhesives and clips. With multiple, membrane pairing colours our Dec-Clad Drip Edge’s PVC coating provides one of the strongest and longest lasting waterproof details and a great aesthetic finish.',
      dec:true,
    },
    {
      id: 3,
      position:"two",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecKickout.jpg",
      isImageOnlyFlag: true,
      title: 'Dec -',
      titleSec: 'Clad Kickout',
      textShow: 'left',
      textbtn: 'PRODUCT DATASHEET',
      description: 'The Dec-Clad Kickout introduces one of the easiest and most efficient ways to terminate Dec-Tec membrane to vertical surfaces. With a weld-able PVC coating, standard dimensions, and multiple colour options, our Kickout is able to pair with any one of our beautiful Dec-Tec membranes.',
      dec:true,
    },
    {
      id: 4,
      position:"three",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_Sheet-metal-o33iajmdlt0kz7igc0q3zfwfc6zg3a6umg4weeyth4.jpg",
      isImageOnlyFlag: false,
      title: 'Dec -',
      titleSec: 'Clad Sheet Metal',
      textShow: 'right',
      textbtn: 'PRODUCT DATASHEET',
      description: 'Dec-Clad PVC coated sheet metal allows you to take care of your own metal work and create custom profiles while maintaining the great vinyl to vinyl weldability and colour matching our many Dec-Tec membranes.',
      dec:true,
    },
    {
      id: 5,
      position:"five",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_DecDrain.jpg",
      isImageOnlyFlag: true,
      title: 'Dec -',
      titleSec: 'Drain NDR 4-1',
      textShow: 'left',
      textbtn: 'PRODUCT DATASHEET',
      description: 'The NDR 4-1 Dec-Drain is the ultimate in field flexibility. The multi-stepped design allows for attachment of various sizes of outlet pipes, while still fitting inside confined joist spaces. Dec-Tec membranes can be easily welded to the PVC coated flange, ensuring a long-lasting waterproof detail and great aesthetic finish.',
      dec:true,
    },
    {
      id: 6,
      position:"six",
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/Accessories_Scupper.jpg",
      isImageOnlyFlag: false,
      title: 'Dec -',
      titleSec: 'Scupper',
      textShow: 'right',
      textbtn: 'PRODUCT DATASHEET',
      description: 'The Dec-Scupper is the ideal through wall drain to use with Dec-Tec membranes. Dec-Scupper’s have a PVC coated flange to allow for excellent vinyl to vinyl weldability and improve the ease of use when working on critical leak points. The one-piece construction and pliability of the flange provide increased on-site workability and easier installation.',
      dec:true,
    },
  ]

  return (
    <div>
      <Grid xs={12} >
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>


      <Container maxWidth="lg" sx={{ padding: "5% 0%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6} lg={6} sm={12} sx={{ alignItems: 'center', display: "flex" }}>
            <div className='product-div-wrap content-padding-right'>
              <h3 className='heading-title'>OUR <span className='title-bold'>ACCESSORIES </span></h3>
              <div className='divider accessories-card-margin'></div>
              <div className='paragraph right-div'>
                <p >
                  Dec-Tec is more than membrane: it’s a full system. We provide all of the necessary components to create a beautiful, waterproof deck. From scuppers to Dec-Flash, we make the solutions easy for our contractors because we know their time and peace of mind is valuable
                </p>
                <p>
                  For our homeowners, it means their decks are finished faster with standard, professional details so that they can get back to relaxing as soon as possible.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <div >
              <MultiImageSliders array={multiImages} noOfImages={1} />
            </div>
          </Grid>
        </Grid>
      </Container>

      


      <section className='accesories-section-third'>
        <Container maxWidth="lg" sx={{ padding: "150px 0px" }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid lg={4} sm={12} xs={12} md={4}>
              <div className='Section-four-element-first'>
                <div className='content-wrapper'>
                  <div className='content-wrapper-icon'>
                    <FontAwesomeIcon icon={faWrench} />
                  </div>
                  <div className='content-wrapper-heading'>
                    <h3>FASTER INSTALLATION</h3>
                  </div>
                  <div className='content-wrapper-para'>
                    <p>
                      Pre-formed accessories means less time on job sites
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid lg={4} sm={12} xs={12} md={4}>
              <div className='Section-four-element-first'>
                <div className='content-wrapper'>
                  <div className='content-wrapper-icon'>
                    <FontAwesomeIcon icon={faUsers} />
                  </div>
                  <div className='content-wrapper-heading'>
                    <h3>EASE OF USE</h3>
                  </div>
                  <div className='content-wrapper-para'>
                    <p>
                      Our line of accessories are designed to work flawlessly with Dec-Tec membranes
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid lg={4} sm={12} xs={12} md={4}>
              <div className='Section-four-element-first'>
                <div className='content-wrapper'>
                  <div className='content-wrapper-icon'>
                    <FontAwesomeIcon icon={faTrophy} />
                  </div>
                  <div className='content-wrapper-heading'>
                    <h3>PROFESSIONAL DETAILS</h3>
                  </div>
                  <div className='content-wrapper-para'>
                    <p>
                      Our accessories provide consistent, high standard details for a beautiful installation
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className="elementor-shape elementor-shape-bottom" data-shape="triangle" data-negative="true">
          <svg viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path className="elementor-shape-fill" fill="#ffffff" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
          </svg>
        </div>
      </section>
      

      <section className='classic-section-fourth'>
        <div className='elementor' style={{ padding: "30px 0px" }}>
          <h3 className='heading-title'> <span className='title-bold'>VIEW ALL </span> ACCESSORIES</h3>
          <div className='divider' style={{ width: '5%' }}></div>
        </div>
      </section>
      <section className='accesories-section-fifth'>
        <Container maxWidth="lg" className="my-container">
          <AccesoriesCard postId={postId} toggleRoute={toggleRoute} cardData={classicCardDetails} />
        </Container>
      </section>
    </div >
  )
}

export default Accesories