import React, { useState } from 'react';
import { MapContainer, TileLayer, Polygon, Tooltip, } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Grid } from '@mui/material';
import { canadaStatesData } from '../mapData/canadaStatesData';
import SingleBuilderModal from './SingleBuilderModal';
const CanadaMap = () => {
    const [openCanadaModal, setOpenCanadaModal] = useState(false);
    const [newData, setnewData] = useState({
        cityName: '',
        builderName: '',
        builderType: '',
        phoneNo: '',
        email: '',
        builderValue: '',
    })
    function getColor(d) {
        return d === "Matthew Lefebvre " ? '#3799ba' :
            d === "Louis Simone " ? '#29779f' :
                'red';
    }
    const canadaMapCenter = [53.130367, -95.346771];
    return (
        <div>
            <Grid container spacing={5} columnSpacing={{ xs: 5, sm: 5, md: 1 }}>
                <Grid item xs={12} md={12} lg={12} sx={{ alignItems: 'center', display: "flex" }} >
                    <MapContainer
                        center={canadaMapCenter}
                        zoom={4.1}
                        style={{ width: '100%', height: '75vh',zIndex:9 }}
                        className='tilelayer mapHeight'
                        attributionControl={false}
                        doubleClickZoom={false}
                        closePopupOnClick={false}
                        dragging={false}
                        zoomSnap={false}
                        zoomDelta={false}
                        trackResize={false}
                        touchZoom={false}
                        scrollWheelZoom={false}
                        zoomControl={false}

                    >
                        <TileLayer
                            opacity={0}
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

                        />
                        {
                            canadaStatesData.features.map((state) => {
                                const coordinates = state.geometry.coordinates[0].map((item) => [item[1], item[0]]);
                                return (
                                    <>
                                        <div>
                                            <SingleBuilderModal
                                                openModal={openCanadaModal}
                                                setOpenModal={setOpenCanadaModal}
                                                builderName={newData.builderName}
                                                builderType={newData.builderType}
                                                builderValue={newData.builderValue}
                                                phoneNo={newData.phoneNo}
                                                email={newData.email}
                                            />
                                            <Polygon
                                                pathOptions={{
                                                    fillColor: getColor(state.properties.builderName),
                                                    fillOpacity: 0.95,
                                                    weight: 1,
                                                    opacity: 1,
                                                    dashArray: 0,
                                                    stroke: getColor(state.properties.builderName),
                                                    color: getColor(state.properties.builderName),

                                                }}
                                                positions={coordinates}
                                                eventHandlers={{
                                                    mouseover: (e) => {
                                                        const layer = e.target;
                                                        layer.setStyle({
                                                            dashArray: 0,
                                                            fillColor: 'white',
                                                            fillOpacity: 0.95,
                                                            stroke: getColor(state.properties.builderName),
                                                            weight: 1,
                                                            opacity: 0.9,
                                                        })

                                                    },
                                                    mouseout: (e) => {
                                                        const layer = e.target;
                                                        layer.setStyle({
                                                            fillOpacity: 0.95,
                                                            opacity: 0.9,
                                                            weight: 1,
                                                            dashArray: 0,
                                                            fillColor: getColor(state.properties.builderName),
                                                            stroke: getColor(state.properties.builderName),
                                                        });
                                                    },
                                                    click: () => {
                                                        setOpenCanadaModal(true)
                                                        setnewData({
                                                            ...newData,
                                                            cityName: state.properties.cityName,
                                                            builderName: state.properties.builderName,
                                                            builderType: state.properties.builderType,
                                                            builderValue: state.properties.singleBuilder,
                                                            phoneNo: state.properties.phoneNo,
                                                            email: state.properties.email,
                                                        })
                                                    }
                                                }}
                                            >
                                                <Tooltip className='tooltip' sticky ><b> {state.properties.cityName} </b><br /> {state.properties.builderName}</Tooltip>
                                            </Polygon>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </MapContainer>
                </Grid>
            </Grid>
        </div>
    )
}

export default CanadaMap