import React from 'react'
import "../css/membranes.css"
import { Grid } from '@mui/material'
import { Container } from '@mui/system'
import MediaCard from '../components/MembraneCard'
import TitleCard from '../components/TitleCard'
import { HashLink } from 'react-router-hash-link';
import { NavLink} from 'react-router-dom'


const Membranes = () => {

    
    const allCardDetails = [
        {
            id: 1,
            imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/products/DT-CL-Granite-1.jpg",
            firstText: 'our',
            boldText: 'membranes',
            description: '',
            isImageOnlyFlag: false,
            textShow: false,
            textDirect: true,
            textAlign: false,
            cartHeight: "100vh",
            bgAttachment: 'initial',
            bgPosition: 'bottom center',
        },
    ]
    return (
        <div>
            <Grid item xs={12}>
                <TitleCard cardData={{ allCardDetails }} />
            </Grid>
            <Container maxWidth="lg" >
                <div className='container'>
                    <Grid container spacing={5} columnSpacing={{ xs: 5, sm: 5, md: 3 }}>
                        <Grid item xs={12} md={5} lg={5} sx={{ alignItems: 'center', display: "flex" }} >
                            <div className='elementor-wrap-div '>
                                <div className='elementor-row elementor-m-row '>
                                    <h3 className='heading-title'> OUR </h3>
                                    <h3 className='heading-title'> <span className='title-bold'>MEMBRANES</span></h3>
                                </div>
                                <div className='divider'></div>
                                <div className='paragraph ' style={{ width: '90%' }} >
                                    <p >
                                        For years Dec-Tec has delivered proven waterproofing and roofing
                                        solutions for decks, balconies, walkways and many other types of exterior surface applications.
                                    </p>
                                    <p >
                                        As North America’s leading innovator in pedestrian traffic bearing PVC membranes,
                                        along with industry-leading system accessories and components, water intrusion problems on deck areas are a thing of the past.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <div className='image-wrapper-first'>
                            <NavLink to="/products/membranes/classic-line" alt="classline">
                                    <img style={{ width: '50%', paddingLeft: '5%' }}
                                        srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/products/Harland-Tile-square-phblw207ebyil0fjojuqs8f8isdoea2fap93goehdk.jpg"
                                        src="https://dectecstorageprod.blob.core.windows.net/website/images/products/Harland-Tile-square-phblw207ebyil0fjojuqs8f8isdoea2fap93goehdk.jpg"
                                        alt='logoher'
                                    />
                                </NavLink>
                                <figcaption className='captionFig'>  Classic Line </figcaption>
                            </div>
                            <div className='image-wrapper-second'>
                            <NavLink to="/products/membranes/coolstep-line" alt="coolstepline">
                                    <img style={{ width: '90%' }}
                                        srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-Slate-Tile-square-phblu3ib5ra0e99y47dq387nyw15czak10bogxb0c8.jpg"
                                        src="https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-Slate-Tile-square-phblu3ib5ra0e99y47dq387nyw15czak10bogxb0c8.jpg"
                                        alt='ashdown'
                                    />
                                </NavLink>
                                <figcaption className='captionFig-second'>  Coolstep Line </figcaption>
                            </div>

                        </Grid>
                    </Grid>
                </div>
            </Container>
            <Grid item xs={12}>
            <section className='membranes-section-third'>
                    <div className='membranes-section-second-title'>
                        <h3 className='membranes-second-heading-title'> <span className='title-bold'>CLASSIC </span> LINE</h3>
                    </div>
                    <div className='membranes-section-second-divider'>
                        <span></span>
                    </div>
                </section>
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "50px 0px" }}>
                <Grid container spacing={5} columnSpacing={{ xs: 5, sm: 5, md: 3 }}>
                    <Grid item md={2} lg={2}></Grid>
                    <Grid item xs={12} md={4} lg={4} sx={{ alignItems: 'center', display: "flex", justifyContent: 'center' }} >
                        <div className='elementor-wrap-div '>
                            <div className='elementor-col elementor-m-col '>
                                <h3 className='heading-title' style={{ fontSize: '2em', lineHeight: '1.5' }}> <span className='title-bold'>TRADITIONAL &</span></h3>
                                <h3 className='heading-title' style={{ fontSize: '2em', lineHeight: '1.5' }}> FUNCTIONAL STYLE </h3>
                                <h3 className='heading-title-new'>(NEW!)</h3>
                            </div>
                            <div className='divider'></div>
                            <div className='elementor-button'>
                                <HashLink to="/products/membranes/classic-line/#harland1">
                                    <button className='btnSecond'>MORE INFO</button>
                                </HashLink>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{ alignItems: 'center', display: "flex" }} >
                        <div className='elementor-wrap-div left-div'>
                            <div className='paragraph ' style={{ width: '90%' }} >
                                <p >
                                    We’ve added four new membrane colors to our most popular Classic Line that are sure to impress. Harland, Rowan, Grafton and Ashdown boast unique wood designs on a PVC walkable surface and offer the same benefits you’ve come to love from our Classic Line.
                                </p>
                                <p >
                                    With our proven Classic Line membrane technology, our new membrane colors complement all décor styles and accentuate any outdoor space, whether it’s decks, patios, boathouses, or walkways so you can enjoy style, comfort and protection.
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid className="pl-15 pr-15" container spacing={3} columnSpacing={{ xs: 3, sm: 1, md: 1 }} margin={"50px 0px"}>
                    <Grid item md={2.4} xs={12} sm={2.4} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            sx={{ padding: 0 }}
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-bronze-01.jpg"
                            title='Bronze'
                         />
                    </Grid>
                    <Grid item md={2.4} xs={12} sm={2.4} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-solid-grey-01.jpg"
                            title='Solid Gray' />
                    </Grid>
                    <Grid item md={2.4} xs={12} sm={2.4} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-brick-01.jpg"
                            title='Brick' />
                    </Grid>
                    <Grid item md={2.4} xs={12} sm={2.4} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-beige.jpg"
                            title='Beige' />
                    </Grid>
                    <Grid item md={2.4} xs={12} sm={2.4} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-granite-01.jpg"
                            title='Granite' />
                    </Grid>
                </Grid>
                {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='elementor padding-left' >
                            <div className='membranes-second-text-wrap'>
                                <h3 className='heading-title'> <span className='title-bold'>tradional & </span> </h3>
                                <h3 className='heading-title'>  functional style</h3>
                                <h3 className='heading-title-new'>(NEW!)</h3>
                            </div>
                            <div className='divider'>
                                <span></span>
                            </div>
                            <div style={{ paddingTop: '10px' }}>
                                <button className='btnLearnMore'>MORE INFO</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='product-div-wrap'>
                            <div className='paragraph' style={{ width: '93%' }}>
                                <p >
                                    We’ve added four new membrane colors to our most popular Classic
                                    Line that are sure to impress. Harland, Rowan, Grafton and Ashdown
                                    boast unique wood designs on a PVC walkable surface and offer the
                                    same benefits you’ve come to love from our Classic Line.
                                </p>
                                <p >
                                    With our proven Classic Line membrane technology, our new membrane
                                    colors complement all décor styles and accentuate any outdoor space,
                                    whether it’s decks, patios, boathouses, or walkways so you can enjoy
                                    style, comfort and protection.
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid> */}
                <Grid container spacing={5} columnSpacing={{ xs: 5, sm: 5, md: 3 }}>
                    <Grid item md={2} lg={2}></Grid>
                    <Grid item xs={12} md={4} lg={4} sx={{ alignItems: 'center', display: "flex", justifyContent: 'center' }} >
                        <div className='elementor-wrap-div '>
                            <div className='elementor-col elementor-m-col '>
                                <h3 className='heading-title' style={{ fontSize: '2em', lineHeight: '1.5' }}> <span className='title-bold'>MODERN &</span></h3>
                                <h3 className='heading-title' style={{ fontSize: '2em', lineHeight: '1.5' }}> FUNCTIONAL STYLE </h3>
                                <h3 className='heading-title-new'>(NEW!)</h3>
                            </div>
                            <div className='divider'></div>
                            <div className='elementor-button' style={{ padding: '5% 0%' }}>
                            <HashLink to="/products/membranes/classic-line/#harland1">
                                <button className='btnSecond'>MORE INFO</button>
                                </HashLink>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{ alignItems: 'center', display: "flex" }} >
                        <div className='elementor-wrap-div '>
                            <div className='paragraph ' style={{ width: '90%' }} >
                                <p >
                                    We’ve added four new membrane colors to our most popular Classic Line that are sure to impress. Harland, Rowan, Grafton and Ashdown boast unique wood designs on a PVC walkable surface and offer the same benefits you’ve come to love from our Classic Line.
                                </p>
                                <p >
                                    With our proven Classic Line membrane technology, our new membrane colors complement all décor styles and accentuate any outdoor space, whether it’s decks, patios, boathouses, or walkways so you can enjoy style, comfort and protection.
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid className="pl-15 pr-15" container spacing={3} columnSpacing={{ xs: 3, sm: 1, md: 1 }} margin={"50px 0px"}>
                {/* <Grid container spacing={3} columnSpacing={{ xs: 3, sm: 0, md: 1 }} margin={"50px 0px"}> */}
                    <Grid item md={1} sm={0} className="hideOnMD"></Grid>
                    <Grid item md={2.4} xs={12} sm={3} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/Harland-Tile-square-300x300.jpg"
                            title='Harland' />
                    </Grid>
                    <Grid item md={2.4} xs={12} sm={3} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/Rowan-Tile-square-300x300.jpg"
                            title='Rowan' />
                    </Grid>
                    <Grid item md={2.4} xs={12} sm={3} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/Grafton-Tile-square-300x300.jpg"
                            title='Grafton' />
                    </Grid>
                    <Grid item md={2.4} xs={12} sm={3} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/Ashdown-Tile-square-300x300.jpg"
                            title='Ashdown' />
                    </Grid>
                    <Grid item md={1} sm={0}></Grid>
                </Grid>
            </Container>
            <Grid item xs={12}>
                <section className='membranes-section-third'>
                    <div className='membranes-section-second-title'>
                        <h3 className='membranes-second-heading-title'> <span className='title-bold'>COOLSTEP </span> LINE</h3>
                    </div>
                    <div className='membranes-section-second-divider'>
                        <span></span>
                    </div>
                </section>
            </Grid>
            <Container maxWidth="lg" sx={{ padding: "50px 0px" }}>
                <Grid>
                    <div className='elementor-wrap-div'>
                        <div className='paragraph' style={{ width: '70%' }}>
                            <p >
                                With our CoolStep Line, Dec-Tec offers all the same waterproofing capabilities in the form of white, light pattern membranes
                                with increased solar reflectance making it the perfect option for warmer, sunnier climates. CoolStep is only available as an
                                80mil product, automatically providing you with our 20-year limited membrane warranty and comes in two neutral toned patterns
                                – a great option for both cool and warm color palettes.
                            </p>
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                        <HashLink to="/products/membranes/coolstep-line/#coolstepline">
                            <button className='btnLearnMore'>MORE INFO</button>
                            </HashLink>
                        </div>
                    </div>
                </Grid>
                <Grid className="pl-15 pr-15" container spacing={3} columnSpacing={{ xs: 3, sm: 1, md: 1 }} margin={"50px 0px"}>
                    <Grid item md={1.2} lg={1.2} sm={0} className="hideOnMD"></Grid>
                    <Grid item md={3} xs={12} sm={3} lg={2.4} className="grid-padding-tab">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-coolstep-01-300x300.jpg"
                            title='CoolStep' />
                    </Grid>
                    <Grid item md={3} xs={12} sm={3} lg={2.4} className="grid-padding-tab">
                    <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/NEW-EMBOSS-coolstep-copper-01-300x300.jpg"
                            title='CoolStep Copper' />    
                    </Grid>
                    <Grid item md={3} xs={12} sm={3} lg={2.4} className="grid-padding-tab grid-padding">
                        <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-Sandalwood-Tile-square-300x300.jpg"
                            title='CoolStep Sandalwood' />
                    </Grid>
                    <Grid item md={3} xs={12} sm={3} lg={2.4} className="grid-padding-tab">
                    <MediaCard
                            itemWidth={"100%"}
                            itemHeight={200}
                            img="https://dectecstorageprod.blob.core.windows.net/website/images/products/CoolStep-Slate-Tile-square-300x300.jpg"
                            title='CoolStep Slate' />
                    </Grid>
                    <Grid item md={1.2} sm={0}></Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Membranes