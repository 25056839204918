import React from 'react'
import "../css/products.css"
import { NavLink} from 'react-router-dom'
import { Container, Grid } from '@mui/material'
import NewProduct from '../components/NewProduct'


const Products = () => {
    return (
        <div>
            <section className='products-section-first'>
                <div className='products-wrap-logo'>
                    <NavLink to="/products/membranes/">
                    <img className='product-dectec-logo' srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/logo/dectec-logo-bigger.png" src="https://dectecstorageprod.blob.core.windows.net/website/images/logo/dectec-logo-bigger.png" alt='logo' />
                    </NavLink>
                 
                    <span className='product-text'>premium quality vinyl decking</span>
                </div>
            </section>
            <section className='products-section-second'>
                <NewProduct />
            </section>
            <section className='products-section-third'>
                <Container maxWidth="lg" >
                    <div style={{ paddingTop: '10%', paddingBottom: '20%' }} >
                        <Grid item xs={12} md={12} sm={12} lg={12} sx={{ justifyContent: 'center', display: "flex" }}>
                            <div className='elementor-wrap-div' style={{ textAlign: "center" }}>
                                <h3 className='heading-title textWhite'>OUR <span className='title-bold'><span className='text-with-border'>PRO</span>DUCTS</span></h3>
                                <div className='products-paragraph' style={{ width: '92%', paddingTop:"50px" }}>
                                    <p>Low maintenance and long warranties, paired with beautiful and versatile designs make Dec-Tec the best solution for your outdoor living space.</p>
                                    <p>Dec-Tec has been committed to providing products of quality, durability and unparalleled beauty. With brilliant traction ratings, reinforcement and prints, Dec-Tec will keep your deck looking great for years to come.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 0 }} >
                            <Grid item xs={12} md={4} sm={12} lg={4} sx={{ paddingLeft: 0 }}>
                                <div className='products-image-first'>
                                <NavLink to="/products/membranes">
                                        <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/products/membranes.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/products/membranes.jpg" alt='logo' className='products-image' style={{objectPosition:"left"}} width={"100%"} />

                                        <div className="products-image-overlay">
                                            <div className="products-image-middle">
                                                <div className="products-image-text">
                                                    <div className='products-image-text-wrap'>
                                                        <h3 className='products-image-heading-title'><b>Membranes</b></h3>
                                                        <p className='products-image-heading-para'>
                                                            Dec-Tec provides a variety of beautiful and versatile designs to choose from to make the perfect
                                                            addition to your desk. With its low maintenance and long warranties backing up the quality of
                                                            each membrane, Dec-Tec is the best solution for any outdoor living space!						</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className='products-image-bottom-title'>
                                    <h3>Membranes</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} sm={12} lg={4} sx={{ paddingLeft: 0 }}>
                                <div className='products-image-first'>
                                <NavLink to="/products/accesories">
                                        <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/products/AdobeStock_119843923_COOLSTEP-min.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/products/AdobeStock_119843923_COOLSTEP-min.jpg" alt='logo' className='products-image' />

                                        <div className="products-image-overlay">
                                            <div className="products-image-middle">
                                                <div className="products-image-text">
                                                    <div className='products-image-text-wrap'>
                                                        <h3 className='products-image-heading-title'><b>accessories</b></h3>
                                                        <p className='products-image-heading-para'>

                                                            Dec-Tec is more than our membranes: we provide a full system. We provide all the necessary components to create a beautiful, waterproof deck. From scuppers to Dec-Flash, we make each installation seamless for our contractors because we know everyone's time is valuable.												</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className='products-image-bottom-title'>
                                    <h3>accessories</h3>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={4} sm={12} lg={4} sx={{ paddingLeft: 0 }}>
                                <div className='products-image-first'>
                                <NavLink to="/products/adhesives">
                                        <img srcSet="https://dectecstorageprod.blob.core.windows.net/website/images/products/installproduct.jpg" src="https://dectecstorageprod.blob.core.windows.net/website/images/products/installproduct.jpg" alt='logo' className='products-image' />
                                        <div className="products-image-overlay">
                                            <div className="products-image-middle">
                                                <div className="products-image-text">
                                                    <div className='products-image-text-wrap'>
                                                        <h3 className='products-image-heading-title'><b>installation </b></h3>
                                                        <h3 className='products-image-heading-title'><b>products</b></h3>
                                                        <p className='products-image-heading-para'>

                                                            Dec-Tec is more than membrane: it’s a full system. We provide all of the necessary components to create a beautiful, waterproof deck. We offer a variety of adhesive options comprised of the highest-quality raw ingredients because we understand the stringent requirements in construction. We make the solutions easy for our contractors because we know their time and peace of mind is valuable.												</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className='products-image-bottom-title'>
                                    <h3>INSTALLATION PRODUCTS</h3>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </section>

        </div>
    )
}

export default Products