import React, { useState } from 'react';
import { MapContainer, TileLayer, Polygon, Tooltip, } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Grid } from '@mui/material';
import { usaStatesData } from '../mapData/usaStatesData';
import { alaskaStatesData } from '../mapData/alaskaStatesData';
import { hawaiiStatesData } from '../mapData/hawaiiStatesData';
import SingleBuilderModal from './SingleBuilderModal';

const UASMap = () => {
    const [openUsaModal, setOpenUsaModal] = useState(false);
    const [openAlaskaModal, setOpenAlaskaModal] = useState(false);
    const [openHawaiiModal, setOpenHawaiiModal] = useState(false);
    const [newData, setnewData] = useState({
        cityName: '',
        builderName: '',
        builderType: '',
        phoneNo: '',
        email: '',
        builderValue: '',
        firstBuilderName: '',
        secondBuilderName: '',
        firstBuilderPhoneNo: '',
        secondBuilderPhoneNo: '',
        fristBuilderEmail: '',
        secondBuilderEmail: '',
    })
    function getColor(d) {
        return d === "John Chembers" ? '#21637d' :
            d === "Dan Govan" ? '#69c7e0' :
                d === "Darrin Curtis" ? '#3da7d4' :
                    d === "David Schuldt" ? '#28738d' :
                        d === "Tammy Minnis" ? '#3ca5d1' :
                        d === "Tom Counts" ? '#3ca5d1' :
                            d === "Ken Norman" ? '#75b5c4' :
                                d === "Connie Weiss" ? '#44b8f7' :
                                    d === "Al Devasto" ? '#1c5689' :
                                        d === "Jamie Intili" ? '#2977a0' :
                                            d === "Mark Barnoski" ? '#27383d' :
                                                d === "Paul Tilloston & Ken Norman" ? '#2976a6' :
                                                    d === "Ken Norman & Mark Barnoski" ? '#75b5c4' :
                                                        'red';
    }

    const usaMapCenter = [38.63463151377654, -95.89969605983609];
    const alaskaMapCenter = [63.63463151377654, -152.89969605983609];
    const hawaiiMapCenter = [20.63463151377654, -154.89969605983609];
    return (
        <>
            <Grid container spacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
                <Grid item xs={12} md={12} lg={12}>
                    <MapContainer
                        center={usaMapCenter}
                        zoom={4.7}
                        style={{ width: '100%', height: '100vh', zIndex: 9 }}
                        className='tilelayer mapHeight'
                        attributionControl={false}
                        doubleClickZoom={false}
                        closePopupOnClick={false}
                        dragging={false}
                        zoomSnap={false}
                        zoomDelta={false}
                        trackResize={false}
                        touchZoom={false}
                        scrollWheelZoom={false}
                        zoomControl={false}
                    >
                        <TileLayer
                            opacity={0}
                            url="https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=Vugg7D5KYhqFqvgKtujb"
                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>'

                        />
                        {
                            usaStatesData.features.map((state) => {
                                const coordinates = state.geometry.coordinates[0].map((item) => [item[1], item[0]]);
                                return (
                                    <>
                                        <div >
                                            <SingleBuilderModal
                                                openModal={openUsaModal}
                                                setOpenModal={setOpenUsaModal}
                                                builderName={newData.builderName}
                                                builderType={newData.builderType}
                                                phoneNo={newData.phoneNo}
                                                email={newData.email}
                                                builderValue={newData.builderValue}
                                                firstBuilderName={newData.firstBuilderName}
                                                firstBuilderPhn={newData.firstBuilderPhoneNo}
                                                firstBuilderEmail={newData.fristBuilderEmail}
                                                secondBuilderName={newData.secondBuilderName}
                                                secondBuilderPhn={newData.secondBuilderPhoneNo}
                                                secondBuilderEmail={newData.secondBuilderEmail}
                                            />
                                            <Polygon
                                                pathOptions={{
                                                    fillColor: getColor(state.properties.builderName),
                                                    fillOpacity: 0.95,
                                                    weight: 1,
                                                    opacity: 1,
                                                    dashArray: 0,
                                                    stroke: getColor(state.properties.builderName),
                                                    color: getColor(state.properties.builderName)

                                                }}
                                                positions={coordinates}
                                                eventHandlers={{
                                                    mouseover: (e) => {
                                                        const layer = e.target;
                                                        layer.setStyle({
                                                            dashArray: 0,
                                                            fillColor: 'white',
                                                            fillOpacity: 0.95,
                                                            stroke: getColor(state.properties.builderName),
                                                            weight: 1,
                                                            opacity: 0.9,
                                                        })

                                                    },
                                                    mouseout: (e) => {
                                                        const layer = e.target;
                                                        layer.setStyle({
                                                            fillOpacity: 0.95,
                                                            opacity: 0.9,
                                                            weight: 1,
                                                            dashArray: 0,
                                                            fillColor: getColor(state.properties.builderName),
                                                            stroke: getColor(state.properties.builderName),
                                                        });
                                                    },
                                                    click: () => {
                                                        setnewData({
                                                            ...newData,
                                                            cityName: state.properties.cityName,
                                                            builderName: state.properties.builderName,
                                                            builderType: state.properties.builderType,
                                                            phoneNo: state.properties.phoneNo,
                                                            email: state.properties.email,
                                                            builderValue: state.properties.singleBuilder,
                                                            firstBuilderName: state.properties.firstBuilderName,
                                                            secondBuilderName: state.properties.secondBuilderName,
                                                            firstBuilderPhoneNo: state.properties.firstBuilderPhoneNo,
                                                            secondBuilderPhoneNo: state.properties.secondBuilderPhoneNo,
                                                            fristBuilderEmail: state.properties.firstBuilderEmail,
                                                            secondBuilderEmail: state.properties.secondBuilderEmail,
                                                        })
                                                        setOpenUsaModal(true)
                                                    }
                                                }}
                                            >
                                                <Tooltip className='tooltip' direction='right' sticky ><b> {state.properties.cityName} </b><br /> {state.properties.builderName}</Tooltip>
                                            </Polygon>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </MapContainer>
                </Grid>
            </Grid>
            <div className='mapdiv'>
                <MapContainer
                    center={alaskaMapCenter}
                    zoom={3}
                    style={{ width: '100%', height: '40vh' }}
                    className='tilelayer mapHeightAlaska'
                    attributionControl={false}
                    doubleClickZoom={false}
                    closePopupOnClick={false}
                    dragging={false}
                    zoomSnap={false}
                    zoomDelta={false}
                    trackResize={false}
                    touchZoom={false}
                    scrollWheelZoom={false}
                    zoomControl={false}
                >
                    <TileLayer
                        opacity={0}
                        url="https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=Vugg7D5KYhqFqvgKtujb"
                        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'

                    />
                    {
                        alaskaStatesData.features.map((state) => {
                            const coordinates = state.geometry.coordinates[0].map((item) => [item[1], item[0]]);
                            return (
                                <>
                                    <div>
                                        <SingleBuilderModal
                                            openModal={openAlaskaModal}
                                            setOpenModal={setOpenAlaskaModal}
                                            builderName={newData.builderName}
                                            builderType={newData.builderType}
                                            builderValue={newData.builderValue}
                                            phoneNo={newData.phoneNo}
                                            email={newData.email}
                                        />
                                        <Polygon
                                            pathOptions={{
                                                fillColor: getColor(state.properties.builderName),
                                                fillOpacity: 0.9,
                                                weight: 0.5,
                                                opacity: 1,
                                                dashArray: 0,
                                                color: getColor(state.properties.builderName)

                                            }}
                                            positions={coordinates}
                                            eventHandlers={{
                                                mouseover: (e) => {
                                                    const layer = e.target;
                                                    layer.setStyle({
                                                        dashArray: 0,
                                                        fillColor: 'white',
                                                        fillOpacity: 0.9,
                                                        weight: 0.5,
                                                        opacity: 1,
                                                    })

                                                },
                                                mouseout: (e) => {
                                                    const layer = e.target;
                                                    layer.setStyle({
                                                        fillOpacity: 0.9,
                                                        weight: 0.5,
                                                        dashArray: 0,
                                                        fillColor: getColor(state.properties.builderName),

                                                    });
                                                },
                                                click: () => {
                                                    setOpenAlaskaModal(true)
                                                    setnewData({
                                                        ...newData,
                                                        cityName: state.properties.cityName,
                                                        builderName: state.properties.builderName,
                                                        builderType: state.properties.builderType,
                                                        builderValue: state.properties.singleBuilder,
                                                        phoneNo: state.properties.phoneNo,
                                                        email: state.properties.email,
                                                    })
                                                }
                                            }}
                                        >
                                            <Tooltip className='tooltip' sticky ><b> {state.properties.cityName} </b><br /> {state.properties.builderName}</Tooltip>
                                        </Polygon>
                                    </div>
                                </>
                            )
                        })
                    }
                </MapContainer>
                <MapContainer
                    center={hawaiiMapCenter}
                    zoom={5}
                    style={{ width: '100%', height: '40vh' }}
                    className='tilelayer '
                    attributionControl={false}
                    doubleClickZoom={false}
                    closePopupOnClick={false}
                    dragging={false}
                    zoomSnap={false}
                    zoomDelta={false}
                    trackResize={false}
                    touchZoom={false}
                    scrollWheelZoom={false}
                    zoomControl={false}
                >
                    <TileLayer
                        opacity={0}
                        url="https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=Vugg7D5KYhqFqvgKtujb"
                        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                    />
                    {
                        hawaiiStatesData.features.map((state) => {
                            const coordinates = state.geometry.coordinates[0].map((item) => [item[1], item[0]]);
                            return (
                                <>
                                    <div>
                                        <SingleBuilderModal
                                            openModal={openHawaiiModal}
                                            setOpenModal={setOpenHawaiiModal}
                                            builderName={newData.builderName}
                                            builderType={newData.builderType}
                                            builderValue={newData.builderValue}
                                            phoneNo={newData.phoneNo}
                                            email={newData.email}
                                        />
                                        <Polygon
                                            pathOptions={{
                                                fillColor: getColor(state.properties.builderName),
                                                fillOpacity: 0.9,
                                                weight: 0.5,
                                                opacity: 1,
                                                dashArray: 0,
                                                color: getColor(state.properties.builderName)

                                            }}
                                            positions={coordinates}
                                            eventHandlers={{
                                                mouseover: (e) => {
                                                    const layer = e.target;
                                                    layer.setStyle({
                                                        dashArray: 0,
                                                        fillColor: 'white',
                                                        fillOpacity: 0.9,
                                                        weight: 0.5,
                                                        opacity: 1,
                                                    })

                                                },
                                                mouseout: (e) => {
                                                    const layer = e.target;
                                                    layer.setStyle({
                                                        fillOpacity: 0.9,
                                                        weight: 0.5,
                                                        dashArray: 0,
                                                        fillColor: getColor(state.properties.builderName),

                                                    });
                                                },
                                                click: () => {
                                                    setOpenHawaiiModal(true)
                                                    setnewData({
                                                        ...newData,
                                                        cityName: state.properties.cityName,
                                                        builderName: state.properties.builderName,
                                                        builderType: state.properties.builderType,
                                                        builderValue: state.properties.singleBuilder,
                                                        phoneNo: state.properties.phoneNo,
                                                        email: state.properties.email,
                                                    })
                                                }
                                            }}
                                        >
                                            <Tooltip direction='bottom' className='tooltip' sticky ><b> {state.properties.cityName} </b><br /> {state.properties.builderName}</Tooltip>
                                        </Polygon>
                                    </div>
                                </>
                            )
                        })
                    }
                </MapContainer>
                <MapContainer
                    style={{ width: '100%', height: '40vh', }}
                    center={hawaiiMapCenter}
                    zoom={5}
                    className='tilelayer '
                    attributionControl={false}
                    doubleClickZoom={false}
                    closePopupOnClick={false}
                    dragging={false}
                    zoomSnap={false}
                    zoomDelta={false}
                    trackResize={false}
                    touchZoom={false}
                    scrollWheelZoom={false}
                    zoomControl={false}
                >
                </MapContainer>
                <MapContainer
                    style={{ width: '100%', height: '40vh', }}
                    center={hawaiiMapCenter}
                    zoom={5}
                    className='tilelayer '
                    attributionControl={false}
                    doubleClickZoom={false}
                    closePopupOnClick={false}
                    dragging={false}
                    zoomSnap={false}
                    zoomDelta={false}
                    trackResize={false}
                    touchZoom={false}
                    scrollWheelZoom={false}
                    zoomControl={false}
                >
                </MapContainer>
            </div>
        </>
    )
}
export default UASMap
