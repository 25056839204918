import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { fetchCountries, fetchDecTecAbouts, fetchInquiryReasons, fetchRequestorTypes, fetchState, saveContactUs } from '../api/UserAPI';
import CanadaMap from '../components/CanadaMap';
import TitleCard from '../components/TitleCard';
import UASMap from '../components/UASMap';
import { Helmet } from "react-helmet";

const Contact = () => {

  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [inquiryReasonList, setInquiryReasonList] = useState([]);
  const [decTecAboutList, setDecTecAboutList] = useState([]);
  const [requesterTypeList, setRequesterTypeList] = useState([]);

  const contactUsDetailsValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    companyName: '',
    address: '',
    city: '',
    zipCode: '',
    stateId: 0,
    countryId: 0,
    inquiryReasonId: 0,
    decTecAboutId: 0,
    requesterTypeId: 0,
    message: "",
  }
  const [contactUsDetails, setContactUsDetails] = useState(contactUsDetailsValues);

  function handleChange(e) {
    const { name, value } = e.target;
    setContactUsDetails({ ...contactUsDetails, [name]: value });
  }

  async function submitHandleSend() {
    console.log("CALLED")
    setFormErrors(validate(contactUsDetails));
    setIsSubmit(true);
  }


  async function fetchStateList() {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: 1,
      IncludeRecordNr: true,
      SearchList: [{}],
      SortList: [
        {
          FieldName: "StateName",
          Direction: "ASC",
        },
      ],
    }
    const stateRes = await fetchState(obj);
    setStateList(stateRes);
  }

  async function fetchCountry() {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: 1,
      IncludeRecordNr: true,
      SearchList: [{}]
    }
    try {
      const countryRes = await fetchCountries(obj);
      setCountryList(countryRes);
    }
    catch (error) {
      console.log(error)
    }
  }

  async function fetchReasonForEnq() {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: 1,
      IncludeRecordNr: true,
      SearchList: [{}]
    }
    try {
      const inquiryReasonRes = await fetchInquiryReasons(obj);
      setInquiryReasonList(inquiryReasonRes);
    }
    catch (error) {
      console.log(error)
    }
  }

  async function fetchWhereYouHeardAboutDecTec() {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: 1,
      IncludeRecordNr: true,
      SearchList: [{}]
    }
    const fetchDecTecAboutsRes = await fetchDecTecAbouts(obj);
    setDecTecAboutList(fetchDecTecAboutsRes);
  }

  async function fetchIAmField() {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: 1,
      IncludeRecordNr: true,
      SearchList: [{}]
    }
    try {
      const fetchRequestorTypesRes = await fetchRequestorTypes(obj);
      setRequesterTypeList(fetchRequestorTypesRes);
    }
    catch (error) {
      console.log(error)
    }
  }

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstName) {
      errors.firstName = "FirstName is required!";
    }
    if (!values.lastName) {
      errors.lastName = "LastName is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phoneNo) {
      errors.phoneNo = "Phone Number is required!";
    }

    if (!values.city) {
      errors.city = "City is required!";
    }
    if (!values.stateId) {
      errors.stateId = "State is required!";
    }
    if (!values.zipCode) {
      errors.zipCode = "ZipCode is required!";
    }
    if (!values.countryId) {
      errors.countryId = "Country is required!";
    }
    if (!values.inquiryReasonId) {
      errors.inquiryReasonId = "Inquiry Reason is required!";
    }
    if (!values.decTecAboutId) {
      errors.decTecAboutId = "This field is required!";
    }
    if (!values.requesterTypeId) {
      errors.requesterTypeId = "This field is required!";
    }

    return errors;
  };


  async function getFinalApiCall() {
    alert("Submitted Successfully...")
    if (contactUsDetails.firstName === '' && contactUsDetails.lastName === '' && contactUsDetails.email === '' && contactUsDetails.phoneNo === '' && contactUsDetails.city === '' &&
      contactUsDetails.stateId === '' && contactUsDetails.zipCode === '' && contactUsDetails.countryId === '' && contactUsDetails.inquiryReasonId === '' && contactUsDetails.decTecAboutId === '' && contactUsDetails.requesterTypeId === '') {
      return;
    }
    try {
      const usersProfileObject = {
        UserId: 1,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [{
          Id: 0,
          FirstName: contactUsDetails.firstName,
          LastName: contactUsDetails.lastName,
          Email: contactUsDetails.email,
          PhoneNumber: contactUsDetails.phoneNo,
          CompanyName: contactUsDetails.companyName,
          Address: contactUsDetails.address,
          City: contactUsDetails.city,
          StateId: contactUsDetails.stateId,
          PostalCode: contactUsDetails.zipCode,
          CountryId: contactUsDetails.countryId,
          InquiryReasonId: contactUsDetails.inquiryReasonId,
          DecTecAboutId: contactUsDetails.decTecAboutId,
          RequestorTypeId: contactUsDetails.requesterTypeId,
          Message: contactUsDetails.message,
          ModifiedBy: 1
        }]
      }

      const usersProfileResponces = await saveContactUs(usersProfileObject);
      console.log("usersProfileResponces===>", usersProfileResponces)
      console.log("usersProfileResponces-Id", usersProfileResponces[0].objData.id);

      setContactUsDetails(contactUsDetailsValues)
    }
    catch (err) {
      console.log("submitHandleSend --> ", err);
    }

  }


  useEffect(() => {
    fetchStateList();
    fetchCountry();
    fetchReasonForEnq();
    fetchWhereYouHeardAboutDecTec();
    fetchIAmField();

  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      getFinalApiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);





  const allCardDetails = [
    {
      id: 1,
      imagePath: "https://dectecstorageprod.blob.core.windows.net/website/images/contactus/tyler-franta-603090-unsplash-min.jpg",
      firstText: 'CONTACT',
      boldText: 'Us',
      description: '',
      isImageOnlyFlag: true,
      textShow: true,
      textDirect: true,
      textAlign: true,
      cartHeight: '100vh',
      bgAttachment: 'initial',
      bgPosition: 'center top',
    },
  ];


  return (
    <>
         <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Contact Us : 1-866-461-3914"
        />
      </Helmet>
    <div>

      <Grid item xs={12}>
        <TitleCard cardData={{ allCardDetails }} />
      </Grid>


      <Container maxWidth="lg">
        <div className="warranty-form padding-lg">
          <div className='title-container'>
            <h3 align="center" className='headingTitle small-font' ><span className='text-with-border title-bold '>GET IN TOUCH </span><span className='thin-content'>WITH US</span> </h3>
          </div>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel shrink htmlFor="bootstrap-input"> First Name <span className="mandatoryField">*</span></InputLabel>
              <TextField
                placeholder="John"
                type='text'
                inputProps={{ style: { color: "black" } }}
                name="firstName"
                value={contactUsDetails.firstName}
                onChange={handleChange}
                style={{ width: '100%' }}
                id="outlined-start-adornment"
              />
              <p style={{ color: 'red' }}>{formErrors.firstName}</p>
            </Grid>

            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> Last Name <span className="mandatoryField">*</span></InputLabel>
              <TextField
                placeholder="Doe"
                type="text"
                inputProps={{ style: { color: "black" } }}
                name="lastName"
                value={contactUsDetails.lastName}
                onChange={handleChange}
                style={{ width: '100%' }}
                id="outlined-start-adornment"
              />
              <p style={{ color: 'red' }}>{formErrors.lastName}</p>
            </Grid>

            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel shrink htmlFor="bootstrap-input"> EMAIL <span className="mandatoryField">*</span></InputLabel>
              <TextField
                placeholder="johndoe@email.com"
                type="email"
                size="small"
                inputProps={{ style: { color: "black" } }}
                name="email"
                value={contactUsDetails.email}
                onChange={handleChange}
                style={{ width: '100%' }}
                id="outlined-start-adornment"
              />
              <p style={{ color: 'red' }}>{formErrors.email}</p>
            </Grid>

            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> PHONE NUMBER <span className="mandatoryField">*</span></InputLabel>
              <TextField
                placeholder="1-555-123-4567"
                type="number"
                size="small"
                inputProps={{ style: { color: "black" } }}
                name="phoneNo"
                value={contactUsDetails.phoneNo}
                onChange={handleChange}
                style={{ width: '100%' }}
                id="outlined-start-adornment"
              />
              <p style={{ color: 'red' }}>{formErrors.phoneNo}</p>
            </Grid>

            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel shrink htmlFor="bootstrap-input"> COMPANY NAME (IF APPLICABLE) </InputLabel>
              <TextField
                placeholder="Joe's Contractors Inc."
                inputProps={{ style: { color: "black" } }}
                name="companyName"
                value={contactUsDetails.companyName}
                onChange={handleChange}
                style={{ width: '100%' }}
                id="outlined-start-adornment"
              />
            </Grid>


            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel shrink htmlFor="bootstrap-input"> ADDRESS</InputLabel>
              <TextField
                placeholder="123 Main Street"
                inputProps={{ style: { color: "black" } }}
                name="address"
                value={contactUsDetails.address}
                onChange={handleChange}
                style={{ width: '100%' }}
                id="outlined-start-adornment"
              />
            </Grid>

            <Grid item lg={3} sm={12} xs={12} md={3}>
              <InputLabel shrink htmlFor="bootstrap-input"> CITY <span className="mandatoryField">*</span></InputLabel>
              <TextField
                placeholder="New York City"
                type='text'
                inputProps={{ style: { color: "black" } }}
                name="city"
                value={contactUsDetails.city}
                onChange={handleChange}
                style={{ width: '100%' }}
                id="outlined-start-adornment"
              />
              <p style={{ color: 'red' }}>{formErrors.city}</p>
            </Grid>



            <Grid item lg={3} sm={12} xs={12} md={3}>
              <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> STATE/PROVINCE <span className="mandatoryField">*</span></InputLabel>
              <FormControl
                style={{ width: '100%' }}>
                <Select
                  // disableUnderline
                  displayEmpty
                  name="stateId"
                  defaultValue=""
                  // value={contactUsDetails.stateName}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {
                    stateList.map(({ id, stateName }) => {
                      return <MenuItem key={id} value={id}>{stateName}
                      </MenuItem>
                    })
                  }
                </Select>
                <p style={{ color: 'red' }}>{formErrors.stateId}</p>
              </FormControl>
            </Grid>



            <Grid item lg={3} sm={12} xs={12} md={3}>
              <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> ZIP/POSTAL CODE <span className="mandatoryField">*</span></InputLabel>
              <TextField
                placeholder="85001 or A1B 2C3"
                type='text'
                size="small"
                inputProps={{ style: { color: "black" } }}
                name="zipCode"
                value={contactUsDetails.zipCode}
                onChange={handleChange}
                style={{ width: '100%' }}
                id="outlined-start-adornment"
              />
              <p style={{ color: 'red' }}>{formErrors.zipCode}</p>
            </Grid>



            <Grid item lg={3} sm={12} xs={12} md={3} >
              <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> COUNTRY <span className="mandatoryField">*</span></InputLabel>
              <FormControl
                style={{ width: '100%' }}>
                <Select
                  // disableUnderline
                  displayEmpty
                  name="countryId"
                  defaultValue=""
                  // value={contactUsDetails.countryName}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {countryList.map(({ id, countryName }) => {
                    return <MenuItem key={id} value={id}>{countryName}</MenuItem>
                  })
                  }
                </Select>
                <p style={{ color: 'red' }}>{formErrors.countryId}</p>
              </FormControl>
            </Grid>


            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel shrink htmlFor="bootstrap-input"> REASON FOR INQUIRY <span className="mandatoryField">*</span></InputLabel>
              <FormControl
                style={{ width: '100%' }}>
                <Select
                  // disableUnderline
                  displayEmpty
                  name="inquiryReasonId"
                  defaultValue=""
                  // value={contactUsDetails.inquiryReasonId}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {inquiryReasonList.map(({ id, inquiryReasonName }) => {
                    return <MenuItem key={id} value={id}>{inquiryReasonName}</MenuItem>
                  })
                  }
                </Select>
                <p style={{ color: 'red' }}>{formErrors.inquiryReasonId}</p>
              </FormControl>
            </Grid>



            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> WHERE YOU HEARD ABOUT DEC-TEC <span className="mandatoryField">*</span></InputLabel>
              <FormControl
                style={{ width: '100%' }}>
                <Select
                  // disableUnderline
                  displayEmpty
                  name="decTecAboutId"
                  defaultValue=""
                  // value={contactUsDetails.inquiryReasonId}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {decTecAboutList.map(({ id, decTecAboutName }) => {
                    return <MenuItem key={id} value={id}>{decTecAboutName}</MenuItem>
                  })
                  }
                </Select>
                <p style={{ color: 'red' }}>{formErrors.decTecAboutId}</p>
              </FormControl>
            </Grid>

            <Grid item lg={4} sm={12} xs={12} md={3}>
              <InputLabel shrink htmlFor="bootstrap-input"> I AM A... <span className="mandatoryField">*</span></InputLabel>
              <FormControl
                style={{ width: '100%' }}>
                <Select
                  // dcisableUnderline
                  displayEmpty
                  name="requesterTypeId"
                  defaultValue=""
                  // value={contactUsDetails.requesterTypeId}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {requesterTypeList.map(({ id, requestorTypeName }) => {
                    return <MenuItem key={id} value={id}>{requestorTypeName}</MenuItem>
                  })
                  }
                </Select>
                <p style={{ color: 'red' }}>{formErrors.requesterTypeId}</p>
              </FormControl>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} md={12}>
              <InputLabel className="textField-label" shrink htmlFor="bootstrap-input"> MESSAGE </InputLabel>
              <TextareaAutosize
                placeholder="Type your message here"
                style={{ width: '99.5%', height: 200 }}
                name="message"
                value={contactUsDetails.message}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item lg={12} md={12} xs={12} sm={12}>
              <Button style={{ marginTop: "10px", width: '100%', backgroundColor: "#00779f" }} onClick={(e) => submitHandleSend(e)} variant="contained">Send</Button>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="lg">
        <div className="warranty-form">
          <div className='title-container'>
            <h1 align="center" className='contentTitle small-font'><span className='thin-content'>FIND YOUR </span>SALES REPRESENTATIVE </h1>
          </div>
          <div>
            <h1 className='contentTitle small-font'><b>United States</b></h1>
          </div>
          <div key={Math.random()}>
            <UASMap />
          </div>
        </div>
        <div className="warranty-form">
          <div>
            <h1 className='contentTitle small-font'><b>Canada</b></h1>
          </div>
          <div key={Math.random()}>
            <CanadaMap />
          </div>
        </div>
      </Container>
    </div>
    </>
  )
}

export default Contact